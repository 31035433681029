import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UnitesDeMesuresService} from '../entities/unites-de-mesures.service';
import {StockMouvementStatutDTO} from '../../dtos/stock-mouvement-statut-dto';
import {Subject} from "rxjs/index";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../suppliers/search-supplier";
import {StockDTO} from "../../dtos/stock-dto";

export const URL_POST_SAVE_SORTIE=`dolrest/gestion-stocks/save/sortie`;
export const URL_FIND_ALL_BY=`dolrest/gestion-stocks/findBy/stock-mouvement-statut`;
export const URL_DELETE=`dolrest/gestion-stocks/delete/stock-mouvement-statut`;
export const URL_SAVE=`dolrest/gestion-stocks/save/stock-mouvement-statut`

@Injectable({
  providedIn: 'root'
})
export class StockMouvementStatutService {

  stockMouvementStatutListEnvironnement: StockMouvementStatutDTO[] = [];

  private subjectOpenDialogStatut = new Subject<StockMouvementStatutDTO>();
  openDialogStatut$ = this.subjectOpenDialogStatut.asObservable();

  private subjectStatutSaved = new Subject<StatutSavedSupplier>();
  statutSaved$ = this.subjectStatutSaved.asObservable();

  constructor(private gds: GenericDatagridService,
              private udmSvc: UnitesDeMesuresService,
              private httpSvc: HttpService
  ) {
  }

  announceOpenDialogStatut(param: StockMouvementStatutDTO) {
    this.subjectOpenDialogStatut.next(param);
  }

  announceStatutSaved(statut: StockMouvementStatutDTO, isUpdate: boolean = false) {
    this.subjectStatutSaved.next({ isUpdate, statut } as StatutSavedSupplier);
  }

  saveSpecific(statut: StockMouvementStatutDTO): Observable<ResponseWrapper<StockMouvementStatutDTO>> {
    return this.httpSvc.post(URL_SAVE, statut);
  }

  /**
   * Supprime un ou plusieurs statut(s) en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds(idsToDelete: number[]): Observable<any> {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  /**
   * Récupérer toutes les valeurs
   * @returns {Observable<StockMouvementStatutDTO>}
   */
  getAll(isSortie?: boolean, isEntree?: boolean): Observable<ResponseWrapper<StockMouvementStatutDTO>> {
    const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
    if (isEntree !== undefined && isEntree !== null) {
      ssw.filtersMap['entree'] = new SearchSupplier(isEntree);
    }
    if (isSortie !== undefined && isSortie !== null) {
      ssw.filtersMap['sortie'] = new SearchSupplier(isSortie);
    }
    return this.httpSvc.post(`${URL_FIND_ALL_BY}?page=0&size=999`, ssw);
  }

  saveSortie: (stock: StockDTO) => Observable<any> = (stock: StockDTO) => this.httpSvc.post(URL_POST_SAVE_SORTIE, stock);

}

export interface StatutSavedSupplier {
  isUpdate: boolean;
  statut: StockMouvementStatutDTO;
}

