import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {catchError, switchMap} from 'rxjs/operators';
import {Injectable, NgZone, OnDestroy} from '@angular/core';

import {PreferenceutilisateurDTO} from '../dtos/preferenceutilisateur-dto';
import {UtilsService} from '../utils/utils.service';
import {AUTH, DATES_FORMAT, USER_TYPE_PREFERENCE} from '../constants';
import {Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';
import {FrontStorageService} from './storage/front-storage.service';
import * as moment from 'moment';
import {findIndex as _findIndex, parseInt as _parseInt, split as _split} from 'lodash';
import {HttpService} from "./technique/http.service";
import {ResponseWrapper} from "../suppliers/wrappers/response-wrapper";

export const URL_SAVE_ONE_PREFERENCE = 'dolrest/gestionpreferences/save/one';

@Injectable()
export class PreferencesUtilisateurService implements OnDestroy {


  private preferencesUtilisateur: PreferenceutilisateurDTO[] = this.initPreferencesFromLocalStorage();

  preferencesGlobales: any;

  private subjectPrefOfUser = new BehaviorSubject(this.preferencesUtilisateur);
  public preferencesOfUser$ = this.subjectPrefOfUser.asObservable();

  private subjectOpenDialogEditPreferences = new Subject<boolean>();
  openDialogEditPreferences$ = this.subjectOpenDialogEditPreferences.asObservable();

  constructor(
    private httpSvc: HttpService,
    private _ngZone: NgZone,
    private frontStorageSvc: FrontStorageService,
    private router: Router,
    private utils: UtilsService) {
    this.preferencesGlobales = this.initPreferencesGlobalesFromLocalStorage();
  }

  /**
   * notifier tous les composants abonnés aux preferences utilisateur de mettre à jour leurs preferences
   */
  announcePreferencesUtilisateur = () => {
    if (this.preferencesUtilisateur) {
      this.subjectPrefOfUser.next(this.preferencesUtilisateur);
    }
  };

  /**
   * notifier tous les composants abonnés aux preferences utilisateur de mettre à jour leurs preferences
   * Assigne les nouvelles preferences
   */
  announceAndAssignUserPrefs = (prefs: PreferenceutilisateurDTO[]) => {
    if (!this.utils.isCollectionNullOrEmpty(prefs)) {
      this.preferencesUtilisateur = prefs;
      this.subjectPrefOfUser.next(this.preferencesUtilisateur);
    }
  };

  announceAndAssignGlobalesPrefs = (prefs: any) => {
    this.preferencesGlobales = prefs;
  }

  announceOpenDialogPreferences = (hasSuperAdmin: boolean) => {
    this.subjectOpenDialogEditPreferences.next(hasSuperAdmin);
  };

  ngOnDestroy(): void {

  }

  initPreferencesFromLocalStorage() {

    let prefs = this.frontStorageSvc.secureLS.get(AUTH.preferences);
    if (this.utils.isCollectionNullOrEmpty(prefs)) {
      prefs = [];
    }
    return prefs;

  }

  initPreferencesGlobalesFromLocalStorage = (): any => this.frontStorageSvc.secureLS.get(AUTH.preferencesGlobales);

  getMyPreferences = (username: string): Observable<ResponseWrapper<any>> => {
    if (username) {
      return this.httpSvc.get(`dolrest/gestionpreferences/mypreferences/${username}`);
    }
    return of(null);
  };

  savePreference = (key:string, typePref:string, value:any) => {
    this.assignPreferenceUtilisateurValeur(key, typePref, value);
    this.saveOnePreferenceUtilisateur(this.getPreferenceUtilisateur(key)).subscribe(response=> this.frontStorageSvc.secureLS.set(AUTH.preferences, this.preferencesUtilisateur));
  };

  saveOnePreferenceUtilisateur = (preference: PreferenceutilisateurDTO): Observable<PreferenceutilisateurDTO> => {

    if (this.utils.isNullOrEmpty(preference)) {
      return of(null);
    }
    return this.httpSvc.post(URL_SAVE_ONE_PREFERENCE, preference).pipe(
      catchError(error => this.utils.handleError(error, true)));
  };


  savePreferencesUtilisateur = (globalPreferencesApp: any): Observable<PreferenceutilisateurDTO[]> => {
    if (!this.utils.isCollectionNullOrEmpty(this.preferencesUtilisateur)) {
      const preferences: any[] = this.preferencesUtilisateur.map(p => ({ id: p.id, idUtilisateur: p.idUtilisateur, idPreference: p.idPreference, valeur: p.valeur, clefPreference: p.clefPreference }));
      return this.httpSvc.post(`dolrest/gestionpreferences/save`, { preferencesGlobaux: globalPreferencesApp, preferencesUtilisateur: preferences }).pipe(
        switchMap(data => {
          this.preferencesUtilisateur = data?.one?.preferencesUtilisateur;
          this.frontStorageSvc.secureLS.set(AUTH.preferences, this.preferencesUtilisateur);
          this.frontStorageSvc.secureLS.set(AUTH.preferencesGlobales, globalPreferencesApp);
          this.announcePreferencesUtilisateur();
          this.announceAndAssignGlobalesPrefs(globalPreferencesApp);
          return of(data);
        }),
        catchError(error => this.utils.handleError(error, true)));
    }
    return of(null);
  };

  assignPreferenceUtilisateurValeur = (key: string, typeValeur: string, newValeur: any) => {
    let prefUser = this.getPreferenceUtilisateur(key);

    if (prefUser) {
      if (typeValeur === USER_TYPE_PREFERENCE.BOOLEAN) {
        prefUser.valeur = newValeur === true ? 'true' : 'false';
      } else if (typeValeur === USER_TYPE_PREFERENCE.NUMBER) {
        prefUser.valeur = newValeur + '';
      } else if (typeValeur === USER_TYPE_PREFERENCE.NUMBERARR) {

        let str = '';
        // prefUser.valeur = '' + newValeur;
        prefUser.valeur = newValeur.reduce((prev, next, i) => {
          if (i < newValeur.length - 1) {
            return prev + next + ',';
          } else {
            return prev + next;
          }

        }, '');

      } else if (typeValeur === USER_TYPE_PREFERENCE.STRING) {

        prefUser.valeur = newValeur;

      } else if (typeValeur === USER_TYPE_PREFERENCE.STRINGARR) {

      } else if (typeValeur === USER_TYPE_PREFERENCE.DATE) {
        prefUser.valeur = this.utils.getYYYYMMDD(moment(newValeur));
      }
    }
  };

  /**
   *
   * @param {string} key --> see USER_PREFERENCE in constants.ts
   * @returns {PreferenceutilisateurDTO}
   */
  getPreferenceUtilisateur = (key: string): PreferenceutilisateurDTO => {

    if (this.preferencesUtilisateur) {
      let idx = _findIndex(this.preferencesUtilisateur, {
        clefPreference: key
      });
      if (idx && idx > -1) {

        // console.log(this.preferencesUtilisateur[idx]);
        return this.preferencesUtilisateur[idx];
      }
    }
    return null;
  };

  getPreferenceUtilisateurValue = (key: string): string => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser) {
      return prefUser.valeur;
    }
    return '';
  };

  getPreferenceUtilisateurBooleanValue = (key: string): boolean => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser && prefUser.valeur) {
      return prefUser.valeur.trim() === 'true' ? true : false;
    }
    return false;
  };

  getPreferenceUtilisateurDateValue = (key: string): Date => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser && prefUser.valeur) {
      return moment(prefUser.valeur, DATES_FORMAT.YYYYMMDD).toDate();
    }
    return undefined;
  };


  getPreferenceUtilisateurIntValue = (key: string): number => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser) {
      return +prefUser.valeur;
    }
    return 0;
  };


  getPreferenceUtilisateurArrIntValue = (key: string): number[] => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser && prefUser.valeur) {
      let arrStr: string[] = _split(prefUser.valeur, ',');
      if (arrStr) {
        let arrNumber: number[] = [];
        for (let a of arrStr) {
          arrNumber.push(_parseInt(a));
        }
        return arrNumber;
      }
    }
    return null;
  };

  getPreferenceUtilisateurArrStringValue = (key: string): string[] => {
    let prefUser = this.getPreferenceUtilisateur(key);
    if (prefUser && prefUser.valeur) {
      let arrStr: string[] = _split(prefUser.valeur, ',');

      return arrStr;
    }
    return null;
  };

}
