import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {GestionFournisseurService} from '../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {FournisseurDTO} from '../../core/dtos/fournisseur-d-t-o';

@Injectable({
  providedIn: 'root'
})
export class GridFournisseurResolverService implements Resolve<FournissseurSupplier> {

  constructor(private gestionFournisseurSvc: GestionFournisseurService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FournissseurSupplier> | Promise<FournissseurSupplier> | FournissseurSupplier => {

    let fournissseurSupplier: FournissseurSupplier = new FournissseurSupplier();

      const FournissseurParentList$ = this.gestionFournisseurSvc.getAllFournisseurParent();

      const all$ = combineLatest([FournissseurParentList$]);

      return all$.toPromise().then(data => {
        fournissseurSupplier.fournisseurParentList = data[0].resultList;

        return fournissseurSupplier;
      });
  };
}

export class FournissseurSupplier {
  fournisseurParentList: FournisseurDTO[] = [];
}


