<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openConditionnement(undefined)"
          [disabled]="!canEdit()"
          tooltip="Créer un conditionnement">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button [disabled]="!canEdit() || !selectedRows.length" buttonClass="cta-delete" (onClick)="deleteValues()"
                 tooltip="Supprimer les éléments sélectionnés" iconClass="fas fa-trash"></yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="conditionnementsList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" caption="Site"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="actionsSiteTemplate"
              [calculateCellValue]="calculateSiteValue">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="abreviation" caption="Abréviation"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="poidsEnKg" caption="Poids en Kg"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">

    <yo-cell-button (yoNavigation)="openConditionnement(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="gds.canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="gds.canModify(cell.row.data)?'Modifier le conditionnement':'Voir le conditionnement'">
    </yo-cell-button>

  </div>

  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} conditionnements">
  </dxo-pager>

</dx-data-grid>

<yo-conditionnement></yo-conditionnement>
