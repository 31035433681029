import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ProduitArticleDTO} from '../../dtos/produit-article-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';

@Injectable({
  providedIn: 'root'
})
export class ProduitArticleService  extends GenericHandler<ProduitArticleDTO>  {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }


  createEmptyDTO(): ProduitArticleDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: ProduitArticleDTO): string {
    return "";
  }

  getEntityName(): string {
    return "ProduitArticle";
  }

  getFields(dto: ProduitArticleDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES ARTICLES";
  }

  getTotalRecordsLabel(): string {
    return "";
  }
}



