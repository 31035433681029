<ng-container *ngIf="dateCreationMenu">

  <ng-container *ngIf="dateSaisieEffectif">

    <span class="saisie-effectifs-day" title="Menus validés"
    >{{cellData.text}}</span>

  </ng-container>

  <ng-container *ngIf="!dateSaisieEffectif">
    <span class="creation-menus-day" title="Menus en cours de saisie">{{cellData.text}}</span>
  </ng-container>
</ng-container>


<ng-container *ngIf="!dateCreationMenu">

  <ng-container *ngIf="dateSaisieEffectif">

    <span class="saisie-effectifs-day" title="Menus validés"
    >{{cellData.text}}</span>

  </ng-container>

  <ng-container *ngIf="!dateSaisieEffectif">
    <span title="Aucun menu">{{cellData.text}}</span>
  </ng-container>

</ng-container>
