<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <div>
        <i class="fas fa-cubes mg-r-5 mg-l-15"></i> MATIÈRES PREMIÈRES
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button iconClass="fas fa-trash"
                 class="mg-r-15"
                 buttonClass="cta-inner-delete"
                 label="Supprimer les lots périmés"
                 tooltip="Supprimer les lots périmés. Inactif, si le champ 'Périmé le' n'est pas renseigné."
                 (onClick)="openDialogPerimes()">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<div [class]="stocksAnnounced ? 'container-raws' : 'container-full-raws'">
  <dx-data-grid
    [dataSource]="matieresPremieres | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: totalDenrees }"
    keyExpr="id"
    height="100%"
    width="100%"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="false"
    [showRowLines]="true"
    [showBorders]="true"
    (onRowPrepared)="onRowPrepared($event)"
    (onRowClick)="onRowSelect($event)"
    (onInitialized)="onInitialized()"
    #grid
  >
    <dxi-column dataField="id" alignment="center" caption="Actions" [width]="80"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="produitDeclinaisonLibelle" alignment="left" caption="Déclinaison"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="produitDeclinaisonCode" alignment="left" caption="Code" [width]="200"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="quantite" alignment="left" caption="Qté en Kilo"
                [allowGrouping]="false" [allowFiltering]="false" cellTemplate="qteCellTemplate"></dxi-column>
    <dxi-column dataField="pump" alignment="left" caption="P.U.M.P"
                [allowGrouping]="false" [allowFiltering]="false" cellTemplate="amountCellTemplate"></dxi-column>
    <dxi-column dataField="totalHT" alignment="left" caption="Total HT"
                [allowGrouping]="false" [allowFiltering]="false" cellTemplate="amountCellTemplate"></dxi-column>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <yo-button iconClass="fas fa-plus" buttonClass="cta-inner-secondary"
                 tooltip="Ajout d'un nouveau lot" (onClick)="openDialogAjoutLot(cell.row.data, $event)"></yo-button>
    </div>
    <div *dxTemplate="let cell of 'qteCellTemplate'">
      {{ cell.value  | number:'1.4-4'}} Kg
    </div>
    <div *dxTemplate="let cell of 'amountCellTemplate'">
      {{ cell.value | number:'1.4-4' }} €
    </div>

    <dxo-paging
      [enabled]="true"
      [pageSize]="50"
      [pageIndex]="1">
    </dxo-paging>

  </dx-data-grid>
</div>
<div class="container">
  <div class="item">
    <pagination-controls
      class="text-center"
      [directionLinks]="false"
      (pageChange)="handlePageChange($event)"
      responsive="true"></pagination-controls>
  </div>
  <!--<div class="item">
    <dx-select-box
      style="width: max-content"
      [items]="[20, 50, 100, 200]"
      [(ngModel)]="pageSize"
      (ngModelChange)="updatePageSize()"
    ></dx-select-box>
  </div>-->
</div>
<div [class]="stocksAnnounced ? 'container-lots' : ''">
  <yo-lots [stocks]="stocks" [matierePremiere]="selectedMatierePremiere" [filters]="filters"></yo-lots>
</div>

<yo-dialog-perimes></yo-dialog-perimes>
<yo-dialog-lot-ajout></yo-dialog-lot-ajout>
<yo-dialog-sortie></yo-dialog-sortie>
