<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">

      <!--SELECTION CONTRAT-->
      <dx-lookup
        #dxLookupContrat
        class="mg-r-5"
        (onValueChanged)="changePlanning($event, chooserPlanning.CONTRAT)"
        [dataSource]="gms?.contrats"
        [(value)]="selectedContratMenu"
        [showClearButton]="false"
        [displayExpr]="'libelle'"
        [searchEnabled]="true"
        [style]="{'min-width':'300px','max-width':'750px' }"
        [cleanSearchOnOpening]="true"
        [showCancelButton]="false"
        placeholder="Choisir une offre alimentaire..."
        fieldTemplate="field"
        itemTemplate="listItem"
      >
        <dxo-drop-down-options [hideOnOutsideClick]="true" [showTitle]="false"></dxo-drop-down-options>

        <div *dxTemplate="let item of 'field'">
          <ng-container *ngIf="!utils.isNullOrEmpty(selectedContratMenu)">
            <label class="space no-overflow max-width-300 mg-b-0 font-style">
              <i class="fa fa-lock danger-color space"
                 *ngIf="menusToolbarSvc.isLockedContratMenu(selectedContratMenu)"
                 title="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"></i>
              <i class="fa fa-unlock save-color space"
                 *ngIf="!menusToolbarSvc.isLockedContratMenu(selectedContratMenu)"
                 title="Vous avez les droits pour créer des menus sur cette offre alimentaire"></i>

              {{selectedContratMenu.libelle | uppercase}}</label>
            <label class="font-12 floatright no-overflow max-width-300">
              <yo-site
                [siteId]="selectedContratMenu?.site?.id" [siteLibelle]="selectedContratMenu?.site?.libelle"></yo-site>
            </label>
          </ng-container>
        </div>

        <div *dxTemplate="let contratMenu of 'listItem'">
          <div title="{{contratMenu?.libelle | uppercase}} - {{contratMenu?.site.libelle  | uppercase}}">
            <label class="no-overflow max-width-300 mg-b-0 font-style">
              <span class="inline-block mg-r-5">
                <i class="fa fa-lock danger-color space"
                   *ngIf="!canModify(contratMenu)"
                   title="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"></i>
                <i class="fa fa-unlock save-color space"
                   *ngIf="canModify(contratMenu)"
                   title="Vous avez les droits pour créer des menus sur cette offre alimentaire"></i>
              </span>
              {{contratMenu?.libelle | uppercase}}
            </label>
            <span class="font-style floatright">
              <label class="no-overflow max-width-300">
                <yo-site [siteId]="contratMenu?.site.id" [siteLibelle]="contratMenu?.site.libelle"></yo-site>
              </label>
          </span>
          </div>
        </div>
      </dx-lookup>

      <!--SELECTION PRESTATIONS-->
      <dx-lookup
        #dxLookupPrestation
        class="mg-r-5"
        (onValueChanged)="changePlanning($event, chooserPlanning.PRESTATION)"
        [dataSource]="prestations"
        [(value)]="selectedPrestation"
        [showClearButton]="false"
        [displayExpr]="'libelle'"
        [searchEnabled]="true"
        [style]="{'min-width':'200px','max-width':'400px'}"
        [cleanSearchOnOpening]="true"
        [showCancelButton]="false"
        placeholder="Choisir une prestation..."
        fieldTemplate="field"
        itemTemplate="listItem"
      >
        <dxo-drop-down-options [hideOnOutsideClick]="true" [showTitle]="false"></dxo-drop-down-options>

        <div *dxTemplate="let item of 'field'">
          <span class="mg-r-5 font-style">{{item?.libelle}}</span>
        </div>

        <div *dxTemplate="let item of 'listItem'">
          <span class="mg-r-5 font-style">{{item?.libelle}}</span>
        </div>
      </dx-lookup>

      <!--SELECTION RÉGIME-->
      <dx-lookup
        #dxLookupRegime
        class="mg-r-5"
        (onValueChanged)="changePlanning($event, chooserPlanning.REGIME)"
        [dataSource]="regimes"
        [(value)]="selectedRegime"
        [showClearButton]="false"
        [style]="{'min-width':'200px','max-width':'400px'}"
        [displayExpr]="'libelle'"
        [searchEnabled]="true"
        [cleanSearchOnOpening]="true"
        [showCancelButton]="false"
        placeholder="Choisir un régime..."
        fieldTemplate="field"
        itemTemplate="listItem"
      >
        <dxo-drop-down-options [hideOnOutsideClick]="true" [showTitle]="false"></dxo-drop-down-options>

        <div *dxTemplate="let item of 'field'">
          <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item?.referent" title="Régime référent"></i>
          <span class="font-style">{{item?.libelle}}</span>
        </div>

        <div *dxTemplate="let item of 'listItem'">
          <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item?.referent" title="Régime référent"></i>
          <span class="font-style">{{item?.libelle}}</span>
        </div>
      </dx-lookup>

      <!--CALENDRIER-->
      <i class="fas fa-chevron-left cursor" (click)="goToPreviousDate()" title="Période précédente"></i>
      <dx-date-box
        class="w-10"
        [showDropDownButton]="false"
        [openOnFieldClick]="true"
        [(value)]="selectedStartDateMenus"
        (onValueChanged)="yoStartDateMenusChange($event)"
        [calendarOptions]="{ onOptionChanged: this.onOptionChanged }"
        [applyValueMode]="'instantly'">
                        <span *dxTemplate="let cell of 'cell'">
                          <yo-devextreme-date-dispo [cellData]="cell"
                                                    [dateSaisieEffectif]="utils.isDateInList(convertToDate(cell.date),datesSaisieEffectifs)"
                                                    [dateCreationMenu]="utils.isDateInList(convertToDate(cell.date),datesCreationMenus)"></yo-devextreme-date-dispo>
                        </span>
      </dx-date-box>
      <i class="fas fa-chevron-right cursor mg-r-5" (click)="goToNextDate()" title="Période suivante"></i>

      <!--DUPLICATION DES MENUS-->
      <yo-button class="mg-r-5" tooltip="Dupliquer des menus"
                 (onClick)="openDuplicationDialog()"
                 [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)"
                 iconClass="fas fa-copy">
      </yo-button>

      <!--DIALOGUE D'IMPRESSION DES MENUS-->
      <!--    <yo-button class="mg-r-5" [disabled]="printButtonDisabled()" tooltip="Impression du menu" (onClick)="openPrintDialog()" iconClass="fas fa-print"></yo-button>-->
      <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
      <yo-button
        iconClass="fas fa-print"
        class="mg-r-5"
        (onClick)="printMenu.toggle($event)"
        tooltip="Impression"
        [disabled]="isDisabledActionButton()">
      </yo-button>

      <!--GEMRCN-->
      <p-menu appendTo="body" #analyseMenu [popup]="true" [model]="itemsAnalyseMenu"></p-menu>
      <yo-button class="mg-r-5"
                 tooltip="Analyses"
                 (onClick)="analyseMenu.toggle($event)"
                 iconClass="fas fa-chart-line"
                 [disabled]="isDisabledActionButton()">
      </yo-button>

      <!--VALIDATION DES MENUS-->
      <yo-button class="mg-r-5"
                 iconClass="far fa-calendar-check"
                 tooltip="Envoi des menus sur les PLC associés à cette prestation"
                 (onClick)="openValidationMenu()"
                 [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)">
      </yo-button>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <!--afficher/cacher les famnut, allergenes, appellations, effectifs... -->
      <ng-container *ngIf="prefsUser">
        <ng-container *ngTemplateOutlet="tplSettings; context:{}">
        </ng-container>
      </ng-container>

      <!--TEMPLATE CASES À COCHER PARAMÉTRAGE-->
      <ng-template #tplSettings>

        <yo-button (onClick)="menusToolbarSvc.openSettings()"
                   class="mg-r-15"
                   iconClass="fas fa-wrench"
                   tooltip="Réglages du planning">
        </yo-button>

      </ng-template>
    </div>
  </dxi-item>
</dx-toolbar>

<yo-validation-menu></yo-validation-menu>

<!--DIALOG DE DUPLICATION DES MENUS-->
<yo-duplication></yo-duplication>

<!--DIALOG PRINT COUT MATIERE-->
<yo-print-cout-matiere
  [contratsMenuConvive]="selectedPrestation"
  [selectedStartDateMenus]="selectedStartDateMenus"
  [selectedRegime]="selectedRegime"
  [regimeList]="regimes"
  [repasList]="mp2Svc.planningMenus ? mp2Svc.planningMenus.repasList : undefined">
</yo-print-cout-matiere>

<!--DIALOG PRINT BILAN ALLERGENE-->
<yo-print-bilan-allergene
  [selectedStartDateMenus]="selectedStartDateMenus"
  [contratsMenuConvives]="[selectedPrestation]"
  [regimeList]="regimes">
</yo-print-bilan-allergene>

<yo-print-menu
  [contratMenuConvive]="selectedPrestation"
  [regimeList]="regimes"
  [selectedRegime]="selectedRegime"
  [selectedStartDateMenus]="selectedStartDateMenus">
</yo-print-menu>


