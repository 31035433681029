import {MenuCompositionDTO} from "../../dtos/menucomposition-dto";
import DuplicationMenuErrorModel from "./duplication-menu-error-model";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../dtos/contratmenuconvive__contrainte-alim-dto";

export default class DuplicationMenuResultModel {
  elementsImported: MenuCompositionDTO[] = [];
  reports: Map<string, DuplicationMenuErrorModel[]> = new Map();
  nbPlatsCopiesTarget: {[key: string]: number};
  allElementsImported: boolean;
  cmcCaSource: ContratMenuConvive__ContrainteAlimDTO;
  nbPlatsCopies: number;
  nbPlatsSources: number;
}
