import {SecteurDTO} from './secteur-dto';
import {ObjectDTO} from './object-dto';
import {FournisseurDTO} from './fournisseur-d-t-o';

export class SecteurFournisseurDTO extends ObjectDTO {

  actif: boolean;
  secteur: SecteurDTO;
  secteurLibelle:string;

  fournisseurLibelle: string;
  fournisseurCode: string;
  fournisseurActif: boolean;
  fournisseurSiteId:number;
  libelle: string;
  fournisseurId: number;
  fournisseur: FournisseurDTO;

  //TODO TO remove all below

  //fournisseur
  fId: number;
  fCode: string;
  fLibelle: string;
  fSiteId: string;

  //fournisseur filiale
  ffId: number;
  ffLibelle: string;
  ffAdresseId: number;
  ffSiteId: number;

  //secteur fournisseur
  sfId: number;

  //adresse
  adresseVille: string;
  adresseCodePostal: string;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  adresseTelephone: string;
  adresseFax: string;
  adresseSiret: string;
  adresseCodeTva: string;
  adresseIban: string;
  adresseId: number;


  //image
  imageId: number;
  imageType: number;


  constructor(idFournisseur : number, secteur: SecteurDTO) {
    super();
    this.fournisseurId = idFournisseur;
    this.secteur = secteur;
  }
}
