import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {LotMarcheLmupCaDto} from '../../dtos/gerstion-marche/lot-marche-lmup-ca-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';

export enum FK_LMUPCA {
  catalogueAchatFournisseurLibelle = 'catalogueAchatFournisseurLibelle',
  valeurEngagement = 'valeurEngagement',
  volumeEngagement = 'volumeEngagement',
  catalogueAchatId = 'catalogueAchatId',
  catalogueAchatLibelle = 'catalogueAchatLibelle',
  catalogueAchatSecteurLibelle = 'catalogueAchatSecteurLibelle',
  lotMarcheId = 'lotMarcheId',
  lotMarcheLibelle = 'lotMarcheLibelle',
  lotMarcheCode = 'lotMarcheCode',
  udpId = 'udpId',
  udpLibelle = 'udpLibelle',
  udpCode = 'udpCode',
  lmupcaId='lmupcaId'
}

@Injectable({
  providedIn: 'root'
})
export class LotmarcheUpCaService extends GenericHandler<LotMarcheLmupCaDto> {

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService) {

    super(utils, auth2Svc, router, http, title);
  }

  createEmptyDTO(): LotMarcheLmupCaDto {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  getEditObjectLabel(data: LotMarcheLmupCaDto): string {
    return '';
  }

  getEntityName(): string {
    return 'LotMarche__Lmup__Ca';
  }

  getFields(dto: LotMarcheLmupCaDto): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return '';
  }

  getTotalRecordsLabel(): string {
    return '';
  }
}
