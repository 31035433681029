import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {GroupeNutritionnelDTO} from '../../dtos/groupe-nutritionnel-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldColorPickerSupplier} from '../../suppliers/form-field-colorpicker-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';


@Injectable()
export class GroupesNutritionnelsService extends GenericHandler<GroupeNutritionnelDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getFields(dto: GroupeNutritionnelDTO): FormFieldBaseSupplier<any>[] {
    if (this.utils.isNullOrEmpty(dto)) {
      dto = new GroupeNutritionnelDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites,dto.site),
        order: 1,
        options: this.optionsSites
      }),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'ordre',
        label: 'Ordre',
        readonly: !this.canModify(dto),
        type: 'number',
        value: dto.ordre,
        required: true,
        order: 6
      }),

      new FormFieldColorPickerSupplier({
        key: 'couleur',
        label: 'Couleur',
        readonly: !this.canModify(dto),
        value: this.getHexColor(dto.couleur),
        type: 'string',
        order: 5
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: true,
        order: 7
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 8
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getEntityName(): string {
    return 'GroupeNutritionnel';
  }

  getTitle(): string {
    return 'GESTION DES GROUPES NUTRITIONNELS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN GROUPE NUTRITIONNEL';
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): GroupeNutritionnelDTO {
    return new GroupeNutritionnelDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {

    return `MODIFIER LE GROUPE NUTRITIONNEL '${data.libelle.toUpperCase()}'`;
  }

}
