export class PrintCoutRevientPlatDTO {

  periode: Date[];
  dateProductionSelected: Date;
  pointsLivraisonClients: any[] = [];
  unitesProduction: any[] = [];
  repas: any[] = [];
  typeEffectif: string;

  exclureCoutsSortiesDenrees: boolean;

}
