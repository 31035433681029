import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ProduitAppellationDTO} from '../../dtos/produit-appellation-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {AppellationDTO} from '../../dtos/appellations-dto';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {catchError} from 'rxjs/operators';
import {startCase as _startCase} from 'lodash';


export const URL_POST_SAVE_APPELLATIONS=`dolrest/gestionproduits/produit/save/appellations`;

@Injectable({
  providedIn: 'root'
})
export class ProduitAppellationService extends GenericHandler<ProduitAppellationDTO> {


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  createEmptyDTO = (): ProduitAppellationDTO => undefined;

  createEmptyProduitAppellation = (produitDeclinaison: ProduitDeclinaisonDTO, appellation: AppellationDTO) => {
    const pa = new ProduitAppellationDTO();

    pa.id = 0;

    pa.actif = false;
    pa.appellationActif = appellation.actif + '';
    pa.appellationCode = appellation.code;
    pa.appellationId = appellation.id;
    pa.appellationLibelle = appellation.libelle;

    pa.produitDeclinaisonActif = produitDeclinaison.actif;
    pa.produitDeclinaisonId = produitDeclinaison.id;
    pa.produitDeclinaisonLibelle = produitDeclinaison.libelle;


    return pa;
  };

  /**
   * Enregistrer les appellations d'un produit
   * @param produitsAppellations
   */
  saveAppellations = (produitsAppellations: ProduitAppellationDTO[], idProduit:number): Observable<ResponseWrapper<ProduitAppellationDTO>> => {
    return this.http.post(URL_POST_SAVE_APPELLATIONS+`/${idProduit}`, produitsAppellations).pipe(
      catchError(err => this.utils.handleError(err)),
    );
  };

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: ObjectDTO): string => "";

  getEntityName = (): string => "produitAppellation";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "GESTION DES APPELLATIONS PRODUIT";
}
