<p-panel [header]="titleHeaderModels" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <yo-button *ngFor="let elem of btnModelList"
                 class="mg-r-10" [label]="elem.label" [disabled]="!hasRequeteurGraphQL"
                 (onClick)="elem.action()"></yo-button>
    </div>
  </div>
  <div *ngIf="dataModelLoaded" class="row position-relative">
    <yo-button class="mg-r-10 close-data-model-graphql" label="FERMER" tooltip="Fermer"
               [disabled]="!hasRequeteurGraphQL" (onClick)="closeModel()"></yo-button>
    <div class="col-md-12 data-model-graphql" [innerHTML]="sanitizer.bypassSecurityTrustHtml(dataModel)">
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderParametersVizualisation" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.pretty_json"
        label="JSON"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.pretty_json}}">
      </p-radioButton>
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.plain_json"
        label="JSON brut"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.plain_json}}">
      </p-radioButton>
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.table"
        label="Tableau"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.table}}">
      </p-radioButton>
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderQuery" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <textarea rows="8" [(ngModel)]="queryGraphQL" [placeholder]="placeHolderQueryGraphQL"></textarea>
    </div>
    <div class="col-md-12 text-right">
      <yo-button
        class="mg-r-10" [label]="labelExec" tooltip="Exécuter la requête"
        [disabled]="!hasRequeteurGraphQL" (onClick)="sendQuery()"></yo-button>
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderViewResults" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <ng-container style="margin-bottom: 5px;"
                *ngIf="jsonForExport && (typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.pretty_json || typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.plain_json)">
    <a class="mg-r-10 export" title="Export JSON" [href]="jsonForExport" [download]="namefileJsonExport">Export JSON</a>
  </ng-container>
  <yo-view-json-graphql *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.pretty_json"></yo-view-json-graphql>
  <yo-view-plain-json-graphql
    *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.plain_json"></yo-view-plain-json-graphql>
  <yo-view-table-graphql *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.table"></yo-view-table-graphql>
</p-panel>
