import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {EnvironnementDTO} from "../../core/dtos/environnement-dto";
import {Subscription} from "rxjs";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {EnvironnementService} from "../../core/services/entities/gestionenvironnements/environnement.service";
import {EnvironnementResolverService} from "../environnement-resolver.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";


@Component({
  selector: 'yo-environnements',
  templateUrl: './environnements.component.html',
  styleUrls: ['./environnements.component.scss']
})
export class EnvironnementsComponent implements OnInit {

  environnementsForGrille: EnvironnementDTO[];

  subDelete: Subscription;
  subGrilleEnvironnements: Subscription;
  subDeleteEnvironnement: Subscription;
  subEnvironnement: Subscription;
  subDeleteRequestEnvironnement: Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/environnements';

  constructor(public utils: UtilsService,
              private environnementSvc: EnvironnementService,
              private environnementResolverSvc: EnvironnementResolverService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.utils.setTitle('Gestion des environnements');

    this.initGrilleEnvironnementSubscription();
    this.initDeleteSubscription();
    this.initSourceDataSubscription();
  }

  private initSourceDataSubscription() {
    this.subEnvironnement = this.environnementResolverSvc.environnementAnnounceSource.subscribe(environnement => {
      const idx = this.environnementsForGrille.findIndex(e => e.id === environnement.id);
      if (idx >= 0)
        this.environnementsForGrille[idx] = environnement;
      else
        this.environnementsForGrille.push(environnement);
    });
  }

  private initDeleteSubscription() {
    this.environnementSvc.environnementSubjectToDelete$.subscribe(env => {
      const idx = this.environnementsForGrille.findIndex(e => e.id === env.id);
      this.environnementsForGrille.splice(idx, 1);
    });
  }

  private initGrilleEnvironnementSubscription() {
    this.subGrilleEnvironnements = this.environnementSvc.searchEnvironnement('')
      .subscribe(environnementsForGrille => this.environnementsForGrille = environnementsForGrille)
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleEnvironnements);
    this.utils.unsubscribe(this.subDeleteEnvironnement);
    this.utils.unsubscribe(this.subEnvironnement);
    this.utils.unsubscribe(this.subDeleteRequestEnvironnement);
    this.utils.unsubscribe(this.subDelete);
  }

  confirmDelete = async (environnementDTO: EnvironnementDTO): Promise<void> => {
    const result = confirm("Etes vous sûr de vouloir supprimer l'environnement '" + environnementDTO.libelle + "' ?",
      'Suppression de l\'environnement');
    const isDeleted: boolean = await result;
    if (isDeleted)
      this.delete(environnementDTO);
  };

  delete = (environnementDTO: EnvironnementDTO) => {
    this.subDelete = this.subDeleteRequestEnvironnement = this.environnementSvc.delete(environnementDTO.id)
      .subscribe(
        data => {
          let result: ResponseWrapper<boolean> = data;
          if (result.inError) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Suppression impossible : ${result.resultMessage}`);
          } else {
            this.environnementSvc.announceEnvironnementToDelete(environnementDTO);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Environnement ${environnementDTO.libelle} supprimé avec succès`);
          }
        },
        error => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible de supprimer l'environnement ${environnementDTO.libelle}. Vérifiez qu'il n'est pas utilisé dans l'application`);
        }
      );
  };

  openEnvironnementDialog = (environnement: EnvironnementDTO | null) => {
    this.environnementSvc.announceOpenDialog(environnement);
  };
}
