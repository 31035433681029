import {Component, Input, OnInit} from "@angular/core";
import {VersionDto} from "../../core/dtos/changelog/version-dto";

@Component({
  selector: 'yo-detailedVersion',
  templateUrl: './detailed-version.component.html',
  styleUrls: ['./detailed-version.component.scss']
})
export class DetailedVersionComponent implements OnInit {

  @Input()
  versions: VersionDto[]

  @Input()
  isFirstParent: boolean

  ngOnInit(): void {
  }

}
