import {ObjectDTO} from '../../../../core/dtos/object-dto';

export class MPrintFicheTechniqueComposants extends ObjectDTO {

  composant: string;
  code: string;
  unite:string;
  quantite:number;
  quantiteKg:number;

}
