<div class="d-flex flex-column" [style.height]="utils.scrollHeightContent(140)">

  <div class="d-flex flex-grow-1">
    <p-accordion>
      <p-accordionTab [header]="labelTabParametrage">
        <yo-parametrage-association></yo-parametrage-association>
      </p-accordionTab>
      <p-accordionTab [header]="labelTabPreview" [selected]="true">
        <yo-parametrage-previsualisation></yo-parametrage-previsualisation>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div class="d-flex mg-t-10">

    <div class="mr-auto ">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="mg-r-5 font-12">{{ labelHelp }}</span>
    </div>

    <div id="ctas">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        buttonClass="cta-success"
        [tooltip]="toolTipSave"
        (onClick)="saveAssociations()"
        [disabled]="!gds.canModify(uniteProduction)"></yo-button>
    </div>
  </div>

</div>
