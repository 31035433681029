import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {TypeUtilisateurPmsDto} from "../../dtos/pms/type-utilisateur-pms-dto";

export const URL_GET_ALL = 'dolrest/types-utilisateur-pms/all';
export const URL_SAVE = 'dolrest/types-utilisateur-pms/';
export const URL_DELETE = 'dolrest/types-utilisateur-pms/';

@Injectable({
  providedIn: 'root'
})
export class TypeUtilisateurPmsService {
  private subjectOpenDialog = new Subject<TypeUtilisateurPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectTypeUtilisateurSaved = new Subject<TypeUtilisateurPmsSavedSupplier>();
  typeUtilisateurSaved$ = this.subjectTypeUtilisateurSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère tous les types utilisateur pms
   * @returns {Observable<TypeUtilisateurPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<TypeUtilisateurPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde un type utilisateur pms
   * @param typeUtilisateur pms
   */
  save = (typeUtilisateur: TypeUtilisateurPmsDto) : Observable<ResponseWrapper<TypeUtilisateurPmsDto>> => {
    let formData = new FormData();
    formData.set('type', JSON.stringify(typeUtilisateur));
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.httpSvc.post(URL_SAVE, formData);
  }


  /**
   * Supprime un ou plusieurs type(s) utilisateur pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: TypeUtilisateurPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (typeUtilisateur: TypeUtilisateurPmsDto, isUpdate: boolean = false) : void => {
    this.subjectTypeUtilisateurSaved.next({ isUpdate, typeUtilisateur } as TypeUtilisateurPmsSavedSupplier);
  }

}

export interface TypeUtilisateurPmsSavedSupplier {
  isUpdate: boolean;
  typeUtilisateur: TypeUtilisateurPmsDto;
}
