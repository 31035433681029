<dx-popup
  [(visible)]="displayDialog"
  title="Changement de mot de passe"
  width="auto"
  height="auto"
  [showCloseButton]="false"
>
  <form class="utilisateur-form" #formLogin="ngForm">

    <div class="p-field p-grid">
      <label for="motdepasse" class="p-col-fixed width-200">Mot de passe</label>
      <div class="p-col">
        <input id="motdepasse" class="width-250" type="password" name="password" [(ngModel)]="userPassword" required/>
      </div>
    </div>

    <div class="p-field p-grid">
      <label for="confirmmotdepasse" class="p-col-fixed width-200">Confirmer le mot de passe</label>
      <div class="p-col">
        <input id="confirmmotdepasse" class="width-250" type="password" name="password"
               [(ngModel)]="userPasswordConfirm" required/>
      </div>
    </div>


    <footer class="text-right">
      <yo-button
        (onClick)="sendNewPassword()"
        label="Envoyer"
        class="mg-r-5"
        [disabled]="isBoutonEnvoyerDisabled()">
      </yo-button>
    </footer>

  </form>

</dx-popup>



