import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-gestion-production-root',
  templateUrl: './gestion-production-root.component.html',
  styleUrls: ['./gestion-production-root.component.scss']
})
export class GestionProductionRootComponent implements OnInit {

  constructor(private titleSvc:Title) { }

  ngOnInit() {
    this.titleSvc.setTitle('GESTION DE LA PRODUCTION');
  }

}
