import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldCurrencySupplier extends FormFieldBaseSupplier<string> {
  controlType = 'currency';


  constructor(options :any) {
    super(options);
  }

}
