import {Injectable} from '@angular/core';
import {AllergeneDTO} from '../../dtos/allergene-dto';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {Observable, Subject} from 'rxjs';
import {ProduitRouteMapService} from '../gestionproduits/produit-routemap.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {ImageSize} from "../../enums/image-size-enum";

export const CODE_INFO_INCOMPLETE = 'NONRENS';

export const URL_FIND_ALL_ALLERGENES=`dolrest/referentiel/base/allergenes/findAll`;

@Injectable()
export class AllergenesService {

  private subjectOpenDialog = new Subject<AllergeneDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectAllergeneSaved = new Subject<AllergeneSavedSupplier>();
  allergeneSaved$ = this.subjectAllergeneSaved.asObservable();

  public noImagePath: string = 'assets/images/no-image.png';

  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              private produitRouteMap: ProduitRouteMapService,
              private httpSvc :HttpService
  ) {}

  exportAllergeneByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/base/allergenes/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importAllergeneFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/base/allergenes/import", formData, null);
  };

  deleteAllergeneByFilters = (rowsToDelete?: number[]) => {
    let params: string = '1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`dolrest/referentiel/base/allergenes/delete?${params}`);
  };

  announceOpenDialogEdition = (allergene: AllergeneDTO) => {
    this.subjectOpenDialog.next(allergene);
  };

  announceAllergeneSaved = (allergene: AllergeneDTO, isUpdate: boolean = false) => {
    this.subjectAllergeneSaved.next({isUpdate, allergene} as AllergeneSavedSupplier);
  }

  save = (allergene: AllergeneDTO, needToDeleteFile: boolean, image: File): Observable<ResponseWrapper<AllergeneDTO>> => {
    const formData = new FormData();
    formData.set('allergene', JSON.stringify(allergene));
    formData.set('deleteFile', JSON.stringify(needToDeleteFile));
    formData.set('file', image);
    return this.httpSvc.post("dolrest/referentiel/base/allergenes/save", formData);
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  getUrl = (item: AllergeneDTO, size: ImageSize, noCache: boolean = false): string => {
    return `dolrest/document/loadByEntity/allergene/${item?.id}/1/${size}?date=1${noCache ? `&${Date.now()}` : ''}`;
  }

  getEntityName = (): string => 'allergene';

  onImageError = (event: Event) => {
    let image = (event.target as HTMLImageElement);
    image.onerror = null;
    image.src = this.noImagePath;
  }

  /**
   * Récupère tous les allergenes auxquelles l'utilisateur connecté a droit
   */
  findAll = (isActif: boolean = true) => this.httpSvc.get(`${URL_FIND_ALL_ALLERGENES}?isActif=${isActif}`);

}

export interface AllergeneSavedSupplier {
  isUpdate: boolean;
  allergene: AllergeneDTO;
}
