import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {Marche} from '../../../core/models/gestion-marches/tableau-de-bord/marche';
import {ActivatedRoute} from '@angular/router';
import {MarcheTableauDeBordSupplier} from './tableau-de-bord-resolver.service';
import {GestionsMarcheService} from '../../../core/services/gestion-marche/gestions-marche.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Article} from '../../../core/models/gestion-marches/tableau-de-bord/article';

@Component({
  selector: 'yo-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss']
})
export class TableauDeBordComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  marche: Marche;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              public gestionMarcheSvc: GestionsMarcheService,
              public sanitizeSvc: DomSanitizer) {
  }

  ngOnInit(): void {
    this.routeSubscription();

  }

  routeSubscription() {
    //init component via la route
    this.subRoute = this.route.data
      .subscribe((data: { marcheTableauDeBordSupplier: MarcheTableauDeBordSupplier }) => {
        this.marche = data.marcheTableauDeBordSupplier.marche;
      });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  getProgressMontant(montantCourant: number, montantEngagement: number) {
    let percent = 0;
    if (montantEngagement > 0) {
      percent = montantCourant / montantEngagement * 100;
    } else {
      percent = 0;
    }
    percent = +percent.toFixed(2);
    if (isNaN(percent)) {
      percent = 0;
    }
    return percent;
  }

  getEngagementArticle(article: Article) {
    let engagementArticle = {totalEngagement: 0, percentEngagement: 0};
    let totalEngagement = 0;
    let totalMontantCourant = 0;

    if (!this.utils.isCollectionNullOrEmpty(article.uniteDeProductionList)) {
      for (const udp of article.uniteDeProductionList) {
        totalMontantCourant += udp.montantCourant;
        totalEngagement += udp.montantEngagement;
      }
      engagementArticle.percentEngagement = this.getProgressMontant(totalMontantCourant, totalEngagement);
      engagementArticle.totalEngagement = totalEngagement;
    }
    return engagementArticle;
  }
}
