import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {SeuilPmsDto} from "../../dtos/pms/seuil-pms-dto";

export const URL_GET_ALL = 'dolrest/seuils-pms/all';
export const URL_SAVE = 'dolrest/seuils-pms/';
export const URL_DELETE = 'dolrest/seuils-pms/';

@Injectable({
  providedIn: 'root'
})
export class SeuilPmsService {
  private subjectOpenDialog = new Subject<SeuilPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectSeuilSaved = new Subject<SeuilPmsSavedSupplier>();
  seuilSaved$ = this.subjectSeuilSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupère toutes les seuils pms
   * @returns {Observable<SeuilPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<SeuilPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Sauvegarde un seuil pms
   * @param seuil pms
   */
  save = (seuil: SeuilPmsDto) : Observable<ResponseWrapper<SeuilPmsDto>> => {
    let formData = new FormData();
    formData.set('seuil', JSON.stringify(seuil));
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.httpSvc.post(URL_SAVE, formData);
  }


  /**
   * Supprime un ou plusieurs seuil(s) pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: SeuilPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (seuil: SeuilPmsDto, isUpdate: boolean = false) : void => {
    this.subjectSeuilSaved.next({ isUpdate, seuil } as SeuilPmsSavedSupplier);
  }

}

export interface SeuilPmsSavedSupplier {
  isUpdate: boolean;
  seuil: SeuilPmsDto;
}
