import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../core/utils/utils.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs/index";
import {PortailPlcSupplier} from "./portail-plc-resolver.service";
import {PointDeLivraisonDTO} from "../../core/dtos/point-de-livraison-d-t-o";
import * as moment from "moment";
import {unitOfTime} from "moment";
import {PointDeLivraisonService} from "../../core/services/entities/point-de-livraison.service";
import {PlcRepas, PlcWeek} from "../menu-plc/menu-plc-resolver.service";
import {SelectItem} from "primeng/api";
import {EffectifMode} from "../menu-plc/constant/menu-plc.constant";
import {ConfigurationPortailService} from "../../core/services/portail/configuration-portail.service";
import {ConfigurationPortailDTO} from "../../core/dtos/portail/configuration-portail-dto";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";

@Component({
  selector: 'yo-portail-plc',
  templateUrl: './portail-plc.component.html',
  styleUrls: ['./portail-plc.component.scss']
})
export class PortailPlcComponent implements OnInit, OnDestroy {

  subRouteData: Subscription

  selectedDate: Date = new Date();
  week: Week;
  localeFr: any;

  disabledDays: number[];
  dateMin: Date;
  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];

  plcList: PointDeLivraisonDTO[] = [];
  selectedPlc: PointDeLivraisonDTO;

  plcWeek: PlcWeek;
  plcRepas: PlcRepas;

  configPortailPlc: ConfigurationPortailDTO;

  typeEffectif = EffectifMode.Prevision;
  modes: SelectItem[] = [
    {label: 'Prévision', value: EffectifMode.Prevision},
    {label: 'Fabrication', value: EffectifMode.Fabrication},
    {label: 'Facturation', value: EffectifMode.Facturation},
  ];

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private plcSvc: PointDeLivraisonService,
              private configPortailSvc: ConfigurationPortailService) {
  }

  ngOnInit() {
    this.subcriptionRouteData();
    this.updateSelectedDate();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRouteData);
  }

  subcriptionRouteData = (): void => {
    this.subRouteData = this.route.data.subscribe((data: { portailPlcSupplier: PortailPlcSupplier }) => {
      this.plcList = data?.portailPlcSupplier?.plcList;
      if (this.plcList && this.plcList.length === 1) {
        this.selectedPlc = this.plcList[0];
        this.loadConfigPortailByPlc();
      }
      this.selectedDate = new Date();
    });
  };

  changePlcPortail = (event): void => {
    this.loadDataEffectif();
    this.loadConfigPortailByPlc();
  };

  loadDataEffectif = (): void => {
    this.plcSvc.loadPlcWeek(this.week.weekMonday, this.week.weekSunday, this.selectedPlc).subscribe(response => {
      if (!this.utils.isNullOrEmpty(response)) {
        this.plcWeek = response.one;
      }
    });
  };

  loadConfigPortailByPlc = (): void => {
    this.configPortailSvc.getById(this.selectedPlc.id).subscribe(
      (response: ResponseWrapper<ConfigurationPortailDTO>) => {
        if(response.one) {
          this.configPortailPlc = response.one;
        } else {
          this.configPortailPlc = new ConfigurationPortailDTO();
          this.configPortailPlc.maxValeurSaisie = 10000;
          this.configPortailPlc.minValeurSaisie = 0;
          this.configPortailPlc.dateLimiteSaisie = new Date();
        }
      });
  };

  getWeekLabel = (week: Week): string => 'S' + moment(week.weekMonday).isoWeek() + ' ' + this.utils.getFrenchDateDDMMYYYY(moment(week.weekMonday)) + ' au ' + this.utils.getFrenchDateDDMMYYYY(moment(week.weekSunday));

  changeWeek = ($event: any): void => {
    this.updateSelectedDate();
    this.loadDataEffectif();
  };

  getMonthDates = (event: any): void => {
    this.plcSvc.getMonthDates(event, this.selectedDate, this.selectedPlc, 2).subscribe(response => {
      if (response) {
        this.initDateAvailable(response);
      }
    });
  };

  updateSelectedDate = (): void => {
    this.selectedDate = moment(this.selectedDate).startOf('isoweek' as unitOfTime.StartOf).toDate();

    this.week = {
      weekInYear: moment(this.selectedDate).isoWeek(),
      weekMonday: moment(this.selectedDate).startOf('isoweek' as unitOfTime.StartOf).toDate(),
      weekSunday: moment(this.selectedDate).endOf('isoweek' as unitOfTime.StartOf).toDate(),
      weekYear: moment(this.selectedDate).year()
    };
  };

  initDateAvailable = (response: any): void => {
    const joursDisponibles = response.additionalProperties['joursDisponibles'];
    this.disabledDays = this.utils.getDisabledDays(joursDisponibles);

    this.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
    this.datesCreationMenus = response.additionalProperties['datesCreationMenus'];
    this.dateMin = response.additionalProperties['dateMin'];

    this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

    if (!this.utils.isCollectionNullOrEmpty(this.datesSaisieEffectifs)) {
      this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
    }

    if (!this.utils.isCollectionNullOrEmpty(this.datesCreationMenus)) {
      this.datesCreationMenus = this.datesCreationMenus.map(item => this.utils.convertNumberDateToDate(item));
    }
  };

  onEmitPlcRepasAgain = (event): void => {
    this.plcRepas = event;
  };

}

interface Week {
  weekInYear: number;
  weekMonday: Date;
  weekSunday: Date;
  weekYear: number;
}
