<p-dialog
  [dismissableMask]="false"
  [showHeader]="true"
  (onHide)="closeDialog()"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'width':'520px'}"
>


  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-print mg-r-5"></i> Impression des effectifs
    </div>
  </ng-template>

  <!--  CONTENT-->
  <ng-container>

    <div class="d-flex">
      <div class="width-100">Mode</div>
      <p-multiSelect [options]="modeOptionsList"
                     [(ngModel)]="selectedMode"
                     showToggleAll="true"
                     defaultLabel="Selection..."
                     optionLabel="label"
                     [style]="{'width':'400px'}"
                     appendTo="body"></p-multiSelect>
    </div>

    <div class="d-flex mg-t-20">
      <div class="width-100">Repas</div>
      <p-multiSelect [options]="repasList"
                     [(ngModel)]="selectedRepasList"
                     [filter]="true"
                     showToggleAll="true"
                     filterBy="libelle"
                     optionLabel="libelle"
                     [style]="{'width':'400px'}"
      >
      </p-multiSelect>
    </div>

    <div class="d-flex mg-t-20">
      <div class="width-100">Régimes</div>
      <p-multiSelect [options]="modeRegimesList"
                     [(ngModel)]="selectedRegimes"
                     showToggleAll="true"
                     defaultLabel="Selection..."
                     optionLabel="label"
                     appendTo="body"
                     [style]="{'width':'400px'}"
      ></p-multiSelect>
    </div>

  </ng-container>

  <ng-template pTemplate="footer">
    <span class="mg-r-5">
      <yo-button
        [disabled]="isPrintDisabled()"
        iconClass="fas fa-print"
        (onClick)="printEffectif()"
        tooltip="Imprimer les effectifs"
        label="Imprimer"
      ></yo-button>
    </span>
    <span class="mg-r-5">
      <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (onClick)="closeDialog()"
                 tooltip="Annuler" label="Annuler"></yo-button>
    </span>


  </ng-template>


</p-dialog>
