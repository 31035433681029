<dx-popup
  [showTitle]="true"
  [title]="'Impression des menus'"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="dx-field">
    <div class="dx-field-label">Du :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de début"
        type="date"
        [value]="startDate"
        (onValueChanged)="startDateChanged($event)">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">au :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de fin"
        type="date"
        [value]="endDate"
        (onValueChanged)="endDateChanged($event)"
        [min]="minDate">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Régimes :</div>
    <div class="dx-field-value">
      <dx-select-box
        placeholder="Choisir un régime"
        displayExpr="label"
        valueExpr="value"
        [items]="regimeOptionList"
        [value]="selectedRegimeId"
        (onValueChanged)="regimeChanged($event)"
      ></dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Jours :</div>
    <div class="dx-field-value">
      <dx-tag-box
        searchEnabled="true"
        searchExpr="viewValue"
        [showSelectionControls]="true"
        [maxDisplayedTags]="3"
        [multiline]="false"
        [showMultiTagOnly]="false"
        placeholder="Sélection..."
        [dataSource]="dayOfWeekOptionList"
        [value]="dayOfWeekOptionSelected"
        displayExpr="viewValue"
        valueExpr="value"
        (onValueChanged)="onDaysChange($event)"
      >
      </dx-tag-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Message personnalisé :</div>
    <div class="dx-field-value">
      <dx-text-area [(ngModel)]="messagePerso"></dx-text-area>
    </div>
  </div>


  <div class="row align-items-center mb-4">
    <div class="col-4">Image logo :</div>
    <div class="col-4">
      <dx-file-uploader
        selectButtonText="Choisir un fichier"
        labelText=""
        (change)="uploadMenuLogo($event)"
        accept="image/*"
        [showFileList]="false"
        uploadMode="useForm"
      >
      </dx-file-uploader>
    </div>
    <div class="col-4">
      <div *ngIf="isLogo" class="image-logo-menu">
        <img src="dolrest/utils/parametre-image?imageName=menuLogo&date={{refresh}}" height="32" alt="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isContratPrestationRegime"
          text="Offre, Prestation, Régime"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isPrice"
        text="Coût matière plat"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isLibelleLong"
          text="Plat libellé long"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isCoutRepas"
        text="Coût matière repas"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isCouleur"
          text="Code couleur des aliments"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isLogo"
        text="Logo"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button class="mr-2" label="Imprimer" [disabled]="this.isValidForm()" iconClass="fas fa-print"
               (onClick)="downloadMenu()">
    </yo-button>

    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (onClick)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>
