import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContratmenuDTO} from '../../dtos/contratmenu-dto';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {GenericHandler} from '../generics/generic-handler';
import {FS_ROUTES} from '../../constants';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {RoutemapService} from '../routemap.service';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ViewModelContrat} from '../../../gestion-contrats/grille-contrats/contrat-viewmodel';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {HttpService} from "../technique/http.service";

export const URL_CONTRATSMENUSACTIFS = `dolrest/gestioncontrats/findAllActifs`;
export const URL_CONTRATSMENUSOFUSER = `dolrest/gestioncontrats/findAllContratsOfUser`;
export const URL_DELETE_OFFRE_ALIMENTAIRE = `dolrest/contratsmenus/`;

@Injectable()
export class ContratMenuService extends GenericHandler<ContratmenuDTO> {
  getEditObjectLabel = (data: ObjectDTO): string => "";

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;


  getOas = (): boolean => undefined;

  getSort = (): string[] => ['libelle,desc'];

  getTitle = (): string => 'GESTION DES CONTRATS MENUS';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE OFFRE ALIMENTAIRE';


  constructor(utils: UtilsService,
              private  routeMapSvc: RoutemapService,
              auth2Svc: Auth2Service, router: Router,
              private httpSvc: HttpService,
              http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  private subjectContratMenusConvive = new BehaviorSubject(undefined);


  private subjectSidenavGestionContrats = new BehaviorSubject(undefined);
  sidenavGestionContrats$ = this.subjectSidenavGestionContrats.asObservable();

  private subjectEditContratMenu = new Subject();
  editContratMenu$ = this.subjectEditContratMenu.asObservable();

  private subjectCreateContratMenu = new Subject();
  createContratMenu$ = this.subjectCreateContratMenu.asObservable();

  private subjectAnnounceContratMenuCreated = new Subject();
  contratMenuCreated$ = this.subjectAnnounceContratMenuCreated.asObservable();

  announceSavedInstance = (instance: ContratmenuDTO) => {
    this.subjectAnnounceContratMenuCreated.next(instance);
  };

  getEntityName = (): string => 'contratmenu';

  announceContratsMenus = (contratsMenus: ContratmenuDTO[]) => {
    this.subjectObjects.next(contratsMenus);
  };


  announceContratsMenuEdit = (contratsMenus: ContratmenuDTO) => {
    this.subjectEditContratMenu.next(contratsMenus);
  };

  announceContratsMenuCreate = (contratsMenus: ContratmenuDTO) => {
    this.subjectCreateContratMenu.next(contratsMenus);
  };

  deleteOffreAlimentaire = (id: number): Observable<boolean> => this.httpSvc.delete(`${URL_DELETE_OFFRE_ALIMENTAIRE}${id}`)

  /**
   * cf. : ContratMenuController#findAllActifs()
   * @returns {Observable<ContratmenuDTO[]>}
   */
  getListeAllContratsMenusActifs = (): Observable<ContratmenuDTO[]> => {
    return this.http.get<ContratmenuDTO[]>(URL_CONTRATSMENUSACTIFS).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  /**
   * Récupérer tous les contrats menus auxquels l'utilisateur connecté a droit
   * cf .ContratMenuController#findAllContratsOfUser
   * @returns {Observable<ResponseWrapper<ContratmenuDTO>>}
   */
  getAllContratsMenus = (): Observable<ResponseWrapper<ContratmenuDTO>> => this.httpSvc.get(URL_CONTRATSMENUSOFUSER);

  getFields = (contratMenu: ContratmenuDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(contratMenu) || contratMenu.id == 0 || contratMenu.id == undefined) {
      contratMenu = new ContratmenuDTO();
      contratMenu.id = 0;
      // Par défaut un ContratMenu est actif
      contratMenu.actif = true;


    }
    let formFields: FormFieldBaseSupplier<any>[] = [

      // new FormFieldTextboxSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: true,
      //   value: contratMenu.site,
      //   order: 1
      // }),

      // new FormFieldDropdownSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: !this.canModifySite(contratMenu),
      //   required:true,
      //   type: 'externalRef',
      //   value: this.utils.preSelectSingleList(this.optionsSites,contratMenu.site),
      //   order: 1,
      //   options: this.optionsSites
      // }),

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(contratMenu) || contratMenu.id != 0,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, contratMenu.site),
        order: 1,
        options: this.optionsSites,
        required: true
      }),


      // this.createFormFieldBaseSupplierForSite(contratMenu, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: contratMenu.libelle,
        required: true,
        order: 2,
        readonly: !this.canModify(contratMenu)
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: contratMenu.code,
        maxLength: 50,
        required: true,
        order: 3,
        readonly: !this.canModify(contratMenu)
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: contratMenu.actif,
        required: false,
        order: 4,
        readonly: !this.canModify(contratMenu)
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: contratMenu.id,
        order: 5
      })
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  openContratMenuConvive = (contratMenuConvive: ContratMenuConviveDTO, cm?: ContratmenuDTO) => {
    //si pas de contrat menu convive defini, c'est un nouveau contrat menu convive, on l'init avec ses valeurs par défaut
    if (this.utils.isNullOrEmpty(contratMenuConvive)) {
      contratMenuConvive = this.createNewContratMenuConvive(cm);
    }
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GCO_CMC, cm.id, contratMenuConvive.id, 'ficheidentite']);
  };

  private createNewContratMenuConvive = (cm: ContratmenuDTO): ContratMenuConviveDTO => {

    let contratMenuConvive = new ContratMenuConviveDTO();
    contratMenuConvive.id = 0;
    contratMenuConvive.actif = true;
    contratMenuConvive.contratsMenusConvivesRepas = [];
    contratMenuConvive.contratMenuLibelle = cm.libelle;
    contratMenuConvive.contratMenuId = cm.id;
    contratMenuConvive.modeleNutritionnel = new ModeleNutritionnelDTO();
    contratMenuConvive.modeleNutritionnel.actif = false;

    return contratMenuConvive;
  };


  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): ContratmenuDTO => new ContratmenuDTO();

  mapDtoToViewModel = (contratMenu: ContratmenuDTO): ViewModelContrat => {
    const vmContrat = new ViewModelContrat();

    vmContrat.actif = contratMenu.actif;
    vmContrat.code = contratMenu.code;
    vmContrat.id = contratMenu.id;
    vmContrat.contrat = contratMenu.libelle;
    vmContrat.siteId = contratMenu.site.id;
    vmContrat.siteLibelle = contratMenu.site.libelle;

    vmContrat.contratMenuConvives = contratMenu.contratMenuConvives;

    return vmContrat;
  };

  mapDtoListToViewModelList = (contratMenuList: ContratmenuDTO[]): ViewModelContrat[] => {
    let vmContratList: ViewModelContrat[] = [];

    if (!this.utils.isCollectionNullOrEmpty(contratMenuList)) {
      vmContratList = contratMenuList.map(item => this.mapDtoToViewModel(item));
    }

    return vmContratList;
  };


}
