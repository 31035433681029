import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import ModeleConditionnementPlatDTO from "../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DeclinaisonsService} from "../../../../core/services/declinaisons/declinaisons.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModelesPlatsService} from "../../../../core/services/conditionnements/modeles-plats.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {RoutemapService} from "../../../../core/services/routemap.service";
import ModeMcpListBoxDTO from "../../../../core/dtos/conditionnement/mode-mcp-listbox-dto";
import {UniteDeMesureDTO} from "../../../../core/dtos/unitedemesure-dto";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {UnitesDeProductionService} from "../../../../core/services/entities/unites-de-production.service";
import {GestionUniteProduction__ModeleConditionnementPlatService} from "../../../../core/services/gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-mp-identite',
  templateUrl: './mp-identite.component.html',
  styleUrls: ['./mp-identite.component.scss']
})
export class ModelePlatIdentiteComponent implements OnInit, OnDestroy {

  hasIDistri = false;

  modelePlat: ModeleConditionnementPlatDTO = null;
  modes: ModeMcpListBoxDTO[] = [];
  modeSelected: ModeMcpListBoxDTO;

  uniteAConditionnerList: UniteDeMesureDTO[] = [];
  unitesDeProductionList: UniteDeProductionDTO[] = [];
  udpList: UniteDeProductionDTO[] = [];
  associationsList: UniteDeProduction__ModeleConditionnementPlatDto[] = [];

  subSupplier: Subscription;
  subSidenav: Subscription;
  subUnitesDeProduction: Subscription;
  subUnitesDeProductionAssociations: Subscription;

  form: FormGroup = new FormGroup({});

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plats';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private dcSvc: DeclinaisonsService,
              private mpcSvc: ModelesPlatsService,
              public gds: GenericDatagridService,
              private udpSvc: UnitesDeProductionService,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService,
              private toastSvc: ToastService) {

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
    this.utils.unsubscribe(this.subSidenav);
    this.utils.unsubscribe(this.subUnitesDeProduction);
    this.utils.unsubscribe(this.subUnitesDeProductionAssociations);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initSidenavSubscription();
    this.initDataFromSupplier();
    this.initForm();
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  initSidenavSubscription = (): void => {
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
      this.initForm();
    });
  };

  initUnitesDeProductionLocauxSubscription = (): void => {
    this.subUnitesDeProduction = this.udpSvc.findUnitesProductionByFilters({ enabled: true, onlyLocalSites: true })
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.unitesDeProductionList = response.resultList;
          this.initUnitesDeProductionAssociesSubscription();
        }
      });
  };

  initUnitesDeProductionAssociesSubscription = (): void => {
    if (this.modelePlat && this.modelePlat.id) {
      this.subUnitesDeProductionAssociations = this.udpMcpSvc.getAllByMcpId(this.modelePlat.id)
        .subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.associationsList = response.resultList;

            if (this.associationsList && this.associationsList.length) {
              this.unitesDeProductionList.forEach(udp => {

                if (this.associationsList.find(asso => asso.idUniteDeProduction === udp.id)) {
                  this.udpList.push(udp);
                }
              });
            }

            this.initForm();
          }
        });
    }
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initDataFromSupplier = () => {
    this.subSupplier = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlatSupplier && data.modelePlatSupplier.modelePlatConditionnement) {
          this.modelePlat = data.modelePlatSupplier.modelePlatConditionnement;
          this.modeSelected = { mode: this.modelePlat.mode, libelle: this.initLabelMode(this.modelePlat.mode) } as ModeMcpListBoxDTO;
        } else {
          this.modelePlat = new ModeleConditionnementPlatDTO();
          this.modelePlat.id = 0;

          this.mpcSvc.getAllModes().subscribe(modes => this.modes = modes.resultList.map(mode => ({ mode, libelle: this.initLabelMode(mode) }) as ModeMcpListBoxDTO) );
        }

        if(data.modelePlatSupplier && data.modelePlatSupplier.modesModelePlat) {
          this.modes = data.modelePlatSupplier.modesModelePlat.map(mode => ({ mode, libelle: this.initLabelMode(mode) }) as ModeMcpListBoxDTO);
        }

        this.gds.getAll('unitedemesure').subscribe((response: ResponseWrapper<any>) => {
          this.uniteAConditionnerList = response.resultList ? response.resultList : [];
          this.initForm();
        });
        this.initUnitesDeProductionLocauxSubscription();

      });
  };

  private initLabelMode: (mode: string) => string = (mode: string) => {
    switch(mode) {
      case 'PIECE_PROPORTIONNELLE':
        return 'Pièce proportionnelle';
      case 'PIECE_NON_PROPORTIONNELLE':
        return 'Pièce non proportionelle';
      case 'PESEE':
        return 'Pesées éventuellement - Non regroupable';
      return 'Pièce proportionnelle';
    }
  };

  initForm = (): void => {
    const libelle = this.modelePlat && this.modelePlat.libelle ? this.modelePlat.libelle : '';
    const code = this.modelePlat && this.modelePlat.code ? this.modelePlat.code : '';
    const mode = this.modeSelected ? this.modeSelected.mode : '';
    const uniteAConditionner = this.modelePlat && this.modelePlat.uniteAConditionner ? this.uniteAConditionnerList.find(u => u.id === this.modelePlat.uniteAConditionner.id)  : '';
    const site = this.modelePlat ? this.findAllLocalSites().find(s => s.id === this.modelePlat.site?.id) : null;

    this.form = new FormGroup({
      site: new FormControl(site, [Validators.required]),
      libelle: new FormControl(libelle, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(code, [Validators.maxLength(50), Validators.minLength(2)]),
      mode: new FormControl(mode, [Validators.required]),
      uniteAConditionner: new FormControl(uniteAConditionner, [Validators.required]),
      udpList: new FormControl(this.udpList.sort((a, b) => a.libelle.localeCompare(b.libelle)), [Validators.required])
    });

  };

  canModify = (): boolean => this.modelePlat && this.hasIDistri && this.gds.canModify(this.modelePlat);

  save = (): void => {
    if (this.form.valid) {
      this.modelePlat.site = { id: this.form.controls['site'].value.id } as SiteDTO;
      this.modelePlat.libelle = this.form.controls['libelle'].value;
      this.modelePlat.code = this.form.controls['code'].value;
      this.modelePlat.mode = this.form.controls['mode'].value;
      this.modelePlat.uniteAConditionner = this.form.controls['uniteAConditionner'].value;

      const udpListSelected = this.form.controls['udpList'].value;

      this.mpcSvc.save(this.modelePlat).subscribe(response => {
        const mpc: ModeleConditionnementPlatDTO = response.one;

        this.udpMcpSvc.save(mpc.id, this.form.controls['udpList'].value.map(udp => udp.id))
          .subscribe(response => {
            this.mpcSvc.announceMcpSaved(mpc, this.modelePlat.id !== undefined && this.modelePlat.id !== null && this.modelePlat.id !== 0);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du modèle plat réalisée avec succès`);
            this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT, mpc.id, 'ficheidentite']);
          });
      });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Fiche d'identité`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
