import {Component} from '@angular/core';

@Component({
  selector: 'yo-gplc-root',
  templateUrl: './gplc-root.component.html',
  styleUrls: ['./gplc-root.component.scss']
})
export class GplcRootComponent {

  constructor() { }

}
