import {FormFieldBaseSupplier} from './form-fieldbase-supplier';


export class FormFieldTextareaSupplier extends FormFieldBaseSupplier<string> {
  controlType = 'textarea';


  constructor(options: any){
    super(options);
  }

}
