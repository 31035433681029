<ng-container *ngIf="!utils.isNullOrEmpty(articleSupplier)">
  <div class="d-flex flex-row ">

    <!-- GRILLE -->
    <div class="container-wh">
      <dx-toolbar class="yo-toolbar">
        <dxi-item location="before">
          <div class="custom-toolbar-subdivision">
            <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
            <yo-lookup
            [data]="articleSupplier.uniteDeProductionList"
            [selectedItem]="selectedUniteDeProduction.id"
            (onValueChange)="onSelectedUniteDeProductionChange($event)"
            [width]="250"
            >
            </yo-lookup>

<!--            <div class="mg-l-5 mg-r-5">
              <yo-button
                class="mg-r-10"
                iconClass="fa fa-plus"
                label="CRÉER"
                tooltip="Créer un article"
                (onClick)="openCreateArticle($event)">
              </yo-button>
            </div>-->
            <div>
              <dx-drop-down-box
                [width]="270"
                [(value)]="treeBoxValue"
                valueExpr="id"
                displayExpr="name"
                placeholder="Filtrer par famille de produit..."
                [showClearButton]="true"
                [dataSource]="treeDataSource"
              >
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view
                    #treeViewFamillePlat
                    [dataSource]="famillesPlats"
                    [height]="150"
                    dataStructure="plain"
                    keyExpr="id"
                    parentIdExpr="parent.id"
                    selectionMode="multiple"
                    showCheckBoxesMode="normal"
                    [selectNodesRecursive]="true"
                    displayExpr="libelle"
                    [selectByClick]="true"
                    (onItemSelectionChanged)="onChangeParentFamilleProduitSelection($event)"
                  >
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
            </div>
            <div *ngIf="operationProcessing">
              <dx-progress-bar
                #progressBar
                id="progress-bar-status"
                width="100%"
                [class.complete]="progressBar.value === 100"
                [min]="0"
                [max]="100"
                [value]="progression"
                [statusFormat]="formatProgression"
              >
              </dx-progress-bar>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="after">
          <div class="custom-button-container">
            <yo-button
              id="export-excel-file"
              [disabled]="exportBtnDisabled"
              iconClass="fas fa-file-excel"
              tooltip="Exporter les articles au format Excel">
            </yo-button>
            <yo-button
              id="opImportXls"
              iconClass="fas fa-download"
              tooltip="Importer les articles à partir d'une feuille Excel">
            </yo-button>

            <yo-button
              tooltip="Enregistrer les modifications de prix"
              type="submit"
              iconClass="fas fa-save"
              buttonClass="cta-success"
              (onClick)="save()">
            </yo-button>
          </div>
        </dxi-item>
      </dx-toolbar>

      <!--      GRID-->
      <dx-data-grid
        [dataSource]="customStore"
        keyExpr="id"
        width="100%"
        height="100%"
        [remoteOperations]="true"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        (onEditingStart)="onEditingStart($event)"
        #grid>
        <dxi-column dataField="id" caption="Actions" [width]="90"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [allowEditing]="false"
                    cellTemplate="actionsCellTemplate">
        </dxi-column>

        <dxi-column dataField="denreeLibelle" caption="Déclinaison"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    cellTemplate="denreeCellTemplate">
        </dxi-column>

        <dxi-column dataField="produitArticle.libelle" caption="Article"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    [allowEditing]="false"
                    cellTemplate="articleCellTemplate">
        </dxi-column>

        <dxi-column dataField="fournisseur.libelle" caption="Fournisseur" [width]="200"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    [allowEditing]="false"
                    cellTemplate="fournisseurCellTemplate">
        </dxi-column>

        <dxi-column dataField="groupeAchatCaList"
                    cellTemplate="groupeAchatCaCellTemplate"
                    caption="Prix d'achat en UF"
                    [allowEditing]="true"
                    [allowFiltering]="false"
                    editCellTemplate="prixGacaEditCellTemplate"
                    [width]="200">
        </dxi-column>
        <dxi-column dataField="libelleFamilleProduit" caption="Famille produit" [width]="200"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="false">
        </dxi-column>
        <dxi-column dataField="codeApi" caption="Code Api" [width]="100"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column dataField="referenceArticle"
                    caption="Réf.Fournisseur"
                    [width]="100"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="true">
        </dxi-column>

        <!-- OPTIONS-->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false">
        </dxo-editing>
        <dxo-pager
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[20, 50, 100, 1000]"
          [showNavigationButtons]="false"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} Article(s)">
        </dxo-pager>

        <!-- TEMPLATE DENREE -->
        <div class="d-flex justify-content-between" *dxTemplate="let cell of 'denreeCellTemplate'">
          <div class="no-overflow">
            <div class="width-25">
              <i *ngIf="!cell.row.data.produitDeclinaisonActif" title="Attention, denrée inactive !"
                 class="fas fa-exclamation-triangle danger-color cursor"></i>
            </div>
            <label class="font-12" title="{{cell.row.data.produitDeclinaisonLibelle}}">
              {{cell.row.data.produitDeclinaisonLibelle | uppercase}}
            </label><br/>
            <span class="font-10" title="Code de la denrée">
              {{cell.row.data.produitDeclinaisonCode | uppercase}}
            </span>
          </div>
        </div>

        <!-- TEMPLATE ARTICLE -->
        <div *dxTemplate="let cell of 'articleCellTemplate'">
          <div class="no-overflow">
            <label class="font-12" title="{{cell.row.data.produitArticleLibelle}}">
              {{cell.row.data.produitArticleLibelle | uppercase}}
            </label>
          </div>
          <div>
            <yo-info-lotmarche-article [uniteDeProductionId]="articleSupplier?.selectedUniteDeProduction?.id"
                                       [lotMarchePdLumpCa]="cell.row.data?.lmPdUpCa"></yo-info-lotmarche-article>
          </div>
        </div>

        <div *dxTemplate="let cell of 'fournisseurCellTemplate'" class="d-flex flex-row">
          <label class="font-12">
            {{cell.row.data.fournisseur.libelle | uppercase}}
          </label>
          <div class="size-icon mg-l-5" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
               [title]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
            <span class="fa-stack fa-2x">
              <i class="fas fa-circle fa-stack-2x"></i>
              <strong class="fa-stack-1x custom-letter">H</strong>
            </span>
          </div>
        </div>


        <div *dxTemplate="let cell of 'prixGacaEditCellTemplate'">
          <dx-number-box
            format="#0.## €"
            class="input-prix-ca" *ngIf="canModifyActionButton(cell.row.data)"
            [ngModel]="getPrixValueEditCellTemplate(cell)"
            (onValueChanged)="onChangePrixValueEditCellTemplate($event, cell)"
            [inputAttr]="{ 'aria-label': 'Weight Format' }"
          ></dx-number-box>
          <dx-number-box
            format="#0.## €"
            class="input-prix-ca" *ngIf="!canModifyActionButton(cell.row.data)"
            [ngModel]="getPrixValueEditCellTemplate(cell)"
            [inputAttr]="{ 'aria-label': 'Weight Format' }"
          ></dx-number-box>
        </div>

        <div *dxTemplate="let cell of 'groupeAchatCaCellTemplate'">
          <div *ngFor="let groupeAchatCa of cell.row.data?.groupeAchatCaList" class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center">
              <ng-container [ngSwitch]="groupeAchatCa.prefere">
                <i *ngSwitchCase="true" class="mg-r-5 fas fa-star cursor" title="Article préféré"></i>
                <i *ngSwitchCase="false" class="mg-r-5 far fa-star cursor rotate-1-turn"
                   title="Rendre l'article comme préféré"
                   (click)="updatePrefere(groupeAchatCa, articles ,cell.row.data,true)"></i>
                <ng-container *ngSwitchCase="undefined">
                  <!--                // DO NOTHING-->
                </ng-container>
              </ng-container>
              <div>
                {{groupeAchatCa.prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
                / {{cell.row.data.uniteDeFacturation.libelle | slice:0:5}}
                <span
                  [title]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.row.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
              </div>
            </div>
          </div>
        </div>

        <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
          <yo-cell-button (yoNavigation)="openArticle(cell.row.data)"
                          [yoTooltipShowDelay]="1500"
                          [yoSmall]="true"
                          yoTextAlign="center"
                          [yoIconClass]="canModifyActionButton(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                          [yoTooltip]="canModifyActionButton(cell.row.data)?'Modifier l\'article':'Voir l\'article'">
          </yo-cell-button>

          <ng-container *ngIf="!isDisabledDeleteBtn(cell.row.data)">
            <yo-cell-button (yoNavigation)="deleteArticle(cell.row.data)"
                            [yoTooltipShowDelay]="1500"
                            [yoSmall]="true"
                            yoTextAlign="center"
                            yoIconClass="fa fa-trash"
                            yoTooltip="Supprimer l'article">
            </yo-cell-button>
          </ng-container>
        </div>

        <div *dxTemplate="let cell of 'prixCellTemplate'">
          <div class="p-inputgroup mb-3">
            <dx-number-box
              class="width-75 text-right line-height-24"
              [disabled]="disabledPriceNumberBox(cell.row.data)"
              [(ngModel)]="cell.row.data.prix"
              [value]="cell.row.data.prix">
            </dx-number-box>
            <span class="p-inputgroup-addon">€ / {{cell.row.data.uniteDeFacturation.libelle}}</span>
            <div
              [title]="cell.row.data.prixPrecedent !== null ? 'Prix précédent : '+cell.row.data.prixPrecedent : 'Pas de précédent prix'">
              <yo-button
                [disabled]="cell.row.data.prixPrecedent === null"
                buttonClass="cta-inner-secondary"
                iconClass="fas fa-info">
              </yo-button>
            </div>
          </div>
        </div>

      </dx-data-grid>


      🗣️<em class="mg-r-5"> La mise à jour des articles ne peut se faire que sur vos unités de production
      locales.</em>
    </div>
  </div>
</ng-container>

<dx-tooltip
  #importTooltip
  target="#opImportXls"
  showEvent="click"
  hideOnOutsideClick="true"
  wrapperAttr="true"
  [wrapperAttr]="{ class: 'custom-tooltip' }">
  hidden>
  <div *dxTemplate="let data = data of 'content'">
    <div class="custom-file-uploader">
      <input id="importExcelFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
             (change)="importXslx($event)">
    </div>
  </div>
</dx-tooltip>

<!--CREATION ARTICLE, OUVERTURE DE LA SELECTION UDP-->
<yo-creer-article></yo-creer-article>


<!--DIALOG CREATION ARTICLE-->
<yo-dialog></yo-dialog>


<!--PANEL EXPORT EXCEL-->

<dx-tooltip
  #opExcel
  target="#export-excel-file"
  showEvent="click"
  hideOnOutsideClick="true"
  wrapperAttr="true"
  [wrapperAttr]="{ class: 'custom-tooltip' }">
  <div class="form-group ">
    <label
      [class.requiredControl]="true"
      class="genlabel  font-16"
      title=""
    >Nombre d'articles à exporter</label>
    <yo-lookup
      [data]="exportOptionList"
      [selectedItem]="selectExportOption.value"
      placeholder="Sélection..."
      (onValueChange)="onSelectedExportOptionChange($event)"
      key="value"
    >
    </yo-lookup>
  </div>
  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button
        label="Exporter"
        iconClass="fas fa-file-excel"
        (onClick)="exportXslx()"
        [disabled]="selectExportOption === undefined ? true : false">
      </yo-button>
    </div>
  </div>
</dx-tooltip>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été importées"
  [(visible)]="displayImportPopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été importés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
  >
    <dxi-column dataField="infosLine" caption="Ligne non importée" [filterOperations]="['contains']"
                [allowFiltering]="true" [width]="500"></dxi-column>
    <dxi-column dataField="labelsError" caption="Erreur(s)" [allowFiltering]="false"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>
