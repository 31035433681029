import {TreeNode} from "primeng/api";

export class FlatNode {

   idPointDeLivraison :number;
   idContratMenuConvive :number ;
   idTypePrestation :number ;
   idRepas:number ;


  constructor( idPointDeLivraison :number, idContratMenuConvive :number, idTypePrestation :number, idRepas:number ){
    this.idPointDeLivraison = idPointDeLivraison;
    this.idContratMenuConvive =idContratMenuConvive ;
    this.idTypePrestation = idTypePrestation;
    this.idRepas = idRepas;
  }

   public static treeNodeToFlatNodeList(treeNodeList : TreeNode[]){
     let result: FlatNode[] = [];

     treeNodeList.forEach( (treeNode :TreeNode) =>{
       const newOne :FlatNode = new FlatNode(treeNode.data.idPointDeLivraison, treeNode.data.idContratMenuConvive, treeNode.data.idTypePrestation, treeNode.data.idRepas);
       result.push(newOne);
     });

     return result;
   }
}
