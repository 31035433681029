<ng-container *ngIf="!isEditMode()" i18n="@@visualisation" >
  <span title="VISUALISATION">
    <b>{{yoLabelFab}}</b> {{libelle}}
  </span>
  <i class="fas fa-eye mg-l-10"></i>
</ng-container>

<ng-container *ngIf="isEditMode()" i18n="@@modification" >
  <span  title="MODIFICATION">
    <b>{{yoLabelFab}}</b> {{libelle}}
  </span>
  <i class="fas fa-edit mg-l-10"></i>
</ng-container>
