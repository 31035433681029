import {FormFieldBaseSupplier} from './form-fieldbase-supplier';

export class FormFieldTableSupplier extends FormFieldBaseSupplier<string> {

  cols: { field, header}[] = [];
  tableHeight;
  options: any[] = [];
  controlType='table';

  constructor(options: any) {
    super(options);
    this.cols=options['cols'];
    this.options = options['options'] || [];
    this.tableHeight=options['tableHeight'];
  }

}
