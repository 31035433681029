<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help lang="fr" [file]="pathFile" [width]="800"></yo-help>
        <yo-button
                label="Créer"
                iconClass="fa fa-plus"
                tooltip="Créer une famille"
                (onClick)="openDialogEdition()">
        </yo-button>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <yo-button [disabled]="!canDelete()" buttonClass="cta-delete" (onClick)="deleteValues()"
               tooltip="Supprimer les éléments sélectionnés" iconClass="fas fa-trash"></yo-button>
  </dxi-item>
</dx-toolbar>


<dx-tree-list
  id="famille-produit-grid"
  [dataSource]="familleProduitList"
  [rootValue]="null"
  keyExpr="id"
  [height]="utils.scrollHeightContent(74)"
  parentIdExpr="parent.id"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [(selectedRowKeys)]="selectedRows"
  (onSelectionChanged)="onSelectionChanged($event)"
  [expandedRowKeys]="[1]">

  <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="id" caption="Actions"
              [width]="100"
              cssClass="text-align-center vertical-align-middle mg-r-0"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
  <dxi-column dataField="site" caption="Site" cellTemplate="siteCellTemplate"></dxi-column>
  <dxi-column dataField="code" caption="Code"></dxi-column>
  <dxi-column dataField="fabrique" caption="Est fabriqué ?"></dxi-column>
  <dxi-column dataField="actif" caption="Actif"></dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openDialogEdition(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="familleProduitSvc.canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="familleProduitSvc.canModify(cell.row.data)?'Modifier la famille de produit':'Voir la famille de produit'">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.value.libelle"
             [siteId]="cell.value.id">
    </yo-site>
  </div>

</dx-tree-list>


<yo-dialog-form-famille-produit></yo-dialog-form-famille-produit>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été supprimées"
  [(visible)]="displayErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été supprimés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Ligne non supprimée" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>
