import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';

export class ViewModelContrat {

  id: number;
  contrat:string;
  siteLibelle:string;
  siteId : number;
  code:string;
  actif: boolean;
  contratMenuConvives : ContratMenuConviveDTO[];

}
