import {ObjectDTO} from "./object-dto";

export class SelectionRepasDTO  extends ObjectDTO{

  actif:boolean;
  date:number;

  // instance workflow
  idWorkflowInstance:number;

  // contrat menu
  idContratMenu:number;
  contratMenuLibelle:string;

  // site contrat menu
  idContratMenuSite:number;

  // contrat menu convive
  idContratMenuConvive:number;
  contratMenuConviveLibelle:string;
   idJourSemaine:number;

  // contrat menu convive repas
  idContratMenuConviveRepas:number;


  // repas
  idRepas:number;
  repasLibelle:string;

}
