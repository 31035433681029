import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {Observable, Subject} from "rxjs";
import {UniteDeProduction__DenreeBlackListBesoinDTO} from "../../dtos/unite-de-production__denree-black-list-besoin.-dto";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";

export const URL_GET_ALL = `dolrest/gestion-unites-de-production/findAllUdpDenreeBlackListBesoinByUdpId/`;
export const URL_DELETE = `dolrest/gestion-unites-de-production/deleteUdpDenreeBlackListBesoinByUdpId`;
export const URL_SAVE = `dolrest/gestion-unites-de-production/saveUdpDenreeBlackListBesoin`;

@Injectable({
  providedIn: 'root'
})
export class GestionUniteProduction__DenreeBlackListBesoinService {

  private subjectOpenDialog = new Subject<Number>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectElementsSaved = new Subject<UniteDeProduction__DenreeBlackListBesoinDTO[]>();
  elementsSaved$ = this.subjectElementsSaved.asObservable();

  private subjectSuppression = new Subject<number[]>();
  announceSuppression$ = this.subjectSuppression.asObservable();

  constructor(private httpSvc: HttpService) {}

  /**
   * Récupère toutes les denrées non approvisionnées
   * @param idUdp Identifiant de l'unité de production liée aux denrées non approvisionnées
   * @returns {Observable<UniteDeProduction__DenreeBlackListBesoinDTO>}
   */
  getAll = (idUdp: number): Observable<ResponseWrapper<UniteDeProduction__DenreeBlackListBesoinDTO>> => this.httpSvc.get(`${URL_GET_ALL}${idUdp}`, null);

  /**
   * Sauvegarde une ou plusieurs association(s)
   * @param elementsList
   */
  save = (elementsList: UniteDeProduction__DenreeBlackListBesoinDTO[]) => this.httpSvc.post(URL_SAVE, elementsList);

  /**
   * Supprime une ou plusieurs association(s)
   * @param idsToDelete Identifiants des associations à supprimer
   */
  deleteByIds = (idsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

  announceOpenDialogAjout = (idUdp: number) => {
    this.subjectOpenDialog.next(idUdp);
  };

  announceDenreeSaved = (elementsList: UniteDeProduction__DenreeBlackListBesoinDTO[]) => {
    this.subjectElementsSaved.next(elementsList);
  };

  announceSuppression = (idsToDelete: number[]) => {
    this.subjectSuppression.next(idsToDelete);
  };

}
