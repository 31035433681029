import {ObjectDTO} from "./object-dto";

export class OrigineDTO  extends ObjectDTO{

  id: number;
  code: string;
  libelle: string;
  actif: boolean;

}
