<h5>
  <label class="badge badge-secondary">
    <i class="fa fa-boxes yoni-color mg-r-5"></i>
   <yo-viewmode [libelle]="titleHeader">
    </yo-viewmode>
  </label>
</h5>


<p-tabMenu [model]="tabMenu" [activeItem]="tabSelectedMenu">
</p-tabMenu>

<div class="mg-t-5" style="height: calc(80vh);overflow-y:auto;overflow-x: auto">
  <router-outlet></router-outlet>
</div>

