import {Component} from '@angular/core';

@Component({
  selector: 'yo-processus',
  templateUrl: './processus.component.html',
  styleUrls: ['./processus.component.scss']
})
export class ProcessusComponent {
  constructor() { }
}
