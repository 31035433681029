import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class VisualisationMcpParametrageResolverService implements Resolve<number> {

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> | Promise<number> | number => {
    let idUdpMdp: number = +route.paramMap.get('idUdpMcp');
    return idUdpMdp;
  };

}

