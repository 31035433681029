import {Injectable} from '@angular/core';
import {HttpService} from '../technique/http.service';
import {GroupeAchatDTO} from '../../dtos/unite-de-production/groupe-achat-dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldTextareaSupplier} from '../../suppliers/form-field-textarea-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {MarcheDTO} from '../../dtos/gerstion-marche/marche-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Subject} from 'rxjs';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {GroupeAchat__FournisseurDTO} from '../../dtos/unite-de-production/groupe-achat-fournisseur-dto';

export const URL_GET_GROUPE_ACHAT_LIST = `dolrest/gestion-groupe-achat/get-groupe-achat-list`;
export const URL_GET_GROUPE_ACHAT_BY_ID = `dolrest/gestion-groupe-achat/get-groupe-achat-by-id`;
export const URL_SAVE_GROUPE_ACHAT = `dolrest/gestion-groupe-achat/save-groupe-achat`;
export const URL_DELETE_GROUPE_ACHAT = `dolrest/gestion-groupe-achat/delete-by-id`;
export const URL_GET_MEMBRE_GROUPE_ACHAT = `dolrest/gestion-groupe-achat/get-membre-groupe-achat`;
export const URL_SAVE_GROUPE_ACHAT_UDP_LIST = `dolrest/gestion-groupe-achat/save-membre-groupe-achat`;
export const URL_DELETE_MEMBRE_GROUPE_ACHAT = `dolrest/gestion-groupe-achat/delete-membre-groupe-achat`;
export const URL_GET_GROUPE_ACHAT_FOURNISSEUR = `dolrest/gestion-groupe-achat/get-groupe-achat-fournisseur`;
export const URL_SAVE_GROUPE_ACHAT_FOURNISSEUR_LIST = `dolrest/gestion-groupe-achat/save-groupe-achat-fournisseur`;
export const URL_DELETE_GROUPE_ACHAT_FOURNISSEUR = `dolrest/gestion-groupe-achat/delete-groupe-achat-fournisseur`;
export const URL_GET_GROUPE_ACHAT_BY_UDP_ID = `dolrest/gestion-groupe-achat/get-groupe-achat-by_id_udp`;
export const URL_GET_APPLY_GROUPE_ACHAT_PRIX_ARTICLES = `dolrest/gestion-groupe-achat/apply-groupe-achat-prix-articles`;

export enum TAB_NAME_GR {
  info,
  membres,
  fournisseurs
}

@Injectable({
  providedIn: 'root'
})
export class GroupeAchatService extends GenericHandler<GroupeAchatDTO> {


  protected subjectOpenGroupeAchatDialog = new Subject<any>();
  openGroupeAchatDialog$ = this.subjectOpenGroupeAchatDialog.asObservable();

  protected subjectActiveMenuItem = new Subject<any>();
  activeMenuItem$ = this.subjectActiveMenuItem.asObservable();

  protected subjectgroupeAchatDTO = new Subject<any>();
  groupeAchatDto$ = this.subjectgroupeAchatDTO.asObservable();

  protected subjectRefreshgroupeAchatList = new Subject<any>();
  refreshGroupeAchatList$ = this.subjectRefreshgroupeAchatList.asObservable();

  protected subjectGetUniteDeProductionSelectedList = new Subject<any>();
  uniteDeProductionSelected$ = this.subjectGetUniteDeProductionSelectedList.asObservable();

  protected subjectOpenDialogAddMember = new Subject<any>();
  openDialogAddMember$ = this.subjectOpenDialogAddMember.asObservable();

  protected subjectGetFournisseurSelected = new Subject<any>();
  fournisseurSelected$ = this.subjectGetFournisseurSelected.asObservable();

  protected subjectOpenDialogAddFournisseur = new Subject<any>();
  openDialogAddFournisseur$ = this.subjectOpenDialogAddFournisseur.asObservable();

  constructor(
    utils: UtilsService,
    auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
    private gds: GenericDatagridService,
    private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
    this.environnement$ = this.auth2Svc.environnement$;

    if (this.environnement$) {
      this.environnement$.subscribe(response => {
        // Quand l'utilisateur change d'environnement, il faut que la liste optionsSites soit mise à jour.
        this.optionsSites = this.auth2Svc.utilisateur.siteListLocaux;
      });
    }
  }

  announceActiveMenuItem = (activeItem: number) => {
    this.subjectActiveMenuItem.next(activeItem)
  };

  announceGroupeAchat = (groupeAchat: GroupeAchatDTO, tabName: number) => {
    const wrapper: any = {
      groupeAchat: groupeAchat,
      tabName: tabName
    };
    this.subjectgroupeAchatDTO.next(wrapper)
  };

  announceRefreshGroupeAchatList = () => {
    this.subjectRefreshgroupeAchatList.next(true)
  };


  announceOpenGroupeAchatDialog = (groupeAchat: GroupeAchatDTO) => {
    this.subjectOpenGroupeAchatDialog.next(groupeAchat)
  }

  announceUniteDeProductionSelected = (uniteDeProductionList: UniteDeProductionDTO[]) => {
    this.subjectGetUniteDeProductionSelectedList.next(uniteDeProductionList)
  };

  announceOpenDialogAddMember = () => {
    this.subjectOpenDialogAddMember.next(true);
  };

  announceFournisseurSelected = (gafList: GroupeAchat__FournisseurDTO[]) => {
    this.subjectGetFournisseurSelected.next(gafList);
  };

  announceOpenDialogAddFournisseur = () => {
    this.subjectOpenDialogAddFournisseur.next(true);
  };

  getFields = (dto: GroupeAchatDTO): FormFieldBaseSupplier<any>[] => {

    const isReadonly = !this.canModify(dto);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }

    let formFieldSite: FormFieldTextboxSupplier;

    if (dto.id > 0) {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      })
    } else {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: false,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        required: true,

        options: this.optionsSites
      })
    }

    let formFieldCode: FormFieldTextboxSupplier;
    if (dto.id > 0) {
      formFieldCode = new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 4,
        readonly: true,
      })
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly: isReadonly
      }),

      new FormFieldTextareaSupplier({
        key: 'descriptif',
        label: 'Description',
        minLength: 0,
        maxLength: 250,
        value: dto.descriptif,
        required: false,
        order: 3,
        readonly: isReadonly
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 7,
        readonly: isReadonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 8
      })
    ];

    if (dto.id > 0) {
      formFields.push(formFieldCode);
    }

    return formFields.sort((a, b) => a.order - b.order);
  };

  createEmptyDTO = (): GroupeAchatDTO => {
    let ga = new GroupeAchatDTO()
    ga.id = 0;
    ga.actif = true;
    return ga;
  };

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: MarcheDTO): string => "";

  getEntityName = (): string => 'groupe-marche';

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "";

  getTotalRecordsLabel = (): string => "";

  /***********  HTTP METHODES  **************/
  getGroupeAchatList = () => this.httpSvc.get(URL_GET_GROUPE_ACHAT_LIST);

  getGroupeAchatById = (idGroupeAchat: number) => {
    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');
    return this.httpSvc.get(URL_GET_GROUPE_ACHAT_BY_ID, params);
  };

  saveGroupeAchat = (groupeAchat: GroupeAchatDTO) => this.httpSvc.post(URL_SAVE_GROUPE_ACHAT, groupeAchat);

  deleteById = (idGroupeAchat: number) => {
    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');
    return this.httpSvc.delete(URL_DELETE_GROUPE_ACHAT, params);
  };


  getMembreGroupeAchat = (idGroupeAchat: number) => {
    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');
    return this.httpSvc.get(URL_GET_MEMBRE_GROUPE_ACHAT, params);
  };

  saveGroupeAchatUdpList = (idUdpList: number[], idGroupeAchat: number) => {

    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');

    return this.httpSvc.post(URL_SAVE_GROUPE_ACHAT_UDP_LIST, idUdpList, params);
  };

  applyGroupeAchatPrixArticles = (idGroupeAchat:number, idUdpList: number[]) => {

    const params = new HttpParams()
      .set('idUdpList', idUdpList.join(','))
      .set('idGroupeAchat', idGroupeAchat+'');

    return this.httpSvc.get(URL_GET_APPLY_GROUPE_ACHAT_PRIX_ARTICLES, params);
  };


  deleteMemberGroupeAchat = (idGroupeAchatUdpList: number[]) => {
    const params = new HttpParams()
      .set('idGroupeAchatUdpList', idGroupeAchatUdpList.join(','));
    return this.httpSvc.delete(URL_DELETE_MEMBRE_GROUPE_ACHAT, params);
  };

  getGroupeAchatFournisseurList = (idGroupeAchat: number) => {
    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');
    return this.httpSvc.get(URL_GET_GROUPE_ACHAT_FOURNISSEUR, params);
  };

  saveGroupeAchatFournisseurList = (idFournisseurRatioML: any, idGroupeAchat: number) => {
    const params = new HttpParams()
      .set('idGroupeAchat', idGroupeAchat + '');
    return this.httpSvc.post(URL_SAVE_GROUPE_ACHAT_FOURNISSEUR_LIST, idFournisseurRatioML, params);
  };

  deleteGroupeAchatFournisseur = (idGroupeAchatFournisseurList: number[]) => {
    const params = new HttpParams()
      .set('idGroupeAchatFournisseurList', idGroupeAchatFournisseurList.join(','));
    return this.httpSvc.delete(URL_DELETE_GROUPE_ACHAT_FOURNISSEUR, params);
  };


}
