import {ObjectDTO} from "./object-dto";

export class StockMouvementDTO extends ObjectDTO {

  dateMouvement: Date;
  remarque: string;
  quantite: number;

  entree: number;
  idStock: number;
  stockMouvementStatutCode: string;
  idStockMouvementStatut:number;

  // transient
  dateSortie:Date;


  constructor(dateMouvement: Date, remarque: string, quantite: number, entreeSortie: number, idStock: number, stockMouvementStatutCode: string, idStockMouvementStatut: number,dateSortie:Date) {
    super();
    this.dateMouvement = dateMouvement;
    this.remarque = remarque;
    this.quantite = quantite;
    this.entree = entreeSortie;
    this.idStock = idStock;
    this.stockMouvementStatutCode = stockMouvementStatutCode;
    this.idStockMouvementStatut = idStockMouvementStatut;
    this.dateSortie = dateSortie;
  }
}
