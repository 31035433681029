import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ConditionnementVarianteDeclinaisonDTO from '../../dtos/conditionnement/conditionnement-variante-declinaison-dto';

export const URL_GET_ALL_BY_ID_MCP = `dolrest/conditionnements/conditionnements-variantes-declinaisons/listByIdMcp`;

@Injectable({
  providedIn: 'root'
})
export class ConditionnementsVarianteDeclinaisonService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les conditionnements variantes déclinaisons en fonction d'un identifiant
   * de modèle de conditionnement de plat
   * @returns {Observable<ConditionnementVarianteDeclinaisonDTO>}
   */
  getAllByIdMcp(idMcp: number): Observable<ResponseWrapper<ConditionnementVarianteDeclinaisonDTO>> {
    return this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCP}/${idMcp}`, null);
  }

}

