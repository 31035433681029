<div class="error" *ngIf="form.controls[field.key].invalid && (form.controls[field.key].dirty )">

  <div class="pg-2" *ngIf="form.controls[field.key].hasError('required')">
    {{startError(field)}} est obligatoire.
  </div>
  <div class="pg-2" *ngIf="form.controls[field.key].hasError('minlength')">
    {{startError(field)}} ne peut avoir moins de {{field.minLength}} caractères.
  </div>
  <div class="pg-2" *ngIf="form.controls[field.key].hasError('maxlength')">
    {{startError(field)}} ne peut dépasser {{field.maxLength}} caractères.
  </div>
  <div class="pg-2" *ngIf="form.controls[field.key].hasError('min')">
    {{startError(field)}} doit être supérieur ou égal à {{field.min}}.
  </div>
  <div class="pg-2" *ngIf="form.controls[field.key].hasError('max')">
    {{startError(field)}} doit être inférieur ou égal à {{field.max}}.
  </div>

</div>

