import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {SelectionRepasPlcsDTO} from '../../dtos/selection-repas-plcs-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {UtilsService} from '../../utils/utils.service';
import {WorkflowsService} from '../entities/workflows.service';
import {RoutemapService} from '../routemap.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {find as _find, startCase as _startCase} from 'lodash';
import {catchError} from 'rxjs/operators';
import {TreeNode} from 'primeng/api';

import {Subject} from 'rxjs';
import * as moment from 'moment';
import {FlatNode} from '../../models/flatNode';


export const URL_POST_LOAD_SELECTION_REPAS_PLCS = `dolrest/gestion-selection-menus/load/selection-repas-plcs`;
export const URL_POST_MONTHS_DATES_SAISIE_EFFECTIFS= `dolrest/gestion-selection-menus/months/dates`;

@Injectable({
  providedIn: 'root'
})
export class SelectionRepasPlcsService extends GenericHandler<SelectionRepasPlcsDTO> {

  private subjectChangeMonths = new Subject();
  changeMonths$ = this.subjectChangeMonths.asObservable();

  constructor(utils: UtilsService,
              private workflowSvc: WorkflowsService,
              private  routeMapSvc: RoutemapService,
              auth2Svc: Auth2Service, router: Router,
              http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getSelectionRepasPlcs = (idWorkflowInstance: number) => {
    const fd = new FormData();
    fd.set('idWorkflowInstance', idWorkflowInstance + '');
    return this.http.post(URL_POST_LOAD_SELECTION_REPAS_PLCS, fd).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };



  createEmptyDTO = (): SelectionRepasPlcsDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: ObjectDTO): string => "";

  getEntityName = (): string => "SelectionRepasPlcs";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "SÉLECTIONNER DES REPAS";

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  preSelectNodes = (tree: TreeNode[], selectedNodes: TreeNode[]) => {

    const selectedNodesSet: Set<TreeNode> = new Set();

    const selectedNodesData = selectedNodes ? selectedNodes.map(item=>item.data):[];


    tree.map(udpNode => {

      if (!this.utils.isCollectionNullOrEmpty(udpNode.children)) {

        for (let contratNode of udpNode.children) {

          if (!this.utils.isCollectionNullOrEmpty(contratNode.children)) {

            for (let prestationNode of contratNode.children) {

              if (!this.utils.isCollectionNullOrEmpty(prestationNode.children)) {

                for (let repasNode of prestationNode.children) {

                  if (!this.utils.isCollectionNullOrEmpty(repasNode.children)) {

                    for (const plcNode of repasNode.children) {


                      const result = _find(selectedNodesData, {
                        'idRepas':plcNode.data.idRepas,
                        'idPointDeLivraison':plcNode.data.idPointDeLivraison,
                        'idContratMenuConvive':plcNode.data.idContratMenuConvive
                      });

                      if (result) {

                        selectedNodesSet.add(udpNode);
                        selectedNodesSet.add(contratNode);
                        selectedNodesSet.add(prestationNode);
                        selectedNodesSet.add(repasNode);
                        selectedNodesSet.add(plcNode);
                      }
                    }


                  }
                }
              }
            }
          }
        }
      }
    });

    const selectedNodesArr: TreeNode[] = [];
    selectedNodesSet.forEach(node => selectedNodesArr.push(node));

    return selectedNodesArr;





  };

  changeMonthsHttpRequest = (event, selectedNodes: FlatNode[], startDate:Date, nbMonths:number) => {

    // si ca vient du onFocus
    const momentDate = moment(startDate);
    let startMonth: number = momentDate.month() + 1;
    let startYear: number = momentDate.year();

    // console.log('start month');

    // si ca vient du onMonthChange kjlnhjk
    if (event && event.month && event.year) {
      startMonth = event.month;
      startYear = event.year;
    }

    return this.getMonthsDates(selectedNodes,startYear,startMonth,nbMonths);

  };

  announceChangeMonths = event => {
    this.subjectChangeMonths.next(event);
  };

  private getMonthsDates = (selectedNodes: FlatNode[], startYear: number, startMonth: number, nbMonths: number) => {


    // suppression du cache car au bout de 2 requete successive chrome utilise le cache de la requete
    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });
    return this.http.post(URL_POST_MONTHS_DATES_SAISIE_EFFECTIFS, selectedNodes,{
      headers : headers,
      params : new HttpParams()
        .set('startMonth',startMonth+'')
        .set('startYear',startYear+'')
        .set('nbMonths',nbMonths+'')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };
}
