<!--[title]="getTooltip()"-->

<ng-container *ngIf="!isError">

  <img [src]="url"
       [height]="height"
       fallback-src="assets/images/no-image.png"
       yoFallback
       (click)="displayOriginalImage()"
       class="cursor rounded">
  <ng-container *ngIf="openable">
    <p-dialog
      [header]="title"
      [dismissableMask]="false"
      (onHide)="closeDialog()"
      [contentStyle]="{'overflow':'visible','max-height':'600px'}"
      [(visible)]="displayOriginalImg"
      [responsive]="true" showEffect="fade"
      [modal]="true"
      [(visible)]="displayOriginalImg">
      <img [src]="urlOriginal"
           height="256"
           class="rounded">
    </p-dialog>
  </ng-container>
</ng-container>

<ng-container *ngIf="isError">
  <ng-container *ngIf="_displayNoImage">
    <img [src]="url"
         [height]="height"
         fallback-src="assets/images/no-image.png"

         class="rounded">
  </ng-container>
</ng-container>


