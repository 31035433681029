import {shareReplay} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {TaxeDTO} from '../../dtos/taxe-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";

@Injectable()
export class TaxesService extends GenericHandler<TaxeDTO> {

  taxesEnvironnement: TaxeDTO[];

  private subjectOpenDialogEdition: Subject<TaxeDTO> = new Subject<TaxeDTO>();

  openDialogEdition$: Observable<TaxeDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectTaxSaved: Subject<TaxeDTO> = new Subject<TaxeDTO>();

  taxSaved$: Observable<any> = this.subjectTaxSaved.asObservable();

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES TAXES';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE TAXE';
  }

  constructor(utils: UtilsService,
              private gds: GenericDatagridService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  getEntityName(): string {
    return 'taxe';
  }

  getFields(dto: TaxeDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Taxe est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new TaxeDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        excelType: 'string',
        order: 2,

      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        excelType: 'string',
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'valeur',
        label: 'Valeur',
        readonly: !this.canModify(dto),
        type: 'number',
        value: dto.valeur,
        excelType: 'bigdecimal',
        required: true,
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        excelType: 'boolean',
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        excelType: 'integer',
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true).pipe(
      shareReplay())
      .subscribe(response => {
        this.taxesEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.taxesEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.taxesEnvironnement);
      });
  }

  createEmptyDTO(): TaxeDTO {
    return new TaxeDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA TAXE '${data.libelle.toUpperCase()}'`;
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/base/taxes/export${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/referentiel/base/taxes/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/base/taxes/import", formData, null);
  }

  save = (taxe: TaxeDTO): Observable<ResponseWrapper<TaxeDTO>> => {
    return this.httpSvc.post(`dolrest/referentiel/base/taxes/save`, taxe);
  }

  announceOpeningEditionDialog = (tax: TaxeDTO): void => {
    this.subjectOpenDialogEdition.next(tax);
  }

  announceTaxSaved = (): void => {
    this.subjectTaxSaved.next(null);
  }

}
