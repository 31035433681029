import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {FiltersContextOrganization} from "../../dtos/filters-context-organization-dto";
import {InfosRoundDto} from "../../dtos/infos-round-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../constants";
import {ProduitDeclinaisonDTO} from "../../dtos/produit-declinaison-dto";
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";
import {RepasDTO} from "../../dtos/repas-dto";
import {Tournee} from "../../dtos/tournee-dto";
import {PointDeLivraisonDTO} from "../../dtos/point-de-livraison-d-t-o";
import {ToastService} from "../technique/toast.service";
import {GraphQLService} from "../technique/graphql.service";
import {OrganisationTourneeInfoDto} from "../../dtos/organisation-tournee-info-dto";
import {SiteDTO} from "../../dtos/site-dto";

@Injectable({
  providedIn: 'root'
})
export class OrganisationsTourneesService {

  private subjectOpenDialog = new Subject<number>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  constructor(private httpSvc: HttpService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService) {
  }

  deleteOrganizationsByFilters = (rowsToRemove: number[]): Observable<ResponseWrapper<boolean>> => {
    let params: string = '?1=1';
    if (rowsToRemove?.length) params += `&idsToDelete=${rowsToRemove.join(',')}`
    return this.httpSvc.delete(`dolrest/tournees/organisations/delete${params}`);
  }

  fetchFiltersContext = (): Observable<ResponseWrapper<FiltersContextOrganization>> => this.httpSvc.get(`dolrest/tournees/organisations/filtersContext`);

  announceOpenEditionDialog = (id?: number) => this.subjectOpenDialog.next(id);

  save = (organisationTournees: OrganisationTourneeInfoDto, onlyCheck: boolean): Observable<ResponseWrapper<any>> => {
    let copyOrganization: OrganisationTourneeInfoDto = JSON.parse(JSON.stringify(organisationTournees));

    copyOrganization.site = { id: copyOrganization?.site?.id } as SiteDTO;
    Object.keys(copyOrganization?.informationsByDeliveryDay).forEach(dayDelivery => {
      copyOrganization?.informationsByDeliveryDay[dayDelivery].forEach(row => {
        row.pointLivraison = { id: row.pointLivraison.id, libelle: row.pointLivraison.libelle } as PointDeLivraisonDTO;

        if (row?.famillesPlats?.length) {
          row.famillesPlats = row.famillesPlats.map(f => { delete(f.selected); delete(f.expanded); return f; });
        }

        if (row?.prestation?.libelle === 'Toutes' && !row?.prestation?.id)
          row.prestation = null;
        if (row?.famillesPlats?.find(fp => !fp.id && fp.libelle === 'Toutes'))
          row.famillesPlats = null;
        if (row?.plats?.find(p => !p.id && p.code === 'Tous'))
          row.plats = null;
      });
    });
    return this.httpSvc.post(`dolrest/tournees/organisations/save/${onlyCheck}`, copyOrganization);
  }

  prepareRow = (form: FormInfosRound, callbackSuccess: any): void => {
    form?.jourConsoSelected.forEach(jourConso => {
      form?.repasSelected.forEach(repas => {
        if (form?.prestationsSelected.length) {
          form?.prestationsSelected.forEach(cmc => {
            let info: InfosRoundDto = this.initializeInfosRound(jourConso, repas, form);
            info.prestation = cmc;
            this.prepareFamillesAndPlatsForPrestation(info, form?.famillesPlats, form?.codesPlats);
            callbackSuccess(form?.jourLivSelected.toUpperCase(), info);
          });
        } else {
          let info: InfosRoundDto  = this.initializeInfosRound(jourConso, repas, form);
          info.prestation = { id: null, libelle: 'Toutes' } as ContratMenuConviveDTO;
          this.prepareFamillesAndPlatsForPrestation(info, form?.famillesPlats, form?.codesPlats);
          callbackSuccess(form?.jourLivSelected.toUpperCase(), info);
        }
      });
    });
  }

  private initializeInfosRound = (jourConso: string, repas: RepasDTO, form: FormInfosRound): InfosRoundDto => {
    let info: InfosRoundDto  = new InfosRoundDto();
    info.tournee = form?.tourneeSelected;
    info.order = form?.orderSelected || 1;
    info.pointLivraison = form?.plcSelected
    info.deliveryDay = form?.jourLivSelected.toUpperCase();
    info.consumptionDay = jourConso.toUpperCase();
    info.repas = repas;
    return info;
  }

  private prepareFamillesAndPlatsForPrestation = (info: InfosRoundDto, famillesPlats: any[], codesPlats: string): void => {
    if (famillesPlats && famillesPlats.length)
      info.famillesPlats = famillesPlats;

    if (codesPlats) {
      this.checkExistenceCodes(codesPlats, (platsNotFounds) => {
        info.plats = info.plats.filter(p => !platsNotFounds.find(e => e === p.code));
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Les codes de plats suivants sont inconnus : ${platsNotFounds.map(p => p.code).join(",")}`);
      }, (plats: ProduitDeclinaisonDTO[]) => { info.plats = plats });
    }
    if (!info.famillesPlats || !info.famillesPlats.length)
      info.famillesPlats = [{ id: null, libelle: 'Toutes'} as any];
    if (!info.plats || !info.plats.length)
      info.plats = [{ id: null, code: 'Tous'} as ProduitDeclinaisonDTO];
  }

  private checkExistenceCodes = (codesPlats: string, callBackPlatsUnfound: any, callBackPlatsSuccess: any): void => {
    const plats: ProduitDeclinaisonDTO[] = codesPlats.split(',').map(code => ({ code }) as ProduitDeclinaisonDTO);
    this.graphQlSvc.sendQuery(`
                {
                  allProduitsDeclines(filters: {
                    codes: [${plats.map(it => `"${it.code}"`)}]
                  }) {
                      id,
                      libelle,
                      code
                  }
                }
              `).toPromise()
      .then(response => {
        let produitsDeclinesFound: ProduitDeclinaisonDTO[] = response.allProduitsDeclines;
        let errorsPlatsUnfound: string[] = [];
        plats.forEach(plat => {
          const platFound: ProduitDeclinaisonDTO = produitsDeclinesFound.find(pd => pd.code === plat.code);
          if (platFound)
            plat.id = platFound.id;
          else
            errorsPlatsUnfound.push(`${plat.code}`);
        });
        if (errorsPlatsUnfound)
          callBackPlatsUnfound(plats);
        else
          callBackPlatsSuccess(plats);
      });
  }

}

export interface FormInfosRound {
  tourneeSelected: Tournee;
  orderSelected: number;
  plcSelected: PointDeLivraisonDTO;
  jourLivSelected: string;
  jourConsoSelected: string[];
  repasSelected: RepasDTO[];
  prestationsSelected: ContratMenuConviveDTO[];
  famillesPlats: any[];

  codesPlats: string;
}
