import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {EquipementPmsDto} from "../../dtos/pms/equipement-pms-dto";
import {UtilsService} from "../../utils/utils.service";
import {IsDeletableObject} from "../../models/is-deletable-object";

export const URL_GET_ALL = 'dolrest/equipements-pms/all';
export const URL_SAVE = 'dolrest/equipements-pms/';
export const URL_DELETE = 'dolrest/equipements-pms/';

@Injectable({
  providedIn: 'root'
})
export class EquipementPmsService {
  private subjectOpenDialog = new Subject<EquipementPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectEquipementSaved = new Subject<EquipementPmsSavedSupplier>();
  equipementSaved$ = this.subjectEquipementSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  getEntityName = () : string => {
    return "equipementpms";
  }

  /**
   * Récupère tous les equipements pms
   * @returns {Observable<EquipementPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<EquipementPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde un equipements pms
   * @param equipement pms
   * @param file
   */
  save = (equipement: EquipementPmsDto, file: File) : Observable<ResponseWrapper<EquipementPmsDto>> => {
    let formData = new FormData();
    formData.set('equipement', JSON.stringify(equipement));
    formData.set('file', file);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpSvc.post(URL_SAVE, formData);
  }

  /**
   * Supprime un ou plusieurs equipement(s) pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<ResponseWrapper<IsDeletableObject>> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: EquipementPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (equipement: EquipementPmsDto, isUpdate: boolean = false) : void => {
    this.subjectEquipementSaved.next({ isUpdate, equipement } as EquipementPmsSavedSupplier);
  }

}

export interface EquipementPmsSavedSupplier {
  isUpdate: boolean;
  equipement: EquipementPmsDto;
}
