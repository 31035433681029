import {UniteDeMesureDTO} from "./unitedemesure-dto";
import {ObjectDTO} from "./object-dto";

export class ApportNutritionnelDTO  extends ObjectDTO{

  //apport nutritionnel

  code: string;
  libelle: string;
  libellelong:string;
  actif:boolean;
  uniteDeMesureDTO : UniteDeMesureDTO;

  /**
   * Exemple : les ApportNutritionnel AG saturés (g/100g), AG monoinsaturés (g/100g), AG polyinsaturés (g/100g),...
   * AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA (g/100g), Cholestérol (mg/100g) ont l'ApportNutritionnel Lipides (g/100g) pour parent.
   */
  parent: ApportNutritionnelDTO;
  obligatoire: boolean;
  ordre: number;

}
