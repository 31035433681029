import {NgModule} from '@angular/core';
import {ModelePlatResolverService} from './modele-plat-resolver.service';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GestionConditionnementsRootComponent} from './gestionconditionnements.component';
import {GestionConditionnementsRoutingModule} from './gestionconditionnements-routing.module';
import {ParametrageConditionnementModelesPlatsComponent} from './modeles-plats/dialog/parametrage/mp-parametrage.component';
import {PlatsComponent} from './modeles-plats/dialog/plats/mp-plats.component';
import {ModelePlatComponent} from './modeles-plats/dialog/modele-plat.component';
import {
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule, DxListModule, DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule, DxRadioGroupModule,
  DxSelectBoxModule, DxTabPanelModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
  DxTreeViewModule
} from 'devextreme-angular';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';

import {CardModule} from 'primeng/card';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';

import {ModelesPlatsComponent} from './modeles-plats/modeles-plats.component';
import {InputTextModule} from "primeng/inputtext";
import {ModelePlatIdentiteComponent} from "./modeles-plats/dialog/fiche-identite/mp-identite.component";
import {ModelePlatAddConditionnementVarianteDeclinaison} from "./modeles-plats/dialog/parametrage/dialog/mp-add-cv-decli.component";
import {ParametrageConditionnementModelesPlcComponent} from "./modeles-plc/dialog/parametrage/mp-parametrage.component";
import {PointsLivraisonComponent} from "./modeles-plc/dialog/points-livraison/mp-points-livraison.component";
import {ModelePlcIdentiteComponent} from "./modeles-plc/dialog/fiche-identite/mp-identite.component";
import {ModelesPlcComponent} from "./modeles-plc/modeles-plc.component";
import {ModelePlcComponent} from "./modeles-plc/dialog/modele-plc.component";
import {ModelePlcResolverService} from "./modele-plc-resolver.service";
import {ModelePlcRowParametrage} from "./modeles-plc/dialog/parametrage/dialog/mp-row-parametrage.component";
import {PreparationConditionnementComponent} from "./preparation/preparation-conditionnement.component";
import {PreparationCalculConditionnementComponent} from "./preparation/calcul/preparation-calcul-conditionnement.component";
import {PreparationConditionnementResolverService} from "./preparation-conditionnement-resolver.service";
import {PreparationCalculConditionnementDetailsComponent} from "./preparation/calcul/details/preparation-calcul-conditionnement-details.component";
import {MenuModule} from "primeng/menu";
import {DialogAjoutMcpPlatsComponent} from "./modeles-plats/dialog/plats/dialog/dialog-ajout-mcp-plats.component";
import {DialogAjoutMcplcPlcComponent} from "./modeles-plc/dialog/points-livraison/dialog/dialog-ajout-mcplc-plc.component";
import {WizardConfigurationComponent} from "./wizard-configuration/wizard-configuration.component";
import {WizardConfigurationStepOneComponent} from "./wizard-configuration/step1/wizard-configuration-step-1.component";
import {WizardConfigurationStepTwoComponent} from "./wizard-configuration/step2/wizard-configuration-step-2.component";
import {WizardConfigurationStepThreeComponent} from "./wizard-configuration/step3/wizard-configuration-step-3.component";
import {WizardConfigurationStepFourComponent} from "./wizard-configuration/step4/wizard-configuration-step-4.component";
import {WizardConfigurationStepFiveComponent} from "./wizard-configuration/step5/wizard-configuration-step-5.component";
import {WizardConfigurationStepSixComponent} from "./wizard-configuration/step6/wizard-configuration-step-6.component";
import {
  WizardConfigurationStepSevenComponent
} from "./wizard-configuration/step7/wizard-configuration-step-7.component";
import {
  WizardConfigurationStepEightComponent
} from "./wizard-configuration/step8/wizard-configuration-step-8.component";
import {WizardConfigurationStepNineComponent} from "./wizard-configuration/step9/wizard-configuration-step-9.component";
import {
  WizardConfigurationStepTenComponent
} from "./wizard-configuration/step10/wizard-configuration-step-10.component";
import {
  WizardConfigurationStepElevenComponent
} from "./wizard-configuration/step11/wizard-configuration-step-11.component";
import {
  WizardConfigurationStepThirteenComponent
} from "./wizard-configuration/step13/wizard-configuration-step-13.component";
import {
  WizardConfigurationStepTwelveComponent
} from "./wizard-configuration/step12/wizard-configuration-step-12.component";
import {
  PrintSettingsEditionsConditionnementComponent
} from "./preparation/calcul/print-settings/print-settings-editions-conditionnement.component";

@NgModule({
  imports: [
    CommonModule,
    GestionConditionnementsRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,


    CardModule,
    PanelModule,
    ListboxModule,
    TabMenuModule,
    DialogModule,


    DxTreeListModule,
    DxDataGridModule,
    OverlayPanelModule,
    DropdownModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    InputTextModule,
    MenuModule,
    DropdownModule,
    DxTagBoxModule,
    DxPopupModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxToolbarModule,
    DxLookupModule,
    DxListModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
  ],
  entryComponents: [],
  declarations: [
    GestionConditionnementsRootComponent,
    ParametrageConditionnementModelesPlatsComponent,
    PlatsComponent,
    ModelePlatComponent,
    ModelesPlatsComponent,
    ModelePlatIdentiteComponent,
    ModelePlatAddConditionnementVarianteDeclinaison,
    ParametrageConditionnementModelesPlcComponent,
    PointsLivraisonComponent,
    ModelePlcIdentiteComponent,
    ModelesPlcComponent,
    ModelePlcComponent,
    ModelePlcRowParametrage,
    PreparationConditionnementComponent,
    PreparationCalculConditionnementComponent,
    PreparationCalculConditionnementDetailsComponent,
    DialogAjoutMcpPlatsComponent,
    DialogAjoutMcplcPlcComponent,
    WizardConfigurationComponent,
    WizardConfigurationStepOneComponent,
    WizardConfigurationStepTwoComponent,
    WizardConfigurationStepThreeComponent,
    WizardConfigurationStepFourComponent,
    WizardConfigurationStepFiveComponent,
    WizardConfigurationStepSixComponent,
    WizardConfigurationStepSevenComponent,
    WizardConfigurationStepEightComponent,
    WizardConfigurationStepNineComponent,
    WizardConfigurationStepTenComponent,
    WizardConfigurationStepElevenComponent,
    WizardConfigurationStepTwelveComponent,
    WizardConfigurationStepThirteenComponent,
    PrintSettingsEditionsConditionnementComponent
  ],
  exports: [
    WizardConfigurationComponent
  ],
  providers: [ModelePlatResolverService, ModelePlcResolverService, PreparationConditionnementResolverService]
})
export class GestionConditionnementsModule {
}
