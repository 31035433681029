import {Injectable} from '@angular/core';
import {FamilleGemrcnDTO} from '../../dtos/famille-gemrcn-dto';
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {FormFieldBaseSupplier} from "../../suppliers/form-fieldbase-supplier";
import {FormFieldTextboxSupplier} from "../../suppliers/form-field-textbox-supplier";
import {FormFieldCheckboxSupplier} from "../../suppliers/form-field-checkbox-supplier";
import {CouleurDTO} from "../../dtos/couleur-dto";
import {SelectItem} from "primeng/api";
import {ObjectDTO} from "../../dtos/object-dto";
import {FormFieldDropdownSupplier} from "../../suppliers/form-field-dropdown-supplier";
import {UtilsService} from "../../utils/utils.service";
import {Auth2Service} from "../security/auth2.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {CouleursService} from "./couleurs.service";
import {GenericHandler} from "../generics/generic-handler";
import {GenericDatagridService} from "../generics/generic-datagrid.service";
import {DialogMsgSupplier} from "../../suppliers/dialog-msg-supplier";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";

export const URL_SAVE_GEMRCN = `dolrest/referentiel/equilibre-alimentaire/famille-gemrcn/save`;
export const URL_EXPORT_XLSX_FAMILLES_GEMRCN = `dolrest/referentiel/equilibre-alimentaire/famille-gemrcn/export`;
export const URL_IMPORT_XLSX_FAMILLES_GEMRCN = `dolrest/referentiel/equilibre-alimentaire/famille-gemrcn/import`;
export const URL_DELETE_FAMILLES_GEMRCN = `dolrest/referentiel/equilibre-alimentaire/famille-gemrcn/delete`;

@Injectable()
export class FamillesGemrcnService extends GenericHandler<FamilleGemrcnDTO> {

  private subjectOpenDialogFamilleGemrcn = new Subject<FamilleGemrcnDTO>();
  openDialogEdition$ = this.subjectOpenDialogFamilleGemrcn.asObservable();

  private subjectFamilleGemrcnSaved = new Subject<FamilleGemrcnSavedSupplier>();
  familleGemrcnSaved$ = this.subjectFamilleGemrcnSaved.asObservable();

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private httpSvc: HttpService,
              private couleursSvc: CouleursService,
              private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);
  }

  announceOpenDialogEdition = (familleGemrcn: FamilleGemrcnDTO) => {
    this.subjectOpenDialogFamilleGemrcn.next(familleGemrcn);
  };

  announceFamilleGemrcnSaved = (familleGemrcn: FamilleGemrcnDTO, isUpdate: boolean = false) => {
    this.subjectFamilleGemrcnSaved.next({isUpdate, familleGemrcn} as FamilleGemrcnSavedSupplier);
  }


  save = (familleGemrcn: FamilleGemrcnDTO): Observable<ResponseWrapper<FamilleGemrcnDTO>> => this.httpSvc.post(URL_SAVE_GEMRCN, familleGemrcn);

  getTotalRecordsLabel(): string {
    return "Famille GEMRCN";
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES FAMILLES GEMRCN';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE FAMILLE GEMRCN';
  }

  getEntityName(): string {
    return 'FamilleNutritionnelle';
  }

  getFields(dto: FamilleGemrcnDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une FamilleGemrcn est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new FamilleGemrcnDTO();
      dto.actif = true;

      // Si la liste des sites ne contient qu'un élément, on affecte ce site au FamilleGemrcnDTO en création.
      if (this.optionsSites.length == 1) {
        dto.site = this.optionsSites[0];
      }
    }

    const readonly = !this.canModify(dto);


    let formFieldSite: FormFieldBaseSupplier<any> = this.createFormFieldBaseSupplierForSite(dto, 1);

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType: 'string',
        readonly: readonly,
        minLength: 2,
        maxLength: 200,
        value: dto.libelle,
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType: 'string',
        readonly: readonly,
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4,
      }),

      new FormFieldTextboxSupplier({
        key: 'ordre',
        label: 'Ordre',
        excelType: 'string',
        readonly: readonly,
        value: dto.ordre,
        type: 'number',
        required: true,
        order: 5
      }),

      new FormFieldDropdownSupplier({
        key: 'couleur',
        label: 'Couleur',
        readonly: readonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.couleursSvc.couleursEnvironnement, dto.couleur),
        order: 2,
        options: this.couleursSvc.couleursEnvironnement,
        required: true,
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType: 'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getSelectedItemCouleur(couleur: CouleurDTO, couleurs: SelectItem[]): CouleurDTO {

    if (couleurs && couleur) {

      for (let c of couleurs) {
        if (c.value.id === couleur.id) {
          return c.value;
        }
      }

    }

    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): FamilleGemrcnDTO {
    return new FamilleGemrcnDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA FAMILLE GEMRCN '${data.libelle.toUpperCase()}'`;
  }

  exportFamilleGemrcnByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&idsToExport=${rowsToExport.join(',')}`
    return this.http.get(`${URL_EXPORT_XLSX_FAMILLES_GEMRCN}?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importFamilleGemrcnFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post(URL_IMPORT_XLSX_FAMILLES_GEMRCN, formData, null);
  };

  deleteFamilleGemrcnByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&idsToDelete=${rowsToDelete.join(',')}`
    return this.http.delete(`${URL_DELETE_FAMILLES_GEMRCN}?${params}`);
  };

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }
}

export interface FamilleGemrcnSavedSupplier {
  isUpdate: boolean;
  familleGemrcn: FamilleGemrcnDTO;
}

