import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldCalendarSupplier extends FormFieldBaseSupplier<string> {
  controlType = 'calendar';

  // afficher l'heure
  showTime:boolean=false;

  // format de la date à afficher dans l'input
  dateFormat:string;

  width=150;

  constructor(options: any){
    super(options);

    this.showTime=options['showTime'];
    this.dateFormat=options['dateFormat'];
    this.width=options['width'];
  }

}
