import {BehaviorSubject, combineLatest, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NavigationStart, ResolveStart, Router, RoutesRecognized} from '@angular/router';

@Injectable()
export class LoadingService  {

  private subjectSpecificLoading = new BehaviorSubject(false);
  specificLoading$ = this.subjectSpecificLoading.asObservable();
  allLoading$: Observable<boolean>;

  /**
   * Le message qui sera affiché dans le snackbar.
   */
  public message: string;

  announceLoading(isLoading: boolean) {
   this.subjectSpecificLoading.next(isLoading);
  }

  constructor(private router: Router) {
    this.allLoading$ =  combineLatest([this.router.events, this.specificLoading$]).pipe(
       map(data => data[0] instanceof NavigationStart ||
         data[0] instanceof RoutesRecognized || data[0] instanceof ResolveStart || data[1] && data[1] === true )) ;
  }
}
