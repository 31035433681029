import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {SelectionRepasSupplier} from './selection-menus-resolver.service';
import {TreeNode} from 'primeng/api';
import {SelectionRepasService} from '../../core/services/gestion-selection-repas/selection-repas.service';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY, WORKFLOW_REVISION} from '../../core/constants';
import * as moment from 'moment';
import {SelectionRepasDTO} from '../../core/dtos/selection-repas-dto';
import {RunStepDTO} from '../../core/dtos/run-step-dto';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {ContratMenuConviveRepasDTO} from '../../core/dtos/contratmenuconviverepas-dto';
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-selection-menus',
  templateUrl: './selection-menus.component.html',
  styleUrls: ['./selection-menus.component.scss'],
  animations: [
    trigger('enterState', [

      state('inactive', style({
        transform: 'translateX(0)'
      })),

      transition('inactive => *', [
        animate(800, keyframes([

          style({opacity: 0, transform: 'translateX(0)', offset: 0}),
          style({opacity: 0, transform: 'translateX(0)', offset: 0.5}),
          style({opacity: 1, transform: 'translateX(0)',     offset: 1.0})

        ]))
      ]),

      transition('active => *', [
        animate(800, keyframes([

          style({opacity: 0, transform: 'translateX(0)', offset: 0}),
          style({opacity: 0, transform: 'translateX(0)', offset: 0.5}),
          style({opacity: 1, transform: 'translateX(0)',     offset: 1.0})

        ]))
      ]),

    ])

  ]
})
export class SelectionMenusComponent implements OnInit, OnDestroy {

  enterState='inactive';
  subRoute: Subscription;
  srs: SelectionRepasSupplier;

  selectedNodes: TreeNode[];
  //  noeuds sélectionnés de niveau 4
  selectedRepas: TreeNode[];
  dateCols: ColSupplier[];
  expandTree: boolean = false;
  selectTree: boolean = false;
  selectedDates: Date[];
  repasDates: Date[];
  selectAllPeriod: boolean;
  disabledDays: boolean;
  localeFr: any;
  selectionRepasMap: Map<string, SelectionRepasDTO> = new Map();

  constructor(private route: ActivatedRoute,
              public workflowSvc: WorkflowsService,
              public selectionRepasSvc: SelectionRepasService,
              public utils: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.localeFr = DATEPICKER_FR;
    this.subscriptionRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  subscriptionRouteData = () => {
    this.subRoute = this.route.data
      .subscribe((data: { selectionRepasSupplier: SelectionRepasSupplier }) => {
        this.srs = data.selectionRepasSupplier;
        this.selectedDates = data.selectionRepasSupplier.selectedDates.map(date => moment(date).toDate());

        // si aucune date existante on met celle du jour
        if (this.utils.isCollectionNullOrEmpty(this.selectedDates)) {
          this.selectedDates = [];
          this.selectedDates.push(new Date());
          this.selectedDates.push(new Date());
        }

        this.expandTree = true;
        this.expandAll(this.expandTree);
        this.selectTree = false;
        this.selectAllPeriod = true;
        // noeuds selectionnés de l'arbre des repas
        this.selectedNodes = this.selectionRepasSvc.getSelectedNodes(this.srs);
        // grille
        this.updateTableDefinition(this.selectedNodes, this.selectedDates, true);
      });
  };

  onChangeSelectionRepas = ($event: TreeNode[]) => {
    this.selectedNodes = $event;
    this.updateTableDefinition(this.selectedNodes, this.selectedDates, false);

    // on coche tous les repas par défaut
    this.selectAllPeriod=true;
    this.selectionRepasMap.forEach((value, key) => {
      value.actif = this.selectAllPeriod;
    });

    if (!this.utils.isCollectionNullOrEmpty(this.selectedRepas)) {
      this.selectedRepas.forEach(row => row.data['rowActive'] =  this.selectAllPeriod)
    }

    if (!this.utils.isCollectionNullOrEmpty(this.dateCols)) {
      this.dateCols.forEach(row => row.colActive =  this.selectAllPeriod);
    }
  };

  /**
   * Rafraichir la grille lorsque des criteres de selection changent
   * @param selectedNodes
   */
  updateTableDefinition = (selectedNodes: TreeNode[], selectedDates: Date[], init: boolean) => {
    this.selectedRepas = this.selectionRepasSvc.getSelectedNodesLevel4(selectedNodes);

    this.dateCols = [];
    if (selectedDates) {
      if (this.selectedDates.length < 2) {
        this.dateCols = this.selectionRepasSvc.getDateCols(selectedDates[0], selectedDates[0])
      } else {
        this.dateCols = this.selectionRepasSvc.getDateCols(selectedDates[0], selectedDates[this.selectedDates.length - 1]);
      }
    }


    // creation de la matrice
    this.selectionRepasMap = this.selectionRepasSvc.createSelectionRepasMap(this.selectedRepas, this.dateCols, this.srs.selectionRepasList, this.selectionRepasMap, init);


    console.log('dateCols', this.dateCols);
    console.log('selectedDates', selectedDates);
    console.log('selectedRepas', this.selectedRepas);
    console.log('selectionRepasList', this.srs.selectionRepasList);
  };

  expandAll = (expandTree: boolean) => {
    this.srs.tree.forEach(node => this.utils.expandRecursive(node, expandTree,3));
  };

  selectAll = () => {
    if (this.selectTree) {
      this.srs.tree.forEach(node => this.selectedNodes = this.utils.selectRecursive(node, this.selectedNodes));
    } else {
      this.selectedNodes = [];
    }
    this.updateTableDefinition(this.selectedNodes, this.selectedDates, false);
  };

  changeSelectedPeriod = ($event, rows: TreeNode[], cols: ColSupplier[]) => {
    this.selectionRepasMap.forEach((value, key) => value.actif = $event);

    for (let i = 0; i < cols.length; i++) {
      cols[i].colActive = $event;
    }

    for (let i = 0; i < rows.length; i++) {
      rows[i].data['rowActive'] = $event;
    }
  };


  /**
   * Récupérer les dates où il existe des menus selon les repas selectionnes dans l'interface
   * @param event
   */
  getMonthDates = (event: any, selectedNodes: TreeNode[]) => {

    const idsContratMenuConviveRepas = this.selectionRepasSvc.getIdsCmcrFromLevel4(selectedNodes);

    // si ca vient du onFocus
    const momentDate = moment(this.selectedDates[0]);
    let month: number = momentDate.month() + 1;
    let year: number = momentDate.year();

    // si ca vient du onMonthChange
    if (event.month && event.year) {
      month = event.month;
      year = event.year;
    }

    this.selectionRepasSvc.getMonthDates(idsContratMenuConviveRepas, year, month).subscribe(response => {
      this.repasDates = response.resultList;
    });
  };

  /**
   * Est ce que la date fait partie des repas dates (jours de menus existants)
   * @param date
   * @param repasDates
   */
  isRepasDate = (date: Date, repasDates: Date[]): boolean => {

    if (!this.utils.isCollectionNullOrEmpty(repasDates)) {
      for (let cmcrDate of  repasDates) {
        if (this.utils.isDateEqualsYYYYMMDD(moment(cmcrDate), moment(date))) {
          return true;
        }
      }
    }
    return false;
  };

  isJourActif = (selectionRepasMap: Map<string, SelectionRepasDTO>, row: TreeNode, col: ColSupplier) => {
    const sr = this.selectionRepasSvc.getCellSelectionRepas(selectionRepasMap, row, col);

    if (!this.utils.isNullOrEmpty(sr)) {
      return sr.actif;
    }
    return false;
  };

  setJourActif = (selectionRepasMap: Map<string, SelectionRepasDTO>, row: TreeNode, col: ColSupplier) => {
    const sr = this.selectionRepasSvc.getCellSelectionRepas(selectionRepasMap, row, col);

    if (!this.utils.isNullOrEmpty(sr)) {
      sr.actif = !sr.actif;
    }

    console.log('setJourActif repas map', this.selectionRepasMap);
  };

  /**
   * Activer une colonne complete
   *
   * @param $event
   * @param selectedRepas
   * @param dateCols
   * @param row
   */
  activeCol = ($event: any, rows: TreeNode[], cols: ColSupplier[], col: ColSupplier) => {

    //si coché
    if ($event) {

      // compter le nombre de colonnes cochées
      let countColActive = 0;
      for (let i = 0; i < cols.length; i++) {
        if (cols[i].colActive) {
          countColActive++;
        }
      }

      // si toutes les colonnes sont cochées, on coche la case "selectionner toute la periode" et on coche toutes les lignes
      if (countColActive === cols.length) {
        this.selectAllPeriod = true;
        if (!this.utils.isCollectionNullOrEmpty(rows)) {
          rows.forEach(row => row.data['rowActive'] = true)
        }
      }

    } else {
      // si la colonne n'est pas cochée, on decoche  la case "selectionner toute la periode" et on decoche toutes les lignes
      this.selectAllPeriod = false;
      if (!this.utils.isCollectionNullOrEmpty(rows)) {
        rows.forEach(row => row.data['rowActive'] = false)
      }
    }

    this.selectionRepasMap.forEach((value, key) => {

      if (this.utils.isDateEqualsYYYYMMDD(moment(value.date), col.date)) {
        value.actif = $event;
      }
    });
  };

  /**
   * Activer une ligne complete
   *
   * @param $event
   * @param selectedRepas
   * @param dateCols
   * @param row
   */
  activeRow = ($event: any, selectedRepas: TreeNode[], dateCols: ColSupplier[], row: TreeNode) => {

    //si coché
    if ($event) {

      // compter le nombre de colonnes cochées
      let countRowActive = 0;
      for (let i = 0; i < selectedRepas.length; i++) {
        if (selectedRepas[i].data['rowActive']) {
          countRowActive++;
        }
      }

      // si toutes les lignes sont cochées, on coche la case "selectionner toute la periode" et on coche toutes les colonnes
      if (countRowActive === selectedRepas.length) {
        this.selectAllPeriod = true;
        if (!this.utils.isCollectionNullOrEmpty(dateCols)) {
          dateCols.forEach(row => row.colActive = true);
        }
      }

    } else {
      // si la ligne n'est pas cochée, on decoche  la case "selectionner toute la periode" et on decoche toutes les colonnes
      this.selectAllPeriod = false;
      if (!this.utils.isCollectionNullOrEmpty(dateCols)) {
        dateCols.forEach(row => row.colActive = false);
      }
    }

    this.selectionRepasMap.forEach((value, key) => {
      // une ligne c'est un contrat menu convive avec un repas
      // un cmcr c'est pareil mais avec l'id jour semaine en plus
      if (value.idContratMenuConvive === row.data['cmcId'] && row.data['id'] === value.idRepas) {
        value.actif = $event;
      }
    });
  };

  getRunInput = () => this.selectionRepasSvc.prepareSelectionRepasList(this.selectionRepasMap, this.selectedRepas, this.dateCols, this.srs.workflowInstance);

  saveSelectionRepas = () => {

    const selectionRepasListToSave = this.getRunInput();
    const runStep: RunStepDTO = new RunStepDTO();

    runStep.idWorkflowInstance = this.srs.workflowInstance.id;
    runStep.revTypeValue = WORKFLOW_REVISION.MODIFIE;
    runStep.runInput = selectionRepasListToSave;

    this.workflowSvc.runStep(runStep).subscribe(response => {

      if (response) {

        const erreurs = response.additionalProperties['erreurs'];
        this.srs.erreurs = erreurs;
        this.workflowSvc.announceWorkflowInstance(response.one);

        if (this.utils.isCollectionNullOrEmpty(erreurs))
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sélection de repas est enregistrée avec succès`);
      }
    });
  };


  copyErrors = (elementId: string) => {
    const toCopy: HTMLInputElement = document.querySelector('#' + elementId);
    toCopy.select();

    document.execCommand('copy');
  };

  updateErrors = ($event: any) => {

    if(!this.utils.isCollectionNullOrEmpty($event)){
      this.srs.erreurs = $event;
    }
  };
}

export class MatrixSupplier {
  cols: ColSupplier[] = [];
  rows: RowSupplier[] = [];
}

export interface RowSupplier {
  sortLabel: string;
  cmcr: ContratMenuConviveRepasDTO;
  cmc: ContratMenuConviveDTO;
  label: string;
  rowActive: boolean;
}

export interface ColSupplier {
  date: moment.Moment;
  colActive: boolean;
}
