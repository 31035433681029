export class RunStepDTO {


  nextStepCode :string;
  revTypeValue:string;
  idWorkflowInstance:number;
  runInput : any;
  recompute:boolean;
  message:string;

}
