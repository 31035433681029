import {MotifNonConformiteDTO} from "../../motif-non-conformite-dto";
import {PrixDto} from "../../prix-dto";
import {QuantiteDto} from "../../quantite-dto";

export class AvoirFournisseurLigneDto {

  id: number;
  idFactureLigne: number;
  referenceArticle: String;
  libelleArticle: String;
  quantiteBonLivraison: QuantiteDto;
  prixBonLivraison: PrixDto;
  totalBonLivraison: PrixDto;
  quantiteFacture: QuantiteDto;
  prixFacture: PrixDto;
  totalFacture: PrixDto;
  montant: PrixDto;
  motifNonConformite: MotifNonConformiteDTO[];

  public static getAllProperties = (): String => {
    return `
      id,
      idFactureLigne,
      referenceArticle,
      libelleArticle,
      quantiteBonLivraison {
          value,
          uniteDeMesure {
              abreviation
          }
      },
      prixBonLivraison {
        value,
        uniteMonetaire
      },
      totalBonLivraison {
        value,
        uniteMonetaire
      },
      quantiteFacture {
          value,
          uniteDeMesure {
              abreviation
          }
      },
      prixFacture {
        value,
        uniteMonetaire
      },
      totalFacture {
        value,
        uniteMonetaire
      },
      montant {
        value,
        uniteMonetaire
      },
      motifNonConformite {
        id,
        libelle
      }
    `
  }
}
