import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SelectionFiltersResolverService implements Resolve<any> {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const supplier: SelectionFiltersPlanProductionSupplier = new SelectionFiltersPlanProductionSupplier();
    const idPlanProduction: number = +route.paramMap.get('idPlanProduction');
    if (idPlanProduction) supplier.idPlanProduction = idPlanProduction;
    return supplier;
  }

}

export class SelectionFiltersPlanProductionSupplier {
  idPlanProduction: number;
}
