import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoutemapService} from '../../../core/services/routemap.service';
import {Subscription} from 'rxjs';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {CmcPlcSupplier} from './cmc-points-de-livraison-resolver.service';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {FS_ROUTES, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {cloneDeep as _cloneDeep, find as _find, sortBy as _sortBy, union as _union} from 'lodash'
import {ContratMenuConvive__ModeleNutriDTO} from '../../../core/dtos/contratmenuconvive__modele-nutri-dto';
import {CmcMnService} from '../../../core/services/entities/cmc-mn.service';
import {DomSanitizer} from '@angular/platform-browser';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-cmc-points-de-livraison',
  templateUrl: './cmc-points-de-livraison.component.html',
  styleUrls: ['./cmc-points-de-livraison.component.scss']
})
export class CmcPointsDeLivraisonComponent implements OnInit, OnDestroy {

  periodes: ContratMenuConvive__ModeleNutriDTO[] = [];
  selectedPeriode: ContratMenuConvive__ModeleNutriDTO;

  contratMenuConvive: ContratMenuConviveDTO;
  pointsDeLivraison: PointDeLivraisonDTO[];
  selectedPointDeLivraison: PointDeLivraisonDTO;
  displayPlcAddDialog: boolean = false;
  subscriptionRoute: Subscription;
  selectedPointsDeLivraisonToAdd: PointDeLivraisonDTO[] = [];
  pointsDeLivraisonToAdd: PointDeLivraisonDTO[] = [];
  selectedPointsDeLivraisonToRemove: PointDeLivraisonDTO[] = [];
  displayPlcRemoveDialog: boolean = false;
  pointsDeLivraisonToRemove: PointDeLivraisonDTO[] = [];




  constructor(private route: ActivatedRoute,
              private contratsMenusConvivesSvc: ContratsMenusConvivesService,
              private routeMapSvc: RoutemapService,
              private pdlSvc: PointDeLivraisonService,
              public utils: UtilsService,
              public gds: GenericDatagridService,
              public cmcMnSvc:CmcMnService,
              public sanitizer:DomSanitizer,
              private toastSvc: ToastService) {
  }


  ngOnInit() {

    this.subscriptionRoute = this.route.data
      .subscribe((data: { cmcPlcSupplier: CmcPlcSupplier }) => {

        this.pointsDeLivraison = data.cmcPlcSupplier.pointsDeLivraison;
        this.contratMenuConvive = data.cmcPlcSupplier.contratMenuConvive;
        this.periodes=data.cmcPlcSupplier.periodes;

        // on charge le 1er planning par defaut
        this.selectedPeriode = this.utils.isCollectionNullOrEmpty(this.periodes) ?undefined:this.periodes[0];
        this.selectedPointDeLivraison= this.utils.isCollectionNullOrEmpty(this.pointsDeLivraison) ?undefined:this.pointsDeLivraison[0];
        this.loadTauxDePrisePrev(this.selectedPeriode,this.selectedPointDeLivraison);
      });

  }

  ngOnDestroy(): void {

    this.utils.unsubscribe(this.subscriptionRoute);

  }


  displayDialogRemovePlc = () => {
    this.displayPlcRemoveDialog = true;
    this.pointsDeLivraisonToRemove = _cloneDeep(this.pointsDeLivraison);
    this.selectedPointsDeLivraisonToRemove = [];
  };


  displayDialogAddPlc = () => {
    this.displayPlcAddDialog = true;
    this.pointsDeLivraisonToAdd = [];
    this.selectedPointsDeLivraisonToAdd = [];
    this.pdlSvc.getPlcList().subscribe(response => {

      let plcList = response.resultList;

      if (this.utils.isNullOrEmpty(plcList)) {
        plcList = [];
      }

      // ne prendre en compte que les plc qui ne sont pas déjà présents.
      for (let plc of plcList) {
        const elt = _find(this.pointsDeLivraison, {'id': plc.id});
        if (!elt) {
          this.pointsDeLivraisonToAdd.push(plc);
        }
      }
      // forcer la detection angular en recreant une nouvelle reference d'objet, sinon la listbox ne se met pas à jour
      // bug
      this.pointsDeLivraisonToAdd = _cloneDeep(this.pointsDeLivraisonToAdd);

    });

  };

  loadTauxDePrisePrev = (periode:ContratMenuConvive__ModeleNutriDTO, pointDeLivraison:PointDeLivraisonDTO) => {
    if(!this.utils.isNullOrEmpty(periode) && !this.utils.isNullOrEmpty(pointDeLivraison)){
      this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GCO_CMC, this.contratMenuConvive.contratMenuId, this.contratMenuConvive.id, 'points-de-livraison', periode.id, pointDeLivraison.id]);
    }
  };

  attachSelectedPointsDeLivraison = ($event: any) => {

    this.pdlSvc.attachToPrestation(this.contratMenuConvive, this.selectedPointsDeLivraisonToAdd).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        this.pointsDeLivraison = _union(this.pointsDeLivraison, this.selectedPointsDeLivraisonToAdd);

        this.pointsDeLivraison = _sortBy(this.pointsDeLivraison, 'libelle');

        // forcer la detection angular
        this.pointsDeLivraison = _cloneDeep(this.pointsDeLivraison);

        this.displayPlcAddDialog = false;
      }
    });
  };


  detachSelectedPointsDeLivraison = async ($event) => {

    if (!this.utils.isCollectionNullOrEmpty(this.selectedPointsDeLivraisonToRemove)) {

      const result = confirm(`Etes vous sûr de retirer les points de livraison sélectionnés de la prestation "${this.contratMenuConvive.libelle}" ?`,
        'Suppression de point de livraison');
      const isDeleted: boolean = await result;
      if(isDeleted) {
        this.pdlSvc.detachFromPrestation(this.contratMenuConvive, this.selectedPointsDeLivraisonToRemove).subscribe(response => {

          if (!this.utils.isResponseSupplierError(response)) {
            const summary = `Points de livraison  retirés de la prestation "${this.contratMenuConvive.libelle}".`;
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, summary);

            this.pointsDeLivraison = this.pointsDeLivraison.filter(plc => {
              const result = _find(this.selectedPointsDeLivraisonToRemove, {'id': plc.id});
              return !result;
            });

            // vider la page du point de livraison supprimé
            this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GCO_CMC, this.contratMenuConvive.contratMenuId, this.contratMenuConvive.id, 'points-de-livraison']);

            this.displayPlcRemoveDialog = false;
          }
        });
      }
    }
  };
}
