import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TypeUtilisateurPmsService} from "../../../../core/services/pms/type-utilisateur-pms.service";
import {TypeUtilisateurPmsDto} from "../../../../core/dtos/pms/type-utilisateur-pms-dto";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-type-utilisateur-dialog',
  templateUrl: './pms-type-utilisateur-dialog.component.html',
  styleUrls: ['./pms-type-utilisateur-dialog.component.scss']
})
export class PmsTypeUtilisateurDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  typeUtilisateur: TypeUtilisateurPmsDto;

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  docError = false;


  dialogTitle = 'Modification d\'un type d\'utilisateur';

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private typeUtilisateurPmsService: TypeUtilisateurPmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.typeUtilisateurPmsService.openDialog$
      .subscribe((t: TypeUtilisateurPmsDto) => {
        this.displayDialog = true;
        if (!t) {
          this.forUpdate = false;
          this.typeUtilisateur = new TypeUtilisateurPmsDto();
          this.typeUtilisateur.id = null;
          this.dialogTitle = 'Création d\'un type d\'utilisateur';
        } else {
          this.forUpdate = true;
          this.typeUtilisateur = t;
          this.siteSelected = t.site;
          this.dialogTitle = 'Modification d\'un type d\'utilisateur';

        }
        this.initForm();

      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.siteSelected = null;
  };

  canModify = (): boolean => {
    if (!this.typeUtilisateur || !this.typeUtilisateur.site || !this.typeUtilisateur.site.id) return this.hasPms;

    let response: boolean = false;

    if (this.typeUtilisateur && this.typeUtilisateur.site)
        response = this.auth2Svc.isSiteLocal(this.typeUtilisateur.site.id);

    return response && this.hasPms;
  }

  /**
   * fonction qui sauvegarde le type d'utilisateur pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.typeUtilisateur.libelle = this.form.controls['libelle'].value;
      this.typeUtilisateur.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.typeUtilisateurPmsService.save(this.typeUtilisateur)
        .subscribe(response => {
          this.typeUtilisateurPmsService.announceSaved(response.one, this.typeUtilisateur.id !== undefined && this.typeUtilisateur.id !== null && this.typeUtilisateur.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde du type d'utilisateur a été réalisée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'un type d'utilisateur pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.forUpdate ? this.typeUtilisateur.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]),
      site: new FormControl(this.forUpdate ? this.typeUtilisateur.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Type d'Utilisateur (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
