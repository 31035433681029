import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, HAS_GESTION_PLC, HAS_GESTIONCLIENTS, HAS_PORTAIL_USER} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {GplcRootComponent} from "./gplc-root/gplc-root.component";
import {MenuPlcComponent} from "./menu-plc/menu-plc.component";
import {SaisieEffectifsComponent} from "./menu-plc/saisie-effectifs/saisie-effectifs.component";
import {MenuPlcResolverService} from "./menu-plc/menu-plc-resolver.service";
import {PortailPlcComponent} from "./portail/portail-plc.component";
import {PortailPlcResolverService} from "./portail/portail-plc-resolver.service";

const routes: Routes = [
  {
    path:FP_ROUTES.GESTION_PLC,
    component:GplcRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_GESTIONCLIENTS,
    children:[
      {
        path:'menu-plc',
        component:MenuPlcComponent,
        data:HAS_GESTION_PLC,
        resolve:{
          menuPlcSupplier:MenuPlcResolverService
        },
        children:[
          {
            path:'saisie-effectifs',
            component:SaisieEffectifsComponent,
            data:HAS_GESTION_PLC,
          }
        ]
      },
      {
        path: 'portail-plc',
        component: PortailPlcComponent,
        data: HAS_PORTAIL_USER,
        resolve: {
          portailPlcSupplier: PortailPlcResolverService
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionPlcRoutingModule { }
