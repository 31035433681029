<ng-container *ngIf="!utils.isNullOrEmpty(marche)">

  <div class="d-flex">
    <div>
      <p-card>
        <h3 class="first">Engagement au marché</h3>
        <ngx-gauge type="semi"
                   size="200"
                   cap="round"
                   [title]="'Montant à atteindre : '+marche.montantEngagement+' €<br/><br/>Montant atteint : '+marche.montantCourant+' €'"
                   [thick]="6"
                   [thresholds]="gestionMarcheSvc.getGaugeThreshold(marche.montantEngagement)"
                   [min]="0"
                   [max]="marche.montantEngagement"
                   [value]="marche.montantCourant">
          <ngx-gauge-append>
            {{gestionMarcheSvc.displayGaugeAppend('K €', '€', marche.montantCourant)}}
          </ngx-gauge-append>
          <ngx-gauge-label>
          <span
            [innerHTML]="sanitizeSvc.bypassSecurityTrustHtml(gestionMarcheSvc.displayGaugeLabel(marche.montantCourant,'Montant','en kilo euros'))"></span>
          </ngx-gauge-label>
          <ngx-gauge-value>
            {{gestionMarcheSvc.displayGaugeValue(marche.montantCourant)}}
          </ngx-gauge-value>
        </ngx-gauge>
      </p-card>
    </div>

    <div class="mg-l-10 flex-grow-1">
      <p-card>
        <h3 class="first">Lots marché</h3>
        <div style="max-width:1024px; max-height: 75vh;overflow: auto;">


          <p-accordion>
            <ng-container *ngFor="let lm of marche.lotMarcheList">

              <p-accordionTab>
                <ng-template pTemplate="header">

                     <span>
                  <strong>{{lm.lotMarcheLibelle | uppercase }} - {{lm.lotMarcheCode | uppercase}} </strong>
                </span>
                  <span class="mg-l-5">
                  (Engagement : {{lm.montantCourant | currency:'EUR':'symbol':'1.4-4'}}
                    / {{lm.montantEngagement | currency:'EUR':'symbol':'1.4-4'}})
                </span>

                  <span class="floatright">
                      <div style="width:100px;">
                    <p-progressBar class="progress-bar-cumul-articles"
                                   [title]="'ENGAGEMENT PAR CUMUL DES ARTICLES<br/><br/>Montant à atteindre : '+lm.montantEngagementCumulArticles+' €<br/><br/>Montant atteint : '+lm.montantCourant+' €'"
                                   [value]="getProgressMontant(lm.montantCourant,lm.montantEngagementCumulArticles)"></p-progressBar>
                    </div>
                  </span>
                  <span class="floatright mg-r-20">
                  <div style="width:100px;">
                    <p-progressBar
                      [title]="'ENGAGEMENT DU LOT MARCHÉ<br/><br/>Montant à atteindre : '+lm.montantEngagement+' €<br/><br/>Montant atteint : '+lm.montantCourant+' €'"
                      [value]="getProgressMontant(lm.montantCourant,lm.montantEngagement)"></p-progressBar>
                    </div>
                </span>


                </ng-template>

                <h5 class="first">Période du {{lm.lotMarcheDateDebut | date:'dd/MM/yyyy'}}
                  au {{lm.lotMarcheDateFin | date:'dd/MM/yyyy'}}</h5>

                <table border="1" class="table-articles" style="width:100%">
                  <thead>
                  <tr>
                    <th>Article</th>
                    <th class="text-right">Montant atteint</th>
                    <th class="text-right">Montant à atteindre</th>
                    <th class="text-right">Engagement</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let article of lm.articleList">
                    <tr class="tr-header-article">
                      <td>
                        {{article.produitArticleLibelle}}
                        <span class="font-10">{{article.filialeLibelle | uppercase}}</span>
                      </td>
                      <td class="text-right">
                        <strong>{{article.montantCourant | currency:'EUR':'symbol':'1.4-4'}} </strong>
                      </td>
                      <td class="text-right">
                        <strong>{{getEngagementArticle(article).totalEngagement | currency:'EUR':'symbol':'1.4-4'}}</strong>
                      </td>
                      <td class="text-left">
                        <div style="width:100px;">
                          <p-progressBar
                            class="progress-bar-article"
                            [value]="getEngagementArticle(article).percentEngagement"></p-progressBar>
                        </div>
                      </td>
                    </tr>
                    <ng-container *ngFor="let udp of article.uniteDeProductionList">
                      <tr class="tr-header-udp">
                        <td>

                          <span class="mg-l-40">
                            <yo-site [siteId]="udp.udpSiteId"></yo-site>
                            {{udp.udpLibelle | uppercase}}
                          </span>
                        </td>
                        <td class="text-right">
                          {{udp.montantCourant   | currency:'EUR':'symbol':'1.4-4'}}
                        </td>
                        <td class="text-right">
                          {{udp.montantEngagement  | currency:'EUR':'symbol':'1.4-4'}}
                        </td>
                        <td>
                          <div style="width:100px;">
                            <p-progressBar
                              class="progress-bar-udp"
                              [value]="getProgressMontant(udp.montantCourant,udp.montantEngagement)"></p-progressBar>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>

                  </tbody>
                </table>


              </p-accordionTab>


            </ng-container>
          </p-accordion>
        </div>
      </p-card>

    </div>
  </div>


</ng-container>

