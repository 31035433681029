<div class="custom-toolbar">
  <div class="custom-toolbar-subdivision">
    <yo-help lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <yo-button
      iconClass="fa fa-plus"
      label="CRÉER"
      (onClick)="openEnvironnementDialog(null)"
      [tooltip]="'Créer un environnement'">
    </yo-button>
  </div>
</div>

<dx-data-grid
  [dataSource]="environnementsForGrille"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" cellTemplate="templateActions" caption="Actions" [allowFiltering]="false"
              [width]="100"></dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
  <dxi-column dataField="code" caption="Code"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEnvironnementDialog(cell.row.data)"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-edit"
                    [yoTooltip]="'Modifier l\'environnement '+ cell.row.data.libelle">
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="confirmDelete(cell.row.data)"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    [yoTooltip]="'Supprimer l\'environnement '+ cell.row.data.libelle">
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} environnement(s)">
  </dxo-pager>

</dx-data-grid>

<yo-ficheidentite-environnement></yo-ficheidentite-environnement>
