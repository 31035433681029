import {MenuCompositionDTO} from '../../menucomposition-dto';
import {ContratMenuConviveDecoupageDTO} from '../../contratmenuconvivedecoupage-dto';
import {ChangeDetectorRef} from '@angular/core';
import {FamilleGemrcnDTO} from '../../famille-gemrcn-dto';

export class CelluleTableauDTO {

  choixMax: number;
  dateMenu: number;
  idMenuDecoupageDate:number;
  idContratMenuConviveDecoupage: number;
  mapMenuComposition: Map<number, MenuCompositionDTO>;
  libelleDecoupageRepas: string;
  libelleConvive: string;
  libelleRepas: string;
  contratMenuSiteId: number;
  contratMenuConviveDecoupage: ContratMenuConviveDecoupageDTO;
  contratMenuConviveId:number;
  repasId: number;
  coutTheoriqueRepas:number;
  nonModifiable:boolean;

  coutUnitaireTotal:number;
  coutUnitairePondere:number;
  tauxDePriseTotal:number;

  // DIET
  famillesGemrcn : FamilleGemrcnDTO[];





  // transient
  changeDetector:ChangeDetectorRef;


}
