import {SelectItem} from 'primeng/api';

export class BaseComponent {
  /**
   * Fonction servant à classer les SelectItem en fonction de leur champ <b>label</b>
   * @param {SelectItem} a
   * @param {SelectItem} b
   * @returns {number}
   */
  public sortSelectItemByLabel(a: SelectItem, b: SelectItem) {
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    } else if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    } else {
      return 0;
    }
  }

}
