import {ObjectDTO} from "./object-dto";

export class FamilleProduitDTO extends ObjectDTO{

  libelle : string;
  code : string;
  actif:boolean;
  fabrique: boolean;
  parent : FamilleProduitDTO;

}
