import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';

@Component({
  selector: 'yo-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit {

  constructor(public utils: UtilsService) {
  }

  ngOnInit() {
  }
}
