import {Component, OnDestroy, OnInit} from '@angular/core';
import {Environnement_PlcDTO} from "../../../../core/dtos/administration/environnement-plc-dto";
import {FormGroup} from "@angular/forms";
import {FormFieldBaseSupplier} from "../../../../core/suppliers/form-fieldbase-supplier";
import {GROUP_POSITION, GroupFormFields} from "../../../../core/suppliers/group-formfields";
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GenericFormService} from "../../../../core/services/generics/generic-form.service";
import {SidebarEnvironnementPlcSupplier} from "./sidebar-environnement-plc-resolver.service";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {EnvironnementPlcService} from "../../../../core/services/portail/environnement-plc.service";
import {remove as _remove} from 'lodash';
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-sidebar-environnement-plc',
  templateUrl: './sidebar-environnement-plc.component.html',
  styleUrls: ['./sidebar-environnement-plc.component.scss']
})
export class SidebarEnvironnementPlcComponent implements OnInit, OnDestroy{

  environnementPlc : Environnement_PlcDTO;

  form: FormGroup;
  formInfoGenerales: FormGroup = new FormGroup({});
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  groups: GroupFormFields[] = [];

  subscriptionRoute : Subscription;
  subSelectedPlc : Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/portail-environnement-plc';

  constructor(public utils:UtilsService,
              private route: ActivatedRoute,
              private environnementPlcSvc :EnvironnementPlcService,
              private gfs: GenericFormService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initData();
    this.subscriptionSelectedPlc();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSelectedPlc);
  }

  help = () => {
    return undefined
  };

  initData = () => {
    this.environnementPlc = this.environnementPlcSvc.createEmptyDTO();
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });
    this.subscriptionRoute = this.route.data
      .subscribe((data: { sidebarEnvironnementPlcSupplier: SidebarEnvironnementPlcSupplier }) => {
        if(!this.utils.isNullOrEmpty(data.sidebarEnvironnementPlcSupplier)){
          this.environnementPlc = data.sidebarEnvironnementPlcSupplier.environnementPlc;
          this.initForm(this.environnementPlc);
        }
      });
  };

  subscriptionSelectedPlc = () => {
    this.subSelectedPlc = this.environnementPlcSvc.selectedPlcList$.subscribe( data=>{
      this.environnementPlc.pointDeLivraisonList = data;
    });
  };

  initForm = (environnementPlc: Environnement_PlcDTO) => {
    this.fieldsInfosGenerales = this.environnementPlcSvc.getFields(environnementPlc);

    //desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });

    this.groups = [
      {
        titlePanel: 'Informations générales',
        fields: this.fieldsInfosGenerales,
        form: this.formInfoGenerales,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['site', 'libelle', 'actif'],
          col2: [ 'descriptif']
        }
      },
    ];
  };

  saveEnvironnementPlc = () => {
    if (this.form.valid && this.environnementPlc.pointDeLivraisonList.length >0 ) {

      // @ts-ignore
      this.environnementPlc.site = this.utils.getLightSite(this.formInfoGenerales.controls['site'].value);
      this.environnementPlc.libelle = this.formInfoGenerales.controls['libelle'].value;
      this.environnementPlc.descriptif = this.formInfoGenerales.controls['descriptif'].value;
      this.environnementPlc.actif = this.formInfoGenerales.controls['actif'].value;

      this.environnementPlcSvc.saveEnvironnementPlc(this.environnementPlc)
        .subscribe(
          (response: ResponseWrapper<Environnement_PlcDTO>) => {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde de l'environnement du point de livraison ${response.one.libelle} a été réalisée avec succès`);
            this.environnementPlcSvc.announceEnvironnementPlc(response.one);
            this.environnementPlc = response.one;
          }
        );
    }else{
      if(!this.environnementPlc.pointDeLivraisonList.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Veuillez sélectionner un point de livraison`);
    }
  };

  isDisabledSaveBtn = () => !this.form.valid && this.environnementPlc.pointDeLivraisonList.length === 0;



  deletetPlc = (row :PointDeLivraisonDTO) => {
    _remove(this.environnementPlc.pointDeLivraisonList, row);
  };

  openDialogAddPlc = () => {
    this.environnementPlcSvc.announceOpenDialogAddPlc(this.environnementPlc);
  };

}
