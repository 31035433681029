import {ObjectDTO} from "./object-dto";

export class GroupeNutritionnelDTO  extends ObjectDTO{


  libelle : string;
  code : string;
  actif:boolean;
  couleur:number;
  ordre:number;

}
