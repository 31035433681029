<p-card [style]="{'width': '100%',
'min-height':'calc(100vh - 180px)',
'max-height':'calc(100vh - 180px)',
'overflow-y': 'auto',
'overflow-x':'hidden',
'margin-right': '15px'}">

  <ng-template pTemplate="header">
    <i class="fas fa-search mg-t-10 mg-r-5 mg-l-15"></i> FILTRES DE RECHERCHE
  </ng-template>

  <form [formGroup]="formSearch">

    <div class="row">
      <div class="col-md-6 no-padding-right">
        <input type="text"
               formControlName="denree"
               placeholder="Nom Déclinaison"
               class="mg-b-15 full-width no-padding-right font-14">
      </div>
      <div class="col-md-6 no-padding-right">
        <input type="text"
               formControlName="codeDenree"
               placeholder="Code Déclinaison"
               class="mg-b-15 full-width no-padding-right font-14">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 no-padding-right">
        <input type="text"
               pInputText
               formControlName="denominationArticle"
               placeholder="Libellé article"
               class="mg-b-15 full-width no-padding-right font-14">
          </div>
      <div class="col-md-6 no-padding-right">
        <input type="text"
               formControlName="referenceInterneArticle"
               placeholder="Réf. article"
               class="mg-b-15 full-width no-padding-right font-14">
      </div>
    </div>
    <div class="mg-b-15">
      <p-calendar
        [style]="{'font-size':'14px'}"
        placeholder="Entrée à partir du"
        firstDayOfWeek="1"
        appendTo="body"
        dateFormat="dd/mm/yy"


        dataType="date"
        [readonlyInput]="true"
        formControlName="dateEntree"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true"
        [showButtonBar]="true">
      </p-calendar>
    </div>
    <div class="mg-b-15">
      <p-calendar
        [style]="{'font-size':'14px'}"
        placeholder="Périmé avant le"
        firstDayOfWeek="1"
        appendTo="body"
        dateFormat="dd/mm/yy"
        dataType="date"


        [readonlyInput]="true"
        formControlName="datePerime"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true"
        [showButtonBar]="true">
      </p-calendar>
    </div>
    <div class="p-g mg-b-15">
      <dx-radio-group formControlName="thresholdStocks" valueExpr="value" displayExpr="label" [items]="seuils"></dx-radio-group>
    </div>
    <div class="mg-b-15">
      <dx-list
        #listSites
        [dataSource]="sites"
        [height]="'auto'"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        keyExpr="id"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        [selectedItemKeys]="idsSitesSelected"
        (onSelectionChanged)="onChangeSite($event)"
        searchExpr="libelle"
        searchMode="contains"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par site..."
        >
        </dxo-search-editor-options>
      </dx-list>
    </div>
    <div class="mg-b-15">
      <dx-tree-view
        #treeViewZonesStockages
        [dataSource]="zonesStockages"
        [height]="250"
        keyExpr="id"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        [selectNodesRecursive]="true"
        [selectByClick]="true"
        [searchEnabled]="true"
        (onItemSelectionChanged)="changeZoneStockage($event)"
        [searchEditorOptions]="{placeholder: 'Filtrer par zone de stockage'}"
        searchExpr="label"
        searchMode="contains"
        itemTemplate="itemTemplate"
      >
        <div *dxTemplate="let item of 'itemTemplate'">
          <i class="{{ item.icon }}" ></i> {{ item.label }}
        </div>
      </dx-tree-view>
    </div>
  </form>

</p-card>
