<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'min-height':'500px', 'max-height':'500px', 'width':'80wh'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-edit"></i>
      Traitement de masse
    </div>
  </ng-template>

  <div class="mg-b-20">
    <p-panel>
      <ng-template pTemplate="header">Date</ng-template>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center justify-content-between mg-r-15 mg-b-5">
            <p class="mg-r-5">Date de livraison : </p>
            <p-calendar firstDayOfWeek="1" class="mg-l-5" [(ngModel)]="dateLiv" [locale]="localeFr"
                        dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mg-r-15 mg-b-5">
            <p class="mg-r-5">Date de approvisionnement : </p>
            <p-calendar firstDayOfWeek="1" class="mg-l-5" [(ngModel)]="dateAppro" [locale]="localeFr"
                        dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between mg-r-15 mg-b-5">
            <p class="mg-r-5">Date de consommation : </p>
            <p-calendar firstDayOfWeek="1" class="mg-l-5" [(ngModel)]="dateConso" [locale]="localeFr"
                        dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
        <div class="d-flex flex-column  ">

          <div class="d-flex flex-row align-items-center justify-content-between mg-r-15 mg-b-5">
            <p class="mg-r-5">Date de sortie : </p>
            <p-calendar firstDayOfWeek="1" class="mg-l-5" [(ngModel)]="dateSortie" [locale]="localeFr"
                        dateFormat="dd/mm/yy"></p-calendar>
          </div>

          <div class="d-flex flex-row align-items-center justify-content-between mg-r-15 mg-b-5">
            <p class="mg-r-5">Date de fabrication : </p>
            <p-calendar firstDayOfWeek="1" class="mg-l-5" [(ngModel)]="dateFab" [locale]="localeFr"
                        dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
      </div>
    </p-panel>
  </div>

  <p-panel>
    <ng-template pTemplate="header">Autre</ng-template>
    <div class="d-flex flex-row align-items-center mg-r-15">
      <p class="mg-r-5">Besoin ajusté : </p>
      <input pInputText class="mg-l-5" type="number" [(ngModel)]="besoinAjuste"/>
    </div>
  </p-panel>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check"
                   class="mg-r-5"
                   [disabled]="isDisabledSaveBtn()"
                   (onClick)="sendBulkBesoinSupplier()"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="closeDialog()"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>
</p-dialog>
