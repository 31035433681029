import {Component, Input} from '@angular/core';

@Component({
  selector: 'yo-icon-produitfab',
  template: `<ng-container>
    <i *ngIf="fabrique" class="fas fa-utensils yoni-color space"  title="PLAT" ></i>
    <i *ngIf="!fabrique" class="fas fa-apple-alt new-color space"  title="DENRÉE" ></i>
            </ng-container>`,
  styleUrls: ['./icon-produitfab.component.scss']
})
export class IconproduitFabriqueComponent {

  @Input() fabrique: boolean;
  @Input() yoDelayMsTooltip : number;

  constructor() {
  }

  isProduitFabrique(): boolean {

    if (this.fabrique) {
      return true;
    } else {
      return false;
    }
  }


}
