import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  templateUrl: './view-details-row-graphql.component.html',
  styleUrls: ['./view-details-row-graphql.component.scss'],
  selector: 'yo-view-details-row-graphql',
})
export class ViewDetailsRowGraphqlComponent implements OnInit, OnDestroy {

  subOpenDialog: Subscription;

  detailsHtml: string;
  displayDialog: boolean = false;

  constructor(private utilsSvc: UtilsService,
              private graphQlSvc: GraphQLService,
              public sanitizer: DomSanitizer){}

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  ngOnInit(): void {
    this.initSubscriptionOpenDialog();
  }

  initSubscriptionOpenDialog(): void {
    this.subOpenDialog = this.graphQlSvc.openDialogForDetailsRow$
      .subscribe(row => {
        this.displayDialog = true;
        this.detailsHtml = this.recursiveLoopJson(row);
      });
  }

  /**
   * Construit les détails de la ligne sélectionnée
   * @param jsonObject Ligne d'origine sans modifications (graphQL)
   */
  recursiveLoopJson(jsonObject: any, nbDecalage?: number): string {
    let resultHtml = '', decalagesHtml = '';
    let nbDecalageInitial = nbDecalage || 0;
    if (jsonObject) {

      if (nbDecalageInitial) {
        for(let i = 0; i < nbDecalageInitial; ++i) {
          decalagesHtml += "<span class='mg-r-10'></span>";
        }
      }

      for (var [key, value] of Object.entries(jsonObject)) {
        if (value && value.constructor.name.toLowerCase() === 'array') {
          ++nbDecalageInitial;
          resultHtml += `<h5 class="mg-t-10 mg-b-20">${decalagesHtml} ${key} :</h5>`;
          (value as any[]).forEach(val => {
            resultHtml += this.recursiveLoopJson(val, nbDecalageInitial);
          });
        } else if (value && value.constructor.name.toLowerCase() === 'object') {
          resultHtml += `<h5 class="mg-t-10 mg-b-20">${decalagesHtml} ${key} :</h5>`;
          resultHtml += this.recursiveLoopJson(value, nbDecalageInitial);
        } else if (value) {
          resultHtml += `<div><label>${decalagesHtml}<b>${key}</b></label> : ${value}</div>`;
        } else {
          resultHtml += `<div><label>${decalagesHtml}<b>${key}</b></label> : Non renseigné</div>`;
        }
      }
    }
    return resultHtml;
  }

  closeDialog(): void {
    this.displayDialog = false;
  }

}
