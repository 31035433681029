import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {Subscription} from 'rxjs';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {UniteDeProduction__secteurFournisseurService} from '../../../../core/services/entities/unite-de-production__secteur-fournisseur.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {FS_ROUTES} from '../../../../core/constants';
import {RoutemapService} from '../../../../core/services/routemap.service';

@Component({
  selector: 'yo-dialog-filiales-preferees',
  templateUrl: './dialog-filiales-preferees.component.html',
  styleUrls: ['./dialog-filiales-preferees.component.scss']
})
export class DialogFilialesPrefereesComponent implements OnInit, OnDestroy {

  displayDialog = false;
  subActionSelectionFilialesPreferees: Subscription;
  uniteDeProduction: UniteDeProductionDTO;
  udpSfList: UniteDeProduction__SecteurFournisseurDTO[] = [];
  selectedUdpSfList: UniteDeProduction__SecteurFournisseurDTO[] = [];

  constructor(public utils: UtilsService, private udpSvc: UnitesDeProductionService,
              private gds: GenericDatagridService,
              private routeMapSvc:RoutemapService,
              private udpSecteurFournisseurSvc: UniteDeProduction__secteurFournisseurService) {}

  ngOnInit() {
   this.subscriptionActionSelectionFilialesPreferees();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subActionSelectionFilialesPreferees);
  }

  subscriptionActionSelectionFilialesPreferees(){
    this.subActionSelectionFilialesPreferees = this.udpSvc.actionSelectionFilialesPreferees$.pipe(
      tap(response => this.uniteDeProduction = response),
      switchMap(response => this.gds.search(this.udpSecteurFournisseurSvc.filterByUniteDeProduction(this.uniteDeProduction))),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(response => {
        this.udpSfList = response.resultList;
        this.selectedUdpSfList = this.initSelectedUdpSfList(this.udpSfList);
        this.displayDialog = true;
      });
  }

  initSelectedUdpSfList(udpSfList: UniteDeProduction__SecteurFournisseurDTO[]) {
    const items = this.utils.safe(udpSfList);
    const selectedItems = [];
    for (const udpSf of items) {
      if (udpSf.prefere) {
        selectedItems.push(udpSf);
      }
    }
    return selectedItems;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  saveFilialesPreferees() {
    this.selectedUdpSfList = this.utils.safe(this.selectedUdpSfList);
    this.udpSecteurFournisseurSvc.saveFilialesPreferees(this.selectedUdpSfList, this.uniteDeProduction).subscribe(response=>{
      if(!this.utils.isResponseSupplierError(response)){
        this.closeDialog();
        this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GUP_UPRO,this.uniteDeProduction.id,'articles-preferes','grille']);
      }
    });
  }

}
