import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {JoursDecalageSupplier} from './JoursDecalageSupplier';
import {PlanningEquipesService} from '../../../../../core/services/gestion-unites-productions/planning-equipes.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {MJourDecalage} from '../../../../../core/models/gestion-unites-production/m-jour-decalage';
import {find as _find} from 'lodash';
import {DxSelectBoxComponent} from 'devextreme-angular';

@Component({
  selector: 'yo-jours-decalage',
  templateUrl: './jours-decalage.component.html',
  styleUrls: ['./jours-decalage.component.scss'],
})
export class JoursDecalageComponent implements OnInit {

  @ViewChild('selectBox') selectBox: DxSelectBoxComponent;

  @Input() set joursDecalageSupplier(value: JoursDecalageSupplier) {

    this._jds = value;


    if (!this.utils.isNullOrEmpty(this._jds)) {

      this._jourDecalageList = this._jds.joursDecalageList;
      this._selectedJourDecalage = _find(this._jourDecalageList, {'nbJoursDecalage': this._jds.selectedJourDecalage.nbJoursDecalage});
    }
  }

  _jds: JoursDecalageSupplier;
  _jourDecalageList: MJourDecalage[] = [];
  _selectedJourDecalage: MJourDecalage;
  editable = false;

  @Output() onChangeEmitter: EventEmitter<MJourDecalage> = new EventEmitter();

  constructor(public planningEquipesSvc: PlanningEquipesService, public utils: UtilsService) {
  }

  ngOnInit(): void {

  }

  valueChanged($event) {
    this.editable = false;
    this._selectedJourDecalage = $event.value;
    this.onChangeEmitter.emit(this._selectedJourDecalage);
  }

  onInitialized(event: any) {

    setTimeout(function () {
      event.component.focus();
      event.component.open();
    }, 50);

  }

  onFocusOut($event: any) {
    this.editable = false;
  }

}
