import {ObjectDTO} from "./object-dto";

export class ContratMenuConviveDecoupage__PlcDTO extends ObjectDTO {

  idContratMenuConviveDecoupage: number;
  idContratMenuConvivePlc: number;
  effectifPrevu:number;
  tauxDePrise:number;

  idJourSemaine:number;
  idRepas:number;
  idDecoupageRepas:number;

  // contrat menu convive decoupage
  nombreChoixMaximum:number;

  ordre:number;

  // transient, s'il y a une repartition d'effectif, permet d'indiquer sur quel cmcPlc a été réalisé l'arrondi.
  arrondiEffPrev: boolean;

}
