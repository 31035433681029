import {ObjectDTO} from '../object-dto';
import {UniteDeMesureDTO} from "../unitedemesure-dto";

export default class ModeleConditionnementPlatDTO extends ObjectDTO {

  libelle: string;

  code: string;

  mode: string;

  uniteAConditionner: UniteDeMesureDTO;

}
