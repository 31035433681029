<ng-container *ngIf="dateCreationMenu">

  <ng-container *ngIf="dateSaisieEffectif">

    <div class="day-cell saisie-effectifs-day" title="Menus validés"
         >{{date.day}}</div>

  </ng-container>

  <ng-container *ngIf="!dateSaisieEffectif">
    <div class="creation-menus-day" title="Menus en cours de saisie" >{{date.day}}</div>
  </ng-container>
</ng-container>


<ng-container *ngIf="!dateCreationMenu">

  <ng-container *ngIf="dateSaisieEffectif">

    <div class="day-cell saisie-effectifs-day" title="Menus validés"
         >{{date.day}}</div>

  </ng-container>

  <ng-container *ngIf="!dateSaisieEffectif">
  <div title="Aucun menu" >{{date.day}}</div>
  </ng-container>

</ng-container>
