import {ObjectDTO} from "./object-dto";

export class AuditedDTO extends ObjectDTO {

  dateCreation: Date;
  dateDerniereModification: Date;
  creePar: string;
  modifiePar: string;

}
