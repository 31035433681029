import {Component, Input, OnInit} from "@angular/core";
import {UtilisateurService} from "../../core/utilisateur.service";
import {Auth2Service} from "../../core/services/security/auth2.service";

@Component({
  selector: 'yo-viewmode',
  templateUrl: './viewmode.component.html',
  styleUrls: ['./viewmode.component.scss']
})
export class ViewmodeComponent implements OnInit {

  @Input() libelle;
  @Input() siteId;
  @Input() yoLabelFab;
  private user;

  constructor(private userService: UtilisateurService,
              private auth2Svc : Auth2Service  ) {
  }

  ngOnInit() {
    this.user = this.auth2Svc.utilisateur;
  }

  /**
   * Afficher le bouton modifier si un produit decli est sélectionné et qu'il fait partie des sites locaux
   * de l'utilisateur connecté.
   * @returns {boolean}
   */
  // isEditMode(): boolean {
  //   debugger;
  //   let response: boolean;
  //
  //   let sitePrincipalId: number = this.user.sitePrincipal.id;
  //
  //
  //   if (this.siteId && this.siteId === sitePrincipalId) {
  //     response = true;
  //   } else {
  //     response = false;
  //   }
  //
  //   console.log("this.siteId = " + this.siteId + ", this.user.sitePrincipal.id = "  + this.user.sitePrincipal.id);
  //   console.log("this.libelle = " + this.libelle + ", this.user.sitePrincipal.libelle = "  + this.user.sitePrincipal.libelle);
  //   console.log("isEditMode = " + response);
  //
  //   return  response;
  // }

  isEditMode(): boolean {
    let response: boolean = this.siteId && this.auth2Svc.isSiteLocal(this.siteId);
    return  response;
  }

}
