import {Component, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Router} from '@angular/router';

@Component({
  selector: 'yo-utilisateurs-connexions',
  templateUrl: './utilisateurs-connexions.component.html',
  styleUrls: ['./utilisateurs-connexions.component.scss']
})
export class UtilisateursConnexionsComponent implements OnInit {


  @ViewChild('grilleutilisateursconnexions', { static: true }) grilleutilisateursconnexions;

  constructor(private router: Router, private utils: UtilsService) { }


  ngOnInit() {
    this.utils.setTitle('Gestion des connexions des utilisateurs');
  }

  ngAfterViewInit(): void {

  }


}
