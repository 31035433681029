import {Injectable} from '@angular/core';
import {UtilsService} from "../utils/utils.service";

@Injectable()
export class SearchService {

  constructor(private utils:UtilsService) { }

  /**
   * Creation du Parametre URL pour trier la grille envoyé au back
   * on recoit un tableau de sort, et on trie en consequence
   * exemple : ['ordre,asc','libelle,desc'] devient &sort=ordre,asc&sort=libelle,desc
   * @param {string[]} sort
   * @returns {string}
   */
  public createSortUrlParam( sort?:string[]):string{
    let sortParam = '' ;
    if(!this.utils.isCollectionNullOrEmpty(sort)){
      let sortMakerArr = sort.map(s=> 'sort='+s);
      sortParam = '&'+ sortMakerArr.join('&');
    }
    return sortParam;
  }

  /**
   * Creation du Parametre URL pour paginer la grille envoyé au back
   * exemple : [0,10] devient &page=0&size=10
   * @param page
   * @return {string}
   */
  public createPageUrlParam( page:Page):string{
    let pageParam = '&page=0&size=20' ;

    if(!this.utils.isNullOrEmpty(page)){
      pageParam = `&page=${page.page}&size=${page.size}`;
    }
    return pageParam;
  }


  /**
   * Calculer la page à renvoyer pour une datagrid kjlnhjk
   * @param {number} offset
   * @param {number} size
   * @returns {string}
   */
  page(offset: number, size: number) {
    let pagePartUrl = '';
    let page: number = (offset + size) / size;
    //page starts at index 0
    page = page - 1;
    pagePartUrl = `page=${page}&size=${size}&`;
    return pagePartUrl;
  }

  getPageParam(offset: number, size: number):number{
    let page = 0;
    if(offset>0){
      page = offset/size;
    }
    return page;
  }


}

export class Page{
  page:number;
  size:number;
  constructor(page : number=0, size: number=20) {
    this.page = page;
    this.size = size;
  }
}
