import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ContratsMenusConvivesEquipesService} from '../../../../core/services/entities/contrats-menus-convives-equipes.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';
import {ContratMenuConvive__EquipeDTO} from '../../../../core/dtos/contratmenuconvive-equipe-dto';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {FS_ROUTES, JOURS_SEMAINES} from '../../../../core/constants';
import {Router} from "@angular/router";

/**
 * Composant d'affichage des jours de fab utilisé dans l'edition et la grille d'affectation des équipes
 */
@Component({
  selector: 'yo-cmc-equipes-jours',
  templateUrl: './cmc-equipes-jours.component.html',
  styleUrls: ['./cmc-equipes-jours.component.scss']
})
export class CmcEquipesJoursComponent implements OnInit {

  readonly joursSemaine = JOURS_SEMAINES;

  @Input() contratMenuConvive: ContratMenuConviveDTO;
  @Input() cmcEquipe: ContratMenuConvive__EquipeDTO;
  @Input() udpEquipeList: UniteDeProduction__EquipeDTO[];
  @Input() moreDetails: boolean = true;

  constructor(public utils: UtilsService,
              public cmcEquipeSvc: ContratsMenusConvivesEquipesService,
              private router : Router) {
  }

  ngOnInit() {
  }

  openUdpFabrication = () => {
    this.router.navigate([{outlets: {secondary: [FS_ROUTES.GUP_UPRO, this.cmcEquipe.idUniteDeProduction, 'equipes','parametrage']}}])
  };

}
