import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GestionsMarcheService} from '../../../../../core/services/gestion-marche/gestions-marche.service';
import {GestionLotMarcheService} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {combineLatest, Observable} from 'rxjs';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {LotMarcheService} from '../../../../../core/services/entities/lot-marche.service';


@Injectable({
  providedIn: 'root'
})
export class InfoLotMarcheResolverService implements Resolve<InfoLotMarcheSupplier> {

  constructor(private lotMarcheSvc: LotMarcheService,
              private gestionsMarcheSvc: GestionsMarcheService,
              private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve = (route: ActivatedRouteSnapshot | any, state: RouterStateSnapshot): Observable<InfoLotMarcheSupplier> | Promise<InfoLotMarcheSupplier> | InfoLotMarcheSupplier => {
    let lotMarcheSupplier: InfoLotMarcheSupplier = new InfoLotMarcheSupplier();
    let idLotMarche: number = +route.paramMap.get('idLotMarche');
    let idMarche: number = +route._urlSegment.segments[2].path;

    const marcheDTO$ = this.gestionsMarcheSvc.getMarcheById(idMarche);

    if (idLotMarche > 0) {
      const lotMarcheDTO$ = this.gestionLotMarcheSvc.getById(idLotMarche);
      const all$ = combineLatest(lotMarcheDTO$);
      return all$.toPromise().then(data => {
        lotMarcheSupplier.lotMarche = data[0].one;
        return lotMarcheSupplier;
      });

    } else {
      //si nouveau lot marché
      const all$ = combineLatest(marcheDTO$);
      return all$.toPromise().then(data => {
        lotMarcheSupplier.lotMarche = this.lotMarcheSvc.createEmptyDTO();
        lotMarcheSupplier.lotMarche.id = 0;
        lotMarcheSupplier.lotMarche.marche = data[0].one;
        lotMarcheSupplier.lotMarche.dateDebut = lotMarcheSupplier.lotMarche.marche.dateDebut;
        lotMarcheSupplier.lotMarche.dateFin = lotMarcheSupplier.lotMarche.marche.dateFin;
        lotMarcheSupplier.lotMarche.actif = true;
        return lotMarcheSupplier;
      });
    }
  };
}


export class InfoLotMarcheSupplier {
  lotMarche: LotMarcheDTO;
}
