<div class="d-flex flex-row container-cmc-planning">

  <!--FILTRES-->
  <div class="d-flex flex-column max-width-300">

    <h3 class="first">
      <span class="mg-r-5">Périodes</span>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="helpPeriodes()" [width]="800"></yo-help>
    </h3>
    <p-listbox [options]="periodes"
               [style]="{'width':'300px'}"
               [listStyle]="{'max-height':'300px'}"
               [(ngModel)]="selectedPeriode"
               optionLabel="modeleNutritionnelLibelle"
               placeholder="sélection..."
               showClear="true"
               (onChange)="changePeriode(selectedPeriode)"
               dataKey="id">

      <ng-template let-item pTemplate="selectedItem">
        <div style="display:inline;position: relative;height: 25px;">
              <span style="font-size:14px;margin-top:4px" [title]="selectedPeriode?.modeleNutritionnelLibelle"

                    [innerHTML]="domSanitizerSvc.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(item))"></span>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div style="display:inline;position: relative;height: 25px;">
              <span style="font-size:14px;margin-top:4px" [title]="selectedPeriode?.modeleNutritionnelLibelle"

                    [innerHTML]="domSanitizerSvc.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(item))"></span>
        </div>
      </ng-template>
    </p-listbox>

    <p-card @triggerPeriode *ngIf="displayAddPlanning" class="mg-t-20">
      <div>
        <i class="far fa-calendar-plus mg-b-10 mg-r-5 pn-border-color"></i>Nouvelle période
      </div>
      <div>
        <div class="genlabel">Date de début</div>
        <p-calendar [(ngModel)]="dateDebutPlanning"
                    [minDate]="prochaineDateDisponible"
                    [locale]="localeFr"
                    firstDayOfWeek="1"
                    dateFormat="dd/mm/yy"
                    showButtonBar="true"
                    [readonlyInput]="true"
                    [showWeek]="true">
        </p-calendar>

        <div class="genlabel mg-t-10">Modèle de menu</div>
        <p-dropdown [options]="plansAlimentaires"
                    filter="true"
                    [style]="{'width':'200px','height':'36px'}"
                    [(ngModel)]="selectedPlanAlimentaire"
                    optionLabel="libelle"
                    placeholder="sélection..."
                    (onChange)="changePlanAlimentaire({$event : $event})"
                    [panelStyle]="{minWidth:'12em'}">
          <ng-template let-planAlimentaire pTemplate="selectedItem">
            <div style="display:inline;position: relative;height: 25px;">
                <span style="font-size:14px;margin-top:4px">
                     <yo-site [siteId]="planAlimentaire.site.id"></yo-site>
                  {{planAlimentaire.libelle}}</span>
            </div>
          </ng-template>
          <ng-template let-planAlimentaire pTemplate="item">
            <div style="display:inline;position: relative;height: 25px;">

                <span style="font-size:14px;margin-top:4px">
                     <yo-site [siteId]="planAlimentaire.site.id"></yo-site>
                  {{planAlimentaire.libelle}}</span>
            </div>
          </ng-template>
        </p-dropdown>

        <div @triggerPeriode *ngIf="!validAddPlaning" class="mg-t-10 text-left danger-color"
             style="max-width:240px;">
          <span [innerHTML]="domSanitizerSvc.bypassSecurityTrustHtml(errorAddPlanning)"></span>
        </div>

        <div class="mg-t-10 text-right" style="width:100%;">
          <yo-button label="Ajouter"
                     tooltip="Ajouter la nouvelle période avec le modèle de menu sélectionné" iconClass="fas fa-plus"
                     (onClick)="createPeriode()"></yo-button>
        </div>

      </div>
    </p-card>

  </div>

  <!--GRILLE-->
  <div class="mg-l-15 table-width-size">

    <div *ngIf="isGridDisplayable()" @triggerPeriode>
      <p-table
        [scrollHeight]="utils.scrollHeightContent(230)"
        styleClass="p-datatable-sm"
        [scrollable]="true"
        [frozenWidth]="(colRepasWidth*4)+'px'"
        [value]="rows"
        #dt
        dataKey="id"
      >

        <!--FROZEN COLUMNS HEADER-->
        <ng-template pTemplate="frozenheader">
          <tr>
            <th [style.width.px]="colRepasWidth">
              <span class="mg-r-5">Jour</span>

            </th>
            <th [style.width.px]="colRepasWidth">Repas</th>
            <th [style.width.px]="colRepasWidth">

              <div class="no-overflow " >
                Eff. théorique
              </div>
            </th>
            <th [style.width.px]="colRepasWidth">
              Coût théorique
            </th>
          </tr>
        </ng-template>

        <!--FROZEN COLUMNS BODY-->
        <ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td *ngIf="rowGroupMetadata[rowData.idJourSemaine].index === rowIndex"
                [attr.rowspan]="rowGroupMetadata[rowData.idJourSemaine].size">
              <div class="no-overflow" >
                <strong>{{utils.getJourSemaineById(rowData.idJourSemaine).viewValue | uppercase }}</strong>
              </div>
            </td>
            <td [style.height.px]="getTailleComposante(rowData)">
              <div class="no-overflow"  [title]="rowData.repasLibelle">
                <strong>{{rowData.repasLibelle}}</strong>
              </div>
            </td>
            <td style="text-align: right;">
              <ng-container *ngTemplateOutlet="tplEffectifPrevisionnel,context:{rowData:rowData}"></ng-container>
            </td>
            <td style="text-align: right;">
              <ng-container *ngTemplateOutlet="tplCoutTheorique,context:{rowData:rowData}"></ng-container>
            </td>
          </tr>
        </ng-template>

        <!--CAPTION-->
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-md-6 text-left font-12">
              <i
                class="fas fa-calendar mg-r-5"></i> {{selectedPeriode.modeleNutritionnelLibelle | uppercase}}
            </div>
            <div class="col-md-6 text-right">

                   <span class="mg-r-5">
                  <p-menu appendTo="body" #editPlanning [popup]="true" [model]="itemsEditPlanning"></p-menu>
                  <yo-button iconClass="fas fa-edit" class="mg-r-5"
                             (onClick)="editPlanning.toggle($event)"
                             tooltip="Modification en masse des  effectifs, coûts et taux de prise théoriques"
                  ></yo-button>
                   </span>

              <span class="mg-r-5">
                <yo-button
                  tooltip="Enregistrer"
                  type="submit"
                  iconClass="fas fa-save"
                  buttonClass="cta-success"
                  (onClick)="displaySaveDialog=true"
                  [disabled]="!gds.canModify(contratMenuConvive)">

                </yo-button>
              </span>
              <span class="mg-r-5">
                    <yo-button (onClick)="removePeriode(selectedPeriode)" iconClass="fas fa-trash"
                               buttonClass="cta-delete"></yo-button>
                  </span>

            </div>
          </div>
        </ng-template>

        <!--HEADER-->
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let col of cols ; trackBy:utils.trackByFn ">
              <th [style.width.px]="cellWidth">
                <div class="no-overflow"  [title]="col.libelle">
                  {{col.libelle | uppercase}}
                </div>
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <!--BODY-->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>
            <!--CELLULES-->
            <ng-container *ngFor="let col of cols ; trackBy:utils.trackByFn ">

              <ng-container *ngTemplateOutlet="tplCell;context:{cell:getCell(rowData,col),cmcr:rowData}">
              </ng-container>

            </ng-container>
          </tr>

        </ng-template>

      </p-table>

    </div>

  </div>


  <!--TEMPLATE EFFECTIF PREVISIONNEL-->
  <ng-template #tplEffectifPrevisionnel let-rowData="rowData">

    <div class="input-group input-group-sm ">
      <input type="number" pKeyFilter="pnum" style="width: 60px;" [(ngModel)]="rowData.effectifPrevu">
      <div class="input-group-append ">
      <span class="input-group-text font-10">
       pers.
      </span>
      </div>
    </div>
  </ng-template>

  <!--TEMPLATE COUT THEORIQUE-->
  <ng-template #tplCoutTheorique let-rowData="rowData">

    <div class="input-group input-group-sm ">
      <input type="number" step="any" pKeyFilter="pnum" style="width: 60px;" [(ngModel)]="rowData.prixRepasTheorique">
      <div class="input-group-append ">
        <span class="input-group-text font-10">€</span>
      </div>
    </div>
  </ng-template>

  <!--TEMPLATE CELLULE-->
  <ng-template #tplCell let-cell="cell" let-cmcr="cmcr">
    <td [style.width.px]="cellWidth"
        [style.height.px]="getTailleComposante(cmcr)"
        [style.vertical-align]="'top'"
        [style.text-align]="'right'">

      <ng-container *ngIf="utils.isNullOrEmpty(cell)">
        <i class="fas fa-exclamation-triangle mg-r-5 danger-color"></i><em class="font-12 genlabel-color"> Aucun
        plat
        paramétré</em>
      </ng-container>

      <!--    <ng-container *ngIf="!utils.isNullOrEmpty(cell)">-->
      <!--      <ng-container *ngIf="cell.nombreChoixMaximum===1">-->
      <!--        {{cell.nombreChoixMaximum}} plat-->
      <!--      </ng-container>-->
      <!--      <ng-container *ngIf="cell.nombreChoixMaximum>1">-->
      <!--        {{cell.nombreChoixMaximum}} plats-->
      <!--      </ng-container>-->
      <!--    </ng-container>-->


      <ng-container *ngFor="let plat of cell.contratMenuConvivePlatDTOList; trackBy:utils.trackByFn">

        <div class="input-group input-group-sm  ">
          <div class="input-group-prepend ">
            <span class="input-group-text font-10">Plat {{plat.ordre}}</span>
          </div>
          <input type="number" pKeyFilter="pint"
                 style="width: 40px;"
                 [ngModel]="plat.tauxDePrise"
                 (keydown)="utils.keydownNumber($event,plat,'tauxDePrise',true)"
                 (ngModelChange)="changeTauxDePrise(plat,cell,cmcr,$event)"
          >
          <div class="input-group-append ">
          <span class="input-group-text font-10"> %
          </span>
          </div>
        </div>

      </ng-container>


    </td>
  </ng-template>


  <!--DIALOG COUT THEORIQUE-->
  <p-dialog
    [modal]="true"
    [dismissableMask]="false"
    [style]="{'width':'800px'}"
    [contentStyle]="{'overflow':'visible'}"
    [(visible)]="displayCoutTheoriqueDialog">

    <!--TITRE-->
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        Modifier les coûts théoriques
      </div>
    </ng-template>


    <!--CONTENT-->
    <div class="row">
      <div class="col-md-4">
        <h3 class="first">Jours ouvrés</h3>
        <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                   optionLabel="viewValue">
        </p-listbox>
      </div>
      <div class="col-md-4">
        <h3 class="first">Repas</h3>
        <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                   optionLabel="libelle">
        </p-listbox>
      </div>
      <div class="col-md-4">
        <h3 class="first">Coût théorique</h3>
        <div class="input-group input-group-sm ">
          <input type="number" pKeyFilter="pnum" style="width: 60px;" [(ngModel)]="majPrixRepasTheorique">
          <div class="input-group-append ">
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>
    </div>


    <!--FOOTER-->
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-12 text-right">
          <yo-button iconClass="fas fa-check" class="mg-r-5"
                     (onClick)="updateCoutsTheoriques(selectedJoursSemaine,selectedRepasList,majPrixRepasTheorique)"></yo-button>
          <yo-button iconClass="fas fa-times" (onClick)="displayCoutTheoriqueDialog=false"
                     buttonClass="cta-delete"></yo-button>
        </div>
      </div>

    </ng-template>


  </p-dialog>


  <!--DIALOG TAUX DE PRISE PREV-->
  <p-dialog
    [modal]="true"
    [dismissableMask]="false"
    [style]="{'width':'1200px'}"
    [contentStyle]="{'overflow':'visible'}"
    [(visible)]="displayTauxDePriseDialog">

    <!--TITRE-->
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        Modifier les taux de prise prévisionnels
      </div>
    </ng-template>


    <!--CONTENT-->
    <div class="row">
      <div class="col-md-2">
        <h3 class="first">Jours ouvrés</h3>
        <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                   optionLabel="viewValue">
        </p-listbox>
      </div>
      <div class="col-md-2">
        <h3 class="first">Repas</h3>
        <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                   optionLabel="libelle">
        </p-listbox>
      </div>

      <div class="col-md-2">
        <h3 class="first">Composantes</h3>
        <p-listbox [options]="decoupageRepasList" [(ngModel)]="selectedDecoupageRepasList" multiple="multiple"
                   checkbox="checkbox"
                   optionLabel="libelle">
        </p-listbox>
      </div>

      <div class="col-md-2">
        <h3 class="first">Plats</h3>
        <p-listbox [options]="platList" [(ngModel)]="selectedPlatList" multiple="multiple" checkbox="checkbox"
        >
        </p-listbox>
      </div>

      <div class="col-md-2">
        <h3 class="first">Taux de prise prévisionnel</h3>
        <div class="input-group input-group-sm ">
          <input type="number" pKeyFilter="pint" style="width: 60px;" [(ngModel)]="majTauxDePrisePrev">
          <div class="input-group-append ">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>


    <!--FOOTER-->
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-12 text-right">
          <yo-button iconClass="fas fa-check" class="mg-r-5"
                     (onClick)="updateTauxDePrisePrev(selectedJoursSemaine,selectedRepasList,selectedDecoupageRepasList,selectedPlatList,majTauxDePrisePrev)"></yo-button>
          <yo-button iconClass="fas fa-times"
                     class="cta-delete"
                     (onClick)="displayTauxDePriseDialog=false"></yo-button>
        </div>
      </div>

    </ng-template>


  </p-dialog>

  <!--DIALOG EFFECTIFS PREV-->
  <p-dialog
    [modal]="true"
    [dismissableMask]="false"
    [style]="{'width':'800px'}"
    [contentStyle]="{'overflow':'visible'}"
    [(visible)]="displayEffectifDialog">

    <!--TITRE-->
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        Modifier les effectifs théoriques
      </div>
    </ng-template>


    <!--CONTENT-->
    <div class="row">
      <div class="col-md-4">
        <h3 class="first">Jours ouvrés</h3>
        <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                   optionLabel="viewValue">
        </p-listbox>
      </div>
      <div class="col-md-4">
        <h3 class="first">Repas</h3>
        <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                   optionLabel="libelle">
        </p-listbox>
      </div>
      <div class="col-md-4">
        <h3 class="first">Effectif prévisionnel</h3>
        <div class="input-group input-group-sm ">
          <input type="number" pKeyFilter="pint" style="width: 60px;" [(ngModel)]="majEffectifPrev">
          <div class="input-group-append ">
            <span class="input-group-text">pers.</span>
          </div>
        </div>
      </div>
    </div>


    <!--FOOTER-->
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-12 text-right">
          <yo-button iconClass="fas fa-check" class="mg-r-5"
                     (onClick)="updateEffectifsPrev(selectedJoursSemaine,selectedRepasList,majEffectifPrev)"></yo-button>
          <yo-button iconClass="fas fa-times" (onClick)="displayEffectifDialog=false"
                     buttonClass="cta-delete"></yo-button>
        </div>
      </div>

    </ng-template>


  </p-dialog>

  <!--DIALOG SAUVEGARDE DU PLANNING-->
  <p-dialog
    [modal]="true"
    position="top"
    [dismissableMask]="false"
    [style]="{'width':'800px'}"
    [contentStyle]="{'overflow':'visible'}"
    [(visible)]="displaySaveDialog">

    <!--TITRE-->
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        <i class="fas fa-calendar mg-r-5"></i>Enregistrer le plan
        alimentaire {{selectedPeriode?.modeleNutritionnelLibelle}} pour la
        période <span
        [innerHTML]="domSanitizerSvc.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(selectedPeriode))"></span>
      </div>
    </ng-template>


    <!--CONTENT-->


    <div class="row">
      <div class="col-md-12">
        <ng-container *ngIf="!utils.isCollectionNullOrEmpty(cmcPlcList)">
          <p-checkbox [(ngModel)]="applyPrevOnPlc" binary="true"
                      label="Appliquer les effectifs, coûts théoriques et taux de prise sur les points de livraison"></p-checkbox>
        </ng-container>
      </div>
    </div>
    <div class="row" *ngIf="applyPrevOnPlc">
      <div class="col-md-12">

        <p-listbox [options]="cmcPlcList" [(ngModel)]="selectedCmcPlcList" multiple="multiple" checkbox="checkbox"
                   [style]="{'width':'400px'}"
                   [listStyle]="{'max-height':'450px','min-height':'450px'}"
                   optionLabel="libellePointDeLivraison">
        </p-listbox>

      </div>
    </div>


    <!--FOOTER-->
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-12 text-right">
          <yo-button iconClass="fas fa-save" class="mg-r-5"
                     buttonClass="cta-success"
                     (onClick)="savePlanning()"></yo-button>
          <yo-button iconClass="fas fa-times" (onClick)="displaySaveDialog=false" buttonClass="cta-delete"></yo-button>
        </div>
      </div>

    </ng-template>


  </p-dialog>
