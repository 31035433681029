<dx-popup
  [showTitle]="true"
  title="Supprimer les lots périmés du stock"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
  (onDisposing)="closeDialog()"
  width="auto"
>
  <div class="row">
    <div class="col-lg-12">
      <dx-radio-group [(value)]="deleteOption" valueExpr="value" displayExpr="label"
                      [items]="deleteOptions"></dx-radio-group>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-lg-12 text-right">
      <span class="mg-r-5">
        <yo-button iconClass="fas fa-trash" (onClick)="deletePerimes()" tooltip="Supprimer les lots périmés"
                   label="Supprimer"
                   buttonClass="cta-inner-delete"></yo-button>
      </span>
      <span class="mg-r-5">
        <yo-button iconClass="fas fa-times" (onClick)="closeDialog()" tooltip="Annuler" label="Annuler"
                   buttonClass="cta-inner-delete"></yo-button>
      </span>
    </div>
  </div>
</dx-popup>
