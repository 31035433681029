import {ContratMenuConvive__EquipeDTO} from "../../dtos/contratmenuconvive-equipe-dto";
import {TacheDTO} from "../../dtos/tache-dto";
import {UniteDeProduction__EquipeDTO} from "../../dtos/unite-de-production__equipe";
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";

export class CmcEquipeDialogSupplier {
  cmcEquipe: ContratMenuConvive__EquipeDTO;
  tacheFabricationList: TacheDTO[];
  udpEquipeList: UniteDeProduction__EquipeDTO[];
  contratMenuConvive: ContratMenuConviveDTO;
  title: string;
  logo:string;
}
