import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

export const debuggerOn = !environment.production;

// Observable.prototype.debug = function (message) {
//   return this.do(
//     nextValue => {
//       if (debuggerOn) {
//         console.log(message, nextValue)
//       }
//     },
//     error => {
//       if (debuggerOn) {
//         console.log(message, error)
//       }
//     },
//     () => {
//       if (debuggerOn) {
//         console.log('Observable completed - ', message)
//       }
//     });
//
// };

// declare module 'rxjs/Observable' {
//   interface Observable<T> {
//     debug : (...any) =>  Observable<T>
//   }
// }



if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
