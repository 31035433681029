import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ClientDTO} from '../../../../core/dtos/client-dto';
import {ClientSupplier} from '../client-resolver.service';
import {FormGroup} from '@angular/forms';
import {ClientsService} from '../../../../core/services/entities/clients.service';
import {FormFieldBaseSupplier} from '../../../../core/suppliers/form-fieldbase-supplier';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {ContactsService} from '../../../../core/services/entities/contacts.service';
import {ContactDTO} from '../../../../core/dtos/contact-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {AdressesService} from '../../../../core/services/entities/adresses.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {FP_ROUTES, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-client-fiche-identite',
  templateUrl: './client-fiche-identite.component.html',
  styleUrls: ['./client-fiche-identite.component.scss']
})
export class ClientFicheIdentiteComponent implements OnInit, OnDestroy {

  subClient: Subscription;
  client: ClientDTO;

  formClient: FormGroup;
  fieldsClient: FormFieldBaseSupplier<any>[];

  formContact1: FormGroup;
  fieldsContact1: FormFieldBaseSupplier<any>[];

  formContact2: FormGroup;
  fieldsContact2: FormFieldBaseSupplier<any>[];

  formAdresseClient: FormGroup;
  fieldsAdresseClient: FormFieldBaseSupplier<any>[];

  subLibelle: Subscription;
  clientLibelle$;

  ancienNomAdresseClient: string;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService,
              public gfs: GenericFormService,
              public adresseSvc: AdressesService,
              private routeMapSvc: RoutemapService,
              public contactsSvc: ContactsService,
              public clientsSvc: ClientsService,
              private toastSvc: ToastService) {}


  ngOnInit() {
    this.initForms(this.client);
    this.clientLibelle$ = this.clientsSvc.libelle$;
    this.subLibelle = this.clientLibelle$.subscribe(value => {
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdresseClient)) {
        const field = _find(this.fieldsAdresseClient, {'key': 'nom'});
        if (UtilsService.staticIsNullOrEmpty(this.ancienNomAdresseClient)) {
          this.formAdresseClient.controls[field.key].setValue(value);
        }
      }
    });

    this.subClient = this.route.data
      .subscribe((data: { clientSupplier: ClientSupplier }) => {
        this.client = data.clientSupplier.client;
        this.initForms(this.client);
      });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subClient);
  }

  initForms = (client: ClientDTO): void => {

    if (!this.utils.isNullOrEmpty(client)) {

      this.fieldsClient = this.clientsSvc.getFields(client);
      this.formClient = this.gfs.toFormGroup(this.fieldsClient);

      this.fieldsAdresseClient = this.adresseSvc.getFields(client.adresse);
      this.formAdresseClient = this.gfs.toFormGroup(this.fieldsAdresseClient);

      this.fieldsContact1 = this.contactsSvc.getFields(client.contact1);
      this.formContact1 = this.gfs.toFormGroup(this.fieldsContact1);

      this.fieldsContact2 = this.contactsSvc.getFields(client.contact2);
      this.formContact2 = this.gfs.toFormGroup(this.fieldsContact2);

      if (!this.utils.isNullOrEmpty(client.adresse)) {
        this.ancienNomAdresseClient = client.adresse.nom;
      } else {
        this.ancienNomAdresseClient = null;
      }
    }
  };

  save = () => {
    this.gfs.validateAllFormFields(this.formClient);
    this.gfs.validateAllFormFields(this.formAdresseClient);
    this.gfs.validateAllFormFields(this.formContact1);
    this.gfs.validateAllFormFields(this.formContact2);

    if (this.formClient.valid && this.formAdresseClient.valid && this.formContact1.valid && this.formContact2.valid) {

      const client = this.gfs.mapFormToDTO(this.formClient) as ClientDTO;
      const adresseClient = this.gfs.mapFormToDTO(this.formAdresseClient) as AdresseDTO;
      const contact1 = this.gfs.mapFormToDTO(this.formContact1) as ContactDTO;
      const contact2 = this.gfs.mapFormToDTO(this.formContact2) as ContactDTO;

      client.adresse = adresseClient;
      // le site de l'adresse doit être le meme que site du client
      client.adresse.site = client.site;
      client.contact1 = contact1;
      client.contact2 = contact2;

      this.client = _cloneDeep(client);

      this.clientsSvc.save(client).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Client ${client.libelle} enregistré avec succès`);
          this.client = response.one;
          this.initForms(this.client);
          this.clientsSvc.announceRefreshgrid(response.one);
        }
      });
    }
  };

  newPointDeLivraison(client: ClientDTO) {
    this.routeMapSvc.goToPrimaryRoute([FP_ROUTES.GESTION_CLIENTS, 'clients', client.id, 'point-de-livraison', 0]);
  }

}
