import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {PlanProductionDTO} from '../../dtos/plan-production-dto';
import {startCase as _startCase} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PlanProductionService extends GenericHandler<PlanProductionDTO> {

  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES PLANS DE PRODUCTION';

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getCreateNewObjectLabel = (): string => {
    return ;
  };

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  getEntityName = (): string => 'PlanProduction';

  getFields = (dto: PlanProductionDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = new PlanProductionDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getAllFromEnvironnement = (): void => {

  };

  createEmptyDTO = (): PlanProductionDTO => new PlanProductionDTO();

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PLAN DE PRODUCTION '${data.libelle.toUpperCase()}'`;
}
