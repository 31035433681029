import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {RoutemapService} from '../../../core/services/routemap.service';
import {Subscription} from 'rxjs';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {ContratMenuConviveSupplier} from '../contratmenu-convive-resolver.service';
import {UniteDeProduction__EquipeDTO} from '../../../core/dtos/unite-de-production__equipe';
import {CmcEquipesSupplier} from './cmc-equipes-resolver.service';
import {ContratMenuConvive__EquipeDTO} from '../../../core/dtos/contratmenuconvive-equipe-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {FS_ROUTES, HELP_FOLDERS} from '../../../core/constants';
import {ContratsMenusConvivesEquipesService} from '../../../core/services/entities/contrats-menus-convives-equipes.service';
import {TacheDTO} from '../../../core/dtos/tache-dto';
import {CmcEquipeDialogSupplier} from "../../../core/suppliers/gestionmenus/cmc-equipe-dialog-supplier";

@Component({
  selector: 'yo-cmc-equipes',
  templateUrl: './cmc-equipes.component.html',
  styleUrls: ['./cmc-equipes.component.scss']
})
export class CmcEquipesComponent implements OnInit, OnDestroy {

  subDeletedCmcEquipe: Subscription;
  subscriptionParentRoute: Subscription;
  subscriptionRoute: Subscription;
  subSavedCmcEquipe : Subscription;

  contratMenuConvive: ContratMenuConviveDTO;
  tacheFabricationList: TacheDTO[] = [];
  udpEquipeList: UniteDeProduction__EquipeDTO[] = [];

  totalRecords: number = 0;
  loading: boolean = false;

  moreDetails: boolean = true;

  cmcEquipeList: ContratMenuConvive__EquipeDTO[] = [];

  selectedRows: number[] = [];

  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'tacheLibelle', header: 'Types de fabrication'},
    {field: 'equipeLibelle', header: 'Équipes'},
    {field: 'repasLibelle', header: 'Repas' },
    {field: 'zoneDeProductionLibelle', header: 'Ateliers'},
    {field: 'uniteDeProductionLibelle', header: 'Unités de production'},
    {field: 'jours', header: `Jours du contrat affectés à l'équipe`},
    {field: 'actif', header: 'Actif'},
  ];

  pathFile: string = HELP_FOLDERS.CONTRATS_MENUS_CONVIVES + '/contrats-menus-convives-equipes';

  constructor(private route: ActivatedRoute,
              public cmcSvc: ContratsMenusConvivesService,
              public cmcEquipeSvc: ContratsMenusConvivesEquipesService,
              private routeMapSvc: RoutemapService,
              public auth2Svc: Auth2Service,
              public utils: UtilsService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService) {

  }

  ngOnInit() {

    this.subscriptionParentRoute = this.route.parent.data
      .subscribe((data: { contratMenuConviveSupplier: ContratMenuConviveSupplier }) => {
        this.contratMenuConvive = data.contratMenuConviveSupplier.contratMenuConvive;
      });

    this.subscriptionRoute = this.route.data
      .subscribe((data: { cmcEquipesSupplier: CmcEquipesSupplier }) => {
        this.udpEquipeList = data.cmcEquipesSupplier.udpEquipeList;
        this.tacheFabricationList = data.cmcEquipesSupplier.tacheFabricationList;
        this.cmcEquipeList = data.cmcEquipesSupplier.cmcEquipeList;
        this.totalRecords = this.cmcEquipeList ? this.cmcEquipeList.length : 0;
      });

    this.subSavedCmcEquipe = this.cmcEquipeSvc.savedDto$.subscribe(response=>{

      this.cmcEquipeList = this.gds.refreshDatagridPrimengAfterSave(this.cmcEquipeList,response,this.cmcEquipeSvc.getEntityName()) as ContratMenuConvive__EquipeDTO[];
      this.totalRecords = this.cmcEquipeList ? this.cmcEquipeList.length : 0;
    });

    this.subDeletedCmcEquipe = this.gfs.deletedId$.subscribe(response => {

      this.cmcEquipeList = this.gds.refreshDatagridPrimengAfterDelete(this.cmcEquipeList, response, this.cmcEquipeSvc.getEntityName()) as ContratMenuConvive__EquipeDTO[];
      this.totalRecords = this.cmcEquipeList ? this.cmcEquipeList.length : 0;
      // fix bug de fermeture de la sidenav lors d'une fermeture de dialogCompoent
      this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GCO_CMC,this.contratMenuConvive.contratMenuId, this.contratMenuConvive.id, 'equipes']);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionParentRoute);
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subDeletedCmcEquipe);
    this.utils.unsubscribe(this.subSavedCmcEquipe);
  }


  openEditDialog = (cmcEquipe: ContratMenuConvive__EquipeDTO) => {

    let title = '';
    let logo='';

    let creationLien: boolean;

    // Si c'est une nouvelle equipe à affecter
    if(this.utils.isNullOrEmpty(cmcEquipe)){

      creationLien = true;

      cmcEquipe =this.cmcEquipeSvc.createEmptyDTOWithCmc(this.contratMenuConvive);
      title = `Créer un lien type de fabrication / équipe`;
      logo = 'fa fa-plus';

    }else{
      creationLien = false;
      title = `${cmcEquipe.contratMenuConviveLibelle.toUpperCase()}, modification équipe "${cmcEquipe.equipeLibelle.toUpperCase()}"`;
      logo='fa fa-edit';
    }

    const cmcEquipeDialogSupplier = new CmcEquipeDialogSupplier();
    cmcEquipeDialogSupplier.title=title;
    cmcEquipeDialogSupplier.cmcEquipe=cmcEquipe;
    cmcEquipeDialogSupplier.udpEquipeList = this.udpEquipeList;
    cmcEquipeDialogSupplier.contratMenuConvive = this.contratMenuConvive;
    cmcEquipeDialogSupplier.logo = logo;

    if (creationLien) {
      cmcEquipeDialogSupplier.tacheFabricationList = this.getTacheFabricationDisponibles(this.tacheFabricationList, this.cmcEquipeList);
    } else {
      cmcEquipeDialogSupplier.tacheFabricationList = this.tacheFabricationList;
    }
    this.cmcEquipeSvc.announceDisplayCmcEquipeDialog(cmcEquipeDialogSupplier);
  };

  openUdpFabrication = idUniteDeProduction => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GUP_UPRO, idUniteDeProduction, 'zonesDeProduction']);
  };


  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Les équipes`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Les équipes paramétrées dans les unités de production sont affectées selon les types de fabrication.`,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };
    return dms;
  };

  canCreate = (): boolean => {
    let response  = this.auth2Svc.hasSitesLocaux() && this.udpEquipeList.length > 0;
    return response;
  };

  delete = (): void => {
    this.cmcEquipeSvc.delete(this.selectedRows)
      .subscribe((response) => {
        this.cmcEquipeList = this.cmcEquipeList.filter(item => !this.selectedRows.includes(item.id));
        this.selectedRows = [];
      });
  }

  /**
   * Renvoie la sous-liste de la liste de {@link TacheDTO}s passée en paramètre privée des {@link TacheDTO}s utilisés dans la liste de {@link ContratMenuConvive__EquipeDTO}.
   * @param tacheFabricationDTOListe
   * @param contratMenuConvive__EquipeDTOListe
   */
  getTacheFabricationDisponibles = (tacheFabricationDTOListe: TacheDTO[], contratMenuConvive__EquipeDTOListe: ContratMenuConvive__EquipeDTO[]): TacheDTO[] => {
   let response: TacheDTO[] = [];
    tacheFabricationDTOListe.forEach(tacheFabrication => {
      let utilise = false;
      contratMenuConvive__EquipeDTOListe.forEach(contratMenuConvive__EquipeDTO => {
        if (contratMenuConvive__EquipeDTO.tacheId === tacheFabrication.id) {
          utilise = true;
        }
      });
      if (!utilise) {
        response.push(tacheFabrication);
      }
    });
    return response;
  };
}
