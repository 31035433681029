import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {ProduitDTO} from '../../core/dtos/produit-dto';
import {UtilsService} from '../../core/utils/utils.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {ProduitsService, ProduitSupplier} from '../../core/services/entities/produits.service';
import {MenuItem} from 'primeng/api';
import {ItemNavigationService} from '../../core/services/technique/item-navigation.service';
import {TabMenu} from 'primeng/tabmenu';
import {Auth2Service} from "../../core/services/security/auth2.service";

@Component({
  selector: 'yo-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.scss']
})
export class ProduitComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  subDeletedProduitDeclinaison: Subscription;
  subActiveSecondaryRoute: Subscription;

  @ViewChild('tabMenu') tabMenu: TabMenu;

  produit: ProduitDTO;
  isFabrique: boolean;
  tabMenuProduit: MenuItem[] = [];
  activeTab: MenuItem;
  refresh = new Date().getTime();

  hasIDistri = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private gfs: GenericFormService,
              private itemNavSvc: ItemNavigationService,
              private titleSvc: Title,
              public produitsSvc: ProduitsService,
              private cd: ChangeDetectorRef,
              private authSvc: Auth2Service,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.initHasGestionIDistri();
    // Si pas de produit decli rattaché, on cache les onglets qui ont besoin des prdouits déclis
    this.deletedProduitDeclinaisonSubscription();

    // Chargement de la page, recup du produit à partir du resolver
    this.routeSubscription();

    this.activeSecondaryRouteSubscription();


  }

  initHasGestionIDistri = (): void => {
    this.authSvc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  /**
   * Si pas de produit decli rattaché, on cache les onglets qui ont besoin des prdouits déclis
   */
  deletedProduitDeclinaisonSubscription = () => {
    this.subDeletedProduitDeclinaison = this.gfs.deletedId$.subscribe(response => {

      if (!this.utils.isNullOrEmpty(this.produit) && !this.utils.isCollectionNullOrEmpty(this.produit.produitDeclinaisons)) {
        let arrProduitDecli = this.produit.produitDeclinaisons.filter(item => item.id != response.one);
        if (this.utils.isCollectionNullOrEmpty(arrProduitDecli)) {
          this.produit.produitDeclinaisons = [];
          this.initTaMenuProduit();
        }
      }
    });
  };

  /**
   * Chargement de la page, recup du produit à partir du resolver
   */
  routeSubscription = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { produitSupplier: ProduitSupplier }) => {
        this.produit = data.produitSupplier.produitDTO;
        this.isFabrique = data.produitSupplier.isFabrique;
        this.titleSvc.setTitle(this.produit && this.produit.libelle);
        this.initTaMenuProduit();
        this.refresh = new Date().getTime();
      });
  };

  private initTaMenuProduit = () => {


    this.tabMenuProduit = [];

    this.tabMenuProduit.push({label: `Fiche d'identité`, routerLink: ['ficheidentite']});

    //Si le produit existe
    if (this.utils.isNumberGt0(this.produit.id)) {
      this.tabMenuProduit.push({label: `Déclinaisons`, routerLink: ['produit-declinaisons']});

      if (!this.utils.isCollectionNullOrEmpty(this.produit.produitDeclinaisons)) {

        //on affiche les attributs du plat
        if (this.isFabrique) {

          this.tabMenuProduit.push({label: `Allergènes`, routerLink: ['allergenes']});
          this.tabMenuProduit.push({label: `Appellations`, routerLink: ['appellations']});
          this.tabMenuProduit.push({label: `Apports nutris`, routerLink: ['ciqual']});
          this.tabMenuProduit.push({label: `Gemrcn`, routerLink: ['gemrcn']});
          this.tabMenuProduit.push({label: `Fiche technique`, routerLink: ['fichetechnique']});
          this.tabMenuProduit.push({label: `Mode opératoire`, routerLink: ['mode-operatoire']});

          if (this.hasIDistri) {
            this.tabMenuProduit.push({label: `Conditionnement`, routerLink: ['conditionnement']});
          }

        }

        // on affiche les attributs de la denree
        else {
          this.tabMenuProduit.push({label: `Allergènes`, routerLink: ['allergenes']});
          this.tabMenuProduit.push({label: `Appellations`, routerLink: ['appellations']});
          this.tabMenuProduit.push({label: `Apports nutris`, routerLink: ['ciqual']});
          this.tabMenuProduit.push({label: `Articles`, routerLink: ['articles']});
          this.tabMenuProduit.push({label: `Marchés`, routerLink: ['marches']});
          this.tabMenuProduit.push({label: `Gemrcn`, routerLink: ['gemrcn']});
        }
      }
    }

    if (!this.activeTab) {
      this.activeTab = this.tabMenuProduit[0];
    }

  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subDeletedProduitDeclinaison);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  }

  libelleProduit = (produit: ProduitDTO): string => {
    let response = produit.libelle;
    return response;
  };


  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.activeTab = this.itemNavSvc.activeSecondaryRoute(event, this.tabMenuProduit);
    });

  };

}
