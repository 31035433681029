import {Component, OnDestroy, OnInit} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UtilsService} from '../../core/utils/utils.service';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {catchError, switchMap} from 'rxjs/operators';
import {MenuItem} from 'primeng/api';
import {ItemNavigationService} from '../../core/services/technique/item-navigation.service';

@Component({
  selector: 'yo-unite-production',
  templateUrl: './unite-production.component.html',
  styleUrls: ['./unite-production.component.scss']
})
export class UniteProductionComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  subActiveSecondaryRoute: Subscription;
  uniteDeProduction: UniteDeProductionDTO;
  tabMenuUdp: MenuItem[] = [];
  tabSelectedMenuUdp: MenuItem;

  constructor(private route: ActivatedRoute,
              private titleSvc: Title,
              private auth2Svc: Auth2Service,
              private itemNavSvc: ItemNavigationService,
              private utils: UtilsService) {
  }


  ngOnInit() {

    this.routeSubscription();

    // fix kjlnhjk tabmenu route
    this.activeSecondaryRouteSubscription();
  }

  routeSubscription() {
    this.subscriptionRoute = this.route.data.pipe(
      switchMap(data => {

        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        this.titleSvc.setTitle(this.uniteDeProduction && this.uniteDeProduction.libelle);
        return this.auth2Svc.hasUniteDeProduction$;
      }),
      switchMap(data => {
        this.initTabMenuUdp();

        return of(null);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe();
  }

  initTabMenuUdp() {
    this.tabMenuUdp = [];

    if (this.utils.isNumberGt0(this.uniteDeProduction.id)) {

      this.auth2Svc.hasUniteDeProduction$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Fiche d'identité`, routerLink: ['ficheidentite']});
        }
      });
      this.auth2Svc.hasUniteDeProductionLiv$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Livraisons`, routerLink: ['livraisons']});
        }
      });
      this.auth2Svc.hasUniteDeProductionArticlesPreferes$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Articles préférés`, routerLink: ['articles-preferes/grille']});
        }
      });
      this.auth2Svc.hasUniteDeProductionEquipes$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Equipes`, routerLink: ['equipes']});
        }
      });

      this.auth2Svc.hasUniteDeProductionStocks$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Stocks`, routerLink: ['zonesDeStockage']});
        }
      });
      this.auth2Svc.hasUniteDeProductionDenreesInterdites$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Déclinaisons interdites`, routerLink: ['denreesInterdites', 'grille']});
        }
      });

      this.auth2Svc.hasGestionIDistri$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Conditionnement`, routerLink: ['conditionnement']});
        }
      });
      this.auth2Svc.hasUniteDeProduction$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Incohérences`, routerLink: ['incoherences']});
        }
      });
      this.auth2Svc.hasUniteDeProduction$.subscribe((response: boolean) => {
        if (response) {
          this.tabMenuUdp.push({label: `Déclinaisons sans appro`, routerLink: ['produits-declinaisons-non-approvisionnes']});
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  activeSecondaryRouteSubscription() {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.tabSelectedMenuUdp = this.itemNavSvc.activeSecondaryRoute(event, this.tabMenuUdp);
    });

  }

}
