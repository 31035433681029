import {ApportNutritionnelDTO} from "./apport-nutritionnel-dto";

export class ProduitApportNutritionnelDTO {

  id: number;
  idProduitDecline: number;
  idApportNutritionnel: number;
  quantite: number;
  teneur: number;

  //apport nutritionnel
  apportNutritionnel: ApportNutritionnelDTO;

  //produit declinaison
  produitDeclinaisonLibelle: string;

  // theConst:ConstDTO;
}
