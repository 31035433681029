import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {GenericHandler} from '../generics/generic-handler';
import {TextureDTO} from '../../dtos/texture-dto';
import {shareReplay} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TextureService  extends GenericHandler<TextureDTO>  {

  texturesEnvironnement:TextureDTO[]=[];

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  createEmptyDTO = (): TextureDTO => undefined;

  getAllFromEnvironnement = (): void => {
    this.gds.getAll(this.getEntityName(), this.getSort(), true).pipe(
      shareReplay())
      .subscribe(response => {
        this.texturesEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.texturesEnvironnement = response.resultList;
        }

        this.announceObjects(this.texturesEnvironnement);
      });


  };

  getCreateNewObjectLabel = (): string => "CRÉER UNE TEXTURE";

  getEditObjectLabel = (data: TextureDTO): string => `MODIFIER LA TEXTURE '${data.libelle.toUpperCase()}'`;

  getEntityName = (): string => "Texture";

  getFields = (dto: TextureDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new TextureDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites,dto.site),
        order: 1,
        options: this.optionsSites
      }),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);

  };

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => true;

  getSort = (): string[] => ['code,asc'];

  getTitle = (): string => "GESTION DES TEXTURES";

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());
}
