import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {FormControl, FormGroup} from "@angular/forms";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";
import {RegimeAlimentaireService} from "../../../core/services/entities/regime-alimentaire.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-regimeAlimentaireDialogEdition',
  templateUrl: './regime-alimentaire-dialog-edition.component.html',
  styleUrls: ['./regime-alimentaire-dialog-edition.component.scss']
})
export class RegimeAlimentaireDialogEditionComponent implements OnInit, OnDestroy {

  dialogTitle: String = 'Modification d\'un régime alimentaire';

  displayDialog = false;

  form: FormGroup = new FormGroup({});

  idSelectedSite: any;

  regimeAlimentaire: RegimeAlimentaireDTO;

  subOpenDialog: Subscription

  selectedRows: number[] = [];

  sitePlaceholder = "Selectionner le site...";

  constructor(
    private regimeAlimentaireSvc: RegimeAlimentaireService,
    private toastSvc: ToastService,
    private utilsSvc: UtilsService,
    private auth2Svc: Auth2Service
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  save = (): void => {
    if (this.form.valid) {
      let newRegimeAlimentaire: RegimeAlimentaireDTO = {...this.regimeAlimentaire};
      newRegimeAlimentaire.site = {id: this.idSelectedSite} as SiteDTO;
      newRegimeAlimentaire.libelle = this.form.controls['libelle'].value;
      newRegimeAlimentaire.actif = this.form.controls['actif'].value;
      newRegimeAlimentaire.code = this.form.controls['code'].value;
      this.regimeAlimentaireSvc.save(newRegimeAlimentaire)
        .subscribe(response => {
          const isUpdate = newRegimeAlimentaire.id !== null && newRegimeAlimentaire.id !== undefined && newRegimeAlimentaire.id !== 0;
          this.regimeAlimentaireSvc.announceRegimeAlimentaireSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du régime alimentaire réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.regimeAlimentaireSvc.openDialogEdition$
      .subscribe((regimeAlimentaire: RegimeAlimentaireDTO) => {
        this.displayDialog = true;
        if (!regimeAlimentaire) {
          this.regimeAlimentaire = new RegimeAlimentaireDTO();
          this.regimeAlimentaire.id = 0;
          this.dialogTitle = 'Création d\'un régime alimentaire';
        } else {
          this.regimeAlimentaire = regimeAlimentaire;
          this.dialogTitle = 'Modification d\'un régime alimentaire';
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.regimeAlimentaire?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.regimeAlimentaire?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.regimeAlimentaire?.libelle),
      actif: new FormControl(this.regimeAlimentaire?.actif != null ? this.regimeAlimentaire.actif : true),
      code: new FormControl(this.regimeAlimentaire?.code),
    });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => {
    return this.regimeAlimentaireSvc.canModify(this.regimeAlimentaire);
  }
}
