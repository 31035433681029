import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {TypeProduitDTO} from '../../dtos/type-produit-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";


@Injectable()
export class TypesProduitService extends GenericHandler<TypeProduitDTO> {

  typeProduitList : TypeProduitDTO[];

  private subjectOpenDialogEdition: Subject<TypeProduitDTO> = new Subject<TypeProduitDTO>();

  openDialogEdition$: Observable<TypeProduitDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectTypeProduitSaved: Subject<TypeProduitDTO> = new Subject<TypeProduitDTO>();

  typeProduitSaved$: Observable<any> = this.subjectTypeProduitSaved.asObservable();

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['code,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES TYPES DE PRODUIT';

  getCreateNewObjectLabel = (): string => 'CRÉER UN TYPE DE PRODUIT';

  getEntityName = (): string => 'TypeProduit';

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient,title:Title, private  gds:GenericDatagridService, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http,title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/typesproduits/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  getFields = (dto: TypeProduitDTO): FormFieldBaseSupplier<any>[] => {

    // Par défaut un TypeProduit est actif.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new TypeProduitDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 1,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 3
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 1,
        maxLength: 50,
        value: dto.code,
        required: true,
        order: 5
      }),

      new FormFieldCheckboxSupplier({
        key: 'fabrique',
        label: 'Est fabriqué ?',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.fabrique,
        required: false,
        order: 6
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 8
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 9
      }),
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getAllFromEnvironnement = (): void => {
    this.gds.getAll(this.getEntityName(), ['code,asc'], true)
      .subscribe(response => {
        this.typeProduitList = [];
        this.typeProduitList = response.resultList;
        this.announceObjects(this.typeProduitList);
      });
  };

  /**
   * Récupérer les types produits selon que c'est une denrée ou un plat
   * @param typeProduitList
   * @param isFabrique 0=denree, 1=plat
   * @return {any[]}
   */
  getTypesProduits = (typeProduitList:TypeProduitDTO[], isFabrique:boolean) => {

    let typesProduits = [];
    if(!this.utils.isCollectionNullOrEmpty(typeProduitList)){
      const arr= typeProduitList.filter(item=>item.fabrique===isFabrique)
      if(!this.utils.isCollectionNullOrEmpty(arr)){
        typesProduits = arr;
      }
    }
    return typesProduits;
  };

  createEmptyDTO = (): TypeProduitDTO => new TypeProduitDTO();

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE TYPE DE PRODUIT '${data.libelle.toUpperCase()}'`;

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/typesproduits/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/typesproduits/import", formData, null);
  }

  announceTypeProduitSaved = (): void => {
    this.subjectTypeProduitSaved.next(null);
  }

  announceOpeningEditionDialog = (typeProduit: TypeProduitDTO): void => {
    this.subjectOpenDialogEdition.next(typeProduit);
  }

  save = (typeProduit: TypeProduitDTO): Observable<ResponseWrapper<TypeProduitDTO>> => {
    return this.httpSvc.post(`dolrest/typesproduits/save`, typeProduit);
  }

}
