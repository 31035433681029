<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="dialog-line">
      <div class="label" title="Site">Site <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idSelectedSite"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="true"
          [placeholder]="sitePlaceholder"
          (onSelectionChanged)="onChangeSite($event)"
          [disabled]="!canModify()"
        >
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Libellé" formControlName="libelle"
                     [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="actif" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Bio">Bio <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="bio" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Durable">Durable <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="durable" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div id="dropzone"
         [class]="'border-like-dx' + (isDropZoneActive ? ' file-over' : '')">
      <div class="appellation-image-uploader">
        <input id="fileInput"
               type="file"
               accept="image/*"
               (change)="onImageSelected($event)"
               hidden>
        <label for="fileInput">Sélectionner une image</label>
        <span>{{this.selectedFile ? this.selectedFile.name : 'Sélectionnez une image ou glissez / déposez la'}}</span>
      </div>

      <div class="centered-items">
        <div class="image-label-container">
          <div [class]="'image-container' + (this.isCurrentImageInterractable ? ' loaded' : '')">
            <img class="border-like-dx"
                 draggable="false"
                 [src]="currentFileUrl"
                 title="Aucune image"
                 (load)="onCurrentImageLoaded($event)"
                 (error)="appellationsSvc.onImageError($event)">
            <div class="fas fa-trash fa-3x centered-items"
                 title="Supprimer l'image actuelle"
                 (click)="deleteCurrentImage()"
            ></div>
          </div>
          <span class="image-legend">Image actuelle</span>
        </div>
        <div id="animatedContent"
             [class]="'centered-items animate hidden' + (displayNewImage ? '' : ' hide')">
          <i class="fas fa-arrow-right fa-2x spaced-image"></i>
          <div class="image-label-container">
            <div class="image-container loaded">
              <img class="border-like-dx"
                   draggable="false"
                   [src]="selectedFileUrl"
                   (error)="appellationsSvc.onImageError($event)">
              <div class="fas fa-times-circle fa-3x centered-items"
                   title="Annuler la modification"
                   (click)="cancelSelectedImage()"
              ></div>
            </div>
            <span class="image-legend ">Nouvelle image</span>
          </div>
        </div>
      </div>
      <div class="dropzone-overlay">
        <span>Glissez / déposez l'image ici</span>
      </div>
    </div>

    <div class="d-flex mg-t-10 justify-content-end">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        tooltip="Enregistrer"
        buttonClass="cta-success"
        [hidden]="!canModify()">
      </yo-button>
      <yo-button
        class="mg-l-5"
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        tooltip="Fermer"
        iconClass="fas fa-times">
      </yo-button>
    </div>
  </form>

</dx-popup>
