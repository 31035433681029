import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {ContratsMenusConvivesService} from '../../core/services/gestioncontrats/contrats-menus-convives.service';
import {ContratMenuService} from '../../core/services/gestioncontrats/contrat-menu.service';

@Injectable()
export class ContratmenuConviveResolverService implements Resolve<ContratMenuConviveSupplier> {

  constructor(private gds: GenericDatagridService,
              private contratMenuSvc: ContratMenuService,
              private cmcSvc: ContratsMenusConvivesService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContratMenuConviveSupplier> | Promise<ContratMenuConviveSupplier> | ContratMenuConviveSupplier => {

    const cmcSupplier = new ContratMenuConviveSupplier();

    const idContratMenu: number = +route.paramMap.get('idContratMenu');
    const idContratMenuConvive: number = +route.paramMap.get('idContratMenuConvive');

    const cmc$ = this.gds.getOne(this.cmcSvc.getEntityName(), idContratMenuConvive);
    const cmcContraintesAlim$ = this.gds.search(this.cmcSvc.filterCmcContrainteAlim(idContratMenuConvive));

    // si modification
    if (idContratMenuConvive > 0) {

      const all$ = combineLatest([cmc$,cmcContraintesAlim$]);
      return all$.toPromise().then(response => {
        const cmc = response[0].one ;
        cmcSupplier.contratMenuConvive = cmc;
        cmcSupplier.contratMenuConvive.contratMenuConvive__contrainteAlimList=response[1].resultList;
        return cmcSupplier;
      });
    }
    // si création
    else {
      return this.gds.getOne( this.contratMenuSvc.getEntityName(), idContratMenu)
        .toPromise()
        .then(response => {
          const cmc = this.cmcSvc.createEmptyDTOWithContratMenu(response.one);
          cmcSupplier.contratMenuConvive = cmc;

          return cmcSupplier;
        });
    }

  };

}

export class ContratMenuConviveSupplier {
  contratMenuConvive: ContratMenuConviveDTO;
}
