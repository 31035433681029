import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FormControl, FormGroup} from '@angular/forms';
import {combineLatest, Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {FamillesProduitService} from '../../../../core/services/entities/familles-produit.service';
import {TypesProduitService} from '../../../../core/services/entities/types-produit.service';
import {FamilleProduitDTO} from '../../../../core/dtos/famille-produit-dto';
import {TypeProduitDTO} from '../../../../core/dtos/type-produit-dto';
import {catchError, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../../core/suppliers/search-supplier';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {FichetechniqueService} from '../../../../core/services/gestionproduits/fichetechnique.service';
import {DeclinaisonsService} from '../../../../core/services/entities/declinaisons.service';
import {DeclinaisonDTO} from '../../../../core/dtos/declinaison-dto';
import {AllergenesService} from '../../../../core/services/entities/allergenes-service.service';
import {AppellationsService} from '../../../../core/services/entities/appellations.service';
import {AllergeneDTO} from '../../../../core/dtos/allergene-dto';
import {AppellationDTO} from '../../../../core/dtos/appellations-dto';
import {ObjectDTO} from '../../../../core/dtos/object-dto';
import {Sort} from '../../../../core/suppliers/generics/generic-request-supplier';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {cloneDeep as _cloneDeep} from 'lodash'
import {ProduitDTO} from '../../../../core/dtos/produit-dto';
import {PREDICAT_DIR} from "../../../../core/constants";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-ft-search-panel',
  templateUrl: './ft-search-panel.component.html',
  styleUrls: ['./ft-search-pane.component.scss']
})
export class FtSearchPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;

  subInit: Subscription;
  subForm: Subscription;

  produitsDeclinaisons: ProduitDeclinaisonDTO[] = [];
  familles: FamilleProduitDTO[] = [];
  types: TypeProduitDTO[] = [];
  allergenes: AllergeneDTO[] = [];
  appellations: AppellationDTO[] = [];
  declinaisons: DeclinaisonDTO[] = [];
  totalElements = 0;

  // prix achat
  minPrixAchat = 0;
  maxPrixAchat = 100;

  // tri
  sort: Sort;
  fieldName: string;
  order: number;

  // recherche
  ssw: SearchSupplierWrapper;

  // panier
  displayCart: boolean;
  produitsDeclinaisonsInCart: ProduitDeclinaisonDTO[] = [];

  selectedProduitsDeclinaisons: ProduitDeclinaisonDTO[] = [];


  /**
   * Le {@ProduitDTO} auquel on veut ajouter des composants.
   */
  @Input() produit: ProduitDTO;
  @Input() udpId :number;

  selectedView: string = 'cout';

  cols: any[] = [
    {field: 'selection', header: ''},
    {field: 'libelle', header: 'Composant'},
    {field: 'declinaison', header: 'Déclinaison'},
    {field: 'typeProduitLibelle', header: 'Type'},
    {field: 'familleProduitLibelle', header: 'Famille'},
    {field: 'prixAchat', header: `Prix d'achat`},
    {field: 'prixAchatUniversel', header: 'Prix d\'achat (Unité universelle)' }
  ];

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private ficheTechSvc: FichetechniqueService,
              private familleProduitSvc: FamillesProduitService,
              public allergenesSvc: AllergenesService,
              public appellationsSvc: AppellationsService,
              private declinaisonsSvc: DeclinaisonsService,
              private typeProduitSvc: TypesProduitService,
              private graphQlSvc: GraphQLService) {
  }

  initForm() {
    this.form = new FormGroup({
      libelle: new FormControl(''),
      types: new FormControl(undefined),
      declinaisons: new FormControl(undefined),
      familles: new FormControl(undefined),
      allergenes: new FormControl(undefined),
      appellations: new FormControl(undefined),
      prixAchatRange: new FormControl([this.minPrixAchat, this.maxPrixAchat])
    });
  }

  ngOnInit() {
    this.initForm();
    this.sort = this.createEmptySort();
  }

  ngAfterViewInit(): void {

    this.produitsDeclinaisons = [];

    // INIT DES FAMILLES ET TYPES
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    const familleProduitList$ = this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true);
    const typeProduitList$ = this.gds.getAll(this.typeProduitSvc.getEntityName(), this.typeProduitSvc.getSort(), true);
    const declinaisons$ = this.graphQlSvc.sendQuery(`
      {
          allDeclinaisons(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle
          }
      }
    `);
    const allergenes$ = this.graphQlSvc.sendQuery(`
      {
        allAllergenes(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            appellationInformationConsommateur,
            actif,
            site {
                id,
                libelle,
            },
        }
      }
    `);
    const appellations$ = this.graphQlSvc.sendQuery(`
      {
        allAppellations(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            durable,
            bio,
            site {
                id,
                libelle,
            },
        }
      }
    `);

    const allInitList$ = combineLatest([familleProduitList$, typeProduitList$, declinaisons$, allergenes$, appellations$]);

    this.utils.unsubscribe(this.subInit);
    this.subInit = allInitList$.pipe(
      switchMap(response=>{

        this.familles = response[0].resultList;

        if (!this.utils.isCollectionNullOrEmpty(this.familles)) {

          // enlever tous les sous niveaux de famille
          // enlever les familles winapro incoherentes A000...
          this.familles = this.familleProduitSvc.removeChildren(this.familles);


        }

        this.types = response[1].resultList;

        this.declinaisons = response[2].allDeclinaisons;

        this.allergenes = response[3].allAllergenes;

        this.appellations = response[4].allAppellations;

        this.ssw = this.getSearchFilters(undefined);

        // recherche par défaut
        return this.udpId ? this.ficheTechSvc.searchProduitDeclinaisonList(this.ssw, this.udpId) : [];

      }),
      catchError(err=>this.utils.handleError(err))
    ).subscribe(response=>this.updateSearchResponse(response));

    // LA RECHERCHE EST LANCÉE LORSQU'UN DES ÉLÉMENTS DU FORMULAIRE EST MODIFIÉ
    this.utils.unsubscribe(this.subForm);
    this.subForm = this.form.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),

      //launch http request
      switchMap(values => {
        this.ssw = this.getSearchFilters(values);

        return this.udpId ? this.ficheTechSvc.searchProduitDeclinaisonList(this.ssw, this.udpId) : [];
      }),
      //catch error if any
      catchError(error => this.utils.handleError(error)),)
      .subscribe(response => {

        this.updateSearchResponse(response);

      });
  }

  getSearchFilters = (values: any) => {

    const ssw = this.initSearchSupplier(undefined);

    // récuperation de unite de production id
    ssw.filtersMap['udpId'] = new SearchSupplier(this.udpId, undefined, undefined, undefined);

    if (!this.utils.isNullOrEmpty(values)) {

      //filters for backend fulltext search
      ssw.filtersMap['fullTextProduitDecli'] = new SearchSupplier(values.libelle, undefined, 'sortLibelle', 'string');


      ssw.filtersMap['libelle'] = new SearchSupplier(values.libelle, undefined, 'sortLibelle', 'string');

      const declinaisons = this.utils.isCollectionNullOrEmpty(values.declinaisons) ? [] : values.declinaisons;
      ssw.filtersMap['idsDeclinaison'] = new SearchSupplier(undefined, declinaisons.map(d => d.id), 'declinaison.sortLibelle', 'string');

      const types = this.utils.isCollectionNullOrEmpty(values.types) ? [] : values.types;
      ssw.filtersMap['idsTypeProduit'] = new SearchSupplier(undefined, types.map(t => t.id), 'produit.typeProduit.sortLibelle', 'string');


      const familles = this.utils.isCollectionNullOrEmpty(values.familles) ? [] : values.familles;
      ssw.filtersMap['idsFamilleProduit'] = new SearchSupplier(undefined, familles.map(f => f.id), 'produit.familleProduit.sortLibelle', 'string');


      const allergenes = this.utils.isCollectionNullOrEmpty(values.allergenes) ? [] : values.allergenes;
      ssw.filtersMap['idsAllergene'] = new SearchSupplier(undefined, allergenes.map(al => al.id), 'produitAllergeneList.allergene.sortLibelle', 'string');

      const appellations = this.utils.isCollectionNullOrEmpty(values.appellations) ? [] : values.appellations;
      ssw.filtersMap['idsAppellation'] = new SearchSupplier(undefined, appellations.map(ap => ap.id), 'produitAppellationList.appellation.sortLibelle', 'string');


      if (!this.utils.isCollectionNullOrEmpty(values.prixAchatRange)) {
        //force float values with + 0.00001 and - 0.00001

        let prixMin = -0.0001;
        if (values.prixAchatRange[0] > 0) {
          prixMin = values.prixAchatRange[0] - 0.0001;
        }

        ssw.filtersMap['prixMin'] = new SearchSupplier(prixMin, undefined, undefined, undefined);
        ssw.filtersMap['prixMax'] = new SearchSupplier(+values.prixAchatRange[1] + 0.00001, undefined, undefined, undefined);
      }


      //sort
      if (!this.utils.isNullOrEmpty(this.sort)) {
        ssw.filtersMap['sortDir'] = new SearchSupplier(this.sort.dir, undefined, this.sort.path, this.sort.luceneSortType);
        ssw.filtersMap['sortField'] = new SearchSupplier(this.sort.path, undefined, this.sort.path, this.sort.luceneSortType);
      }

    }

    return ssw;

  };

  updateSearchResponse = (response: ResponseWrapper<ProduitDeclinaisonDTO>) => {

    if (!this.utils.isResponseSupplierError(response)) {
      // Récupération des ProduitDeclinaisonDTO
      let produitsDeclinaisons: ProduitDeclinaisonDTO[] = response.resultList;

      // Suppression des ProduitDeclinaisonDTO issus du Produit this.produit :
      // Anomalie #1166 version : [master]v1.0.1-2315(87517db5c)-24/04/2019-13:34:18 : bloquer en recherche composant d'une FT la FT elle même
      if(!this.utils.isNullOrEmpty(this.produit) && produitsDeclinaisons && produitsDeclinaisons.length){
        this.produitsDeclinaisons = produitsDeclinaisons.filter(pd => pd.produitId != this.produit.id);
      } else {
        this.produitsDeclinaisons = [];
      }

      this.selectedProduitsDeclinaisons = [];
      this.totalElements = response.totalElements;
    }
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subInit);
    this.utils.unsubscribe(this.subForm);
  }

  /**
   * Ajouter les composants du panier à la recette
   * @param selectedProduitsDeclinaisons
   */
  addProduitDecliInCartToRecette = () => {

    if (!this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)) {

      this.produitsDeclinaisonsInCart.forEach(pd => this.ficheTechSvc.announceSelectedProduitFromSearch(pd));
      this.eraseCart();
    }

  };

  /**
   * Afficher le panier
   */
  showCart = () => {
    this.displayCart = true;

  };

  onRowSelect = ($event: any) => {

    if (this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)) {
      this.produitsDeclinaisonsInCart = [];
    }
    // on ajoute l'element du panier
    const pd: ProduitDeclinaisonDTO = $event.data;
    this.produitsDeclinaisonsInCart.unshift(pd);
  };


  onRowUnselect = ($event: any) => {
    // on enleve l'element du panier
    if (!this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)) {
      this.produitsDeclinaisonsInCart = this.produitsDeclinaisonsInCart.filter(pd => pd.id != $event.data.id);
    }
  };

  /**
   * Enlever un produit du panier
   * @param pdInCart
   */
  removeProduitDeclinaisonInCart = (pdInCart: ProduitDeclinaisonDTO) => {

    // on enleve l'element du panier
    if (!this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)) {
      this.produitsDeclinaisonsInCart = this.produitsDeclinaisonsInCart.filter(pd => pd.id != pdInCart.id);

    }

    if (!this.utils.isCollectionNullOrEmpty(this.selectedProduitsDeclinaisons)) {
      this.selectedProduitsDeclinaisons = this.selectedProduitsDeclinaisons.filter(pd => pd.id != pdInCart.id);
    }

  };

  /**
   * Vider le panier
   */
  eraseCart = () => {
    this.produitsDeclinaisonsInCart = [];
    this.selectedProduitsDeclinaisons = [];
    this.displayCart = false;
  };

  onHideCart = () => {
    this.displayCart = false;
  };

  onHideAjoutComposants = () => {
    this.ssw = this.initSearchSupplier(undefined);
    this.ficheTechSvc.announceCloseAjoutComposants();
  };

  getActifs = (pas: ObjectDTO[], property: string) => {

    const arr = pas.filter(pa => pa.actif);
    if (!this.utils.isCollectionNullOrEmpty(arr)) {
      return arr.map(pa => pa[property]).join(', ');
    }
    return '';
  };


  /**
   * event.field: Field name of the sorted column
   * event.order: Sort order as 1 or -1
   * event.multisortmeta: Sort metadata in multi sort mode. See multiple sorting section for the structure of this object.
   * @param $event
   */
  onSort = $event => {


    // eviter de boucler sur le onSort
    if (this.fieldName != $event.field || this.order != +$event.order) {

      this.fieldName = _cloneDeep($event.field);
      this.order = _cloneDeep($event.order);

      const colField = $event.field;
      const dir = $event.order === 1 ? 'asc' : 'desc';
      let path = 'sortLibelle';
      let lucenesortType = 'string';

      if (colField === 'libelle') {
        path = 'sortLibelle';
      } else if (colField === 'declinaison') {
        path = 'declinaison.sortLibelle';
      } else if (colField === 'typeProduitLibelle') {
        path = 'produit.typeProduit.sortLibelle';
      } else if (colField === 'familleProduitLibelle') {
        path = 'produit.familleProduit.sortLibelle';
      } else if (colField === 'allergene') {
        path = 'produitAllergeneList.allergene.sortLibelle';
      } else if (colField === 'appellation') {
        path = 'produitAppellationList.appellation.sortLibelle';
      } else if (colField === 'prixAchat') {
        path = 'sortPrixAchatLucene';
        lucenesortType = 'float';
      } else if (colField === 'prixAchatUniversel') {
        path = 'prixAchatUniversel';
      }

      this.sort.path = path;
      this.sort.dir = dir;
      this.sort.luceneSortType = lucenesortType;

      this.ssw.filtersMap['sortDir'] = new SearchSupplier(this.sort.dir, undefined, this.sort.path, this.sort.luceneSortType);
      this.ssw.filtersMap['sortField'] = new SearchSupplier(this.sort.path, undefined, this.sort.path, this.sort.luceneSortType);

      this.ficheTechSvc.searchProduitDeclinaisonList(this.ssw, this.udpId).pipe(
        catchError(error => this.utils.handleError(error))
      )
        .subscribe(response => {
          this.updateSearchResponse(response);
        });


    }


  };

  /**
   * On créé un nouveau search supplier s'il n'existe pas deja
   */
  initSearchSupplier = (ssw:SearchSupplierWrapper): SearchSupplierWrapper => {

    if (this.utils.isNullOrEmpty(ssw)) {

      ssw = new SearchSupplierWrapper();

      this.sort = this.createEmptySort();

      //sort
      if (!this.utils.isNullOrEmpty(this.sort)) {
        ssw.filtersMap['sortDir'] = new SearchSupplier(this.sort.dir, undefined, this.sort.path, this.sort.luceneSortType);
        ssw.filtersMap['sortField'] = new SearchSupplier(this.sort.path, undefined, this.sort.path, this.sort.luceneSortType);
      }


      //page size
      ssw.filtersMap['pageSize'] = new SearchSupplier(100, undefined, undefined, undefined);
      let siteIds: number[] = []; // this.auth2Svc.utilisateur.sites
      this.auth2Svc.utilisateur.sites.forEach(site => siteIds.push(site.id));
      ssw.filtersMap['sites'] = new SearchSupplier(undefined, siteIds, undefined, undefined);

    }
    return ssw;
  };

  createEmptySort = (): Sort => {

    const sort = new Sort();

    sort.path = 'sortLibelle';
    sort.dir = PREDICAT_DIR.Ascendant;;
    sort.luceneSortType = 'string';

    return sort;
  };

}
