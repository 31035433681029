import {Injectable} from '@angular/core';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {FamillesGemrcnService} from '../../../core/services/entities/familles-gemrcn.service';
import {ModeleNutritionnelDetailDTO} from '../../../core/dtos/modele-nutritionnel-detail-dto';
import {RegleGemrcnSupplier} from '../../../shared/ui/regle-gemrcn/regle-gemrcn-supplier';
import {ModeleNutritionnelGroupeGemrcnDTO} from '../../../core/dtos/modele-nutritionnel-groupe-gemrcn-dto';
import {ModeleNutritionnelGroupeGemrcnService} from '../../../core/services/entities/modele-nutritionnel-groupe-gemrcn.service';
import {uniqBy as _uniqBy} from 'lodash'
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Injectable({
  providedIn: 'root'
})
export class ModeleNutriResolverService implements Resolve<ModeleNutri2Supplier> {

  constructor(private gds: GenericDatagridService,
              private famillesGemrcn: FamillesGemrcnService,
              private mnGroupeGemrcnSvc: ModeleNutritionnelGroupeGemrcnService,
              private utils: UtilsService,
              private ggSvc: GestionGemrcnService,
              private graphQlSvc: GraphQLService,
              private auth2Svc: Auth2Service) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModeleNutri2Supplier> | Promise<ModeleNutri2Supplier> | ModeleNutri2Supplier => {
    const vns = new ModeleNutri2Supplier();
    const idModeleNutri: number = +route.parent.paramMap.get('idModeleNutri');

    const famillesGemrcn$ = this.getFamillesGemrcn();
    const grilleGemrcn$ = this.gds.search(this.ggSvc.filterGrilleGemrcn(idModeleNutri));
    const groupesGemrcn$ = this.gds.search(this.mnGroupeGemrcnSvc.filterByIdModeleNutritionnel(idModeleNutri));
    const prepareDialogAddGroupeGemrcn$ = this.mnGroupeGemrcnSvc.prepareDialogAddGroupeGemrcn(idModeleNutri);
    const controlesGemrcn$ = this.ggSvc.controleGemrcnPlanAlimentaire(idModeleNutri);

    const initAll$ = combineLatest([famillesGemrcn$, grilleGemrcn$, prepareDialogAddGroupeGemrcn$, groupesGemrcn$, controlesGemrcn$]);
    return initAll$.toPromise().then(response => {
      vns.famillesGemrcn = response[0].allFamillesGemrcn;
      vns.grilleGemrcn = response[1].resultList;
      vns.nbJoursSemaine = response[2].additionalProperties['nbJoursSemaine'];
      vns.nbSemaines = response[2].additionalProperties['nbSemaines'];
      vns.groupesAnalyseGemrn = response[3].resultList;
      // preselectionner les familles gemrcn de la grille, ...
      vns.selectedFamillesGemrcn = this.preselectFamillesGemrcn(vns.grilleGemrcn);
      // recuperer les regles gemrcn (la grille gemrcn doivent avoir été récupérées au préalable)
      vns.reglesGemrcn = this.ggSvc.getReglesGemrcn(vns.grilleGemrcn, vns.groupesAnalyseGemrn);
      // récupérer les controles gemrcn
      vns.controlesGemrcn = this.ggSvc.getControlesGemrcn(response[4].resultList);
      return vns;
    });
    return undefined;
  };

  getFamillesGemrcn = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allFamillesGemrcn(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            ordre,
            couleur {
              hexa,
              libelle
            }
            site {
              id,
              libelle
            }
        }
      }
    `)
  }

  preselectFamillesGemrcn = (grilleGemrcn: ModeleNutritionnelDetailDTO[]) => {
    let famillesGemrcn: FamilleGemrcnDTO[] = [];
    if (!this.utils.isCollectionNullOrEmpty(grilleGemrcn)) {
      for (let row of grilleGemrcn) {
        famillesGemrcn.push(row.familleGemrcn);
      }
    }
    famillesGemrcn = _uniqBy(famillesGemrcn, 'id');
    return famillesGemrcn;
  };
}

export class ModeleNutri2Supplier {
  famillesGemrcn: FamilleGemrcnDTO[] = [];
  grilleGemrcn: ModeleNutritionnelDetailDTO[] = [];
  selectedFamillesGemrcn: FamilleGemrcnDTO[] = [];
  reglesGemrcn: Map<number, RegleGemrcnSupplier> = new Map();
  controlesGemrcn: Map<number, RegleGemrcnSupplier> = new Map();
  groupesAnalyseGemrn: ModeleNutritionnelGroupeGemrcnDTO[] = [];
  nbJoursSemaine: number;
  nbSemaines: number;
}

