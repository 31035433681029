import {ObjectDTO} from "./object-dto";

export class AllergeneDTO  extends ObjectDTO{

  // allergene
  code: string;
  libelle: string;
  appellationInformationConsommateur: string;
  actif:boolean;

}
