import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';

import {OrigineDTO} from "../../dtos/origine-dto";
import {GenericRequestSupplier, Predicat, Search, Sort} from "../../suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../technique/devextreme.service";
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../constants";
import {Observable, Subject} from "rxjs";
import {FormFieldDropdownSupplierSiteDTO} from "../../suppliers/form-field-dropdown-supplier-siteDTO";
import {HttpService} from "../technique/http.service";


export enum FK_ORIGINES {
  site = 'site',
  libelle = 'libelle',
  code = 'code',
  actif = 'actif'
}

export const URL_POST_FIND_ORIGINES_WITH_PARAMS = `dolrest/referentiel/origine/find-all-with-params`;
export const URL_POST_SAVE_ORIGINE = `dolrest/referentiel/origine/save-origine`;
export const URL_DELETE_ORIGINE = `dolrest/referentiel/origine/delete-origine`;

@Injectable({
  providedIn: 'root'
})
export class OrigineService extends GenericHandler<OrigineDTO> {


  constructor(utils: UtilsService,
              private httpSvc : HttpService,
              private gds: GenericDatagridService,
              private dxSvc: DevextremeService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  /**
   * Liste des origines dispo dans l'environnement
   */
  originesEnvironnement: OrigineDTO[];

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ORIGINES';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE ORIGINES';
  }

  getEntityName(): string {
    return 'Origine';
  }

  getFields(dto: OrigineDTO): FormFieldBaseSupplier<any>[] {


    const isReadOnly = !this.canModify(dto);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
      dto.id = 0;
      dto.libelle = "";
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      //LIBELLE
      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly: isReadOnly
      }),
      //ACTIF
      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 4,
        readonly: isReadOnly,
      }),
      //ID
      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 10
      })
    ];

    //SITE
    if (dto.id === 0) {
      let formFieldSite = new FormFieldDropdownSupplierSiteDTO({
        key: 'site',
        label: 'Site',
        readonly: isReadOnly,
        // type: 'linkedField',
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        // value: this.getSelectedItem(dto.site,        this.initArray(this.auth2Svc.utilisateur.siteListLocaux),
        order: 1,
        options: this.optionsSites,
        required: true,
      });
      formFields.push(formFieldSite);
    } else {
      let formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      });
      formFields.push(formFieldSite);
    }

    //CODE
    let formFieldCode: FormFieldTextboxSupplier;
    if (dto.id > 0) {
      formFieldCode = new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 3,
        readonly: true,
      })
      formFields.push(formFieldCode);
    }



    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
    let genericRequest: GenericRequestSupplier = new GenericRequestSupplier(this.getEntityName());
    genericRequest.search = new Search();
    genericRequest.search.predicats =[];
    genericRequest.search.sorts =[];

    this.findAll(genericRequest).subscribe( response => {
      this.originesEnvironnement = response.resultList;;
    });

  }

  createEmptyDTO(): OrigineDTO {
    let origine = new OrigineDTO();
    origine.id = 0;
    origine.code="";
    origine.libelle="";
    origine.actif=true;
    return origine;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER L'ORIGINE '${data.libelle.toUpperCase()}'`;
  }

  /** ****************************************************************************************************************************** */
  /** ****************************************************************************************************************************** */
  /** ****************************************************************************************************************************** */

  private subjectOpenDialogOrigine = new Subject<OrigineDTO>();
  public openDialogOrigine$: Observable<OrigineDTO> = this.subjectOpenDialogOrigine.asObservable();

  private subjectRefreshDialogListPlatGrid = new Subject<boolean>();
  public refreshDialogListPlatGrid$: Observable<boolean> = this.subjectRefreshDialogListPlatGrid.asObservable();

  public announceOpenDialogOrigine = (origine: OrigineDTO): void => {
    this.subjectOpenDialogOrigine.next(origine);
  };

  announceRefreshOrigineListGrid = (hastoRefresh :boolean): void => {
    this.subjectRefreshDialogListPlatGrid.next(hastoRefresh);
  };

  findAll = (genericRequest?: GenericRequestSupplier) => this.httpSvc.post(URL_POST_FIND_ORIGINES_WITH_PARAMS, genericRequest);

  saveOrigine = (origine : OrigineDTO) => this.httpSvc.post(URL_POST_SAVE_ORIGINE, origine);

  deleteOrigine = (idOrigine : number) => {
    const params =  new HttpParams()
      .set('idOrigine', idOrigine + '');
    return this.httpSvc.delete(URL_DELETE_ORIGINE, params);
  };


  filterByParams = (page: number, pageSize: number, filters: FilterItem[], sorts: Sort[]) => {
    const grs = new GenericRequestSupplier(this.getEntityName());
    const search = new Search();

    const rootPath = this.getEntityName().toLowerCase();

    if (!this.utils.isCollectionNullOrEmpty(filters)) {

      for (const filter of filters) {

        let path = '';
        switch (filter.column) {
          case FK_ORIGINES.libelle:
            path = `${rootPath}.libelle`;
            break;
          case FK_ORIGINES.code:
            path = `${rootPath}.code`;
            break;
        }

        const predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;
    grs.page = page + 1;
    grs.size = pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case FK_ORIGINES.libelle:
            sort.path = `${rootPath}.libelle`;
            break;
          case FK_ORIGINES.code:
            sort.path = `${rootPath}.code`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;

    return grs;
  };

}
