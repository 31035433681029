import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {UdpConditionnementVarianteDTO} from "../../../../core/dtos/conditionnement/udp-conditionnement-variante-dto";
import {UdpConditionnementVarianteService} from "../../../../core/services/conditionnements/udp-conditionnement-variante.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";

@Injectable({
  providedIn: 'root'
})
export class UpConditionnnementResolverService implements Resolve<UpConditionnnementSupplier> {

  constructor(private gds :GenericDatagridService,
              private  udpCondiVarianteSvc: UdpConditionnementVarianteService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpConditionnnementSupplier> | Promise<UpConditionnnementSupplier> | UpConditionnnementSupplier {
    const upConditionnnementSupplier = new UpConditionnnementSupplier();

    const idUniteDeProduction: number = +route.parent.parent.paramMap.get('idUniteDeProduction');

    const uniteDeProduction$ = this.gds.getOne('uniteDeProduction', idUniteDeProduction);
    const udpConditionnementVarianteList$ = this.udpCondiVarianteSvc.findByUdpId(idUniteDeProduction);


    const all$ = combineLatest([uniteDeProduction$,udpConditionnementVarianteList$]);
    return all$.toPromise().then(response => {

      upConditionnnementSupplier.uniteDeProduction = response[0].one;
      upConditionnnementSupplier.udpConditionnementVarianteList = response[1].resultList;

      return upConditionnnementSupplier;
    });
  }
}

export class UpConditionnnementSupplier{
  uniteDeProduction : UniteDeProductionDTO;
  udpConditionnementVarianteList :UdpConditionnementVarianteDTO[]=[];
}
