import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {MenusCompositionService} from './menus-composition.service';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import {DROP_ACTION, DROP_ZONES, MSG_KEY, MSG_SEVERITY} from '../../constants';
import {MenuDecoupageDateDTO} from '../../dtos/menudecoupagedate-dto';
import {DropMenu} from '../../suppliers/gestionmenus/dropmenu';
import {UtilsService} from '../../utils/utils.service';
import {cloneDeep as _cloneDeep} from 'lodash'
import * as moment from 'moment';
import {MenusToolbarService} from './menus-toolbar.service';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {CelluleTableauDTO} from '../../dtos/gestionmenus/planning/cellule-tableau-dto';
import {MenusPlanning2Service} from './menus-planning2.service';
import {ToastService} from "../technique/toast.service";

@Injectable()
export class MenusDragndropService {

  private subjectMoveMenuCompo: Subject<DropMenu<MenuCompositionDTO>> = new Subject();
  moveMenuCompo$ = this.subjectMoveMenuCompo.asObservable();


  /**************************************************************************
   * Angular methods
   *************************************************************************/
  constructor(private menuCompoSvc: MenusCompositionService,
              private menusComposSvc: MenusCompositionService,
              private menuToolbarSvc: MenusToolbarService,
              private utils: UtilsService,
              private menuPlanning2Svc: MenusPlanning2Service,
              private toastSvc: ToastService) {
  }

  /**************************************************************************
   * Private implementation
   *************************************************************************/
  private createMenuCompoFromProduitDecline = (dropMenu: DropMenu<ProduitDeclinaisonDTO>): MenuCompositionDTO => {
    let produitDeclinaison = dropMenu.transferObject;
    let ordre = dropMenu.ordreTarget;
    let menuDecoupageDate = dropMenu.menuDecoupageDateTarget;

    let newMdd = new MenuCompositionDTO();
    //quand id=0; le backend comprend qu'il doit créer le menu composition
    newMdd.id = 0;
    newMdd.ordre = ordre;
    newMdd.produitDeclinaisonPrixAchat = produitDeclinaison.prixAchat;
    newMdd.effectifPrevu = 0;
    newMdd.produitDeclinaisonId = produitDeclinaison.id;
    newMdd.produitDeclinaisonLibelle = produitDeclinaison.libelle;
    newMdd.familleNutritionnelleProduitDTOList = produitDeclinaison.famillesNutritionnellesProduits;
    newMdd.produitAllergeneDTOList = produitDeclinaison.produitAllergeneList;

    let newMenuDecoupageDateDTO: MenuDecoupageDateDTO = new MenuDecoupageDateDTO();
    newMenuDecoupageDateDTO.dateMenu = menuDecoupageDate.dateMenu;

    const cmcd = new ContratMenuConviveDecoupageDTO();
    cmcd.id = menuDecoupageDate.contratMenuConviveDecoupage.id;

    newMenuDecoupageDateDTO.contratMenuConviveDecoupage = cmcd;

    newMdd.menuDecoupageDate = newMenuDecoupageDateDTO;

    return newMdd;
  };


  private createMenuCompoFromProduitDecline2 = (dropMenu: DropMenu<ProduitDeclinaisonDTO>): MenuCompositionDTO => {
    const produitDeclinaison = dropMenu.transferObject;
    const ordre = dropMenu.ordreTarget;
    const celluleTarget = dropMenu.celluleTarget;


    const dateMenu = moment(celluleTarget.dateMenu).toDate();
    const idCmcd = celluleTarget.idContratMenuConviveDecoupage;
    const idMdd = celluleTarget.idMenuDecoupageDate;
    const newMc = this.menuCompoSvc.createMenuCompoFromProduitDeclinaison(produitDeclinaison, ordre, dateMenu, idCmcd, idMdd, this.menuPlanning2Svc.planningMenus.contrainteAlimentaire.id);

    return newMc;
  };

  /**
   * valeurs par defaut récupérées du contrat,  effectif et taux de prise
   * @param {ContratMenuConviveDecoupageDTO} cmcd
   * @param {MenuCompositionDTO} menuCompo
   * @param {number} ordre --> position du plat dans le cmcd
   * @returns {MenuCompositionDTO}
   */
  private setDefaultValuesFromContrat = (cmcd: ContratMenuConviveDecoupageDTO, menuCompo: MenuCompositionDTO, ordre: number): MenuCompositionDTO => {


    let cmcpArr = cmcd.contratMenuConvivePlatDTOList;
    if (!this.utils.isCollectionNullOrEmpty(cmcpArr)) {
      let cmcpArr0 = cmcpArr.filter(plat => plat.ordre === ordre);
      if (!this.utils.isCollectionNullOrEmpty(cmcpArr0)) {
        menuCompo.tauxDePrise = cmcpArr0[0].tauxDePrise;
      }
    }

    return menuCompo;
  };


  private saveProduitDeclinaisonToMenuComposition2 = (dropMenu: DropMenu<ProduitDeclinaisonDTO>) => {
    let menuCompo: MenuCompositionDTO = this.createMenuCompoFromProduitDecline2(dropMenu);
    // const menuDecoupageDate = dropMenu.celluleTarget;
    const ordre = dropMenu.ordreTarget;
    const changeDetector = dropMenu.changeDetectorRefTarget;

    // valeurs par defaut récupérées du contrat,  effectif et taux de prise
    menuCompo = this.setDefaultValuesFromContrat(dropMenu.celluleTarget.contratMenuConviveDecoupage, menuCompo, ordre);

    this.menuCompoSvc.saveMenuComposition(menuCompo).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        dropMenu.celluleTarget.mapMenuComposition[ordre] = response.one;
        changeDetector.markForCheck();
      }
    });
  };


  private deleteMenuComposition2 = (dropMenu: DropMenu<MenuCompositionDTO>) => {
    const menuCompo = dropMenu.transferObject;
    const ordre = dropMenu.ordreSource;

    const ids = [menuCompo.id];
    this.menuPlanning2Svc.deleteMenusCompositions(ids).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        dropMenu.celluleSource.mapMenuComposition[ordre] = undefined;
        dropMenu.changeDetectorRefSource.markForCheck();

        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Suppression du plat "${menuCompo.produitDeclinaisonLibelle}" réussie avec succès.`);
      }
    });
  };

  /**
   * @deprecated
   * @param dropMenu
   */
  private isSameMenuDecoupage = (dropMenu: DropMenu<MenuCompositionDTO>): boolean => dropMenu.menuDecoupageDateSource == dropMenu.menuDecoupageDateTarget;

  private isSameCellule = (dropMenu: DropMenu<MenuCompositionDTO>): boolean => dropMenu.celluleSource == dropMenu.celluleTarget;


  private isTargetCelluleMenuCompoEmpty = (dropMenu: DropMenu<MenuCompositionDTO>): boolean => dropMenu.celluleTarget.mapMenuComposition[dropMenu.ordreTarget] == undefined;


  private moveMenuCompositionEmptySlot2 = (dropMenu: DropMenu<MenuCompositionDTO>) => {
    let source: MenuCompositionDTO = _cloneDeep(dropMenu.transferObject);

    let destination: MenuCompositionDTO = dropMenu.transferObject;

    destination.ordre = dropMenu.ordreTarget;

    const cmcd = new ContratMenuConviveDecoupageDTO();
    cmcd.id = dropMenu.celluleTarget.idContratMenuConviveDecoupage;

    destination.menuDecoupageDate.contratMenuConviveDecoupage = cmcd;
    destination.menuDecoupageDate.dateMenu = moment(dropMenu.celluleTarget.dateMenu).toDate();
    destination.menuDecoupageDate.id = dropMenu.celluleTarget.idMenuDecoupageDate;

    this.menuCompoSvc.moveMenuComposition(source, destination).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        dropMenu.celluleTarget.mapMenuComposition[dropMenu.ordreTarget] = response.one;
        dropMenu.celluleSource.mapMenuComposition[dropMenu.ordreSource] = undefined;
        dropMenu.changeDetectorRefSource.markForCheck();
        dropMenu.changeDetectorRefTarget.markForCheck();

      }
    });
  };

  private moveMenuCompositionSameCellule = (dropMenu: DropMenu<MenuCompositionDTO>) => {

    let menuCompoSource: MenuCompositionDTO = _cloneDeep(dropMenu.transferObject);
    let menuCompoTarget: MenuCompositionDTO = _cloneDeep(dropMenu.celluleTarget.mapMenuComposition[dropMenu.ordreTarget]);

    this.menuCompoSvc.permuteMenusCompositions(menuCompoSource, menuCompoTarget).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        dropMenu.celluleTarget.mapMenuComposition[dropMenu.ordreTarget] = response.resultList[1];
        dropMenu.celluleSource.mapMenuComposition[dropMenu.ordreSource] = response.resultList[0];
        dropMenu.changeDetectorRefSource.markForCheck();
        dropMenu.changeDetectorRefTarget.markForCheck();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les deux plats ont été permutés avec succès ainsi que les régimes annexes`);
      }

    });

  };

  private moveMenuComposition2 = (dropMenu: DropMenu<MenuCompositionDTO>) => {


    //si slot cible est vide
    if (this.isTargetCelluleMenuCompoEmpty(dropMenu)) {
      this.moveMenuCompositionEmptySlot2(dropMenu);
    } else {

      //si source et cible sont sur le  meme menu decoupage date
      if (this.isSameCellule(dropMenu)) {

        //faire la permutation
        this.moveMenuCompositionSameCellule(dropMenu);

      }
    }

  };


  private getCellIdComposite = (cellule: CelluleTableauDTO): string => cellule.idContratMenuConviveDecoupage + '-' + moment(cellule.dateMenu).clone().format('DDMMYYYY');


  /**************************************************************************
   * Public implementation
   *************************************************************************/
  dropOnCellule = (dropMenu: DropMenu<any>) => {

    switch (dropMenu.dropAction) {
      case DROP_ACTION.GM_PRODUITDECLINAISON_TO_MENUCOMPOSITION : {
        this.saveProduitDeclinaisonToMenuComposition2(dropMenu);
        break;
      }
      case DROP_ACTION.GM_DROP_TO_TRASH : {
        this.deleteMenuComposition2(dropMenu);
        break;
      }
      case DROP_ACTION.GM_MOVE_MENUCOMPOSITION : {
        this.moveMenuComposition2(dropMenu);
        break;
      }
    }
  };


  dropZonePlanningAndTrashAndSameCellule = (cellule: CelluleTableauDTO): string[] => {
    if(!cellule.nonModifiable){
      return [DROP_ZONES.PLANNING, DROP_ZONES.TRASH, this.getCellIdComposite(cellule)];
    }
  };

  dropZoneTrash = (): string[] => [DROP_ZONES.TRASH];


  dropZoneSameCellule = (cellule: CelluleTableauDTO): string[] => [this.getCellIdComposite(cellule)];


  dropZonePlanning = (cellule: CelluleTableauDTO): string[] => {
    if(!cellule.nonModifiable) {
      return [DROP_ZONES.PLANNING];
    }
  };
}
