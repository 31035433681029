import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';
import {Observable, Subscription} from 'rxjs';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {catchError, debounceTime, map, switchMap} from 'rxjs/operators';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {MENU_PROFIL} from '../../core/constants';
import {ColonneDateDTO} from '../../core/dtos/gestionmenus/planning/colonne-date-dto';

@Component({
  selector: 'yo-cout-periode',
  templateUrl: './cout-periode.component.html',
  styleUrls: ['./cout-periode.component.scss']
})
export class CoutPeriodeComponent implements OnInit, OnDestroy {


  @Input() dateList: ColonneDateDTO[];
  @Input() contratMenuConviveList: ContratMenuConviveDTO[];
  @Input() idRepas: number;
  @Input() libelleRepas: string;

  coutUnitairePeriodeRepas: number;
  coutMoyenPeriodeRepas: number;
  coutGlobalPeriodeRepas: number;
  coutTheoriquePeriodeRepas: number;
  subDisplayProfil: Subscription;
  displayProfil:boolean;

  calculerCoutGlobal$: Observable<ResponseWrapper<ObjectDTO>>;


  constructor(private utils: UtilsService,
              private menu2Svc: MenusPlanning2Service,
              private menuToolbarSvc:MenusToolbarService) {
  }

  ngOnInit() {

    this.subDisplayProfil = this.menuToolbarSvc.displayProfil$.subscribe(item=>this.displayProfil = this.menuToolbarSvc.isProfilToDisplay(item,MENU_PROFIL.ECONOMAT));

    this.calculerCoutGlobal$ = this.menu2Svc.calculerCoutGlobalPeriode$
      .pipe(
        debounceTime(300),
        switchMap(response => this.menu2Svc.getCoutPeriodeRepas(this.dateList, this.contratMenuConviveList, this.idRepas)),
        map((response: ResponseWrapper<ObjectDTO>) => {
          this.coutUnitairePeriodeRepas = response.additionalProperties['coutUnitairePeriodeRepas'];
          this.coutGlobalPeriodeRepas = response.additionalProperties['coutGlobalPeriodeRepas'];
          this.coutMoyenPeriodeRepas = response.additionalProperties['coutMoyenPeriodeRepas'];
          this.coutTheoriquePeriodeRepas = response.additionalProperties['coutTheoriquePeriodeRepas'];

          return response;
        }),
        catchError(err => this.utils.handleError(err))
      );
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayProfil);
  }

  refresh = () => {

  };
}
