import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {Observable} from 'rxjs';
import {MenusRoutemapService} from './menus-routemap.service';
import * as moment from 'moment';
import {MenuEffectifRepasDTO} from '../../dtos/menu-effectif-repas-dto';
import {DialogMsgSupplier, Paragraphe} from '../../suppliers/dialog-msg-supplier';
import {SelectItem} from 'primeng/api';
import {DATES_FORMAT} from '../../constants';
import {MessageDialogService} from "../technique/message-dialog-service";
import {catchError} from "rxjs/operators";

export enum COUT_GLOBAL {EFFECTIF_PLAT, EFFECTIF_GLOBAL, MIXTE};

@Injectable()
export class EffectifsService {

  coutGlobalModeItems: SelectItem[] = [
    {label: 'Effectif Plat', value: {idx: 1, cgm: COUT_GLOBAL.EFFECTIF_PLAT}},
  ];

  constructor(private http: HttpClient,
              private menusRoutemapSvc: MenusRoutemapService,
              private messageDialogSvc :MessageDialogService,
              private utils: UtilsService) {
  }


  /****************************************************
   * PRIVATE IMPLEMENTATION
   ****************************************************/


  private infoCoutTheorique(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = 'Coût théorique';
    dms.logo = 'fa fa-question-circle';

    let p1: Paragraphe = new Paragraphe();
    p1.title = '';
    p1.lines = [
      'Il permet de respecter une politique de prix.',
      'Il peut être utilisé pour faire des analyses de prix dans le temps.',
    ];

    dms.content = {
      intro: 'Seuil de prix affecté à un contrat menu convive repas.',
      paragraphes: [p1]

    };

    return dms;
  }

  private infoCoutUnitaire = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = 'Règles de calcul du coût unitaire';
    dms.logo = 'fa fa-question-circle';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Coût unitaire`;
    p1.lines = [
      `On réalise une moyenne pondérée de chaque composante repas (Entrée, Plat, Dessert...).`,
      `Le coût unitaire est la somme de ces moyennes pondérées.`,
    ];


    let sp11: Paragraphe = new Paragraphe();
    sp11.lines = [
      `Moyenne pondérée d'une composante repas (Entrée, Plat, Dessert...) : pour chaque ligne d'une composante repas, effectif plat *  prix plat, on divise par l'effectif total de la composante repas.`,
    ];

    p1.sousParagraphes = [sp11];

    dms.content = {
      intro: '',
      paragraphes: [p1]
    };

    return dms;
  };


  private infoCalculCoutGlobal = (coutGlobalMode: COUT_GLOBAL): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = 'Règles de calcul du coût global';
    dms.logo = 'fa fa-question-circle';

    let p1: Paragraphe = new Paragraphe();
    p1.title = this.getCheckedCoutGlobalModeIcon(coutGlobalMode, COUT_GLOBAL.EFFECTIF_PLAT, 'Par effectif des plats');
    p1.lines = [
      'Somme  effectifs plats * prix plat ',
      `<small>L'effectif global et les taux de prise ne sont plus visibles dans ce mode.</small>`
    ];

    let p2: Paragraphe = new Paragraphe();
    p2.title = this.getCheckedCoutGlobalModeIcon(coutGlobalMode, COUT_GLOBAL.EFFECTIF_GLOBAL, 'Par effectif global');
    p2.lines = [
      `Somme  effectif global * (% taux de prise) * prix plats`,
      `<small>Les effectifs plats ne sont plus éditables dans ce mode.</small>`
    ];

    let p3: Paragraphe = new Paragraphe();
    p3.title = this.getCheckedCoutGlobalModeIcon(coutGlobalMode, COUT_GLOBAL.MIXTE, 'Mixte');
    p3.lines = [
      `Par défaut on est en mode <strong>effectifs des plats</strong>.`,
      `Si l'effectif d'un plat est nul ou égal à 0, on le calcule en mode effectif global --> effectif global * prix plat.`,
    ];

    let sp31: Paragraphe = new Paragraphe();
    sp31.title = `Si % taux de prise > 0 `
    sp31.lines = [`effectif global * (% taux de prise) * prix plat`];

    let sp32: Paragraphe = new Paragraphe();
    sp32.title = `Sinon (effectif prevu = 0, taux de prise =0) `
    sp32.lines = [`prix plat nom compté dans le calcul`];

    dms.content = {
      intro: '2 modes de calcul',
      paragraphes: [p1, p2]
    };

    return dms;
  };

  /**
   * Afficher un check si le cout global correspond
   * @returns {string}
   */
  private getCheckedCoutGlobalModeIcon = (prefCgMode: COUT_GLOBAL, cgm: COUT_GLOBAL, libelle: string): string => {
    if (cgm === prefCgMode) {
      return `<span title="Mode sélectionné"><i class="fa fa-check new-color"></i>&nbsp${libelle}</span>`;
    }
    return libelle;
  };

  /****************************************************
   * PUBLIC IMPLEMENTATION
   ****************************************************/
  openInfoCoutGlobal = (coutGlobalMode: COUT_GLOBAL): void => {
    this.messageDialogSvc.announceDisplayMessageDialog(this.infoCalculCoutGlobal(coutGlobalMode));
  };

  openInfoCoutIndividuel = (coutGlobalMode: COUT_GLOBAL): void => {
    this.messageDialogSvc.announceDisplayMessageDialog(this.infoCoutUnitaire());
  };

  /**
   * Mode de calcul du cout global : 1=effectif plat, 2=effectif global, 3=mixte
   * @param {number} prefUserCoutGlobalMode
   * @returns {any}
   */
  public getCoutGlobalMode = (prefUserCoutGlobalMode: number): COUT_GLOBAL => {
    if (prefUserCoutGlobalMode === 1) {
      return COUT_GLOBAL.EFFECTIF_PLAT;
    } else if (prefUserCoutGlobalMode === 2) {
      return COUT_GLOBAL.EFFECTIF_GLOBAL;
    } else if (prefUserCoutGlobalMode === 3) {
      return COUT_GLOBAL.MIXTE;
    }
    return 2;
  };

  saveEffectifGlobal = (idContratMenuConviveRepas: number, dateEffectif: moment.Moment, effectifPrevu: number): Observable<ResponseWrapper<MenuEffectifRepasDTO>> => {

    if (idContratMenuConviveRepas > 0 && effectifPrevu != null && effectifPrevu > -1 && moment.isMoment(dateEffectif)) {
      return this.http.get<ResponseWrapper<MenuEffectifRepasDTO>>(this.menusRoutemapSvc.getSaveEffectifGlobalJourSLink(idContratMenuConviveRepas, dateEffectif.format(DATES_FORMAT.YYYYMMDD), effectifPrevu))
        .pipe(catchError(error => this.utils.handleError(error, true)));
    }
    return this.utils.handleResponseError(`L'effectif global est invalide`, true);
  };

}


