import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DxDataGridComponent} from "devextreme-angular";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {UniteDeProductionDTO} from "../../../../../core/dtos/unite-de-production-dto";
import {GroupeAchatService} from "../../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {UnitesDeProductionService} from "../../../../../core/services/entities/unites-de-production.service";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {TYPE_LIST_UDP} from "../../../../../core/constants";

@Component({
  selector: 'yo-dialog-add-membre',
  templateUrl: './dialog-add-membre.component.html',
  styleUrls: ['./dialog-add-membre.component.scss']
})
export class DialogAddMembreComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: DxDataGridComponent;

  uniteDeProductionList: UniteDeProductionDTO[] = [];
  uniteDeProductionListSelected: UniteDeProductionDTO[] = [];

  displayDialog: boolean = false;
  subOpenDialog: Subscription;

  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private uniteDeProductionSvc: UnitesDeProductionService) {
  }

  ngOnInit() {
    this.subOpenDialog = this.groupeAchatSvc.openDialogAddMember$.subscribe(data => {
      this.displayDialog = data;
    });
    this.uniteDeProductionSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe((uniteDeProductionList: ResponseWrapper<UniteDeProductionDTO>) => {
      this.uniteDeProductionList = uniteDeProductionList.resultList.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id));
    });
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  addUniteDeProductionSelected = () => {
    this.groupeAchatSvc.announceUniteDeProductionSelected(this.uniteDeProductionListSelected);
    this.closeDialog();
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.grid.instance.clearSelection();
    this.uniteDeProductionListSelected = [];
  };

  onChangeSelection = (event: any) => {
    this.uniteDeProductionListSelected = event.selectedRowsData;
  };
}
