import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {Observable} from "rxjs";
import {ChangelogDto} from "../../dtos/changelog/changelog-dto";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";

export const URL_FETCH_CHANGELOG = 'dolrest/changelogs';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {

  constructor(private httpSvc: HttpService) {}

  fetchChangeLog: () => Observable<ResponseWrapper<ChangelogDto>> = () => this.httpSvc.get(URL_FETCH_CHANGELOG);

}
