import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';

import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ProduitnomenclatureDTO} from '../../../core/dtos/produitnomenclature-dto';
import {ProduitAllergeneDTO} from '../../../core/dtos/produit-allergene-dto';
import {combineLatest, Observable} from 'rxjs';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProduitDeclinaisonResolverService implements Resolve<ProduitDeclinaisonSupplier> {


  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private produitRoutemapSvc: ProduitRouteMapService,
              private produitsSvc: ProduitsService,
              private produitDecliSvc: ProduitDeclinaisonService) {

  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProduitDeclinaisonSupplier> | Promise<ProduitDeclinaisonSupplier> | ProduitDeclinaisonSupplier => {
    let produitDeclinaisonSupplier: ProduitDeclinaisonSupplier = new ProduitDeclinaisonSupplier();

    // console.log('site principal resolve',this.user.sitePrincipal);

    let idProduit: number = +route.parent.paramMap.get('idProduit');
    let isFabrique: boolean = route.parent.paramMap.get('isFabrique') === 'true' ? true : false;

    let urlProduitAllergenes = this.produitRoutemapSvc.getProduitAllergenesSLink(idProduit);

    produitDeclinaisonSupplier.isFabrique = isFabrique;
    const produitAllergeneDTOList$ = this.http.get<ProduitAllergeneDTO[]>(urlProduitAllergenes);
    const produitDTO$ = this.gds.getOne(this.produitsSvc.getEntityName(), idProduit);


    return produitDTO$.pipe(
      switchMap(response => {
        produitDeclinaisonSupplier.produitDTO = response.one;
        const idUdp = produitDeclinaisonSupplier.produitDTO.site.idUniteDeProduction;
        if(produitDeclinaisonSupplier.produitDTO.site.idUniteDeProduction === 0){
          return combineLatest([produitAllergeneDTOList$]);
        }else{
          const produitNomenclatureList$ = this.produitDecliSvc.getProduitsdecliProduitNomenclatureFichetech(idProduit, idUdp);
          const produitDeclinaisonUtilises$ = this.produitDecliSvc.getProduitsdeclisFichetechComponent(idProduit, idUdp);
          return combineLatest([produitAllergeneDTOList$,produitNomenclatureList$, produitDeclinaisonUtilises$]);
        }

      }),
    ).toPromise().then(data => {
      produitDeclinaisonSupplier.produitAllergeneDTOList = data[0];
      if(produitDeclinaisonSupplier.produitDTO.site.idUniteDeProduction !== 0) {
        produitDeclinaisonSupplier.produitNomenclatureList = data[1];
        produitDeclinaisonSupplier.produitDeclinaisonUtilises = data[2];
      }


      return produitDeclinaisonSupplier;
    });
  };

}

export class ProduitDeclinaisonSupplier {

  produitDeclinaisonDTOList: ProduitDeclinaisonDTO[]= [];
  produitDTO: ProduitDTO;
  isFabrique: boolean;
  produitNomenclatureList: ProduitnomenclatureDTO[] =[];
  produitDeclinaisonUtilises: ProduitDeclinaisonDTO[] =[];
  produitAllergeneDTOList: ProduitAllergeneDTO[]= [];
}
