import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {UtilsService} from '../../core/utils/utils.service';
import {MarcheDTO} from '../../core/dtos/gerstion-marche/marche-dto';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {MarcheSupplier} from './marche-resolver.service';
import {GestionsMarcheService, TAB_NAME_M} from '../../core/services/gestion-marche/gestions-marche.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {LotMarcheDTO} from '../../core/dtos/gerstion-marche/lot-marche-dto';

@Component({
  selector: 'yo-marche',
  templateUrl: './marche.component.html',
  styleUrls: ['./marche.component.scss']
})
export class MarcheComponent implements OnInit, OnDestroy {

  tabMenuMarche: MenuItem[] = [];
  activeItem: MenuItem;

  marche: MarcheDTO;
  lotMarcheList:LotMarcheDTO[];

  subRoute: Subscription;
  subMarcheDto: Subscription;

  constructor(public utils: UtilsService,
              private gestionsMarcheSvc :GestionsMarcheService,
              private routeMapSvc: RoutemapService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initDataSupplierAndRoute();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subMarcheDto);
  }

  initDataSupplierAndRoute(){

    //init component via la route
    this.subRoute = this.route.data
      .subscribe((data: { marcheSupplier: MarcheSupplier }) => {
        this.marche = data.marcheSupplier.marche;
        this.lotMarcheList = data.marcheSupplier.lotMarcheList;
        this.initTabMenuMarche(this.marche, TAB_NAME_M.info);
      });

    // refresh component via un 'announceMarcheDto'
    this.subMarcheDto = this.gestionsMarcheSvc.marcheDto$.subscribe( (response: any) =>{
      this.marche = response.marche;
      this.initTabMenuMarche(this.marche, response.tabName);
    });
  }

  initTabMenuMarche(marche: MarcheDTO,  tabName: number) {

    this.tabMenuMarche = [];

    this.tabMenuMarche.push({label: `Fiche d'identité`, routerLink: ['fiche-identite']});

    // Si le marché existe
    if (this.utils.isNumberGt0(marche.id)) {
      this.tabMenuMarche.push({
        label: `Lots marchés`,
        command: () => {
          this.routeMapSvc.goToSecondaryRoute(['gestion-marche', 'marche', marche.id, 'lots-marche']);
        }
      });

      // On n'affiche l'acces au tableau de bord que si des lots marches existent
      if(!this.utils.isCollectionNullOrEmpty(this.lotMarcheList)){
        this.tabMenuMarche.push({label: `Tableau de bord`, routerLink: ['tableau-de-bord']});
      }
    }

    switch (tabName) {
      case TAB_NAME_M.lotMarche :
        this.activeItem = this.tabMenuMarche[1];
        break;
      case TAB_NAME_M.tableauDeBord :
        this.activeItem = this.tabMenuMarche[2];
        break;
      default:
        this.activeItem = this.tabMenuMarche[0];
    }
  }
}
