import {GenericRequestSupplier} from "../../suppliers/generics/generic-request-supplier";
import {ImportPropertyDTO} from "./import-property-dto";

export class ImportRequestDTO extends GenericRequestSupplier{

  filename:string;
  sheetname:string;
  importProperties:ImportPropertyDTO[];


}
