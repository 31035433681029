<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Etape de contrôle des fiches techniques</h1>
    <div class="item-wizard">
      <p>
        Cette étape vous permet de contrôler le poids net total des fiches techniques des plats (pour
        un(e) {{ uniteMesureLibelleModelePlat }} ).
        <ng-container *ngIf="modeSelected === 'PIECE_NON_PROPORTIONNELLE'">
          De plus, il est possible de préciser le poids d'un(e) {{ uniteMesureLibelleModelePlat }} en kilogrammes pour
          une typologie de prestation donnée. Sachant
          qu'un(e) {{ uniteMesureLibelleModelePlat }} peut potentiellement contenir
          plusieurs {{ uniteMesureLibelleModelePlat }}s selon le paramétrage proposé précédemment.
          Si ce dernier ne possède aucune saisie ou une valeur négative / égale à zéro, une valeur égale à 1 sera mise
          par défaut.
        </ng-container>
        Le bouton d'action + permet d'ajouter des instructions à destination des équipes de conditionnement (ex :
        Ajouter du persil...).
        Pour modifier une valeur, il suffit de cliquer sur une cellule présentant un champ de saisie puis d'y effectuer
        une saisie qui sera automatiquement mise à jour.
      </p>
      <div class="row mg-t-12">
        <div class="col-lg-12 container-table">
          <dx-data-grid
            [dataSource]="produits"
            keyExpr="id"
            width="100%"
            height="100%"
            columnResizingMode="widget"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true">
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false"
                        cellTemplate="libelleTemplate"></dxi-column>
            <dxi-column *ngFor="let d of declinaisons"
                        cellTemplate="dCellTemplate"
                        [width]="300"
                        [dataField]="d.id"
                        [caption]="d?.libelle">
            </dxi-column>
            <dxi-column dataField="id" caption="Actions" [allowFiltering]="false"
                        cellTemplate="actionsTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'libelleTemplate'">
              {{ cell.value }} <i *ngIf="containsWrongValues(cell.row.data.id)" class="fa-solid fa-triangle-exclamation"
                                  title="Un ou plusieurs poids net(s) ne sont pas renseignés ou ont une valeur égale à 0 ou à 1"></i>
            </div>
            <div class="flex-wrap" *dxTemplate="let cell of 'dCellTemplate'">
              <div *ngIf="findTotalNetUT(cell.row.data.id, cell.column.dataField) !== undefined; let totalNetUT">
                <input [class.warning-total]="findTotalNetUT(cell.row.data.id, cell.column.dataField) === 0"
                       [class.light-warning-total]="findTotalNetUT(cell.row.data.id, cell.column.dataField) === 1"
                       type="number" (change)="updateTotalNetUT($event, cell.row.data.id, cell.column.dataField)"
                       [value]="findTotalNetUT(cell.row.data.id, cell.column.dataField)"/> grammes
              </div>
              <div *ngIf="modeSelected === 'PIECE_NON_PROPORTIONNELLE'">
                Poids net d'un(e) {{ uniteMesureAbrevModelePlat }} en Kg : <input type="number" min="0" step=".0001"
                                                                                  [value]="readPoidsNet(cell.row.data.id, cell.column.dataField)"
                                                                                  (input)="updatePoidsNetUniteAConditionner($event, cell.row.data.id, cell.column.dataField)"/>
              </div>
            </div>
            <div *dxTemplate="let cell of 'actionsTemplate'" class="custom-button-container">
              <yo-cell-button (yoNavigation)="gererInstruction(cell.row.data.id, cell.column.dataField)"
                              [yoTooltipShowDelay]="1500"
                              [yoViewMode]="false"
                              [yoSmall]="true"
                              yoTextAlign="center"
                              yoIconClass="fas fa-plus"
                              yoTooltip="Ajouter une instruction"
              ></yo-cell-button>
            </div>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 7/13 : Etape de contrôle des fiches techniques - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar"
           [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button title="Passer à l'étape suivante"
            class="mg-r-5"
            type="button"
            (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

<div
  id="popup-handle-instructions"
  *ngIf="displayDialogHandleInstructions"
>
  <div class="row">
    <div class="col-lg-11">
      <h1>Gérer des instructions <span *ngIf="currentProduit">- {{ currentProduit.libelle }}</span></h1>
    </div>
    <div class="col-lg-1">
      <span class="closed" (click)="closeDialogGererInstructions()">x</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <i id="add-instruction" class="p-button-primary fas fa-plus" title="Ajouter une instruction"
         (click)="addRowInstruction()"></i>
    </div>
  </div>
  <div class="row" *ngIf="instructionsByDeclinaison[currentProduit.id]">
    <div class="col-lg-12">
      <div class="p-inputgroup input-group-sm ">
        <dx-data-grid
          [dataSource]="instructionsByDeclinaison[currentProduit.id]"
          height="150px"
          width="100%"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"

          #gridInstructions>
          <dxi-column width="80px" caption="Action(s)" cellTemplate="actionsTemplate"></dxi-column>
          <dxi-column caption="Instruction" datafield="libelle" cellTemplate="libelleTemplate"></dxi-column>
          <dxi-column *ngFor="let declinaison of declinaisons"
                      cellTemplate="instructionTemplate"
                      dataField="valuesByDeclinaison"
                      [name]="declinaison.libelle"
                      [caption]="declinaison.libelle">
          </dxi-column>

          <div *dxTemplate="let cell of 'actionsTemplate'" class="custom-button-container">
            <yo-cell-button (yoNavigation)="deleteRowInstruction($event, cell.rowIndex)"
                            [yoTooltipShowDelay]="1500"
                            [yoSmall]="true"
                            yoTextAlign="center"
                            yoIconClass="fas fa-trash"
                            title="Supprimer l'instruction">
            </yo-cell-button>
          </div>

          <div *dxTemplate="let cell of 'libelleTemplate'">
            <dx-text-box *ngIf="instructionsByDeclinaison[currentProduit.id].length" [value]="cell.row.data.libelle"
                         (onValueChanged)="changeLibelleInstruction($event, cell.rowIndex)"
                         placeholder="Libellé de l'instruction"></dx-text-box>
          </div>
          <div *dxTemplate="let cell of 'instructionTemplate'">
            <dx-number-box [value]="cell.row.data.values[cell.columnIndex - 2]"
                           (onValueChanged)="changeValueInstruction($event, cell.rowIndex, cell.columnIndex - 2)"></dx-number-box>
          </div>

        </dx-data-grid>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-right mg-t-15">
      <yo-button
        id="cta-save"
        iconClass="fas fa-save"
        label="Enregistrer"
        buttonClass="cta-success"
        (onClick)="saveInstructions()"
      >
      </yo-button>
    </div>
  </div>
</div>

