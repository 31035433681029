import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {saveAs} from "file-saver";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {TourneesService} from "../../core/services/referentiel/tournees.service";
import {Tournee} from "../../core/dtos/tournee-dto";
import {Subscription} from "rxjs";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-tournees',
  templateUrl: './tournees.component.html',
  styleUrls: ['./tournees.component.scss']
})
export class TourneesComponent implements OnInit, OnDestroy {

  pathHelpFile: string = HELP_FOLDERS.REFERENTIEL + '/tournees/tournees';

  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  selectedRows: number[] = [];
  errors: any[] = [];

  displayImportPopupErrors: boolean = false;
  displayDeletePopupErrors: boolean = false;

  tournees: Tournee[] = [];

  subTourneeSaved$: Subscription;

  subTournees$: Subscription;

  constructor(private auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              private graphQlSvc: GraphQLService,
              public utilsSvc: UtilsService,
              public tourneesSvc: TourneesService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subTourneeSaved$);
    this.utilsSvc.unsubscribe(this.subTournees$);
  }

  initSubscriptions = (): void => {
    this.initSubscriptionTournees();
    this.initSubscriptionTourneeSaved();
  }

  initSubscriptionTournees = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subTournees$ = this.graphQlSvc.sendQuery(`
      {
        allTournees(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            poidsMaxEssieux,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.tournees = response.allTournees;
      });
  }

  initSubscriptionTourneeSaved = (): void => {
    this.subTourneeSaved$ = this.tourneesSvc.tourneeSaved$.subscribe(() => {
      this.graphQlSvc.sendQuery(`
            {
              allTournees(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}]
              }) {
                  id,
                  code,
                  libelle,
                  actif,
                  poidsMaxEssieux,
                  site {
                      id,
                      libelle,
                  },
              }
            }
          `)
        .toPromise()
        .then(response => this.tournees = response.allTournees);
    });
  }

  openDialogEdition = (tournee: Tournee): void => {
    this.tourneesSvc.announceOpenDialogTournee(tournee);
  };

  calculateSiteValue = (tournee: Tournee): string => {
    return tournee?.site?.libelle
  };

  exportXslx = () => {
    this.tourneesSvc.exportTourneesByFilters(this.selectedRows).subscribe(response => {
      const filename = `tournee-export-${new Date().getTime()}.xlsx`;
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.selectedRows = [];
      saveAs(blob, filename);
    });
  }

  importXslx = async ($event: any) => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.tourneesSvc.importTourneesFromXlsx(file)
        .subscribe(response => {
          const res: any = response.one;
          if (res.allElementsImported)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }
          for (const item of res.elementsImported) {
            let index = this.tournees.findIndex(tournee => tournee.id == item.id);
            if (index >= 0)
              this.tournees[index] = item;
            else
              this.tournees.push(item)
          }

          if (this.errors != null && this.errors.length)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
    this.displayDeletePopupErrors = false;
  }

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_TOURNEE_DELETION_PLURAL" : "CONFIRM_TOURNEE_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.tourneesSvc.deleteTourneeByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.tournees.find(tournee => tournee.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `TOURNEE_DELETION_COMPLETED_PLURAL` : `TOURNEE_DELETION_COMPLETED_SINGULAR`, null));
        }
        this.graphQlSvc.sendQuery(`
            {
              allTournees(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}]
              }) {
                  id,
                  code,
                  libelle,
                  actif,
                  poidsMaxEssieux,
                  site {
                      id,
                      libelle,
                  },
              }
            }
          `)
          .toPromise()
          .then(response => this.tournees = response.allTournees);
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;


}
