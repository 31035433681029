import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {GestionUniteProduction__ModeleConditionnementPlatService} from "../../../../core/services/gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";

@Injectable({
  providedIn: 'root'
})
export class UpModeleConditionnnementPlatResolverService implements Resolve<UpModeleConditionnnementPlatSupplier> {

  constructor(private gds: GenericDatagridService,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpModeleConditionnnementPlatSupplier> | Promise<UpModeleConditionnnementPlatSupplier> | UpModeleConditionnnementPlatSupplier {
    const supplier = new UpModeleConditionnnementPlatSupplier();

    const idUniteDeProduction: number = +route.parent.parent.paramMap.get('idUniteDeProduction');
    const uniteDeProduction$ = this.gds.getOne('uniteDeProduction', idUniteDeProduction);
    const udpMcpList$ = this.udpMcpSvc.getAll(idUniteDeProduction);

    const all$ = combineLatest([uniteDeProduction$, udpMcpList$]);
    return all$.toPromise().then(response => {
      supplier.uniteDeProduction = response[0].one;
      supplier.udpMcpList = response[1].resultList;
      return supplier;
    });
  }
}

export class UpModeleConditionnnementPlatSupplier {
  uniteDeProduction: UniteDeProductionDTO;
  udpMcpList: UniteDeProduction__ModeleConditionnementPlatDto[] = [];
}
