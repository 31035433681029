<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  width="auto"
  maxHeight="70vh"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <dx-scroll-view
    #scrollView
    id="scrollview"
    showScrollbar="always"
  >
    <div>
      <div class="dialog-line">
        <dx-check-box
          (onValueChanged)="onChangeIsVisibleCaInherted()"
          text="Articles hérités"
          [(ngModel)]="isVisibleCaInherited">
        </dx-check-box>
      </div>
    </div>


    <dx-data-grid
      class="dialog-line"
      [dataSource]="customStore"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(220)"
      [width]="utils.getWindowAvailableWidth(100)"
      [remoteOperations]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="id" caption="Actions" [width]="100"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  cellTemplate="actionsCellTemplate">
      </dxi-column>
      <dxi-column dataField="produitArticle.libelle" caption="Article" [width]="500"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="fournisseurOwnerLibelle" caption="Hérité de"
                  [width]="60"
                  [allowFiltering]="false"
                  [allowSorting]="false"
                  cellTemplate="fournisseurOwnerLibelleCellTemplate">
      </dxi-column>

      <dxi-column dataField="referenceArticle"
                  caption="Réf.Article"
                  [width]="200"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="uniteDeCommande.libelle" caption="Unité de commande"
                  [width]="350"
                  [allowFiltering]="true">
      </dxi-column>


      <dxo-filter-row
        [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [showNavigationButtons]="false"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Articles">
      </dxo-pager>

      <!--    TEMPLATE-->

      <div *dxTemplate="let cell of 'fournisseurOwnerLibelleCellTemplate'">
        <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
             [title]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
      <span class="fa-stack fa-2x">
        <i class="fas fa-circle fa-stack-2x"></i>
        <strong class="fa-stack-1x custom-letter">H</strong>
      </span>
        </div>
      </div>

      <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
        <yo-cell-button *ngIf="!isDisabledDeleteBtn(cell.row.data)"
                        (yoNavigation)="openDeleteObject(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoSmall]="true"
                        yoTextAlign="center"
                        yoIconClass="fa fa-trash"
                        yoTooltip="Supprimer l'article"
        ></yo-cell-button>
      </div>

    </dx-data-grid>
  </dx-scroll-view>
</dx-popup>
