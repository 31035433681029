import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest} from "rxjs/index";
import {Observable} from "rxjs";
import {GroupeAchatService} from "../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {GroupeAchatDTO} from "../../../core/dtos/unite-de-production/groupe-achat-dto";
import {GroupeAchat__UdpDTO} from "../../../core/dtos/unite-de-production/groupe-achat-udp-dto";
import {GroupeAchat__FournisseurDTO} from "../../../core/dtos/unite-de-production/groupe-achat-fournisseur-dto";

@Injectable({
  providedIn: 'root'
})
export class GroupeAchatSidebarResolverService implements Resolve<GroupeAchatSidebarSupplier> {

  constructor(private groupeAchatSvc: GroupeAchatService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GroupeAchatSidebarSupplier> | Promise<GroupeAchatSidebarSupplier> | GroupeAchatSidebarSupplier => {

    let idGroupeAchat: number = +route.paramMap.get('idGroupeAchat');
    let groupeAchatSidebarSupplier: GroupeAchatSidebarSupplier = new GroupeAchatSidebarSupplier();

    if(idGroupeAchat ===  0){
      groupeAchatSidebarSupplier.groupeAchat = this.groupeAchatSvc.createEmptyDTO();

      return groupeAchatSidebarSupplier
    }else{
      const groupeAchatById$ = this.groupeAchatSvc.getGroupeAchatById(idGroupeAchat);
      const membreGrouepAchat$ = this.groupeAchatSvc.getMembreGroupeAchat(idGroupeAchat);
      const groupeAchatFournisseurList$ = this.groupeAchatSvc.getGroupeAchatFournisseurList(idGroupeAchat);

      const all$ = combineLatest([groupeAchatById$,membreGrouepAchat$,groupeAchatFournisseurList$]);

      return all$.toPromise().then(data => {
        groupeAchatSidebarSupplier.groupeAchat = data[0].one;
        groupeAchatSidebarSupplier.membreGroupeAchatList = data[1].resultList;
        groupeAchatSidebarSupplier.groupeAchatFournisseurList = data[2].resultList;
        return groupeAchatSidebarSupplier;
      });
    }
  };
}

export class GroupeAchatSidebarSupplier {
  groupeAchat: GroupeAchatDTO;
  membreGroupeAchatList : GroupeAchat__UdpDTO[] =[];
  groupeAchatFournisseurList : GroupeAchat__FournisseurDTO[]=[];
}
