import {Component, OnDestroy, OnInit} from "@angular/core";
import {GroupeAchatDTO} from "../../../core/dtos/unite-de-production/groupe-achat-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ActivatedRoute} from "@angular/router";
import {GridGroupeAchatSupplier} from "./grid-groupe-achat-resolver.service";
import {GroupeAchatService} from "../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {RoutemapService} from "../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {HELP_FOLDERS, MSG_KEY} from "../../../core/constants";
import {catchError} from "rxjs/operators";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-grid-groupe-achat',
  templateUrl: './grid-groupe-achat.component.html',
  styleUrls: ['./grid-groupe-achat.component.scss']
})
export class GridGroupeAchatComponent implements OnInit, OnDestroy {

  groupeAchatList: GroupeAchatDTO[] = [];
  subRoute: Subscription;
  subNewGroupeAchat: Subscription;
  subRefreshGroupeAchat: Subscription;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-groupe-achat';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public gds: GenericDatagridService,
              private groupeAchatSvc: GroupeAchatService,
              public routeMapSvc: RoutemapService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initData();
    this.subcriptionNewGroupeAchat();
    this.subcriptionRefreshGroupeAchat();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subNewGroupeAchat);
    this.utils.unsubscribe(this.subRefreshGroupeAchat);
  }


  initData = () => {
    this.subRoute = this.route.data
      .pipe(catchError(err=>{
        return err;
      }))
      .subscribe((data: { gridGroupeAchatSupplier: GridGroupeAchatSupplier }) => {
      this.groupeAchatList = data.gridGroupeAchatSupplier.groupeAchatList;
    });
  };

  subcriptionRefreshGroupeAchat = () => {
    this.subRefreshGroupeAchat = this.groupeAchatSvc.refreshGroupeAchatList$.subscribe(data =>{
      this.groupeAchatSvc.getGroupeAchatList()
        .subscribe( (data: ResponseWrapper<GroupeAchatDTO>) => this.groupeAchatList = data.resultList);
    });
  };

  subcriptionNewGroupeAchat = () => {
    this.subNewGroupeAchat = this.groupeAchatSvc.groupeAchatDto$.subscribe(data => {
      if (data.groupeAchat) {
          const index: number = this.groupeAchatList.findIndex(fournisseur => fournisseur.id === data.groupeAchat.id)
          index === -1 ? this.groupeAchatList.push(data.groupeAchat) : this.groupeAchatList[index] = data.groupeAchat
        this.groupeAchatSvc.announceOpenGroupeAchatDialog(data.groupeAchat);
      }
    });
  };

  openGroupeAchat = async (groupeAchat: GroupeAchatDTO) => {
    if (this.utils.isNullOrEmpty(groupeAchat)) {
      groupeAchat = this.groupeAchatSvc.createEmptyDTO();
      groupeAchat.id = 0;
    }
    await this.routeMapSvc.goToSecondaryRoute(['groupe-achat', groupeAchat.id, 'fiche-identite']);
  };

  deleteGroupeAchat = async (cell: GroupeAchatDTO) => {
    const result = confirm(`Etes vous sûr(e) de vouloir supprimer le groupe achat ${cell.libelle} ?`, 'Suppression de groupe achat');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.groupeAchatSvc.deleteById(cell.id)
        .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          this.utils.deleteMessage(response, MSG_KEY.ROOT);
          this.groupeAchatSvc.announceRefreshGroupeAchatList();
        });
    }
  };

  isDisabledDeleteBtn = (cell: GroupeAchatDTO) => this.auth2Svc.isSiteLocal(cell.site.id);

  help = () => undefined;

  canCreate = () => true;

  openGroupeAchatDialog = (groupeAchat: GroupeAchatDTO): void => {
    this.groupeAchatSvc.announceOpenGroupeAchatDialog(groupeAchat);
  }

}
