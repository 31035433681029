import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FS_ROUTES, OUTLET_SECONDARY, SECURITY} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {GestiongemrcnRootComponent} from './gestiongemrcn-root/gestiongemrcn-root.component';
import {ModeleNutriComponent} from './plan-alimentaire/modele-nutri/modele-nutri.component';
import {PlanAlimentaireResolverService} from './plan-alimentaire/plan-alimentaire-resolver.service';
import {PlanAlimentaireComponent} from './plan-alimentaire/plan-alimentaire.component';
import {FicheIdentiteComponent} from './plan-alimentaire/fiche-identite/fiche-identite.component';
import {PlanningComponent} from './plan-alimentaire/planning/planning.component';
import {FamillesPlatsComponent} from './plan-alimentaire/familles-plats/familles-plats.component';
import {ModeleNutriResolverService} from './plan-alimentaire/modele-nutri/modele-nutri-resolver.service';
import {FamillesPlatsResolverService} from './plan-alimentaire/familles-plats/familles-plats-resolver.service';
import {PlanningResolverService} from './plan-alimentaire/planning/planning-resolver.service';

const routes: Routes = [
  {
    path: 'gestiongemrcn',
    component: GestiongemrcnRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    data: {
      role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS
    }
  },
  {
    path: `${FS_ROUTES.GGE_MODELENUTRI}/:idModeleNutri`,
    component: PlanAlimentaireComponent,
    data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    outlet:OUTLET_SECONDARY,
    runGuardsAndResolvers: 'always',
    resolve:{
      planAlimentaireSupplier:PlanAlimentaireResolverService
    },
    children:[
      {
        path:'fiche-identite',
        component:FicheIdentiteComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
      },
      {
        path:'modele-nutri',
        component:ModeleNutriComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
        resolve:{
          modeleNutriSupplier:ModeleNutriResolverService
        },
      },
      {
        path:'familles-plats',
        component:FamillesPlatsComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
        resolve:{
          famillePlatSupplier:FamillesPlatsResolverService
        },
      },
      {
        path:'planning',
        component:PlanningComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
        resolve:{
          planningSupplier:PlanningResolverService
        },
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestiongemrcnRoutingModule {
}
