<dx-popup
  [showTitle]="true"
  title="Ajouter des unités de production"
  [(visible)]="displayDialog"
  width="auto"
  maxHeight="auto"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <dx-scroll-view>
    <dx-data-grid
      [dataSource]="uniteDeProductionList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(400)"
      [width]="utils.getWindowAvailableWidth(300)"
      (onSelectionChanged)="onChangeSelection($event)"
      width="100%"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="site" alignment="left" caption="Site" [width]="300"
                  [allowFiltering]="false" [allowSorting]="false"
                  cellTemplate="siteCellTemplate">
      </dxi-column>
      <dxi-column dataField="libelle" caption="Unité de production" [width]="300"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="code" caption="Code" [width]="300"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true">
      </dxi-column>


      <div *dxTemplate="let cell of 'siteCellTemplate'">
        <yo-site [siteLibelle]="cell.data?.site?.libelle"
                 [siteId]="cell.data?.site?.id">
        </yo-site>
      </div>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-selection mode="multiple"
                     selectAllMode="true"
                     [showCheckBoxesMode]="'always'">
      </dxo-selection>
    </dx-data-grid>


    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button label="Ajouter"
                   (onClick)="addUniteDeProductionSelected()"
                   [disabled]="uniteDeProductionListSelected.length === 0">
        </yo-button>

        <yo-button buttonClass="cta-delete"
                   iconClass="fas fa-times"
                   (onClick)="closeDialog()"></yo-button>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
