import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {ModeleNutritionnelParamGemrcnDTO} from '../../dtos/modele-nutritionnel-param-gemrcn-dto';
import {catchError} from 'rxjs/operators';
import {RepasDTO} from '../../dtos/repas-dto';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {ModeleNutritionnelGroupeGemrcnDTO} from '../../dtos/modele-nutritionnel-groupe-gemrcn-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {CHOIX_GEMRCN, PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {CmcMnGroupeGemrcnDTO} from '../../dtos/cmcmn-groupe-gemrcn-dto';

export const URL_GET_PREPARE_DIALOG_ADD_ANALYSE_GEMRCN = `dolrest/gestiongemrcn/prepare/dialog-add-groupe-gemrcn`;
export const URL_POST_SAVE_MODELENUTRUTIONNEL_GROUPE_GEMRCN = `dolrest/gestiongemrcn/save/modelenutritionnel-groupe-gemrcn`;
export const URL_POST_DELETE_GROUPE_GEMRCN = `dolrest/gestiongemrcn/delete/modelenutritionnel-groupe-gemrcn`;

@Injectable({
  providedIn: 'root'
})
export class ModeleNutritionnelGroupeGemrcnService extends GenericHandler<ModeleNutritionnelGroupeGemrcnDTO> {


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO(): ModeleNutritionnelGroupeGemrcnDTO {

    return undefined;
  }

  createDTO(modeleNutritionnel: ModeleNutritionnelDTO, repasList: RepasDTO[], choix: number) {

    const mnGroupeGemrcn = new ModeleNutritionnelGroupeGemrcnDTO();
    mnGroupeGemrcn.modeleNutritionnelId = modeleNutritionnel.id;
    mnGroupeGemrcn.choix = choix;

    mnGroupeGemrcn.modeleNutritionnelParamGemrcnList = [];
    if (!this.utils.isCollectionNullOrEmpty(repasList)) {

      for (const repas of repasList) {
        const mnParamGemrcn = new ModeleNutritionnelParamGemrcnDTO();
        mnParamGemrcn.repasId = repas.id;
        mnParamGemrcn.repasLibelle = repas.libelle;
        mnParamGemrcn.repasCode = repas.code;
        mnGroupeGemrcn.modeleNutritionnelParamGemrcnList.push(mnParamGemrcn);
      }
    }


    return mnGroupeGemrcn;
  }


  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEntityName(): string {
    return "ModeleNutritionnelGroupeGemrcn";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }


  /**
   * Récupérer les données nécessaires à l'affichage du dialog "Paramétrer analyse GEMRCN"
   * @param mcPlc
   */
  prepareDialogAddGroupeGemrcn(modeleNutritionnelId: number) {

    return this.http.get(URL_GET_PREPARE_DIALOG_ADD_ANALYSE_GEMRCN, {
      params: new HttpParams()
        .set('modeleNutritionnelId', modeleNutritionnelId + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }


  saveGroupeGemrcn(mnGroupeGemrcn: ModeleNutritionnelGroupeGemrcnDTO) {

    return this.http.post(URL_POST_SAVE_MODELENUTRUTIONNEL_GROUPE_GEMRCN, mnGroupeGemrcn)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }


  deleteGroupeGemrcn(idModeleNutritionnelGroupeGemrcn: number) {

    return this.http.post(URL_POST_DELETE_GROUPE_GEMRCN, {}, {
      params: new HttpParams().set('idModeleNutritionnelGroupeGemrcn', idModeleNutritionnelGroupeGemrcn + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }


  filterByIdModeleNutritionnel(idModeleNutri: number) {

    const grs = new GenericRequestSupplier(this.getEntityName());

    const rootPath = this.getEntityName().toLowerCase();
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `${rootPath}.modeleNutritionnel.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type =  PREDICAT_TYPE.Integer;
    predicat1.value = idModeleNutri + '';

    search.predicats = [predicat1];

    grs.search = search;

    return grs;

  }

  getGroupeLibelle(groupe: ModeleNutritionnelGroupeGemrcnDTO) {

    let libelle='';

    if(!this.utils.isCollectionNullOrEmpty(groupe.modeleNutritionnelParamGemrcnList)){
      libelle =groupe.modeleNutritionnelParamGemrcnList.map(item=>item.repasLibelle.toUpperCase()).join(' + ');
    }

    return libelle;

  }

  getCmcMnGroupeLibelle(groupe: CmcMnGroupeGemrcnDTO) {

    let libelle='';

    if(!this.utils.isCollectionNullOrEmpty(groupe.cmcMnParamGemrcnList)){
      libelle =groupe.cmcMnParamGemrcnList.map(item=>item.repasLibelle.toUpperCase()).join(' + ');
    }

    return libelle;

  }

  getTooltipChoixGermcn(choix: number) {
    if (choix === CHOIX_GEMRCN.DIRIGE) {
      return 'choix dirigé';
    }

    return 'choix multiple';
  }

  getFrequenceRepas(groupe: ModeleNutritionnelGroupeGemrcnDTO,nbSemaines:number,nbJoursSemaine:number) {

    return groupe.modeleNutritionnelParamGemrcnList.length * nbSemaines * nbJoursSemaine;

  }
}


export class GroupeAnalyseGemrcn {

  libelle: string;
  groupe: number;
  choix: number;
  modeleNutritionnelLibelle: string;
  frequenceRepas: number;
}
