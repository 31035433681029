import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {ContactDTO} from '../../dtos/contact-dto';
import {FormFieldTextareaSupplier} from '../../suppliers/form-field-textarea-supplier';
import {startCase as _startCase} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends GenericHandler<ContactDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService,
  ) {

    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES CONTACTS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN CONTACT';
  }


  getEntityName(): string {
    return 'contact';
  }

  getFields(dto: ContactDTO): FormFieldBaseSupplier<any>[] {
    // console.log('getFuields contact',dto);

    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldTextboxSupplier({
        key: 'nom',
        label: 'Nom',
        maxLength: 50,
        value: dto.nom,
        required: false,
        order: 1
      }),

      new FormFieldTextboxSupplier({
        key: 'prenom',
        label: 'Prénom',
        maxLength: 50,
        value: dto.prenom,
        required: false,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'telephone1',
        label: 'Téléphone 1',
        value: dto.telephone1,
        maxLength: 50,
        required: false,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'telephone2',
        label: 'Téléphone 2',
        value: dto.telephone2,
        maxLength: 50,
        required: false,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'fax',
        label: 'Fax',
        value: dto.fax,
        maxLength: 50,
        required: false,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'mail',
        label: 'Mail',
        maxLength: 50,
        value: dto.mail,
        required: false,
        order: 6
      }),


      new FormFieldTextareaSupplier({
        key: 'commentaires',
        label: 'Commentaires',
        value: dto.commentaires,
        required: false,

        order: 8,
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 9
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);

  }


  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): ContactDTO {
    const contact = new ContactDTO();
    contact.actif = true;

    return contact;
  }


  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

}
