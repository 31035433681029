<ng-container *ngIf="logged">
  <p-panel header="Changelogs" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="row">
      <div id="changelog-content" class="col-md-12">
        <h2>Quoi de neuf ?</h2>
        <p>
          La description des fonctionnalités de la version
          <a class="scroll-link" (click)="scrollToLastestVersion()">{{ changelog.latestVersion }}</a>
          est désormais disponible.
        </p>
        <h2>Table des matières</h2>
        <div *ngIf="changelog.versions && changelog.versions.length">
          <yo-numberVersion [versions]="changelog.versions"></yo-numberVersion>
          <yo-detailedVersion [versions]="changelog.versions"  [isFirstParent]="true"></yo-detailedVersion>
        </div>
      </div>
    </div>
  </p-panel>
</ng-container>
<ng-container *ngIf="!logged">
  <p-panel header="Changelogs" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="row">
      <div class="col-md-12">
        Accès interdit
      </div>
    </div>
  </p-panel>
</ng-container>
