<h5>
  <div *ngIf="marche?.id !== 0">
    <span *ngIf="marche.site && marche.site.id" class="space">
    <label class="badge badge-secondary">
      <yo-site [siteId]="marche?.site?.id" [siteLibelle]="marche?.site?.libelle"></yo-site>
    </label>
    </span>

    <label class="badge badge-secondary mg-r-20">
      <i class="fas fa-handshake mg-r-5"></i>
      <yo-viewmode
        [yoLabelFab]="'MARCHÉ'" [libelle]="marche?.libelle"
        [siteId]="marche?.site?.id">
      </yo-viewmode>
    </label>
  </div>
  <div *ngIf="marche?.id === 0">
    <label class="badge badge-secondary">
      <i class="fas fa-handshake"></i>
      <span class="mg-l-5">Création d'un marché</span>
    </label>
  </div>
</h5>

<p-tabMenu [model]="tabMenuMarche" [activeItem]="activeItem"></p-tabMenu>

<p-card>
  <div class="card-size">
    <router-outlet></router-outlet>
  </div>
</p-card>


