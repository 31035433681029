import {InfosRoundDto} from "./infos-round-dto";

export class InformationsByDeliveryDayDto {

  LUNDI: InfosRoundDto[] = [];

  MARDI: InfosRoundDto[] = [];

  MERCREDI: InfosRoundDto[] = [];

  JEUDI: InfosRoundDto[] = [];

  VENDREDI: InfosRoundDto[] = [];

  SAMEDI: InfosRoundDto[] = [];

  DIMANCHE: InfosRoundDto[] = [];

}
