import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {RepasDTO} from '../../dtos/repas-dto';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ReceptionStatutDTO} from '../../dtos/reception-statut-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {startCase as _startCase} from 'lodash';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../constants";

@Injectable({
  providedIn: 'root'
})
export class ReceptionStatutService extends GenericHandler<RepasDTO> {

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  createEmptyDTO = (): RepasDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: ReceptionStatutDTO): string => "";

  getEntityName = (): string => "receptionStatut";

  getFields = (dto: ReceptionStatutDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "GESTION DES STATUTS DE RÉCEPTION";

  filterStatut = (code:string): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'receptionstatut.code';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.String;
    predicat1.value = code;

    search.predicats.push(predicat1);

    grs.search = search;

    return grs;
  };
}
