import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ZoneDeStockageDTO} from '../../dtos/zone-de-stockage-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {startCase as _startCase} from 'lodash';
import {shareReplay} from 'rxjs/operators';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Observable, Subject} from "rxjs";
import {GraphQLService} from "../technique/graphql.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {HttpService} from "../technique/http.service";

@Injectable()
export class ZonesDeStockageService extends GenericHandler<ZoneDeStockageDTO> {

  zdsEnvironnement: ZoneDeStockageDTO[]=[];

  private subjectOpenDialog = new Subject<ZoneDeStockageDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectZoneDeStockageSaved = new Subject<ZoneDeStockageSavedSupplier>();
  zoneDeStockageSaved$ = this.subjectZoneDeStockageSaved.asObservable();

  exportZoneDeStockageByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/production/zones-de-stockage/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importZoneDeStockageFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/production/zones-de-stockage/import", formData, null);
  };

  deleteZoneDeStockageByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`${"dolrest/referentiel/production/zones-de-stockage/delete"}?${params}`);
  };

  announceOpenDialogEdition = (zoneDeStockage: ZoneDeStockageDTO) => {
    this.subjectOpenDialog.next(zoneDeStockage);
  };

  announceZoneDeStockageSaved = (zoneDeStockage: ZoneDeStockageDTO, isUpdate: boolean = false) => {
    this.subjectZoneDeStockageSaved.next({isUpdate, zoneDeStockage} as ZoneDeStockageSavedSupplier);
  }


  save = (zoneDeStockage: ZoneDeStockageDTO): Observable<ResponseWrapper<ZoneDeStockageDTO>> => this.httpSvc.post("dolrest/referentiel/production/zones-de-stockage/save", zoneDeStockage);

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }



  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ZONES DE STOCKAGE';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE ZONE DE STOCKAGE';
  }


  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private graphQlSvc: GraphQLService,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }

  getEntityName(): string {
    return 'zoneDeStockage';
  }

  getFields(dto: ZoneDeStockageDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une ZoneDeStockage est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new ZoneDeStockageDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        excelType:'string',
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        excelType:'string',
        maxLength: 50,
        required: true,
        order: 3
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        excelType:'boolean',
        required: false,
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
    this.getZonesDeStockage().pipe(
      shareReplay())
      .subscribe(response => {
        this.zdsEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.allZonesDeStockage)) {
          this.zdsEnvironnement = response.allZonesDeStockage.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.zdsEnvironnement);
      });

  }

  getZonesDeStockage = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allZonesDeStockage(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `)
  }

  createZdsFromUdpZds(udpZds:UniteDeProduction__ZoneDeStockageDTO) : ZoneDeStockageDTO{
    const zds = new ZoneDeStockageDTO();
    zds.actif = udpZds.actifZoneDeStockage;
    zds.code = udpZds.codeZoneDeStockage;
    zds.id = udpZds.idZoneDeStockage;
    zds.libelle = udpZds.libelleZoneDeStockage;

    return zds;
  }

  createEmptyDTO(): ZoneDeStockageDTO {

    return new ZoneDeStockageDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA ZONE DE STOCKAGE '${data.libelle.toUpperCase()}'`;
  }
}

export interface ZoneDeStockageSavedSupplier {
  isUpdate: boolean;
  zoneDeStockage: ZoneDeStockageDTO;
}

