import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {ContratMenuService} from '../../core/services/gestioncontrats/contrat-menu.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'yo-gestioncontrats-root',
  templateUrl: './gestioncontrats-root.component.html',
  styleUrls: ['./gestioncontrats-root.component.scss']
})
export class GestioncontratsRootComponent implements OnInit, OnDestroy {

  private subContratsMenus: Subscription;

  constructor(private utils: UtilsService, private contratMenuSvc: ContratMenuService) {
  }

  ngOnInit() {
    this.subContratsMenus = this.contratMenuSvc.getAllContratsMenus().subscribe(response => {
      this.contratMenuSvc.announceObjects(response.resultList);
    });
    this.utils.setTitle('GESTION DES OFFRES ALIMENTAIRES');
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subContratsMenus);
  }

}
