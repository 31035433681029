import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GestionLotMarcheService} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {combineLatest, Observable} from 'rxjs';
import {LotMarcheService} from '../../../../../core/services/entities/lot-marche.service';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../../../../core/services/entities/unites-de-production.service';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {FournisseurService} from '../../../../../core/services/entities/fournisseur.service';
import {FournisseurDTO} from '../../../../../core/dtos/fournisseur-d-t-o';
import {ProduitDeclinaisonDTO} from '../../../../../core/dtos/produit-declinaison-dto';
import {GestionsMarcheService} from '../../../../../core/services/gestion-marche/gestions-marche.service';
import {TYPE_LIST_UDP} from "../../../../../core/constants";


@Injectable({
  providedIn: 'root'
})
export class ContextLotMarcheResolverService implements Resolve<ContextLotMarcheSupplier> {

  constructor(private lotMarcheSvc: LotMarcheService,
              private udpSvc: UnitesDeProductionService,
              private fournisseurSvc: FournisseurService,
              private gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private gestionsMarcheSvc: GestionsMarcheService,
              private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextLotMarcheSupplier> | Promise<ContextLotMarcheSupplier> | ContextLotMarcheSupplier {
    let contextLotMarcheSupplier: ContextLotMarcheSupplier = new ContextLotMarcheSupplier();
    let idLotMarche: number = +route.paramMap.get('idLotMarche');

    const uniteDeProductionList$ = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const fournisseurList$ = this.gds.getAll(this.fournisseurSvc.getEntityName(), ['libelle,asc'], true);
    const selectedUniteDeProductionList$ = this.gestionLotMarcheSvc.getUniteProdByLmId(idLotMarche);
    const selectedFournisseurList$ = this.gestionLotMarcheSvc.getFournisseurByLmId(idLotMarche);
    const selectedDenree$ = this.gestionLotMarcheSvc.getProdDecliByLmId(idLotMarche);

    if (idLotMarche > 0) {
      const lotMarche$ = this.gestionLotMarcheSvc.getById(idLotMarche);

      const all$ = combineLatest([lotMarche$, uniteDeProductionList$, fournisseurList$, selectedUniteDeProductionList$, selectedFournisseurList$, selectedDenree$]);
      return all$.toPromise().then(data => {
        contextLotMarcheSupplier.lotMarche = data[0].one;

        const uniteDeProductionList = data[1].resultList;
        contextLotMarcheSupplier.uniteDeProductionList = uniteDeProductionList.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id));

        contextLotMarcheSupplier.fournisseurList = data[2].resultList;
        contextLotMarcheSupplier.selectedUniteDeProductionList = data[3].resultList;
        contextLotMarcheSupplier.selectedFournisseurList = data[4].resultList;
        contextLotMarcheSupplier.selectedDenreeList = data[5].resultList;

        return contextLotMarcheSupplier;
      });
    }
  }
}


export class ContextLotMarcheSupplier {
  lotMarche: LotMarcheDTO;
  uniteDeProductionList: UniteDeProductionDTO[] = [];
  fournisseurList: FournisseurDTO[] = [];
  selectedDenreeList: ProduitDeclinaisonDTO[] = [];
  selectedUniteDeProductionList: UniteDeProductionDTO[] = [];
  selectedFournisseurList: FournisseurDTO[] = [];
}
