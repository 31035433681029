import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';

import {MenuItem} from 'primeng/api';
import {TieredMenu} from 'primeng/tieredmenu';
import {Subscription} from 'rxjs';
import {cloneDeep as _cloneDeep} from 'lodash';

@Component({
  selector: 'yo-left-app-menu',
  templateUrl: './left-app-menu.component.html',
  styleUrls: ['./left-app-menu.component.scss']
})
export class LeftAppMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  subActivatedRoute: Subscription;

  @ViewChild('menu') menu: TieredMenu;

  subMenuItems: any[] = [];

  isSideMenuOpened: boolean = false;

  private closedSidebarSize = 40;
  private openedSidebarSize = 300;

  constructor(public itemNavSvc: ItemNavigationService, public router: Router, public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initSidebar();
    // detecter la route active
    this.activatedRouteSubscription();
  }

  openMenu = (idSubMenu: string, menu: TieredMenu) => {
    // fix change detection p-tiered-menu
    document.getElementById(idSubMenu).click();
    setTimeout(() => {
      document.getElementById(idSubMenu).click();
    }, 50);

  };

  initSidebar = () => {
    window.addEventListener('resize', this.updateSidebarStatus, true);
    this.updateSidebarStatus(null);
  }

  updateSidebarStatus = (event) => {
    this.isSideMenuOpened = window.innerWidth > 1200;
    this.updateCssVariables();
  }

  activatedRouteSubscription = () => {
    this.subActivatedRoute = this.itemNavSvc.primaryRoute$.subscribe(event => {

      if (event) {
        const elts = this.itemNavSvc.selectMenuItem(event.url);
        this.itemNavSvc.selectedMenuItem = elts.selectedMenuItem;
        this.itemNavSvc.selectedFlMenuItem = elts.selectedFlMenuItem;
        this.itemNavSvc.newFlMenuItem = null;

        if (this.menu) {
          this.menu.hide();
        }
      }
    });
  };


  openRealMenu = (item: MenuItem, menu: TieredMenu, event: MouseEvent) => {
    this.itemNavSvc.newFlMenuItem = item;

    // 1 seul niveau
    if (this.utils.isCollectionNullOrEmpty(item.items)) {

      // suppression de l'icone dans le sous menu
      const dupItem = _cloneDeep(item);
      dupItem.icon = '';
      this.subMenuItems = [dupItem];
    }
    // sous menu
    else {
      this.subMenuItems = item.items;
    }
    if (this.menu) {
      this.menu.show(event);
    }
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subActivatedRoute);
  }

  ngAfterViewInit(): void {
  }

  toggleSideMenu = (): void => {
    this.isSideMenuOpened = !this.isSideMenuOpened;
    this.updateCssVariables();
  }

  updateCssVariables = (): void => {
    document.documentElement.style.setProperty("--sidebar-size", this.isSideMenuOpened ? `${this.openedSidebarSize}px` : `${this.closedSidebarSize}px`)
  }

}
