import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {PointDeLivraisonDTO} from "../../core/dtos/point-de-livraison-d-t-o";
import {UtilsService} from "../../core/utils/utils.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {DialogMsgSupplier} from "../../core/suppliers/dialog-msg-supplier";
import {ClientWithPlcDTO} from "../../core/dtos/client-with-plc.dto";
import {RoutemapService} from "../../core/services/routemap.service";
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {ClientsSupplier} from "../clients/clients-resolver.service";
import {ActivatedRoute} from "@angular/router";
import {ClientsService} from "../../core/services/entities/clients.service";
import {PointDeLivraisonService} from "../../core/services/entities/point-de-livraison.service";
import {GenericFormService} from "../../core/services/generics/generic-form.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";
import {DropMenuInterface} from "../../shared/ui/drop-menu/drop-menu-interface.component";

@Component({
  selector: 'yo-grid-clients',
  templateUrl: './grid-clients.component.html',
  styleUrls: ['./grid-clients.component.scss']
})
export class GridClientsComponent implements OnInit, OnDestroy {

  title: string;
  subRoute: Subscription;
  subClients: Subscription;
  subSavedClient: Subscription;
  subSavedPointDeLivraison: Subscription;
  private subRefreshGrid: Subscription;
  private subRefreshGridFromClients: Subscription;

  selectedPlc: PointDeLivraisonDTO;

  clientsList: ClientWithPlcDTO[] = [];
  clientsListFiltered: ClientWithPlcDTO[] = [];
  selectedClient: ClientWithPlcDTO;

  private filterPlcValue: string;
  hasGestionClients: boolean;

  totalRow: number = 0;

  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'site', header: 'Site'},
    {field: 'libelle', header: 'Client'},
    {field: 'plcList', header: 'Point(s) de livraison(s)'},
    {field: 'adresse', header: 'Adresse'},
    {field: 'actif', header: 'Actif'}
  ];

  /**
   * Selection des actions sur un PLC
   */
  plcActions: DropMenuInterface[] = [];

  pathFile: string = HELP_FOLDERS.CLIENTS + '/clients';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public gds: GenericDatagridService,
              private routeMapSvc: RoutemapService,
              private clientsSvc: ClientsService,
              private pdlSvc: PointDeLivraisonService,
              private gfs: GenericFormService,
              private route: ActivatedRoute,
              private plcSvc: PointDeLivraisonService,
              private toastSvc: ToastService) {
    this.initPlcsActions();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subClients);
    this.utils.unsubscribe(this.subSavedClient);
    this.utils.unsubscribe(this.subSavedPointDeLivraison);
    this.utils.unsubscribe(this.subRefreshGridFromClients);
  }

  ngOnInit(): void {
    this.initHasGestionClientSubscription();
    this.initClientsSubscription();
    this.initSavedPointDeLivraisonSubscription();
    this.initRefreshgrid();
    this.refreshGrid();
  }

  initHasGestionClientSubscription = (): void => {
    this.auth2Svc.hasGestionClients$
      .subscribe(response => this.hasGestionClients = response);
  };


  initClientsSubscription = (): void => {
    this.subClients = this.route.data.subscribe((data: { clientsSupplier: ClientsSupplier }) => {
      this.clientsList = data.clientsSupplier.clients as ClientWithPlcDTO[];
      this.gds.search(this.pdlSvc.findByClientsIds(this.clientsList.map(client => client.id)))
        .toPromise().then(data => {
        let plcList = data.resultList;
        this.addPlcToClients(this.clientsList, plcList);
      });
    });
    this.clientsListFiltered = this.clientsList
  };

  addPlcToClients = (clientsList: ClientWithPlcDTO[], plcList: PointDeLivraisonDTO[]): ClientWithPlcDTO[] => {
    if (this.utils.isCollectionNullOrEmpty(clientsList)) {
      clientsList = [];
    } else {
      clientsList.forEach(client => {
        let plc = plcList.filter(p => p.client.id === client.id);
        if (plc.length) {
          client.plcList = plc;
        }
      });
    }
    return clientsList;
  };

  initSavedClientSubscription = (): void => {
    // un client a été modifié ou ajouté, on s'abonne pour mettre à jour l'arbre
    this.subSavedClient = this.clientsSvc.savedDto$.subscribe(response => {
    });
  };

  initSavedPointDeLivraisonSubscription = (): void => {
    // un point de livraison a été créé ou modifié
    this.subSavedPointDeLivraison = this.pdlSvc.savedDto$.subscribe(response => {
      const plc: PointDeLivraisonDTO = response.one as PointDeLivraisonDTO;
      const client = plc.client;
      const clientFromList: ClientWithPlcDTO = this.clientsList.find(c => c.id === client.id);
      const plcList: PointDeLivraisonDTO[] = clientFromList.plcList;
      const idxPlcFound = plcList.findIndex(p => p.id === plc.id);
      if (idxPlcFound > -1) {
        plcList[idxPlcFound] = plc;
      } else {
        plcList.push(plc);
      }
    });
  };

  canCreate = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasGestionClients;

  openPlc = (client: ClientWithPlcDTO, plc: PointDeLivraisonDTO) => {
    if (this.utils.isNullOrEmpty(plc)) {
      plc = {id: 0} as PointDeLivraisonDTO;
    }
    this.routeMapSvc.goToSecondaryRoute(['point-de-livraison', client ? client.id : plc.client.id, plc.id, 'ficheidentite']);
  };

  closeDropMenu = (res: boolean): void => {
    if (res) this.selectedPlc = null;
  }

  choosePlcAction = ($event: MouseEvent, plc: PointDeLivraisonDTO) => {
    this.selectedPlc = plc;
  };

  onFilterdata = (event: any) => {
    this.totalRow = event.filteredValue.length;
  };

  openEditObject = (client: ClientWithPlcDTO) => {
    if (this.utils.isNullOrEmpty(client)) {
      client = {id: 0} as ClientWithPlcDTO;
    }
    this.routeMapSvc.goToSecondaryRoute(['clients', client.id, 'ficheidentite']);
  };

  openDeleteObject = async (client: ClientWithPlcDTO) => {
    const result = confirm(`Etes-vous sûr de vouloir supprimer ce client  '${client.libelle}' ?`, 'Suppression du client');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.clientsSvc.deleteClient(client)
        .subscribe(result => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le client ${client.libelle} a été supprimé avec succès`);
          this.clientsListFiltered = this.clientsListFiltered.filter(c => c.id !== client.id);
          this.clientsList = this.clientsList.filter(c => c.id !== client.id);
        });
    }
  };

  refreshGrid = (): void => {
    this.subRefreshGrid = this.clientsSvc.refreshGrid$.subscribe((editedClient:ClientWithPlcDTO) => {
      const indexClient = this.clientsList.findIndex(client => client.id === editedClient.id)
      if (indexClient !== -1)
        this.clientsList[indexClient] = editedClient
      else
        this.clientsList.push(editedClient)
      this.clientsListFiltered = this.clientsList
    })
  }

  deletePointDeLivraison = async (pointDeLivraison: PointDeLivraisonDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer le point de livraison "${pointDeLivraison.libelle}" ?`,
      'Suppression du point de livraison');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.gfs.deleteOne(this.pdlSvc.getEntityName(), pointDeLivraison.id).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le point de livraison ${pointDeLivraison.libelle} a été supprimé avec succès`);
          // annoncer que le point de livraison a été supprimé à l'arbre
          const clientFromList: ClientWithPlcDTO = this.clientsList.find(c => c.id === pointDeLivraison.client.id);
          const plcList: PointDeLivraisonDTO[] = clientFromList.plcList;
          clientFromList.plcList = plcList.filter(plc => plc.id !== pointDeLivraison.id);
          this.clientsListFiltered = this.clientsList
          this.pdlSvc.announceDeletedDTO(response);
        }
      });
    }
  };

  isDisabledBtn = () => {
    if (!this.hasGestionClients || this.utils.isNullOrEmpty(this.selectedPlc)) {
      return false;
    }
    return !this.canCreate(); // plc ne dispose pas de site donc je fais comme ça
  };

  initPlcsActions = () => {
    this.plcActions = [
      {
        label: 'Voir le point de livraison',
        iconClass: 'fas fa-file-invoice blue',
        actionCallbackClick: () => this.openPlc(this.selectedClient, this.selectedPlc)
      }
    ];

    if (!this.isDisabledBtn()) {
      this.plcActions.push({
        label: 'Supprimer le point de livraison',
        iconClass: 'fas fa-trash-alt red',
        actionCallbackClick: () => this.deletePointDeLivraison(this.selectedPlc)
      });
    }

  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Clients et leurs points de livraison`;
    return dms;
  };

  calculateFilterExpression = (filterValue) => {
    const listFiltered = this.clientsList.filter(clf => clf.plcList?.some(plc => plc.libelle.toLowerCase().includes(filterValue.toLowerCase())));
    if (filterValue && listFiltered.length !== this.clientsListFiltered.length) {
      this.clientsListFiltered = listFiltered
    }
  }

  checkFilterExpression = (data) => {
    this.filterPlcValue = data
    if (!data && this.clientsListFiltered.length != this.clientsList.length)
      this.clientsListFiltered = this.clientsList
  }

  private initRefreshgrid = (): void => {
    this.subRefreshGrid = this.plcSvc.refreshGrid$.subscribe((data: PointDeLivraisonDTO) => {
      if (data) {
        const indexClient = this.clientsList.findIndex(client => client.id === data.client.id)
        const indexPlc = this.clientsListFiltered[indexClient].plcList.findIndex(plc => plc.id === data.id)
        if (indexPlc !== -1)
          this.clientsList[indexClient].plcList[indexPlc] = data
        else
          [data, ...this.clientsList[indexClient].plcList]
        this.clientsListFiltered = this.clientsList
      }
    });
  }

  isPlcFiltered = (plcLibelle): boolean => {
    if (this.filterPlcValue)
      return plcLibelle.toLowerCase().includes(this.filterPlcValue.toLowerCase())
    return false
  }
}
