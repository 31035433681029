import {ObjectDTO} from '../object-dto';
import ConditionnementDTO from './conditionnement-dto';
import VarianteDTO from './variante-dto';

export default class ConditionnementVarianteDTO extends ObjectDTO {

  conditionnement: ConditionnementDTO;

  variante: VarianteDTO;

}
