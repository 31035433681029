export class SaveCommandeDenreeModel {

  idGroupeAchat_CatalogueAchat: number;

  idProduitDeclinaison: number;

  libelleProduitDeclinaison: string;

  dateLivraisonPossible: Date;

  quantiteUC: number;

  quantiteUT: number;

  idFournisseur: number;
}
