
  <div class="d-flex justify-content-between mg-t-15 mg-b-10">
    <span>Veuillez sélectionner les zones de stockages à activer avec l'unité de production {{ uniteDeProduction.libelle }}</span>
  </div>


  <fieldset>
    <div class="row">
      <div class="col-md-auto">
        <div class="row">
          <div class="col-md-12">
            <h3 class="first">Zones de stockage</h3>
            <dx-list
              #list
              [dataSource]="dataSource"
              selectionMode="multiple"
              selectAllMode="allPages"
              displayExpr="libelle"
              valueExpr="id"
              [showSelectionControls]="true"
              [searchEnabled]="true"
              searchExpr="libelle"
              searchMode="contains"
              (onSelectionChanged)="onChangeZoneDeStockage($event)"
              [selectedItemKeys]="zoneDeStockageSelectedList"
            >
              <div *dxTemplate="let item of 'item'">
                {{ item.libelle }} <i title="Cette zone de stockage est inactive" class="fas fa-exclamation-triangle danger-color space" *ngIf="!item.actif"></i>
              </div>
            </dx-list>
          </div>
        </div>
      </div>
    </div>
  </fieldset>


