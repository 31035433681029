import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {ProduitsService} from "../../core/services/entities/produits.service";
import {SiteDTO} from "../../core/dtos/site-dto";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {DeclinaisonDTO} from "../../core/dtos/declinaison-dto";
import {RegimeAlimentaireDTO} from "../../core/dtos/regime-alimentaire-dto";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {TypeProduitDTO} from "../../core/dtos/type-produit-dto";
import {DxTreeViewComponent} from "devextreme-angular";
import {TachesService} from "../../core/services/taches/taches.service";
import {TacheDTO} from "../../core/dtos/tache-dto";
import {ToastService} from "../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";

@Component({
  selector: 'yo-dialog-batch-produits',
  templateUrl: './dialog-batch-produits.component.html',
  styleUrls: ['./dialog-batch-produits.component.scss']
})
export class DialogBatchProduitsComponent implements OnInit, OnDestroy {

  displayDialog: boolean = false;

  fullScreen: boolean = false;

  subscriptionDialog$: Subscription;

  subscriptionDeclinaisons$: Subscription;

  subscriptionRegimes$: Subscription;

  subscriptionTypesProduit$: Subscription;

  subscriptionFamilles$: Subscription;

  subscriptionTypesFab$: Subscription;

  sites: SiteDTO[] = [];

  allDeclinaisonsItems: DeclinaisonDTO[] = [];

  allRegimesItems: RegimeAlimentaireDTO[] = [];

  allTypesProduitsItems: TypeProduitDTO[] = [];

  taches: TacheDTO[] = [];

  allFamillesProduits: any[] = [];

  treeDataSource: any;

  treeBoxValue: number[];

  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;

  filterLabel: string;

  filterCode: string;

  sitesSelected: number[]  = [];

  typesProduitsSelected: TypeProduitDTO[] = [];

  declinaisonsSelected: number[]  = [];

  regimesSelected: number[]  = [];

  optionAddRegimesOnlyMeals: boolean = true;

  optionAddTypesFabOnlyMeals: boolean = true;

  regimesToAdd: number[];
  typeToSave: number;

  constructor(private utilsSvc: UtilsService,
              private produitsSvc: ProduitsService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService,
              private tacheSvc: TachesService,
              private toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subscriptionDialog$);
    this.utilsSvc.unsubscribe(this.subscriptionDeclinaisons$);
    this.utilsSvc.unsubscribe(this.subscriptionRegimes$);
    this.utilsSvc.unsubscribe(this.subscriptionTypesProduit$);
    this.utilsSvc.unsubscribe(this.subscriptionDeclinaisons$);
    this.utilsSvc.unsubscribe(this.subscriptionFamilles$);
    this.utilsSvc.unsubscribe(this.subscriptionTypesFab$);
  }

  closeDialog = (): void => {
    this.clean();
    this.displayDialog = false;
  }

  clean = (): void => {
    this.filterLabel = "";
    this.filterCode = "";
    this.treeBoxValue = [];
    this.sitesSelected = [];
    this.typesProduitsSelected = [];
    this.declinaisonsSelected = [];
    this.regimesSelected = [];
    this.optionAddRegimesOnlyMeals = true;
    this.optionAddTypesFabOnlyMeals = true;
    this.regimesToAdd = [];
    this.typeToSave = undefined;
    this.treeView?.instance?.unselectAll();
  }

  ngOnInit(): void {
    this.initSubscriptionDialog();
    this.loadFilterSitesSubscription();
    this.loadFilterDeclinaisonsSubscription();
    this.loadRegimesSubscription();
    this.loadTypesProduitSubscription();
    this.loadFamillesSubscription();
    this.loadTypesFab();
  }

  initSubscriptionDialog = (): void => {
    this.subscriptionDialog$ = this.produitsSvc.dialogBatchSubject$
                                   .subscribe(() => this.displayDialog = true);
  }

  loadFilterDeclinaisonsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subscriptionDeclinaisons$ = this.graphQlSvc.sendQuery(`
        {
          allDeclinaisons(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `).subscribe(response => this.allDeclinaisonsItems = response.allDeclinaisons);
  };

  loadFilterSitesSubscription = (): void => {
    this.sites = this.auth2Svc.utilisateur.sites.map(site => ({
      libelle: site.libelle,
      id: site.id
    }));
  };

  loadRegimesSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subscriptionRegimes$ = this.graphQlSvc.sendQuery(`
    {
      allRegimesAlimentaires(filters: {
          siteIds: [${idsSites}]
        }) {
        id,
        libelle,
      }
    }
    `).subscribe(response => this.allRegimesItems = response.allRegimesAlimentaires);
  }

  loadTypesProduitSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subscriptionTypesProduit$ = this.graphQlSvc.sendQuery(`
      {
        allTypesDeProduit(filters: {
          siteIds: [${idsSites}]
        }) {
          id,
          libelle,
          fabrique
      }
    }
    `).subscribe(response => this.allTypesProduitsItems = response.allTypesDeProduit);
  }

  loadFamillesSubscription = (): void => {
    this.subscriptionFamilles$ = this.graphQlSvc.sendQuery(`
            {
              allFamillesDeProduit(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}]
              }) {
                  id,
                  libelle,
                  parent { id, libelle }
              }
            }
          `)
      .subscribe(response => {
        this.allFamillesProduits = response.allFamillesDeProduit;
        this.allFamillesProduits = this.allFamillesProduits.map(fp => { if (fp.parent?.id === -1) fp.parent = null; return fp; });
      });
  }

  loadTypesFab = (): void => {
    this.subscriptionTypesFab$ = this.tacheSvc.getAll()
      .subscribe(response => {
        this.taches = response.resultList?.filter(t => t.typeTacheCode === 'FAB');
      });
  }

  onDropDownBoxValueChanged(e: any) {
    this.updateSelection(this.treeView && this.treeView.instance);
  }

  onTreeViewReady(e: any) {
    this.updateSelection(e.component);
  }

  updateSelection(treeView: any) {
    if (!treeView) return;

    if (!this.treeBoxValue) {
      treeView.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(((value) => {
        treeView.selectItem(value);
      }));
    }
  }

  onTreeViewSelectionChanged(e: any) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  onTagBoxValueChanged(e: any) {
    if (!this.treeView) return;
    this.treeView.instance.unselectAll();
    this.updateSelection(this.treeView.instance);
  }

  containsTypeProduitDenree = (): boolean => {
    return this.typesProduitsSelected?.filter(tp => !tp.fabrique)?.length > 0;
  }

  save = (): void => {
    let idsFamilles: number[] = (this.treeBoxValue && this.treeBoxValue.length) ? this.allFamillesProduits.filter(f => this.treeBoxValue.includes(f.id)).map(f => f.id ) : []
    this.produitsSvc.applyMassiveTreatments({
      filterLabel: this.filterLabel,
      filterCode: this.filterCode,
      idsFamilles,
      sitesSelected: this.sitesSelected,
      typesProduitsSelected: this.typesProduitsSelected.map(tp => tp.id),
      declinaisonsSelected: this.declinaisonsSelected,
      regimesSelected: this.regimesSelected,
      optionAddRegimesOnlyMeals: this.optionAddRegimesOnlyMeals,
      optionAddTypesFabOnlyMeals: this.optionAddTypesFabOnlyMeals,
      regimesToAdd: this.regimesToAdd,
      typeToSave: this.typeToSave
    }).subscribe(() => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le traitement en masse a été réalisé avec succès`);
      this.closeDialog();
    });
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

}
