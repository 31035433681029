<div class="d-flex flex-grow-1" [style.height]="'calc(68vh)'">
  <div class="d-flex flex-column">

    <div *ngIf="!utils.isNullOrEmpty(lotMarche)" class="flex-grow-1">
      <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getWindowAvailableWidth(300)}">
      </yo-form>
    </div>
  </div>
</div>
<hr/>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>
  </div>
  <div>
    <span class="mg-r-5">
      <yo-button
        iconClass="fas fa-save"
        (onClick)="saveFicheIdentite()"
        [disabled]="isDisabledSaveBtn()"
        tooltip="Enregistrer"
        buttonClass="cta-success">
      </yo-button>
    </span>
    <span>
      <yo-button
        iconClass="fas fa-times"
        tooltip="Fermer"
        (onClick)="closeDialog()"
        buttonClass="cta-inner-delete">
      </yo-button>
    </span>
  </div>
</div>

