import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from './form-fieldbase-supplier';

export class GroupFormFields {

  /*
   *titre du groupe
   */
  titlePanel: string;

  /**
   * Icon du titre
   */
  titleIcon: string;

  /**
   * Formulaire du groupe
   */
  form: FormGroup;

  /**
   * Champs à utiliser dans le groupe
   */
  fields: FormFieldBaseSupplier<any>[];

  /**
   * A l'intérieur du groupe, positionner les champs dans la colonne voulue
   */
  colsOrganization: ColsOrganization;

  /**
   * Positionner le groupe dans<br>
   *  la colonne 1 <br>
   *  ou la colonne 2<br>
   *  ou la colonne 1 et 2
   */
  position: GROUP_POSITION;



}

export enum GROUP_POSITION {
  COL1 = 'COL1',
  COL2 = 'COL2',
  COL1_AND_COL2 = 'COL1_AND_COL2',
}


export interface ColsOrganization {

  /**
   * Tableau de key(s) des champs à mettre dans la colonne 1
   */
  col1?: string[];

  /**
   * Tableau de key(s) des champs à mettre dans la colonne 2
   */
  col2?: string[];

}
