<dx-popup
  [showTitle]="true"
  [title]="'Dates de livraison possible'"
  [(visible)]="displayDialog"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <span>
    Avant de valider la commande, une des articles n'a pas de date de livraison possible par rapport au planning de
    livraison de son fournisseur.
  </span>
  <dx-data-grid
    class="pt-4 pb-4"
    [dataSource]="possibleDeliveryDates"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [columnAutoWidth]="true"
  >
    <dxi-column dataField="libelleProduitDeclinaison" alignment="left" caption="Libellé produit déclinaison"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="dateLivraisonPossible" alignment="left" caption="Date livraison possible"
                [allowFiltering]="true"
                cellTemplate="dateCellTemplate">
    </dxi-column>

    <div *dxTemplate="let cell of 'fournisseurCellTemplate'">
      {{cell.row.data.fournisseur?.libelle}}
    </div>
    <div *dxTemplate="let cell of 'dateCellTemplate'">
      {{cell.row.data.dateLivraisonPossible | date:'dd/MM/yyyy'}}
    </div>

    <dxo-scrolling mode="virtual"></dxo-scrolling>
  </dx-data-grid>
  <span>
    Veuillez modifier la date de livraison au plus tard par rapport à cette (ces) date(s) de livraison proposée(s).
    Puis valider la proposition de commande pour la sauvegarder.
  </span>
</dx-popup>
