import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {DxDataGridComponent} from "devextreme-angular";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {SearchSupplierWrapper} from "../../../core/suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../../core/suppliers/search-supplier";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {ConvivesService} from "../../../core/services/entities/convives.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {ConviveDTO} from "../../../core/dtos/convive-dto";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-10',
  templateUrl: './wizard-configuration-step-10.component.html',
  styleUrls: ['./wizard-configuration-step-10.component.scss']
})
export class WizardConfigurationStepTenComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  @ViewChild("grid") grid: DxDataGridComponent;

  private currentStep: number;

  pointsLivraisons: PointDeLivraisonDTO[] = [];

  prestations: ConviveDTO[] = [];

  subPlcs: Subscription;

  allMode: string = 'allPages';

  checkBoxesMode: string = 'always';

  selectedRows: string[] = [];

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly plcSvc: PointDeLivraisonService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly prestationSvc: ConvivesService,
              private readonly utilsSvc: UtilsService,
              private readonly wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_SELECT_PLCS;
  }


  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subPlcs);
  }

  ngOnInit(): void {
    this.initPointsLivraisons();
  }

  initPointsLivraisons = (): void => {
    const dataStepNine: any = this.wizardSvc.getDataStepNine();
    const dataStepTen: any = this.wizardSvc.getDataStepTen();
    const sitesIds = this.auth2Svc.utilisateur.sites.map(item => item.id);

    const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();

    ssw.filtersMap['sites'] = new SearchSupplier(undefined, sitesIds);
    ssw.filtersMap['idsPrestations'] = new SearchSupplier(undefined, dataStepNine.prestationsSelected);
    if (!dataStepTen.pointsLivraisonsKeys?.length)
      ssw.filtersMap['withoutModeleConditionnementPlc'] = new SearchSupplier(true, undefined);

    this.subPlcs = this.plcSvc.getPlcWithoutMcPlcList(undefined, undefined, ssw)
      .subscribe((res) => {
        let prestationsByPlc: any = {};
        this.pointsLivraisons = res.resultList;
        this.pointsLivraisons.forEach(plc => {
          plc.keyPlcPrestation = `${plc.id}{}${plc.prestation?.libelle}`;
          if(!prestationsByPlc[plc.id])  prestationsByPlc[plc.id] = [];
          prestationsByPlc[plc.id].push(plc.prestation?.libelle);
        });
        this.pointsLivraisons = [ ...new Map(this.pointsLivraisons.map((item) => [item["keyPlcPrestation"], item])).values() ];
        this.pointsLivraisons.forEach(plc => plc.prestation.libelle = prestationsByPlc[plc.id].join(","));
        this.selectedRows = this.pointsLivraisons.filter(pl =>
          dataStepTen.pointsLivraisonsKeys.includes(pl.keyPlcPrestation)
        ).map(pl => pl.keyPlcPrestation);
    });
  }

  convertNumber = (value): number => parseInt(value, 10);

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      pointsLivraisonsIds: this.selectedRows.map(s => s.split("{}")[0]),
      pointsLivraisonsKeys: this.selectedRows
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
