import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ContratMenuService} from '../../../../core/services/gestioncontrats/contrat-menu.service';
import {ContratsMenusConvivesRepasService} from '../../../../core/services/entities/contrats-menus-convives-repas.service';
import {RepasService} from '../../../../core/services/entities/repas.service';
import {DecoupagesRepasService} from '../../../../core/services/entities/decoupages-repas.service';
import {ContratsMenusConvivesDecoupagesService} from '../../../../core/services/entities/contrats-menus-convives-decoupages.service';
import {PointDeLivraisonService} from '../../../../core/services/entities/point-de-livraison.service';
import {ModelesNutritionnelsService} from '../../../../core/services/entities/modeles-nutritionnels.service';
import {GestionGemrcnService} from '../../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ContratsMenusConvivesService} from '../../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {combineLatest, Observable} from 'rxjs';
import {CmcPlcSupplier} from '../cmc-points-de-livraison-resolver.service';
import {CmcPlanningResolverService} from '../../cmc-planning/cmc-planning-resolver.service';
import {CmcdPlcService} from '../../../../core/services/entities/cmcd-plc.service';
import {CmcPlcService} from '../../../../core/services/entities/cmc-plc.service';
import {CmcMnService} from '../../../../core/services/entities/cmc-mn.service';

@Injectable({
  providedIn: 'root'
})
export class CmcPointDeLivraisonResolverService implements Resolve<CmcPlcSupplier> {

  constructor(private gds: GenericDatagridService,
              private utils: UtilsService,
              private contratMenuSvc: ContratMenuService,
              private cmcrSvc: ContratsMenusConvivesRepasService,
              private cmcPlanningResolverSvc: CmcPlanningResolverService,
              private repasSvc: RepasService,
              private decoupageRepasSvc: DecoupagesRepasService,
              private cmcdSvc: ContratsMenusConvivesDecoupagesService,
              private pdlSvc: PointDeLivraisonService,
              private cmcdPlcSvc: CmcdPlcService,
              private cmcPlcSvc: CmcPlcService,
              private modeleNutriSvc: ModelesNutritionnelsService,
              private ggSvc: GestionGemrcnService,
              private cmcMnSvc: CmcMnService,
              private cmcSvc: ContratsMenusConvivesService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CmcPlcSupplier> | Promise<CmcPlcSupplier> | CmcPlcSupplier => {

    const cmcPlcSupplier = new CmcPlcSupplier();
    const idPlc: number = +route.paramMap.get('idPlc');
    const idCmcMn: number = +route.paramMap.get('idContratMenuConviveModeleNutri');
    const idContratMenuConvive: number = +route.parent.parent.paramMap.get('idContratMenuConvive');

    const cmc$ = this.gds.getOne(this.cmcSvc.getEntityName(), idContratMenuConvive);
    const plc$ = this.gds.getOne(this.pdlSvc.getEntityName(), idPlc);
    const planning$ = this.cmcMnSvc.loadPlanning(idCmcMn, idPlc, 2);

    const all$ = combineLatest([cmc$, planning$, plc$]);

    return all$
      .toPromise().then(response => {

        cmcPlcSupplier.contratMenuConvive = response[0].one;
        cmcPlcSupplier.rows = response[1].additionalProperties['rows'];
        cmcPlcSupplier.cols = response[1].additionalProperties['cols'];
        cmcPlcSupplier.cmcdList = response[1].additionalProperties['cells'];
        cmcPlcSupplier.cmcdPlcList = response[1].additionalProperties['cmcdPlcList'];
        cmcPlcSupplier.cmcrPlcList = response[1].additionalProperties['cmcrPlcList'];
        cmcPlcSupplier.joursSemaine = response[1].additionalProperties['joursOuvres'];
        cmcPlcSupplier.repasList = response[1].additionalProperties['repasList'];
        cmcPlcSupplier.maxPlats = response[1].additionalProperties['maxPlats'];
        cmcPlcSupplier.selectedPeriode = response[1].one;
        cmcPlcSupplier.selectedPointDeLivraison = response[2].one;


        return cmcPlcSupplier;

      });

  };


}



