import {ObjectDTO} from './object-dto';
import {MotifNonConformiteDTO} from "./motif-non-conformite-dto";

export class BonReceptionLigneMotifNonConfoDTO extends ObjectDTO {

  // bon reception ligne
   idBonReceptionLigne : number;

  // motif non conformite
  idMotifNonConformite : number;
  libelleMotifNonConformite : string;

  motifNonConformite: MotifNonConformiteDTO;

  // commentaire
  commentaire : string;

}
