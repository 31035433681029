import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {ProduitAppellationDTO} from '../../core/dtos/produit-appellation-dto';
import {isEmpty as _isEmpty} from 'lodash'
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {USER_PREFERENCE} from '../../core/constants';


@Component({
  selector: 'yo-appellations-menucompo',
  templateUrl: './appellations-menucompo.component.html',
  styleUrls: ['./appellations-menucompo.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class AppellationsMenucompoComponent implements OnInit, OnDestroy  {

  //Subscription
  subDisplayAppellations: Subscription;
  displayAppellations: boolean = false;
  refreshImg:number=new Date().getTime();

  @Input() set produitAppellations (values: ProduitAppellationDTO[]){
    this._produitAppellations=values;
  }

  _produitAppellations:ProduitAppellationDTO[]=[];

  constructor(private cd: ChangeDetectorRef,
              public appellationSvc:AppellationsService,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.subDisplayAppellations = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayAppellations = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_APPELLATIONS);
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayAppellations);
  }

  getInfoAppellations = (): string => 'Appellations : ' + this._produitAppellations.map(item => item.appellationLibelle).join(', ');

  canDisplayAppellations = (): boolean => this.displayAppellations && !_isEmpty(this._produitAppellations);
}
