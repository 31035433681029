import {FormFieldBaseSupplier} from './form-fieldbase-supplier';


export class FormFieldPrimengEditorSupplier extends FormFieldBaseSupplier<string> {
  controlType = 'primengeditor';
  constructor(options: any){
    super(options);
  }

}
