<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-15"
        label="CRÉER"
        iconClass="fa fa-plus"
        tooltip="Création d'un marché"
        (onClick)="openEditObject(undefined)"
        [disabled]="!canCreate()">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="marcheList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Marché" [width]="350"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="site" caption="Site" [width]="200" cellTemplate="siteCellTemplate"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code" [width]="150" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="descriptif" caption="Description"
              [width]="350"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateDebut" caption="Date de début" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateFin" caption="Date de fin" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="actif" caption="Actif" [width]="80"
              [allowFiltering]="true">
  </dxi-column>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="canModify(cell.row.data) ? 'fa fa-edit' : 'fa fa-eye'"
                    [yoTooltip]="(canModify(cell.row.data) ? 'Modifier' : 'Voir') + ' le marché'"
    ></yo-cell-button>
    <yo-cell-button *ngIf="canModify(cell.row.data)"
                    (yoNavigation)="openDeleteObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer le marché"
    ></yo-cell-button>
  </div>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} marchés">
  </dxo-pager>

</dx-data-grid>




