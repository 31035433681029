import {ObjectDTO} from "./object-dto";
import {RoleDTO} from "./role-dto";

export class ProfilDTO extends ObjectDTO{

  libelle: string;
  code: string;
  actif: boolean;
  description:string

  roleList:RoleDTO[] =[];
}


