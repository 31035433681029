import {Component, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {BoncfService} from "../../../core/services/entities/boncf.service";
import {BonCfDTO} from "../../../core/dtos/boncfs-dto";
import {UniteDeProduction__SecteurFournisseurDTO} from "../../../core/dtos/unite-de-production__secteur-fournisseur-dto";
import {DxCalendarComponent} from "devextreme-angular";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ChoixQteCalculPrixBonCfDetailEnum} from "../../../core/enums/choix-qte-calcul-prix-bon-cf-detail-enum";

@Component({
  selector: 'yo-reassignBcFournisseurDialog',
  templateUrl: './reassign-bc-fournisseur-dialog.component.html',
  styleUrls: ['./reassign-bc-fournisseur-dialog.component.scss']
})
export class ReassignBcFournisseurDialogComponent implements OnInit {

  subOpenDialog: Subscription

  form: FormGroup = new FormGroup({});

  dialogTitle: String = 'Réassigner la commande à un nouveau fournisseur';

  bonCf: BonCfDTO;

  uniteProduction: UniteDeProductionDTO;

  fournisseurs: UniteDeProduction__SecteurFournisseurDTO[];
  isFournisseursListReady: boolean = true;
  selectedFournisseurId: number;

  displayDialog = false;

  // ------------------------------ Calendrier ------------------------------
  joursSemaineFournisseur: number[] = [];
  udpJoursLivraison: number[] = [];
  delaiLivraison = 0;
  heureLimite = new Date();
  minDate: Date = new Date();

  @ViewChild('calendar') calendar: DxCalendarComponent;
  isCalendarReady: boolean = true;

  choixQtes: any[] = [
    {
      label: 'Quantité à commander réajustée',
      value: ChoixQteCalculPrixBonCfDetailEnum.QTE_COMMANDEE_REAJUSTEE
    },
    {
      label: 'Quantité en UT',
      value: ChoixQteCalculPrixBonCfDetailEnum.BESOIN_UT
    }];

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private bonCfSvc: BoncfService,
              private udpSvc: UnitesDeProductionService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openReassignDialogSubscription();

  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openReassignDialogSubscription = (): void => {
    this.subOpenDialog = this.bonCfSvc.openReassignDialog$
      .subscribe((bonCf: BonCfDTO) => {
        this.displayDialog = true;
        this.initData(bonCf);
        this.initForm();
      });
  };

  initData = (bonCf: BonCfDTO): void => {
    this.bonCf = bonCf;

    if (this.utilsSvc.isNullOrEmpty(this.bonCf))
      return;

    this.isFournisseursListReady = false;
    this.isCalendarReady = false;

    this.udpSvc.getUniteDeProductionById(bonCf.uniteDeProductionId).subscribe((response: any) => {
        this.uniteProduction = response.one;

        this.fournisseurs = this.uniteProduction.uniteDeProduction__secteurFournisseurList;
        this.selectedFournisseurId = bonCf.fournisseurId;
        this.udpJoursLivraison = this.udpSvc.getJoursLivraisonOfUdp(this.uniteProduction);
        this.isFournisseursListReady = true;
      }
    );

  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.isFournisseursListReady = false;
    this.isCalendarReady = false;
    this.selectedFournisseurId = null;
  };

  save = (): void => {
    if (this.form.valid) {
      let fournisseurId: number = this.form.controls['fournisseur'].value;
      let dateLivraison: Date = this.form.controls['dateLivraison'].value
      let choixQte: ChoixQteCalculPrixBonCfDetailEnum = this.form.controls['choixQte'].value;

      this.bonCfSvc.reassignBonCf(this.bonCf, fournisseurId, dateLivraison, choixQte)
        .subscribe(response => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Réassignation réalisée avec succès`);
          this.bonCfSvc.announceReassignBonCfResult(response.one);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    let fournisseur: UniteDeProduction__SecteurFournisseurDTO = this.getFournisseur();

    this.form = new FormGroup({
      uniteProduction: new FormControl(this.uniteProduction),
      fournisseur: new FormControl(fournisseur),
      dateLivraison: new FormControl(this.getFirstDateLivraison()),
      choixQte: new FormControl(ChoixQteCalculPrixBonCfDetailEnum.QTE_COMMANDEE_REAJUSTEE)
    });
  };

  getFirstDateLivraison = (): Date => {
    if (this.joursSemaineFournisseur && this.joursSemaineFournisseur.length > 0)
      return this.bonCfSvc.computeFirstDateLivraison(this.joursSemaineFournisseur, this.udpJoursLivraison, this.delaiLivraison, this.heureLimite);
    return null;
  }

  onFournisseurChange = (event: any) => {
    if (event.selectedItem)
      this.getInfosFournisseur(event.selectedItem);
  }

  getInfosFournisseur = (fournisseur: UniteDeProduction__SecteurFournisseurDTO) => {
    this.delaiLivraison = fournisseur.delaiLivraisonJours;
    this.heureLimite = this.utilsSvc.convertNumberDateToDate(fournisseur.delaiLivraisonHeureLimite);
    this.isCalendarReady = false;

    this.udpSvc.getLivraisons(fournisseur).subscribe(response => {
      let days = response.resultList ? response.resultList : [];
      this.joursSemaineFournisseur = days.map(liv => liv.numeroJourSemaine);
      this.form.controls['dateLivraison'].setValue(this.getFirstDateLivraison());

      this.calendar.instance.repaint();

      this.isCalendarReady = true;
    });
  };

  isDateFournisseur = (date: Date): boolean => this.bonCfSvc.isDateFournisseur(
    {year: date.getFullYear(), month: date.getMonth(), day: date.getDate()},
    this.joursSemaineFournisseur,
    this.udpJoursLivraison,
    this.delaiLivraison,
    this.heureLimite);

  getCellCssClass = ($event: any): string => this.isDateFournisseur($event.date) ? 'creation-menus-day' : '';

  disabledDates = ($event: any) => !this.isDateFournisseur($event.date);

  private getFournisseur = (): UniteDeProduction__SecteurFournisseurDTO => this.fournisseurs?.find(fournisseur => fournisseur.fournisseurId === this.selectedFournisseurId);

}
