<h5>

  <label class="badge badge-secondary" *ngIf="utils.isNumberGt0(contratMenuConvive?.id)">
    <i class="fas fa-handshake site-secondaire-color mg-r-5"></i>
    {{contratMenuConvive.contratMenuLibelle | uppercase}} -  {{contratMenuConvive?.libelle | uppercase}}
  </label>
  <label class="badge badge-secondary"
         *ngIf="!utils.isNumberGt0(contratMenuConvive?.convive?.id)">{{contratMenuConvive.contratMenuLibelle | uppercase}}
    - NOUVELLE PRESTATION</label>
</h5>

<p-tabMenu [model]="tabContratMenuConvive" [activeItem]="selectedTabCmc"></p-tabMenu>
<p-card>
  <router-outlet></router-outlet>
</p-card>
