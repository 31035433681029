<form *ngIf="fournisseur" (ngSubmit)="saveFicheIdentite()" #fournisseurForm="ngForm">
  <div class="dialog-line">
    <div class="custom-button-container-right save-container">
      <yo-button
        iconClass="fas fa-save"
        name="save"
        type="submit"
        tooltip="Enregistrer"
        buttonClass="cta-success"
        [disabled]="!isFormValid() || !canEdit()">
      </yo-button>
    </div>
  </div>
  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >
    <div id="fournisseurForm" class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="title-ca">Informations générales</div>
          <div class="container-fournisseur">
            <div class="dialog-line">
              <div class="label" title="site">Site <span class="danger-color">*</span></div>
              <div class="value">
                <yo-lookup
                  *ngIf="create"
                  #siteLookup
                  [data]="sitesList"
                  [selectedItem]="selectedSite"
                >
                </yo-lookup>
                <dx-text-box
                  *ngIf="!create"
                  [readOnly]="true"
                  [value]="fournisseur.site.libelle"
                >
                </dx-text-box>

              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  required
                  class='inputfield'
                  [(ngModel)]="fournisseur.libelle"
                  #libelle="ngModel"
                  name="libelle"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Code">Code <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box
                  [required]="!create"
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.code"
                  #code="ngModel"
                  name="code"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Siret">Siret</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.siret"
                  #siret="ngModel"
                  name="siret"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line actif-container">
              <div class="label" title="Actif">Actif</div>
              <div class="value">
                <dx-check-box
                  [readOnly]="!canEdit()"
                  [(ngModel)]="fournisseur.actif"
                  #actif="ngModel"
                  name="actif"
                >
                </dx-check-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Secteur">Secteur <span class="danger-color">*</span></div>
              <div class="value">
                <yo-tagbox
                  [readOnly]="!canEdit()"
                  #secteursTagbox
                  [data]="secteursList"
                  [selectedItems]="selectedSecteurs"
                  maxDisplayedTags=3
                >
                </yo-tagbox>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Frais de port">Frais de port</div>
              <div class="value">
                <dx-check-box
                  [readOnly]="!canEdit()"
                  [(ngModel)]="fournisseur.francoDePortActif"
                  #francoDePortActif="ngModel"
                  name="francoDePortActif"
                >
                </dx-check-box>
              </div>
            </div>

            <div *ngIf="fournisseur.francoDePortActif">
              <div class="dialog-line">
                <div class="label text-nowrap" title="Coût des frais de port">Coût des frais de port</div>
                <div class="input-group input-group-sm">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="fournisseur.francoDePortCout"
                    #francoDePortCout="ngModel"
                    name="francoDePortCout"
                  >
                  </dx-text-box>
                  <div class="input-group-append ">
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label text-nowrap" title="Montant HT minimal">Montant HT minimal</div>
                <div class="input-group input-group-sm">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="fournisseur.francoDePortMontant"
                    #francoDePortMontant="ngModel"
                    name="francoDePortMontant"
                  >
                  </dx-text-box>
                  <div class="input-group-append ">
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </div>


              <div class="dialog-line">
                <div class="label text-nowrap" title="Poids minimal">Poids minimal</div>
                <div class="input-group input-group-sm">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="fournisseur.francoDePortPoids"
                    #francoDePortPoids="ngModel"
                    name="francoDePortPoids"
                  >
                  </dx-text-box>
                  <div class="input-group-append ">
                    <span class="input-group-text">KG</span>
                  </div>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label text-nowrap" title="TVA frais de port">TVA frais de port</div>
                <div class="input-group input-group-sm">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="fournisseur.francoDePortTva"
                    #francoDePortTva="ngModel"
                    name="francoDePortTva"
                  >
                  </dx-text-box>
                  <div class="input-group-append ">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="title-ca">Adresse</div>
          <div class="container-fournisseur">
            <div class="dialog-line">
              <div class="label" title="Nom de l'adresse">Nom de l'adresse</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.nom"
                  #nom="ngModel"
                  name="nom"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Adresse 1">Adresse 1</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.adresse1"
                  #adresse1="ngModel"
                  name="adresse1"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Adresse 2">Adresse 2</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.adresse2"
                  #adresse2="ngModel"
                  name="adresse2"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Adresse 3">Adresse 3</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.adresse3"
                  #adresse3="ngModel"
                  name="adresse3"
                >

                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Code Postal">Code Postal</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.codePostal"
                  #codePostal="ngModel"
                  name="codePostal"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Ville">Ville</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.ville"
                  #ville="ngModel"
                  name="ville"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Téléphone">Téléphone</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.telephone"
                  #telephone="ngModel"
                  name="telephone"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Fax">Fax</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.fax"
                  #fax="ngModel"
                  name="fax"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Email">Email</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="fournisseur.adresse.email"
                  #email="ngModel"
                  name="email"
                >
                </dx-text-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</form>


