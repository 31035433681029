import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GestioncontratsRootComponent} from './gestioncontrats-root/gestioncontrats-root.component';
import {FP_ROUTES, FS_ROUTES, HAS_GESTIONMENU, OUTLET_SECONDARY, SECURITY} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {ContratmenuConviveComponent} from './contratmenu-convive/contratmenu-convive.component';
import {ContratmenuConviveResolverService} from './contratmenu-convive/contratmenu-convive-resolver.service';
import {CmcFicheidentiteComponent} from './contratmenu-convive/cmc-ficheidentite/cmc-ficheidentite.component';
import {CmcPlanningComponent} from './contratmenu-convive/cmc-planning/cmc-planning.component';
import {CmcEquipesComponent} from './contratmenu-convive/cmc-equipes/cmc-equipes.component';
import {CmcPointsDeLivraisonComponent} from './contratmenu-convive/cmc-points-de-livraison/cmc-points-de-livraison.component';
import {CmcPlanningResolverService} from './contratmenu-convive/cmc-planning/cmc-planning-resolver.service';
import {CmcEquipesResolverService} from './contratmenu-convive/cmc-equipes/cmc-equipes-resolver.service';
import {CmcPointsDeLivraisonResolverService} from './contratmenu-convive/cmc-points-de-livraison/cmc-points-de-livraison-resolver.service';
import {CmcPointDeLivraisonComponent} from './contratmenu-convive/cmc-points-de-livraison/cmc-point-de-livraison/cmc-point-de-livraison.component';
import {CmcPointDeLivraisonResolverService} from './contratmenu-convive/cmc-points-de-livraison/cmc-point-de-livraison/cmc-point-de-livraison-resolver.service';
import {CmcPointsDeLivraisonGcomComponent} from './contratmenu-convive/cmc-points-de livraison-gcom/cmc-point-de-livraison-gcom.component';


const routes: Routes = [{
  path:  FP_ROUTES.GCO,
  component: GestioncontratsRootComponent,
  runGuardsAndResolvers: 'always',
  canActivate: [Auth2Guard],
  canActivateChild: [Auth2Guard],
  data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
  children: [

  ]

},
  {
    path: `${FS_ROUTES.GCO_CMC}/:idContratMenu/:idContratMenuConvive`,
    component: ContratmenuConviveComponent,
    data: {role: SECURITY.ROLES.ROLE_DIET, licence: SECURITY.FEATURES.GESTIONMENUS},
    outlet: OUTLET_SECONDARY,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    resolve: {
      contratMenuConviveSupplier: ContratmenuConviveResolverService,
    },
    children:[
      {
        path: `ficheidentite`,
        component: CmcFicheidentiteComponent,
        runGuardsAndResolvers: 'always',
        data:  HAS_GESTIONMENU,

      },
      {
        path: `planning`,
        component: CmcPlanningComponent,
        runGuardsAndResolvers: 'always',
        data:  HAS_GESTIONMENU,
        resolve: {
          cmcPlanningSupplier: CmcPlanningResolverService,
        },
      },
      {
        path: `equipes`,
        component: CmcEquipesComponent,
        runGuardsAndResolvers: 'always',
        data:  HAS_GESTIONMENU,
        resolve: {
          cmcEquipesSupplier: CmcEquipesResolverService,
        },
      },
      {
        path: `points-de-livraison`,
        // runGuardsAndResolvers: 'always',
        component: CmcPointsDeLivraisonComponent,
        data:  HAS_GESTIONMENU,
        resolve: {
          cmcPlcSupplier: CmcPointsDeLivraisonResolverService
        },
        children:[
          {
            path:':idContratMenuConviveModeleNutri/:idPlc',
            component:CmcPointDeLivraisonComponent,
            data:  HAS_GESTIONMENU,
            resolve: {
              cmcPlcSupplier: CmcPointDeLivraisonResolverService
            },
          }
        ]
      },
      {
        path: `points-de-livraison-gcom`,
        component: CmcPointsDeLivraisonGcomComponent,
        data:  HAS_GESTIONMENU,
        resolve: {
          cmcPlcSupplier: CmcPointsDeLivraisonResolverService
        },
      }
    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers : [
    ContratmenuConviveResolverService,
    CmcPlanningResolverService
  ]
})
export class GestioncontratsRoutingModule {
}
