import {Injectable} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {ConstDTO} from '../../../core/dtos/ciqual/const-dto';
import {ApportNutritionnelDTO} from '../../../core/dtos/apport-nutritionnel-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {ApportsNutritionnelsService} from '../../../core/services/entities/apports-nutritionnels.service';


@Injectable()
export class CiqualResolverServiceService implements Resolve<CiqualSupplier> {

  constructor(private http: HttpClient,
              private utils : UtilsService,
              private produitRouteMapService: ProduitRouteMapService,
              private produitdecliSvc : ProduitDeclinaisonService,
              private apportsNutriSvc : ApportsNutritionnelsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CiqualSupplier> | Promise<CiqualSupplier> | CiqualSupplier {
    return this.resolveActivatedRouteSnapshot(route);

  }

  public resolveActivatedRouteSnapshot(route: ActivatedRouteSnapshot): Observable<CiqualSupplier> | Promise<CiqualSupplier> | CiqualSupplier {
    let idProduit: number = +route.parent.paramMap.get('idProduit');

    // Les urls
    let urlAllCiqualConst = this.produitRouteMapService.getAllCiqualConstSLink();

    // Les Observables
    const produitDeclinaisonDTOList$ = this.produitdecliSvc.getProduitDeclinaisonListByIdProduit(idProduit);
    const allConstDTOList$ = this.http.get<ConstDTO[]>(urlAllCiqualConst);
    const allApportsNutritionnels$ = this.apportsNutriSvc.getApportNutritionnelsActifsBySiteNull();

    const all$ = combineLatest(produitDeclinaisonDTOList$, allConstDTOList$, allApportsNutritionnels$);

    // Le supplier
    let ciqualSupplier = new CiqualSupplier();


    return all$.toPromise().then(data => {
      ciqualSupplier.produitDeclinaisonDTOList = data[0];
      ciqualSupplier.allConstDTOList = data[1];
      ciqualSupplier.allApportsNutritionnels = data[2];
      return ciqualSupplier;
    });

  }



}

export class CiqualSupplier {
  produitDeclinaisonDTOList: ProduitDeclinaisonDTO[];
  allConstDTOList: ConstDTO[];
  allApportsNutritionnels: ApportNutritionnelDTO[];
}
