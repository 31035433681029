import {Component, Input} from '@angular/core';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {cloneDeep as _cloneDeep, sortBy as _sortBy} from 'lodash';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {
  FournisseurLivraisonsAffectationSupplier,
  UniteDeProduction__secteurFournisseurService
} from '../../../../core/services/entities/unite-de-production__secteur-fournisseur.service';
import {UniteDeProduction__LivraisonDTO} from '../../../../core/dtos/unite-de-production__livraison-dto';
import {SecteurFournisseurDTO} from '../../../../core/dtos/secteurfournisseur-dto';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {DATEPICKER_FR} from '../../../../core/constants';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';

@Component({
  selector: 'yo-up-livraisons-affectation',
  templateUrl: './up-livraisons-affectation.component.html',
  styleUrls: ['./up-livraisons-affectation.component.scss'],

})
export class UpLivraisonsAffectationComponent {

  uniteDeProduction: UniteDeProductionDTO;
  joursSemaine: { value, viewValue }[];
  secteursFournisseurs: SecteurFournisseurDTO[];
  secteursFournisseursModel: SecteurFournisseurDTO[];
  livraisonsOfUDP: UniteDeProduction__LivraisonDTO[];
  newIsChecked: boolean;
  fr = DATEPICKER_FR;

  displayDialogRemarqueCommande:boolean = false;


  constructor(public gds: GenericDatagridService,
              public secteursFournisseurOfUDPSvc: UniteDeProduction__secteurFournisseurService,
              public utils: UtilsService) {
  }

  @Input()
  set affectationsSupplier(value: FournisseurLivraisonsAffectationSupplier) {

    if (!this.utils.isNullOrEmpty(value)) {

      this.joursSemaine = value.joursSemaine;
      this.secteursFournisseurs = value.secteursFournisseurs;
      this.secteursFournisseursModel = _cloneDeep(this.secteursFournisseurs);
      this.uniteDeProduction = value.uniteDeProduction;
      this.livraisonsOfUDP = [...value.livraisonsOfUDP];
      this.newIsChecked = value.newIsChecked;

      //Creer la matrice de selection des jours fournisseurs
      this.setAffectations();
    }
  }

  /**
   *
   * @param {UniteDeProduction__SecteurFournisseurDTO[]} secteursFournisseursOfUDP
   * @param {{value; viewValue}[]} joursSemaine
   */
  setAffectations() {
    if (this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      this.livraisonsOfUDP = [];
    }
    //Lignes secteurs fournisseurs rattaché à l'unité de production
    this.secteursFournisseursModel = _sortBy(this.secteursFournisseursModel, ['secteur.code', 'fournisseurLibelle']);

    //Init des Checkboxes
    let newLivraisonsOfUDP: UniteDeProduction__LivraisonDTO[] = [];
    for (let row of this.secteursFournisseursModel) {
      for (let col of this.joursSemaine) {

        let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item => {
          if (item.idSecteurFournisseur === row.id && item.numeroJourSemaine === col.value) {

            if (item.actif === undefined) {
              //data is coming from backend because item.checked is transient, so it is undefined
              item.actif = true;
            }
            return true;
          }
          return false;
        }));

        if (this.utils.isNullOrEmpty(elt)) {

          elt = new UniteDeProduction__LivraisonDTO();
          elt.idSecteurFournisseur = row.id;
          elt.numeroJourSemaine = col.value;
          elt.actif = this.newIsChecked;

          // fix redmine 1330  doit être une date, par defaut on positionne à 16h00
          elt.delaiLivraisonHeureLimite = this.utils.setHour(new Date(),16);

          elt.delaiLivraisonJours = 0;
        }

        newLivraisonsOfUDP.push(elt);
      }
    }
    this.livraisonsOfUDP = newLivraisonsOfUDP;
    this.secteursFournisseurOfUDPSvc.announceUdpLivraison(this.livraisonsOfUDP);
  }

  /**
   *
   * @param {UniteDeProduction__SecteurFournisseurDTO} sfOfUdp
   * @param {{value; viewValue}} jourSemaine
   * @param {MatCheckboxChange} event
   * @Deprecated
   */
  onChangeAffectation(secteurFournisseur: SecteurFournisseurDTO, jourSemaine: { value, viewValue }, event) {
    if (!this.utils.isNullOrEmpty(this.livraisonsOfUDP)) {
      let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item =>
        item.numeroJourSemaine === jourSemaine.value
        && item.idSecteurFournisseur === secteurFournisseur.id));

      if (!this.utils.isNullOrEmpty(elt)) {
        elt.actif = event.checked;
      }
    }
    this.secteursFournisseurOfUDPSvc.announceUdpLivraison(this.livraisonsOfUDP);
  }

  getAffectationValue(secteurFournisseur: SecteurFournisseurDTO, jourSemaine: { value, viewValue }): boolean {
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item =>
        item.idSecteurFournisseur === secteurFournisseur.id
        && item.numeroJourSemaine === jourSemaine.value));

      if (!this.utils.isNullOrEmpty(elt)) {
        return elt.actif;
      }
    }
    return false;
  }

  getSecteurFournisseurInfoLivraison(secteurFournisseur: SecteurFournisseurDTO): string {
    let response = '';
    if (!this.utils.isNullOrEmpty(secteurFournisseur)) {
      response += `livraisons sur  ${secteurFournisseur.secteur.libelle}` + ".";
    }
    return response;
  }

  getTooltipWarningFilialeInactive(secteurFournisseur: SecteurFournisseurDTO): string {
    let response = '';
    if (!secteurFournisseur.fournisseurActif) {
      response += "Attention : la filiale '" + secteurFournisseur.fournisseurLibelle + "' est inactive.";
    }
    return response;
  }

  getCodeClient(sf: SecteurFournisseurDTO): string {
    let code = '';
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      let elt  =  this.utils.getFirstElement(this.livraisonsOfUDP.filter(item => item.idSecteurFournisseur === sf.id && !this.utils.isNullOrEmpty(item.codeClient)));
      if (!this.utils.isNullOrEmpty(elt)  ) {
        code = elt.codeClient;
        if (!this.utils.isNullOrEmpty(code)) {
          code = code.trim().toUpperCase();
        }
      }
    }
    return code;
  }

  getDelaiLivraison(sf: SecteurFournisseurDTO) {
    let delaiLivraison = 0;
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item => item.idSecteurFournisseur === sf.id  && item.delaiLivraisonJours!=0));
      if (!this.utils.isNullOrEmpty(elt)) {

        delaiLivraison = elt.delaiLivraisonJours;

        if (this.utils.isNullOrEmpty(delaiLivraison)) {
          delaiLivraison = 0;
        }
      }
    }
    return delaiLivraison;
  }

  getHeureLimite(sf: SecteurFournisseurDTO) {
    let heureLimite = new Date();
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item => item.idSecteurFournisseur === sf.id  && !this.utils.isNullOrEmpty(item.delaiLivraisonHeureLimite)));
      if (!this.utils.isNullOrEmpty(elt)) {
        heureLimite = elt.delaiLivraisonHeureLimite;
      }
    }
    return heureLimite;
  }

  onChangeCodeClient(secteurFournisseur: SecteurFournisseurDTO, event) {
    const codeClient = event;
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      for (const uniteDeProductionLivraisonDTO of this.livraisonsOfUDP) {
        if (uniteDeProductionLivraisonDTO.idSecteurFournisseur === secteurFournisseur.id) {
          uniteDeProductionLivraisonDTO.codeClient = codeClient;
        }
      }
    }
    this.secteursFournisseurOfUDPSvc.announceUdpLivraison(this.livraisonsOfUDP);
  }


  onChangeDelaiLivraison(secteurFournisseur: SecteurFournisseurDTO, event) {
    const delaiLivraison = event;
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      for (const uniteDeProductionLivraisonDTO of this.livraisonsOfUDP) {
        if (uniteDeProductionLivraisonDTO.idSecteurFournisseur === secteurFournisseur.id) {
          uniteDeProductionLivraisonDTO.delaiLivraisonJours = delaiLivraison;
        }
      }
    }
    this.secteursFournisseurOfUDPSvc.announceUdpLivraison(this.livraisonsOfUDP);
  }

  onChangeHeureLimite(secteurFournisseur: SecteurFournisseurDTO, event) {
    const heureLimite = event;
    if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      for (const uniteDeProductionLivraisonDTO of this.livraisonsOfUDP) {
        if (uniteDeProductionLivraisonDTO.idSecteurFournisseur === secteurFournisseur.id) {
          uniteDeProductionLivraisonDTO.delaiLivraisonHeureLimite = heureLimite;
        }
      }
    }
    this.secteursFournisseurOfUDPSvc.announceUdpLivraison(this.livraisonsOfUDP);
  }

  openDialogRemarqueCommande(secteurFournisseur: SecteurFournisseurDTO){
    this.secteursFournisseurOfUDPSvc.announceUdpSecteurFournisseur(this.secteursFournisseurOfUDPSvc.createEmptyDTO());
    this.secteursFournisseurOfUDPSvc.getUdpSecteurFournisseurByUdpAndSf(this.uniteDeProduction.id,secteurFournisseur.fournisseurId,secteurFournisseur.secteur.id)
      .subscribe((response:ResponseWrapper<UniteDeProduction__SecteurFournisseurDTO>) =>{
        if(!this.utils.isNullOrEmpty(response.one)){
          this.secteursFournisseurOfUDPSvc.announceUdpSecteurFournisseur(response.one);
        }

      });
    this.displayDialogRemarqueCommande = true;
  }

  onCloseDialogRemarqueCommande(){
    this.displayDialogRemarqueCommande = false;
  }
}
