<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="isPopUpDisplayed"
  [showCloseButton]="true"
  width="70vw"
  height="80vh"
  (onHidden)="closeDialog()"
  [fullScreen]="fullScreen"
  [resizeEnabled]="!fullScreen"
  [dragEnabled]="!fullScreen"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>

  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >
    <dx-tabs #tabs
             [items]="navLinks"
             [selectedItem]="navLinks[0]"
    ></dx-tabs>
    <dx-multi-view
      [items]="navLinks"
      [(selectedItem)]="tabs.selectedItem"
      [loop]="false"
      [animationEnabled]="true"
    >
      <div *dxTemplate="let tab of 'item'">
        <div *ngIf="tab.component">
          <ng-container *ngIf="dynamicInjector">
            <ng-container *ngComponentOutlet="tab.component; injector: dynamicInjector"></ng-container>
          </ng-container>
        </div>
      </div>
    </dx-multi-view>
  </dx-scroll-view>
</dx-popup>

