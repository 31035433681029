import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {MarcheService} from "../../../core/services/entities/marche.service";
import {GestionsMarcheService} from "../../../core/services/gestion-marche/gestions-marche.service";
import {GestionLotMarcheService} from "../../../core/services/gestion-marche/gestion-lot-marche.service";
import {combineLatest, Observable} from 'rxjs';
import {MarcheSupplier} from "../marche-resolver.service";


@Injectable({
  providedIn: 'root'
})
export class LotMarcheResolverService implements Resolve<MarcheSupplier> {

  constructor(private marcheSvc: MarcheService,
              private gestionsMarcheSvc: GestionsMarcheService,
              private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve = (route: ActivatedRouteSnapshot| any, state: RouterStateSnapshot): Observable<MarcheSupplier> | Promise<MarcheSupplier> | MarcheSupplier => {
    let lotMarcheSupplier: MarcheSupplier = new MarcheSupplier();
    let idMarche: number = +route._urlSegment.segments[2].path;

    if (idMarche > 0) {
      const marcheDTO$ = this.gestionsMarcheSvc.getMarcheById(idMarche);
      const lotMarcheDTO$ = this.gestionLotMarcheSvc.getAllLotMarcheByIdMarche(idMarche);
      const all$ = combineLatest([marcheDTO$,lotMarcheDTO$]);
      return all$.toPromise().then(data => {
        lotMarcheSupplier.marche = data[0].one;
        lotMarcheSupplier.lotMarcheList = data[1].resultList;

        return lotMarcheSupplier;
      });
    }
  };
}


