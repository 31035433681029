import {ObjectDTO} from "../object-dto";
import {UnitePmsDto} from "./unite-pms-dto";

export class SeuilPmsDto extends ObjectDTO {
  libelle: string;
  min: number;
  max: number;
  description: string;
  icone: string;
  couleur: string;
  unite: UnitePmsDto;
}
