import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-saisie-effectifs',
  templateUrl: './saisie-effectifs.component.html',
  styleUrls: ['./saisie-effectifs.component.scss']
})
export class SaisieEffectifsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
