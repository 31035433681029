import {Component, OnInit} from "@angular/core";
import {UniteDeMesureDTO} from "../../../core/dtos/unitedemesure-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {UnitesDeMesuresService} from "../../../core/services/entities/unites-de-mesures.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-unite-de-mesure-edition-dialog',
  templateUrl: './unite-de-mesure-edition-dialog.component.html',
  styleUrls: ['./unite-de-mesure-edition-dialog.component.scss']
})
export class UniteDeMesureEditionDialogComponent implements OnInit {

  uniteMesure: UniteDeMesureDTO;

  dialogTitle: string;

  subOpenDialog: Subscription;

  displayDialog: boolean = false;

  sitePlaceholder: string;

  idSelectedSite: number;

  form: FormGroup;

  types: any[] = [];

  constructor(private utilsSvc: UtilsService,
              private uniteMesureSvc: UnitesDeMesuresService,
              private i8nSvc: InternationalizationService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
    this.types = [{ value: 1, label: "Masse" }, {value: 2, label: "Volume" }];
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.uniteMesureSvc.openDialogEdition$
      .subscribe((uniteMesure: UniteDeMesureDTO) => {
        this.displayDialog = true;
        if (!uniteMesure) {
          this.uniteMesure = new UniteDeMesureDTO();
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_MEASURE_UNIT", null);
        } else {
          this.uniteMesure = uniteMesure;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_MEASURE_UNIT", null);
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.uniteMesure?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.uniteMesure?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.uniteMesure?.libelle),
      abreviation: new FormControl(this.uniteMesure?.abreviation),
      type: new FormControl(this.uniteMesure?.type || 1),
      actif: new FormControl(this.uniteMesure?.actif != null ? this.uniteMesure.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.uniteMesureSvc.canModify(this.uniteMesure);
  }

  save = (): void => {
    if (this.form.valid) {
      let newUdm: UniteDeMesureDTO = {...this.uniteMesure };
      newUdm.site = {id: this.idSelectedSite } as SiteDTO;
      newUdm.libelle = this.form.controls['libelle'].value;
      newUdm.abreviation = this.form.controls['abreviation'].value;
      newUdm.type = this.form.controls['type'].value;
      newUdm.actif = this.form.controls['actif'].value;
      this.uniteMesureSvc.save(newUdm)
        .subscribe(response => {
          this.uniteMesureSvc.announceUniteMesureSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de l'unité de mesure réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
