import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {catchError} from 'rxjs/operators';
import {SiteDTO} from '../../dtos/site-dto';
import {startCase as _startCase} from 'lodash';


export const URL_POST_DUPLICATE_MODELE_NUTRI = `dolrest/gestiongemrcn/duplicate/modele-nutritionnel`;

@Injectable()
export class ModelesNutritionnelsService extends GenericHandler<ModeleNutritionnelDTO> {

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient,
              title: Title
  ) {
    super(utils, auth2Svc, router, http, title);
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }


  getFields(dto: ModeleNutritionnelDTO): FormFieldBaseSupplier<any>[] {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }

    let formFieldSite: FormFieldBaseSupplier<any>;
    if (this.canModifySite(dto)) {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef', // this.initArray(this.typeProduitSvc.typeProduitList);
        value: this.utils.preSelectSingleList(this.optionsSites,dto.site),
        // value: this.getSelectedItem(dto.site,        this.initArray(this.auth2Svc.utilisateur.siteListLocaux),
        order: 1,
        options: this.optionsSites,
        required: true,
      });
    } else {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      });


    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        help: '',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: '',
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        help: '',
        value: dto.actif,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getEntityName(): string {
    return 'ModeleNutritionnel';
  }

  getTitle(): string {
    return 'GESTION DES MODÈLES NUTRITIONNELS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN MODÈLE NUTRITIONNEL';
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): ModeleNutritionnelDTO {
    const modeleNutri = new ModeleNutritionnelDTO();
    modeleNutri.typeCycle = 2;
    modeleNutri.nbItemsParCycle = 4;
    modeleNutri.actif = true;
    // Si la liste des sites locaux contient un site et un seul, on affecte ce site au ModeleNutritionnelDTO
    // en création.
    if (this.auth2Svc.utilisateur.siteListLocaux.length == 1) {
      modeleNutri.site = this.auth2Svc.utilisateur.siteListLocaux[0];
    }


    return modeleNutri;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LE MODÈLE NUTRITIONNEL '${data.libelle.toUpperCase()}'`;
  }

  duplicate(modeleNutri: ModeleNutritionnelDTO, site: SiteDTO) {

    modeleNutri.site = site;
    modeleNutri.libelle='Copie '+modeleNutri.libelle;
    modeleNutri.code='COPIE_'+modeleNutri.code;

    return this.http.post(URL_POST_DUPLICATE_MODELE_NUTRI, modeleNutri)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );


  }

}
