import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {combineLatest, Observable} from "rxjs";
import {PointDeLivraisonDTO} from "../../core/dtos/point-de-livraison-d-t-o";
import {PointDeLivraisonService} from "../../core/services/entities/point-de-livraison.service";

@Injectable({
  providedIn: 'root'
})
export class PortailPlcResolverService implements Resolve<PortailPlcSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private pointDeLivrasionSvc : PointDeLivraisonService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PortailPlcSupplier> | Promise<PortailPlcSupplier> | PortailPlcSupplier => {

    let pps: PortailPlcSupplier = new PortailPlcSupplier();
    const plcList$ = this.pointDeLivrasionSvc.getPlcListLinkToUserByEnvPlc();
    const all$ = combineLatest([plcList$]);
    return all$.toPromise().then(response => {
      pps.plcList = response[0].resultList;
      return pps;
    });
  };
}


export class PortailPlcSupplier {
  plcList: PointDeLivraisonDTO[] = [];
}
