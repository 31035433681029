import {Component, Input, OnInit} from '@angular/core';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {UtilsService} from '../../../core/utils/utils.service';

@Component({
  selector: 'yo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {


  @Input() selectedItem : any;

  constructor(public itemNavSvc:ItemNavigationService, public utils:UtilsService) { }

  ngOnInit(): void {
  }



}
