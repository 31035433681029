import {Component, OnInit, ViewChild} from "@angular/core";
import {CataloguesAchatsService} from "../../../../../core/services/entities/catalogues-achats.service";
import {CatalogueAchatDTO} from "../../../../../core/dtos/catalogue-achat-dto";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {DxDataGridComponent} from "devextreme-angular";
import {DevextremeService} from "../../../../../core/services/technique/devextreme.service";
import {Subscription} from "rxjs";
import {UniteDeProductionDTO} from "../../../../../core/dtos/unite-de-production-dto";
import {CatalogueAchatPrixPeriodeDTO} from "../../../../../core/dtos/catalogue-achat-prix-periode-dto";

@Component({
  selector: 'yo-period-price',
  templateUrl: './period-price.component.html',
  styleUrls: ['./period-price.component.scss']
})
export class PeriodPriceComponent implements OnInit {

  displayDialog: boolean = false;
  catalogueAchat: CatalogueAchatDTO;
  uniteDeProduction: UniteDeProductionDTO;

  allPricePeriod: CatalogueAchatPrixPeriodeDTO[] = [];

  checkBoxesMode: string;
  selectAll: boolean = false;

  isActionDisabled: boolean = true;

  subOpenDialogPeriodPrice: Subscription;
  subCloseDialogPeriodPrice: Subscription;

  @ViewChild("grid", {static: false}) grid: DxDataGridComponent;

  constructor(public catalogueAchatSvc: CataloguesAchatsService,
              public utils: UtilsService,
              private dxSvc: DevextremeService) {
  }

  ngOnInit(): void {
    this.checkBoxesMode = 'always';
    this.openDialogPeriodPriceSubscription();
    this.periodPriceClosedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogPeriodPrice);
    this.utils.unsubscribe(this.subCloseDialogPeriodPrice);
  }

  findAllPeriodPriceByArticle = () => {
    this.catalogueAchatSvc.findAllPricePeriod(this.catalogueAchat?.id, this.uniteDeProduction?.id)
      .subscribe(result => this.allPricePeriod = result.resultList);
  }

  openDialogSavePeriodPrice = (item?: CatalogueAchatPrixPeriodeDTO): void => {
    this.catalogueAchatSvc.announceOpenDialogSavePricePeriod(item ? item : null, this.catalogueAchat, !!item);
  }

  openDialogPeriodPriceSubscription = () => {
    this.subOpenDialogPeriodPrice = this.catalogueAchatSvc.openDialogPricePeriod$
      .subscribe((item: any) => {
        this.displayDialog = true;
        this.catalogueAchat = item.ca;
        this.uniteDeProduction = item.udp;
        this.findAllPeriodPriceByArticle();
      });
  }

  periodPriceClosedSubscription = (): void => {
    this.subCloseDialogPeriodPrice = this.catalogueAchatSvc.savedPricePeriod$
      .subscribe(() => this.findAllPeriodPriceByArticle());
  }

  closeDialog = () => {
    this.displayDialog = false;
  }

  checkDisabledButtons = async (): Promise<void> => {
    this.isActionDisabled = (await this.grid.instance.getSelectedRowsData()).length === 0;
  }

  cellClick = (e: any) => this.selectAll = (e.rowType === 'header');

  deletePricePeriods = async () => {
    const rowsSelected: number[] = await this.grid.instance.getSelectedRowKeys();
    const selectedItems: CatalogueAchatPrixPeriodeDTO[] = await this.grid.instance.getSelectedRowsData();

    if (!this.utils.isCollectionNullOrEmpty(selectedItems)) {
      await this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
        this.catalogueAchatSvc.deletePricePeriods(selectedItems).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.findAllPeriodPriceByArticle();
            this.catalogueAchatSvc.announceSavedPricePeriod();
          }
        });
      });
    }
  };

  canEdit = () => {
    if (this.utils.isNullOrEmpty(this.catalogueAchat?.fournisseurOwnerLibelle)) {
      return this.catalogueAchat?.site?.id == this.uniteDeProduction?.site?.id;
    } else {
      return false;
    }
  };

  isDisabledDeleteBtn = () => {
    if (this.utils.isNullOrEmpty(this.catalogueAchat?.fournisseurOwnerLibelle)) {
      return this.catalogueAchat?.site?.id !== this.uniteDeProduction?.site?.id;
    } else {
      return true;
    }
  };
}
