import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AUTH} from '../../constants';
import {Auth2Service} from './auth2.service';
import {Utilisateur} from '../../utilisateur';
import {UserHasRight} from './user-has-right';
import {FrontStorageService} from '../storage/front-storage.service';

@Injectable()
export class Auth2Guard implements CanActivate, CanActivateChild {

  userSub: Subscription;
  user: Utilisateur;

  constructor(private auth2Svc: Auth2Service, private frontStorageSvc: FrontStorageService) {
    this.userSub = this.auth2Svc.user$.subscribe(userAuth => this.user = userAuth);
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.storeRouteToActivate(next.data, state);
    const userHasRight: UserHasRight = {role: next.data.role, licence: next.data.licence};

    if (this.auth2Svc.hasFeature(this.user, userHasRight)) {
      return true;
    } else {
      this.auth2Svc.redirectToLoginPage();
    }
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  private storeRouteToActivate(routeData: any, state: RouterStateSnapshot) {
    let url: string = state.url;

    //suppression de la route secondaire
    let indexOfParenthesis = url.indexOf('(');
    if (indexOfParenthesis >= 0) {
      url = url.substr(0, indexOfParenthesis);
    }

    //stockage dans le localstorage
    this.frontStorageSvc.secureLS.set(AUTH.licenceFeatureNeeded, AUTH.licenceFeatureNeeded);
    this.frontStorageSvc.secureLS.set(AUTH.redirectUrl, url);
    this.frontStorageSvc.secureLS.set(AUTH.roleNeeded, routeData.role);

  }

}
