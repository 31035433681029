import {combineLatest, Observable, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {ProduitnomenclatureDTO} from '../../../core/dtos/produitnomenclature-dto';
import {ProduitModeOperatoireDTO} from '../../../core/dtos/produit-mode-operatoire.dto';

import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {HttpClient} from '@angular/common/http';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';


@Injectable()
export class FichetechniqueResolverService implements Resolve<FichetechniqueSupplier>, OnDestroy {

  sub: Subscription;


  constructor(private produitRouteMapSvc: ProduitRouteMapService,
              private http: HttpClient,
              private produitSvc:ProduitsService,
              private gds:GenericDatagridService,
              private utils: UtilsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FichetechniqueSupplier> | Promise<FichetechniqueSupplier> | FichetechniqueSupplier => {
    let fichetechniqueSupplier: FichetechniqueSupplier = new FichetechniqueSupplier();

    fichetechniqueSupplier.idProduit = +route.parent.paramMap.get('idProduit');
    const modesOperatoires$ = this.http.get<ProduitModeOperatoireDTO[]> (this.produitRouteMapSvc.getModesOperatoiresSLink(fichetechniqueSupplier.idProduit));
    const produit$ = this.gds.getOne(this.produitSvc.getEntityName(),fichetechniqueSupplier.idProduit);

    let idSiteContratMenu: number = +route.paramMap.get('idSiteContratMenu');
    const idUdp: number = +route.paramMap.get('idUdp');

    const all$ = combineLatest([modesOperatoires$, produit$]);

    return all$.toPromise().then((data) => {
      fichetechniqueSupplier.produitsModesOperatoires = data[0];
      fichetechniqueSupplier.produitDTO = data[1].one;
      fichetechniqueSupplier.idSiteContratMenu = idSiteContratMenu;
      fichetechniqueSupplier.idUdp = idUdp;
      return fichetechniqueSupplier;
    });
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.sub);
  }


}

export class FichetechniqueSupplier {
  idProduit: number;
  colsProduitDeclinaison: ProduitDeclinaisonDTO[];
  rowsProduitDeclinaison: ProduitDeclinaisonDTO[];
  cellsProduitNomenclature: ProduitnomenclatureDTO[];
  produitDTO: ProduitDTO;
  produitsModesOperatoires: ProduitModeOperatoireDTO[];
  uniteDeProductionList: UniteDeProductionDTO[];
  selectedUniteDeProduction: UniteDeProductionDTO;
  idSiteContratMenu: number;
  idUdp: number;
}
