import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MenuItem} from 'primeng/api';
import {ClientDTO} from "../../core/dtos/client-dto";
import {UtilsService} from "../../core/utils/utils.service";
import {ItemNavigationService} from "../../core/services/technique/item-navigation.service";
import {RoutemapService} from "../../core/services/routemap.service";
import {ClientsBusinessService} from "../../core/services/gestion-clients/clients.service";
import {Auth2Service} from "../../core/services/security/auth2.service";

@Component({
  templateUrl:'./client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {

  subGlobal: Subscription;
  subActiveSecondaryRoute:Subscription;

  client: ClientDTO;
  navLinks: MenuItem[] = [];
  selectedNavLink:MenuItem;

  hasGestionClients: boolean = false;
  hasGestionIDistri: boolean = false;

  constructor(private route: ActivatedRoute,
              private clientsBSvc: ClientsBusinessService,
              private routeMapSvc : RoutemapService,
              private titleSvc: Title,
              private itemNavSvc:ItemNavigationService,
              private auth2Svc: Auth2Service,
              private utils: UtilsService) {}

  ngOnInit() {
    this.initGlobalSubscription();
    this.activeSecondaryRouteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGlobal);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  }

  initGlobalSubscription = (): void => {
    const all$ = combineLatest([this.auth2Svc.hasGestionClients$, this.auth2Svc.hasGestionIDistri$, this.clientsBSvc.announceClient$]);
    this.subGlobal = all$.subscribe(response => {
      this.hasGestionClients = response[0];
      this.hasGestionIDistri = response[1];
      this.client = response[2];
      this.initNavLinks();
    });
  };

  private initNavLinks = () => {

    if(this.utils.isNullOrEmpty(this.client)){
      this.client = new ClientDTO();
      this.client.id=0;
    }

    this.navLinks = [];
    this.navLinks.push({
      label: `Fiche d'identité`,
      routerLink:[`${this.client.id}/ficheidentite`],
    });
  };

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.selectedNavLink = this.itemNavSvc.activeSecondaryRoute(event, this.navLinks);
    });
  };
}
