<div class="custom-toolbar">
  <div id="before-toolbar" class="custom-toolbar-subdivision">
    <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <yo-commande-fournisseur-date-filter
      (onValueChange)="onDateFilterValueChange($event)"
    >
    </yo-commande-fournisseur-date-filter>

    <dx-text-box
      class="box-size-1"
      placeholder="Filtrer par numéro de commande..."
      (onValueChanged)="onChangeFilterNumCommande($event)"
    >

    </dx-text-box>
    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsFournisseur"
      placeholder="Filtrer par fournisseur..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterFournisseurs($event)"
    >
    </dx-tag-box>
    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsUdp"
      placeholder="Filtrer par unité de production..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterUdps()"
    >
    </dx-tag-box>
    <dx-tag-box
      class="box-size-2"
      [dataSource]="filterItemsListeBesoin"
      placeholder="Filtrer par liste de besoins..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      [multiline]="false"
      (onValueChanged)="onChangeFilterListesBesoins($event)"
    >
    </dx-tag-box>
  </div>
  <div id="after-toolbar" class="custom-toolbar-subdivision">
    <yo-button
      iconClass="fas fa-print"
      (onClick)="printPDFCommandes()"
      [disabled]="isActionDisabled"
      tooltip="Imprimer les propositions sélectionnées au format pdf">
    </yo-button>
  </div>
</div>


<dx-data-grid
  (onSelectionChanged)="checkDisabledButtons()"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  (onCellClick)="cellClick($event)"
  #grid>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="100" cellTemplate="templateActions" alignement="left"
              [allowSorting]="false"></dxi-column>
  <dxi-column dataField="numeroBcf" caption="Bon de commande" cellTemplate="templateBonCf"
              alignement="left"></dxi-column>
  <dxi-column dataField="bonReceptionList" caption="Bon de réception" cellTemplate="templateBonReception"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'fas fa-edit':'fas fa-eye'"
                    [yoTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Modifier la commande':'Voir la commande'"
    ></yo-cell-button>
  </div>
  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a title="Aller sur la liste de besoins"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateBonCf'">
    <span class="mg-r-5">{{cell.row.data.numeroBcf}}</span>
  </div>
  <div *dxTemplate="let cell of 'templateBonReception'">
    <td style="text-align: left;">
      <div class="p-grid">
        <div class="p-col">
          <div *ngFor="let bonReception of cell.row.data.bonReceptionList">
            <ng-container
              *ngTemplateOutlet="tplBonReception;context:{bonReception:bonReception}"></ng-container>
          </div>
        </div>
      </div>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} commande(s) facturée(s)">
  </dxo-pager>

</dx-data-grid>

<!--TEMPLATE COLONNE BON DE RECEPTION DANS GRILLE COMMANDES À RÉCEPTIONNER-->
<ng-template #tplBonReception let-bonReception="bonReception">

  <!--  <p-contextMenu [global]="true" [target]="divBonReception" [model]="items" ></p-contextMenu>-->
  <p-menu appendTo="body" #menuActions [model]="brlActions" [popup]="true"></p-menu>

  <div (click)="chooseBrlAction(menuActions,$event,bonReception)" class="cursor">

    <span class="mg-r-5">
    <yo-statuts-reception [bonReception]="bonReception"></yo-statuts-reception>
    </span>

    <span class="mg-r-5">
      <label class="genlabel cursor">{{bonReception?.numeroBonReception | uppercase}}</label>
    </span>

    <yo-litige-warning [bonReception]="bonReception"></yo-litige-warning>

  </div>

</ng-template>


<!--DIALOG MODIFIER UNE COMMANDE FACTUREE-->
<yo-bc-edit-fournisseur [displayDialog]="displayEditDialog" (onSave)="onBonCommandSaved()"
                        (onClose)="displayEditDialog=$event" [bonCf]="bonCf" [lignesBonCf]="lignesBonCf"
                        [bonCfs]="commandesFacturees"></yo-bc-edit-fournisseur>


<!--DIALOG DE RECECPTION DES ARTICLES-->
<yo-dialog-article-reception-grid (onClose)="displayDialogArticlesReception=$event"
                                  [displayEdit]="false"
                                  [displayDialog]="displayDialogArticlesReception">
</yo-dialog-article-reception-grid>
