import {ObjectDTO} from './object-dto';
import {UniteDeMesureDTO} from './unitedemesure-dto';
import {BonCfDetailProvenanceDTO} from './boncf-detail-provenance-dto';
import {StockDTO} from './stock-dto';
import {LotMarchePdLumpCaDto} from './gerstion-marche/lot-marche-pd-lump-ca-dto';

export class BonCfDetailDTO extends ObjectDTO {

  idBonCf: number;
  idCatalogueAchat:number;

  bonCfDetailProvenanceList: BonCfDetailProvenanceDTO[] =[];

  uniteDeFacturation: UniteDeMesureDTO;
  uniteTechnique: UniteDeMesureDTO;
  uniteDeCommande: UniteDeMesureDTO;

  ratioUC: number;
  ratioUT: number;
  ratioUF: number;

  referenceArticle: string;
  description: string;
  prixUnitaire: number;
  prixTotal: number;
  quantiteBesoin: number;
  quantiteACommander: number;
  quantiteACommanderAjustee: number;
  quantiteAFacturer: number;
  quantiteADestocker: number;

  minimumDeCommande: number;

  stock: StockDTO;

  commentaire: string;

  tvaArticle : number;

  lmPdUpCa:LotMarchePdLumpCaDto;


  constructor() {
    super();
    this.commentaire = null;
  }
}
