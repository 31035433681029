import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {SelectionRepasDTO} from '../../dtos/selection-repas-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {RoutemapService} from '../routemap.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {TreeNode} from 'primeng/api';
import {find as _find, sortBy as _sortBy, startCase as _startCase} from 'lodash'
import {SelectionRepasSupplier} from '../../../gestion-processus/selection-menus/selection-menus-resolver.service';
import {CmcrSpecModel} from '../../../gestion-processus/selection-menus/cmcr-spec-model';

import * as moment from 'moment';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {WorkflowsService} from '../entities/workflows.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {ColSupplier} from '../../../gestion-processus/selection-menus/selection-menus.component';

export const URL_LOAD_SELECTION_REPAS = `dolrest/gestion-selection-menus/loadSelectionRepas`;
export const URL_GET_MONTH_DATES = `dolrest/gestion-selection-menus/getMonthDates`;



@Injectable({
  providedIn: 'root'
})
export class SelectionRepasService extends GenericHandler<SelectionRepasDTO> {

  constructor(utils: UtilsService,
              private workflowSvc:WorkflowsService,
              private  routeMapSvc: RoutemapService,
              auth2Svc: Auth2Service, router: Router,
              http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  createEmptyDTO = (): SelectionRepasDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEntityName = (): string => "";

  getFields = (dto: SelectionRepasDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "SÉLECTIONNER DES REPAS";

  getSelectionRepas = (idWorkflowInstance: number): Observable<ResponseWrapper<SelectionRepasDTO>> => {
    const fd = new FormData();
    fd.set('idWorkflowInstance', idWorkflowInstance + '');

    return this.http.post(URL_LOAD_SELECTION_REPAS, fd).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  /**
   *
   * @param selectionRepasMap
   * @param row
   * @param col
   *
   * @return renvoie undefined si non trouvé
   */
  getCellSelectionRepas = (selectionRepasMap: Map<string, SelectionRepasDTO>, row: TreeNode, col: ColSupplier): SelectionRepasDTO => {
    const colJourSemaine = col.date.isoWeekday();
    let cmcrSpecList: CmcrSpecModel[] = row.data['cmcrSpecList'];

    if (this.utils.isCollectionNullOrEmpty(cmcrSpecList)) {
      cmcrSpecList = [];
    }

    const result = _find(cmcrSpecList, {'idJourSemaine': colJourSemaine});
    if (result) {
      const key = this.keySelectionRepas(result.idCmcr, col.date);
      return selectionRepasMap.get(key);
    }

    return undefined;
  };

  createNewSelectionRepas = (cmcrSpec: CmcrSpecModel, row: TreeNode, col: ColSupplier): SelectionRepasDTO => {
    let sr = new SelectionRepasDTO();

    sr.date = col.date.toDate().getTime();
    sr.idContratMenuConviveRepas = cmcrSpec.idCmcr;
    sr.actif = true;
    // fix pour cocher les lignes --> ici on ne connait pas l'idCmcr, il faut l'idCmc
    // cf activeRow
    sr.idContratMenuConvive = row.data['cmcId'];
    sr.idRepas = row.data['id'];

    return sr;
  };

  createOrGetSelectionRepas = (cmcrSpec: CmcrSpecModel, row: TreeNode, col: ColSupplier, selectionRepasList: SelectionRepasDTO[], alreadyMap: Map<string, SelectionRepasDTO>, init: boolean): SelectionRepasDTO => {

    let sr = undefined;

    // à l'init on créé les cellules à partir du back
    if (init) {

      sr = this.createNewSelectionRepas(cmcrSpec, row, col);
      if (!this.utils.isCollectionNullOrEmpty(selectionRepasList)) {

        for (let srBack of selectionRepasList) {
          if (srBack.idContratMenuConviveRepas === sr.idContratMenuConviveRepas) {
            if (this.utils.isDateEqualsYYYYMMDD(moment(srBack.date), moment(sr.date))) {
              sr = srBack;

              break;
            }
          }
        }
      }

    }
    // sinon on créé à partir de la map existante
    else {
      const key = this.keySelectionRepas(cmcrSpec.idCmcr, col.date);
      sr = alreadyMap.get(key);
      if (this.utils.isNullOrEmpty(sr)) {
        sr = this.createNewSelectionRepas(cmcrSpec, row, col);
      }
    }


    return sr;

  };

  createSelectionRepasMap = (rows: TreeNode[], cols: ColSupplier[], selectionRepasList: SelectionRepasDTO[], alreadyMap: Map<string, SelectionRepasDTO>, init: boolean): Map<string, SelectionRepasDTO> => {

    let selectionRepasMap: Map<string, SelectionRepasDTO> = new Map();
    if (!init) {
      selectionRepasMap = alreadyMap;
    }

    // on parcours les repas
    for (let row of rows) {

      let cmcrSpecList: CmcrSpecModel[] = row.data['cmcrSpecList'];

      // on parcours les dates
      for (let col of cols) {

        const colJourSemaine = col.date.isoWeekday();

        if (this.utils.isCollectionNullOrEmpty(cmcrSpecList)) {
          cmcrSpecList = [];
        }

        // pour chaque couple date / repas, on récupére le bon contrat menu convive repas (cmcr)
        // le cmcr est le repas avec son idJourSemaine. Cet idJourSemaine doit être le meme que pour la date.
        const result = _find(cmcrSpecList, {'idJourSemaine': colJourSemaine});
        if (result) {
          const key = this.keySelectionRepas(result.idCmcr, col.date);
          const sr = this.createOrGetSelectionRepas(result, row, col, selectionRepasList, alreadyMap, init);
          selectionRepasMap.set(key, sr);
        }

      }
    }
    console.log('selectionRepasMap', selectionRepasMap);
    return selectionRepasMap;
  };

  keySelectionRepas = (idCmcr: number, date: moment.Moment): string => idCmcr + '-' + this.utils.getYYYYMMDD(date);

  isJourRepasOkForContrat = (col: ColSupplier, row: TreeNode): boolean => {

    const idJourSemaine = col.date.isoWeekday();

    let cmcrSpecList: CmcrSpecModel[] = row.data['cmcrSpecList'];

    if (this.utils.isCollectionNullOrEmpty(cmcrSpecList)) {
      cmcrSpecList = [];
    }

    const result = _find(cmcrSpecList, {'idJourSemaine': idJourSemaine});

    return result ? true : false;
  };

  /**
   * Récupérer les noeuds de niveau 4 (repas) de maniere unique
   * @param selectedNodes
   */
  getSelectedNodesLevel4 = (selectedNodes: TreeNode[]): TreeNode[] => {

    const nodes4: Set<TreeNode> = new Set();
    if (this.utils.isCollectionNullOrEmpty(selectedNodes)) {
      selectedNodes = [];
    }

    selectedNodes.map(node => {
      if (node.data['niveau'] === 4) {
        nodes4.add(node);
      }
    });

    let selectedNodes4: TreeNode[] = [];
    nodes4.forEach(node => selectedNodes4.push(node));

    // on tri par ordre alphabetique
    selectedNodes4 = _sortBy(selectedNodes4, ['data.rowLabel']);

    return selectedNodes4;
  };

  getDateCols = (dateDebut: Date, dateFin: Date): ColSupplier[] => {

    const days: ColSupplier[] = [];

    const momentDateFin = moment(dateFin).clone();
    const momentDateDebut = moment(dateDebut).clone();

    let cursor = momentDateDebut;

    while (cursor.isSameOrBefore(momentDateFin, 'day')) {

      days.push({date: cursor, colActive: false});

      cursor = cursor.clone().add(1, 'day');
    }

    return days;
  };


  /**
   * Récupérer les ids contrat menu convive repas qui ont été sélectionnés dans l'arbre
   */
  getIdsCmcrFromLevel4 = (selectedNodes: TreeNode[]): number[] => {
    let ids: number[] = [];
    let selectedNodesLevel4 = this.getSelectedNodesLevel4(selectedNodes);

    if (this.utils.isCollectionNullOrEmpty(selectedNodesLevel4)) {
      selectedNodesLevel4 = [];
    }

    selectedNodesLevel4.forEach(node => {
      const cmcrSpecList: CmcrSpecModel[] = node.data['cmcrSpecList'];
      if (!this.utils.isCollectionNullOrEmpty(cmcrSpecList)) {
        cmcrSpecList.forEach(cmcrSpec => ids.push(cmcrSpec.idCmcr));
      }
    });

    return ids;

  };

  /**
   * Préselectionner les noeuds qui sont dans liste de repas
   * @param srs
   */
  getSelectedNodes = (srs: SelectionRepasSupplier): TreeNode[] => {

    const selectedNodesSet: Set<TreeNode> = new Set();

    if (this.utils.isCollectionNullOrEmpty(srs.selectionRepasList)) {
      srs.selectionRepasList = [];
    }

    srs.tree.map(node1 => {

      if (!this.utils.isCollectionNullOrEmpty(node1.children)) {

        for (let node2 of node1.children) {

          if (!this.utils.isCollectionNullOrEmpty(node2.children)) {

            for (let node3 of node2.children) {

              if (!this.utils.isCollectionNullOrEmpty(node3.children)) {

                for (let node4 of node3.children) {

                  const cmcrSpecList: CmcrSpecModel[] = node4.data['cmcrSpecList'];

                  if (!this.utils.isCollectionNullOrEmpty(cmcrSpecList)) {

                    for (let cmcrSpec of cmcrSpecList) {

                      const result = _find(srs.selectionRepasList, {'idContratMenuConviveRepas': cmcrSpec.idCmcr});
                      if (result) {
                        selectedNodesSet.add(node1);
                        selectedNodesSet.add(node2);
                        selectedNodesSet.add(node3);
                        selectedNodesSet.add(node4);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

    const selectedNodes: TreeNode[] = [];
    selectedNodesSet.forEach(node => selectedNodes.push(node));

    return selectedNodes;
  };




  /**
   * Ne récupérer que les cellules visibles à l'écran
   * @param srMap
   * @param rows
   * @param cols
   */
  prepareSelectionRepasList = (srMap: Map<string, SelectionRepasDTO>, rows: TreeNode[], cols: ColSupplier[], wi:WorkflowInstanceDTO): SelectionRepasDTO[] => {
    const srList: SelectionRepasDTO[] = [];

    if (srMap) {

      for (let col of cols) {
        for (let row of rows) {

          srMap.forEach((value, key) => {

            if (value.idRepas === row.data['id'] && value.idContratMenuConvive === row.data['cmcId']
              && this.utils.isDateEqualsYYYYMMDD(col.date, moment(value.date))) {
              // ajouter l'instance de workflow
              value.idWorkflowInstance = wi.id;
              srList.push(value);
            }
          });
        }
      }

    }

    return srList;
  };



  /**
   * Récupérer toutes les dates d'un mois donné qui ont des menus compos pour la selection de contrats menus convives
   * @param selectedRepas
   * @param year
   * @param month
   */
  getMonthDates = (idsContratMenuConviveRepas: number[], year: number, month: number) => {
    const fd = new FormData();
    fd.set('month', month + '');
    fd.set('year', year + '');
    fd.set('idsContratMenuConviveRepas', idsContratMenuConviveRepas ? idsContratMenuConviveRepas.join(',') : '');

    return this.http.post(URL_GET_MONTH_DATES, fd).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  getEditObjectLabel = (data: ObjectDTO): string => "";

}
