<p-dialog
  [dismissableMask]="false"
  [closable]="true"
  [closeOnEscape]="true"
  (onHide)="closeDialog()"
  [(visible)]="displayDialog"
  showEffect="fade"
  [style]="{'width':'1010px','height':utils.getWindowAvailableHeight(100)+'px'}"
  [contentStyle]="{'overflow':'hidden'}"
  [modal]="true"
  #pDialog>

  <ng-container *ngIf="!utils.isNullOrEmpty(pDialog)">
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        <span [innerHTML]="domSanitizer.bypassSecurityTrustHtml(getTitle(produitDeclinaison))"> </span>
      </div>
    </ng-template>

    <div [style.height.px]="utils.getWindowAvailableHeight(240)" style="overflow-y:auto;overflow-x: hidden;">
      <yo-form [groups]="groups" [form]="form" [options]="{'width':980}"></yo-form>
    </div>

    <ng-template pTemplate="footer">
      <div class="d-flex">
        <div class="mr-auto">
        </div>
        <div>
          <yo-button buttonClass="cta-inner-secondary" iconClass="fas fa-cog" (onClick)="menuopt.toggle($event)"></yo-button>
          <p-menu #menuopt [popup]="true" appendTo="body" [model]="items"></p-menu>
        </div>
        <div>
          <yo-button iconClass="fas fa-save" (onClick)="saveProduitDeclinaison()"
                     [disabled]="!gds.canModify(produitDeclinaison)" tooltip="Enregistrer"
                     buttonClass="cta-success"></yo-button>
        </div>
        <div>
          <yo-button *ngIf="produitDeclinaison?.id !== 0" iconClass="fas fa-trash"
                     (onClick)="openDeleteDialog(produitDeclinaison)"
                     [disabled]="!canDelete(produitDeclinaison)"
                     buttonClass="cta-inner-delete"
                     tooltip="Supprimer">
          </yo-button>
        </div>
        <div>
          <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
                     buttonClass="cta-inner-delete"></yo-button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</p-dialog>


<yo-dialog-change-unite-technique></yo-dialog-change-unite-technique>
