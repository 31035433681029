import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ClientsService} from '../../../core/services/entities/clients.service';
import {combineLatest, Observable} from 'rxjs';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';

@Injectable({
  providedIn: 'root'
})
export class PointDeLivraisonResolverService implements Resolve<PointDeLivraisonSupplier> {

  constructor(private gds: GenericDatagridService,
              private pdlSvc: PointDeLivraisonService,
              private clientsSvc: ClientsService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PointDeLivraisonSupplier> | Promise<PointDeLivraisonSupplier> | PointDeLivraisonSupplier => {

    const cs = new PointDeLivraisonSupplier();
    const idClient: number = +route.paramMap.get('idClient');
    const idPointDeLivraison: number = +route.paramMap.get('idPlc');
    const pointDeLivraison$ = this.gds.getOne(this.pdlSvc.getEntityName(), idPointDeLivraison);
    const client$ = this.gds.getOne(this.clientsSvc.getEntityName(), idClient);

    cs.idClient = idClient;

    // si nouveau point de livraison, on récupére le client sur lequel on veut le créer
    if (idPointDeLivraison === 0) {
      return client$.toPromise().then(response => {
        cs.pointDeLivraison = this.pdlSvc.createEmptyDTO();
        cs.pointDeLivraison.client = response.one;
        return cs;
      });
    }
    // si modification
    else {
      const all$ = combineLatest([pointDeLivraison$]);
      return all$.toPromise().then(response => {
        cs.pointDeLivraison = response[0].one;
        return cs;
      });
    }
  };

}


export class PointDeLivraisonSupplier {
  pointDeLivraison: PointDeLivraisonDTO;
  idClient: number;
}
