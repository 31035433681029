import {Observable, of, Subject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Title} from '@angular/platform-browser';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {UniteDeProduction__JourLivraisonDTO} from '../../dtos/unite-de-production__jour-livraison-dto';
import {SecteurDTO} from '../../dtos/secteur-dto';
import {UniteDeProduction__SecteurDTO} from '../../dtos/unite-de-production__secteur-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UniteDeProduction__LivraisonDTO} from '../../dtos/unite-de-production__livraison-dto';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../dtos/unite-de-production__secteur-fournisseur-dto';
import {sortBy as _sortBy, startCase as _startCase, uniq as _uniq, uniqBy as _uniqBy} from 'lodash';
import {UniteDeProduction__EquipeDTO} from '../../dtos/unite-de-production__equipe';
import {UniteDeProduction_Planning_EquipeDTO} from '../../dtos/unite_de_production__planning__equipe_d_t_o';
import {SiteDTO} from '../../dtos/site-dto';


import {RequestWrapper} from '../../suppliers/wrappers/request-wrapper';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormGroup} from '@angular/forms';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldFileSupplier} from '../../suppliers/form-field-file-supplier';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {FormFieldTextareaSupplier} from '../../suppliers/form-field-textarea-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {HttpService} from "../technique/http.service";
import {TYPE_LIST_UDP} from "../../constants";


const URL_SAVE_UPROD_FICHEIDENTITE = 'dolrest/gestion-unites-de-production/save/unite-de-production/fiche-identite';
const URL_SAVE_UPROD_LIVRAISONS = 'dolrest/gestion-unites-de-production/save/unite-de-production/livraisons';
const URL_SAVE_UPROD_ZONES_DE_STOCKAGE = 'dolrest/gestion-unites-de-production/save/unite-de-production/zones-de-stockage';
const URL_FIND_FABRICATION_BY_UNITEDEPRODUCTION = 'dolrest/gestion-unites-de-production/find/unite-de-production/fabrication';
const URL_FIND_EQUIPES_BY_UNITEDEPRODUCTION = 'dolrest/gestion-unites-de-production/find/unite-de-production/equipes';
const URL_FIND_ZONES_STOCKAGE_BY_UNITEDEPRODUCTION = 'dolrest/gestion-unites-de-production/find/unite-de-production/zones-stockage';
const URL_CHECK_UPROD_ESTAMPILLE_VETERINAIRE = 'dolrest/gestion-unites-de-production/estampille-veterinaire/unite-de-production/check/';
const URL_FIND_BY_ID_SITE = 'dolrest/gestion-unites-de-production/find-by-id-site';

const URL_GET_UDP_SECTEURS_FOURNISSEURS = `dolrest/gestion-unites-de-production/find/unite-de-production/secteurs-fournisseurs`;
const URL_GET_LIVRAISONS_OF_UDP_SECTEURS_FOURNISSEURS = `dolrest/gestion-unites-de-production/find/unite-de-production/sf/livraisons`;
const URL_GET_UDP_LIST = `dolrest/gestion-unites-de-production/unite-de-production-list-by-type-list`;
const URL_GET_UDP_SECTEUR_FOURNISSEUR = `dolrest/gestion-unites-de-production/find/unite-de-production/secteur-fournisseur`;
const URL_GET_UDP_ZONE_STOCKAGE_LIST = `dolrest/gestion-unites-de-production/find/unite-de-production/zones-stockage-list`;
const URL_FIND_UDP_BY_FILTERS = `dolrest/gestion-unites-de-production/unite-de-production/by-filters`;


@Injectable()
export class UnitesDeProductionService extends GenericHandler<UniteDeProductionDTO> {

  static subjectSite = new Subject();

  static subjectLibelle = new Subject();
  site$ = UnitesDeProductionService.subjectSite.asObservable();
  libelle$ = UnitesDeProductionService.subjectLibelle.asObservable();

  private subjectActionSelectionFilialesPreferees = new Subject<UniteDeProductionDTO>();
  actionSelectionFilialesPreferees$ = this.subjectActionSelectionFilialesPreferees.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service,
              router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
  }


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['code,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES UNITÉS DE PRODUCTION';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE UNITÉ DE PRODUCTION';

  getEntityName = (): string => 'UniteDeProduction';

  getFieldsDivers = (dto: UniteDeProductionDTO): FormFieldBaseSupplier<any>[] => {

    const readonly = !this.canModify(dto);

    return [
      new FormFieldTextboxSupplier({
        key: 'signature',
        label: 'Signature',
        maxLength: 100,
        value: dto.signature,
        required: false,
        order: 0,
        readonly: readonly
      }),

      new FormFieldTextboxSupplier({
        key: 'egalimThresholdBio',
        label: 'Seuil Egalim Bio',
        value: dto.egalimThresholdBio,
        required: false,
        order: 0,
        readonly: readonly
      }),

      new FormFieldTextboxSupplier({
        key: 'egalimThresholdSustainableProducts',
        label: 'Seuil Egalim Produits durables',
        value: dto.egalimThresholdSustainableProducts,
        required: false,
        order: 0,
        readonly: readonly
      }),

      new FormFieldTextareaSupplier({
          key: 'infosLivraison',
          label: 'Informations livraison',
          minLength: 0,
          maxLength: 360,
          value: dto.infosLivraison,
          required: false,
          order: 1,
          readonly: readonly

        }
      ),
      new FormFieldTextareaSupplier({
          key: 'infosFacturation',
          label: 'Informations facturation',
          minLength: 0,
          maxLength: 360,
          value: dto.infosFacturation,
          required: false,
          order: 2,
          readonly: readonly
        }
      ),


    ];
  };

  getFieldsAvecSitesSansUniteDeProduction = (dto: UniteDeProductionDTO, siteListWithoutUniteDeProduction: SiteDTO[]): FormFieldBaseSupplier<any>[] => {
    this.optionsSites = siteListWithoutUniteDeProduction;
    const response = this.getFields(dto);
    return response;
  };

  getFields = (dto: UniteDeProductionDTO): FormFieldBaseSupplier<any>[] => {
    const readonly = !this.canModify(dto);

    if (this.utils.isNullOrEmpty(dto)) {
      const site = new SiteDTO();
      // site.id = this.auth2Svc.utilisateur.sitePrincipal.id;
      // site.libelle = this.auth2Svc.utilisateur.sitePrincipal.libelle;
      dto = new UniteDeProductionDTO(0, site);
    }
    let formFieldSite: FormFieldTextboxSupplier;
    if (dto.id > 0) {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      });
    } else {

      // S'il n'existe qu'une seule possibilité pour le site de l'unité de production, alors on positionne
      // le champ site de unité de production avec ce site.
      if (this.optionsSites.length == 1) {
        dto.site = this.optionsSites[0];
      }
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: readonly,
        // type: 'linkedField',
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        options: this.optionsSites,
        required: true,
        linkedFieldFn: this.updateAdressesSites
      })

    }

    const formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly: readonly,
        linkedFieldFn: this.onChangeLibelle
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        minLength: 1,
        maxLength: 50,
        value: dto.code,
        required: true,
        order: 3,
        readonly: readonly
      }),


      new FormFieldFileSupplier({
        key: 'file',
        label: 'Photo',
        value: '',
        readonly: readonly,
        entityName: this.getEntityName().toLowerCase(),
        refresh: new Date().getTime(),
        ordre: 1,
        required: false,
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 5,
        readonly: !this.canModify(dto)
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): UniteDeProductionDTO => {
    // return new UniteDeProductionDTO(0, this.sitePrincipalId, this.sitePrincipalLibelle);
    return new UniteDeProductionDTO(0, null);
  };


  onChangeLibelle = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    UnitesDeProductionService.subjectLibelle.next(object);
  };

  /**
   * Enregistrer une unité de production
   * @param {UniteDeProductionDTO} uniteDeProduction
   */
  saveFicheIdentite = (uniteDeProduction: UniteDeProductionDTO, file: File, estampilleVeterinaire: File): Observable<ResponseWrapper<UniteDeProductionDTO>> => {

    if (this.utils.isNullOrEmpty(uniteDeProduction)) {
      return of(null);
    }

    const fd = new FormData();
    fd.set('myJson', JSON.stringify(uniteDeProduction));
    fd.set('file', file);
    fd.set('estampilleVeterinaire', estampilleVeterinaire);

    return this.httpSvc.post(URL_SAVE_UPROD_FICHEIDENTITE, fd);

  };

  checkEstampilleVeterinaire = (idUniteDeProduction: number): Observable<ResponseWrapper<boolean>> => {
    if (!idUniteDeProduction) {
      const rw: ResponseWrapper<boolean> = new ResponseWrapper();
      rw.one = false;
      return of(rw);
    }
    return this.http.get(`${URL_CHECK_UPROD_ESTAMPILLE_VETERINAIRE}${idUniteDeProduction}`)
      .pipe(
        catchError(error => this.utils.handleError(error)));
  };

  /**
   * Enregistrer le parametrage des livraisons d'une unité de production
   * @param {UniteDeProductionDTO} uniteDeProduction
   */
  saveLivraisons = (uniteDeProduction: UniteDeProductionDTO, livraisonsOfUdp: UniteDeProduction__LivraisonDTO[]): Observable<ResponseWrapper<UniteDeProductionDTO>> => {

    if (this.utils.isNullOrEmpty(uniteDeProduction)) {
      return of(null);
    }

    const reqWrapper: RequestWrapper = new RequestWrapper();
    reqWrapper.one = {udp: uniteDeProduction, livraisons: livraisonsOfUdp};

    return this.httpSvc.post(URL_SAVE_UPROD_LIVRAISONS, reqWrapper);
  };

  /**
   * Sauvegarde des zones de stockage d'une unité de production.
   * @param uniteDeProduction l'unité de production concernée.
   * @param zoneDeStockageList la liste des zones de stockage à associer à l'unité de production.
   */
  saveZonesDeStockage = (uniteDeProduction: UniteDeProductionDTO, zoneDeStockageList: number[]) => {
    return this.httpSvc.post(`${URL_SAVE_UPROD_ZONES_DE_STOCKAGE}?idUniteDeProduction=${uniteDeProduction.id}&idZoneStockageList=${zoneDeStockageList}`, null);
  };

  fetchStorageAreas = (filters: any) => {
    let url: string = 'dolrest/gestion-unites-de-production/unite-de-production/fetch-storage-areas?1';
    if (filters) {
      if (filters.idUniteProduction) url += `&idUniteProduction=${filters.idUniteProduction}`;
    }
    return this.httpSvc.get(url);
  }

  /**
   * Rechercher  les parametres de fabrication d'une unité de production
   * @param {UniteDeProductionDTO} uniteDeProduction
   */
  findFabrication = (idUniteDeProduction: number): Observable<ResponseWrapper<UniteDeProduction_Planning_EquipeDTO>> => {
    const params: HttpParams = new HttpParams().set('idUniteDeProduction', idUniteDeProduction + '')
    if (!this.utils.isNumberGt0(idUniteDeProduction)) {
      return of(null);
    }

    return this.httpSvc.get(URL_FIND_FABRICATION_BY_UNITEDEPRODUCTION, params).pipe(
      catchError(error => this.utils.handleError(error)));
  };


  /**
   * Recherche des UniteDeProduction__ZoneDeStockageDTO associées à une {@link UniteDeProductionDTO}.
   * @param idUniteDeProduction id de l'{@link UniteDeProductionDTO}.
   */
  findZonesStockage = (idUniteDeProduction: number): Observable<ResponseWrapper<UniteDeProduction__ZoneDeStockageDTO>> => {
    if (!this.utils.isNumberGt0(idUniteDeProduction)) {
      return of(null);
    }
    const params: HttpParams = new HttpParams().set('idUniteDeProduction', idUniteDeProduction + '');
    return this.httpSvc.get(URL_FIND_ZONES_STOCKAGE_BY_UNITEDEPRODUCTION, params);
  };

  /**
   * Rechercher  les equipes d'une unité de production
   * @param {UniteDeProductionDTO} uniteDeProduction
   */
  findEquipes = (idUniteDeProduction: number): Observable<ResponseWrapper<UniteDeProduction__EquipeDTO>> => {
    if (!this.utils.isNumberGt0(idUniteDeProduction)) {
      return of(null);
    }
    const params: HttpParams = new HttpParams().set('idUniteDeProduction', idUniteDeProduction + '')
    return this.httpSvc.get(URL_FIND_EQUIPES_BY_UNITEDEPRODUCTION, params).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  findByIdSite = (idSite: number): Observable<ResponseWrapper<UniteDeProductionDTO>> => {
    if (!idSite) return of(null);
    const params: HttpParams = new HttpParams().set('idSite', idSite + '')
    return this.httpSvc.get(URL_FIND_BY_ID_SITE, params).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  /***
   * cabler les jours semaine livraison pour le back
   */
  prepareJoursLivraisonDispo = (uniteDeProduction: UniteDeProductionDTO, selectedJourSemaineList: { value, viewValue }[]): UniteDeProductionDTO => {


    uniteDeProduction.uniteDeProduction__jourLivraisonList = [];
    selectedJourSemaineList.map(item => {
      const udpJs = new UniteDeProduction__JourLivraisonDTO(item.value, uniteDeProduction.id);

      uniteDeProduction.uniteDeProduction__jourLivraisonList.push(udpJs);
    });


    return uniteDeProduction;
  };

  /***
   * cabler les secteurs de rattachement
   */
  prepareSecteurs = (uniteDeProduction: UniteDeProductionDTO, selectedSecteurList: SecteurDTO[]): UniteDeProductionDTO => {

    uniteDeProduction.uniteDeProduction__secteurList = [];

    selectedSecteurList.map(item => {
      const udpS = new UniteDeProduction__SecteurDTO();
      udpS.idSecteur = item.id;
      udpS.idUniteDeProduction = uniteDeProduction.id;
      uniteDeProduction.uniteDeProduction__secteurList.push(udpS);
    });

    return uniteDeProduction;
  };

  prepareSecteursFournisseurs = (uniteDeProduction: UniteDeProductionDTO, livraisonsOfUDP: UniteDeProduction__LivraisonDTO[]): UniteDeProduction__SecteurFournisseurDTO[] => {

    let sfArr = [];

    if (!this.utils.isCollectionNullOrEmpty(livraisonsOfUDP)) {

      for (const liv of livraisonsOfUDP) {

        const udpSf = new UniteDeProduction__SecteurFournisseurDTO();
        udpSf.id = 0;
        udpSf.codeClient = liv.codeClient;
        udpSf.delaiLivraisonJours = liv.delaiLivraisonJours;
        udpSf.delaiLivraisonHeureLimite = liv.delaiLivraisonHeureLimite;
        udpSf.idSecteurFournisseur = liv.idSecteurFournisseur;
        udpSf.idUniteDeProduction = uniteDeProduction.id;
        udpSf.uniteDeProduction__livraisonList = [];

        sfArr.push(udpSf);

        sfArr = _uniqBy(sfArr, 'idSecteurFournisseur');
      }

    }

    return sfArr;

  };

  /**
   * cf.FormFieldBaseSupplier.linkedFieldFn: (value: string, form?:FormGroup, fields?:FormFieldBaseSupplier<any>[], object?:any) => void;
   * @param value
   * @param fields
   */
  updateAdressesSites = (value: any, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object?: any) => {
    UnitesDeProductionService.subjectSite.next(object);
  };

  /**
   * Récupérer les secteurs fournisseurs d'une unité de production
   * @param udp
   */
  getSecteursFournisseurs = (udp: UniteDeProductionDTO): Observable<ResponseWrapper<UniteDeProduction__SecteurFournisseurDTO>> => {
    if (!this.utils.isNullOrEmpty(udp)) {
      const params: HttpParams = new HttpParams().set('idUniteDeProduction', udp.id + '')
      return this.httpSvc.get(URL_GET_UDP_SECTEURS_FOURNISSEURS, params);
    }
  };

  /**
   * Récupérer un {@link UniteDeProduction__SecteurFournisseurDTO} connaissant l'id de son {@link UniteDeProductionDTO} et l'id de son {@link SecteurFournisseur}
   * @param idUniteDeProduction id de l'unité de production.
   * @param idSecteurFournisseur id du secteur fournisseur.
   */
  getUniteDeProductionSecteurFournisseur = (idUniteDeProduction: number, idSecteurFournisseur: number): Observable<ResponseWrapper<UniteDeProduction__SecteurFournisseurDTO>> => {
    if (!this.utils.isNullOrEmpty(idUniteDeProduction) && !this.utils.isNullOrEmpty(idSecteurFournisseur)) {
      const params: HttpParams = new HttpParams().set('idUniteDeProduction', idUniteDeProduction + '').set('idSecteurFournisseur', idSecteurFournisseur + '');
      return this.httpSvc.get(URL_GET_UDP_SECTEUR_FOURNISSEUR, params);
    }
  };

  /**
   * Récupérer le param des jours de livraison d'un fournisseur cablé sur une unité de production
   * @param uniteDeProduction__SecteurFournisseur
   */
  getLivraisons = (uniteDeProduction__SecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO): Observable<ResponseWrapper<UniteDeProduction__LivraisonDTO>> => {
    const params: HttpParams = new HttpParams().set('idUdpSecteurFournisseur', uniteDeProduction__SecteurFournisseur.id + '');
    return this.httpSvc.get(URL_GET_LIVRAISONS_OF_UDP_SECTEURS_FOURNISSEURS, params);
  };

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER L'UNITÉ DE PRODUCTION '${data.libelle.toUpperCase()}'`;


  /**
   * Récupérer les jours de livraison d'une unité de production
   * @param udp
   */
  getJoursLivraisonOfUdp = (udp: UniteDeProductionDTO): number[] => {

    let udpJoursLivraison = [];

    if (!this.utils.isNullOrEmpty(udp)
      && !this.utils.isCollectionNullOrEmpty(udp.uniteDeProduction__jourLivraisonList)
    ) {
      udpJoursLivraison = udp.uniteDeProduction__jourLivraisonList.map(item => item.numeroJourSemaine);
      udpJoursLivraison = _sortBy(udpJoursLivraison);
      udpJoursLivraison = _uniq(udpJoursLivraison);
    }

    return udpJoursLivraison;

  };

  announceActionSelectionFilialesPreferees = (uniteDeProduction: UniteDeProductionDTO) => {
    this.subjectActionSelectionFilialesPreferees.next(uniteDeProduction);
  };

  findUnitesProductionByFilters = (filters: any) => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.httpSvc.get(`${URL_FIND_UDP_BY_FILTERS}?${args}`);
  }

  /***
   * remonte la liste des udp en fonction du TYPE_LIST_UDP Enum renseigné
   */
  getUniteDeProductionlist = (typeList: TYPE_LIST_UDP) => {
    const params: HttpParams = new HttpParams().set('typeList', typeList + '');
    return this.httpSvc.get(URL_GET_UDP_LIST, params);
  };

  getUniteDeProductionById = (id: number) => {
    if (!this.utils.isNumberGt0(id)) {
      return of(null);
    }
    return this.gds.getOne(this.getEntityName(), id)
      .pipe(
        catchError(err => this.utils.handleError(err, true))
      );
  };

  getZoneDeStockageByIdUdp = (idUdp: number) => {
    const params: HttpParams = new HttpParams().set('idUdp', idUdp + '');
    return this.httpSvc.get(URL_GET_UDP_ZONE_STOCKAGE_LIST, params)
  };

  fetchIdsByMcPlc = (idMcplc: number): Observable<ResponseWrapper<number>> => this.httpSvc.get(`dolrest/gestion-unites-de-production/unites-de-production/by-mcplc?idMcplc=${idMcplc}`);

}
