import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem, SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BesoinsSupplier} from './besoins-resolver.service';
import {BesoinsService} from '../../../core/services/gestion-commandes/besoins.service';
import {ListeBesoinsDto} from '../../../core/dtos/liste-besoins-dto';
import {Title} from '@angular/platform-browser';
import {catchError, delay, tap} from 'rxjs/operators';
import {
  LISTEBESOINS_CHOIX_VUE,
  MSG_KEY,
  MSG_SEVERITY,
  REGLES_PREFERES,
  TYPE_EFFECTIF,
  USER_PREFERENCE,
  USER_TYPE_PREFERENCE,
  WORKFLOW_TASKS
} from '../../../core/constants';
import {WorkflowInstanceDTO} from '../../../core/dtos/workflow-instance-dto';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {ReglePrefereDTO} from '../../../core/dtos/regle-prefere-dto';
import {PreferencesUtilisateurService} from '../../../core/services/preferences-utilisateur.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {UtilsService} from "../../../core/utils/utils.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-besoins',
  templateUrl: './besoins.component.html',
  styleUrls: ['./besoins.component.scss']
})
export class BesoinsComponent implements OnInit, OnDestroy {

  @ViewChild('opInfosDenree', {static: false}) opInfosDenree: OverlayPanel;

  activeItem: MenuItem;
  chooseView: MenuItem[] = [
    {
      label: 'ARTICLES',
      routerLink: ['articles'],
      command: (event?: any) => this.prefUsrSvc.savePreference(USER_PREFERENCE.LISTEBESOINS_CHOIX_VUE, USER_TYPE_PREFERENCE.NUMBER, LISTEBESOINS_CHOIX_VUE.ARTICLES)
    },
    {
      label: 'DENRÉES',
      routerLink: ['denrees'],
      command: (event?: any) => this.prefUsrSvc.savePreference(USER_PREFERENCE.LISTEBESOINS_CHOIX_VUE, USER_TYPE_PREFERENCE.NUMBER, LISTEBESOINS_CHOIX_VUE.DENREES)
    },
  ];

  totalRecords = 0;
  loadingTable: boolean = false;

  typeEffectif: string;

  reglePrefereDefaut: SelectItem = {label: 'Paramétrage par défaut', value: REGLES_PREFERES.PAR_DEFAUT};
  selectedReglePrefere: any;
  reglesPreferes: SelectItem[] = [];
  bs: BesoinsSupplier;


  listeBesoin: ListeBesoinsDto;

  workflowInstance: WorkflowInstanceDTO;

  subBesoins: Subscription;
  subParentBesoins: Subscription;
  subWorkflowInstance: Subscription;
  subPlatsDemandeurs: Subscription;
  subActiveSecondaryRoute: Subscription;
  subPrefsUser : Subscription;
  selectedColumns: any[] = [];

  cols: any[] = [

    {field: 'udpId', header: `Unité de production`},
    {field: 'dateConsommation', header: 'Date de consommation'},
    {field: 'produitDeclinaisonId', header: 'Déclinaison'},
    {field: 'besoin', header: 'Besoin'},
    {field: 'stock', header: 'Quantité en Stock'}

  ];

  optionsReglePrefere: SelectItem[] = [
    {label: 'E-', value: false, title: 'Ne pas écraser les modifications manuelles faites sur les articles'},
    {label: 'E+', value: true, title: 'Écraser les modifications manuelles faites sur les articles'},
  ];

  constructor(public utils: UtilsService,
              private title: Title,
              public besoinsSvc: BesoinsService,
              private route: ActivatedRoute,
              private wkfSvc: WorkflowsService,
              private prefUsrSvc: PreferencesUtilisateurService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.title.setTitle('BESOINS');
    this.selectedColumns = this.cols;
    // changement d'etat du workflow
    this.workflowInstanceSubscription();
    // precharger l'onglet articles ou denrees selon les prefs utilisateurs
    this.preferenceUserSubscription();
    this.initRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subBesoins);
    this.utils.unsubscribe(this.subParentBesoins);
    this.utils.unsubscribe(this.subWorkflowInstance);
    this.utils.unsubscribe(this.subPlatsDemandeurs);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
    this.utils.unsubscribe(this.subPrefsUser);
  }

  /**
   * precharger l'onglet articles ou denrees selon les prefs utilisateurs
   */
  preferenceUserSubscription = () => {
    this.prefUsrSvc.preferencesOfUser$.subscribe(response=>{
      const item : LISTEBESOINS_CHOIX_VUE = this.prefUsrSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.LISTEBESOINS_CHOIX_VUE);
      switch (item) {
        case LISTEBESOINS_CHOIX_VUE.ARTICLES:
          this.activeItem = this.chooseView[0];
          break;
        case LISTEBESOINS_CHOIX_VUE.DENREES:
          this.activeItem = this.chooseView[1];
          break;
      }
    });
  };

  /**
   *  changement d'etat du workflow
   */
  workflowInstanceSubscription = () => {
    this.subWorkflowInstance = this.wkfSvc.workflowInstance$.subscribe(response => {
      // si la liste de besoin a été passée en commande fournisseur, on ferme le sidenav
      if (response.codeWorkflowStatut === WORKFLOW_TASKS.COMMANDES_FOURNISSEURS) {
        this.utils.sidenav = false;
      }
    });
  };

  help = () => undefined;

  initRouteData = () => {
    this.subBesoins = this.route.data
      .pipe(delay(0))
      .subscribe((data: { besoinsSupplier: BesoinsSupplier }) => {

        this.listeBesoin = data.besoinsSupplier.listeBesoin;
        this.workflowInstance = data.besoinsSupplier.workflowInstance;
        this.reglesPreferes = this.initReglesPreferesItems(data.besoinsSupplier.reglesPreferes, data.besoinsSupplier.workflowInstance, this.reglePrefereDefaut);
        this.selectedReglePrefere = this.initSelectedReglePrefereItem(this.reglesPreferes, data.besoinsSupplier.workflowInstance, this.reglePrefereDefaut);
        this.typeEffectif = this.getTypeEffectif(this.workflowInstance);
        this.bs = data.besoinsSupplier;

        if (this.utils.isNullOrEmpty(this.listeBesoin))
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Aucune liste de besoins n'a été trouvée pour le processus ${this.workflowInstance.libelle}`);
      });
  };

  getTypeEffectif = (workflowInstance: WorkflowInstanceDTO) => {
    switch (workflowInstance.typeEffectif) {
      case TYPE_EFFECTIF.previsionnelle :
        return 'Calcul fait sur l\'effectif previsionnel';
      case TYPE_EFFECTIF.fabrication :
        return 'Calcul fait sur l\'effectif de fabrication'
      case TYPE_EFFECTIF.facturation :
        return 'Calcul fait sur l\'effectif de facturation';
      default :
        return '';
    }
  };

  initReglesPreferesItems = (reglesPreferes: ReglePrefereDTO[], wi: WorkflowInstanceDTO, reglePrefereDefaut: SelectItem) => {
    const items: SelectItem[] = [];
    items.push(reglePrefereDefaut);
    for (const reglePrefere of reglesPreferes) {
      items.push({label: reglePrefere.libelle, value: reglePrefere.code});
    }
    return items;
  };

  initSelectedReglePrefereItem = (reglesPreferes: SelectItem[], wi: WorkflowInstanceDTO, reglePrefereDefaut: SelectItem) => {
    if (wi.reglePrefereParametrageDefaut) {
      return reglePrefereDefaut.value;
    }
    for (const reglePrefere of reglesPreferes) {
      if (reglePrefere.value === wi.reglePrefereCode) {
        return reglePrefere.value;
      }
    }
    return undefined;
  };

  changeReglePrefere = ($event: any) => {
    this.workflowInstance = this.getWorkflowInstanceWithReglePrefere(this.selectedReglePrefere, this.bs.reglesPreferes, this.workflowInstance);
    this.saveAndRecomputeWorkfowInstance(this.workflowInstance);
  };

  /**
   * Si paramétrage par defaut, on positionne reglePrefereParametrageDefaut à true dans workflowInstance
   * Sinon, on positionne la regle
   * @param reglePrefereItem
   * @param reglesPrefere
   * @param wiFrom
   */
  getWorkflowInstanceWithReglePrefere = (reglePrefereItem, reglesPrefere: ReglePrefereDTO[], wiFrom: WorkflowInstanceDTO) => {
    const wi = wiFrom;
    wi.reglePrefereParametrageDefaut = false;
    if (reglePrefereItem === this.reglePrefereDefaut.value) {
      wi.reglePrefereParametrageDefaut = true;
      wi.reglePrefereId = 1;
    }

    for (const reglePrefere of reglesPrefere) {
      if (reglePrefere.code === reglePrefereItem) {
        wi.reglePrefereId = reglePrefere.id;
      }
    }
    return wi;
  };

  getUdpInfoRegleValues = () => {
    if (this.selectedReglePrefere === REGLES_PREFERES.PAR_DEFAUT) {
      return this.bs.udpRpList;
    } else {
      return this.bs.udpList;
    }
  };

  getSelectedRegleLabel = (selectedReglePrefere: string) => {
    for (const rp of this.reglesPreferes) {
      if (rp.value === selectedReglePrefere) {
        return rp.label;
      }
    }
    return '';
  };

  /**
   * enregistrer le workflowinstance et recalculer les preferes sur la lb
   * @param workflowInstance
   */
  saveAndRecomputeWorkfowInstance = (workflowInstance: WorkflowInstanceDTO) => {
    this.besoinsSvc.changeReglePrefere(workflowInstance)
      .pipe(
        tap(response => this.besoinsSvc.announceCalculReglePrefereTermine(response)),
        catchError(err => this.utils.handleError(err))
      ).subscribe();

  };

  changeRPEcraserModificationManuelle = async ($event: any) => {
    if ($event) {
      if ($event.value) {
        const result = confirm(`Etes vous sûr de vouloir écraser toutes les modifications faites sur vos articles ?`, 'Confirmation de suppression')
        const isDeleted: boolean = await result;
        if (isDeleted) {
          this.saveAndRecomputeWorkfowInstance(this.workflowInstance);
        } else {
          this.workflowInstance.lbArticlesEcraserModifManuel = false;
        }
      } else {
        this.saveAndRecomputeWorkfowInstance(this.workflowInstance);
      }
    }
  };
}
