import {Injectable} from '@angular/core';
import {TYPE_TACHE} from '../entities/type-tache.service';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {PlanningEquipesSupplier} from '../../../shared/ui/gestion-unitesproduction/planning-equipes/PlanningEquipesSupplier';
import {UniteDeProduction_Planning_EquipeDTO} from '../../dtos/unite_de_production__planning__equipe_d_t_o';
import {UniteDeProduction__EquipeDTO} from '../../dtos/unite-de-production__equipe';
import {MJour} from '../../models/gestion-unites-production/m-jour';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {MJourDecalage} from '../../models/gestion-unites-production/m-jour-decalage';
import {HttpService} from '../technique/http.service';
import {SENS_JOUR_DECALAGE} from "../../constants";

export const URL_GET_FIND_UDP_PLANNING_EQUIPES = `dolrest/gestion-unites-de-production/find/unite-de-production/planning-equipes`;
export const URL_GET_FIND_TYPES_TACHES_CONFIGURES = `dolrest/gestion-unites-de-production/find/unite-de-production/planning-equipes/types-taches-configures`;
export const URL_POST_SAVE_UDP_PLANNING_EQUIPES = `dolrest/gestion-unites-de-production/save/unite-de-production/planning-equipes`;


@Injectable({
  providedIn: 'root'
})
export class PlanningEquipesService {


  readonly BLANK_JOURS_DECALAGE = new MJourDecalage('Jour même', 0);

  constructor(private httpSvc: HttpService,
              private utils: UtilsService) {
  }

  getPlanning = (idUniteDeProduction: number, typeTache: TYPE_TACHE) => {

    const params = new HttpParams()
      .set('idUdp', idUniteDeProduction + '')
      .set('typeTacheCode', typeTache);

    return this.httpSvc.get(URL_GET_FIND_UDP_PLANNING_EQUIPES, params);
  };

  getTypesTachesConfiguresByUdp = (idUniteDeProduction: number) => {

    const params = new HttpParams()
      .set('idUdp', idUniteDeProduction + '');

    return this.httpSvc.get(URL_GET_FIND_TYPES_TACHES_CONFIGURES, params);
  };

  savePlanning = (cells: Map<string, UniteDeProduction_Planning_EquipeDTO>) => {

    if (cells) {

      const udpPlannningEquipeElements: UniteDeProduction_Planning_EquipeDTO[] = Object.entries(cells).map(item => item[1]);

      return this.httpSvc.post(URL_POST_SAVE_UDP_PLANNING_EQUIPES, udpPlannningEquipeElements);
    }

    return of(null);

  };

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, typeTache: TYPE_TACHE, joursDecalageMax: number): Observable<PlanningEquipesSupplier> | Promise<PlanningEquipesSupplier> | PlanningEquipesSupplier => {

    const idUniteDeProduction: number = +route.parent.parent.paramMap.get('idUniteDeProduction');
    const planningEquipes$ = this.getPlanning(idUniteDeProduction, typeTache);

    const all$ = combineLatest([planningEquipes$]);
    const result$ = all$.pipe(
      map(results => {
        const planningResult: any = results[0];

        if (!this.utils.isResponseSupplierError(planningResult)) {

          const cells: Map<string, UniteDeProduction_Planning_EquipeDTO> = planningResult.additionalProperties['cells'];
          const rows: UniteDeProduction__EquipeDTO[] = planningResult.additionalProperties['rows'];
          const cols: MJour[] = planningResult.additionalProperties['cols'];
          const uniteDeProduction: UniteDeProductionDTO = planningResult.additionalProperties['uniteDeProduction'];
          const sjd = this.detectSensJourDecalage(typeTache);

          return new PlanningEquipesSupplier(uniteDeProduction, cells, rows, cols, typeTache, joursDecalageMax, sjd);
        } else {
          return null;
        }


      }));
    return result$;
  };

  private detectSensJourDecalage(typeTache: TYPE_TACHE): SENS_JOUR_DECALAGE {

    let sjd = SENS_JOUR_DECALAGE.MOINS;

    switch (typeTache) {
      case TYPE_TACHE.APPROVISIONNEMENT:
        sjd = SENS_JOUR_DECALAGE.MOINS;
        break;
      case TYPE_TACHE.SORTIE:
        sjd = SENS_JOUR_DECALAGE.MOINS;
        break;
      case TYPE_TACHE.FABRICATION:
        sjd = SENS_JOUR_DECALAGE.MOINS;
        break;
      case TYPE_TACHE.LIVRAISON:
        sjd = SENS_JOUR_DECALAGE.MOINS;
        break;
      case TYPE_TACHE.CONDITIONNEMENT:
        sjd = SENS_JOUR_DECALAGE.PLUS;
        break;
      case TYPE_TACHE.PREPA_LIVRAISON:
        sjd = SENS_JOUR_DECALAGE.MOINS;
        break;
    }

    return sjd;
  }


  getJourDecalageList(jourSemaine: number, joursDecalageMax: number, selectedJours: MJour[], sjd: SENS_JOUR_DECALAGE): MJourDecalage[] {

    let jourDecalageList = [];

    switch (sjd) {
      case SENS_JOUR_DECALAGE.PLUS:
        jourDecalageList = this.createPositiveJoursDecalages(jourSemaine, joursDecalageMax);
        break;
      case SENS_JOUR_DECALAGE.MOINS:
        jourDecalageList = this.createNegativeJoursDecalages(jourSemaine, joursDecalageMax);
        break;
    }

    return jourDecalageList;
  }

  private createNegativeJoursDecalages(cursor: number, joursDecalageMax: number) {

    const jourDecalageList = [];
    const current = new MJourDecalage('Jour même', 0);
    jourDecalageList.push(current);

    let nbJoursDecalage = 1;
    for (let jourSemaine = cursor - 1; nbJoursDecalage <= joursDecalageMax; jourSemaine--, nbJoursDecalage++) {

      if (jourSemaine < 1) {
        jourSemaine = 7;
      }

      const mJour: MJourDecalage = this.createJourDecalage(jourSemaine, nbJoursDecalage, SENS_JOUR_DECALAGE.MOINS);
      jourDecalageList.push(mJour);
    }


    return jourDecalageList;
  }

  private createPositiveJoursDecalages(cursor: number, joursDecalageMax: number) {

    const jourDecalageList = [];
    const current = new MJourDecalage('Jour même', 0);
    jourDecalageList.push(current);

    let nbJoursDecalage = 1;
    for (let jourSemaine = cursor + 1; nbJoursDecalage <= joursDecalageMax; jourSemaine++, nbJoursDecalage++) {

      if (jourSemaine > 7) {
        jourSemaine = 1;
      }

      const mJour: MJourDecalage = this.createJourDecalage(jourSemaine, nbJoursDecalage, SENS_JOUR_DECALAGE.PLUS);
      jourDecalageList.push(mJour);
    }


    return jourDecalageList;
  }

  private createJourDecalage(jourSemaine: number, nbJoursDecalage: number, sjd: SENS_JOUR_DECALAGE,) {

    let jd = null;

    switch (jourSemaine) {
      case 1 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Lundi', nbJoursDecalage);
        break;
      case 2 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Mardi', nbJoursDecalage);
        break;
      case 3 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Mercredi', nbJoursDecalage);
        break;
      case 4 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Jeudi', nbJoursDecalage);
        break;
      case 5 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Vendredi', nbJoursDecalage);
        break;
      case 6 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Samedi', nbJoursDecalage);
        break;
      case 7 :
        jd = new MJourDecalage(`J${sjd}` + nbJoursDecalage + ' Dimanche', nbJoursDecalage);
        break;
    }

    return jd;


  }


}
