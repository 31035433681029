import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {CommandesService} from '../../core/services/gestion-commandes/commandes.service';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {UnitesDeProductionService} from '../../core/services/entities/unites-de-production.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {BoncfService} from '../../core/services/entities/boncf.service';
import {BoncfStatutService} from '../../core/services/entities/boncf-statut.service';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {BoncfStatutDTO} from '../../core/dtos/boncf-statut-d-t-o';
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {AppellationDTO} from '../../core/dtos/appellations-dto';
import {TYPE_LIST_UDP} from "../../core/constants";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";

@Injectable({
  providedIn: 'root'
})
export class TableauDeBordResolverService implements Resolve<TableauDeBordSupplier>{

  constructor(private commandesSvc:CommandesService,
              private unitesDeProductionSvc:UnitesDeProductionService,
              private bcStatusSvc: BoncfStatutService,
              private bcfSvc: BoncfService,
              public appellationsSvc: AppellationsService,
              private gds:GenericDatagridService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService) { }


  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TableauDeBordSupplier> | Promise<TableauDeBordSupplier> | TableauDeBordSupplier => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    const unitesDeProduction$ = this.unitesDeProductionSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const statutsCommandeList$ = this.gds.search(this.bcStatusSvc.filterStatus());
    const appellations$ = this.graphQlSvc.sendQuery(`
      {
        allAppellations(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            durable,
            bio,
            site {
                id,
                libelle,
            },
        }
      }
    `);

    const all$ = combineLatest([unitesDeProduction$,statutsCommandeList$, appellations$]);

    return all$.toPromise().then(response=>{

      const unitesDeProduction = response[0].resultList;
      const statuts = response[1].resultList;
      const appellations = response[2].allAppellations;

      const tbs = new TableauDeBordSupplier();
      tbs.unitesProduction = unitesDeProduction;
      tbs.statuts = statuts;
      tbs.appellations = appellations;

      return tbs;
    });

  };
}

export class TableauDeBordSupplier{

  contrats:ContratmenuDTO[];
  unitesProduction: UniteDeProductionDTO[];
  statuts: BoncfStatutDTO[];
  appellations: AppellationDTO[];

}
