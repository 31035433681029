import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {ObjectDTO} from '../../dtos/object-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {SiteDTO} from '../../dtos/site-dto';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class GenericHandler<T extends ObjectDTO> {

  environnement$;
  public subjectObjects = new Subject<T[]>();
  objects$ = this.subjectObjects.asObservable();
  protected optionsSites: SiteDTO[] = [];
  protected subjectSavedDTO = new Subject<ResponseWrapper<ObjectDTO>>();
  savedDto$ = this.subjectSavedDTO.asObservable();
  protected subjectDeletedDTO = new Subject<ResponseWrapper<number>>();
  deletedDto$ = this.subjectDeletedDTO.asObservable();

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public router: Router,
              public http: HttpClient,
              public title: Title
  ) {

    this.environnement$ = this.auth2Svc.environnement$;

    if(this.environnement$){
      this.environnement$.subscribe(response => {
        // Quand l'utilisateur change d'environnement, il faut que la liste optionsSites soit mise à jour.
        this.optionsSites = this.auth2Svc.utilisateur.siteListLocaux;
      });
    }



  }


  /**
   * Annoncer qu'un objet a été supprimé côté back
   * @param {ObjectDTO} response
   */
  announceDeletedDTO(response: ResponseWrapper<number>) {
    this.subjectDeletedDTO.next(response);
  }

  /**
   * Annoncer qu'un objet a été enregistré côté back
   * @param {ObjectDTO} response
   */
  announceSavedDTO(response: ResponseWrapper<ObjectDTO>) {
    // console.log('announceSavedDTO',response);
    this.subjectSavedDTO.next(response);
  }

  announceObjects(objects: T[]) {
    this.subjectObjects.next(objects);
  }

  /***
   * Fournir le dto pour générer les champs du formulaire
   * @param dto
   * @returns {FormFieldBaseSupplier<any>[]}
   */
  abstract getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[];


  /**
   * Triage de la grille
   * @returns {string[]}
   */
  abstract getSort(): string[];

  /**
   * Ajouter une aide
   */
  abstract getHelp(): DialogMsgSupplier;

  /**
   *  doit renvoyer true si on ne veut trier que sur le site principal et les elements actifs
   *  oas => Only Actif and Siteprincipal
   * @returns {boolean}
   */
  abstract getOas(): boolean;


  abstract getEntityName(): string;

  equalsEntityName(svcEntityName: string, entityName: string) {
    if (!this.utils.isNullOrEmpty(svcEntityName) && !this.utils.isNullOrEmpty(entityName)) {
      if (svcEntityName.toLowerCase() === entityName.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Début du label du nombre d'éléments dans une grille
   */
  abstract getTotalRecordsLabel(): string;

  /**
   * Titre du formulaire ou de la datagrid
   */
  abstract getTitle(): string;

  /**
   * Libellé du bouton de création
   */
  abstract getCreateNewObjectLabel(): string;

  /**
   * Libellé dialog Modification
   */
  abstract getEditObjectLabel(data: ObjectDTO): string;

  /**
   * Récupérer la référence externe et la positionner dans la liste deroulante
   * @param {ObjectDTO} dto
   * @param {{key; value}[]} options
   * @returns {ObjectDTO}
   */
  public getSelectedItem(dto: ObjectDTO, options: { key, value }[]): ObjectDTO {

    if (!this.utils.isNullOrEmpty(dto) && !this.utils.isCollectionNullOrEmpty(options)) {

      let selectedItem = this.utils.getFirstElement(options.filter(opt => ((opt.value.id == dto.id))));
      if (!this.utils.isNullOrEmpty(selectedItem)) {
        return selectedItem.value;
      }
    }
    return dto;
  }

  /**
   * Indique si l'{@link ObjectDTO} passé en paramètre peut être détruit.
   * Il ne peut l'être que s'il est lié au site principal de l'utilisateur connecté.
   * @param {ObjectDTO} element
   * @returns {boolean}
   */
  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element)) {
      return true;
    }
    if (this.utils.isNullOrEmpty(element.site)) {
      return true;
    }
    if (this.utils.isNullOrEmpty(element.site.id)) {
      return true;
    }

    if (element.site.id == 0) {
      return true;
    }

    let response: boolean = false;

    if (!this.utils.isNullOrEmpty(element)) {
      if (!this.utils.isNullOrEmpty(element.site)) {
        response = this.auth2Svc.isSiteLocal(element.site.id);
      }
    }

    return response;
  }

  /**
   * Le site ne peut être modifié qu'en création.
   * @param element
   */
  canModifySite(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element) || element.id < 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Récupérer la liste des objets rattachés à l'environnement courant (site local + sites référent)
   * --> requete asynchrone sur le back
   */
  abstract getAllFromEnvironnement(): void;

  abstract createEmptyDTO(): T;

  /**
   * Alimenter un tableau {key, value}
   *  @param backData
   * @returns {any[]}
   */
  initArray<T extends ObjectDTO>(backData: T[]): { key, value }[] {

    let data = this.utils.initArray(backData);

    return data;
  }

  /**
   * Indique si le bouton permettant de créer un objet doit être affiché.
   * @returns {boolean}
   */
  displayCreationButton(): boolean {
    return true;
  }

  /**
   * Renvoie une <b>string</b> représentant une couleur en fonction de la couleur passsée ne paramètre.
   * Exemple : codeCouleur = 109551( 01ABEF en hexadécimal), valeur renvoyée : #01ABEF
   * @param {number} codeCouleur l'entier représentant une couleur.
   * @returns {string}
   */
  protected getHexColor(codeCouleur: number): string {
    let response: string = this.utils.getHexColor(codeCouleur);
    return response;
  }

  private buildLabelsMap_(arrayFields: FormFieldBaseSupplier<any>[]): Map<string, string> {
    let map: Map<string, string> = new Map<string, string>();
    arrayFields.forEach(formFieldBaseSupplier => {
      map.set(formFieldBaseSupplier.label, formFieldBaseSupplier.key);
    });

    return map;

  }

  /**
   *
   * @param dto
   */
  public createFormFieldBaseSupplierForSite(dto: ObjectDTO, order: number): FormFieldBaseSupplier<any> {

    // Le FormFieldBaseSupplier à renvoyer
    let formFieldSite: FormFieldBaseSupplier<any>;

    // ObjectDTO en création
    if (dto.id == 0) {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        options: this.optionsSites
      });

      // ObjectDTO en modification ou consultation
    } else {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      });
    }

    return formFieldSite;
  }
}
