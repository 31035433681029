import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {UtilisateurService} from '../../../core/utilisateur.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {UtilisateurConnexionDTO} from '../../../core/dtos/utilisateur-connexion-dto';

import * as moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import {DxDataGridComponent} from 'devextreme-angular';
import {DevextremeService} from '../../../core/services/technique/devextreme.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';

@Component({
  selector: 'yo-grilleutilisateursconnexions',
  templateUrl: './grilleutilisateursconnexions.component.html',
  styleUrls: ['./grilleutilisateursconnexions.component.scss']
})
export class GrilleutilisateursconnexionsComponent implements OnInit {

  @ViewChild(DxDataGridComponent, {static: false}) grid: DxDataGridComponent;

  start = true;
  totalRecords: number;
  loading: boolean;

  NB_LAST_CONNECTIONS = 100;

  dataSource: CustomStore;

  constructor( private router: Router,
               private routeMapSvc: RoutemapService,
               private dxSvc: DevextremeService,
               private gds: GenericDatagridService,
               public utils: UtilsService,
               private utilisateurService: UtilisateurService) { }

  ngOnInit() {
    this.utils.setTitle('Gestion des connexions des utilisateurs');

    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        return this.utilisateurService.searchUtilisateurConnexion(`page=0&size=${this.NB_LAST_CONNECTIONS}&sort=date,desc`)
          .toPromise()
          .then(o => {
            return {
              data: o.content.map(res => {
                const dateRes: string = this.utils.getFrenchDateHHMM(res.date);
                const dateDerniereUtilisation: string = this.utils.getFrenchDateHHMM(res.dateDerniereUtilisation);
                return Object.assign({}, { jour: dateRes.slice(0, 10), heure: dateRes.slice(dateRes.length - 5, dateRes.length)
                                                      , jourDu: dateDerniereUtilisation.slice(0, 10), heureDu: dateDerniereUtilisation.slice(dateDerniereUtilisation.length - 5, dateDerniereUtilisation.length) }
                                                      , res);
              }),
              totalCount: o.totalElements
            };
        });
      }
    });
  }

  isActif = (utilisateurConnexionDTO: UtilisateurConnexionDTO): boolean => {
    const dateNow = new Date();
    dateNow.setMinutes(dateNow.getMinutes() - 5);
    const date = moment(dateNow);
    const dateToken = moment(utilisateurConnexionDTO.dateDerniereUtilisation, 'DD/MM/YY hh:mm');
    if (date.isBefore(dateToken)) {
      return true;
    }
    return false;
  };

}
