import {Injectable} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ProduitRouteMapService} from './produit-routemap.service';
import {Observable, Subject} from 'rxjs';
import {UtilsService} from '../../utils/utils.service';
import {CompoDTO} from '../../dtos/ciqual/compo-dto';
import {catchError} from 'rxjs/operators';
import {ApportNutritionnelDTO} from '../../dtos/apport-nutritionnel-dto';
import {AlimDTO} from "../../dtos/ciqual/alim-dto";


export const URL_GET_CIQUAL_KEYWORD = `dolrest/ciqual/alim/findByKeywordsAlimNomFrAnd`;
export const URL_GET_COMPO_ALIM_ID = `dolrest/ciqual/compo/findCompoByAlimId`;


@Injectable()
export class CiqualService {


  private subjectAlimDTO = new Subject<Report>();
  subjectAlimDTO$ = this.subjectAlimDTO.asObservable();

  private subjectOpenSearchDialog = new Subject();
  openSearchDialog$ = this.subjectOpenSearchDialog.asObservable();

  private subjectFindCompByAlimID = new Subject<CompoDTO[]>();
  subjectFindCompByAlimID$ = this.subjectFindCompByAlimID.asObservable();


  constructor(private http: HttpClient,
              private produitRouteMapService: ProduitRouteMapService,
              private utils: UtilsService) {
  }

  public save = (listProduitDeclinesDTO: ProduitDeclinaisonDTO[]): Observable<ProduitDeclinaisonDTO[]> => {
    let url = this.produitRouteMapService.getSaveProduitsApportsNutritionnelsSLink();
    return this.http.post<ProduitDeclinaisonDTO[]>(url, listProduitDeclinesDTO)
      .pipe(
        catchError(error => this.utils.handleError(error))
      )


  };

  announceAlimDTO = (report: any) => {
    this.subjectAlimDTO.next(report);
  };

  /**
   *
   * @param {number} alimId ID de l'Alim recherché.
   * @returns {Observable<CompoDTO[]>}
   */
  findCompoListByAlimId = (alimId: number): Observable<CompoDTO[]> => this.http.get(URL_GET_COMPO_ALIM_ID, {
    params: new HttpParams()
      .set('alimId', alimId + '')
  }).pipe(
    catchError(error => this.utils.handleError(error, true))
  );

  /**
   * Recherche d'un {@link ApportNutritionnelDTO} connaissant son champ <b>libelle</b> au sein d'un tableau d'{@link ApportNutritionnelDTO}s.
   * @param {ApportNutritionnelDTO[]} apportNutritionnelDTOList le tableau d'{@link ApportNutritionnelDTO}s au sein
   * duquel s'effectue la recherche.
   * @param {string} libelle le libellé recherché.
   * @returns {ApportNutritionnelDTO} l'{@link ApportNutritionnelDTO} correspondant au libellé recherché ou <b>null</b>.
   */
  findApportNutritionnelByLibelle = (apportNutritionnelDTOList: ApportNutritionnelDTO[], libelle: string): ApportNutritionnelDTO => {
    let response: ApportNutritionnelDTO = null;

    for (let apportNutritionnelDTO of apportNutritionnelDTOList) {
      if (apportNutritionnelDTO.libelle === libelle) {
        response = apportNutritionnelDTO;
        break;
      }
    }

    return response;
  };


  findByKeywordsAlimNomFrAnd = (strKeyWords: string) => this.http.get(URL_GET_CIQUAL_KEYWORD, {
    params: new HttpParams()
      .set('strKeyWords', strKeyWords + '')
  }).pipe(
    catchError(error => this.utils.handleError(error, true))
  );


  announceOpenSearchDialog = () => {
    this.subjectOpenSearchDialog.next();
  };


}

export interface Report {
  propage: boolean;
  selected : AlimDTO;
}
