<dx-popup
  [showTitle]="true"
  [title]="title"
  [visible]="isPopupDisplayed"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <form [formGroup]="formGroupCtrl" (ngSubmit)="save()" novalidate>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box [value]="environnement.libelle"
                     class='inputfield'
                     placeholder="Libellé"
                     formControlName="libelle">
        </dx-text-box>
      </div>
    </div>
    <div class="dialog-line">
      <div class="label" title="Code">Code <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box [value]="environnement.code"
                     class='inputfield'
                     placeholder="Code"
                     formControlName="code">
        </dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label text-nowrap" title="Sites locaux">Sites locaux <span class="danger-color">*</span></div>
      <div class="value">
        <yo-tagbox
          #sitesLocaux
          [data]="listSites"
          [selectedItems]="selectedSitesLocaux"
          (onValueChange)="onSiteLocauxChange($event)"
        >
        </yo-tagbox>
      </div>
    </div>
    <div class="dialog-line">
      <div class="label text-nowrap" title="Sites référents">Sites référents <span
        class="inline-block danger-color">*</span>
      </div>
      <div class="value">
        <yo-tagbox
          #sitesReferents
          [data]="listSites"
          [selectedItems]="selectedSitesReferents"
          (onValueChange)="onSiteReferentChange($event)"
        >
        </yo-tagbox>
      </div>
    </div>

    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button type="submit"
                   name="save"
                   buttonClass="cta-success"
                   tooltip="Enregistrer l'environnement"
                   iconClass="fas fa-save"
                   [disabled]="!formGroupCtrl">
        </yo-button>
        <yo-button tooltip="Fermer"
                   buttonClass="cta-delete"
                   (onClick)="closeDialog()"
                   iconClass="fas fa-times"
        ></yo-button>
      </div>
    </div>
  </form>
</dx-popup>
