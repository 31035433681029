import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {TypeTacheDTO} from '../../dtos/type-tache-dto';


@Injectable({
  providedIn: 'root'
})
export class TypeTacheService extends GenericHandler<TypeTacheDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private  gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);


  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getTitle(): string {
    return '';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  getEntityName(): string {
    return 'typeTache';
  }




  getFields(dto: TypeTacheDTO): FormFieldBaseSupplier<any>[] {

    let fields = [];

    return fields;

  }


  getAllFromEnvironnement(): void {

  }


  createEmptyDTO(): TypeTacheDTO {
    return new TypeTacheDTO();
  }



  getEditObjectLabel(data: TypeTacheDTO): string {
    return ``;
  }




}

export enum TYPE_TACHE{
  APPROVISIONNEMENT='APPRO',
  CONDITIONNEMENT='CONDI',
  FABRICATION='FAB',
  LIVRAISON='LIV',
  PREPA_LIVRAISON='PREAP_LIV',
  SORTIE='SORTIE',
}
