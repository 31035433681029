import {ObjectDTO} from "./object-dto";


export class UniteDeProduction__JourLivraisonDTO extends ObjectDTO {

  numeroJourSemaine :number;
  idUniteDeProduction : number;
  actif:boolean;


  constructor(numeroJourSemaine: number, idUniteDeProduction: number) {
    super();
    this.numeroJourSemaine = numeroJourSemaine;
    this.idUniteDeProduction = idUniteDeProduction;
    this.actif=true;
  }
}
