import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'yo-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  typeCatalogue: MenuItem[] = [];
  activeItem: MenuItem;


  ngOnInit(): void {
    this.typeCatalogue = [{label: 'Articles', routerLink: ['article-affecte']}];
  }

}
