/**
 * Classe utilitaire pour l'utilisation du composant kjlnhjk <p-tree></p-tree>
 */
export class PTreeNode {

  /**
   * Identifiant du noeud
   */
  id: number;

  /**
   * Label du noeud
   */
  label: string;

  /**
   * Icône qui doit apparaître lorsqu'un sous-arbre du noeud s'ouvre
   */
  expandedIcon: string;

  /**
   * Icône qui doit apparaître lorsqu'un sous-arbre du noeud se ferme
   */
  collapsedIcon: string;

  /**
   * Sous-arbres / enfants du noeud
   */
  children: PTreeNode[];

  /**
   * Ouvre le noeud
   */
  expanded: boolean;

  /**
   * Données uniquement utiles pour initialiser un TableTree.
   * Exemple : { nom: "Toto" }
   */
  data: object;

  /**
   * Informations extra allant au-delà du rôle principal du PTreeNode.
   */
  extra: object;

  constructor(id: number, label: string, expandedIcon: string, collapsedIcon: string, children: PTreeNode[], extra: object = {}) {
    this.id = id;
    this.label = label;
    this.expandedIcon = expandedIcon;
    this.collapsedIcon = collapsedIcon;
    this.children = children || [];
    this.expanded = false;
    this.extra = extra;
  }

}
