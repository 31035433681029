import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {AllergeneDTO} from "../../../core/dtos/allergene-dto";
import {AllergenesService} from "../../../core/services/entities/allergenes-service.service";
import {ImageSize} from "../../../core/enums/image-size-enum";

@Component({
  selector: 'yo-allergeneEditionDialog',
  templateUrl: './allergene-edition-dialog.component.html',
  styleUrls: ['./allergene-edition-dialog.component.scss']
})
export class AllergeneDialogComponent implements OnInit {

  subOpenDialog: Subscription

  allergene: AllergeneDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle: string = 'Modification d\'un allergène';

  sitePlaceholder: string = 'Selectionner le site...';

  displayDialog = false;

  idSelectedSite: number;

  selectedFile: File;

  selectedFileUrl: string;

  currentFileUrl: string;

  displayNewImage: boolean;

  fileInput: HTMLInputElement;

  isDropZoneActive: boolean;

  dropZone: HTMLElement;

  dragCounter: number = 0;

  sendDeleteCurrentImageRequest: boolean;

  animatedContent: HTMLDivElement;

  isCurrentImageInterractable: boolean;

  needToDeleteCurrentImage: boolean;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public allergenesSvc: AllergenesService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initDragAndDrop();
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  initDragAndDrop = () => {
    this.dropZone = document.getElementById("dropzone");
    this.fileInput = document.getElementById("fileInput") as HTMLInputElement;
    this.animatedContent = document.getElementById("animatedContent") as HTMLDivElement;
    this.animatedContent.addEventListener("transitionrun", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitionstart", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitioncancel", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitionend", this.displayAnimatedContent);

    this.dropZone.addEventListener("dragenter", this.toggleDragZone);
    this.dropZone.addEventListener("dragover", this.toggleDragZone);
    this.dropZone.addEventListener("dragleave", this.toggleDragZone);
    this.dropZone.addEventListener("drop", this.drop);
  }

  toggleDragZone = (e) => {
    e.preventDefault();
    if (e.type === "dragenter")
      this.dragCounter += 1;
    else if (e.type === "dragleave")
      this.dragCounter -= 1;

    this.isDropZoneActive = this.dragCounter != 0;
  }

  displayAnimatedContent = (event: TransitionEvent) => {
    console.log("trigger : " + event.type);
    if ((event.type === "transitionend" || event.type === "transitioncancel") && this.animatedContent.classList.contains("hide"))
      this.animatedContent.classList.add("hidden");
    else if ((event.type === "transitionrun" || event.type === "transitionstart") && this.animatedContent.classList.contains("hidden"))
      this.animatedContent.classList.remove("hidden");
  }

  drop = (e) => {
    e.preventDefault();
    this.dragCounter = 0;
    this.isDropZoneActive = false;
    e.dataTransfer
    this.onImageSelected({
      target: {
        files: e.dataTransfer.files
      }
    });
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.allergenesSvc.openDialogEdition$
      .subscribe((cdt: AllergeneDTO) => {
        this.displayDialog = true;
        if (!cdt) {
          this.allergene = new AllergeneDTO();
          this.allergene.id = 0;
          this.dialogTitle = 'Création d\'un allergène';
        } else {
          this.allergene = cdt;
          this.dialogTitle = 'Modification d\'un allergène';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.displayNewImage = false;
    this.currentFileUrl = null;
  };

  save = (): void => {
    if (this.form.valid) {
      let newAllergene: AllergeneDTO = {...this.allergene};
      newAllergene.site = {id: this.idSelectedSite} as SiteDTO;
      newAllergene.libelle = this.form.controls['libelle'].value;
      newAllergene.appellationInformationConsommateur = this.form.controls['appellationInformationConsommateur'].value;
      newAllergene.actif = this.form.controls['actif'].value;
      this.allergenesSvc.save(newAllergene, this.needToDeleteCurrentImage, this.selectedFile)
        .subscribe(response => {
          const isUpdate = newAllergene.id !== null && newAllergene.id !== undefined && newAllergene.id !== 0;
          this.allergenesSvc.announceAllergeneSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de l'allergène réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.displayNewImage = false;
    this.needToDeleteCurrentImage = false;
    this.resetSelectedImage();
    this.sitePlaceholder = !this.canModify() ? this.allergene?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.allergene?.site?.id;
    this.currentFileUrl = this.allergene?.id != null && this.allergene.id > 0 ? this.allergenesSvc.getUrl(this.allergene, ImageSize.Full, true) : this.allergenesSvc.noImagePath;
    this.selectedFileUrl = null;
    this.form = new FormGroup({
      libelle: new FormControl(this.allergene?.libelle),
      appellationInformationConsommateur: new FormControl(this.allergene?.appellationInformationConsommateur),
      actif: new FormControl(this.allergene?.actif != null ? this.allergene.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.allergenesSvc.canModify(this.allergene);
  }

  onImageSelected = (event: any) => {
    this.needToDeleteCurrentImage = false;
    this.selectedFile = event?.target?.files[0];
    this.selectedFileUrl = null;

    this.displayNewImage = this.selectedFile != null;

    if (this.displayNewImage) {
      const reader = new FileReader();

      reader.onload = (_event: any) => {
        this.selectedFileUrl = _event.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  deleteCurrentImage = () => {
    this.resetSelectedImage();
    this.needToDeleteCurrentImage = true;
    this.displayNewImage = true;
    this.sendDeleteCurrentImageRequest = true;
  }

  cancelSelectedImage = () => {
    this.resetSelectedImage();
  }

  resetSelectedImage = () => {
    if (this.fileInput)
      this.fileInput.value = null;
    this.onImageSelected(null);
  }

  onCurrentImageLoaded = (event: Event) => {
    this.isCurrentImageInterractable = !(event.target as HTMLImageElement).src.endsWith(this.allergenesSvc.noImagePath);
  }

}
