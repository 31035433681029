import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, HAS_ADMINISTRATION, HAS_MONBUREAU, OUTLET_SECONDARY} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {GpRootComponent} from "./gp-root/gp-root.component";
import {SelectionMenusComponent} from "./selection-menus/selection-menus.component";
import {ProcessusComponent} from "./processus/processus.component";
import {SelectionMenusResolverService} from "./selection-menus/selection-menus-resolver.service";
import {BesoinsComponent} from "../gestion-listes-besoins/liste-besoins/besoins/besoins.component";
import {BesoinsResolverService} from "../gestion-listes-besoins/liste-besoins/besoins/besoins-resolver.service";
import {BcPropositionsComponent} from "../gestion-commandes-fournisseurs/bc/bc-propositions/bc-propositions.component";
import {SelectionPlcsComponent} from "./selection-plcs/selection-plcs.component";
import {SelectionPlcsResolverService} from "./selection-plcs/selection-plcs-resolver.service";
import {ArticlesComponent} from "../gestion-listes-besoins/liste-besoins/besoins/articles/articles.component";

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_PROCESSUS,
    component: GpRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_ADMINISTRATION,
    children: [
      {
        path: 'bc-propositions',
        component: BcPropositionsComponent,
        runGuardsAndResolvers: 'always',
        data: HAS_ADMINISTRATION
      },
    ]
  },
  {
    path: 'gestion-processus/:idWorkflowInstance',
    component: ProcessusComponent,
    outlet: OUTLET_SECONDARY,
    canActivate: [Auth2Guard],
    data: HAS_MONBUREAU,
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'selection-menus',
        component: SelectionMenusComponent,
        data: HAS_MONBUREAU,
        runGuardsAndResolvers: 'always',
        resolve: {
          selectionRepasSupplier: SelectionMenusResolverService
        }
      },
      {
        path: 'selection-plcs',
        component: SelectionPlcsComponent,
        data: HAS_MONBUREAU,
        runGuardsAndResolvers: 'always',
        resolve: {
          selectionPlcsSupplier: SelectionPlcsResolverService
        }
      },
      {
        path: 'lb-details',
        runGuardsAndResolvers: 'always',
        component: BesoinsComponent,
        data: HAS_MONBUREAU,
        resolve: {
          besoinsSupplier: BesoinsResolverService
        },
        children:[
          {
            path:'articles',
            component:ArticlesComponent,
            data:HAS_MONBUREAU,
          }
        ]
      }
    ]
  }


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProcessusRoutingModule {
}
