import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdministrationRoutingModule} from './administration-routing.module';
import {AdministrationComponent} from './administration.component';
import {SitesComponent} from './sites/sites.component';
import {EnvironnementsComponent} from './environnements/environnements.component';
import {UtilisateursComponent} from './utilisateurs/utilisateurs.component';
import {UtilisateursConnexionsComponent} from './utilisateurs-connexions/utilisateurs-connexions.component';
import {SharedModule} from '../shared/shared.module';
import {FicheidentiteSiteComponent} from './sites/ficheidentite-site/ficheidentite-site.component';
import {FeaturesComponent} from './features/features.component';
import {FicheidentiteEnvironnementComponent} from './environnements/ficheidentite-environnement/ficheidentite-environnement.component';
import {ConvivesComponent} from './convives/convives.component';
import {FamillesGemrcnComponent} from './familles-gemrcn/familles-gemrcn.component';
import {RepasComponent} from './repas/repas.component';
import {DecoupagesRepasComponent} from './decoupages-repas/decoupages-repas.component';
import {TypesProduitComponent} from './types-produit/types-produit.component';
import {FamillesProduitComponent} from './familles-produit/familles-produit.component';
import {AllergenesComponent} from './allergenes/allergenes.component';
import {FicheidentiteUtilisateurComponent} from './utilisateurs/ficheidentite-utilisateur/ficheidentite-utilisateur.component';
import {GrilleutilisateursconnexionsComponent} from './utilisateurs-connexions/grilleutilisateursconnexions/grilleutilisateursconnexions.component';
import {DeclinaisonsComponent} from './declinaisons/declinaisons.component';
import {UnitesDeMesuresComponent} from './unites-de-mesures/unites-de-mesures.component';
import {EquipesComponent} from './equipes/equipes.component';
import {AtelierComponent} from './ateliers/atelier.component';
import {SecteursComponent} from './secteurs/secteurs.component';
import {TaxesComponent} from './taxes/taxes.component';
import {AppellationsComponent} from './appellations/appellations.component';
import {ParametresComponent} from './parametres/parametres.component';
import {LitigesComponent} from './litiges/litiges.component';
import {MotifsNonConformiteComponent} from './motifs-non-conformite/motifs-non-conformite.component';
import {ProfilsComponent} from './profils/profils.component';
import {RegimesAlimentairesComponent} from './regimes-alimentaires/regimes-alimentaires.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxColorBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxGalleryModule,
  DxListModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule, DxRadioGroupModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxTooltipModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import {DialogFormFamillesProduitComponent} from './familles-produit/dialog-form-famille-produit/dialog-form-famille-produit.component';
import {GrilleConditionnementsComponent} from './conditionnements/grille-conditionnements.component';
import {VariantesComponent} from './conditionnements/variantes/grille-variantes/variantes.component';
import {RestesComponent} from './conditionnements/restes/grille-restes/restes.component';
import {ResteComponent} from './conditionnements/restes/reste/reste.component';
import {VarianteComponent} from './conditionnements/variantes/variante/variante.component';
// tslint:disable-next-line:import-spacing
import {OrigineGridComponent} from './origines/origine-grid.component';
import {DialogOrigineComponent} from './origines/dialog-origine/dialog-origine.component';
import {ConditionnementComponent} from './conditionnements/conditionnement.component';
import {ConditionnementsVariantesComponent} from './conditionnements/conditionnements-variantes/grille/conditionnements-variantes.component';
import {ConditionnementVarianteComponent} from './conditionnements/conditionnements-variantes/conditionnement-variante/conditionnement-variante.component';

import {StockMouvementStatutComponent} from "./stocks/statuts/statut-dialog/statut.component";
import {GrilleStocksMouvementsStatutComponent} from "./stocks/statuts/grille-statuts/grille-statuts.component";
import {GrilleTacheComponent} from "./taches/grille-taches.component";
import {TacheComponent} from "./taches/tache.component";
import {PmsUniteGrilleComponent} from "./pms/unite/grille/pms-unite-grille.component";
import {PmsUniteDialogComponent} from "./pms/unite/dialog/pms-unite-dialog.component";
import {PmsSeuilGrilleComponent} from "./pms/seuil/grille/pms-seuil-grille.component";
import {PmsSeuilDialogComponent} from "./pms/seuil/dialog/pms-seuil-dialog.component";
import {PmsEquipementGrilleComponent} from "./pms/equipement/grille/pms-equipement-grille.component";
import {PmsEquipementDialogComponent} from "./pms/equipement/dialog/pms-equipement-dialog.component";
import {PmsZoneANettoyerGrilleComponent} from "./pms/zone-a-nettoyer/grille/pms-zone-a-nettoyer-grille.component";
import {PmsZoneANettoyerDialogComponent} from "./pms/zone-a-nettoyer/dialog/pms-zone-a-nettoyer-dialog.component";
import {PmsEquipementANettoyerGrilleComponent} from "./pms/equipement-a-nettoyer/grille/pms-equipement-a-nettoyer-grille.component";
import {PmsEquipementANettoyerDialogComponent} from "./pms/equipement-a-nettoyer/dialog/pms-equipement-a-nettoyer-dialog.component";
import {PmsSignatureUtilisateurGrilleComponent} from "./pms/signature-utilisateur/grille/pms-signature-utilisateur-grille.component";
import {PmsSignatureUtilisateurDialogComponent} from "./pms/signature-utilisateur/dialog/pms-signature-utilisateur-dialog.component";
import {PmsTypeUtilisateurGrilleComponent} from "./pms/type-utilisateur/grille/pms-type-utilisateur-grille.component";
import {PmsTypeUtilisateurDialogComponent} from "./pms/type-utilisateur/dialog/pms-type-utilisateur-dialog.component";
import {GridEnvironnementPlcComponent} from "./portail/environnement-plc/grid/grid-environnement-plc.component";
import {SidebarEnvironnementPlcComponent} from "./portail/environnement-plc/sidebar/sidebar-environnement-plc.component";
import {DialogAddPlcComponent} from "./portail/environnement-plc/sidebar/dialog-add-plc/dialog-add-plc.component";
import {PmsTypeTacheGrilleComponent} from "./pms/type-tache/grille/pms-type-tache-grille.component";
import {PmsTypeTacheDialogComponent} from "./pms/type-tache/dialog/pms-type-tache-dialog.component";
import {PmsTypeEquipementGrilleComponent} from "./pms/type-equipement/grille/pms-type-equipement-grille.component";
import {PmsTypeEquipementDialogComponent} from "./pms/type-equipement/dialog/pms-type-equipement-dialog.component";
import {PmsTacheGrilleComponent} from "./pms/tache/grille/pms-tache-grille.component";
import {PmsTacheDialogComponent} from "./pms/tache/dialog/pms-tache-dialog.component";
import {ConfigurationPortailPlcGridComponent} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-grid.component";
import {ConfigurationPortailPlcFormComponent} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-form/configuration-portail-plc-form.component";
import {GestionAdministrationComponent} from "./gestion-administration/gestion-administration.component";
import {DialogEditionComponent} from "./familles-gemrcn/dialog-edition/dialog-edition.component";
import {RegimeAlimentaireDialogEditionComponent} from "./regimes-alimentaires/regimes-alimentaires-dialog-edition/regime-alimentaire-dialog-edition.component";
import {EquipeDialogComponent} from "./equipes/equipe-dialog/equipe-dialog.component";
import {AtelierDialogComponent} from "./ateliers/atelier-dialog/atelier-dialog.component";
import {ZonesDeStockageComponent} from "./zones-de-stockage/zones-de-stockage.component";
import {LitigeDialogComponent} from "./litiges/litige-dialog/litige-dialog.component";
import {ZoneDeStockageDialogComponent} from "./zones-de-stockage/zone-de-stockage-dialog/zone-de-stockage-dialog.component";
import {SecteurDialogComponent} from "./secteurs/secteur-dialog/secteur-dialog.component";
import {MotifNonConformiteDialogComponent} from "./motifs-non-conformite/motif-non-conformite-dialog/motif-non-conformite-dialog.component";
import {AllergeneDialogComponent} from "./allergenes/allergene-edition-dialog/allergene-edition-dialog.component";
import {TourneesComponent} from "./tournees/tournees.component";
import {TourneeDialogComponent} from "./tournees/dialog-edition/tournee-dialog-edition.component";
import {
  UniteDeMesureEditionDialogComponent
} from "./unites-de-mesures/edition-dialog/unite-de-mesure-edition-dialog.component";
import {TypeProduitEditionDialogComponent} from "./types-produit/edition-dialog/type-produit-edition-dialog.component";
import {TaxeEditionDialogComponent} from "./taxes/edition-dialog/taxe-edition-dialog.component";
import {MealEditionDialogComponent} from "./repas/edition-dialog/meal-edition-dialog.component";
import {DeclinaisonEditionDialogComponent} from "./declinaisons/edition-dialog/declinaison-edition-dialog.component";
import {
  DecoupageRepasEditionDialogComponent
} from "./decoupages-repas/edition-dialog/decoupage-repas-edition-dialog.component";
import {ConviveEditionDialogComponent} from "./convives/edition-dialog/convive-edition-dialog.component";
import {
  AppellationEditionDialogComponent
} from "./appellations/appellation-edition-dialog/appellation-edition-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DxTreeListModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxScrollViewModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxFileUploaderModule,
    DxTextBoxModule,
    DxFormModule,
    DxListModule,
    DxLookupModule,
    DxColorBoxModule,
    DxGalleryModule,
    DxTreeViewModule,
    DxToolbarModule,
    DxButtonModule,
    DxSwitchModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxPopupModule,
    DxTooltipModule
  ],
  entryComponents: [

  ],
  declarations: [AdministrationComponent,
    SitesComponent,
    EnvironnementsComponent,
    UtilisateursComponent,
    UtilisateursConnexionsComponent,
    FicheidentiteSiteComponent,
    FeaturesComponent,
    FicheidentiteEnvironnementComponent,
    ConvivesComponent,
    FamillesGemrcnComponent,
    DialogEditionComponent,
    RepasComponent,
    DecoupagesRepasComponent,
    TypesProduitComponent,
    FamillesProduitComponent,
    AllergenesComponent,
    AllergeneDialogComponent,
    FicheidentiteUtilisateurComponent,
    GrilleutilisateursconnexionsComponent,
    DeclinaisonsComponent,
    UnitesDeMesuresComponent,
    EquipesComponent,
    EquipeDialogComponent,
    AtelierDialogComponent,
    AtelierComponent,
    SecteurDialogComponent,
    ZonesDeStockageComponent,
    ZoneDeStockageDialogComponent,
    SecteursComponent,
    TaxesComponent,
    AppellationsComponent,
    ParametresComponent,
    LitigesComponent,
    LitigeDialogComponent,
    MotifsNonConformiteComponent,
    MotifNonConformiteDialogComponent,
    ProfilsComponent,
    RegimesAlimentairesComponent,
    RegimeAlimentaireDialogEditionComponent,
    DialogFormFamillesProduitComponent,
    DialogFormFamillesProduitComponent,
    OrigineGridComponent,
    DialogOrigineComponent,
    GrilleConditionnementsComponent,
    VariantesComponent,
    RestesComponent,
    ResteComponent,
    VarianteComponent,
    ConditionnementComponent,
    ConditionnementsVariantesComponent,
    ConditionnementVarianteComponent,
    StockMouvementStatutComponent,
    GrilleStocksMouvementsStatutComponent,
    TacheComponent,
    GrilleTacheComponent,
    PmsUniteGrilleComponent,
    PmsUniteDialogComponent,
    PmsSeuilGrilleComponent,
    PmsSeuilDialogComponent,
    PmsEquipementGrilleComponent,
    PmsEquipementDialogComponent,
    PmsZoneANettoyerGrilleComponent,
    PmsZoneANettoyerDialogComponent,
    PmsEquipementANettoyerGrilleComponent,
    PmsEquipementANettoyerDialogComponent,
    PmsSignatureUtilisateurGrilleComponent,
    PmsSignatureUtilisateurDialogComponent,
    PmsTypeUtilisateurGrilleComponent,
    PmsTypeUtilisateurDialogComponent,
    GridEnvironnementPlcComponent,
    SidebarEnvironnementPlcComponent,
    DialogAddPlcComponent,
    PmsTypeUtilisateurDialogComponent,
    PmsTypeTacheGrilleComponent,
    PmsTypeTacheDialogComponent,
    PmsTypeEquipementGrilleComponent,
    PmsTypeEquipementDialogComponent,
    PmsTacheGrilleComponent,
    PmsTacheDialogComponent,
    ConfigurationPortailPlcGridComponent,
    ConfigurationPortailPlcFormComponent,
    GestionAdministrationComponent,
    TourneesComponent,
    TourneeDialogComponent,
    UniteDeMesureEditionDialogComponent,
    TypeProduitEditionDialogComponent,
    TaxeEditionDialogComponent,
    MealEditionDialogComponent,
    DeclinaisonEditionDialogComponent,
    DecoupageRepasEditionDialogComponent,
    ConviveEditionDialogComponent,
    AppellationEditionDialogComponent
    ],
  exports: [AdministrationComponent, SitesComponent, EnvironnementsComponent,
    UtilisateursComponent,
    FicheidentiteSiteComponent,
    FicheidentiteEnvironnementComponent],
  providers: []
})
export class AdministrationModule {
}
