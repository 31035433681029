<dx-popup
  [showTitle]="true"
  [title]="'Détails de la facture'"
  [(visible)]="displayInvoice"
  [width]="1500"
  [height]="700"
  [resizeEnabled]="!fullScreenInvoice"
  [showCloseButton]="true"
  [dragEnabled]="!fullScreenInvoice"

  (onHiding)="closeInvoiceDialog()"
  [fullScreen]="fullScreenInvoice"
  (onResize)="resizeChilds($event)"
  #popup>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreenInvoice
        }">
  </dxi-toolbar-item>

  <div class="dialog-line text-right" *ngIf="auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
    <div class="col-lg-12">
      <yo-button *ngIf="avoirRetreived && isMotifNonConformite() && !avoirId" label="Générer une demande d'avoir" tooltip="Générer une demande d'avoir"
                 (onClick)="generateAvoir()" class="mg-r-5"></yo-button>
      <yo-button *ngIf="avoirRetreived && isMotifNonConformite() && avoirId" label="Ouvrir la demande d'avoir" tooltip="Ouvrir la demande d'avoir"
                 (onClick)="navigateToAvoir()" class="mg-r-5"></yo-button>
      <yo-button tooltip="Imprimer la facture" (onClick)="printInvoice()" class="mg-r-5" iconClass="fas fa-print"></yo-button>
      <yo-button tooltip="Valider la facture" (onClick)="validInvoice()" class="mg-r-16" iconClass="fas fa-check"></yo-button>
    </div>
  </div>

  <div class="dialog-line">
    <div class="col-lg-12">
      <table class="invoice-table">
        <thead>
        <tr>
          <th>Numéro de facture</th>
          <th>Fournisseur</th>
          <th class="modifiable">Date d'émission</th>
          <th>Total HT</th>
          <th>Frais de port</th>
          <th class="modifiable">Frais de port ajustés</th>
          <th>Total TTC</th>
          <th>Auteur (création)</th>
          <th>Date de création</th>
          <th>Auteur (modification)</th>
          <th>Date de modification</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ currentInvoice?.number }}</td>
          <td>{{ currentInvoice?.provider?.libelle }}</td>
          <td>
            <ng-container *ngIf="auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
              <span *ngIf="!chooseDate" class="pointer italic"
                    (click)="chooseDateInput()">{{ !currentInvoice?.dateBill ? 'Cliquez ici pour choisir une date' : utilsSvc.formatToDDMMYYYY(currentInvoice?.dateBill) }}</span>
              <ng-container *ngIf="chooseDate">
                <dx-date-box
                  (onValueChanged)="switchChooseDate($event)"
                >
                </dx-date-box>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
              <span>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateBill) }}</span>
            </ng-container>
          </td>
          <td>{{ currentInvoice?.totalHT?.value }} {{ currentInvoice?.totalHT?.uniteMonetaire }}</td>
          <td>{{ currentInvoice?.shippingFees?.value }} {{ currentInvoice?.shippingFees?.uniteMonetaire }}</td>
          <td [class]="currentInvoice?.shippingFeesModifiedManually ? 'pointer italic cell-changed' : 'pointer italic'">
            <ng-container *ngIf="!changeShippingFeesEnabled">
              <span
                (click)="toggleShippingFeesState()">{{ currentInvoice?.realShippingFees?.value }} {{ currentInvoice?.realShippingFees?.uniteMonetaire }}</span>
            </ng-container>
            <ng-container *ngIf="changeShippingFeesEnabled">
              <dx-text-box (onValueChanged)="updateInvoice($event, 'realShippingFees.value', currentInvoice?.id)"
                           [(value)]="currentInvoice?.realShippingFees.value"></dx-text-box>
            </ng-container>
          </td>
          <td>{{ currentInvoice?.totalTTC?.value }} {{ currentInvoice?.totalTTC?.uniteMonetaire }}</td>
          <td>{{ currentInvoice?.authorCreation }}</td>
          <td>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateCreation) }}</td>
          <td>{{ currentInvoice?.authorModification}}</td>
          <td>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateModification) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="dialog-line remaining-space" *ngIf="currentInvoice?.rows.length">
    <dx-data-grid
      (onCellPrepared)="onCellPreparedDetails($event)"
      [dataSource]="currentInvoice?.rows"
      keyExpr="id"
      width="100%"
      height="490"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #gridDetails>
      <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>
      <dxi-column dataField="bonReceptionLigne.numBonReception" caption="Bon réception" cellTemplate="numBonReceptionTemplate"
                  [allowEditing]="false"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  [alignment]="'left'">
      </dxi-column>
      <dxi-column caption="Article" alignment="center" allowEditing="false">
        <dxi-column dataField="bonReceptionLigne.lotArticle.lotArticle" caption="Lot"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"></dxi-column>
        <dxi-column dataField="bonReceptionLigne.denominationArticle" caption="Dénomination"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"></dxi-column>
      </dxi-column>
      <dxi-column caption="Bon de réception" alignment="center"  allowEditing="false">
        <dxi-column dataField="quantityInvoiced" caption="Quantité"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"
                    cellTemplate="quantityCellTemplate">
        </dxi-column>
        <dxi-column dataField="unitPrice" caption="Prix unitaire UF"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"
                    cellTemplate="unitPriceCellTemplate"></dxi-column>
      </dxi-column>
      <dxi-column caption="Facture" alignment="center"  allowEditing="false">
        <dxi-column dataField="realQuantityInvoiced.value" caption="Quantité facturée"
                    [allowEditing]="true"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"
                    editCellTemplate="editQuantityAjusteTemplate"
                    cellTemplate="realQuantityCellTemplate">
        </dxi-column>
        <dxi-column dataField="realUnitPrice.value" caption="Prix unitaire UF"
                    [allowEditing]="true"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    editCellTemplate="editPuAjusteTemplate"
                    [alignment]="'left'"
                    cellTemplate="realUnitPriceCellTemplate"></dxi-column>
        <dxi-column dataField="realTotalInvoiced" caption="Total HT"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'"
                    cellTemplate="realTotalInvoicedTemplate"></dxi-column>
      </dxi-column>
      <dxi-column dataField="tva.value" caption="TVA" [width]="100"
                  [allowEditing]="false"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  [alignment]="'left'"
                  cellTemplate="tvaTemplate"></dxi-column>
      <dxi-column dataField="dxMotifNonConfoList"
                  caption="Motifs de non conformité"
                  [cellTemplate]="cellTemplate"
                  editCellTemplate="motifsNonConformiteTemplate"
                  [allowEditing]="true"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  [alignment]="'left'">
        <dxo-lookup
          [dataSource]="motifNonConformiteList"
          displayExpr="libelle"
          valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="bonReceptionLigne.bonReceptionLigneLitigeList" caption="Litiges" cellTemplate="litigesTemplate"
                  [allowEditing]="false"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  [alignment]="'left'">
      </dxi-column>
      <div *dxTemplate="let cell of 'numBonReceptionTemplate'">
        {{ cell?.row.data.bonReceptionLigne.numBonReception }}
      </div>
      <div class="total" *dxTemplate="let cell of 'unitPriceCellTemplate'">
        {{ cell?.row.data.unitPrice.value }} {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
      </div>
      <div class="total" *dxTemplate="let cell of 'realUnitPriceCellTemplate'">
        {{ cell?.row.data.realUnitPrice.value }} {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
      </div>
      <div class="total" *dxTemplate="let cell of 'quantityCellTemplate'">
        {{ cell?.row.data.quantityInvoiced.value }} {{ cell?.row.data.bonReceptionLigne.uniteDeFacturationAbreviation }}
      </div>
      <div class="total" *dxTemplate="let cell of 'realQuantityCellTemplate'">
        {{ cell?.row.data.realQuantityInvoiced.value }} {{ cell?.row.data.bonReceptionLigne.uniteDeFacturationAbreviation }}
      </div>
      <div class="total" *dxTemplate="let cell of 'realTotalInvoicedTemplate'">
        {{ cell?.row.data.realTotalInvoiced.value }} {{ cell?.row.data.realTotalInvoiced.uniteMonetaire }}
      </div>
      <div class="total" *dxTemplate="let cell of 'tvaTemplate'">
        {{ cell?.row.data.tva.valeur }} %
      </div>
      <div class="total" *dxTemplate="let cell of 'priceCellTemplate'">
        {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
      </div>

      <div *dxTemplate="let cell of 'editPuAjusteTemplate'">
        <dx-text-box (onValueChanged)="updateInvoice($event, 'realUnitPrice.value', cell?.row.data.id)"
                     [(value)]="cell?.row.data.realUnitPrice.value"></dx-text-box>
      </div>
      <div *dxTemplate="let cell of 'editQuantityAjusteTemplate'">
        <dx-text-box (onValueChanged)="updateInvoice($event, 'realQuantityInvoiced.value', cell?.row.data.id)"
                     [(value)]="cell?.row.data.realQuantityInvoiced.value"></dx-text-box>
      </div>

      <div *dxTemplate="let cell of 'motifsNonConformiteTemplate'">
        <dx-tag-box
          [dataSource]="motifNonConformiteList"
          [value]="cell.value"
          [width]="350"
          displayExpr="libelle"
          maxDisplayedTags="5"
          [showSelectionControls]="true"
          [showMultiTagOnly]="false"
          applyValueMode="instantly"
          [searchEnabled]="true"
          (onValueChanged)="updateInvoice($event, 'dxMotifNonConfoList', cell?.row.data.id)">
        </dx-tag-box>
      </div>

      <div *dxTemplate="let cell of 'litigesTemplate'">
        {{ joinLitigesOrMotifs(cell?.row.data.bonReceptionLigne.bonReceptionLigneLitigeList, false) }}
      </div>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} ligne(s)">
      </dxo-pager>
    </dx-data-grid>
  </div>
</dx-popup>
