<div class="d-flex">
  <div class="max-width-300  flex-column mg-r-30">

    <h3 class="first">{{utils.getTotalRecordsLabel('point de livraison', pointsDeLivraisonListUsed?.length)}} </h3>
    <p-listbox [options]="pointsDeLivraisonListUsed" [(ngModel)]="selectedPointDeLivraison"
               [style]="{'width':'260px'}"
               (onChange)="loadRepasCatalogue()"
               [listStyle]="{'max-height':'71vh','min-height':'71vh'}"
               filter="filter" optionLabel="libelle">
      <ng-template let-item pTemplate="item">
        <i class="fas fa-map-pin mg-r-5 pn-border-color"></i>{{item.libelle}}
      </ng-template>
      <ng-template pTemplate="header">
        <span class="mg-r-5">
          <yo-button
            class="mg-r-5"
            iconClass="fas fa-plus"
            buttonClass="cta-secondary"
            (onClick)="displayDialogAddPlc()">
          </yo-button>
        </span>

        <span class="mg-r-5">
          <yo-button
            tooltip="Supprimer un point de livraison"
            class="mg-r-5"
            buttonClass="cta-delete"
            iconClass="fas fa-minus"
            (onClick)="displayDialogRemovePlc()"
            [disabled]="utils.isCollectionNullOrEmpty(pointsDeLivraisonListUsed)">
          </yo-button>
        </span>

      </ng-template>
    </p-listbox>
  </div>
  <div class="d-flex  flex-column repas-setting">
    <div class="d-flex justify-content-between action-bar ">
      <div>
        <yo-button
          tooltip="Ajouter un repas"
          [disabled]="pointsDeLivraisonListUsed.length === 0"
          class="mg-r-5"
          (onClick)="displayDialogAddRepas($event)"
          iconClass="fas fa-plus">
        </yo-button>
        <yo-button
          tooltip="Supprimer un repas"
          [disabled]="pointsDeLivraisonListUsed.length === 0"
          class="mg-r-5"
          (onClick)="displayDialogRemoveRepas($event)"
          iconClass="fas fa-minus">
        </yo-button>
      </div>
      <span class="genlabel font-12 pd-t-10" *ngIf="repasListUsed.length === 0">
          <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
          Aucun repas disponible pour ce point de livraison
      </span>
    </div>
    <div *ngIf="selectedPointDeLivraison">
      <p-tabMenu [model]="tabMenuRepasList" [activeItem]="activeRepas"></p-tabMenu>
      <p-card>
        <div class="size-card">
          <!-- todo mettre un  <router-outlet></router-outlet> -->
        </div>
      </p-card>
    </div>

  </div>
</div>


<!--DIALOG DE SELECTION DES POINTS DE LIVRAISON-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayPlcAddDialog">


  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-map-pin mg-r-5"></i> Ajouter des points de livraison
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedPointsDeLivraisonToAdd"
             multiple="multiple"
             checkbox="checkbox"
             filter="filter"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="pointsDeLivraisonToAdd"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-map-pin mg-r-5"></i>   {{item.libelle}} <span
      class="genlabel font-12">({{item.adresse.ville}})</span>
    </ng-template>
  </p-listbox>

  <span class="genlabel font-10" *ngIf="pointsDeLivraisonToAdd.length>1">{{pointsDeLivraisonToAdd.length}} points de livraison disponibles</span>
  <span class="genlabel font-12" *ngIf="pointsDeLivraisonToAdd.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun point de livraison disponible</span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check" tooltip="Ajouter les points de livraison sélectionnés"
                   class="mg-r-5"
                   (onClick)="attachSelectedPointsDeLivraison($event)"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayPlcAddDialog=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>

</p-dialog>

<!--DIALOG DE SUPPRESSION DES POINTS DE LIVRAISON-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayPlcRemoveDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-map-pin mg-r-5"></i> Supprimer des points de livraison
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedPointsDeLivraisonToRemove"
             multiple="multiple"
             checkbox="checkbox"
             filter="filter"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="pointsDeLivraisonToRemove"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-map-pin mg-r-5"></i>   {{item.libelle}} <span
      class="genlabel font-12">({{item.adresse.ville}})</span>
    </ng-template>
  </p-listbox>

  <span class="genlabel font-10" *ngIf="pointsDeLivraisonToRemove.length>1">{{pointsDeLivraisonToRemove.length}} points de livraison à supprimer</span>
  <span class="genlabel font-12" *ngIf="pointsDeLivraisonToRemove.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun point de livraison à supprimer</span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check"
                   tooltip="Supprimer les points de livraison sélectionnés"
                   class="mg-r-5"
                   (onClick)="detachSelectedPointsDeLivraison($event)"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayPlcRemoveDialog=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>

</p-dialog>


<!--DIALOG DE SELECTION DES REPAS-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayRepasAddDialog">


  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-utensils mg-r-5"></i>Ajouter des repas au point de
      livrasion {{selectedPointDeLivraison.libelle}}
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedRepasToAdd"
             multiple="multiple"
             checkbox="checkbox"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="repasToAdd"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-utensils mg-r-5"></i>   {{item.libelle}}
    </ng-template>
  </p-listbox>
  <span class="genlabel font-12" *ngIf="repasToAdd.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun repas disponible
  </span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check" tooltip="Ajouter les repas a votre point de livraison"
                   class="mg-r-5"
                   (onClick)="attachSelectedRepas()"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayRepasAddDialog=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>
</p-dialog>

<!--DIALOG DE SUPPRESSION DES REPAS-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  appendTo="body"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayRepasRemoveDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-map-pin mg-r-5"></i> Supprimer les repas du point de
      livraison {{selectedPointDeLivraison.libelle}}
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedRepasToRemove"
             multiple="multiple"
             checkbox="checkbox"
             filter="filter"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="repasToRemove"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-utensils mg-r-5"></i>   {{item.libelle}}
    </ng-template>
  </p-listbox>

  <span class="genlabel font-10" *ngIf="repasToRemove.length>1">{{repasToRemove.length}}
    points de livraison à supprimer</span>
  <span class="genlabel font-12" *ngIf="repasToRemove.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun point de livraison à supprimer</span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check" tooltip="Supprimer les repas selectionnés"
                   class="mg-r-5"
                   (onClick)="detachSelectedRepas($event)"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayRepasRemoveDialog=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>

</p-dialog>
