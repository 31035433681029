<ng-container *ngIf="!yoHiddenMode">
  <button
    [class]="'custom-button' + (yoSmall ? '-small ' : ' ') + buttonClasses"
    [style.text-align]="yoTextAlign"
    (click)="onClickYoButton($event)"
    [class.disable]="yoDisabled"
    [title]="yoTooltip"
    type="button">
    <i [class]="iconClasses"></i>
  </button>
</ng-container>

