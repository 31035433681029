<dx-popup
  [showTitle]="true"
  [title]="'Impression bilan allergène'"
  [(visible)]="displayDialog"
  [width]="600"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="dx-field">
    <div class="dx-field-label">Du :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de début"
        type="date"
        [value]="startDate"
        (onValueChanged)="startDateChanged($event)">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">au :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de fin"
        type="date"
        [value]="endDate"
        (onValueChanged)="endDateChanged($event)"
        [min]="minDate">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Commentaire : </div>
    <div class="dx-field-value">
      <dx-text-area
        class="textarea"
        [(ngModel)]="comment"
      ></dx-text-area>
    </div>
  </div>

  <div class="custom-button-container-right">
    <yo-button label="Imprimer" [disabled]="this.isValidForm()" iconClass="fas fa-print"
               (onClick)="downloadBilanAllergene()">
    </yo-button>

    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>
