import {Injectable} from '@angular/core';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {JourSemaineDTO} from '../../dtos/jour-semaine-dto';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {DialogMsgSupplier, Paragraphe} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';

/**
 * @deprecated
 */
@Injectable()
export class JoursSemaineService extends GenericHandler<JourSemaineDTO> {


  getHelp(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Les jours de la semaine`;
    dms.logo = 'fa fa-question-circle  yoni-color';


    let p1: Paragraphe = new Paragraphe();
    p1.title = `Exemple 1`;
    p1.lines = [
      ` Les unites de production paramétrées sur le site 1 (Lundi,Mardi) pourront proposer au maximum le Lundi et Mardi comme jour de livraison.`,
    ];

    let p2: Paragraphe = new Paragraphe();
    p2.title = `Exemple 2`;
    p2.lines = [
      `Les unites de production paramétrées sur le site 2 (Lundi,Mardi,Mercredi,Jeudi,Vendredi) pourront proposer des livraisons du Lundi au Vendredi.`,
    ];

    dms.content = {
      intro: `Les jours de la semaine se paramètrent par site.`,
      paragraphes: [p1, p2]
    };


    return dms;
  }

  joursSemaineList: { value: { libelle, js } }[] = [
    {value: {libelle: 'Lundi', js: 1}},
    {value: {libelle: 'Mardi', js: 2}},
    {value: {libelle: 'Mercredi', js: 3}},
    {value: {libelle: 'Jeudi', js: 4}},
    {value: {libelle: 'Vendredi', js: 5}},
    {value: {libelle: 'Samedi', js: 6}},
    {value: {libelle: 'Dimanche', js: 7}},
  ];


  private getSelectedJourSemaine(dto: JourSemaineDTO, options: { value: { libelle, js } }[]) {
    if (!this.utils.isNullOrEmpty(dto) && !this.utils.isCollectionNullOrEmpty(options)) {
      let elt = this.utils.getFirstElement(options.filter(opt => opt.value.js === dto.numeroJourSemaine));
      if (!this.utils.isNullOrEmpty(elt)) {
        // on active le numero jour semaine
        dto.numeroJourSemaine = elt.value.js;
        return elt.value.libelle;
      }
    }
    return undefined;
  }

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);


  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }



  getSort(): string[] {
    return ['numeroJourSemaine,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getFields(dto: JourSemaineDTO): FormFieldBaseSupplier<any>[] {
    if (this.utils.isNullOrEmpty(dto)) {
      dto = new JourSemaineDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [


      new FormFieldDropdownSupplier({
        key: 'libelle',
        label: 'Jour de la semaine',
        value: this.getSelectedJourSemaine(dto, this.joursSemaineList),
        type: 'linkedField',
        linkedFieldFn: this.setLinkedNumeroJourSemaine,
        maxLength: 100,
        order: 5,
        required: true,
        options: this.joursSemaineList
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: true,
        order: 7
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 8
      }),

      new FormFieldTextboxSupplier({
        key: 'numeroJourSemaine',
        type: 'hidden',
        value: dto.numeroJourSemaine,
        order: 8
      }),


    ];

    console.log('show form fields jour semaine', formFields);

    return formFields.sort((a, b) => a.order - b.order);
  }

  setLinkedNumeroJourSemaine(value: string) {
    const elt = this.utils.getFirstElement(this.joursSemaineList.filter(jourSemaine => jourSemaine.value.libelle === value));
    // dto
  }


  getEntityName(): string {
    return 'jourSemaine';
  }

  getTitle(): string {
    return 'GESTION DES JOURS DE LA SEMAINE';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN JOUR DE SEMAINE';
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): JourSemaineDTO {
    return new JourSemaineDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }


}


