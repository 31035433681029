import {ObjectDTO} from "../object-dto";
import {SeuilPmsDto} from "./seuil-pms-dto";
import {TypeEquipementPmsDto} from "./type-equipement-pms-dto";

export class EquipementPmsDto extends ObjectDTO {
  libelle: string;
  image: File;
  seuil: SeuilPmsDto;
  type: TypeEquipementPmsDto;
}
