import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ListeBesoinsService} from '../../../core/services/entities/liste-besoins.service';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {UtilsService} from '../../../core/utils/utils.service';

import {combineLatest, Observable} from "rxjs";
import {BesoinsService} from "../../../core/services/gestion-commandes/besoins.service";
import {ListeBesoinsDto} from '../../../core/dtos/liste-besoins-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../../core/suppliers/generics/generic-request-supplier';
import {WorkflowInstanceDTO} from '../../../core/dtos/workflow-instance-dto';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {WorkflowInstanceService} from '../../../core/services/entities/workflow-instance.service';
import {ReglePrefereDTO} from '../../../core/dtos/regle-prefere-dto';
import {SelectionRepasPlcsService} from '../../../core/services/selection-plcs/selection-repas-plcs.service';
import {UniteDeProduction__ReglePrefereDTO} from '../../../core/dtos/unite-de-production__regle-prefere-dto';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../../core/constants";

@Injectable({
  providedIn: 'root'
})
export class BesoinsResolverService implements Resolve<BesoinsSupplier> {

  listeBesoins: ListeBesoinsDto;


  constructor(private gds: GenericDatagridService,
              private gfs: GenericFormService,
              private lbSvc: ListeBesoinsService,
              private cmSvc: ContratMenuService,
              private besoinsSvc: BesoinsService,
              private wkfSvc: WorkflowsService,
              private wkfInstanceSvc: WorkflowInstanceService,
              private selectionRepasPlcsSvc: SelectionRepasPlcsService,
              private utils: UtilsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BesoinsSupplier> | Promise<BesoinsSupplier> | BesoinsSupplier => {

    let bs = new BesoinsSupplier();
    const idWorkflowInstance: number = +route.parent.paramMap.get('idWorkflowInstance');

    const listeBesoins$ = this.gds.search(this.filterListeBesoin(idWorkflowInstance));
    const workflowInstance$ = this.gds.getOne(this.wkfInstanceSvc.getEntityName(), idWorkflowInstance);
    const selectionRepasPlcs$ = this.selectionRepasPlcsSvc.getSelectionRepasPlcs(idWorkflowInstance);
    const all$ = combineLatest([listeBesoins$, workflowInstance$, selectionRepasPlcs$]);

    return all$.toPromise().then(response => {
      const result = response[0].resultList as ListeBesoinsDto[];
      if (!this.utils.isCollectionNullOrEmpty(result)) {
        bs.listeBesoin = result[0];
      }
      bs.workflowInstance = response[1].one;
      // regles de sélection des articles preferes
      bs.reglesPreferes = response[2].additionalProperties['reglesPreferes'];
      // régle selectionnée
      bs.selectedReglePrefere = response[2].additionalProperties['selectedReglePrefere'];
      // regles du parametrage par defaut
      bs.udpRpList = response[2].additionalProperties['udpRpList'];
      // unites de production
      bs.udpList = response[2].additionalProperties['udpList'];
      return bs;
    });
  };

  filterListeBesoin = (idWorkflowInstance: number): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(this.lbSvc.getEntityName());
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'listebesoin.workflowInstance.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idWorkflowInstance + '';

    search.predicats.push(predicat1);

    grs.search = search;
    return grs;
  };
}

export class BesoinsSupplier {
  workflowInstance: WorkflowInstanceDTO;
  listeBesoin: ListeBesoinsDto;
  reglesPreferes: ReglePrefereDTO[] = [];
  selectedReglePrefere: ReglePrefereDTO;
  udpRpList: UniteDeProduction__ReglePrefereDTO[] = [];
  udpList: UniteDeProductionDTO[] = [];
}
