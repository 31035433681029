<div id="up-equipes-affectation" class="row mg-t-10">
  <div class="col-sm-4">
    <p-listbox [options]="ateliers"
               [(ngModel)]="selectedZdp"
               [listStyle]="{'height': utils.scrollHeightContent(482)}"
               checkbox="checkbox"
               filter="filter"
               optionLabel="libelle"
               id="ateliers"
               [filterPlaceHolder]="placeholderAtelier"
               (onChange)="onChangeAtelier()"
    >
      <ng-template pTemplate="item" let-udpzdp let-rowIndex="rowIndex">
        <div class="udp-zdp-item">
          <div *ngIf="associationsPreselectionnesIds.includes(udpzdp.id)"><i
            class="fas configured fa-check mg-r-14"></i> {{ udpzdp.libelle }}</div>
          <div *ngIf="!associationsPreselectionnesIds.includes(udpzdp.id)"><i
            class="fas not-configured fa-check mg-r-14"></i> {{ udpzdp.libelle }}</div>
        </div>
      </ng-template>
    </p-listbox>
  </div>

  <div class="col-sm-4">
    <p-listbox [options]="equipes"
               [(ngModel)]="selectedEq"
               [disabled]="desactiverSelectionEquipes"
               [listStyle]="{'height': utils.scrollHeightContent(482)}"
               checkbox="checkbox"
               filter="filter"
               optionLabel="libelle"
               id="equipes"
               [filterPlaceHolder]="placeholderEquipe"
               (onChange)="onChangeEquipe()"
    >
      <ng-template pTemplate="item" let-udpeq let-rowIndex="rowIndex">
        <div *ngIf="!desactiverSelectionEquipes" class="udp-eq-item">
          <div *ngIf="equipesPreselectionnesIds.includes(udpeq.id)"><i
            class="fas configured fa-check mg-r-14"></i> {{ udpeq.libelle }}</div>
          <div *ngIf="!equipesPreselectionnesIds.includes(udpeq.id)"><i
            class="fas not-configured fa-check mg-r-14"></i> {{ udpeq.libelle }}</div>
        </div>
        <div *ngIf="desactiverSelectionEquipes" class="udp-eq-item">
          <div><i style="color: #fff" class="fas fa-check mg-r-14"></i> {{ udpeq.libelle }}</div>
        </div>
      </ng-template>
    </p-listbox>
  </div>

  <div class="col-sm-4" [class.taches-disabled]="desactiverSelectionTaches">
    <p-tree [value]="treeTaches"
            [(selection)]="selectedTaches"
            [filter]="true"
            (onNodeSelect)="onSelectTache()"
            [scrollHeight]="utils.scrollHeightContent(488)"
            [style]="{'height': utils.scrollHeightContent(426)}"
            [filterPlaceholder]="placeholderTache"
            selectionMode="checkbox">
    </p-tree>
  </div>
</div>

<div class="row mg-t-15">
  <div class="col-sm-12 text-center">
    {{ messageHelpForUser }}
  </div>
</div>
