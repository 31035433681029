<dx-popup
  [width]="700"
  [height]="'auto'"
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="isPopupDisplayed"
  [resizeEnabled]="true"
  (onHiding)="closeDialog()"
>

  <form (ngSubmit)="save()" [formGroup]="formGroupCtrl">
    <div class="informations-generales">
      <div class="dialog-line">
        <div class="label text-nowrap" title="Libellé">Libellé :<span class="danger-color">*</span></div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="libelle"
            [required]="true">
          </dx-text-box>
        </div>
      </div>

      <div class="dialog-line">
        <div class="label text-nowrap" title="Code">Code :<span class="danger-color">*</span></div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="reference"
            [required]="true">
          </dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label text-nowrap" title="Actif">Actif :<span class="danger-color">*</span></div>
        <div class="value">
          <dx-check-box
            [value]="true"
            formControlName="actif">
          </dx-check-box>
        </div>
      </div>
    </div>
    <hr>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Adresse 1">Adresse 1 :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="adresse1">
          </dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="ville">Ville :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="ville">
          </dx-text-box>
        </div>
      </div>
    </div>

    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Adresse 2">Adresse 2 :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="adresse2">
          </dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="telephone">Téléphone :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="telephone">
          </dx-text-box>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Adresse 3">Adresse 3 :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="adresse3">
          </dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="fax">Fax :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="fax">
          </dx-text-box>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="codePostal">Code postal :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="codePostal">
          </dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="email">Email :</div>
        <div class="value">
          <dx-text-box
            class="inputfield"
            formControlName="email">
          </dx-text-box>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          tooltip="Enregistrer le site"
          type="submit"
          iconClass="fas fa-save"
          buttonClass="cta-success">
        </yo-button>
        <yo-button
          buttonClass="cta-delete"
          (onClick)="closeDialog()"
          tooltip="Fermer"
          iconClass="fas fa-times">
        </yo-button>
      </div>
    </div>
  </form>
</dx-popup>
