import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenusPlanning2Service} from '../../../core/services/gestionmenus/menus-planning2.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {DATEPICKER_FR} from '../../../core/constants';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import moment from 'moment';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {cloneDeep as _cloneDeep} from 'lodash';
import {CelluleTableauDTO} from '../../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {MenusToolbarService} from '../../../core/services/gestionmenus/menus-toolbar.service';

@Component({
  selector: 'yo-couts',
  templateUrl: './couts.component.html',
  styleUrls: ['./couts.component.scss']
})
export class CoutsComponent implements OnInit, OnDestroy {

  selectedRegimes: ContratMenuConvive__ContrainteAlimDTO[] = [];
  selectedRepas: RepasDTO[];
  selectedPeriode: Date[] = [new Date(), new Date()];
  localeFr = DATEPICKER_FR;
  subAnalyseCout: Subscription;
  subChangeValues: Subscription;

  subjectSelectedRegimes = new BehaviorSubject(undefined);
  selectedRegimes$ = this.subjectSelectedRegimes.asObservable();
  subjectSelectedRepas = new BehaviorSubject(undefined);
  selectedRepas$ = this.subjectSelectedRepas.asObservable();
  subjectSelectedPeriode = new BehaviorSubject(undefined);
  selectedPeriodes$ = this.subjectSelectedPeriode.asObservable();

  listMapDecoupagesRepas: Map<string, CelluleTableauDTO>[] = [];

  dataCoutsRepasPeriode: any[] = [];

  optionsCoutsRepasPeriode = {
    title: {
      display: true,
      text: 'Coûts unitaires des repas'
    },
    elements: {
      point: {
        pointStyle: 'circle'
      }
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Date de consommation'
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Prix en euros'
        }
      }],
    }
  }

  constructor(public mp2Svc: MenusPlanning2Service,
              public utils: UtilsService,
              public menuToolbarSvc: MenusToolbarService) {
  }

  ngOnInit(): void {
    // lors de l'ouverture du composant
    this.subscribeOpenAnalyseCouts();
    // lorsque le repas / regime / periode change on recalcule le graph
    this.subscribeChangeValues();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAnalyseCout);
    this.utils.unsubscribe(this.subChangeValues);
  }

  subscribeChangeValues = () => {
    const all$ = combineLatest([this.selectedRegimes$, this.selectedRepas$, this.selectedPeriodes$]);
    this.subChangeValues = all$.pipe(
      debounceTime(500),
      filter(data => !this.utils.isCollectionNullOrEmpty(this.selectedRegimes)
        && !this.utils.isCollectionNullOrEmpty(this.selectedPeriode)
        && this.selectedPeriode[1] instanceof Date
        && !this.utils.isCollectionNullOrEmpty(this.selectedRepas)),
      switchMap(response => this.mp2Svc.getCoutsRepasPeriode(this.selectedRegimes, this.selectedRepas, this.selectedPeriode))
    ).subscribe(response => {

      this.dataCoutsRepasPeriode = [];
      response.additionalProperties['xCoutsUnitairesPeriode'].map(item => this.utils.getFrenchDateDDMMYYYY(moment(item)))
        .forEach(date => this.dataCoutsRepasPeriode.push({ date }));


      for (let i = 0; i < this.dataCoutsRepasPeriode.length; ++i) {
        this.dataCoutsRepasPeriode[i]['cout-unitaire-pondere'] = response.additionalProperties['yCoutsUnitairesPonderesPeriode'][i];

        this.dataCoutsRepasPeriode[i]['cout-unitaire-pondere-moyen'] = response.additionalProperties['yCoutsUnitairesPonderesMoyenPeriode'][i];
        this.dataCoutsRepasPeriode[i]['cout-theorique'] = response.additionalProperties['yCoutsUnitairesTheoriquesPeriode'][i];
      }
      this.dataCoutsRepasPeriode = _cloneDeep(this.dataCoutsRepasPeriode);

      this.listMapDecoupagesRepas = [];
    });
  };

  subscribeOpenAnalyseCouts = () => {
    this.subAnalyseCout = this.mp2Svc.analyseCouts$.subscribe(response => {
      this.initForm();
    });
  };

  initForm = () => {
    if (this.canDisplay()) {

      const cmcCaList = this.mp2Svc.planningMenus.contratMenuConviveList[0].contratMenuConvive__contrainteAlimList;
      this.selectedRegimes = this.utils.preSelectMultiList(cmcCaList, [this.mp2Svc.planningMenus.contrainteAlimentaire]);
      this.subjectSelectedRegimes.next(this.selectedRegimes);

      this.selectedRepas = this.utils.preSelectMultiList(this.mp2Svc.planningMenus.repasList, [this.mp2Svc.repasActif]);
      this.subjectSelectedRepas.next(this.selectedRepas);

      const dates = this.mp2Svc.planningMenus.dateList;
      this.selectedPeriode = [moment(dates[0].date).toDate(), moment(dates[dates.length - 1].date).toDate()];
      this.subjectSelectedPeriode.next(this.selectedPeriode);
    }
  };

  canDisplay = () => {
    if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)
      && !this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.contratMenuConviveList)) {
      return true;
    }
    return false;
  };

  changeSelectedRepas = ($event: any) => {
    this.subjectSelectedRepas.next(this.selectedRepas);
  };

  changeSelectedRegimes = ($event: any) => {
    this.subjectSelectedRegimes.next(this.selectedRegimes);
  };

  changeSelectedPeriode = ($event: any) => {
    if (!this.utils.isNullOrEmpty(this.selectedPeriode[0])
      && !this.utils.isNullOrEmpty(this.selectedPeriode[1])
      && this.selectedPeriode[0] instanceof Date
      && this.selectedPeriode[1] instanceof Date
    ) {
      this.subjectSelectedPeriode.next(this.selectedPeriode);
    }
  };

  selectData = ($event: any) => {
    const dateRepas = moment($event.target.argument, 'DD/MM/YYYY').toDate();
    this.mp2Svc.getCoutRepasDetails(dateRepas, this.selectedRepas, this.selectedRegimes).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.listMapDecoupagesRepas = response.additionalProperties['cellulesRepas'];
      }
    });
  };

  getValues = (mapCelluleRepas: Map<string, CelluleTableauDTO>) => {
    const arr = [];
    Object.keys(mapCelluleRepas).forEach(key => {
      arr.push(mapCelluleRepas[key]);
    });
    return arr;
  };

  getFirstValue = (mapCelluleRepas: Map<string, CelluleTableauDTO>,) => {
    let cell;
    Object.keys(mapCelluleRepas).forEach(key => {
      cell= mapCelluleRepas[key];
      return;
    });
    return cell;
  };

  getCoutUnitairePondereRepas = (mapCelluleRepas: Map<string, CelluleTableauDTO>) => {
    let cupr= 0;
    Object.keys(mapCelluleRepas).forEach(key => {
       const cell : CelluleTableauDTO= mapCelluleRepas[key];
       cupr+=cell.coutUnitairePondere;
    });
    return cupr;
  };

  getCoutTheoriqueRepas = (mapCelluleRepas: Map<string, CelluleTableauDTO>) => {
    let cutr= 0;
    Object.keys(mapCelluleRepas).forEach(key => {
      const cell : CelluleTableauDTO= mapCelluleRepas[key];
      cutr=cell.coutTheoriqueRepas;
      return;
    });
    return cutr;
  };
}
