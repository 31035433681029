import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {SelectButton} from 'primeng/selectbutton';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {MenuItem} from 'primeng/api';
import {PlanningEquipesService} from '../../../core/services/gestion-unites-productions/planning-equipes.service';
import {TYPE_TACHE} from "../../../core/services/entities/type-tache.service";
import {TachesService} from "../../../core/services/entities/taches.service";

@Component({
  selector: 'yo-up-equipes',
  templateUrl: './up-equipes.component.html',
  styleUrls: ['./up-equipes.component.scss']
})
export class UpEquipesComponent implements OnInit, OnDestroy, AfterViewInit {

  subRoute: Subscription;
  subActiveSecondaryRoute: Subscription;
  subLoadAssociations: Subscription;
  uniteDeProduction: UniteDeProductionDTO;


  readonly itemParametrage = 'PARAMETRAGE';

  @ViewChild('selectItems') selectItems: SelectButton;

  items: MenuItem[] = [

    {label: 'Paramétrage', routerLink: ['parametrage'], id: this.itemParametrage},
    {
      label: 'Approvisionnements',
      routerLink: ['planning-approvisionnements'],
      disabled: false,
      id: TYPE_TACHE.APPROVISIONNEMENT
    },
    {label: 'Sorties', routerLink: ['planning-sorties'], disabled: false, id: TYPE_TACHE.SORTIE},
    {label: 'Fabrications', routerLink: ['planning-fabrications'], disabled: false, id: TYPE_TACHE.FABRICATION},
    {
      label: 'Conditionnements',
      routerLink: ['planning-conditionnements'],
      disabled: false,
      id: TYPE_TACHE.CONDITIONNEMENT
    },

  ];

  selectedItem = this.items[0];

  @ViewChild('selectButton') selectButton: SelectButton;

  constructor(public utils: UtilsService, private router: Router,
              private itemNavSvc: ItemNavigationService,
              private tachesSvc: TachesService,
              private planningEquipesService: PlanningEquipesService,
              private routeMapSvc: RoutemapService, private route: ActivatedRoute,
              private cd: ChangeDetectorRef) {

  }


  ngOnInit(): void {

    this.routeSubscription();

    this.activeSecondaryRouteSubscription();

    this.loadAssociationsSubscription();
  }

  routeSubscription() {
    this.subRoute = this.route.parent.data.subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
      this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
    });
  }

  loadAssociationsSubscription() {
    this.subLoadAssociations = this.tachesSvc.loadAssociations$.subscribe(response => {
      this.checkAllPlanningsEquipes();
    });
  }


  checkAllPlanningsEquipes() {

    if (this.selectItems && !this.utils.isCollectionNullOrEmpty(this.items)) {

      this.items.filter(item => item.id !== this.itemParametrage).map(item => item.disabled = true);

      this.planningEquipesService.getTypesTachesConfiguresByUdp(this.uniteDeProduction.id).subscribe(response => {

          if (response && response.resultList) {
            const codesTypesTaches = response.resultList.map(item => item.code);

            this.items.forEach(item => {
              const codeTypeTache = codesTypesTaches.find(code => item.id === code);
              if (codeTypeTache) {
                item.disabled = false;
              }
            });
            this.selectItems.cd.markForCheck();
          }
        }
      );
    }
  }

  activeSecondaryRouteSubscription() {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {

      this.selectedItem = this.itemNavSvc.activeSecondaryRoute(event, this.items);
      if (this.utils.isNullOrEmpty(this.selectedItem)) {
        this.selectedItem = this.items[0];
        this.goToSelectionUrl(this.uniteDeProduction, this.selectedItem.routerLink);
      }

    });
  }

  ngAfterViewInit(): void {
    // this.goToSelectionUrl(this.uniteDeProduction, this.items[0].link);
    this.checkAllPlanningsEquipes();
  }


  changeSelectedItem(event: any) {
    this.goToSelectionUrl(this.uniteDeProduction, event.value.routerLink);
  }

  goToSelectionUrl(udp: UniteDeProductionDTO, link: string[]) {
    const url = [`gestion-unitesproduction-uniteDeProduction`, udp.id, 'equipes', link[0]];
    this.routeMapSvc.goToSecondaryRoute(url)
      .then(() => this.cd.detectChanges())
      .then(() => this.cd.markForCheck())
      .catch(e => console.error(e));
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  }


}
