<div *ngIf="!utils.isNullOrEmpty(cellule)">
  <table #table [style.width.px]="mp2Svc.decoupageRepasWidth">
    <thead class="decoupageRepas">
    <tr>
      <th [style.width.px]="mp2Svc.drWidthLibelle"
          [style.padding-left.px]="4"
          [title]="'Repas : '+cellule.libelleRepas.toUpperCase()"
          >

        <!--LIBELLE-->
        <div class="d-flex flex-row cursor" (click)="initItemsMenuDecoupageAndDisplay($event)">
          <span>{{cellule?.libelleDecoupageRepas | uppercase}}</span>
          <span class="d-flex flex-row mg-l-5" *ngIf="!hasToDisplay">{{cellule.choixMax}} plat <span
            *ngIf="cellule.choixMax > 1">s</span></span>
        </div>

      </th>

      <th *ngIf="displayFamillesGemrcn"
          [style.width.px]="mp2Svc.drWidthFamGemrcn"
          [style.text-align]="'center'">
        <yo-famnuts-decoupage [cellule]="cellule"></yo-famnuts-decoupage>
      </th>

      <th *ngIf="displayAppellations" [style.width.px]="mp2Svc.drWidthAppellations" class="no-overflow"></th>
      <th *ngIf="displayAllergenes" [style.width.px]="mp2Svc.drWidthAllergenes" class="no-overflow"></th>

      <th *ngIf="displayTauxDePrise"
          [style.width.px]="mp2Svc.drWidthTauxDePrise"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-taux-de-prise-decoupagerepas [cellule]="cellule"></yo-taux-de-prise-decoupagerepas>
      </th>

      <th *ngIf="displayCoutsUnitaires" [style.width.px]="mp2Svc.drWidthCoutsUnitaires"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-cout-decoupage-portion [cellule]="cellule"></yo-cout-decoupage-portion>
      </th>

      <th *ngIf="displayCoutsUnitairesPonderes"
          [style.width.px]="mp2Svc.drWidthCoutsUnitairesPonderes"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-cout-decoupage-pondere [cellule]="cellule"></yo-cout-decoupage-pondere>
      </th>

    </tr>
    </thead>

    <tbody class="font-12" [style.display]="hasToDisplay ? '' : 'none'">
    <ng-container *ngFor="let slot of mp2Svc.getSlots(cellule); trackBy: trackBySlot">
      <ng-container
        *ngTemplateOutlet="tplRow;context:{ menuComposition:getMenuComposition(cellule,slot.idxSlot),ordre:slot.idxSlot}"></ng-container>
    </ng-container>
    </tbody>
  </table>
</div>


<!--SOUS MENU DE SELECTION-->
<p-menu #menuDecoupage appendTo="body" [popup]="true" [model]="itemsMenuDecoupage"></p-menu>
<div #emptyDiv></div>


<!--TEMPLATE ROW-->
<ng-template #tplRow let-menuComposition="menuComposition" let-ordre="ordre">

  <tr [class.non-modifiable]="cellule.nonModifiable"
      class="tr-menucompo"
      [class.duplication-recopie-forcee]="!this.utils.isCollectionNullOrEmpty(menuComposition?.menuCompositionRecopieList)">

    <ng-container *ngIf="!utils.isNullOrEmpty(menuComposition)">
      <td [style.width.px]="mp2Svc.drWidthLibelle"
          [style.padding-left.px]="6"
          class="no-overflow"
          dnd-draggable
          (click)="initItemsMenuCompositionAndDisplay($event,cellule,menuComposition, ordre)"
          [dragEnabled]="isDragEnabled()"
          [dropZones]="menuDndSvc.dropZonePlanningAndTrashAndSameCellule(cellule)"
          [dragData]="dragMenuComposition(menuComposition,ordre)"
      >

        <p-menu #menuMenuComposition appendTo="body" [popup]="true" [model]="itemsMenuComposition"></p-menu>

        <span dnd-droppable
              [dropZones]="menuDndSvc.dropZoneSameCellule(cellule)"
              (onDropSuccess)="dropMenuComposition($event,ordre)"
        >
        <i *ngIf="menuComposition.dishWithoutFavouritesItems" class="width-25 fas fa-exclamation-triangle text-warning cursor"
           id="warning-{{menuComposition.id}}" title="Le plat dispose d'une denrée sans article préféré"></i> {{menuComposition.produitDeclinaisonLibelle}}
        </span>

      </td>

      <td *ngIf="displayFamillesGemrcn"
          [style.text-align]="'center'"
          [style.width.px]="mp2Svc.drWidthFamGemrcn" class="no-overflow">
        <yo-famnuts-menucompo
          [famillesNutritionnellesProduits]="menuComposition.familleNutritionnelleProduitDTOList"></yo-famnuts-menucompo>
      </td>

      <td *ngIf="displayAppellations"
          [style.width.px]="mp2Svc.drWidthAppellations"
          [style.text-align]="'center'"
          class="no-overflow">
        <yo-appellations-menucompo
          [produitAppellations]="menuComposition.produitAppellationDTOList"></yo-appellations-menucompo>
      </td>

      <td *ngIf="displayAllergenes"
          [style.text-align]="'center'"
          [style.width.px]="mp2Svc.drWidthAllergenes"
          class="no-overflow">
        <yo-allergenes-menucompo
          [produitsAllergenes]="menuComposition.produitAllergeneDTOList"></yo-allergenes-menucompo>
      </td>

      <td *ngIf="displayTauxDePrise"
          [style.width.px]="mp2Svc.drWidthTauxDePrise"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-taux-de-prise-menucompo
          [disable]="isDisabled(cellule.contratMenuSiteId, cellule.nonModifiable)"
          [menuCompo]="menuComposition"
          [cellule]="cellule"
        ></yo-taux-de-prise-menucompo>
      </td>

      <td *ngIf="displayCoutsUnitaires"
          [style.width.px]="mp2Svc.drWidthCoutsUnitaires"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-cout-portion
          [menuComposition]="menuComposition"></yo-cout-portion>
      </td>

      <td *ngIf="displayCoutsUnitairesPonderes"
          [style.width.px]="mp2Svc.drWidthCoutsUnitairesPonderes"
          [style.text-align]="'right'"
          class="no-overflow">
        <yo-cout-portion-pondere
          [menuCompo]="menuComposition"></yo-cout-portion-pondere>
      </td>
    </ng-container>

    <ng-container *ngIf="utils.isNullOrEmpty(menuComposition)">
      <td *ngIf="!cellule.nonModifiable" [style.width.%]="100" [attr.colspan]="getColspan()">
        <div
          dnd-droppable
          (onDropSuccess)="dropMenuComposition($event,ordre)"
          [dropZones]="menuDndSvc.dropZonePlanning(cellule)"
          (click)="showDialogRecherchePlats(cellule)">
          <label class="menucomposition-label mg-r-5"></label>
        </div>
      </td>
    </ng-container>

  </tr>

</ng-template>
