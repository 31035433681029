import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {ActivatedRoute} from "@angular/router";
import {RestesService} from "../../../../../core/services/conditionnements/restes.service";
import {TachesService} from "../../../../../core/services/taches/taches.service";
import ResteDTO from "../../../../../core/dtos/conditionnement/reste-dto";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import {TacheDTO} from "../../../../../core/dtos/tache-dto";
import {ImplementationModelesConditionnementsPlatsService} from "../../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import {ProduitDTO} from "../../../../../core/dtos/produit-dto";
import {ModelesPlatsService} from "../../../../../core/services/conditionnements/modeles-plats.service";

@Component({
  selector: 'yo-visualisation-mcp-parametrage',
  templateUrl: './visualisation-mcp-parametrage.component.html',
  styleUrls: ['./visualisation-mcp-parametrage.component.scss']
})
export class VisualisationMcpParametrageComponent implements OnInit, OnDestroy {

  subSupplier: Subscription;

  subAll: Subscription;

  /**
   * Totalité des déclinaisons
   */
  declinaisonList: DeclinaisonDTO[] = [];

  /**
   * Gestion des queues
   */
  queuesConfiguration: any = {};

  /**
   * Totalité des restes
   */
  resteList: ResteDTO[];

  /**
   *  Un trompe oeil pour DevExtreme pour avoir 1 footer d'une seule ligne.
   */
  footerList: any[] = [{id: 1}];

  /**
   * Mapping entre le n° de colonne DevExtreme et l'identifiant d'une déclinaison
   */
  mappingColumnsDeclinaisons: any = {};

  /**
   * Totalité des tâches
   */
  tachesList: TacheDTO[];

  gridParametrage: any[];

  hasIDistri = false;

  idMcp: number;
  mode: string;

  produit: ProduitDTO;
  isFabrique: boolean;

  gridGenerated: any;

  uniteConditionneLibelle: string;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private resteSvc: RestesService,
              private tacheSvc: TachesService,
              private modelsPlatsSvc: ModelesPlatsService,
              private implSvc: ImplementationModelesConditionnementsPlatsService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
    this.utils.unsubscribe(this.subAll);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initDataFromSupplierSubscription();
  }

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initDataFromSupplierSubscription = () => {
    const all$ = combineLatest([this.route.parent.data, this.route.data]);
    this.subAll = all$.subscribe(data => {
      const produitSupplier = data[0].produitSupplier;
      const idUdpMdp = data[1].idUdpMdp;

      this.produit = produitSupplier.produitDTO;
      this.isFabrique = produitSupplier.isFabrique;
      this.declinaisonList = produitSupplier.produitDeclinaisonDTOList.map(pd => pd.declinaison);

      this.implSvc.findVisualization(this.produit.id, idUdpMdp)
        .subscribe(res => {
          const model = res.one;
          const grid = model.modelePlatParametrage.grid;
          this.mode = model.modelePlatParametrage.mode;
          this.uniteConditionneLibelle = model.modeleConditionnementPlat?.uniteAConditionner?.libelle;

          let declinaisons: any = {};

          // Pour permettre la visualisation si le paramétrage n'a pas la déclinaison en question... :
          this.declinaisonList = this.declinaisonList.filter(d => model.modelePlatParametrage.queues.map(q => q.idDeclinaison).includes(d.id));
          this.declinaisonList.forEach((decli, idx: number) => {
            this.mappingColumnsDeclinaisons[idx+2] = decli.id;
            declinaisons[decli.id] = [];
            const queue = model.modelePlatParametrage.queues.find(q => q.idDeclinaison === decli.id);
            if (queue) {
              this.queuesConfiguration[decli.id] = { resteLibelle: queue.reste ? queue.reste.libelle : '' };
            } else {
              this.queuesConfiguration[decli.id] = { resteLibelle: '' };
            }
            grid.forEach((row: any, idx: number) => {
              declinaisons[decli.id][row.id] = row.data.find(d => d.idDeclinaison === decli.id && d.idCvd === row.id);
            });
          });
          this.gridGenerated = declinaisons;
          this.gridParametrage = grid;
          this.initRestes();
        });

    });

  };

  private initRestes = () => {
    this.resteSvc.getAll()
      .subscribe(restes => {
        this.resteList = restes.resultList;
      });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Visualisation de l'implémentation du modèle de conditionnement plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
