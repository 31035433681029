import {ModeleNutritionnelDetailDTO} from "./modele-nutritionnel-detail-dto";
import {ObjectDTO} from "./object-dto";

export class ModeleNutritionnelDTO  extends ObjectDTO{

  // modele nutritionnel
  code: string;
  libelle: string;
  actif:boolean;
  nbRepas:number;


  // 1 = repas consecutifs, 2 = semaines
  typeCycle:number;
  nbItemsParCycle:number;


  //modele nutritionnel details
  modeleNutritionnelDetails : ModeleNutritionnelDetailDTO[] =[];
}
