import {combineLatest, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Auth2Service} from '../core/services/security/auth2.service';
import {ConditionnementsVarianteDeclinaisonService} from '../core/services/conditionnements/conditionnement-variante-declinaison.service';
import {ModelesConditionnementsPlatsDeclinaisonsService} from '../core/services/conditionnements/modeles-conditionnements-plats-declinaison.service';
import ConditionnementVarianteDeclinaisonDTO
  from '../core/dtos/conditionnement/conditionnement-variante-declinaison-dto';
import ModeleConditionnementPlatDeclinaisonDTO
  from '../core/dtos/conditionnement/modele-conditionnement-plat-declinaison-dto';
import {ConditionnementsVarianteService} from '../core/services/conditionnements/conditionnement-variante.service';
import ConditionnementVarianteDTO from '../core/dtos/conditionnement/conditionnement-variante-dto';
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from '../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto';
import {McpConditionnementsVariantesService} from '../core/services/conditionnements/mcp-conditionnements-variantes.service';
import ModeleConditionnementPlatDTO from '../core/dtos/conditionnement/modele-conditionnement-plat-dto';
import {ModelesPlatsService} from '../core/services/conditionnements/modeles-plats.service';

@Injectable()
export class ModelePlatResolverService implements Resolve<ModelePlatSupplier> {

  constructor(private auth2Svc: Auth2Service,
              private cvSvc: ConditionnementsVarianteService,
              private cvdSvc: ConditionnementsVarianteDeclinaisonService,
              private mcpdSvc: ModelesConditionnementsPlatsDeclinaisonsService,
              private mcpcvSvc: McpConditionnementsVariantesService,
              private mcpSvc: ModelesPlatsService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModelePlatSupplier> | Promise<ModelePlatSupplier> | ModelePlatSupplier => {
    const supplier: ModelePlatSupplier = new ModelePlatSupplier();
    const idModeleConditionnementPlat: number = parseInt(route.params.idModeleConditionnementPlat, 10);

    if (idModeleConditionnementPlat) {
      const allConditionnementsVariablesByMcp$ = this.cvSvc.getAllByIdMcp(idModeleConditionnementPlat);
      const allConditionnementsVariablesDeclinaisonsByMcp$ = this.cvdSvc.getAllByIdMcp(idModeleConditionnementPlat);
      const allMcpDeclinaisonsByMcp$ = this.mcpdSvc.getAllByIdMcp(idModeleConditionnementPlat);
      const allMcpCvByMcp$ = this.mcpcvSvc.getAllByIdMcp(idModeleConditionnementPlat);
      const mcpById$ = this.mcpSvc.findById(idModeleConditionnementPlat);
      const modes$ = this.mcpSvc.getAllModes();

      supplier.idModeleConditionnementPlat = idModeleConditionnementPlat;

      const all$ = combineLatest( [
        allConditionnementsVariablesByMcp$,
        allConditionnementsVariablesDeclinaisonsByMcp$,
        allMcpDeclinaisonsByMcp$,
        allMcpCvByMcp$,
        mcpById$,
        modes$
       ]);

      return all$
        .toPromise().then(data => {
          supplier.conditionnementVarianteList = data[0].resultList;
          supplier.conditionnementVarianteDeclinaisonList = data[1].resultList;
          supplier.mcpDeclinaisonList = data[2].resultList;
          supplier.mcpConditionnementVarianteList = data[3].resultList;
          supplier.modelePlatConditionnement = data[4].one;
          supplier.modesModelePlat = data[5].resultList;
          return supplier;
        });
    }
    return of(supplier);
  };
}

export class ModelePlatSupplier {
  idModeleConditionnementPlat: number;
  conditionnementVarianteList: ConditionnementVarianteDTO[];
  conditionnementVarianteDeclinaisonList: ConditionnementVarianteDeclinaisonDTO[];
  mcpDeclinaisonList: ModeleConditionnementPlatDeclinaisonDTO[];
  mcpConditionnementVarianteList: ModeleConditionnementsPlatConditionnementsVariantesDTO[];
  modelePlatConditionnement: ModeleConditionnementPlatDTO;
  modesModelePlat: string[];
}

