import {ObjectDTO} from "./object-dto";

/**
 *@deprecated
 */
export class JourSemaineDTO extends ObjectDTO {

  // jour semaine
  id: number;
  libelle: string;
  numeroJourSemaine:number;
  actif:boolean;

}
