import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldFileSupplier extends FormFieldBaseSupplier<string> {
  controlType = 'file';
  entityName='';

  // ordre du fichier pour l'entité
  ordre:number=1;
  refresh = new Date();

  constructor(options: any){
    super(options);
    this.entityName=options['entityName'];
    this.ordre=options['ordre'];
    this.refresh=options['refresh'];
  }

}
