import {Observable, of} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {Router} from '@angular/router';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import * as moment from 'moment';
import {RegleGemrcnSupplier} from '../../../shared/ui/regle-gemrcn/regle-gemrcn-supplier';
import {CalculGemrcnService} from '../gestiongemrcn/calcul-gemrcn.service';
import {GemrcnCmcSupplier} from '../../suppliers/gestionmenus/gemrcn-cmc-supplier';
import {GemrcnSupplier} from '../../suppliers/gestionmenus/gemrcn-supplier';
import {SearchSupplier} from '../../suppliers/search-supplier';
import {DATES_FORMAT} from '../../constants';
import {ModeleNutritionnelDetailDTO} from '../../dtos/modele-nutritionnel-detail-dto';
import {sortBy as _sortBy} from 'lodash';

const URL_MODELESNUTRIS = 'dolrest/gestionmenus/modeles-nutritionnels';
const URL_SEARCH_MENUSCOMPOS = 'dolrest/gestionmenus/searchMenusComposForGemrcn';

@Injectable()
export class MenusGemrcnService {

  nbRepasPeriode:number;

  //cle de la map modeleNutritionnelDetail.id, Current Period Choix Multiple
  public mapCRgsCM: Map<number, RegleGemrcnSupplier> = new Map();

  //cle de la map modeleNutritionnelDetail.id, Current Period Choix Dirigé
  public mapCRgsCD: Map<number, RegleGemrcnSupplier> = new Map();

  //cle de la map modeleNutritionnelDetail.id, Contract Period Choix Multiple
  public mapContractRgsCM: Map<number, RegleGemrcnSupplier> = new Map();

  //cle de la map modeleNutritionnelDetail.id, Contract Period Choix Dirigé
  public mapContractRgsCD: Map<number, RegleGemrcnSupplier> = new Map();

  constructor(private http: HttpClient,
              private utils: UtilsService,
              private router: Router,
              private calculGemrcnSvc: CalculGemrcnService) {
  }

  /**
   * Récupérer les modeles nutritionnels rattachés au contrats menus convives passés en parametre
   * @returns {Observable<ResponseWrapper<ModeleNutritionnelDTO>>}
   */
  ModeleNutritionnelDTO(contratsMenusConvives: ContratMenuConviveDTO[]): Observable<ResponseWrapper<ModeleNutritionnelDTO>> {

    if (!this.utils.isCollectionNullOrEmpty(contratsMenusConvives)) {
      const idsContratMenuConvive = contratsMenusConvives.map(cmc => cmc.id).join(',');
      return this.http.get<ResponseWrapper<ModeleNutritionnelDTO>>(`${URL_MODELESNUTRIS}?idsContratMenusConvives=${idsContratMenuConvive}`).pipe(
        catchError(error => this.utils.handleError(error)));
    }
    return of(null);
  }


  /**
   * Initialisation  des grilles CMC (contrats menus convives)
   * @param gemrcnSupplier
   * @returns {GemrcnCmcSupplier[]}
   */
  init(gemrcnSupplier: GemrcnSupplier): GemrcnCmcSupplier[] {

    let gemrcnCmcSuppliers: GemrcnCmcSupplier[] = [];

    // on recupere les contrats menus convives
    if (!this.utils.isNullOrEmpty(gemrcnSupplier) && !this.utils.isCollectionNullOrEmpty(gemrcnSupplier.selectedContratsMenusConvives)) {

      const cmcs = gemrcnSupplier.selectedContratsMenusConvives.filter(cmc => !this.utils.isNullOrEmpty(cmc.modeleNutritionnel));
      cmcs.map(cmc => {

        //PERIODE COURANTE
        let gcs = this.initPeriodCourante(gemrcnSupplier, cmc);

        //PERIODE CONTRAT
        this.initContractPeriod(gemrcnSupplier, gcs);

        // on trie le modele nutri par l'ordre des familles gemrcn
        gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails = _sortBy(gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails, 'familleGemrcn.ordre');

        gemrcnCmcSuppliers.push(gcs);
      });
    }
    return gemrcnCmcSuppliers;
  }

  /**
   * Initialisation de la période courante
   * */
  private initPeriodCourante(gemrcnSupplier: GemrcnSupplier, cmc: ContratMenuConviveDTO): GemrcnCmcSupplier {
    const gcs = new GemrcnCmcSupplier();

    gcs.contratMenuConvive = cmc;
    gcs.currentDates = gemrcnSupplier.dates;
    gcs.currentStartDate = gcs.currentDates[0].clone();
    gcs.currentStopDate = gcs.currentDates[gcs.currentDates.length - 1];
    gcs.currentNbRepasPeriode = gemrcnSupplier.nbRepasPeriode;
    this.nbRepasPeriode = gemrcnSupplier.nbRepasPeriode;

    //recup des menus compos de la periode actuelle
    gcs.currentMenusCompositions = gemrcnSupplier.currentMenusCompos;

    if (!this.utils.isCollectionNullOrEmpty(gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails)) {
      //trier les modeles nutris details par l'ordre des familles GEMRCN
      gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails = _sortBy(gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails, ['familleGemrcn.ordre']);

      //calcul des regles et les stocker dans les map de choix dirigé ou choix multiple
      this.computeRules(gcs.contratMenuConvive.modeleNutritionnel.nbRepas, gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails, this.mapCRgsCM, this.mapCRgsCD, gcs.currentMenusCompositions);
    }

    return gcs;
  }

  private computeRules(nbRepas: number, mndList: ModeleNutritionnelDetailDTO[], mapCM: Map<number, RegleGemrcnSupplier>, mapCD: Map<number, RegleGemrcnSupplier>, menusCompos: MenuCompositionDTO[]) {
    if (!this.utils.isCollectionNullOrEmpty(mndList)) {
      //calcul des regles
      for (let mnd of mndList) {
        //choix multiple
        mapCM.set(mnd.id, this.calculGemrcnSvc.computeRegleGemrcn(nbRepas,this.nbRepasPeriode, menusCompos, mnd, true));
        //choix dirigé
        mapCD.set(mnd.id, this.calculGemrcnSvc.computeRegleGemrcn(nbRepas,this.nbRepasPeriode, menusCompos, mnd, false));
      }
    }
  }

  /**
   * Initialisation et calculs de la période contrat
   * @param gemrcnSupplier
   * @returns {GemrcnCmcSupplier[]}
   */
  initContractPeriod(gemrcnSupplier, gcs: GemrcnCmcSupplier) {

    //periode recurrente contrat
    const joursSemaines = gcs.contratMenuConvive.contratsMenusConvivesRepas.map(cmcr => cmcr.idJourSemaine);
    gcs.pgs = this.calculGemrcnSvc.getCurrentPeriodeGemrcn(gcs.contratMenuConvive.dateDebGemrcn, gcs.currentDates[0].clone().toDate(), gcs.contratMenuConvive.periodeGemrcn, joursSemaines);
    //si borne de date valide sur la per recurrente gemrcn
    if (!gcs.pgs.inError) {
      gcs.contractStartDate = moment(gcs.pgs.periodDates[0]).clone();
      gcs.contractStopDate = moment(gcs.pgs.periodDates[gcs.pgs.periodDates.length - 1]).clone();

      const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
      let strDates = gcs.pgs.periodDates.map(d => moment(d).clone().format(DATES_FORMAT.YYYYMMDD));
      ssw.filtersMap['contratMenuConviveId'] = new SearchSupplier(gcs.contratMenuConvive.id, undefined, undefined, undefined);
      ssw.filtersMap['dates'] = new SearchSupplier(undefined, strDates, undefined, undefined);
      let idsFamillesGemrcn = gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails.map(mnd => mnd.familleGemrcn.id);

      ssw.filtersMap['famillesGemrcn'] = new SearchSupplier(undefined, idsFamillesGemrcn, undefined, undefined);

      this.http.post<ResponseWrapper<MenuCompositionDTO>>(URL_SEARCH_MENUSCOMPOS, ssw).subscribe(response => {
        gcs.contractMenusCompositions = response.resultList;

        //calcul des regles et les stocker dans les map de choix dirigé ou choix multiple
        this.computeRules(gcs.contratMenuConvive.modeleNutritionnel.nbRepas, gcs.contratMenuConvive.modeleNutritionnel.modeleNutritionnelDetails, this.mapContractRgsCM, this.mapContractRgsCD, gcs.contractMenusCompositions);

      });
    }
  }
}

