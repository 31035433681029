import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {UtilsService} from "../../utils/utils.service";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";

export const URL_PURGE_INACTIVE_ARTICLE = 'dolrest/administration/purgeInactiveArticlesUE';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Suppression des articles inactifs
   * @returns {Observable<void>}
   */
  purgeInactiveArticles = () : Observable<ResponseWrapper<string>> => {
    return this.httpSvc.get(URL_PURGE_INACTIVE_ARTICLE, null);
  }
}
