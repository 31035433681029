import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {OrigineService} from "../../../core/services/entities/origines-service";
import {OrigineDTO} from "../../../core/dtos/origine-dto";
import {FormControl, FormGroup} from "@angular/forms";
import {GroupFormFields} from "../../../core/suppliers/group-formfields";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormFieldBaseSupplier} from "../../../core/suppliers/form-fieldbase-supplier";
import {GenericFormService} from "../../../core/services/generics/generic-form.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {LotMarcheDTO} from "../../../core/dtos/gerstion-marche/lot-marche-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {Subscription} from "rxjs";
import {ToastService} from "../../../core/services/technique/toast.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {LookUpComponent} from "../../../shared/ui/lookup/lookup.component";

@Component({
  selector: 'yo-dialog-origine',
  templateUrl: './dialog-origine.component.html',
  styleUrls: ['./dialog-origine.component.scss']
})
export class DialogOrigineComponent implements OnInit, OnDestroy {

  @ViewChild("siteLookUp") site: LookUpComponent;

  idSelectedSite: number;
  sitePlaceholder: string;
  subOpenDialogOrigine: Subscription;

  title: string;

  displayDialog: boolean = false;
  origine: OrigineDTO;
  form: FormGroup = new FormGroup({});

  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  formInfoGenerales: FormGroup = new FormGroup({});
  groups: GroupFormFields[] = [];

  constructor(public utils: UtilsService,
              private origineSvc: OrigineService,
              private gfs: GenericFormService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.openDialogOrigineSubscription();
  }

  private openDialogOrigineSubscription() {
    this.subOpenDialogOrigine = this.origineSvc.openDialogOrigine$.subscribe(response => {
      this.displayDialog = true;
      this.origine = response;
      this.initForm();
      this.initTitle(this.origine);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogOrigine);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  canModify = () => {
    return this.origineSvc.canModify(this.origine);
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }


  closeDialog = () => {
    this.displayDialog = false;
  };

  initTitle = (origine: OrigineDTO) => {
    if (origine.id === 0)
      this.title = 'Création d\'une origine';
    else if (this.auth2Svc.isSiteLocal(origine.site.id))
      this.title = 'Modification d\'une origine';
    else
      this.title = 'Consultation d\'une origine';
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.origine?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.origine?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.origine?.libelle),
      actif: new FormControl(this.origine?.actif != null ? this.origine.actif : true),
      site: new FormControl(this.origine?.site),
    });
  };

  saveOrigine = () => {
    this.form.controls['site'].setValue(this.site.getSelectedItem());
    if (this.form.valid) {
      this.origine.libelle = this.form.controls['libelle'].value;
      this.origine.site = {id: this.site.getSelectedItem().id, libelle: this.site.getSelectedItem().libelle} as SiteDTO;
      this.origine.actif = this.form.controls['actif'].value;
      this.origineSvc.saveOrigine(this.origine).subscribe((response: ResponseWrapper<LotMarcheDTO>) => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'origine a été enregistrée avec succès`);
        this.origineSvc.announceRefreshOrigineListGrid(true);
        this.closeDialog();
      });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  isDisabledSaveBtn = () => {
    if (this.origine && this.form) {
      if (this.origine.id === 0) {
        return false;
      } else {
        return !(this.form.valid && this.auth2Svc.isSiteLocal(this.origine.site.id));
      }
    }
  };

}
