import {Injectable} from "@angular/core";
import {GestionLotMarcheService} from "../../../../../core/services/gestion-marche/gestion-lot-marche.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {LotMarcheDTO} from "../../../../../core/dtos/gerstion-marche/lot-marche-dto";
import {LotMarcheIncoherenceDTO} from "../../../../../core/dtos/lot-marche-incoherence-dto";

@Injectable({
  providedIn: 'root'
})
export class IncoherenceLotMarcheResolverService implements Resolve<IncoherenceLotMarcheSupplier> {

  constructor(private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IncoherenceLotMarcheSupplier> | Promise<IncoherenceLotMarcheSupplier> | IncoherenceLotMarcheSupplier => {
    let incoLmSupplier: IncoherenceLotMarcheSupplier = new IncoherenceLotMarcheSupplier();
    let idLotMarche: number = +route.paramMap.get('idLotMarche');

    const lotMarche$ = this.gestionLotMarcheSvc.getById(idLotMarche);
    const incoherenceList$ = this.gestionLotMarcheSvc.getIncoherenceList(idLotMarche);

    const all$ = combineLatest([lotMarche$,incoherenceList$]);
    return all$.toPromise().then(data => {
      incoLmSupplier.lotMarche = data[0].one;
      incoLmSupplier.incoherenceList = data[1].resultList;
      return incoLmSupplier;
    });
  };
}

export class IncoherenceLotMarcheSupplier {
  lotMarche: LotMarcheDTO;
  incoherenceList : LotMarcheIncoherenceDTO[] = [];
}
