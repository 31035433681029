<div (click)="editable=true" *ngIf="editable===false" class="cursor">
  {{_selectedJourDecalage?.jourSemaine}}
</div>


<div *ngIf="editable">

<!--  <select ngModel (ngModelChange)="valueChanged($event)">-->
<!--    <option *ngFor="let jd of _jourDecalageList" [ngValue]="jd">{{jd.jourSemaine}}</option>-->
<!--  </select>-->

  <dx-select-box
    #selectBox
    [items]="_jourDecalageList"
    displayExpr="jourSemaine"
    (onValueChanged)="valueChanged($event)"
    (onInitialized)="onInitialized($event)"
    (onFocusOut)="onFocusOut($event)"
    [value]="_selectedJourDecalage">
  </dx-select-box>



</div>
