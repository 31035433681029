import {TextureDTO} from "../dtos/texture-dto";
import {RegimeAlimentaireDTO} from "../dtos/regime-alimentaire-dto";

export class ContrainteModel {


  texture: TextureDTO;
  regime: RegimeAlimentaireDTO;
  libelle: string;

  constructor(texture: TextureDTO, regime: RegimeAlimentaireDTO) {
    this.texture = texture;
    this.regime = regime;
    this.libelle= `${regime.libelle.toUpperCase()} ${texture.code === '0_NON_DEFINI'? '':' et '+texture.libelle.toUpperCase()}`;
  }
}
