import {Component, Input} from '@angular/core';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {WorkflowInstanceDTO} from '../../core/dtos/workflow-instance-dto';

@Component({
  selector: 'yo-button-current-step',
  templateUrl: './button-current-step.component.html',
  styleUrls: ['./button-current-step.component.scss']
})
export class ButtonCurrentStepComponent {

  @Input() workflowInstance:WorkflowInstanceDTO;
  @Input() prefix:string;

  constructor(public workflowSvc:WorkflowsService) { }

}
