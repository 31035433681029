import {Component, Input} from '@angular/core';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';
import {UtilsService} from '../../core/utils/utils.service';

@Component({
  selector: 'yo-cout-repas',
  templateUrl: './cout-repas.component.html',
  styleUrls: ['./cout-repas.component.scss']
})
export class CoutRepasComponent {

  @Input() coutRepas:number;
  @Input() coutTheoriqueRepas:number;
  @Input() libelleRepas:string;

  constructor(public mp2Svc: MenusPlanning2Service,
              public utils: UtilsService) {
  }

  getInfoCoutUnitaire = (libelleRepas:string, coutRepas: number, coutTheoriqueRepas: number) => {
    let str=`cout unitaire pondéré inférieur ou égal au cout théorique de ${coutTheoriqueRepas.toFixed(4)}€ pour le repas ${libelleRepas.toUpperCase()}`;
    if(coutRepas>coutTheoriqueRepas){
      str=`cout unitaire pondéré supérieur au cout théorique de ${coutTheoriqueRepas.toFixed(4)}€ pour le repas ${libelleRepas.toUpperCase()}`;
    }
    return str;
  };
}
