import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../../../../core/dtos/produit-declinaison-dto';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {FournisseurDTO} from '../../../../../core/dtos/fournisseur-d-t-o';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ContextLotMarcheSupplier} from './context-lot-marche-resolver.service';
import {remove as _remove, uniqBy as _uniqBy} from 'lodash'
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {MenuItem} from 'primeng/api';
import {ProduitDeclinaisonService} from '../../../../../core/services/entities/produit-declinaison.service';
import {LotMarcheContextWrapper} from '../../../../../core/suppliers/wrappers/lot-marche-context-wrapper';
import {ResponseWrapper} from '../../../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {Chips} from 'primeng/chips';
import {ToastService} from "../../../../../core/services/technique/toast.service";

export enum TabMenuContext {
  denree = 'denree',
  fournisseurs = 'fournisseurs',
  uniteDeProduction = 'uniteDeProduction'
}

@Component({
  selector: 'yo-context-dialog-lot-marche',
  templateUrl: './dialog-context-lot-marche.component.html',
  styleUrls: ['./dialog-context-lot-marche.component.scss']
})
export class DialogContextLotMarcheComponent implements OnInit, OnDestroy {

  @ViewChild('basketFournisseur') basketFournisseur: Chips;
  @ViewChild('basketUniteDeProduction') basketUniteDeProduction: Chips;

  TabMenuContext = TabMenuContext;

  activeItem: MenuItem;
  tabMenuContext: MenuItem[] = [];

  lotMarche: LotMarcheDTO;
  selectedDenreeList: ProduitDeclinaisonDTO[] = [];
  fournisseurList: FournisseurDTO[] = [];
  selectedFournisseurList: FournisseurDTO[] = [];
  uniteDeProductionList: UniteDeProductionDTO[] = [];
  selectedUniteDeProductionList: UniteDeProductionDTO[] = [];

  selectedRowKeysFournisseur = [];
  selectedRowKeysUniteDeProduction = [];

  modeSelectionDxDatagrid: string  = 'multiple';

  subscriptionRoute: Subscription;
  subProduitDecliListDto: Subscription;

  currentOrderNumber = 1;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private produitDecliSvc: ProduitDeclinaisonService,
              private auth2Svc: Auth2Service,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subProduitDecliListDto);
  }

  initTabMenuContext(activTab : any) {
    this.tabMenuContext = [
      {
        id: TabMenuContext.denree,
        label: 'Déclinaison',
        command: () => this.onChangeTabMenu(TabMenuContext.denree),
        icon: this.selectedDenreeList.length > 0 ? 'fas fa-check' : ''
      },
      {
        id: TabMenuContext.uniteDeProduction,
        label: 'Unité de production',
        command: () => this.onChangeTabMenu(TabMenuContext.uniteDeProduction),
        icon: this.selectedUniteDeProductionList.length > 0 ? 'fas fa-check' : ''
      },
      {
        id: TabMenuContext.fournisseurs,
        label: 'Fournisseurs',
        command: () => this.onChangeTabMenu(TabMenuContext.fournisseurs),
        icon: this.selectedFournisseurList.length > 0 ? 'fas fa-check' : ''
      }
    ];

    switch (activTab) {
      case TabMenuContext.fournisseurs :
        this.activeItem =this.tabMenuContext[2];
        break;
      case TabMenuContext.uniteDeProduction :
        this.activeItem =this.tabMenuContext[1];
        break;
      default:
        this.activeItem =this.tabMenuContext[0];
    }
  }

  initData() {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { contextLotMarcheSupplier: ContextLotMarcheSupplier }) => {
        //collection data
        this.lotMarche = data.contextLotMarcheSupplier.lotMarche;
        this.fournisseurList = data.contextLotMarcheSupplier.fournisseurList;
        this.uniteDeProductionList = data.contextLotMarcheSupplier.uniteDeProductionList;

        //selected data
        this.selectedUniteDeProductionList = data.contextLotMarcheSupplier.selectedUniteDeProductionList;
        this.selectedFournisseurList = data.contextLotMarcheSupplier.selectedFournisseurList;
        this.selectedDenreeList = data.contextLotMarcheSupplier.selectedDenreeList;

        this.selectedFournisseurList = this.setOrderFournisseur(this.selectedFournisseurList);

        //binding datagrid
        this.selectedRowKeysFournisseur = this.setSelectedRowKeysFournisseur(this.selectedFournisseurList);
        this.selectedRowKeysUniteDeProduction = this.setSelectedRowKeysUniteDeProduction(this.selectedUniteDeProductionList);

        //init tabmenu
        this.initTabMenuContext(TabMenuContext.denree);
        this.activeItem = this.tabMenuContext[0];

        //annonce le display du dialog pour eviter de la latence
        this.gestionLotMarcheSvc.announceOpenDialogLotMarche(this.lotMarche, TAB_NAME_LM.context);
      });

    this.subProduitDecliListDto = this.produitDecliSvc.produitDecliListDto$.subscribe((response: ProduitDeclinaisonDTO[]) => {
      let message : string = '';
      response.forEach((prodDecli: ProduitDeclinaisonDTO) => {
          this.selectedDenreeList.push(prodDecli);
      });
      //gérer les doublons.
      this.selectedDenreeList = _uniqBy( this.selectedDenreeList, (item) => item.id);
      //actualiser le menu
      this.initTabMenuContext(TabMenuContext.denree);
    });
  }

  onChangeTabMenu(tabMenu: any) {
    switch (tabMenu) {
      case TabMenuContext.fournisseurs:
        this.activeItem = this.tabMenuContext[2];
        break;
      case TabMenuContext.uniteDeProduction:
        this.activeItem = this.tabMenuContext[1];
        break;
      default :
        this.activeItem = this.tabMenuContext[0];
    }
  }

  setSelectedRowKeysFournisseur(sffList: FournisseurDTO[]) {
    let selectedRowKeysFournisseur: number[] = [];
    sffList.forEach((ff: FournisseurDTO) => {
      selectedRowKeysFournisseur.push(ff.id);
    });
    return selectedRowKeysFournisseur;
  }

  setSelectedRowKeysUniteDeProduction(sUdpLIst: UniteDeProductionDTO[]) {
    let selectedRowKeysUniteDeProduction: number[] = [];
    sUdpLIst.forEach((udp: UniteDeProductionDTO) => {
      selectedRowKeysUniteDeProduction.push(udp.id);
    });
    return selectedRowKeysUniteDeProduction;
  }

  removeDenree(produitDeclinaison: ProduitDeclinaisonDTO) {
    _remove(this.selectedDenreeList, (item: ProduitDeclinaisonDTO) => {
      return item.id === produitDeclinaison.id;
    });

    //actualiser le menu
    if(this.selectedDenreeList.length === 0){
      this.initTabMenuContext(TabMenuContext.denree);
    }
  }

  onRemoveChipFournisseur(event: any) {
    _remove(this.selectedFournisseurList, (item: FournisseurDTO) => {
      return item.id === event.value.id;
    });
    // synchronisation selectedRowKey
    _remove(this.selectedRowKeysFournisseur, (item) => {
      return item === event.value.id;
    });
    //ajout ou suppression icon tabMenu
    if(this.selectedFournisseurList.length === 0){
      this.initTabMenuContext(TabMenuContext.fournisseurs);
    }
    //Ordonne les fournisseurs
    this.selectedFournisseurList = this.setOrderFournisseur(this.selectedFournisseurList);
  }

  onRemoveChipUniteDeProduction(event: any) {
    _remove(this.selectedUniteDeProductionList, (item: UniteDeProductionDTO) => {
      return item.id === event.value.id;
    });
    // synchronisation selectedRowKey
    _remove(this.selectedRowKeysUniteDeProduction, (item) => {
      return item === event.value.id;
    });

    if(this.selectedUniteDeProductionList.length === 0){
      this.initTabMenuContext(TabMenuContext.uniteDeProduction);
    }
  }

  onSelectionChangedFournisseur(event: any) {
    let fournisseur: FournisseurDTO = event.selectedRowsData;
    fournisseur.ordre = this.currentOrderNumber;
    this.selectedFournisseurList = event.selectedRowsData;
    this.initTabMenuContext(TabMenuContext.fournisseurs);

    //Ordonne les fournisseurs
    this.selectedFournisseurList = this.setOrderFournisseur(this.selectedFournisseurList);
    this.basketFournisseur.cd.markForCheck();
  }

  onSelectionChangedUdp(event: any) {
    this.selectedUniteDeProductionList = event.selectedRowsData;
    this.basketUniteDeProduction.cd.markForCheck();
    this.initTabMenuContext(TabMenuContext.uniteDeProduction);
  }

  isDisabledSaveBtn() {
    if (this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id)) {
      return this.selectedFournisseurList.length === 0 && this.selectedDenreeList.length === 0 && this.selectedUniteDeProductionList.length === 0
    } else {
      return true;
    }
  }

  saveContext() {
    let lmContextWrapper :LotMarcheContextWrapper = {
      lotMarcheId : this.lotMarche.id,
      produitDeclinaisonList : this.selectedDenreeList,
      uniteDeProductionList: this.selectedUniteDeProductionList,
      fournisseurList : this.selectedFournisseurList
    };

    this.gestionLotMarcheSvc.saveContextLotmarche(lmContextWrapper).subscribe((response : ResponseWrapper<boolean>) =>{
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Contexte enregistré avec succès');
      this.lotMarche.contextComplete = response.one;
      this.gestionLotMarcheSvc.announceLotMarche( this.lotMarche, TAB_NAME_LM.context)
    });
  }

  closeDialog() {
    this.gestionLotMarcheSvc.announceCloseDialogLotMarche(true);
  }

  isDisabled() {
    if (this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id)) {
      this.modeSelectionDxDatagrid = 'multiple';
      return false;
    } else {
      this.modeSelectionDxDatagrid = 'none';
      return true;
    }
  }

  addDenree() {
    this.gestionLotMarcheSvc.announceDisplaySearchDenree(true);
    this.utils.focus('autocompleteSearch',20,100);
  }

  openDialogPrisization(){
    this.gestionLotMarcheSvc.announceDisplayPrioritizationFournisseur(true, this.selectedFournisseurList);
  }

  setOrderFournisseur(fournisseurList: FournisseurDTO[]){
    let orderNumber = 1 ;
    fournisseurList.forEach( (item : FournisseurDTO) =>{
      item.ordre = orderNumber;
      orderNumber++;
    });
    return fournisseurList;
  }
}
