import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {LotMarchePdLumpCaDto} from '../../../core/dtos/gerstion-marche/lot-marche-pd-lump-ca-dto';

@Component({
  selector: 'yo-info-lotmarche-article',
  templateUrl: './info-lotmarche-article.component.html',
  styleUrls: ['./info-lotmarche.component.scss']
})
export class InfoLotmarcheArticleComponent implements OnInit {

  // le composant ne s'affiche que si l'unité de production correspont au lotmarche
  @Input() uniteDeProductionId: number;
  @Input() lotMarchePdLumpCa: LotMarchePdLumpCaDto;


  constructor(public utils: UtilsService) {
  }

  ngOnInit(): void {
  }


  isArticleLotMarche() {

    return !this.utils.isNullOrEmpty(this.lotMarchePdLumpCa) && this.lotMarchePdLumpCa.lmupUdpId === this.uniteDeProductionId;
  }
}
