import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {ModelViewMouvementDTO} from '../model-view-mouvement';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ActivatedRoute} from '@angular/router';
import {StockMouvementService} from '../../../core/services/gestion-stock/stock-mouvement.service';
import {saveAs as fs_saveAs} from 'file-saver';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';


import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {DxDataGridComponent} from "devextreme-angular";


@Component({
  selector: 'yo-grille-mouvements',
  templateUrl: './grille-mouvements.component.html',
  styleUrls: ['./grille-mouvements.component.scss']
})
export class GrilleMouvementsComponent implements OnInit, OnDestroy {

  subResultSearchMouvements: Subscription;
  subRoute: Subscription;
  subSearchMouvements: Subscription;

  mouvements: ModelViewMouvementDTO[] = [];

  // criteres de recherche
  ssw: SearchSupplierWrapper;
  totalMouvements = 0;


  balanceEntreesHT = 0;
  balanceSortiesHT = 0;
  balanceHT = 0;
  balanceEntreesTTC = 0;
  balanceSortiesTTC = 0;
  balanceTTC = 0;

  readonly DELTA_WIDTH_MOUVEMENTS = 425;

  cols: any[] = [
    {field: 'action', header: 'Actions'},
    {field: 'entreeSortie', header: 'E/S'},
    {field: 'stockMouvementStatutCode', header: 'Motif'},
    {field: 'dateMouvement', header: 'Date du mouvement'},
    {field: 'zdsLibelle', header: 'Emplacement'},
    {field: 'lotArticleFournisseur', header: 'Lot Fournisseur'},
    {field: 'denominationArticle', header: 'Dénomination'},
    {field: 'fournisseurLibelle', header: 'Fournisseur filiale'},
    {field: 'quantite', header: 'Quantité'},
    {field: 'prix', header: 'Prix unitaire'},
    {field: 'tva', header: 'TVA'},
    {field: 'prixTotalHT', header: 'HT'},
    {field: 'prixTotalTTC', header: 'TTC'},

  ];

  private filters: any;
  private idsSitesSelected: number[];

  private idsZonesStockagesSelected: number[];

  private isDataRetrieved: boolean = true;

  @ViewChild('grid') grid: DxDataGridComponent;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private stocksSvc: StocksService,
              private stockMvtSvc: StockMouvementService,
              private gds: GenericDatagridService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initOnRequest();
    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.initSubRoute();
    this.initSubResultSearchMouvements();
  }

  private initSubRoute = () => {
    this.subRoute = this.route.parent.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature, null, null);
    });
  }

  private initSubResultSearchMouvements = () => {
    this.updateLoadingPopup()

    this.subResultSearchMouvements = this.stockMvtSvc.resultSearchMouvements$.subscribe(res => {
      const response: ResponseWrapper<ModelViewMouvementDTO> = res.response;
      const filters: any = res.filters;
      const idsSitesSelected: number[] = res.idsSitesSelected;
      const idsZonesStockagesSelected: number[] = res.idsZonesStockagesSelected;

      this.filters = filters;
      this.idsSitesSelected = idsSitesSelected;
      this.idsZonesStockagesSelected = idsZonesStockagesSelected;

      this.mouvements = response.resultList;
      this.totalMouvements = response.totalElements;

      this.balanceEntreesHT = response.additionalProperties['balanceEntreeHT'];
      this.balanceSortiesHT = response.additionalProperties['balanceSortieHT'];
      this.balanceHT = response.additionalProperties['balanceHT'];
      this.balanceEntreesTTC = response.additionalProperties['balanceEntreeTTC'];
      this.balanceSortiesTTC = response.additionalProperties['balanceSortieTTC'];
      this.balanceTTC = response.additionalProperties['balanceTTC'];

      this.isDataRetrieved = true;
      this.updateLoadingPopup();
    });
  }

  initOnRequest = () => {
    console.log("init sub request");
    this.subSearchMouvements = this.stockMvtSvc.onSearchMouvements$.subscribe(empty => {
      console.log("Search Catched " + empty);
      this.isDataRetrieved = false;
      this.updateLoadingPopup();
    })
  }


  lazyLoad = (event: LazyLoadEvent) => {
    this.mouvements = [];

    const page = this.gds.getPage(event);
    const size = this.gds.getSize(event);

    // relancer une recherche mouvements avec la pagination demandée
    this.stockMvtSvc.announcePaginationSearchMouvement({page, size});
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchMouvements);
    this.utils.unsubscribe(this.subRoute);
  }

  exportMouvementsDetails = () => {
    this.stockMvtSvc.exportMouvementsDetails(this.filters, this.idsSitesSelected, this.idsZonesStockagesSelected)
      .subscribe(response => {
        const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fs_saveAs(blob, `bilans-mouvements-stock-details-${new Date().getTime()}.xlsx`);
      });
  };

  formatDate = (date: string): string => date.substring(6) + '/' + date.substring(4, 6) + '/' + date.substring(0, 4);

  openModification = (mvmDto: ModelViewMouvementDTO) => {
    if (this.auth2Svc.isSiteLocal(mvmDto.site?.id)) {
      this.stockMvtSvc.announceOpenDialogModificationMouvement(mvmDto);
    }
  };

  private updateLoadingPopup = () => {
    console.log("Update : ");
    if (this.grid) {
      if (!this.isDataRetrieved)
        this.grid.instance.beginCustomLoading("Chargement en cours");
      else
        this.grid.instance.endCustomLoading();
    }
  }

  onInitialized = () => {
    this.updateLoadingPopup();
  };
}
