export class MJourDecalage{

  jourSemaine:string;
  nbJoursDecalage:number;


  constructor(jourSemaine: string, nbJoursDecalage: number) {
    this.jourSemaine = jourSemaine;
    this.nbJoursDecalage = nbJoursDecalage;
  }
}
