import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import VarianteDTO from '../../dtos/conditionnement/variante-dto';
import {HttpParams} from "@angular/common/http";

export const URL_SAVE = `dolrest/conditionnements/variantes/save`;
export const URL_DELETE = `dolrest/conditionnements/variantes/delete`;

@Injectable({
  providedIn: 'root'
})
export class VariantesService {

  private subjectOpenDialogVariante = new Subject<VarianteDTO>();
  openDialogVariante$ = this.subjectOpenDialogVariante.asObservable();

  private subjectVarianteSaved = new Subject<VarianteSavedSupplier>();
  varianteSaved$ = this.subjectVarianteSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer toutes les variantes
   * @returns {Observable<VarianteDTO>}
   */
  getAll = (idsSites?: number[]): Observable<ResponseWrapper<VarianteDTO>> => this.httpSvc.get(`dolrest/conditionnements/variantes/list${idsSites && idsSites.length ? `?idsSites=${idsSites}` : ''}`, null);

  /**
   * Sauvegarde une variante
   * @param variante
   */
  save = (v: VarianteDTO): Observable<ResponseWrapper<VarianteDTO>> => this.httpSvc.post(URL_SAVE, v);

  announceOpenDialogVariante = (param: VarianteDTO) => {
    this.subjectOpenDialogVariante.next(param);
  };

  announceVarianteSaved = (variante: VarianteDTO, isUpdate: boolean = false) => {
    this.subjectVarianteSaved.next({ isUpdate, variante } as VarianteSavedSupplier);
  };

  /**
   * Supprime une ou plusieurs variante(s) en fonction de
   * leurs identifiants.
   * @param variantesIdsToDelete
   */
  deleteByIds = (variantesIdsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", variantesIdsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

}

export interface VarianteSavedSupplier {
  isUpdate: boolean;
  variante: VarianteDTO;
}
