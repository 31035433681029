import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CatalogueAchatNonAffecteDTO} from '../../../../core/dtos/gestion-fournisseur/catalogue-achat-non-affecte-dto';
import {LazyLoadEvent} from 'primeng/api';
import {UtilsService} from '../../../../core/utils/utils.service';
import {CatalogueAchatNonAffecteService} from '../../../../core/services/gestion-fournisseurs/catalogue-achat-non-achat.service';
import {SearchSupplier} from '../../../../core/suppliers/search-supplier';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {SiteDTO} from '../../../../core/dtos/site-dto';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {remove} from 'lodash';
import {ProduitArticleDTO} from '../../../../core/dtos/produit-article-dto';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {ToastService} from "../../../../core/services/technique/toast.service";


export enum STEP_ASSOCIATE_DENREE {
  produitDeclinaisonSelection,
  siteSeletion
}

@Component({
  selector: 'yo-dialog-panier-articles',
  templateUrl: './dialog-panier.component.html',
  styleUrls: ['./dialog-panier.component.scss']
})
export class DialogPanierComponent implements OnInit {

  @Input() public displayDialog: boolean = false;
  @Input() public articles: CatalogueAchatNonAffecteDTO[] = [];
  @Output() public onClose = new EventEmitter<boolean>();
  @Output() public  affectSucessCallBack = new EventEmitter();

  colProduitDeclinaison: any[] = [
    {field: 'reference', header: 'Référence'},
    {field: 'libelle', header: 'Déclinaison'},
    {field: 'code', header: 'Code'},
    {field: 'actif', header: 'Actif'}
  ];

  colsArticle: any[] = [
    {field: 'action', header: ''},
    {field: 'codeApi', header: 'Code api'},
    {field: 'libelle', header: 'Article'},
    {field: 'referenceArticle', header: 'Reference art.'},


    {field: 'prix', header: 'Prix unitaire'}
  ];

  colsProduitArticle: any[] = [
    {field: 'siteLibelle', header: 'Site'},
    {field: 'libelle', header: 'Article'},
    {field: 'reference', header: 'Reference'},
    {field: 'marque', header: 'Marque'}
  ];


  siteList: SiteDTO[] = [];
  selectedSite: SiteDTO;

  trueBoolean = true;
  produitDeclinaisonList: ProduitDeclinaisonDTO[] = [];
  selectedProduitDeclinaison: ProduitDeclinaisonDTO;

  totalRecords: number;
  loading: boolean = false;

  StepEnum = STEP_ASSOCIATE_DENREE;
  displayAssociatedButton: boolean = false;
  displayProduitDecliSelection: boolean = true;

  titleStep: String = "";
  activeStep: number;

  displayDialogProduitArticle: boolean = false;
  isReadyOnlyProduitArticle:boolean = false;
  produitArticleList: ProduitArticleDTO[] = [];
  selectedProduitArticle: ProduitArticleDTO;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private caNonAffecteService: CatalogueAchatNonAffecteService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.activeStep = STEP_ASSOCIATE_DENREE.produitDeclinaisonSelection;
    this.titleStep = "Choisir une denrée";
    this.initData();

    this.selectedProduitArticle = <ProduitArticleDTO>{
      libelle :'',
      reference:'',
      id: 0
    }
  }

  initData = () => {

    this.siteList = this.auth2Svc.utilisateur.sites

    let ssW: SearchSupplierWrapper = new SearchSupplierWrapper();

    ssW.filtersMap['produitsDeclinaison'] = new SearchSupplier("");
    ssW.filtersMap['reference'] = new SearchSupplier("");
    ssW.filtersMap['code'] = new SearchSupplier("");

    this.caNonAffecteService.searchDenree(ssW).subscribe((data: ResponseWrapper<ProduitDeclinaisonDTO>) => {
      this.produitDeclinaisonList = data.resultList;
      this.totalRecords = data.totalElements;
    });
  };

  deleteArticle = (rowData: CatalogueAchatNonAffecteDTO) => {
    remove(this.articles, (item) => {
      return item.codeApi === rowData.codeApi;
    });
  };

  onChangeStep = (step: number) => {

    this.activeStep = step;

    switch (step) {
      case STEP_ASSOCIATE_DENREE.produitDeclinaisonSelection: {

        this.displayDialogProduitArticle = false;
        this.displayAssociatedButton = false;
        this.titleStep = "Choisir une denrée";
        this.displayProduitDecliSelection = true;

        break;
      }
      case STEP_ASSOCIATE_DENREE.siteSeletion: {

        this.titleStep = "Choisir le site";
        this.displayProduitDecliSelection = false;

        this.caNonAffecteService.getProduitArticlesLinkToProduitDeclinaison(this.selectedProduitDeclinaison).subscribe((response: ResponseWrapper<ProduitArticleDTO>) => {
          this.produitArticleList = response.resultList;

          //afficher le dialog des articles associer au produit decli
          if (this.produitArticleList.length > 0) {
            this.displayDialogProduitArticle = true;
          //sinon le créer
          } else {
            this.createProduitArticle();
          }
        });
        break;
      }
    }
  };

  onChangeSiteSelection = event => {
    if (!this.utils.isNullOrEmpty(this.selectedSite)) {
      this.displayAssociatedButton = true;
    } else {
      this.displayAssociatedButton = false;
    }
  };


  closeDialog = () => {
    this.onClose.emit(false);
    this.displayDialog = false;
    this.displayDialogProduitArticle = false;
  };

  affectArticleNonAffectList = () => {

   this.caNonAffecteService.postAffectationCaNonAffect(this.selectedProduitArticle , this.selectedSite, this.articles, this.selectedProduitDeclinaison)
     .subscribe((response :ResponseWrapper<CatalogueAchatDTO>) => {
       this.displayDialog = false;
       this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Articles affectés avec succès`);
       this.affectSucessCallBack.emit();

   });
  };

  loadDataDenree = $event => {

    let ssW: SearchSupplierWrapper = this.getSearchSupplierWrapper($event);

    this.caNonAffecteService.searchDenree(ssW).subscribe((data: ResponseWrapper<ProduitDeclinaisonDTO>) => {
      this.produitDeclinaisonList = data.resultList;
      this.totalRecords = data.totalElements;
    });
  };


  getSearchSupplierWrapper = ($event: LazyLoadEvent) => {
    let ssW: SearchSupplierWrapper = new SearchSupplierWrapper();

    if ($event !== undefined) {
      const filterProduits = $event.filters['produitsDeclinaison'];
      ssW.filtersMap['produitsDeclinaison'] = new SearchSupplier(filterProduits ? filterProduits.value : "", undefined, 'sortLibelle', 'string');

      const filterReference = $event.filters['reference'];
      ssW.filtersMap['reference'] = new SearchSupplier(filterReference ? filterReference.value : "", undefined, 'sortReference', 'string');

      const filterCode = $event.filters['code'];
      ssW.filtersMap['code'] = new SearchSupplier(filterCode ? filterCode.value : "", undefined, 'sortCode', 'string');
    }
    return ssW;
  };

  closeManualyDialogProduitArticle = () => {
    this.displayDialogProduitArticle = false;
    this.onChangeStep(this.StepEnum.produitDeclinaisonSelection)
  };

  createProduitArticle = () => {

    this.selectedProduitArticle.libelle = this.articles[0].libelle;
    this.selectedProduitArticle.id = 0;

    this.isReadyOnlyProduitArticle= false;
    this.displayDialogProduitArticle = false;

    if(this.siteList.length > 0){
     this.getUnicityProduitArticleReference(this.siteList[0]);
    }
  };

  getUnicityProduitArticleReference = (site :SiteDTO) => {
    this.caNonAffecteService.getUnicityProduitArticle(site).subscribe((response: ResponseWrapper<string>) =>{
      this.selectedProduitArticle.reference = response.one;
    });
  };

  affectProduitArticle = () => {
    this.displayDialogProduitArticle = false;
    this.isReadyOnlyProduitArticle= true;
  };

  isDisabledAssociatedBtn = () => !(this.selectedProduitArticle !== undefined &&
    this.selectedSite !== undefined &&
    this.selectedProduitArticle.libelle !== '' &&
    this.selectedProduitArticle.reference !== '');
}

