import {combineLatest, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {EnvironnementDTO} from '../core/dtos/environnement-dto';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {EnvironnementRouteMapService} from '../core/services/entities/environnement-route-map.service';
import {UtilsService} from '../core/utils/utils.service';
import {SiteService} from '../core/site.service';
import {SiteDTO} from '../core/dtos/site-dto';
import {ContratmenuDTO} from '../core/dtos/contratmenu-dto';
import {ContratMenuService} from '../core/services/gestioncontrats/contrat-menu.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class EnvironnementResolverService {
  // Observable environnement
  environnementAnnounceSource = new Subject<EnvironnementDTO>();


  constructor(private http: HttpClient,
              private environnementRouteMapService: EnvironnementRouteMapService,
              private utils: UtilsService,
              private siteService: SiteService,
              private contratMenuService: ContratMenuService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EnvironnementSupplier> | Promise<EnvironnementSupplier> | EnvironnementSupplier => {
    let environnementSupplier = new EnvironnementSupplier();
    let idEnvironnement: number = +route.paramMap.get('idEnvironnement');

    if (idEnvironnement > 0) {
      const environnementDTO$ = this.http.get<any>(this.environnementRouteMapService.getEnvironnementFicheIdentiteLink(idEnvironnement));
      const listeAllSitesActifs$ = this.siteService.getListeSitesActifs();
      const listeAllContratsMenusActifs$ = this.contratMenuService.getListeAllContratsMenusActifs();
      const all$ = combineLatest([environnementDTO$, listeAllSitesActifs$, listeAllContratsMenusActifs$]);
      return all$
        .toPromise().then(data => {

          environnementSupplier.environnementDTO = data[0];
          environnementSupplier.listeAllSitesActifs = data[1];
          environnementSupplier.listeAllContratsMenusActifs = data[2];
          return environnementSupplier;

        });


    } else {
      const listeAllSitesActifs$ = this.siteService.getListeSitesActifs();

      const listeAllContratsMenusActifs$ = this.contratMenuService.getListeAllContratsMenusActifs();

      const all$ = combineLatest(listeAllSitesActifs$, listeAllContratsMenusActifs$);

      return all$
        .toPromise().then(data => {
          environnementSupplier.listeAllSitesActifs = data[0];
          environnementSupplier.environnementDTO = null;
          environnementSupplier.listeAllContratsMenusActifs = data[1];
          return environnementSupplier;
        });
    }
  };
}


export class EnvironnementSupplier {
  environnementDTO: EnvironnementDTO;
  listeAllSitesActifs: SiteDTO[];
  listeAllContratsMenusActifs: ContratmenuDTO[];

}

