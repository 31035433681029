import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {FichetechniqueSupplier} from './fichetechnique-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {RoutemapService} from "../../../core/services/routemap.service";


@Component({
  selector: 'app-fichetechnique-produit',
  templateUrl: './fichetechnique-produit.component.html',
  styleUrls: ['./fichetechnique-produit.component.scss']
})
export class FichetechniqueProduitComponent implements OnInit, OnDestroy {

// SUBSCRIPTIONS
  sub: Subscription;

  yoProduit: ProduitDTO;

  fromMenuPlanning: boolean = false;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private produitSvc : ProduitsService,
              private routeMapSvc: RoutemapService) {
  }

  ngOnInit() {
    this.initDataResolverSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.sub);
 //   if (this.fromMenuPlanning)
 //     window.location.href = '/gestionmenus';
  }

  private initDataResolverSubscription = (): void => {
    this.fromMenuPlanning = window.location.href.includes('preselection');
    this.sub = this.route.data.subscribe((data: { fichetechniqueSupplier: FichetechniqueSupplier }) => {
      this.produitSvc.getProduitById(data.fichetechniqueSupplier.idProduit).subscribe(response => {
        this.yoProduit = response;
        if (this.utils.isNullOrEmpty(this.yoProduit))
          this.utils.setTitle('Fiche technique', this.yoProduit.libelle);
      });
    });
  };


}
