import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {FournisseurDTO} from '../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../core/services/entities/fournisseur.service';
import {SecteursService} from '../../core/services/entities/secteurs.service';
import {SecteursFournisseursService} from '../../core/services/entities/secteurs-fournisseurs.service';
import {CataloguesAchatsService} from '../../core/services/entities/catalogues-achats.service';
import {FournissseurSupplier} from './grid-fournisseur-resolver.service';
import {GestionFournisseurService} from '../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {
  GestionFournisseurCatalogueAchatService
} from '../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {ToastService} from "../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";

@Component({
  selector: 'yo-grid-fournisseurs',
  templateUrl: './grid-fournisseur.component.html',
  styleUrls: ['./grid-fournisseur.component.scss']
})
export class GridFournisseurComponent implements OnInit, OnDestroy {

  items: any;
  title: string;

  private subDeleteFournisseurParent: Subscription;
  subRoute: Subscription;
  subNewFournisseurParent: Subscription;

  selectedFiliale: FournisseurDTO;
  fournisseursList: FournisseurDTO[] = [];
  selectedFournisseur: FournisseurDTO;

  displayFormDialog: boolean = false;

  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'site', header: 'Site'},
    {field: 'libelle', header: 'Fournisseur'},
    {field: 'filiales', header: 'Filiales'},
    {field: 'code', header: 'Code'},
    {field: 'adresse', header: 'Adresse'},
    {field: 'actif', header: 'Actif'}
  ];

  /**
   * Selection des actions sur le bon de réception
   */
  filialeActions: any[] = [];

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseurs';

  popupVisible: boolean = false;

  deleteDialogTitle: string;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private fournisseurSvc: FournisseurService,
              private secteurSvc: SecteursService,
              private secteurFournisseurSvc: SecteursFournisseursService,
              private catalogueAchatSvc: CataloguesAchatsService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              private cd: ChangeDetectorRef,
              private caFournsseurSvc: GestionFournisseurCatalogueAchatService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
    this.initFilialeActions();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subNewFournisseurParent);
    this.utils.unsubscribe(this.subDeleteFournisseurParent);
  }

  initData = () => {
    this.subRoute = this.route.data.subscribe((data: { fournisseurSupplier: FournissseurSupplier }) => {
      this.fournisseursList = data.fournisseurSupplier.fournisseurParentList;
    });

    this.subNewFournisseurParent = this.gestionFournisseurSvc.refreshGridParent$.subscribe((newOne: FournisseurDTO) => {
      if (newOne) {
        const index: number = this.fournisseursList.findIndex(fournisseur => fournisseur.id === newOne.id)
        index === -1 ? this.fournisseursList.push(newOne) && this.openFournisseurDialog(newOne) : this.fournisseursList[index] = newOne
      }
    });
  };

  initFilialeActions = () => {
    if (this.selectedFiliale) {
      this.filialeActions = [
        {
          text: this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id) ? 'Modifier la filiale' : 'Voir la filiale',
          icon: this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id) ? 'fas fa-edit' : 'fas fa-eye',
          command: () => this.openFiliale(this.selectedFournisseur, this.selectedFiliale)
        },
        {
          text: ' Voir le catalogue articles de la filiale',
          icon: 'fas fa-book-open',
          command: () => this.openArticlesCatalogueAchat(this.selectedFiliale)
        },
        {
          text: ' Supprimer la filiale',
          icon: 'fas fa-trash',
          disabled: !this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id),
          command: () => this.showDeletePopup(this.selectedFiliale)
        }
      ];
    }
  };

  help = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Les fournisseurs`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Fournisseurs et Unités de production`;
    p1.lines = [
      `Les fournisseurs ont des filiales.`,
      `Les filiales sont rattachées à des secteurs géographiques.`,
      `Les filiales peuvent livrer les unités de production si elles ont le même secteur géographique que celles-ci.`
    ];

    dms.content = {
      intro: `Fonctionnement des fournisseurs`,
      paragraphes: [p1]
    };
    return dms;
  };

  getFilialeInfos = (dto: FournisseurDTO): string => {

    if (!this.utils.isCollectionNullOrEmpty(dto.secteurFournisseurList)) {
      return 'Livraisons sur : <br>' + dto.secteurFournisseurList.map(sf => sf.secteur.libelle).join('<br>');
    }
    return '';
  };

  openFiliale = (fournisseur: FournisseurDTO, filiale: FournisseurDTO) => {
    //si undefined mode creation
    if (this.utils.isNullOrEmpty(filiale)) {
      this.fournisseurSvc.announceCreateFiliale(fournisseur);
      this.displayFormDialog = true;
    } else {
      this.gestionFournisseurSvc.getFournisseuById(this.utils.isNullOrEmpty(filiale) ? 0 : filiale.id).subscribe((response: ResponseWrapper<FournisseurDTO>) => {
        const filialeAllProps = response.one;
        this.fournisseurSvc.announceEditFiliale(filialeAllProps)
        this.displayFormDialog = true;
      });
    }};

  openArticlesCatalogueAchat = (filiale: FournisseurDTO) => {
    this.caFournsseurSvc.announceDisplayCatalogueAchatFournisseur(filiale);
  };


  deleteObject = async () => {
    this.gestionFournisseurSvc.deleteFournisseur(this.selectedFournisseur.id).subscribe((response) => {
      this.closeDeleteDialog();
      this.initData();
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, response.one);
      if (this.fournisseursList.some(fournisseur => fournisseur.id === this.selectedFournisseur.id))
        this.fournisseursList = this.fournisseursList.filter(fournisseur => fournisseur.id !== this.selectedFournisseur.id)
      else {
        const editedParent = this.fournisseursList.find(fournisseur => fournisseur.children.some(child => child.id === this.selectedFournisseur.id));
        editedParent.children = editedParent.children.filter(child => child.id !== this.selectedFournisseur.id)
        this.gestionFournisseurSvc.announceRefreshGrid(editedParent);
      }
    });
  };

  showDeletePopup = (dto: FournisseurDTO) => {
    this.selectedFournisseur = dto;
    this.deleteDialogTitle = 'Confirmation de la suppression du fournisseur ' + dto.libelle;
    this.popupVisible = true;
  }

  closeDeleteDialog = () => this.popupVisible = false;

  chooseFilialeAction = (filiale: FournisseurDTO) => {
    this.selectedFiliale = filiale;
    this.initFilialeActions();
  };

  canCreate = (): boolean => {
    return this.auth2Svc.hasSitesLocaux();
  };

  onCloseFormDialog = () => {
    this.displayFormDialog = false;
  };

  isDisabledBtn = () => {
    if (!this.utils.isNullOrEmpty(this.selectedFiliale)) {
      return !this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id);
    } else {
      return false;
    }
  };

  openFournisseurDialog = (fournisseur: FournisseurDTO): void => {
    this.gestionFournisseurSvc.announceOpenFournisseurDialog(fournisseur);
  }

  itemClick = (e): void => {
    e.itemData.command();
  }
}
