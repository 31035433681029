import {Component, Input, OnInit} from '@angular/core';
import {BonReceptionLigneDTO} from "../../../core/dtos/bon-reception-ligne-dto";
import {BonReceptionDTO} from "../../../core/dtos/bon-reception-dto";
import {UtilsService} from "../../../core/utils/utils.service";

@Component({
  selector: 'yo-litige-warning',
  templateUrl: './litige-warning.component.html',
  styleUrls: ['./litige-warning.component.scss']
})
export class LitigeWarningComponent implements OnInit {

  @Input() bonReceptionLigne : BonReceptionLigneDTO;
  @Input() bonReception:BonReceptionDTO;

  constructor(public utils:UtilsService) { }

  ngOnInit() {
  }

  hasLitigeOrMnc(bonReceptionLigne:BonReceptionLigneDTO){

    if(!this.utils.isNullOrEmpty(bonReceptionLigne)){
      if(!this.utils.isCollectionNullOrEmpty(bonReceptionLigne.bonReceptionLigneMotifNonConfoList)
        || !this.utils.isCollectionNullOrEmpty(bonReceptionLigne.bonReceptionLigneLitigeList)
      ){
        return true;
      }
    }
    return false;
  }

  hasLitigeOrMncBr(bonReception:BonReceptionDTO){

    if(!this.utils.isNullOrEmpty(bonReception)){

    }
    return false;
  }

}
