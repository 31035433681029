import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {USER_PREFERENCE} from '../../constants';
import {UtilsService} from '../../utils/utils.service';
import {Subscription} from 'rxjs';
import {MenusDatesService} from './menus-dates.service';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';

@Injectable()
/**
 * @deprecated
 */
export class MenusPlanningService implements OnDestroy {


  repas: AxeRepas[];
  convives: AxeConvive[];
  dates: AxeDate[];

  //pivotsPrefs par defaut
  pivotsPrefs: number[] = this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_PLANNING_PIVOT);

  //subscriptions
  subPrefUser: Subscription;

  constructor(private menuDateSvc: MenusDatesService,
              private utils: UtilsService,
              private prefUserSvc: PreferencesUtilisateurService) {
    this.subPrefUser = this.prefUserSvc.preferencesOfUser$.subscribe(prefs => this.pivotsPrefs = this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_PLANNING_PIVOT));
  }

  /***************************************************************
   *  Angular Methods
   ****************************************************************/

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPrefUser);
  }

  /***************************************************************
   *
   * PRIVATE IMPLEMENTATION
   *
   ****************************************************************/


  trackByFn(index, item) {
    return index;
  }

}

export class Axe{
  display:boolean=true;
}

export class AxeDate extends Axe{
  date:moment.Moment;

  constructor(date:moment.Moment){
    super();
    this.date=date;
  }
}

export class DecoupageRepas extends Axe {
  drLibelle: string;
  id: number;
  contratsMenusConvivesDecoupages: ContratMenuConviveDecoupageDTO[];


  /**
   *
   * @param {string} drLibelle --> libelle du decoupage repas
   * @param {number} id
   */
  constructor(drLibelle: string, id: number) {
    super();
    this.drLibelle = drLibelle;
    this.id = id;
  }
}

export class AxeRepas extends Axe {
  rLibelle: string;
  id: number;
  ordre: number;

  /**
   *
   * @param {string} rLibelle --> libelle du repas
   * @param {number} id
   */
  constructor(rLibelle: string, id: number, ordre: number) {
    super();
    this.rLibelle = rLibelle;
    this.id = id;
    this.ordre = ordre;
  }
}

export class AxeConvive extends Axe {
  cLibelle: string;
  id: number;

  /**
   *
   * @param {string} cLibelle --> libelle du convive
   * @param {number} id
   */
  constructor(cLibelle: string, id: number) {
    super();
    this.cLibelle = cLibelle;
    this.id = id;
  }
}

export class MenuDecoupageDate {

  mapMenuComposition: Map<number, MenuCompositionDTO>;
  contratMenuConviveDecoupage: ContratMenuConviveDecoupageDTO;
  dateMenu: Date;
}
