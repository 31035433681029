import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private sanitizer: DomSanitizer) {
  }

  /**
   * Prépare le json au format URI pour un export json
   * @param json Objet JSON
   */
  generateDownloadJsonUri(json: object): SafeUrl {
    const strJson = JSON.stringify(json);
    return this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(strJson));
  }

}
