import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {UtilsService} from '../../../../core/utils/utils.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {DxDataGridComponent} from 'devextreme-angular';
import {SecteurFournisseurDTO} from '../../../../core/dtos/secteurfournisseur-dto';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {
  GestionFournisseurCatalogueAchatService
} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {HELP_FOLDERS} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-fournisseur-filiale-grid',
  templateUrl: './fournisseur-filiale-grid.component.html',
  styleUrls: ['./fournisseur-filiale-grid.component.scss']
})
export class FournisseurFilialeGridComponent implements OnInit {

  fournisseurList: FournisseurDTO[] = [];
  secteurList: SecteurDTO[] = [];
  displayFormDialog: boolean = false;

  subFilialeList: Subscription;

  @ViewChild('grid') grid: DxDataGridComponent;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseurs';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private fournisseurSvc: FournisseurService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private caFournsseurSvc: GestionFournisseurCatalogueAchatService,
              private auth2Svc: Auth2Service,
              @Inject('dynamicData') public fournisseurData: FournisseurDTO) {
  }


  ngOnInit(): void {
    this.initData();
  }

  canEdit = () => {
    return this.auth2Svc.isSiteLocal(this.fournisseurData?.site.id);
  }


  initData = () => {
    const idFournisseur = (this.fournisseurData.id);
    this.subFilialeList = this.gestionFournisseurSvc.getFilialeListByIdParentFournisseur(idFournisseur).subscribe((filialesList: FournisseurDTO[]) => {
      if (filialesList)
        this.fournisseurList = filialesList['resultList']
      else
        this.fournisseurList = []
    });
  };

  getTooltipAdresse = (adresse: AdresseDTO) => {
    const adresseToString =
      (adresse.adresse1 ? adresse.adresse1 + ' ' : '') +
      (adresse.adresse2 ? adresse.adresse2 + ' ' : '') +
      (adresse.adresse3 ? adresse.adresse3 + ' ' : '') +
      (adresse.codePostal ? adresse.codePostal + ' ' : '') +
      (adresse.ville ? adresse.ville : '');
    return adresseToString;
  };

  cellTemplate = (container, options) => {

    let secteurList: SecteurFournisseurDTO[] = [];
    if (options.value) {
      options.value.forEach((item: SecteurFournisseurDTO) => {
        if (item.actif) {
          secteurList.push(item)
        }
      });
    }

    var noBreakSpace = "\u00A0",
      text = secteurList.map((element: any) => {
        return element.secteurLibelle;
      }).join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };

  createFiliale = () => {
    this.displayFormDialog = true;
    this.fournisseurSvc.announceCreateFiliale(this.fournisseurData);
  };

  editFiliale = (cell: FournisseurDTO) => {
    this.displayFormDialog = true;
    this.fournisseurSvc.announceEditFiliale(cell);
  };

  isDisabledCreateBtn = () => {
    return false;
    //todo gere les droit de creation
  };

  onCloseFormDialog = (isFromSavingBtn: boolean) => {
    this.displayFormDialog = false;
    if (isFromSavingBtn) {
      this.refreshDataGrid();
      //todo refresh grid principale
    }
  };

  refreshDataGrid = () => {
    this.gestionFournisseurSvc.getFilialeListByIdParentFournisseur(this.fournisseurData.id).subscribe((data: ResponseWrapper<FournisseurDTO>) => {
      this.fournisseurList = data.resultList;
    });
  };

  help = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Les filiales`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Filiales et fournisseurs`;
    p1.lines = [
      `Les fournisseurs ont des filiales.`,
      `Les filiales sont rattachées à des secteurs géographiques.`,
      `Les filiales peuvent livrer les unités de production si elles ont le même secteur géographique que celles-ci.`
    ];

    dms.content = {
      intro: `Fonctionnement des filiales`,
      paragraphes: [p1]
    };
    return dms;
  };

  openDialogCatalogue = (fournisseur: FournisseurDTO) => {
    this.caFournsseurSvc.announceDisplayCatalogueAchatFournisseur(fournisseur);
  };
}
