import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EnvironnementRouteMapService} from '../environnement-route-map.service';
import {EnvironnementDTO} from '../../../dtos/environnement-dto';
import {ResponseWrapper} from 'app/core/suppliers/wrappers/response-wrapper';
import {SiteDTO} from '../../../dtos/site-dto';
import {HttpService} from "../../technique/http.service";

@Injectable()
export class EnvironnementService {

  environnementSubjectToDelete = new Subject<EnvironnementDTO>();
  environnementSubjectToDelete$ = this.environnementSubjectToDelete.asObservable();

  environnementSubjectToCreate = new Subject<EnvironnementDTO>();
  environnementSubjectToCreate$ = this.environnementSubjectToCreate.asObservable();

  openDialogSubject = new Subject<EnvironnementDTO>();
  openDialog$ = this.openDialogSubject.asObservable();

  searchTerms = new Subject<string>();

  constructor(private httpSvc :HttpService,
              private environnementRouteMapService: EnvironnementRouteMapService) {
  }

  public searchEnvironnement = (endUrl: string): Observable<EnvironnementDTO[]> => this.httpSvc.get(this.environnementRouteMapService.getSearchEnvironnementsLink(endUrl), null);

  public announceSearch = (term: string) => {
    this.searchTerms.next(term);
  };

  announceEnvironnementToDelete = (environnementDTO: EnvironnementDTO) => {
    this.environnementSubjectToDelete.next(environnementDTO);
  };

  delete = (environnementID: number): Observable<ResponseWrapper<boolean>> =>
    this.httpSvc.delete(this.environnementRouteMapService.getDeleteEnvironnementLink(environnementID));

  /**
   * cf. EnvironnementController#save
   * @param {EnvironnementDTO} environnementDTO
   * @returns {Observable<ResponseWrapper<boolean>>}
   */
  save = (environnementDTO: EnvironnementDTO): Observable<ResponseWrapper<boolean>> => this.httpSvc.post(`dolrest/environnements/environnement/saveone`, environnementDTO);


   buildUrlSite = (sitePrincipal: SiteDTO, siteReferentID: number, arrayACreer: any, arrayASupprimer: any): string => `dolrest/environnements/environnement/majContratsMenusConvives?sitePrincipal=${sitePrincipal}&siteReferentID=${siteReferentID}&arrayACreer=${arrayACreer}&arrayASupprimer=${arrayASupprimer}`;

  refreshEnvironnementGrille = (environnementsForGrille: EnvironnementDTO[], environnement: EnvironnementDTO): EnvironnementDTO[] => {
    let arr = [];
    let isFoundSiteArr = environnementsForGrille.filter(item => environnement && item.id === environnement.id);
    if (isFoundSiteArr && isFoundSiteArr.length > 0) {
      environnementsForGrille.map(item => {
        if (environnement && item.id === environnement.id) {
          item = environnement;
        }
        arr.push(item);
      });
    }
    else {
      environnementsForGrille.unshift(environnement);
      environnementsForGrille.map(item => arr.push(item));
    }
    return arr;
  };

  /**
   * cf.
   * @returns {Observable<EnvironnementDTO[]>}
   */
  findAll = (): Observable<EnvironnementDTO[]> => this.httpSvc.get('dolrest/environnements/findAll');

  announceEnvironnementToCreate = () => {
    this.environnementSubjectToCreate.next();
  };

  announceOpenDialog = (environnement: EnvironnementDTO) => {
    this.openDialogSubject.next(environnement)
  };
}


export class GrilleEnvironnement {
  content: any[];
  totalElements: number;
}
