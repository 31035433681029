import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ObjectDTO} from '../../../core/dtos/object-dto';

@Component({
  selector: 'yo-cell-button',
  templateUrl: './cell-button.component.html',
  styleUrls: ['./cell-button.component.scss']
})
export class CellButtonComponent implements OnInit {

  @Input() yoTooltipShowDelay: number = 1500;
  @Input() yoTooltip: string;
  @Input() yoLabel: string;
  @Input() yoIconClass: string;
  @Input() yoSmall: boolean;
  @Input() yoTextAlign: string = 'left';
  @Input() yoDisabled: boolean;
  @Input() yoViewMode: boolean;
  @Input() yoHiddenMode: boolean = false;

  iconClasses: string;
  buttonClasses: string;

  /**
   * Objet venant du back, sers à savoir si on peut editer ou pas : s'il appartient au site principal ou pas
   */
  @Input() yoElement: ObjectDTO;

  @Output() yoNavigation = new EventEmitter();

  constructor(public gds: GenericDatagridService) {
  }

  ngOnInit() {
    this.initButton();
    this.initIcon();
  }

  initButton = (): void => {
    if (this.yoViewMode || this.yoIconClass.includes('fa-edit') || this.yoIconClass.includes('fa-eye'))
      this.buttonClasses = "cta-inner-edit-or-watch";
    else if (this.yoIconClass.includes('trash'))
      this.buttonClasses = "cta-delete";
    else if (this.yoIconClass.includes('save'))
      this.buttonClasses = "cta-success";
    else if (!this.yoViewMode && !this.yoIconClass.includes('fa-search'))
      this.buttonClasses = "cta-inner-secondary";
  }

  initIcon = (): void => {
    if (this.yoViewMode)
      this.iconClasses = 'fa fa-eye';
    else
      this.iconClasses = this.yoIconClass;
  }

  onClickYoButton($event) {
    if (!this.yoDisabled)
      this.yoNavigation.emit($event);
  }

}
