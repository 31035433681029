<form [formGroup]="form" [style.width.px]="options.width" class="mg-r-5">

  <ng-container *ngFor="let group of _groups; index as i; trackBy : trackGroupFormFieldsByFn">

    <!--    ne pas afficher le groupe s'il n'y  a aucun champ dans ce groupe-->
    <ng-container *ngIf="!utils.isCollectionNullOrEmpty(group.fields)">

      <div class="row" *ngIf="addCssRow(i,indexNewRows)">

        <ng-container *ngIf="group.position===groupPosition.COL1">
          <div class="col-md-6 ">
            <ng-container *ngIf="group.position===groupPosition.COL1">
              <p-panel [toggleable]="true" [style]="{'margin-bottom':'10px'}">
                <ng-template pTemplate="header">
                  <div class="p-dialog-title">
                    <i [class]="group.titleIcon+' mg-r-5'"></i> <strong>{{group.titlePanel}}</strong>
                  </div>
                </ng-template>
                <ng-container *ngFor="let fieldKey of group.colsOrganization.col1; trackBy : utils.trackByFn">
                  <ng-container
                    *ngTemplateOutlet="tplField;context:{group:group,field:getField(fieldKey,group)}"></ng-container>
                </ng-container>
              </p-panel>
            </ng-container>
          </div>
          <div class="col-md-6">
            <ng-container *ngIf=" i < _groups.length-1 && _groups[(i+1)].position===groupPosition.COL2">
              <p-panel [toggleable]="true" [style]="{'margin-bottom':'10px', 'margin-right':'35px'}">
                <ng-template pTemplate="header">
                  <div class="p-dialog-title">
                    <i [class]="_groups[i+1].titleIcon+' mg-r-5'"></i> <strong>{{_groups[i + 1].titlePanel}}</strong>
                  </div>
                </ng-template>
                <ng-container *ngFor="let fieldKey of _groups[i+1].colsOrganization.col2; trackBy : utils.trackByFn">
                  <ng-container
                    *ngTemplateOutlet="tplField;context:{group:_groups[i+1],field:getField(fieldKey,_groups[i+1])}"></ng-container>
                </ng-container>
              </p-panel>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="group.position===groupPosition.COL1_AND_COL2">
          <div class="col-md-12">

            <p-panel [toggleable]="true" [style]="{'margin-bottom':'10px','margin-right':'35px'}">
              <ng-template pTemplate="header">
                <div class="p-dialog-title">
                  <i [class]="group.titleIcon+' mg-r-5'"></i> <strong>{{group.titlePanel}}</strong>
                </div>
              </ng-template>
              <div class="row">
                <div class="col-md-6">
                  <ng-container *ngFor="let fieldKey of group.colsOrganization.col1; trackBy : utils.trackByFn">
                    <ng-container
                      *ngTemplateOutlet="tplField;context:{group:group,field:getField(fieldKey,group)}"></ng-container>
                  </ng-container>
                </div>
                <div class="col-md-6" >
                  <ng-container *ngFor="let fieldKey of group.colsOrganization.col2; trackBy : utils.trackByFn">
                    <ng-container
                      *ngTemplateOutlet="tplField;context:{group:group,field:getField(fieldKey,group)}"></ng-container>
                  </ng-container>
                </div>
              </div>
            </p-panel>
          </div>
        </ng-container>

      </div>


    </ng-container>
  </ng-container>
</form>

<!--TEMPLATE GENERIC FIELD-->
<ng-template #tplField let-group="group" let-field="field">

  <div>
    <yo-dynamic-form-field-bs [fields]="group.fields" [field]="field"
                              [form]="group.form"></yo-dynamic-form-field-bs>
  </div>
</ng-template>
