<ng-container *ngIf="!utils.isNullOrEmpty(bonReceptionLigne)">

  <ng-container *ngIf="hasLitigeOrMnc(bonReceptionLigne)">

    <i class="fas fa-exclamation-triangle danger-color"
       title="Il y a une non conformité ou un litige sur cet article" ></i>

  </ng-container>


</ng-container>

<ng-container *ngIf="!utils.isNullOrEmpty(bonReception)">

  <ng-container *ngIf="bonReception.hasMncOrLitige">

    <i class="fas fa-exclamation-triangle danger-color"
       title="Il y a une non conformité ou un litige sur ce bon de réception" ></i>
  </ng-container>

</ng-container>
