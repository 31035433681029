import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {startCase as _startCase} from 'lodash';
import {shareReplay} from 'rxjs/operators';
import {RoutemapService} from '../routemap.service';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from "../../constants";

@Injectable({
  providedIn: 'root'
})
export class UniteDeProduction__zoneDeStockageService extends GenericHandler<UniteDeProduction__ZoneDeStockageDTO> {


  udpZdsListEnvironnement: UniteDeProduction__ZoneDeStockageDTO[];

  constructor(
    private routeMapSvc: RoutemapService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router,
    http: HttpClient, title: Title, private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  createEmptyDTO(): UniteDeProduction__ZoneDeStockageDTO {
    const udpZds = new UniteDeProduction__ZoneDeStockageDTO();
    udpZds.id = 0;
    return udpZds;
  }

  getAllFromEnvironnement(): void {

    console.log("UniteDeProduction__zoneDeStockageService#getAllFromEnvironnement()");
    this.gds.search(this.filterUdpZds()).pipe(
      shareReplay())
      .subscribe(response => {
        this.udpZdsListEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          response.resultList.forEach((item :UniteDeProduction__ZoneDeStockageDTO) =>{
            if(item.actif){
              this.udpZdsListEnvironnement.push(item);
            }
          });
        }
      });
  }


  filterUdpZds(): GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.getEntityName());
    grs.size = 2000;

    const lcEntityName = this.getEntityName().toLowerCase();

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `${lcEntityName}.uniteDeProduction.site.id`;
    predicat1.operator = PREDICAT_OPERATOR.In;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.ids = this.auth2Svc.utilisateur.siteListLocaux.map(item => item.id);

    search.predicats.push(predicat1);

    const predicat2 = new Predicat();
    predicat2.path = `${lcEntityName}.uniteDeProduction.actif`;
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat2);

    const predicat3 = new Predicat();
    predicat3.path = `${lcEntityName}.zoneDeStockage.actif`;
    predicat3.operator = PREDICAT_OPERATOR.Equals;
    predicat3.type = PREDICAT_TYPE.Boolean;
    predicat3.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat3);

    const predicat4 = new Predicat();
    predicat4.path = `${lcEntityName}.actif`;
    predicat4.operator = PREDICAT_OPERATOR.Equals;
    predicat4.type = PREDICAT_TYPE.Boolean;
    predicat4.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat4);

    grs.search = search;

    return grs;
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEntityName(): string {
    return "uniteDeProduction__ZoneDeStockage";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return undefined;
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return undefined;
  }

  getTitle(): string {
    return "GESTION DES ZONES DE STOCKAGE PAR UNITÉ DE PRODUCTION";
  }


  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LE PROCESSUS '${data.libelle.toUpperCase()}'`;
  }
}
