import {Injectable} from "@angular/core";
import {PointDeLivraisonDTO} from "../../dtos/point-de-livraison-d-t-o";
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";
import {RepasDTO} from "../../dtos/repas-dto";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";

export const URL_POST_ATTACH_REPAS_TO_PLC = `dolrest/gcom/prestation/points-de-livraison/attach-repas`;
export const URL_GET_REPAS_TO_PLC = `dolrest/gcom/prestation/points-de-livraison/get-repas`;
export const URL_POST_DETACH_REPAS_TO_PLC = `dolrest/gcom/prestation/points-de-livraison/detach-repas`;
export const URL_POST_DETACH_PLC = `dolrest/gcom/prestation/points-de-livraison/detach-plc`;



@Injectable()
export class GcomPointDeLivraisonService {

  constructor(private http: HttpClient,
              private utils: UtilsService){}


  /**
   * Recuperer les repas liée a un point de livraison
   *
   * @param selectedPointDeLivraison
   * @param contratMenuConvive
   */
  getGcomRepasOfPlc(selectedPointDeLivraison: PointDeLivraisonDTO, contratMenuConvive: ContratMenuConviveDTO){

    return this.http.get(URL_GET_REPAS_TO_PLC, {
      params: new HttpParams()
        .set('idContratMenuConvive', contratMenuConvive.id + '')
        .set('idPointDeLivraison', selectedPointDeLivraison.id + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }


  /**
   * Attacher des repas au point de le livraison
   *
   * @param contratMenuConvive
   * @param selectedPointDeLivraison
   * @param selectedRepas
   */
  attachRepasToPlc(contratMenuConvive: ContratMenuConviveDTO, selectedPointDeLivraison: PointDeLivraisonDTO, selectedRepas : RepasDTO[] ) {

    const fd = new FormData();
    fd.set('idContratMenuConvive', contratMenuConvive.id + '');
    fd.set('idPointDeLivraison', selectedPointDeLivraison.id + '');
    fd.set('idsRepas', selectedRepas ? selectedRepas.map(item => item.id).join(',') : '');

    return this.http.post(URL_POST_ATTACH_REPAS_TO_PLC, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }

  /**
   * Supprimer les repas "selectedRepasToRemove" liées a la prestation  "selectedPointDeLivraison"
   *
   * @param contratMenuConvive
   * @param selectedPointDeLivraison
   * @param selectedRepasToRemove
   */
  detachRepasFromPrestation(contratMenuConvive: ContratMenuConviveDTO, selectedPointDeLivraison: PointDeLivraisonDTO, selectedRepasToRemove: RepasDTO[]){

    const fd = new FormData();
    fd.set('idContratMenuConvive', contratMenuConvive.id + '');
    fd.set('idPointDeLivraison', selectedPointDeLivraison.id + '');
    fd.set('idsRepas', selectedRepasToRemove ? selectedRepasToRemove.map(item => item.id).join(',') : '');

    return this.http.post(URL_POST_DETACH_REPAS_TO_PLC, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }


  /**
   * Supprimer les prestations selectionnées et leur dependance (repas)
   *
   * @param contratMenuConvive
   * @param selectedPointDeLivraisonToRemove
   *
   */
  detachPrestation(contratMenuConvive: ContratMenuConviveDTO, selectedPointDeLivraisonToRemove: PointDeLivraisonDTO[]){

    const fd = new FormData();
    fd.set('idContratMenuConvive', contratMenuConvive.id + '');
    fd.set('idsPointDeLivraison', selectedPointDeLivraisonToRemove ? selectedPointDeLivraisonToRemove.map(item => item.id).join(',') : '');

    return this.http.post(URL_POST_DETACH_PLC, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}
