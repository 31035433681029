import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {ProduitRouteMapService} from '../../../../core/services/gestionproduits/produit-routemap.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';


@Injectable({
  providedIn: 'root'
})
export class FournisseurGridResolverService implements Resolve<FournisseurGridSupplier> {

  constructor(private auth2Svc: Auth2Service,
              private produitRouteMap: ProduitRouteMapService,
              private gestionFournisseurSvc :GestionFournisseurService,
              private gds: GenericDatagridService,
              private utils: UtilsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FournisseurGridSupplier> | Promise<FournisseurGridSupplier> | FournisseurGridSupplier => {

    const fs = new FournisseurGridSupplier();
    const idFournisseur: number = +route.paramMap.get('idFournisseur');
    let fournisseurParent$ = this.gestionFournisseurSvc.getFournisseuById(idFournisseur);
    let filialeList$ =  this.gestionFournisseurSvc.getFilialeListByIdParentFournisseur(idFournisseur);

    if (this.utils.isNumberGt0(idFournisseur)) {

      const initData$ = combineLatest([fournisseurParent$, filialeList$]);

      return initData$.toPromise().then(items => {
        if (!this.utils.isResponseSupplierError(items[0])) {
          fs.fournisseur = items[0].one;
          fs.filialeList = items[1].resultList;
          this.gestionFournisseurSvc.announceFournisseur(fs.fournisseur);
          return fs;
        }
      });
    }
  };
}

export class FournisseurGridSupplier {
  fournisseur: FournisseurDTO;
  filialeList:  FournisseurDTO[] = [];
  secteurs: SecteurDTO[] =[];
}
