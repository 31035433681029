import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {FP_ROUTES, MSG_KEY, MSG_POSITION} from '../../../../core/constants';
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheDTO} from '../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MarcheSupplier} from '../../marche-resolver.service';
import {MarcheDTO} from '../../../../core/dtos/gerstion-marche/marche-dto';


@Component({
  selector: 'yo-dialog-lot-marche',
  templateUrl: './dialog-lot-marche.component.html',
  styleUrls: ['./dialog-lot-marche.component.scss']
})
export class DialogLotMarcheComponent implements OnInit, OnDestroy {

  lotMarche: LotMarcheDTO;
  marche: MarcheDTO;

  displayDialog: boolean = false;
  lotMarcheTabMenu: MenuItem[] = [];
  activeItem: MenuItem;

  subLotMarcheDto: Subscription;
  subscriptionRoute: Subscription;
  subOpenDialog: Subscription;
  subCloseDialog: Subscription;

  MSG_KEY = MSG_KEY;
  MSG_POSITION = MSG_POSITION;

  constructor(private gestionLotMarcheSvc: GestionLotMarcheService,
              private route: ActivatedRoute,
              private router: Router,
              public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subLotMarcheDto);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subCloseDialog);
  }

  initData() {

    //pour linitalisation du titre et recuperation des info marche pour la redirection du close dialog
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { marcheSupplier: MarcheSupplier }) => {
        this.marche = data.marcheSupplier.marche;
      });

    //initialisation de la tabulation info et de redirection vers info
    this.subLotMarcheDto = this.gestionLotMarcheSvc.lotMarcheDto$.subscribe((response: any) => {
      this.lotMarche = response.lotMarche;
      this.initTabMenu(this.lotMarche, response.tabName);
      if( this.lotMarche.id === 0){
        this.initRoute();
      }
    });

    // appel reçu de dialog-info-lot-marche.component une fois le formulaire initialiser afin de ne pas créer de la latence a l'ouverture
    this.subOpenDialog = this.gestionLotMarcheSvc.openDialogLotMarche$.subscribe(response => {
      this.lotMarche = response.lotMarche;
      this.initTabMenu(this.lotMarche, response.tabName);
      this.displayDialog = true;
    });

    this.subCloseDialog = this.gestionLotMarcheSvc.closeDialogLotMarche$.subscribe(response => {
      this.closeDialog();
    });
  }

  initRoute() {
    this.router.navigate([{outlets: {secondary: [FP_ROUTES.GESTION_MARCHE, 'marche', this.marche.id, 'lots-marche', this.lotMarche.id, 'infos']}}]);
    this.activeItem = this.lotMarcheTabMenu[0];
  }

  initTabMenu(lotMarche: LotMarcheDTO, tabName: number) {
    this.lotMarcheTabMenu = [];
    this.lotMarcheTabMenu.push({
      label: 'Informations',
      routerLink: `${lotMarche.id}/infos`
    });

    if (this.utils.isNumberGt0(lotMarche.id)) {
      this.lotMarcheTabMenu.push({
        label: 'Contexte',
        routerLink: `${lotMarche.id}/context`,
      });
    }

    if (lotMarche.contextComplete) {
      this.lotMarcheTabMenu.push({
        label: 'Articles',
        routerLink: `${lotMarche.id}/articles`,
      });
    }

    if (lotMarche.articlesComplete) {
      this.lotMarcheTabMenu.push({
        label: 'Engagement',
        routerLink: `${lotMarche.id}/engagement`,
      });

      this.lotMarcheTabMenu.push({
        label: 'Incohérence',
        routerLink: `${lotMarche.id}/incoherence`,
      });
    }

    switch (tabName) {
      case TAB_NAME_LM.info:
        this.activeItem = this.lotMarcheTabMenu[0];
        break;
      case TAB_NAME_LM.context:
        this.activeItem = this.lotMarcheTabMenu[1];
        break;
      case TAB_NAME_LM.articles:
        this.activeItem = this.lotMarcheTabMenu[2];
        break;
      case TAB_NAME_LM.engagement:
        this.activeItem = this.lotMarcheTabMenu[3];
        break;
      case TAB_NAME_LM.incoherence:
        this.activeItem = this.lotMarcheTabMenu[4];
        break;
    }
  }

  closeDialog() {
    this.displayDialog = false;
    this.router.navigate([{outlets: {secondary: [FP_ROUTES.GESTION_MARCHE, 'marche', this.marche.id, 'lots-marche']}}]);
  }
}
