import {Component, OnDestroy, OnInit} from "@angular/core";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {Subscription} from "rxjs";
import {OrganisationsTourneesService} from "../../core/services/gestion-production/organisations-tournees.service";
import {OrganisationTourneesDto} from "../../core/dtos/organisation-tournees-dto";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";

@Component({
  selector: 'yo-organisations-tournees',
  templateUrl: './organisations-tournees.component.html',
  styleUrls: ['./organisations-tournees.component.scss']
})
export class OrganisationsTourneesComponent implements OnInit, OnDestroy {

  pathHelpFile: string = HELP_FOLDERS.ORGANISATIONS_TOURNEES + '/organisations';

  selectedRows: number[] = [];
  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  organisationsTournees: OrganisationTourneesDto[] = [];

  subTourneeSaved$: Subscription;

  subTournees$: Subscription;

  constructor(private auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              private graphQlSvc: GraphQLService,
              public utilsSvc: UtilsService,
              public orgTourneesSvc: OrganisationsTourneesService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subTourneeSaved$);
    this.utilsSvc.unsubscribe(this.subTournees$);
  }

  initSubscriptions = (): void => {
    this.initSubscriptionOrganisationsTournees();
  }

  initSubscriptionOrganisationsTournees = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subTournees$ = this.graphQlSvc.sendQuery(`
      {
        allOrganizationsRounds(filters: {
          idsSites: [${idsSites}]
        }) {
            id,
            code,
            label,
            periods,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.organisationsTournees = response.allOrganizationsRounds;
      });
  }

  calculateSiteValue = (org: OrganisationTourneesDto): string => {
    return org?.site?.libelle
  };

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_ORG_TOURNEES_DELETION_PLURAL" : "CONFIRM_ORG_TOURNEES_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.orgTourneesSvc.deleteOrganizationsByFilters(this.selectedRows).subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `TOURNEE_ORG_TOURNEES_COMPLETED_PLURAL` : `TOURNEE_ORG_TOURNEES_COMPLETED_SINGULAR`, null));
        this.graphQlSvc.sendQuery(`
          {
            allOrganizationsRounds(filters: {
              idsSites: [${idsSites}]
            }) {
                id,
                code,
                label,
                periods,
                site {
                    id,
                    libelle,
                },
            }
          }
        `)
          .toPromise()
          .then(response => this.organisationsTournees = response.allOrganizationsRounds);
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
  }


  openDialogEdition(id?: number) {
    this.orgTourneesSvc.announceOpenEditionDialog();
  }

}
