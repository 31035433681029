import {ObjectDTO} from "./object-dto";
import {PointDeLivraisonDTO} from "./point-de-livraison-d-t-o";

export class RepasDTO  extends ObjectDTO{

  id: number;
  code: string;
  libelle: string;
  ordre: number;
  actif: boolean;

  pointLivraison: PointDeLivraisonDTO;

}
