import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TypeEquipementPmsDto} from "../../../../core/dtos/pms/type-equipement-pms-dto";
import {TypeEquipementPmsService} from "../../../../core/services/pms/type-equipement-pms.service";
import {TachePmsService} from "../../../../core/services/pms/tache-pms.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-type-equipement-dialog',
  templateUrl: './pms-type-equipement-dialog.component.html',
  styleUrls: ['./pms-type-equipement-dialog.component.scss']
})
export class PmsTypeEquipementDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  typeEquipement: TypeEquipementPmsDto;

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  docError = false;

  dialogTitle = 'Modification d\'un type d\'équipement';

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private typeEquipementPmsService: TypeEquipementPmsService,
              private tachePmsService: TachePmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.typeEquipementPmsService.openDialog$
      .subscribe((t: TypeEquipementPmsDto) => {
        this.displayDialog = true;
        if (!t) {
          this.forUpdate = false;
          this.typeEquipement = new TypeEquipementPmsDto();
          this.typeEquipement.id = 0;
          this.dialogTitle = 'Création d\'un type d\'équipement';
        } else {
          this.forUpdate = true;
          this.typeEquipement = t;
          this.siteSelected = t.site;
          this.dialogTitle = 'Modification d\'un type d\'équipement';
        }

        this.initForm();

      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => {
    if (!this.typeEquipement || !this.typeEquipement.site || !this.typeEquipement.site.id) return this.hasPms;

    let response: boolean = false;

    if (this.typeEquipement && this.typeEquipement.site)
        response = this.auth2Svc.isSiteLocal(this.typeEquipement.site.id);

    return response && this.hasPms;
  }

  /**
   * fonction qui sauvegarde le type d'évènement pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.typeEquipement.libelle = this.form.controls['libelle'].value;
      this.typeEquipement.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.typeEquipementPmsService.save(this.typeEquipement)
        .subscribe(response => {
          this.typeEquipementPmsService.announceSaved(response.one, this.typeEquipement.id !== undefined && this.typeEquipement.id !== null && this.typeEquipement.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde du type d'équipement a été réalisée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'un type d'équipement pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.forUpdate ? this.typeEquipement.libelle : '', [Validators.required, Validators.minLength(1),Validators.maxLength(50)]),
      site: new FormControl(this.forUpdate ? this.typeEquipement.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Type d'équipement (Plan de Maîtrise Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
