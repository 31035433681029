import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {MailHistoDTO} from '../../dtos/mail-histo-dto';
import {BCF_MAIL_STATUT} from '../../constants';


@Injectable({
  providedIn: 'root'
})
export class MailHistoService extends GenericHandler<MailHistoDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  createEmptyDTO(): MailHistoDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  getEntityName(): string {
    return "mailHisto";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "HISTORIQUE DES MAILS";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

  getShortLabel(statut:number) {

    let label = '';

    if(statut === BCF_MAIL_STATUT.EN_COURS_ENVOI){

      label='EC';

    }else if(statut === BCF_MAIL_STATUT.ENVOYE){

      label='ENV';

    }
    else if(statut === BCF_MAIL_STATUT.LU){

      label='LU';

    }else if(statut === BCF_MAIL_STATUT.EN_ERREUR){

      label='ERR';
    }

    return label;

  }

  getLabelStatut(statut: number) {


    let label = '';

    if(statut === BCF_MAIL_STATUT.EN_COURS_ENVOI){

      label=`Mail en cours d'envoi`;

    }else if(statut === BCF_MAIL_STATUT.ENVOYE){

      label='Mail envoyé';

    }
    else if(statut === BCF_MAIL_STATUT.LU){

      label='Mail lu par le destinataire';

    }else if(statut === BCF_MAIL_STATUT.EN_ERREUR){

      label='Mail en erreur';
    }

    return label;

  }
}
