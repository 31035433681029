import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'yo-date-dispo',
  templateUrl: './date-dispo.component.html',
  styleUrls: ['./date-dispo.component.scss']
})
export class DateDispoComponent implements OnInit {

  @Input() dateCreationMenu :boolean;
  @Input()  dateSaisieEffectif: boolean;

  @Input() date;

  constructor() { }

  ngOnInit() {
  }

}
