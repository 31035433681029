import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {PlanningEquipesSupplier} from '../../../../shared/ui/gestion-unitesproduction/planning-equipes/PlanningEquipesSupplier';
import {PlanningEquipesService} from '../../../../core/services/gestion-unites-productions/planning-equipes.service';
import {Observable} from 'rxjs';
import {TYPE_TACHE} from '../../../../core/services/entities/type-tache.service';

@Injectable({
  providedIn: 'root'
})
export class PlanningConditionnementsResolverService implements Resolve<PlanningEquipesSupplier> {

  constructor(private planningEquipesSvc: PlanningEquipesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlanningEquipesSupplier> | Promise<PlanningEquipesSupplier> | PlanningEquipesSupplier {
    return this.planningEquipesSvc.resolve(route, state, TYPE_TACHE.CONDITIONNEMENT,30);
  }

}
