<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Contrôle des équipes</h1>
    <div class="item-wizard">
      <p class="required-field">Comment souhaitez-vous configurer vos équipes pour gérer vos conditionnements ? </p>
      <div>
        <input id="auto" type="radio" name="modeModelePlat" [(ngModel)]="modeSelected" [checked]="modeSelected === 'AUTO' ? 'checked' : ''" value="AUTO" />
        <label for="auto" class="mg-l-5 bold pointer">Configuration automatique</label>
        <p class="mg-l-18">
          En mode automatique, le système va alimenter une équipe et un atelier que vous choisirez peu après pour toutes les offres alimentaires et les prestations (compatibles avec le modèle de conditionnement point de livraison courant) ne possédant pas d'équipes pour
          les types de conditionnement configurés dans la grille du modèle de conditionnement plat.
        </p>
      </div>
      <div>
        <input id="manuel" type="radio" name="modeModelePlat" [(ngModel)]="modeSelected" [checked]="modeSelected === 'MANUAL' ? 'checked' : ''" value="MANUAL" />
        <label for="manuel" class="mg-l-5 bold pointer">Configuration manuelle</label>
        <p class="mg-l-18">
          En mode manuel, vous devrez paramétrer vous mêmes les équipes et les ateliers pour les offres alimentaires et les prestations (compatibles avec le modèle de conditionnement point de livraison courant).
          En cas de mauvais paramétrage, le conditionnement de vos plats pourrait ne pas fonctionner.
        </p>
      </div>
    </div>
    <hr>
    <div class="item-wizard" *ngIf="modeSelected === 'AUTO'">
      <div *ngFor="let tache of taches">
        <div class="row no-margin mg-t-15">
          <div class="col-lg-12">
            <label class="badge badge-secondary badge-tache">{{ tache.libelle }}</label>
          </div>
        </div>
        <div class="row no-margin">
          <div class="col-lg-6">
            <dx-lookup
              [dataSource]="ateliers"
              [(value)]="valuesByTache[tache.id].idAtelier"
              valueExpr="id"
              displayExpr="libelle"
              [searchEnabled]="true"
              [placeholder]="placeHolder('atelier', tache.libelle)">
              <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
            </dx-lookup>
          </div>
          <div class="col-lg-6">
            <dx-lookup
              [dataSource]="equipes"
              [(value)]="valuesByTache[tache.id].idEquipe"
              valueExpr="id"
              displayExpr="libelle"
              [searchEnabled]="true"
              [placeholder]="placeHolder('equipe', tache.libelle)">
              <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
            </dx-lookup>
          </div>
        </div>
      </div>
    </div>
    <div class="item-wizard" *ngIf="modeSelected === 'MANUAL'">
      <div class="row">
        <div class="col-lg-12 container-oa">
          Cliquez <a routerLink="/gestion-unitesproduction" target="_blank">ici</a> pour rejoindre la page des unités de production de votre système afin de configurer vos différentes équipes. Une fois, vos équipes configurées, vous pourrez
          fermer l'onglet en question puis appuyer sur le bouton suivant pour finaliser la configuration.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 12/13 : Contrôle des équipes - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
