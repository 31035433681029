<dx-popup
  [showTitle]="true"
  title="Ajouter un/des article(s) du fournisseur {{bonCf?.fournisseurLibelle | uppercase}} à la proposition {{bonCf?.numeroBcf | uppercase}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [fullScreen]="false"
  (onHiding)="closeDialog()"
  [style]="{'width':'82vw','height':utils.getWindowAvailableHeight(110)+'px'}"
  [(visible)]="displayDialog"
>
  <div class="row mg-b-15">
    <div class="col-md-3">
      <dx-drop-down-box
        [(value)]="treeBoxValue"
        valueExpr="id"
        displayExpr="name"
        placeholder="Filtrer par famille de produit..."
        [showClearButton]="true"
        [dataSource]="treeDataSource"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            #treeViewProduitDeclinaison
            [dataSource]="familleProduitList"
            [height]="150"
            dataStructure="plain"
            keyExpr="id"
            parentIdExpr="parent.id"
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            [selectNodesRecursive]="true"
            displayExpr="libelle"
            [selectByClick]="true"
            (onItemSelectionChanged)="onChangeParentDenreeSelection($event)"
            [searchEnabled]="true"
            searchMode="contains"
          >
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
  <dx-data-grid
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [height]="utils.getWindowAvailableHeight(375)"
    keyExpr="id"
    width="100%"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>
    <dxi-column dataField="id" cellTemplate="templateSelection" caption="Sélection" [width]="200" [allowSorting]="false" alignment="left"
                [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="referenceArticle" cellTemplate="templateReference" caption="Référence" [width]="150" alignment="left"></dxi-column>
    <dxi-column dataField="produitArticle.libelle" cellTemplate="templateArticle" caption="Article" alignment="left"></dxi-column>
    <dxi-column dataField="prix" cellTemplate="templatePrix" caption="Prix d'achat" [allowSorting]="false" alignment="left"
                [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="uniteTechnique" cellTemplate="templateUT" caption="UT" [width]="150" [allowSorting]="false" alignment="left"
                [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="uniteDeCommande" cellTemplate="templateUC" caption="UC" [width]="150" [allowSorting]="false" alignment="left"
                [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="libelleFamilleProduit" caption="Famille de produit" [allowSorting]="false"
                alignment="left" [allowFiltering]="false"></dxi-column>

    <div *dxTemplate="let cell of 'templateSelection'" class="custom-button-container">
      <yo-cell-button (click)="addArticle(cell.row.data)"
                      [class.hidden]="cell.row.data.quantite && cell.row.data.quantite>=0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-cart-plus"
                      yoTooltip="Ajouter un article"
      ></yo-cell-button>
      <yo-cell-button (click)="removeArticle(cell.row.data)"
                      [class.hidden]="!cell.row.data.quantite || cell.row.data?.quantite <= 0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-minus"
                      yoTooltip="Enlever un article"
      ></yo-cell-button>
      <ng-container id="input" *ngIf="cell.row.data.quantite && cell.row.data.quantite > 0">
            <span style="padding: 0 5px;">
                          <input pInputText pKeyFilter="pnum"
                                 [style.width.px]="62"
                                 min="0"
                                 [(ngModel)]="cell.row.data.quantite">
                          <span>{{cell.row.data.uniteDeCommande.abreviation}}</span>
                        </span>
      </ng-container>
      <yo-cell-button (click)="addArticle(cell.row.data)"
                      [class.hidden]="!cell.row.data.quantite || cell.row.data.quantite<=0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-plus"
                      yoTooltip="Ajouter un article"
      ></yo-cell-button>
    </div>
    <div *dxTemplate="let cell of 'templateReference'">
      {{ cell.row.data.referenceArticle | uppercase}}
    </div>
    <div *dxTemplate="let cell of 'templateArticle'">
      <div class="d-flex">
        <div class="width-25 size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
             [title]="'Hérité du founisseur : ' + cell.row.data.fournisseurOwnerLibelle">
                        <span class="fa-stack fa-2x">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <strong class="fa-stack-1x custom-letter">H</strong>
                        </span>
        </div>
        <div class="width-25" *ngIf="utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"></div>
        <div>
          {{cell.row.data.produitArticle.libelle | uppercase}}
        </div>
      </div>

      <yo-info-lotmarche-article [uniteDeProductionId]="bonCf?.uniteDeProductionId"
                                 [lotMarchePdLumpCa]="cell.row.data.lmPdUpCa"></yo-info-lotmarche-article>
    </div>
    <div *dxTemplate="let cell of 'templatePrix'">
      <ng-container
        *ngTemplateOutlet="tplGroupeAchatPrix;context:{catalogueAchat:cell.row.data}"></ng-container>
    </div>
    <div *dxTemplate="let cell of 'templateUT'">
      <div class="no-overflow"
           [title]="cell.row.data.produitArticle.produitDeclinaison.uniteDeMesure.libelle"
      >
        {{cell.row.data.produitArticle.produitDeclinaison.uniteDeMesure.libelle | uppercase}}
      </div>
    </div>
    <div *dxTemplate="let cell of 'templateUC'">
      <div class="no-overflow" [title]="cell.row.data.uniteDeCommande.libelle">
        {{cell.row.data.uniteDeCommande.libelle | uppercase}}
      </div>
    </div>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="single"></dxo-sorting>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} article(s)">
    </dxo-pager>

  </dx-data-grid>
  <!--FOOTER-->
  <div class="row mg-t-10">
    <div class="col-md-12 text-right">
      <span class="mg-r-5">
        {{selectedArticles.length}} article(s) sélectionné(s)
      </span>
      <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-plus"
          (onClick)="addArticlesForPropositionCommande()"
          label="Ajouter"
          [disabled]="!canAddArticlesForPropositionCommande()"
        ></yo-button>
      </span>
      <yo-button iconClass="fas fa-times"
                 buttonClass="cta-inner-delete"
                 (onClick)="closeDialog()" label="Fermer"></yo-button>
    </div>
  </div>

</dx-popup>

<ng-template #tplGroupeAchatPrix let-catalogueAchat="catalogueAchat">

  <ng-container [ngSwitch]="catalogueAchat.groupeAchatCaList.length">

    <!--    1 seul groupement d'achat pour l'article-->
    <ng-container *ngSwitchCase="1">
      <div class="d-flex">
        <div class="width-54">
        </div>
        <div>
          {{catalogueAchat.groupeAchatCaList[0].prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
          / {{catalogueAchat.uniteDeFacturation.libelle | slice:0:5}}
        </div>
      </div>


    </ng-container>

    <!--    plusieurs groupements d'achats pour l'article-->
    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let gaca of catalogueAchat.groupeAchatCaList">


        <div class="d-flex">

          <!-- selectionner le prix-->
          <div class="width-40">
            <p-radioButton [name]="'groupname'+catalogueAchat.id" [value]="gaca.prixUF.value"
                           [(ngModel)]="catalogueAchat.prix"></p-radioButton>
          </div>

          <!-- afficher le prefere-->
          <div class="width-24">
            <i *ngIf="gaca.prefere" class="mg-r-5 fas fa-star cursor" title="Article préféré"></i>
          </div>

          <!-- prix en uf-->
          <div class="width-150">
            {{gaca.prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
            / {{catalogueAchat.uniteDeFacturation.libelle | slice:0:5}}
          </div>

          <!-- groupement d'achat-->
          <div class="width-100 font-14 cursor" title="Groupement d'achat">
            ({{gaca.libelleGroupeAchat}})
          </div>
        </div>


      </ng-container>

    </ng-container>

  </ng-container>

</ng-template>

