import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {CatalogueAchatNonAffecteService} from '../../../core/services/gestion-fournisseurs/catalogue-achat-non-achat.service';
import {CatalogueAchatNonAffecteDTO} from '../../../core/dtos/gestion-fournisseur/catalogue-achat-non-affecte-dto';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {FournisseurDTO} from '../../../core/dtos/fournisseur-d-t-o';
import {SecteurDTO} from '../../../core/dtos/secteur-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../../core/suppliers/generics/generic-request-supplier';
import {catchError, debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {LazyLoadEvent} from 'primeng/api';
import {uniq} from 'lodash';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../../core/constants';

@Component({
  selector: 'yo-catalogue-achat-non-affecte',
  templateUrl: './catalogue-achat-non-affecte.component.html',
  styleUrls: ['./catalogue-achat-non-affecte.component.scss']
})
export class CatalogueAchatNonAffecteComponent implements OnInit {

  cols: any[] = [
    {field: 'selection', header: ''},
    {field: 'codeApi', header: 'Code API'},
    {field: 'libelle', header: 'Article'},
    {field: 'referenceArticle', header: 'Reference art.'},
    {field: 'fournisseur', header: 'Fournisseur'},
    {field: 'filialeSecteur', header: 'Filiale / secteur'},
    {field: 'prix', header: 'Prix unitaire'},
    {field: 'uniteFacturation', header: `Unité de facturation`},
    {field: 'uniteCommande', header: `Unité de commande`},
    {field: 'uniteDeStockage', header: `Unité de stockage`}
  ];

  articles :CatalogueAchatNonAffecteDTO[] =[];
  selectedArticles :CatalogueAchatNonAffecteDTO[] =[];
  articlesInBasket :CatalogueAchatNonAffecteDTO[] =[];

  loading: boolean = false;
  totalRecords: number;

  filiales:FournisseurDTO[];
  selectedFiliales:FournisseurDTO;
  secteurs:SecteurDTO[];
  selectedSecteurs:SecteurDTO[];
  minPrixAchat = 0;
  maxPrixAchat = 100;
  rangePrixAchat : number[] = [0,100];

  displayBasketDialog:boolean = false;

  trueBoolean: boolean = true;

  grs: GenericRequestSupplier;

  constructor(public utils : UtilsService,
              private gds: GenericDatagridService,
              private caNonAffecteService : CatalogueAchatNonAffecteService){
  }

  public ngOnInit(): void {
    this.initData();
  }

  private initData = () => {
    this.caNonAffecteService.getListCatalogueAchatNonAffecte()
      .subscribe((data: ResponseWrapper<CatalogueAchatNonAffecteDTO>) =>{
      this.articles = data.resultList;
      this.totalRecords = data.totalElements;
    });


    this.caNonAffecteService.getListSecteur().subscribe((data: ResponseWrapper<SecteurDTO>) =>{
       this.secteurs = data.resultList;
    });

    this.caNonAffecteService.getListFilliale().subscribe((data: ResponseWrapper<FournisseurDTO>) =>{
       this.filiales = data.resultList;
    });
  };

  affectSucessCallBack = () => {
    this.articlesInBasket = [];
    this.caNonAffecteService.getListCatalogueAchatNonAffecte().subscribe((data: ResponseWrapper<CatalogueAchatNonAffecteDTO>) =>{
      this.articles = data.resultList;
      this.totalRecords = data.totalElements;
    });
  };

  public loadData = $event => {

    this.loading = true;

    this.articles = [];

    this.grs = this.filterArticles(this.caNonAffecteService.getEntityName(), $event);
    const all$ = this.gds.search(this.grs);

    all$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      catchError(err => this.utils.handleError(err)),
      finalize(() => this.loading = false)
    ).subscribe(response => {

      this.articles = response.resultList;
      this.totalRecords = response.totalElements;

    });
  };

  addArticle = () => {
    this.selectedArticles.forEach( (article:CatalogueAchatNonAffecteDTO)  => {
      this.articlesInBasket.push(article);
    });

    this.articlesInBasket = uniq(this.articlesInBasket)
    this.selectedArticles = [];
  };

  displayBasket = () => {
    this.displayBasketDialog = true;
  };

  onCloseDialog = (event:boolean) => {
    this.displayBasketDialog = event;
  };

  filterArticles = (entityName: string, $event: LazyLoadEvent): GenericRequestSupplier => {

    if (this.utils.isNullOrEmpty(this.grs)) {
      this.grs = new GenericRequestSupplier(entityName);
    }

    const search = new Search();

    search.predicats = [];



    const filterCodeApi = $event.filters['codeApi'];
    if (filterCodeApi) {

      const predicatCodeApi = new Predicat();

      predicatCodeApi.path = 'catalogueachatnonaffecte.codeApi';
      predicatCodeApi.operator = PREDICAT_OPERATOR.Contains;
      predicatCodeApi.type = PREDICAT_TYPE.String;
      predicatCodeApi.value = filterCodeApi.value;

      search.predicats.push(predicatCodeApi);
    }

    const filterReferenceArticle = $event.filters['referenceArticle'];
    if (filterReferenceArticle) {

      const predicatReferenceArticle = new Predicat();

      predicatReferenceArticle.path = 'catalogueachatnonaffecte.referenceArticle';
      predicatReferenceArticle.operator = PREDICAT_OPERATOR.Contains;
      predicatReferenceArticle.type = PREDICAT_TYPE.String;
      predicatReferenceArticle.value = filterReferenceArticle.value;

      search.predicats.push(predicatReferenceArticle);
    }

    const filterArticle= $event.filters['articles'];
    if (filterArticle) {

      const predicatArticle = new Predicat();

      predicatArticle.path = 'catalogueachatnonaffecte.libelle';
      predicatArticle.operator = PREDICAT_OPERATOR.Contains;
      predicatArticle.type = PREDICAT_TYPE.String;
      predicatArticle.value = filterArticle.value;

      search.predicats.push(predicatArticle);
    }

    const filterPrix = $event.filters['prix'];
    if (filterPrix) {

      const predicatPrixMin= new Predicat();

      predicatPrixMin.path = 'catalogueachatnonaffecte.prix';
      predicatPrixMin.operator = PREDICAT_OPERATOR.GreaterThanEquals;
      predicatPrixMin.type = PREDICAT_TYPE.Integer;
      predicatPrixMin.value = filterPrix.value.values[0];

      search.predicats.push(predicatPrixMin);

      const predicatPrixMax= new Predicat();

      predicatPrixMax.path = 'catalogueachatnonaffecte.prix';
      predicatPrixMax.operator = PREDICAT_OPERATOR.LessThanEquals;
      predicatPrixMax.type = PREDICAT_TYPE.Integer;
      predicatPrixMax.value = filterPrix.value.values[1];

      search.predicats.push(predicatPrixMax);
    }

    this.grs.search = search;

    const sort = this.gds.getSort($event, 'catalogueachatnonaffecte.libelle');

    search.sorts = [];
    search.sorts.push(sort);

    this.grs.page = this.gds.getPage($event);
    this.grs.size = this.gds.getSize($event);

    return this.grs;
  };

  closeDialog = () => {
    this.displayBasketDialog = false;
  };
}
