import {ObjectDTO} from '../../../../core/dtos/object-dto';
import {MPrintFicheTechniqueComposants} from './model-print-pdf-ft-composants';

export class MPrintFicheTechniqueDTO extends ObjectDTO {

  libelle: string;
  libelleDeclinaison: string;
  nombreUT:number;
  libelleUT: string;
  coutTotal:number;
  poidsTotal:number;
  poidsTotalKg:number;
  dlc:string;
  modeoperatoire:string;

  printFicheTechniqueComposantList:MPrintFicheTechniqueComposants[];

}
