import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CelluleTableauDTO} from '../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {USER_PREFERENCE} from '../../core/constants';

@Component({
  selector: 'yo-cout-decoupage-portion',
  templateUrl: './cout-decoupage-portion.component.html',
  styleUrls: ['./cout-decoupage-portion.component.scss']
})
export class CoutDecoupagePortionComponent implements OnInit {

  subDisplay: Subscription;
  displayCouts: boolean = false;

  @Input() cellule: CelluleTableauDTO;

  constructor(private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.subcriptionDisplayCout();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplay);
  }

  subcriptionDisplayCout = () => {
    this.subDisplay = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayCouts = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
      this.cd.markForCheck();
    });
  };

  display = () => {
    if (this.displayCouts) {
      if (Object.keys(this.cellule.mapMenuComposition).length > 0) {
        return true;
      }
    }
    return false;
  };
}
