export class ImportPropertyDTO{

  colIndex:number;
  domainNameProperty:string;
  isObject:boolean
  objectClassName : string;
  type:string;




  constructor(colIndex: number, domainNameProperty: string, type: string) {
    this.colIndex = colIndex;
    this.domainNameProperty = domainNameProperty;
    this.type = type;
  }
}
