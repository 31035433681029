import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ClientDTO} from "../../dtos/client-dto";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {PointDeLivraisonDTO} from "../../dtos/point-de-livraison-d-t-o";
import {HttpParams} from "@angular/common/http";

export const URL_SAVE_LINK_BETWEEN_MCPLC_AND_PLC = "dolrest/gestion-clients/setMcPlc";
export const URL_DELETE_LINK_BETWEEN_MCPLC_AND_PLC = "dolrest/gestion-clients/deleteMcPlc";

export const URL_SAVE_LINK_BETWEEN_MCPLC_AND_MULTIPLE_PLC = "dolrest/gestion-clients/setMcPlcMultiplePlc";
export const URL_DELETE_LINK_BETWEEN_MCPLC_AND_MULTIPLE_PLC = "dolrest/gestion-clients/deleteMcPlc-multiple-plc";


@Injectable({
  providedIn: 'root'
})
export class ClientsBusinessService {

  private subjectAnnounceClient = new BehaviorSubject(null);
  announceClient$ = this.subjectAnnounceClient.asObservable();

  private subjectAnnounceOpenDialogLiaisonPlc_McPlc = new Subject<PointDeLivraisonDTO>();
  announceOpenDialogLiaisonPlc_McPlc$ = this.subjectAnnounceOpenDialogLiaisonPlc_McPlc.asObservable();

  private subjectAnnounceLiaisonPlc_McPlcSaved = new Subject<boolean>();
  announceAnnounceLiaisonPlc_McPlcSaved$ = this.subjectAnnounceLiaisonPlc_McPlcSaved.asObservable();

  private subjectBindPlcsDone = new Subject<Boolean>();
  bindPlcsDone$ = this.subjectBindPlcsDone.asObservable();

  constructor(private httpSvc: HttpService) {
  }

  announceBindPlcsDone = () => {
    this.subjectBindPlcsDone.next(true);
  };

  announceClient = (client: ClientDTO) => {
    this.subjectAnnounceClient.next(client);
  };

  announceOpenDialogLiaisonPlc_McPlc = (plc: PointDeLivraisonDTO) => {
    this.subjectAnnounceOpenDialogLiaisonPlc_McPlc.next(plc);
  };

  announceLiaisonPlc_McPlcSaved = (result: boolean) => {
    this.subjectAnnounceLiaisonPlc_McPlcSaved.next(true);
  };

  /**
   * Sauvegarde un lien entre un mcplc et un plc
   */
  save = (id: number, idMcPlc: number): Observable<ResponseWrapper<boolean>> => this.httpSvc.post(`${URL_SAVE_LINK_BETWEEN_MCPLC_AND_PLC}/${id}/${idMcPlc}`, null);

  /**
   * Sauvegarde un lien entre un mcplc et un ou plusieurs plc(s)
   * @param idMcPlc
   * @param plcIdList
   */
  saveMultiplePlc = (idMcPlc: number, plcIdList: number[]): Observable<ResponseWrapper<boolean>> => this.httpSvc.post(`${URL_SAVE_LINK_BETWEEN_MCPLC_AND_MULTIPLE_PLC}/${idMcPlc}`, plcIdList);

  /**
   * Supprime un lien entre un mcplc et un plc
   */
  delete = (id: number): Observable<ResponseWrapper<boolean>> => this.httpSvc.delete(`${URL_DELETE_LINK_BETWEEN_MCPLC_AND_PLC}/${id}`, null);

  /**
   * Supprime un lien entre un mcplc et un ou plusieurs plc(s)
   */
  deleteMultiplePlc = (plcIdList: number[]): Observable<ResponseWrapper<boolean>> =>
     this.httpSvc.delete(`${URL_DELETE_LINK_BETWEEN_MCPLC_AND_MULTIPLE_PLC}`, new HttpParams().set("idPlcList", plcIdList.join(',')));

}
