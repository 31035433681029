import {Injectable} from '@angular/core';
import {UniteDeProduction__EquipeDTO} from '../../../core/dtos/unite-de-production__equipe';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {combineLatest, Observable} from 'rxjs';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {ContratMenuConvive__EquipeDTO} from '../../../core/dtos/contratmenuconvive-equipe-dto';
import {TacheDTO} from '../../../core/dtos/tache-dto';
import {TachesService} from '../../../core/services/entities/taches.service';
import {map} from 'rxjs/operators';
import {TYPE_TACHE} from "../../../core/services/entities/type-tache.service";

@Injectable({
  providedIn: 'root'
})
export class CmcEquipesResolverService implements Resolve<CmcEquipesSupplier> {

  constructor(private gds: GenericDatagridService,
              private tacheSvc: TachesService,
              private cmcSvc: ContratsMenusConvivesService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CmcEquipesSupplier> | Promise<CmcEquipesSupplier> | CmcEquipesSupplier => {

    const idContratMenuConvive: number = +route.parent.paramMap.get('idContratMenuConvive');
    const udpEquipeList$ = this.cmcSvc.getUdpEquipes(idContratMenuConvive);
    const cmcEquipeList$ = this.cmcSvc.getCmcEquipes(idContratMenuConvive);
    const tacheFabricationList$ =  this.tacheSvc.getAll();

    const all$ = combineLatest([udpEquipeList$, cmcEquipeList$, tacheFabricationList$]);

    const result$ = all$.pipe(
       map(response => {
         const udpEquipeList: UniteDeProduction__EquipeDTO[] = response[0].resultList;
         const cmcEquipeList: ContratMenuConvive__EquipeDTO[] = response[1].resultList;
         const tacheFabricationList: TacheDTO[]  = this.tacheSvc.filterByTypeTache(response[2].resultList, TYPE_TACHE.FABRICATION);
         return new CmcEquipesSupplier(tacheFabricationList, udpEquipeList, cmcEquipeList);
       })
    );


    return result$;
  };
}

export class CmcEquipesSupplier {

  tacheFabricationList: TacheDTO[];
  udpEquipeList: UniteDeProduction__EquipeDTO[];
  cmcEquipeList: ContratMenuConvive__EquipeDTO[];


  constructor(tacheFabricationList: TacheDTO[], udpEquipeList: UniteDeProduction__EquipeDTO[], cmcEquipeList: ContratMenuConvive__EquipeDTO[]) {
    this.tacheFabricationList = tacheFabricationList;
    this.udpEquipeList = udpEquipeList;
    this.cmcEquipeList = cmcEquipeList;
  }
}
