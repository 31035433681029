import {Component, Input, OnInit} from '@angular/core';
import {FormFieldBaseSupplier} from '../../../../../core/suppliers/form-fieldbase-supplier';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'yo-dynamic-form-field-message-bs',
  templateUrl: './dynamic-form-field-message-bs.component.html',
  styleUrls: ['./dynamic-form-field-message-bs.component.scss']
})
export class DynamicFormFieldMessageBsComponent implements OnInit {
  @Input() field: FormFieldBaseSupplier<any>;
  @Input() form: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Renvoie le contenu du champ 'message' du {@link FormFieldBaseSupplier} passé en paramètre.
   * @param field
   */
  message(field: FormFieldBaseSupplier<any>): string {
    return field.message;
  }
}
