import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'yo-pp',
  templateUrl: './pp.component.html',
  styleUrls: ['./pp.component.scss']
})
export class PpComponent implements OnInit {

  ppStatuts: MenuItem[];

  constructor() { }

  ngOnInit() {

    this.ppStatuts = [
      {label:'PLANS DE PRODUCTION', icon:'fas fa-file-invoice', routerLink:['plans-de-production']},
    ];

  }

}
