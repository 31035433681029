import {FamilleGemrcnDTO} from "../../../core/dtos/famille-gemrcn-dto";
import {MenuCompositionDTO} from "../../../core/dtos/menucomposition-dto";
import {ModeleNutritionnelGroupeGemrcnDTO} from "../../../core/dtos/modele-nutritionnel-groupe-gemrcn-dto";

export class RegleGemrcnSupplier {

  //@deprecated
  isOk:boolean;
  // @deprecated
  counted:number;

  familleGemrcn : FamilleGemrcnDTO;
  numerator : number;
  denominator : number;
  baseNumerator:number;
  baseDenominator:number;
  operator: string;

  modeleNutritionnelGroupeGemrcn:ModeleNutritionnelGroupeGemrcnDTO;

  choixDirigeOk:boolean;
  choixMultipleOk:boolean;

  //nb repas periode
  nrp:number;
  //nb repas modele nutri
  nrs:number;
  //ration nb_repas_periode/nb_repas modele nutri
  ratio_nrp_nrs:number;

  countChoixDirige:number;

  countChoixMultiple:number;

  //menus compos
  menusCompos : MenuCompositionDTO[];


  constructor(familleGemrcn:FamilleGemrcnDTO, numerator: number, denominator: number, operator: string,modeleNutritionnelGroupeGemrcn:ModeleNutritionnelGroupeGemrcnDTO){
    this.familleGemrcn = familleGemrcn;
    this.numerator = numerator;
    this.denominator = denominator;
    this.operator = operator;
    this.modeleNutritionnelGroupeGemrcn= modeleNutritionnelGroupeGemrcn;
}

}
