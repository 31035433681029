import {ObjectDTO} from "./object-dto";

export class ProduitAppellationDTO extends ObjectDTO {

//produit appellation
  produitDeclinaisonId: number;
  appellationId: number;
  actif:boolean;

//appellation
  appellationLibelle: string;
  appellationCode: string;
  appellationActif: string;

//produit declinaison
  produitDeclinaisonLibelle: string;
  produitDeclinaisonActif: boolean;

}
