import {Component, Input, OnInit} from '@angular/core';
import {BonReceptionDTO} from "../../../core/dtos/bon-reception-dto";
import {RECEPTION_STATUT} from "../../../core/constants";
import {BonReceptionLigneDTO} from "../../../core/dtos/bon-reception-ligne-dto";

@Component({
  selector: 'yo-statuts-reception',
  templateUrl: './statuts-reception.component.html',
  styleUrls: ['./statuts-reception.component.scss']
})
export class StatutsReceptionComponent implements OnInit {

  receptionStatutCode = RECEPTION_STATUT;

  @Input() bonReception : BonReceptionDTO;
  @Input() longLabel:boolean;
  @Input() bonReceptionLigne:BonReceptionLigneDTO;

  constructor() { }

  ngOnInit() {
  }

  getLabel(label:string){

    if(this.longLabel){
      return label;
    }

    return label.substring(0,1).toUpperCase();
  }

  isStatut(receptionStatutCode:string){

    return this.bonReception.receptionStatut.code === receptionStatutCode;
  }

  isStatutLigne(receptionStatutCode:string){

    return this.bonReceptionLigne.receptionStatutCode === receptionStatutCode;
  }

}
