import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {Subscription} from 'rxjs';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {MailHistoDTO} from '../../../core/dtos/mail-histo-dto';
import {catchError, switchMap} from 'rxjs/operators';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {MailHistoService} from '../../../core/services/entities/mail-histo.service';
import {BCF_MAIL_STATUT} from '../../../core/constants';

@Component({
  selector: 'yo-dialog-histo-mails',
  templateUrl: './dialog-histo-mails.component.html',
  styleUrls: ['./dialog-histo-mails.component.scss']
})
export class DialogHistoMailsComponent implements OnInit, OnDestroy {

  subMailsHisto: Subscription;
  displayDialog = false;
  bonCf: BonCfDTO;

  BONCF_MAIL_STATUT = BCF_MAIL_STATUT;

  mails: MailHistoDTO[];

  constructor(public utils: UtilsService,
              private bonCfSvc: BoncfService,
              public mailHistoSvc: MailHistoService,
              private gds: GenericDatagridService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subMailsHisto);
  }

  initData = () => {
    this.subMailsHisto = this.bonCfSvc.bonCf$.pipe(
      switchMap(bonCf => {
        this.bonCf = bonCf;
        return this.gds.search(this.bonCfSvc.filterMailsHisto(this.bonCf));
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.displayDialog = true;
      this.mails = response.resultList;
      // fix date
      if (!this.utils.isCollectionNullOrEmpty(this.mails)) {
        this.mails.forEach(item => {
          item.sentDate = this.utils.convertNumberDateToDate(item.sentDate, false);
          item.readDate = this.utils.convertNumberDateToDate(item.readDate, false);
        });
        this.bonCf.mailHisto = this.mails[0];
        this.bonCfSvc.announceMailHisto(this.bonCf);
      }
    });
  };

  closeDialog = () => {
    this.bonCf = null;
    this.mails = [];
    this.displayDialog = false;
  };

  getScrollPanelStyle = () => {
    if (window.innerHeight <= 768) {
      return {width: '100%', height: '540px'};
    } else if (window.innerHeight <= 1024) {
      return {width: '100%', height: '704px'};
    } else {
      return {width: '100%', height: '740px'};
    }

  };
}
