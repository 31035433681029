import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {WizardService} from "../../../core/services/wizard-service";
import {ModelesPlcService} from "../../../core/services/conditionnements/plc/modeles-plc.service";
import ModeleConditionnementPlcDTO from "../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {Subscription} from "rxjs";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";

@Component({
  selector: 'yo-wizard-configuration-step-8',
  templateUrl: './wizard-configuration-step-8.component.html',
  styleUrls: ['./wizard-configuration-step-8.component.scss']
})
export class WizardConfigurationStepEightComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  idSelectedSite: number;

  selectedMcp: ModeleConditionnementPlcDTO;

  sitePlaceholder: string = 'Site gestionnaire des modèles de point de livraison';

  mcpPlaceholder: string = 'Modèle point de livraison';

  dataSource: any;

  dataAlreadyChoosen: any;

  placeholderLabelModelePlc: string = "Libellé du modèle de point de livraison";

  labelModelPlc: string;

  modelesPlc: ModeleConditionnementPlcDTO[] = [];

  subMcp$: Subscription;

  modeSelected: string;

  idsPointsLivraisons: number[] = [];
  keysPointsLivraisons: string[] = [];

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly udpSvc: UnitesDeProductionService,
              private readonly utilsSvc: UtilsService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly wizardGlobalSvc: WizardService,
              private readonly mcpSvc: ModelesPlcService,
              private readonly plcSvc: PointDeLivraisonService) {
    this.currentStep = this.wizardSvc.STEP_TARGET_MODEL_PLC;
  }

  ngOnInit() {
    this.initDataAlreadyChoosenByUser();
    this.findAllLocalSites();
    this.findAllModelesPlc();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subMcp$);
  }

  initDataAlreadyChoosenByUser = (): void => {
    this.dataAlreadyChoosen = this.wizardSvc.getDataStepEight();
    if (this.dataAlreadyChoosen?.idSelectedSite)
      this.idSelectedSite = this.dataAlreadyChoosen.idSelectedSite;
    if (this.dataAlreadyChoosen?.labelModelPlc)
      this.labelModelPlc = this.dataAlreadyChoosen.labelModelPlc;
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep, idSelectedSite: this.idSelectedSite, labelModelPlc: this.labelModelPlc });
  }

  next = (): void => {
    let dataToSend: any = { currentStep: this.currentStep, id: this.dataAlreadyChoosen?.id, idSelectedSite: this.idSelectedSite, labelModelPlc: this.labelModelPlc };
    if (this.selectedMcp)
      dataToSend = { currentStep: this.currentStep, id: this.selectedMcp?.id, idSelectedSite: this.selectedMcp?.site?.id, labelModelPlc: this.selectedMcp?.libelle, idsPointsLivraisons: this.idsPointsLivraisons, keysPointsLivraisons: this.keysPointsLivraisons };
    this.onEmitNextStep.emit(dataToSend);
  }

  findAllLocalSites = (): SiteDTO[] => this.auth2Svc.utilisateur.siteListLocaux;

  findAllModelesPlc = (): void => {
    this.subMcp$ = this.mcpSvc.getAllModelesConditionnementsPlc()
      .subscribe(res => this.modelesPlc = res.resultList);
  }

  onChangeSite = ($event): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  onChangeMcp = ($event): void => {
    this.selectedMcp = $event.selectedItem;
    this.plcSvc.getPlcWithMcPlcList(this.selectedMcp.id)
      .toPromise()
      .then(res => {
        let pointsLivraisons: PointDeLivraisonDTO[] = res.resultList;
        this.idsPointsLivraisons = pointsLivraisons.map(plc => plc.id);
        this.keysPointsLivraisons = pointsLivraisons.map(plc => `${plc.id}{}${plc.prestation?.libelle}`);
      });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
