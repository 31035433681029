import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DropMenuInterface} from "./drop-menu-interface.component";

@Component({
  selector: 'yo-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss']
})
export class DropMenuComponent implements OnInit{

  @Input() rows: DropMenuInterface[] = [];

  @Output() close = new EventEmitter<boolean>();

  closeDropMenu = (): void => {
    this.close.emit(true);
  }

  ngOnInit(): void {
  }

  onChooseRow = (rowSelected: DropMenuInterface): void => {
    rowSelected.actionCallbackClick();
    this.closeDropMenu();
  }

}
