import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {StockMouvementStatutDTO} from "../../../../core/dtos/stock-mouvement-statut-dto";
import {StockMouvementStatutService} from "../../../../core/services/gestion-stock/stock-mouvement-statut.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";

@Component({
  selector: 'yo-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss']
})
export class StockMouvementStatutComponent implements OnInit, OnDestroy {

  hasStocks = false;

  displayDialog = false;

  subOpenDialogStatut: Subscription;

  statut: StockMouvementStatutDTO;

  form: FormGroup = new FormGroup({});

  entree: boolean = false;

  sortie: boolean = false;

  modifiable: boolean = true;

  dialogTitle: string = 'Modification d\'un statut de mouvement de stock';

  sitePlaceholder: string = 'Selectionner le site...';

  idSelectedSite: number;

  libelle: string;

  description: string;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private smsSvc: StockMouvementStatutService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initHasGestionStocks();
    this.openDialogStatutSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogStatut);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  openDialogStatutSubscription = (): void => {
    this.subOpenDialogStatut = this.smsSvc.openDialogStatut$
      .subscribe((s: StockMouvementStatutDTO) => {
        this.displayDialog = true;
        if (!s) {
          this.statut = new StockMouvementStatutDTO();
          this.statut.id = 0;
          this.dialogTitle = 'Création d\'un statut de mouvement de stock';
        } else {
          this.statut = Object.assign({}, s);
          this.idSelectedSite = this.statut.idSite;
          this.libelle = this.statut.libelle;
          this.description = this.statut.description;
          this.entree = this.statut.entree;
          this.sortie = this.statut.sortie;
          this.modifiable = this.statut.modifiable;
        }
      });
  };

  initHasGestionStocks = (): void => {
    this.auth2Svc.hasStocks$.subscribe(response => this.hasStocks = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.modifiable = false;
    this.libelle = '';
    this.description = '';
    this.sortie = false;
    this.entree = false;
  };

  canModify = (): boolean => {
    return this.hasStocks;
  };

  save = (): void => {
    if (this.form.valid) {
      this.statut.idSite = this.idSelectedSite;
      this.statut.libelle = this.libelle;
      this.statut.description = this.description;
      this.statut.entree = this.entree;
      this.statut.sortie = this.sortie;
      this.statut.modifiable = this.modifiable;
      this.smsSvc.saveSpecific(this.statut)
        .subscribe(response => {
          this.smsSvc.announceStatutSaved(response.one, this.statut.id !== undefined && this.statut.id !== null && this.statut.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le statut a été enregistré avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Le motif d'un mouvement de stock`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
