import {combineLatest, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Auth2Service} from '../core/services/security/auth2.service';
import {ModelesConditionnementsPlatsDeclinaisonsService} from '../core/services/conditionnements/modeles-conditionnements-plats-declinaison.service';
import {McpConditionnementsVariantesService} from '../core/services/conditionnements/mcp-conditionnements-variantes.service';
import {ModelesPlcService} from "../core/services/conditionnements/plc/modeles-plc.service";
import ModeleConditionnementPlcDTO from "../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import {ModelesPlcPrestationsService} from "../core/services/conditionnements/plc/conditionnement/prestation/modeles-plc-prestations.service";
import ModeleConditionnementPlc__PrestationDTO
  from "../core/dtos/conditionnement/plc/conditionnement/prestation/mc-plc-prestation-dto";
import {McPlcPrestaMcPlcMcCVService} from "../core/services/conditionnements/plc/conditionnement/plat/mc-plc-presta-mplc-mp-cv.service";
import McPlcPrestation__McPlc__McpCvDTO
  from "../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-prestation-mc-plc-mc-cv-dto";
import McPlc__McpCvDTO from "../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-mc-cv-dto";
import McPlcMcpCVService from "../core/services/conditionnements/plc/conditionnement/plat/mplc-mp-cv.service";

@Injectable()
export class ModelePlcResolverService implements Resolve<ModelePlcSupplier> {

  constructor(private auth2Svc: Auth2Service,
              private mplcPrestaSvc: ModelesPlcPrestationsService,
              private mcPlcPrestaMcPlcCVSvc: McPlcPrestaMcPlcMcCVService,
              private mcPlcMcpCVSvc: McPlcMcpCVService,
              private mcpdSvc: ModelesConditionnementsPlatsDeclinaisonsService,
              private mcpcvSvc: McpConditionnementsVariantesService,
              private mcPlcSvc: ModelesPlcService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModelePlcSupplier> | Promise<ModelePlcSupplier> | ModelePlcSupplier => {

    const supplier: ModelePlcSupplier = new ModelePlcSupplier();
    const idModeleConditionnementPlc: number = parseInt(route.params.idModeleConditionnementPlc, 10);

    if (idModeleConditionnementPlc) {
      const mcpclById$ = this.mcPlcSvc.findById(idModeleConditionnementPlc);
      const allMcpCvByMcplc$ = this.mcpcvSvc.getAllByIdMcplc(idModeleConditionnementPlc);
      const allMcplcPrestationsByMcplc$ = this.mplcPrestaSvc.getAllByIdMcplc(idModeleConditionnementPlc);
      const allMcPlcPresta__McPlc_McpCvByMcplc$ = this.mcPlcPrestaMcPlcCVSvc.getAllByIdMcplc(idModeleConditionnementPlc);
      const allMcPlcMcpCV$ = this.mcPlcMcpCVSvc.getAllByIdMcplc(idModeleConditionnementPlc);

      supplier.idModeleConditionnementPlc = idModeleConditionnementPlc;

      const all$ = combineLatest([mcpclById$, allMcpCvByMcplc$, allMcplcPrestationsByMcplc$, allMcPlcPresta__McPlc_McpCvByMcplc$, allMcPlcMcpCV$]);

      return all$
        .toPromise().then(data => {

          supplier.modelePlc = data[0].one;
          supplier.mcpConditionnementVariantesList = data[1].resultList;
          supplier.mcplcPrestations = data[2].resultList;
          supplier.mcPlcPrestaMcPlcCVList = data[3].resultList;
          supplier.mcPlcMcpCVList = data[4].resultList;
          return supplier;
        });
    }
    return of(supplier);
  };
}

export class ModelePlcSupplier {
  idModeleConditionnementPlc: number;
  modelePlc: ModeleConditionnementPlcDTO;
  mcpConditionnementVariantesList: ModeleConditionnementsPlatConditionnementsVariantesDTO[];
  mcplcPrestations: ModeleConditionnementPlc__PrestationDTO[];
  mcPlcPrestaMcPlcCVList: McPlcPrestation__McPlc__McpCvDTO[];
  mcPlcMcpCVList: McPlc__McpCvDTO[];
}

