import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TachePmsDto} from "../../../../core/dtos/pms/tache-pms-dto";
import {TypeTachePmsDto} from "../../../../core/dtos/pms/type-tache-pms-dto";
import {TachePmsService} from "../../../../core/services/pms/tache-pms.service";
import {TypeTachePmsService} from "../../../../core/services/pms/type-tache-pms.service";
import {TypeEquipementPmsService} from "../../../../core/services/pms/type-equipement-pms.service";
import json from "../../../../../assets/logo/logos.json";
import {ImagePmsDto} from "../../../../core/dtos/pms/image-pms-dto";
import {MotifNonConformiteDTO} from "../../../../core/dtos/motif-non-conformite-dto";
import {MotifNonConformiteService} from "../../../../core/services/conformite/motif-non-conformite.service";
import {TypeUtilisateurPmsService} from "../../../../core/services/pms/type-utilisateur-pms.service";
import {TypeUtilisateurPmsDto} from "../../../../core/dtos/pms/type-utilisateur-pms-dto";
import {OrdreTachePmsDto} from "../../../../core/dtos/pms/ordre-tache-pms-dto";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import DataSource from "devextreme/data/data_source";
import {DxTreeViewComponent} from "devextreme-angular";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {saveAs as fs_saveAs} from "file-saver";

@Component({
  selector: 'yo-pms-tache-dialog',
  templateUrl: './pms-tache-dialog.component.html',
  styleUrls: ['./pms-tache-dialog.component.scss']
})
export class PmsTacheDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  /**
   * Tâche sélectionnée à modifier ou à créer
   */
  tache: TachePmsDto;

  /**
   * Listes des types de tâche
   */
  typesTacheDataSource: DataSource;

  /**
   * Type tâche sélectionnée à modifier ou à créer
   */
  typeTacheSelected: TypeTachePmsDto;

  /**
   * Liste des types d'équipement
   */
  typesEquipement: TypeUtilisateurPmsDto[] = [];

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'une tâche';

  /**
   * Déclaration du chemin de l'icône à afficher
   */
  iconPath: ImagePmsDto;

  /**
   * Déclaratione et implémentation de nos données stockées sous format JSON
   */
  jsonFile: any = json;

  /**
   * Liste des icones
   */
  imagesPath: ImagePmsDto[] = [];

  /**
   * Liste des motifs de non conformité
   */
  motifs: MotifNonConformiteDTO[] = [];

  /**
   * Liste des types d'utilisateur
   */
  typesUtilisateur: TypeUtilisateurPmsDto[] = [];

  /**
   * Liste des ordres disponibles pour placer la nouvelle tâche
   */
  orders: OrdreTachePmsDto[] = [];

  /**
   * Ordre sélectionné
   */
  orderSelected: OrdreTachePmsDto;
  initOrder: OrdreTachePmsDto;
  maxOrder: number;

  /**
   * Couleur sélectionné
   */
  couleurSelected: string;

  /**
   * Liste des sites disponibles
   */
  sitesList: SiteDTO[] = [];

  /**
   * Site sélectionné
   */
  siteSelected: SiteDTO;

  /**
   * Fichier téléchargé
   */
  fileSelected: any[] = [];

  //Types équipement
  @ViewChild("treeViewTE", {static: false}) treeViewTE: DxTreeViewComponent;
  treeDataSourceTypeEquipement: DataSource;
  treeBoxValueTypeEquipement: number[];

  //Types utilisateur
  @ViewChild("treeViewTU", {static: false}) treeViewTU: DxTreeViewComponent;
  treeDataSourceTypeUtilisateur: DataSource;
  treeBoxValueTypeUtilisateur: number[];

  //Motifs non conforme
  @ViewChild("treeViewMNC", {static: false}) treeViewMNC: DxTreeViewComponent;
  treeDataSourceMotifNonConforme: DataSource;
  treeBoxValueMotifNonConforme: number[];

  TREE_BOX_VALUE_TYPE_EQUIPEMENT: string = 'TYPE_EQUIPEMENT';
  TREE_BOX_VALUE_TYPE_UTILISATEUR: string = 'TYPE_UTILISATEUR';
  TREE_BOX_VALUE_MOTIF_NON_CONFORMITE: string = 'MOTIF_NON_CONFORMITE';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private tachePmsSvc: TachePmsService,
              private typeTachePmsSvc: TypeTachePmsService,
              private typeEquipementPmsSvc: TypeEquipementPmsService,
              private typeUtilisateurPmsSvc: TypeUtilisateurPmsService,
              private motifNonConformitePmsSvc: MotifNonConformiteService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.openDialogVarianteSubscription();
    this.initTypesTachePms();
    this.initIconsPath();
    this.initAllOrders();
    this.initHasPms();
    this.findAllLocalSites();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  /**
   * Initialisation des sites locales
   */
  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  /**
   * Initialisation des icônes possibles
   */
  initIconsPath = (): void => {
    for (const icon of this.jsonFile.icons) {
      this.imagesPath.push({
        path: `${this.utils.getIconPath(icon + '.png')}`,
        value: icon + '.png'
      });
    }
  };

  /**
   * Initialisation des ordres possibles pour cette nouvelle tâche
   */
  initAllOrders = (): void => {
    this.tachePmsSvc.getAllOrders()
      .subscribe(response => {
        for (let i = 1; i <= response.resultList.length; i++) this.orders.push({value: i});

        this.maxOrder = response.resultList.reduce((a, b) => Math.max(a, b)) + 1;
        this.orders.push({value: this.maxOrder});
      })
  };

  /**
   * Initialisation des types de tâche possible
   */
  initTypesTachePms = (): void => {
    this.typeTachePmsSvc.getAll()
      .subscribe(response => {
        this.typesTacheDataSource = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
      });
  };

  /**
   * Initialisation des types d'équipement possible
   */
  initTypesEquipementPms = (): void => {
    this.typeEquipementPmsSvc.getAll()
      .subscribe(response => {
        this.typesEquipement = response.resultList;
        this.treeDataSourceTypeEquipement = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
        if (this.tache.typesEquipement) this.treeBoxValueTypeEquipement = this.tache.typesEquipement.map(t => t.id);
      });
  };

  /**
   * Initialisation des types d'utilisateur possible
   */
  initTypesUtilisateurPms = (): void => {
    //Récupération de tous les types d'utilisateur existants
    this.typeUtilisateurPmsSvc.getAll()
      .subscribe(response => {
        this.typesUtilisateur = response.resultList;
        //Types d'utilisateur que l'on va afficher
        this.treeDataSourceTypeUtilisateur = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
        if (this.tache.typesUtilisateur) this.treeBoxValueTypeUtilisateur = this.tache.typesUtilisateur.map(t => t.id);
      })
  };

  /**
   * Initialisation des motifs de non conformité possible
   */
  initMotifsNonConformitePms = (): void => {
    //Récupération de tous les motifs de non conformité existants
    this.motifNonConformitePmsSvc.getAll()
      .subscribe(response => {
        this.motifs = response.resultList;
        //Equipements que l'on va afficher
        this.treeDataSourceMotifNonConforme = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
        if (this.tache.motifsNonConformite) this.treeBoxValueMotifNonConforme = this.tache.motifsNonConformite.map(t => t.id);
      });
  };

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.tachePmsSvc.openDialog$
      .subscribe((t: TachePmsDto) => {
        this.displayDialog = true;
        if (!t) {
          this.forUpdate = false;

          this.tache = new TachePmsDto();
          this.tache.id = null;
          this.dialogTitle = 'Création d\'une tâche';

        } else {
          this.forUpdate = true;

          this.iconPath = new ImagePmsDto();
          this.iconPath.value = t.icone;
          this.iconPath.path = this.utils.getIconPath(t.icone.split(".")[0] + '.png');

          this.orderSelected = new OrdreTachePmsDto();
          this.initOrder = new OrdreTachePmsDto();
          this.orderSelected.value = t.ordre;
          this.initOrder.value = t.ordre;
          this.orders.sort((a: any, b: any) => a.value - b.value);

          if (t.site) this.siteSelected = t.site;
          if (t.typeTache) this.typeTacheSelected = t.typeTache;

          this.tache = t;
          this.dialogTitle = 'Modification d\'une tâche';
        }
        this.initTypesEquipementPms();
        this.initMotifsNonConformitePms();
        this.initTypesUtilisateurPms();
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.iconPath = null;
    this.siteSelected = null;
    this.typeTacheSelected = null;
    this.orderSelected = null;
    this.fileSelected = [];
    this.treeBoxValueTypeUtilisateur = null;
    this.treeBoxValueTypeEquipement = null;
    this.treeBoxValueMotifNonConforme = null;
  };

  canModify = (): boolean => {
    if (!this.tache || !this.tache.site || !this.tache.site.id) return this.hasPms;

    let response: boolean = false;

    if (this.tache &&this.tache.site)
        response = this.auth2Svc.isSiteLocal(this.tache.site.id);

    return response && this.hasPms;
  }

  /**
   * Méthode pour valider le formulaire
   */
  save = (): void => {
    if (this.form.valid) {
      this.tache.libelle = this.form.controls['libelle'].value;
      this.tache.couleur = this.form.controls['couleur'].value;
      this.tache.isPlannable = this.form.controls['isPlannable'].value;
      this.tache.lienExterne = this.form.controls['field'].value;
      this.tache.icone = this.iconPath.value;
      this.tache.typeTache = this.typeTacheSelected;

      this.tache.typesEquipement = [];
      if (this.treeBoxValueTypeEquipement)
        this.tache.typesEquipement = this.typesEquipement.filter(item => this.treeBoxValueTypeEquipement.find(t => t === item.id));

      this.tache.typesUtilisateur = [];
      if (this.treeBoxValueTypeUtilisateur)
        this.tache.typesUtilisateur = this.typesUtilisateur.filter(item => this.treeBoxValueTypeUtilisateur.find(t => t === item.id));

      this.tache.motifsNonConformite = [];
      if (this.treeBoxValueMotifNonConforme)
        this.tache.motifsNonConformite = this.motifs.filter(item => this.treeBoxValueMotifNonConforme.find(t => t === item.id));

      this.tache.ordre = this.orderSelected.value;
      if (this.forUpdate && this.tache.site || !this.forUpdate) this.tache.site = this.form.controls['site'].value;

      this.tachePmsSvc.save(this.tache, this.initOrder ? this.initOrder.value : this.maxOrder, this.tache.lienExterne)
        .subscribe(response => {
          this.tachePmsSvc.announceSaved(response.one, this.tache.id !== undefined && this.tache.id !== null && this.tache.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde de la tâche a été réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Initialisation du formulaire
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.tache ? this.tache.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
      couleur: new FormControl(this.tache ? this.tache.couleur : '', [Validators.required]),
      field: new FormControl(null),
      isPlannable: new FormControl(this.tache ? this.tache.isPlannable : false)
    });

    if (this.forUpdate && this.tache.site || !this.forUpdate)
      this.form.addControl('site', new FormControl(this.forUpdate ? this.tache.site : null));
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Tâches (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  /**
   * Méthode pour les multi-select avec les Tree-views
   */
  onDropDownBoxValueChanged = (e): void => {
    switch (e) {
      case this.TREE_BOX_VALUE_TYPE_EQUIPEMENT:
        this.updateSelection(this.treeViewTE && this.treeViewTE.instance, e);
        break;
      case this.TREE_BOX_VALUE_TYPE_UTILISATEUR:
        this.updateSelection(this.treeViewTU && this.treeViewTU.instance, e);
        break;
      case this.TREE_BOX_VALUE_MOTIF_NON_CONFORMITE:
        this.updateSelection(this.treeViewMNC && this.treeViewMNC.instance, e);
        break;
    }
  }

  onTreeViewReady = (e, value: string): void => this.updateSelection(e.component, value);

  updateSelection = (treeView, value): void => {
    if (!treeView) return;

    switch (value) {
      case this.TREE_BOX_VALUE_TYPE_EQUIPEMENT:
        if (!this.treeBoxValueTypeEquipement) treeView.unselectAll();
        if (this.treeBoxValueTypeEquipement) this.treeBoxValueTypeEquipement.forEach(value => treeView.selectItem(value));
        break;

      case this.TREE_BOX_VALUE_TYPE_UTILISATEUR:
        if (!this.treeBoxValueTypeUtilisateur) treeView.unselectAll();
        if (this.treeBoxValueTypeUtilisateur) this.treeBoxValueTypeUtilisateur.forEach(value => treeView.selectItem(value));
        break;

      case this.TREE_BOX_VALUE_MOTIF_NON_CONFORMITE:
        if (!this.treeBoxValueMotifNonConforme) treeView.unselectAll();
        if (this.treeBoxValueMotifNonConforme) this.treeBoxValueMotifNonConforme.forEach(value => treeView.selectItem(value));
        break;
    }
  };

  onTreeViewSelectionChanged = (e, value: string): void => {
    switch (value) {
      case this.TREE_BOX_VALUE_MOTIF_NON_CONFORMITE:
        this.treeBoxValueMotifNonConforme = e.component.getSelectedNodeKeys();
        break;

      case this.TREE_BOX_VALUE_TYPE_UTILISATEUR:
        this.treeBoxValueTypeUtilisateur = e.component.getSelectedNodeKeys();
        break;

      case this.TREE_BOX_VALUE_TYPE_EQUIPEMENT:
        this.treeBoxValueTypeEquipement = e.component.getSelectedNodeKeys();
        break;
    }
  };

  updateIcon = (e: any): void => {
    this.iconPath = e;
  };

  /**
   * Fonction qui récupère le fichier
   * @param event
   */
  onFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.form.get("field").setValue(event.target.files[0]);
    }
  };

  /**
   * Fonction qui permet de télécharger le document
   */
  printPDFLienExterne = async () => {
    const lienExterne: any = this.tache.lienExterne;
    const r = await fetch(lienExterne.base64);
    const blob = await r.blob();
    fs_saveAs(blob, lienExterne.filename);
  }
}
