/**
 * Created by romain on 12/02/2017.
 */

export class Credentials {
  username: string;
  password: string;


  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}
