import {ObjectDTO} from "../object-dto";

export class MarcheDTO extends ObjectDTO {

  libelle: string;
  code: string;
  descriptif: string;
  dateDebut: Date;
  dateFin: Date;
  actif: boolean
}
