import {ObjectDTO} from "./object-dto";
import {FonctionDTO} from "./fonction-dto";
import {AdresseDTO} from "./adresse-dto";

export class ContactDTO extends ObjectDTO {

  nom: string;
  prenom: string;
  civilite: string;
  telephone1: string;
  telephone2: string;
  fax: string;
  mail: string;
  adresse: AdresseDTO;
  fonction: FonctionDTO;
  commentaires: string;
  url: string;
  idEntityName: number;
  entityName: string;
  actif: boolean;

}
