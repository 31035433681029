import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, HAS_GESTIONMARCHE, OUTLET_SECONDARY} from '../core/constants';
import {MarcheGridComponent} from './marche-grid/marche-grid.component';
import {MarcheComponent} from './marche/marche.component';
import {MarcheResolverService} from './marche/marche-resolver.service';
import {FicheIdentiteMarcheComponent} from './marche/fiche-identite-marche/fiche-identite-marche.component';
import {LotsMarcheComponent} from './marche/lots-marche/lots-marche.component';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {DialogInfoLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/info/dialog-info-lot-marche.component';
import {InfoLotMarcheResolverService} from './marche/lots-marche/dialog-lot-marche/info/info-lot-marche-resolver.service';
import {DialogContextLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/context/dialog-context-lot-marche.component';
import {ContextLotMarcheResolverService} from './marche/lots-marche/dialog-lot-marche/context/context-lot-marche-resolver.service';
import {ArticlesLotMarcheResolverService} from './marche/lots-marche/dialog-lot-marche/articles/articles-lot-marche-resolver.service';
import {DialogArticlesLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/articles/dialog-articles-lot-marche.component';
import {DialogEngagementLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/engagement/dialog-engagement-lot-marche.component';
import {EngagementLotMarcheResolverService} from './marche/lots-marche/dialog-lot-marche/engagement/engagement-lot-marche-resolver.service';
import {TableauDeBordComponent} from './marche/tableau-de-bord/tableau-de-bord.component';
import {TableauDeBordResolverService} from './marche/tableau-de-bord/tableau-de-bord-resolver.service';
import {LotMarcheResolverService} from './marche/lots-marche/lot-marche-resolver.service';
import {IncoherenceLotMarcheResolverService} from './marche/lots-marche/dialog-lot-marche/incoherence/incoherence-lot-marche-resolver.service';
import {DialogIncoherenceLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/incoherence/dialog-incoherence-lot-marche.component';

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_MARCHE,
    component: MarcheGridComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_GESTIONMARCHE
  },
  {
    path: `${FP_ROUTES.GESTION_MARCHE}/marche/:idMarche`,
    component: MarcheComponent,
    outlet: OUTLET_SECONDARY,
    data: HAS_GESTIONMARCHE,
    runGuardsAndResolvers: 'always',
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    resolve: {
      marcheSupplier: MarcheResolverService,
    },
    children: [
      {
        path: 'fiche-identite',
        component: FicheIdentiteMarcheComponent,
        data: HAS_GESTIONMARCHE,
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'lots-marche',
        component: LotsMarcheComponent,
        data: HAS_GESTIONMARCHE,
        runGuardsAndResolvers: 'always',
        resolve: {
          marcheSupplier: LotMarcheResolverService,
        },
        children: [
          {
            path: ':idLotMarche/infos',
            data: HAS_GESTIONMARCHE,
            component: DialogInfoLotMarcheComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              infoLotMarcheSupplier: InfoLotMarcheResolverService
            }
          },
          {
            path: ':idLotMarche/context',
            data: HAS_GESTIONMARCHE,
            component: DialogContextLotMarcheComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              contextLotMarcheSupplier: ContextLotMarcheResolverService
            }
          },
          {
            path: ':idLotMarche/articles',
            data: HAS_GESTIONMARCHE,
            component: DialogArticlesLotMarcheComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              contextLotMarcheSupplier: ArticlesLotMarcheResolverService
            }
          },
          {
            path: ':idLotMarche/engagement',
            data: HAS_GESTIONMARCHE,
            component: DialogEngagementLotMarcheComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              engagementLotMarcheSupplier: EngagementLotMarcheResolverService
            }
          },
          {
            path: ':idLotMarche/incoherence',
            data: HAS_GESTIONMARCHE,
            component: DialogIncoherenceLotMarcheComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              incoheranceLotMarcheSupplier: IncoherenceLotMarcheResolverService
            }
          }
        ]
      },
      {
        path: 'tableau-de-bord',
        component: TableauDeBordComponent,
        data: HAS_GESTIONMARCHE,
        runGuardsAndResolvers: 'always',
        resolve: {
          marcheTableauDeBordSupplier: TableauDeBordResolverService
        }
      },
    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class GestionMarcheRoutingModule {
}
