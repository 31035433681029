import {ObjectDTO} from "./object-dto";

export class TaxeDTO extends ObjectDTO {


  code: string;
  libelle: string;
  actif: boolean;
  valeur:string;


}
