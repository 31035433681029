<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Association des modèles de plats aux prestations</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de gérer à travers cette étape la combinaison de modèles de plats existants (conditionnements/variantes) avec des prestations. Quand une association est réalisée,
        la grille du modèle de conditionnement de points de livraison client se trouvant en-dessous se met à jour, il faudra alors la compléter avant de passer à l'étape suivante.
      </p>
      <div class="row">
        <div class="col-lg-6">
          <div class="p-inputgroup input-group-sm ">
            <dx-tag-box
              [items]="mcpCvList"
              [(value)]="mcpCvSelectedList"
              valueExpr="value"
              itemTemplate="templateCV"
              tagTemplate="tagTemplate"
              multiline="false"
              [showSelectionControls]="true"
              [grouped]="true"
              applyValueMode="useButtons"
              width="100%"
            >
              <div *dxTemplate="let cv of 'templateCV'">
                {{ cv.label }}
              </div>
              <div *dxTemplate="let cv of 'tagTemplate'">
                <span *ngIf="cv.labelTag" class="badge-tag">{{ cv.labelTag }}</span>
              </div>
            </dx-tag-box>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="p-inputgroup input-group-sm ">
            <dx-tag-box
              [items]="prestationsList"
              [(value)]="prestationsSelectedList"
              multiline="false"
              displayExpr="libelle"
              [showSelectionControls]="true"
              applyValueMode="useButtons"
              width="100%"
            >
            </dx-tag-box>
          </div>
        </div>
      </div>
      <div class="row mg-t-15 mg-b-15">
        <div class="col-lg-12 text-center">
          <button (click)="updateGridFromBindings()"><i class="fa-solid fa-gear"></i> Mise à jour de la grille de configuration</button>
        </div>
      </div>
     <div class="row">
        <div class="col-lg-12 container-table">
          <dx-data-grid
            [dataSource]="gridParametrage"
            keyExpr="id"
            height="100%"
            width="100%"
            [hoverStateEnabled]="true"
            [allowColumnResizing]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"
            (onCellClick)="onCellClick($event)"
            (onCellPrepared)="onCellPrepared($event)"
            (onRowPrepared)="onRowPrepared($event)"
            columnResizingMode="widget"
            #grid>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxi-column dataField="mcpLabel" caption="Modèle de conditionnement plat" [width]="250"  [groupIndex]="0"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true" [fixed]="true" fixedPosition="left">
            </dxi-column>
            <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="170"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true" [fixed]="true" fixedPosition="left">
            </dxi-column>
            <dxi-column dataField="varianteLabel" caption="Variante" [width]="170"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true" [fixed]="true" fixedPosition="left">
            </dxi-column>
            <dxi-column *ngFor="let prestation of prestationsForGrid"
                        dataField="prestation.id"
                        cellTemplate="prestationCellTemplate"
                        [allowFiltering]="false"
                        [caption]="prestation.libelle">
            </dxi-column>

            <dxo-summary>
              <dxi-total-item
                *ngFor="let prestation of prestationsForGrid"
                name="SelectedRowsSummary"
                showInColumn="prestation">
              </dxi-total-item>
            </dxo-summary>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>

            <dxo-pager
              [showPageSizeSelector]="true"
              [showNavigationButtons]="false"
              [allowedPageSizes]="[20, 50, 100, 200]"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} conditionnements plc">
            </dxo-pager>

            <div [class]="cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ? 'prestation-enabled' : 'prestation-disabled'"
                 [title]="getToolTipPrestation(cell)"
                 *dxTemplate="let cell of 'prestationCellTemplate'">
              <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
            </div>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 9/13 : Association des modèles de plats aux prestations - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
