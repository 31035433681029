import {ObjectDTO} from "./object-dto";

export class TextureDTO  extends ObjectDTO{

  code: string;
  libelle: string;
  actif: boolean;

}

