import {ObjectDTO} from "./object-dto";

export class DecoupageRepasDTO  extends ObjectDTO{


  code: string;
  libelle: string;
  ordre: number;
  actif: boolean;

}
