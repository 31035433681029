import {PreferenceutilisateurDTO} from './dtos/preferenceutilisateur-dto';
import {SiteDTO} from './dtos/site-dto';
import {UserHasRight} from './services/security/user-has-right';

export class Utilisateur {
  id: number;

  /**
   * Tableau de tous les {@link Site}s visibles par l'utilisateur.
   */
  sites: any;
  /**
   * @deprecated : champ appelé à disparaître.
   */
  sitePrincipal: any;
  roles: any;
  licence: any;
  prenom: string;
  nom: string;
  username: string;
  preferences: PreferenceutilisateurDTO[];
  iddefaultenvironnement: number;
  profil: string;

  /**
   * Tableau des {@link Site}s locaux de l'utilisateur. C'est à dire la liste des {@link Site}s
   * qu'il peut modifier ou supprimer.
   */
  siteListLocaux: SiteDTO[];

  /**
   * Tableau des {@link Site}s référents de l'utilisateur. c'est à dire la liste des {@link Site}s
   * qu'il peut voir mais non modifier ou supprimer.
   */
  siteListReferents: SiteDTO[];

  /**
   * Défini quels modules l'utilisateur a droit
   */
  userHasRights:UserHasRight[];

}
