/**
 * Afficher un message dialg avec un systeme à 2 niveau de paragraphes
 */
export class DialogMsgSupplier {

  iconClick:string;
  title: string = '';
  logo: string = '';

  content: {
    intro: string,
    paragraphes: Paragraphe[]
  };


  constructor() {
    this.logo = 'fa fa-question-circle  yoni-color';
    this.content = {
      intro: '',
      paragraphes:[]
    };
  }
}

export class Paragraphe {
  title: string = '';
  lines: string[] = [];
  sousParagraphes : Paragraphe[]=[];
}

