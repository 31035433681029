import {animate, animation, style} from '@angular/animations';

/**
 * Effet de transition pour afficher un élément
 */
export const transAnimationEnter = animation(
  [style({
    opacity: '{{ opacityStart }}',
    maxHeight: '0px',
    visibility: 'hidden'
  }),
    animate('{{ time }}', style(
      {
        opacity: '{{opacityEnd}}',
        maxHeight: '{{maxHeight}}',
        visibility:'visible'
      }))]);
