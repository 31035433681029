import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {UniteDeProductionReglePrefereService} from '../../../../core/services/entities/unite-de-production__regle-prefere.service';
import {Subscription} from 'rxjs';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {map} from 'rxjs/operators';

import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {CatalogueAchatDTO} from "../../../../core/dtos/catalogue-achat-dto";
import CustomStore from "devextreme/data/custom_store";
import {GenericRequestSupplier, Sort} from "../../../../core/suppliers/generics/generic-request-supplier";
import {UniteDeProductionSupplier} from "../../unite-production-resolver.service";
import {DxDataGridComponent} from "devextreme-angular";
import {FournisseurDTO} from "../../../../core/dtos/fournisseur-d-t-o";
import {CataloguesAchatsService} from "../../../../core/services/entities/catalogues-achats.service";

import {Subject} from "rxjs/index";
import {debounceTime} from "rxjs/internal/operators";
import {GestionFournisseurService} from "../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service";
import {GroupeAchatCaViewModel} from "../../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {DevextremeService} from "../../../../core/services/technique/devextreme.service";
import {UniteDeProductionCatalogueAchatService} from "../../../../core/services/entities/unite-de-production__catalogue-achat.service";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-grille-articles-preferes',
  templateUrl: './grille-articles-preferes.component.html',
  styleUrls: ['./grille-articles-preferes.component.scss']
})
export class GrilleArticlesPreferesComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;

  customStore: CustomStore;
  grs: GenericRequestSupplier;
  catalogueAchatList: CatalogueAchatDTO[] = [];
  fournisseurList: FournisseurDTO[] = [];
  fournisseurListSelected: FournisseurDTO[] = [];
  produitDeclinaisonSearch: string ="";
  uniteDeProduction: UniteDeProductionDTO;
  stopPolling = false;
  totalRecords = 0;
  loading = false;

  subRoute: Subscription;
  subRefreshGrilleArticles: Subscription;
  subPollAvancementCalculArticlesPreferes: Subscription;
  subProduitDeclinaisonSearch :Subscription;
  subRefreshGridArticlesPreferes: Subscription;

  page = 0;

  subjectProduitDeclinaisonSearch: Subject<string> = new Subject<string>();
  produitDeclinaisonSearch$ = this.subjectProduitDeclinaisonSearch.asObservable();

  colorsDenrees: any = {};


  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private dxSvc: DevextremeService,
              private catalogueAchatSvc: CataloguesAchatsService,
              private udpReglePrefereSvc: UniteDeProductionReglePrefereService,
              private udpCaSvc: UniteDeProductionCatalogueAchatService,
              private gestionfournisseurSvc: GestionFournisseurService,
              private toastSvc: ToastService) {}

  ngOnInit(): void {
    this.subscriptionInitRoute();
    this.subcriptionSearchArticles();
    this.subscriptionRefreshGridArticlesPreferes();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subRefreshGrilleArticles);
    this.utils.unsubscribe(this.subPollAvancementCalculArticlesPreferes);
    this.utils.unsubscribe(this.subProduitDeclinaisonSearch);
    this.utils.unsubscribe(this.subRefreshGridArticlesPreferes);
  }

  subcriptionSearchArticles = (): void => {
    this.subProduitDeclinaisonSearch = this.produitDeclinaisonSearch$.pipe(map((i: any) => i),
      debounceTime(1000)).subscribe(searchValue => {
      this.produitDeclinaisonSearch = searchValue;
      this.grid.instance.refresh();
    });
  };

  subscriptionInitRoute = (): void => {
    this.subRoute = this.route.parent.parent.data
      .subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;

        this.gestionfournisseurSvc.getFournisseuByIdUdp(this.uniteDeProduction.id)
          .subscribe(response => {
            this.fournisseurList = response.resultList;
          });

        this.customStore = this.initCustomStore();
      });
  };

  initCustomStore = (): CustomStore => {

    const cs = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const sortProvider = sorts.find(filter => filter.path === 'fournisseur');
        const sortCodeApi = sorts.find(filter => filter.path === 'produitDeclinaisonCode');
        const sortDenree = sorts.find(filter => filter.path === 'produitDeclinaisonLibelle');
        const sortArticle = sorts.find(filter => filter.path === 'produitArticleLibelle');
        const sortRefArticle = sorts.find(filter => filter.path === 'produitArticle.reference');
        const sortFamilleProduit = sorts.find(filter => filter.path === 'libelleFamilleProduit');
        const sortPrixUf = sorts.find(filter => filter.path === 'groupeAchatCaList');
        const sortPrixKg = sorts.find(filter => filter.path === 'prix');

        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();

        const idFournisseurList = this.fournisseurListSelected.map(f => f.id);
        return this.catalogueAchatSvc.getCatalogueAchatByFilters({
          idsUniteProduction: [this.uniteDeProduction.id],
          idsFournisseurs: idFournisseurList,
          onlyParent: false,
          produitDeclineLabel: this.produitDeclinaisonSearch,
          page: page + 1,
          size: pageSize,
          sortByDenreeLabel: sortDenree ? sortDenree.dir : '',
          sortByArticleLabel: sortArticle ? sortArticle.dir : '',
          sortByProviderLabel: sortProvider ? sortProvider.dir : '',
          sortByPriceUF: sortPrixUf ? sortPrixUf.dir : '',
          sortByPriceKg: sortPrixKg ? sortPrixKg.dir : '',
          sortByFamilyProduct: sortFamilleProduit ? sortFamilleProduit.dir : '',
          sortByCodeApi: sortCodeApi ? sortCodeApi.dir : '',
          sortByRefProvider: sortRefArticle ? sortRefArticle.dir : '',
          checkOnlyActive: true
        })
          .toPromise()
          .then(response => {
            this.catalogueAchatList = response.resultList;
            this.initColorsDenrees();
            return {
              data: response.resultList,
              totalCount: response.totalElements
            }
        });

      },
      update: (key, values) => {
        return null;
      }
    });

    return cs;
  };

  updatePrefere = (rowData: CatalogueAchatDTO, groupeAchatCa: GroupeAchatCaViewModel): void => {
    // récuperation des catalogue acaht rattaché au meme produit déclinaison
    const codeProduitDeclinaison = rowData.produitDeclinaisonCode;
    let elts = this.catalogueAchatList.filter(item => item.produitDeclinaisonCode === codeProduitDeclinaison);
    if (elts) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Mise à jour de l'article préféré en cours...`);
      this.udpCaSvc.updatePrefere(groupeAchatCa.id, this.uniteDeProduction.id).subscribe(data => {
        this.grid.instance.refresh();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'article préféré pour la denrée ${rowData.produitDeclinaisonCode} ${rowData.produitDeclinaisonLibelle} a été modifié avec succès`);
      });
    }
  }

  announceRefreshGrid = (): void => {
    this.grid.instance.refresh();
  }

  changedSearchProduitDeclinaisonLibelle = (event): void => {
    this.subjectProduitDeclinaisonSearch.next(event);
  }

  changeFournisseurSelection = (event): void => {
    this.fournisseurListSelected = event.value;
    this.grid.instance.refresh();
  }

  isManuelFavoriteAppleColor = (row): boolean => {
    let isManuelFavorite = false;
    if (row.data) {
      isManuelFavorite = row.data.groupeAchatCaList.find(gca => gca.modificationManuelle);
    }
    return isManuelFavorite;
  }

  initColorsDenrees = (): void => {
    const pdCodeList: string[] = [... new Set(this.catalogueAchatList.map(ca => ca.produitDeclinaisonCode)) ];
    if (pdCodeList && pdCodeList.length) {
      let previousColor = '';
      let colors = ['red', 'blue', 'purple', 'black', 'green', 'orange', 'grey'];
      pdCodeList.forEach((code, idx) => {
        let currentColor = colors[idx % 6];
        do {
          this.colorsDenrees[code] = currentColor;
          currentColor = colors[idx % 6];
        } while (previousColor === currentColor);
        previousColor = currentColor;
      });
    }
  }

  subscriptionRefreshGridArticlesPreferes = (): void => {
    this.subRefreshGridArticlesPreferes = this.udpReglePrefereSvc.refreshGridArticlesPreferes$
      .subscribe(() => this.grid.instance.refresh());
  };
}
