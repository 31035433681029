<dx-popup
  [showTitle]="true"
  title="Création d'une association"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="dialog-line">
      <div class="label" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          #site
          [data]="findAllLocalSites()"
        >
        </yo-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Conditionnement <span class="danger-color">*</span>
      </div>
      <div class="value">
        <yo-lookup
          #conditionnement
          [data]="conditionnements"
        >
        </yo-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Variante <span class="danger-color">*</span></div>
      <div class="value">
        <yo-tagbox
          #variante
          [data]="variantes"
        >

        </yo-tagbox>
      </div>
    </div>

    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          type="submit"
          iconClass="fas fa-save"
          tooltip="Enregistrer"
          buttonClass="cta-success"
          [disabled]="!canSave()">
        </yo-button>
        <yo-button
          tooltip="Fermer"
          buttonClass="cta-delete"
          (onClick)="closeDialog()"
          iconClass="fas fa-times"
        ></yo-button>
      </div>
    </div>
  </form>
</dx-popup>
