import {ObjectDTO} from "./object-dto";

export class ModeleNutritionnel__FamilleGemrcnDTO extends ObjectDTO {

  idModeleNutritionnelPlanning:number;
  idFamilleNutritionnelle:number;
  numeroSemaine:number;
  ordre:number;

  libelleFamilleNutritionnelle:string;
  couleurFamilleNutritionnelle:string;


}
