<div class="header">
  <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="getTreeHelp()" [width]="800"></yo-help>
  <i class="fas fa-calendar-check mg-r-5"></i>{{sps?.workflowInstance?.libelle }} (Site
  : {{sps?.workflowInstance?.site.libelle }}), Sélection des menus

  <span class="floatright" [class.disable]="!valid">
      <span class="mg-r-5">
      <yo-button
        buttonClass="cta-success"
        type="submit"
        tabindex="-1"
        (onClick)="saveSelectionRepas()"
        tooltip="Enregistrer les modifications"
        iconClass="fas fa-save"
        [disabled]="!workflowSvc.canModifyCurrentStep(sps.workflowInstance)">
      </yo-button>
      </span>

    <!--PASSAGE À L'ETAPE SUIVANTE DU WORKFLOW-->
      <span [class.disable]="!valid ">
        <yo-button-next-steps [workflowInstance]="sps?.workflowInstance"
                              class="mg-r-40"
                              [play]="true"
                              (onError)="updateErrors($event)"
                              [runInput]="getRunInput()"></yo-button-next-steps>
      </span>

    </span>
</div>


<div class="mg-b-14">
  <p-steps [model]="items" [(activeIndex)]="activeIndex" (activeIndexChange)="changeStep($event)"
           [readonly]="false"></p-steps>
</div>

<div class="d-flex" [style.max-height.px]="utils.getWindowAvailableHeight(350)">

  <div class="d-flex flex-column">
    <h5 class="d-flex flex-row">
      <label class="badge badge-secondary mg-b-10">Points de livraison</label>
      <div class="icon-size">
        <i
          class="fas fa-sm mg-l-10 "
          [class.fa-lock-open]="activeIndex === STEP_SELECTION_PLCS.selectionBesoin"
          [class.fa-lock]="activeIndex === STEP_SELECTION_PLCS.sectionPeriode">
        </i>
      </div>
    </h5>

    <!-- CHOIX PLC -->
    <div class="mg-b-5" [style.visibility]="sps.selectedNodes.length === 0 ? 'visible': 'hidden'">
      <span class="danger-color">Aucun point de livraison n'a été sélectionné</span>
    </div>
    <div [style.max-height.px]="utils.getWindowAvailableHeight(500)" style="overflow: auto;">
      <p-tree [value]="sps.tree"
              class="mg-b-20"
              (selectionChange)="onChangeSelectionRepas($event)"
              [style]="{'width':'calc(32vw)'}"
              selectionMode="checkbox" [(selection)]="sps.selectedNodes">
      </p-tree>
    </div>

    <!-- EFFECTIF -->
    <div class="d-flex flex-column mg-t-14">
      <strong class="mg-b-5">Choisir l'effectif sur lequel sera fait le calcul :</strong>
      <p-radioButton name="effectif"
                     class="mg-b-5 mg-l-15"
                     [value]="TYPE_EFFECTIF.previsionnelle"
                     label="Effectif prévisionnel"
                     [(ngModel)]="sps.typeEffectif"
                     inputId="preopt1">
      </p-radioButton>
      <p-radioButton name="effectif"
                     class="mg-b-5 mg-l-15"
                     [value]="TYPE_EFFECTIF.fabrication"
                     label="Effectif de fabrication"
                     [(ngModel)]="sps.typeEffectif"
                     inputId="preopt2">
      </p-radioButton>
      <p-radioButton name="effectif"
                     class="mg-b-5 mg-l-15"
                     [value]="TYPE_EFFECTIF.facturation"
                     label="Effectif de facturation"
                     [(ngModel)]="sps.typeEffectif"
                     inputId="preopt3">
      </p-radioButton>
    </div>
  </div>

  <div *ngIf="activeIndex === STEP_SELECTION_PLCS.sectionPeriode" class="d-flex flex-column mg-l-20"
       [style.max-width.px]="utils.getWindowAvailableWidth(370)" style="overflow: auto;">
    <h5><label class="badge badge-secondary mg-b-10">Période</label></h5>
    <div class="d-flex flex-column center mg-b-10">
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(getPeriode(sps.selectedDates))">
      </div>
      <div class="d-flex flex-row">
        <div *ngIf="!utils.isNullOrEmpty(selectionInterfaceErrorMessages.ERR_SELECTION_PERIODE)">
          <span class="danger-color">{{selectionInterfaceErrorMessages.ERR_SELECTION_PERIODE}}</span>
        </div>
        <div>
          <span class="mg-l-10 danger-color">{{userMessage}}</span>
        </div>
      </div>
    </div>

    <p-calendar
      firstDayOfWeek="1"
      inline="true"
      class="mg-r-40 p-calendar-sm"
      dateFormat="dd/mm/yy"


      selectionMode="range"
      [numberOfMonths]="2"
      [readonlyInput]="true"
      [(ngModel)]="sps.selectedDates"
      (onSelect)="changeDates($event)"
      (onMonthChange)="changeDates($event)"
      (onYearChange)="changeDates($event)"
      [locale]="localeFr"
      [showIcon]="true"
      [showWeek]="true">
      <ng-template pTemplate="date" let-date>
        <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                       [dateCreationMenu]="utils.isDateInList(date,dateCreationMenu)"></yo-date-dispo>
      </ng-template>
    </p-calendar>

    <div class="d-flex">
      <div class="mg-r-10">
        <i class="fas fa-square checked-color mg-r-5 "></i>Menus validés
      </div>
      <div>
        <i class="fas fa-square save-color mg-r-5 "></i>Période sélectionnée
      </div>
    </div>


  </div>


</div>
<hr>
<div class="d-flex justify-content-center">
  <yo-button
    *ngIf="activeIndex === STEP_SELECTION_PLCS.sectionPeriode"
    label="Précédent"
    class="mg-r-5"
    buttonClass="cta-edit-or-watch"
    (onClick)="changeStep(STEP_SELECTION_PLCS.selectionBesoin)"
    tooltip="Vers sélection des repas"
    iconClass="fas fa-angle-left">
  </yo-button>

  <yo-button
    *ngIf="activeIndex === STEP_SELECTION_PLCS.selectionBesoin"
    iconPos="right"
    buttonClass="cta-edit-or-watch"
    label="Suivant"
    (onClick)="changeStep(STEP_SELECTION_PLCS.sectionPeriode)"
    tooltip="Vers sélection de la période"
    iconClass="fas fa-angle-right">
  </yo-button>

  <span [class.disable]="!valid">
          <yo-button-next-steps
            *ngIf="activeIndex === STEP_SELECTION_PLCS.sectionPeriode"
            [play]="false"
            [workflowInstance]="sps?.workflowInstance"
            (onError)="updateErrors($event)"
            [runInput]="getRunInput()"
          ></yo-button-next-steps>
        </span>
</div>
<div class="row">
  <div class="col-xs-12 erreurs-card">
    <ng-container *ngTemplateOutlet="tplErreurs;context:{erreurs:sps.erreurs}"></ng-container>
  </div>
</div>

<!--TEMPLATE ERREURS -->
<ng-template #tplErreurs let-erreurs="erreurs">
  <ng-container *ngIf="!utils.isCollectionNullOrEmpty(erreurs)">
    <div>
      <strong><i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>ATTENTION, vous ne pouvez pas
        passer à l'étape suivante tant que les erreurs ci-dessous ne sont pas résolues.</strong>
    </div>
    <div class="erreurs">
      <ng-container *ngFor="let erreur of erreurs; let i=index;">
        <div>
            <span class="font-14">
              <i class="fas fa-times danger-color mg-r-5"></i>
              <strong>{{erreur.message}}</strong><br/>
              <span>{{erreur.detail}}</span>
            </span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!--TEMPLATE AIDE -->
<ng-template #tplAide>

  <div class="card mg-t-10">
    <div class="card-header"><i class="fas fa-info-circle mg-r-5"></i>AIDE</div>
    <div class="card-body">
      <div class="font-12">
        <div>Niveau 1 = Unités de Production</div>
        <div>Niveau 2 = Contrats Menus</div>
        <div>Niveau 3 = Contrats Menus Convives</div>
        <div>Niveau 4 = Repas</div>
      </div>
    </div>
  </div>

</ng-template>

<!--TEMPLATE DATE CALENDRIER-->
<ng-template #tplDateCalendrier let-date="date" let-isRepasDate="isRepasDate">

  <ng-container *ngIf="isRepasDate">
    <div class="creation-menus-day" title="Il existe des repas sur ce jour" >{{date.day}}</div>
  </ng-container>

  <ng-container *ngIf="!isRepasDate">
    <div>{{date.day}}</div>
  </ng-container>

</ng-template>


