import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {PlanAlimentaireSupplier} from './plan-alimentaire-resolver.service';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'yo-plan-alimentaire',
  templateUrl: './plan-alimentaire.component.html',
  styleUrls: ['./plan-alimentaire.component.scss']
})
export class PlanAlimentaireComponent implements OnInit {

  subPlanAlimentaire: Subscription;
  pas: PlanAlimentaireSupplier;

  items: MenuItem[];
  activeItem: MenuItem;
  isTabsVisibled: boolean = true;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.planAlimentaireSubscription();
    this.activeItem = this.items[0];
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPlanAlimentaire);
  }

  planAlimentaireSubscription = () => {
    const tabFicheIdentite = {label: `Fiche d'identité`, icon: '', routerLink: 'fiche-identite'};
    // si pas de modele nutri, on n'accede qu'a la fiche identite
    this.items = [tabFicheIdentite];
    this.activeItem = this.items[0];
    this.subPlanAlimentaire = this.route.data
      .subscribe((data: { planAlimentaireSupplier: PlanAlimentaireSupplier }) => {
        this.pas = data.planAlimentaireSupplier;
        this.activeItem = this.items[0];
        // si modele nutri existe, on affiche tous les onglets
        if (this.utils.isNumberGt0(this.pas.planAlimentaire.id)) {
          const addItems = [
            {label: `Planning`, icon: '', routerLink: 'planning', visible: this.isTabsVisibled,},
            {label: `Fréquences GEMRCN`, icon: '', routerLink: 'modele-nutri', visible: this.isTabsVisibled},
            {label: `Familles de plats`, icon: '', routerLink: 'familles-plats', visible: this.isTabsVisibled},
          ];
          this.items = [tabFicheIdentite,...addItems];
        }
      });
  };
}
