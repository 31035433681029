import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ContratMenuConvive__EquipeDTO} from '../../dtos/contratmenuconvive-equipe-dto';
import {UniteDeProduction__EquipeDTO} from '../../dtos/unite-de-production__equipe';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {capitalize as _capitalize, find as _find, startCase as _startCase, uniqBy as _uniqBy} from 'lodash';
import {UniteDeProduction_Planning_EquipeDTO} from '../../dtos/unite_de_production__planning__equipe_d_t_o';
import * as moment from 'moment';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {catchError} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {SiteDTO} from '../../dtos/site-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {TacheDTO} from '../../dtos/tache-dto';
import {GenericManageDialogService} from "../generics/generic-manage-dialog.service";
import {CmcEquipeDialogSupplier} from "../../suppliers/gestionmenus/cmc-equipe-dialog-supplier";

@Injectable()
export class ContratsMenusConvivesEquipesService extends GenericHandler<ContratMenuConvive__EquipeDTO> {


  private subjectDisplayCmcEquipeDialog = new Subject<CmcEquipeDialogSupplier>();
  displayCmcEquipeDialog$ = this.subjectDisplayCmcEquipeDialog.asObservable();

  announceDisplayCmcEquipeDialog(data:CmcEquipeDialogSupplier) {
    this.subjectDisplayCmcEquipeDialog.next(data);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES EQUIPES PAR CONTRAT';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private gmdSvc: GenericManageDialogService,
              private httpSvc: HttpClient,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);


  }

  getEntityName(): string {
    return 'ContratMenuConvive__Equipe';
  }

  getFields(dto: ContratMenuConvive__EquipeDTO): FormFieldBaseSupplier<any>[] {
    return undefined;
  }

  getAllFromEnvironnement(): void {

  }

  /**
   * Mettre la selection par defaut du type de fab à partir d'un tableau de type de fab
   * @param {ContratMenuConvive__EquipeDTO} cmcEquipe
   * @param {TacheDTO[]} tacheFabricationList
   * @returns {TacheDTO}
   */
  initTacheFabrication(cmcEquipe: ContratMenuConvive__EquipeDTO, tacheFabricationList: TacheDTO[]): number {
    let selectedTacheFab: TacheDTO;

    if (!this.utils.isCollectionNullOrEmpty(tacheFabricationList)) {
      tacheFabricationList.map(item => {
        if (item.id === cmcEquipe.tacheId) {
          selectedTacheFab = item;
        }
      });
    }

    return selectedTacheFab?.id;
  }

  /**
   * Mettre la selection par defaut de l'equipe de prod à partir d'un tableau d'equipe de prod
   * @param {ContratMenuConvive__EquipeDTO} cmcEquipe
   * @param {TacheDTO[]} typeFabricationList
   * @returns {TacheDTO}
   */
  initUdpEquipe(cmcEquipe: ContratMenuConvive__EquipeDTO, equipeUdpList: UniteDeProduction__EquipeDTO[]): number {
    let selectedUdpEquipe: UniteDeProduction__EquipeDTO;

    if (!this.utils.isCollectionNullOrEmpty(equipeUdpList)) {
      equipeUdpList.map(item => {
        if (item.id === cmcEquipe.idUdpEquipe) {
          selectedUdpEquipe = item;
        }
      });
    }

    return selectedUdpEquipe?.id;
  }

  delete = (selectedRows: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/gestioncontrats/contrat-menu-convive/equipes/delete?ids=${selectedRows}`);
  }

  createEmptyDTO(): ContratMenuConvive__EquipeDTO {
    return this.createEmptyDTOWithCmc(undefined);
  }

  createEmptyDTOWithCmc(cmc: ContratMenuConviveDTO): ContratMenuConvive__EquipeDTO {
    const cmcEquipe = new ContratMenuConvive__EquipeDTO();
    cmcEquipe.site = new SiteDTO();

    if (!this.utils.isNullOrEmpty(cmc)) {
      cmcEquipe.idContratMenuConvive = cmc.id;
      cmcEquipe.contratMenuConviveLibelle = cmc.libelle;
      cmcEquipe.site.id = cmc.site.id;
      cmcEquipe.site.libelle = cmc.site.libelle;

    }

    return cmcEquipe;
  }

  /**
   * Récupérer les fabrications qui correspondent aux jours actifs du contrat menu convive
   * Note : Les fabs doivent être actives pour être récupérées
   * @param {number} idUdpEquipe
   * @returns {UniteDeProduction_Planning_EquipeDTO[]}
   */
  getFabrications(cmc: ContratMenuConviveDTO, cmcEquipe: ContratMenuConvive__EquipeDTO, udpEquipeList: UniteDeProduction__EquipeDTO[]): UniteDeProduction_Planning_EquipeDTO[] {
    let fabs: UniteDeProduction_Planning_EquipeDTO[] = [];

    if (!this.utils.isNullOrEmpty(cmcEquipe)) {
      let udpEquipe = _find(udpEquipeList, {'id': cmcEquipe.idUdpEquipe});

      if (!this.utils.isNullOrEmpty(udpEquipe)) {
        // Seules les fabs actives doivent être récupérées !
        fabs = udpEquipe.uniteDeProduction_planning_equipes.filter(uniteDeProduction__FabricationDTO => uniteDeProduction__FabricationDTO.actif == true);
        return fabs;
      }

    }

    return fabs;
  }


  getJourFabName(jourConsoValue: number, joursDecalage: number): string {

    if (joursDecalage === 0) {
      return 'Jour même';
    }

    moment.locale('fr');
    return `J-${joursDecalage} (${_capitalize(moment().isoWeekday(jourConsoValue).add(-joursDecalage, 'day').format('dddd'))})`;
  }

  /**
   * Sauvegarde des equipes du contrat menu convive
   * @param contratMenuConvive
   * @return {Observable<any>}
   *
   */
  saveCmcEquipe(cmcEquipe: ContratMenuConvive__EquipeDTO): Observable<ResponseWrapper<ContratMenuConvive__EquipeDTO>> {
    if (cmcEquipe)
      return this.httpSvc.post<ResponseWrapper<ContratMenuConvive__EquipeDTO>>('dolrest/gestioncontrats/contrat-menu-convive/equipes/save', cmcEquipe);
    return of(null);
  }

  /**
   * Grouper les unités de production à partir des equipes
   * @param {UniteDeProduction__EquipeDTO[]} udpEquipeList
   * @returns {undefined}
   */
  initUdpList(udpEquipeList: UniteDeProduction__EquipeDTO[]) {

    let udps = [];

    if (!this.utils.isCollectionNullOrEmpty(udpEquipeList)) {
      udps = udpEquipeList.map(item => {
        return {id: item.idUniteDeProduction, label: item.uniteDeProductionLibelle, ateliers: []};
      });

      udps = _uniqBy(udps, 'id');

      udps.map(udp => {
        udp.ateliers = this.getAteliers(udp.id, udpEquipeList);
        udp.ateliers.map(item => {
          item.equipes = this.getEquipes(item.id, udpEquipeList);
        });
      })
    }
    return udps;
  }

  /**
   * Renvoyer les ateliers de l'unité de production
   * @param {number} idUniteDeProduction
   */
  getAteliers(idUniteDeProduction: number, udpEquipeList: UniteDeProduction__EquipeDTO[]): { id, label }[] {

    let ateliers: { id, label }[] = udpEquipeList
      .filter(item => item.idUniteDeProduction === idUniteDeProduction)
      .map(equipe => {
        return {id: equipe.idUdpZoneDeProduction, label: equipe.zoneDeProductionLibelle}
      });

    ateliers = _uniqBy(ateliers, 'id');

    // console.log('ateliers', ateliers);
    return ateliers;
  }

  /**
   * Renvoyer les ateliers de l'unité de production
   * @param {number} idUniteDeProduction
   */
  getEquipes(idUdpZoneDeProduction: number, udpEquipeList: UniteDeProduction__EquipeDTO[]) {

    let equipes: UniteDeProduction__EquipeDTO[] = udpEquipeList
      .filter(item => item.idUdpZoneDeProduction === idUdpZoneDeProduction);

    equipes = _uniqBy(equipes, 'id');

    return equipes;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }


  fetchFiltersForOffreAlimentairePrestationEquipe = (idOffreAlimentairePrestation: number): Observable<any> =>
    this.httpSvc.get(`dolrest/gestioncontrats/fetchFiltersForOffreAlimentairePrestationEquipe?idOffreAlimentairePrestation=${idOffreAlimentairePrestation}`);


}

