import {Injectable} from '@angular/core';
import {FORMATS_FICHIERS_KEYS} from "../constants";

@Injectable()
export class ImpressionService {

  constructor() { }

  getFilename(filename:string,formatFichier : string) : string{

    let extension = 'xls';
    if(formatFichier === FORMATS_FICHIERS_KEYS.PDF){
      extension = 'pdf';
    }else if(formatFichier === FORMATS_FICHIERS_KEYS.WORD){
      extension = 'doc';
    }

    return `${filename}.${extension}`;
  }

}
