<p-dialog (onHide)="closeDialog()"
          [dismissableMask]="false"
          [modal]="true"
          position="top"
          [style]="{ 'max-width': '90vw', 'max-height': '70vh'}"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="displayDialog"
          appendTo="body">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <ng-container *ngTemplateOutlet="tplDialogHeader;context:{bonReception:bonReception}"></ng-container>
    </div>
  </ng-template>


  <dx-data-grid #grid
                [dataSource]="lignesBonReception"
                [height]="utils.scrollHeightContent(400)"
                [width]="'100%'"
                [showColumnLines]="true"
                columnResizingMode="widget"
                [showRowLines]="true"
                [allowColumnResizing]="true"
                [allowColumnReordering]="true"
                (onCellPrepared)="onCellPrepared($event)"
                (onRowUpdated)="onRowUpdated($event)"
                (onCellClick)="setColumnOnFocus($event)"
                [showBorders]="true">

    <!--    COLONNES-->

    <dxi-column dataField="action"
                caption="Actions"
                cellTemplate="actionCellTemplate"
                [allowEditing]="false"
                width="50">
    </dxi-column>


    <dxi-column dataField="denominationArticle"
                [groupIndex]="0"
                caption="Dénomination"
                groupCellTemplate="denominationArticlegroupCellTemplate"
                [allowEditing]="false"
                cellTemplate="denominationArticleCellTemplate">
    </dxi-column>

    <dxi-column dataField="numeroLotInterne"
                headerCellTemplate="updateHeaderCellTemplate"
                caption="Numéro de lot interne"
                [visible]="false"
                [allowEditing]="false"
                width="200">
    </dxi-column>

    <dxi-column dataField="numeroLotFabriquant"
                headerCellTemplate="updateHeaderCellTemplate"
                [allowEditing]="!isDisabled()"
                caption="Numéro de Lot du fabriquant"
                width="200">
    </dxi-column>

    <dxi-column dataField="quantiteRecue"
                headerCellTemplate="updateHeaderCellTemplate"
                [allowEditing]="!isDisabled()"
                name="quantiteRecueUC"
                cellTemplate="quantiteRecuCellTemplate"
                caption="Qté Lot reçue en UC"
                width="120">
    </dxi-column>

    <dxi-column dataField="quantiteRecue"
                name="quantiteRecueUF"
                [allowEditing]="false"
                cellTemplate="quantiteRecuUFCellTemplate"
                caption="Qté Lot reçue en UF"
                width="120">
    </dxi-column>

    <dxi-column dataField="prixUnitaireFacture"
                headerCellTemplate="updateHeaderCellTemplate"
                cellTemplate="prixUnitaireFactureCellTemplate"
                caption="Prix Unitaire en UF"
                [allowEditing]="!isDisabled()"
                width="140">
    </dxi-column>


    <dxi-column dataField="totalFacture"
                headerCellTemplate="updateHeaderCellTemplate"
                cellTemplate="totalFactureCellTemplate"
                caption="Total HT Facturé"
                [allowEditing]="false"
                width="100">
    </dxi-column>

    <dxi-column dataField="idZds"
                headerCellTemplate="mandatoryHeaderCellTemplate"
                caption="Zone de stockage"
                [allowEditing]="!isDisabled()"
                [width]="200">
      <dxo-lookup [dataSource]="udpZdsList"
                  displayExpr="libelleZoneDeStockage"
                  valueExpr="idZoneDeStockage">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="dxAppellationList"
                headerCellTemplate="updateHeaderCellTemplate"

                caption="Appellation"
                [allowEditing]="!isDisabled()"
                editCellTemplate="appellationEditorCellTemplate"
                [cellTemplate]="cellTemplate"
                [width]="200">
      <dxo-lookup
        [dataSource]="appelationList"
        displayExpr="libelle"
        valueExpr="id">
      </dxo-lookup>
    </dxi-column>


    <dxi-column dataField="idOrigine"
                headerCellTemplate="updateHeaderCellTemplate"
                caption="Origine"
                [allowEditing]="!isDisabled()"
                cellTemplate="origineCellTemplate"
                [width]="200">
      <dxo-lookup [dataSource]="origineList"
                  displayExpr="libelle"
                  valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="dxMotifNonConfoList"
                headerCellTemplate="updateHeaderCellTemplate"
                caption="Motifs de non conformité"
                [cellTemplate]="cellTemplate"
                editCellTemplate="motifNonConfoEditorCellTemplate"
                [allowEditing]="!isDisabled()"
                [width]="200">
      <dxo-lookup
        [dataSource]="motifNonConformiteList"
        displayExpr="libelle"
        valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="dxLitigeList"
                headerCellTemplate="updateHeaderCellTemplate"
                caption="Litiges"
                [cellTemplate]="cellTemplate"
                editCellTemplate="litigeEditorCellTemplate"
                [allowEditing]="!isDisabled()"
                [width]="200">
      <dxo-lookup
        [dataSource]="litigeList"
        displayExpr="libelle"
        valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="dlc"
                headerCellTemplate="updateHeaderCellTemplate"
                caption="DLC"
                [allowEditing]="!isDisabled()"
                width="100">
    </dxi-column>

    <dxi-column dataField="temperatureCelsius"
                headerCellTemplate="updateHeaderCellTemplate"
                dataType="number"
                caption="Temperature"
                [allowEditing]="!isDisabled()"
                width="100">
    </dxi-column>

    <dxi-column dataField="remarque"
                caption="Remarque"
                [allowEditing]="!isDisabled()"
                width="500">
    </dxi-column>
    <div class="d-flex justify-content-between mg-b-10">
      <label class="label-produit-carne mg-r-5">Origine </label>
      <input class="form-control form-control-sm mg-l-5"
             [readOnly]="isDisabled()"
             maxlength="200"
             size="10"
             id="origine"
             [(ngModel)]="selectedBonReceptionLigne.origine"/>
    </div>


    <dxi-column dataField="lotManuel"
                sortOrder="asc"
                [visible]="false"
                [allowHiding]="true"
                [allowEditing]="false"
                width="50"
                caption="Lot additionel">
    </dxi-column>

    <!--  OPTION TABLE-->
    <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>
    <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
    <dxo-grouping #expand [allowCollapsing]="false" [autoExpandAll]="true"></dxo-grouping>

    <!-- TEMPLATE HEADER-->
    <div *dxTemplate="let cell of 'mandatoryHeaderCellTemplate'">
      <div [title]="'Colonne obligatoire : ' + cell.column.caption">
        {{cell.column.caption}}
      </div>
    </div>

    <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
      <div [title]="'Colonne modifiable : ' + cell.column.caption">
        {{cell.column.caption}}
      </div>
    </div>
    <!-- TEMPLATE COLONNE-->

    <div *dxTemplate="let cell of 'actionCellTemplate'" class="custom-button-container">
      <ng-container
        *ngIf="displayEdit && bonReception.receptionStatut.code !==  receptionStatutCode.RECEPTION_PARTIELLE && bonReception.receptionStatut.code !== receptionStatutCode.TERMINE ">
        <yo-button
          *ngIf="!cell.row.data.lotManuel"
          iconClass="fas fa-plus"
          buttonClass="cta-inner-secondary"
          (onClick)="addLotArticle(cell.row.data)"
          tooltip="Ajouter un lot pour cet article"
          [disabled]="isDisabled()">
        </yo-button>

        <ng-container *ngIf="displayEdit && cell.row.data.lotManuel" class="custom-button-container">
          <yo-button
            iconClass="fas fa-times"
            buttonClass="cta-inner-delete"
            (onClick)="bonReceptionLigneSvc.deleteLotArticle(cell.row.data)"
            tooltip="Supprimer ce lot"
            [disabled]="isDisabled()">
          </yo-button>
        </ng-container>
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'denominationArticleCellTemplate'"
         [class.justify-content-start]="!cell.row.data.lotManuel"
         [class.justify-content-end]="cell.row.data.lotManuel"
         class="d-flex align-items-center">


      <span *ngIf="cell.row.data.ratioUniteDeStockageUniteBase === 0 && !cell.row.data.lotManuel">
        <i class="fas fa-exclamation-triangle danger-color space"
           title="Le ratio d'unité de stockage de cet article est égal à 0">
        </i>
      </span>

      {{!cell.row.data.lotManuel ? cell.row.data.denominationArticle : ''}}
    </div>

    <div *dxTemplate="let cell of 'denominationArticlegroupCellTemplate'">

      <!--      DENOMINATION-->
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
        <span class="fa-stack mg-r-10" [title]="getTooltipIcon(cell.data.items[0].receptionStatutCode)">
              <i class="far fa-square fa-stack-2x"
                 [class.color-nouveau]="cell.data.items[0].receptionStatutCode === receptionStatutCode.NOUVEAU"
                 [class.color-en-cours]="cell.data.items[0].receptionStatutCode === receptionStatutCode.EN_COURS"
                 [class.color-partiel]="cell.data.items[0].receptionStatutCode === receptionStatutCode.RECEPTION_PARTIELLE"
                 [class.color-termine]="cell.data.items[0].receptionStatutCode === receptionStatutCode.TERMINE"></i>
              <strong *ngIf="cell.data.items[0].receptionStatutCode === receptionStatutCode.NOUVEAU"
                      class="fa-stack-1x color-nouveau">N</strong>
              <strong *ngIf="cell.data.items[0].receptionStatutCode === receptionStatutCode.EN_COURS"
                      class="fa-stack-1x color-en-cours">E</strong>
              <strong *ngIf="cell.data.items[0].receptionStatutCode === receptionStatutCode.RECEPTION_PARTIELLE"
                      class="fa-stack-1x color-partiel">R</strong>
              <strong *ngIf="cell.data.items[0].receptionStatutCode === receptionStatutCode.TERMINE"
                      class="fa-stack-1x color-termine">T</strong>
        </span>
          <p>{{ cell.value}}</p>
          <span class="mg-l-5" *ngIf="cell.row.data.items[0].ratioUniteDeStockageUniteBase === 0">
            <i class="fas fa-exclamation-triangle danger-color space"
               title="Le ratio d'unité de stockage de cet article est égal à 0">
            </i>
           </span>
          <span class="mg-l-5">
          <yo-info-lotmarche-article [uniteDeProductionId]="cell.row.data.items[0].idUniteDeProduction"
                                     [lotMarchePdLumpCa]="cell.row.data.items[0].lmPdUpCa"></yo-info-lotmarche-article>
          </span>
          <div>

            <label *ngIf="cell.data.items[0].produitCarne"
                   (click)="openDialogProduitCarne($event, cell.row.data.items[0])"
                   title="Informations supplémentaires pour les produits carnés"
                   class="badge mg-l-5 cursor badge-danger">

              <div class="i-size">V</div>
            </label>
          </div>
        </div>

        <!--        QUANTITE-->
        <div class="d-flex flex-row align-items-center">
          <div class="d-flex flex-row  align-items-center mg-r-10">
            <p class="mg-r-5"> Qté Commandée : </p>
            <span class="mg-r-5">
              {{cell.data.items[0].quantiteCommandee}}
            </span>
            <span class="mg-r-5">
              {{cell.data.items[0].uniteDeCommande}}
            </span>
          </div>

          <div class="d-flex flex-row align-items-center mg-r-10">
            <p class="mg-r-5"> Qté déjà réceptionnée : </p>
            <span class="mg-r-5">
              {{cell.data.items[0].quantiteDejaReceptionnee}}
            </span>
            <span class="mg-r-5">
              {{cell.data.items[0].uniteDeCommande}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let cell of 'origineCellTemplate'"
         class="d-flex justify-content-center align-items-center">
      {{cell.row.data.origine?.libelle}}
    </div>

    <div *dxTemplate="let item of 'origineEditCellTemplate'">
    </div>

    <div *dxTemplate="let cell of 'quantiteRecuCellTemplate'"
         class="d-flex justify-content-center align-items-center">
      <span class=" mg-r-5">{{cell?.value}}</span>
      <span [title]="cell.row.data.uniteDeCommande">{{cell.row.data.uniteDeCommandeAbreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'quantiteRecuUFCellTemplate'"
         class="d-flex justify-content-center align-items-center">
      <span class=" mg-r-5">{{getQuantiteRecuUF(cell.row.data)}}</span>
      <span [title]="cell.row.data.uniteDeFacturation">{{cell.row.data.uniteDeFacturationAbreviation}}</span>
    </div>


    <div *dxTemplate="let cell of 'prixUnitaireFactureCellTemplate'">
      <span>{{cell?.value | currency:'EUR':'symbol':'1.4-4'  }}</span>/<span
      [title]="cell.row.data.uniteDeFacturation">{{cell.row.data.uniteDeFacturationAbreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'totalFactureCellTemplate'">
      <span>{{cell?.value | currency:'EUR':'symbol':'1.4-4'  }}</span>
    </div>

    <div *dxTemplate="let cell of 'dlcCellTemplate'">
      <p-calendar
        appendTo="body"
        [locale]="fr"
        firstDayOfWeek="1"

        showButtonBar="true"
        class="vertical-align"
        [(ngModel)]="cell.value"
        dateFormat="dd/mm/yy"
        readonlyInput="true"
        [showWeek]="true"
        [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}"
        [disabled]="isDisabled()">
      </p-calendar>
    </div>

    <div *dxTemplate="let cell of 'prixUnitaireFactureTemplate'"
         class="d-flex justify-content-center align-items-center">

      <span class=" mg-r-5">{{cell.value}}</span>
      <div class="input-group-append" [title]="cell.row.data.uniteDeFacturation">€
        /  {{cell.row.data.uniteDeFacturationAbreviation}}</div>
    </div>


    <div *dxTemplate="let cellInfo of 'appellationEditorCellTemplate'">
      <dx-tag-box
        [dataSource]="appelationList"
        [value]="cellInfo.value"
        [width]="350"
        displayExpr="libelle"
        maxDisplayedTags="5"
        [showSelectionControls]="true"
        [showMultiTagOnly]="false"
        applyValueMode="instantly"
        [searchEnabled]="true"
        (onValueChanged)="cellInfo.setValue($event.value)">
        <!--        itemTemplate="customItem">-->

        <div *dxTemplate="let data of 'customItem'">
          <div class="d-flex justify-content-between align-items-stretch">
            <p class="libelle-appellation">{{data.libelle}}</p>
            <yo-img-entity [displayNoImage]="false"
                           [ordre]="1"
                           [entityName]="appellationSvc.getEntityName()"
                           [height]="22"
                           [refresh]="1"
                           [openable]="true"
                           format="thumb"
                           [entityId]="data.id"
                           [title]="data.libelle">
            </yo-img-entity>
          </div>
        </div>

      </dx-tag-box>
    </div>

    <div *dxTemplate="let cellInfo of 'motifNonConfoEditorCellTemplate'">
      <dx-tag-box
        [dataSource]="motifNonConformiteList"
        [value]="cellInfo.value"
        [width]="350"
        displayExpr="libelle"
        maxDisplayedTags="5"
        [showSelectionControls]="true"
        [showMultiTagOnly]="false"
        applyValueMode="instantly"
        [searchEnabled]="true"
        (onValueChanged)="cellInfo.setValue($event.value)">
      </dx-tag-box>
    </div>

    <div *dxTemplate="let cellInfo of 'litigeEditorCellTemplate'">
      <dx-tag-box
        [dataSource]="litigeList"
        [value]="cellInfo.value"
        [width]="350"
        displayExpr="libelle"
        maxDisplayedTags="5"
        [showSelectionControls]="true"
        [showMultiTagOnly]="false"
        applyValueMode="instantly"
        [searchEnabled]="true"
        (onValueChanged)="cellInfo.setValue($event.value)">
      </dx-tag-box>
    </div>

  </dx-data-grid>

  <yo-legend-grid-dev-extreme
    [modificationManuelle]="false"
    [colonneModifiable]="true"
    [colonneInformations]="false"
    [colonneObligatoire]="true">
  </yo-legend-grid-dev-extreme>

  <div class="d-inline-block float-right mg-t-15" *ngIf="lignesBonReception && lignesBonReception.length > 0">
    <div class="m-0 dx-field">
      <div class="dx-field-label">
        <strong>Frais de Port</strong>
      </div>
      <div class="dx-field-value">
        <dx-number-box [class]="isDisabled() ? 'border-0' : ''" format="#0.###0 €"
                       [value]="bonReception.francoDePortCout" [step]="0.0001"
                       (onValueChanged)="updateFrancoPort($event)"
        ></dx-number-box>
      </div>
    </div>
    <div class="m-0 dx-field">
      <div class="dx-field-label">
        <strong>Montant HT</strong>
      </div>
      <div class="dx-field-value">
        <dx-number-box class="border-0" format="#0.###0 €" [value]="bonReception.montantTotalHT"
                       [readOnly]="true"></dx-number-box>
      </div>
    </div>
    <div class="m-0 dx-field">
      <div class="dx-field-label">
        <strong>TVA</strong>
      </div>
      <div class="dx-field-value">
        <dx-number-box class="border-0" format="#0.###0 €"
                       [value]="(bonReception.montantTotalTTC - bonReception.montantTotalHT - bonReception.francoDePortCout)"
                       [readOnly]="true"></dx-number-box>
      </div>
    </div>
    <div class="m-0 dx-field">
      <div class="dx-field-label">
        <strong>Montant TTC</strong>
      </div>
      <div class="dx-field-value">
        <dx-number-box class="border-0" format="#0.###0 €" [value]="bonReception.montantTotalTTC"
                       [readOnly]="true"></dx-number-box>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <!--    BOUTONS D'ACTIONS-->
    <ng-container *ngTemplateOutlet="tplButtonsReception;context:{bonReception:bonReception}"></ng-container>
  </ng-template>

</p-dialog>

<!--TEMPLATE HEADER DIALOG-->
<ng-template #tplDialogHeader let-bonReception="bonReception">
  <ng-container *ngIf="bonReception">
      <span class="mg-r-5">
        <yo-statuts-reception [bonReception]="bonReception" [longLabel]="true"></yo-statuts-reception>
      </span>

    <span class="mg-r-5 available-invoice" *ngIf="numberInvoice">
      Associé à la facture n°{{ numberInvoice }}
    </span>

    <span class="mg-r-5">
        - Bon de réception {{bonReception?.numeroBonReception}}
      </span>

    <span class="mg-r-5">
       - Le  {{bonReception.dateLivraison | date:'dd/MM/yyyy HH:mm' | titlecase}}
      </span>

    <span class="mg-r-5">
       -  {{bonReception.libelleUniteDeProduction}}
      </span>

    <span class="mg-r-5" *ngIf="!utils.isNullOrEmpty(bonReception.numeroBonLivraison)">
       - Numéro de livraison {{bonReception.numeroBonLivraison}}
      </span>
  </ng-container>
</ng-template>

<!--TEMPLATE DES BOUTONS DE RECEPTION-->
<ng-template #tplButtonsReception let-bonReception="bonReception">

  <ng-container *ngIf="bonReception">

    <yo-button
      *ngIf="hasCommandesFacturesRight && numberInvoice"
      iconClass="fas fa-file-invoice-dollar"
      (onClick)="navigateToFacture(this.numberInvoice)"
      label="Ouvrir la facture">
    </yo-button>

    <span class="mg-r-5"
          *ngIf="hasCommandesFacturesRight && !numberInvoice && (bonReception?.receptionStatut.code === receptionStatutCode.RECEPTION_PARTIELLE || bonReception?.receptionStatut.code === receptionStatutCode.TERMINE)">
     <yo-button
       iconClass="fas fa-dollar-sign"
       (onClick)="enableDialogBindBill()"
       label="Lier une facture">
     </yo-button>
    </span>

    <span class="mg-r-5">
     <yo-button
       iconClass="fas fa-print"
       (onClick)="bonReceptionSvc.getPDFBonReception(bonReception)"
       label="Imprimer">
     </yo-button>
    </span>

    <span class="mg-r-5 ">
      <p-menu #menuTypeReception appendTo="body" [popup]="true" [model]="itemsReceptions"></p-menu>
      <yo-button
        buttonClass="cta-success"
        iconClass="fas fa-save"
        label="Réception"
        [disabled]="isDisabled()"
        (onClick)="menuTypeReception.toggle($event)">
      </yo-button>
    </span>

    <span class="mg-r-5 ">
      <yo-button
        iconClass="fas fa-times"
        buttonClass="cta-inner-delete"
        (onClick)="closeDialog()"
        label="Fermer">
      </yo-button>
    </span>
  </ng-container>

</ng-template>


<p-dialog (onHide)="hideDialogProduitCarne()"
          [dismissableMask]="false"
          [modal]="true"
          position="top"
          [style]="{ 'max-width': '500px', 'max-height': '500px'}"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="isDisplayDialogProduitCarne"
          appendTo="body">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Article :
      {{selectedBonReceptionLigne?.denominationArticle}}
    </div>
  </ng-template>
  <div *ngIf="origineList.length > 0">
    <div class="d-flex justify-content-between mg-b-10">
      <label class="label-produit-carne mg-r-5">Naissance: </label>
      <p-dropdown [options]="origineList"
                  [(ngModel)]="selectedBonReceptionLigne.naissance"
                  [style]="{'width':'300px'}"
                  optionLabel="libelle"
                  placeholder="Choisir un lieu de naissance"
                  appendTo="body"
                  filter="true"
                  [showClear]="true"></p-dropdown>
    </div>

    <div class="d-flex justify-content-between mg-b-10">
      <label class="label-produit-carne mg-r-5">Elevage: </label>
      <p-dropdown [options]="origineList"
                  [(ngModel)]="selectedBonReceptionLigne.elevage"
                  [style]="{'width':'300px'}"
                  optionLabel="libelle"
                  placeholder="Choisir un lieu d'élevage"
                  appendTo="body"
                  filter="true"
                  [showClear]="true"></p-dropdown>
    </div>

    <div class="d-flex justify-content-between mg-b-10">
      <label class="label-produit-carne mg-r-5">Abattage: </label>
      <p-dropdown [options]="origineList"
                  [(ngModel)]="selectedBonReceptionLigne.abattage"
                  [style]="{'width':'300px'}"
                  optionLabel="libelle"
                  placeholder="Choisir un lieu d'abattage"
                  appendTo="body"
                  filter="true"
                  [showClear]="true"></p-dropdown>
    </div>
  </div>
  <div *ngIf="origineList.length === 0">
    <i class="fas fa-exclamation-triangle danger-color space">
    </i>
    <span>Il n'existe aucune origine renseignée dans le référentiel</span>
  </div>
</p-dialog>

<dx-popup
  [showTitle]="true"
  title="Lier le bon de réception à une facture"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="openDialogBindBill"
  (onHiding)="closeDialogBindBill()"
  [width]="450"
  [height]="'auto'"
>
  <div class="m-auto">
    <div class="row mg-b-15">
      <div class="col-lg-12">
        <label *ngIf="numbersBills.length === 1" for="numBill" class="mg-b-15">Pour générer une facture, saisissez le
          numéro de facture fourni par votre fournisseur : </label>
        <label *ngIf="numbersBills.length > 1" for="numBill" class="mg-b-15">Pour générer une facture, sélectionnez un
          numéro de facture existant ou créer un nouveau numéro de facture avec le numéro fourni par votre fournisseur
          : </label>
      </div>
    </div>
    <div class="row mg-b-15">
      <div class="col-lg-12" *ngIf="numbersBills.length === 1">
        <input id="numBill" class="full-width" type="text" [(ngModel)]="numBill" placeholder="Numéro de facture"/>
      </div>
      <div class="col-lg-12" *ngIf="numbersBills.length > 1">
        <dx-lookup
          [value]="numBill"
          [items]="numbersBills"
          (onValueChanged)="onChangeNumBill($event)"
          placeholder="Sélection/Création d'un numéro de facture (existant)"
        >
        </dx-lookup>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center m-auto custom-button-container">
        <yo-button
          buttonClass="cta-success"
          iconClass="fas fa-save"
          label="Enregistrer"
          (onClick)="bindBonReceptionToBill()">
        </yo-button>

        <yo-button
          buttonClass="cta-success"
          iconClass="fas fa-save"
          label="Enregistrer et ouvrir la facture"
          (onClick)="bindBonReceptionToBillAndNavigate()">
        </yo-button>
      </div>
    </div>
  </div>
</dx-popup>
