import {ObjectDTO} from './object-dto';
import {TypeTacheDTO} from "./type-tache-dto";

export class TacheDTO extends ObjectDTO {

  libelle: string;
  code: string;
  actif: boolean;

  // type tache
  typeTacheId: number;
  typeTacheLibelle: string;
  typeTacheCode:string;

  typeTache: TypeTacheDTO;

}
