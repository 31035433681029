import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../core/utils/utils.service";

@Component({
  selector: 'yo-couleur',
  templateUrl: './couleur.component.html',
  styleUrls: ['./couleur.component.scss']
})
export class CouleurComponent implements OnInit {

  /**
   * Tooltip
   */
  @Input() tooltip: string;


  /**
   * Couleur en hexadecimal
   */
  @Input() hexColor: string;

  constructor(public utils: UtilsService) {
  }

  ngOnInit() {
  }

}
