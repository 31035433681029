import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {WorkflowInstanceDTO} from "../../core/dtos/workflow-instance-dto";
import {SelectionRepasDTO} from "../../core/dtos/selection-repas-dto";
import {SelectionRepasService} from "../../core/services/gestion-selection-repas/selection-repas.service";
import {ErreurFonctionnelleDto} from "../../core/dtos/erreur-fonctionnelle-dto";
import {TreeNode} from "primeng/api";
import {WorkflowDto} from "../../core/dtos/workflow-dto";
import {WorkflowEtapeDto} from "../../core/dtos/workflow-etape-dto";


@Injectable({
  providedIn: 'root'
})
export class SelectionMenusResolverService implements Resolve<SelectionRepasSupplier> {

  constructor(
              private selectionRepasSvc: SelectionRepasService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SelectionRepasSupplier> | Promise<SelectionRepasSupplier> | SelectionRepasSupplier => {
    let srs = new SelectionRepasSupplier();
    const idWorkflowInstance: number = +route.parent.paramMap.get('idWorkflowInstance');

    return this.selectionRepasSvc.getSelectionRepas(idWorkflowInstance)
      .toPromise().then(response => {
        // workflow
        srs.workflow = response.additionalProperties['workflow'];
        // workflow etape
        srs.workflowEtape = response.additionalProperties['workflowEtape'];
        // workflow instance
        srs.workflowInstance = response.additionalProperties['worklowInstance'];
        // date selectionnes
        srs.selectedDates = response.additionalProperties['selectedDates'];
        // selections jours
        srs.selectionRepasList = response.resultList;
        // arbre structure
        srs.tree = response.additionalProperties['tree'];
        // erreurs
        srs.erreurs = response.additionalProperties['erreurs'];
        return srs;
      });
  };
}

export class SelectionRepasSupplier {
  workflowInstance: WorkflowInstanceDTO;
  workflow:WorkflowDto;
  workflowEtape:WorkflowEtapeDto;
  selectedDates: number[];
  selectionRepasList: SelectionRepasDTO[];
  tree: TreeNode[];
  erreurs: ErreurFonctionnelleDto[];
}
