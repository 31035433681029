import {Component, OnDestroy, OnInit} from '@angular/core';
import {FamillesProduitService} from '../../core/services/entities/familles-produit.service';
import {FamilleProduitDTO} from '../../core/dtos/famille-produit-dto';
import {UtilsService} from '../../core/utils/utils.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {Subscription} from "rxjs";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {confirm} from "devextreme/ui/dialog";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-familles-produit',
  templateUrl: './familles-produit.component.html',
  styleUrls: ['./familles-produit.component.scss']
})

export class FamillesProduitComponent implements OnInit, OnDestroy {

  familleProduitList: FamilleProduitDTO[] = [];
  selectedFamilleProduit: FamilleProduitDTO;

  selectedRows: number[] = [];

  errors: any[] = [];
  displayErrors: boolean = false;

  private subQuery: Subscription;

  private subFamilleProduitSaved$: Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/familles-produits/familles-produits';

  constructor(public familleProduitSvc: FamillesProduitService,
              private readonly i8nSvc: InternationalizationService,
              public utils: UtilsService,
              private readonly toastSvc: ToastService) {
    this.selectedFamilleProduit = new FamilleProduitDTO();
    this.selectedFamilleProduit.parent = new FamilleProduitDTO();
    this.familleProduitSvc.announceFamilleProduit(this.familleProduitList, this.selectedFamilleProduit);
  }

  ngOnInit() {
   this.initData();
   this.subscribeFamilleProduitSaved();
  }

  initData(){
    this.subQuery = this.familleProduitSvc.getAllFamilleProduits().subscribe((response: ResponseWrapper<FamilleProduitDTO>) => {
      this.familleProduitList = response.resultList;
    });
  }

  openDialogEdition = (familleProduit?: FamilleProduitDTO) => {
    this.familleProduitSvc.announceFamilleProduit(this.familleProduitList, familleProduit);
  };

  subscribeFamilleProduitSaved = (): void => {
    this.subFamilleProduitSaved$ = this.familleProduitSvc.familleProduitSaved$.subscribe(() => this.initData());
  }

  onSelectionChanged = (event : any) => {
    this.selectedFamilleProduit = event.selectedRowsData[0];
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subQuery);
    this.utils.unsubscribe(this.subFamilleProduitSaved$);
  }

  canDelete = (): boolean => this.selectedRows?.length > 0;

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.familleProduitSvc.delete(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res?.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const labelsError: any = this.i8nSvc.getLabelFromCode(error.code, null);
            const infosLine: string = labelsError.replace('{}', error.args);
            this.errors.push({infosLine});
            const elementToKeep = this.familleProduitList.find(u => u.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToDeleteInTable.length > 1 ? `Les familles ont bien été supprimées` : `La famille a bien été supprimée`);
        }
        this.familleProduitList = this.familleProduitList.filter(famille => !idsToDeleteInTable.find(id => id === famille.id));
      }
    });
  }

  displayDeleteErrors = (): void => {
    this.displayErrors = true;
  }

  closeErrors = (): void => {
    this.displayErrors = false;
  }

}
