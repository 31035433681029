import {ObjectDTO} from './object-dto';
import {StockMouvementDTO} from "./stock-mouvement-dto";

export class StockDTO extends ObjectDTO {

  prixUnitaire: number;
  quantite: number;
  dateEntree: number;
  dlc: number;
  dluo: number;
  actif: boolean;
  dateCreation: Date;
  dateDerniereModification: Date;
  creePar: string;
  modifiePar: string;
  modificationManuelle: boolean;
  moves: StockMouvementDTO[] = [];

  // debut transient
  quantiteReaj: number;
  prixUnitaireUS: number;
  prixUnitaireKilo: number;
  quantiteEnKilo: number;
  quantiteSortieUS: number;
  // fin transient


  // produit article
  produitArticleLibelle: string;
  produitArticleReference: string;
  fournisseurLibelle: string;
  secteurFournisseurLibelle: string;

  // unite de prod zone de stockage
  zdsLibelle: string;
  idZoneDeStockage : number;
  idUdpZoneDeStockage: number;

  // unite de production
  idUdp: number;
  udpLibelle: string;

  // lot article
  lotArticle: string;
  lotArticleId: number;
  lotArticleFournisseur: string;

  // catalogue achat
  idCatalogueAchat: number;
  catalogueAchatRefFournisseur: string;

  // unite de mesure
  udmStockLibelle: string;
  udmFacturationLibelle: string;

  // produit declinaison
  produitDeclinaisonCode: string;

  constructor() {
    super();
  }
}
