import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import CustomStore from 'devextreme/data/custom_store';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {DevextremeService} from '../../../../core/services/technique/devextreme.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {
  GestionFournisseurCatalogueAchatService
} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {Sort} from '../../../../core/suppliers/generics/generic-request-supplier';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../core/models/is-deletable-object";
import {MSG_KEY} from "../../../../core/constants";
import {CataloguesAchatsService} from "../../../../core/services/entities/catalogues-achats.service";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";

@Component({
  templateUrl: './fournisseur-catalogue-article.component.html',
  styleUrls: ['./fournisseur-catalogue-article.component.scss']
})
export class FournisseurCatalogueArticleComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent, {static: false}) grid: DxDataGridComponent;

  catalogueAchatList: CatalogueAchatDTO[] = [];
  filiale: FournisseurDTO;
  displayDialog: boolean = false;
  customStore: CustomStore;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private catalogueAchatSvc: CataloguesAchatsService,
              private dxSvc: DevextremeService,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private gestionFournisseurCaSvc: GestionFournisseurCatalogueAchatService,
              @Inject('dynamicData') public fournisseurData: FournisseurDTO) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
  }

  initData = () => {
    this.filiale = this.fournisseurData;
    this.customStore = this.initCustomStore(this.filiale);
  };

  initCustomStore = (filiale: FournisseurDTO) => {
    return new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {

        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex() + 1;
        const filters = this.dxSvc.dxToGrsFilters(loadOptions.filter);
        const filterLibArticle = filters.find(filter => filter.column === 'produitArticle.libelle');
        const filterRefArticle = filters.find(filter => filter.column === 'referenceArticle');
        const filterLibUniteCmd = filters.find(filter => filter.column === 'uniteDeCommande.libelle');

        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const sortLibArticle = sorts.find(filter => filter.path === 'produitArticle.libelle');
        const sortRefArticle = sorts.find(filter => filter.path === 'referenceArticle');
        const sortLibUniteCmd = sorts.find(filter => filter.path === 'uniteDeCommande.libelle');

        return this.catalogueAchatSvc.dynamicSearchCatalogueAchat([filiale.id], true, null, filterLibArticle?.value, filterRefArticle?.value, filterLibUniteCmd?.value,
          sortLibArticle?.dir, sortRefArticle?.dir, sortLibUniteCmd?.dir, null, page, pageSize)
          .toPromise().then(response => {
            this.catalogueAchatList = response.resultList;

            return {
              data: this.utils.isNullOrEmpty(this.catalogueAchatList) ? [] : this.catalogueAchatList,
              totalCount: this.utils.isNullOrEmpty(response.totalElements) ? 0 : response.totalElements
            }
          });
      },
      update: (key, values) => {
        return null;
      }
    });
  };

  deleteArticle = async (catalogueAchat: CatalogueAchatDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer l'article ${catalogueAchat.produitArticle.libelle} du fournisseur ${catalogueAchat.fournisseur.libelle} ?`,
      'Suppression d\'article');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.catalogueAchatSvc.deleteCatalogueAchat(catalogueAchat.id).subscribe(async (response: ResponseWrapper<IsDeletableObject>) => {
        this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
        await this.grid.instance.refresh();
      });
    }
  };

  isDisabledDeleteBtn = (cell: CatalogueAchatDTO) => !this.auth2Svc.isSiteLocal(cell.site.id);

  canModify = (): boolean => this.auth2Svc.isSiteLocal(this.filiale.site.id);
}
