import {ObjectDTO} from "./object-dto";

export class CouleurDTO extends ObjectDTO {

  libelle: string;
  code: string;
  hexa: string;
  actif: boolean;

}
