import {ObjectDTO} from '../object-dto';
import {MarcheDTO} from './marche-dto';

export class LotMarcheDTO extends ObjectDTO {

  libelle: string;
  code: string;
  descriptif: string;
  dateDebut: Date;
  dateFin: Date;
  actif: boolean;
  valeurEngagement: number;
  marche: MarcheDTO;
  contextComplete: boolean;
  articlesComplete: boolean;

}
