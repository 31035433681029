import {Injectable} from '@angular/core';
import {ConviveDTO} from '../../dtos/convive-dto';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {ObjectDTO} from "../../dtos/object-dto";
import {GraphQLService} from "../technique/graphql.service";

export const URL_GET_ALL = `dolrest/prestations/search`;

@Injectable()
export class ConvivesService {

  convivesEnvironnement: ConviveDTO[] = [];

  subjectOpenDialogEdition: Subject<ConviveDTO> = new Subject<ConviveDTO>();

  openDialogEdition$: Observable<ConviveDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectConviveSaved: Subject<ConviveDTO> = new Subject<ConviveDTO>();

  conviveSaved$: Observable<any> = this.subjectConviveSaved.asObservable();

  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              title: Title,
              private httpSvc: HttpService,
              private graphQlSvc: GraphQLService
              ) {
    this.findGraphQL();
  }

  findGraphQL = (): void => {
    this.graphQlSvc.sendQuery(`
      {
          allPrestations(filters: {
          siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}]
        }) {
              id,
              libelle,
              site { id, libelle },
              code,
              actif
          }
      }
    `)
      .subscribe((res) => {
        this.convivesEnvironnement = res.allPrestations;
      });
  }

  findAll(idsSites?: number[]): Observable<ResponseWrapper<ConviveDTO>> {
    return this.httpSvc.get(`${URL_GET_ALL}?size=999${idsSites && idsSites.length ? `&idsSites=${idsSites}` : ''}`);
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  getEntityName(): string {
    return 'convive';
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/prestations/export${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/prestations/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/prestations/import", formData, null);
  }

  announceOpeningEditionDialog = (prestation: ConviveDTO): void => {
    this.subjectOpenDialogEdition.next(prestation);
  }

  announceConviveSaved = (): void => {
    this.subjectConviveSaved.next(null);
  }

  save = (prestation: ConviveDTO): Observable<ResponseWrapper<ConviveDTO>> => {
    return this.httpSvc.post("dolrest/prestations/save", prestation);
  }

}
