import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {UniteDeProduction__secteurFournisseurService} from '../../../../../core/services/entities/unite-de-production__secteur-fournisseur.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {ToastService} from "../../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-remarque-commande',
  templateUrl: './dialog-remarque-commande.component.html',
  styleUrls: ['./dialog-remarque-commande.component.scss'],

})
export class DialogRemarqueCommandeComponent implements  OnInit, OnDestroy{

  @Input() displayDialog:boolean = false;
  @Output() onClose = new EventEmitter<boolean>();

  subUdpSecteurFournisseur: Subscription;

  selectedUdpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO;

  constructor( private udpSecteurFournisseurSvc : UniteDeProduction__secteurFournisseurService,
               public utils:UtilsService,
               private toastSvc: ToastService){}

  ngOnInit(): void {
    this.selectedUdpSecteurFournisseur =  this.udpSecteurFournisseurSvc.createEmptyDTO();

    this.subUdpSecteurFournisseur = this.udpSecteurFournisseurSvc.udpSecteurFournisseur$.subscribe(response =>{
      this.selectedUdpSecteurFournisseur = response;

      this.utils.focus('textRemarque',10,200);

    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subUdpSecteurFournisseur);
  }

  closeDialog(){
    this.displayDialog = false;
    this.onClose.emit();
  }

  saveRemarque(){
    this.udpSecteurFournisseurSvc.saveUdpSecteurFournisseur(this.selectedUdpSecteurFournisseur).subscribe(response =>{
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Remarque sauvegardée`);
      this.closeDialog();
    });
  }



}
