import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {MarcheService} from '../../../core/services/entities/marche.service';
import {GestionsMarcheService} from '../../../core/services/gestion-marche/gestions-marche.service';
import {combineLatest, Observable} from 'rxjs';
import {Marche} from '../../../core/models/gestion-marches/tableau-de-bord/marche';

@Injectable({
  providedIn: 'root'
})
export class TableauDeBordResolverService  implements Resolve<MarcheTableauDeBordSupplier> {

  constructor(private marcheSvc: MarcheService,
              private gestionsMarcheSvc: GestionsMarcheService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MarcheTableauDeBordSupplier> | Promise<MarcheTableauDeBordSupplier> | MarcheTableauDeBordSupplier {
    let mtbs: MarcheTableauDeBordSupplier = new MarcheTableauDeBordSupplier();
    let idMarche: number = +route.parent.paramMap.get('idMarche');
    const marche$ = this.gestionsMarcheSvc.getTableauDeBord(idMarche);

    const all$ = combineLatest([marche$]);
    return all$.toPromise().then(data => {
      mtbs.marche = data[0].one;
      return mtbs;
    });
  }
}

export class MarcheTableauDeBordSupplier {
  marche: Marche;
}


