import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {TypeTacheDTO} from "../../dtos/type-tache-dto";

export const URL_GET_ALL = `dolrest/types-taches/list`;

@Injectable({
  providedIn: 'root'
})
export class TypesTachesService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les types de tâches
   * @returns {Observable<TypeTacheDTO>}
   */
  getAll(): Observable<ResponseWrapper<TypeTacheDTO>> {
    return this.httpSvc.get(URL_GET_ALL, null);
  }

}

