import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilisateurDTO} from '../../dtos/utilisateur-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';

@Injectable()
export class UtilisateurConnexionService extends GenericHandler<UtilisateurDTO> {

  createEmptyDTO(): UtilisateurDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return '';
  }

  getEntityName(): string {
    return 'UtilisateurConnexion';
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return '';
  }

  getTotalRecordsLabel(): string {
    return '';
  }

}
