import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {FP_ROUTES, HAS_GESTIONBESOIN} from "../core/constants";
import {GestionListesBesoinsRootComponent} from "./gestion-listes-besoins-root/gestion-listes-besoins-root.component";
import {GrilleListesBesoinsComponent} from "./grille-listes-besoins/grille-listes-besoins.component";
import {LbComponent} from "./lb/lb.component";


const routes: Routes = [
  {

    path : FP_ROUTES.GESTION_LISTES_BESOINS,
    component: GestionListesBesoinsRootComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_GESTIONBESOIN,
    children:[
      {
        path:'lb',
        component:LbComponent,
        runGuardsAndResolvers: 'always',
        data: HAS_GESTIONBESOIN,
        children:[
          {
            path:'listes-de-besoins',
            runGuardsAndResolvers:'always',
            component:GrilleListesBesoinsComponent,
            data: HAS_GESTIONBESOIN,
          },
        ]

      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionListesBesoinsRoutingModule {
}
