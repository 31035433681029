<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button class="button-style-primeng"
              (click)="openEditObject(undefined)"
              [disabled]="!canCreate()"
              title="Créer un client">
        <i class="fa fa-plus"></i> CRÉER
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<div class="container-table">
  <dx-data-grid
    [dataSource]="clientsListFiltered"
    keyExpr="id"
    height="100%"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="false"
    [showRowLines]="true"
    [showBorders]="true"
    #grid
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxi-column dataField="id" alignment="center" caption="Actions" cellTemplate="actionsCellTemplate" width="100" [allowFiltering]="false">
    </dxi-column>
    <dxi-column dataField="site.libelle" caption="Site" cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="libelle" alignment="left" caption="Client" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxi-column dataField="plcList" alignment="left" caption="Point(s) de livraison(s)" cellTemplate="plcCellTemplate"
                width="400"
                [calculateFilterExpression]="calculateFilterExpression"
                (filterValueChange)="checkFilterExpression($event)"
                [allowFiltering]="true"
                [filterOperations]="['contains']"
    >
    </dxi-column>
    <dxi-column dataField="adresse" alignment="left" caption="Adresse" cellTemplate="adresseCellTemplate" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="actif" alignment="center" caption="Actif" [allowFiltering]="false"></dxi-column>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                      [yoViewMode]="!gds.canModify(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fa fa-edit"
                      yoTooltip="Modifier le client"
      ></yo-cell-button>
      <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      [yoDisabled]="!gds.canModify(cell.row.data)"
                      yoTextAlign="center"
                      yoIconClass="fa fa-trash"
                      yoTooltip="Supprimer le client"
      ></yo-cell-button>
    </div>
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteLibelle]="cell.row.data.site.libelle" [siteId]="cell.row.data.site.id"></yo-site>
    </div>
    <div *dxTemplate="let cell of 'plcCellTemplate'">
      <table>
        <tr>
          <td [style.width.px]="60" style="vertical-align: top;padding:4px;"
              title="Ajouter un point de livraison"
              class="custom-button-container"
          >
            <yo-cell-button (yoNavigation)="openPlc(cell.row.data, undefined)"
                            [yoTooltipShowDelay]="1500"
                            [yoSmall]="true"
                            yoTextAlign="center"
                            yoIconClass="fa fa-plus"
            ></yo-cell-button>
          </td>
          <td style="vertical-align: top;padding:4px;">
            <ng-container *ngFor="let plc of cell.row.data.plcList">
              <div (click)="choosePlcAction($event,plc)" class="plc">
                <span class="genlabel cursor" [ngClass]="isPlcFiltered(plc.libelle) && 'plcFiltered'">{{plc.libelle | uppercase}}</span>
              </div>
              <yo-drop-menu *ngIf="selectedPlc?.id === plc?.id" (close)="closeDropMenu($event)"
                            [rows]="plcActions"></yo-drop-menu>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>
    <div *dxTemplate="let cell of 'adresseCellTemplate'">
      {{cell.row.data.adresse.codePostal}} {{cell.row.data.adresse.ville}}
    </div>

  </dx-data-grid>
</div>
