import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {UtilisateurTokenSetMdpDTO} from '../../dtos/utilisateur-token-set-mdp-dto';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {HttpService} from "../technique/http.service";

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private httpSvc: HttpService,
              private utils: UtilsService) {
  }

  resetPasswordStep1 = (email: string) => {
    return this.httpSvc.get(`dolrest/utilisateurAuth/resetPassword/${email}`);
  };

  sendNewPassword = (id: number, token: string, password: string, confirmationPassword: string) => {
    return this.httpSvc.post(`dolrest/utilisateurAuth/saveFromRecovery/${token}`, { id, password, confirmationPassword});
  };

}
