import {ObjectDTO} from "./object-dto";

export class ZoneDeStockageDTO  extends ObjectDTO{

  id: number;
  libelle: string;
  actif:boolean;
  code:string;



}
