import {Injectable} from '@angular/core';
import {Router} from "@angular/router";


@Injectable()
export class MenusRoutemapService {

  constructor(private router: Router) {
  }

  public getContratMenuConvivesRepasSLink(idsContratMenuConvive: number[]): string {
    return `dolrest/gestionmenus/contratmenusconvivesrepas?idsContratConvive=${idsContratMenuConvive}`;
  }

  public getContratMenuConvivesDecoupageSLink(idsContratMenuConviveRepas: number[]): string {
    return `dolrest/gestionmenus/contratmenusconvivesdecoupage?idsContratConviveRepas=${idsContratMenuConviveRepas}`;
  }

  public getGroupesNutritionnelsDecoupagesSLink(idsContratMenuConviveDecoupage: number[]): string {
    return `dolrest/gestionmenus/groupesNutritionnelsDecoupages?idsContratMenuConviveDecoupage=${idsContratMenuConviveDecoupage}`;
  }

  public getMenusCompositionsSLink(idsContratMenuConviveDecoupage: number[], strDates: string[]): string {
    return `dolrest/gestionmenus/menuscompositions?idsContratMenuConviveDecoupage=${idsContratMenuConviveDecoupage}&strDates=${strDates}`;
  }

  public getMenusEffectifsRepasSLink(idsContratMenuConviveRepas: number[], strDates: string[]): string {
    return `dolrest/gestionmenus/menusEffectifsRepas?idsContratMenuConviveRepas=${idsContratMenuConviveRepas}&strDates=${strDates}`;
  }


  public getSaveEffectifGlobalJourSLink(idContratMenuConviveRepas: number, dateEffectif : string , effectifPrevu: number): string {
    return `dolrest/gestionmenus/saveEffectifGlobalJour?idContratMenuConviveRepas=${idContratMenuConviveRepas}&dateEffectif=${dateEffectif}&effectifPrevu=${effectifPrevu}`;
  }

  public getImpressionCalculMatieresPremieres(startDate:string, stopDate:string , idsContratsMenusConvives: number[], formatFichier: string): string {
    return `dolrest/gestionmenus/impression/calculMatieresPremieres?startDate=${startDate}&stopDate=${stopDate}&idsContratsMenusConvives=${idsContratsMenusConvives}&formatFichier=${formatFichier}`;
  }
}
