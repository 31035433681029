import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {ConfigurationPortailDTO} from "../../../core/dtos/portail/configuration-portail-dto";
import {ConfigurationPortailService} from "../../../core/services/portail/configuration-portail.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationPortailPlcGridResolverService implements Resolve<ConfigurationPortailPlcGridSupplier> {

  constructor(private configurationPortailSvc: ConfigurationPortailService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ConfigurationPortailPlcGridSupplier> | Promise<ConfigurationPortailPlcGridSupplier> | ConfigurationPortailPlcGridSupplier => {
    let supplier: ConfigurationPortailPlcGridSupplier = new ConfigurationPortailPlcGridSupplier();

      const configurationPortailPlcList$ = this.configurationPortailSvc.getAll();

      const all$ = combineLatest([configurationPortailPlcList$]);
      return all$.toPromise().then(data => {
        supplier.configurationPortailPlcList = data[0].resultList;
        return supplier;
      });
  };

}

export class ConfigurationPortailPlcGridSupplier {
  configurationPortailPlcList: ConfigurationPortailDTO[] = [];
}
