import {FamilleGemrcnDTO} from "../../dtos/famille-gemrcn-dto";
import * as moment from "moment";
import {MenuCompositionDTO} from "../../dtos/menucomposition-dto";
import {PeriodeGermcnSupplier} from "../../services/gestiongemrcn/calcul-gemrcn.service";
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";

/**
 * Gemrcn - Contrat Menu Convive (CMC) -> Supplier Class pour l'affichage du GEMRCN dans les menus
 */
export class GemrcnCmcSupplier {

  famillesGemrcn: FamilleGemrcnDTO[];
  contratMenuConvive: ContratMenuConviveDTO;

  //periode actuelle
  currentDates: moment.Moment[];
  currentStartDate: moment.Moment;
  currentStopDate: moment.Moment;
  currentMenusCompositions: MenuCompositionDTO[];
  currentNbRepasPeriode:number;

  //periode recurrente du contrat
  pgs: PeriodeGermcnSupplier;
  contractStartDate: moment.Moment;
  contractStopDate: moment.Moment;
  contractMenusCompositions : MenuCompositionDTO[];

}

