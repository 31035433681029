import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ContratMenuConviveRepasPlcDateDTO} from '../../dtos/cmcr-plc-date-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {catchError} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {HttpService} from "../technique/http.service";

export const URL_POST_SAVE_WEEK_PLC = `dolrest/gestion-clients/point-de-livraison/semaine/save`;
export const URL_POST_SAVE_EFFECTIF_REPAS_PLC = `dolrest/gestion-clients/point-de-livraison/repas/effectif/save`;
export const URL_POST_DUPLICATE_EFFECTIF_REPAS_PLC = `dolrest/gestion-clients/point-de-livraison/repas/effectif/duplicate`;

@Injectable({
  providedIn: 'root'
})
export class CmcrPlcDateService extends GenericHandler<ContratMenuConviveRepasPlcDateDTO> {

  private subjectChangePlcEffectifRepas = new Subject<ContratMenuConviveRepasPlcDateDTO>();
  changePlcEffectifRepas$ = this.subjectChangePlcEffectifRepas.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, private httpSvc: HttpService, title: Title, private gds: GenericDatagridService,
  ) {
    super(utils, auth2Svc, router, http, title);
  }


  createEmptyDTO = (): ContratMenuConviveRepasPlcDateDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: ObjectDTO): string => "";

  getEntityName = (): string => "ContratMenuConviveRepasPlcDate";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "";

  getTotalRecordsLabel = (): string => "";

  saveEffectifsPlc = (cmcrPdList: ContratMenuConviveRepasPlcDateDTO[]) => this.httpSvc.post(URL_POST_SAVE_WEEK_PLC, cmcrPdList);

  saveEffectifRepasPlc = (cell: ContratMenuConviveRepasPlcDateDTO, typeEffectif: number, isPortailSaisi: boolean) => {
    return this.http.post(URL_POST_SAVE_EFFECTIF_REPAS_PLC, cell, {
      params: new HttpParams()
        .set('typeEffectif', typeEffectif + '')
        .set('isPortailSaisi', isPortailSaisi)
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  duplicateEffectivesRepasPlc = (cellsEffectivesPrevisionnal: ContratMenuConviveRepasPlcDateDTO[], typesEffectifs: number[]) => this.httpSvc.post(`${URL_POST_DUPLICATE_EFFECTIF_REPAS_PLC}?typesEffectifs=${typesEffectifs.join(',')}`, cellsEffectivesPrevisionnal);
}
