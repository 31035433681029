<dx-popup
  id="avoir-dialog-popup"
  [showTitle]="true"
  [title]="'Avoir n° ' + avoir.code"
  [(visible)]="displayDialog"
  [width]="1500"
  [height]="700"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
  (onResize)="resizeChilds($event)"
  (onContentReady)="resizeChilds($event)"
>
  <div id="data">
    <div class="dialog-line text-right mg-t-0">
      <div class="custom-button-container-right">
        <yo-button tooltip="Imprimer la facture" (onClick)="printAvoirPDF()" iconClass="fas fa-print"></yo-button>
        <yo-button tooltip="Sauvegarder la facture" (onClick)="editAvoir()" iconClass="fas fa-save" buttonClass="cta-success"></yo-button>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Statut">Statut</div>
        <div class="value">
          <dx-lookup
            [dataSource]="avoirStatus"
            [value]="avoir?.statut"
            valueExpr="code"
            displayExpr="displayCode"
            (onValueChanged)="onStatusChange($event)"
          >
            <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
          </dx-lookup>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Numéro de facture">N° Facture</div>
        <div class="value">
          <yo-button buttonClass="cta-link custom-link" [label]="avoir?.facture?.numero" (onClick)="navigateToFacture()"></yo-button>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Fournisseur">Fournisseur</div>
        <div class="value">
          <dx-text-box [value]="avoir?.facture?.fournisseur?.libelle" readOnly="true" disabled="true"></dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Montant demandé ">Montant demandé</div>
        <div class="value">
          <dx-number-box class='inputfield'
                         placeholder="Montant validé"
                         [value]="avoir?.montant?.value"
                         format=",##0.#### €"
                         readOnly="true"
                         disabled="true"></dx-number-box>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Référence">Référence</div>
        <div class="value">
          <dx-text-box class='inputfield'
                       placeholder="Référence"
                       [value]="avoir?.reference"
                       (onValueChanged)="onReferenceChange($event)"
          ></dx-text-box>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Montant validé ">Montant validé</div>
        <div class="value">
          <dx-number-box class='inputfield'
                         [value]="avoir?.montantValide?.value"
                         format=",##0.#### €"
                         (onValueChanged)="onMontantValideChange($event)"
          ></dx-number-box>
        </div>
      </div>
    </div>
    <div class="dialog-line-large">
      <div class="label" title="Commentaire">Commentaire</div>
      <div class="value">
        <dx-text-box class='inputfield'
                     placeholder="Commentaire"
                     [value]="avoir?.commentaire"
                     (onValueChanged)="onCommentaireChange($event)">
        </dx-text-box>
      </div>
    </div>
  </div>

  <div class="dialog-line">
    <dx-data-grid
      id="lignes-grid"
      height="470"
      [dataSource]="avoir?.lignes"
      keyExpr="id"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
      #grid>
      <dxi-column caption="Article" alignment="center">
        <dxi-column dataField="referenceArticle" caption="Référence" [filterOperations]="['contains']" width="10%" [allowFiltering]="true">
          <dxo-filter-row [visible]="true"></dxo-filter-row>
        </dxi-column>
        <dxi-column dataField="libelleArticle" caption="Libellé" [filterOperations]="['contains']" width="30%" [allowFiltering]="true">
          <dxo-filter-row [visible]="true"></dxo-filter-row>
        </dxi-column>
      </dxi-column>

      <dxi-column caption="Bon de réception" alignment="center">
        <dxi-column dataField="quantiteBonLivraison" caption="Quantité" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getQuantiteDisplayValue" alignment="center">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column dataField="prixBonLivraison" caption="Prix" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getPrixDisplayValue" alignment="right">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column dataField="totalBonLivraison" caption="Total" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getPrixDisplayValue" alignment="right">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
      </dxi-column>

      <dxi-column caption="Facture" alignment="center">
        <dxi-column dataField="quantiteFacture" caption="Quantité" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getQuantiteDisplayValue" alignment="center">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column dataField="prixFacture" caption="Prix" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getPrixDisplayValue" alignment="right">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column dataField="totalFacture" caption="Total" [filterOperations]="['contains']" width="5%" [allowFiltering]="true"
                    [calculateCellValue]="getPrixDisplayValue" alignment="right">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
      </dxi-column>

      <dxi-column caption="Avoir" alignment="center">
        <dxi-column dataField="montant" caption="Montant" [filterOperations]="['contains']" width="7%" [allowFiltering]="true"
                    [calculateCellValue]="getPrixDisplayValue" alignment="right">
        </dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column caption="Motif(s)" [filterOperations]="['contains']" [allowFiltering]="true"
                    [calculateDisplayValue]="getMotifsNonConformiteDisplayValue"></dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
      </dxi-column>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} avoir(s)">
      </dxo-pager>
    </dx-data-grid>
  </div>


</dx-popup>
