<div>

  <dx-data-grid
    [dataSource]="customStore"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(300)"
    [width]="utils.getWindowAvailableWidth(300)"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [remoteOperations]="true"
    (onCellPrepared)="onCellPrepared($event)"
    #gridLotMarcheEngagement>

    <dxi-column dataField="udpLibelle" caption="Unité de production"
                [allowEditing]="false"
                [filterOperations]="['contains']"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="catalogueAchatLibelle" caption="Article"
                [filterOperations]="['contains']"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="catalogueAchatReference" caption="Ref. fournisseur"
                [filterOperations]="['contains']"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="catalogueAchatFournisseurLibelle" caption="Fournisseur"
                [filterOperations]="['contains']"
                [allowEditing]="false"
                [allowFiltering]="true">

    </dxi-column>

    <dxi-column dataField="volumeEngagement" caption="Volume à atteindre"
                cellTemplate="volumeEngagementCellTemplate"
                [filterOperations]="['>=','<=']"
                [allowEditing]="true"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="valeurEngagement" caption="Valeur à atteindre"
                cellTemplate="valeurEngagementCellTemplate"
                [filterOperations]="['>=','<=']"
                [allowEditing]="true"
                [allowFiltering]="true">
    </dxi-column>

    <dxo-filter-row
      [visible]="true"></dxo-filter-row>

    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-editing
      mode="cell"
      [allowUpdating]="true">
    </dxo-editing>


    <!--    TEMPLATE VOLUME ENGAGEMENT-->
    <div *dxTemplate="let cell of 'volumeEngagementCellTemplate'">
      {{cell.row.data.volumeEngagement  | number:'1.4-4'}} KILO
    </div>

    <!--    TEMPLATE VALEUR ENGAGEMENT-->
    <div *dxTemplate="let cell of 'valeurEngagementCellTemplate'">
      {{cell.row.data.valeurEngagement  | currency:'EUR':'symbol':'1.4-4'}}
    </div>

    <dxo-paging [pageSize]="50"></dxo-paging>

    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[20, 50, 100]"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} items">
    </dxo-pager>

  </dx-data-grid>

  <yo-legend-grid-dev-extreme
    [modificationManuelle]="false"
    [colonneModifiable]="true"
    [colonneInformations]="false"
    [colonneObligatoire]="false">
  </yo-legend-grid-dev-extreme>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Paramétrer les engagements des articles au marché pour chaque unité de production</label></div>
    </div>
  </div>



</div>
