import {ObjectDTO} from './object-dto';

export class Produit__TacheDTO extends ObjectDTO {

  tacheId : number;
  tacheLibelle : string;
  tacheCode : string;

  produitId : number;
  produitLibelle : string;
  produitCode : string;

  typeTacheId : number;
  typeTacheLibelle : string;
  typeTacheCode : string;

}
