import {Injectable} from '@angular/core';

@Injectable()
export class EnvironnementRouteMapService {

  constructor() { }

  // cf. SiteController#sitesSearch
  getSearchEnvironnementsLink(endUrl?: string) {
    return `dolrest/environnements/search?${endUrl}`;
  }



  getDeleteEnvironnementLink(idEnvironnement:number):string{
    const url = `dolrest/environnements/deleteone/${idEnvironnement}`;
    return url;
  }


  getEnvironnementFicheIdentiteLink(idEnvironnement:number):string{
    const url = `dolrest/environnements/environnement/${idEnvironnement}`;
    return url;
  }
}
