<dx-popup
  [showTitle]="true"
  [title]="'FRAIS DE PORT'"
  [(visible)]="displayDialog"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <dx-data-grid
    [dataSource]="fraisDePort"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [columnAutoWidth]="true"
  >
    <dxi-column dataField="fournisseur" alignment="left" caption="Fournisseur"
                [allowFiltering]="true"
                cellTemplate="fournisseurCellTemplate">
    </dxi-column>
    <dxi-column dataField="dateLivraison" alignment="left" caption="Date livraison"
                [allowFiltering]="true"
                cellTemplate="dateCellTemplate">
    </dxi-column>
    <dxi-column dataField="site" alignment="left" caption="Montant total HT"
                [allowFiltering]="true"
                cellTemplate="montantCellTemplate">
    </dxi-column>
    <dxi-column dataField="prixUF" alignment="left" caption="Remarque"
                [allowFiltering]="false"
                cellTemplate="remarqueCellTemplate">
    </dxi-column>

    <div *dxTemplate="let cell of 'fournisseurCellTemplate'">
      {{cell.row.data.fournisseur?.libelle}}
    </div>
    <div *dxTemplate="let cell of 'dateCellTemplate'">
      {{cell.row.data.dateLivraison | date:'dd/MM/yyyy'}}
    </div>
    <div *dxTemplate="let cell of 'montantCellTemplate'">
      {{cell.row.data.montantTotalHT | currency:'EUR':'symbol':'1.4-4'}}
    </div>
    <div *dxTemplate="let cell of 'remarqueCellTemplate'">
      <p>{{cell.row.data.remarque}}</p>
    </div>

    <dxo-scrolling mode="virtual"></dxo-scrolling>
  </dx-data-grid>
</dx-popup>
