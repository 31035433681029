import {Injectable} from '@angular/core';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DialogMsgSupplier, Paragraphe} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Observable, Subject} from "rxjs";
import {SecteurDTO} from "../../dtos/secteur-dto";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {HttpService} from "../technique/http.service";

@Injectable()
export class SecteursService extends GenericHandler<SecteurDTO> {

  private subjectOpenDialog = new Subject<SecteurDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectSecteurSaved = new Subject<SecteurSavedSupplier>();
  secteurSaved$ = this.subjectSecteurSaved.asObservable();

  exportSecteurByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/production/secteurs/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importSecteurFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/production/secteurs/import", formData, null);
  };

  deleteSecteurByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`${"dolrest/referentiel/production/secteurs/delete"}?${params}`);
  };

  announceOpenDialogEdition = (secteur: SecteurDTO) => {
    this.subjectOpenDialog.next(secteur);
  };

  announceSecteurSaved = (secteur: SecteurDTO, isUpdate: boolean = false) => {
    this.subjectSecteurSaved.next({isUpdate, secteur} as SecteurSavedSupplier);
  }


  save = (secteur: SecteurDTO): Observable<ResponseWrapper<SecteurDTO>> => this.httpSvc.post("dolrest/referentiel/production/secteurs/save", secteur);

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  // region GENERIQUE

  secteurEnvironnement:SecteurDTO[]=[];

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp() : DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Les secteurs`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      ` Un secteur permet de rattacher des fournisseurs avec des unités de production.`,
    ];

    dms.content = {
      intro: `Les secteurs se paramètrent par site.`,
      paragraphes: [p1]

    };


    return dms;
  }



  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES SECTEURS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN SECTEUR';
  }

  getEntityName(): string {
    return 'Secteur';
  }

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,title:Title,
              private gds:GenericDatagridService,
              private httpSvc: HttpService){
    super(utils, auth2Svc, router, http,title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }

  getFields(dto: SecteurDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Secteur est actif.
    if(this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new SecteurDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        excelType:'string',
        value: dto.libelle,
        required: true,
        order: 2
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 1,
        maxLength: 50,
        value: dto.code,
        required: true,
        order: 3
      }),



      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      })

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }



  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.secteurEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.secteurEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }
        this.announceObjects(this.secteurEnvironnement);

      });
  }

  createEmptyDTO(): SecteurDTO {
    return new SecteurDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LE SECTEUR '${data.libelle.toUpperCase()}'`;
  }

  // endregion
}

export interface SecteurSavedSupplier {
  isUpdate: boolean;
  secteur: SecteurDTO;
}
