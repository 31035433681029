/**
 * Message de confirmation de suppression
 */
import {RequestWrapper} from "./wrappers/request-wrapper";

export class DialogDeleteSupplier extends RequestWrapper {

  title: string;
  question:string;
  idToDelete : number;
  /**
   * Utiliser le mécanisme generique ou pas.
   * Si on l'utilise, on passe par notification abonnement et on ne peut utilise la boite de dialogue que sur un seul element de la page
   */
  isGeneric:boolean=true;

}
