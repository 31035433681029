import {Injectable} from '@angular/core';
import {CanDeactivate} from "@angular/router";
import {GrilleArticlesPreferesComponent} from "./grille-articles-preferes.component";

@Injectable({
  providedIn: 'root'
})
export class DeactivateGrilleArticlesPreferesGuard implements CanDeactivate<GrilleArticlesPreferesComponent> {

  canDeactivate(component: GrilleArticlesPreferesComponent, currentRoute: import("@angular/router").ActivatedRouteSnapshot, currentState: import("@angular/router").RouterStateSnapshot, nextState?: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    component.stopPolling = true;
    console.log('canDeactivate, stop polling = ', component.stopPolling);

    return true;
  }

  constructor() {
  }
}
