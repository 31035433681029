import {EnvironnementSitesVisiblesDTO} from './enrironnement-sites-visibles-dto';
import {EnvironnementUtilisateurDTO} from './environnement-utilisateur-dto';
import {ObjectDTO} from "./object-dto";

export class EnvironnementDTO extends ObjectDTO{

  libelle :string;
  code:string;

  /**
   * Tableau contenant les EnvironnementSitesVisiblesDTO liés à l'environnement.
   */
  environnementSitesVisiblesDTOList: EnvironnementSitesVisiblesDTO[];

  /**
   * Tableau contenant les EnvironnementUtilisateurDTO liés à l'environnement.
   */
  environnementUtilisateurDTOList: EnvironnementUtilisateurDTO[];

  /**
   * Constructeur.
   * Initialisation de tous les champs.
   */
  constructor() {
    super();
    this.id = 0;
    this.libelle = "";
    this.code = "";
    this.environnementUtilisateurDTOList = [];
    this.environnementSitesVisiblesDTOList = [];
  }
}
