import {shareReplay} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UniteDeMesureDTO} from '../../dtos/unitedemesure-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldSelectButtonSupplier} from '../../suppliers/form-field-selectbutton-supplier';
import {UDM_TYPE} from '../../dtos/produitnomenclature-dto';
import {HttpService} from "../technique/http.service";
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";

const URL_GET_ALL_UNITE_DE_MESURE_LIST = `dolrest/unite-de-mesure/get-all`;

@Injectable()
export class UnitesDeMesuresService extends GenericHandler<UniteDeMesureDTO> {

  unitesDeMesureEnvironnement: UniteDeMesureDTO[];

  readonly typeMasse = {label: 'MASSE', value: UDM_TYPE.MASSE, icon: 'fas fa-weight'};
  readonly typeVolume = {label: 'VOLUME', value: UDM_TYPE.VOLUME, icon: 'fas fa-wine-bottle'};
  readonly types = [this.typeMasse, this.typeVolume];

  private subjectOpenDialogEdition: Subject<UniteDeMesureDTO> = new Subject<UniteDeMesureDTO>();

  openDialogEdition$: Observable<UniteDeMesureDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectUniteMesureSaved: Subject<UniteDeMesureDTO> = new Subject<UniteDeMesureDTO>();

  uniteMesureSaved$: Observable<any> = this.subjectUniteMesureSaved.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private httpSvc: HttpService) {
    super(utils,  auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/unite-de-mesure/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/unite-de-mesure/delete?ids=${idsToDelete}`, null);
  }

  getTotalRecordsLabel(): string {
    return 'Unités de mesure';
  }


  /**
   * Récupération des unités de mesure liées au site principal de l'utilisateur.
   */
  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true).pipe(
      shareReplay())
      .subscribe(response => {
        this.unitesDeMesureEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.unitesDeMesureEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.unitesDeMesureEnvironnement);
      });
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['abreviation,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES UNITÉS DE MESURE';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE UNITÉ DE MESURE';
  }

  getEntityName(): string {
    return 'UniteDeMesure';
  }

  getFields(dto: UniteDeMesureDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Unitedemesure est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new UniteDeMesureDTO();
      dto.actif = true;
      dto.id = 0;
    }

    const readonly = !this.canModify(dto);


    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType: 'string',
        readonly: readonly,
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'abreviation',
        label: 'Abréviation',
        excelType: 'string',
        readonly: readonly,
        minLength: 1,
        maxLength: 4,
        value: dto.abreviation,
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType: 'string',
        readonly: readonly,
        minLength: 1,
        maxLength: 4,
        value: dto.code,
        required: true,
        order: 4
      }),

      new FormFieldSelectButtonSupplier({
        key: 'type',
        label: 'Type',
        readonly: readonly,
        value: dto.type,
        required: true,
        order: 5,
        options: this.types
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType: 'boolean',
        readonly: readonly,
        value: dto.actif,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  createEmptyDTO(): UniteDeMesureDTO {
    return new UniteDeMesureDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER L'UNITÉ DE MESURE '${data.libelle.toUpperCase()}'`;
  }

  public getAllUniteDeMesure() {
    return this.httpSvc.get(URL_GET_ALL_UNITE_DE_MESURE_LIST);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/unite-de-mesure/import", formData, null);
  }

  announceOpeningEditionDialog = (uniteMesure: UniteDeMesureDTO): void => {
    this.subjectOpenDialogEdition.next(uniteMesure);
  }

  announceUniteMesureSaved = (): void => {
    this.subjectUniteMesureSaved.next(null);
  }

  save = (uniteMesure: UniteDeMesureDTO): Observable<ResponseWrapper<UniteDeMesureDTO>> => {
    return this.httpSvc.post(`dolrest/unite-de-mesure/save`, uniteMesure);
  }

}
