import {Component, OnDestroy, OnInit} from '@angular/core';
import {MZoneProduction} from '../../../../core/models/gestion-unites-production/m-zone-production';
import {Subscription} from 'rxjs';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {TachesService} from '../../../../core/services/entities/taches.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {UpEquipesSupplier} from './up-parametrage-resolver.service';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.scss']
})
export class ParametrageComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;

  subscriptionElementsToSendAreReady: Subscription;

  elementsToSend: MZoneProduction[];

  labelTabParametrage = 'Paramétrage';

  labelTabPreview = 'Prévisualisation des paramétrages';

  labelHelp = 'Paramétrage des équipes';

  toolTipSave = 'Enregistrer les associations';

  uniteProduction: UniteDeProductionDTO;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-equipes-parametrage';

  constructor(public auth2Svc: Auth2Service,
              private tachesService: TachesService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
    this.elementsToSendAreReadySubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionElementsToSendAreReady);
  }

  elementsToSendAreReadySubscription(): void {
    this.subscriptionElementsToSendAreReady = this.tachesService
      .elementsToSendAreReady$
      .subscribe((elements) => this.elementsToSend = elements);
  }

  routeSubscription(): void {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { upEquipesSupplier: UpEquipesSupplier }) => this.uniteProduction = data.upEquipesSupplier.uniteDeProduction);
  }

  saveAssociations(): void {
    this.tachesService.saveAssociations(this.elementsToSend).subscribe(response => {
      if (!this.utils.isNullOrEmpty(response)) {
        if (!response.inError) {
          this.tachesService.announceLoadAssociations();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les associations ont été enregistrées avec succès`);
        }
      }
    });
  }

  help(): DialogMsgSupplier {
    const dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des équipes`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    const p1: Paragraphe = new Paragraphe();
    p1.title = `Etape 1`;
    p1.lines = [
      ` Sélectionner un atelier.`,
    ];

    const p2: Paragraphe = new Paragraphe();
    p2.title = `Etape 2`;
    p2.lines = [
      `Sélectionner une équipe pour l'atelier sélectionné.`,
    ];

    const p3: Paragraphe = new Paragraphe();
    p3.title = `Etape 3`;
    p3.lines = [
      `Sélectionner une ou plusieurs "tâche(s)" pour l'équipe sélectionnée`,
    ];

    dms.content = {
      intro: `Le paramétrage se réalise en 3 étapes.`,
      paragraphes: [p1, p2, p3]
    };

    return dms;
  }// help()

}



