import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DeclinaisonDTO} from '../../dtos/declinaison-dto';
import {TypesProduitService} from './types-produit.service';
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {ObjectDTO} from "../../dtos/object-dto";
import {GraphQLService} from "../technique/graphql.service";

@Injectable()
export class DeclinaisonsService {

  declinaisonEnvironnement: DeclinaisonDTO[] = [];

  private subjectOpenDialogEdition: Subject<DeclinaisonDTO> = new Subject<DeclinaisonDTO>();

  openDialogEdition$: Observable<DeclinaisonDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectDeclinaisonSaved: Subject<DeclinaisonDTO> = new Subject<DeclinaisonDTO>();

  declinaisonSaved$: Observable<any> = this.subjectDeclinaisonSaved.asObservable();

  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              private typeProduitSvc: TypesProduitService,
              private httpSvc: HttpService,
              private graphQlSvc: GraphQLService) {
    this.findGraphQL();
  }

  findGraphQL = (): void => {
    this.graphQlSvc.sendQuery(`
      {
          allDeclinaisons(filters: {
          siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}]
        }) {
              id,
              libelle,
              site { id, libelle },
              code,
              typeProduit { id, libelle, fabrique },
              actif
          }
      }
    `)
      .subscribe((res) => {
        this.declinaisonEnvironnement = res.allDeclinaisons;
      });
  }


  /**
   * Filtrer les declinaisons de type plat ou denree
   * @param declinaisons
   * @param isFabrique 0=denree, 1=plat
   */
  getDeclinaisonList(declinaisonList: DeclinaisonDTO[], isFabrique: boolean): DeclinaisonDTO[] {

    let declinaisons = [];

    if (!this.utils.isCollectionNullOrEmpty(declinaisonList)) {
      const arr = declinaisonList.filter(item => item.typeProduit.fabrique === isFabrique);
      if (!this.utils.isCollectionNullOrEmpty(arr)) {
        declinaisons = arr;
      }
    }

    return declinaisons;
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/declinaisons/export${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/declinaisons/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/declinaisons/import", formData, null);
  }

  announceOpeningEditionDialog = (d: DeclinaisonDTO): void => {
    this.subjectOpenDialogEdition.next(d);
  }

  announceDeclinaisonSaved = (): void => {
    this.subjectDeclinaisonSaved.next(null);
  }

  save = (d: DeclinaisonDTO): Observable<ResponseWrapper<DeclinaisonDTO>> => {
    return this.httpSvc.post("dolrest/declinaisons/save", d);
  }

}
