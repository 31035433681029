import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {ErreurFonctionnelleService} from "../../../core/services/technique/erreur-fonctionnelle.service";
import {ErreurFonctionnelleDto} from "../../../core/dtos/erreur-fonctionnelle-dto";


@Injectable({
  providedIn: 'root'
})
export class UpIncoherenceResolverService implements Resolve<UpIncoherenceSupplier> {

  constructor(private erreurSvc: ErreurFonctionnelleService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpIncoherenceSupplier> | Promise<UpIncoherenceSupplier> | UpIncoherenceSupplier {
    const upIncoherenceSupplier = new UpIncoherenceSupplier();
    const idUniteDeProduction: number = +route.parent.paramMap.get('idUniteDeProduction');

    const erreurList$ = this.erreurSvc.getErreurListByUniteDeProduction(idUniteDeProduction);

    const all$ = combineLatest([erreurList$]);
    return all$.toPromise().then(response => {
      upIncoherenceSupplier.erreurFonctionnelleList = response[0].resultList;
      return upIncoherenceSupplier
    });
  }
}

export class UpIncoherenceSupplier {
  erreurFonctionnelleList: ErreurFonctionnelleDto[] = [];
}
