import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {TacheDTO} from '../../dtos/tache-dto';
import {catchError} from 'rxjs/operators';
import {GenericRequestSupplier, Predicat, Sort} from '../../suppliers/generics/generic-request-supplier';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from '../../constants';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {MZoneProduction} from '../../models/gestion-unites-production/m-zone-production';
import {TYPE_TACHE} from './type-tache.service';
import {HttpService} from '../technique/http.service';

const URL_ALL_TACHES = 'dolrest/gestion-unites-de-production/find/unite-de-production/taches';
const URL_SAVE_ASSOCIATIONS = 'dolrest/gestion-unites-de-production/save/unite-de-production/associations-udp-zdp-equipes-taches';
const URL_DELETE_ASSOCIATION = 'dolrest/gestion-unites-de-production/delete/unite-de-production/association-udp-zdp-equipe-tache';

@Injectable()
export class TachesService extends GenericHandler<TacheDTO> {
  get deleteAssociation(): (udpId: number, idZdp: number, idEquipe: number, idTache: number) => Observable<any> {
    return this._deleteAssociation;
  }

  set deleteAssociation(value: (udpId: number, idZdp: number, idEquipe: number, idTache: number) => Observable<any>) {
    this._deleteAssociation = value;
  }

  httpSvc: HttpService;
  tacheList: TacheDTO[];
  tacheFabricationList: TacheDTO[];
  tacheConditionnementList: TacheDTO[];
  tacheApprovisionnementList: TacheDTO[];
  tacheLivraisonList: TacheDTO[];
  tachePrepaLivraisonList: TacheDTO[];
  tacheSortieList: TacheDTO[];

  private subjectLoadAssociations = new Subject<Boolean>();
  loadAssociations$ = this.subjectLoadAssociations.asObservable();

  private subjectElementsToSendAreReady = new BehaviorSubject([]);
  elementsToSendAreReady$ = this.subjectElementsToSendAreReady.asObservable();

  announceLoadAssociations() {
    this.subjectLoadAssociations.next(true);
  }

  announceElementsToSendAreReady(elementsToSend: MZoneProduction[]) {
    this.subjectElementsToSendAreReady.next(elementsToSend);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES TACHES';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE TACHE';
  }

  constructor(utils: UtilsService,
              httpSvc: HttpService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
    this.httpSvc = httpSvc;
    this.auth2Svc.isLoggedIn$
      .subscribe((res) => {
        if (res) {
          this.getAllFromEnvironnement();

          this.environnement$.subscribe(() => {
            this.getAllFromEnvironnement();
          });
        }
      });
  }

  getEntityName = (): string => 'tache';

  getFields = (dto: TacheDTO): FormFieldBaseSupplier<any>[] => [];

  getAllFromEnvironnement = (): void => {

    this.tacheList = [];

    this.getAll().subscribe(response => {

      this.tacheList = response.resultList;
      if (!this.utils.isCollectionNullOrEmpty(this.tacheList)) {

        this.tacheApprovisionnementList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.APPROVISIONNEMENT);
        this.tacheConditionnementList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.CONDITIONNEMENT);
        this.tacheFabricationList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.FABRICATION);
        this.tacheLivraisonList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.LIVRAISON);
        this.tachePrepaLivraisonList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.PREPA_LIVRAISON);
        this.tacheSortieList = this.filterByTypeTache(this.tacheList, TYPE_TACHE.SORTIE);
      }

      this.announceObjects(this.tacheList);
    });

  };

  filterByTypeTache = (elements: TacheDTO[], typeTache: TYPE_TACHE) => {

    if (!this.utils.isCollectionNullOrEmpty(elements)) {

      return elements.filter(item => item.typeTacheCode === typeTache);
    }

    return [];

  };

  createEmptyDTO = (): TacheDTO => new TacheDTO();

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LA TACHE '${data.libelle.toUpperCase()}'`;

  saveAssociations = (associations: MZoneProduction[]) => {
    if (associations) {
      return this.httpSvc.post(URL_SAVE_ASSOCIATIONS, associations);
    }
    return of(null);
  };

  private _deleteAssociation = (udpId: number, idZdp: number, idEquipe: number, idTache: number) => {
    if (udpId && idZdp && idEquipe && idTache) {
      const params = new HttpParams()
        .set('idUdp', udpId + '')
        .set('idZdp', idZdp + '')
        .set('idEquipe', idEquipe + '')
        .set('idTache', idTache + '');
      return this.httpSvc.delete(URL_DELETE_ASSOCIATION, params);
    }
  };

  getAll = () => this.gds.search(this.filterAll())
    .pipe(
      catchError(err => this.utils.handleError(err, true))
    );

  findTachesByZoneProductionAndEquipes = (idUdp: number): Observable<ResponseWrapper<MZoneProduction>> => this.http.get<ResponseWrapper<MZoneProduction>>(`${URL_ALL_TACHES}?idUdp=${idUdp}`).pipe(
    catchError(error => this.utils.handleError(error)));

  private filterAll = (): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(this.getEntityName());
    const predicat1 = new Predicat();
    predicat1.path = `${this.getEntityName().toLowerCase()}.actif`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = PREDICAT_VALUE.True;
    grs.search.predicats.push(predicat1);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = `${this.getEntityName().toLowerCase()}.libelle`;
    grs.search.sorts = [sort];

    return grs;
  };

}
