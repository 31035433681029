import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {TextureDTO} from '../../dtos/texture-dto';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {ProduitContrainteAlimDTO} from '../../dtos/produit-contrainte-alim-dto';
import {ContrainteModel} from '../../models/contrainte-model';
import {ProduitDTO} from '../../dtos/produit-dto';
import {RegimeAlimentaireService} from './regime-alimentaire.service';
import {TextureService} from './texture.service';
import {RegimeAlimentaireDTO} from '../../dtos/regime-alimentaire-dto';

@Injectable({
  providedIn: 'root'
})
export class ProduitContrainteAlimService extends GenericHandler<ProduitContrainteAlimDTO> {

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private regimeSvc:RegimeAlimentaireService,
              private textureSvc:TextureService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);


  }

  createEmptyDTO = (): ProduitContrainteAlimDTO => undefined;

  createEmptyFromContrainte = (contrainte:ContrainteModel): ProduitContrainteAlimDTO => {
    const pca = new ProduitContrainteAlimDTO();

    pca.textureId=contrainte.texture.id;
    pca.regimeId=contrainte.regime.id;
    pca.produitId=0;

    return pca;
  };

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: TextureDTO): string => ``;

  getEntityName = (): string => "ProduitContrainteAlim";

  getFields = (dto: ProduitContrainteAlimDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => true;

  getSort = (): string[] => [];

  getTitle = (): string => "";

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());





  /**
   * Convertir une liste de contraintes alimentaires en ProduitContrainteAlimDTO[]
   * @param contraintes
   * @param produit
   */
  convertToPca = (contraintes: ContrainteModel[], produit: ProduitDTO): ProduitContrainteAlimDTO[] => {

    const pcaList = [];

    if(!this.utils.isCollectionNullOrEmpty(contraintes) && !this.utils.isNullOrEmpty(produit)){

      for (const contrainte of contraintes) {

        const pca = this.createEmptyFromContrainte(contrainte);
        pca.produitId=produit.id;
        pcaList.push(pca);

      }

    }

    return pcaList;
  };


  /**
   * Convertir une liste de ProduitContrainteAlimDTO[] en contraintes alimentaires
   * @param contraintes
   * @param produit
   */
  convertToContraintes = (produitContrainteAlimList: ProduitContrainteAlimDTO[]): ContrainteModel[] => {

    const contrainteList = [];

    if(!this.utils.isCollectionNullOrEmpty(produitContrainteAlimList)){

      for (const pca of produitContrainteAlimList) {

        const texture = new TextureDTO();
        texture.code=pca.textureCode;
        texture.libelle=pca.textureLibelle;

        const regime = new RegimeAlimentaireDTO();
        regime.libelle=pca.regimeLibelle;
        regime.code=pca.regimeCode;

        const contrainte = new ContrainteModel(texture,regime);

        contrainteList.push(contrainte);
      }
    }
    return contrainteList;
  };
}
