import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MonbureauComponent} from './monbureau.component';
import {HAS_MONBUREAU} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {MotDePasseOublieComponent} from '../shared/mot-de-passe-oublie/mot-de-passe-oublie.component';
import {UtilisateurAuthComponent} from '../shared/utilisateurAuth/utilisateur-auth.component';
import {TokenResolverService} from '../gestion-referentiel/token-resolver.service';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'monbureau',
        component: MonbureauComponent,
        canActivate: [Auth2Guard],
        data: HAS_MONBUREAU
      },
      {
        path: 'mot-de-passe-oublie',
        component: MotDePasseOublieComponent,

      },
      {
        path: 'utilisateurAuth/setNewPasswordfromToken/:tokenUtilisateur/:id',
        component: UtilisateurAuthComponent,
        resolve: {
          tokenSupplier: TokenResolverService,
        },


      }
    ])
  ],
  exports: [RouterModule]

})
export class MonbureauRoutingModule {
}

