import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {HttpParams} from "@angular/common/http";
import {UdpConditionnementVarianteDTO} from "../../dtos/conditionnement/udp-conditionnement-variante-dto";
import ConditionnementVarianteDTO from "../../dtos/conditionnement/conditionnement-variante-dto";

export const URL_FIND_ALL_UDP_CONDI_BY_UDP_ID = `dolrest/conditionnements/udp-conditionnements-variantes/find-all-by-udp-id`;
export const URL_SAVE_UDP_CONDI_FOR_UDP = `dolrest/conditionnements/udp-conditionnements-variantes/save-for-udp-id`;
export const URL_DELETE_UDP_CONDI = `dolrest/conditionnements/udp-conditionnements-variantes/delete-one`;

@Injectable({
  providedIn: 'root'
})
export class UdpConditionnementVarianteService {

  private subjectOpenDialogAjoutCondi = new Subject<boolean>();
  openDialogAjoutCondi$ = this.subjectOpenDialogAjoutCondi.asObservable();

  private subjectCondiVariantesSelectedList = new Subject<ConditionnementVarianteDTO[]>();
  condiVariantesSelectedList$ = this.subjectCondiVariantesSelectedList.asObservable();

  constructor(private httpSvc: HttpService) {
  }

  announceOpenDialogAjoutCondi() {
    this.subjectOpenDialogAjoutCondi.next(true);
  }

  announceCondiVariantesSelectedList(list: ConditionnementVarianteDTO[]) {
    this.subjectCondiVariantesSelectedList.next(list);
  }

  /**
   * Récupérer tous les udp conditionnements variantes correspondant a une unite de production
   * @returns {Observable<UdpConditionnementVarianteDTO>}
   */
  findByUdpId(idUdp: number): Observable<ResponseWrapper<UdpConditionnementVarianteDTO>> {

    const params: HttpParams = new HttpParams().set("idUdp", idUdp + '');
    return this.httpSvc.get(URL_FIND_ALL_UDP_CONDI_BY_UDP_ID, params);
  }

  /**
   * Sauvegarde des conditionnements variantes pour une unité de production
   * @param idUdp
   * @param conditionnementVariantesList
   */
  saveCondiVaraintesListForUdp(idUdp: number, conditionnementVariantesList: ConditionnementVarianteDTO[]) {

    const idCondiVarianteList = [];

    conditionnementVariantesList.forEach(item => {
      idCondiVarianteList.push(item.id);
    });

    const fd: FormData = new FormData();
    fd.set("idUdp", idUdp + '');
    fd.set("idCondiVarianteList", idCondiVarianteList + '');

    return this.httpSvc.post(URL_SAVE_UDP_CONDI_FOR_UDP, fd);
  }

  /**
   * Suppression des conditionnements variantes pour une unité de production
   * @param idUdp
   */
  deleteOne(idUdp: number) {
    const params: HttpParams = new HttpParams().set("idUdp", idUdp + '');

    return this.httpSvc.delete(URL_DELETE_UDP_CONDI, params);
  }


}
