import {ContratMenuConvivePlatDTO} from "./contratmenuconviveplat-dto";
import {ObjectDTO} from "./object-dto";

export class ContratMenuConviveDecoupageDTO  extends ObjectDTO{

  //    contrat menu convive decoupage
  nombreChoixMinimum : number;
  nombreChoixMaximum : number;
  actif:boolean;

  // cmcd plat
  contratMenuConvivePlatDTOList:ContratMenuConvivePlatDTO[] =[];

  //decoupage repas
  decoupageRepasId : number;
  decoupageRepasIdSite : number;
  decoupageRepasCode : string;
  decoupageRepasLibelle : string;
  decoupageRepasOrdre : number;
  decoupageRepasActif : boolean;

  //  contrat menu convive repas
  contratMenuConviveRepasId : number;
  contratMenuConviveRepasIdJourSemaine : number;
  contratMenuConviveRepasIntegreSuiviNutritionnel : boolean;
  contratMenuConviveRepasPrixRepasTheorique :number;
  contratMenuConviveRepasPrixRepasVente : number;
  contratMenuConviveRepasEffectifPrevu : number;
  contratMenuConviveRepasActif : boolean;


  // contrat Menu AxeConvive
  contratMenuConviveId : number;

  //convive
  conviveId : number;

  //repas
  repasId : number;


}
