import {ContratMenuConviveDecoupageDTO} from "./contratmenuconvivedecoupage-dto";
import {MenuCompositionDTO} from './menucomposition-dto';
import {ObjectDTO} from "./object-dto";

export class MenuDecoupageDateDTO extends ObjectDTO{

  dateMenu : Date;
  nonModifiable:boolean;
  contratMenuConviveDecoupage : ContratMenuConviveDecoupageDTO;


  menuCompositions: MenuCompositionDTO[] =[];

}
