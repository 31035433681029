<form [formGroup]="form" (ngSubmit)="saveFicheIdentite()" novalidate>
  <div class="card ">
    <div class="card-header">
      <label class="mg-r-5 bold">Prestation {{contratMenuConvive?.convive?.libelle | uppercase}}</label>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="floatright">
          <yo-button
            title="Enregistrer"
            type="submit"
            iconClass="fas fa-save"
            class="mg-r-5"
            buttonClass="cta-success"
            [disabled]="!gds.canModify(contratMenuConvive)"></yo-button>
          <yo-button *ngIf="contratMenuConvive?.id !== 0" iconClass="fas fa-trash"
                  (onClick)="openDeleteDialog(contratMenuConvive)"
                  [disabled]="!canDelete(contratMenuConvive)"
                  buttonClass="cta-inner-delete"
                  tooltip="Supprimer">
          </yo-button>
      </span>
    </div>
    <div class="card-body" style="overflow: auto;max-height:85vh;">

      <ng-container *ngFor="let field of fieldsInfosGenerales">
        <yo-dynamic-form-field-bs [fields]="fieldsInfosGenerales" [field]="field"
                                  [form]="formInfoGenerales"></yo-dynamic-form-field-bs>
      </ng-container>

    </div>
  </div>
</form>

