import {ObjectDTO} from "./object-dto";

export class ProduitAllergeneDTO extends ObjectDTO {

  // produit allergene
  produitDeclinaisonId: number;
  allergeneId: number;
  actif: boolean;

  // allergene
  allergeneLibelle: string;
  allergeneCode: string;
  allergeneLibellelong: string;
  allergeneActif:boolean;

  // produit declinaison
  produitDeclinaisonLibelle: string;
  produitDeclinaisonActif:boolean

  // produit
  produitId: number;

  //type produit fabrique
  fabrique:boolean;


}
