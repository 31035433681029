import {Injectable, Optional} from '@angular/core';
import {SwPush, SwUpdate} from "@angular/service-worker";
import {VAPID_PUBLIC_KEY} from "../../constants";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class DolServiceWorkerService {

  constructor(private http: HttpClient,
              @Optional() private swUpdate: SwUpdate, @Optional() private swPush: SwPush) {

  }

  /**
   * Activer les notifications
   */
  enableNotifications(enableNotification: boolean) {

    if(this.swPush && this.swUpdate){
      //Le service worker n'est activé qu'en production
      if (environment.production ) {
        console.log('enableNotifications',enableNotification);
        if(enableNotification){

          this.subscribeToNotifications();
        }else{
          this.removePushSubscriber().subscribe();
        }
      }
    }


  }

  private subscribeToNotifications() {

    if(this.swPush){
      console.log('subscribeToNotifications');
      this.swPush.requestSubscription({serverPublicKey: VAPID_PUBLIC_KEY})
        .then(sub => {
          console.log("Notification subscription", sub);

          this.addPushSubscriber(sub).subscribe(
            () => console.log('Sent push subscription object to server'),
            err => console.log('Could not send  subscription object to server, reason :', err)
          );
        })
        .catch(err => console.log("Could not subscribe to notifications", err));
    }
  }

  /**
   * Enregistrer le subscriber côté server
   * @param sub
   * @returns {Observable<Object>}
   */
  private addPushSubscriber(sub: any) {

    return this.http.post('dolrest/notifications/addPushSubscriber', sub);
  }

  private removePushSubscriber() {
    return this.http.delete('dolrest/notifications/removePushSubscriber');
  }

}
