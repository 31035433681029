import {AdresseDTO} from './adresse-dto';
import {ObjectDTO} from './object-dto';
import {UniteDeProduction__JourLivraisonDTO} from './unite-de-production__jour-livraison-dto';
import {UniteDeProduction__SecteurDTO} from './unite-de-production__secteur-dto';
import {UniteDeProduction__SecteurFournisseurDTO} from './unite-de-production__secteur-fournisseur-dto';
import {UniteDeProduction__JourFabricationDTO} from './unite-de-production__jour-fabrication';
import {SiteDTO} from './site-dto';
import {UniteDeProduction__JourApproDTO} from './unite-de-production__jour-appro';
import {ColumnsToHideDTO} from "./columns-to-hide.dto";
import {ContratMenuConviveDTO} from "./contratmenuconvive-dto";
import {DateReferenteUniteDeProductionEnum} from "../enums/date-referente-unite-de-production-enum";

export class UniteDeProductionDTO extends ObjectDTO {

  joursDecalageMax: number;
  libelle: string;
  actif: boolean;
  code: string;
  adresseFacturation: AdresseDTO;
  adresseLivraison: AdresseDTO;

  signature: string;
  infosLivraison: string;
  infosFacturation: string;
  hideFinancialDataCommands: boolean;

  uniteDeProduction__jourLivraisonList: UniteDeProduction__JourLivraisonDTO[] = [];
  uniteDeProduction__jourFabricationList: UniteDeProduction__JourFabricationDTO[] = [];
  uniteDeProduction__jourApproList: UniteDeProduction__JourApproDTO[] = [];
  uniteDeProduction__secteurList: UniteDeProduction__SecteurDTO[] = [];
  uniteDeProduction__secteurFournisseurList: UniteDeProduction__SecteurFournisseurDTO[] = [];
  columnsToHideList: ColumnsToHideDTO[] = [];

  hasEstampilleVeterinaire: boolean;

  offresAlimentairesPrestations: ContratMenuConviveDTO[] = [];

  egalimThresholdBio: number;

  egalimThresholdSustainableProducts: number;

  dateReferente: DateReferenteUniteDeProductionEnum;

  constructor(id: number, site?: SiteDTO) {
    super();

    this.id = id;
    this.hasEstampilleVeterinaire = false;
    if (site) {
      this.adresseFacturation = new AdresseDTO(site, id);
      this.adresseLivraison = new AdresseDTO(site, id);
      this.actif = true;
    }

  }
}
