import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {ContratMenuConvive__ModeleNutriDTO} from '../../dtos/contratmenuconvive__modele-nutri-dto';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {catchError} from 'rxjs/operators';
import * as moment from 'moment';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from '../../constants';


export const URL_GET_LOAD_PLANNING= `dolrest/gestioncontrats/cmc-mn/planning`;
export const URL_GET_REMOVE_PLANNING= `dolrest/gestioncontrats/cmc-mn/planning/remove`;

/**
 * ContratMenuConvive__ModeleNutri Service
 */
@Injectable({
  providedIn: 'root'
})
export class CmcMnService extends GenericHandler<ContratMenuConvive__ModeleNutriDTO> {


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService
  ) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return '';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }


  getEntityName(): string {
    return 'ContratMenuConvive__ModeleNutri';
  }

  getFields(dto: ContratMenuConvive__ModeleNutriDTO): FormFieldBaseSupplier<any>[] {

    return undefined;

  }


  getAllFromEnvironnement(): void {
  }


  createEmptyDTO(): ContratMenuConvive__ModeleNutriDTO {
    const cmcMn = new ContratMenuConvive__ModeleNutriDTO();
    cmcMn.id=0;
    return cmcMn;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  /**
   * Filtrer par prestation (contrat menu convive)
   * Trier par date d'application et modele nutritionnel
   * Les prestations et les modeles nutritionnels doivent être actifs
   * @param idCmc id de la prestation (ContratMenuConvive = cmc)
   */
  filterByPrestation(idCmc:number):GenericRequestSupplier{

    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `${this.getEntityName().toLowerCase()}.contratMenuConvive.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idCmc + '';

    const predicat2 = new Predicat();
    predicat2.path = `${this.getEntityName().toLowerCase()}.contratMenuConvive.actif`;
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    const predicat3 = new Predicat();
    predicat3.path = `${this.getEntityName().toLowerCase()}.modeleNutritionnel.actif`;
    predicat3.operator = PREDICAT_OPERATOR.Equals;
    predicat3.type = PREDICAT_TYPE.Boolean;
    predicat3.value = PREDICAT_VALUE.True;

    search.predicats= [predicat1,predicat2,predicat3];

    const sort1 = new Sort();
    sort1.dir = PREDICAT_DIR.Descendant;
    sort1.path =`${this.getEntityName().toLowerCase()}.dateDebut`;

    const sort2 = new Sort();
    sort2.dir = PREDICAT_DIR.Ascendant;
    sort2.path =`${this.getEntityName().toLowerCase()}.modeleNutritionnel.libelle`;

    search.sorts = [sort1,sort2];

    grs.search = search;

    return grs;
  }

  /**
   * Charger le planning de menu associé à cette perdioe
   * @param idCmcMn idContratMenuConvive_modeleNutri
   * @param type 1 = planning structure, 2 = planning plc taux de prise
   * @param idPlc id du point de livraison requis ssi type=2
   * @return {Observable<any>}
   */
  loadPlanning(idCmcMn: number,idPlc:number, type:number) {

    return this.http.get(URL_GET_LOAD_PLANNING,{params:new HttpParams()
        .set('idCmcMn',idCmcMn+'')
        .set('idPlc',idPlc ? idPlc+'': '0')
        .set('type',type+'')
    } )
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }

  removePeriode(selectedPeriode:ContratMenuConvive__ModeleNutriDTO){

    return this.http.get(URL_GET_REMOVE_PLANNING,{params:new HttpParams().set('idCmcMn',selectedPeriode.id+'')} )
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }

  getPeriodeLabelHTML(periode: ContratMenuConvive__ModeleNutriDTO) {

    let periodeLabel = '';

    if(!this.utils.isNullOrEmpty(periode)){
      if(periode.id===0){
        periodeLabel = '<i class="far fa-calendar-plus mg-r-5 pn-border-color"></i>Ajouter une période';
      }else if(this.utils.isNullOrEmpty(periode.dateFin)){
        periodeLabel = `<i class="far fa-calendar mg-r-5 pn-border-color"></i>à partir du ${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateDebut))} <span class="font-10">(${periode.modeleNutritionnelLibelle})</span>`;
      }else{
        periodeLabel = `<i class="far fa-calendar mg-r-5 pn-border-color"></i>${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateDebut))} au ${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateFin))}  <span class="font-10">(${periode.modeleNutritionnelLibelle})</span>`;
      }
    }

    // console.log(periodeLabel);

    return periodeLabel;

  }

  getPeriodeLabelStr(periode: ContratMenuConvive__ModeleNutriDTO) {

    let periodeLabel = '';

    if(!this.utils.isNullOrEmpty(periode)){
      if(periode.id===0){
        periodeLabel = 'Ajouter une période';
      }else if(this.utils.isNullOrEmpty(periode.dateFin)){
        periodeLabel = `à partir du ${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateDebut))}</span>`;
      }else{
        periodeLabel = `${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateDebut))} au ${this.utils.getFrenchDateDDMMYYYY(moment(periode.dateFin))} (${periode.modeleNutritionnelLibelle})`;
      }
    }

    return periodeLabel;

  }


}
