import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {UtilsService} from '../../core/utils/utils.service';
import {TabMenu} from 'primeng/tabmenu';
import {Router} from '@angular/router';
import {ItemNavigationService} from '../../core/services/technique/item-navigation.service';

@Component({
  selector: 'yo-bc',
  templateUrl: './bc.component.html',
  styleUrls: ['./bc.component.scss']
})
export class BcComponent implements OnInit, OnDestroy {

  bcfStatuts: MenuItem[];
  activeItem: MenuItem;

  subHasFactu: Subscription;
  subHasStocks: Subscription;
  subHasAdmin: Subscription;
  subHasCommandes: Subscription;
  subPrimaryRoute: Subscription;

  @ViewChild('tabMenu') tabMenu: TabMenu;

  constructor(private auth2Svc: Auth2Service,
              private utils: UtilsService,
              private router: Router,
              private itemNavSvc: ItemNavigationService) {
  }

  ngOnInit() {
    // initialiser les tab selon les droits de l'utilisateur
    this.initTabs();
    // selectionner l'item actif selon la route demandée
    this.activePrimaryRouteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subHasStocks);
    this.utils.unsubscribe(this.subHasFactu);
    this.utils.unsubscribe(this.subHasAdmin);
    this.utils.unsubscribe(this.subPrimaryRoute);
  }

  initTabs = () => {
    const propositionsCommande: MenuItem = {label: 'Propositions', icon: 'fas fa-file-invoice', routerLink: ['propositions']};
    const commandesRefusees: MenuItem = {label: 'Refusées', icon: 'fas fa-file-invoice', routerLink: ['refusees']};
    const commandesConfirmees: MenuItem = {label: 'À valider', icon: 'fas fa-file-invoice', routerLink: ['a-valider']};
    const commandesAReceptionner: MenuItem = {label: 'À réceptionner', icon: 'fas fa-file-invoice', routerLink: ['a-receptionner']};
    const commandesReceptionnees: MenuItem = {label: 'Réceptionnées', icon: 'fas fa-file-invoice', routerLink: ['receptions']};
    const commandesAFacturer: MenuItem = {label: 'Facturées', icon: 'fas fa-file-invoice', routerLink: ['facturees']};
    const tableauDeBord: MenuItem = {label: 'Tableau de bord', icon: 'fas fa-tachometer-alt', routerLink: ['tableau-de-bord']};

    this.bcfStatuts = [];

    this.subHasCommandes = this.auth2Svc.hasGestionCommandes$.subscribe(response => {
      if (response) {
        this.bcfStatuts.push(propositionsCommande);
        this.bcfStatuts.push(commandesRefusees);
        this.bcfStatuts.push(commandesConfirmees);
      }
    });

    this.subHasStocks = this.auth2Svc.hasStocks$.subscribe(response => {
      if (response) {
        this.bcfStatuts.push(commandesAReceptionner);
        this.bcfStatuts.push(commandesReceptionnees);
      }
    });
    this.subHasFactu = this.auth2Svc.hasFacturation$.subscribe(response => response ? this.bcfStatuts.push(commandesAFacturer) : '');
    this.subHasAdmin = this.auth2Svc.hasAdministration$.subscribe(response => response ? this.bcfStatuts.unshift(tableauDeBord) : '');
    this.activeItem = this.bcfStatuts[0];
  };

  /**
   * selectionner l'item actif selon la route demandée
   */
  activePrimaryRouteSubscription = () => {
    this.subPrimaryRoute = this.itemNavSvc.primaryRoute$.subscribe((event) => {
      this.activeItem = this.itemNavSvc.activePrimaryRoute(event, this.bcfStatuts);
    });
  };
}
