import {Injectable} from '@angular/core';
import {SiteDTO} from '../../dtos/site-dto';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';
import {RepasService} from './repas.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';


@Injectable()
export class ContratsMenusConvivesRepasService  extends GenericHandler<ContratMenuConviveRepasDTO>{

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp() : DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getSort(): string[] {
    return ['idJourSemaine,asc'];
  }

  getTitle(): string {
    return 'GESTION DES REPAS';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  constructor(private repasSvc:RepasService,
              utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,title:Title) {
    super(utils, auth2Svc, router, http,title);
  }

  getEntityName(): string {
    return 'contratMenuConviveRepas';
  }

  getFields(dto: ContratMenuConviveRepasDTO): FormFieldBaseSupplier<any>[] {

    if(this.utils.isNullOrEmpty(dto)){
      dto = new ContratMenuConviveRepasDTO();
      // dto.site = this.auth2Svc.utilisateur.sitePrincipal as SiteDTO;
    }

    const isSitePrincipal = this.auth2Svc.isSiteLocal(dto.site.id);

    let formFields: FormFieldBaseSupplier<any>[] = [


      // new FormFieldTextboxSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: true,
      //   value: dto.site,
      //   order: 1
      // }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !isSitePrincipal,
        value: dto.actif,
        required: true,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {

  }

  createEmptyDTO(): ContratMenuConviveRepasDTO {
    let cmcr = new ContratMenuConviveRepasDTO();
    cmcr.site = new SiteDTO();
    // cmcr.site.libelle = this.auth2Svc.utilisateur.sitePrincipal.libelle;
    // cmcr.site.id = this.auth2Svc.utilisateur.sitePrincipal.id;
    return cmcr;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }




}
