import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {ContratMenuConvive__PlcDTO} from '../../dtos/contratmenuconvive__plc-dto';
import {startCase as _startCase} from 'lodash';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import {catchError} from 'rxjs/operators';


export const URL_POST_SAVE_CMC_PLC =`dolrest/gestion-clients/point-de-livraison/contrat-menu-convive`;


@Injectable({
  providedIn: 'root'
})
export class CmcPlcService  extends GenericHandler<ContratMenuConvive__PlcDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService
  ) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return '';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }


  getEntityName(): string {
    return 'ContratMenuConvive__Plc';
  }

  getFields(dto: ContratMenuConvive__PlcDTO): FormFieldBaseSupplier<any>[] {

    return undefined;

  }


  getAllFromEnvironnement(): void {
  }


  createEmptyDTO(): ContratMenuConvive__PlcDTO {
    return undefined;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  saveCmcPlc(cmcDto: ContratMenuConviveDTO, plcDtoList : PointDeLivraisonDTO[]){

   return  this.http.post(URL_POST_SAVE_CMC_PLC,plcDtoList, {
      params:new HttpParams()
        .set('cmcId',cmcDto.id+'')
    }) .pipe(
      catchError(error => this.utils.handleError(error))
    );
  }
}
