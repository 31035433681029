<dx-popup
  [showTitle]="true"
  [title]="'Impression coût matière'"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="dx-field">
    <div class="dx-field-label">Du :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de début"
        type="date"
        [value]="startDate"
        (onValueChanged)="startDateChanged($event)">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">au :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de fin"
        type="date"
        [value]="endDate"
        (onValueChanged)="endDateChanged($event)"
        [min]="minDate">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Régimes :</div>
    <div class="dx-field-value">
      <dx-select-box
        placeholder="Choisir un régime"
        displayExpr="label"
        valueExpr="value"
        [items]="regimeOptionList"
        [(value)]="selectedRegimeId"
        (onValueChanged)="regimeChanged($event)"
      ></dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Repas :</div>
    <div class="dx-field-value">
      <dx-tag-box
        searchEnabled="true"
        searchExpr="viewValue"
        [showSelectionControls]="true"
        [maxDisplayedTags]="3"
        [multiline]="false"
        [showMultiTagOnly]="false"
        placeholder="Sélection..."
        [dataSource]="repasList"
        [(value)]="repasListResult"
        (onValueChanged)="repasChanged($event)"
        displayExpr="libelle"
        valueExpr="id"
      >
      </dx-tag-box>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button class="mr-2" label="Imprimer" [disabled]="this.isValidForm()" iconClass="fas fa-print"
               (onClick)="printCoutMatiere()">
    </yo-button>

    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (onClick)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>
