<div class="card-size">

  <div class="mg-b-10">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>

    <yo-button
      class="mg-r-15"
      label="CRÉER"
      iconClass="fa fa-plus"
      tooltip="Création d'un lot marché"
      (onClick)="openEditLotMarche(undefined)"
      [disabled]="!canCreate()">
    </yo-button>
  </div>
</div>

<dx-data-grid
  [dataSource]="lotMarcheList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(240)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" alignment="center" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Lot marché" [width]="300" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code" [width]="150" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="descriptif" caption="Description" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateDebut" alignment="center" caption="Date de début" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateFin" alignment="center" caption="Date de fin" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="valeurEngagement" alignment="center" caption="Engagement (€)" [width]="140"
              cellTemplate="engagementPrixCellTemplate" [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="actif" alignment="center" caption="Actif" [width]="80"
              [allowFiltering]="true">
  </dxi-column>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditLotMarche(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="canModify() ? 'fa fa-edit' : 'fa fa-eye'"
                    [yoTooltip]="(canModify() ? 'Modifier' : 'Voir') + ' le lot marché'">
    </yo-cell-button>
    <yo-cell-button *ngIf="canModify()"
                    (yoNavigation)="openDeleteLotMarche(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer le lot marché"
    ></yo-cell-button>
  </div>

  <!--    TEMPLATE ENGAGEMENT PRIX-->
  <div *dxTemplate="let cell of 'engagementPrixCellTemplate'">
    {{cell.row.data.valeurEngagement  | currency:'EUR':'symbol':'1.4-4'}}
  </div>

  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} lots marché">
  </dxo-pager>

</dx-data-grid>

<yo-dialog-lot-marche></yo-dialog-lot-marche>


