import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash'

@Injectable()
export class ContratsMenusConvivesDecoupagesService  extends GenericHandler<ContratMenuConviveDecoupageDTO>{

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp() : DialogMsgSupplier {
    return undefined;
  }
  getSort(): string[] {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES COMPOSANTES REPAS PAR CONTRAT';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }

  constructor(utils: UtilsService,
              private gds:GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient,title:Title) {
    super(utils, auth2Svc, router, http,title);


  }
  getEntityName(): string {
    return 'ContratMenuConviveDecoupage';
  }

  getFields(dto: ContratMenuConviveDecoupageDTO): FormFieldBaseSupplier<any>[] {
   return undefined;
  }

  getAllFromEnvironnement(): void {
    console.log("Cmcd#getAllFromEnvironnement()");
  }

  createEmptyDTO(): ContratMenuConviveDecoupageDTO {
    return new ContratMenuConviveDecoupageDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

}
