import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {
  GemrcnHelperSupplier,
  GestionGemrcnService,
  PlanAlimGemrcn
} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ActivatedRoute} from '@angular/router';
import {DATEPICKER_FR} from '../../../core/constants';
import * as moment from 'moment';
import {ModeleNutritionnelGroupeGemrcnService} from '../../../core/services/entities/modele-nutritionnel-groupe-gemrcn.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {transAnimationEnter} from '../../../shared/ui/animations';

@Component({
  selector: 'yo-gemrcn',
  templateUrl: './gemrcn.component.html',
  styleUrls: ['./gemrcn.component.scss'],
  animations: [
    trigger('animateTransition', [
      transition(':enter', [
        useAnimation(transAnimationEnter, {
          params: {
            opacityStart: 0,
            opacityEnd: 1,
            maxHeight: '500px',
            time: '0.7s',
          }
        })
      ])
    ])
  ]
})
export class GemrcnComponent implements OnInit, OnDestroy {

  private subRoute: Subscription;
  ghs: GemrcnHelperSupplier;

  colFrequence = 120;
  colFamilleGemrcnWidth = 400;

  localeFr = DATEPICKER_FR;

  readonly CHOIX_DIRIGE = 1;
  readonly CHOIX_MULTIPLE = 2;

  constructor(public utils: UtilsService,
              public mnGroupeGemrcnSvc: ModeleNutritionnelGroupeGemrcnService,
              public ggSvc: GestionGemrcnService,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.initRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  initRouteData = () => {
    this.subRoute = this.route.data
      .subscribe((data: { gemrcnSupplier: GemrcnHelperSupplier }) => {
        this.ghs = data.gemrcnSupplier;
      });
  };

  isGridDisplayable = () => {
    if (!this.utils.isNullOrEmpty(this.ghs) && !this.utils.isCollectionNullOrEmpty(this.ghs.plansAlimentaires)) {
      return true;
    }
    return false;
  };


  changeDates = ($event: any) => {
    const dateDeb = this.ghs.dateDebEtFin[0];
    const dateFin = this.ghs.dateDebEtFin[1];

    if (!this.utils.isNullOrEmpty(dateDeb) && !this.utils.isNullOrEmpty(dateFin)) {
      this.ggSvc.getAnalyseGemrcnPromise(this.ghs.prestation.id, this.ghs.contrainteAlimentaire.id, dateDeb, dateFin).then(response => {
        this.ghs = response;
      });
    }
  };

  getPeriodeLabel = (planAlim: PlanAlimGemrcn) => {
    let periode = '';
    const dateDeb = this.utils.convertNumberDateToDate(planAlim.dateDebut);
    if (!this.utils.isNullOrEmpty(planAlim.dateFin)) {
      const dateFin = this.utils.convertNumberDateToDate(planAlim.dateFin);
      periode = `du ${this.utils.getFrenchDateDDMMYYYY(moment(dateDeb))} au ${this.utils.getFrenchDateDDMMYYYY(moment(dateFin))}`;
    } else {
      periode = `à partir du ${this.utils.getFrenchDateDDMMYYYY(moment(dateDeb))}`;
    }
    return periode;
  };

  keyRegleGemrcn = (familleGemrcnId: number, modeleNutritionnelGroupeGemrcnId: number) => familleGemrcnId + '-' + modeleNutritionnelGroupeGemrcnId;

  getRegleGemrcn = (planAlimGemrcn: PlanAlimGemrcn, familleGemrcnId: number, cmcMnGroupeGemrcnId: number) => {
    const keyRegleGemrcn = this.keyRegleGemrcn(familleGemrcnId, cmcMnGroupeGemrcnId);
    let regleGemrcn = undefined;
    if (!this.utils.isNullOrEmpty(planAlimGemrcn)) {
      regleGemrcn = planAlimGemrcn.cellulePlanAlimGemrcnMap[keyRegleGemrcn];
    }
    return regleGemrcn;
  };


  isPeriodeGemrcnExists = (pa: PlanAlimGemrcn) => {
    if(this.utils.isCollectionNullOrEmpty(pa.lignePlanAlimGemrcnList) || this.utils.isCollectionNullOrEmpty(pa.colonnePlanAlimGemrcnList)){
      return false;
    }
    return true;
  };
}

