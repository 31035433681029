import {MJourDecalage} from '../../../../../core/models/gestion-unites-production/m-jour-decalage';

export class JoursDecalageSupplier {

  selectedJourDecalage:MJourDecalage;
  joursDecalageList:MJourDecalage[];



  constructor(selectedJourDecalage: MJourDecalage, joursDecalageList: MJourDecalage[]) {
    this.selectedJourDecalage = selectedJourDecalage;
    this.joursDecalageList = joursDecalageList;

  }
}
