import {ObjectDTO} from './object-dto';
import {AppellationDTO} from "./appellations-dto";

export class BonReceptionLigneAppellationDTO extends ObjectDTO {

  // bon reception ligne
   idBonReceptionLigne : number;

  // litige
  idAppellation : number;
  libelleAppellation : string;

  appellation: AppellationDTO;


}
