import {ObjectDTO} from "./object-dto";

export class SiteDTO extends ObjectDTO{
  id: number;
  libelle: string;
  reference: string;
  idAdresse: number;

  actif: boolean;

  // Champs liés à l'Adresse du Site.
  ville: string;

  codePostal: string;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  telephone: string;
  fax: string;
  siret: string;
  codeTva: string;
  iban: string;
  email: string;

  /**
   * Id de l'unité de production du site.
   */
  idUniteDeProduction: number;


  constructor() {
    super();
    this.id = 0;
    this.libelle = "";
    this.actif = true;
  }
}
