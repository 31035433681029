import {Injectable} from '@angular/core';

import {PREFIX_CODE_DOCUMENT_IMAGE_PRODUIT} from "../constants";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../utils/utils.service";
import {ProduitDTO} from "../dtos/produit-dto";


export const URL_REMOVE_BY_ENTITY = `dolrest/document/removeByEntity`;

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient, private utils: UtilsService) {
  }

  /**
   * Création d'un code pour le {@link ProduitDTO} passé en paramètre.
   * C'est la concatenation de la chaîne "ImageProduit" (cf.PREFIX_CODE_DOCUMENT_IMAGE_PRODUIT) avec l'id du {@link ProduitDTO} passé en paramètre.
   * @param {ProduitDTO} produit
   * @returns {string}
   * @deprecated
   */
  public creationCode(produit: ProduitDTO): string {
    let code = PREFIX_CODE_DOCUMENT_IMAGE_PRODUIT + produit.id;
    return code;
  }



  /**
   * Chargement de l'image associée à un produit
   * @param {ProduitDTO} produit le {ProduitDTO} concerné
   * @param {boolean} defaultImage dans le cas où le {ProduitDTO} n'a pas d'image associée, ce paramètre
   * indique s'il faut ou non charger l'image par défaut
   * @returns {Observable<Object>}
   * @deprecated
   */
  loadImage(produit: ProduitDTO, defaultImage: boolean) {
    let codeSite = produit.site.reference;
    let codeDocument = this.creationCode(produit);
    let url = `dolrest/document/loadImageBase64/${codeSite}/${codeDocument}?download=true&default=${defaultImage}`;
    return this.http.get(url);
  }

  removeByEntity(entityName:string,entityId:number,ordre:number){

    const url =`${URL_REMOVE_BY_ENTITY}/${entityName}/${entityId}/${ordre}`;

    return this.http.post(url,{}).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  }
}
