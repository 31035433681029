import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {PreferenceutilisateurDTO} from '../../core/dtos/preferenceutilisateur-dto';

@Injectable()
export class MenusettingsResolverService implements Resolve<MenuSettingsSupplier> {


  constructor(private prefUserSvc: PreferencesUtilisateurService,
              private auth2Svc: Auth2Service) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuSettingsSupplier> | Promise<MenuSettingsSupplier> | MenuSettingsSupplier {

    let msSupplier = new MenuSettingsSupplier();

    return this.prefUserSvc.getMyPreferences(this.auth2Svc.utilisateur.username)
      .toPromise()
      .then(res => {
        const preferences: any = res.one;
        msSupplier.prefUserDTOs = preferences?.preferencesUtilisateur;
        return msSupplier;
      });
  }
}

export class MenuSettingsSupplier {
  prefUserDTOs: PreferenceutilisateurDTO[];
}
