import {FamilleNutritionnelleProduitDTO} from './famillenutritionelle-produit-dto';
import {ProduitAllergeneDTO} from './produit-allergene-dto';
import {MenuDecoupageDateDTO} from './menudecoupagedate-dto';
import {ProduitAppellationDTO} from "./produit-appellation-dto";
import {ObjectDTO} from "./object-dto";
import {ErreurFonctionnelleDto} from "./erreur-fonctionnelle-dto";
import {ProduitApportNutritionnelDTO} from "./produit-apportnutritionnel-dto";
import {RegimeAlimentaireDTO} from './regime-alimentaire-dto';
import {TextureDTO} from './texture-dto';
import {MenuCompositionRecopieDTO} from "./menucomposition-recopie-dto";

export class MenuCompositionDTO extends ObjectDTO {


  // menu composition
  ordre: number;
  effectifPrevu: number;
  effectifReel: number;
  tauxDePrise: number;
  coutEffectifPrevu: number;
  coutPortionPondereEffPrev: number;
  menuCompositionRecopieList:MenuCompositionRecopieDTO[] =[];

  // produit declinaison
  produitDeclinaisonId: number;
  produitDeclinaisonLibelle: string;
  produitDeclinaisonPrixAchat: number;

  dishWithoutFavouritesItems: boolean;

  // ID de la contrainte alimentaire
  cmcContrainteAlimId: number;

  // Partie RegimeAlimentaire de la contrainte alimentaire.
  regimeAlimentaire: RegimeAlimentaireDTO;

  // Partie Texture de la contrainte alimentaire.
  texture: TextureDTO;

  // produit
  produitId: number;
  produitLibelle: string;

  // type produit fabrique
  fabrique: boolean;

  // type produit
  typeProduitId: number;

  // declinaison
  declinaisonId: number;
  declinaisonLibelle: string;

  // famille produit
  familleProduitId: number;

  // menu decoupage date
  menuDecoupageDate: MenuDecoupageDateDTO;

  // contrat menu convive decoupage
  cmcdId: number;

  // repas
  repasLibelle: string;

  // decoupage repas
  decoupageRepasLibelle: string;


  // familles GEMRCN rattachées au produit declinaison
  familleNutritionnelleProduitDTOList: FamilleNutritionnelleProduitDTO[];

  // produit allergene
  produitAllergeneDTOList: ProduitAllergeneDTO[];

  // produit appellation
  produitAppellationDTOList: ProduitAppellationDTO[];

  // produit apports nutris
  produitApportNutriDTOList: ProduitApportNutritionnelDTO[];

  // ??
  menuCompositionErreurList: ErreurFonctionnelleDto[];

  // produit mode operatoire
  modeOperatoire: string;

  // prix d'achat du plat sur l'unité de production. L'unité de production est récupéré par la prestation > site > unité de producyion
  udpPdPrixAchat: number;
  udpPdEnErreur: boolean;
  udpPdErreurs: ErreurFonctionnelleDto[];

}

