import {ObjectDTO} from "./object-dto";

export class ProduitDeclinaisonContrainteAlimDTO extends ObjectDTO {

  //    produit
  produitDeclinaisonId: number;
  produitDeclinaisonLibelle: string;

  // regime
  regimeCode: string;
  regimeLibelle: string;
  regimeId: number;
  regimeActif: boolean;

  // texture
  textureCode: string;
  textureLibelle: string;
  textureId: number;
  textureActif: boolean;

}
