import {Observable, of} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SecteurDTO} from '../../dtos/secteur-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {SecteurFournisseurDTO} from '../../dtos/secteurfournisseur-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {UniteDeProduction__LivraisonDTO} from '../../dtos/unite-de-production__livraison-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {HttpService} from "../technique/http.service";

export const URL_FIND_SECTEURSFOURNISSEURS_BY_SECTEURS = 'dolrest/gestion-fournisseurs/find/secteursFournisseursBySecteurs';
export const URL_FIND_LIVRAISONS_BY_UNITEDEPRODUCTION = 'dolrest/gestion-fournisseurs/find/livraisonsOfUDP';
export const URL_GET_SEARCH_SECTEURS_FOURNISSEURS = 'dolrest/gestion-fournisseurs/secteurfournisseur/search';

@Injectable()
export class SecteursFournisseursService extends GenericHandler<SecteurFournisseurDTO> {

  /**
   * Contient la liste de TOUS les SecteurFournisseurDTO de la base de données.
   */
  allSecteursFournisseurs: SecteurFournisseurDTO[];

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private httpSvc: HttpService
              ) {
    super(utils, auth2Svc, router, http, title);
    this.getAllSecteursFournisseurs();
    this.environnement$.subscribe(() => {
      this.getAllSecteursFournisseurs();
    });
  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['code,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES SECTEURS FOURNISSEURS';

  getCreateNewObjectLabel = (): string => 'CRÉER UN SECTEUR FOURNISSEUR';

  getEntityName = (): string => 'SecteurFournisseur';

  getFields = (dto: SecteurFournisseurDTO): FormFieldBaseSupplier<any>[] => undefined;

  openDeleteDialog = (dto: SecteurFournisseurDTO) => {

  };

  /**
   * Récupérer tous les secteurs fournisseurs secteurFournisseurRows qui correspondent aux secteurs passés en paramètre.
   * @param {SecteurDTO[]} secteurList
   */
  findSecteursFournisseursBySecteurs = (secteurList: SecteurDTO[]): Observable<ResponseWrapper<SecteurFournisseurDTO>> => {
    console.log(secteurList)
    console.log(this.utils.isCollectionNullOrEmpty(secteurList))
    if (this.utils.isCollectionNullOrEmpty(secteurList)) {
      return of(null);
    }

    const idSecteurList = [];
    secteurList.forEach( item =>{
      idSecteurList.push(item.id);
    });

    const params: HttpParams = new HttpParams().set("idSecteurList", idSecteurList.join(','));

    return this.httpSvc.get(URL_FIND_SECTEURSFOURNISSEURS_BY_SECTEURS, params);
  };



  /**
   * Récupérer le parametrage livraison d'un unité de production
   *
   * @param idUniteDeProduction
   * @return {Observable<any>}
   */
  findLivraisonsOfUniteDeProduction = (idUniteDeProduction: number): Observable<ResponseWrapper<UniteDeProduction__LivraisonDTO>> => {
    if (!this.utils.isNumberGt0(idUniteDeProduction)) {
      return of(null);
    }
    return this.http.get<ResponseWrapper<UniteDeProduction__LivraisonDTO>>(URL_FIND_LIVRAISONS_BY_UNITEDEPRODUCTION, {
      params: new HttpParams().set('idUniteDeProduction', idUniteDeProduction.toString())
    }).pipe(
      catchError(error => this.utils.handleError(error)))
  };

  getAllSecteursFournisseurs = () => {
    this.allSecteursFournisseurs = [];
    this.http.get(URL_GET_SEARCH_SECTEURS_FOURNISSEURS, {
      // params: new HttpParams().set('siteIds', this.auth2Svc.utilisateur.sitePrincipal.id)
    })
      .pipe(
        catchError(err => of(err))
      ).subscribe(response => {
      this.allSecteursFournisseurs = response.resultList as SecteurFournisseurDTO[];

      if (!this.utils.isCollectionNullOrEmpty(this.allSecteursFournisseurs)) {
        this.allSecteursFournisseurs = this.allSecteursFournisseurs.map(item => {
          SecteursFournisseursService.addLibelle(item);
          return item;
        });
      }

      this.announceObjects(this.allSecteursFournisseurs);
    });

  };

  /**
   * Ajout d'un libelle à un {@link SecteurFournisseurDTO}.
   * Ce libelle est obtenu comme concaténation des champs suivants du {@link SecteurFournisseurDTO}
   * - fournisseurLibelle (en majuscules)
   * - secteur.libelle (en majuscules)
   * @param item
   */
  public static addLibelle = (item: SecteurFournisseurDTO): void => {
    item.libelle = item.fournisseurLibelle.toUpperCase()+' '+item.secteur.libelle.toUpperCase() ;
  };

  getAllFromEnvironnement = (): void => {
    this.getAllSecteursFournisseurs();
  };

  createEmptyDTO = (): SecteurFournisseurDTO => new SecteurFournisseurDTO(0, null);

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE SECTEUR FOURNISSEUR '${data.libelle.toUpperCase()}'`;

}
