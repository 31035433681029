import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  RegimeAlimentaireSavedSupplier,
  RegimeAlimentaireService
} from '../../core/services/entities/regime-alimentaire.service';
import {RegimeAlimentaireDTO} from "../../core/dtos/regime-alimentaire-dto";
import {UtilsService} from "../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {saveAs} from "file-saver";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {ToastService} from "../../core/services/technique/toast.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-regimes-alimentaires',
  templateUrl: './regimes-alimentaires.component.html',
  styleUrls: ['./regimes-alimentaires.component.scss']
})
export class RegimesAlimentairesComponent implements OnInit, OnDestroy {

  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  regimes: RegimeAlimentaireDTO[] = [];
  selectedRows: number[] = [];
  errors: any[] = [];

  isLogged: boolean = false;
  displayImportPopupErrors: boolean = false;
  displayDeletePopupErrors: boolean = false;

  subLog: Subscription;
  subRegimeAlimentaireSaved: Subscription;
  subRegimesAlimentaires: Subscription

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/regimes-alimentaires/regimes-alimentaires';

  constructor(public regimeAlimentaireSvc: RegimeAlimentaireService,
              public utilsSvc: UtilsService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private i8nSvc: InternationalizationService,
              private graphQlSvc: GraphQLService
  ) {
  }

  ngOnInit() {
    this.initLoggedSubscription();
    this.initRegimeAlimentaireSubscription();
    this.initRegimeAlimentaireSavedSubscription();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subLog);
    this.utilsSvc.unsubscribe(this.subRegimeAlimentaireSaved);
    this.utilsSvc.unsubscribe(this.subRegimesAlimentaires);
  }

  initLoggedSubscription = (): void => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.isLogged = res);
  }

  initRegimeAlimentaireSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subRegimesAlimentaires = this.graphQlSvc.sendQuery(`
      {
        allRegimesAlimentaires(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            site {
                id,
                libelle
            }
        }
      }
    `)
      .subscribe(response => {
        this.regimes = response.allRegimesAlimentaires;
      });
  }

  initRegimeAlimentaireSavedSubscription = (): void => {
    this.subRegimeAlimentaireSaved = this.regimeAlimentaireSvc.regimeAlimentaireSaved$
      .subscribe((response: RegimeAlimentaireSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.regimes.findIndex(regime => regime.id === response.regimeAlimentaire.id);
          this.regimes[idx] = response.regimeAlimentaire;
        } else {
          this.regimes.push(response.regimeAlimentaire);
        }
      });
  };

  openDialogEdition = (regimeAlimentaire: RegimeAlimentaireDTO): void => {
    this.regimeAlimentaireSvc.announceOpenDialogEdition(regimeAlimentaire);
  };

  calculateSiteValue = (regimeAlimentaire: RegimeAlimentaireDTO): string => {
    return regimeAlimentaire?.site?.libelle
  };

  exportXslx = () => {
    this.regimeAlimentaireSvc.exportRegimeAlimentaireByFilters(this.selectedRows).subscribe(response => {
      const filename = `regimes-alimentaires-export-${new Date().getTime()}.xlsx`;
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.selectedRows = [];
      saveAs(blob, filename);
    });
  }

  importXslx = async ($event: any) => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.regimeAlimentaireSvc.importRegimeAlimentaireFromXlsx(file)
        .subscribe(response => {
          const res: any = response.one;
          if (res.allElementsImported)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args)).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }
          for (const item of res.elementsImported) {
            let index = this.regimes.findIndex(equipe => equipe.id == item.id);
            if (index >= 0)
              this.regimes[index] = item;
            else
              this.regimes.push(item)
          }

          if (this.errors != null && this.errors.length > 0)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.displayImportPopupErrors = false;
    this.displayDeletePopupErrors = false;
  }

  deleteValues = async () => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.regimeAlimentaireSvc.deleteRegimeAlimentaireByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const labelsError: any = this.i8nSvc.getLabelFromCode(error.code, null);
            const infosLine: string = labelsError.replace('{}', error.args);
            this.errors.push({infosLine});
            if (error.args) {
              const elementToKeep = this.regimes.find(regime => regime.code === error.args);
              if (elementToKeep)
                idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
            }
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "REGIME_ALIMENTAIRE_DELETION_COMPLETED_PLURAL" : "REGIME_ALIMENTAIRE_DELETION_COMPLETED_SINGULAR", null));
        }
        this.regimes = this.regimes.filter(regime => !idsToDeleteInTable.find(id => id === regime.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;
}
