import {ObjectDTO} from "./object-dto";

export class FamilleNutritionnelleProduitDTO extends ObjectDTO{

//famille nutritionnelle produit
  idFamille: number;
  idProduitDecline: number;

//famille nutritionnelle
  familleNutritionnelleLibelle: string;
  familleNutritionnelleActif: boolean;

  // couleur
  familleNutritionnelleCouleurId:number;
  familleNutritionnelleCouleurHexa: string;

//produit declinaison
  produitDeclinaisonLibelle: string;

  //produit
  produitId:number;

//type produit fabrique
  fabrique: boolean;

}
