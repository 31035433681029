import {Component, Input} from "@angular/core";

@Component({
  selector: 'yo-tabs-errors-org-tournee',
  templateUrl: './tabs-errors-org-tournee.component.html',
  styleUrls: ['./tabs-errors-org-tournee.component.scss']
})
export class TabsErrorsOrgTourneeComponent {

  @Input() errors: any;

  displayTourneesOrPlcs = (tournees: any[]): string => {
    return tournees.map(t => t.libelle).join(",");
  }

}
