import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProduitDeclinaisonMarche} from '../../../../core/models/gestion-produits/marches/produit-declinaison-marche';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {MarcheProduitService} from '../../../../core/services/gestionproduits/marche-produit.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {MarcheIndicateur} from '../../../../core/models/gestion-produits/marches/marche-indicateur';
import {DevextremeService} from '../../../../core/services/technique/devextreme.service';
import {Subscription} from 'rxjs';
import {findIndex as _findIndex} from 'lodash'
import {DomSanitizer} from '@angular/platform-browser';
import {GestionsMarcheService} from '../../../../core/services/gestion-marche/gestions-marche.service';

@Component({
  selector: 'yo-marche-produit',
  templateUrl: './marche-produit.component.html',
  styleUrls: ['./marche-produit.component.scss']
})
export class MarcheProduitComponent implements OnInit, OnDestroy {


  @Input() uniteDeProduction: UniteDeProductionDTO;
  @Input() produitDeclinaisonMarche: ProduitDeclinaisonMarche;


  marcheIndicateur: MarcheIndicateur;
  idActiveMarche = 0;

  subMarcheEnCours: Subscription;


  constructor(private mpSvc: MarcheProduitService,
              public utils: UtilsService,
              public gestionMarcheSvc: GestionsMarcheService,
              public dxSvc: DevextremeService,
              public sanitizeSvc: DomSanitizer) {
  }

  ngOnInit(): void {

    // Par défaut, ouvrir le panel du 1er marché en cours
    this.marcheEnCoursSubscription();
  }

  /**
   * Par défaut, ouvrir le panel du 1er marché en cours
   */
  marcheEnCoursSubscription = () => {
    this.subMarcheEnCours = this.mpSvc.marcheEnCours$.subscribe(response => {

      if (!this.utils.isNullOrEmpty(this.uniteDeProduction) && !this.utils.isNullOrEmpty(response) && !this.utils.isCollectionNullOrEmpty(response.marcheEnCoursList)) {
        this.idActiveMarche = response.marcheEnCoursList[0].id;
        const firstIndexMarche = _findIndex(response.marcheList, {'id': this.idActiveMarche});
        if (firstIndexMarche != null || firstIndexMarche != undefined) {
          const event = {index: firstIndexMarche};
          this.onOpenMarche(event);
        }
      }

    });
  };


  onOpenMarche = (event: any) => {

    const marche = this.produitDeclinaisonMarche.marcheList[event.index];
    this.mpSvc.getMarcheIndicateurs(this.produitDeclinaisonMarche.produitDeclinaisonId, this.uniteDeProduction.id, marche.id).subscribe(response => this.marcheIndicateur = response.one);

  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subMarcheEnCours);
  }

  getRepartitionVolumeTotal = (marcheIndicateur: MarcheIndicateur) => marcheIndicateur.articleIndicateurList.map(item => item.volumeCourant).reduce((t, n) => t += n);

  getRepartitionMontantTotal = (marcheIndicateur: MarcheIndicateur) => marcheIndicateur.articleIndicateurList.map(item => item.montantCourant).reduce((t, n) => t += n);
}
