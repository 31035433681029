import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-primeng-editor-header',
  templateUrl: './primeng-editor-header.component.html',
  styleUrls: ['./primeng-editor-header.component.scss']
})
export class PrimengEditorHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
