import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {catchError} from 'rxjs/operators';
import {StockMouvementDTO} from '../../dtos/stock-mouvement-dto';
import {ModelViewMouvementDTO} from '../../../gestion-stocks/mouvements/model-view-mouvement';
import {HttpService} from "../technique/http.service";

export const URL_SEARCH_MOUVEMENTS = `dolrest/gestion-stocks/search/mouvements`;
export const URL_POST_PRINT_MOUVEMENTS = `dolrest/gestion-stocks/print/mouvements`;
export const URL_POST_SAVE_MOUVEMENT = `dolrest/gestion-stocks/save/mouvement`;

@Injectable({
  providedIn: 'root'
})
export class StockMouvementService extends GenericHandler<StockMouvementDTO> {

  private subjectResultSearchMouvements = new Subject<any>();
  resultSearchMouvements$ = this.subjectResultSearchMouvements.asObservable();

  private subjectPaginationSearchMouvement = new Subject<any>();
  paginationSearchMouvement$ = this.subjectPaginationSearchMouvement.asObservable();

  private subjectOpenDialogModificationMouvement = new Subject<DialogModificationMouvementSupplier>();
  openDialogModificationMouvement$ = this.subjectOpenDialogModificationMouvement.asObservable();

  private subOnSearchMouvements = new Subject<void>();
  onSearchMouvements$ = this.subOnSearchMouvements.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO(): StockMouvementDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: StockMouvementDTO): string {
    return "";
  }

  getEntityName(): string {
    return "StockMouvement";
  }

  getFields(dto: StockMouvementDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES MOUVEMENTS DE STOCK";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

  searchInMouvements(values: any, idsSites: number[], idsZonesStockagesSelected: number[], page: number, size: number) {

    this.subOnSearchMouvements.next();

    let args = '';
    if (values) {
      if(values.periode) {
        args += `&dateDeb=${this.utils.convertYYYYMMdd(values.periode[0].getTime())}`;
        args += `&dateFin=${this.utils.convertYYYYMMdd(values.periode[1].getTime())}`;
      }
      if (values.denominationArticle) args += `&denominationArticle=${values.denominationArticle}`;
      if (values.referenceInterneArticle) args += `&referenceInterneArticle=${values.referenceInterneArticle}`;
      if (values.mouvements) args += `&typesMouvements=${values.mouvements.map(type => type)}`;
      if (values.lotArticle) args += `&lotArticle=${values.lotArticle}`;
      if (values.lotArticleFournisseur) args += `&lotFournisseur=${values.lotArticleFournisseur}`;
      if (page !== undefined && page !== null && size) args += `&page=${page + 1}&size=${size}`;
      if (idsSites) args += `&sitesIds=${idsSites}`;
      if (values.filterEntree) args += `&searchEntree=true`;
      else if (values.filterSortie) args += `&searchEntree=false`;

      if (idsZonesStockagesSelected && idsZonesStockagesSelected.length) args += `&idsUniteProductionZonesStockage=${idsZonesStockagesSelected.join(',')}`;
    }
    return this.httpSvc.get(`${URL_SEARCH_MOUVEMENTS}?args=1&${args}`);
  }

  announceResultSearchMouvements(response: ResponseWrapper<ModelViewMouvementDTO>, filters, idsSitesSelected, idsZonesStockagesSelected) {
    this.subjectResultSearchMouvements.next({ response, filters, idsSitesSelected, idsZonesStockagesSelected });
  }


  announcePaginationSearchMouvement(param: { size: number; page: number }) {
    this.subjectPaginationSearchMouvement.next(param);
  }

  announceOpenDialogModificationMouvement(mvmDto: ModelViewMouvementDTO) {
    const dmms = new DialogModificationMouvementSupplier(mvmDto);
    this.subjectOpenDialogModificationMouvement.next(dmms);
  }

  printMouvements(ssw: SearchSupplierWrapper) {
    return this.http.post(URL_POST_PRINT_MOUVEMENTS, ssw, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  }

  saveMouvement = (viewMouvementDto: ModelViewMouvementDTO) => this.httpSvc.post(URL_POST_SAVE_MOUVEMENT, viewMouvementDto);

  exportMouvementsDetails(filters: any, idsSitesSelected: number[], idsZonesStockagesSelected: number[]) {
    let args = '';
    if (filters) {
      if(filters.periode) {
        args += `&dateDeb=${this.utils.convertYYYYMMdd(filters.periode[0].getTime())}`;
        args += `&dateFin=${this.utils.convertYYYYMMdd(filters.periode[1].getTime())}`;
      }
      if (filters.denominationArticle) args += `&denominationArticle=${filters.denominationArticle}`;
      if (filters.referenceInterneArticle) args += `&referenceInterneArticle=${filters.referenceInterneArticle}`;
      if (filters.mouvements) args += `&typesMouvements=${filters.mouvements.map(type => type)}`;
      if (filters.lotArticle) args += `&lotArticle=${filters.lotArticle}`;
      if (filters.lotArticleFournisseur) args += `&lotFournisseur=${filters.lotArticleFournisseur}`;
      if (idsSitesSelected) args += `&sitesIds=${idsSitesSelected}`;
      if (filters.filterEntree) args += `&searchEntree=true`;
      else if (filters.filterSortie) args += `&searchEntree=false`;

      if (idsZonesStockagesSelected && idsZonesStockagesSelected.length) args += `&idsUniteProductionZonesStockage=${idsZonesStockagesSelected.join(',')}`;
    }
    return this.http.get(`dolrest/gestion-stocks/bilan-mouvements-details?args=1&${args}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );;
  }

}

export class DialogModificationMouvementSupplier{

  mvmDto:ModelViewMouvementDTO;

  constructor(mvmDto:ModelViewMouvementDTO) {
    this.mvmDto = mvmDto;
  }
}
