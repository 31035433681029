import {ObjectDTO} from "./object-dto";
import {ETAPE_CALCUL_ARTICLES_PREFERES} from "../constants";

export class UniteDeProduction__ReglePrefereDTO extends ObjectDTO {

  // unité de production
  udpLibelle: string;
  udpId: number;

  // regle preferee
  reglePrefereLibelle: string;
  reglePrefereId: number;
  reglePrefereCode:string;

  calculEnCours:boolean;
  etape:ETAPE_CALCUL_ARTICLES_PREFERES;

}
