import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {GestionMenusSupplier} from '../gestionmenus-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';

@Component({
  selector: 'yo-gestionmenus-root',
  templateUrl: './gestionmenus-root.component.html',
  styleUrls: ['./gestionmenus-root.component.scss'],
  animations: [
    trigger('panelInOutFormSearch', [

      state('inactive', style({
        opacity: '0',
        transform: 'translateX(-100%)',
        display: 'none'
      })),

      state('active', style({
        opacity: '1',
        // display: 'inline-block',
        verticalAlign: 'top'
      })),

      transition('inactive => active', animate('250ms ease-in')),
      transition('active => inactive', animate('250ms ease-out'))
    ]),
  ]
})
export class GestionmenusRootComponent implements OnInit, OnDestroy {

  searchPanelWidth = 350;
  displayFormSearch: boolean = true;
  sub: Subscription;
  gms: GestionMenusSupplier;

  //subscriptions
  subDisplayFormSearch: Subscription;
  subSearchResults: Subscription;

  stateMenu: string;
  statePlanning: string = 'active';

  constructor(private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private mp2Svc: MenusPlanning2Service,
              private menusToolbarSvc: MenusToolbarService,
              public utils: UtilsService) {
  }

  ngOnInit() {
    this.initRouteData();
    this.subscriptionSearchResults();
    this.subscriptionDisplayFormSearch();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.sub);
    this.utils.unsubscribe(this.subDisplayFormSearch);
  }

  subscriptionDisplayFormSearch = (): void => {
    this.subDisplayFormSearch = this.menusToolbarSvc.displayFormSearch$.subscribe(item => {
      this.displayFormSearch = item;
      this.statePlanning = this.statePlanning === 'active' ? 'inactive' : 'active';
      this.cd.markForCheck();
    });
  };

  subscriptionSearchResults = () => {
    // on affiche le panel de recherche si un recherche a été lancée depuis un decoupage repas
    this.subSearchResults = this.mp2Svc.searchResults$.subscribe(response => {

      this.displayFormSearch = false;
      if (!this.utils.isResponseSupplierError(response)) {
        let produitsDeclinaisons = response.resultList;
        if (!this.utils.isCollectionNullOrEmpty(produitsDeclinaisons)) {
          this.displayFormSearch = true;
          this.getFormSearchState();
        }
      }
      this.cd.markForCheck();
    });
  };

  initRouteData = () => {
    this.sub = this.route.data.subscribe((data: { gestionmenusSupplier: GestionMenusSupplier }) => {
      this.gms = data.gestionmenusSupplier;
      // this.mp2Svc.planningMenus=null;
      this.utils.setTitle('Gestion des menus');
    });

  };

  getFormSearchState = () => this.stateMenu = this.displayFormSearch ? 'active' : 'inactive';
}
