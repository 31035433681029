import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) {
  }

  navigateToFacture = (fournisseurId: number = null, numeroFacture: string = null) => {
    let params = {};

    if (fournisseurId && numeroFacture) {
      params['queryParams'] = {};
      params['queryParams']['fournisseurId'] = fournisseurId;
      params['queryParams']['numeroFacture'] = numeroFacture;
    }

    this.router.navigate(['/factures/fournisseurs'], params);
  };
}
