<ng-container *ngIf="isReady()" id="results-wrapper">
  <div>
    <yo-button
      class="mb-2"
      iconClass="fas fa-print"
      (onClick)="exportXlsx()"
      tooltip="Export des résultats (les tableaux et les enfants d'enfants ne sont pas pris en compte)"
      label="Export XLSX"
    ></yo-button>
    <label class="ml-2"><i class="fas fa-exclamation-triangle"></i> Attention, les éléments enfants de type tableau ou
      qui ont une profondeur supérieure à 1 ne sont pas exportés. Veuillez vous reporter au mode JSON le cas
      échéant.</label>
  </div>
  <dx-data-grid
    [dataSource]="data"
    keyExpr="id"
    width="100%"
    height="400px"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [grouping]="true"
    #grid>

    <dxi-column *ngFor="let column of columns"
                [allowGrouping]="true"
                [allowFiltering]="true"
                cellTemplate="columnCellTemplate"
                [filterOperations]="['contains']"
                [dataField]="column"
                [caption]="column">
    </dxi-column>

    <dxi-column
      [allowGrouping]="false"
      [allowFiltering]="false"
      cellTemplate="columnActionsTemplate"
      caption="Action(s)"
    >
    </dxi-column>


    <div *dxTemplate="let cell of 'columnCellTemplate'">
      {{ cell.value }}
    </div>

    <div *dxTemplate="let cell of 'columnActionsTemplate'" class="custom-button-container">
      <yo-button
        [label]="'Voir détails'" tooltip="Voir les détails de la ligne"
        (onClick)="seeDetailsRow(cell.row.data)"></yo-button>
    </div>

    <!--  FILTERING-->
    <dxo-header-filter
      [visible]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} résultat(s)">
    </dxo-pager>

    <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
    <!--<dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>-->


  </dx-data-grid>
  <yo-view-details-row-graphql></yo-view-details-row-graphql>
</ng-container>
<ng-container *ngIf="!isReady()">
  <span>Aucun résultat...</span>
</ng-container>
