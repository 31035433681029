import {ObjectDTO} from "../object-dto";
import {PointDeLivraisonDTO} from "../point-de-livraison-d-t-o";
import {ClientDTO} from "../client-dto";

export class ConfigurationPortailDTO extends ObjectDTO {

  constructor() {
    super();
  }

  plcListToCreate: PointDeLivraisonDTO[] = [];

  plc: PointDeLivraisonDTO;

  originPlcId: number;

  client: ClientDTO;

  jourLimiteSaisi: string = 'Lundi';

  heureLimiteSaisie: number = 1;

  nombreUniteBlocage: number = 1;

  uniteBlocage: string = 'week';

  minValeurSaisie: number = 0;

  maxValeurSaisie: number = 10;

  //donnée calculée par le back-end
  dateLimiteSaisie: Date = new Date();

}
