<dx-popup
  [showTitle]="true"
  title="Envoi d'un mail"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="displayDialog"
  (onHiding)="closeDialog()"
  [width]="600"
  [height]="'auto'"
>
  <div class="m-auto">
    <div class="row mg-b-15">
      <div class="col-lg-12">
        <span>Confirmez-vous l'envoi des commandes sélectionnées aux fournisseurs ?</span>
      </div>
    </div>
    <div class="row mg-b-15">
      <div class="col-lg-12 d-flex justify-content-between">
        <span>Passer ces commandes en statut "A réceptionner"</span>
        <dx-check-box [value]="passToModeAReceptionner" (onValueChanged)="handleValueChange($event)"></dx-check-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-12 text-center">
        <span class="mg-r-5">
          <yo-button (onClick)="sendEmail()" label="Oui"></yo-button>
        </span>
        <yo-button (onClick)="closeDialog()" label="Non"></yo-button>

      </div>
    </div>
  </div>
</dx-popup>
