import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, HAS_GESTION_STOCKS, STOCKS_FEATURES} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {StocksComponent} from "./stocks/stocks.component";
import {InventaireComponent} from "./stocks/inventaire/inventaire.component";
import {GestionStocksRootComponent} from "./gestion-stocks-root.component";
import {StocksResolverService} from "./stocks/stocks-resolver.service";
import {MatieresPremieresComponent} from "./stocks/matieres-premieres/matieres-premieres.component";
import {MouvementsComponent} from "./mouvements/mouvements.component";
import {GrilleMouvementsComponent} from "./mouvements/grille-mouvements/grille-mouvements.component";


const routes: Routes = [

  {
    path: FP_ROUTES.GESTION_STOCKS,
    component: GestionStocksRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    data: HAS_GESTION_STOCKS,
    children: [
      {
        path: STOCKS_FEATURES.MOUVEMENTS,
        component: MouvementsComponent,
        data: HAS_GESTION_STOCKS,
        resolve: {
          stocksSupplier: StocksResolverService
        },
        children:[
          {
            path: 'grille-mouvements',
            runGuardsAndResolvers: 'always',
            component: GrilleMouvementsComponent,
            data: HAS_GESTION_STOCKS,
          },
        ]
      },
      {
        path: 'stocks',
        component: StocksComponent,
        data: HAS_GESTION_STOCKS,
        resolve: {
          stocksSupplier: StocksResolverService
        },
        children: [
          {
            path: STOCKS_FEATURES.INVENTAIRE,
            runGuardsAndResolvers: 'always',
            component: InventaireComponent,
            data: HAS_GESTION_STOCKS,
          },
          {
            path: STOCKS_FEATURES.MATIERES_PREMIERES,
            runGuardsAndResolvers: 'always',
            component: MatieresPremieresComponent,
            data: HAS_GESTION_STOCKS,
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionStocksRoutingModule {
}
