import {Component, OnDestroy, OnInit} from '@angular/core';
import {TokenResolverService, TokenSupplier} from '../../gestion-referentiel/token-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {PasswordService} from '../../core/services/mail/password.service';
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-utilisateur-auth',
  templateUrl: './utilisateur-auth.component.html',
  styleUrls: ['./utilisateur-auth.component.scss']
})
export class UtilisateurAuthComponent implements OnInit, OnDestroy {

  userPassword: string;
  userPasswordConfirm: string;

  displayDialog = false;

  subRoute: Subscription;
  subOpenDialog: Subscription;
  subSendNewPassword: Subscription;

  tokenSupplier: TokenSupplier = null;

  constructor(private tokenResolverService: TokenResolverService,
              private route: ActivatedRoute,
              private utils: UtilsService,
              private router: Router,
              private auth2Svc: Auth2Service,
              private passwordService: PasswordService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.routeSubscription();
  }

  routeSubscription() {
    this.subRoute = this.route.data
      .subscribe(response => {
        this.tokenSupplier = response.tokenSupplier;
        this.displayDialog = true;
      });
  }

  sendNewPasswordSubscription() {
    this.subSendNewPassword = this.passwordService.sendNewPassword(this.tokenSupplier.id, this.tokenSupplier.token, this.userPassword, this.userPasswordConfirm).subscribe(response => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Félicitations, le mot de passe a été changé avec succès. Vous allez être redirigé d'ici quelques secondes sur la page de connexion dans laquelle vous serez invitez à vous identifier avec votre nouveau mot de passe.`);
      setTimeout(
        () => this.auth2Svc.redirectToLoginPage()
      , 5000);
    });
  }

  sendNewPassword() {
    this.sendNewPasswordSubscription();
  }

  isBoutonEnvoyerDisabled(): boolean {
    return !this.userPassword || !this.userPasswordConfirm;
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subSendNewPassword);
  }


}
