import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from "../../../../technique/http.service";
import {ResponseWrapper} from "../../../../../suppliers/wrappers/response-wrapper";
import McPlcPrestation__McPlc__McpCvDTO
  from "../../../../../dtos/conditionnement/plc/conditionnement/plat/mc-plc-prestation-mc-plc-mc-cv-dto";

export const URL_FIND_ALL_BY_ID_MCPLC = `dolrest/conditionnements/modeles-conditionnements-plc/mcplcPresta-mcplcCv/findByIdMcPlc`;

@Injectable({
  providedIn: 'root'
})
export class McPlcPrestaMcPlcMcCVService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des points de livraison <-> prestations
   * en fonction de l'identifiant du modèle de conditionnement PLC
   * @returns {Observable<McPlcPrestation__McPlc__McpCvDTO>}
   */
  getAllByIdMcplc(idMcplc: number): Observable<ResponseWrapper<McPlcPrestation__McPlc__McpCvDTO>> {
    return this.httpSvc.get(`${URL_FIND_ALL_BY_ID_MCPLC}/${idMcplc}`, null);
  }

}
