import {Injectable} from '@angular/core';

@Injectable()
export class SiteRoutemapService {

  getSearchSitesLink(endUrl?: string) {
    return `dolrest/sites/search?${endUrl}`;
  }

  getSiteFicheIdentiteLink(idSite:number):string{
    const url = `dolrest/sites/site/${idSite}`;
    return url;
  }

  getDeleteSiteSLink(idSite:number):string{
    const url = `dolrest/sites/site/deleteone/${idSite}`;
    return url;
  }
}
