
export class SearchSupplier{

  value:any;
  values:any[];
  luceneSortField:string;
  luceneSortType:string;


  constructor(value?: any, values?: any[], luceneSortField?: string, luceneSortType?: string) {
    this.value = value;
    this.values = values;
    this.luceneSortField = luceneSortField;
    this.luceneSortType = luceneSortType;
  }
}
