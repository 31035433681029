<dx-popup
  [showTitle]="true"
  [title]="'Duplication des menus'"
  [(visible)]="displayDialog"
  [width]="1300"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <!--  CONTENT-->
  <ng-container>
    <div style="min-height:600px;max-height: 900px;">

      <!--  VISUALISER LES ETAPES-->
      <div class="row">
        <div class="col-md-12">
          <p-steps [model]="steps" [(activeIndex)]="activeStep" [readonly]="true"></p-steps>
        </div>
      </div>

      <!--    ETAPE DE SELECTION DES PRESTATIONS-->
      <ng-container *ngIf="activeStep === STEP_DUPLICATION.SELECTION_PRESTATION">
        <div class="row mt-5 mb-5">
          <div class="col-md-6">
            <div class="dx-field-label">Type de duplication :</div>
            <div class="dx-field-value">
              <dx-select-box
                #selectBox
                [items]="typesDuplication"
                [value]="typeDuplicationSelected"
                (onValueChanged)="changeTypeDuplication($event)"
                valueExpr="value"
                displayExpr="label"
              >
              </dx-select-box>
            </div>
          </div>
        </div>

        <div *ngIf="typeDuplicationSelected === 0">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-8">
                  <h6 class="first font-14">Choisir une prestation à dupliquer</h6>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <h6 class="first font-14">Sélectionner les prestations cibles</h6>

            </div>
          </div>
          <div class="row">

            <div class="col-md-6">
              <dx-data-grid
                [dataSource]="duplicationMenuData?.sourceCmcList"
                (onSelectionChanged)="selectSourceCmc($event)"
                [selectedRowKeys]="idSelectedSourceCmcList"
                keyExpr="id"
                [height]="'400px'"
                [hoverStateEnabled]="true"
                [allowColumnResizing]="true"
                [rowAlternationEnabled]="true"
                [showRowLines]="true"
                [showBorders]="true">

                <dxi-column dataField="offreAlimentaire.site.libelle" alignment="left" caption="Site"
                            [allowFiltering]="true" cellTemplate="siteCellTemplate"></dxi-column>
                <dxi-column dataField="offreAlimentaire.libelle" alignment="left" caption="Offre Alimentaire"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="libelle" alignment="left" caption="Prestation"
                            [allowFiltering]="true"></dxi-column>

                <div *dxTemplate="let cell of 'siteCellTemplate'">
                  <div class="no-overflow">
                    <yo-site [siteId]="cell.data.offreAlimentaire.site.id"
                             [siteLibelle]="cell.data.offreAlimentaire.site.libelle"
                             [siteLibelleTooltip]="cell.data.offreAlimentaire.site.libelle"></yo-site>
                  </div>
                </div>

                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-selection mode="single"></dxo-selection>
              </dx-data-grid>
            </div>

            <div class="col-md-6">
              <dx-data-grid
                [dataSource]="duplicationMenuData?.targetCmcList"
                (onSelectionChanged)="selectTargetCmc($event)"
                [(selectedRowKeys)]="idSelectedTargetCmcList"
                keyExpr="id"
                [height]="'400px'"
                [hoverStateEnabled]="true"
                [allowColumnResizing]="true"
                [rowAlternationEnabled]="true"
                [showRowLines]="true"
                [showBorders]="true">

                <dxi-column dataField="offreAlimentaire.site.libelle" alignment="left" caption="Site"
                            [allowFiltering]="true" cellTemplate="siteCellTemplate"></dxi-column>
                <dxi-column dataField="offreAlimentaire.libelle" alignment="left" caption="Offre Alimentaire"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="libelle" alignment="left" caption="Prestation"
                            [allowFiltering]="true"></dxi-column>

                <div *dxTemplate="let cell of 'siteCellTemplate'">
                  <div class="no-overflow">
                    <yo-site [siteId]="cell.data.offreAlimentaire.site.id"
                             [siteLibelle]="cell.data.offreAlimentaire.site.libelle"
                             [siteLibelleTooltip]="cell.data.offreAlimentaire.site.libelle"></yo-site>
                  </div>
                </div>

                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-selection
                  [selectAllMode]="'allPages'"
                  [showCheckBoxesMode]="'onClick'"
                  mode="multiple"
                ></dxo-selection>
              </dx-data-grid>
            </div>
          </div>
          <div class="row mg-t-20">
            <div class="col-md-auto">
              <ng-container *ngTemplateOutlet="tplErrorList;context:{errorList:errorStepPrestationList}"></ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="typeDuplicationSelected === 1">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-8">
                  <h6 class="first font-14">Choisir une prestation à dupliquer</h6>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <h6 class="first font-14">Sélectionner les prestations cibles</h6>

            </div>
          </div>
          <div class="row">

            <div class="col-md-6">
              <dx-data-grid
                [dataSource]="duplicationMenuData?.sourceCmcCaList"
                (onSelectionChanged)="selectSourceCmcCa($event)"
                [selectedRowKeys]="idSelectedSourceCmcCaList"
                keyExpr="id"
                [height]="'400px'"
                [hoverStateEnabled]="true"
                [allowColumnResizing]="true"
                [rowAlternationEnabled]="true"
                [showRowLines]="true"
                [showBorders]="true">

                <dxi-column dataField="cmc.offreAlimentaire.site.libelle" alignment="left" caption="Site"
                            [allowFiltering]="true" cellTemplate="siteCellTemplate"></dxi-column>
                <dxi-column dataField="cmc.offreAlimentaire.libelle" alignment="left" caption="Offre Alimentaire"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="cmc.libelle" alignment="left" caption="Prestation"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="regimeAlimentaire.libelle" alignment="left" caption="Régime"
                            [allowFiltering]="true" cellTemplate="regimeCellTemplate"></dxi-column>

                <div *dxTemplate="let cell of 'siteCellTemplate'">
                  <div class="no-overflow">
                    <yo-site [siteId]="cell.data.cmc.offreAlimentaire.site.id"
                             [siteLibelle]="cell.data.cmc.offreAlimentaire.site.libelle"
                             [siteLibelleTooltip]="cell.data.cmc.offreAlimentaire.site.libelle"></yo-site>
                  </div>
                </div>
                <div *dxTemplate="let cell of 'regimeCellTemplate'">
                  <div class="no-overflow">
                    <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="cell.data.referent"
                       title="Régime référent"
                    ></i>
                    <span [title]="cell.data.regimeAlimentaire.libelle">{{cell.data.regimeAlimentaire.libelle}}</span>

                  </div>
                </div>

                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-selection mode="single"></dxo-selection>
              </dx-data-grid>
            </div>

            <div class="col-md-6">
              <dx-data-grid
                [dataSource]="duplicationMenuData?.targetCmcCaList"
                [(selectedRowKeys)]="idSelectedTargetCmcCaList"
                (onSelectionChanged)="selectTargetCmcCa($event)"
                keyExpr="id"
                [height]="'400px'"
                [hoverStateEnabled]="true"
                [allowColumnResizing]="true"
                [rowAlternationEnabled]="true"
                [showRowLines]="true"
                [showBorders]="true">
                <dxi-column dataField="cmc.offreAlimentaire.site.libelle" alignment="left" caption="Site"
                            [allowFiltering]="true" cellTemplate="siteCellTemplate"></dxi-column>
                <dxi-column dataField="cmc.offreAlimentaire.libelle" alignment="left" caption="Offre Alimentaire"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="cmc.libelle" alignment="left" caption="Prestation"
                            [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="regimeAlimentaire.libelle" alignment="left" caption="Régime"
                            [allowFiltering]="true" cellTemplate="regimeCellTemplate"></dxi-column>

                <div *dxTemplate="let cell of 'siteCellTemplate'">
                  <div class="no-overflow">
                    <yo-site [siteId]="cell.data.cmc.offreAlimentaire.site.id"
                             [siteLibelle]="cell.data.cmc.offreAlimentaire.site.libelle"
                             [siteLibelleTooltip]="cell.data.cmc.offreAlimentaire.site.libelle"></yo-site>
                  </div>
                </div>
                <div *dxTemplate="let cell of 'regimeCellTemplate'">
                  <div class="no-overflow">
                    <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="cell.data.referent"
                       title="Régime référent"
                    ></i>
                    <span [title]="cell.data.regimeAlimentaire.libelle">{{cell.data.regimeAlimentaire.libelle}}</span>

                  </div>
                </div>

                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-selection
                  [selectAllMode]="'allPages'"
                  mode="multiple"
                ></dxo-selection>
              </dx-data-grid>
            </div>
          </div>
          <div class="row mg-t-20">
            <div class="col-md-auto">
              <ng-container *ngTemplateOutlet="tplErrorList;context:{errorList:errorStepPrestationList}"></ng-container>
            </div>
          </div>
        </div>

      </ng-container>

      <!--    ETAPE DE SELECTION DE LA PERIODE-->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PERIODE">

        <div class="container m-5">
          <div class="row mt-3 mb-3">
            <div class="col-md-6">
              <h6>Ce que vous souhaitez recopier : </h6>
            </div>
            <div class="col-md-6">
              <h6>Choix des périodes : </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ng-container *ngTemplateOutlet="tplRecapSelectionPrestations"></ng-container>
            </div>
            <div class="col-md-6">
              <div class="row">

                <div class="col-md-12">
                  <h6 class="first font-14">Période de la prestation à dupliquer</h6>
                  <div class="p-g p-fluid">
                    <div class="p-g-12 p-md-6">

                      <dx-date-range-box
                        startDateLabel="Début"
                        endDateLabel="Fin"
                        [(value)]="selectedRangeSourceDates"
                        [calendarOptions]="{ onOptionChanged: this.onOptionChanged }"
                        [applyValueMode]="'instantly'">
                        <span *dxTemplate="let cell of 'cell'">
                          <yo-devextreme-date-dispo [cellData]="cell"
                                         [dateSaisieEffectif]="utils.isDateInList(convertToDate(cell.date),sourceDatesSaisieEffectifs)"
                                         [dateCreationMenu]="utils.isDateInList(convertToDate(cell.date),sourceDatesCreationMenus)"></yo-devextreme-date-dispo>
                        </span>
                      </dx-date-range-box>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mg-t-20">
                <div class="col-md-12">
                  <h6 class="first font-14">Jour de début sur les prestations cibles</h6>
                  <div class="p-g p-fluid">
                    <div class="p-g-12 p-md-6">
                      <dx-date-box
                        [(value)]="selectedDateDebutTarget"
                        [calendarOptions]="{ onOptionChanged: this.onOptionChanged }"
                        [applyValueMode]="'instantly'">
                        <span *dxTemplate="let cell of 'cell'">
                          <yo-devextreme-date-dispo [cellData]="cell"
                                                    [dateSaisieEffectif]="utils.isDateInList(convertToDate(cell.date),sourceDatesSaisieEffectifs)"
                                                    [dateCreationMenu]="utils.isDateInList(convertToDate(cell.date),sourceDatesCreationMenus)"></yo-devextreme-date-dispo>
                        </span>
                      </dx-date-box>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row mg-t-20">
            <div class="col-md-auto">
              <ng-container *ngTemplateOutlet="tplErrorList;context:{errorList:errorStepPeriodeList}"></ng-container>
            </div>
          </div>

        </div>

      </ng-container>

      <!--    ETAPE RECAPITULATIF-->
      <ng-container *ngIf="activeStep === STEP_DUPLICATION.RECAPITULATIF">
        <div class="container m-5">
          <div class="row mt-3 mb-3">
            <div class="col-md-6">
              <h6>Ce que vous souhaitez recopier : </h6>
            </div>
            <div class="col-md-6">
              <h6>Choix des périodes : </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngTemplateOutlet="tplRecapSelectionPrestations"></ng-container>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="first font-14">Période de la prestation à dupliquer</h6>
                  <div>
                    {{duplicationMenuService.getPeriodeADupliquerLabel(selectedRangeSourceDates)}}
                  </div>
                </div>
              </div>
              <div class="row mg-t-20 ">
                <div class="col-md-12">
                  <h6 class="first font-14">Jour de début sur les prestations cibles</h6>
                  <div>
                    {{duplicationMenuService.getJourDebutPrestationCibles(selectedDateDebutTarget)}}
                  </div>
                </div>
              </div>
              <div class="row mg-t-50">
                <div class="col-md-12">
                  <h6>Vos options de recopie : </h6>
                  <div class="d-flex flex-column">
                    <h6 class="first font-14">Écraser les menus</h6>

                    <div class="d-flex flex-row">
                      <p-selectButton [options]="optionsEcraserMenu" [(ngModel)]="optionEcraserMenu">
                      </p-selectButton>
                      <div class="col font-12">
                          <span *ngIf="optionEcraserMenu === DME.AUCUN">
                                Ne recopier les plats sources que lorsque l'emplacement est vide dans les prestations cibles.
                          </span>
                        <span *ngIf="optionEcraserMenu === DME.ECRASER_SI_PLAT_EXISTANT">
                                Si le plat cible existe déjà, il sera écrasé !
                          </span>
                        <span *ngIf="optionEcraserMenu === DME.ECRASER_TOUT">
                                          Enlever tous les plats de la période cible et recopier les plats sources !!!
                          </span>
                      </div>
                    </div>

                    <h6 class="first font-14 mg-t-20">Régime cible non trouvé</h6>
                    <p-checkbox [(ngModel)]="optionRegimeSource"
                                label="Recopier le plat du régime source"
                                binary="true"></p-checkbox>

                    <h6 class="first font-14 mg-t-20">Déclinaison cible non trouvée</h6>
                    <p-checkbox [(ngModel)]="optionDeclinaisonSource"
                                label="Recopier le plat de la déclinaison source"
                                binary="true"></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

      <!--      ETAPE DE RAPPORT DE LOGS-->
      <ng-container *ngIf="activeStep === STEP_DUPLICATION.RAPPORT">
        <div *ngIf="typeDuplicationSelected === 0">
          <ng-container
            *ngTemplateOutlet="tplRapportDuplicationByPrestation;context:{duplicationMenuResultByPrestation:duplicationMenuResultByPrestation}"></ng-container>
        </div>
        <div *ngIf="typeDuplicationSelected === 1">
          <ng-container
            *ngTemplateOutlet="tplRapportDuplication;context:{duplicationMenuResult:duplicationMenuResult}"></ng-container>
        </div>
      </ng-container>

    </div>
  </ng-container>

  <div class="text-right">
    <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PRESTATION">
      <!--      bouton visibles à l'etape selection des prestations-->

      <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-chevron-right"
          (onClick)="validateStepPrestation(STEP_DUPLICATION.SELECTION_PERIODE)"
          tooltip="Sélectionner la période"
          label="Suivant"
        ></yo-button>
      </span>
    </ng-container>

    <!--      bouton visibles à l'etape selection des périodes-->
    <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PERIODE">
      <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-chevron-left"
          (onClick)="activeStep=STEP_DUPLICATION.SELECTION_PRESTATION"
          tooltip="Sélection des prestations"
          label="Précédent">
        </yo-button>
      </span>
      <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-chevron-right"
          (onClick)="validateStepPeriode(STEP_DUPLICATION.RECAPITULATIF)"
          tooltip="Options de recopie "
          label="Suivant">
        </yo-button>
      </span>

    </ng-container>

    <!--      bouton visibles à l'etape de récapitulatif -->
    <ng-container *ngIf="activeStep===STEP_DUPLICATION.RECAPITULATIF">

      <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-chevron-left"
          (onClick)="activeStep=STEP_DUPLICATION.SELECTION_PERIODE"
          tooltip="Sélection de la période"
          label="Précédent">
        </yo-button>
      </span>

      <span class="mg-r-5">
        <yo-button iconClass="fas fa-chevron-right"
                   (onClick)="launchDuplicationMenus()"
                   tooltip="Lancer la duplication des menus"
                   [disabled]="duplicationEnCours"
                   label="Suivant">
        </yo-button>
      </span>

    </ng-container>

    <!--      bouton visibles à l'etape de rapport -->
    <ng-container *ngIf="activeStep===STEP_DUPLICATION.RAPPORT">

      <span class="mg-r-5">
        <yo-button iconClass="fas fa-chevron-left"
                   (onClick)="activeStep=STEP_DUPLICATION.RECAPITULATIF"
                   tooltip="Options de recopie"
                   label="Précédent">
        </yo-button>
      </span>

    </ng-container>

    <span class="mg-r-5">
      <yo-button buttonClass="cta-inner-delete"
                 iconClass="fas fa-times"
                 (onClick)="closeDialog()"
                 tooltip="Fermer la fenêtre"
                 label="Fermer"></yo-button>
    </span>
  </div>
</dx-popup>


<!--TEMPLATE ERRORLIST-->
<ng-template #tplErrorList let-errorList="errorList">
  <div class="row">
    <div class="col-md-auto">
      <ng-container *ngIf="!utils.isCollectionNullOrEmpty(errorList)">
        <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
      </ng-container>
    </div>
    <div class="col">
      <div *ngFor="let error of errorList" class="danger-color">
        {{error}}
      </div>
    </div>
  </div>

</ng-template>

<ng-template #tplRecapSelectionPrestations>

  <div *ngIf="typeDuplicationSelected === 0" class="row">
    <div class="col-md-12">
      <h6 class="first font-14">Prestation à dupliquer</h6>
      <div class="mg-t-10">
        <yo-site [siteId]="selectedSourceCmc?.offreAlimentaire?.site?.id"
                 [siteLibelle]="selectedSourceCmc?.offreAlimentaire?.site?.libelle"></yo-site>
        ,
        <span class="mg-r-5" title="Offre alimentaire">{{selectedSourceCmc?.offreAlimentaire?.libelle}}, </span>
        <span class="mg-r-5" title="Prestation">{{selectedSourceCmc?.libelle}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="typeDuplicationSelected === 0" class="row mg-t-20">
    <div class="col-md-12">
      <h6 class="first font-14">Prestations cibles</h6>
      <div style="overflow: auto; max-height: 350px;">
        <div class="mg-t-10" *ngFor="let targeCmc of selectedTargetCmcList">
          <yo-site [siteId]="targeCmc?.offreAlimentaire?.site?.id"
                   [siteLibelle]="targeCmc?.offreAlimentaire?.site?.libelle"></yo-site>
          ,
          <span class="mg-r-5" title="Offre alimentaire">{{targeCmc?.offreAlimentaire?.libelle}}, </span>
          <span class="mg-r-5" title="Prestation">{{targeCmc?.libelle}}</span>

        </div>
      </div>
    </div>
  </div>

  <div *ngIf="typeDuplicationSelected === 1" class="row">
    <div class="col-md-12">
      <h6 class="first font-14">Prestation à dupliquer</h6>
      <div class="mg-t-10">
        <yo-site [siteId]="selectedSourceCmcCa?.cmc?.offreAlimentaire?.site?.id"
                 [siteLibelle]="selectedSourceCmcCa?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
        ,
        <span class="mg-r-5" title="Offre alimentaire">{{selectedSourceCmcCa?.cmc?.offreAlimentaire?.libelle}}, </span>
        <span class="mg-r-5" title="Prestation">{{selectedSourceCmcCa?.cmc?.libelle}}, </span>

        <span style="white-space: nowrap">
          <i class="fab fa-first-order fa-xs  prefere-color mg-r-5"
             *ngIf="selectedSourceCmcCa?.referent" title="Régime référent"></i>
          <span class="mg-r-5" title="Régime">{{selectedSourceCmcCa?.regimeAlimentaire?.libelle}}</span>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="typeDuplicationSelected === 1" class="row mg-t-20">
    <div class="col-md-12">
      <h6 class="first font-14">Prestations cibles</h6>
      <div style="overflow: auto; max-height: 350px;">
        <div class="mg-t-10" *ngFor="let targetCmcCa of selectedTargetCmcCaList">
          <yo-site [siteId]="targetCmcCa?.cmc?.offreAlimentaire?.site?.id"
                   [siteLibelle]="targetCmcCa?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
          ,

          <span class="mg-r-5" title="Offre alimentaire">{{targetCmcCa?.cmc?.offreAlimentaire?.libelle}}, </span>
          <span class="mg-r-5" title="Prestation">{{targetCmcCa?.cmc?.libelle}}, </span>

          <span style="white-space: nowrap">
            <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="targetCmcCa?.referent"
               title="Régime référent"></i>
            <span class="mg-r-5" title="Régime">{{targetCmcCa?.regimeAlimentaire?.libelle}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!--TEMPLATE RAPPORT D'ERREUR-->
<ng-template #tplRapportDuplication>
  <div *ngIf="typeDuplicationSelected === 1" class="row mg-t-20">
    <div class="col-md-6">
      <h3 class="first font-12">Prestation source </h3>
      <div class="mg-t-10">
        <yo-site [siteId]="duplicationMenuResult.cmcCaSource?.cmc?.offreAlimentaire?.site?.id"
                 [siteLibelle]="duplicationMenuResult.cmcCaSource?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
        ,
        <span class="mg-r-5" title="Offre alimentaire">{{duplicationMenuResult.cmcCaSource?.cmc?.offreAlimentaire?.libelle}}
          , </span>
        <span class="mg-r-5" title="Prestation">{{duplicationMenuResult.cmcCaSource?.cmc?.libelle}}
          , </span>
        <span class="mg-r-5" title="Régime">{{duplicationMenuResult.cmcCaSource?.regimeAlimentaire?.libelle}}</span>
      </div>
    </div>
    <div class="col-md-6">
    </div>
  </div>



  <h3 class="first font-12">RÉSULTATS </h3>

  <div class="row">
    <div class="col-md-3">Nombre de plats à dupliquer :</div>
    <div class="col-md-9">{{duplicationMenuResult.nbPlatsSources}}</div>
  </div>

  <div class="row">
    <div class="col-md-3">Nombre de plats copiés :</div>
    <div class="col-md-9">{{duplicationMenuResult.nbPlatsCopies}}</div>
  </div>

  <ng-container *ngIf="!duplicationMenuResult.allElementsImported">
    <h3 class="first font-12 mg-t-20">RAPPORT</h3>

    <div class="row" style="max-height: 400px;">
      <div class="col-md-12">

        <ng-container *ngFor="let report of duplicationMenuResult.reports | keyvalue">
          <div class="row mg-t-10">
            <div class="col-md-12">
                    <span class="badge badge-secondary">
                    <yo-site [siteId]="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.site?.id"
                             [siteLibelle]="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
                    ,
                    <span class="mg-r-5" title="Offre alimentaire"
                    >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.libelle}}
                      ,</span>
                    <span class="mg-r-5" title="Prestation"
                    >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.libelle}}
                      ,</span>

                    <span style="white-space: nowrap">
                  <i class="fab fa-first-order fa-xs  prefere-color mg-r-5"
                     *ngIf="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.referent"
                     title="Régime référent"
                  ></i>
                  <span class="mg-r-5" title="Régime"
                  >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.regimeAlimentaire?.libelle}}</span>
                    </span>

                      <span>(Nombre de plats copiés : {{getNbPlatsCopiesTarget(duplicationMenuResult, report.key)}}
                        )</span>

                      </span>

              <span class="mg-l-5 mg-t-10 font-12">{{getDateFromKey(report.key)}}</span>

            </div>
          </div>

          <!--                  ERREUR DE PERIODE INACTIVE-->
          <ng-container
            *ngFor="let error of report.value; let i=index;let last=last; let first=first;">
            <ng-container>
              <div style="margin-top:20px; ">
                    <span
                      class="badge badge-light">ERREUR {{error.codeError.code}}</span> {{error.infos}}
              </div>
              <span style="margin-left: 115px;"></span>
            </ng-container>

            <ng-container *ngIf="!last">
              ,<span class="mg-r-5"></span>
            </ng-container>
          </ng-container>

        </ng-container>

      </div>

    </div>
  </ng-container>

</ng-template>

<ng-template #tplRapportDuplicationByPrestation>

  <div style="max-height: 400px;">
    <div *ngFor="let result of duplicationMenuResultByPrestation">

      <div class="col-md-12">
        <h6 class="first font-14">Prestation source</h6>
        <div class="mg-t-10">
          <yo-site [siteId]="result.cmcCaSource?.cmc?.offreAlimentaire?.site?.id"
                   [siteLibelle]="result.cmcCaSource?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
          ,
          <span class="mg-r-5" title="Offre alimentaire">{{result.cmcCaSource?.cmc?.offreAlimentaire?.libelle}}, </span>
          <span class="mg-r-5" title="Prestation">{{result.cmcCaSource?.cmc?.libelle}}, </span>
          <span class="mg-r-5" title="Régime">{{result.cmcCaSource?.regimeAlimentaire?.libelle}}</span>
        </div>
      </div>

      <h3 class="first font-12">RÉSULTATS </h3>

      <div class="row">
        <div class="col-md-3">Nombre de plats à dupliquer :</div>
        <div class="col-md-9">{{result.nbPlatsSources}}</div>
      </div>

      <div class="row">
        <div class="col-md-3">Nombre de plats copiés :</div>
        <div class="col-md-9">{{result.nbPlatsCopies}}</div>
      </div>

      <ng-container *ngIf="!result.allElementsImported">
        <h3 class="first font-12 mg-t-20">RAPPORT</h3>

        <div class="row">
          <div class="col-md-12">

            <ng-container *ngFor="let report of result.reports | keyvalue">
              <div class="row mg-t-10">
                <div class="col-md-12">
                    <span class="badge badge-secondary">
                    <yo-site [siteId]="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.site?.id"
                             [siteLibelle]="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.site?.libelle"></yo-site>
                    ,
                    <span class="mg-r-5" title="Offre alimentaire"
                    >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.offreAlimentaire?.libelle}}
                      ,</span>
                    <span class="mg-r-5" title="Prestation"
                    >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.cmc?.libelle}}
                      ,</span>

                    <span style="white-space: nowrap">
                  <i class="fab fa-first-order fa-xs  prefere-color mg-r-5"
                     *ngIf="getCmcCaFromId(getIdCmcCaFromKey(report.key))?.referent"
                     title="Régime référent"
                  ></i>
                  <span class="mg-r-5" title="Régime"
                  >{{getCmcCaFromId(getIdCmcCaFromKey(report.key))?.regimeAlimentaire?.libelle}}</span>
                    </span>

                      <span>(Nombre de plats copiés : {{getNbPlatsCopiesTarget(result, report.key)}})</span>

                      </span>

                  <span class="mg-l-5 mg-t-10 font-12">{{getDateFromKey(report.key)}}</span>

                </div>
              </div>

              <!--                  ERREUR DE PERIODE INACTIVE-->
              <ng-container
                *ngFor="let error of report.value; let i=index;let last=last; let first=first;">
                <ng-container>
                  <div style="margin-top:20px; ">
                    <span
                      class="badge badge-light">ERREUR {{error.codeError.code}}</span> {{error.infos}}
                  </div>
                  <span style="margin-left: 115px;"></span>
                </ng-container>

                <ng-container *ngIf="!last">
                  ,<span class="mg-r-5"></span>
                </ng-container>
              </ng-container>

            </ng-container>

          </div>

        </div>
      </ng-container>

    </div>
  </div>

</ng-template>
