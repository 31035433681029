import {ObjectDTO} from "./object-dto";
import {EnvironnementDTO} from "./environnement-dto";

export class UtilisateurConnexionDTO extends ObjectDTO {

  utilisateurId: number;
  utilisateurNom: string;
  utilisateurPrenom: string;
  token: string;
  ip: string;
  date: string;
  dateDerniereUtilisation: string;
  environnement: EnvironnementDTO;

  constructor() {
    super();
    this.utilisateurId = 0;
    this.utilisateurNom = '';
    this.utilisateurPrenom = '';
    this.token = '';
    this.ip = '';
    this.date = '';
    this.dateDerniereUtilisation = '';
    this.environnement = new EnvironnementDTO();
  }
}
