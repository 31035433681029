import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RoutemapService} from "../../../../core/services/routemap.service";

@Component({
  selector: 'yo-init-plan-de-production',
  templateUrl: './initialization-plan-de-production.component.html',
  styleUrls: ['./initialization-plan-de-production.component.scss']
})
export class InitializationPlanDeProductionComponent implements OnInit, OnDestroy {

  subOpenDialog: Subscription;

  displayDialog: boolean = false;

  dialogTitle: string = 'CREER UN PLAN DE PRODUCTION';

  hasProduction: boolean = false;

  form: FormGroup = new FormGroup({});

  label: string;

  emptySite: boolean = false;

  constructor(private utils: UtilsService,
              private prodSvc: ProductionService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.initForm();
  }

  initSubscriptions = (): void => {
    this.subOpenDialog = this.prodSvc.startPlanProd$
      .subscribe(() => this.displayDialog = true);
    this.auth2Svc.hasGestionProduction$.subscribe(response => this.hasProduction = response);
  }

  initForm = (): void => {
    const now: Date = new Date();
    const minutes: string = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`;
    this.label = `PLAN DE PRODUCTION DU ${now.toLocaleDateString()} À ${now.getHours()}:${minutes}:${now.getMilliseconds()}`;
    let sitesLocaux = this.getSitesLocaux();
    this.form = new FormGroup({
      libelle: new FormControl(this.label, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      site: new FormControl(sitesLocaux.length === 1 ? sitesLocaux[0] : null, [Validators.required])
    });
  };

  getSitesLocaux = (): SiteDTO[] => this.auth2Svc.utilisateur.siteListLocaux;

  closeDialog = (): void => {
    this.initLabel();
    this.displayDialog = false;
  }

  private initLabel = (): void => {
    const now: Date = new Date();
    const minutes: string = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`;
    this.label = `PLAN DE PRODUCTION DU ${now.toLocaleDateString()} À ${now.getHours()}:${minutes}:${now.getMilliseconds()}`;
    this.form.patchValue({
      site: null,
      libelle: this.label,
    });
  };

  canCreate = (): boolean => this.hasProduction;

  save = (): void => {
      this.prodSvc.startPlanProduction(this.form.controls['site'].value, this.form.controls['libelle'].value)
        .subscribe((response) => {
          this.closeDialog();
          this.prodSvc.announceRefreshItems(response.one);
          this.routeMapSvc.goToSecondaryRoute(['steps', 'selection-filters', response?.one?.id]);
        });
  }

}
