import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {ModelesNutritionnelsService} from '../../../core/services/entities/modeles-nutritionnels.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {ContratsMenusConvivesRepasService} from '../../../core/services/entities/contrats-menus-convives-repas.service';
import {ContratsMenusConvivesDecoupagesService} from '../../../core/services/entities/contrats-menus-convives-decoupages.service';
import {RepasService} from '../../../core/services/entities/repas.service';
import {DecoupagesRepasService} from '../../../core/services/entities/decoupages-repas.service';
import {ContratMenuConvive__ModeleNutriDTO} from '../../../core/dtos/contratmenuconvive__modele-nutri-dto';
import {CmcMnService} from '../../../core/services/entities/cmc-mn.service';
import {ContratMenuConvive__PlcDTO} from '../../../core/dtos/contratmenuconvive__plc-dto';
import {CmcPlcService} from '../../../core/services/entities/cmc-plc.service';

@Injectable()
export class CmcPlanningResolverService implements Resolve<CmcPlanningSupplier> {

  constructor(private gds: GenericDatagridService,
              private utils: UtilsService,
              private contratMenuSvc: ContratMenuService,
              private cmcrSvc: ContratsMenusConvivesRepasService,
              private repasSvc: RepasService,
              private decoupageRepasSvc:DecoupagesRepasService,
              private cmcdSvc: ContratsMenusConvivesDecoupagesService,
              private modeleNutriSvc: ModelesNutritionnelsService,
              private ggSvc: GestionGemrcnService,
              private cmcMnSvc:CmcMnService,
              private cmcPlcSvc:CmcPlcService,

              private cmcSvc: ContratsMenusConvivesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CmcPlanningSupplier> | Promise<CmcPlanningSupplier> | CmcPlanningSupplier {

    const cmcPs = new CmcPlanningSupplier();
    const idContratMenuConvive: number = +route.parent.paramMap.get('idContratMenuConvive');

    const cmc$ = this.gds.getOne(this.cmcSvc.getEntityName(), idContratMenuConvive);
    const planAlimentaireList$ =this.gds.getAll(this.modeleNutriSvc.getEntityName(),this.modeleNutriSvc.getSort(),true);
    const cmcMnList$=this.gds.search(this.cmcMnSvc.filterByPrestation(idContratMenuConvive));
    const prochaineDateDisponible$ = this.cmcSvc.getProchaineDateDisponible(idContratMenuConvive);

    const all$ = combineLatest([cmc$,planAlimentaireList$,cmcMnList$,prochaineDateDisponible$]);

    return all$
      .toPromise().then(response => {
        cmcPs.cmc = response[0].one;
        cmcPs.plansAlimentaires = response[1].resultList;
        cmcPs.periodes = response[2].resultList;
        cmcPs.prochaineDateDisponible = this.utils.convertNumberDateToDate(response[3].one);

        return cmcPs;
      });
  }
}

export class CmcPlanningSupplier {
  cmc: ContratMenuConviveDTO;
  periodes:ContratMenuConvive__ModeleNutriDTO[]=[];
  plansAlimentaires:ModeleNutritionnelDTO[]=[];
  prochaineDateDisponible : Date;
  cmcPlcList:ContratMenuConvive__PlcDTO[]=[];
}
