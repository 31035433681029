import {ObjectDTO} from '../object-dto';
import {TypeTacheDTO} from '../type-tache-dto';

export default class ConditionnementDTO extends ObjectDTO {

  libelle: string;

  abreviation: string;

  typeTache: TypeTacheDTO;

  poidsEnKg: number;

}
