import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ApportNutritionnelDTO} from '../../dtos/apport-nutritionnel-dto';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {catchError} from 'rxjs/operators';

export const URL_GET_APPORT_NUTRI = `dolrest/gestionproduits/produit/apports-nutritionnels/findApportNutritionnelsActifsBySiteNull`;
export const URL_GET_APPORT_NUTRI_OBLIGATOIRE = `dolrest/gestionproduits/produit/apports-nutritionnels/findApportNutritionnelsObligatoiresActifsBySiteNull`;


@Injectable()
export class ApportsNutritionnelsService extends GenericHandler<ApportNutritionnelDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService, private udmSvc: UnitesDeMesuresService) {
    super(utils, auth2Svc, router, http, title);

    this.optionsUdm = this.initArray(this.udmSvc.unitesDeMesureEnvironnement);
    this.udmSvc.objects$.subscribe(udmList => this.optionsUdm = this.initArray(udmList));
  }

  optionsUdm: { key, value }[] = [];

  getHelp = (): DialogMsgSupplier => undefined;

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES APPORTS NUTRITIONNELS';

  getCreateNewObjectLabel = (): string => 'CRÉER UN APPORT NUTRITIONNEL';


  getEntityName = (): string => 'apportNutritionnel';

  getFields = (dto: ApportNutritionnelDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = new ApportNutritionnelDTO();
    }


    let formFields: FormFieldBaseSupplier<any>[] = [


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),


      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Nom familier',
        readonly: !this.canModify(dto),
        value: dto.libellelong,
        maxLength: 255,
        required: false,
        order: 3
      }),

      new FormFieldDropdownSupplier({
        key: 'uniteDeMesureDTO',
        label: 'Unité de mesure',
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: this.getSelectedItem(dto.uniteDeMesureDTO, this.optionsUdm),
        order: 5,
        options: this.optionsUdm,
        required: true,
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: true,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  };


  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): ApportNutritionnelDTO => new ApportNutritionnelDTO();

  /**
   * Pas de possibilité de créer un ApportNutritionnel depuis l'interface gratuite.
   * @returns {boolean}
   */
  displayCreationButton = (): boolean => false;

  canModify = (element: ObjectDTO): boolean => false;

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER L'APPORT NUTRITIONNEL '${data.libelle.toUpperCase()}'`;

  /**
   * Récupére TOUS les apports nutritionnels actifs liés à aucun site
   * (c'est à dire dont le champ site vaut null
   * @returns  {Observable<ApportNutritionnelDTO[]>}
   */
  getApportNutritionnelsActifsBySiteNull = () => {
    return this.http.get(URL_GET_APPORT_NUTRI ).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getApportNutritionnelsObligatoiresActifsBySiteNull = () => {
    return this.http.get(URL_GET_APPORT_NUTRI_OBLIGATOIRE ).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };
}
