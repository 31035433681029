import {FormGroup} from '@angular/forms';

type TypeField = 'hidden' | 'password' | 'text' | 'externalRef' | 'linkedField' | 'multiple' | 'simple' | 'number';
type ExcelType= 'string' | 'integer' | 'date' | 'bigdecimal' | 'boolean';

export class FormFieldBaseSupplier<T> {

  protected _value: T;

  key: string;
  label: string;
  required: boolean;
  maxLength: number;
  minLength: number;
  min:number;
  max:number;
  order: number;
  readonly: boolean;
  help: string;
  options: { key: string, value: any }[] = [];
  width: number;
  message: string;
  showTime:boolean;
  dateFormat:string;
  dto;
  cols:{field,header}[]=[];
  tableHeight:number;
  optionLabel:string;

  // date time pour rafraichir une image
  refresh = new Date();

  /**
   * Ajouter une icone ou une chaine devant le champ
   */
  prefix: string;

  /**
   * Ajouter une icone ou une chaine après le champ
   */
  suffix: string;

  linkedFieldFn: (value, form?:FormGroup, fields?:FormFieldBaseSupplier<any>[], object?) => void;

  /**
   * textbox, ...
   */
  controlType: string;
  /**
   * hidden, password, text, externalRef
   */
  type: TypeField;

  /**
   * Afficher le placeholder
   */
  displayPlaceholder: boolean = true;

  // type de donnée excel
  excelType:ExcelType;

  // Pour le champ FILE
  entityName='';
  ordre:number=1;


  constructor(options: any) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.maxLength = options.maxLength || 0;
    this.minLength = options.minLength || 0;
    this.controlType = options.controlType || '';
    this.readonly = !!options.readonly;
    this.type = options.type;
    this.linkedFieldFn = options.linkedFieldFn;
    this.help = options.help;
    this.options = options.options;
    this.width = options.width || 380;
    this.prefix = options.prefix || '';
    this.suffix = options.suffix || '';
    this.min=options.min || undefined;
    this.max=options.max || undefined;
    this.excelType = options.excelType || 'unknown';

    // cas specifique File (entityName,ordre,modifiy)
    this.entityName=options.entityName || '';
    this.ordre=options.ordre || 1;
    this.refresh=options.refresh || 1;

    if (options.displayPlaceholder === undefined) {
      this.displayPlaceholder = true;
    } else {
      this.displayPlaceholder = options.displayPlaceholder;
    }

    this.showTime = options.showTime || false;
    this.dateFormat = options.dateFormat || 'dd/mm/yy';

    this.dto=options.dto || undefined;
    this.cols = options.cols || [];
    this.tableHeight=options.tableHeight || 200;
    this.optionLabel= options.optionLabel || 'libelle';

  }


  /**
   * getter du champ privé <b>_value</b>.
   * @returns {T}
   */
  get value() {
    return this._value;
  }

  /**
   * Setter du champ privé <b>_value</b>.
   * @param value nouvelle valeur pour le champ privé <b>_value</b>.
   */
  set value(value) {
    this._value = value;
  }
}
