import {ObjectDTO} from './object-dto';

export class ProduitDeclinaison__TacheDTO extends ObjectDTO {

  tacheId : number;
  tacheLibelle : string;
  tacheCode : string;

  typeTacheId : number;
  typeTacheLibelle : string;
  typeTacheCode : string;

  produitDeclinaisonId : number;
  produitDeclinaisonLibelle : string;
  produitDeclinaisonCode : string;

}
