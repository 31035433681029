import {Component, OnDestroy, OnInit} from "@angular/core";
import {UniteDeProduction__EquipeDTO} from "../../../../../core/dtos/unite-de-production__equipe";
import {DATEPICKER_FR} from "../../../../../core/constants";
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {ProductionService} from "../../../../../core/services/gestion-production/production.service";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'yo-bulk-edition-details-dialog',
  templateUrl: './bulk-edition-details-dialog.component.html',
  styleUrls: ['./bulk-edition-details-dialog.component.scss']
})

export class BulkEditionDetailsDialogComponent implements OnInit, OnDestroy {

  udpEquipeList: UniteDeProduction__EquipeDTO[] = [];
  displayDialog: boolean = false;
  dateFab: Date;
  dlc: Date;
  udpEquipe: UniteDeProduction__EquipeDTO;
  nbPortions: number;
  localeFr = DATEPICKER_FR;
  dataSourceTeams: any;
  subOpenDialog: Subscription;

  constructor(private prodSvc: ProductionService, private utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  initSubscriptions = (): void => {
    this.subOpenDialog = this.prodSvc.openMassiveTreatmentDialog$
      .subscribe((args) => {
        this.displayDialog = true;
        this.udpEquipeList = args.udpEquipeList;
        this.dataSourceTeams = new DataSource({
          store: new ArrayStore({
            data: this.udpEquipeList,
            key: 'id',
          }),
          group: 'zoneDeProductionLibelle',
        });
      })
  }

  closeDialog = () => {
    this.displayDialog = false;
    this.clearData();
  };

  disabledSaveBtn = () => (this.nbPortions === null || this.nbPortions === undefined) && !this.dateFab && !this.dlc && !this.udpEquipe;

  sendBulkDetailSupplier = () => {
    const data: BulkDetailSupplier = new BulkDetailSupplier();
    data.nbParts = this.nbPortions;
    data.dateFab = this.dateFab;
    data.dlc = this.dlc;
    data.udpEquipe = this.udpEquipe;
    if ((data.nbParts !== null && data.nbParts !== undefined) || data.dateFab || data.dlc || data.udpEquipe)
     this.prodSvc.announceApplyMassiveTreatment(data);
    this.closeDialog();
  };

  clearData = () => {
    this.udpEquipe = undefined;
    this.dateFab = undefined;
    this.dlc = undefined;
    this.nbPortions = undefined;
  };

}

export class BulkDetailSupplier {
  dateFab: Date;
  dlc: Date;
  dateLiv: Date;
  udpEquipe: UniteDeProduction__EquipeDTO;
  isFabDateUpdate: boolean;
  nbParts: number;
}
