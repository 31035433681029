import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Subscription} from "rxjs";
import {UtilisateurDTO} from "../../core/dtos/utilisateur-dto";
import {UtilisateurService} from "../../core/utilisateur.service";
import {HELP_FOLDERS} from '../../core/constants';
import {UtilisateurResolverService} from "../utilisateur-resolver.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.scss']
})
export class UtilisateursComponent implements OnInit {


  start: boolean = true;

  // Subscriptions
  subGrilleUtilisateurs: Subscription;
  subUtilisateur: Subscription;
  subDeleteUtilisateur: Subscription;


  totalRecords: number;
  loading: boolean;
  utilisateursForGrille: UtilisateurDTO[];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/utilisateurs';



  constructor( public utils: UtilsService,
               private utilisateurSvc: UtilisateurService,
               private utilisateurResolverService: UtilisateurResolverService) { }

  ngOnInit() {
    this.utils.setTitle('Gestion des utilisateurs');
    this.subGrilleUtilisateurs = this.utilisateurSvc.searchUtilisateur('').subscribe(utilisateurs => this.utilisateursForGrille = utilisateurs);

    this.subUtilisateur = this.utilisateurResolverService.utilisateurAnnounceSource.subscribe(utilisateur => {
      const idx = this.utilisateursForGrille.findIndex(u => u.id === utilisateur.id);
      if (idx >= 0)
        this.utilisateursForGrille[idx] = utilisateur;
      else
        this.utilisateursForGrille.push(utilisateur);
    });

    this.utilisateurSvc.utilisateurSubjectToDelete$.subscribe(utilisateur => {
      const idx = this.utilisateursForGrille.findIndex(u => u.id === utilisateur.id);
      this.utilisateursForGrille.splice(idx, 1);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleUtilisateurs);
    this.utils.unsubscribe(this.subUtilisateur);
    this.utils.unsubscribe(this.subDeleteUtilisateur);
  }

  confirmDelete = async (utilisateurDTO: UtilisateurDTO): Promise<void> => {
    const result = confirm("Etes vous sûr de vouloir supprimer l\'utilisateur '" + utilisateurDTO.login + "' ?", 'Suppression de l\'utilisateur');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.delete(utilisateurDTO);
    }
  };

  /**
   * Suppression de l'UtilisateurDTO passé en paramètre.
   * @param {UtilisateurDTO} utilisateurDTO
   */
  delete = (utilisateurDTO: UtilisateurDTO): void => {
    this.subDeleteUtilisateur = this.utilisateurSvc.deleteUtilisateur(utilisateurDTO);
  };

  help = (): undefined => undefined;

  openUtilisateurDialog = (utilisateur: UtilisateurDTO): void => {
    this.utilisateurSvc.announceOpenDialog(utilisateur);
  }
}
