import {Component, OnDestroy, OnInit} from '@angular/core';
import {MarcheDTO} from '../../../core/dtos/gerstion-marche/marche-dto';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {MarcheSupplier} from '../marche-resolver.service';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';

import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GROUP_POSITION, GroupFormFields} from '../../../core/suppliers/group-formfields';
import {MarcheService} from '../../../core/services/entities/marche.service';
import {GestionsMarcheService, TAB_NAME_M} from '../../../core/services/gestion-marche/gestions-marche.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-fiche-identite-marche',
  templateUrl: './fiche-identite-marche.component.html',
  styleUrls: ['./fiche-identite-marche.component.scss']
})
export class FicheIdentiteMarcheComponent implements OnInit, OnDestroy {

  marche: MarcheDTO;
  subscriptionRoute: Subscription;

  form: FormGroup;
  formInfoGenerales: FormGroup = new FormGroup({});
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  groups: GroupFormFields[] = [];

  pathFile: string = HELP_FOLDERS.MARCHES + '/marche-edition';

  constructor(public utils: UtilsService,
              private marcheSvc: MarcheService,
              private gfs: GenericFormService,
              private gestionsMarcheSvc: GestionsMarcheService,
              private route: ActivatedRoute,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initData = () => {
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { marcheSupplier: MarcheSupplier }) => {
        this.marche = data.marcheSupplier.marche;
        this.initForm(this.marche);
      });
  };

  initForm = (marche: MarcheDTO) => {
    this.fieldsInfosGenerales = this.marcheSvc.getFields(marche);

    //desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });

    this.groups = [
      {
        titlePanel: 'Informations générales',
        fields: this.fieldsInfosGenerales,
        form: this.formInfoGenerales,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: marche.id > 0 ? ['site','code', 'libelle', 'descriptif', 'dateDebut', 'dateFin', 'actif']
            : ['site', 'libelle', 'descriptif', 'dateDebut', 'dateFin', 'actif']
        }
      },
    ];
  };


  isDisabledSaveBtn = (): boolean => {
    if (this.marche) {
      if (this.marche.id === 0) {
        return !(this.form.valid );
      }
      return !(this.form.valid && this.auth2Svc.isSiteLocal(this.marche.site.id));
    }
  };

  saveFicheIdentite = () => {
    if (this.form.valid) {

      // @ts-ignore
      this.marche.site = this.utils.getLightSite(this.formInfoGenerales.controls['site'].value);
      this.marche.libelle = this.formInfoGenerales.controls['libelle'].value;
      this.marche.descriptif = this.formInfoGenerales.controls['descriptif'].value;
      this.marche.dateDebut = this.formInfoGenerales.controls['dateDebut'].value;
      this.marche.dateFin = this.formInfoGenerales.controls['dateFin'].value;
      this.marche.actif = this.formInfoGenerales.controls['actif'].value;
      this.marche.code = this.formInfoGenerales.controls['code'] && this.formInfoGenerales.controls['code'].value ? this.formInfoGenerales.controls['code'].value : null;

      if(this.checkDateFin( this.marche.dateDebut,  this.marche.dateFin)){
        this.gestionsMarcheSvc.saveMarche(this.marche).subscribe(
          (response: ResponseWrapper<MarcheDTO>) => {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du marché ${response.one.libelle} avec succès`);
            this.gestionsMarcheSvc.announceMarche(response.one, TAB_NAME_M.info);
          }
        );
      }
    }
  };

  help = () => {
  };

  checkDateFin = (dateDebut: Date, dateFin: Date) => {
    if(dateFin >=  dateDebut){
      return true;
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Attention la "Date de fin" doit être supérieure ou égale à la "Date de début"`);
      return false;
    }
  };
}
