import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {HttpParams} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {TextureService} from './texture.service';
import {HttpService} from "../technique/http.service";

export const URL_POST_UPDATE_ARTICLE_PREFERE = `dolrest/gestion-unites-de-production/update/article-prefere`;

@Injectable({
  providedIn: 'root'
})
export class UniteDeProductionCatalogueAchatService {

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private textureSvc: TextureService,
              private httpSvc: HttpService,
              ) {
  }

  updatePrefere = (idGroupeAchatCa: number, idUdp: number) => {

    const params = new HttpParams()
      .set('idGroupeAchatCa', idGroupeAchatCa + '')
      .set('idUdp', idUdp + '')
      .set('modificationManuelle', true+'');
    return this.httpSvc.post(URL_POST_UPDATE_ARTICLE_PREFERE, params);
  };

}
