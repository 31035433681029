import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {BonCfDTO} from '../../../../core/dtos/boncfs-dto';
import {Table} from 'primeng/table';
import {BoncfDetailService} from '../../../../core/services/entities/boncf-detail.service';
import {CommandesService} from '../../../../core/services/gestion-commandes/commandes.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {SiteDTO} from '../../../../core/dtos/site-dto';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../../../core/services/technique/devextreme.service";
import {DxDataGridComponent} from "devextreme-angular";
import {FamilleProduitDTO} from "../../../../core/dtos/famille-produit-dto";
import {concat as _concat} from 'lodash';
import {FamillesProduitService} from "../../../../core/services/entities/familles-produit.service";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {UnitesDeProductionService} from "../../../../core/services/entities/unites-de-production.service";
import {DateReferenteUniteDeProductionEnum} from "../../../../core/enums/date-referente-unite-de-production-enum";
import * as moment from "moment";

@Component({
  selector: 'yo-bc-add-article-fournisseur',
  templateUrl: './bc-add-article-fournisseur.component.html',
  styleUrls: ['./bc-add-article-fournisseur.component.scss'],
})
export class BcAddArticleFournisseurComponent implements OnInit, OnDestroy {

  displayDialog: boolean;
  loadingTable: boolean = false;
  subAvailableArticles: Subscription;
  totalArticles: number;
  treeBoxValue: string[];
  treeDataSource: any;
  familleProduitList: FamilleProduitDTO[] = [];
  idFamilleProduitListSelected: number[] = [];

  /**
   * Articles sélectionnés (classe CatalogueAchat, table catalogue_achat).
   * Dans toute l'application, quand on parle d'articles c'est la classe CatalogueAchat qui est concernée.
   */
  selectedArticles: CatalogueAchatDTO[] = [];

  @Output() onSave = new EventEmitter<CatalogueAchatDTO[]>();

  /**
   * La p-table contenant les articles.
   */
  @ViewChild('ptable') ptable: Table;

  /**
   * Tous les articles (classe CatalogueAchat, table catalogue_achat).
   * Dans toute l'application, quand on parle d'articles c'est la classe CatalogueAchat qui est concernée.
   */
  articles: CatalogueAchatDTO[] = [];

  // RECHERCHE
  sites: SiteDTO[] = [];
  selectedSites: SiteDTO[] = [];
  referenceArticle: string;
  article: string;

  bonCf: BonCfDTO;

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  selectedRows: number[] = [];
  allMode: string;
  checkBoxesMode: string;

  udp: UniteDeProductionDTO;

  constructor(public utils: UtilsService,
              public commandesSvc: CommandesService,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              public bonCfDetailSvc: BoncfDetailService,
              private dxSvc: DevextremeService,
              private familleProduitSvc: FamillesProduitService,
              private udpSvc: UnitesDeProductionService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.availableArticlesSubscription();
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAvailableArticles);
  }

  availableArticlesSubscription = (): void => {
    this.subAvailableArticles = this.bonCfDetailSvc.availableArticles$
      .subscribe(response => {
        this.sites = [];
        this.displayDialog = false;
        this.articles = [];
        this.selectedSites = [];
        this.selectedArticles = [];
        this.bonCf = undefined;
        this.referenceArticle = '';
        this.article = '';

        this.familleProduitSvc.findAll(false)
          .subscribe(response => {
            this.familleProduitList = response.resultList;
          });

        if (!this.utils.isNullOrEmpty(response)) {
          this.displayDialog = true;
          this.bonCf = response;
          this.sites = this.auth2Svc.utilisateur.sites;
          this.initUdp();
        }
      });
  };

  closeDialog = (): void => {
    this.selectedSites = [];
    this.referenceArticle = '';
    this.article = '';
    this.dataSource = null;
    this.treeBoxValue = null;
    this.treeDataSource = null;
    this.familleProduitList = null;
    this.idFamilleProduitListSelected = null;
    this.grid.instance.clearFilter();
    this.bonCfDetailSvc.announceAvailableArticles(undefined);
    this.displayDialog = false;
  };

  addArticlesForPropositionCommande = (): void => {
    try {
      this.onSave.emit(this.selectedArticles);
    } finally {
      // toujours appeler le closeDialog apres le saveEmit, sous peine de voir le selectedArticles reinitialisé
      this.closeDialog();
    }
  };

  canAddArticlesForPropositionCommande = (): boolean => !this.utils.isCollectionNullOrEmpty(this.selectedArticles);

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        const filterRefArticle: FilterItem = filters.find(f => f.column === 'referenceArticle');
        const filterArticle: FilterItem = filters.find(f => f.column === 'produitArticle.libelle');
        const sortArticle: Sort = sorts.find(s => s.path === 'produitArticleLibelle');
        const sortRefArticle: Sort = sorts.find(s => s.path === 'referenceArticle');

        console.log(this.udp.dateReferente === DateReferenteUniteDeProductionEnum.DATE_LIVRAISON)
        return this.commandesSvc.getArticlesFournisseur({
          page: this.grid.instance.pageIndex() + 1,
          size: loadOptions.take || this.grid.instance.pageSize(),
          idsUniteProduction: [this.bonCf.uniteDeProductionId],
          idsSites: this.auth2Svc.utilisateur.sites.map(site => site.id),
          idsFournisseurs: [this.bonCf.fournisseurId],
          onlyParent: false,
          articleLabel: filterArticle ? filterArticle.value : '',
          refArticle: filterRefArticle ? filterRefArticle.value : '',
          dateLivraisonAjustee: this.bonCf.dateLivraisonAjustee ? this.utils.getYYYYMMDD(moment(this.bonCf.dateLivraisonAjustee)) : "",
          idsFamilyProduct: this.idFamilleProduitListSelected || [],
          sortByArticleLabel: sortArticle ? sortArticle.dir : '',
          marketUsed: false, // En attendant de voir avec Sébastien
          sortByRefProvider: sortRefArticle ? sortRefArticle.dir : '',
          checkOnlyActive: true,
          dateEffectiveForPeriodPrice: (this.udp.dateReferente === DateReferenteUniteDeProductionEnum.DATE_LIVRAISON) ? this.utils.getYYYYMMDD(moment(this.bonCf.dateLivraisonAjustee)) : this.utils.getYYYYMMDD(moment(this.bonCf.dateCommande))
        }).toPromise().then(response => {
          this.articles = response.resultList;
          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  initUdp = () => {
    this.gds.getOne(this.udpSvc.getEntityName(), this.bonCf?.uniteDeProductionId)
      .subscribe(response => {
        this.udp = response.one;
        this.initCustomStore();
      });
  }

  removeArticle = (catalogueAchat: CatalogueAchatDTO): void => {
    if (catalogueAchat.quantite) {
      catalogueAchat.quantite--;
      if (catalogueAchat.quantite < 0) {
        catalogueAchat.quantite = 0;
      }
    } else {
      catalogueAchat.quantite = 0;
    }
    this.selectArticle(catalogueAchat);
  };

  addArticle = (catalogueAchat: CatalogueAchatDTO): void => {
    if (!catalogueAchat.quantite) {
      catalogueAchat.quantite = 0;
    }
    catalogueAchat.quantite++;
    catalogueAchat.prix = catalogueAchat.groupeAchatCaList && catalogueAchat.groupeAchatCaList.length && catalogueAchat.groupeAchatCaList[0].prixUF ? catalogueAchat.groupeAchatCaList[0].prixUF.value : catalogueAchat.prix
    this.selectArticle(catalogueAchat);
  };

  isSelectedArticle = (catalogueAchat: CatalogueAchatDTO): boolean => catalogueAchat.quantite && catalogueAchat.quantite > 0;

  selectArticle = (catalogueAchat: CatalogueAchatDTO): void => {
    let i = 0;
    while (i < this.selectedArticles.length && this.selectedArticles[i].id !== catalogueAchat.id) {
      i++;
    }
    if (i >= this.selectedArticles.length) {
      if (this.isSelectedArticle(catalogueAchat)) {
        this.selectedArticles.push(catalogueAchat);
      }
    } else {
      if (!this.isSelectedArticle(catalogueAchat)) {
        this.selectedArticles.splice(i, 1);
      }
    }
  };

  onChangeParentDenreeSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.idFamilleProduitListSelected = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.grid.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.familleProduitList.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = _concat([...result], this.reflexionSearchId([...this.familleProduitList], fp.id))
        }
      })
    });

    result = _concat([...result], idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = _concat([...idResultList], this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

}
