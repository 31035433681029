import {Component, Input, OnInit} from '@angular/core';
import {DOL_VERSION_JSON} from '../../../../dol-version';
import {UtilsService} from '../../../core/utils/utils.service';

@Component({
  selector: 'yo-infos-version',
  templateUrl: './infos-version.component.html',
  styleUrls: ['./infos-version.component.scss']
})
export class InfosVersionComponent implements OnInit {

  dolVersionJson = DOL_VERSION_JSON;

  @Input() resetIconClasses: boolean
  @Input() iconClasses: String;
  @Input() labelClasses: String;
  @Input() displayLabel: boolean;

  constructor(public utils: UtilsService) {
  }

  ngOnInit(): void {
  }

}
