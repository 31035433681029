import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService {

  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TokenSupplier> | Promise<TokenSupplier> | TokenSupplier {
    let tokenUtilisateur: string = route.paramMap.get('tokenUtilisateur');
    let idUtilisateur: string = route.paramMap.get('id');

    let tokenSupplier = new TokenSupplier();

    tokenSupplier.token = tokenUtilisateur;
    tokenSupplier.id = parseInt(idUtilisateur);

    return tokenSupplier;
  }
}

export class  TokenSupplier {
  id: number;
  token: string;
}
