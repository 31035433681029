import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldCheckboxSupplier extends FormFieldBaseSupplier<boolean> {
  controlType = 'checkbox';

  constructor(options: any) {
    super(options);
  }

}
