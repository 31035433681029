import {ContratmenuDTO} from "../../dtos/contratmenu-dto";
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../dtos/contratmenuconvive__contrainte-alim-dto";

export default class InfoMenuValidationSupplier {
  contrats: ContratmenuDTO[];
  prestations: ContratMenuConviveDTO[];
  regimes: ContratMenuConvive__ContrainteAlimDTO[];

  displayDialog: boolean;

  selectedContrat: ContratmenuDTO;
  selectedPrestation: ContratMenuConviveDTO;
  selectedRegime: ContratMenuConvive__ContrainteAlimDTO;
}
