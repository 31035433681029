import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuCompositionDTO} from '../../core/dtos/menucomposition-dto';
import {CelluleTableauDTO} from '../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {MenusCompositionService} from '../../core/services/gestionmenus/menus-composition.service';
import {UtilsService} from '../../core/utils/utils.service';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {MSG_KEY, MSG_SEVERITY, USER_PREFERENCE} from '../../core/constants';
import {Subscription} from 'rxjs';
import {cloneDeep} from 'lodash';
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-taux-de-prise-menucompo',
  templateUrl: './taux-de-prise-menucompo.component.html',
  styleUrls: ['./taux-de-prise-menucompo.component.scss']
})
export class TauxDePriseMenucompoComponent implements OnInit, OnDestroy {

  @ViewChild('txprise', {static: false}) public inputTxPrise: ElementRef;

  @Input() set menuCompo(value: MenuCompositionDTO) {
    this.internalMenuCompo = cloneDeep(value);
    this.externalMenuCompo = value;
    this.tauxDePriseInputCache = cloneDeep(value.tauxDePrise);
  }

  @Input() cellule: CelluleTableauDTO;
  @Input() disable: boolean;

  subTauxDePrise: Subscription;
  displayTauxDePrise: boolean;
  displayDialogTauxDePrise: boolean;
  tauxDePrise: number;
  tauxDePriseInputCache: number

  internalMenuCompo: MenuCompositionDTO;
  externalMenuCompo: MenuCompositionDTO;

  constructor(private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private menuCompoSvc: MenusCompositionService,
              public utils: UtilsService,
              private mp2Svc: MenusPlanning2Service,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {}


  ngOnInit() {
    this.subscriptionDisplayTauxDePrise();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTauxDePrise);
  }

  subscriptionDisplayTauxDePrise = () => {
    this.subTauxDePrise = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayTauxDePrise = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_TAUX_DE_PRISE);
      this.cd.markForCheck();
    });
  };

  canModify = () => this.auth2Svc.isSiteLocal(this.cellule.contratMenuSiteId);

  closeDialog = (isSaved: boolean) => {
    this.displayDialogTauxDePrise = false;
    this.cd.markForCheck();
    this.mp2Svc.planningMenus.changeDetector.markForCheck();
    if (!isSaved) {
      this.internalMenuCompo.tauxDePrise = this.tauxDePriseInputCache;
    }
  };

  saveMenuComposition = (menuCompo: MenuCompositionDTO) => {
    this.menuCompoSvc.saveMenuComposition(menuCompo).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.externalMenuCompo.tauxDePrise = menuCompo.tauxDePrise;
        // recalculer le tdp du decoupage repas global
        this.mp2Svc.computeTauxDePriseDecoupageRepas(this.cellule);
        // recalculer le cout unitaire pondere du decoupage repas
        this.mp2Svc.computeCoutPortionPondereDecoupageRepas(this.cellule);
        // recalculer le cout unitaire pondere du menucompo
        this.mp2Svc.computeCoutPortionPondereMenuCompo(this.externalMenuCompo);
        // recalculer le cout unitaire pondere du repas --> fait par le recalcul de l'interface graphique
        this.closeDialog(true);
      }
    });
  };


  editTauxDePrise = () => {

    if (this.displayTauxDePrise && this.canModify()) {
      if (!this.disable)
        this.displayDialogTauxDePrise = true;
      else
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous ne pouvez pas modifier le taux de prise lorsque le menu est validé`);
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous ne pouvez pas modifier le taux de prise. Vous n'avez pas les droits sur cet environnement`);
    }
  };

  changeTauxDePrise = ($event: any) => {
    this.internalMenuCompo.tauxDePrise = $event;
  };

  saveOnKeyPressEnter = ($event: KeyboardEvent) => {
    if ($event.key === 'Enter') {
      this.saveMenuComposition(this.internalMenuCompo);
    }
  };

  onShow = () => {
    setTimeout(() => {
      this.inputTxPrise.nativeElement.focus();
    }, 0);
  };
}
