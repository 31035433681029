import {Component, Input, OnInit} from '@angular/core';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {MenuCompositionDTO} from '../../../core/dtos/menucomposition-dto';
import {find as _find, sortBy as _sortBy} from 'lodash';

@Component({
  selector: 'yo-famille-gemrcn',
  templateUrl: './famille-gemrcn.component.html',
  styleUrls: ['./famille-gemrcn.component.scss']
})
export class FamilleGemrcnComponent implements OnInit {

  @Input() familleNutri : FamilleGemrcnDTO;
  @Input() displayLabel : boolean=false;

  @Input()  set menusCompos(value:MenuCompositionDTO[]){
    this._mcsOfFamilleGemrcn= this.getDetailFamille(this.familleNutri,value);
  }
  @Input() showDetails : boolean=false;
  displayDetails : boolean=false;

  _mcsOfFamilleGemrcn :MenuCompositionDTO[]=[];

  constructor(public utils : UtilsService) { }

  ngOnInit() {
  }

  expandDetails(){
    this.displayDetails = !this.displayDetails;
  }

  getDetailFamille(familleGemrcn : FamilleGemrcnDTO, menusCompos:MenuCompositionDTO[]):MenuCompositionDTO[]{

    let mcsOfFamilleGemrcn :MenuCompositionDTO[]=[];

    if(!this.utils.isCollectionNullOrEmpty(menusCompos)){

      for(let mc of menusCompos){
        const item= _find(mc.familleNutritionnelleProduitDTOList,{'idFamille':familleGemrcn.id});
        if(item){
          mcsOfFamilleGemrcn.push(mc);
        }
      }
    }

    mcsOfFamilleGemrcn= _sortBy(mcsOfFamilleGemrcn,['menuDecoupageDate.dateMenu']);

    return mcsOfFamilleGemrcn;
  }


}
