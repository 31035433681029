import {ObjectDTO} from "./object-dto";

export class MailHistoDTO extends ObjectDTO {

  sentDate:Date;
  readDate:Date;
  statut:number;
  token:string;
  emitterNom:string;
  emitterPrenom:string;
  emitterEmail:string;
  receiver:string;
  extraInfos:string;
  erreur:string;

}
