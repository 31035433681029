import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {USER_PREFERENCE} from '../../core/constants';
import {MenuCompositionDTO} from '../../core/dtos/menucomposition-dto';

@Component({
  selector: 'yo-cout-portion',
  templateUrl: './cout-portion.component.html',
  styleUrls: ['./cout-portion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoutPortionComponent implements OnInit {

  subDisplayPrixAchat: Subscription;
  subMenuCompo: Subscription;
  displayPrixAchat: boolean = false;

  @Input() menuComposition: MenuCompositionDTO;

  constructor(private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              public utils: UtilsService) {
  }

  ngOnInit() {
    this.subscriptionDisplayPrixAchat();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayPrixAchat);
    this.utils.unsubscribe(this.subMenuCompo);
  }

  subscriptionDisplayPrixAchat = () => {
    this.subDisplayPrixAchat = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayPrixAchat = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
      this.cd.markForCheck();
    });
  };

  getErreurs = (menuComposition: MenuCompositionDTO) => {
    if (!this.utils.isCollectionNullOrEmpty(menuComposition.udpPdErreurs)) {
      return menuComposition.udpPdErreurs.map(item => item.message + '<br>' + item.detail).join(', ');
    } else if (!menuComposition.udpPdPrixAchat) {
      return "Le prix n'a pas encore été calculé. Veuillez attendre la prochaine mise à jour des prix.";
    }
    return '';
  };
}
