import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'yo-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  @Input() displayDialog: boolean;
  passToModeAReceptionner: boolean = true;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  sendEmail = () => {
    this.displayDialog = false;
    this.onSave.emit(this.passToModeAReceptionner);
  }

  handleValueChange = (e: any) => {
    this.passToModeAReceptionner = e.value;
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.onClose.emit(this.displayDialog);
  };
}
