<div class="error" *ngIf="control.invalid && (control.dirty )">

  <div class="pg-2" *ngIf="control.hasError('required')">
    {{startError(label)}} est obligatoire.
  </div>
  <div class="pg-2" *ngIf="control.hasError('minlength')">
    {{startError(label)}} ne peut avoir moins de {{control.getError('minlength').requiredLength}} caractères.
  </div>
  <div class="pg-2" *ngIf="control.hasError('maxlength')">
    {{startError(label)}} ne peut dépasser {{control.getError('maxlength').requiredLength}} caractères.
  </div>
  <div class="pg-2" *ngIf="control.hasError('min')">
    {{startError(label)}} doit être supérieur ou égal à {{control.getError('min').min}}.
  </div>
  <div class="pg-2" *ngIf="control.hasError('max')">
    {{startError(label)}} doit être inférieur ou égal à {{control.getError('max').max}}.
  </div>

</div>

