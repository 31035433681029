<p-dialog
  (onHide)="closeDialog()"
  [dismissableMask]="true"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [style]="{'width':widthDialog}"
  [modal]="true"
  appendTo="body"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-utensils yoni-color"></i> Fiche Technique : {{infosMenuCompoSupplier?.menuCompo.produitDeclinaisonLibelle}}
    </div>
  </ng-template>

  <div class="row">

    <div class="col" style="min-width:520px;">
      <div class="row">
        <div *ngIf="infosMenuCompoSupplier" class="col-md-7">
          <yo-img-entity
            [entityId]="infosMenuCompoSupplier.menuCompo.produitId"
            [entityName]="produitsSvc.getEntityName()"
            [height]="280"
            format="regular"
            [refresh]="refreshImage"
            [displayNoImage]="false"
            [title]="infosMenuCompoSupplier.menuCompo.produitDeclinaisonLibelle"
            [ordre]="1"></yo-img-entity>
        </div>
        <div class="col-md-5 mg-t-10">
          <div class="row">
            <div class="col-md-12">
              <strong class="cursor "
                      (click)="openDetailsProduit(infosMenuCompoSupplier?.menuCompo.fabrique,infosMenuCompoSupplier?.menuCompo.produitId)">
                <yo-icon-produitfab [fabrique]="infosMenuCompoSupplier?.menuCompo.fabrique"></yo-icon-produitfab>
                {{infosMenuCompoSupplier?.menuCompo.produitDeclinaisonLibelle | uppercase}}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label class="badge-color">Prestation</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.cellule.libelleConvive}}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label class="badge-color">Jour</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.cellule.dateMenu | date:'E d LLL yyyy' | titlecase}}</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Repas</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.cellule.libelleRepas}}</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Composante</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.cellule.libelleDecoupageRepas}}</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Déclinaison</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.menuCompo.declinaisonLibelle}}</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Prix d'achat</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.menuCompo.udpPdPrixAchat | currency:'EUR':'symbol':'1.4-4'}} / portion</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Effectif Prévu</label>
            </div>
            <div class="col-md-9">
              <strong>{{infosMenuCompoSupplier?.menuCompo.effectifPrevu}} pers.</strong>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color mg-r-2">
                Allergènes
              </label>

            </div>
            <div class="col-md-9">
              <ng-template *ngTemplateOutlet="tplAllergenes;context:{menuCompo:infosMenuCompoSupplier?.menuCompo}"></ng-template>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <label class="badge-color">Appellations</label>
            </div>
            <div class="col-md-9">
              <ng-template *ngTemplateOutlet="tplAppellations;context:{menuCompo:infosMenuCompoSupplier?.menuCompo}"></ng-template>
            </div>
          </div>
        </div>
    </div>
      </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p-tabView (activeIndexChange)="activeIndexChange($event)" [(activeIndex)]="activeIndex">
        <p-tabPanel header="Composants">
          <ng-template
            *ngTemplateOutlet="tplIngredients;context:{menuCompo:infosMenuCompoSupplier?.menuCompo, ingredients:ingredients}"></ng-template>
        </p-tabPanel>
        <p-tabPanel header="Apports nutritionnels">
          <ng-template
            *ngTemplateOutlet="tplApportsNutris;context:{menuCompo:infosMenuCompoSupplier?.menuCompo}"></ng-template>
        </p-tabPanel>
        <p-tabPanel header="Mode opératoire">
          <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(infosMenuCompoSupplier?.menuCompo.modeOperatoire)">
          </p>
        </p-tabPanel>
        <p-tabPanel header="Familles GEMRCN">
          <ng-template *ngTemplateOutlet="tplFamillesGemrcn;context:{menuCompo:infosMenuCompoSupplier?.menuCompo}"></ng-template>
        </p-tabPanel>
        <p-tabPanel header="Aller sur la fiche technique" leftIcon="fas fa-arrow-circle-right"
                    tooltip="Voir la fiche technique">
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <!--TEMPLATE INGREDIENTS-->
  <ng-template #tplIngredients let-menuCompo="menuCompo" let-ingredients="ingredients">

    <ng-container *ngIf="utils.isCollectionNullOrEmpty(ingredients)">
      Aucun ingrédient.
    </ng-container>

    <ng-container *ngIf="!utils.isCollectionNullOrEmpty(ingredients)">
      <ng-container *ngFor="let ing of ingredients">
        <a class="ingLinks cursor"
           (click)="openDetailsProduit(ing.produitUtilise.fabrique,ing.produitUtilise.produitId)">
          <yo-icon-produitfab [fabrique]="ing.produitUtilise.fabrique"></yo-icon-produitfab>
          {{ing.produitUtilise.libelle}}</a><br/>
      </ng-container>

    </ng-container>

  </ng-template>


  <!--TEMPLATE FAMILLES GEMRCN-->
  <ng-template #tplFamillesGemrcn let-menuCompo="menuCompo">

    <ng-container *ngIf="utils.isCollectionNullOrEmpty(famillesGemrcn)">
      Aucune.
    </ng-container>

    <ng-container *ngIf="!utils.isCollectionNullOrEmpty(famillesGemrcn)">

      <ng-container *ngFor="let fnp of menuCompo.familleNutritionnelleProduitDTOList">
        <yo-famille-gemrcn [familleNutri]="menuCompoSvc.getFamilleNutri(fnp)" [displayLabel]="true"></yo-famille-gemrcn>
      </ng-container>

    </ng-container>

  </ng-template>

  <!--TEMPLATE APPELLATIONS-->
  <ng-template #tplAppellations let-menuCompo="menuCompo">

    <ng-container *ngIf="utils.isCollectionNullOrEmpty(produitAppellations)">
      Aucune.
    </ng-container>

    <ng-container *ngIf="!utils.isCollectionNullOrEmpty(produitAppellations)">

      <span class="mg-r-2">
          {{labelAppellations()}}
        </span>


    </ng-container>

  </ng-template>


  <!--TEMPLATE ALLERGENES-->
  <ng-template #tplAllergenes let-menuCompo="menuCompo">


    <ng-container *ngIf="utils.isCollectionNullOrEmpty(produitAllergenes)">
      Aucun.
    </ng-container>

    <ng-container *ngIf="!utils.isCollectionNullOrEmpty(produitAllergenes)">


      <span class="mg-r-2">
          {{labelAllergenes()}}
        </span>

    </ng-container>

  </ng-template>

  <!--TEMPLATE APPORTS NUTRIS-->
  <ng-template #tplApportsNutris let-menuCompo="menuCompo">

    <div class="row">
      <div class="col-md-6 col-md-auto">
        <p-table [columns]="cols" [value]="apportsNutritionnels" *ngIf="kcalPortion>0">

          <!--COLGROUP-->
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

                <col *ngSwitchCase="'libelle'"  [style.width]="'200px'"
                     [style.text-align]="'left'">
                <col *ngSwitchDefault [style.width]="'155px'" [style.text-align]="'right'">

              </ng-container>

            </colgroup>
          </ng-template>

          <!--HEADER-->
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.field">

                  <ng-container *ngSwitchCase="'teneurPortion'">
                    {{col.header}} ({{getQuantitePortion(produitDeclinaison)}} g)
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    {{col.header}}
                  </ng-container>
                </ng-container>

              </th>
            </tr>
          </ng-template>

          <!--BODY-->
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <ng-container *ngFor="let col of columns" >

                <ng-container [ngSwitch]="col.field">
                  <ng-container *ngSwitchCase="'libelle'">
                    <td>
                      {{rowData.libelle}}
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'teneur100g'">
                    <td class="text-right">
                      {{getTeneur(produitApportsNutris,rowData)}} g
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'teneurPortion'">
                    <td class="text-right">
                      {{getQuantite(produitDeclinaison,rowData,produitApportsNutris)}} g
                    </td>
                  </ng-container>

                </ng-container>

              </ng-container>


            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-md-6 col-md-auto">
        <div class="row" *ngIf="kcalPortion && kcalPortion > 0">
          <div class="col-md-12 text-center">
            <span class="kcalPortion">{{getKcalPortionLabel()}}</span>
          </div>
        </div>
        <div class="row" *ngIf="kcalPortion>0">
          <div class="col-md-12 center-chart-compo">
            <dx-pie-chart
              id="apportsNutrisGraph"
              title="Apports nutritionnels"
              palette="bright"
              [dataSource]="apportsNutriGraphData"
            >
              <dxo-legend
                orientation="horizontal"
                itemTextPosition="right"
                horizontalAlignment="center"
                verticalAlignment="bottom"
                [columnCount]="4"
              ></dxo-legend>
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxi-series argumentField="label" valueField="value">
                <dxo-label
                  [visible]="true"
                  position="columns"
                >
                  <dxo-font [size]="16"></dxo-font>
                  <dxo-connector [visible]="true" [width]="0.5"></dxo-connector>
                </dxo-label>
              </dxi-series>
            </dx-pie-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!kcalPortion || kcalPortion === 0">
      <div class="col-md-12 text-center">
        <span class="kcalPortion">Aucune donnée nutritionnelle renseignée.</span>
      </div>
    </div>
  </ng-template>

</p-dialog>


