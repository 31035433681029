import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {BonCfDTO} from '../../dtos/boncfs-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {ProfilDTO} from '../../dtos/profil-dto';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {catchError} from 'rxjs/operators';
import {startCase as _startCase} from 'lodash';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from "../../constants";
import {Observable} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";

export const URL_POST_SAVE_PROFIL = `dolrest/utilisateur/save/profil`;
export const URL_GET_DELETE_PROFIL = `dolrest/utilisateur/delete/profil`;

@Injectable({
  providedIn: 'root'
})
export class ProfilsService extends GenericHandler<ProfilDTO> {

  constructor(private httpSvc :HttpService, utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title
  ) {
    super(utils, auth2Svc, router, http, title);
  }


  createEmptyDTO(): ProfilDTO {
    return new ProfilDTO();
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getAllFromEnvironnement =(): Observable<ResponseWrapper<ProfilDTO>> => {
    return this.httpSvc.get('dolrest/utilisateur/get-profil-list');
  }

  getCreateNewObjectLabel()
    :
    string {
    return "";
  }

  getEntityName()
    :
    string {
    return "profil";
  }


  getHelp()
    :
    DialogMsgSupplier {
    return undefined;
  }

  getOas()
    :
    boolean {
    return false;
  }

  getSort()
    :
    string[] {
    return ['libelle,asc'];
  }

  getTitle()
    :
    string {
    return "GESTION DES PROFILS";
  }

  getFields(dto
              :
              BonCfDTO
  ):
    FormFieldBaseSupplier<any> [] {
    return [];
  }

  getEditObjectLabel(data
                       :
                       ObjectDTO
  ):
    string {
    return "";
  }

  filterAllActiveProfils(actif
                           :
                           boolean
  ):
    GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();

    predicat1.path = 'profil.actif';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = actif ? PREDICAT_VALUE.True : PREDICAT_VALUE.False;

    search.predicats.push(predicat1);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.luceneSortType = 'string';
    sort.path = 'profil.libelle';
    search.sorts = [];
    search.sorts.push(sort);

    grs.search = search;

    return grs;

  }

  saveProfil(dialogProfil: ProfilDTO) {

    return this.http.post(URL_POST_SAVE_PROFIL, dialogProfil)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );


  }

  deleteProfil(idProfil: number) {

    return this.http.get(URL_GET_DELETE_PROFIL, {params: new HttpParams().set('idProfil', idProfil + '')})
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}
