import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {Title} from '@angular/platform-browser';


@Component({
  templateUrl: './gestionproduits.component.html',
  styleUrls: ['./gestionproduits.component.scss']
})
export class GestionproduitsComponent implements OnInit, AfterViewInit {

  @ViewChild('grilleproduits') grilleproduits;

  constructor(private titleSvc: Title) {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.titleSvc.setTitle('Gestion des produits');
  }


}
