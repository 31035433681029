<div class="d-flex justify-content-between mg-b-10">
  <div class="full-width">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <yo-button
      label="CRÉER"
      iconClass="fa fa-plus"
      (onClick)="openEditDialog(undefined)"
      [disabled]="!canCreate()"
    >
    </yo-button>
    <yo-button
      iconClass="fa fa-trash"
      class="float-right"
      buttonClass="cta-delete"
      (onClick)="delete()"
      [disabled]="!selectedRows.length || !auth2Svc.isSiteLocal(contratMenuConvive?.site?.id)"
    >
    </yo-button>
  </div>
</div>

<div class="container-table">
  <dx-data-grid
    [dataSource]="cmcEquipeList"
    [remoteOperations]="true"
    keyExpr="id"
    height="100%"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [(selectedRowKeys)]="selectedRows"
    #grid>
    <dxi-column dataField="id" caption="Actions"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="false"
                [alignment]="'left'"
                [width]="90"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="tacheLibelle" caption="Types de fabrication"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="false"
                [width]="150"
                cellTemplate="tacheCellTemplate">
    </dxi-column>
    <dxi-column dataField="equipeLibelle" caption="Équipes" cellTemplate="teamsCellTemplate"
                [width]="150"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="repasLibelle" caption="Repas"
                [width]="150"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="repasCellTemplate">
    </dxi-column>
    <dxi-column dataField="zoneDeProductionLibelle"
                caption="Atelier"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [width]="150"
                cellTemplate="atelierCellTemplate">
    </dxi-column>
    <dxi-column dataField="uniteDeProductionLibelle" caption="Unités de production"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [width]="200"
                cellTemplate="udpCellTemplate">
    </dxi-column>
    <dxi-column dataField="jours" caption="Jours du contrat affectés à l'équipe"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                headerCellTemplate="joursHeadTemplate"
                cellTemplate="joursCellTemplate">
    </dxi-column>
    <dxi-column dataField="actif" caption="Actif"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [width]="90">
    </dxi-column>

    <dxo-selection
      [selectAllMode]="'true'"
      [showCheckBoxesMode]="'always'"
      mode="multiple">
    </dxo-selection>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <ng-container *ngIf="!auth2Svc.isSiteLocal(contratMenuConvive?.site?.id)">
        <yo-cell-button (yoNavigation)="false"
                        [yoTooltipShowDelay]="1500"
                        [yoSmall]="true"
                        yoTextAlign="center"
                        yoIconClass="fa fa-eye"
                        yoTooltip="Voir"
        ></yo-cell-button>
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'tacheCellTemplate'">
      <label>{{cell.row.data.tacheLibelle | uppercase}}</label>
    </div>

    <div *dxTemplate="let cell of 'udpCellTemplate'">
      <td>
        <label class="cursor space" title="Aller sur l'unité de production"
               (click)="openUdpFabrication(cell.row.data.idUniteDeProduction)">{{cell.row.data.uniteDeProductionLibelle | uppercase}}</label>
      </td>
    </div>

    <div *dxTemplate="let cell of 'atelierCellTemplate'">
      <label class="space"
             title="Aller sur l'unité de production"
             (click)="openUdpFabrication(cell.row.data.idUniteDeProduction)">{{utils.capitalize(cell.row.data.zoneDeProductionLibelle)}}</label>
    </div>

    <div *dxTemplate="let cell of 'teamsCellTemplate'">
      <td class="no-overflow">
        <label class="badge badge-secondary cursor no-overflow"> {{cell.row.data.equipeLibelle | uppercase}}</label>
      </td>
    </div>

    <div *dxTemplate="let cell of 'repasCellTemplate'">
      <td>
        <label *ngIf="cell.row.data.libelleRepas">{{cell.row.data.libelleRepas | uppercase}}</label>
        <label *ngIf="!cell.row.data.libelleRepas">Aucun</label>
      </td>
    </div>

    <div *dxTemplate="let cell of 'joursCellTemplate'">
      <yo-cmc-equipes-jours [contratMenuConvive]="contratMenuConvive"
                            [moreDetails]="moreDetails"
                            [cmcEquipe]="cell.row.data"
                            [udpEquipeList]="udpEquipeList">
      </yo-cmc-equipes-jours>
    </div>
    <div *dxTemplate="let cell of 'joursHeadTemplate'">
      <div>Jours du contrat affectés à l'équipe</div>
      <div>
        <dx-check-box [disabled]="cmcEquipeList.length === 0 " [(ngModel)]="moreDetails" text="Détails"></dx-check-box>
      </div>
    </div>


    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} ligne(s)">
    </dxo-pager>

  </dx-data-grid>

</div>


<yo-cmc-equipe-dialog-edit></yo-cmc-equipe-dialog-edit>
