import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Title} from '@angular/platform-browser';
import {FournisseurDTO} from '../../dtos/fournisseur-d-t-o';
import {ObjectDTO} from '../../dtos/object-dto';
import {SecteurFournisseurDTO} from '../../dtos/secteurfournisseur-dto';
import {SecteurDTO} from '../../dtos/secteur-dto';
import {startCase as _startCase} from 'lodash';
import {FormFieldCurrencySupplier} from '../../suppliers/form-field-currency-supplier';
import {FormGroup} from '@angular/forms';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {SecteursService} from './secteurs.service';

@Injectable()
export class FournisseurService extends GenericHandler<FournisseurDTO> {


  private subjectActionArticleCatalogueAchat = new BehaviorSubject(undefined);
  actionArticlesCatalogueAchat$ = this.subjectActionArticleCatalogueAchat.asObservable();

  openDialogSubject = new Subject<FournisseurDTO>();
  openDialog$ = this.openDialogSubject.asObservable();

  static subjectLibelle = new Subject();
  libelle$ = FournisseurService.subjectLibelle.asObservable();

  private subjectEditFiliale = new Subject();
  editFiliale$ = this.subjectEditFiliale.asObservable();

  private subjectCreateFiliale = new Subject();
  createFiliale$ = this.subjectCreateFiliale.asObservable();

  announceActionArticlesCatalogueAchat = (majCatalogueAchatSupplier: ActionArticlesCatalogueAchatSupplier) => {
    this.subjectActionArticleCatalogueAchat.next(majCatalogueAchatSupplier);
  };

  announceCreateFiliale = (fournisseur: FournisseurDTO) => {
    this.subjectCreateFiliale.next(fournisseur);
  };

  announceEditFiliale = (fournisseur: FournisseurDTO) => {
    this.subjectEditFiliale.next(fournisseur);
  };

  announceOpenDialog = (fournisseur: FournisseurDTO) => {
    this.openDialogSubject.next(fournisseur);
  };


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['code,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES FILIALES';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE FILIALE';

  getEntityName = (): string => 'fournisseur';

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private secteurSvc: SecteursService) {
    super(utils, auth2Svc, router, http, title);
  }

  mapSecteurFournisseurtoSecteur = (dto: FournisseurDTO) => {
    const selectedSecteurs = [];
    if (!this.utils.isCollectionNullOrEmpty(dto.secteurFournisseurList)) {
      this.secteurSvc.secteurEnvironnement.map(item => {

        dto.secteurFournisseurList.map(sf => {
          if (sf.secteur.id === item.id && sf.actif) {
            selectedSecteurs.push(item);
          }
        })
      });
    }
    return selectedSecteurs;
  };

  getFields = (dto: FournisseurDTO): FormFieldBaseSupplier<any>[] => {

    let selectedSecteurs = [];

    if (this.utils.isNullOrEmpty(dto)) {
      dto = new FournisseurDTO();
      // En création le FournisseurDTO est actif.
      dto.actif = true;
    }

    if (dto.id === 0) {
      // En création le FournisseurDTO est actif.
      dto.actif = true;
    } else {
      selectedSecteurs = this.mapSecteurFournisseurtoSecteur(dto);
    }

    let formFieldSite: FormFieldTextboxSupplier;

    if (dto.id > 0) {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      })
    } else {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        required: true,
        options: this.optionsSites
      })
    }

    const formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 3,
        linkedFieldFn: this.onChangeLibelle
      }),

      new FormFieldDropdownSupplier({
        key: 'secteur',
        label: 'Secteur',
        type: 'multipleListBoxSiteAndValue',
        width: 400,
        value: this.utils.preSelectMultiList(this.secteurSvc.secteurEnvironnement, selectedSecteurs),
        order: 2,
        options: this.secteurSvc.secteurEnvironnement,
        required: true,
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'siret',
        label: 'Siret',
        value: dto.siret,
        maxLength: 14,
        required: false,
        order: 5
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 6
      }),

      new FormFieldCheckboxSupplier({
        key: 'francoDePortActif',
        label: 'Frais de port',
        help: 'Activer le frais de port',
        value: dto.francoDePortActif,
        required: false,
        order: 7
      }),


      new FormFieldCurrencySupplier({
        key: 'francoDePortCout',
        label: 'Coût des frais de port',
        help: `Frais de livraison à appliquer`,
        width: 150,
        type: 'number',
        suffix: `€`,
        value: dto.francoDePortCout,
        required: false,
        order: 8
      }),

      new FormFieldCurrencySupplier({
        key: 'francoDePortMontant',
        label: 'Montant HT minimal',
        help: 'Montant minimal à commander pour ne pas déclencher de frais de port',
        width: 150,
        type: 'number',
        suffix: `€`,
        value: dto.francoDePortMontant,
        required: false,
        order: 9
      }),

      new FormFieldTextboxSupplier({
        key: 'francoDePortPoids',
        label: 'Poids minimal',
        type: 'number',
        width: 150,
        suffix: 'KG',
        help: 'Poids en KG minimal à commander pour ne pas déclencher de frais de port',
        value: dto.francoDePortPoids,
        required: false,
        order: 10
      }),

      new FormFieldTextboxSupplier({
        key: 'francoDePortTva',
        label: 'TVA des frais de port',
        type: 'number',
        width: 150,
        suffix: '%',
        help: 'TVA des frais de port exprimée en %. Par defaut, elle est fixée à 20%.',
        value: dto.francoDePortTva,
        required: false,
        order: 11
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 999
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): FournisseurDTO => new FournisseurDTO();


  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LA FILIALE FOURNISSEUR '${data.libelle.toUpperCase()}'`;

  onChangeLibelle = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    FournisseurService.subjectLibelle.next(object);
  };
}

export class ActionArticlesCatalogueAchatSupplier {
  filiale: FournisseurDTO;
  sourceSecteurFournisseurList: SecteurFournisseurDTO[];
  destinationSecteurList: SecteurDTO[];
}
