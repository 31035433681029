
<span  [class.famnut]="!displayLabel" [class.withLabel]="displayLabel" data-toggle="tooltip" title="{{familleNutri.libelle}}" >

  <!--AFFICHER / CACHER LES DETAILS-->
  <ng-container *ngIf="showDetails && !utils.isCollectionNullOrEmpty(_mcsOfFamilleGemrcn)" >
      <i class="fas fa-minus cursor space" title="cacher les plats" *ngIf="displayDetails" (click)="expandDetails()"></i>
      <i class="fas fa-plus cursor space" title="afficher les plats" *ngIf="!displayDetails" (click)="expandDetails()"></i>
    </ng-container>

  <!--PASTILLE-->
  <i
    class="fa fa-circle fa-sm mg-r-5"
    [style.color]="utils.getHexColorFromString(familleNutri.couleur.hexa,true)"
    [style.text-shadow]="'0px 0px 1px #000'"
  >
  </i>

  <!--LIBELLE-->
  <ng-container *ngIf="displayLabel">{{familleNutri.libelle | uppercase}}</ng-container>

  <!--DETAILS-->
   <ng-container *ngIf="displayDetails">
    <ul *ngFor="let mc of _mcsOfFamilleGemrcn" >
      <li> Repas {{mc.repasLibelle}} du {{mc.menuDecoupageDate.dateMenu | date:'EEE d LLL yyyy' | titlecase}}, {{mc.decoupageRepasLibelle}} : {{mc.produitDeclinaisonLibelle}}</li>
    </ul>
  </ng-container>
</span>
