<form [formGroup]="form" (ngSubmit)="save()" novalidate>
  <div class="d-flex justify-content-between mg-b-10">
    <div>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    </div>
    <yo-button
      tooltip="Enregistrer"
      type="submit"
      iconClass="fas fa-save"
      buttonClass="cta-success"
      [disabled]="!canModify"></yo-button>
  </div>

  <div class="wrapper-fiche-id">
    <p-panel header="INFORMATIONS GÉNÉRALES" [toggleable]="true" [style]="{'margin-bottom': '10px'}">

      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Site <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <p-listbox
            id="site"
            filter="true"
            [listStyle]="{'height':'150px'}"
            [style]="{'max-width':'100%'}"
            defaultLabel="Selectionner le site..."
            [options]="findAllLocalSites()"
            optionLabel="libelle"
            formControlName="site"
          >
          </p-listbox>
        </div>
        <ng-container
          *ngTemplateOutlet="tplError;context:{form:form,property:'site',label:'Site'}"></ng-container>
      </div>

      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Libellé">Libellé<span class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm ">
            <input pInputText formControlName="libelle"/>
            <ng-container
              *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
          </div>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Code">Code</div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm ">
            <input pInputText formControlName="code"/>
            <ng-container
              *ngTemplateOutlet="tplError;context:{form:form,property:'code',label:'Code'}"></ng-container>
          </div>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Mode">Mode <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="modes"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'150px'}"
                         formControlName="mode"
                         [checkbox]="true"
                         optionLabel="libelle"
                         optionValue="mode"
                         filterPlaceHolder="Mode *">
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Unité à conditionner">Unité à conditionner <span
          class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="uniteAConditionnerList"
                         [filter]="true"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'150px'}"
                         formControlName="uniteAConditionner"
                         [checkbox]="true"
                         optionLabel="libelle"
                         filterPlaceHolder="Unité à conditionner *">
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
    <p-panel header="UNITÉS DE PRODUCTION ASSOCIÉS" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" title="Unité(s) de production à associer">Unité(s) de production à
          associer <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="unitesDeProductionList"
                         [multiple]="true"
                         [filter]="true"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'150px'}"
                         formControlName="udpList"
                         [checkbox]="true"
                         optionLabel="libelle"
                         filterPlaceHolder="Unité(s) de production à associer *">
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</form>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
