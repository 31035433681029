<div class="inner-warnings">
  <dx-tab-panel height="100%">
    <dxi-item title="Ordres différents" icon="warning" *ngIf="errors?.tourneePlcByDeliveryDayWithMultipleOrders">
      <dx-tab-panel height="100%">
        <dxi-item [disabled]="!errors.tourneePlcByDeliveryDayWithMultipleOrders[day] || !errors.tourneePlcByDeliveryDayWithMultipleOrders[day].length" [title]="day" icon="event" *ngFor="let day of ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']">
          <dx-data-grid
            [dataSource]="errors.tourneePlcByDeliveryDayWithMultipleOrders[day] || []"
            class="data-grid"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [height]="'100%'"
            columnResizingMode="widget">
            <dxi-column dataField="tournee.libelle" caption="Tournée"></dxi-column>
            <dxi-column dataField="pointLivraison.libelle" caption="Point de livraison client"></dxi-column>
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
    <dxi-item title="Tournées différentes" icon="warning" *ngIf="errors?.ordersPlcsWithMultipleRounds">
      <dx-tab-panel height="100%">
        <dxi-item [disabled]="!errors.ordersPlcsWithMultipleRounds[day] || !errors.ordersPlcsWithMultipleRounds[day].length" [title]="day" icon="event" *ngFor="let day of ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']">
          <dx-data-grid
            [dataSource]="errors.ordersPlcsWithMultipleRounds[day] || []"
            class="data-grid"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [height]="'100%'"
            columnResizingMode="widget">
            <dxi-column dataField="pointLivraison.libelle" caption="Point de livraison client"></dxi-column>
            <dxi-column dataField="tournees" caption="Tournée(s)" cellTemplate="templateCellTournees"></dxi-column>
            <div *dxTemplate="let cell of 'templateCellTournees'">
              {{ displayTourneesOrPlcs(cell.value) }}
            </div>
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
    <dxi-item title="Points de livraison différents" icon="warning" *ngIf="errors?.tourneeOrderByDeliveryDayWithMultiplePlcs">
      <dx-tab-panel height="100%">
        <dxi-item [disabled]="!errors.tourneeOrderByDeliveryDayWithMultiplePlcs[day] || !errors.tourneeOrderByDeliveryDayWithMultiplePlcs[day].length" [title]="day" icon="event" *ngFor="let day of ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']">
          <dx-data-grid
            [dataSource]="errors.tourneeOrderByDeliveryDayWithMultiplePlcs[day] || []"
            class="data-grid"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [height]="'100%'"
            columnResizingMode="widget">
            <dxi-column dataField="pointLivraisons" caption="Points de livraison client" cellTemplate="templateCellPlcs"></dxi-column>
            <dxi-column dataField="tournee.libelle" caption="Tournée(s)"></dxi-column>
            <div *dxTemplate="let cell of 'templateCellPlcs'">
              {{ displayTourneesOrPlcs(cell.value) }}
            </div>
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
    </dxi-item>
  </dx-tab-panel>
</div>
