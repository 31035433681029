import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {ClientDTO} from '../../dtos/client-dto';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {cloneDeep as _cloneDeep, startCase as _startCase} from 'lodash'
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {catchError} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';

export const URL_POST_SAVE_CLIENT = `dolrest/gestion-clients/save/one`;
export const URL_POST_DELETE_CLIENT = `dolrest/gestion-clients/delete/one`;

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends GenericHandler<ClientDTO> {

  static subjectLibelle = new Subject();
  libelle$ = ClientsService.subjectLibelle.asObservable();

  private subjectRefreshGrid = new Subject();
  refreshGrid$ = this.subjectRefreshGrid.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService) {

    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES CLIENTS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN CLIENT';
  }


  getEntityName(): string {
    return 'client';
  }

  getFields(dto: ClientDTO): FormFieldBaseSupplier<any>[] {
    if (this.utils.isNullOrEmpty(dto)) {
      dto = new ClientDTO();
    }

    let formFieldSite: FormFieldBaseSupplier<any> = this.createFormFieldBaseSupplierForSite(dto, 1);

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        linkedFieldFn: this.onChangeLibelle
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);

  }


  getAllFromEnvironnement = (): void => {
  };


  createEmptyDTO = (): ClientDTO => {

    const newClient = new ClientDTO();

    if (this.auth2Svc.utilisateur.siteListLocaux.length == 1) {
      newClient.site = _cloneDeep(this.auth2Svc.utilisateur.siteListLocaux[0]);
    }
    newClient.libelle = 'NOUVEAU CLIENT';
    newClient.actif = true;
    newClient.code = 'CLI-' + moment(new Date()).format('YYYYMMDDHHmmss');
    newClient.contact1 = undefined;
    newClient.contact2 = undefined;
    newClient.adresse = undefined;

    return newClient;
  };


  getEditObjectLabel = (data: ObjectDTO): string => "";

  save = (client: ClientDTO): Observable<ResponseWrapper<ClientDTO>> => {
    return this.http.post(URL_POST_SAVE_CLIENT, client)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };


  deleteClient = (client: ClientDTO) => {


    const fd = new FormData();
    fd.set('idClient', client.id + '');

    return this.http.post(URL_POST_DELETE_CLIENT, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  onChangeLibelle = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    ClientsService.subjectLibelle.next(object);
  };

  announceRefreshgrid = (client: ClientDTO) => {
    this.subjectRefreshGrid.next(client)
  }

}
