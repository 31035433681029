import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UtilsService} from './utils/utils.service';
import {SiteDTO} from './dtos/site-dto';
import {Observable, Subject, Subscription} from 'rxjs';
import {SiteRoutemapService} from './services/entities/site-routemap.service';
import {ResponseWrapper} from 'app/core/suppliers/wrappers/response-wrapper';
import {HttpClient} from '@angular/common/http';
import {MSG_KEY, MSG_SEVERITY} from './constants';
import {HttpService} from "./services/technique/http.service";
import {ToastService} from "./services/technique/toast.service";

export const URL_findSitesLocauxSansUniteDeProductionByUser = `dolrest/sites/findSitesLocauxSansUniteDeProductionByUser`;

@Injectable()
export class SiteService {


  // Le Subject

  private subjectOpenDialog = new Subject<SiteDTO>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  siteSubjectToDelete = new Subject<SiteDTO>();
  siteSubjectToDelete$ = this.siteSubjectToDelete.asObservable();

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils: UtilsService,
              private siteRouteMapService: SiteRoutemapService,
              private toastSvc: ToastService) {
  }


  /**
   * cf. SiteController#findAllActifs
   * @returns {Observable<SiteDTO[]>}
   */
  getListeSitesActifs = (): Observable<SiteDTO[]> => {
    return this.httpSvc.get('dolrest/sites/findAllActifs');
  };

  /**
   * Recherche des Site actifs ayant au moins 1 ContratMenu actif.
   * @returns {Observable<SiteDTO[]>}
   */
  findAllActifsWithContratMenu = (): Observable<SiteDTO[]> => {
    return this.http.get<SiteDTO[]>('dolrest/sites/findAllActifsWithContratMenu').pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  searchTerms = new Subject<string>();

  // Service message commands
  announceSearch = (term: string) => {
    this.searchTerms.next(term);
  };


  searchSite = (endUrl: string): Observable<SiteDTO[]> => this.httpSvc.get(this.siteRouteMapService.getSearchSitesLink(endUrl), null);

  /**
   * Recherche des Sites locaux de l'utilisateur connecté.
   */
  findSitesLocauxSansUniteDeProductionByUser = (): Observable<SiteDTO[]> => {
    return this.http.get<SiteDTO[]>(URL_findSitesLocauxSansUniteDeProductionByUser).pipe(
      catchError(error => this.utils.handleError(error, true)),
    );
  };

  /**
   * Raffraichissement du tableau de SiteDTO passé en paramètre.
   * @param {SiteDTO[]} sitesForGrille
   * @param {SiteDTO} site
   * @returns {SiteDTO[]}
   */
  refreshSiteGrille = (sitesForGrille: SiteDTO[], site: SiteDTO): SiteDTO[] => {
    let arr = [];
    let isFoundSiteArr = sitesForGrille.filter(item => site && item.id === site.id);

    if (isFoundSiteArr && isFoundSiteArr.length > 0) {
      sitesForGrille.map(item => {
        if (site && item.id === site.id) {
          item = site;
        }
        arr.push(item);
      });
    }
    else {

      sitesForGrille.unshift(site);
      sitesForGrille.map(item => arr.push(item));
    }

    return arr;
  };

  private delete = (siteID: number): Observable<ResponseWrapper<boolean>> => {
    return this.http.delete<ResponseWrapper<boolean>>(this.siteRouteMapService.getDeleteSiteSLink(siteID)).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  /**
   * Suppression du SiteDTO passé en paramètre.
   * @param {SiteDTO} siteDTO
   * @returns {Subscription}
   */
  deleteSite = (siteDTO: SiteDTO): Subscription => {
    return this.delete(siteDTO.id).subscribe(
      data => {
        if(!this.utils.isResponseSupplierError(data)){
          this.announceSiteToDelete(siteDTO);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Site ${siteDTO.libelle} supprimé avec succès`);
        }
      },
    );
  };

  save = (siteDTO: SiteDTO): Observable<ResponseWrapper<SiteDTO>> => {
    return this.httpSvc.post("dolrest/sites/site/saveone", siteDTO);
  };

  announceSiteToDelete = (siteDTO: SiteDTO) => {
    this.siteSubjectToDelete.next(siteDTO);
  };

  announceOpenDialogEdition = (site: SiteDTO) => {
    this.subjectOpenDialog.next(site);
  };

}





