import {ObjectDTO} from "./object-dto";
import {ModeleNutritionnelParamGemrcnDTO} from "./modele-nutritionnel-param-gemrcn-dto";

export class ModeleNutritionnelGroupeGemrcnDTO extends ObjectDTO {

  // choix dirigé=1, choix multiple=2
  choix: number;

  // modele nutritionnel
  modeleNutritionnelId: number;
  modeleNutritionnelLibelle: string;
  modeleNutritionnelCode: string;

  modeleNutritionnelParamGemrcnList: ModeleNutritionnelParamGemrcnDTO[] =[];


}
