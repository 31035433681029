export class StockMouvementStatutDTO {

  id: number;
  idSite: number;
  labelSite: string;
  code: string;
  libelle: string;
  description: string;
  entree: boolean;
  sortie: boolean;
  modifiable: boolean;

}
