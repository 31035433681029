<h5>
  <label class="badge badge-secondary">
    <i class="fa fa-industry yoni-color mg-r-5"></i>
    <yo-viewmode
      [libelle]="uniteDeProduction?.libelle | uppercase"
      [siteId]="uniteDeProduction?.site?.id">
    </yo-viewmode>
  </label>
</h5>


<p-tabMenu [model]="tabMenuUdp" [activeItem]="tabSelectedMenuUdp"></p-tabMenu>
<div>
  <router-outlet></router-outlet>
</div>
