<div class="mg-t-10">
<dx-data-grid
  id="gridContainer"
  [dataSource]="incoherenceList"
  [allowColumnResizing]="true"
  [height]="utils.getWindowAvailableHeight(180)"
  [allowColumnReordering]="true"
  [showBorders]="true">

  <dxi-column dataField="codeErreur" caption="Type d'erreur" cellTemplate="codeErreurCellTemplate" [width]="150" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="detail" caption="Detail"></dxi-column>

  <div *dxTemplate="let cell of 'codeErreurCellTemplate'">
    {{getTypeErreur(cell.row.data)}}
  </div>

  <dxo-export [enabled]="true"></dxo-export>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>
</div>
