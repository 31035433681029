import {Component, OnInit, ViewChild} from '@angular/core';
import {ProduitRouteMapService} from '../../../../core/services/gestionproduits/produit-routemap.service';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../../core/utils/utils.service';
import {AlimDTO} from '../../../../core/dtos/ciqual/alim-dto';
import {CiqualService} from '../../../../core/services/gestionproduits/ciqual.service';
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-ciqual-dialog-edit',
  templateUrl: './ciqual-dialog-edit.component.html',
  styleUrls: ['./ciqual-dialog-edit.component.scss'],
})
export class CiqualDialogEditComponent implements OnInit {

  searchTerm: string;
  displayDialog = false;
  alimList: AlimDTO[] = [];
  @ViewChild('grid') grid: DxDataGridComponent;


  constructor(
    private produitRouteMapService: ProduitRouteMapService,
    private http: HttpClient,
    public utils: UtilsService,
    private ciqualSvc: CiqualService) {
  }


  applyFilter(filterValue: string) {

    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSourcconsole.log("applyFilter : filterValue=" + filterValue);

    if (filterValue.length < 3) {
      this.alimList = [];
    } else {
      this.find(filterValue);
    }
  }

  ngOnInit() {
    this.openSearchDialogSubscription();
  }


  openSearchDialogSubscription = () => {
    this.ciqualSvc.openSearchDialog$.subscribe(response => {
      this.displayDialog = true;
      this.utils.focus('searchTerm',20,100);
    });
  };


  savePropage = () => {

    if (this.grid.selectedRowKeys.length) {
      const selectedAlim = this.grid.instance.getSelectedRowsData()[0];
      let report = {
        propage: true,
        selected: selectedAlim
      };

      this.closeDialog();
      this.ciqualSvc.announceAlimDTO(report);
    }

  };

  disabled = (): boolean => {

    if (this.grid.selectedRowKeys.length && this.grid.selectedRowKeys.length >= 1) {
      return false;
    }

    return true;
  };



  find = (strKeyWords: string) => {
    this.ciqualSvc.findByKeywordsAlimNomFrAnd(strKeyWords).subscribe(data => {
      this.alimList = data;
    });
  };


  closeDialog = () => {
    this.displayDialog = false;
    this.alimList = [];
    this.searchTerm = '';
  };


}



