import {Injectable} from "@angular/core";
import {UtilsService} from "../../utils/utils.service";
import {HttpService} from "../technique/http.service";
import {GenericHandler} from "../generics/generic-handler";
import {Environnement_PlcDTO} from "../../dtos/administration/environnement-plc-dto";
import {FormFieldTextboxSupplier} from "../../suppliers/form-field-textbox-supplier";
import {FormFieldDropdownSupplier} from "../../suppliers/form-field-dropdown-supplier";
import {FormFieldBaseSupplier} from "../../suppliers/form-fieldbase-supplier";
import {FormFieldTextareaSupplier} from "../../suppliers/form-field-textarea-supplier";
import {FormFieldCheckboxSupplier} from "../../suppliers/form-field-checkbox-supplier";
import {DialogMsgSupplier} from "../../suppliers/dialog-msg-supplier";
import {Auth2Service} from "../security/auth2.service";
import {Router} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {GenericDatagridService} from "../generics/generic-datagrid.service";
import {UnitesDeMesuresService} from "../entities/unites-de-mesures.service";
import {Subject} from "rxjs";
import {PointDeLivraisonDTO} from "../../dtos/point-de-livraison-d-t-o";

export const URL_GET_ALL_ENVRIONNEMENT_PLC = 'dolrest/portail/environnement-plc/get-all';
export const URL_GET_ENV_PLC_BY_ID = 'dolrest/portail/environnement-plc/get-by-id';
export const URL_POST_SAVE_ENV_PLC = 'dolrest/portail/environnement-plc/save-env-plc';
export const URL_DELETE_ENV_PLC_BY_ID = 'dolrest/portail/environnement-plc/delete-by-id';
export const URL_GET_CONFIG_PLC = 'dolrest/portail/configuration-plc/get-by-id-plc';


@Injectable({
  providedIn: 'root'
})
export class EnvironnementPlcService extends GenericHandler<Environnement_PlcDTO> {


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService, private udmSvc: UnitesDeMesuresService,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
  }

  private subjectEnvironnemntPlc = new Subject<Environnement_PlcDTO>();
  environnementPlc$ = this.subjectEnvironnemntPlc.asObservable();

  private subjectOpenDialogAddPlc = new Subject<Environnement_PlcDTO>();
  openDialogAddPlc$ = this.subjectOpenDialogAddPlc.asObservable();

  private subjectSelectedPlcList = new Subject<PointDeLivraisonDTO[]>();
  selectedPlcList$ = this.subjectSelectedPlcList.asObservable();


  announceOpenDialogAddPlc = (environnemetnPlc: Environnement_PlcDTO) => {
    this.subjectOpenDialogAddPlc.next(environnemetnPlc);
  };

  announceSelectedPlcList = (plcList: PointDeLivraisonDTO[]) => {
    this.subjectSelectedPlcList.next(plcList);
  };

  announceEnvironnementPlc = (environnemetnPlc :Environnement_PlcDTO) => {
    this.subjectEnvironnemntPlc.next(environnemetnPlc);
  };

  createEmptyDTO = (): Environnement_PlcDTO => {
    const dto = new Environnement_PlcDTO()
    dto.id = 0;
    dto.actif = true;
    dto.pointDeLivraisonList = [];
    return dto;
  };

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: Environnement_PlcDTO): string => '';

  getEntityName = (): string => 'environnementplc';

  getFields = (dto: Environnement_PlcDTO): FormFieldBaseSupplier<any> [] => {
    const isReadonly = !this.canModify(dto);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
      dto.id = 0;
      dto.actif = true;
    }

    let formFieldSite: FormFieldTextboxSupplier;

    if (dto.id > 0) {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      })
    } else {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        required: true,
        options: this.optionsSites
      })
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly: isReadonly
      }),

      new FormFieldTextareaSupplier({
        key: 'descriptif',
        label: 'Description',
        minLength: 0,
        maxLength: 250,
        value: dto.descriptif,
        required: false,
        order: 3,
        readonly: isReadonly
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 7,
        readonly: isReadonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 8
      })
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => '';

  getTotalRecordsLabel = (): string => '';

  /*******************  HTTP RESSOURCE  *********************/

  /**
   *  Récuperer les environnement plc visibles pour l'utilisateur connecté
   */
  getAll = () => this.httpSvc.get(URL_GET_ALL_ENVRIONNEMENT_PLC);

  getById = (idEnvironnementPlc: number) => {
    const params: HttpParams = new HttpParams()
      .set('idEnvironnementPlc', idEnvironnementPlc+'');

    return this.httpSvc.get(URL_GET_ENV_PLC_BY_ID, params);
  };

  saveEnvironnementPlc = (environnementPlc : Environnement_PlcDTO) => {
    const idPlcList = [];
    environnementPlc.pointDeLivraisonList.forEach( item =>{
      idPlcList.push(item.id);
    });
    const params: HttpParams = new HttpParams().set('idPlcList', idPlcList.join(","));
    environnementPlc.pointDeLivraisonList = [];
    return this.httpSvc.post(URL_POST_SAVE_ENV_PLC, environnementPlc, params);
  };

  deleteById = (idEnvironnementPlc : number) => {
    const params: HttpParams = new HttpParams().set('idEnvironnementPlc', idEnvironnementPlc+'');
    return this.httpSvc.delete(URL_DELETE_ENV_PLC_BY_ID, params);
  };
}
