import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {UtilsService} from '../../../core/utils/utils.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {FS_ROUTES} from '../../../core/constants';

@Component({
  selector: 'yo-modele-plat',
  templateUrl: './modele-plat.component.html',
  styleUrls: ['./modele-plat.component.scss']
})
export class ModelePlatComponent implements OnInit, OnDestroy {

  displayDialog = false;
  subActiveSecondaryRoute: Subscription;
  subSupplier: Subscription;

  tabMenu: MenuItem[] = [];
  tabSelectedMenu: MenuItem;

  idModeleConditionnementPlat = null;
  titleHeader = 'Modèle de plat';

  hasIDistri = false;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private itemNavSvc: ItemNavigationService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService) {
  }

  ngOnInit(): void {
    this._initModeleConditionnementPlat();
    this._activeSecondaryRouteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  private _initModeleConditionnementPlat = () => {
    this.subSupplier = this.route.data
      .subscribe((data: any) => {
        if (data.modelePlatSupplier) {
          this.idModeleConditionnementPlat = data.modelePlatSupplier.idModeleConditionnementPlat;
          if (data.modelePlatSupplier.modelePlatConditionnement) {
            this.titleHeader = `Modèle de plat ${data.modelePlatSupplier.modelePlatConditionnement.libelle}`;
          }
          this._initTabMenuUdp();
        }
      });
  };

  private _initHasGestionIDistri = () => {
    this.auth2Svc.hasGestionIDistri$.subscribe((response: boolean) => {
      this.hasIDistri = response;
    });
  };

  private _initTabMenuUdp = () => {
    this.tabMenu = [];

    this.tabMenu.push({
      label: `Fiche identité`,
      routerLink: ['ficheidentite'],
      command: () => this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT, this.idModeleConditionnementPlat, 'ficheidentite'])
    });
    if (this.idModeleConditionnementPlat) {
      this.tabMenu.push({
        label: `Paramétrage`,
        routerLink: ['parametrage'],
        command: () => this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT, this.idModeleConditionnementPlat, 'parametrage'])
      });
      this.tabMenu.push({
        label: `Plats`,
        routerLink: ['plats'],
        command: () => this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT, this.idModeleConditionnementPlat, 'plats'])
      });
    }


  };

  private _activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.tabSelectedMenu = this.itemNavSvc.activeSecondaryRoute(event, this.tabMenu);
    });
  };

}
