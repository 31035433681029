import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {HttpService} from './http.service';


export const URL_GET_ERREUR_LIST = `dolrest/erreur/geterreurlist`;
export const URL_GET_ERREUR_LIST_BY_UDP = `dolrest/erreur/geterreurlistbyunitedeproduction`;


@Injectable()
export class ErreurFonctionnelleService {


  constructor(private httpSvc: HttpService,
              private utils: UtilsService) {
  }

  /**
   * Recuperation de la list des erreurs fcontionelle  liée au un object
   * @param entityName
   * @param idObject
   */
  getErreurList(entityName: string, idObject: number) {

    const params = new HttpParams()
      .set('entityName', entityName + '')
      .set('idObject', idObject + '');

    return this.httpSvc.get(URL_GET_ERREUR_LIST, params);
  }

  getErreurListByUniteDeProduction(idUniteDeProduction : number){

    const params = new HttpParams()
      .set('idUniteDeproduction', idUniteDeProduction + '');

    return this.httpSvc.get(URL_GET_ERREUR_LIST_BY_UDP, params);
  }
}
