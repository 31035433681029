import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {SelectButton} from 'primeng/selectbutton';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'yo-up-conditionnement-root',
  templateUrl: './up-conditionnement.component.html',
  styleUrls: ['./up-conditionnement.component.scss']
})
export class UpConditionnementComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subActiveSecondaryRoute: Subscription;
  subLoadAssociations: Subscription;
  uniteDeProduction: UniteDeProductionDTO;

  readonly itemUdpCV = 'UDP-CV';
  readonly itemUdpMcp = 'UDP-MCP';

  @ViewChild('selectItems') selectItems: SelectButton;

  items: MenuItem[] = [
    {label: 'Conditionnements - Variantes', routerLink: ['conditionnements-variantes'], id: this.itemUdpCV},
    {
      label: 'Modèles de conditionnement plat',
      routerLink: ['modeles-conditionnement-plat'],
      disabled: false,
      id: this.itemUdpMcp
    }
  ];

  selectedItem = this.items[0];

  @ViewChild('selectButton') selectButton: SelectButton;

  constructor(public utils: UtilsService,
              private router: Router,
              private itemNavSvc: ItemNavigationService,
              private routeMapSvc: RoutemapService, private route: ActivatedRoute,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.routeSubscription();
    this.activeSecondaryRouteSubscription();
  }

  routeSubscription = () => {
    this.subRoute = this.route.parent.data.subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
      this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
    });
  };

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {

      this.selectedItem = this.itemNavSvc.activeSecondaryRoute(event, this.items);
      if (this.utils.isNullOrEmpty(this.selectedItem)) {
        this.selectedItem = this.items[0];
        this.goToSelectionUrl(this.uniteDeProduction, this.selectedItem.routerLink);
      }

    });
  };

  changeSelectedItem = (event: any) => {
    this.goToSelectionUrl(this.uniteDeProduction, event.value.routerLink);
  };

  goToSelectionUrl = (udp: UniteDeProductionDTO, link: string[]) => {
    const url = [`gestion-unitesproduction-uniteDeProduction`, udp.id, 'conditionnement', link[0]];
    this.routeMapSvc.goToSecondaryRoute(url)
      .then(() => this.cd.detectChanges())
      .then(() => this.cd.markForCheck())
      .catch(e => console.error(e));
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
    this.utils.unsubscribe(this.subLoadAssociations);
  }


}
