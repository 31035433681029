<div id="versionIconDiv" class="cursor mg-t-15" (mouseenter)="opInfosDatamealOnline.toggle($event)"
     (mouseleave)="opInfosDatamealOnline.toggle($event)">
  <i id="versionIcon" class="{{resetIconClasses ? '' : 'far fa-question-circle mg-r-5'}} {{iconClasses}}"></i>
  <span class="{{labelClasses}}" *ngIf="displayLabel">version</span>
</div>


<!--INFORMATIONS DE VERSION-->
<p-overlayPanel appendTo="body" #opInfosDatamealOnline>
  <ng-template pTemplate>
    <h6 class="genlabel"> &copy; DATAMEAL ONLINE</h6>

    <div class="d-flex flex-column">
      <table>
        <tbody>
        <tr>
          <td>
            <i class="mg-r-5 cursor fas fa-tag" title="version"></i>
          </td>
          <td>
            Version
          </td>
          <td>
            {{dolVersionJson.latestTag}}
          </td>
        </tr>
        <tr>
          <td>
            <i class="mg-r-5 cursor fas fa-code-branch" title="branche"></i>
          </td>
          <td>
           Branche
          </td>
          <td>
            {{dolVersionJson.branch}}
          </td>
        </tr>
        <tr>
          <td>
            <i class="mg-r-5 cursor fas fa-hashtag" title="hash"></i>
          </td>
          <td>
            Hash
          </td>
          <td>
            {{dolVersionJson.shortHash}}
          </td>
        </tr>
        <tr>
          <td>
            <i class="mg-r-5 cursor fas fa-code" title="date des sources"></i>
          </td>
          <td>
           Date des sources
          </td>
          <td>
            {{utils.formatDate(dolVersionJson.dateCommit, 'DD/MM/YYYY-HH:mm:ss', 'DD/MM/YYYY HH:mm:ss')}}
          </td>
        </tr>
        <tr>
          <td>
            <i class="mg-r-5 cursor fas fa-code" title="compilé le"></i>
          </td>
          <td>
           Compilé le
          </td>
          <td>
            {{utils.formatDate(dolVersionJson.dateBuild, 'DD/MM/YYYY-HH:mm:ss', 'DD/MM/YYYY HH:mm:ss')}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </ng-template>
</p-overlayPanel>
