<span class="ql-formats">
  <select class="ql-header">
    <option value="1">Heading</option>
    <option value="2">Subheading</option>
    <option selected>Normal</option>
  </select>
  <select class="ql-font">
    <option selected>Sans Serif</option>
    <option value="serif">Serif</option>
    <option value="monospace">Monospace</option>
  </select>
</span>

<span class="ql-formats">
  <button class="ql-bold" title="Bold"></button>
  <button class="ql-italic" title="Italic"></button>
  <button class="ql-underline" title="Underline"></button>
</span>

<span class="ql-formats">
  <select class="ql-color"></select>
  <select class="ql-background"></select>
</span>


<span class="ql-formats">
  <button class="ql-list" value="ordered" title="Liste ordonnée"></button>
  <button class="ql-list" value="bullet" title="Liste simple"></button>
  <select class="ql-align">
    <option selected title="Aligné à gauche" ></option>
    <option value="center" title="Centré"></option>
    <option value="right" title="Aligné à droite"></option>
    <option value="justify"title="Justifié"></option>
  </select>
</span>

<span class="ql-formats">
  <button class="ql-clean" title="Supprimer les styles"></button>
</span>

