import {PrestationRegroupementModel} from "./prestation-regroupement-model";

export class RegleRegimeModel {

  /**
   * N° de règle
   */
  id: number;

  /**
   * Prestations liées à la règle
   */
  prestations: PrestationRegroupementModel[];

  /**
   * Identifiant Point de livraison client
   */
  plcId: number;

}
