import {ObjectDTO} from "./object-dto";
import {SiteDTO} from "./site-dto";

export class AdresseDTO extends ObjectDTO {

  // adresse
  ville: string;
  codePostal: string;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  telephone: string;
  fax: string;
  email: string;

  entityName: string;
  nom: string;


  constructor(site: SiteDTO, id: number = 0) {
    super();
    this.id = id;
    this.site = site;
  }

  // static fillEmpty() {
  //   return <AdresseDTO>{
  //     ville: '',
  //     codePostal: '',
  //     adresse1: '',
  //     adresse2: '',
  //     adresse3: '',
  //     telephon: '',
  //     fax: '',
  //     email: '',
  //     entityName: '',
  //     nom: ''
  //   }
  // }
}
