<dx-popup
  [showTitle]="true"
  [title]="titleDialog"
  [(visible)]="displayDialog"
  (onHiding)="closeDialog()"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>

  <form [formGroup]="form" novalidate>
    <div class="dialog-line">
      <div class="label" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idSelectedSite"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="true"
          [placeholder]="sitePlaceholder"
          (onSelectionChanged)="onChangeSite($event)"
          [disabled]="!canModify()"
        >
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box placeholder="Libellé" formControlName="libelle" [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Type">Fabriqué ?</div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="fab" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="actif" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Selection du parent">Selection du parent :

      </div>
      <div class="value">
        <strong class="mg-l-5">{{selectedParent ? selectedParent.libelle : ''}}</strong>
        <span class="mg-l-15">
            <yo-button
              (onClick)="removeParent()"
              [disabled]="utils.isNullOrEmpty(selectedParent)"
              label="Enlever">
            </yo-button>
        </span>
      </div>
    </div>
    <div class="mg-t-5">
      <dx-tree-list
        id="parent-grid"
        [dataSource]="parentList"
        [showColumnHeaders]="false"
        [rootValue]="null"
        keyExpr="id"
        [height]="utils.scrollHeightContent(600)"
        (onSelectionChanged)="onChangeParentSelection($event)"
        parentIdExpr="parent.id"
        [showRowLines]="true"
        [showBorders]="true"
        [disabled]="isReadOnly"
        [columnAutoWidth]="true"
        [selectedRowKeys]="selectedRowKeys"
        [focusedRowEnabled]="true"
        [(focusedRowKey)]="selectedRowKeys[0]"
        [expandedRowKeys]="[1]">

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>

        <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
        <dxo-scrolling mode="standard"></dxo-scrolling>
      </dx-tree-list>
    </div>

  </form>


  <!--<div class="d-flex flex-row">
    <div class="mg-r-30">

    </div>

    <div>
      <div class="d-flex justify-content-between mg-b-10">
        <div class="d-flex flex-row">
          <label>Sélection du parent :</label>
          <strong class="mg-l-5">{{selectedParent ? selectedParent.libelle : ''}}</strong>
        </div>

        <span class="mg-l-15">
            <yo-button
              (onClick)="removeParent()"
              [disabled]="utils.isNullOrEmpty(selectedParent)"
              label="Enlever">
            </yo-button>
        </span>
      </div>

      <dx-tree-list
        id="parent-grid"
        [dataSource]="parentList"
        [showColumnHeaders]="false"
        [rootValue]="null"
        keyExpr="id"
        [height]="utils.scrollHeightContent(600)"
        (onSelectionChanged)="onChangeParentSelection($event)"
        parentIdExpr="parent.id"
        [showRowLines]="true"
        [showBorders]="true"
        [disabled]="isReadOnly"
        [columnAutoWidth]="true"
        [selectedRowKeys]="selectedRowKeys"
        [focusedRowEnabled]="true"
        [(focusedRowKey)]="selectedRowKeys[0]"
        [expandedRowKeys]="[1]">

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>

        <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
        <dxo-scrolling mode="standard"></dxo-scrolling>
      </dx-tree-list>

    </div>
  </div>-->
  <div class="d-flex justify-content-end mg-t-15">
    <span class="mg-r-5">
      <yo-button buttonClass="cta-success" [disabled]="isReadOnly" (onClick)="save()" tooltip="Enregistrer la famille produit" iconClass="fas fa-save"></yo-button>
    </span>

    <span class="mg-r-5">
      <yo-button class="mg-r-5" buttonClass="cta-delete" (onClick)="closeDialog()" tooltip="Annuler" iconClass="fas fa-times"></yo-button>
    </span>
  </div>
</dx-popup>
