import {combineLatest, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {UtilisateurDTO} from '../core/dtos/utilisateur-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UtilisateurRoutemapService} from '../core/services/entities/utilisateur-routemap.service';
import {EnvironnementDTO} from '../core/dtos/environnement-dto';
import {RoleService} from '../core/role.service';
import {EnvironnementService} from '../core/services/entities/gestionenvironnements/environnement.service';
import {GenericDatagridService} from '../core/services/generics/generic-datagrid.service';
import {ProfilsService} from '../core/services/entities/profils.service';
import {ProfilDTO} from '../core/dtos/profil-dto';
import {UtilisateurService} from "../core/utilisateur.service";
import {EnvironnementPlcService} from "../core/services/portail/environnement-plc.service";
import {Environnement_PlcDTO} from "../core/dtos/administration/environnement-plc-dto";
import {ResponseWrapper} from "../core/suppliers/wrappers/response-wrapper";

@Injectable()
export class UtilisateurResolverService implements Resolve<UtilisateurSupplier> {
  // Observable site
  utilisateurAnnounceSource = new Subject<UtilisateurDTO>();

  constructor(private http: HttpClient,
              private roleService: RoleService,
              private utilisateurSvc : UtilisateurService,
              private environnementService: EnvironnementService,
              private environnementPlcSvc: EnvironnementPlcService,
              private gds: GenericDatagridService,
              private profilSvc: ProfilsService,
              private utilisateurRoutemapService: UtilisateurRoutemapService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UtilisateurSupplier> | Promise<UtilisateurSupplier> | UtilisateurSupplier {

    let utilisateurSupplier = new UtilisateurSupplier();
    let idUtilisateur: number = +route.paramMap.get('idUtilisateur');

    // const listeAllRolesActifs$ = this.roleService.findAllRolesActifs();
    const listAllEnvironnements$ = this.environnementService.findAll();
    const profils$ = this.utilisateurSvc.getProfilList();
    const environnementPlcList$ = this.environnementPlcSvc.getAll();

    // Utilisateur en modification
    if (idUtilisateur > 0) {

      const utilisateurDTO$ = this.http.get<ResponseWrapper<UtilisateurDTO>>(this.utilisateurRoutemapService.getUtilisateurFicheIdentiteLink(idUtilisateur));

      const all$ = combineLatest([utilisateurDTO$, listAllEnvironnements$, profils$, environnementPlcList$]);
      return all$.toPromise().then(data => {

        utilisateurSupplier.utilisateurDTO = data[0].one;
        utilisateurSupplier.arrayAllEnvironnementDTO = data[1];
        utilisateurSupplier.arrayAllProfilDTO = data[2].resultList;
        utilisateurSupplier.environnementPlcList = data[3].resultList;

        return utilisateurSupplier;

      });
      // Utilisateur en création
    } else {

      const all$ = combineLatest( [listAllEnvironnements$, profils$, environnementPlcList$]);
      return all$.toPromise().then(data => {

        utilisateurSupplier.arrayAllEnvironnementDTO = data[0];
        utilisateurSupplier.arrayAllProfilDTO = data[1].resultList;
        utilisateurSupplier.environnementPlcList = data[2].resultList;

        return utilisateurSupplier;

      });
    }
  }


}

export class UtilisateurSupplier {

  /**
   * L'utilisateur.
   */
  utilisateurDTO: UtilisateurDTO;

  /**
   * Tableau contenant TOUS les environnements de la base de données.
   */
  arrayAllEnvironnementDTO: EnvironnementDTO[]=[];

  /**
   * Tableau contenant TOUS les profils de la base de données
   */
  arrayAllProfilDTO : ProfilDTO[]=[];

  environnementPlcList : Environnement_PlcDTO[]=[];

}
