import {ObjectDTO} from "./object-dto";
import {AdresseDTO} from "./adresse-dto";
import {ContactDTO} from "./contact-dto";

export class ClientDTO extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  guid: string;
  adresse: AdresseDTO;
  contact1: ContactDTO;
  contact2: ContactDTO;


  constructor() {
     super();
  }
}
