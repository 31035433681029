import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PointDeLivraisonDTO} from "../../../../../core/dtos/point-de-livraison-d-t-o";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {EnvironnementPlcService} from "../../../../../core/services/portail/environnement-plc.service";
import {PointDeLivraisonService} from "../../../../../core/services/entities/point-de-livraison.service";
import {Subscription} from "rxjs/index";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {DxDataGridComponent} from "devextreme-angular";
import {Environnement_PlcDTO} from "../../../../../core/dtos/administration/environnement-plc-dto";

@Component({
  selector: 'yo-dialog-add-plc',
  templateUrl: './dialog-add-plc.component.html',
  styleUrls: ['./dialog-add-plc.component.scss']
})
export class DialogAddPlcComponent implements OnInit, OnDestroy {

  @ViewChild('gridPlc') grid: DxDataGridComponent;

  selectedRows: number[] = [];

  environnementPlc: Environnement_PlcDTO;

  displayDialog: boolean
  plcList: PointDeLivraisonDTO[] = [];
  selectedPlcList: PointDeLivraisonDTO[] = [];

  subOpenDialogAddPlc :Subscription;

  constructor(public utils: UtilsService,
              private environnementPlcSvc: EnvironnementPlcService,
              private plcSvc: PointDeLivraisonService) {
  }

  ngOnInit() {
    this.subscriptionOpenDialogAddPlc();
    this.loadPlcList();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialogAddPlc);
  }

  subscriptionOpenDialogAddPlc = () => {
    this.subOpenDialogAddPlc = this.environnementPlcSvc.openDialogAddPlc$.subscribe( data =>{
      this.displayDialog = true;
      this.selectedPlcList = data.pointDeLivraisonList;
      data.pointDeLivraisonList.forEach( item =>{
        this.selectedRows.push(item.id);
      });
    });
  };

  loadPlcList = () => {
    this.plcSvc.getPlcList().subscribe( (data: ResponseWrapper<PointDeLivraisonDTO>) =>{
      this.plcList = data.resultList;
    });
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.selectedPlcList = [];
    this.grid.instance.clearSelection();
  };

  addPlc = () => {
    this.environnementPlcSvc.announceSelectedPlcList(this.selectedPlcList);
    this.closeDialog();
  };

  onChangeSelection = (event: any) => {
    this.selectedPlcList = event.selectedRowsData;
  };

}
