import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {CiqualSupplier} from './ciqual-resolver-service.service';
import {CiqualService} from '../../../core/services/gestionproduits/ciqual.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {HttpErrorResponse} from '@angular/common/http';
import {CompoDTO} from '../../../core/dtos/ciqual/compo-dto';
import {ProduitApportNutritionnelDTO} from '../../../core/dtos/produit-apportnutritionnel-dto';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {ApportNutritionnelDTO} from '../../../core/dtos/apport-nutritionnel-dto';
import {ObjectDTO} from '../../../core/dtos/object-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ProduitsService, ProduitSupplier} from '../../../core/services/entities/produits.service';
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-ciqual',
  templateUrl: './ciqual.component.html',
  styleUrls: ['./ciqual.component.scss']
})
export class CiqualComponent implements OnInit, OnDestroy {

  subProduitDeclinaisons: Subscription;
  subSaveCiqualData: Subscription;
  yoProduit: ProduitDTO;

  /**
   * Les ProduitDeclinaisonDTO du ProduitDTO yoProduit
   * @type {any[]}
   */
  listProduitDeclinesDTO: ProduitDeclinaisonDTO[] = [];

  private allApportsNutritionnels: ApportNutritionnelDTO[];
  selectedApportsNutritionnels: ApportNutritionnelDTO[];
  subCiqual: Subscription;

  subAlimDTO: Subscription;

  selectedProduitDeclinaisonDTO: ProduitDeclinaisonDTO[];

  selectedSiteDTO: SiteDTO;
  expandConstituants: boolean = false;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-ciqual';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService,
              private ciqualService: CiqualService,
              private produitsService: ProduitsService,
              private toastSvc: ToastService
  ) {
  }


  ngOnInit() {
    this.setExpandConstituants(false);

    this.subCiqual = this.route.data.subscribe((data: { ciqualSupplier: CiqualSupplier }) => {
      this.listProduitDeclinesDTO = data.ciqualSupplier.produitDeclinaisonDTOList;
      this.listProduitDeclinesDTO = this.produitsService.sortListeProduitDeclinesDTO(this.listProduitDeclinesDTO);

      this.allApportsNutritionnels = data.ciqualSupplier.allApportsNutritionnels;
      this.allApportsNutritionnels = this.sortApportNutritionnelDTOArray(this.allApportsNutritionnels);

      this.selectedApportsNutritionnels = this.allApportsNutritionnels;

      this.creationProduitApportNutritionnelData(this.listProduitDeclinesDTO);

    });
    this.subProduitDeclinaisons = this.route.parent.data.subscribe((data: { produitSupplier: ProduitSupplier }) => {
      this.utils.setTitle('Ciqual', data.produitSupplier.produitDTO.libelle);
      this.yoProduit = data.produitSupplier.produitDTO;
    });

    this.subAlimDTO = this.ciqualService.subjectAlimDTO$.subscribe(report => {

      let alimDTO = report.selected;
      let propage = report.propage;
      this.ciqualService.findCompoListByAlimId(alimDTO.id).subscribe(compoDTOList => {
        if (propage) {
          this.miseAjourProduitApportNutritionnelData(this.listProduitDeclinesDTO, compoDTOList);
        } else {
          this.miseAjourProduitApportNutritionnelData(this.selectedProduitDeclinaisonDTO, compoDTOList);
        }
      });
    });

    this.ciqualService.subjectFindCompByAlimID$.subscribe(compDTOList => {
    });

  }


  /**
   * Recherche d'un {@link ApportNutritionnelDTO} connaissant son champ <b>libelle</b> au sein d'un tableau d'{@link ApportNutritionnelDTO}s.
   * @param {ApportNutritionnelDTO[]} apportNutritionnelDTOList le tableau d'{@link ApportNutritionnelDTO}s au sein
   * duquel s'effectue la recherche.
   * @param {string} libelle le libellé recherché.
   * @returns {ApportNutritionnelDTO} l'{@link ApportNutritionnelDTO} correspondant au libellé recherché ou <b>null</b>.
   */
  findApportNutritionnelByLibelle = (apportNutritionnelDTOList: ApportNutritionnelDTO[], libelle: string): ApportNutritionnelDTO => {
    let response = this.ciqualService.findApportNutritionnelByLibelle(apportNutritionnelDTOList, libelle);
    return response;
  };

  miseAjourProduitApportNutritionnelData = (listProduitDeclinesDTO: ProduitDeclinaisonDTO[], compoDTOList: CompoDTO[]) => {
    listProduitDeclinesDTO.forEach(produitDeclinaisonDTO => {
      produitDeclinaisonDTO.produitApportNutritionnelList = [];
      this.selectedApportsNutritionnels = this.allApportsNutritionnels;


      this.selectedApportsNutritionnels.forEach(apportNutritionnnel => {
        let produitApportNutritionnelDTO: ProduitApportNutritionnelDTO = null;
        compoDTOList.forEach(compoDTO => {
          // let eq = compoDTO.theConst.id == constDTO.id;
          let eq = compoDTO.theConst.const_nom_fr == apportNutritionnnel.libelle;
          if (eq) {
            // Création du ProduitApportNutritionnelDTO
            produitApportNutritionnelDTO = new ProduitApportNutritionnelDTO();
            produitApportNutritionnelDTO.id = 0;
            produitApportNutritionnelDTO.idProduitDecline = produitDeclinaisonDTO.id;
            produitApportNutritionnelDTO.teneur = compoDTO.teneur;
            // produitApportNutritionnelDTO.theConst = constDTO;

            let apportNutritionnelDTO: ApportNutritionnelDTO = this.findApportNutritionnelByLibelle(this.selectedApportsNutritionnels, apportNutritionnnel.libelle);
            produitApportNutritionnelDTO.apportNutritionnel = apportNutritionnelDTO;
            produitApportNutritionnelDTO.idApportNutritionnel = apportNutritionnelDTO.id;

            // Ajout du ProduitApportNutritionnelDTO à la liste des ProduitApportNutritionnelDTO
            produitDeclinaisonDTO.produitApportNutritionnelList.push(produitApportNutritionnelDTO)
          }
        });

      });
      this.selectedApportsNutritionnels = this.elimineTeneurNulleCompDTO(this.allApportsNutritionnels, compoDTOList);
    });

  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/denrée`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Apports nutritionnels d'un <b>produit/denrée</b>`;
    p1.lines = [
      `Ils sont liés à la <b>déclinaison</b> et sont par conséquent génériques.`,
      `Ils peuvent être saisis manuellement par constituant ou bien en utilisant l’import de données de la table CIQUAL comme modèle de référence sur la composition nutritionnelle des aliments.`,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };

    return dms;
  };


  /**
   * Indique si l'apport nutritionnel passé en paramètre doit être affiché.
   * @param {CompoDTO} compoDTO
   * @param {ApportNutritionnelDTO} apportNutritionnelDTO
   * @returns {boolean}
   */
  displayCompo = (compoDTO: CompoDTO, apportNutritionnelDTO: ApportNutritionnelDTO): boolean => {
    // let response = this.expandConstituants || (!this.utils.isNullOrEmpty(compoDTO.teneur) && (compoDTO.teneur > 0) || apportNutritionnel.obligatoire);
    let response = this.expandConstituants || apportNutritionnelDTO.obligatoire;
    return response;
  };

  /**
   * Indique si l'apport nutritionnel passé en paramètre doit être affiché.
   * @param {ProduitApportNutritionnelDTO} produitApportNutritionnelDTO
   * @param {ApportNutritionnelDTO} apportNutritionnelDTO
   * @returns {boolean}
   */
  displayProduit = (produitApportNutritionnelDTO: ProduitApportNutritionnelDTO, apportNutritionnelDTO: ApportNutritionnelDTO): boolean => {
    let response = this.expandConstituants || apportNutritionnelDTO.obligatoire;
    return response;
  };

  elimineTeneurNulleCompDTO = (apportNutritionnelDTOListe: ApportNutritionnelDTO[], compoDTOList: CompoDTO[]): ApportNutritionnelDTO[] => {
    let cleanedArray: ApportNutritionnelDTO[] = [];
    apportNutritionnelDTOListe.forEach(apportNutritionnelDTO => {
      let trouve: boolean = false;
      compoDTOList.forEach(compoDTO => {
        let eq = compoDTO.theConst.const_nom_fr == apportNutritionnelDTO.libelle;
        if (eq) {
          if (this.displayCompo(compoDTO, apportNutritionnelDTO)) {
            trouve = true;
          }
        }

      });
      if (trouve) {
        cleanedArray.push(apportNutritionnelDTO);
      }

    });
    return cleanedArray;
  };

  elimineTeneurNulleProduitApportNutritionnelDTO = (apportNutritionnelDTOListe: ApportNutritionnelDTO[], produitApportNutritionnelList: ProduitApportNutritionnelDTO[]): ApportNutritionnelDTO[] => {
    let cleanedArray: ApportNutritionnelDTO[] = [];
    apportNutritionnelDTOListe.forEach(apportNutritionnelDTO => {
      let trouve: boolean = false;
      produitApportNutritionnelList.forEach(produitApportNutritionnelDTO => {
        let eq = produitApportNutritionnelDTO.apportNutritionnel.libelle == apportNutritionnelDTO.libelle;
        if (eq) {
          if (this.displayProduit(produitApportNutritionnelDTO, apportNutritionnelDTO)) {
            trouve = true;
          }
        }

      });
      if (trouve) {
        cleanedArray.push(apportNutritionnelDTO);
      }

    });
    return cleanedArray;
  };


  creationProduitApportNutritionnelData = (listProduitDeclinesDTO: ProduitDeclinaisonDTO[]) => {
    this.selectedApportsNutritionnels = this.allApportsNutritionnels;


    listProduitDeclinesDTO.forEach(produitDeclinaisonDTO => {

      if (this.utils.isNullOrEmpty(produitDeclinaisonDTO.produitApportNutritionnelList) || produitDeclinaisonDTO.produitApportNutritionnelList.length == 0) {


        this.setExpandConstituants(true);

        produitDeclinaisonDTO.produitApportNutritionnelList = [];

        this.allApportsNutritionnels.forEach(apportNutritionnel => {
          let produitApportNutritionnelDTO: ProduitApportNutritionnelDTO = null;

          // Création du ProduitApportNutritionnelDTO
          produitApportNutritionnelDTO = new ProduitApportNutritionnelDTO();
          produitApportNutritionnelDTO.id = 0;
          produitApportNutritionnelDTO.idProduitDecline = produitDeclinaisonDTO.id;

          produitApportNutritionnelDTO.teneur = null;

          let apportNutritionnelDTO: ApportNutritionnelDTO = this.findApportNutritionnelByLibelle(this.allApportsNutritionnels, apportNutritionnel.libelle);
          produitApportNutritionnelDTO.apportNutritionnel = apportNutritionnelDTO;
          produitApportNutritionnelDTO.idApportNutritionnel = apportNutritionnelDTO.id;

          // Ajout du ProduitApportNutritionnelDTO à la liste des ProduitApportNutritionnelDTO
          produitDeclinaisonDTO.produitApportNutritionnelList.push(produitApportNutritionnelDTO);


        });
      } else {
        this.selectedApportsNutritionnels = this.elimineTeneurNulleProduitApportNutritionnelDTO(this.allApportsNutritionnels, produitDeclinaisonDTO.produitApportNutritionnelList);
      }
    });
  };

  save = () => {
    console.log("Sauvegarde des informations ciqual component");

    this.subSaveCiqualData = this.ciqualService.save(this.listProduitDeclinesDTO).subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Erreur serveur`);
        } else {
          this.listProduitDeclinesDTO = data;
          this.listProduitDeclinesDTO = this.produitsService.sortListeProduitDeclinesDTO(this.listProduitDeclinesDTO);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement effectué avec succès`);
        }
      },
      error => {
      },

      () => {
      });

  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subProduitDeclinaisons);
    this.utils.unsubscribe(this.subCiqual);
    this.utils.unsubscribe(this.subSaveCiqualData);
    this.utils.unsubscribe(this.subAlimDTO);

  }

  getTeneur(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnelDTO: ApportNutritionnelDTO): number {
    let response = null;
    for (let produitApportNutritionnel of produitDeclinaisonDTO.produitApportNutritionnelList) {
      if (produitApportNutritionnel.apportNutritionnel.libelle === apportNutritionnelDTO.libelle) {
        response = produitApportNutritionnel.teneur;
        break
      }
    }
    return response;
  }


  tooltipQuantite(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnel: ApportNutritionnelDTO): string {
    let quantite = this.getQuantite(produitDeclinaisonDTO, apportNutritionnel);
    if (quantite == null) {
      return "entrez le nombre de " + this.getUnite(apportNutritionnel) + " par " + produitDeclinaisonDTO.uniteDeMesure.libelle + " de " + produitDeclinaisonDTO.ratioUniteTechnique + " kg";
    } else {
      let response = "quantité de " + this.getLibelleApportNutritionnel(apportNutritionnel) + " : " + quantite + " " + this.getUnite(apportNutritionnel) + " par " + produitDeclinaisonDTO.uniteDeMesure.libelle + " de " + produitDeclinaisonDTO.ratioUniteTechnique + " kg";
      return response;
    }
  }

  tooltipTeneur(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnel: ApportNutritionnelDTO): string {
    let teneur = this.getTeneur(produitDeclinaisonDTO, apportNutritionnel);
    if (teneur == null) {
      return "entrez le nombre de " + this.getUnite(apportNutritionnel) + " pour 100 g";
    } else {
      let response = "teneur en " + this.getLibelleApportNutritionnel(apportNutritionnel) + " : " + teneur + " " + this.getUnite(apportNutritionnel) + " pour 100 g";
      return response;
    }
  }

  getHeaderParUnite(produitDeclinaisonDTO: ProduitDeclinaisonDTO): string {
    let header = " par " + produitDeclinaisonDTO.uniteDeMesure.libelle + " de " + produitDeclinaisonDTO.ratioUniteTechnique + " kg";
    return header;
  }

  setTeneur(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnelDTO: ApportNutritionnelDTO, event: any): void {
    let trouve = false;
    for (let produitApportNutritionnel of produitDeclinaisonDTO.produitApportNutritionnelList) {
      if (produitApportNutritionnel.apportNutritionnel.libelle === apportNutritionnelDTO.libelle) {
        produitApportNutritionnel.teneur = event;
        trouve = true;
        break;
      }
    }
  }

  getQuantite(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnelDTO: ApportNutritionnelDTO): number {
    let teneur = this.getTeneur(produitDeclinaisonDTO, apportNutritionnelDTO);


    if (this.utils.isNullOrEmpty(teneur)) {
      return null
    } else {
      // return teneur;

      let quantite = 0;
      // La quantité est le produit du ratio technique(valeur pour 1 kg) multiplié par la teneur (pour 100 g) multiplié par 10 (parce que 1kg = 10 fois 100 g.
      quantite = produitDeclinaisonDTO.ratioUniteTechnique * teneur * 10;
      quantite = parseFloat(quantite.toPrecision(9));
      return quantite;
    }

  }


  setQuantite(produitDeclinaisonDTO: ProduitDeclinaisonDTO, apportNutritionnelDTO: ApportNutritionnelDTO, event: any): void {
    let quantite: number = event;

    console.log("setQuantite valeur = " + event);
    if (event == null) {
      let teneur = null;
      this.setTeneur(produitDeclinaisonDTO, apportNutritionnelDTO, teneur);

    } else {
      let teneur = quantite / (10 * produitDeclinaisonDTO.ratioUniteTechnique);
      console.log("teneur avant : " + teneur);


      teneur = parseFloat(teneur.toPrecision(9));
      console.log("teneur après : " + teneur);

      this.setTeneur(produitDeclinaisonDTO, apportNutritionnelDTO, teneur);

    }
  }


  getUnite(apportNutritionnelDTO: ApportNutritionnelDTO): string {
    let libelleConst: string = apportNutritionnelDTO.libelle;
    let indexSlash: number = libelleConst.lastIndexOf("/");
    let indexParenthese: number = libelleConst.lastIndexOf("(");
    let unite = libelleConst.substring(indexParenthese + 1, indexSlash);
    return unite;
  }



  openDialogNoParam() {

    this.ciqualService.announceOpenSearchDialog();


    // this.selectedSiteDTO = null;
    // this.selectedProduitDeclinaisonDTO = [];
    // let dialogRef = this.matDialog.open(CiqualDialogEditComponent, {
    //   hasBackdrop: false,
    //   width: '800px'
    // });
    // dialogRef
    //   .afterClosed()
    //   .subscribe(report => {
    //       if (report === "") {
    //
    //       } else {
    //         this.ciqualService.announceAlimDTO(report);
    //       }
    //     }
    //   );
  }

  reload(): void {
    console.log("reload");
    this.creationProduitApportNutritionnelData(this.listProduitDeclinesDTO);
  }


  canModify(element: ObjectDTO): boolean {
    let response = this.gds.canModify(element);
    // console.log(" canModify response  = " + response);
    return response;
  }

  /** Exemple :
   * entrée : Beta-Carotène (µg/100g)
   * sortie : Beta-Carotène
   * On supprime les parenthèses et ce qu'il y a dedans.
   *
   *
   * @param apportNutritionnelDTO
   */
  getLibelleApportNutritionnel(apportNutritionnelDTO: ApportNutritionnelDTO) {
    let libelle = apportNutritionnelDTO.libelle;
    let indexParenthese: number = libelle.lastIndexOf("(");
    libelle = libelle.substring(0, indexParenthese);
    return libelle;
  }

  /**
   * Renvoie, sous forme de string la marge gauche (en px) qui doit être appliquée. Celle-ci dépend du fait que l'apport nutritionnel
   * possède un parent ou non :
   * - possède un parent : margin vaut "0px".
   * - ne possède pas de parent : margin vaut : "20px".
   * @param {ApportNutritionnelDTO} apportNutritionnelDTO
   * @returns {string}
   */
  getMarginLeftStyleApportNutritionnel(apportNutritionnelDTO: ApportNutritionnelDTO) {
    let margin = "0px";
    if (apportNutritionnelDTO.parent != null) {
      margin = "20px";
    }
    return margin;
  }

  sortApportNutritionnelDTOArray(allApportsNutritionnels: ApportNutritionnelDTO[]): ApportNutritionnelDTO[] {
    let sortedArray = [];
    allApportsNutritionnels.forEach(apportNutritionnelDTOParent => {
      if (apportNutritionnelDTOParent.parent == null) {
        sortedArray.push(apportNutritionnelDTOParent);
        allApportsNutritionnels.forEach(apportNutritionnelDTOChild => {
          if ((apportNutritionnelDTOChild.parent != null) && (apportNutritionnelDTOChild.parent.id == apportNutritionnelDTOParent.id)) {
            sortedArray.push(apportNutritionnelDTOChild);
          }
        });
      }
    });


    return sortedArray;

  }

  notExpandConstituants(): void {
    this.expandConstituants = !this.expandConstituants;
    this.creationProduitApportNutritionnelData(this.listProduitDeclinesDTO);
  }


  onChangeToggle($event) {
    this.notExpandConstituants();
  }

  getExpandConstituants(): boolean {
    return this.expandConstituants;
  }

  setExpandConstituants(expandConstituants: boolean): void {
    this.expandConstituants = expandConstituants;
  }

  getToggleTooltip(): string {
    if (this.expandConstituants) {
      return "Replier"
    } else {
      return "Déplier"
    }
  }
}
