import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {ProduitDeclinaisonMarche} from '../../models/gestion-produits/marches/produit-declinaison-marche';
import {BehaviorSubject} from 'rxjs';

export const URL_GET_PRODUITDECLINAISON_MARCHE_LIST=`dolrest/gestionproduits/produitdeclinaison-marche-list`;
export const URL_GET_MARCHE_INDICATEURS=`dolrest/gestionproduits/marche-indicateurs`;

@Injectable({
  providedIn: 'root'
})
export class MarcheProduitService {

  private subjectMarcheEnCours= new BehaviorSubject(undefined);
  marcheEnCours$ = this.subjectMarcheEnCours.asObservable();

  constructor(private http:HttpClient, private utils:UtilsService) { }

  getProduitDeclinaisonMarcheList = (idProduit:number, idUniteDeProduction:number) => this.http.get(URL_GET_PRODUITDECLINAISON_MARCHE_LIST, {
    params: new HttpParams()
      .set('idProduit', idProduit + '')
      .set('idUniteDeProduction', idUniteDeProduction + '')
  }).pipe(
    catchError(error => this.utils.handleError(error))
  );

  getMarcheIndicateurs = (idProduitDeclinaison:number, idUniteDeProduction:number, idMarche:number) => this.http.get(URL_GET_MARCHE_INDICATEURS, {
    params: new HttpParams()
      .set('idProduitDeclinaison', idProduitDeclinaison + '')
      .set('idUniteDeProduction', idUniteDeProduction + '')
      .set('idMarche', idMarche + '')
  }).pipe(
    catchError(error => this.utils.handleError(error))
  );

  announceMarcheEnCours = (produitDeclinaisonMarcheSelected: ProduitDeclinaisonMarche) => {
    this.subjectMarcheEnCours.next(produitDeclinaisonMarcheSelected);
  };
}
