<div class="inner-warnings">
  <dx-tab-panel height="100%">
    <dxi-item title="Points de livraisons non configurés" icon="user" *ngIf="warnings?.pointsLivraisonsNotConfigured">
      <dx-data-grid
        [dataSource]="warnings?.pointsLivraisonsNotConfigured"
        keyExpr="id"
        class="data-grid"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [height]="'100%'"
        columnResizingMode="widget">
        <dxi-column dataField="libelle" caption="Libellé" allowFiltering="true"></dxi-column>
        <dxi-column dataField="code" caption="Code" allowFiltering="true"></dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} point(s) de livraison client(s)">
        </dxo-pager>
      </dx-data-grid>
    </dxi-item>
    <dxi-item title="Jours de consommation non configurés" icon="event" *ngIf="warnings?.consumptionsDaysNotConfiguredByPointLivraison">
      <dx-data-grid
        [dataSource]="warnings?.consumptionsDaysNotConfiguredByPointLivraison"
        keyExpr="pointLivraisonClient.id"
        class="data-grid"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [height]="'100%'"
        columnResizingMode="widget">
        <dxi-column dataField="pointLivraisonClient.tourneeParent" caption="Tournée" allowFiltering="true"></dxi-column>
        <dxi-column dataField="pointLivraisonClient.ordre" caption="Ordre" allowSorting="true"></dxi-column>
        <dxi-column dataField="pointLivraisonClient.libelle" caption="Point de livraison client" allowFiltering="true"></dxi-column>
        <dxi-column dataField="consumptionDays" caption="Jours de consommation" allowFiltering="true"></dxi-column>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} point(s) de livraison client(s)">
        </dxo-pager>
      </dx-data-grid>
    </dxi-item>
    <dxi-item title="Repas non configurés" icon="coffee" *ngIf="warnings?.consistencyRepas">
      <dx-data-grid
        [dataSource]="warnings?.consistencyRepas"
        class="data-grid"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [height]="'100%'"
        columnResizingMode="widget">
        <dxi-column dataField="label" caption="Point de livraison client" allowFiltering="false" allowSorting="false" cellTemplate="templateCellPlc"></dxi-column>
        <div *dxTemplate="let cell of 'templateCellPlc'">
          <h6>{{ cell.row.data.label }}</h6>
          <dx-tab-panel height="100%">
            <dxi-item [disabled]="!cell.row.data.repasByConsumptionDay[day] || !cell.row.data.repasByConsumptionDay[day].length" [title]="day" icon="event" *ngFor="let day of ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']">
              <dx-data-grid
                [dataSource]="cell.row.data.repasByConsumptionDay[day] || []"
                class="data-grid"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                [showRowLines]="true"
                [showBorders]="true"
                [height]="'100%'"
                columnResizingMode="widget">
                <dxi-column dataField="label" caption="Repas non configuré"></dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dx-tab-panel>
        </div>
        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} point(s) de livraison client(s)">
        </dxo-pager>
      </dx-data-grid>
    </dxi-item>
    <dxi-item title="Prestations non configurées" icon="runner" *ngIf="warnings?.consistencyPrestations">
      <dx-data-grid
        [dataSource]="warnings?.consistencyPrestations"
        class="data-grid"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [height]="'100%'"
        columnResizingMode="widget">
        <dxi-column dataField="label" caption="Point de livraison client" allowFiltering="false" allowSorting="false" cellTemplate="templateCellPlc"></dxi-column>
        <div *dxTemplate="let cell of 'templateCellPlc'">
          <h6>{{ cell.row.data.label }}</h6>
          <dx-tab-panel height="100%">
            <dxi-item [disabled]="!cell.row.data.repasByConsumptionDay[day] || !cell.row.data.repasByConsumptionDay[day].length || disableDayPrestationNotConfigured(cell.row.data.repasByConsumptionDay[day])" [title]="day" icon="event" *ngFor="let day of ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']">
              <dx-data-grid
                [dataSource]="cell.row.data.repasByConsumptionDay[day] || []"
                class="data-grid"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                [showRowLines]="true"
                [showBorders]="true"
                [height]="'100%'"
                columnResizingMode="widget">
                <dxi-column dataField="label" caption="Repas"></dxi-column>
                <dxi-column dataField="labelsPrestations" caption="Prestations"></dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dx-tab-panel>
        </div>
        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} point(s) de livraison client(s)">
        </dxo-pager>
      </dx-data-grid>
    </dxi-item>
  </dx-tab-panel>
</div>
