<div class="card-size">

  <div class="row">
    <div class="col-md-6">
      <div class="row ">
        <div class="col-md-12">
          <!-- FORMULAIRE POINT DE LIVRAISON -->
          <div class="card">
            <div class="card-header">
              <h3 class="first">INFOS PRINCIPALES</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="formPointDeLivraison">
                <ng-container *ngFor="let field of fieldsPointDeLivraison">
                  <yo-dynamic-form-field-bs [form]="formPointDeLivraison" [field]="field"
                                            [fields]="fieldsPointDeLivraison"></yo-dynamic-form-field-bs>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-t-10">
        <div class="col-md-12">
          <!--              FORMULAIRE ADRESSE-->
          <div class="card">
            <div class="card-header">
              <h3 class="first">ADRESSE</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="formAdressePdl">
                <ng-container *ngFor="let field of fieldsAdressePdl">
                  <yo-dynamic-form-field-bs [form]="formAdressePdl" [field]="field"
                                            [fields]="fieldsAdressePdl"></yo-dynamic-form-field-bs>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>


    </div>


    <div class="col-md-6">

      <div class="row ">
        <div class="col-md-12">
          <!--              FORMULAIRE CONTACT 1-->
          <div class="card">
            <div class="card-header">
              <h3 class="first">Contact 1</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="formContact1">
                <ng-container *ngFor="let field of fieldsContact1">
                  <yo-dynamic-form-field-bs [form]="formContact1" [field]="field"
                                            [fields]="fieldsContact1"></yo-dynamic-form-field-bs>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-t-10">
        <div class="col-md-12">
          <!--              FORMULAIRE CONTACT 2-->
          <div class="card">
            <div class="card-header">
              <h3 class="first">Contact 2</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="formContact1">
                <ng-container *ngFor="let field of fieldsContact2">
                  <yo-dynamic-form-field-bs [form]="formContact2" [field]="field"
                                            [fields]="fieldsContact2"></yo-dynamic-form-field-bs>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end mg-t-15">
        <span class="mg-r-5">
            <yo-button
                    tooltip="Enregistrer"
                    type="submit"
                    iconClass="fas fa-save"
                    buttonClass="cta-success"
                    (onClick)="save()"
                    [disabled]="!gds.canModify(client)"></yo-button>
        </span>
</div>



