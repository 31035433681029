import {MTache} from './m-tache';
import {ObjectDTO} from '../../dtos/object-dto';

export class MEquipe extends ObjectDTO {

  id: number;

  libelle: string;

  tachesList: MTache[];

}
