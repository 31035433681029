import {ObjectDTO} from "../object-dto";
import {TypeUtilisateurPmsDto} from "./type-utilisateur-pms-dto";
import {UtilisateurDTO} from "../utilisateur-dto";

export class SignatureUtilisateurPmsDto extends ObjectDTO {
  nom: string;
  prenom: string;
  image: File;
  typeUtilisateurPmsList: TypeUtilisateurPmsDto[];
  utilisateur: UtilisateurDTO;
  warningsEnvironnementsPms: string[];
}
