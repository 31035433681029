import {Component, OnDestroy, OnInit} from '@angular/core';
import {StocksService} from '../../../../../core/services/gestion-stock/stocks.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {catchError} from 'rxjs/operators';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";
import {ModelViewMatierePremiere} from "../../model-view-matiere-premiere";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {UnitesDeProductionService} from "../../../../../core/services/entities/unites-de-production.service";
import {UniteDeProduction__ZoneDeStockageDTO} from "../../../../../core/dtos/unite-de-production__zone-stockage-dto";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {StockDTO} from "../../../../../core/dtos/stock-dto";

@Component({
  selector: 'yo-dialog-modification-lots',
  templateUrl: './dialog-modification-lots.component.html',
  styleUrls: ['./dialog-modification-lots.component.scss']
})
export class DialogModificationLotsComponent implements OnInit, OnDestroy {

  subAction: Subscription;
  matierePremiere: ModelViewMatierePremiere;
  lotSelected: any;
  quantite: number;
  prixUnitaireHt: number;
  dateEntree: number;
  dlc: number;

  displayDialog: boolean = false;

  labelSubmit: string = 'Modifier le lot';
  labelCancel: string = 'Annuler';

  udpZds: UniteDeProduction__ZoneDeStockageDTO[] = [];

  dataSource: DataSource;

  selectedUdpZdsKeys: number[] = [];

  constructor(private stocksSvc: StocksService,
              private udpSvc: UnitesDeProductionService,
              public utils: UtilsService,
              private gfs: GenericFormService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.actionSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
  }

  actionSubscription = (): void => {
    this.subAction = this.stocksSvc.openDialogEditLot$
      .pipe(catchError(err => this.utils.handleError(err)))
      .subscribe(response => {
        this.lotSelected = response.selectedStock;
        this.quantite = this.lotSelected?.quantite;
        this.prixUnitaireHt = this.lotSelected?.prixUnitaire;
        this.dateEntree = this.lotSelected?.dateEntree;
        this.dlc = this.lotSelected?.dlc;
        this.selectedUdpZdsKeys = [this.lotSelected?.idUdpZds]

        this.matierePremiere = response.matierePremiere;
        this.initForm();
        this.displayDialog = true;
      });
  };

  onChangeUdpZds = (event): void => {
    if (event.addedItems?.length) {
      event.addedItems.forEach(item => {
        if (!this.selectedUdpZdsKeys.filter(id => id === item.id).length) {
          this.selectedUdpZdsKeys.push(item.id);
        }
      });
    }
    if (event.removedItems?.length) {
      event.removedItems.forEach(item => {
        if (this.selectedUdpZdsKeys.filter(id => id === item.id).length) {
          this.selectedUdpZdsKeys = this.selectedUdpZdsKeys.filter(id => id !== item.id);
        }
      });
    }
  }

  initForm = (): void => {
    this.udpSvc.fetchStorageAreas({ idUniteProduction: this.lotSelected?.idUdp })
      .subscribe(response => {
        this.udpZds = response.resultList;
        this.dataSource = new DataSource({
          store: new ArrayStore({
            key: 'id',
            data: this.udpZds
          })
        });
      });
  };

  updateLot = (): void => {
    let stock = {
      id: this.lotSelected.id,
      idUdpZoneDeStockage: this.selectedUdpZdsKeys[0],
      dateEntree: this.dateEntree,
      prixUnitaire: this.prixUnitaireHt,
      quantite: this.quantite,
      dlc: this.dlc
    } as StockDTO;

    this.stocksSvc.updateStock(stock).subscribe(response => {
      if (!this.utils.isNullOrEmpty(response)) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le lot a été mis à jour avec succès`);
        this.stocksSvc.announceLoadStocks(this.matierePremiere);
        this.produitDeclinaisonSvc.announcePaginationSearchStock({page: 0, size: 50});
        this.closeDialog();
      }
    });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  onChangeDateEntree = ($event: any): void => {
    this.dateEntree = $event.value;
  };

  onChangeDlc = ($event: any): void => {
    this.dlc = $event.value;
  };

}
