import {Component, OnDestroy, OnInit} from '@angular/core';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {Subscription} from 'rxjs';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {catchError, switchMap} from 'rxjs/operators';
import {MarcheService} from '../../../core/services/entities/marche.service';
import {GestionLotMarcheService} from '../../../core/services/gestion-marche/gestion-lot-marche.service';
import {DevextremeService} from '../../../core/services/technique/devextreme.service';
import {MarcheProduitSupplier} from './marches-produit-resolver.service';
import {ProduitDeclinaisonMarche} from '../../../core/models/gestion-produits/marches/produit-declinaison-marche';
import {MarcheDTO} from '../../../core/dtos/gerstion-marche/marche-dto';
import * as moment from 'moment';
import {MarcheProduitService} from '../../../core/services/gestionproduits/marche-produit.service';

@Component({
  selector: 'yo-marches-produit',
  templateUrl: './marches-produit.component.html',
  styleUrls: ['./marches-produit.component.scss']
})
export class MarchesProduitComponent implements OnInit, OnDestroy {

  subParent: Subscription;

  uniteDeProductionList: UniteDeProductionDTO[];
  uniteDeProductionSelected: UniteDeProductionDTO;

  produitDeclinaisonMarcheList: ProduitDeclinaisonMarche[];
  produitDeclinaisonMarcheSelected: ProduitDeclinaisonMarche;
  produit: ProduitDTO;


  constructor(public utils: UtilsService, private route: ActivatedRoute,
              private marcheSvc: MarcheService,
              private mpSvc: MarcheProduitService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              public dxSvc: DevextremeService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit(): void {

    this.routeInitSubscription();

  }

  routeInitSubscription = () => {
    this.subParent = this.route.data
      .pipe(
        switchMap((data: { marcheProduitSupplier: MarcheProduitSupplier }) => {

          // PRODUIT
          this.produit = data.marcheProduitSupplier.produit;

          //UNITE DE PRODUCTION
          this.uniteDeProductionList = [...data.marcheProduitSupplier.uniteDeProductionList];
          this.uniteDeProductionSelected = this.auth2Svc.preselectSingleByFirstLocalSite(this.uniteDeProductionList);

          return this.mpSvc.getProduitDeclinaisonMarcheList(this.produit.id, this.uniteDeProductionSelected.id);
        }),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response=>{
        this.updateViewFromResponse(response);
      });
  };

  updateViewFromResponse = response => {

     // console.log('updateViewFromResponse',response);
    this.produitDeclinaisonMarcheList = response.resultList;
    this.produitDeclinaisonMarcheSelected = this.utils.getFirstElement(this.produitDeclinaisonMarcheList);
    this.mpSvc.announceMarcheEnCours(this.produitDeclinaisonMarcheSelected);

  };

  onChangeSelectedUniteDeProduction = ($event: any) => {

    this.mpSvc.getProduitDeclinaisonMarcheList(this.produit.id, this.uniteDeProductionSelected.id).subscribe(response=>this.updateViewFromResponse(response) )

  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subParent);
  }


  getTooltipMarcheList = (marcheList: MarcheDTO[], isMarcheEnCoursList: boolean) => {

    let tooltip = isMarcheEnCoursList ? 'aucun marché en cours' : 'aucun marché';

    if (!this.utils.isCollectionNullOrEmpty(marcheList)) {
      tooltip = isMarcheEnCoursList ? 'LISTE DES MARCHÉS EN COURS' : 'LISTE DES MARCHÉS';
      tooltip += '<br><br>';
      tooltip += marcheList.map(item => 'Marché : ' + item.libelle + '<br>Période : ' + this.utils.getFrenchDateDDMMYYYY(moment(item.dateDebut)) + ' - ' + this.utils.getFrenchDateDDMMYYYY(moment(item.dateFin))).join('<br><br>');
    }


    return tooltip;

  };



}
