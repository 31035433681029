<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="dialog-line">
    <div class="label" title="Unité de production">Unité de production <span class="danger-color">*</span></div>
    <div class="value">
      <dx-lookup
        [dataSource]="[this.uniteProduction]"
        [value]="this.uniteProduction?.id"
        valueExpr="id"
        displayExpr="libelle"
        [disabled]="true"
      >
      </dx-lookup>
    </div>
  </div>


  <form (ngSubmit)="save()" [formGroup]="form">

    <div id="loadingZone">
      <div class="dialog-line">
        <div class="label" title="Fournisseur">Fournisseur <span class="danger-color">*</span></div>
        <div class="value">
          <dx-lookup
            [dataSource]="this.fournisseurs"
            [value]="this.selectedFournisseurId"
            valueExpr="fournisseurId"
            displayExpr="fournisseurLibelle"
            [searchEnabled]="true"
            formControlName="fournisseur"
            (onSelectionChanged)="onFournisseurChange($event)"
            [disabled]="!isFournisseursListReady"
          >
            <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
          </dx-lookup>
        </div>
      </div>

      <div class="dialog-line">
        <div class="label" title="Date de livraison">Date de livraison <span class="danger-color">*</span></div>
        <div class="value">
          <dx-calendar
            #calendar
            id="calendar"
            [min]="minDate"
            [disabled]="!isCalendarReady"
            [firstDayOfWeek]="0"
            formControlName="dateLivraison"
            [disabledDates]="disabledDates"
            cellTemplate="custom">
          <span *dxTemplate="let cell of 'custom'"
                [ngClass]="getCellCssClass(cell)">
            {{ cell.text }}
          </span>
          </dx-calendar>
        </div>
      </div>
    </div>

    <div *ngIf="bonCf?.listeBesoinId" class="dialog-line">
      <div class="label" title="Choix de la quantité">Quantité à prendre en compte par référence <span class="danger-color">*</span></div>
      <div class="value">
        <div class="value">
          <dx-select-box
            [items]="choixQtes"
            formControlName="choixQte"
            valueExpr="value"
            displayExpr="label"
          >
            <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
          </dx-select-box>
        </div>
      </div>
    </div>

    <div class="d-flex mg-t-10 justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Réassigner"
        class="p-button-success">
      </button>
      <button
        class="mg-l-5 cta-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times">
      </button>
    </div>
  </form>


</dx-popup>

