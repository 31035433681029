import {Injectable} from '@angular/core';
import {RoutemapService} from "../routemap.service";

@Injectable()
export class ProduitRouteMapService {


  constructor(public routeMapSvc:RoutemapService) {
  }

  getDeleteProduitSLink(idProduit: number): string {
    const url = `dolrest/produits/deleteone/${idProduit}`
    return url;
  }

  getAllCiqualConstSLink(): string {
    const url = `dolrest/ciqual/const/findall`
    return url;
  }

  getSaveProduitsApportsNutritionnelsSLink(): string {
    const url = `dolrest/gestionproduits/produitsApportsNutritionnels/save`
    return url;
  }

  getSaveProduitsModesOperatoiresSLink(idProduit: number): string {
    // const url = `dolrest/gestionproduits/produit/save/produitsmodeoperatoire?idProduit=${idProduit}`
    const url = `dolrest/gestionproduits/produit/save/produitsmodeoperatoire/${idProduit}`
    return url;

  }

  getModesOperatoiresSLink(idProduit: number) {
    const url = `dolrest/gestionproduits/produit/get/modesOperatoires?idProduit=${idProduit}`;
    return url;
  }

  getModeOperatoireProduitDeclinaisonSLink(idProduitDeclinaison: number) {
    const url = `dolrest/gestionproduits/produit/get/modeOperatoireProduitDeclinaison?idProduitDeclinaison=${idProduitDeclinaison}`;
    return url;
  }

  getProduitDeclinaisonSLink(idProduitDeclinaison: number) {
    const url = `dolrest/gestionproduits/produit/get/produitdeclinaison?idProduitDeclinaison=${idProduitDeclinaison}`;
    return url;
  }


  /**
   * Enregristrer les familles GEMRCN d'une liste de produits declinés
   * @returns {string}
   */
  getSaveFamillesGemrcn() {
    return `dolrest/gestionproduits/produit/save/familles-gemrcn`;
  }

  /**
   * Enregristrer les apports nutritionnels d'une liste de produits declinés
   * @returns {string}
   */
  getSaveApportsNutritionnels() {
    return `dolrest/gestionproduits/produit/save/apports-nutritionnels`;
  }

  /**
   *
   */
  getSaveProduitsAllergenesSLink(): string {
    const url = `dolrest/gestionproduits/produitAllergenes/save`;
    return url;
  }


  /**
   * Route pour les allergenes du produit sur le serveur
   * @param {number} idProduit
   * @returns {string}
   */
  getProduitAllergenesSLink(idProduit: number): string {
    const url = `dolrest/gestionproduits/produitAllergenes/${idProduit}`;
    return url;
  }

}
