import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {combineLatest, Observable} from 'rxjs';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {AppellationDTO} from '../../../core/dtos/appellations-dto';
import {ProduitAppellationDTO} from '../../../core/dtos/produit-appellation-dto';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {AppellationsService} from '../../../core/services/entities/appellations.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {
  GenericRequestSupplier,
  Predicat,
  Search,
  Sort
} from '../../../core/suppliers/generics/generic-request-supplier';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {ProduitAppellationService} from '../../../core/services/entities/produit-appellation.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from '../../../core/constants';

@Injectable({
  providedIn: 'root'
})
export class AppellationsResolverService implements Resolve<AppellationsSupplier> {


  constructor(private gds: GenericDatagridService,
              private appellationSvc: AppellationsService,
              private produitSvc: ProduitsService,
              private produitAppellationSvc:ProduitAppellationService,
              private produitDecliSvc: ProduitDeclinaisonService,
              public auth2Svc: Auth2Service) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppellationsSupplier> | Promise<AppellationsSupplier> | AppellationsSupplier => {

    const idProduit: number = +route.parent.paramMap.get('idProduit');

    const appellationsSupplier = new AppellationsSupplier();

    appellationsSupplier.idProduit=idProduit;

    const idsSites =this.auth2Svc.utilisateur.sites.map(site=>site.id);

    const appellations$ = this.gds.search(this.filterAppellations(this.appellationSvc.getEntityName(),idsSites));

    const produitsAppellations$ = this.gds.search(this.filterProduitsAppellations(idProduit,this.produitAppellationSvc.getEntityName(),idsSites));

    const produitsDeclinaisons$ = this.gds.search(this.filterProduitsDeclinaisons(idProduit,this.produitDecliSvc.getEntityName(),idsSites));

    const produit$ = this.gds.getOne(this.produitSvc.getEntityName(),idProduit);

    const all$ = combineLatest(appellations$, produitsAppellations$, produitsDeclinaisons$, produit$);

    return all$.toPromise().then(response => {

      appellationsSupplier.appellations = response[0].resultList;
      appellationsSupplier.produitsAppellations = response[1].resultList;
      appellationsSupplier.produitsDeclinaisons = response[2].resultList;

      appellationsSupplier.produitSiteId = response[3].one.site.id;
      appellationsSupplier.produit = response[3].one;

      return appellationsSupplier;
    });
  };

  /**
   * Récupérer toutes les appellations  qui sont actives et dans les sites de l'utilisateur
   * @param idProduit
   */
  filterAppellations = (entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(entityName);
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'appellation.actif';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat1);

    const predicatSites = new Predicat();

    predicatSites.path = 'appellation.site';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type =  PREDICAT_TYPE.Integer;
    predicatSites.ids=idsSite;

    search.predicats.push(predicatSites);


    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = 'appellation.libelle';
    search.sorts = [];
    search.sorts.push(sort);


    grs.search = search;

    return grs;
  };


  /**
   * Récupérer tous les produits appellations du produit qui ont des appellations actives et dans les sites de l'utilisateur
   * @param idProduit
   */
  filterProduitsAppellations = (idProduit:number,entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(entityName);
    const search = new Search();

    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'produitappellation.produitDeclinaison.produit.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type =  PREDICAT_TYPE.Integer;
    predicat1.value = idProduit + '';

    search.predicats.push(predicat1);

    const predicat2 = new Predicat();
    predicat2.path = 'produitappellation.appellation.actif';
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat2);

    const predicatSites = new Predicat();

    predicatSites.path = 'produitappellation.appellation.site';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type =  PREDICAT_TYPE.Integer;
    predicatSites.ids=idsSite;

    search.predicats.push(predicatSites);

    grs.search = search;

    return grs;
  };

  /**
   * Récupérer tous les produits déclinaisons du produit, les trier par libelle déclinaison et dans les sites de l'utilisateur
   * @param idProduit
   */
  filterProduitsDeclinaisons = (idProduit:number,entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(entityName);
    const search = new Search();

    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'produitdeclinaison.produit.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idProduit + '';

    search.predicats.push(predicat1);

    const predicatSites = new Predicat();

    predicatSites.path = 'produitdeclinaison.site';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type = PREDICAT_TYPE.Integer;
    predicatSites.ids = idsSite;

    search.predicats.push(predicatSites);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = 'produitdeclinaison.declinaison.libelle';
    search.sorts = [];
    search.sorts.push(sort);

    grs.search = search;

    return grs;
  };

}

export class AppellationsSupplier {

  appellations: AppellationDTO[];
  produitsAppellations: ProduitAppellationDTO[];
  produitsDeclinaisons: ProduitDeclinaisonDTO[];

  idProduit:number;
  produitSiteId:number;
  produit: ProduitDTO;

}
