<a *ngIf="isDataFound()"  (click)="getHelp()" class="cursor">
  <i [class]="_iconClick"></i>
</a>
<dx-popup
  [width]="'80%'"
  [height]="'80%'"
  [showTitle]="true"
  title="Aide"
  (onHiding)="closeDialog()"
  [resizeEnabled]="true"
  [(visible)]="displayDialog"
  [showCloseButton]="true"
  class="dialog-help"
  [wrapperAttr]="{ id: 'dialog-help' }">
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      width="100%"
      height="100%">
      <markdown [data]="fileData"></markdown>
    </dx-scroll-view>
  </div>

</dx-popup>
