import {Component, OnDestroy, OnInit} from "@angular/core";
import {TourneesService} from "../../../core/services/referentiel/tournees.service";
import {Tournee} from "../../../core/dtos/tournee-dto";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {FormControl, FormGroup} from "@angular/forms";
import {ToastService} from "../../../core/services/technique/toast.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";

@Component({
  selector: 'yo-tournee-dialog',
  templateUrl: './tournee-dialog-edition.component.html',
  styleUrls: ['./tournee-dialog-edition.component.scss']
})
export class TourneeDialogComponent implements OnInit, OnDestroy {

  private subOpenDialog$: Subscription;

  displayDialog: boolean = false;

  tournee: Tournee;

  dialogTitle: string;

  idSelectedSite: number;

  sitePlaceholder: string;

  form: FormGroup = new FormGroup({});

  constructor(private tourneeSvc: TourneesService,
              private utilsSvc: UtilsService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private i8nSvc: InternationalizationService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog$);
  }

  save = (): void => {
    if (this.form.valid) {
      let tourneeToSave: Tournee = {...this.tournee};
      tourneeToSave.site = {id: this.idSelectedSite} as SiteDTO;
      tourneeToSave.libelle = this.form.controls['libelle'].value;
      tourneeToSave.actif = this.form.controls['actif'].value;
      tourneeToSave.poidsMaxEssieux = this.form.controls['poidsMaxEssieux'].value;
      this.tourneeSvc.save(tourneeToSave)
        .subscribe(response => {
          this.tourneeSvc.announceRoundSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode('ROUND_SAVED', null));
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, this.i8nSvc.getLabelFromCode('FILL_FORM', null));
    }
  }

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.tournee?.site?.libelle : this.i8nSvc.getLabelFromCode('SELECT_SITE', null);
    this.idSelectedSite = this.tournee?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.tournee?.libelle),
      actif: new FormControl(this.tournee?.actif != null ? this.tournee.actif : true),
      poidsMaxEssieux: new FormControl(this.tournee?.poidsMaxEssieux || 0)
    });
  }

  openDialogSubscription = (): void => {
    this.subOpenDialog$ = this.tourneeSvc.openDialogEdition$
      .subscribe((tournee: Tournee) => {
        this.displayDialog = true;
        this.tournee = tournee;
        if (!this.tournee) {
          this.tournee = new Tournee();
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_CREATION_ROUND', null);
        } else {
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_MODIFICATION_ROUND', null);
        }
        this.initForm();
      });
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.tourneeSvc.canModify(this.tournee);
  }

}
