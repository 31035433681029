import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {PlcRepasWeek, PlcWeek} from '../menu-plc-resolver.service';
import {EffectifMode} from '../constant/menu-plc.constant';

@Component({
  selector: 'yo-prestations-semaine',
  templateUrl: './prestations-semaine.component.html',
  styleUrls: ['./prestations-semaine.component.scss'],

})
export class PrestationsSemaineComponent implements OnInit, OnDestroy {

  subPlcWeek: Subscription;
  repasPlcWeekList: PlcRepasWeek[];
  typeEffectif = EffectifMode.Prevision;
  cols: any[] = [];

  constructor(public utils: UtilsService,
              private plcSvc: PointDeLivraisonService) {
  }

  ngOnInit() {
    this.plcWeekSubscription();
  }

  plcWeekSubscription = () => {
    this.subPlcWeek = this.plcSvc.plcWeek$.subscribe((response: PlcWeek) => {
      this.repasPlcWeekList = [];
      if (!this.utils.isNullOrEmpty(response)) {
        this.repasPlcWeekList = response.repasList;
      }
    });
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPlcWeek);
  }

  canDisplayGrille = () => {
    if (!this.utils.isCollectionNullOrEmpty(this.repasPlcWeekList)) {
      return true;
    }
    return false;
  };
}
