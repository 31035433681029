import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {MarcheDTO} from '../../core/dtos/gerstion-marche/marche-dto';
import {GestionsMarcheService} from '../../core/services/gestion-marche/gestions-marche.service';
import {MarcheService} from '../../core/services/entities/marche.service';
import {GestionLotMarcheService} from '../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheDTO} from '../../core/dtos/gerstion-marche/lot-marche-dto';

@Injectable({
  providedIn: 'root'
})
export class MarcheResolverService implements Resolve<MarcheSupplier> {

  constructor(private marcheSvc: MarcheService,
              private gestionsMarcheSvc: GestionsMarcheService,
              private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MarcheSupplier> | Promise<MarcheSupplier> | MarcheSupplier => {
    let marcheSupplier: MarcheSupplier = new MarcheSupplier();
    let idMarche: number = +route.paramMap.get('idMarche');

    if (idMarche > 0) {
      const marcheDTO$ = this.gestionsMarcheSvc.getMarcheById(idMarche);
      const lotMarcheDTO$ = this.gestionLotMarcheSvc.getAllLotMarcheByIdMarche(idMarche);
      const all$ = combineLatest([marcheDTO$,lotMarcheDTO$]);
      return all$.toPromise().then(data => {
        marcheSupplier.marche = data[0].one;
        marcheSupplier.lotMarcheList = data[1].resultList;

        return marcheSupplier;
      });
    }else{
      //si nouveau marché
      marcheSupplier.marche = this.marcheSvc.createEmptyDTO();
      marcheSupplier.marche.id = 0;
      marcheSupplier.marche.actif = true;

      return marcheSupplier;
    }
  };
}

export class MarcheSupplier {
  marche: MarcheDTO;
  lotMarcheList: LotMarcheDTO[] = [];
}


