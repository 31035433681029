import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {combineLatest, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {ReglePrefereService} from '../../../core/services/entities/regle-prefere.service';
import {UniteDeProduction__denreesInterditesService} from '../../../core/services/entities/unite-de-production__denrees-interdites.service';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {HELP_FOLDERS} from "../../../core/constants";


@Component({
  selector: 'yo-up-denrees-interdites',
  templateUrl: './up-denrees-interdites.component.html',
  styleUrls: ['./up-denrees-interdites.component.scss']
})
export class UpDenreesInterditesComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  uniteDeProduction: UniteDeProductionDTO;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-denrees-substitution';

  constructor(public utils: UtilsService,
              private reglePrefereSvc: ReglePrefereService,
              private udpDenreeInterditeSvc: UniteDeProduction__denreesInterditesService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscriptionRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  subscriptionRouteData(){
    const all$ = combineLatest([this.route.parent.data]);
    this.subRoute = all$.subscribe(data => {
      this.uniteDeProduction = data[0].uniteDeProductionSupplier.uniteDeProduction;
    });
  }

  public help(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Les denrées interdites`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    dms.content = {
      intro: `Elles permettent de remplacer une denrée par une autre dans toutes les recettes de vos plats.`,
      paragraphes: []
    };
    return dms;
  }

  showAddDialog() {
    // on ouvre le dialog 'Ajout d'une denrée interdite'
    if (this.uniteDeProduction) {
      this.udpDenreeInterditeSvc.announceOpenDialogAjoutDenreeInterdite(this.uniteDeProduction.id);
    }
  }
}
