import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldSelectButtonSupplier extends FormFieldBaseSupplier<string> {

  controlType = 'selectbutton';


  constructor(options: any){
    super(options);

  }

}
