import {TypeProduitDTO} from "./type-produit-dto";
import {ObjectDTO} from "./object-dto";

export class DeclinaisonDTO  extends ObjectDTO{

  // declinaison
  code: string;
  libelle: string;
  actif:boolean;

  typeProduit : TypeProduitDTO;
}
