import {FamilleGemrcnDTO} from "./famille-gemrcn-dto";
import {ObjectDTO} from "./object-dto";

export class ModeleNutritionnelDetailDTO extends ObjectDTO {

  //modele nutritionnel detail
  valeur: number;
  typeValeur: string;
  familleGemrcn: FamilleGemrcnDTO;
  actif: boolean = false;

  // modele nutritionnel groupe gemrcn
  modeleNutritionnelGroupeGemrcnId : number;
  modeleNutritionnelGroupeGemrcnChoix : number;

  // modele nutri
  idModeleNutritionnel: number;
  nbRepas: number;



}
