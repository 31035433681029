import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  calculateCurrentStepPourcentage = (totalSteps: number, currentStep: number) => {
    const res: number = (currentStep * 100) / totalSteps;
    return Math.round((res + Number.EPSILON) * 10) / 10;
  }

}
