import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {MenuComposition__PlcDTO} from "../../../../core/dtos/menucomposition-plc-dto";
import {MPlcRepasRow} from "../../../../core/models/plc-repas-row";
import {STATUTS_MENUS} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {MenucompositionPlcService} from "../../../../core/services/entities/menucomposition-plc.service";
import {PlcRepas} from "../../menu-plc-resolver.service";
import {MenuItem} from "primeng/api";
import {EffectifMode} from "../../constant/menu-plc.constant";
import {Subject} from "rxjs";
import {catchError, debounceTime, filter, switchMap} from "rxjs/operators";
import {Subscription} from "rxjs/index";
import {ConfigurationPortailDTO} from "../../../../core/dtos/portail/configuration-portail-dto";

@Component({
  selector: 'yo-generic-repas',
  templateUrl: './generic-repas.component.html',
  styleUrls: ['./generic-repas.component.scss']
})
export class GenericRepasComponent implements OnInit,OnDestroy{

  _plcRepas: PlcRepas;
  @Input('plcRepas') set plcRepas(value: PlcRepas) {
    this._plcRepas = value;
    this.infoPlcRepas = !this.utils.isNullOrEmpty(this._plcRepas) && !this.utils.isCollectionNullOrEmpty(this._plcRepas.rows) ? this._plcRepas.rows[0] : null;
  };

  @Input() configPortailPlc : ConfigurationPortailDTO;

  private subjectChangeEffectif = new Subject<MenuComposition__PlcDTO>();
  changeEffectif$ = this.subjectChangeEffectif.asObservable();
  subChangeEffectifPlat :Subscription;

  selectedPlat: MenuComposition__PlcDTO;
  selectedRow: MPlcRepasRow;

  infoPlcRepas: MPlcRepasRow;

  STATUTS_MENUS = STATUTS_MENUS;
  EffectifMode = EffectifMode;

  // 1 = effectif previsionnel, 2=effectif fabrication, 3 = effectif facturé
  @Input() typeEffectif = EffectifMode.Prevision;
  actions: MenuItem[] = [];

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private mcPlcSvc: MenucompositionPlcService) {
  }

  ngOnInit() {
    this.changeEffectifPlatSubscription();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subChangeEffectifPlat);
  }

  changeEffectif = ($event: KeyboardEvent, plat: MenuComposition__PlcDTO, propertyName: string) => {
    this.utils.keydownNumber($event, plat, propertyName, true);
    if (!this.utils.isNullOrEmpty(plat.effectifPrevisionnel) && !this.utils.isNullOrEmpty(plat.effectifFabrication) && !this.utils.isNullOrEmpty(plat.effectifFacture)) {
     this.announceChangeEffectifPlat(plat);
    }
  };

  changeEffectifPlatSubscription = () => {
    this.subChangeEffectifPlat = this.changeEffectif$
      .pipe(
        debounceTime(300),
        filter(response => !this.utils.isNullOrEmpty(response.effectifPrevisionnel) && !this.utils.isNullOrEmpty(response.effectifFabrication) && !this.utils.isNullOrEmpty(response.effectifFacture)),
        switchMap(response => this.mcPlcSvc.saveEffectifPlatPlc(response, this.typeEffectif)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.plcRepas.rows = this.updateRows(response.one, this.plcRepas.rows);
        }
      });
  };

  changeTauxDePrise = ($event: KeyboardEvent, plat: MenuComposition__PlcDTO, propertyName: string) => {
    this.utils.keydownNumber($event, plat, propertyName, true);
    this.mcPlcSvc.announceChangeTdpPlat(plat);
  };

  getHistoLabel = (plat: MenuComposition__PlcDTO) => {
    if (!this.utils.isNullOrEmpty(plat)) {
      const dateCreation = this.utils.convertNumberDateToDate(plat.dateCreation);
      const dateDerniereModification = this.utils.convertNumberDateToDate(plat.dateDerniereModification);
      return `Créé le ${this.utils.getFrenchDateHHMM(dateCreation.getTime())} par ${plat.creePar}.<br><br>Dernière modification le ${this.utils.getFrenchDateHHMM(dateDerniereModification.getTime())} par ${plat.modifiePar}.`;
    }
  };

  isPlatActif = (rowData: MPlcRepasRow) => {
    if (this.utils.isNullOrEmpty(rowData.plat)) {
      return false;
    } else {
      return rowData.plat.actif;
    }
  };

  isInputDisabled = (row: MPlcRepasRow) => {
    if (this.auth2Svc.isSiteLocal(row.prestation.contratMenuSiteId)) {
      return false;
    } else {
      return true;
    }
  };

  canDisplayGrid = () => {
    if (!this.utils.isNullOrEmpty(this._plcRepas)
      && !this.utils.isCollectionNullOrEmpty(this._plcRepas.rows)
    ) {
      return true;
    }
    return false;
  };

  updateRows = (plat: MenuComposition__PlcDTO, rows: MPlcRepasRow[]): MPlcRepasRow[] => {

    const newRows = [];

    for (const row of rows) {
      if (!this.utils.isNullOrEmpty(row.plat)
        && row.plat.id === plat.id) {
        row.plat = plat;
      }
      newRows.push(row);
    }
    return newRows;
  };

  announceChangeEffectifPlat = (plat: MenuComposition__PlcDTO) => {
    this.subjectChangeEffectif.next(plat);
  };

  isEffectifInputDisabled = (row: MPlcRepasRow): boolean => {

    if (this.configPortailPlc.dateLimiteSaisie < new Date(row.cmcrPlcDate.dateRepas)) {
      if (row.cmcrPlcDate.statutMenuCode != STATUTS_MENUS.SAISIE_EFFECTIFS) {
        return true;
      } else {
        return !this.auth2Svc.isSiteLocal(row.cmcrPlcDate.cmSiteId);
      }
    } else {
      return true;
    }
  };
}
