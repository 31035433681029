import {RepasDTO} from "./repas-dto";
import {DecoupageRepasDTO} from "./decoupagerepas-dto";
import {ObjectDTO} from "./object-dto";
import {FamilleProduitDTO} from "./famille-produit-dto";

export class ModeleNutritionnel__FamillePlatDTO extends ObjectDTO {

  //modele nutritionnel famille plat
  familleProduit: FamilleProduitDTO;
  repas: RepasDTO;
  decoupageRepas: DecoupageRepasDTO;
  actif: boolean = false;

  // modele nutri
  idModeleNutritionnel: number;




}
