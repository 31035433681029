import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AtelierDTO} from '../../dtos/atelier-d-t-o';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {catchError} from 'rxjs/operators';
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {HttpService} from "../technique/http.service";


@Injectable()
export class AteliersService extends GenericHandler<AtelierDTO> {

  private subjectOpenDialog = new Subject<AtelierDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectAtelierSaved = new Subject<AtelierSavedSupplier>();
  atelierSaved$ = this.subjectAtelierSaved.asObservable();

  exportAtelierByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/production/ateliers/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importAtelierFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/production/ateliers/import", formData, null);
  };

  deleteAtelierByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`${"dolrest/referentiel/production/ateliers/delete"}?${params}`);
  };

  announceOpenDialogEdition = (atelier: AtelierDTO) => {
    this.subjectOpenDialog.next(atelier);
  };

  announceAtelierSaved = (atelier: AtelierDTO, isUpdate: boolean = false) => {
    this.subjectAtelierSaved.next({isUpdate, atelier} as AtelierSavedSupplier);
  }


  save = (atelier: AtelierDTO): Observable<ResponseWrapper<AtelierDTO>> => this.httpSvc.post("dolrest/referentiel/production/ateliers/save", atelier);

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }


  getTotalRecordsLabel(): string {
    return _startCase('Ateliers');
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ATELIERS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN ATELIER';
  }


  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private gds: GenericDatagridService,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName(): string {
    return 'atelier';
  }

  getFields(dto: AtelierDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une ZoneDeProduction est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new AtelierDTO();
      dto.actif = true;
    }

    const formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        excelType: 'string',
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        excelType: 'string',
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        excelType: 'boolean',
        required: false,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      })

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): AtelierDTO {
    return new AtelierDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER L'ATELIER '${data.libelle.toUpperCase()}'`;
  }

  getAll() {
    return this.gds.getAll(this.getEntityName(), ['code,asc'], true)
      .pipe(
        catchError(err => this.utils.handleError(err, true))
      );
  }

}

export interface AtelierSavedSupplier {
  isUpdate: boolean;
  atelier: AtelierDTO;
}

