import {Component, OnDestroy, OnInit} from "@angular/core";
import {combineLatest, Subscription} from "rxjs";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {UtilsService} from "../../../core/utils/utils.service";

import {ActivatedRoute} from "@angular/router";
import {DialogMsgSupplier} from "../../../core/suppliers/dialog-msg-supplier";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UniteDeProduction__DenreeBlackListBesoinDTO} from "../../../core/dtos/unite-de-production__denree-black-list-besoin.-dto";
import {GestionUniteProduction__DenreeBlackListBesoinService} from "../../../core/services/gestion-unites-productions/gestion-unite-production__denree-black-list-besoin.service";
import {HELP_FOLDERS} from "../../../core/constants";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-up-produits-declinaisons-non-approvisionnes',
  templateUrl: './up-produits-declinaisons-non-approvisionnes.html',
  styleUrls: ['./up-produits-declinaisons-non-approvisionnes.scss']
})
export class UpProduitsDeclinaisonsNonApprovisionnesComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subDenreesSaved: Subscription;
  uniteDeProduction: UniteDeProductionDTO;
  udpProduitsDeclinaisonsNonApprovisionnesList: UniteDeProduction__DenreeBlackListBesoinDTO[] = [];

  selectedRows: number[] = [];
  hasUdpRights: boolean;

  allMode: string;
  checkBoxesMode: string;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-produits-declinaisons-non-approvisionnes';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private udpDenreeBlackLBSvc: GestionUniteProduction__DenreeBlackListBesoinService,
              private route: ActivatedRoute) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.initHasGestionUdp();
    this.initFromSupplierRouteSubscription();
    this.initDenreesSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subDenreesSaved);
  }

  initHasGestionUdp = (): void => {
    this.auth2Svc.hasUniteDeProduction$.subscribe(response => this.hasUdpRights = response);
  };

  initFromSupplierRouteSubscription = (): void => {
    const all$ = combineLatest([this.route.parent.data]);
    this.subRoute = all$.subscribe(data => {
      this.uniteDeProduction = data[0].uniteDeProductionSupplier.uniteDeProduction;
      this.initDenreesSubscription(this.uniteDeProduction.id);
    });
  };

  initDenreesSubscription = (idUdp: number): void => {
    this.udpDenreeBlackLBSvc.getAll(idUdp)
      .subscribe(response => {
        this.udpProduitsDeclinaisonsNonApprovisionnesList = response.resultList
      });
  };

  /**
   * Utilisé pour vérifier si la création ou la suppression est possible
   * pour l'utilisateur courant.
   */
  canEdit = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasUdpRights;

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isConfirmed: boolean = await result;
    if (isConfirmed) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.udpProduitsDeclinaisonsNonApprovisionnesList.find(u => u.id === idSelected))
        .map((u: UniteDeProduction__DenreeBlackListBesoinDTO) => u.id);

      const idsPdToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.udpProduitsDeclinaisonsNonApprovisionnesList.find(u => u.id === idSelected))
        .map((u: UniteDeProduction__DenreeBlackListBesoinDTO) => u.idProduitDeclinaison);

      this.udpDenreeBlackLBSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          this.udpDenreeBlackLBSvc.announceSuppression(idsPdToDelete);
          this.udpProduitsDeclinaisonsNonApprovisionnesList = this.udpProduitsDeclinaisonsNonApprovisionnesList.filter(u => !idsToDelete.includes(u.id));
        });
    }
  };

  initDenreesSavedSubscription = (): void => {
    this.subDenreesSaved = this.udpDenreeBlackLBSvc.elementsSaved$
      .subscribe((response: UniteDeProduction__DenreeBlackListBesoinDTO[]) => {
        this.udpProduitsDeclinaisonsNonApprovisionnesList = [...this.udpProduitsDeclinaisonsNonApprovisionnesList, ...response];
      });
  };

  openDialogAjoutProduitDeclinaison = () => {
    if (this.canEdit()) {
      this.udpDenreeBlackLBSvc.announceOpenDialogAjout(this.uniteDeProduction.id);
    }
  };

  public help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Les denrées non approvisionnables`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    dms.content = {
      intro: `Les denrées présentes sur cet écran sont destinées à ne pas être pris en compte au niveau du calcul des listes de besoin`,
      paragraphes: []
    };

    return dms;
  };

}
