<dx-data-grid
  id="gridContainer"
  [dataSource]="dataSource"
  [height]="utils.getWindowAvailableHeight(220)"
  [showBorders]="true"
  [showRowLines]="true"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [rowAlternationEnabled]="true"
  keyExpr="id"
  #grid>
  <dxi-column dataField="utilisateurNom" caption="Nom" cellTemplate="articleCellNom"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="utilisateurPrenom" caption="Prénom"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="environnement.libelle" caption="Environnement"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="jour" caption="Date dernière connexion"

              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="heure" caption="Heure dernière connexion"

              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="jourDu" caption="Date dernière utilisation"

              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="heureDu" caption="Heure dernière utilisation"

              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>

  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20, 25 ,50, 100]"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} connexions">
  </dxo-pager>

  <div *dxTemplate="let cell of 'articleCellNom'">
    <ng-container *ngIf="isActif(cell.row.data); else inactif">
      <i class="fas fa-circle checked-color"
         title="Actif"
         [style.text-shadow]="'0px 0px 1px #000'"
      ></i>
    </ng-container>
    <ng-template #inactif>
      <i class="fas fa-circle pn-border-color"
         title="Session terminée"
         [style.text-shadow]="'0px 0px 1px #000'"></i>
    </ng-template>
    {{ cell.row.data.utilisateurNom }}
  </div>

</dx-data-grid>
