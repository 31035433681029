import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {GROUP_POSITION, GroupFormFields} from '../../../../../core/suppliers/group-formfields';
import {FormGroup} from '@angular/forms';
import {FormOptions} from '../../../../../core/suppliers/form-options';
import {find as _find} from 'lodash';

@Component({
  selector: 'yo-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() set groups(value: GroupFormFields[]){
    this._groups=value;
    this.indexNewRows = this.getIndexNewRows(this._groups);
  }
  @Input() form: FormGroup;
  @Input() options: FormOptions;

  groupPosition = GROUP_POSITION;
  rows = 0;
  _groups:GroupFormFields[];
  indexNewRows:number[]=[];

  constructor(public utils: UtilsService) {
  }

  ngOnInit(): void {
  }

  getField(fieldKey: string, group: GroupFormFields) {

    return _find(group.fields, {'key': fieldKey});
  }

  getIndexNewRows(groups : GroupFormFields[]){

    const indexNewRows = [];

    for (let i = 0; i < this._groups.length; i++) {
      if (i == 0) {
        indexNewRows.push(i);
      } else if (this._groups[i].position === GROUP_POSITION.COL1_AND_COL2) {
        indexNewRows.push(i);
      } else if (i > 0 && (
        this._groups[i - 1].position === GROUP_POSITION.COL1_AND_COL2
        || this._groups[i].position === GROUP_POSITION.COL1
      )) {
        indexNewRows.push(i);
      }
    }

    return indexNewRows;

  }

  addCssRow(index: number, indexNewRows:number[]) {

    for (const idxNewRow of indexNewRows) {
      if (idxNewRow === index) {

        return true;
      }
    }

    return false;

  }

  trackGroupFormFieldsByFn(index, item: GroupFormFields) {

    switch (item.position) {
      case GROUP_POSITION.COL1:

        break;
      case GROUP_POSITION.COL2:
        break;
      case GROUP_POSITION.COL1_AND_COL2:
        break;
    }


    return index; // or item.id
  }

}


