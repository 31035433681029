import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GestionLotMarcheService} from '../../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {UtilsService} from '../../../../../../core/utils/utils.service';
import {ProduitDeclinaisonDTO} from '../../../../../../core/dtos/produit-declinaison-dto';
import {find as _find, remove as _remove} from 'lodash'
import {GenericDatagridService} from '../../../../../../core/services/generics/generic-datagrid.service';
import {ResponseWrapper} from '../../../../../../core/suppliers/wrappers/response-wrapper';
import {UniteDeProductionDTO} from '../../../../../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../../../../../core/services/entities/unites-de-production.service';
import {Auth2Service} from '../../../../../../core/services/security/auth2.service';
import {ProduitDeclinaisonService} from '../../../../../../core/services/entities/produit-declinaison.service';
import {AutoComplete} from 'primeng/autocomplete';
import {Chips} from 'primeng/chips';
import {TYPE_LIST_UDP} from "../../../../../../core/constants";
import {Subscription} from "rxjs/index";

@Component({
  selector: 'yo-search-denree',
  templateUrl: './search-denree.component.html',
  styleUrls: ['./search-denree.component.scss']
})
export class SearchDenreeComponent implements OnInit, OnDestroy {

  @ViewChild('autocompleteSearch') autocompleteSearch: AutoComplete;
  @ViewChild('basketDenree') basketDenree: Chips;

  displayDialog: boolean;

  values: string[] = [];

  denree: ProduitDeclinaisonDTO;
  denreeList: ProduitDeclinaisonDTO[] = [];
  selectedDenreeList: ProduitDeclinaisonDTO[] = [];
  suggestionDenreeList: ProduitDeclinaisonDTO[] = [];

  udpIdList: number[] = [];
  selectedRowKeysDenree: any[] = [];

  subDisplaySearchDenree :Subscription;

  constructor(public utils: UtilsService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private udpSvc: UnitesDeProductionService,
              private produitDecliSvc :ProduitDeclinaisonService) {
  }

  ngOnInit() {
    this.initData();
    // focus sur la recherche par libelle
    this.utils.focus('autocompleteSearch',20,100);
  }
  ngOnDestroy() {
    this.utils.unsubscribe(this.subDisplaySearchDenree);
  }

  initData() {
    this.subDisplaySearchDenree = this.gestionLotMarcheSvc.displaySearchDenree$.subscribe(response => {
      this.displayDialog = response;
      this.clearData();
    });

    this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe((response: ResponseWrapper<UniteDeProductionDTO>) => {
      const udpList: UniteDeProductionDTO[] = response.resultList;
      udpList.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id)).map((udp: UniteDeProductionDTO) => {
        this.udpIdList.push(udp.id);
      });
    });
  }

  clearData(){
    this.selectedDenreeList = [];
    this.suggestionDenreeList = [];
    this.denreeList = [];
    this.denree = undefined;
  }

  closeDialog() {
    this.displayDialog = false;
    this.clearData();
  }

  isDisabled() {
    return false;
  }

  onRemoveChipDenree(event: any) {
    _remove(this.selectedDenreeList, (item: ProduitDeclinaisonDTO) => {
      return item.id === event.value.id;
    });
  }

  addDenreeToBasket(produitDeclinaison: ProduitDeclinaisonDTO){
    const selectedDenree: ProduitDeclinaisonDTO = _find(this.selectedDenreeList, (item: ProduitDeclinaisonDTO) => {
      return item.id === produitDeclinaison.id;
    });
    if(this.utils.isNullOrEmpty(selectedDenree)){
      this.selectedDenreeList.push(produitDeclinaison);
      this.basketDenree.cd.markForCheck();
    }
  }

  sendDenreeBasket() {
    this.produitDecliSvc.announceProduitDecliListDto(this.selectedDenreeList);
    this.clearData();
    this.closeDialog();
  }

  search(event) {
    const filtered: ProduitDeclinaisonDTO[] = [];
    this.gds.search(this.gestionLotMarcheSvc.filterDenrees(event.query))
      .subscribe((response: ResponseWrapper<ProduitDeclinaisonDTO>) => {
      this.denreeList = response.resultList;

      for (let i = 0; i < this.denreeList.length; i++) {
        const denree = this.denreeList[i];
        if (denree.libelle.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          filtered.push(denree);
        }
      }
      this.suggestionDenreeList = filtered;
      this.autocompleteSearch.loading = false;
    });
  }
}
