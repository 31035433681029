import {combineLatest, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {SiteDTO} from '../core/dtos/site-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SiteRoutemapService} from '../core/services/entities/site-routemap.service';
import {Auth2Service} from '../core/services/security/auth2.service';
import {UtilsService} from '../core/utils/utils.service';
import {HttpClient} from '@angular/common/http';

import {ContratmenuDTO} from '../core/dtos/contratmenu-dto';
import {SiteService} from 'app/core/site.service';
import {ContratMenuService} from '../core/services/gestioncontrats/contrat-menu.service';

@Injectable()
export class SiteResolverService implements Resolve<SiteSupplier> {

  // Observable site
  siteAnnounceSource = new Subject<SiteDTO>();

  constructor(private http: HttpClient,
              private siteRoutemapService: SiteRoutemapService,
              private auth2Svc: Auth2Service,
              private utils: UtilsService,
              private contratMenuService: ContratMenuService,
              private siteService: SiteService,) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SiteSupplier> | Promise<SiteSupplier> | SiteSupplier {
    let siteSupplier = new SiteSupplier();
    let idSite: number = +route.paramMap.get('idSite');

    // Observable sur TOUS les contrats actifs.
    const listeAllContratsMenusActifs$ = this.contratMenuService.getListeAllContratsMenusActifs();

    // Observable sur TOUS les sites actifs ayant au moins 1 contrat.
    const listeAllSitesActifsWithContratMenu$ = this.siteService.findAllActifsWithContratMenu();


    // Site en modification
    if (idSite > 0) {

      const siteDTO$ = this.http.get<SiteDTO>(this.siteRoutemapService.getSiteFicheIdentiteLink(idSite));

      const all$ = combineLatest([siteDTO$, listeAllContratsMenusActifs$, listeAllSitesActifsWithContratMenu$]);
      return all$.toPromise().then(data => {
        siteSupplier.siteDTO = data[0];
        siteSupplier.listeAllContratsMenusActifs = data[1];
        siteSupplier.listeAllSitesActifsWithContratMenu = data[2];

        return siteSupplier;

      });
      // Site en création.
    } else {
      const all$ = combineLatest([listeAllContratsMenusActifs$, listeAllSitesActifsWithContratMenu$]);
      return all$.toPromise().then(data => {

        siteSupplier.listeAllContratsMenusActifs = data[0];
        siteSupplier.listeAllSitesActifsWithContratMenu = data[1];

        return siteSupplier;

      });
    }




  }

}

export class SiteSupplier {
  siteDTO: SiteDTO;
  listeAllContratsMenusActifs: ContratmenuDTO[];
  /**
   * Liste de tous les Site actifs ayant au moins 1 ContratMenu actif.
   */
  listeAllSitesActifsWithContratMenu: SiteDTO[];
}

