import {Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {GroupeAchatDTO} from "../../../core/dtos/unite-de-production/groupe-achat-dto";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {GroupeAchatService} from "../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {FicheIdentiteGroupeAchatComponent} from "./fiche-identite-groupe-achat/fiche-identite-groupe-achat.component";
import {FounisseurGroupeAchatComponent} from "./founisseur-groupe-achat/founisseur-groupe-achat.component";
import {MembreGroupeAchatComponent} from "./membre-groupe-achat/membre-groupe-achat.component";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-groupe-achat-sidebar',
  templateUrl: './groupe-achat-sidebar.component.html',
  styleUrls: ['./groupe-achat-sidebar.component.scss']
})
export class GroupeAchatSidebarComponent implements OnInit, OnDestroy {


  dynamicInjector: Injector;
  subGroupeAchat: Subscription
  fullScreen: boolean = false;
  groupeAchat: GroupeAchatDTO;
  title: string;
  isPopUpDisplayed: boolean = false;
  navLinks: any[] = [];


  constructor(public utils :UtilsService,
              private groupeAchatSvc :GroupeAchatService,
              private auth2Svc: Auth2Service,
              private injector: Injector) {
  }

  ngOnInit() {
    this.initGroupeAchat();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subGroupeAchat)
  }

  private initNavLinks = () => {
    this.navLinks = [];
    this.navLinks.push({
      text: `Fiche d'identité`,
      component: FicheIdentiteGroupeAchatComponent
    });

    if (this.groupeAchat.id !== 0) {
      this.navLinks.push({
        text: `Fournisseurs`,
        component: FounisseurGroupeAchatComponent
      });
      this.navLinks.push({
        text: `Membres`,
        component: MembreGroupeAchatComponent
      });
    }
  };

  initGroupeAchat = () => {
    this.subGroupeAchat = this.groupeAchatSvc.openGroupeAchatDialog$.subscribe((data: GroupeAchatDTO) => {
      if (!data) {
        this.groupeAchat = new GroupeAchatDTO()
        this.title = "Création d'un groupe achat"
      } else {
        this.groupeAchat = {...data};
        this.title = (this.canEdit() ? `Modifier le groupe achat` : `Groupe achat`) + `${this.groupeAchat.libelle} du site ${this.groupeAchat.site.libelle}`
      }
      this.isPopUpDisplayed = true;
      this.initNavLinks();
      this.createInjector();
    })
  }

  closeDialog = () => {
    this.navLinks = [];
    this.isPopUpDisplayed = false;
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  private createInjector() {
    this.dynamicInjector = Injector.create({
      providers: [{provide: 'dynamicData', useValue: this.groupeAchat}],
      parent: this.injector,
    });
  }

  canEdit = () => {
      return this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);
  };
}
