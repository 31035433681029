<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Association des conditionnements et variantes aux déclinaisons</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de gérer à travers cette étape la combinaison des associations faites précédemment avec des déclinaisons. Quand une association est réalisée,
        la grille du modèle de conditionnement de plats se trouvant en-dessous se met à jour, il faudra alors la compléter avant de passer à l'étape suivante.
      </p>
      <div class="row">
        <div class="col-lg-6">
          <div class="p-inputgroup input-group-sm ">
            <dx-tag-box
              [items]="conditionnementsVariantes"
              [(value)]="conditionnementsVariantesSelected"
              itemTemplate="templateCV"
              tagTemplate="tagTemplate"
              multiline="false"
              [showSelectionControls]="true"
              applyValueMode="useButtons"
              width="100%"
            >
              <div *dxTemplate="let cv of 'templateCV'">
                {{cv.conditionnement.libelle}} / {{cv.variante.libelle}}
              </div>
              <div *dxTemplate="let cv of 'tagTemplate'">
                <span class="badge-tag">{{cv.conditionnement.libelle}} / {{cv.variante.libelle}}</span>
              </div>
            </dx-tag-box>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="p-inputgroup input-group-sm ">
            <dx-tag-box
              [items]="declinaisons"
              [(value)]="declinaisonsSelected"
              multiline="false"
              displayExpr="libelle"
              [showSelectionControls]="true"
              applyValueMode="useButtons"
              width="100%"
            >
            </dx-tag-box>
          </div>
        </div>
      </div>
      <div class="row mg-t-15 mg-b-15">
        <div class="col-lg-12 text-center">
          <button (click)="updateGridFromBindings()"><i class="fa-solid fa-gear"></i> Mise à jour de la grille de configuration</button>
        </div>
      </div>
      <div class="row" *ngIf="modelePlat?.mode === 'PIECE_NON_PROPORTIONNELLE'">
        <div class="col-lg-12 container-nb-pieces-servir-convive">
          <label class="bold">Nombre de {{ modelePlat?.uniteAConditionner.libelle }} à servir au convive :</label>
          <dx-data-grid
            [dataSource]="[declinaisonForGridList]"
            height="100px"
            width="100%"
            [hoverStateEnabled]="true"
            [allowColumnResizing]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"
            columnResizingMode="widget"
            #gridUc>
            <dxi-column *ngFor="let declinaison of declinaisonForGridList"
                        cellTemplate="poidsNetTemplate"
                        [width]="150"
                        [caption]="declinaison.libelle">
            </dxi-column>
            <div *dxTemplate="let cell of 'poidsNetTemplate'">
              <dx-number-box [min]="1" (onValueChanged)="changeNbUc($event, cell)" [value]="declinaisonsNbUCList[cell.columnIndex].nbUc"></dx-number-box>
            </div>
          </dx-data-grid>
        </div>
      </div>
      <div class="row">
        <div [class.container-table-full]="modelePlat?.mode !== 'PIECE_NON_PROPORTIONNELLE'" [class.container-table]="modelePlat?.mode === 'PIECE_NON_PROPORTIONNELLE'" class="col-lg-12">
          <dx-data-grid
            [dataSource]="gridParametrage"
            keyExpr="id"
            width="100%"
            height="100%"
            [allowColumnResizing]="true"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"
            columnResizingMode="widget"
            #grid>
            <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="130"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true" [fixed]="true" fixedPosition="left">
            </dxi-column>
            <dxi-column dataField="varianteLabel" caption="Variante" [width]="130"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true" [fixed]="true" fixedPosition="left">
            </dxi-column>
            <dxi-column *ngFor="let declinaison of declinaisonForGridList"
                        dataField="declinaison.id"
                        cellTemplate="declinaisonCellTemplate"
                        [width]="250"
                        [caption]="declinaison.libelle">
            </dxi-column>
            <dxi-column dataField="tache" caption="Type de conditionnement"
                        cellTemplate="tacheCellTemplate"
                        [width]="250"
                        [allowFiltering]="false">
            </dxi-column>
            <dxi-column dataField="remarque" caption="Commentaires"
                        cellTemplate="tacheCellCommentaires"
                        [width]="200"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>

            <dxo-summary>
              <dxi-total-item
                *ngFor="let declinaison of declinaisonForGridList"
                name="SelectedRowsSummary"
                showInColumn="declinaison">
              </dxi-total-item>
            </dxo-summary>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>

            <dxo-pager
              [showPageSizeSelector]="true"
              [showNavigationButtons]="false"
              [allowedPageSizes]="[20, 50, 100, 200]"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} conditionnements">
            </dxo-pager>

            <div *dxTemplate="let cell of 'declinaisonCellTemplate'">

              <div *ngIf="modelePlat?.mode === 'PIECE_PROPORTIONNELLE' || modelePlat?.mode === 'PIECE_NON_PROPORTIONNELLE' ">
                <span style="display: inline-block;width: 100px;">Effectif nourri :</span>
                <input style="width: 70px" min="0" type="number" [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri" />
              </div>

              <div *ngIf="modelePlat?.mode === 'PIECE_NON_PROPORTIONNELLE'">
                {{ declinaisonsNbUCList[cell.columnIndex - 2]?.nbUc * cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri }} <b>{{ modelePlat?.uniteAConditionner.abreviation }}</b> nourri(ssent) {{ cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri }} convive(s)
              </div>

              <div *ngIf="modelePlat?.mode === 'PESEE'">
                Poids net : <input min="0" style="width: 70px" type="number" [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].poidsNet" /> Kg(s)
              </div>

            </div>

            <div *dxTemplate="let cell of 'tacheCellTemplate'">
              <dx-select-box
                [dataSource]="tachesList"
                displayExpr="libelle"
                valueExpr="id"
                [(ngModel)]="cell.row.data.idtache"
                [value]="cell.row.data.idtache || tachesList[0].id">
              </dx-select-box>
            </div>

            <div *dxTemplate="let cell of 'tacheCellCommentaires'">
              <div><dx-text-area [(ngModel)]="cell.row.data.remarque"></dx-text-area></div>
            </div>

          </dx-data-grid>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 container-table-footer">
          <dx-data-grid
            [dataSource]="[footerList]"
            height="100px"
            width="100%"
            [hoverStateEnabled]="true"
            [allowColumnResizing]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"
            #grid>
            <dxi-column dataField="conditionnementLabel" caption="" [width]="130">
            </dxi-column>
            <dxi-column dataField="varianteLabel" caption="" [width]="130">
            </dxi-column>
            <dxi-column *ngFor="let declinaison of declinaisonForGridList"
                        cellTemplate="queueCellTemplate"
                        [width]="300"
                        [caption]="declinaison.libelle">
            </dxi-column>
            <dxi-column dataField="tache" caption="" [width]="250">
            </dxi-column>
            <dxi-column dataField="remarque" caption="" [width]="200">
            </dxi-column>

            <div *dxTemplate="let cell of 'queueCellTemplate'">
              <dx-select-box
                [dataSource]="resteList"
                displayExpr="libelle"
                valueExpr="id"
                (onValueChanged)="changeQueue($event, cell.columnIndex)"
                [value]="queuesConfiguration[cell.columnIndex] && queuesConfiguration[cell.columnIndex].reste ? queuesConfiguration[cell.columnIndex].reste.id : resteList[2].id">
              </dx-select-box>
            </div>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 5/13 : Association des conditionnements et variantes aux déclinaisons - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
