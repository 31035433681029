import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  FP_ROUTES,
  FS_ROUTES,
  HAS_GESTION_IDISTRI,
  HAS_GESTION_UDP,
  HAS_GESTION_UDP_ARTICLES_PREFERES,
  HAS_GESTION_UDP_DENREES_INTERDITES,
  HAS_GESTION_UDP_EQUIPES,
  HAS_GESTION_UDP_LIV,
  HAS_GESTION_UDP_STOCKS,
  OUTLET_SECONDARY,
  SECURITY
} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {GestionUproRootComponent} from './gestion-upro-root/gestion-upro-root.component';
import {UniteProductionResolverService} from './unite-production/unite-production-resolver.service';
import {UniteProductionComponent} from './unite-production/unite-production.component';
import {UpFicheidentiteComponent} from './unite-production/up-ficheidentite/up-ficheidentite.component';
import {UpLivraisonsComponent} from './unite-production/up-livraisons/up-livraisons.component';
import {UpZonesDeStockageComponent} from './unite-production/up-zones-de-stockage/up-zones-de-stockage.component';
import {UpArticlesPreferesComponent} from './unite-production/up-articles-preferes/up-articles-preferes.component';
import {GrilleArticlesPreferesComponent} from './unite-production/up-articles-preferes/grille-articles-preferes/grille-articles-preferes.component';
import {UpArticlesPreferesResolverService} from './unite-production/up-articles-preferes/up-articles-preferes-resolver.service';
import {UpDenreesInterditesComponent} from './unite-production/up-denrees-interdites/up-denrees-interdites.component';
import {GrilleDenreesInterditesComponent} from './unite-production/up-denrees-interdites/grille-denrees-interdites/grille-denrees-interdites.component';
import {DeactivateGrilleArticlesPreferesGuard} from './unite-production/up-articles-preferes/grille-articles-preferes/deactivate-grille-articles-preferes.guard';
import {UpEquipesComponent} from './unite-production/up-equipes/up-equipes.component';
import {UpParametrageResolverService} from './unite-production/up-equipes/parametrage/up-parametrage-resolver.service';
import {ParametrageComponent} from './unite-production/up-equipes/parametrage/parametrage.component';
import {PlanningFabricationsComponent} from './unite-production/up-equipes/planning-fabrications/planning-fabrications.component';
import {PlanningApprosComponent} from './unite-production/up-equipes/planning-appros/planning-appros.component';
import {PlanningSortiesComponent} from './unite-production/up-equipes/planning-sorties/planning-sorties.component';
import {PlanningConditionnementsComponent} from './unite-production/up-equipes/planning-conditionnements/planning-conditionnements.component';
import {PlanningApprovisionnementsResolverService} from './unite-production/up-equipes/planning-appros/planning-approvisionnements-resolver.service';
import {PlanningFabricationsResolverService} from './unite-production/up-equipes/planning-fabrications/planning-fabrications-resolver.service';
import {PlanningConditionnementsResolverService} from './unite-production/up-equipes/planning-conditionnements/planning-conditionnements-resolver.service';
import {PlanningSortiesResolverService} from './unite-production/up-equipes/planning-sorties/planning-sorties-resolver.service';
import {UpIncoherenceComponent} from "./unite-production/up-incoherence/up-incoherence.component";
import {UpIncoherenceResolverService} from "./unite-production/up-incoherence/up-incoherence-resolver.service";
import {UpConditionnementVarianteComponent} from "./unite-production/up-conditionnement/up-conditionnement-variante/up-conditionnement-variante.component";
import {UpConditionnnementResolverService} from "./unite-production/up-conditionnement/up-conditionnement-variante/up-conditionnnement-resolver.service";
import {UpProduitsDeclinaisonsNonApprovisionnesComponent} from "./unite-production/up-produits-declinaisons-non-approvisionnes/up-produits-declinaisons-non-approvisionnes";
import {UpConditionnementComponent} from "./unite-production/up-conditionnement/up-conditionnement.component";
import {UpModeleConditionnementPlatComponent} from "./unite-production/up-conditionnement/up-modele-conditionnement-plat/up-modele-conditionnement-plat.component";
import {UpModeleConditionnnementPlatResolverService} from "./unite-production/up-conditionnement/up-modele-conditionnement-plat/up-modele-conditionnement-plat-resolver.service";

const routes: Routes = [{
  path: FP_ROUTES.GUP,
  component: GestionUproRootComponent,
  canActivate: [Auth2Guard],
  canActivateChild: [Auth2Guard],
  data: {role: SECURITY.ROLES.ROLE_UNITE_DE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_UNITE_DE_PRODUCTION}
},
  {
    path: `${FS_ROUTES.GUP_UPRO}/:idUniteDeProduction`,
    component: UniteProductionComponent,
    data: HAS_GESTION_UDP,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    outlet: OUTLET_SECONDARY,
    resolve: {
      uniteDeProductionSupplier: UniteProductionResolverService
    },
    children: [
      {
        path: `ficheidentite`,
        component: UpFicheidentiteComponent,
        data: HAS_GESTION_UDP,
      },
      {
        path: `livraisons`,
        component: UpLivraisonsComponent,
        data: HAS_GESTION_UDP_LIV
      },
      {
        path: `zonesDeStockage`,
        component: UpZonesDeStockageComponent,
        data: HAS_GESTION_UDP_STOCKS,
      },
      {
        path: `denreesInterdites`,
        component: UpDenreesInterditesComponent,
        data: HAS_GESTION_UDP_DENREES_INTERDITES,
        children: [
          {
            path: `grille`,
            component: GrilleDenreesInterditesComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_GESTION_UDP_DENREES_INTERDITES,
          }
        ]
      }
      , {
        path: `articles-preferes`,
        component: UpArticlesPreferesComponent,
        data: HAS_GESTION_UDP_ARTICLES_PREFERES,
        runGuardsAndResolvers: 'always',
        resolve: {
          upArticlesPreferesSupplier: UpArticlesPreferesResolverService
        },
        children: [
          {
            path: `grille`,
            component: GrilleArticlesPreferesComponent,
            runGuardsAndResolvers: 'always',
            canDeactivate: [DeactivateGrilleArticlesPreferesGuard],
            data: HAS_GESTION_UDP_ARTICLES_PREFERES,
          }
        ]

      },
      {
        path: `equipes`,
        component: UpEquipesComponent,
        data: HAS_GESTION_UDP_EQUIPES,
        children: [
          {
            path: 'parametrage',
            component: ParametrageComponent,
            data: HAS_GESTION_UDP_EQUIPES,
            resolve: {
              upEquipesSupplier: UpParametrageResolverService
            },
          },
          {
            path: 'planning-fabrications',
            component: PlanningFabricationsComponent,
            data: HAS_GESTION_UDP_EQUIPES,
            resolve: {
              planningEquipesSupplier: PlanningFabricationsResolverService
            },
          },
          {
            path: 'planning-approvisionnements',
            component: PlanningApprosComponent,
            data: HAS_GESTION_UDP_EQUIPES,
            resolve: {
              planningEquipesSupplier: PlanningApprovisionnementsResolverService
            },
          },
          {
            path: 'planning-conditionnements',
            component: PlanningConditionnementsComponent,
            data: HAS_GESTION_UDP_EQUIPES,
            resolve: {
              planningEquipesSupplier: PlanningConditionnementsResolverService
            },
          },
          {
            path: 'planning-sorties',
            component: PlanningSortiesComponent,
            data: HAS_GESTION_UDP_EQUIPES,
            resolve: {
              planningEquipesSupplier: PlanningSortiesResolverService
            },
          }
        ]
      },
      {
        path: `incoherences`,
        component: UpIncoherenceComponent,
        data: HAS_GESTION_UDP,
        runGuardsAndResolvers: 'always',
        resolve: {
          upIncoherenceSupplier: UpIncoherenceResolverService
        }
      },
      {
        path: `conditionnement`,
        component: UpConditionnementComponent,
        data: HAS_GESTION_IDISTRI,
        children: [
          {
            path: 'conditionnements-variantes',
            component: UpConditionnementVarianteComponent,
            data: HAS_GESTION_IDISTRI,
            resolve: {
              upConditionnnementSupplier: UpConditionnnementResolverService
            },
          },
          {
            path: 'modeles-conditionnement-plat',
            component: UpModeleConditionnementPlatComponent,
            data: HAS_GESTION_IDISTRI,
            resolve: {
              upModeleConditionnnementPlatSupplier: UpModeleConditionnnementPlatResolverService
            },
          }]
      },
      {
        path: 'produits-declinaisons-non-approvisionnes',
        component: UpProduitsDeclinaisonsNonApprovisionnesComponent,
        data: HAS_GESTION_UDP
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionUnitesproductionRoutingModule {
}
