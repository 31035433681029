import {Injectable} from "@angular/core";
import {Tournee} from "../../dtos/tournee-dto";
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {HttpService} from "../technique/http.service";
import {Auth2Service} from "../security/auth2.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TourneesService {

  private subjectOpenDialog = new Subject<Tournee>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectAnnounceTourneeSaved = new Subject();
  tourneeSaved$ = this.subjectAnnounceTourneeSaved.asObservable();

  constructor(private httpSvc: HttpService,
              private http: HttpClient,
              private auth2Svc: Auth2Service) {
  }

  announceOpenDialogTournee = (tournee: Tournee): void => {
    this.subjectOpenDialog.next(tournee);
  }

  exportTourneesByFilters = (rowsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/tournees/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  importTourneesFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post(`dolrest/referentiel/tournees/import`, formData, null);
  };

  deleteTourneeByFilters = (rowsToRemove?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (rowsToRemove?.length) params += `&idsToDelete=${rowsToRemove.join(',')}`
    return this.httpSvc.delete(`dolrest/referentiel/tournees/delete${params}`);
  }

  canModify = (element: Tournee): boolean => {
    if (!element?.site?.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  save = (tourneeToSave: Tournee): Observable<ResponseWrapper<Tournee>> =>  this.httpSvc.post(`dolrest/referentiel/tournees/save`, tourneeToSave);

  announceRoundSaved = (): void => {
    this.subjectAnnounceTourneeSaved.next();
  }

}
