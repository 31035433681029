import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ProduitService} from "../../core/services/gestionproduits/produit.service";
import {Subject, Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {ProduitDeclinaisonDTO} from "../../core/dtos/produit-declinaison-dto";
import {debounceTime} from "rxjs/internal/operators";
import {RATIOS} from "../../core/constants";
import {DxNumberBoxComponent} from "devextreme-angular";
import {FormGroup} from "@angular/forms";
import {FormFieldBaseSupplier} from "../../core/suppliers/form-fieldbase-supplier";

@Component({
  selector: 'yo-dialog-check-ratios',
  templateUrl: './dialog-check-ratios.component.html',
  styleUrls: ['./dialog-check-ratios.component.scss']
})
export class DialogCheckRatiosComponent implements OnInit, OnDestroy {

  displayDialog: boolean = false;

  subCheckRatios$: Subscription;
  subRatioUS$: Subscription;
  subRatioUC$: Subscription;
  subRatioUF$: Subscription;
  subRatioUniversel$: Subscription;
  subRatioUCUF$: Subscription;

  subRatioUT$: Subscription;

  subRatioUSUT$: Subscription;

  @ViewChild("boxRatioUniversel") boxRatioUniversel: DxNumberBoxComponent;

  articles: any[] = [];
  produitsDeclines: ProduitDeclinaisonDTO[] = [];

  inversesRatiosUT: any = {};
  ratiosUcUf: any = {};

  ratiosUsUt: any = {};

  subjectRatioUniversel: Subject<any> = new Subject<any>();
  ratioUniversel$ = this.subjectRatioUniversel.asObservable();

  subjectRatioUT: Subject<any> = new Subject<any>();
  ratioUT$ = this.subjectRatioUT.asObservable();

  subjectRatioUS: Subject<any> = new Subject<any>();
  ratioUS$ = this.subjectRatioUS.asObservable();

  subjectRatioUF: Subject<any> = new Subject<any>();
  ratioUF$ = this.subjectRatioUF.asObservable();

  subjectRatioUC: Subject<any> = new Subject<any>();
  ratioUC$ = this.subjectRatioUC.asObservable();

  subjectRatioUCUF: Subject<any> = new Subject<any>();
  ratioUCUF$ = this.subjectRatioUCUF.asObservable();

  subjectRatioUSUT: Subject<any> = new Subject<any>();
  ratioUSUT$ = this.subjectRatioUSUT.asObservable();

  constructor(private produitSvc: ProduitService,
              private utilsSvc: UtilsService) {}

  closeDialog = (): void => {
    this.displayDialog = false;
    this.articles = [];
    this.produitsDeclines = [];
  }

  ngOnInit(): void {
    this.subscribeOpenDialog();
    this.subscribeChangeRatioUF();
    this.subscribeChangeRatioUC();
    this.subscribeChangeRatioUS();
    this.subscribeChangeRatioUT();
    this.subscribeChangeRatioUniversel();
    this.subscribeChangeRatioUCUF();
    this.subscribeChangeRatioUSUT();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subCheckRatios$);
    this.utilsSvc.unsubscribe(this.subRatioUS$);
    this.utilsSvc.unsubscribe(this.subRatioUC$);
    this.utilsSvc.unsubscribe(this.subRatioUF$);
    this.utilsSvc.unsubscribe(this.subRatioUniversel$);
    this.utilsSvc.unsubscribe(this.subRatioUT$);
    this.utilsSvc.unsubscribe(this.subRatioUCUF$);
    this.utilsSvc.unsubscribe(this.subRatioUSUT$);
  }

  onChangeRatioUcUf = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    // COMMANDE --------------------
    const formFacturation = form.parent.controls['formFacturation'];
    const ratioUfKg = formFacturation.controls['ratioUniteFacturationUniteBase'].value;
    const ratioUcUf = form.controls['ratioUniteCommandeUniteFacturation'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUfKg) && UtilsService.UTILS.isNumberGt0(ratioUcUf)) {

      const qte = ratioUfKg * ratioUcUf;
      form.controls['ratioUniteCommandeUniteBase'].setValue(qte);
    }
  };

  subscribeOpenDialog = (): void => {
    this.subCheckRatios$ = this.produitSvc
      .checkRatios$
      .subscribe((checkRatios) => {
        this.articles = checkRatios?.articles;
        this.articles.forEach(article => {
          this.ratiosUcUf[article.id] = this.produitSvc.calculationRatioUcUf(article.ratioUF, article.ratioUC);
          this.ratiosUsUt[article.id] = this.produitSvc.calculationRatioUsUt(article.ratioUT, article.ratioUS);
        });
        this.produitsDeclines = checkRatios?.produitsDeclines;
        this.produitsDeclines.forEach(pd => this.inversesRatiosUT[pd.id] = pd.ratioUniteTechnique ? (1 / pd.ratioUniteTechnique) : 0 );
        this.displayDialog = true;
      });
  }

  subscribeChangeRatioUF = (): void => {
    this.subRatioUF$ = this.ratioUF$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.produitSvc.updateRatio(args.cell.data.id, args.$event.value, RATIOS.RATIO_UF)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUC = (): void => {
    this.subRatioUC$ = this.ratioUC$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.ratiosUcUf[args.cell.data.id] = this.produitSvc.calculationRatioUcUf(args.cell.data.ratioUF, args.$event.value);
        this.produitSvc.updateRatio(args.cell.data.id, args.$event.value, RATIOS.RATIO_UC)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUCUF = (): void => {
    this.subRatioUCUF$ = this.ratioUCUF$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.ratiosUcUf[args.cell.data.id] = args.$event.value;
        args.cell.data.ratioUC = this.produitSvc.calculationRatioUc(args.$event.value, args.cell.data.ratioUF);
        this.produitSvc.updateRatio(args.cell.data.id, args.cell.data.ratioUC, RATIOS.RATIO_UC)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUS = (): void => {
    this.subRatioUS$ = this.ratioUS$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.ratiosUsUt[args.cell.data.id] = this.produitSvc.calculationRatioUsUt(args.cell.row.data.ratioUT, args.$event.value);
        this.produitSvc.updateRatio(args.cell.data.id, args.$event.value, RATIOS.RATIO_US)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUSUT = (): void => {
    this.subRatioUSUT$ = this.ratioUSUT$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.ratiosUsUt[args.cell.data.id] = args.$event.value;
        args.cell.data.ratioUS = this.produitSvc.calculationRatioUs(args.$event.value, args.cell.row.data.ratioUT);
        this.produitSvc.updateRatio(args.cell.data.id, args.cell.data.ratioUS, RATIOS.RATIO_US)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUniversel = (): void => {
    this.subRatioUniversel$ = this.ratioUniversel$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.inversesRatiosUT[args.cell.data.id] = args.$event.value;
        args.cell.data.ratioUniteTechnique = args.$event.value ? 1 / (args.$event.value) : 0;
        this.produitSvc.updateRatio(args.cell.data.id, args.cell.data.ratioUniteTechnique, RATIOS.RATIO_UT)
          .subscribe(() => {});
      });
  }

  subscribeChangeRatioUT = (): void => {
    this.subRatioUT$ = this.ratioUT$
      .pipe(debounceTime(300))
      .subscribe(args => {
        this.inversesRatiosUT[args.cell.data.id] = args.$event.value ? 1 / args.$event.value : 0;
        this.produitSvc.updateRatio(args.cell.data.id, args.$event.value, RATIOS.RATIO_UT)
          .subscribe(() => {});
      });
  }

  announceRatioUFChanged = (value: any): void => {
    this.subjectRatioUF.next(value);
  }

  announceRatioUCUFChanged = (value: any): void => {
    this.subjectRatioUCUF.next(value);
  }

  announceRatioUCChanged = (value: any): void => {
    this.subjectRatioUC.next(value);
  }

  announceRatioUSChanged = (value: any): void => {
    this.subjectRatioUS.next(value);
  }

  announceRatioUSUTChanged = (value: any): void => {
    this.subjectRatioUSUT.next(value);
  }

  announceRatioUniverselChanged = (value: any): void => {
    this.subjectRatioUniversel.next(value);
  }

  announceRatioUTChanged = (value: any): void => {
    this.subjectRatioUT.next(value);
  }

}
