import {Component, ViewChild} from '@angular/core';
import {AtelierSavedSupplier, AteliersService} from '../../core/services/entities/ateliers.service';
import {Subscription} from "rxjs";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {AtelierDTO} from "../../core/dtos/atelier-d-t-o";
import {saveAs} from "file-saver";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-atelier',
  templateUrl: './atelier.component.html',
  styleUrls: ['./atelier.component.scss']
})
export class AtelierComponent {
  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  subLog: Subscription;
  subAtelierSaved: Subscription;
  subAteliers: Subscription;

  logged: boolean = false;

  ateliers: AtelierDTO[] = [];

  selectedRows: number[] = [];
  errors: any[] = [];

  displayImportPopupErrors: boolean = false;
  displayDeletePopupErrors: boolean = false;

  pathHelpFile: string = HELP_FOLDERS.REFERENTIEL + '/ateliers/ateliers';

  constructor(private auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              private graphQlSvc: GraphQLService,
              public utilsSvc: UtilsService,
              public ateliersSvc: AteliersService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initLoggedSubscription();
    this.initAteliersSubscription();
    this.initAtelierSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subLog);
    this.utilsSvc.unsubscribe(this.subAtelierSaved);
    this.utilsSvc.unsubscribe(this.subAteliers);
  }

  initLoggedSubscription = (): void => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.logged = res);
  }

  initAteliersSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subAteliers = this.graphQlSvc.sendQuery(`
      {
        allAteliers(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        console.log(response.allAteliers);
        this.ateliers = response.allAteliers;
      });
  }

  initAtelierSavedSubscription = (): void => {
    this.subAtelierSaved = this.ateliersSvc.atelierSaved$
      .subscribe((response: AtelierSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.ateliers.findIndex(atelier => atelier.id === response.atelier.id);
          this.ateliers[idx] = response.atelier;
        } else {
          this.ateliers.push(response.atelier);
        }
      });
  };

  openDialogEdition = (atelier: AtelierDTO): void => {
    this.ateliersSvc.announceOpenDialogEdition(atelier);
  };

  calculateSiteValue = (atelier: AtelierDTO): string => {
    return atelier?.site?.libelle
  };

  exportXslx = () => {
    this.ateliersSvc.exportAtelierByFilters(this.selectedRows).subscribe(response => {
      const filename = `atelier-export-${new Date().getTime()}.xlsx`;
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.selectedRows = [];
      saveAs(blob, filename);
    });
  }

  importXslx = async ($event: any) => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.ateliersSvc.importAtelierFromXlsx(file)
        .subscribe(response => {
          const res: any = response.one;
          if (res.allElementsImported)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }
          for (const item of res.elementsImported) {
            let index = this.ateliers.findIndex(atelier => atelier.id == item.id);
            if (index >= 0)
              this.ateliers[index] = item;
            else
              this.ateliers.push(item)
          }

          if (this.errors != null && this.errors.length > 0)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
    this.displayDeletePopupErrors = false;
  }

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.ateliersSvc.deleteAtelierByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.ateliers.find(atelier => atelier.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `ATELIER_DELETION_COMPLETED_PLURAL` : `ATELIER_DELETION_COMPLETED_SINGULAR`, null));
        }
        this.ateliers = this.ateliers.filter(atelier => !idsToDeleteInTable.find(id => id === atelier.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;
}
