import {Component, OnInit} from "@angular/core";
import FraisDePortDTO from "../../../../../core/dtos/frais-de-port-dto";
import {Subscription} from "rxjs";
import {CommandesService} from "../../../../../core/services/gestion-commandes/commandes.service";

@Component({
  selector: 'yo-frais-de-port',
  templateUrl: './frais-de-port-proposition-commande.component.html',
  styleUrls: ['./frais-de-port-proposition-commande.component.scss']
})
export class FraisDePortPropositionCommandeComponent implements OnInit {

  displayDialog: boolean = false;
  subFraisDePort: Subscription;
  fraisDePort: FraisDePortDTO[];

  constructor(private commandesSvc: CommandesService) {
  }

  ngOnInit(): void {
    this.fraisDePortSubscription();
  }

  fraisDePortSubscription = () => {
    this.subFraisDePort = this.commandesSvc.fraisDePort$.subscribe((items: FraisDePortDTO[]) => {
      this.fraisDePort = items;
      this.displayDialog = true;
    })
  }

  closeDialog = () => this.displayDialog = false;
}
