import {Injectable} from "@angular/core";
import {Observable, Subject, of, throwError} from "rxjs";
import {InternationalizationService} from "../i8n/i8n.service";
import {
  GestionUniteProduction__ModeleConditionnementPlatService
} from "../gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import ModeleConditionnementPlatDTO from "../../dtos/conditionnement/modele-conditionnement-plat-dto";
import {SiteDTO} from "../../dtos/site-dto";
import {UniteDeMesureDTO} from "../../dtos/unitedemesure-dto";
import {ModelesPlatsService} from "./modeles-plats.service";
import {MSG_KEY, MSG_SEVERITY} from "../../constants";
import {ToastService} from "../technique/toast.service";
import ModeleConditionnementPlcDTO from "../../dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {ModelesPlcService} from "./plc/modeles-plc.service";
import {ClientsBusinessService} from "../gestion-clients/clients.service";
import {MZoneProduction} from "../../models/gestion-unites-production/m-zone-production";
import {MTache} from "../../models/gestion-unites-production/m-tache";
import {MEquipe} from "../../models/gestion-unites-production/m-equipe";
import {TachesService} from "../entities/taches.service";
import {UnitesDeProductionService} from "../entities/unites-de-production.service";

@Injectable({
  providedIn: 'root'
})
export class WizardConditionnementService {

  readonly STEP_INITIALIZATION: number = 0;

  readonly STEP_TARGET_MODEL_DISH: number = 1;

  readonly STEP_TYPO_CONDITIONNEMENT: number = 2;

  readonly STEP_CONFIG_REFERENTIEL: number = 3;

  readonly STEP_CONFIG_REFERENTIEL_COMBINAISONS: number = 4;

  readonly STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT: number = 5;

  readonly STEP_CHOICE_MEALS: number = 6;

  readonly STEP_CHECK_RECIPES: number = 7;

  readonly STEP_TARGET_MODEL_PLC: number = 8;

  readonly STEP_CONFIG_BINDINGS_GRID_MODELE_PLC: number = 9;

  readonly STEP_SELECT_PLCS: number = 10;

  readonly STEP_GROUPING_PLCS: number = 11;

  readonly STEP_CHECK_CREWS: number = 12;

  readonly END: number = 13;

  readonly EXIT: number = 1337;

  private subjectOpenDialogWizard = new Subject<any>();
  openDialogWizard$ = this.subjectOpenDialogWizard.asObservable();

  private subjectStep = new Subject<number>();
  subjectStep$ = this.subjectStep.asObservable();

  totalSteps: number;

  constructor(private readonly i8nSvc: InternationalizationService,
              private readonly udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService,
              private readonly mpcSvc: ModelesPlatsService,
              private readonly toastSvc: ToastService,
              private readonly mcPlcSvc: ModelesPlcService,
              private readonly clientsBusinessSvc: ClientsBusinessService,
              private readonly tachesService: TachesService,
              private readonly udpSvc: UnitesDeProductionService) {
    this.totalSteps = 13;
  }

  announceOpenDialogWizardConditionnement = () => {
    this.subjectOpenDialogWizard.next(null);
  };

  findPreviousStep = (currentStep: number): number => {
    switch (currentStep) {
      case this.STEP_TARGET_MODEL_DISH:
        return this.STEP_INITIALIZATION;
      case this.STEP_TYPO_CONDITIONNEMENT:
        return this.STEP_TARGET_MODEL_DISH;
      case this.STEP_CONFIG_REFERENTIEL:
        return this.STEP_TYPO_CONDITIONNEMENT;
      case this.STEP_CONFIG_REFERENTIEL_COMBINAISONS:
        return this.STEP_CONFIG_REFERENTIEL;
      case this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT:
        return this.STEP_CONFIG_REFERENTIEL_COMBINAISONS;
      case this.STEP_CHOICE_MEALS:
        return this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT;
      case this.STEP_CHECK_RECIPES:
        return this.STEP_CHOICE_MEALS;
      case this.STEP_TARGET_MODEL_PLC:
        return this.STEP_CHECK_RECIPES;
      case this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC:
        return this.STEP_TARGET_MODEL_PLC;
      case this.STEP_SELECT_PLCS:
        return this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC;
      case this.STEP_GROUPING_PLCS:
        return this.STEP_SELECT_PLCS;
      case this.STEP_CHECK_CREWS:
        return this.STEP_GROUPING_PLCS;
      default:
        return this.STEP_INITIALIZATION;
    }
  }

  findNextStep = (data: any): void => {
    switch (data.currentStep) {
      case this.STEP_INITIALIZATION:
        return this.findNextStepOfInitializationStep();
      case this.STEP_TARGET_MODEL_DISH:
        return this.findNextStepOfTargetModelDish(data);
      case this.STEP_TYPO_CONDITIONNEMENT:
        return this.findNextStepOfTypoConditionnement(data);
      case this.STEP_CONFIG_REFERENTIEL:
        return this.findNextStepMainReferentiel(data);
      case this.STEP_CONFIG_REFERENTIEL_COMBINAISONS:
        return this.findNextStepMixReferentiel(data);
      case this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT:
        return this.findNextStepConfigBindingGridModelePlat(data);
      case this.STEP_CHOICE_MEALS:
        return this.findNextStepChoiceMeals(data);
      case this.STEP_CHECK_RECIPES:
        return this.findNextStepCheckRecipes(data);
      case this.STEP_TARGET_MODEL_PLC:
        return this.findNextStepTargetModelPlc(data);
      case this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC:
        return this.findNextStepBindingGridModelePlc(data);
      case this.STEP_SELECT_PLCS:
        return this.findNextStepSelectPlcs(data);
      case this.STEP_GROUPING_PLCS:
        return this.findNextStepGroupingPlcs(data);
      case this.STEP_CHECK_CREWS:
        return this.findNextStepCheckCrews(data);
      case this.END:
        return this.finalStep(data);
      default:
        alert("Opération inconnue");
    }
  }

  private findNextStepOfInitializationStep = (): void => {
    this.subjectStep.next(this.STEP_TARGET_MODEL_DISH);
  }

  private findNextStepOfTargetModelDish = (data: any): void => {
    try {
      this.saveStepOne(data);
      this.subjectStep.next(this.STEP_TYPO_CONDITIONNEMENT);
    } catch (errors) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, errors.join(","));
    }
  }

  private findNextStepOfTypoConditionnement = (data: any): void => {
    try {
      const dataStepOne: any = this.getDataStepOne();
      const modelePlat: ModeleConditionnementPlatDTO = {
        id: data.id,
        site: { id: dataStepOne.idSelectedSite } as SiteDTO,
        libelle: data.labelModelPlat,
        code: undefined,
        mode: data.modeModelePlatSelected,
        uniteAConditionner: { id: data.idUniteMesureSelected } as UniteDeMesureDTO
      } as ModeleConditionnementPlatDTO;
      this.mpcSvc.save(modelePlat).subscribe(response => {
        const mpc: ModeleConditionnementPlatDTO = response.one;
        data.id = mpc.id;
        this.saveStepTwo(data, dataStepOne.unitesDeProductionSelected)
          .subscribe((response) => {
            this.subjectStep.next(this.STEP_CONFIG_REFERENTIEL);
          });
      });
    } catch (errors) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, errors.join(","));
    }
  }

  private findNextStepMainReferentiel = (data: any): void => {
    if (!data.conditionnementsLength || !data.variantesLength)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_CONDITIONNEMENT_VARIANTE_MANDATORY", null));
    else
      this.subjectStep.next(this.STEP_CONFIG_REFERENTIEL_COMBINAISONS);
  }

  private findNextStepMixReferentiel = (data: any): void => {
    if (!data.conditionnementsVariantes || !data.conditionnementsVariantes.length)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode(`WIZARD_MIX_CONDITIONNEMENT_VARIANTE_MANDATORY`, null));
    else
      this.subjectStep.next(this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT);
  }

  private findNextStepConfigBindingGridModelePlat = (data: any): void => {
    if (!data.modelePlatParametrage || !data.modelePlatParametrage.grid.length || !data.modelePlatParametrage.queues.length)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode(`WIZARD_BIND_CONDITIONNEMENT_VARIANTE_TO_DECLINAISONS`, null));
    else {
      this.saveDataStepFive(data.declinaisonsSelected);
      this.mpcSvc.saveNombresUnitesAServir(data.modelePlatParametrage.idMcp, data.nombreUnitesAServir)
        .subscribe(() => {
          this.mpcSvc.saveParametrage(data.modelePlatParametrage)
            .subscribe(() => {
              this.subjectStep.next(this.STEP_CHOICE_MEALS);
            });
        });
    }
  }

  private findNextStepChoiceMeals = (data: any): void => {
    if (!data.idsProducts.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, this.i8nSvc.getLabelFromCode(`WIZARD_MEAL_MANDATORY`, null));
    } else {
      this.subjectStep.next(this.STEP_CHECK_RECIPES);
    }
  }

  private findNextStepCheckRecipes = (data: any): void => {
    if (data.containsValuesWithZero)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, this.i8nSvc.getLabelFromCode(`WIZARD_RECIPES_MANDATORY`, null));
    else
      this.subjectStep.next(this.STEP_TARGET_MODEL_PLC);
  }

  private findNextStepTargetModelPlc = (data: any): void => {
    try {
      const modelePlc: ModeleConditionnementPlcDTO = {
        id: data.id,
        site: {id: data.idSelectedSite} as SiteDTO,
        libelle: data.labelModelPlc,
        code: undefined
      } as ModeleConditionnementPlcDTO;
      this.mcPlcSvc.save(modelePlc).subscribe(response => {
        const mpc: ModeleConditionnementPlcDTO = response.one;
        data.id = mpc.id;
        this.saveDataStepEight(data)
        this.subjectStep.next(this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC);
      });
      if (data.idsPointsLivraisons?.length && data.keysPointsLivraisons?.length)
        this.saveDataStepTen(data.idsPointsLivraisons, data.keysPointsLivraisons);
    } catch (errors) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, errors.join(","));
    }
  }

  private findNextStepBindingGridModelePlc = (data: any): void => {
    if (!data.model || !data.model.grid.length)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_BIND_CONDITIONNEMENT_VARIANTE_TO_PRESTATIONS", null));
    else {
      this.mcPlcSvc.saveParametrage(data.model)
        .subscribe(response => {
          this.saveDataStepNine(data.prestationsSelected);
          this.subjectStep.next(this.STEP_SELECT_PLCS);
        });
    }
  }

  private findNextStepSelectPlcs = (data: any): void => {
    if (!data.pointsLivraisonsIds || !data.pointsLivraisonsIds.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_PLC_MANDATORY", null));
    } else {
      const dataStepEight: any = this.getDataStepEight();
      this.clientsBusinessSvc.saveMultiplePlc(dataStepEight.id, data.pointsLivraisonsIds)
        .subscribe(response => {
          this.saveDataStepTen(data.pointsLivraisonsIds, data.pointsLivraisonsKeys);
          this.subjectStep.next(this.STEP_GROUPING_PLCS);
        });
    }
  }

  private findNextStepGroupingPlcs = (data: any): void => {
    if (!data.rulesByPlcs) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_RULES_MANDATORY", null));
      return;
    }
    else if (data.rulesByPlcs) {
      let nextStep: boolean = true;
      Object.keys(data.rulesByPlcs).forEach((key) => {
        if(!data.rulesByPlcs[key] || !data.rulesByPlcs[key].length) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_RULES_MANDATORY", null));
          nextStep = false;
          return;
        }
      });
      if (nextStep) this.subjectStep.next(this.STEP_CHECK_CREWS);
    }
  }

  private findNextStepCheckCrews = (data: any): void => {
    if(data.valuesByTache && data.modeSelected === 'AUTO') {
      let nextStep: boolean = true;
      Object.keys(data.valuesByTache).forEach((key) => {
        if (!data.valuesByTache[key] || !data.valuesByTache[key].idEquipe || !data.valuesByTache[key].idAtelier) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_CREW_FACTORY_MANDATORY", null));
          nextStep = false;
          return;
        }
      });
      if (nextStep) {
        this.udpSvc.fetchIdsByMcPlc(data.idMcPlc)
          .subscribe((res) => {
            const idsUdp = res.resultList;
            let ateliers: MZoneProduction[] = [];
            idsUdp.forEach(udpId => {
              Object.keys(data.valuesByTache).forEach((key) => {
                let taches: MTache[] = [{id: parseInt(key)} as MTache];
                let equipes: MEquipe[] = [{id: data.valuesByTache[key].idEquipe, tachesList: taches} as MEquipe];
                let atelier: MZoneProduction = {
                  id: data.valuesByTache[key].idAtelier,
                  udpId,
                  equipesList: equipes
                } as MZoneProduction;
                ateliers.push(atelier);
              });
            });
            this.tachesService.saveAssociations(ateliers)
              .subscribe((res) => {
                this.saveDataStepTwelve('Automatique');
                this.subjectStep.next(this.END);
              });
          });
      }
    } else if (data.modeSelected === 'MANUAL') {
      this.saveDataStepTwelve('Manuelle');
      this.subjectStep.next(this.END);
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode("WIZARD_CREW_FACTORY_MANDATORY", null));
    }
  }

  private finalStep = (data: any): void => {
    this.subjectStep.next(this.EXIT);
  }

  saveStepOne = (dataStepOne: any): void => {
    const errors: string[] = [];
    if(!dataStepOne.idSelectedSite)
      errors.push(this.i8nSvc.getLabelFromCode('SITE_MANDATORY', null));
    if(!dataStepOne.unitesDeProductionSelected || !dataStepOne.unitesDeProductionSelected.length)
      errors.push(this.i8nSvc.getLabelFromCode('UDPS_MUST_BE_SELECTED', null));
    if (errors.length) throw errors;
    localStorage.setItem('idSiteForModelePlat', `${dataStepOne.idSelectedSite}`);
    localStorage.setItem('idsUdpForModelePlat', `${dataStepOne.unitesDeProductionSelected}`);
  }

  getDataStepOne = (): any => {
    const idSelectedSite: number = localStorage.getItem('idSiteForModelePlat') ? parseInt(localStorage.getItem('idSiteForModelePlat')) : undefined;
    const unitesDeProductionSelected: number[] = localStorage.getItem('idsUdpForModelePlat') ? localStorage.getItem('idsUdpForModelePlat').split(",").map(id => parseInt(id)) : [];
    return { idSelectedSite, unitesDeProductionSelected };
  }

  saveStepTwo = (dataStepTwo: any, unitesDeProductionSelected: number[]): Observable<any> => {
    const errors: string[] = [];
    if(!dataStepTwo.modeModelePlatSelected)
      errors.push(this.i8nSvc.getLabelFromCode('MODE_MCP_MANDATORY', null));
    if(!dataStepTwo.idUniteMesureSelected)
      errors.push(this.i8nSvc.getLabelFromCode('UNITE_CONDITIONNEMENT_MANDATORY', null));
    if(!dataStepTwo.labelModelPlat)
      errors.push(this.i8nSvc.getLabelFromCode('LABEL_MANDATORY', null));
    if (errors.length) throw errors;
    localStorage.setItem('modeModelePlat', `${dataStepTwo.modeModelePlatSelected}`);
    localStorage.setItem('idUniteAConditionner', `${dataStepTwo.idUniteMesureSelected}`);
    localStorage.setItem('labelModelPlat', `${dataStepTwo.labelModelPlat}`);
    localStorage.setItem('idModelPlat', `${dataStepTwo.id}`);
    return this.udpMcpSvc.save(dataStepTwo.id, unitesDeProductionSelected);
  }

  getDataStepTwo = (): any => {
    const modeModelePlatSelected: string = localStorage.getItem('modeModelePlat');
    const idUniteMesureSelected: number = localStorage.getItem('idUniteAConditionner') ? parseInt(localStorage.getItem('idUniteAConditionner')) : undefined;
    const labelModelPlat: string = localStorage.getItem('labelModelPlat');
    const id: number = localStorage.getItem('idModelPlat') ? parseInt(localStorage.getItem('idModelPlat')) : undefined;
    return { id, modeModelePlatSelected, idUniteMesureSelected, labelModelPlat };
  }

  saveDataStepFive = (declinaisonsSelected): void => {
    localStorage.setItem("declinaisonsSelected", declinaisonsSelected);
  }

  getDataStepFive = (): any => {
    const declinaisonsSelected: number[] = localStorage.getItem("declinaisonsSelected")
      ? localStorage.getItem("declinaisonsSelected").split(",").map(id => parseInt(id))
      : [];
    return { declinaisonsSelected };
  }

  saveStepSix = (dataStepSix: any): any => {
    localStorage.setItem("idsProducts", dataStepSix.idsProducts);
  }

  getDataStepSix = (): any => {
     const idsProducts: number[] = localStorage.getItem("idsProducts")
       ? localStorage.getItem("idsProducts").split(",").map(id => parseInt(id))
       : [];
     return { idsProducts };
   }

  saveDataStepEight = (dataStepEight: any): void => {
    const errors: string[] = [];
    if(!dataStepEight.idSelectedSite)
      errors.push(this.i8nSvc.getLabelFromCode('SITE_MANDATORY', null));
    if(!dataStepEight.labelModelPlc)
      errors.push(this.i8nSvc.getLabelFromCode('LABEL_MANDATORY', null));
    if (errors.length) throw errors;
    localStorage.setItem('idSiteForModelePlc', `${dataStepEight.idSelectedSite}`);
    localStorage.setItem('labelModelPlc', `${dataStepEight.labelModelPlc}`);
    localStorage.setItem('idModelPlc', `${dataStepEight.id}`);
  }

  getDataStepEight = (): any => {
    const idSelectedSite: number = localStorage.getItem('idSiteForModelePlc') ? parseInt(localStorage.getItem('idSiteForModelePlc')) : undefined;
    const labelModelPlc: string = localStorage.getItem('labelModelPlc');
    const id: number = localStorage.getItem('idModelPlc') ? parseInt(localStorage.getItem('idModelPlc')) : undefined;
    return { id, idSelectedSite, labelModelPlc };
  }


  saveDataStepNine = (prestationsSelected: any): void => {
    localStorage.setItem("prestationsSelected", prestationsSelected);
  }

  getDataStepTwelve = (): string => localStorage.getItem("configuration");

  saveDataStepTwelve = (configuration: string): void => {
    localStorage.setItem("configuration", configuration);
  }

  getDataStepNine = (): any => {
    const prestationsSelected: number[] = localStorage.getItem("prestationsSelected")
      ? localStorage.getItem("prestationsSelected").split(",").map(id => parseInt(id))
      : [];
    return { prestationsSelected };
  }

  saveDataStepTen = (pointsLivraisonsSelected: any, pointsLivraisonsKeys: any): void => {
    localStorage.setItem("pointsLivraisonsSelected", pointsLivraisonsSelected);
    localStorage.setItem("pointsLivraisonsKeys", pointsLivraisonsKeys);
  }

  getDataStepTen = (): any => {
    const pointsLivraisonsSelected: number[] = localStorage.getItem("pointsLivraisonsSelected")
      ? localStorage.getItem("pointsLivraisonsSelected").split(",").map(id => parseInt(id))
      : [];
    const pointsLivraisonsKeys: string[] = localStorage.getItem("pointsLivraisonsKeys")
      ? localStorage.getItem("pointsLivraisonsKeys").split(",")
      : [];
    return { pointsLivraisonsSelected, pointsLivraisonsKeys };
  }

  clearPartPlc = (): void => {
    localStorage.removeItem('idSiteForModelePlc');
    localStorage.removeItem('labelModelPlc');
    localStorage.removeItem('idModelPlc');
    localStorage.removeItem('prestationsSelected');
    localStorage.removeItem("pointsLivraisonsSelected");
    localStorage.removeItem("pointsLivraisonsKeys");
    localStorage.removeItem("configuration");
  }

  clear = (): void => {
    localStorage.removeItem('idSiteForModelePlat');
    localStorage.removeItem('idsUdpForModelePlat');
    localStorage.removeItem('modeModelePlat');
    localStorage.removeItem('idUniteAConditionner');
    localStorage.removeItem('labelModelPlat');
    localStorage.removeItem('idModelPlat');
    localStorage.removeItem("idsProducts");
    localStorage.removeItem("declinaisonsSelected");

    this.clearPartPlc();
  }

}
