import {Injectable} from "@angular/core";
import {HttpService} from "../../../../technique/http.service";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../../../../suppliers/wrappers/response-wrapper";
import McPlc__McpCvDTO from "../../../../../dtos/conditionnement/plc/conditionnement/plat/mc-plc-mc-cv-dto";

export const URL_FIND_ALL_BY_ID_MCPLC = `dolrest/conditionnements/modeles-conditionnements-plc/mcplcCv/findByIdMcPlc`;

@Injectable({
  providedIn: 'root'
})
export default class McPlcMcpCVService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des points de livraison <-> prestations
   * en fonction de l'identifiant du modèle de conditionnement PLC
   * @returns {Observable<McPlcPrestation__McPlc__McpCvDTO>}
   */
  getAllByIdMcplc = (idMcplc: number): Observable<ResponseWrapper<McPlc__McpCvDTO>> => this.httpSvc.get(`${URL_FIND_ALL_BY_ID_MCPLC}/${idMcplc}`, null);

}
