import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {CommandesService} from "../../../../core/services/gestion-commandes/commandes.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {BoncfDetailService} from "../../../../core/services/entities/boncf-detail.service";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {BonCfDTO} from "../../../../core/dtos/boncfs-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-bc-edit-delivery-address',
  templateUrl: './bc-edit-delivery-address.component.html',
  styleUrls: ['./bc-edit-delivery-address.component.scss'],
})
export class BcEditDeliveryAddressComponent implements OnInit, OnDestroy {

  @Input("displayDialog") displayDialog: boolean;
  @Input("bonCf") bonCf: BonCfDTO;
  @Output("onClose") onClose = new EventEmitter<any>();

  displayDialogConfirmation: boolean = false;
  pointsDeLivraison: PointDeLivraisonDTO[] = [];
  plcSelected: PointDeLivraisonDTO;

  filterValue: boolean = true;

  constructor(public utils: UtilsService,
              public commandesSvc: CommandesService,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              public bonCfDetailSvc: BoncfDetailService,
              private plcSvc: PointDeLivraisonService,
              private toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initData();
  }

  initData = () => {
    this.plcSvc.getPlcList()
      .subscribe(response => this.pointsDeLivraison = response.resultList);
  }

  closeDialog = (): void => {
    this.filterValue = true;
    this.displayDialog = false;
    this.onClose.emit({displayDialog: this.displayDialog, bonCf: this.bonCf});
  }

  onSelect = ($event: any) => {
    this.plcSelected = $event.data;
    this.displayDialogConfirmation = true;
  }

  closeDialogConfirmation = () => {
    this.plcSelected = null;
    this.displayDialogConfirmation = false;
  }

  updateDeliveryAddress = () => {
    this.commandesSvc.updateDeliveryAddressFromBonCf(this.bonCf, this.plcSelected)
      .subscribe((response) => {
        if(!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one))
          this.bonCf = response.one;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Nouvelle adresse de livraison enregistrée avec succès !');
        this.closeDialogConfirmation();
        this.closeDialog();
      })
  };
}
