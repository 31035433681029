import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {BonCfDTO} from "../../../core/dtos/boncfs-dto";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {UniteDeProduction__SecteurFournisseurDTO} from "../../../core/dtos/unite-de-production__secteur-fournisseur-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BoncfService} from "../../../core/services/entities/boncf.service";
import {DxCalendarComponent} from "devextreme-angular";

@Component({
  selector: 'yo-duplication-boncf',
  templateUrl: './duplication-bon-cf.component.html',
  styleUrls: ['./duplication-bon-cf.component.scss']
})
export class DuplicationBonCfComponent implements OnInit, OnChanges {

  @Input() displayDialog: boolean;
  @Input() selectedBonCfToDuplicate: BonCfDTO;
  @Input() uniteDeProduction: UniteDeProductionDTO;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<boolean>();

  form: FormGroup;
  idFournisseur: number;

  udpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO;

  joursSemaineFournisseur: number[] = [];
  udpJoursLivraison: number[] = [];


  delaiLivraison = 0;

  heureLimite = new Date();

  // Date minimale pour le jour de livraison
  minDate: Date = new Date();

  cellTemplate = 'custom';

  @ViewChild('calendar') calendar: DxCalendarComponent;
  displayCalendar: boolean = false;

  constructor(private udpSvc: UnitesDeProductionService,
              private utils: UtilsService,
              public bonCfSvc: BoncfService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSecteursFournisseurs();
  }

  initData = () => {
    this.initForm();
    this.getSecteursFournisseurs();
  }

  initForm = () => {
    this.form = new FormGroup({
      dateLivraison: new FormControl(new Date(), [Validators.required])
    });
  }

  duplicateBonCf = () => {
    this.displayCalendar = false;
    this.displayDialog = false;
    this.onSave.emit(this.form.value.dateLivraison);
  }

  closeDialog = () => {
    this.delaiLivraison = 0;
    this.heureLimite = new Date();
    this.joursSemaineFournisseur = [];
    this.udpJoursLivraison = [];
    this.displayCalendar = false;
    this.displayDialog = false;
    this.onClose.emit(this.displayDialog);
  };

  /**
   * Récupérer les secteurs fournisseurs de l'unite de production
   * Récupere les jours de livraison de l'unite de production
   */
  getSecteursFournisseurs = () => {
    if(!this.utils.isNullOrEmpty(this.selectedBonCfToDuplicate) && !this.utils.isNullOrEmpty(this.uniteDeProduction)) {
      this.udpJoursLivraison = this.udpSvc.getJoursLivraisonOfUdp(this.uniteDeProduction);
      this.idFournisseur = this.selectedBonCfToDuplicate.fournisseurId;

      this.udpSvc.getSecteursFournisseurs(this.uniteDeProduction).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.udpSecteurFournisseur = response.resultList
            .find(udpSecteur => udpSecteur.fournisseurId === this.idFournisseur && udpSecteur.idUniteDeProduction === this.uniteDeProduction.id);
          this.getJoursLivraisons();
        }
      })
    }
  };

  getJoursLivraisons = () => {
    this.joursSemaineFournisseur = [];
    this.delaiLivraison = 0;
    this.heureLimite = new Date();

    if (this.udpSecteurFournisseur) {
      this.delaiLivraison = this.udpSecteurFournisseur.delaiLivraisonJours;
      this.heureLimite = this.utils.convertNumberDateToDate(this.udpSecteurFournisseur.delaiLivraisonHeureLimite);

      this.udpSvc.getLivraisons(this.udpSecteurFournisseur).subscribe(response => {
        let days = response.resultList;
        if (!days) days = [];
        this.joursSemaineFournisseur = days.map(liv => liv.numeroJourSemaine);
        this.form.controls['dateLivraison'].setValue(null);

        this.displayCalendar = true;
        this.calendar.instance.repaint();
        this.cd.markForCheck();
      });
    }
  };

  isDateFournisseur = (date: Date): boolean => this.bonCfSvc.isDateFournisseur({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() },
    this.joursSemaineFournisseur, this.udpJoursLivraison, this.delaiLivraison, this.heureLimite);

  getCellCssClass = ($event: any) : string => this.isDateFournisseur($event.date) ? 'creation-menus-day' : '';

  disabledDates = ($event: any) => !this.isDateFournisseur($event.date);

}
