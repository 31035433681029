import {Injectable} from '@angular/core';
import {ModeleNutritionnelDTO} from '../../core/dtos/modele-nutritionnel-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProductionService} from '../../core/services/gestion-production/production.service';
import {combineLatest, Observable} from 'rxjs';
import {ModelesNutritionnelsService} from '../../core/services/entities/modeles-nutritionnels.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';

@Injectable({
  providedIn: 'root'
})
export class PlanAlimentaireResolverService implements Resolve<PlanAlimentaireSupplier> {

  constructor(private prodSvc: ProductionService,
              private modeleNutriSvc: ModelesNutritionnelsService,
              private gds: GenericDatagridService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlanAlimentaireSupplier> | Promise<PlanAlimentaireSupplier> | PlanAlimentaireSupplier => {
    const pa = new PlanAlimentaireSupplier();
    const idModeleNutri: number = +route.paramMap.get('idModeleNutri');

    if (idModeleNutri === 0) {
      pa.planAlimentaire = this.modeleNutriSvc.createEmptyDTO();
      return pa;
    } else {
      const modeleNutri$ = this.gds.getOne(this.modeleNutriSvc.getEntityName(), idModeleNutri);
      const all$ = combineLatest([modeleNutri$]);

      return all$.toPromise().then(response => {
        pa.planAlimentaire = response[0].one;
        return pa;
      });
    }
  };
}

export class PlanAlimentaireSupplier {
  planAlimentaire: ModeleNutritionnelDTO;
}
