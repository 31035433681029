import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'yo-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnInit {

  @Input() control:FormControl;
  @Input() label:string;

  constructor() { }

  ngOnInit() {
  }

  startError(label:string):string{
    return  `Le champ "${label.toUpperCase()}" `;
  }

}
