import {EventEmitter} from '@angular/core';
import {Link} from './link';
import {Node} from './node';
import {
  forceCenter as d3ForceCenter,
  forceCollide as d3ForceCollide,
  forceLink as d3ForceLink,
  forceManyBody as d3ForceManyBody,
  forceSimulation as d3ForceSimulation,
  Simulation as d3Simulation,
} from 'd3-force';

const FORCES = {
  LINKS: 1/40 ,
  COLLISION: 3,
  CHARGE: -9
}


export class ForceDirectedGraph {
  public ticker: EventEmitter<d3Simulation<Node, Link>> = new EventEmitter();
  public simulation: d3Simulation<any, any>;

  public nodes: Node[] = [];
  public links: Link[] = [];

  constructor(nodes, links, options: { width, height }) {
    this.nodes = nodes;
    this.links = links;

    this.initSimulation(options);
  }

  connectNodes(source, target) {
    let link;

    if (!this.nodes[source] || !this.nodes[target]) {
      throw new Error('One of the nodes does not exist');
    }

    link = new Link(source, target);
    this.simulation.stop();
    this.links.push(link);
    this.simulation.alphaTarget(0.3).restart();

    this.initLinks();
  }

  initNodes() {
    if (!this.simulation) {
      throw new Error('simulation was not initialized yet');
    }

    this.simulation.nodes(this.nodes);
  }

  initLinks() {
    if (!this.simulation) {
      throw new Error('simulation was not initialized yet');
    }

    this.simulation.force('links',
      d3ForceLink(this.links)
        .id(d => d['id'])
        .strength(FORCES.LINKS)

    );
  }

  initSimulation(options) {
    if (!options || !options.width || !options.height) {
      throw new Error('missing options when initializing simulation');
    }

    /** Creating the simulation */
    if (!this.simulation) {
      const ticker = this.ticker;

      this.simulation = d3ForceSimulation()
        .force('charge',
          d3ForceManyBody()
            .strength(d => FORCES.CHARGE * d['r'])
        )
        .force('collide',
          d3ForceCollide()
            .strength(FORCES.COLLISION)
            .radius(d => d['r'] + 5).iterations(2)
        );

      // Connecting the d3 ticker to an angular event emitter
      this.simulation.on('tick', function () {
        ticker.emit(this);
      });

      this.initNodes();
      this.initLinks();
    }

    /** Updating the central force of the simulation */
    this.simulation.force('centers', d3ForceCenter(options.width / 2, options.height / 2));

    /** Restarting the simulation internal timer */
    this.simulation.restart();
  }
}
