import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {FrontStorageService} from '../../core/services/storage/front-storage.service';
import {combineLatest, Subscription} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {PlanningMenusDTO} from '../../core/dtos/gestionmenus/planning/planning-menus-dto';
import {LigneTableauDTO} from '../../core/dtos/gestionmenus/planning/ligne-tableau-dto';
import {LigneGroupeTableauDTO} from '../../core/dtos/gestionmenus/planning/ligne-groupe-tableau-dto';
import * as moment from 'moment';
import {MenusDragndropService} from '../../core/services/gestionmenus/menus-dragndrop.service';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {RepasDTO} from '../../core/dtos/repas-dto';
import {CelluleTableauDTO} from '../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {MenuCompositionDTO} from '../../core/dtos/menucomposition-dto';
import {MENUS_TYPES_LIGNES, MSG_KEY, MSG_SEVERITY, USER_PREFERENCE, USER_TYPE_PREFERENCE} from '../../core/constants';
import {cloneDeep as _cloneDeep, isEqual as _isEqual, sortBy as _sortBy, uniqWith as _uniqWith} from 'lodash'
import {MenuItem, TreeNode} from 'primeng/api';
import {Table} from 'primeng/table';
import {animate, style, transition, trigger} from '@angular/animations';
import {MenusCompositionService} from '../../core/services/gestionmenus/menus-composition.service';
import {ProduitDeclinaisonService} from '../../core/services/entities/produit-declinaison.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {CustomIntersectionObserver} from '../../core/services/technique/observer.service';
import {SizeTableauDTO} from '../../core/dtos/gestionmenus/planning/size-tableau-dto';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Menu} from "primeng/menu";
import {RoutemapService} from "../../core/services/routemap.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-menus-planning2',
  templateUrl: './menus-planning2.component.html',
  styleUrls: ['./menus-planning2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('triggerPeriode', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.3s', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('0.3s', style({opacity: 0}))
      ])
    ])
  ]
})
export class MenusPlanning2Component implements OnInit, OnDestroy {

  @ViewChild('table') table: Table;
  @ViewChild('paneHScroll') paneHScroll: ElementRef;
  @ViewChild('paneVScroll') paneVScroll: ElementRef;
  @ViewChild('opConfigMenu') opConfigMenu: OverlayPanel;
  @ViewChild('menuDate') menuDate: Menu;

  readonly TYPES_LIGNES = MENUS_TYPES_LIGNES;

  //Observables
  startDateMenu$ = this.menusToolbarSvc.startDateMenu$;
  prestations$ = this.menusToolbarSvc.prestations$;
  preferencesOfUser$ = this.prefUserSvc.preferencesOfUser$;
  regime$ = this.menusToolbarSvc.regime$;
  changeSettings$ = this.mp2Svc.changeSettings$;

  // elements du planning
  loadingTable: boolean = false;
  rows: LigneTableauDTO[] = [];
  rowGroups: Map<number, LigneGroupeTableauDTO> = new Map();

  // souscription selection des menus
  subSelectionMenu: Subscription;
  subProduitsDeclinaisonsFromSearch: Subscription;
  subMoveMenuCompo: Subscription;
  subReglageDecoupageRepas:Subscription;

  subDisplayProfil: Subscription;
  subRoutingSidebar :Subscription;

  vPaneWidth = 0;

  //OverlayPanel Config Repas
  isFirstTime = true;
  decoupageRepasNode: TreeNode[] = [];
  selectedDecoupageRepasNode: TreeNode[] = [];

  siteId: any;

  itemsMenuDate: MenuItem[] = [];

  repasObserver: CustomIntersectionObserver;

  constructor(
    private menusToolbarSvc: MenusToolbarService,
    public mp2Svc: MenusPlanning2Service,
    private routeMapSvc: RoutemapService,
    private menuDndSvc: MenusDragndropService,
    private frontStorageSvc: FrontStorageService,
    private prefUserSvc: PreferencesUtilisateurService,
    private mcSvc: MenusCompositionService,
    private pdSvc: ProduitDeclinaisonService,
    public utils: UtilsService,
    private cd: ChangeDetectorRef,
    private auth2Svc: Auth2Service,
    private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initData();
    this.subscriptionRoutingSidebar();
    this.subscriptionMoveMenuCompo();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSelectionMenu);
    this.utils.unsubscribe(this.subMoveMenuCompo);
    this.utils.unsubscribe(this.subDisplayProfil);
    this.utils.unsubscribe(this.subProduitsDeclinaisonsFromSearch);
    this.utils.unsubscribe(this.subReglageDecoupageRepas);
    this.utils.unsubscribe(this.subRoutingSidebar);
  }

  subscriptionMoveMenuCompo = (): void => {
    this.subMoveMenuCompo = this.menuDndSvc.moveMenuCompo$.subscribe(dropMenu => {
      this.cd.markForCheck();
    });
  };

  subscriptionRoutingSidebar = () => {
    this.subRoutingSidebar = this.mcSvc.routingSidebar$.subscribe( supplier =>{
      if (supplier.fabrique) {
        this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', supplier.fabrique, supplier.idProduit, 'fichetechnique-preselection', supplier.idSiteContratMenu]);
      } else {
        this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', supplier.fabrique, supplier.idProduit, 'produit-declinaisons']);
      }
    });
  };


  initData = () => {
    //on observe les  champs : date, contrats-convives / regime / preferences
    const all$ = combineLatest([this.startDateMenu$, this.prestations$, this.regime$, this.changeSettings$]);

    let contratId = undefined;
    let prestationId = undefined;
    let regimeId = undefined;
    let date = undefined;

    this.subSelectionMenu = all$.pipe(
      tap(data => {
        this.loadingTable = true;
        this.mp2Svc.planningMenus = null;
        this.rows = [];
        this.cd.markForCheck();

      }),
      filter(data => {
        // console.log('filter planning menus');
        return !(this.utils.isNullOrEmpty(data[0])
          || this.utils.isCollectionNullOrEmpty(data[1])
          || this.utils.isNullOrEmpty(data[1][0])
          || this.utils.isNullOrEmpty(data[2]));
      }),
      tap(data => {

        date = data[0];
        contratId = data[1][0] ? data[1][0].contratMenuId : null;
        this.siteId = data[1][0].contratMenuSiteId;
        prestationId = data[1][0] ? data[1][0].id : null;
        regimeId = data[2] ? data[2].id : null;
      }),
      switchMap(data => this.mp2Svc.getPlanningMenus(data[0], data[1], data[2])),
      map(data => {
        this.utils.setTitle('PLANNING DES MENUS');

        this.rows = [];
        this.mp2Svc.planningMenus = new PlanningMenusDTO();

        if (!this.utils.isResponseSupplierError(data)) {
          this.mp2Svc.planningMenus = data.one;
          if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
            if (!this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.ligneTableauList)) {
              this.rows = _cloneDeep(this.mp2Svc.planningMenus.ligneTableauList);
              if(this.rows.length > 0){
                this.isFirstTime = true;
                this.getRepasDecoupageRepasNode();
              }
            }
            this.rowGroups = this.mp2Svc.planningMenus.ligneGroupTableauMap;

            // calcul de la taille du planning pour l'adapter à ses cellules
            const prefProfilMenu = this.prefUserSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_PROFIL);
            this.mp2Svc.planningMenus.tableauSize = this.mp2Svc.computeTableauSize(this.mp2Svc.planningMenus, prefProfilMenu);
            this.vPaneWidth = this.getPlanningWidth(this.mp2Svc.planningMenus.tableauSize);
            this.mp2Svc.repasActif = this.mp2Svc.planningMenus.repasList[0];
            this.mp2Svc.planningMenus.changeDetector = this.cd;
          }
        }
      }),
      // sauvegarde des preferences contrat/prestation/menus
      switchMap(data => {

        // console.log('prefs user menus planning', data, contratId, prestationId, regimeId, date);
        if (!this.utils.isNullOrEmpty(contratId) && !this.utils.isNullOrEmpty(prestationId) && !this.utils.isNullOrEmpty(regimeId) && !this.utils.isNullOrEmpty(date)) {

          this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_CONTRAT, USER_TYPE_PREFERENCE.NUMBER, contratId);
          this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_PRESTATION, USER_TYPE_PREFERENCE.NUMBER, prestationId);
          this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_REGIME, USER_TYPE_PREFERENCE.NUMBER, regimeId);
          this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DATE, USER_TYPE_PREFERENCE.DATE, date);
          return this.prefUserSvc.savePreferencesUtilisateur(null);
        }
      }),
      tap(data => {
        this.loadingTable = false;
        this.cd.markForCheck();
      }),
      catchError(err => {
        return this.utils.handleError(err);
      }),
      finalize(() => {
        this.loadingTable = false;
      })
    ).subscribe( );

    // lorsque les preferences utilisateur changent,
    this.subDisplayProfil = this.menusToolbarSvc.displayProfil$.subscribe(prefProfilMenu => {
      if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
        // on recalcule la taille du planning pour l'adapter à ses cellules
        this.mp2Svc.planningMenus.tableauSize = this.mp2Svc.computeTableauSize(this.mp2Svc.planningMenus, prefProfilMenu);
        this.vPaneWidth = this.getPlanningWidth(this.mp2Svc.planningMenus.tableauSize);
        this.cd.markForCheck();
      }
    });

    // ajout des plats issus de la recherche
    let cell: CelluleTableauDTO = undefined;
    this.subProduitsDeclinaisonsFromSearch = this.mcSvc.produitsDeclinaisonsFromSearch$
      .pipe(
        tap(response => cell = response.sourceObject),
        switchMap(response => {
          const menusComposToSave: MenuCompositionDTO[] = [];

          // preparer les plats à enregistrer sur la composante repas
          let index = 0;
          for (let ordre = 1; ordre <= response.choixMax; ordre++) {

            const menuCompo: MenuCompositionDTO = cell.mapMenuComposition[ordre];
            const pd = response.produitsDeclinaisonsInCart[index];

            if (this.utils.isNullOrEmpty(menuCompo) && !this.utils.isNullOrEmpty(pd)) {
              const dateMenu = moment(cell.dateMenu).toDate();
              const idCmcd = cell.idContratMenuConviveDecoupage;
              const idMdd = cell.idMenuDecoupageDate;
              const newMc = this.mcSvc.createMenuCompoFromProduitDeclinaison(pd, ordre, dateMenu, idCmcd, idMdd, response.cmcContrainteAlim.id);

              menusComposToSave.push(newMc);
              index++;
            }
          }
          // enregistrer les menus compos du panier
          return this.mcSvc.saveMenusCompositions(menusComposToSave);
        }),
        catchError(err => this.utils.handleError(err)
        )
      )
      .subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {
          let menusCompos: MenuCompositionDTO[] = response.resultList;
          menusCompos = _sortBy(menusCompos, 'ordre');
          for (let mc of menusCompos) {
            cell.mapMenuComposition[mc.ordre] = mc;
          }
          cell.changeDetector.markForCheck();
          this.mp2Svc.planningMenus.changeDetector.markForCheck();
          this.mp2Svc.computeTableauSize(this.mp2Svc.planningMenus, 1);
          this.pdSvc.displayDialogRecherchePlats = false;
        }
      });
  };

  getCell = (cmcId: number, repasId: number, decoupageRepasId: number, date: number) => {
    const key = `${cmcId}-${repasId}-${moment(date).isoWeekday()}-${decoupageRepasId}-${date}`;
    const cell: CelluleTableauDTO = this.mp2Svc.planningMenus.celluleTableauMap[key];
    return cell;
  };

  deleteAllRepas = async (cmc: ContratMenuConviveDTO, date: number) => {
    const idsMenuCompo: number[] = [];
    const result = confirm('Êtes vous sûr de vouloir supprimer tous les repas ?', 'Suppression des repas');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
        if (this.mp2Svc.planningMenus.celluleTableauMap) {
          Object.keys(this.mp2Svc.planningMenus.celluleTableauMap).forEach(key => {
            const cell: CelluleTableauDTO = this.mp2Svc.planningMenus.celluleTableauMap[key];
            if (cell.contratMenuConviveDecoupage.contratMenuConviveId === cmc.id) {
              if (cell.mapMenuComposition) {
                Object.keys(cell.mapMenuComposition).forEach(key2 => {
                  const mc: MenuCompositionDTO = cell.mapMenuComposition[key2];
                  if (mc && this.utils.isDateEqualsYYYYMMDD(moment(mc.menuDecoupageDate.dateMenu), moment(date))) {
                    idsMenuCompo.push(cell.mapMenuComposition[key2].id)
                  }
                });
              }
            }
          });
        }
      }

      this.mp2Svc.deleteMenusCompositions(idsMenuCompo).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          // mise à jour du front
          Object.keys(this.mp2Svc.planningMenus.celluleTableauMap).forEach(key => {
            const cell: CelluleTableauDTO = this.mp2Svc.planningMenus.celluleTableauMap[key];
            if (cell.mapMenuComposition) {
              Object.keys(cell.mapMenuComposition).forEach(key2 => {
                const mc: MenuCompositionDTO = cell.mapMenuComposition[key2];
                if (cell.contratMenuConviveDecoupage.contratMenuConviveId === cmc.id
                  && mc
                  && this.utils.isDateEqualsYYYYMMDD(moment(mc.menuDecoupageDate.dateMenu), moment(date))) {
                  // TODO  ne pas assigner dans une const sinon angular ne fait pas la change detection ??
                  this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition[key2] = undefined;
                }
              });
            }
          });
          this.mp2Svc.planningMenus = _cloneDeep(this.mp2Svc.planningMenus);
          this.cd.markForCheck();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les plats ont été retirés du menu avec succès`);
        }
      });
    }
  };

  deleteRepas = async (cmc: ContratMenuConviveDTO, repas: RepasDTO, date: number) => {
    const idsMenuCompo: number[] = [];
    const result = confirm('Êtes vous sûr de vouloir supprimer ce repas ?', 'Suppression de repas');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
        if (this.mp2Svc.planningMenus.celluleTableauMap) {
          Object.keys(this.mp2Svc.planningMenus.celluleTableauMap).forEach(key => {
            const cell: CelluleTableauDTO = this.mp2Svc.planningMenus.celluleTableauMap[key];
            if (cell.contratMenuConviveDecoupage.contratMenuConviveId === cmc.id) {
              if (cell.mapMenuComposition) {
                Object.keys(cell.mapMenuComposition).forEach(key2 => {

                  const mc: MenuCompositionDTO = cell.mapMenuComposition[key2];
                  if (mc && this.utils.isDateEqualsYYYYMMDD(moment(mc.menuDecoupageDate.dateMenu), moment(date))
                    && cell.repasId === repas.id) {
                    idsMenuCompo.push(mc.id);
                  }
                });
              }
            }
          });
        }
      }

      this.mp2Svc.deleteMenusCompositions(idsMenuCompo).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {

          // mise à jour du front
          Object.keys(this.mp2Svc.planningMenus.celluleTableauMap).forEach(key => {
            if (this.mp2Svc.planningMenus.celluleTableauMap[key].contratMenuConviveId === cmc.id) {
              if (this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition) {
                Object.keys(this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition).forEach(key2 => {

                  let mc: MenuCompositionDTO = this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition[key2];
                  if (mc && this.utils.isDateEqualsYYYYMMDD(moment(mc.menuDecoupageDate.dateMenu), moment(date))
                    && this.mp2Svc.planningMenus.celluleTableauMap[key].repasId === repas.id) {

                    // TODO  ne pas assigner dans une const sinon angular ne fait pas la change detection ??
                    this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition[key2] = undefined;
                    // console.log('delete all ok mark for check and clone', this.mp2Svc.planningMenus.celluleTableauMap[key].mapMenuComposition[key2]);

                    //nouvelle ref pour la détection angular
                    this.mp2Svc.planningMenus = _cloneDeep(this.mp2Svc.planningMenus);
                    this.cd.markForCheck();
                  }
                });
              }
            }
          });
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Suppression des menus réalisée avec succès`);
        }
      });
    }
  };

  trackByDate = (index, item: any) => item;

  trackByCmc = (index, item) => item.id;

  trackByRepas = (index, item) => item.id;



  displayPlanning = () => {
    return !(this.utils.isNullOrEmpty(this.mp2Svc.planningMenus) || this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.ligneTableauList));
  };

  canDelete = (): boolean => {
    return this.auth2Svc.isSiteLocal(this.siteId);
  };

  annulerValidation = async (cmc: ContratMenuConviveDTO, date: number) => {
    const dateFormat = new Date(date);
    let selectedDates: Date[] = [dateFormat, dateFormat];

    const result = confirm(`Êtes vous sûr de vouloir annuler la validation de ce menu ?`, 'Suppression de menu');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.mp2Svc.validerMenus(selectedDates, [cmc.id], 0, 0, false).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La période a été annulée avec succès`);
          this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);
        }
      });
    }
  };

  isRepasActif = (selector: string) => selector === this.mp2Svc.getRepasActifHtmlId();

  scrollToRepas = (selector: string) => {
    const elt = document.getElementById(selector);
    const parent = document.getElementById('paneVScroll');
    const scrollOptions : ScrollToOptions = {
      left: elt.offsetLeft,
      top: elt.offsetTop
    };
    parent.scroll(scrollOptions);

    this.mp2Svc.setRepasActif(selector);
  };

  getPlanningWidth = (tableauSize: SizeTableauDTO) => {
    // 50 px pour les stickers repas + 17 px pour la scroll bar vertical
    const maxWidth = window.innerWidth - 73;
    if (tableauSize.tableWidthInt > maxWidth) {
      return maxWidth;
    }
    return tableauSize.tableWidthInt;
  };

  highlightTop = (rowData: LigneTableauDTO, repasActif: string) => {
    const selector = 'repas' + rowData.idRepas;

    if (rowData.ordreDecoupageRepas === 1 && selector === repasActif) {
      return true;
    }
    return false;
  };

  highlightLeft = (rowData: LigneTableauDTO, repasActif: string, date: Date) => {

    const selector = 'repas' + rowData.idRepas;
    const dates = this.mp2Svc.planningMenus.dateList;

    if (selector === repasActif) {

      if (this.utils.isDateEqualsYYYYMMDD(moment(dates[0].date), moment(date))) {
        return true;
      } else if (this.TYPES_LIGNES.SEPARATEUR === rowData.typeLigne) {
        return true;
      }
    }

    return false;
  };

  highlightRight = (rowData: LigneTableauDTO, repasActif: string, date: Date) => {

    const selector = 'repas' + rowData.idRepas;
    const dates = this.mp2Svc.planningMenus.dateList;

    if (selector === repasActif) {
      if (this.utils.isDateEqualsYYYYMMDD(moment(dates[dates.length - 1].date), moment(date))) {
        return true;
      } else if (this.TYPES_LIGNES.SEPARATEUR === rowData.typeLigne) {
        return true;
      }
    }
    return false;
  };

  highlightBottom = (rowData: LigneTableauDTO, repasActif: string) => {
    const selector = 'repas' + rowData.idRepas;

    if (selector === repasActif && rowData.typeLigne === this.TYPES_LIGNES.SEPARATEUR) {
      return true;
    }
    return false;
  };


  scrollHPane = $event => {
    this.vPaneWidth = this.paneHScroll.nativeElement.offsetWidth + this.paneHScroll.nativeElement.scrollLeft;
    this.cd.markForCheck();
  };

  displayDialogCoupageSetting = (event: any) => {
    if(this.rows.length > 0){
      this.opConfigMenu.toggle(event);
    }
  };

  getRepasDecoupageRepasNode = () => {

    let selectedDecoupageRepasNode =[];
    let repasNodeList: TreeNode[] = [];
    let decoupageRepasNodeList: TreeNode[] = [];

    // Recuperation des repas
    this.rows.forEach((row: LigneTableauDTO) => {
      if (row.libelleDecoupageRepas !== null && row.libelleDecoupageRepas !== undefined) {
        let node: TreeNode = {
          children: [],
          data: {idRepas: row.idRepas, libelleRepas: row.libelleRepas, niveau: 1},
          icon: 'fas fa-hamburger',
          label: row.libelleRepas,
          leaf: false,
          selectable: true,
          expanded: true
        };

        repasNodeList.push(node);
      }
    });

    // suppresion des doublons
    repasNodeList = _uniqWith(repasNodeList, _isEqual);

    //Affectation des découpages par repas
    repasNodeList.forEach((repas: TreeNode) => {
      this.rows.forEach((row: LigneTableauDTO) => {
        if (repas.data.idRepas === row.idRepas && row.libelleDecoupageRepas !== null && row.libelleDecoupageRepas !== undefined) {

          let node: TreeNode = {
            data: {
              idRepas: row.idRepas,
              libelleRepas: row.libelleRepas,
              libelleDecoupageRepas: row.libelleDecoupageRepas,
              idDecoupageRepas: row.idDecoupageRepas,
              niveau: 2
            },
            icon: '',
            parent: repas,
            label: row.libelleDecoupageRepas,
            leaf: true,
            selectable: true
          };

          repas.children.push(node);

          // init selectedDecoupageRepasNode
          if (this.isFirstTime) {
            selectedDecoupageRepasNode.push(node);
          }
        }
      });
      decoupageRepasNodeList.push(repas);
      // init selectedDecoupageRepasNode
      if (this.isFirstTime) {
        selectedDecoupageRepasNode.push(repas);
      }
    });

    // init selectedDecoupageRepasNode
    if (this.isFirstTime) {
      this.mp2Svc.announcedecoupageRepasDisplaySettings(selectedDecoupageRepasNode);
      this.isFirstTime = false;
    }
    this.decoupageRepasNode = decoupageRepasNodeList;
    this.selectedDecoupageRepasNode = selectedDecoupageRepasNode;
  };

  onChangeSelectionDecoupageRepas = event => {
    this.selectedDecoupageRepasNode = event;
    this.mp2Svc.announcedecoupageRepasDisplaySettings(this.selectedDecoupageRepasNode);
  };

  initMenuDateAnShowMenuDate = (event : any, cmc:ContratMenuConviveDTO, date: number, repasList:RepasDTO[]) => {
    this.itemsMenuDate = [
      {
        label: 'Supprimer tous les plats du jour',
        icon: 'fas fa-sync-alt',
        command: (event) => this.deleteAllRepas(cmc,date)
      }];

    repasList.forEach( repas =>{
      this.itemsMenuDate.push(<MenuItem>{
        label: 'Supprimer les plats pour ' + repas.libelle,
        icon: 'fas fa-receipt',
        command: (event) => this.deleteRepas(cmc,repas,date)
      });
    });
    this.menuDate.toggle(event);
  };

  checkData(rowData: LigneTableauDTO) {
    console.log(rowData)
    return false;
  }
}
