import {DROP_ACTION} from "../../constants";
import {MenuDecoupageDate} from "../../services/gestionmenus/menus-planning.service";
import {ChangeDetectorRef} from "@angular/core";
import {CelluleTableauDTO} from "../../dtos/gestionmenus/planning/cellule-tableau-dto";

/**
 * Wrapper de l'objet menu à dropper
 */
export class DropMenu<T>{

  public transferObject : T;
  public dropAction : DROP_ACTION;
  public menuDecoupageDateTarget : MenuDecoupageDate;
  public menuDecoupageDateSource : MenuDecoupageDate;
  public ordreSource : number;
  public ordreTarget : number;
  public changeDetectorRefTarget : ChangeDetectorRef;
  public changeDetectorRefSource : ChangeDetectorRef;

  public celluleSource:CelluleTableauDTO;
  public celluleTarget:CelluleTableauDTO;

}
