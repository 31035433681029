import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {RoutemapService} from '../routemap.service';
import {startCase as _startCase} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceService extends GenericHandler<WorkflowInstanceDTO> {

  constructor(
    private routeMapSvc:RoutemapService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router,
    http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO = (): WorkflowInstanceDTO => undefined;

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEntityName = (): string => "workflowInstance";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => undefined;

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => undefined;

  getTitle = (): string => "";

  getEditObjectLabel = (data: ObjectDTO): string => "";
}
