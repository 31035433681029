import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, HAS_GESTION_IDISTRI, HAS_GESTIONCLIENTS, OUTLET_SECONDARY} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {ClientsResolverService} from './clients/clients-resolver.service';
import {ClientFicheIdentiteComponent} from './clients/client/fiche-identite/client-fiche-identite.component';
import {ClientResolverService} from './clients/client/client-resolver.service';
import {GridClientsComponent} from "./grille-clients/grid-clients.component";
import {ClientComponent} from "./clients/client.component";
import {PointDeLivraisonResolverService} from "./points-de-livraison/point-de-livraison/point-de-livraison-resolver.service";
import {PointDeLivraisonComponent} from "./points-de-livraison/point-de-livraison/point-de-livraison.component";
import {PointDeLivraisonFicheIdentiteComponent} from "./points-de-livraison/point-de-livraison/fiche-identite/point-de-livraison-fiche-identite.component";
import {PointDeLivraisonConditionnementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/point-de-livraison-conditionnement.component";
import {LiaisonsComponent} from "./points-de-livraison/point-de-livraison/conditionnement/liaisons/liaisons.component";
import {ReglesRegroupementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/regles/regles-regroupement.component";

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_CLIENTS,
    component: GridClientsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_GESTIONCLIENTS,
    resolve: {
      clientsSupplier: ClientsResolverService,
    },
  },
  {
    path: 'clients',
    component: ClientComponent,
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONCLIENTS,
    outlet: OUTLET_SECONDARY,
    children:[
      {
        path: ':idClient/ficheidentite',
        component: ClientFicheIdentiteComponent,
        runGuardsAndResolvers: 'always',
        data:  HAS_GESTIONCLIENTS,
        resolve: {
          clientSupplier: ClientResolverService,
        }
      },
    ]
  },
  {
    path: 'point-de-livraison/:idClient/:idPlc',
    component: PointDeLivraisonComponent,
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONCLIENTS,
    outlet: OUTLET_SECONDARY,
    resolve: {
      plcSupplier: PointDeLivraisonResolverService,
    },
    children:[
      {
        path: 'ficheidentite',
        component: PointDeLivraisonFicheIdentiteComponent,
        runGuardsAndResolvers: 'always',
        data:  HAS_GESTIONCLIENTS,
      },
      {
        path: 'conditionnement',
        component: PointDeLivraisonConditionnementComponent,
        runGuardsAndResolvers: 'always',
        data: HAS_GESTIONCLIENTS && HAS_GESTION_IDISTRI,
        children: [{
          path: 'liaisons',
          component: LiaisonsComponent,
          data: HAS_GESTIONCLIENTS && HAS_GESTION_IDISTRI
        },{
          path: 'regles-regroupements',
          component: ReglesRegroupementComponent,
          data: HAS_GESTIONCLIENTS && HAS_GESTION_IDISTRI
        }]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionClientsRoutingModule {
}
