import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {isEmpty as _isEmpty} from 'lodash';
import {UtilsService} from '../../utils/utils.service';
import {SESSION_STORE} from '../../constants';
import {HttpClient} from '@angular/common/http';
import * as SecureLS from 'secure-ls';

@Injectable()
export class FrontStorageService {

  /**
   * Crypt / Decrypt localstorage keys/values
   */
  secureLS :SecureLS;

  constructor(private utils: UtilsService, private http: HttpClient) {

    this.secureLS = new SecureLS({encodingType:'des',isCompression:false,encryptionSecret: 'my-secret-key'});

  }


  /**
   * Persister une valeur dans le local storage
   * @param {string} key
   * @param value
   */
  localStoreSet(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Récupérer un élément du localstorage
   * @param {string} key
   */
  localStoreGet(key: string): any {
    try{
      let item = JSON.parse(localStorage.getItem(key));
      if (!this.utils.isNullOrEmpty(item)) {
        return item;
      }
    }catch(e){
      console.error('error localStoreGet : ',e);
    }

    return null;
  }

  /**
   * Récupérer le dernier item sélectionné à partir du localstorage, on utilise l'attribut value pour la comparaison
   * @param {string} key
   * @param {SelectItem[]} items
   * @returns {any} tableau vide si rien trouvé
   */
  localStoreGetSelectedItemByLabel(key: string, items: SelectItem[]): SelectItem {
    if (key) {
      let selected = localStorage.getItem(key);
      if (!this.utils.isNullOrEmpty(selected)) {
        let selectedItem = JSON.parse(selected);
        if (!this.utils.isNullOrEmpty(selectedItem)) {
          let arr = items.filter(item => item.label == selectedItem.label);
          if (!_isEmpty(arr)) {
            return arr[0];
          }
        }
      }
    }
    return {label: '', value: 'undefined'};
  }


  /**
   * Récupérer les derniers items sélectionnés à partir du localstorage. Utilisé pour une liste multichoix
   * @param {string} key
   * @param {SelectItem[]} items
   * @returns {SelectItem[]} tableau vide si rien trouvé
   */
  localStoreGetItemsById(key: string, items: any[]): any[] {
    if (key && !this.utils.isCollectionNullOrEmpty(items)) {
      let selectedItems = JSON.parse(localStorage.getItem(key));
      if (!this.utils.isNullOrEmpty(selectedItems)) {

        let arr = [];
        for (let selectedItem of selectedItems) {
          for (let item of items) {
            if (item.id === selectedItem.id) {
              arr.push(item);
            }
          }
        }
        if (!_isEmpty(arr)) {
          return arr;
        }
      }
    }
    return [];
  }

  /**
   * To implement with more strength
   * @param {string} key
   * @param value
   */
  sessionStoreSet(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }


  /**
   * To implement with more strength
   * @param {string} key
   * @param value
   */
  sessionStoreGet(key: string): any {
    if (key) {
      return JSON.parse(sessionStorage.getItem(key));
    }
    return undefined;
  }


  /**
   * Supprimer les cles du sessionStorage qui commencent par storageKeySuffixForDelete
   * @param {string} storageKeySuffixForDelete
   */
  sessionStoreRemove(sessionKeySuffixForDelete: string) {

    for (var i = 0; i < sessionStorage.length; i++) {
      let itemKey = sessionStorage.key(i);
      if (itemKey.startsWith(sessionKeySuffixForDelete)) {
        sessionStorage.removeItem(itemKey);
        console.log(`itemKey ${itemKey} deleted`);
      }
    }
  }


  /**
   * Enlever les requetes enregistrées du sessionStorage
   */
  clearSessionStorage() {
    //vider le cache
    this.sessionStoreRemove(SESSION_STORE.GESTIONPRODUIT.PRODUIT);
    this.sessionStoreRemove(SESSION_STORE.GESTIONPRODUIT.PRODUITDECLI);
    this.sessionStoreRemove(SESSION_STORE.GESTIONPRODUIT.PRODUITNOMENCLATURE_LIST);
  }
}
