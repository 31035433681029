import {ObjectDTO} from './object-dto';

export class BonReceptionLigneLitigeDTO extends ObjectDTO {

  // bon reception ligne
   idBonReceptionLigne : number;

  // litige
  idLitige : number;
  libelleLitige : string;

  // commentaire
  commentaire : string;


}
