import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {UtilsService} from "../../../core/utils/utils.service";
import {MenuItem} from "primeng/api";
import {ItemNavigationService} from "../../../core/services/technique/item-navigation.service";

@Component({
  templateUrl:'./point-de-livraison.component.html',
  styleUrls: ['./point-de-livraison.component.scss']
})
export class PointDeLivraisonComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  plc: PointDeLivraisonDTO;

  navLinks: MenuItem[] = [];
  selectedNavLink:MenuItem;
  subActiveSecondaryRoute:Subscription;

  constructor(private route: ActivatedRoute,
              private itemNavSvc:ItemNavigationService,
              private utils: UtilsService){
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  }

  ngOnInit(): void {
    this.initRouteSubscription();
    this.activeSecondaryRouteSubscription();
  }

  initRouteSubscription = (): void => {
    this.subscriptionRoute = this.route.data
      .subscribe((data) => {
        this.plc = data.plcSupplier.pointDeLivraison;
        this.initNavLinks();
      });
  };

  private initNavLinks = () => {

    if(this.utils.isNullOrEmpty(this.plc)){
      this.plc = new PointDeLivraisonDTO();
      this.plc.id=0;
    }
    this.navLinks = [];
    this.navLinks.push({
      label: `Fiche d'identité`,
      routerLink:[`ficheidentite`],
    });
    if (this.plc && this.plc.id > 0) {
      this.navLinks.push({
        label: `Conditionnement`,
        routerLink:[`conditionnement`],
      });
    }
  };

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.selectedNavLink = this.itemNavSvc.activeSecondaryRoute(event, this.navLinks);
    });
  };

}
