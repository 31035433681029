import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ZoneDeStockageDTO} from "../../../core/dtos/zone-de-stockage-dto";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {ZonesDeStockageService} from "../../../core/services/entities/zones-de-stockage.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";

@Component({
  selector: 'yo-zoneDeStockageDialog',
  templateUrl: './zone-de-stockage-dialog.component.html',
  styleUrls: ['./zone-de-stockage-dialog.component.scss']
})
export class ZoneDeStockageDialogComponent implements OnInit {

  subOpenDialog: Subscription

  zoneDeStockage: ZoneDeStockageDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle: String = 'Modification d\'une zone de stockage';

  sitePlaceholder: String = 'Selectionner le site...';

  displayDialog = false;

  idSelectedSite: number;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public zonesDeStockageSvc: ZonesDeStockageService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.zonesDeStockageSvc.openDialogEdition$
      .subscribe((cdt: ZoneDeStockageDTO) => {
        this.displayDialog = true;
        if (!cdt) {
          this.zoneDeStockage = new ZoneDeStockageDTO();
          this.zoneDeStockage.id = 0;
          this.dialogTitle = 'Création d\'une zone de stockage';
        } else {
          this.zoneDeStockage = cdt;
          this.dialogTitle = 'Modification d\'une zone de stockage';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let newZoneDeStockage: ZoneDeStockageDTO = {...this.zoneDeStockage};
      newZoneDeStockage.site = {id: this.idSelectedSite} as SiteDTO;
      newZoneDeStockage.libelle = this.form.controls['libelle'].value;
      newZoneDeStockage.actif = this.form.controls['actif'].value;
      this.zonesDeStockageSvc.save(newZoneDeStockage)
        .subscribe(response => {
          const isUpdate = newZoneDeStockage.id !== null && newZoneDeStockage.id !== undefined && newZoneDeStockage.id !== 0;
          this.zonesDeStockageSvc.announceZoneDeStockageSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde d'une zone de stockage réalisé avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.zoneDeStockage?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.zoneDeStockage?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.zoneDeStockage?.libelle),
      actif: new FormControl(this.zoneDeStockage?.actif != null ? this.zoneDeStockage.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.zonesDeStockageSvc.canModify(this.zoneDeStockage);
  }

}
