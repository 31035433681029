import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UtilsService} from '../utils/utils.service';
import {FP_ROUTES} from '../constants';
import {Subject} from 'rxjs';


type PrimaryRoute =
  'admin'
  | 'menus'
  | 'contrats'
  | 'gemrcn'
  | 'produits'
  | 'bureau'
  | 'unitesproduction'
  | 'fournisseurs'
  | 'referentiel'
  | 'commandes'
  | 'besoins'
  | 'production'
  | 'clients';

@Injectable()
export class RoutemapService {

  selectedPrimaryRoute: PrimaryRoute;
  private subjectSidenav = new Subject<boolean>();
  sidenav$ = this.subjectSidenav.asObservable();

  constructor(private router: Router, private utils: UtilsService) {
  }

  /**
   * Annoncer l'ouverture du sidenav de la secondary route
   */
  private announceSidenav(sidenav: boolean) {
    this.subjectSidenav.next(sidenav);
  }



  /**
   * Route à  afficher dans le sidenav secondary
   */
  goToSecondaryRoute(route: any[]) {

    this.utils.sidenav = true;
    this.announceSidenav(this.utils.sidenav);

    return this.router.navigate([{outlets: {secondary: route}}]);
  }


  /**
   * Route à  afficher dans le sidenav tertiary
   */
  goToTertiaryRoute(route: any[]) {

    return this.router.navigate([{outlets: {tertiary: route}}]);
  }


  getMonBureau(): string[] {
    return ['/', FP_ROUTES.MBU];
  }

  getGestionProduits(): string[] {
    return ['/', FP_ROUTES.GPR];
  }

  getGestionUnitesProduction(): string[] {
    return ['/', FP_ROUTES.GUP];
  }

  getGestionClients(): string[] {
    return ['/', FP_ROUTES.GESTION_CLIENTS, 'clients'];
  }

  getGestionFournisseurs(): string[] {
    return ['/', FP_ROUTES.GFO];
  }


  getGestionMenus(): string[] {
    return ['/', FP_ROUTES.GME];
  }

  getGestionCommandes(): string[] {
    return ['/', FP_ROUTES.GESTION_COMMANDES_FOURNISSEURS];
  }

  getGestionBesoins(): string[] {
    return ['/', FP_ROUTES.GESTION_LISTES_BESOINS];
  }

  getGestionProduction(): string[] {
    return ['/', FP_ROUTES.GESTION_PRODUCTION];
  }


  getGestionContrats(): string[] {
    return ['/', FP_ROUTES.GCO];
  }

  getAdministration(): string[] {
    return ['/', FP_ROUTES.ADM];
  }

  getGestionGemrcn(): string[] {
    return ['/', FP_ROUTES.GGE];
  }

  getSites(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.SIT];
  }

  getEnvironnements(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.ENV];
  }

  getProfils(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.PROFILS];
  }

  getZonesDeProduction(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.ZDP];
  }

  getEquipes(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.EQU];
  }

  getZonesDeStockage(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.ZDS];
  }

  getJoursSemaine(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.JSE];
  }

  getUtilisateurs(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.UTI];
  }

  getUtilisateursConnexions(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.UTI_CO];
  }

  getAllergenes(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.ALL];
  }

  getApportsNutritionnels(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.ANUT];
  }

  getConvives(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.CON];
  }

  getAppellations(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.APPELLATIONS];
  }

  getDecoupagesRepas(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.DCR];
  }

  getFamillesGEMRCN(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.FAMGEMRCN];
  }

  getFamillesProduit(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.FAMPRODUITS];
  }

  getMarches(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.MARCHES];
  }

  getFamillesPlanAlimentaires(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.FAMPLANALIM];
  }

  getGroupesNutritionnels(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.GROUPNUTRI];
  }

  getTypesProduit(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.TYPES_PRODUIT];
  }

  getTypesFabrication(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.TYPES_FABRICATION];
  }

  getTypesAppro(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.TYPES_APPRO];
  }

  getTypesSortie(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.TYPES_SORTIE];
  }

  getDeclinaisons(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.DECLINAISONS];
  }

  getRepas(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.REPAS];
  }

  getFournisseurs(): string[] {
    return ['/', FP_ROUTES.GFO];
  }

  getSecteurs(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.SECTEURS];
  }


  getUnitesDeMesures(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.UDM];
  }

  getTaxes(): string[] {
    return ['/', FP_ROUTES.ADM, FP_ROUTES.FEA, FP_ROUTES.TAXES];
  }


  goToAdministration() {
    this.selectedPrimaryRoute = 'admin';
    this.goToPrimaryRoute(this.getAdministration());
  }

  goToGestionGemrcn() {
    this.selectedPrimaryRoute = "gemrcn";
    this.goToPrimaryRoute(this.getGestionGemrcn());
  }

  goToGestionMenus() {
    this.selectedPrimaryRoute = "menus";
    this.goToPrimaryRoute(this.getGestionMenus());
  }

  goToGestionBesoins() {
    this.selectedPrimaryRoute = "besoins";
    this.goToPrimaryRoute(this.getGestionBesoins());
  }

  goToGestionProduction() {
    this.selectedPrimaryRoute = "production";
    this.goToPrimaryRoute(this.getGestionProduction());
  }

  goToGestionCommandes() {
    this.selectedPrimaryRoute = "commandes";
    this.goToPrimaryRoute(this.getGestionCommandes());
  }

  goToGestionContrats() {
    this.selectedPrimaryRoute = "contrats";
    this.goToPrimaryRoute(this.getGestionContrats());
  }

  goToGestionProduits() {
    this.selectedPrimaryRoute = "produits";
    this.goToPrimaryRoute(this.getGestionProduits());
  }

  goToGestionFournisseurs() {
    this.selectedPrimaryRoute = "fournisseurs";
    this.goToPrimaryRoute(this.getGestionFournisseurs());
  }

  goToGestionUnitesDeProduction() {
    this.selectedPrimaryRoute = "unitesproduction";
    this.goToPrimaryRoute(this.getGestionUnitesProduction());
  }

  goToGestionClients() {
    this.selectedPrimaryRoute = "clients";
    this.goToPrimaryRoute(this.getGestionClients());
  }

  goToJoursSemaine() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getJoursSemaine());
  }

  goToMonBureau() {
    this.selectedPrimaryRoute = "bureau";
    this.goToPrimaryRoute(this.getMonBureau());
  }

  goToSites() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getSites());
  }

  goToEnvironnements() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getEnvironnements());
  }

  goToProfils() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getProfils());
  }

  goToEquipes() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getEquipes());
  }

  goToSecteurs() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getSecteurs());
  }

  goToZonesDeProduction() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getZonesDeProduction());
  }

  goToZonesDeStockage() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getZonesDeStockage());
  }

  goToUtilisateurs() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getUtilisateurs());
  }

  goToUtilisateursConnexions() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getUtilisateursConnexions());
  }

  goToAllergenes() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getAllergenes());
  }

  goToApportsNutritionnels() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getApportsNutritionnels());
  }

  goToPrestations() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getConvives());
  }

  goToAppellations() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getAppellations());
  }

  goToDecoupagesRepas() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getDecoupagesRepas());
  }

  goToFamillesGEMRCN() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getFamillesGEMRCN());
  }

  goToFamillesProduit() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getFamillesProduit());
  }

  goToMarches() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getMarches());
  }

  goToFamillesPlanAlimentaires() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getFamillesPlanAlimentaires());
  }

  goToGroupesNutritionnels() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getGroupesNutritionnels());
  }

  goToTypesProduit() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getTypesProduit());
  }

  goToTypesFabrication() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getTypesFabrication());
  }

  goToTypesAppro() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getTypesAppro());
  }

  goToTypesSortie() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getTypesSortie());
  }

  goToDeclinaisons() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getDeclinaisons());
  }

  goToRepas() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getRepas());
  }

  goToTaxes() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getTaxes());
  }

  goToUnitesDeMesures() {
    this.selectedPrimaryRoute = "admin";
    // this.goToPrimaryRoute(['/administration/features/unites-de-mesures']);
    this.goToPrimaryRoute(this.getUnitesDeMesures());
  }

  goToFournisseurs() {
    this.selectedPrimaryRoute = "admin";
    this.goToPrimaryRoute(this.getFournisseurs());
  }


  /**
   * Fermer la route secondaire ainsi que son sidenav
   */
  goToPrimaryRoute(route: any[] = ['/']) {
    //close secondary route and go to primary route

    this.router.navigate([{outlets: {secondary: null}}])
      .then(() => this.router.navigate(route));
    this.utils.sidenav = false;

  }


  isActive(cssActive: PrimaryRoute): boolean {
    if (cssActive === this.selectedPrimaryRoute) {
      return true;
    }
    return false;
  }

  setSelectedPrimaryRoute(selected: PrimaryRoute) {
    this.selectedPrimaryRoute = selected;
  }


}
