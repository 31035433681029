import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TypeTachePmsDto} from "../../../../core/dtos/pms/type-tache-pms-dto";
import {TypeTachePmsService} from "../../../../core/services/pms/type-tache-pms.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-type-tache-dialog',
  templateUrl: './pms-type-tache-dialog.component.html',
  styleUrls: ['./pms-type-tache-dialog.component.scss']
})
export class PmsTypeTacheDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  typeTache: TypeTachePmsDto;

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  docError = false;

  dialogTitle = 'Modification d\'un type de tâche';

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private typeTachePmsService: TypeTachePmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.typeTachePmsService.openDialog$
      .subscribe((t: TypeTachePmsDto) => {
        this.displayDialog = true;
        if (!t) {
          this.forUpdate = false;
          this.typeTache = new TypeTachePmsDto();
          this.typeTache.id = 0;
          this.dialogTitle = 'Création d\'un type de tâche';
        } else {
          this.forUpdate = true;
          this.typeTache = t;
          this.siteSelected = t.site;
          this.dialogTitle = 'Modification d\'un type de tâche';

        }

        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => {
    if (!this.typeTache || !this.typeTache.site || !this.typeTache.site.id) return this.hasPms;

    let response: boolean = false;

    if (this.typeTache && this.typeTache.site)
        response = this.auth2Svc.isSiteLocal(this.typeTache.site.id);

    return response && this.hasPms;
  }

  /**
   * fonction qui sauvegarde le type de tâche pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.typeTache.libelle = this.form.controls['libelle'].value;
      if(this.forUpdate && this.typeTache.site || !this.forUpdate) this.typeTache.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.typeTachePmsService.save(this.typeTache)
        .subscribe(response => {
          this.typeTachePmsService.announceSaved(response.one, this.typeTache.id !== undefined && this.typeTache.id !== null && this.typeTache.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde du type de tâche a été réalisée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'un type de tâche pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.forUpdate ? this.typeTache.libelle : '', [Validators.required, Validators.minLength(1),Validators.maxLength(45)])
    });

    if(this.forUpdate && this.typeTache.site || !this.forUpdate)
      this.form.addControl('site', new FormControl(this.forUpdate ? this.typeTache.site : null));
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Type de Tâche (Plan de Maîtrise Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
