import {Injectable} from '@angular/core';
import {PrintCoutMatiereDTO} from '../../dtos/gestionmenus/print-cout-matiere/print-cout-matiere-dto';
import {PrintCoutMatiereRowDTO} from '../../dtos/gestionmenus/print-cout-matiere/print-cout-matiere-row-dto';
import {PrintCoutMatiereBlockDetailDTO} from '../../dtos/gestionmenus/print-cout-matiere/print-cout-matiere-block-details-dto';
import {
  utils as xlsxUtils,
  WorkBook as xlsxWorkBook,
  WorkSheet as xlsxWorkSheet,
  writeFile as xlsxWriteFile
} from 'xlsx';
import {UtilsService} from '../../utils/utils.service';
import {Subject} from 'rxjs';

@Injectable()
export class PrintCoutMatiereService {

  private subjectDisplayDialogCoutMatiere = new Subject();
  public displayDialogCoutMatiere$ = this.subjectDisplayDialogCoutMatiere.asObservable();

  constructor(private utilsService: UtilsService){}

  public announceDisplayCoutMatiere(value: boolean){
    this.subjectDisplayDialogCoutMatiere.next(value)
  }

  public getJsonForExcel(pcmDto :PrintCoutMatiereDTO,  additionalProperties: Map<String, any>, startDate: Date, endDate: Date, repasList: any[]) {
    const title: any = this.getTitleSheet(additionalProperties);

    const sheetCostByDayJson: any[] = this.getSheetCostByDay(pcmDto.sheetCostByDay, title);

    const sheetCostByDayDetailJson: any[] = this.getSheetCostByDayDetail(pcmDto.sheetCostByDayDetail, title, repasList.length);

    this.exportExcel(sheetCostByDayJson, sheetCostByDayDetailJson, startDate, endDate);
  }

  private exportExcel(sheetCostByDay: any[], sheetCostByDayDetail: any[], startDate: Date, endDate: Date){

    const fileName = 'cout_matiere_'+this.formatDate(startDate)+'_'+this.formatDate(endDate)+'.xlsx';

    const sheet1: xlsxWorkSheet = xlsxUtils.json_to_sheet(sheetCostByDay, {skipHeader:true});
    sheet1["!merges"]= [ { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }];
    sheet1["!cols"] =[{wch:12}, {wch:40},{wch:15}];

    const sheet2: xlsxWorkSheet = xlsxUtils.json_to_sheet(sheetCostByDayDetail, {skipHeader:true});
    sheet2["!merges"]= [ { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }];
    sheet2["!cols"] =[{wch:12}, {wch:40},{wch:10}];

    const wb: xlsxWorkBook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(wb, sheet1, 'coût matière journalier');
    xlsxUtils.book_append_sheet(wb, sheet2, 'coût matière journalier détails');

    xlsxWriteFile(wb, fileName);

  }

  private getSheetCostByDayDetail(sheetCostByDayDetail: PrintCoutMatiereBlockDetailDTO[],  title: any, repasNbByDay: number){

    let result : any[] = [];
    let coutJournalierMoyen: number = 0;
    let dateControl = '';
    let coutJournalier: number = 0;
    let isFirstLoop: boolean = true;

    result.push(title);
    result.push(this.blanckRow());
    result.push(this.headerRow(true));

    const nbDays: number = new Set(sheetCostByDayDetail.map(s => s.date)).size;
    sheetCostByDayDetail.forEach( (row: PrintCoutMatiereBlockDetailDTO) => {

      if(dateControl !== row.date){
        if(repasNbByDay > 1 && !isFirstLoop){
          result.push({'col1':'', 'col2':'Coût journalier', 'col3':this.utilsService.roundFloat(coutJournalier,4)});
          coutJournalier = 0;
        }
        if(!isFirstLoop){
          result.push(this.blanckRow());
        }
        result.push({'col1': row.date, 'col2':row.repas, 'col3':''});
        dateControl = row.date;
        isFirstLoop = false;
      }else{
        result.push(this.blanckRow());
        result.push({'col1':'', 'col2':row.repas, 'col3':''});
      }

      let coutRepas: number = 0;

      let platsDetailsSorted = row.platDetailList.sort((a, b) => a.decoupageRepasId - b.decoupageRepasId);
      let platsDetailsGroupedByDecoupageRepas = platsDetailsSorted
        .reduce((acc, platDetaille) => {
          if (!acc[platDetaille['decoupageRepasId']]) {
            acc[platDetaille['decoupageRepasId']] = [];
          }
          acc[platDetaille['decoupageRepasId']].push(platDetaille);
          return acc;
        }, {});

      Object.keys(platsDetailsGroupedByDecoupageRepas).forEach(decoupageRepasId => {
        const platsByDecoupage = platsDetailsGroupedByDecoupageRepas[decoupageRepasId].sort((a, b) => a.ordre - b.ordre);
        platsByDecoupage.forEach((subRow: PrintCoutMatiereRowDTO) => {
          result.push({'col1': '', 'col2':subRow.repasOrPlat, 'col3':this.utilsService.roundFloat(subRow.coutMoyen,4)});
          coutRepas += subRow.coutMoyen;
          coutJournalierMoyen += subRow.coutMoyen;
        });
      });

      coutJournalier += coutRepas;
      result.push({'col1': '', 'col2':'Coût repas', 'col3':this.utilsService.roundFloat(coutRepas,4)});
    });

    if(repasNbByDay > 1){
      result.push({'col1':'', 'col2':'Coût journalier', 'col3':this.utilsService.roundFloat(coutJournalier,4)});
    }

    result.push(this.blanckRow());
    result.push(this.blanckRow());
    result.push({'col1':'', 'col2':'Coût journalier moyen', 'col3':this.utilsService.roundFloat(coutJournalierMoyen/nbDays,4)});
    result.push(this.blanckRow());
    result.push(this.blanckRow());
    result.push({'col1':'', 'col2':'*Coût du plat pondéré avec taux de prise', 'col3':''});

    return result;
  }

  private getSheetCostByDay(sheetCostByDay: PrintCoutMatiereRowDTO[],  title: any){

    let result : any[] =[];
    let coutMoyen: number =0;

    result.push(title);
    result.push(this.blanckRow());
    result.push(this.headerRow(false));

    sheetCostByDay.forEach( (row: PrintCoutMatiereRowDTO) => {
      result.push({'col1': row.date, 'col2':row.repasOrPlat, 'col3': this.utilsService.roundFloat(row.coutMoyen,4) });
      coutMoyen += row.coutMoyen;
    });

    if(sheetCostByDay.length > 0){
      result.push({'col1': '', 'col2':'Coût journalier moyen', 'col3': this.utilsService.roundFloat(coutMoyen/sheetCostByDay.length,4)});
    }

    result.push(this.blanckRow());
    result.push(this.blanckRow());
    result.push({'col1':'', 'col2':'*Coût journalier pondéré avec taux de prise', 'col3':''});

    return result;
  }

  private getTitleSheet(additionalProperties: any){
    const offreAlim: string = additionalProperties.offreAlim;
    const prestation: string = additionalProperties.prestation;
    const regime: string = additionalProperties.regime;

    const title: string = 'Offre alimentaire : '.concat(offreAlim)
      .concat(', Prestation : ')
      .concat(prestation)
      .concat(', Régime : ')
      .concat(regime);

    const titleObj: any = {'col1':title, 'col2':'', 'col3':''};

    return titleObj;
  }

  private blanckRow(){
    return {'col1': '', 'col2':'', 'col3':''}
  }

  private headerRow(isSheetCostByDayDetail :boolean){
    return {'col1': 'Date', 'col2':'Repas', 'col3':isSheetCostByDayDetail?'*Coût plat':'*Coût journalier' }
  }

  private formatDate(date): string {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day,month,year].join('-');
  }



}
