import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Credentials} from '../../core/services/security/credentials';
import {HttpErrorResponse} from '@angular/common/http';
import {RequirementsService} from '../../core/services/requirements.service';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {Router} from '@angular/router';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('loginState', [

      state('inactive', style({
        transform: 'translateX(0)'
      })),

      transition('inactive => *', [
        animate(200, keyframes([

          style({opacity: 0, transform: 'translateX(-20px)', offset: 0}),
          style({opacity: 0.5, transform: 'translateX(10px)', offset: 0.5}),
          style({opacity: 1, transform: 'translateX(0px)', offset: 1.0})

        ]))
      ]),

    ])

  ]
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {


  loginState: string = 'inactive';
  subLogin: Subscription;
  userLogin: string;
  userPassword: string;
  hide: boolean = true;
  loading = false;


  constructor(public utils: UtilsService,
              private prefsUserSvc: PreferencesUtilisateurService,
              private requirementsSvc: RequirementsService,
              private router: Router,
              private auth2Svc: Auth2Service) {

  }

  ngOnInit(): void {

  }


  login() {

    this.loginState = this.loginState === 'inactive' ? 'active' : 'inactive';
    this.loading = true;

    this.subLogin = this.auth2Svc.login(new Credentials(this.userLogin, this.userPassword)).pipe(
    ).subscribe(
      (token) => {

        const error = !token || token instanceof HttpErrorResponse || token.inError;


        // si l'authentification a échoué, on affiche un message d'erreur et on deconnecte l'utilisateur en cours
        if (error) {

          this.utils.handleError(error, true);
          this.auth2Svc.logout();

        } else {

          // on initalise l'utilisateur avec son environnement par defaut et ses preferences
          this.auth2Svc.initUser(token);

        }

        setTimeout(() => this.loading = false, 10000);

      }
    );
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLogin);
  }

  ngAfterViewInit(): void {
    //mettre le focus sur le login apres la construction de la vue
    //this.uLogin.first.nativeElement.focus();
  }

  openDialogMotDePasseOublie() {
    this.auth2Svc.announceOpenMotDePasseOublieDialog();
  }
}
