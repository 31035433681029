import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {LignePlanAlimGemrcn} from '../../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {transAnimationEnter} from '../../../../shared/ui/animations';

@Component({
  selector: 'yo-famille',
  templateUrl: './famille.component.html',
  styleUrls: ['./famille.component.scss'],
  animations: [
    trigger('animateTransition', [
      transition(':enter', [
        useAnimation(transAnimationEnter,{
          params:{
            opacityStart:0,
            opacityEnd:1,
            maxHeight:'500px',
            time:'0.7s',
          }
        })
      ])

    ])


  ]
})
export class FamilleComponent implements OnInit {

  showPlats=false;

  @Input() ligne : LignePlanAlimGemrcn;

  constructor(public utils:UtilsService) { }

  ngOnInit() {
  }

}
