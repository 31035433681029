import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {BoncfStatutDTO} from '../../dtos/boncf-statut-d-t-o';
import {PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from "../../constants";


@Injectable({
  providedIn: 'root'
})
export class BoncfStatutService extends GenericHandler<BoncfStatutDTO> {

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getEntityName(): string {
    return 'BonCf_Statut';
  }

  getTotalRecordsLabel(): string {
    return undefined;
  }

  getTitle(): string {
    return "GESTION DES BONS DE COMMANDE FOURNISSEUR";
  }

  getCreateNewObjectLabel(): string {
    return undefined;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): BoncfStatutDTO {
    return new BoncfStatutDTO();
  }

  /**
   * Récupérer tous les statuts disponibles
   */
  filterStatus() :GenericRequestSupplier{

    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();
    search.predicats=[];


    const predicat1 = new Predicat();
    predicat1.path = `${this.getEntityName().toLowerCase()}.actif`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat1);

    const sort = new Sort() ;
    sort.dir='asc';
    sort.path='ordre';
    search.sorts = [sort];

    grs.search = search;

    return grs;
  }

}
