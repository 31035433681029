import {Component, Input} from '@angular/core';

import {Router} from "@angular/router";
import {Node} from "../../../../../core/d3/models";


@Component({
  selector: '[nodeVisual]',
  template: `
    <svg:g [attr.transform]="'translate(' + node.x + ',' + node.y + ')'">
      <svg:circle
        class="node"
        [attr.fill]="'#4e5d6c'"
        cx="0"
        cy="0"
        [attr.r]="node.r">
      </svg:circle>

      <svg:text
        class="node-name cursor"        
        [attr.font-family]="'FontAwesome, Roboto'"
        [attr.font-size]="'12px'">       
        <a (click)="goToRoute(node)" title="{{node.value.nav.label}}">
          <!--{{node.value.nav.faUnicode}} -->
          {{node.value.nav.label}}
        </a>
      </svg:text>
    </svg:g>
  `,
  styleUrls: ['./node-visual.component.scss']

})
export class NodeVisualComponent {
  @Input('nodeVisual') node: Node;

  constructor(private router: Router) {

  }

  goToRoute(node: Node) {
    this.router.navigate(node.value.nav.route);
  }
}
