import {ObjectDTO} from "./object-dto";

export class FamillePlanAlimentaireDTO  extends ObjectDTO{

  libelle : string;
  code : string;
  couleur:number;
  actif:boolean;

}
