import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../core/utils/utils.service";
import {Auth2Service} from "../core/services/security/auth2.service";
import {Subscription} from "rxjs";
import {ChangelogDto} from "../core/dtos/changelog/changelog-dto";
import {ChangelogService} from "../core/services/changelog/changelog.service";

@Component({
  templateUrl: './changelogs.component.html',
  styleUrls: ['./changelogs.component.scss']
})
export class ChangelogsComponent implements OnInit, OnDestroy {

  logged: boolean = false;

  changelog: ChangelogDto;

  subLog: Subscription;
  subChangelog: Subscription;

  constructor(public utils: UtilsService, private auth2Svc: Auth2Service, private changelogSvc: ChangelogService) {
  }

  ngOnInit(): void {
    this.initLoggedSubscription();
    this.initChangelogSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLog);
    this.utils.unsubscribe(this.subChangelog);
  }

  initLoggedSubscription = (): void => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.logged = res);
  }

  scrollToLastestVersion = (): void => {
    const destinationPos = document.getElementById(`version-${this.changelog.latestVersion}`).getBoundingClientRect();
    const parent = document.getElementById(`changelog-content`);
    const parentPos = parent.getBoundingClientRect()
    parent.scroll({
      top: destinationPos.top - parentPos.top,
      left: destinationPos.left,
      behavior: 'smooth'
    });
  }

  initChangelogSubscription = (): void => {
    this.subChangelog = this.changelogSvc.fetchChangeLog().subscribe(res => this.changelog = res.one);
  }
}
