<dx-popup
  [showTitle]="true"
  title="Modifier l'adresse de livraison"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [fullScreen]="false"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
>
  <div class="row mg-b-15">
    <div class="col-md-3">
      <yo-button label="Réinitialiser l'adresse de livraison par défaut" buttonClass="cta-inner-secondary" (onClick)="updateDeliveryAddress()"></yo-button>
    </div>
  </div>
  <dx-data-grid
    [dataSource]="pointsDeLivraison"
    [remoteOperations]="true"
    keyExpr="id"
    width="100%"
    height="90%"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    (onRowClick)="onSelect($event)"
    #grid>
    <dxi-column dataField="libelle" cellTemplate="templateLibelle" caption="Point de livraison"></dxi-column>
    <dxi-column dataField="adresse" cellTemplate="templateAdresse" caption="Adresse"></dxi-column>
    <dxi-column dataField="livraisonDirecte" caption="Livraison directe" dataType="boolean"
                [filterValue]="filterValue" [allowFiltering]="true">
      <dxo-lookup [dataSource]="[{ value: true, text: 'Oui' }, { value: false, text: 'Non' }]"
                  valueExpr="value"
                  displayExpr="text"
                  [allowClearing]="false">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let cell of 'templateLibelle'">
      {{cell.row.data.libelle | uppercase}}
    </div>
    <div *dxTemplate="let cell of 'templateAdresse'">
      {{cell.row.data.adresse.adresse1}} {{cell.row.data.adresse.adresse2}} {{cell.row.data.adresse.adresse3}} {{cell.row.data.adresse.codePostal}} {{cell.row.data.adresse.ville | uppercase}}
    </div>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="single"></dxo-sorting>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} point(s) de livraison">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>


<dx-popup
  [showTitle]="true"
  title="Confirmation nouvelle adresse de livraison"
  [(visible)]="displayDialogConfirmation"
  [width]="500"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [closeOnOutsideClick]="true"
  (onHiding)="closeDialogConfirmation()"
>
  <div class="text-center">
    <div>
      <b><span>{{plcSelected?.adresse?.nom}}</span></b>
    </div>

    <div *ngIf="plcSelected?.adresse?.adresse1">
      <span>{{plcSelected?.adresse?.adresse1}}</span>
    </div>

    <div *ngIf="plcSelected?.adresse?.adresse2">
      <span>{{plcSelected?.adresse?.adresse2}}</span>
    </div>

    <div *ngIf="plcSelected?.adresse?.adresse3">
      <span>{{plcSelected?.adresse?.adresse3}}</span>
    </div>

    <div>
      <span>{{plcSelected?.adresse?.codePostal}}</span>&nbsp;
      <span>{{plcSelected?.adresse?.ville}}</span>
    </div>

    <div *ngIf="plcSelected?.adresse?.telephone || plcSelected?.adresse?.fax">
      Tel : <span *ngIf="plcSelected?.adresse?.telephone">{{plcSelected?.adresse?.telephone}}</span>
      &nbsp;
      Fax : <span *ngIf="plcSelected?.adresse?.fax">{{plcSelected?.adresse?.fax}}</span>
    </div>
    <div *ngIf="plcSelected?.adresse?.email">
      Email : <span>{{plcSelected?.adresse?.email}}</span>
    </div>
  </div>

  <div class="row mg-t-10">
    <div class="col-md-12 text-right">
      <yo-button type="submit" label="Confirmer" (onClick)="updateDeliveryAddress()"></yo-button>
    </div>
  </div>
</dx-popup>
