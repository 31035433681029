<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Alimentation du référentiel</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de gérer à travers cette étape la combinaison des différents conditionnements et des différentes variantes pour le site local
        <yo-site *ngIf="currentSite" [siteLibelle]="currentSite.libelle" [siteId]="currentSite.id"></yo-site>
        qui vous ont été proposées à l'étape précédente. Cette étape est facultative dans le cas où
        vous disposez déjà de combinaisons. L'ajout d'une nouvelle association se réalise en appuyant sur le bouton + en haut à droite du tableau concerné, une
        ligne apparaît alors pour la compléter.
      </p>
      <div class="custom-button-container full-size" *ngIf="displayForm">
        <dx-lookup
          class="flex-element"
          [dataSource]="workingConditionnements"
          displayExpr="libelle"
          [searchEnabled]="true"
          (onValueChanged)="updateVariantes($event)"
          [(value)]="conditionnementSelected"
        >
        </dx-lookup>
        <dx-tag-box
          class="flex-element"
          [items]="workingVariantes"
          multiline="false"
          displayExpr="libelle"
          [showSelectionControls]="true"
          [(value)]="variantesSelected"
          applyValueMode="useButtons"
          [disabled]="!enableVariantes"
        >
        </dx-tag-box>
        <yo-button
          (onClick)="addConditionnementVariante()"
          iconClass="fas fa-save"
          buttonClass="cta-success"
        ></yo-button>
      </div>
      <div class="custom-button-container-right full-size" *ngIf="!displayForm">
        <yo-button
          (onClick)="toogleDisplayForm()"
          iconClass="fas fa-plus"
          buttonClass="cta-secondary">
        </yo-button>
      </div>
      <div class="container-table">
        <dx-data-grid
          [dataSource]="conditionnementsVariantes"
          keyExpr="id"
          width="100%"
          height="100%"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          columnResizingMode="widget"
          #grid>
          <dxi-column dataField="conditionnement.libelle" caption="Conditionnement" cellTemplate="conditionnementTemplate"
                      [filterOperations]="['contains']"
                      [allowEditing]="true"
                      [allowFiltering]="true">
          </dxi-column>
          <dxi-column dataField="variantes" caption="Variante" cellTemplate="varianteTemplate"
                      [filterOperations]="['contains']"
                      [allowEditing]="true"
                      [allowFiltering]="true">
          </dxi-column>

          <div *dxTemplate="let cell of 'conditionnementTemplate'">
            {{ cell.row.data.conditionnement ? cell.row.data.conditionnement.libelle : '' }}
          </div>

          <div *dxTemplate="let cell of 'varianteTemplate'">
            {{ getVariantesForConditionnement(cell.row.data) }}
          </div>

          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"></dxo-sorting>

          <dxo-pager
            [showPageSizeSelector]="true"
            [showNavigationButtons]="false"
            [allowedPageSizes]="[20, 50, 100, 200]"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} combinaison(s) de conditionnement(s) et de variante(s)">
          </dxo-pager>

        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 4/13 : Alimentation du référentiel - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button title="Passer à l'étape suivante"
            class="mg-r-5"
            type="button"
            (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
