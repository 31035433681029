import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../core/utils/utils.service';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {SecteurDTO} from '../../core/dtos/secteur-dto';
import {FournisseurDTO} from '../../core/dtos/fournisseur-d-t-o';
import {SecteurFournisseurDTO} from '../../core/dtos/secteurfournisseur-dto';
import {SecteursFournisseursService} from '../../core/services/entities/secteurs-fournisseurs.service';
import {UniteDeProduction__LivraisonDTO} from '../../core/dtos/unite-de-production__livraison-dto';
import {AtelierDTO} from '../../core/dtos/atelier-d-t-o';
import {UniteDeProduction_Planning_EquipeDTO} from '../../core/dtos/unite_de_production__planning__equipe_d_t_o';
import {UnitesDeProductionService} from '../../core/services/entities/unites-de-production.service';
import {SiteDTO} from '../../core/dtos/site-dto';
import {ZoneDeStockageDTO} from '../../core/dtos/zone-de-stockage-dto';
import {EquipesService} from "../../core/services/entities/equipes.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";


@Injectable()
export class UniteProductionResolverService implements Resolve<UniteDeProductionSupplier> {

  constructor(private udpSvc: UnitesDeProductionService,
              private secteursFournisseursSvc: SecteursFournisseursService,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private equipesSvc: EquipesService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService) {
  }

  /**
   * Charger les données necessaires àl'affichage de l'unite de production
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<UniteDeProductionSupplier>}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UniteDeProductionSupplier> | Promise<UniteDeProductionSupplier> | UniteDeProductionSupplier {

    const udps = new UniteDeProductionSupplier();
    const idUniteDeProduction: number = +route.paramMap.get('idUniteDeProduction');
    let obsUniteDeProduction$ = this.gds.getOne('uniteDeProduction', idUniteDeProduction);
    let obsSecteurList$ = this.getSecteurs();
    let obsAteliersList$ = this.getAteliers();

    let obsLivraisonsOfUDP$ = this.secteursFournisseursSvc.findLivraisonsOfUniteDeProduction(idUniteDeProduction);
    let obsFabricationsOfUDP$ = this.udpSvc.findFabrication(idUniteDeProduction);
    let obsZonesStockageOfUDP$ = this.udpSvc.getZoneDeStockageByIdUdp(idUniteDeProduction);
    let obsZonesStockage$ = this.getZonesDeStockage();

    // MODIFICATION, on recupere l'udp du back, on recupere les secteurs
    if (this.utils.isNumberGt0(idUniteDeProduction)) {

      const initAll$ = combineLatest([obsSecteurList$, obsUniteDeProduction$, obsLivraisonsOfUDP$,
        obsAteliersList$, obsFabricationsOfUDP$, obsZonesStockageOfUDP$, obsZonesStockage$]);

      return initAll$.toPromise().then(items => {
        if (!this.utils.isResponseSupplierError(items[0]) && !this.utils.isResponseSupplierError(items[1])) {
          udps.secteurList = items[0].allSecteurs;
          udps.uniteDeProduction = items[1].one;
          udps.livraisonsOfUDP = items[2].resultList;

          if (!this.utils.isCollectionNullOrEmpty(udps.livraisonsOfUDP)) {
            udps.livraisonsOfUDP.forEach(item => item.delaiLivraisonHeureLimite = this.utils.convertNumberDateToDate(item.delaiLivraisonHeureLimite))
          }
          udps.ateliersList = items[3].allAteliers;
          udps.fabricationsOfUDP = items[4].resultList;
          udps.zonesStockageOfUDP = items[5].resultList;
          udps.zonesStockageList = items[6].allZonesDeStockage;
          //si on a des secteurs associés à l'unité de prod, on recupere les fournisseurs associés.
          if (!this.utils.isCollectionNullOrEmpty(udps.uniteDeProduction.uniteDeProduction__secteurList)) {
            let udpSecteurs: SecteurDTO[] = [];
            udpSecteurs = udps.uniteDeProduction.uniteDeProduction__secteurList.map(item => {
              let secteur = new SecteurDTO();
              secteur.id = item.idSecteur;
              return secteur;
            });

            return this.secteursFournisseursSvc.findSecteursFournisseursBySecteurs(udpSecteurs)
              .toPromise()
              .then(response => {
                if (!this.utils.isResponseSupplierError(response)) {
                  udps.secteurFournisseurList = response.resultList;
                }
                return udps;
              });
          }
          return udps;
        }
      });
    }
    // CREATION, on créé l'udp depuis le front (seulement fiche identite)
    else {
      udps.uniteDeProduction = this.initUniteDeProduction();
      return udps;
    }
  }

  getAteliers = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allAteliers(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `)
  }

  getSecteurs = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allSecteurs(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            site {
                id,
                libelle,
            },
        }
      }
    `);
  }

  initUniteDeProduction(): UniteDeProductionDTO {
    //on créé forcement l'unité de production et ses adresses sur le site principal de l'utilisateur
    let site = new SiteDTO();
    //on initialise l'up avec ses adresses
    const uniteDeProduction = new UniteDeProductionDTO(0, site);
    uniteDeProduction.libelle = 'UP';
    uniteDeProduction.code = 'UP';
    uniteDeProduction.site = site;

    return uniteDeProduction;
  }

  getZonesDeStockage = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allZonesDeStockage(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            actif
        }
      }
    `)
  }
}

export class UniteDeProductionSupplier {
  uniteDeProduction: UniteDeProductionDTO;
  secteurList: SecteurDTO[] = [];
  fournisseurList: FournisseurDTO[] = [];
  secteurFournisseurList: SecteurFournisseurDTO[] = [];
  livraisonsOfUDP: UniteDeProduction__LivraisonDTO[] = [];
  fabricationsOfUDP: UniteDeProduction_Planning_EquipeDTO[] = [];
  ateliersList: AtelierDTO[] = [];
  zonesStockageOfUDP: ZoneDeStockageDTO[] = [];
  zonesStockageList: ZoneDeStockageDTO[] = [];
}

