import {ObjectDTO} from "./object-dto";
import {SiteDTO} from "./site-dto";

export class UniteDeMesureDTO extends ObjectDTO {

  // unite de mesure
  code: string;
  libelle: string;
  abreviation: string;
  actif: boolean;

  // 1=Masse, 2=Volume
  type:number;

  constructor(libelle?: string, id?: number, code?: string, abreviation?: string, actif?: boolean, site? : SiteDTO, type?:number) {
    super();
    this.id = id;
    this.code = code;
    this.libelle = libelle;
    this.abreviation = abreviation;
    this.actif = actif;
    this.site = site;
    this.type=type;
  }
}
