import {Component, Input, OnInit} from "@angular/core";
import {VersionDto} from "../../core/dtos/changelog/version-dto";

@Component({
  selector: 'yo-numberVersion',
  templateUrl: './number-version.component.html',
  styleUrls: ['./number-version.component.scss']
})
export class NumberVersionComponent implements OnInit {

  @Input()
  versions: VersionDto[]

  ngOnInit(): void {
  }

  scrollToVersion = (targetId: string): void => {
    const destinationPos = document.getElementById(targetId).getBoundingClientRect();
    const parent = document.getElementById(`changelog-content`);
    const parentPos = parent.getBoundingClientRect()
    parent.scroll({
      top: destinationPos.top - parentPos.top,
      left: destinationPos.left,
      behavior: 'smooth'
    });
  }

}
