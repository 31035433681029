import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {UtilisateurService} from '../../../core/utilisateur.service';
import {CatalogueAchatDTO} from '../../../core/dtos/catalogue-achat-dto';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {SecteursFournisseursService} from '../../../core/services/entities/secteurs-fournisseurs.service';
import {FournisseurService} from '../../../core/services/entities/fournisseur.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {TYPE_LIST_UDP} from "../../../core/constants";


@Injectable()
export class ArticlesresolverService implements Resolve<ArticleSupplier> {

  constructor(private http: HttpClient,
              public auth2Svc: Auth2Service,
              private produitRouteMapSvc: ProduitRouteMapService,
              private gds: GenericDatagridService,
              private produitsSvc: ProduitsService,
              private secteurFournisseurSvc: SecteursFournisseursService,
              private fournisseurSvc: FournisseurService,
              private produitDeclinaisonSvc:ProduitDeclinaisonService,
              private utils: UtilsService,
              private userService: UtilisateurService,
              private udpSvc: UnitesDeProductionService) {
  }

  /**
   *  l'id produit peut etre récupéré du module produit ou du module fournisseur
   */
  getIdProduitFromRoute = (route:ActivatedRouteSnapshot) => {
    let idProduit=0;

    // route provenance fournisseur
    idProduit= +route.paramMap.get('idProduit');

    // route provenance produit
    if(!this.utils.isNumberGt0(idProduit)){
      idProduit = +route.parent.paramMap.get('idProduit');
    }

    return idProduit;
  };

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleSupplier> | Promise<ArticleSupplier> | ArticleSupplier => {

    let articlesSupplier: ArticleSupplier = new ArticleSupplier();

    // l'id produit peut etre récupéré du module produit ou du module fournisseur
    const idProduit=this.getIdProduitFromRoute(route);

    const produit$ = this.gds.getOne(this.produitsSvc.getEntityName(), +idProduit);
    const uniteDeProductionList$ = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const produitDeclinaisonList$ = this.gds.search(this.produitDeclinaisonSvc.filterProduitDeclinaisonList(+idProduit, this.produitDeclinaisonSvc.getEntityName()));
    const all$ = combineLatest([uniteDeProductionList$, produit$, produitDeclinaisonList$]);


    return all$.toPromise().then(data => {

      if (!this.utils.isResponseSupplierError(data[0]) && !this.utils.isResponseSupplierError(data[1])) {

        articlesSupplier.uniteDeProductionList = data[0].resultList as UniteDeProductionDTO[];
        articlesSupplier.produit = data[1].one as ProduitDTO;
        articlesSupplier.produitDeclinaisons = data[2].resultList as ProduitDeclinaisonDTO[];
        articlesSupplier.produit.produitDeclinaisons = articlesSupplier.produitDeclinaisons;
        return articlesSupplier;
      }
    });
  };




}

export class ArticleSupplier {
  produit: ProduitDTO;
  catalogueAchats: CatalogueAchatDTO[];
  uniteDeProductionList: UniteDeProductionDTO[];
  errorMessage: string;
  produitDeclinaisons: ProduitDeclinaisonDTO[];

}
