import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {GestionGemrcnService} from '../../core/services/gestiongemrcn/gestion-gemrcn.service';

@Component({
  selector: 'yo-gestiongemrcn-root',
  templateUrl: './gestiongemrcn-root.component.html',
  styleUrls: ['./gestiongemrcn-root.component.scss']
})
export class GestiongemrcnRootComponent implements OnInit, OnDestroy, AfterViewInit {

  private subModelesNutris: Subscription;

  constructor(private utils: UtilsService,private titleSvc:Title,
              private gds:GenericDatagridService,
              private ggSvc:GestionGemrcnService) { }

  ngOnInit() {
    this.titleSvc.setTitle('MODÈLES MENUS');
  }

  ngAfterViewInit(): void {
    this.subModelesNutris= this.ggSvc.getAllModelesNutrisOfUser().subscribe(response=>{
      this.ggSvc.announceModelesNutris(response.resultList);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subModelesNutris);
  }
}
