import {Injectable} from "@angular/core";
import {Subject} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  private subjectDisplayMessageDialog = new Subject();
  displayMessageDialog$ = this.subjectDisplayMessageDialog.asObservable();


  announceDisplayMessageDialog(object : any) {
    this.subjectDisplayMessageDialog.next(object);
  }
}
