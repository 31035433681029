import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-gestion-lbs-root',
  templateUrl: './gestion-listes-besoins-root.component.html',
  styleUrls: ['./gestion-listes-besoins-root.component.scss']
})
export class GestionListesBesoinsRootComponent implements OnInit {

  constructor(private title : Title) { }

  ngOnInit() {
    this.title.setTitle("GESTION DES LISTES DE BESOINS");
  }
}
