import {MEquipe} from './m-equipe';
import {ObjectDTO} from '../../dtos/object-dto';

export class MZoneProduction extends ObjectDTO {

  id: number;

  udpId: number;

  libelle: string;

  equipesList: MEquipe[];

}
