import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {EngagementLotMarcheSupplier} from './engagement-lot-marche-resolver.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheLmupCaDto} from '../../../../../core/dtos/gerstion-marche/lot-marche-lmup-ca-dto';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import CustomStore from 'devextreme/data/custom_store';
import {Sort} from '../../../../../core/suppliers/generics/generic-request-supplier';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {find as _find} from 'lodash';
import {DxDataGridComponent} from 'devextreme-angular';
import {LotmarcheUpCaService} from '../../../../../core/services/entities/lotmarche-up-ca.service';
import {DATAGRID_ROW_TYPES, DevextremeService} from '../../../../../core/services/technique/devextreme.service';
import {UI_COLORS} from '../../../../../core/constants';

@Component({
  selector: 'yo-dialog-engagement-lot-marche',
  templateUrl: './dialog-engagement-lot-marche.component.html',
  styleUrls: ['./dialog-engagement-lot-marche.component.scss']
})

export class DialogEngagementLotMarcheComponent implements OnInit, OnDestroy {
  @ViewChild('gridLotMarcheEngagement') gridLotMarcheEngagement: DxDataGridComponent;

  lotMarche: LotMarcheDTO;
  subscriptionRoute: Subscription;

  customStore: CustomStore;
  lmupcaList: LotMarcheLmupCaDto[];

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private gfs: GenericFormService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private lotMarcheUpCaSvc: LotmarcheUpCaService,
              private dxSvc: DevextremeService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initCustomStore = (idLotMarche: number) => {
    const cs = new CustomStore({
      key: 'lmupcaId',
      load: (loadOptions: any) => {
        // page
        const pageSize: number = loadOptions.take || this.gridLotMarcheEngagement.instance.pageSize();
        const page: number = this.gridLotMarcheEngagement.instance.pageIndex();
        // filters
        const filters = this.dxSvc.dxToGrsFilters(loadOptions.filter);
        // sorts
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        return this.gds.search(this.gestionLotMarcheSvc.filterLmupCaList(idLotMarche, page, pageSize, filters, sorts)).toPromise().then(response => {
          this.lmupcaList = response.resultList;
          return {
            data: this.lmupcaList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => {
        const row: LotMarcheLmupCaDto = _find(this.lmupcaList, {'lmupcaId': key});
        Object.keys(values).forEach(key => row[key] = values[key]);
        row.modificationManuelle = true;
        return this.gfs.saveOne(row, this.lotMarcheUpCaSvc.getEntityName()).toPromise().then(response => {
        });
      }
    });
    return cs;
  };

  routeSubscription = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { engagementLotMarcheSupplier: EngagementLotMarcheSupplier }) => {
        this.lotMarche = data.engagementLotMarcheSupplier.lotMarche;
        this.customStore = this.initCustomStore(this.lotMarche.id);
        this.gestionLotMarcheSvc.announceOpenDialogLotMarche(this.lotMarche, TAB_NAME_LM.engagement);
      });
  };

  isDisabledSaveBtn = () => {
    if (this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id)) {
      return false;
      // return [DATASOURCE].length === 0
    } else {
      return true;
    }
  };

  closeDialog = () => {
    this.gestionLotMarcheSvc.announceCloseDialogLotMarche(true);
  };

  onCellPrepared = (event: any) => {
    // si cell est un header
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      // si colonne éditable
      if (event.column.allowEditing) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }
  };
}
