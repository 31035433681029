import {UniteDeProduction_Planning_EquipeDTO} from '../../../../core/dtos/unite_de_production__planning__equipe_d_t_o';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {MJour} from '../../../../core/models/gestion-unites-production/m-jour';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {TYPE_TACHE} from '../../../../core/services/entities/type-tache.service';
import {SENS_JOUR_DECALAGE} from "../../../../core/constants";

export class PlanningEquipesSupplier {

  uniteDeProduction: UniteDeProductionDTO;
  cells: Map<string, UniteDeProduction_Planning_EquipeDTO>;
  rows: UniteDeProduction__EquipeDTO[];
  cols: MJour[];
  typeTache: TYPE_TACHE;
  maxJoursDecalage: number;
  sensJourDecalage: SENS_JOUR_DECALAGE;


  constructor(uniteDeProduction: UniteDeProductionDTO,
              cells: Map<string, UniteDeProduction_Planning_EquipeDTO>,
              rows: UniteDeProduction__EquipeDTO[],
              cols: MJour[],
              typeTache: TYPE_TACHE,
              maxJoursDecalage: number,
              sensJourDecalage: SENS_JOUR_DECALAGE = SENS_JOUR_DECALAGE.MOINS
  ) {
    this.uniteDeProduction = uniteDeProduction;
    this.cells = cells;
    this.rows = rows;
    this.cols = cols;
    this.typeTache = typeTache;
    this.maxJoursDecalage = maxJoursDecalage;
    this.sensJourDecalage = sensJourDecalage;
  }
}
