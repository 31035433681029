import {Injectable} from "@angular/core";
import {HttpService} from "../../technique/http.service";
import {Subject} from "rxjs/index";
import ModeleConditionnementPlatDTO from "../../../dtos/conditionnement/modele-conditionnement-plat-dto";

@Injectable({
  providedIn: 'root'
})
export class UdpMcpProduitsService {

  private subjectBindWithPlatsAsked = new Subject<ModeleConditionnementPlatDTO>();
  bindWithPlatsAsked$ = this.subjectBindWithPlatsAsked.asObservable();

  constructor(private httpSvc: HttpService) {}

  findAllByMcpId = (mcpId: number) => this.httpSvc.get(`dolrest/conditionnements/udp-mcp-produits/listByIdMcp/${mcpId}`);

  findAll = () => this.httpSvc.get(`dolrest/conditionnements/udp-mcp-produits/listAll`);

  openDialogBindWithPlats = (model: ModeleConditionnementPlatDTO) => {
    this.subjectBindWithPlatsAsked.next(model);
  };

}
