import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';


export const URL_POST_SAVE_PARAMETERS_IMAGES = `dolrest/utils/images/save`;

@Injectable({
  providedIn: 'root'
})
export class ParametresService {

  constructor(private http: HttpClient,
              private utils: UtilsService) {
  }


  saveImages(bgFile: File, logoFile:File ) {
    const fd = new FormData();
    fd.set('bgFile', bgFile);
    fd.set('logoFile',logoFile);

    return this.http.post(URL_POST_SAVE_PARAMETERS_IMAGES, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}
