import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ProduitContrainteAlimDTO} from '../../dtos/produit-contrainte-alim-dto';
import {TextureDTO} from '../../dtos/texture-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {GenericHandler} from '../generics/generic-handler';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {ContrainteModel} from '../../models/contrainte-model';

@Injectable({
  providedIn: 'root'
})
export class CmcContrainteAlimService extends GenericHandler<ContratMenuConvive__ContrainteAlimDTO> {

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);


  }

  createEmptyDTO(): ContratMenuConvive__ContrainteAlimDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: TextureDTO): string {
    return ``;
  }

  getEntityName(): string {
    return "ContratMenuConvive__ContrainteAlim";
  }

  getFields(dto: ProduitContrainteAlimDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return true;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "";
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  createEmptyFromContrainte(contrainte:ContrainteModel): ContratMenuConvive__ContrainteAlimDTO {
    const cca = new ContratMenuConvive__ContrainteAlimDTO();

    cca.textureId=contrainte.texture.id;
    cca.regimeId=contrainte.regime.id;
    cca.cmcId=0;

    return cca;
  }
}
