import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, switchMap} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {MenusToolbarService} from './menus-toolbar.service';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {DATES_FORMAT, USER_PREFERENCE} from '../../constants';
import * as moment from 'moment';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {PlanningMenusDTO} from '../../dtos/gestionmenus/planning/planning-menus-dto';
import {CelluleTableauDTO} from '../../dtos/gestionmenus/planning/cellule-tableau-dto';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import {MenuEffectifRepasDTO} from '../../dtos/menu-effectif-repas-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {SizeTableauDTO} from '../../dtos/gestionmenus/planning/size-tableau-dto';
import {TreeNode} from 'primeng/api';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {FrontStorageService} from '../storage/front-storage.service';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {DecoupageRepasDTO} from '../../dtos/decoupagerepas-dto';
import {RepasDTO} from '../../dtos/repas-dto';
import {ColonneDateDTO} from '../../dtos/gestionmenus/planning/colonne-date-dto';
import {HttpService} from "../technique/http.service";
import {DisplayEgalimResponse} from "../../dtos/gestionmenus/planning/egalim/display-egalim-response-dto";
import {FamilleGemrcnDTO} from "../../dtos/famille-gemrcn-dto";

export const URL_GET_PLANNING_MENUS = `dolrest/gestionmenus2/getPlanningMenus`;
export const URL_GET_DELETE_MENUS = `dolrest/gestionmenus2/menuComposition/delete`;
export const URL_POST_COUT_PERIODE = `dolrest/gestionmenus2/repas/couts-periode`;
export const URL_POST_MENUS_COMPOS_DATES = `dolrest/gestionmenus2/getMonthMenuCompoDates`;
export const URL_POST_COMPUTE_COUTS = `dolrest/gestionmenus2/computeCouts`;
export const URL_POST_COMPUTE_COUTS_UNITAIRES_MOYENS_PERIODE = `dolrest/gestionmenus2/computeCoutsUnitairesMoyensPeriode`;
export const URL_POST_COMPUTE_COUTS_DECOUPAGE_REPAS_PERIODE = `dolrest/gestionmenus2/computeCoutsDecoupageRepasPeriode`;
export const URL_POST_SAVE_MENU_EFFECTIF_REPAS = `dolrest/gestionmenus2/saveMenuEffectifRepas`;
export const URL_POST_COMPUTE_APPELLATIONS_PERIODE = `dolrest/gestionmenus2/computeAppellationsPeriode`;
export const URL_POST_COMPUTE_ALLERGENES_PERIODE = `dolrest/gestionmenus2/computeAllergenesPeriode`;
export const URL_POST_FILTERS_DIALOG_SEARCH_PLATS = `dolrest/gestionmenus2/filtersDialogSearchPlats`;

export const URL_GET_VALIDATION_MENUS = `dolrest/gestionmenus2/valider-menus`;
export const URL_GET_COUTS_REPAS_PERIODE=`dolrest/gestionmenus2/couts-repas-periode`;
export const URL_GET_COUTS_REPAS_DETAILS=`dolrest/gestionmenus2/couts-repas-details`;
export const URL_GET_ANALYSE_EGALIM = 'dolrest/gestionmenus2/findAnalyseEgalim';
export const URL_GET_VALID_DATES = 'dolrest/gestionmenus2/all-valid-dates-menus';
export const URL_GET_JOURS_SEMAINES_BY_PLANNING_FOR_MENU = 'dolrest/gestionmenus2/find-jours-semaine-by-planning-for-menu';

@Injectable({
  providedIn: 'root'
})
export class MenusPlanning2Service {

  drWidthLibelle=200;
  drWidthFamGemrcn=50;
  drWidthCoutsUnitaires=70;
  drWidthCoutsUnitairesPonderes=70;
  drWidthAppellations=50;
  drWidthAllergenes=70;
  drWidthTauxDePrise=70;
  decoupageRepasWidth=0;
  repasActif:RepasDTO;

  planningMenus: PlanningMenusDTO = new PlanningMenusDTO();

  private subjectRechercheProduitsSimilaires = new Subject<MenuCompositionDTO>();
  rechercheProduitsSimilaires$ = this.subjectRechercheProduitsSimilaires.asObservable();

  private subjectEffectifRepas = new BehaviorSubject<MenuCompositionDTO[]>([]);
  effectifRepas$ = this.subjectEffectifRepas.asObservable();

  private subjectAnalyseCouts = new BehaviorSubject(undefined);
  analyseCouts$ = this.subjectAnalyseCouts.asObservable();

  private subjectAnalyseEgalim = new BehaviorSubject(undefined);
  analyseEgalim$ = this.subjectAnalyseEgalim.asObservable();

  private subjectCalculerCoutDecoupage = new Subject<CelluleTableauDTO>();
  calculerCoutDecoupage$ = this.subjectCalculerCoutDecoupage.asObservable();

  private subjectCalculerCoutGlobalPeriode = new BehaviorSubject(undefined);
  calculerCoutGlobalPeriode$ = this.subjectCalculerCoutGlobalPeriode.asObservable();

  private subjectSearchResults = new BehaviorSubject(undefined);
  searchResults$ = this.subjectSearchResults.asObservable();

  // annoncer que les preferences du menu ont été changées
  private subjectChangeSettings = new BehaviorSubject(undefined);
  changeSettings$ = this.subjectChangeSettings.asObservable();

  private subjectDecoupageRepasDisplaySettings = new Subject<TreeNode[]>();
  decoupageRepasDisplaySettings$ = this.subjectDecoupageRepasDisplaySettings.asObservable();


  private subjectInputRecherchePlats = new Subject<DialogRecherchePlatsSupplier>();
  inputRecherchePlats$ = this.subjectInputRecherchePlats.asObservable();

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private menusToolbarSvc: MenusToolbarService,
              private prefUserSvc: PreferencesUtilisateurService,
              private frontStorageSvc: FrontStorageService,
              private utils: UtilsService) {
  }

  findAnalyseEgalim = (idContratMenuConvive: number, effectif: number, startDate: Date, stopDate: Date, egalimThresholdBio?: number, egalimThresholdSustainableProducts?: number): Observable<ResponseWrapper<DisplayEgalimResponse>> => {
    let url: string = `${URL_GET_ANALYSE_EGALIM}?idContratMenuConvive=${idContratMenuConvive}&effectif=${effectif}&startDate=${startDate.getTime()}&stopDate=${stopDate.getTime()}`;
    if (egalimThresholdBio !== null && egalimThresholdBio !== undefined && egalimThresholdBio >= 0)
      url += `&egalimThresholdBio=${egalimThresholdBio}`;
    if (egalimThresholdSustainableProducts !== null && egalimThresholdSustainableProducts !== undefined && egalimThresholdSustainableProducts >= 0)
      url += `&egalimThresholdSustainableProducts=${egalimThresholdSustainableProducts}`;
    return this.httpSvc.get(url);
  }

  /**
   * Récupérer les éléments nécessaires à l'affichage du planning menu
   * @param startDate
   * @param cmcList
   */
  getPlanningMenus = (startDate: Date, cmcList: ContratMenuConviveDTO[], regime: ContratMenuConvive__ContrainteAlimDTO): Observable<ResponseWrapper<PlanningMenusDTO>> => {

    let idsCmc: number[] = [];
    if (!this.utils.isCollectionNullOrEmpty(cmcList)) {
      idsCmc = cmcList.map(item => item.id);
    }

    const dateStr = this.utils.getYYYYMMDD(moment(startDate));
    const nbJours = this.prefUserSvc.getPreferenceUtilisateur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS);
    const joursSemaine = this.prefUserSvc.getPreferenceUtilisateur(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE);
    const regimeId = regime && regime.id ? regime.id + '' : '0';

    return this.http.get(URL_GET_PLANNING_MENUS, {
      params: new HttpParams()
        .set('date', dateStr)
        .set('nbJours', nbJours.valeur)
        .set('idsContratMenuConvive', idsCmc.join(','))
        .set('joursSemaine', joursSemaine.valeur)
        .set('idCmcContrainteAlim', regimeId)
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };


  getMenuComposition = (cellule: CelluleTableauDTO, idxChoix: number) => {

    if (!this.utils.isNullOrEmpty(cellule)) {

      return cellule.mapMenuComposition[idxChoix];
    }

    return undefined;
  };

  announcedecoupageRepasDisplaySettings = (setting: TreeNode[]) => {
    this.subjectDecoupageRepasDisplaySettings.next(setting);
  };

  announceInputRecherchePlats = (input: DialogRecherchePlatsSupplier) => {
    this.subjectInputRecherchePlats.next(input);
  };

  announceAnalyseCout = () => {
    this.subjectAnalyseCouts.next(undefined);
  };

  announceAnalyseEgalim = (): void => {
    this.subjectAnalyseEgalim.next(undefined);
  }

  announceCalculerCoutGlobalPeriode() {
    this.subjectCalculerCoutGlobalPeriode.next(undefined);
  };

  deleteMenusCompositions = (idsMenuComposition: number[]) => {

    return this.http.get(URL_GET_DELETE_MENUS, {
      params: new HttpParams()
        .set('idsMenuComposition', idsMenuComposition.join(','))
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  /**
   *  Récupérer un menu effectif repas à partir d'un contrat menu convive , d'un repas et d'une date
   * @param cmcId contrat menu convive id
   * @param jour
   * @param idRepas
   */
  getMenuEffectifRepas = (cmc: ContratMenuConviveDTO, jour: Date, idRepas: number): MenuEffectifRepasDTO => {

    if (!this.utils.isNullOrEmpty(cmc)
      && this.utils.isNumberGt0(jour)
      && this.utils.isNumberGt0(idRepas)
      && !this.utils.isCollectionNullOrEmpty(cmc.contratsMenusConvivesRepas)) {

      const cmcr = this.getContratMenuConviveRepas(cmc, jour, idRepas);

      if (cmcr) {

        const key = `${cmcr.id}-${moment(jour).format(DATES_FORMAT.YYYYMMDD)}`;
        return this.planningMenus.menuEffectifRepasMap[key];

      }

    }

    return undefined;
  };

  /**
   *  Récupérer  le contrat menu convive repas
   * @param cmcId contrat menu convive id
   * @param jour
   * @param idRepas
   */
  getContratMenuConviveRepas = (cmc: ContratMenuConviveDTO, jour: Date, idRepas: number): ContratMenuConviveRepasDTO => {

    if (
      !this.utils.isNullOrEmpty(cmc)
      && this.utils.isNumberGt0(jour)
      && this.utils.isNumberGt0(idRepas)
      && !this.utils.isCollectionNullOrEmpty(cmc.contratsMenusConvivesRepas)) {

      const cmcr = _find(cmc.contratsMenusConvivesRepas, {
        'repasId': idRepas,
        'idJourSemaine': moment(jour).isoWeekday()
      });

      if (cmcr) {

        return cmcr;

      }

    }

    return undefined;
  };


  getCoutPeriodeRepas = (dateList: ColonneDateDTO[], contratMenuConviveList: ContratMenuConviveDTO[], idRepas: number): Observable<ResponseWrapper<ObjectDTO>> => {

    const dates = this.getDateList(dateList);
    const idsCmc = contratMenuConviveList ? contratMenuConviveList.map(item => item.id) : [];
    const fd: FormData = new FormData();
    fd.set('dates', dates.join(','));
    fd.set('idsContratMenuConvive', idsCmc.join(','));
    fd.set('idRepas', idRepas + '');

    return this.http.post(URL_POST_COUT_PERIODE, fd).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };


  getMonthMenuCompoDates = (idContratMenuConvive: number, startYear: number, startMonth: number, nbMonths: number) => {

    const fd: FormData = new FormData();
    fd.set('startMonth', startMonth + '');
    fd.set('startYear', startYear + '');
    fd.set('nbMonths', nbMonths + '');
    fd.set('idContratMenuConvive', idContratMenuConvive + '');
    return this.http.post(URL_POST_MENUS_COMPOS_DATES, fd).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  /**
   * Calcul de la taille des cellules du planning
   *
   * @param idsContratMenuConvive nombre de contrat menu convive sélectionnés
   * @param celluleTableauMap
   * @param jwtUser               permet de récupérer les préférences et de calculer les tailles selon les preferences activées
   * @return
   */
  computeTableauSize = (planningMenu: PlanningMenusDTO, profil: number): SizeTableauDTO => {

    const tableauSize: SizeTableauDTO = new SizeTableauDTO();

    const countAllergenes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_ALLERGENES);
    const countAppellations = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_APPELLATIONS);
    const countFamillesGemrcn = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN);
    const tauxDePrise = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_TAUX_DE_PRISE);
    const coutsUnitaires = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
    const coutsUnitairesPonderes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES);

   this.decoupageRepasWidth=this.getDecoupageRepasWidth(countAllergenes,countAppellations,countFamillesGemrcn,tauxDePrise,coutsUnitaires,coutsUnitairesPonderes);

    tableauSize.thLevel1Width = this.decoupageRepasWidth  + 'px';
    tableauSize.tableWidth = this.decoupageRepasWidth + 'px';

    if (!this.utils.isNullOrEmpty(planningMenu) && !this.utils.isCollectionNullOrEmpty(planningMenu.contratMenuConviveList) && !this.utils.isCollectionNullOrEmpty(planningMenu.dateList)) {

      tableauSize.thLevel1Width = this.decoupageRepasWidth * planningMenu.contratMenuConviveList.length + "px";
      const tableWidth = (this.decoupageRepasWidth * planningMenu.contratMenuConviveList.length * planningMenu.dateList.length)+17;
      tableauSize.tableWidth = tableWidth + 'px';

      tableauSize.ligneAnalyseWidth = tableWidth - 50 + 'px';
      tableauSize.tableWidthInt=tableWidth;
    }

    tableauSize.thLevel2Width = this.decoupageRepasWidth + 'px';


    tableauSize.tdWidth = this.decoupageRepasWidth + 'px';

    //console.log('computeTableauSize', tableauSize, this.decoupageRepasWidth);

    return tableauSize;
  };


  /**
   * Calculer les couts d'un decoupage, d'un effectif , d'unt cout global et unitaire
   * @param cellule
   */
  computeCouts = (cellule: CelluleTableauDTO, menuCompo: MenuCompositionDTO): Observable<ResponseWrapper<number>> => {

    const dateMenu = this.utils.getYYYYMMDD(moment(cellule.dateMenu));
    const idCmcd = cellule.contratMenuConviveDecoupage.id;
    const idMenuCompo = menuCompo.id;

    const fd = new FormData();
    fd.set('dateMenu', dateMenu);
    fd.set('idCmcd', idCmcd + '');
    fd.set('idMenuCompo', idMenuCompo + '');

    return this.http.post(URL_POST_COMPUTE_COUTS, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };


  computeCoutsUnitairesMoyensPeriode = (dates: Date[], contratMenuConvives: ContratMenuConviveDTO[], idRepas: number): Observable<ResponseWrapper<number>> => {

    const datesYYYYMMDD = dates.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');

    const fd = new FormData();
    fd.set('dates', datesYYYYMMDD);
    fd.set('idsContratMenuConvive', contratMenuConvives ? contratMenuConvives.map(cmc => cmc.id).join(',') : '');
    fd.set('idRepas', idRepas + '');

    return this.http.post(URL_POST_COMPUTE_COUTS_UNITAIRES_MOYENS_PERIODE, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };

  computeCoutsDecoupagesRepas = (dates: number[], idCmc: number, idRepas: number) => {

    const datesYYYYMMDD = dates.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');

    const fd = new FormData();
    fd.set('dates', datesYYYYMMDD);
    fd.set('idContratMenuConvive', idCmc + '');
    fd.set('idRepas', idRepas + '');

    return this.http.post(URL_POST_COMPUTE_COUTS_DECOUPAGE_REPAS_PERIODE, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };

  /**
   * Enregistrer un menu effectif repas.
   * Mets à jour les effectifs prevus plats (taux de prise), le cout global
   * @param mer
   */
  saveMenuEffectifRepas = (mer: MenuEffectifRepasDTO): Observable<ResponseWrapper<MenuEffectifRepasDTO>> => {
    return this.http.post(URL_POST_SAVE_MENU_EFFECTIF_REPAS, mer).pipe(
      catchError(err => this.utils.handleError(err))
    );
  };

  computeAppellationsPeriode = (dates: number[], contratsMenusConvives: ContratMenuConviveDTO[], idRepas: number) => {

    const datesYYYYMMDD = dates.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');

    const fd = new FormData();
    fd.set('dates', datesYYYYMMDD);
    fd.set('idsContratMenuConvive', contratsMenusConvives ? contratsMenusConvives.map(cmc => cmc.id).join(',') : '');
    fd.set('idRepas', idRepas + '');

    return this.http.post(URL_POST_COMPUTE_APPELLATIONS_PERIODE, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };

  computeAllergenesPeriode = (dates: number[], contratsMenusConvives: ContratMenuConviveDTO[], idRepas: number) => {

    const datesYYYYMMDD = dates.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');

    const fd = new FormData();
    fd.set('dates', datesYYYYMMDD);
    fd.set('idsContratMenuConvive', contratsMenusConvives ? contratsMenusConvives.map(cmc => cmc.id).join(',') : '');
    fd.set('idRepas', idRepas + '');

    return this.http.post(URL_POST_COMPUTE_ALLERGENES_PERIODE, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };

  expandTree = (tree: TreeNode[]) => {
    tree.forEach(node => {
      this.expandRecursive(node, true);
    });
  };

  private expandRecursive = (node: TreeNode, isExpand: boolean) => {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  };


  checkAllRepasViewer = (repasViewerTree: TreeNode[]): TreeNode[] => {

    const selectedNodes = [];

    for (let repas of repasViewerTree) {

      selectedNodes.push(repas);

      for (let prestation of repas.children) {

        selectedNodes.push(prestation);
      }
    }

    return selectedNodes;
  };


  /**
   * Annoncer qu'une recherche de produits declinaisons a eu lieu
   * @param produitsDeclinaisons
   */
  announceSearchResults = (responseProduitsDeclinaisons: ResponseWrapper<ProduitDeclinaisonDTO>) => {
    this.subjectSearchResults.next(responseProduitsDeclinaisons);
  };

  /**
   *  maxItemPanier (nombre de slots vides sur la composante repas)
   * @param cell
   */
  getMaxItemsPanier = (cell: CelluleTableauDTO) => {
    // maxItemPanier (nombre de slots vides sur la composante repas)
    let count = 0;
    Object.keys(cell.mapMenuComposition).forEach(key => {
      if (!this.utils.isNullOrEmpty(cell.mapMenuComposition[key])) {
        count++;
      }
    });

    return cell.choixMax - count;
  };


  announceChangeSettings = () => {
    this.subjectChangeSettings.next(undefined);
  };

  /**
   * Récupérer les filtres de la grille de recherche de plats
   * @param response
   */
  getFiltersDialogSearchPlats = (response: DialogRecherchePlatsSupplier) => {


    const fd = new FormData();
    fd.set('jourRepas', this.utils.getYYYYMMDD(moment(response.dateMenu)));
    fd.set('idCmcd', response.cmcd.id + '');
    fd.set('searchMode', response.searchMode + '');

    return this.http.post(URL_POST_FILTERS_DIALOG_SEARCH_PLATS, fd).pipe(
      catchError(err => this.utils.handleError(err))
    );

  };

  computeTauxDePriseDecoupageRepas = (cellule: CelluleTableauDTO) => {

    let tdpTotal = 0;
    Object.keys(cellule.mapMenuComposition).forEach(key => {
      const mc: MenuCompositionDTO = cellule.mapMenuComposition[key];
      if (mc) {
        tdpTotal += parseInt(mc.tauxDePrise + '');
      }
    });
    cellule.tauxDePriseTotal = tdpTotal;
    console.log('tdp decoupage repas=', cellule.tauxDePriseTotal)
  };

  computeCoutPortionPondereDecoupageRepas = (cellule: CelluleTableauDTO) => {

    let cppTotal = 0;
    Object.keys(cellule.mapMenuComposition).forEach(key => {
      const mc: MenuCompositionDTO = cellule.mapMenuComposition[key];
      cppTotal+=this.computeCoutPortionPondereMenuCompo(mc);
    });
    cellule.coutUnitairePondere = cppTotal;
    console.log('cout unitaire pondere decoupage repas=', cellule.coutUnitairePondere);
  };

  computeCoutPortionPondereMenuCompo = (mc : MenuCompositionDTO): number => {
    let cpp = 0;
    if (mc && mc.udpPdPrixAchat) {
      const tdp = parseInt(mc.tauxDePrise + '') / 100;
      cpp = tdp * mc.udpPdPrixAchat;
      mc.coutPortionPondereEffPrev=cpp;
    }

    console.log('cout unitaire pondere menu compo=', mc.coutPortionPondereEffPrev);
    return cpp;
  };

  computeCoutUnitairePondereRepas = (prestation: ContratMenuConviveDTO, date: Date, idRepas: number, contrainteAlim: ContratMenuConvive__ContrainteAlimDTO) => {

    let coutUnitaire = 0;

    if (!this.utils.isNullOrEmpty(prestation) && !this.utils.isNullOrEmpty(date) && this.utils.isNumberGt0(idRepas)) {

      if (!this.utils.isNullOrEmpty(this.planningMenus)) {

        if (this.planningMenus.celluleTableauMap) {

          Object.keys(this.planningMenus.celluleTableauMap).forEach(key => {

            const cell: CelluleTableauDTO = this.planningMenus.celluleTableauMap[key];

            if (cell.contratMenuConviveDecoupage.contratMenuConviveId === prestation.id) {
              if (cell.mapMenuComposition) {
                Object.keys(cell.mapMenuComposition).forEach(key2 => {

                  const mc: MenuCompositionDTO = cell.mapMenuComposition[key2];
                  if (mc && this.utils.isDateEqualsYYYYMMDD(moment(mc.menuDecoupageDate.dateMenu), moment(date))
                    && cell.repasId === idRepas && mc.cmcContrainteAlimId === contrainteAlim.id) {

                    if (mc.udpPdPrixAchat && mc.udpPdPrixAchat>0) {
                      coutUnitaire += mc.udpPdPrixAchat * (mc.tauxDePrise / 100);
                    }

                  }

                });
              }
            }

          });
        }

      }

    }
    return coutUnitaire;
  };

  /**
   * Récupérer le cout théorique d'un repas à partir de la prestation / date / repas
   * @param prestation
   * @param date
   * @param idRepas
   * @param contrainteAlimentaire
   */
  getCoutTheoriqueRepas = (prestation: ContratMenuConviveDTO, date: Date, idRepas: number) => {


    if (!this.utils.isNullOrEmpty(prestation) && !this.utils.isNullOrEmpty(date) && this.utils.isNumberGt0(idRepas)) {

      if (!this.utils.isNullOrEmpty(this.planningMenus)) {

        if (this.planningMenus.celluleTableauMap) {

          for (const key of  Object.keys(this.planningMenus.celluleTableauMap)) {
            const cell: CelluleTableauDTO = this.planningMenus.celluleTableauMap[key];

            if (cell.contratMenuConviveDecoupage.contratMenuConviveId === prestation.id) {
              if (this.utils.isDateEqualsYYYYMMDD(moment(cell.dateMenu), moment(date))
                && cell.repasId === idRepas) {

                return cell.coutTheoriqueRepas;
              }
            }
          }


        }

      }

    }

    return 0;

  };

  isRepasExists = (prestation: ContratMenuConviveDTO, date: Date, idRepas: any) => {

    if (!this.utils.isNullOrEmpty(prestation) && !this.utils.isNullOrEmpty(date) && this.utils.isNumberGt0(idRepas)) {

      if (!this.utils.isNullOrEmpty(this.planningMenus)) {

        if (this.planningMenus.celluleTableauMap) {

          for (const key of  Object.keys(this.planningMenus.celluleTableauMap)) {
            const cell: CelluleTableauDTO = this.planningMenus.celluleTableauMap[key];

            if (cell.contratMenuConviveDecoupage.contratMenuConviveId === prestation.id) {
              if (this.utils.isDateEqualsYYYYMMDD(moment(cell.dateMenu), moment(date))
                && cell.repasId === idRepas) {

                return true;
              }
            }
          }
        }
      }
    }

    return false;
  };

  /**
   * Rendre une periode non modifiable
   * @param selectedDates
   * @param idsPrestation identifiants des prestations à valider (selon les offres alimentaires sélectionnés)
   * @param optionEcraserMenu
   * @param optionEcraserTauxDePrise
   * @param valid si true, on rend la periode non modifiable
   * @return {Observable<any>}
   */
  validerMenus = (selectedDates: Date[], idsPrestation: number[],
                  optionEcraserMenu: number, optionEcraserTauxDePrise:number, valid: boolean) => {

    return this.http.get(URL_GET_VALIDATION_MENUS, {
      params: new HttpParams()
        .set('dateDebut', this.utils.getYYYYMMDD(moment(selectedDates[0])))
        .set('dateFin', this.utils.getYYYYMMDD(moment(selectedDates[1])))
        .set('idsPrestation', idsPrestation.join(','))
        .set('optionEcraserMenu', optionEcraserMenu+ '')
        .set('optionEcraserTauxDePrise', optionEcraserTauxDePrise+ '')
        .set('valid', valid ? 'true' : 'false')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  changeMonth = (prestation: ContratMenuConviveDTO, startDate: Date, event) => {

    const cs = new CalendarSupplier();

    // si ca vient du onFocus
    const momentDate = moment(startDate);
    let startMonth: number = momentDate.month() + 1;
    let startYear: number = momentDate.year();

    if (event) {
      if(event.month && event.year) {
        startMonth = event.month;
        startYear = event.year;
      } else {
        const momentNewDate = moment(event);

        if(momentNewDate) {
          startMonth = momentNewDate.month() + 1;
          startYear = momentNewDate.year();
        }
      }
    }

    return this.getMonthMenuCompoDates(prestation.id, startYear, startMonth, 2).pipe(
      switchMap(response => {

        cs.datesCreationMenus = response.additionalProperties['datesCreationMenus'];
        cs.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
        cs.dateMin = response.additionalProperties['dateMin'];

        if(!this.utils.isNullOrEmpty(cs.dateMin)){
          cs.dateMin = this.utils.convertNumberDateToDate(cs.dateMin);
        }

        if (!this.utils.isCollectionNullOrEmpty(cs.datesSaisieEffectifs)) {
          cs.datesSaisieEffectifs = cs.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
        }

        if (!this.utils.isCollectionNullOrEmpty(cs.datesCreationMenus)) {
          cs.datesCreationMenus = cs.datesCreationMenus.map(item => this.utils.convertNumberDateToDate(item));
        }

        const joursDisponibles = response.additionalProperties['joursDisponibles'];
        cs.disabledDays = this.utils.getDisabledDays(joursDisponibles);

        return of(cs);
      }),
      catchError(err => this.utils.handleError(err)));
  };

  getRepasList = () => {
    if(this.planningMenus){
      return this.planningMenus.repasList;
    }else{
      return null;
    }
  };

  /**
   * Récupérer les dates à partir d'un tableau de ColonneDateDTO[]
   * @param dateList
   */
  getDateList = (dateList : ColonneDateDTO[]) => {
    let resultList = [];

    if(!this.utils.isCollectionNullOrEmpty(dateList)){

      dateList.forEach( (colDateDto: ColonneDateDTO) => {
        resultList.push(colDateDto.date);
      });

    }

    return resultList;
  };


  getDecoupageRepasWidth = (displayAllergenes:boolean, displayAppellations:boolean, displayFamillesGemrcn:boolean, displayTauxDePrise:boolean, displayCoutsUnitaires:boolean, displayCoutsUnitairesPonderes:boolean) => {

    let width = _cloneDeep(this.drWidthLibelle);

    if(displayAllergenes){
      width+=this.drWidthAllergenes;
    }

    if(displayAppellations){
      width+=this.drWidthAppellations;
    }

    if(displayFamillesGemrcn){
      width+=this.drWidthFamGemrcn;
    }

    if(displayTauxDePrise){
      width+=this.drWidthTauxDePrise;
    }

    if(displayCoutsUnitaires){
      width+=this.drWidthCoutsUnitaires;
    }

    if(displayCoutsUnitairesPonderes){
      width+=this.drWidthCoutsUnitairesPonderes;
    }

    return width;

  };


  getCoutsRepasPeriode = (selectedRegimes: ContratMenuConvive__ContrainteAlimDTO[], selectedRepas: RepasDTO[], selectedPeriode: Date[]) => {

    return this.http.get(URL_GET_COUTS_REPAS_PERIODE, {
      params: new HttpParams()
        .set('dateDebut', this.utils.getYYYYMMDD(moment(selectedPeriode[0])))
        .set('dateFin', this.utils.getYYYYMMDD(moment(selectedPeriode[1])))
        .set('idsCmcCa', selectedRegimes ? selectedRegimes.map(item=>item.id).join(','):'')
        .set('idsRepas', selectedRepas ? selectedRepas.map(item=>item.id).join(','):'')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  setRepasActif = (selector:string) => {

    const repasId=parseInt( selector.substr(5,1));
    const elt =_find(this.planningMenus.repasList,{'id':repasId});
    if(elt){
      this.repasActif = elt;
    }
  };

  getRepasActifHtmlId = () => {

    if(this.repasActif){
      return 'repas'+this.repasActif.id;
    }

    return '';
  };

  getCoutRepasDetails = (dateRepas: Date, selectedRepas: RepasDTO[], selectedRegimes: ContratMenuConvive__ContrainteAlimDTO[]) => {

    return this.http.get(URL_GET_COUTS_REPAS_DETAILS, {
      params: new HttpParams()
        .set('dateRepas', this.utils.getYYYYMMDD(moment(dateRepas)))
        .set('idsCmcCa', selectedRegimes ? selectedRegimes.map(item=>item.id).join(','):'')
        .set('idsRepas', selectedRepas ? selectedRepas.map(item=>item.id).join(','):'')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  getSlots = (cellule:CelluleTableauDTO) => {
    const arr: Slot[] = [];

    for (let choix = 1; choix <= cellule.choixMax; choix++) {

      const slot = new Slot();
      slot.idContratMenuConviveDecoupage = cellule.contratMenuConviveDecoupage.id;
      slot.idxSlot = choix;
      arr.push(slot);
    }

    return arr;
  };

  findValidDates = (startMonth: number, startYear: number, nbMonths: number, idsCmc: number[], idsRepas: number[], idsPlc: number[]) => {
    return this.httpSvc.get(`${URL_GET_VALID_DATES}?startMonth=${startMonth}&startYear=${startYear}&nbMonths=${nbMonths}&idsCmc=${[...new Set(idsCmc)]}&idsRepas=${[...new Set(idsRepas)]}&idsPlc=${[...new Set(idsPlc)]}`);
  }

  findJoursSemainesByPlanning = (date: Date, idCmc: number) => {
    const params: HttpParams = new HttpParams()
      .set('idCmc', idCmc)
      .set('date', this.utils.getYYYYMMDD(moment(date)));
    return this.httpSvc.get(URL_GET_JOURS_SEMAINES_BY_PLANNING_FOR_MENU, params);
  }
}

export class DialogRecherchePlatsSupplier {

  cmcd: ContratMenuConviveDecoupageDTO;
  dateMenu: Date;
  decoupageRepas: DecoupageRepasDTO;
  repas: RepasDTO;
  choixMax: number;
  cmcContrainteAlim: ContratMenuConvive__ContrainteAlimDTO;
  maxItemsPanier: number;
  prestation: ContratMenuConviveDTO;
  famillesGemRcn: FamilleGemrcnDTO[] = []; // Pour filtrer par plan alimentaire
  regimeAlimentaire: any;

  // 1 = libre, 2 = familles de plat du plan alim, 3 = familles Gemrcn du plan alim
  searchMode: number;

  // plat recherche
  platSearch: string;


  // 1 = menus compositions, 2 = menus compositions plc
  typeDialog: number = 1;

  // objet source ayant permis la recherhce
  sourceObject: any;

  // produits declinaisons sélectionnés apres la recherche
  produitsDeclinaisonsInCart: ProduitDeclinaisonDTO[];



}

export class CalendarSupplier {
  disabledDays: number[] = [0, 1, 2, 3, 4, 5, 6];
  startDate: Date;
  prestation: ContratMenuConviveDTO;
  joursDisponibles: number[];
  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];
  datesNonDisponibles: Date[];
  dateMin:Date;
}

class Slot {

  idContratMenuConviveDecoupage: number;
  idxSlot: number;

}
