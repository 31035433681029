import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PlanningEquipesSupplier} from '../../../../shared/ui/gestion-unitesproduction/planning-equipes/PlanningEquipesSupplier';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../../core/utils/utils.service';

@Component({
  selector: 'yo-planning-conditionnement',
  templateUrl: './planning-conditionnements.component.html',
  styleUrls: ['./planning-conditionnements.component.scss']
})
export class PlanningConditionnementsComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  pes: PlanningEquipesSupplier;

  constructor(private route: ActivatedRoute, public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  routeSubscription() {
    this.subRoute = this.route.data.subscribe((data: { planningEquipesSupplier: PlanningEquipesSupplier }) => {
      this.pes = data.planningEquipesSupplier;
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }
}
