<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="custom-button-container-left">
      <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        label="AJOUTER"
        iconClass="fa fa-plus"
        tooltip="Ajouter un nouveau membre au groupe"
        (onClick)="openAddUniteDeProduction()"
        [disabled]="!canAdd()">
      </yo-button>
      <yo-button iconClass="fas fa-cog rotate-1-turn"
                 [disabled]="utils.isCollectionNullOrEmpty(selectedMembers) || !canAdd()"
                 tooltip="Appliquer la mise à jour des prix et des articles preferes"
                 (onClick)="applyMajPrix(groupeAchat,selectedMembers)">
      </yo-button>

      <yo-button iconClass="fas fa-trash " buttonClass="cta-delete"
                 [disabled]="utils.isCollectionNullOrEmpty(selectedMembers) || !canAdd()"
                 tooltip="Enlever des membres"
                 (onClick)="removeMembers(selectedMembers)">
      </yo-button>

    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="membreGroupeAchatList"
  keyExpr="id"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [(selectedRowKeys)]="selectedMembers"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="uniteDeProduction.site" alignment="left" caption="Site"
              [allowFiltering]="false" [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="uniteDeProduction.libelle" caption="Unité de production" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="uniteDeProduction.code" caption="Code" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="traitementDate" caption="Date de traitement" cellTemplate="traitementDateCellTemplate"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="traitementLancePar" caption="Lancé par" [allowFiltering]="true">
  </dxi-column>

  <dxo-selection
    selectAllMode="true"
    [showCheckBoxesMode]="'always'" mode="multiple">
  </dxo-selection>

  <!--    TEMPLATE TRAITEMENT_OK_DATE-->
  <div *dxTemplate="let cell of 'traitementDateCellTemplate'">
    {{cell.row.data.traitementDate | date:'dd/MM/yyyy HH:mm'}}
  </div>

  <!--    TEMPLATE ENGAGEMENT PRIX-->
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.data?.uniteDeProduction?.site?.libelle"
             [siteId]="cell.data?.uniteDeProduction?.site?.id">
    </yo-site>
  </div>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Membre(s)">
  </dxo-pager>

</dx-data-grid>


<yo-dialog-add-membre></yo-dialog-add-membre>


