<!--CREATION ARTICLE, OUVERTURE DE LA SELECTION UDP-->

<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  width="auto"
  maxHeight="70vh"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

<!--  FICHIER A SUPPRIMER-->

  <div class="d-flex flex-column creer-article" [style]="{'height':'460px'}">


    <!--    PRODUIT-->
    <div class="d-flex mg-b-5">
      <div class="input-group input-group-sm " [formGroup]="formSearch">
        <input type="text" style="height:40px;"
               class="form-control form-control-sm "
               placeholder="Rechercher une déclinaison..."
               pKeyFilter="pnum"
               formControlName="produitCtl"
               #searchInput
               id="searchInput"
        >
        <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>

        </div>
      </div>
    </div>

    <dx-data-grid
      [dataSource]="produitList"
      keyExpr="id"
      [height]="400"
      [width]="620"
      [hoverStateEnabled]="true"
      [showBorders]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [selectedRowKeys]="selectedRowKeysPd"
      [focusedRowEnabled]="true"
      [(focusedRowKey)]="selectedRowKeysPd"
      (selectedRowKeysChange)="rowSelected($event)"
      #gridProduit
    >


      <dxi-column dataField="libelle" caption="Denrée" cellTemplate="denreeCellTemplate"
                  alignment="left"
                  width="300"></dxi-column>
      <dxi-column dataField="code" caption="Code"
                  width="200"></dxi-column>

      <!--            SELECTION-->
      <dxo-selection mode="single"></dxo-selection>

      <!--  SORTING-->
      <dxo-sorting mode="multiple"></dxo-sorting>

      <!--  UDP CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'denreeCellTemplate'">
        {{cell.row.data.libelle}}
      </div>

    </dx-data-grid>
    🗣️<em class="mg-r-5"> Les résultats de la recherche sont limités à {{resultProduitSize}}
    résultats.</em>

  </div>

  <!--    NAVIGATION-->
  <div class="d-flex flex-row mg-t-7">
    <div class="mr-auto"></div>
    <div>
      <yo-button label="Suivant" iconClass="fas fa-chevron-right" iconPos="right"
                 [disabled]="utils.isNullOrEmpty(articleSupplier.selectedProduit)"
                 (onClick)="addArticle(articleSupplier.selectedProduit,articleSupplier.selectedUniteDeProduction)"></yo-button>
    </div>
  </div>
</dx-popup>


