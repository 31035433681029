import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {MarcheDTO} from '../../core/dtos/gerstion-marche/marche-dto';
import {UtilsService} from '../../core/utils/utils.service';
import {DevextremeService} from '../../core/services/technique/devextreme.service';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {GestionsMarcheService} from '../../core/services/gestion-marche/gestions-marche.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {MarcheService} from '../../core/services/entities/marche.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {LotMarcheDTO} from '../../core/dtos/gerstion-marche/lot-marche-dto';
import {remove as _remove} from 'lodash';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-marche-grid',
  templateUrl: './marche-grid.component.html',
  styleUrls: ['./marche-grid.component.scss']
})
export class MarcheGridComponent implements OnInit, OnDestroy {

  marcheList: MarcheDTO[] = [];
  hasMarche: boolean;

  subAllMarche: Subscription;
  subHasMarche: Subscription;
  subMarcheDto: Subscription;

  pathFile: string = HELP_FOLDERS.MARCHES + '/marche';

  constructor(public utils: UtilsService,
              private dxSvc: DevextremeService,
              private marcheSvc: MarcheService,
              private gestionMarcheSvc: GestionsMarcheService,
              private routeMapSvc: RoutemapService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) { }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAllMarche);
    this.utils.unsubscribe(this.subHasMarche);
    this.utils.unsubscribe(this.subMarcheDto);
  }

  initData = () => {
    this.subAllMarcheSubscription();
    this.subHasMarcheSubscription();
    this.subMarcheDtoSubscription();
  };

  subAllMarcheSubscription = () => {
    // data grid
    this.subAllMarche = this.gestionMarcheSvc.getAllMarche().subscribe((response: ResponseWrapper<MarcheDTO>) => {
      this.marcheList = response.resultList;
    });
  };

  subHasMarcheSubscription = () => {
    // les droits utilisateurs
    this.subHasMarche = this.auth2Svc.hasMarche$.subscribe( response => {
      this.hasMarche = response;
    });
  };

  subMarcheDtoSubscription = () => {
    this.subMarcheDto = this.gestionMarcheSvc.marcheDto$.subscribe( (response: any) => {
      const idxExistingMarche: number = this.marcheList.findIndex((item: MarcheDTO) => item.id === response.marche.id);
      if (idxExistingMarche === -1) {
        this.marcheList.push(response.marche);
      } else {
        this.marcheList[idxExistingMarche] = response.marche;
      }
    });
  };

  canCreate = () => this.hasMarche;

  canModify = (marche: MarcheDTO) => marche.site && this.auth2Svc.isSiteLocal(marche.site.id) && this.hasMarche;

  openEditObject = (marche: any) => {

    if (this.utils.isNullOrEmpty(marche)) {
      marche = this.marcheSvc.createEmptyDTO();
      marche.id = 0;
    }
    this.routeMapSvc.goToSecondaryRoute(['gestion-marche', 'marche', marche.id, 'fiche-identite']);
  };

  openDeleteObject = async (marche: MarcheDTO) => {
    const result = confirm('Etes vous sûr de vouloir supprimer le marché "' + marche.libelle + '" ?', 'Suppression de(s) marché(s)');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.gestionMarcheSvc.deleteMarcheById(marche.id).subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Suppression réussie avec succès`);
        _remove(this.marcheList, (item: LotMarcheDTO) => {
          return item.id === marche.id;
        });
      });
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les marchés`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];
    dms.content = {
      intro: `Ils permettent la negociation de prix sur une période avec les founisseurs retenus sous forme de lot.`,
      paragraphes: []
    };
    return dms;
  };
}


