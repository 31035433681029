<ng-container *ngIf="canDisplayGrille(); else tplNoRepas">

  <p-accordion [multiple]="true" >
    <ng-container *ngFor="let prw of repasPlcWeekList; let i=index; let isFirst=first;">
      <p-accordionTab [header]="prw.repas.libelle" [selected]="isFirst">
        <yo-generic-repas-plc
          [plcRepasWeek]="prw"
          [configPortailPlc]="configPortailPlc"
          (onEmitPlcRepas)="onEmitPlcRepas($event)"
          [typeEffectif]="typeEffectif"></yo-generic-repas-plc>
      </p-accordionTab>
    </ng-container>
  </p-accordion>

</ng-container>

<ng-template #tplNoRepas>
  <i class="mg-r-5 fas fa-exclamation-triangle danger-color"></i>Aucun menu existant pour cette période.
</ng-template>
