import {ObjectDTO} from "./object-dto";

export class ContratMenuConvive__ModeleNutriDTO extends ObjectDTO {

  dateDebut:Date;
  dateFin:Date;
  nbItemsParCycle:number;

  //    contrat menu convive
  cmcId : number;
  cmcLibelle:string;
  cmcActif:boolean;

  // cmc site
  cmcSiteId:number;
  cmcSiteLibelle:string;
  cmcSiteActif:boolean;

  // modele nutritionnel
  modeleNutritionnelId : number;
  modeleNutritionnelLibelle:string;
  modeleNutritionnelActif:boolean;
  modeleNutritionnelNbItemsParCycle:number;

}
