export class SizeTableauDTO {

  thLevel1Width: string = '450px';
  thLevel2Width: string= '450px';
  tdWidth: string= '450px';
  tableWidth:string = '1200px';
  tableWidthInt:number=1200;
  ligneAnalyseWidth:string='1100px';


}
