import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AtelierDTO} from '../../../../core/dtos/atelier-d-t-o';
import {EquipeDTO} from '../../../../core/dtos/equipe-dto';
import {AteliersService} from '../../../../core/services/entities/ateliers.service';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {TacheDTO} from '../../../../core/dtos/tache-dto';
import {EquipesService} from '../../../../core/services/entities/equipes.service';
import {TachesService} from '../../../../core/services/entities/taches.service';
import {MZoneProduction} from '../../../../core/models/gestion-unites-production/m-zone-production';
import {GraphQLService} from "../../../../core/services/technique/graphql.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";

@Injectable()
export class UpParametrageResolverService implements Resolve<UpEquipesSupplier> {

  constructor(private zonesDeProductionService: AteliersService,
              private unitesDeProductionService: UnitesDeProductionService,
              private equipesService: EquipesService,
              private graphQlSvc: GraphQLService,
              private tacheService: TachesService,
              private auth2Svc: Auth2Service) {
  }

  // tslint:disable-next-line:max-line-length
  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpEquipesSupplier> | Promise<UpEquipesSupplier> | UpEquipesSupplier => {
    const idUniteDeProduction: number = +route.parent.parent.paramMap.get('idUniteDeProduction');
    const uniteDeProduction$ = this.unitesDeProductionService.getUniteDeProductionById(idUniteDeProduction);
    const ateliersList$ = this.getAteliers();
    const equipes$ = this.getEquipes();
    const taches$ = this.tacheService.getAll();
    const tachesConfigureesByZonesProductionEquipes$ = this.tacheService.findTachesByZoneProductionAndEquipes(idUniteDeProduction);

    const all$ = combineLatest([uniteDeProduction$, ateliersList$, equipes$, taches$, tachesConfigureesByZonesProductionEquipes$]);
    const result$ = all$.pipe(
      map(results => {
        const uniteDeProduction: UniteDeProductionDTO = results[0].one;
        const ateliers: AtelierDTO[] = results[1].allAteliers;
        const equipes: EquipeDTO[] = results[2].allEquipes;
        const taches: TacheDTO[] = results[3].resultList;
        const tachesConfigurees: MZoneProduction[] = results[4].resultList;
        return new UpEquipesSupplier(uniteDeProduction, ateliers, equipes, taches, tachesConfigurees);
      }));
    return result$;
  };

  getEquipes = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allEquipes(filters: {
          siteIds: [${idsSites}],
          actif: true
        }) {
            id,
            libelle,
        }
      }
    `)
  }

  getAteliers = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allAteliers(filters: {
          siteIds: [${idsSites}],
          actif: true
        }) {
            id,
            libelle,
        }
      }
    `)
  }

}

export class UpEquipesSupplier {

  // Pour remplir le formulaire :
  uniteDeProduction: UniteDeProductionDTO;
  ateliers: AtelierDTO[];
  equipes: EquipeDTO[];
  taches: TacheDTO[];

  // Pour préselectionner les bons éléments (article -> equipes -> tâches) avec des Modèles spéciaux :
  tachesConfigurees: MZoneProduction[];

  constructor(uniteDeProduction: UniteDeProductionDTO,
              ateliers: AtelierDTO[],
              equipes: EquipeDTO[],
              taches: TacheDTO[],
              tachesConfigurees: MZoneProduction[]) {
    this.uniteDeProduction = uniteDeProduction;
    this.ateliers = ateliers;
    this.equipes = equipes;
    this.taches = taches;
    this.tachesConfigurees = tachesConfigurees;
  }

}
