import {BehaviorSubject, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {UtilsService} from '../../utils/utils.service';
import {GroupeNutritionnelDTO} from '../../dtos/groupe-nutritionnel-dto';
import {FamilleGemrcnDTO} from '../../dtos/famille-gemrcn-dto';
import {Router} from '@angular/router';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {ModeleNutritionnelDetailDTO} from '../../dtos/modele-nutritionnel-detail-dto';
import {cloneDeep as _cloneDeep, find as _find, uniqBy as _uniqBy} from 'lodash';
import * as moment from 'moment';
import {GrilleFrequencesSupplier} from '../../../gestion-gemrcn/grille-frequences-gemrcn/grille-frequences-supplier';
import {RegleGemrcnSupplier} from '../../../shared/ui/regle-gemrcn/regle-gemrcn-supplier';
import {RoutemapService} from '../routemap.service';
import {
  GEMRCN_OPERATORS,
  JOURS_SEMAINES,
  JourSemaine,
  PREDICAT_OPERATOR,
  PREDICAT_TYPE,
  PREDICAT_VALUE
} from '../../constants';
import {RepasDTO} from '../../dtos/repas-dto';
import {DecoupageRepasDTO} from '../../dtos/decoupagerepas-dto';
import {FamilleProduitDTO} from '../../dtos/famille-produit-dto';
import {ModeleNutritionnel__FamillePlatDTO} from '../../dtos/modele-nutritionnel__famille-plat-dto';
import {PlanningRowModel} from '../../models/planning-row-model';
import {ModeleNutritionnelPlanningDTO} from '../../dtos/modele-nutritionnel-planning-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ModeleNutritionnel__FamilleGemrcnDTO} from '../../dtos/modele-nutritionnel__famille-gemrcn-dto';
import {ContratsMenusConvivesService} from '../gestioncontrats/contrats-menus-convives.service';
import {CmcMnService} from '../entities/cmc-mn.service';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {ModeleNutritionnelGroupeGemrcnDTO} from '../../dtos/modele-nutritionnel-groupe-gemrcn-dto';
import {ContratMenuConvive__ModeleNutriDTO} from '../../dtos/contratmenuconvive__modele-nutri-dto';
import {CmcMnGroupeGemrcnDTO} from '../../dtos/cmcmn-groupe-gemrcn-dto';
import {HttpService} from '../technique/http.service';

const URL_MODELESNUTRIS = 'dolrest/gestiongemrcn/modeles-nutritionnels';
/**
 * @deprecated
 * @type {string}
 */
export const URL_POST_SAVE_GRILLE_GEMRCN = 'dolrest/gestiongemrcn/save/grille-gemrcn';
export const URL_POST_SAVE_GRILLE_FAMILLES_PLATS = 'dolrest/gestiongemrcn/save/grille-familles-plats';
export const URL_POST_SAVE_PLANNING = 'dolrest/gestiongemrcn/save/grille-planning';
export const URL_GET_PRESTATIONS = `dolrest/gestiongemrcn/grille-planning/prestations`;
export const URL_POST_ANALYSE_GEMRCN = `dolrest/gestiongemrcn/getAnalyseGemrcn`;
export const URL_POST_CONTROLE_GEMRCN_PLAN_ALIMENTAIRE = `dolrest/gestiongemrcn/controleGemrcnPlanAlimentaire`;

@Injectable()
export class GestionGemrcnService {

  public mapGrilleFrequencesCells: Map<string, ModeleNutritionnelDetailDTO> = new Map();
  public mapRegleGemrnCells: Map<string, RegleGemrcnSupplier> = new Map();
  private subjectModelesNutris = new BehaviorSubject(undefined);
  modelesNutris$ = this.subjectModelesNutris.asObservable();
  private subjectGrilleFrequencesSupplier = new BehaviorSubject(undefined);
  grilleFrequencesSupplier$ = this.subjectGrilleFrequencesSupplier.asObservable();

  constructor(private routeMapSvc: RoutemapService,
              private httpSvc: HttpService,
              private http: HttpClient,
              private utils: UtilsService,
              private router: Router,
              private gds: GenericDatagridService,
              private cmcMnSvc: CmcMnService,
              private cmcSvc: ContratsMenusConvivesService) {
  }


  announceModelesNutris = (modelesNutris: ModeleNutritionnelDTO[]) => {
    this.subjectModelesNutris.next(modelesNutris);
  };


  getAllModelesNutrisOfUser = (): Observable<ResponseWrapper<ModeleNutritionnelDTO>> => {
    return this.http.get<ResponseWrapper<ModeleNutritionnelDTO>>(URL_MODELESNUTRIS).pipe(
      catchError(error => this.utils.handleError(error)))
  };

  canDisplayGrilleFrequence = (fgList: FamilleGemrcnDTO[], gnList: GroupeNutritionnelDTO[]): boolean => {

    if (!this.utils.isCollectionNullOrEmpty(fgList) && !this.utils.isCollectionNullOrEmpty(gnList)) {
      return true;
    }
    return false;
  };

  newPlanningRowModel = (repas: RepasDTO, composante: DecoupageRepasDTO, nbChoixMax: number): PlanningRowModel => {
    const rowPlanning = new PlanningRowModel();
    rowPlanning.composanteRepas = composante;
    rowPlanning.repas = repas;
    rowPlanning.nbChoixMax = nbChoixMax;

    return rowPlanning;
  };

  newModeleNutritionnelFamillePlat = (modeleNutri: ModeleNutritionnelDTO,
                                      famille: FamilleProduitDTO,
                                      repas: RepasDTO,
                                      composante: DecoupageRepasDTO
  ): ModeleNutritionnel__FamillePlatDTO => {
    let mnd = new ModeleNutritionnel__FamillePlatDTO();

    mnd.id = 0;
    mnd.idModeleNutritionnel = modeleNutri.id;
    mnd.familleProduit = famille;
    mnd.repas = repas;
    mnd.decoupageRepas = composante;
    mnd.actif = false;

    return mnd;
  };

  newModeleNutritionnelPlanning = (planAlimentaire: ModeleNutritionnelDTO, jourSemaine: number, repas: RepasDTO, composanteRepas: DecoupageRepasDTO, nbPlats: number): ModeleNutritionnelPlanningDTO => {

    const mnp = new ModeleNutritionnelPlanningDTO();

    mnp.id = 0;
    mnp.idModeleNutritionnel = planAlimentaire.id;
    mnp.repas = repas;
    mnp.decoupageRepas = composanteRepas;
    mnp.nbPlatsParComposanteRepas = nbPlats;
    mnp.jourSemaine = jourSemaine;
    mnp.actif = false;
    mnp.modeleNutritionnel__familleGemrcnList = [];

    return mnp;

  };

  createNewRegleGemrcnSupplier = (groupeGemrcn: ModeleNutritionnelGroupeGemrcnDTO, familleGemrcn: FamilleGemrcnDTO, frequenceRepas: number): RegleGemrcnSupplier => new RegleGemrcnSupplier(familleGemrcn, 0, frequenceRepas, 'l', groupeGemrcn);

  getRegleGemrcnMapKey = (fg: FamilleGemrcnDTO) => `${fg.id}`;

  getGrilleFrequenceMapKey = (idFamilleGemrcn: number, idGroupeNutritionnel: number): string => `${idFamilleGemrcn}-${idGroupeNutritionnel}`;

  /**
   * Initialiser la map des regles gemrcn
   * @param {GrilleFrequencesSupplier} grilleFrequencesSupplier
   * @returns {Map<string, RegleGemrcnSupplier>}
   * @deprecated
   */
  initMapReglesGemrcnCells = (grilleFrequencesSupplier: GrilleFrequencesSupplier): Map<string, RegleGemrcnSupplier> => {
    let mapCells: Map<string, RegleGemrcnSupplier> = new Map();
    return mapCells;
  };

  /**
   * Initialiser les cellules de la grille de frequences GEMRCN
   * @param {GrilleFrequencesSupplier} grilleFrequencesSupplier
   * @returns {Map<string, ModeleNutritionnelDetailDTO>}
   * @deprecated
   */
  initMapGrilleFrequencesCells = (grilleFrequencesSupplier: GrilleFrequencesSupplier): Map<string, ModeleNutritionnelDetailDTO> => {
    let mapCells: Map<string, ModeleNutritionnelDetailDTO> = new Map();
    return mapCells;
  };

  /**
   * Renvoie vrai si la cellule est sélectionnée dans le modele nutritionnel
   * @param {FamilleGemrcnDTO} familleGemrcn
   * @param {GroupeNutritionnelDTO} groupeNutritionnel
   * @returns {boolean}
   */
  isCellChecked = (familleGemrcn: FamilleGemrcnDTO, groupeNutritionnel: GroupeNutritionnelDTO): boolean => {

    if (!this.utils.isNullOrEmpty(familleGemrcn) && !this.utils.isNullOrEmpty(groupeNutritionnel)) {
      let mnd = this.mapGrilleFrequencesCells.get(this.getGrilleFrequenceMapKey(familleGemrcn.id, groupeNutritionnel.id));
      if (!this.utils.isNullOrEmpty(mnd) && mnd.actif) {
        return true;
      }
    }
    return false;
  };

  /**
   * Changer l'etat de la cellule : sélectionnée ou pas sélectionnée
   * @param {FamilleGemrcnDTO} familleGemrcn
   * @param {GroupeNutritionnelDTO} groupeNutritionnel
   * @param event
   * @returns {boolean}
   */
  changeCellChecked = (readonly: boolean, familleGemrcn: FamilleGemrcnDTO, groupeNutritionnel: GroupeNutritionnelDTO, event: any) => {

    if (!readonly) {
      if (!this.utils.isNullOrEmpty(familleGemrcn) && !this.utils.isNullOrEmpty(groupeNutritionnel)) {
        let mnd = this.mapGrilleFrequencesCells.get(this.getGrilleFrequenceMapKey(familleGemrcn.id, groupeNutritionnel.id));
        if (!this.utils.isNullOrEmpty(mnd)) {
          mnd.actif = !_cloneDeep(mnd.actif);
        }
      }
    }

  };

  /**
   * Récupérer la regle GEMRCN par sa famille GEMRCN
   * @param {FamilleGemrcnDTO} fg
   * @returns {RegleGemrcnSupplier}
   */
  getRegleGemrcnSupplier = (fg: FamilleGemrcnDTO): RegleGemrcnSupplier => {

    if (!this.utils.isNullOrEmpty(fg)) {
      return this.mapRegleGemrnCells.get(this.getRegleGemrcnMapKey(fg));
    }
    return undefined;
  };

  saveGrilleGemrcn = (cells: ModeleNutritionnelDetailDTO[], planAlimentaire: ModeleNutritionnelDTO): Observable<ResponseWrapper<ModeleNutritionnelDetailDTO>> => this.httpSvc.post(URL_POST_SAVE_GRILLE_GEMRCN + `/${planAlimentaire.id}`, cells);

  saveGrilleFamillesPlats = (cells: ModeleNutritionnel__FamillePlatDTO[], planAlimentaire: ModeleNutritionnelDTO) => this.httpSvc.post(URL_POST_SAVE_GRILLE_FAMILLES_PLATS + `/${planAlimentaire.id}`, cells);


  savePlanning = (cells: ModeleNutritionnelPlanningDTO[], planAlimentaire: ModeleNutritionnelDTO) => this.httpSvc.post(URL_POST_SAVE_PLANNING + `/${planAlimentaire.id}`, cells);

  getAnalyseGemrcnPromise = (idPrestation: number, idCmcCa: number, startDate: Date, stopDate: Date) => this.getAnalyseGemrcn(idPrestation, idCmcCa, startDate, stopDate).toPromise()
    .then(response => {
      const ghs = new GemrcnHelperSupplier();
      ghs.prestation = response.additionalProperties['prestation'];
      ghs.contrainteAlimentaire = response.additionalProperties['contrainteAlimentaire'];
      const dateDeb = this.utils.convertNumberDateToDate(response.additionalProperties['dateDeb']);
      const dateFin = this.utils.convertNumberDateToDate(response.additionalProperties['dateFin']);
      ghs.dateDebEtFin = [dateDeb, dateFin];

      ghs.plansAlimentaires = response.additionalProperties['plansAlimentaires'];

      return ghs;
    });

  /**
   *
   * @param idContratMenuConvive
   * @param idCmcCa contrat menu convive contrainte alimentaire
   * @param startDate
   * @param stopDate
   */
  getAnalyseGemrcn = (idContratMenuConvive, idCmcCa, startDate: Date, stopDate: Date) => {
    const fd = new FormData();
    fd.set('idContratMenuConvive', idContratMenuConvive + '');
    fd.set('idCmcCa', idCmcCa + '');
    fd.set('startDate', this.utils.getYYYYMMDD(moment(startDate)));
    fd.set('stopDate', this.utils.getYYYYMMDD(moment(stopDate)));

    return this.httpSvc.post(URL_POST_ANALYSE_GEMRCN, fd);
  };

  getPrestations = (planAlimentaire: ModeleNutritionnelDTO) => {
    const params = new HttpParams().set('idModeleNutritionnel', planAlimentaire.id + '');
    return this.httpSvc.get(URL_GET_PRESTATIONS, params);
  };

  filterGrilleGemrcn = (idModeleNutri: number): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier('ModeleNutritionnelDetail');

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'modelenutritionneldetail.modeleNutritionnelGroupeGemrcn.modeleNutritionnel.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idModeleNutri + '';

    search.predicats.push(predicat1);

    const predicat2 = new Predicat();
    predicat2.path = 'modelenutritionneldetail.actif';
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat2);

    grs.search = search;

    return grs;
  };

  filterGrillePlanning = (idModeleNutri: number): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier('ModeleNutritionnelPlanning');

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'modelenutritionnelplanning.modeleNutritionnel.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idModeleNutri + '';

    search.predicats.push(predicat1);

    grs.search = search;

    return grs;
  };


  preselectPlanningElements = (vns: PlanningSupplier) => {

    if (!this.utils.isCollectionNullOrEmpty(vns.grillePlanning)) {

      let repasList: RepasDTO[] = [];
      let composantesRepas: DecoupageRepasDTO[] = [];
      let joursSemaines: number[] = [];


      vns.selectedComposantesRepas = [];
      vns.selectedRepasList = [];
      vns.selectedJoursSemaine = [];
      vns.rowsPlanning = [];


      for (let row of vns.grillePlanning) {

        joursSemaines.push(row.jourSemaine);
        repasList.push(row.repas);
        composantesRepas.push(row.decoupageRepas);

      }

      if (composantesRepas) {
        composantesRepas = _uniqBy(composantesRepas, 'id');
        composantesRepas.forEach(item => vns.selectedComposantesRepas.push(item))
      }

      if (repasList) {
        repasList = _uniqBy(repasList, 'id');
        repasList.forEach(item => vns.selectedRepasList.push(item))
      }


      if (joursSemaines) {

        joursSemaines.forEach(item => {

          const jourSemaine = _find(JOURS_SEMAINES, {'value': item});
          if (jourSemaine) {

            const elt = _find(vns.selectedJoursSemaine, {'value': jourSemaine.value});
            if (!elt) {
              vns.selectedJoursSemaine.push(jourSemaine);
            }


          }

        });
      }

      if (composantesRepas && repasList) {
        for (let r of repasList) {
          for (let cr of composantesRepas) {
            const rowPlanning = this.newPlanningRowModel(r, cr, 0);
            vns.rowsPlanning.push(rowPlanning);
          }
        }
      }

    }


  };

  getControlesGemrcn = (reglesGemrcn: RegleGemrcnSupplier[]) => {
    const reglesMap = new Map();

    if (!this.utils.isCollectionNullOrEmpty(reglesGemrcn)) {

      for (const regleGemrcn of reglesGemrcn) {
        reglesMap.set(regleGemrcn.familleGemrcn.id + '-' + regleGemrcn.modeleNutritionnelGroupeGemrcn.id, regleGemrcn);
      }

    }


    return reglesMap;
  };

  getReglesGemrcn = (grilleGemrcn: ModeleNutritionnelDetailDTO[], groupesGemrcn: ModeleNutritionnelGroupeGemrcnDTO[]): Map<number, RegleGemrcnSupplier> => {

    // console.log('getReglesGemrcn');

    const reglesMap = new Map();


    if (!this.utils.isCollectionNullOrEmpty(grilleGemrcn)) {
      const regleSet: Set<RegleGemrcnSupplier> = new Set();

      for (let row of grilleGemrcn) {


        for (const groupeGemrcn of groupesGemrcn) {

          const modeleNutritionnelGroupeGemrcn = new ModeleNutritionnelGroupeGemrcnDTO();
          modeleNutritionnelGroupeGemrcn.id = row.modeleNutritionnelGroupeGemrcnId;
          modeleNutritionnelGroupeGemrcn.choix = row.modeleNutritionnelGroupeGemrcnChoix;
          const rg = new RegleGemrcnSupplier(row.familleGemrcn, row.valeur, row.nbRepas, row.typeValeur, modeleNutritionnelGroupeGemrcn);

          regleSet.add(rg);

        }


      }

      if (regleSet) {
        regleSet.forEach(rg => reglesMap.set(rg.familleGemrcn.id + '-' + rg.modeleNutritionnelGroupeGemrcn.id, rg));
      }

    }

    return reglesMap;
  };


  /**
   * Afficher l'opérateur de la régle :
   * < est affiché max
   * > est affiché min
   * = est affiché à blanc
   * @returns {string}
   */
  getOperatorViewValue = (operatorValue: string): string => {


    if (operatorValue === '>') {
      return GEMRCN_OPERATORS.MIN.viewValue;
    } else if (operatorValue === '<') {
      return GEMRCN_OPERATORS.MAX.viewValue;
    } else if (operatorValue === '=') {
      return GEMRCN_OPERATORS.EGAL.viewValue;
    } else {
      return GEMRCN_OPERATORS.LIBRE.viewValue;
    }

  };

  labelRegleGemrcn = (regle: RegleGemrcnSupplier, frequenceRepas?: number): string => {

    let str = '';
    if (frequenceRepas) {
      regle.denominator = frequenceRepas;
    }

    if (regle.operator === 'l') {
      str = 'libre';
    } else {

      let opValue = '';

      if (regle.operator === '>') {
        opValue = 'min';
      } else if (regle.operator === '<') {
        opValue = 'max';
      } else if (regle.operator === '=') {
        opValue = 'égal';

      }

      str = opValue + ' ' + regle.numerator + ' / ' + regle.denominator;
    }

    return str;
  };


  createNewModeleNutritionnelDetailDTO = (groupeGemrcn: ModeleNutritionnelGroupeGemrcnDTO, rg: RegleGemrcnSupplier, frequenceRepas: number) => {

    const mnd = new ModeleNutritionnelDetailDTO();

    mnd.familleGemrcn = rg.familleGemrcn;
    mnd.idModeleNutritionnel = groupeGemrcn.modeleNutritionnelId;
    mnd.valeur = rg.numerator;
    mnd.typeValeur = rg.operator;
    mnd.actif = true;
    mnd.nbRepas = frequenceRepas;
    mnd.modeleNutritionnelGroupeGemrcnId = rg.modeleNutritionnelGroupeGemrcn.id;
    mnd.modeleNutritionnelGroupeGemrcnChoix = rg.modeleNutritionnelGroupeGemrcn.choix;

    return mnd;
  };

  newModeleNutritionnelFamilleGemrcn = (mnd: ModeleNutritionnelPlanningDTO, numeroSemaine: number, ordre: number, familleGemrcn: FamilleGemrcnDTO) => {


    if (!this.utils.isNullOrEmpty(mnd) && numeroSemaine && ordre && !this.utils.isNullOrEmpty(familleGemrcn)) {

      const mnfg = new ModeleNutritionnel__FamilleGemrcnDTO();
      mnfg.ordre = ordre;
      mnfg.idFamilleNutritionnelle = familleGemrcn.id;
      mnfg.numeroSemaine = numeroSemaine;
      mnfg.libelleFamilleNutritionnelle = familleGemrcn.libelle;
      mnfg.couleurFamilleNutritionnelle = familleGemrcn.couleur.hexa;
      mnfg.idModeleNutritionnelPlanning = mnd.id;

      return mnfg;

    }

    return undefined;
  };


  controleGemrcnPlanAlimentaire = (idModeleNutri: number) => {

    const fd = new FormData();
    fd.set('idModeleNutritionnel', idModeleNutri + '');

    return this.httpSvc.post(URL_POST_CONTROLE_GEMRCN_PLAN_ALIMENTAIRE, fd);

  };


}

export class PlanningSupplier {

  repasList: RepasDTO[];
  composantesRepas: DecoupageRepasDTO[];
  grillePlanning: ModeleNutritionnelPlanningDTO[];

  selectedRepasList: RepasDTO[];
  selectedComposantesRepas: DecoupageRepasDTO[];
  selectedJoursSemaine: JourSemaine[];

  rowsPlanning: PlanningRowModel[];

  grilleGemrcn: ModeleNutritionnelDetailDTO[];
  famillesGemrcn: FamilleGemrcnDTO[];


}

export class GemrcnHelperSupplier {

  prestation: ContratMenuConviveDTO;
  contrainteAlimentaire: ContratMenuConvive__ContrainteAlimDTO;
  dateDebEtFin: Date[] = [];
  plansAlimentaires: PlanAlimGemrcn[] = [];

}


export class PlanAlimGemrcn {

  dateDebut: Date;
  dateFin: Date;
  cmcMn: ContratMenuConvive__ModeleNutriDTO;
  nbRepasComptabilises: number;
  lignePlanAlimGemrcnList: LignePlanAlimGemrcn[];
  colonnePlanAlimGemrcnList: ColonnePlanAlimGemrcn[];
  cellulePlanAlimGemrcnMap: Map<string, RegleGemrcnSupplier>;
}

export class LignePlanAlimGemrcn {
  familleGemrcn: FamilleGemrcnDTO;
  platGemrcnList: PlatGemrcn[];
}


export class ColonnePlanAlimGemrcn {

  nbRepas: number;
  cmcMnGroupeGemrcn: CmcMnGroupeGemrcnDTO;
}


export class PlatGemrcn {

  dateMenu: Date;
  repasLibelle: string;
  repasId: string;
  decoupageRepasLibelle: string;
  produitDeclinaisonLibelle: string;
  produitDeclinaisonId: number;

  // position du plat dans le decoupage repas
  ordre: number;
}
