export class GenericRequestSupplier {
  entityName: string;
  page: number;
  size: number;
  search: Search;

  constructor(entityName: string) {
    this.entityName = entityName;
    this.page = 0;
    this.size = 999;
    this.search = new Search();
  }
}

export class Search {
  sorts: Sort[];
  predicats: Predicat[];

  constructor() {
    this.sorts = [];
    this.predicats = [];
  }
}

export class Predicat {
  value: string;
  operator: string;
  path: string;
  type: string;
  typeName: string;
  ids: number[];
  values: string[];
  aliasPath: string;
  property : string;
}

export class Sort {
  dir: string;
  path: string;
  luceneSortType: string;
}
