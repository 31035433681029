<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        iconClass="fa fa-plus"
        label="CRÉER"
        tooltip="Créer une déclinaison de produit"
        (onClick)="openEditObject(produit, undefined)"
        [disabled]="!gds.canModify(produit)">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  class="pt-3"
  [dataSource]="produit.produitDeclinaisons"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(180)"
  [width]="utils.getWindowAvailableWidth(190)"
  width="100%"
  columnResizingMode="widget"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" alignment="center" caption="Actions" [width]="200"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libelle"
              cellTemplate="libelleCellTemplate"

              [width]="200" [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="declinaison" caption="Déclinaison" [width]="100" cellTemplate="declinaisonCellTemplate">
  </dxi-column>

  <!--  <dxi-column dataField="prixAchat" caption="Prix d'achat" [width]="120"-->
  <!--              cellTemplate="prixAchatCellTemplate"-->
  <!--  >-->
  <!--  </dxi-column>-->

  <dxi-column dataField="uniteDeMesure" caption="Unité technique" [width]="140"
              cellTemplate="uniteDeMesureCellTemplate"
  >
  </dxi-column>


  <dxi-column dataField="code" caption="Code" [width]="140"
  >
  </dxi-column>

  <dxi-column dataField="dlc" caption="DLC" [width]="80"
              cellTemplate="dlcCellTemplate"
  >
  </dxi-column>

  <dxi-column dataField="actif" caption="Actif" [width]="80"
  >
  </dxi-column>

  <!--  EXCEL EXPORT-->
  <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <!--  RECHERCHE FRONT-->
  <dxo-search-panel [visible]="true"></dxo-search-panel>

  <!--  SORTING-->
  <dxo-sorting mode="multiple"></dxo-sorting>

  <!--  LIBELLE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'libelleCellTemplate'">
    {{ cell.row.data.libelle  | uppercase }}
  </div>


  <!--  DLC CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'dlcCellTemplate'">
    J+{{ cell.row.data.dlc  }}
  </div>

  <!--  UNITE DE MESURE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'uniteDeMesureCellTemplate'">
    {{ cell.row.data.uniteDeMesure.libelle}}
  </div>

  <!--  PRIX ACHAT CELL TEMPLATE-->
  <!--  <div *dxTemplate="let cell of 'prixAchatCellTemplate'">-->
  <!--    {{ cell.row.data.prixAchat | currency:'EUR':'symbol':'1.4-4' }}-->
  <!--  </div>-->

  <!--  DECLINAISON CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'declinaisonCellTemplate'">
    <label class="badge badge-secondary cursor" (click)="openEditObject(produit, cell.row.data)">
      {{cell.row.data.declinaison.libelle | uppercase}}
    </label>
  </div>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditObject(produit, cell.row.data)"
                    [yoViewMode]="!gds.canModify(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-edit"
                    yoTooltip="Modifier la déclinaison"
    ></yo-cell-button>
    <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                    [yoHiddenMode]="!gds.canModify(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer la déclinaison"
    ></yo-cell-button>
    <yo-cell-button (yoNavigation)="printIncoData(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="far fa-file-pdf"
                    yoTooltip="Exporter la fiche inco"
    ></yo-cell-button>
    <yo-cell-button (yoNavigation)="openDialogDuplicationDeclinaison(produit, cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fas fa-copy"
                    yoTooltip="Dupliquer la déclinaison"
                    [yoDisabled]="!canDuplicateDeclinaison(cell.row.data)"
    ></yo-cell-button>
    <ng-container *ngIf="!cell.row.data.fabrique">
      <yo-cell-button (yoNavigation)="openDialogPlatUsingSelectedDenree(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-th-list"
                      yoTooltip="Voir les plats utilisant cette denrée"
      ></yo-cell-button>
    </ng-container>
  </div>
</dx-data-grid>

<p-dialog
  [(visible)]="displayDialog"
  [contentStyle]="{'overflow':'visible'}"
  [modal]="true"

  (onHide)="onHideDuplicationDialog()"
  [style]="{width: '800px', minWidth: '800px'}"
  [dismissableMask]="false">

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-copy mg-r-5"></i>{{getTitre()}}
    </div>
  </ng-template>


  <!--CONTENT-->
  <div class="row">
    <!--SELECTION CONVIVES-->
    <div class="col-md-auto cursor">
      <p-multiSelect [options]="allDeclinaisons"
                     [(ngModel)]="selectedDeclinaisons"
                     optionLabel="libelle"
                     defaultLabel="Choisir une ou plusieurs déclinaisons cibles"
                     (onChange)="changeDeclinaison($event)"
      >

      </p-multiSelect>

    </div>
    &nbsp;
    &nbsp;
    <div *ngIf="this.produitsDeclinaisonsEcrasables.length > 0">

      <table>
        <tr>
          <td>
            <p-radioButton name="groupe" label="Modification des déclinaisons existantes ?"
                           [(ngModel)]="ecrasementDesProduitsDeclinaisonsExistants"
                           value="true">
            </p-radioButton>
          </td>
        </tr>
        <tr>
          <td>
            <p-radioButton name="groupe" label="Création nouvelles déclinaisons ?"
                           [(ngModel)]="ecrasementDesProduitsDeclinaisonsExistants"
                           value="false">
            </p-radioButton>
          </td>
        </tr>
      </table>

    </div>
  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <yo-button (onClick)="duplicationDeclinaison()" iconClass="fas fa-copy" label="Copier"
               [disabled]="!canDuplicateDeclinaisonDialog()"></yo-button>
    <yo-button (onClick)="onHideDuplicationDialog()" iconClass="fas fa-times"
               buttonClass="cta-inner-delete"
               label="Fermer"></yo-button>
  </ng-template>

</p-dialog>


<!--DIALOG DE CREATION / MODIFICATION D'UN PRODUIT DECLINAISON-->
<yo-declinaison-produit-dialog-edit></yo-declinaison-produit-dialog-edit>

<!--DIALOG LISTE PLAT-->
<yo-dialoglistplat></yo-dialoglistplat>
