import {Component, OnInit} from '@angular/core';
import {FichetechniqueSupplier} from '../fichetechnique-produit/fichetechnique-resolver.service';
import {Subscription} from 'rxjs';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitModeOperatoireDTO} from '../../../core/dtos/produit-mode-operatoire.dto';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-mode-operatoire-produit',
  templateUrl: './mode-operatoire-produit.component.html',
  styleUrls: ['./mode-operatoire-produit.component.scss']
})
export class ModeOperatoireProduitComponent implements OnInit {
  sub: Subscription;
  yoProduit: ProduitDTO;
  produitsModesOperatoires: ProduitModeOperatoireDTO[];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-mode-operatoire';

  constructor(private route: ActivatedRoute,
              public gds: GenericDatagridService,
              public produitsService: ProduitsService,
              public utils: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.sub = this.route.data.subscribe((data: { fichetechniqueSupplier: FichetechniqueSupplier }) => {
      this.yoProduit = data.fichetechniqueSupplier.produitDTO;
      this.produitsModesOperatoires = data.fichetechniqueSupplier.produitsModesOperatoires;
      this.produitsModesOperatoires = this.produitsService.sortListeProduitModeOperatoireDTO(this.produitsModesOperatoires);
    });

  }

  checkValiditeModesOperatoires = (produitsModeOperatoireDTO: ProduitModeOperatoireDTO[]): boolean => {
    let valid: boolean = true;
    for (let produitModeOperatoireDTO of produitsModeOperatoireDTO) {
      produitModeOperatoireDTO.modeOperatoire = produitModeOperatoireDTO.modeOperatoire.replace(/<br>/g, '<br/>');
      if (produitModeOperatoireDTO.modeOperatoire.match("<img ")) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le mode opératoire ne doit pas contenir d'images`);
        valid = false;
        break;
      }
    }
    return valid;
  };

  saveModeOperatoire = () => {
    if (this.checkValiditeModesOperatoires(this.produitsModesOperatoires)) {
      this.produitsService.saveModeOperatoire(this.yoProduit.id, this.produitsModesOperatoires).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response))
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les modes opératoires ont été enregistrées avec succès`);
          this.produitsModesOperatoires = response.resultList;
          this.produitsModesOperatoires = this.produitsService.sortListeProduitModeOperatoireDTO(this.produitsModesOperatoires);
          },
        error => this.utils.handleError(error, true));
    }
  };

  canModify = (): boolean => {
    let response = this.gds.canModify(this.yoProduit);
    return response;
  };

  help = (): DialogMsgSupplier => undefined;
}
