import {ObjectDTO} from './object-dto';
import {BoncfStatutDTO} from './boncf-statut-d-t-o';
import {WorkflowInstanceDTO} from './workflow-instance-dto';
import {BonReceptionDTO} from './bon-reception-dto';
import {MailHistoDTO} from './mail-histo-dto';
import {AdresseDTO} from "./adresse-dto";
import {BonCfDetailDTO} from "./boncf-detail-dto";

export class BonCfDTO extends ObjectDTO {

  libelle: string;
  numeroBcf: string;
  dateCommande: number;
  dateLivraison: number;
  dateLivraisonAjustee:number;
  montantHT: number;
  montantTTC: number;
  montantHTReception: number;
  montantTTCReception: number;
  francoDePortReception: number;

  // filiale
  fournisseurId: number;
  fournisseurLibelle:string;
  fournisseurFdpActif:boolean;
  fournisseurFdpMontant:number;
  fournisseurFdpPoids:number;
  fournisseurFdpCout:number;
  fournisseurFdpTva:number;



  secteurFournisseurId:number;

  uniteDeProductionId:number;
  uniteDeProductionLibelle:string;
  uniteDeProductionInfosLivraison:string;
  uniteDeProductionInfosFacturation:string;
  uniteDeProductionSignature: string;
  uniteDeProductionSiteId : number;

  bonCfStatut:BoncfStatutDTO;

  origine:string;
  origineListeBesoinId:number;
  listeBesoinLibelle:string;
  listeBesoinId:string;

  workflowInstance:WorkflowInstanceDTO;

  commentaire: string;

  // bons de reception
  bonReceptionList:BonReceptionDTO[] =[];

  // mail
  mailHisto : MailHistoDTO;

  adresse: AdresseDTO;

  details: BonCfDetailDTO[] = [];

  constructor() {
    super();
    this.commentaire = null;
  }
}
