<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  (onHide)="closeDialog(false)"
  [style]="{'width':'590px',  'max-height': '700px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-lg fa-edit mg-r-5"></i>{{title}}
    </div>
  </ng-template>
  <yo-form [form]="form" [groups]="groups" [options]="formOptions">
  </yo-form>
  <hr>
  <div class="d-flex justify-content-end">
    <!-- Bouton Enregistrer -->
    <span class="mg-r-5">
        <yo-button
          iconClass="fas fa-save"
          buttonClass="cta-success"
          [disabled]="!form?.valid"
          (onClick)="saveContratMenu()">
        </yo-button>
      </span>

    <!-- Bouton Annuler -->
    <yo-button
      class="mg-l-5"
      buttonClass="cta-inner-delete"
      (onClick)="closeDialog(false)"
      iconClass="fas fa-times">
    </yo-button>
  </div>
</p-dialog>
