<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Typologie du conditionnement</h1>
    <div class="item-wizard">
      <p class="required-field">Comment souhaitez-vous remplir vos conditionnements ? </p>
      <div>
        <input id="proportionnel" type="radio" name="modeModelePlat" [(ngModel)]="modeModelePlatSelected" [checked]="modeModelePlatSelected === 'PIECE_PROPORTIONNELLE' ? 'checked' : ''" value="PIECE_PROPORTIONNELLE" />
        <label for="proportionnel" class="mg-l-5 bold pointer">Au poids en fonction de vos prestations (Proportionnel)</label>
        <p class="mg-l-18">
          Ce mode peut-être utilisé par exemple pour un conditionnement de petits pois car la fiche technique détermine la relation entre le poids net à servir
          et le type de prestation.
        </p>
      </div>
      <div>
        <input id="non-proportionnel" type="radio" name="modeModelePlat" [(ngModel)]="modeModelePlatSelected" [checked]="modeModelePlatSelected === 'PIECE_NON_PROPORTIONNELLE' ? 'checked' : ''" value="PIECE_NON_PROPORTIONNELLE" />
        <label for="non-proportionnel" class="mg-l-5 bold pointer">En comptant des piéçages pour chacune de vos prestations (Non proportionnel)</label>
        <p class="mg-l-18">
          Ce mode peut-être utilisé pour un conditionnement de nuggets de poulet car on sert un certain nombre de piéçage(s) en fonction de la prestation, il
          n'y a pas forcément de relation de proportionnalité. On peut par exemple avoir des convives maternelle qui peuvent manger autant de nuggets de poulet
          que des convives primaires.
        </p>
      </div>
      <div>
        <input id="quantite" type="radio" name="modeModelePlat" [(ngModel)]="modeModelePlatSelected" [checked]="modeModelePlatSelected === 'PESEE' ? 'checked' : ''" value="PESEE" />
        <label for="quantite" class="mg-l-5 bold pointer">Au poids indépendemment des prestations (Pesées éventuellement non regroupables)</label>
        <p class="mg-l-18">
          Ce mode peut-être utilisé pour un conditionnement de poches de soupes d'un litre, les clients ne commandent pas à la portion mais pour un poids/volume servi.
        </p>
      </div>
    </div>
    <div class="item-wizard">
      <p class="required-field">Quelle unité à conditionner souhaitez-vous utiliser pour mesurer ce que vous avez l'intention de mettre dans vos emballages ? </p>
      <dx-lookup
        [dataSource]="unitesAConditionner"
        [value]="uniteMesureSelected?.id"
        valueExpr="id"
        displayExpr="libelle"
        [searchEnabled]="true"
        [placeholder]="placeholderUniteConditionnerModelePlat"
        (onSelectionChanged)="onChangeUniteMesure($event)">
        <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
      </dx-lookup>
    </div>
    <div class="item-wizard">
      <p class="required-field">Quel nom souhaitez-vous attribuer au modèle de plats pour votre conditionnement ? </p>
      <input type="text" class="input-text" name="labelModelePlat" [placeholder]="placeholderLabelModelePlat" [(ngModel)]="labelModelPlat" />
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 2/13 : Typologie du conditionnement - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
