import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {WorkflowDto} from '../../dtos/workflow-dto';
import {WorkflowEtapeDto} from '../../dtos/workflow-etape-dto';
import {startCase as _startCase} from 'lodash';
import {FP_ROUTES, WORKFLOW_TASKS} from '../../constants';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {Observable, Subject} from 'rxjs';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {RunStepDTO} from '../../dtos/run-step-dto';
import {RoutemapService} from '../routemap.service';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {HttpService} from '../technique/http.service';
import {InitProcessusSupplier} from "../../suppliers/gestion-processus/init-processus-supplier";

export const URL_POST_RUN_STEP = `dolrest/workflows/runStep`;
export const URL_POST_NEXT_STEP = `dolrest/workflows/nextStep`;

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class WorkflowsService extends GenericHandler<WorkflowDto> {

  private subjectWorkflowInstanceSaved = new Subject<WorkflowInstanceDTO>();
  workflowInstanceSaved$ = this.subjectWorkflowInstanceSaved.asObservable();

  private subjectWorkflowInstance = new Subject<WorkflowInstanceDTO>();
  workflowInstance$ = this.subjectWorkflowInstance.asObservable();

  constructor(
    private routeMapSvc: RoutemapService,
    private prefUsrSvc: PreferencesUtilisateurService,
    private httpSvc: HttpService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router,
    http: HttpClient, title: Title, private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  createEmptyDTO = (): WorkflowDto => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEntityName = (): string => 'workflow';

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => undefined;

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => undefined;

  getTitle = (): string => '';


  /**
   * Déclencher une étape du workflow
   * @param runStep
   */
  runStep = (runStep: RunStepDTO): Observable<ResponseWrapper<WorkflowInstanceDTO>> => this.httpSvc.post(URL_POST_RUN_STEP, runStep);

  /**
   * Passer à l'étape suivante du workflow
   * @param
   * @param {WorkflowEtapeDto} etape
   * @returns {Observable<any>}
   */
  goToNextStep = (runStep: RunStepDTO): Observable<ResponseWrapper<WorkflowInstanceDTO>> => this.httpSvc.post(URL_POST_NEXT_STEP, runStep);


  /**
   * Est ce que l'utilisateur connecté peut modifier l'instance
   * @param
   * @returns {boolean}
   */
  canModifyCurrentStep = (wi: WorkflowInstanceDTO): boolean => {

    if (!this.utils.isNullOrEmpty(wi)) {

      const actorList = wi.actors;
      if (!this.utils.isCollectionNullOrEmpty(actorList)) {

        const exists = !!this.utils.getFirstElement(actorList.filter(actor => actor.idUtilisateur === this.auth2Svc.utilisateur.id));

        return exists;

      }
    }

    return false;
  };

  announceWorkflowInstance = (one: WorkflowInstanceDTO) => {
    this.subjectWorkflowInstance.next(one);
  };


  openTask = (workflowInstance: WorkflowInstanceDTO) => {

    // console.log('open task');

    const workflowStatut = workflowInstance.codeWorkflowStatut.toLowerCase();

    switch (workflowStatut) {

      case WORKFLOW_TASKS.PRODUCTION_LISTE_PLATS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'pp-details']);
        break;

      case WORKFLOW_TASKS.SELECTION_MENUS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;

      case WORKFLOW_TASKS.SELECTION_PLCS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;

      case WORKFLOW_TASKS.BESOINS :
        this.openVueBesoins(workflowInstance);
        break;

      case WORKFLOW_TASKS.SORTIES_PRODUCTION :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;


      case WORKFLOW_TASKS.COMMANDES_FOURNISSEURS :
        this.routeMapSvc.goToPrimaryRoute([FP_ROUTES.GESTION_COMMANDES_FOURNISSEURS, 'bc', 'propositions']);
        break;
    }

  };

  /**
   * La liste de besoins à 2 vues : Denrees et Articles.
   * On selectionne la vue à partir de la preference utilisateur
   * @param workflowInstance
   */
  openVueBesoins = (workflowInstance: WorkflowInstanceDTO) => {

    this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'lb-details', 'articles']);


  };

  /**
   * Est ce que c'est une tache qui a besoin dêtre recalculée ?
   * Par exemple la selection des menus doit etre recalculee (enregistree) avant le passage en production
   * @param workflowStatutCode
   */
  isTaskToRecompute = (workflowStatutCode: string) => {

    if (workflowStatutCode.toLowerCase() === WORKFLOW_TASKS.SELECTION_MENUS || workflowStatutCode.toLowerCase() === WORKFLOW_TASKS.SELECTION_PLCS) {
      return true;
    }

    return false;
  };

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PROCESSUS '${data.libelle.toUpperCase()}'`;

  goToListeBesoinsDetails = (workflowInstance: WorkflowInstanceDTO) => {
    if (!this.utils.isNullOrEmpty(workflowInstance)) {
      this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'lb-details', 'articles'])
    }
  };

}
