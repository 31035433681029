import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DxDataGridComponent} from "devextreme-angular";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {GroupeAchatService} from "../../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {FournisseurService} from "../../../../../core/services/entities/fournisseur.service";
import {FournisseurDTO} from "../../../../../core/dtos/fournisseur-d-t-o";
import {MenuItem} from "primeng/api";
import {GroupeAchat__FournisseurDTO} from "../../../../../core/dtos/unite-de-production/groupe-achat-fournisseur-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ToastService} from "../../../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-dialog-add-fournisseur',
  templateUrl: './dialog-add-fournisseur.component.html',
  styleUrls: ['./dialog-add-fournisseur.component.scss']
})
export class DialogAddFournisseurComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: DxDataGridComponent;
  @ViewChild('gridratio') gridratio: DxDataGridComponent;


  fournisseurList: FournisseurDTO[] = [];
  fournisseurListSelected: FournisseurDTO[] = [];

  gaFournisseurList: GroupeAchat__FournisseurDTO[] = [];

  itemsSteper: MenuItem[] = [];
  activeIndex: number = 0;

  displayDialog: boolean = false;
  subOpenDialog: Subscription;

  ratioMassiveApply: number = 1;


  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private fournisseurSvc: FournisseurService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initData();
    this.initSteper();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  initData = () => {
    this.subOpenDialog = this.groupeAchatSvc.openDialogAddFournisseur$.subscribe(data => {
      this.displayDialog = data;
    });
    this.gds.getAll(this.fournisseurSvc.getEntityName(), ['libelle,asc'], true).subscribe((fournisseurList: ResponseWrapper<FournisseurDTO>) => {
      this.fournisseurList = fournisseurList.resultList.filter(f => this.auth2Svc.isSiteLocal(f.site.id));
    });
  };

  initSteper = () => {
    this.itemsSteper = [
      {label: 'Sélection'},
      {label: 'Majoration'}
    ];
  };

  sendFournisseurSelected = () => {

    if (this.checkRatio()) {

      this.gridratio.instance.closeEditCell();
      console.log(this.gaFournisseurList);
      this.groupeAchatSvc.announceFournisseurSelected(this.gaFournisseurList);
      this.closeDialog();
    }
  };

  checkRatio = (): boolean => {

    if (!this.utils.isCollectionNullOrEmpty(this.gaFournisseurList)) {

      const items = this.gaFournisseurList.filter(item => item.ratioPrixUF === 0);

      if (!this.utils.isCollectionNullOrEmpty(items)) {
        const msg = `Certains fournisseurs n'ont pas de ratio appliqué :\n ${items.map(item => item.fournisseur.libelle).join(', ')}`;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, msg);
        return false;
      }

    }

    return true;
  };

  addFournisseurSelected = () => {
    this.activeIndex = 1;
    this.mapToGroupeAchatFournisseurDto();
  };

  mapToGroupeAchatFournisseurDto = () => {
    this.fournisseurListSelected.forEach(item => {
      let gaf: GroupeAchat__FournisseurDTO = new GroupeAchat__FournisseurDTO();
      gaf.groupeAchat = undefined;
      gaf.fournisseur = item;
      gaf.ratioPrixUF = 0;
      this.gaFournisseurList.push(gaf);
    });
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.fournisseurListSelected = [];
    this.gaFournisseurList = [];
    this.grid.instance.clearSelection();
    this.activeIndex = 0;
    this.ratioMassiveApply = 0;
  };

  onChangeSelection = (event: any) => {
    this.fournisseurListSelected = event.selectedRowsData;
  };


  massiveApplyMajoration = () => {
    this.gaFournisseurList.forEach(item => {
      item.ratioPrixUF = this.ratioMassiveApply;
    });
  };
}
