<dx-popup
  [showTitle]="true"
  [title]="'FUSIONNER DES PROPOSITIONS DE COMMANDE'"
  [(visible)]="displayDialog"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <form [formGroup]="form" novalidate (ngSubmit)="mergeBonsCf()">
    <div class="form-group row">
      <label for="dateLiv" class="col-sm-2 col-form-label space">DATE DE LIVRAISON&nbsp;<span
        class="required"></span></label>
      <div class="col-sm-10" *ngIf="displayCalendar">

        <dx-calendar
          #calendar
          id="dateLiv"
          [min]="minDate"
          [disabled]="false"
          [firstDayOfWeek]="0"
          formControlName="dateLivraison"
          [disabledDates]="disabledDates"
          [cellTemplate]="cellTemplate">
          <span *dxTemplate="let cell of 'custom'"
                [ngClass]="getCellCssClass(cell)">
            {{ cell.text }}
          </span>
        </dx-calendar>

        <yo-control-error label="Date de livraison" [control]="form.controls['dateLivraison']"></yo-control-error>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-12 text-right">
        <span class="mg-r-5">
          <yo-button type="submit" iconClass="fas fa-plus" label="Ajouter"></yo-button>
        </span>
        <yo-button iconClass="fas fa-times"
                   buttonClass="cta-delete"
                   (onClick)="closeDialog()" label="Fermer"></yo-button>

      </div>
    </div>

  </form>
</dx-popup>


<!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI CORRESPONDENT AUX JOURS DE LIVRAISON DU SECTEUR FOURNISSEUR SÉLECTIONNÉ-->
<ng-template #tplDateMenuCompo let-isDateFournisseur="isDateFournisseur" let-date="date">

  <ng-container *ngIf="isDateFournisseur">
    <div class="creation-menus-day" title="Jour de livraison." >{{date.day}}</div>
  </ng-container>


  <ng-container *ngIf="!isDateFournisseur">
    <div title="Jour de livraison NON paramétré pour ce fournisseur">{{date.day}}</div>
  </ng-container>


</ng-template>
