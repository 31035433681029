import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {combineLatest, Subscription} from 'rxjs';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {FamilleProduitDTO} from '../../../core/dtos/famille-produit-dto';
import {ModeleNutritionnel__FamillePlatDTO} from '../../../core/dtos/modele-nutritionnel__famille-plat-dto';
import {sortBy as _sortBy} from 'lodash';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-familles-plats',
  templateUrl: './familles-plats.component.html',
  styleUrls: ['./familles-plats.component.scss']
})
export class FamillesPlatsComponent implements OnInit, OnDestroy {

  subModeleNutri: Subscription;
  planAlimentaire: ModeleNutritionnelDTO;
  selectedRepasList: RepasDTO[];
  famillesPlats: FamilleProduitDTO[];
  selectedFamillesPlats: FamilleProduitDTO[];
  selectedComposantesRepas: DecoupageRepasDTO[];
  grilleFamillesPlatsFromBack: ModeleNutritionnel__FamillePlatDTO[];
  cells: Map<string, ModeleNutritionnel__FamillePlatDTO>;

  cellWidth = 150;
  colFamillePlatWidth = 250;

  constructor(public gds: GenericDatagridService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              private ggSvc: GestionGemrcnService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    const parentRoute$ = this.route.parent.data;
    const currentRoute$ = this.route.data;
    const all$ = combineLatest([parentRoute$, currentRoute$]);

    this.subModeleNutri = all$
      .subscribe(response => {

        console.log(response)
        this.planAlimentaire = response[0].planAlimentaireSupplier.planAlimentaire;
        this.famillesPlats = response[1].famillePlatSupplier.famillesPlats.filter(item => item.fabrique);
        this.grilleFamillesPlatsFromBack = response[1].famillePlatSupplier.grilleFamillesPlats;

        this.selectedRepasList = response[1].famillePlatSupplier.selectedRepasList;
        this.selectedComposantesRepas = response[1].famillePlatSupplier.selectedComposantesRepas;
        const selectedFamillesPlatsFromBack = response[1].famillePlatSupplier.selectedFamillesPlats;

        this.selectedFamillesPlats = this.utils.preSelectMultiList(selectedFamillesPlatsFromBack, this.famillesPlats);

        this.cells = this.initCells(this.grilleFamillesPlatsFromBack, this.planAlimentaire,
          this.selectedFamillesPlats, this.selectedRepasList, this.selectedComposantesRepas);
      });
  }

  initCells = (grilleFamillesPlatsFromBack: ModeleNutritionnel__FamillePlatDTO[], planAlimentaire: ModeleNutritionnelDTO, selectedFamilles: FamilleProduitDTO[], selectedRepasList: RepasDTO[], selectedComposantesRepas: DecoupageRepasDTO[]): Map<string, ModeleNutritionnel__FamillePlatDTO> => {
    const cells = new Map();

    if (!this.utils.isCollectionNullOrEmpty(selectedFamilles) && !this.utils.isCollectionNullOrEmpty(selectedRepasList) && !this.utils.isCollectionNullOrEmpty(selectedComposantesRepas)) {

      for (let famille of selectedFamilles) {
        for (let repas of selectedRepasList) {
          for (let composante of selectedComposantesRepas) {

            let mnd = undefined;
            for (let mndBack of grilleFamillesPlatsFromBack) {

              if (mndBack.idModeleNutritionnel === planAlimentaire.id
                && mndBack.familleProduit.id === famille.id
                && mndBack.repas.id === repas.id
                && mndBack.decoupageRepas.id === composante.id) {
                mnd = mndBack;
                break;
              }
            }

            // si existe pas en bdd, alors on le créé à la volée
            if (this.utils.isCollectionNullOrEmpty(mnd)) {
              mnd = this.ggSvc.newModeleNutritionnelFamillePlat(this.planAlimentaire, famille, repas, composante);
            }

            const key = this.cellKey(famille, repas, composante);
            cells.set(key, mnd);

          }
        }
      }
    }
    return cells;
  };

  cellKey = (famille: FamilleProduitDTO, repas: RepasDTO, composante: DecoupageRepasDTO) => this.planAlimentaire.id + '-' + famille.id + '-' + repas.id + '-' + composante.id;

  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subModeleNutri);
  };

  isGridDisplayable = () => {

    if (!this.utils.isCollectionNullOrEmpty(this.selectedFamillesPlats) && !this.utils.isCollectionNullOrEmpty(this.selectedRepasList)
      && !this.utils.isCollectionNullOrEmpty(this.selectedComposantesRepas)) {
      return true;
    }
    return false;
  };

  save = () => {
    if (this.utils.isNullOrEmpty(this.planAlimentaire) || this.planAlimentaire.id < 1) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez enregistrer la fiche d'identité et le planning avant de paramétrer vos familles de plats`)
    } else {
      const cells = this.prepareCellsForSave(this.cells);
      this.ggSvc.saveGrilleFamillesPlats(cells, this.planAlimentaire).subscribe(response => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Paramétrage des familles de plat enregistré avec succès`));
    }
  };

  /**
   * On transforme la map de cellules en tableau pour l'envoyer au back. Au passage on applique les regles gemrcn sur chaque cellule active
   * @param mapCells
   * @param mapReglesGemrcn
   */
  prepareCellsForSave = (mapCells: Map<string, ModeleNutritionnel__FamillePlatDTO>): ModeleNutritionnel__FamillePlatDTO[] => {
    const cells: ModeleNutritionnel__FamillePlatDTO[] = [];
    mapCells.forEach((value, key) => {
      cells.push(value);
    });
    return cells;
  };


  getCell = (famille: FamilleProduitDTO, repas: RepasDTO, composanteRepas: DecoupageRepasDTO) => {
    const key = this.cellKey(famille, repas, composanteRepas);
    return this.cells.get(key);
  };

  selectCell = (cell: ModeleNutritionnel__FamillePlatDTO) => {
    cell.actif = !cell.actif;
  };

  refreshGrid = ($event: any) => {

    this.selectedRepasList = _sortBy(this.selectedRepasList, 'ordre');
    this.selectedComposantesRepas = _sortBy(this.selectedComposantesRepas, 'ordre');
    this.selectedFamillesPlats = _sortBy(this.selectedFamillesPlats, 'libelle');

    this.cells = this.initCells(this.grilleFamillesPlatsFromBack, this.planAlimentaire, this.selectedFamillesPlats, this.selectedRepasList, this.selectedComposantesRepas);

  };
}
