import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {find as _find, remove as _remove} from 'lodash';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {SearchService} from '../search.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {Auth2Service} from '../security/auth2.service';
import {GenericRequestSupplier, Sort} from '../../suppliers/generics/generic-request-supplier';
import {LazyLoadEvent} from 'primeng/api';
import {ExportRequestDTO} from '../../dtos/importexport/export-request-dto';
import {ImportRequestDTO} from '../../dtos/importexport/import-request-dto';
import {HttpService} from '../technique/http.service';

const URL_FINDALL = 'dolrest/generic/all';
const URL_FINDONE = 'dolrest/generic/one';
const URL_SEARCH = 'dolrest/generic/search';
const URL_EXPORT_XLSX = 'dolrest/generic/export/xlsx';
const URL_IMPORT_XLSX = 'dolrest/generic/import/xlsx';

@Injectable()
export class GenericDatagridService {
  get getUrlPaginationParamsFromDataGridDx(): (pageSize: number, page: number, sorts: Sort[]) => string {
    return this._getUrlPaginationParamsFromDataGridDx;
  }

  set getUrlPaginationParamsFromDataGridDx(value: (pageSize: number, page: number, sorts: Sort[]) => string) {
    this._getUrlPaginationParamsFromDataGridDx = value;
  }


  constructor(private utils: UtilsService,
              private httpSvc:HttpService,
              private http:HttpClient,
              private searchSvc: SearchService,
              private auth2Svc: Auth2Service
  ) {
  }


  /**
   * Récupérer une instance d'entité par son id
   * @param {string} entityName
   * @param {number} id
   */
  getOne = (entityName: string, id: number) => {

    const url = `${URL_FINDONE}?entityName=${entityName}&id=${id}`;
    return this.httpSvc.get(url);
  };

  getPage = (event: LazyLoadEvent): number => {

    if (event.first === 0) {
      return 0;
    } else {
      let page = event.first / event.rows;
      let pageStr = page.toFixed(0);
      page = parseInt(pageStr);

      return page;
    }

  };

  getSize = (event: LazyLoadEvent): number => event.rows;

  getSort = (event: LazyLoadEvent, defaultSortField: string): Sort => {
    const sort = new Sort();

    let sortDir = 'asc';
    if (event.sortOrder === -1) {
      sortDir = 'desc';
    }

    sort.dir = sortDir;
    sort.path = this.utils.isNullOrEmpty(event.sortField) ? defaultSortField : event.sortField;

    return sort;
  };

  private _getUrlPaginationParamsFromDataGridDx = (pageSize: number, page: number, sorts: Sort[]): string =>  {
    let result = page !== null && page !== undefined && pageSize !== null && pageSize !== undefined ? `?page=${page}&size=${pageSize}` : '?';
    let buildSort = '';
    if (sorts && sorts.length) {
      buildSort += '&';
      sorts.forEach((sort, idx) => {
        buildSort += `sort=${sort.path},${sort.dir}`;
        if (idx < sorts.length - 1)
          buildSort += '&';
      });
    }
    result += buildSort;
    return result;
  }

  getUrlPaginationParams = (event: LazyLoadEvent): string => {

    let urlParams = `?`;

    let sortDir = 'asc';
    if (event.sortOrder === -1) {
      sortDir = 'desc';
    }

    urlParams += `page=${this.getPage(event)}`;

    urlParams += `&size=${this.getSize(event)}`;

    if (!this.utils.isNullOrEmpty(event.sortField)) {
      urlParams += `&sort=${event.sortField},${sortDir}`;
    }

    return urlParams;
  };

  /**
   * Récupérer tous objets d'un type d'entité, on peut les trier selon on ordre
   * Filtré par site de l'utilisateur
   * @param entityName
   * @param sort
   * @param oas ne récupérer que les elements actifs des sites de l'uiltisateur (oas =>  only actif and sites)
   * @return {Observable<any>}
   */
  getAll = (entityName: string, sort?: string[], oas ?: boolean) => {

    let oasParam = this.createOasParam(oas);
    let sortParam = this.searchSvc.createSortUrlParam(sort);

    let url = `${URL_FINDALL}?entityName=${entityName}${sortParam}${oasParam}`;

    return this.httpSvc.get(url);
  };

  /**
   * Rechercher des éléments, le retour est paginé
   * @param {GenericRequestSupplier} genericRequest
   */
  search = (genericRequest: GenericRequestSupplier) => this.httpSvc.post(URL_SEARCH, genericRequest);



  refreshDatagridPrimengAfterDelete = (objects: ObjectDTO[], response: ResponseWrapper<number>, entityName: string) => {
    if (!this.utils.isNullOrEmpty(objects)) {
      if (!this.utils.isNullOrEmpty(response) && response.one > 0 && entityName.toLowerCase() === response.entityName.toLowerCase()) {
        objects = objects.filter(item => item.id !== response.one);
      }
    } else {
      objects = [];
    }
    return [...objects];
  };



  /**
   *
   * @param objects
   * @param instanceObj response object
   * @param entityName
   */
  refreshDatagridPrimengAfterSave = (objects: ObjectDTO[], instanceObj, entityName: string) => {

    if (this.utils.isNullOrEmpty(objects)) {
      objects = [];
    }

    if (!this.utils.isNullOrEmpty(instanceObj) && entityName.toLowerCase() === instanceObj.entityName.toLowerCase()) {
      if (instanceObj.newObj) {
        if (this.utils.isNullOrEmpty(objects)) {
          objects = [];
          objects.unshift(instanceObj.one);
        } else {
          objects.unshift(instanceObj.one);
        }
      } else {
        objects = objects.map(item => {
          if (item.id === instanceObj.one.id) {
            return instanceObj.one;
          }
          return item;
        });
      }
    }
    return [...objects];
  };

  getLength = (datasource): number => {
    if (!this.utils.isNullOrEmpty(datasource)) {
      if (!this.utils.isCollectionNullOrEmpty(datasource.data)) {
        return datasource.data.length;
      }
    }
    return 0;
  };

  /**
   * Indique si l'{@link ObjectDTO} passé en paramètre peut être modifié. Il l'est si son
   * champ {@link ObjectDTO#siteId} correspond au site principal de l'utilisateur connecté.
   * @param {ObjectDTO} element
   * @returns {boolean}
   */
  canModify = (element: ObjectDTO): boolean => {
    if (!this.utils.isNullOrEmpty(element)) {

      if (!this.utils.isNullOrEmpty(element.id)) {
        if (element.id === 0) {
          return true;
        }
      }

      if (!this.utils.isNullOrEmpty(element.site)) {
        return this.auth2Svc.isSiteLocal(element.site.id);
      }

    }
    return false;
  };


  /**
   * Pour pouvoir détruire un {@link ObjectDTO}, il faut :
   * <li>avoir un droit d'écriture dessus.
   * <li>que son ID soit non nul (ID = 0 indique un {@link ObjectDTO} non encore créé).
   * @param element
   */
  canDelete = (element: ObjectDTO): boolean => {
    let response: boolean;

    //
    if (!this.utils.isNullOrEmpty(element)) {
      if (!this.utils.isNullOrEmpty(element.id)) {
        if (element.id == 0) { // ID à 0 indique un ObjectDTO non encore créé : on ne peut donc pas le détruire.
          response = false;
        } else { // On ne peut le détruire que si on a des droits d'écriture dessus.
          response =  this.canModify(element);
        }
      } else { // Ne devrait jamais arriver...
        response =  this.canModify(element);
      }
    } else { // ne devrait jamais arriver : on ne peut pas détruire
      response = false;
    }
    return response;
  };


  /**
   * Supprimer les items d'une  grille   *
   * @param objects
   * @param objectsToRemove
   */
  deleteGridRows = <T extends ObjectDTO>(objects: T[], objectsToRemove: T[],) => {

    if (this.utils.isCollectionNullOrEmpty(objects)) {
      objects = [];
    }

    // supprimer les lignes qui sont passées en à confirmer de la grille
    _remove(objects, function (o) {
      for (let otr of objectsToRemove) {
        if (otr.id === o.id) {
          return true;
        }
      }
      return false;
    });


  };

  importXlsx = (importRequest: ImportRequestDTO, file:File) => {

    const fd = new FormData();
    fd.set('myJson', JSON.stringify(importRequest));
    fd.set('xslx', file);

    return this.httpSvc.post(URL_IMPORT_XLSX, fd);
  };


  exportXlsx = (exportRequest: ExportRequestDTO) => {

    return this.http.post(URL_EXPORT_XLSX, exportRequest, {
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true)));
  };

  /**
   * récupérer les libelles des objets mappés par leur id passés en paramétre
   * @param ids
   * @param selectedValues
   */
  mapObjectsFromIds = (ids: number[], selectedValues: ObjectDTO[]): ObjectDTO[] => {

    const elts = [];
    if (!this.utils.isCollectionNullOrEmpty(ids)) {

      for (let id of ids) {
        const exists = _find(selectedValues, {'id': id});
        if (exists) {
          elts.push(exists);
        }
      }
    }

    return elts;
  };

  private createOasParam = (oas?: boolean): string => {
    if (!this.utils.isNullOrEmpty(oas) && oas) {
      return '&oas=true';
    }
    return '';
  };


}
