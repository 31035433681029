<p-dialog
  (onHide)="closeDialog()"
  [dismissableMask]="true"
  [maximizable]="true"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'max-width':'600px'}"
  [(visible)]="displayDialog"
  [modal]="true"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Recherche dans le ciqual
    </div>
  </ng-template>

  <!--  CONTENT-->
  <span class="p-input-icon-left mg-t-10 mg-b-10">
    <i class="pi pi-search"></i>
    <input id="searchTerm" class="width-350" type="text" pInputText [(ngModel)]="searchTerm"
           placeholder="Rechercher un plat dans le ciqual..." (keyup)="applyFilter($event.target.value)"/>
</span>


  <dx-data-grid
    [dataSource]="alimList"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(400)"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [allowColumnResizing]="true"
    [selectedRowKeys]="[]"
    #grid>

    <!--            SELECTION-->
    <dxo-selection mode="single"></dxo-selection>

    <dxi-column dataField="alim_nom_fr" caption="Nom" [width]="100"
                [allowFiltering]="false"
                [allowGrouping]="false">
    </dxi-column>

    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} plat(s)">
    </dxo-pager>

  </dx-data-grid>

  <ng-container *ngIf="grid">
    <div *ngIf="grid.selectedRowKeys.length" class="height-40">
      <span
        class="mg-r-5">Plat sélectionné : </span><strong>{{grid.instance.getSelectedRowsData()[0].alim_nom_fr}}</strong>
    </div>

    <div *ngIf="!grid.selectedRowKeys.length" class="height-40">
    </div>

  </ng-container>


  <!--  FOOTER-->
  <ng-template pTemplate="footer">
    <div class="mg-t-10">
       <span class="mg-r-5 ">
          <yo-button
            tooltip="Importer"
            buttonClass="fas fa-upload"
            [disabled]="disabled()"
            (onClick)="savePropage()"
          ></yo-button>
        </span>
      <span class="mg-r-5">
          <yo-button
            label="Fermer"
            buttonClass="cta-inner-delete"
            (onClick)="closeDialog()"></yo-button>
        </span>
    </div>
  </ng-template>


</p-dialog>
