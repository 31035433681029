import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UdpConditionnementVarianteService} from "../../../../../core/services/conditionnements/udp-conditionnement-variante.service";
import {Subscription} from "rxjs/index";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ConditionnementsVarianteService} from "../../../../../core/services/conditionnements/conditionnement-variante.service";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import ConditionnementVarianteDTO from "../../../../../core/dtos/conditionnement/conditionnement-variante-dto";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-dialog-ajout-conditionnement',
  templateUrl: './dialog-ajout-conditionnement.component.html',
  styleUrls: ['./dialog-ajout-conditionnement.component.scss']
})
export class DialogAjoutConditionnementComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;

  displayDialog: boolean = false;
  subOpenDialog: Subscription;

  conditionnementVariantesList: ConditionnementVarianteDTO[] = [];
  condiVariantesSelectedList: ConditionnementVarianteDTO[] = [];

  constructor(public utils: UtilsService,
              private udpCondiVarianteSvc: UdpConditionnementVarianteService,
              private cvSvc: ConditionnementsVarianteService) {
  }

  ngOnInit() {
    this.subcriptionDialogAjoutCondi();
    this.initData();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  initData = () => {
    this.cvSvc.getAll().subscribe((data: ResponseWrapper<ConditionnementVarianteDTO>) => {
      this.conditionnementVariantesList = data.resultList;
    });
  };

  subcriptionDialogAjoutCondi = () => {
    this.subOpenDialog = this.udpCondiVarianteSvc.openDialogAjoutCondi$.subscribe(data => {
      this.displayDialog = true;
    });
  };

  addContionnementVariantesSelect = () => {
    this.udpCondiVarianteSvc.announceCondiVariantesSelectedList(this.condiVariantesSelectedList);
    this.closeDialog();
  };

  onChangeCondiVarianteSelection = event => {
    this.condiVariantesSelectedList = event.selectedRowsData;
  };

  closeDialog = () => {
    this.grid.instance.clearSelection();
    this.displayDialog = false;
    this.condiVariantesSelectedList = [];
  };
}
