import {Injectable} from '@angular/core';
import {MSG_SEVERITY} from '../../constants';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private subjectDisplayToast = new Subject();
  displayToast$ = this.subjectDisplayToast.asObservable();

  displayToast = (key: string, type: string, message: string, urlMessage?: string, time?: number): void => {
    let timeDefault = 8000;
    let timeByType = timeDefault;
    if (!time) {
      switch (type) {
        case MSG_SEVERITY.INFO:
          timeByType = 4000;
          break;
        case MSG_SEVERITY.SUCCESS:
          timeByType = 3000;
          break;
        case MSG_SEVERITY.WARNING:
          timeByType = 8000;
          break;
        case MSG_SEVERITY.ERROR:
          timeByType = 8000;
          break;
      }
    }
    this.subjectDisplayToast.next({key, type, message, urlMessage, time: !time ? timeByType : timeDefault });
  };

}
