import {Injectable} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {SecteurFournisseurDTO} from '../../dtos/secteurfournisseur-dto';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable()
export class SecteurFournisseurDatasourceService implements DataSource<SecteurFournisseurDTO> {

  private objectsSubject = new BehaviorSubject<SecteurFournisseurDTO[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SecteurFournisseurDTO[]> {
    console.log("Connecting data source");
    return this.objectsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    console.log("disconnecting data source");
    this.objectsSubject.complete();
  }
}
