<div id="moves-wrapper" class="mg-b-10 pg-t-10">
  <div class="row">
    <div class="col-lg-3">
      <yo-search-panel-mouvements></yo-search-panel-mouvements>
    </div>
    <div class="col-lg-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>








