import {TypeProduitDTO} from './type-produit-dto';
import {FamilleProduitDTO} from './famille-produit-dto';
import {ObjectDTO} from './object-dto';
import {ProduitDeclinaisonDTO} from './produit-declinaison-dto';
import {UniteDeMesureDTO} from './unitedemesure-dto';
import {ZoneDeStockageDTO} from './zone-de-stockage-dto';
import {ProduitContrainteAlimDTO} from './produit-contrainte-alim-dto';
import {Produit__TacheDTO} from './produit-tache-dto';

export class ProduitDTO extends ObjectDTO {


  actif: boolean;
  reference: string;
  code: string;
  dlc: number;
  libelle: string;
  guid: string;
  libellelong: string;
  ratioVolume: number;
  recetteNbPortions: number;

  typeProduit: TypeProduitDTO;
  familleProduit: FamilleProduitDTO;
  produit__tacheList: Produit__TacheDTO[];
  uniteDeMesure: UniteDeMesureDTO;
  zoneDeStockage: ZoneDeStockageDTO;

  produitDeclinaisons: ProduitDeclinaisonDTO[] = [];
  produitContrainteAlimList: ProduitContrainteAlimDTO[] = [];

  hasPhoto: boolean;

}
