<dx-toolbar class="yo-toolbar">
  <dxi-item location="before" *ngIf="canEdit()">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-15"
        label="AJOUTER"
        tooltip="Ajouter une denrée sans approvisionnement"
        iconClass="fa fa-plus"
        (onClick)="openDialogAjoutProduitDeclinaison()">
      </yo-button>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canEdit() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="udpProduitsDeclinaisonsNonApprovisionnesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-produits-declinaisons-non-approvisionnes></yo-dialog-ajout-produits-declinaisons-non-approvisionnes>
