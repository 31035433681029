// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10081(0fbc14d7d0)-C28/03/2024-18:39:32-B28/03/2024-18:43:11' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10081(0fbc14d7d0)-C28/03/2024-18:39:32-B28/03/2024-18:43:11",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10081",
  shortHash: "0fbc14d7d0",
  longHash: "0fbc14d7d0f6ff964155c0d48eb4cfe2f6472748",
  dateCommit: "28/03/2024-18:39:32",
  dateBuild: "28/03/2024-18:43:11",
  buildType: "Ansible",
  } ;
