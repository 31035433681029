import {Component, ElementRef, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import JSONFormatter from "json-formatter-js";

@Component({
  templateUrl: './view-json-graphql.component.html',
  styleUrls: ['./view-json-graphql.component.scss'],
  selector: 'yo-view-json-graphql',
})
export class ViewJsonGraphQLComponent implements OnInit, OnDestroy {

  hasRequeteurGraphQL: boolean;
  dataLoaded: boolean;
  formatter: any;

  subData: Subscription;

  constructor(private utils: UtilsService,
              private graphQlSvc: GraphQLService,
              private element: ElementRef) {
    this.dataLoaded = false;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subData);
  }

  ngOnInit() {
    this.initDataSubscription();
  }

  isReady() {
    return this.dataLoaded;
  }

  initDataSubscription(): void {
    this.subData = this.graphQlSvc.queryReceived$
      .subscribe(data => {
        this.dataLoaded = true;
        const formatter = new JSONFormatter(data);
        this.element.nativeElement.innerHTML = "";
        if (formatter) {
          this.element.nativeElement.appendChild(formatter.render());
        }
      });
  }

}
