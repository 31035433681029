import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProduitAllergeneDTO} from '../../core/dtos/produit-allergene-dto';
import {isEmpty as _isEmpty} from 'lodash';
import {Subscription} from 'rxjs';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {AllergenesService} from '../../core/services/entities/allergenes-service.service';
import {USER_PREFERENCE} from '../../core/constants';

@Component({
  selector: 'yo-allergenes-menucompo',
  templateUrl: './allergenes-menucompo.component.html',
  styleUrls: ['./allergenes-menucompo.component.scss'],
  changeDetection :ChangeDetectionStrategy.OnPush
})
export class AllergenesMenucompoComponent implements OnInit, OnDestroy {
  @Input() set produitsAllergenes(values: ProduitAllergeneDTO[]) {
    this._produitsAllergenes = values;
  }

  //Subscription
  subDisplayAllergenes: Subscription;
  subPrefsUser: Subscription;
  displayAllergenes: boolean = false;
  _produitsAllergenes: ProduitAllergeneDTO[];
  refreshImg: number = 1;

  constructor(public allergeneSvc: AllergenesService,
              private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.subDisplayAllergenes = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayAllergenes =  this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_ALLERGENES);
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayAllergenes);
  }

  getInfoAllergenes = (): string => 'Allergènes : \n' + this._produitsAllergenes.map(item => '\t - ' + item.allergeneLibelle).join('\n');

  canDisplayAllergenes = (): boolean => this.displayAllergenes && !_isEmpty(this._produitsAllergenes);
}
