import {Component} from '@angular/core';

@Component({
  selector: 'yo-gp-root',
  templateUrl: './gp-root.component.html',
  styleUrls: ['./gp-root.component.scss']
})
export class GpRootComponent {

  constructor() { }

}
