import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, FS_ROUTES, HAS_ADMINISTRATION, HAS_GESTION_IDISTRI, OUTLET_SECONDARY} from '../core/constants';
import {NgModule} from '@angular/core';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {GestionConditionnementsRootComponent} from './gestionconditionnements.component';
import {ModelePlatResolverService} from './modele-plat-resolver.service';
import {ModelePlatComponent} from './modeles-plats/dialog/modele-plat.component';
import {ParametrageConditionnementModelesPlatsComponent} from './modeles-plats/dialog/parametrage/mp-parametrage.component';
import {PlatsComponent} from './modeles-plats/dialog/plats/mp-plats.component';
import {ModelesPlatsComponent} from './modeles-plats/modeles-plats.component';
import {ModelePlatIdentiteComponent} from './modeles-plats/dialog/fiche-identite/mp-identite.component';
import {ModelesPlcComponent} from "./modeles-plc/modeles-plc.component";
import {ModelePlcComponent} from "./modeles-plc/dialog/modele-plc.component";
import {ModelePlcResolverService} from "./modele-plc-resolver.service";
import {ModelePlcIdentiteComponent} from "./modeles-plc/dialog/fiche-identite/mp-identite.component";
import {ParametrageConditionnementModelesPlcComponent} from "./modeles-plc/dialog/parametrage/mp-parametrage.component";
import {PointsLivraisonComponent} from "./modeles-plc/dialog/points-livraison/mp-points-livraison.component";
import {PreparationConditionnementComponent} from "./preparation/preparation-conditionnement.component";
import {PreparationCalculConditionnementComponent} from "./preparation/calcul/preparation-calcul-conditionnement.component";
import {PreparationConditionnementResolverService,} from "./preparation-conditionnement-resolver.service";

const routes: Routes = [{
      path: FP_ROUTES.GESTION_CONDITIONNEMENTS,
      component: GestionConditionnementsRootComponent,
      canActivate: [Auth2Guard],
      canActivateChild: [Auth2Guard],
      runGuardsAndResolvers: 'always',
      data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
      children: [
        {
          path: `modeles-plats`,
          component: ModelesPlatsComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        },
        {
          path: `modeles-plc`,
          component: ModelesPlcComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        },
      ]
    },
    {
      path: `${FS_ROUTES.GESTION_PREPARATION_CONDITIONNEMENTS}/:planProductionId/:isRefresh`,
      component: PreparationConditionnementComponent,
      data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
      resolve: {
        preparationConditionnementSupplier: PreparationConditionnementResolverService
      },
      outlet: OUTLET_SECONDARY,
      children: [
        {
          path: 'calcul',
          component: PreparationCalculConditionnementComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        }
      ]
    },
    {
        path: `${FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT}/:idModeleConditionnementPlat`,
        component: ModelePlatComponent,
        data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        runGuardsAndResolvers: 'always',
        resolve: {
          modelePlatSupplier: ModelePlatResolverService
        },
        outlet: OUTLET_SECONDARY,
        children: [
          {
            path: 'ficheidentite',
            component: ModelePlatIdentiteComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
          },
          {
            path: 'parametrage',
            component: ParametrageConditionnementModelesPlatsComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
          },
          {
            path: 'plats',
            component: PlatsComponent,
            runGuardsAndResolvers: 'always',
            data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
          }
        ]
    },
    {
      path: `${FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLC}/:idModeleConditionnementPlc`,
      component: ModelePlcComponent,
      data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
      runGuardsAndResolvers: 'always',
      resolve: {
        modelePlcSupplier: ModelePlcResolverService
      },
      outlet: OUTLET_SECONDARY,
      children: [
        {
          path: 'ficheidentite',
          component: ModelePlcIdentiteComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        },
        {
          path: 'parametrage',
          component: ParametrageConditionnementModelesPlcComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        },
        {
          path: 'points-livraisons',
          component: PointsLivraisonComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        }
      ]
    },
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ModelePlatResolverService]
})
export class GestionConditionnementsRoutingModule {
}
