import {LotMarchePdLumpCaDto} from '../../../core/dtos/gerstion-marche/lot-marche-pd-lump-ca-dto';
import {GroupeAchatCaViewModel} from "../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";

export class ViewModelCatalogueAchat {
  id: number;
  caSiteId
  article: string;
  isPrefere: boolean;
  articleSiteId: number;
  articleSiteLibelle: string;
  declinaison: string;
  fournisseur: string;
  fournisseurOwnerLibelle: string;
  fournisseurTooltip: string;
  referenceArticle: string;
  referenceFournisseur: string;
  prixAchat: number;
  uniteDeFacturation: string;
  ratioUniteDeFacturation: number;
  uniteDeCommande: string;
  ratioUniteDeCommande: number;
  privilegie: boolean;
  actif: boolean;
  produitDeclinaisonLibelle: string;
  produitDeclinaisonId: number;
  produitDeclinaisonCode:string;
  produitDeclinaisonActif: boolean;
  uniteDeStockage: string;
  ratioUniteStockageUniteBase: number;
  codeApi: string;
  lmPdUpCa:LotMarchePdLumpCaDto;

  // prix
  groupeAchatCaList :GroupeAchatCaViewModel[] = [];

}
