import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {AppellationDTO} from '../../dtos/appellations-dto';
import {HttpService} from "../technique/http.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";
import {ImageSize} from "../../enums/image-size-enum";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {Subject} from "rxjs/index";
import {ObjectDTO} from "../../dtos/object-dto";
import {Auth2Service} from "../security/auth2.service";
import {GraphQLService} from "../technique/graphql.service";

export const URL_FIND_ALL_APPELATION=`dolrest/appellations/findAll`;

@Injectable({
  providedIn: 'root'
})
export class AppellationsService {

  appellationsEnvironnement:AppellationDTO[];

  public noImagePath: string = 'assets/images/no-image.png';

  private subjectAppellationSaved: Subject<any> = new Subject<any>();

  appellationSaved$ = this.subjectAppellationSaved.asObservable();

  private subjectOpenDialog: Subject<AppellationDTO> = new Subject<AppellationDTO>();

  openDialog$ = this.subjectOpenDialog.asObservable();

  constructor(
              private http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private httpSvc: HttpService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService) {
    this.findGraphQL();
  }

  findGraphQL = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.graphQlSvc.sendQuery(`
      {
        allAppellations(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            durable,
            bio,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.appellationsEnvironnement = response.allAppellations;
      });
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  getUrl = (item: AppellationDTO, size: ImageSize, noCache: boolean = false): string => {
    return `dolrest/document/loadByEntity/appellation/${item?.id}/1/${size}?date=1${noCache ? `&${Date.now()}` : ''}`;
  }

  onImageError = (event: Event) => {
    let image = (event.target as HTMLImageElement);
    image.onerror = null;
    image.src = this.noImagePath;
  }

  exportByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/appellations/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/appellations/import", formData, null);
  };

  deleteByFilters = (rowsToDelete?: number[]) => {
    let params: string = '1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`dolrest/appellations/delete?${params}`);
  };

  announceOpenDialogEdition = (app?: AppellationDTO) => {
    this.subjectOpenDialog.next(app);
  };

  announceAppellationSaved = () => {
    this.subjectAppellationSaved.next(null);
  }

  save = (app: AppellationDTO, needToDeleteFile: boolean, image: File): Observable<ResponseWrapper<AppellationDTO>> => {
    const formData = new FormData();
    formData.set('appellation', JSON.stringify(app));
    formData.set('deleteFile', JSON.stringify(needToDeleteFile));
    formData.set('file', image);
    return this.httpSvc.post("dolrest/appellations/save", formData);
  }

  getEntityName = (): string => 'appellation';

  /**
   * récupèrer toutes les appellations
   */
  findAll = (ssw?: SearchSupplierWrapper) => this.httpSvc.post(URL_FIND_ALL_APPELATION, ssw || null);

}
