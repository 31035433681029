import {ObjectDTO} from './object-dto';

export class TypeProduitDTO extends ObjectDTO {

  libelle : string;
  fabrique : boolean;
  code:string;
  actif:boolean;

}
