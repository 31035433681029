import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'yo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() type: string = "button";
  @Input() name: string = "";
  @Input() buttonId: string = "";
  @Input() buttonClass: string = "cta-edit-or-watch";
  @Input() iconClass: string = "";
  @Input() iconPos: string = "left";
  @Input() label: string = "";
  @Input() tooltip: string = "";
  @Input() disabled: boolean = false;
  @Input() size: string = "";
  @Output() onClick = new EventEmitter;

  constructor() {
  }

  ngOnInit() {
    this.initButtonClasses();
  }

  initButtonClasses = () => {
    let defaultClass = "custom-button";
    if (this.size)
      defaultClass += "-" + this.size;
    this.buttonClass = defaultClass + " " + this.buttonClass;
  }

  onButtonClick = (event): void => {
    this.onClick.emit(event);
  }
}
