import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {FamillesGemrcnService} from '../../../core/services/entities/familles-gemrcn.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {GraphQLService} from "../../../core/services/technique/graphql.service";


@Injectable()
export class GemrcnResolverService implements Resolve<GemrcnSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private produitRouteMap: ProduitRouteMapService,
              private utils: UtilsService,
              private famillesGemrcnSvc:FamillesGemrcnService,
              private produitsSvc:ProduitsService,
              private gds:GenericDatagridService,
              private graphQlSvc: GraphQLService) {
  }

  /**
   * Charger les données necessaires àl'affichage des familles gemrcn, apports caloriques, ciqual pour une liste de produits declines
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<GemrcnSupplier> | Promise<GemrcnSupplier> | GemrcnSupplier}
   */
  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GemrcnSupplier> | Promise<GemrcnSupplier> | GemrcnSupplier => {
    const idProduit: number = +route.parent.paramMap.get('idProduit');

    const vns = new GemrcnSupplier();

    const famillesGemrcn$ = this.getFamillesGemrcn();
    const produitsDeclinaisons$ = this.gds.getOne(this.produitsSvc.getEntityName(),idProduit);
    const initAll$ = combineLatest(famillesGemrcn$,produitsDeclinaisons$);

    return initAll$.toPromise().then(items => {
      if (!this.utils.isNullOrEmpty(items[0])) {
        vns.familleGemrcnList = items[0].allFamillesGemrcn;
      }
      if (!this.utils.isNullOrEmpty(items[1])) {
        const produit : ProduitDTO = items[1].one  ;
        vns.produitDeclinaisonList = produit.produitDeclinaisons;

      }
      return vns;
    });
  };

  private getFamillesGemrcn = () : Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allFamillesGemrcn(filters: {
          siteIds: [${idsSites}]
        }) {
          id,
          libelle,
          couleur {
            hexa
          }
        }
      }
    `);
  }

}

export class GemrcnSupplier {
  familleGemrcnList: FamilleGemrcnDTO[];
  produitDeclinaisonList: ProduitDeclinaisonDTO[];

}
