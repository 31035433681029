import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {CheckRatios} from "../../models/gestion-produits/check-ratios";
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {RATIOS} from "../../constants";

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  constructor(private httpSvc: HttpService) { }

  private subCheckRatios = new Subject<CheckRatios>();
  checkRatios$ = this.subCheckRatios.asObservable();

  announceOpenDialog = (item: CheckRatios) => {
    this.subCheckRatios.next(item);
  }

  checkRatios = (): Observable<ResponseWrapper<CheckRatios>> => this.httpSvc.get(`dolrest/produits/check-ratios`);

  updateRatio = (id: number, value: number, typeRatio: RATIOS): Observable<ResponseWrapper<any>> => this.httpSvc.post(`dolrest/produits/update-ratio`, { id, value, typeRatio });

  calculationRatioUcUf = (ratioUF: number, ratioUC: number): number => ratioUF > 0 ? parseFloat((ratioUC / ratioUF).toFixed(4)) : 0;

  calculationRatioUsUt = (ratioUT: number, ratioUS: number): number => ratioUT > 0 ? parseFloat((ratioUS / ratioUT).toFixed(4)) : 0;

  calculationRatioUs = (ratioUsUt: number, ratioUT: number): number => ratioUsUt * ratioUT;

  calculationRatioUc = (ratioUcUf: number, ratioUF: number): number => ratioUcUf * ratioUF;

}
