import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HAS_READ_GRAPHQL} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {RequeteurGraphqlComponent} from "./requeteur-graphql.component";


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'search-graphql',
        component: RequeteurGraphqlComponent,
        canActivate: [Auth2Guard],
        data: HAS_READ_GRAPHQL
      },
    ])
  ],
  exports: [RouterModule]

})
export class RequeteurGraphqlRoutingModule {}

