import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-preparation-conditionnement',
  templateUrl: './preparation-conditionnement.component.html',
  styleUrls: ['./preparation-conditionnement.component.scss']
})
export class PreparationConditionnementComponent implements OnInit {

  ngOnInit(): void {
  }

}
