import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";
import {SiteDTO} from '../dtos/site-dto';

export class FormFieldDropdownSupplierSiteDTO extends FormFieldBaseSupplier<SiteDTO> {
  controlType = 'dropdown';
  options: {key: string, value: SiteDTO}[] = [];


  constructor(options: any) {
    super(options);
    this.options = options['options'] || [];
  }

}
