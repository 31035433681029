import {Component, Input} from "@angular/core";

@Component({
  selector: 'yo-tabs-warnings-org-tournee',
  templateUrl: './tabs-warnings-org-tournee.component.html',
  styleUrls: ['./tabs-warnings-org-tournee.component.scss']
})
export class TabsWarningsOrgTourneeComponent {

  @Input() warnings: any;

  disableDayPrestationNotConfigured = (rowsByDay: any[]): boolean => {
    if (!rowsByDay || !rowsByDay.length) return true;
    let size: number = rowsByDay.length;
    let nbRowsWithoutPrestationsNotConfigured: number = 0;
    rowsByDay.forEach(row => {
      if (!row.labelsPrestations) ++nbRowsWithoutPrestationsNotConfigured;
    });
    return size === nbRowsWithoutPrestationsNotConfigured;
  }

}
