<dx-popup
  [showTitle]="true"
  [title]="'Joindre un ou plusieurs bon(s) de réception'"
  [(visible)]="displayDialog"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <div class="row">
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-date-box id="dateLivStart" placeholder="Filtrer par début de date de livraison" [(value)]="dateLivraisonStartSelected" type="date" (onValueChanged)="refreshDataSource()"></dx-date-box>
    </div>
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-date-box id="dateLivEnd" placeholder="Filtrer par fin de date de livraison" [(value)]="dateLivraisonEndSelected" type="date" (onValueChanged)="refreshDataSource()"></dx-date-box>
    </div>
  </div>
  <div class="row mg-b-15">
    <div class="col-lg-12"><i class="fas fa-exclamation-triangle warn-color"></i> <span class="font-italic"> Attention, ne figurent que les bons de réception ne faisant parti d'aucune facture existante.</span></div>
  </div>
  <div class="row mg-b-15">
    <div class="col-lg-12">Veuillez cocher les bons de réception que vous souhaitez joindre la facture n°{{ currentNumberInvoice }} puis appuyez sur le bouton enregistrer : </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <dx-data-grid
        [dataSource]="bonsReceptions"
        keyExpr="idBonReception"
        [height]="'auto'"
        width="100%"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [(selectedRowKeys)]="selectedRows"
        #grid>
        <dxo-selection
          [selectAllMode]="'true'"
          [showCheckBoxesMode]="'always'"
          mode="multiple">
        </dxo-selection>
        <dxi-column dataField="numBonReception" caption="Numéro de bon de réception"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'">
        </dxi-column>
        <dxi-column dataField="labelProvider" caption="Fournisseur"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'">
        </dxi-column>
        <dxi-column dataField="dateRealDelivery" caption="Date de livraison ajustée"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    dataType="date"
                    [alignment]="'left'">
        </dxi-column>
        <dxi-column dataField="montantTotalHT" caption="Montant total HT" cellTemplate="montantTemplate"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'">
        </dxi-column>
        <dxi-column dataField="montantTotalTTC" caption="Montant total TTC" cellTemplate="montantTemplate"
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [alignment]="'left'">
        </dxi-column>

        <div *dxTemplate="let cell of 'montantTemplate'">
          {{ cell?.value }} €
        </div>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[10, 20]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} ligne(s)">
        </dxo-pager>
        <dxo-paging
          [pageSize]="10">
        </dxo-paging>
      </dx-data-grid>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-right">
      <yo-button buttonClass="cta-success" tooltip="Enregistrer" (onClick)="applyBinding()" iconClass="fas fa-save"></yo-button>
    </div>
  </div>
</dx-popup>
