import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {DuplicationMenuService} from '../../../../core/services/gestionmenus/duplication-menu.service';
import {Subscription} from 'rxjs';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {cloneDeep as _cloneDeep} from 'lodash'
import * as moment from 'moment';
import {MenuItem, SelectItem} from 'primeng/api';
import {DATEPICKER_FR, DUPLICATION_ECRASER_MENUS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {MenusToolbarService} from '../../../../core/services/gestionmenus/menus-toolbar.service';
import {MenusPlanning2Service} from '../../../../core/services/gestionmenus/menus-planning2.service';
import {PlanningMenusDTO} from '../../../../core/dtos/gestionmenus/planning/planning-menus-dto';
import {Table} from 'primeng/table';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';
import {ToastService} from "../../../../core/services/technique/toast.service";
import DuplicationMenuDataModel from "../../../../core/models/gestion-menus/duplication-menu-data-model";
import DuplicationMenuResultModel from "../../../../core/models/gestion-menus/duplication-menu-result-model";

@Component({
  selector: 'yo-duplication',
  templateUrl: './duplication.component.html',
  styleUrls: ['./duplication.component.scss'],

})
export class DuplicationMenusComponent implements OnInit, OnDestroy {

  subDialogDuplicationMenus: Subscription;
  readonly localeFr = DATEPICKER_FR;
  STEP_DUPLICATION = STEP_DUPLICATION;
  activeStep: STEP_DUPLICATION = this.STEP_DUPLICATION.SELECTION_PRESTATION;
  errorStepPrestationList: string[] = [];
  errorStepPeriodeList: string[] = [];
  displayDialog = false;

  @ViewChild('dtSource', {static: false}) dtSource: Table;

  steps: MenuItem[] = [
    {label: 'Sélection des prestations'},
    {label: 'Sélection de la période'},
    {label: 'Options de recopie'},
    {label: `Résultats`},
  ];

  //subscriptions
  subMonthChange: Subscription;

  sourceDatesSaisieEffectifs: Date[];
  sourceDatesCreationMenus: Date[];
  sourcesDateMin: Date[];
  sourceDisabledDays: number[];

  duplicationMenuData: DuplicationMenuDataModel;

  sourceCmcCaList: ContratMenuConvive__ContrainteAlimDTO[] = [];
  selectedSourceCmcCa: ContratMenuConvive__ContrainteAlimDTO;
  idSelectedSourceCmcCaList: number[] = [];

  selectedTargetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[] = [];
  idSelectedTargetCmcCaList: number[] = [];

  selectedSourceCmc: ContratMenuConviveDTO;
  idSelectedSourceCmcList: number[] = [];

  targetCmcList: ContratMenuConviveDTO[] = [];
  selectedTargetCmcList: ContratMenuConviveDTO[] = [];
  idSelectedTargetCmcList: number[] = [];

  selectedRangeSourceDates: Date[] = [new Date(), new Date()];
  selectedDateDebutTarget: Date;
  disabledDateDebutDays: number[] = [];

  nbPlatsCopies: number;
  nbPlatsSources: number;
  duplicationMenuResult: DuplicationMenuResultModel = new DuplicationMenuResultModel();
  duplicationMenuResultByPrestation: DuplicationMenuResultModel[] = [];


  duplicationEnCours = false;

  DME = DUPLICATION_ECRASER_MENUS;
  optionsEcraserMenu: SelectItem[] = [
    {label: 'E-', value: this.DME.AUCUN},
    {label: 'E', value: this.DME.ECRASER_SI_PLAT_EXISTANT},
    {label: 'E+', value: this.DME.ECRASER_TOUT},
  ];

  optionEcraserMenu = this.DME.AUCUN;
  optionRegimeSource = false;
  optionDeclinaisonSource = false;

  cols: any[] = [
    {field: 'cmSiteLibelle', header: 'Site'},
    {field: 'cmLibelle', header: 'Offre Alimentaire'},
    {field: 'cmcLibelle', header: 'Prestation'},
    {field: 'regimeLibelle', header: 'Régime'}
  ];

  typesDuplication: any[] = [
    {label: 'Par prestation', value: 0},
    {label: 'Par prestation et régime', value: 1}
  ];
  typeDuplicationSelected: number = 1;

  nbPlatsCopiesTarget: Map<string, number> = new Map();

  cellTemplate = 'custom';

  constructor(
    public utils: UtilsService,
    public auth2Svc: Auth2Service,
    public duplicationMenuService: DuplicationMenuService,
    private menusToolbarSvc: MenusToolbarService,
    private mp2Svc: MenusPlanning2Service,
    private toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDialogDuplicationMenus);
    this.utils.unsubscribe(this.subMonthChange);
  }

  ngOnInit(): void {
    // chargement du dialog de duplication menus
    this.duplicationMenuService.displayDialogDuplicationMenus$
      .pipe(
        switchMap(response => {

          // default parameters
          this.duplicationMenuData = new DuplicationMenuDataModel();
          this.activeStep = this.STEP_DUPLICATION.SELECTION_PRESTATION;
          this.errorStepPrestationList = [];
          this.selectedSourceCmcCa = null;
          this.selectedTargetCmcCaList = [];
          this.errorStepPeriodeList = [];
          const defaultStartDate = this.utils.convertNumberDateToDate(this.mp2Svc.planningMenus.dateList[0].date);
          const defaultStopDate = this.utils.convertNumberDateToDate(this.mp2Svc.planningMenus.dateList[this.mp2Svc.planningMenus.dateList.length - 1].date);
          this.selectedRangeSourceDates = [defaultStartDate, defaultStopDate];
          this.selectedDateDebutTarget = _cloneDeep(this.selectedRangeSourceDates[0]);
          this.disabledDateDebutDays = this.duplicationMenuService.getDisabledDateDebutDays(this.selectedRangeSourceDates[0]);
          // afficher le dialog
          this.displayDialog = response as boolean;
          // recuperer les infos nécessaires du back pour afficher le dialog
          return this.duplicationMenuService.prepareDialogDuplicationMenus();
        })
      )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.duplicationMenuData = response.one;
          this.selectedSourceCmc = this.preselectSourceCmc(this.mp2Svc.planningMenus, this.duplicationMenuData.sourceCmcList);
          this.idSelectedSourceCmcList.push(this.selectedSourceCmc.id);
          this.selectedSourceCmcCa = this.preselectSourceCmcCa(this.mp2Svc.planningMenus, this.duplicationMenuData.sourceCmcCaList);
          this.idSelectedSourceCmcCaList.push(this.selectedSourceCmcCa.id);
        }
      });

    this.subMonthChange = this.duplicationMenuService.monthChange$
      .pipe(
        debounceTime(100),
        switchMap(event => {
          const sourceCmc = new ContratMenuConviveDTO();
          sourceCmc.id = this.selectedSourceCmcCa.cmcId;
          return this.mp2Svc.changeMonth(sourceCmc, moment(this.selectedRangeSourceDates[0]).toDate(), event)
        }),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        this.sourceDisabledDays = response.disabledDays;
        this.sourceDatesSaisieEffectifs = response.datesSaisieEffectifs;
        this.sourceDatesCreationMenus = response.datesCreationMenus;
        this.sourcesDateMin = response.dateMin;
      });
  }

  /**
   * Préselectionner la source avec la prestation sélectionnée dans le menu et la faire apparaite en premier
   */
  preselectSourceCmc = (planningMenus: PlanningMenusDTO, sourceCmcList: ContratMenuConviveDTO[]) => {
    let selectedSourceCmc = null;

    if (!this.utils.isCollectionNullOrEmpty(sourceCmcList) && !this.utils.isCollectionNullOrEmpty(planningMenus.contratMenuConviveList)) {
      const sourceCmc = planningMenus.contratMenuConviveList[0];

      for (const cmc of sourceCmcList) {
        if (cmc.id === sourceCmc.id) {
          selectedSourceCmc = cmc;
          break;
        }
      }

      if (!this.utils.isNullOrEmpty(selectedSourceCmc)) {
        sourceCmcList.forEach((item, i) => {
          if (item.id === selectedSourceCmc.id) {
            sourceCmcList.splice(i, 1);
            sourceCmcList.unshift(item);
          }
        });
      }
    }

    return selectedSourceCmc;
  };

  /**
   * Préselectionner la source avec la prestation sélectionnée dans le menu et la faire apparaite en premier
   */
  preselectSourceCmcCa = (planningMenus: PlanningMenusDTO, sourceCmcCaList: ContratMenuConvive__ContrainteAlimDTO[]) => {
    let selectedSourceCmcCa = null;

    if (!this.utils.isCollectionNullOrEmpty(sourceCmcCaList) && !this.utils.isCollectionNullOrEmpty(planningMenus.contratMenuConviveList)) {
      const sourceCmc = planningMenus.contratMenuConviveList[0];
      const sourceCmcCa = planningMenus.contrainteAlimentaire;

      for (const cmcCa of sourceCmcCaList) {
        if (cmcCa.cmcId === sourceCmc.id && cmcCa.id === sourceCmcCa.id) {
          selectedSourceCmcCa = cmcCa;
          break;
        }
      }

      if (!this.utils.isNullOrEmpty(selectedSourceCmcCa)) {
        sourceCmcCaList.forEach((item, i) => {
          if (item.id === selectedSourceCmcCa.id) {
            sourceCmcCaList.splice(i, 1);
            sourceCmcCaList.unshift(item);
          }
        });
      }
    }

    return selectedSourceCmcCa;
  };

  closeDialog = () => {
    this.selectedTargetCmcCaList = [];
    this.selectedTargetCmcList = [];
    this.idSelectedTargetCmcCaList = [];
    this.idSelectedTargetCmcList = [];
    this.selectedSourceCmcCa = null;
    this.selectedSourceCmc = null;
    this.optionEcraserMenu = this.DME.AUCUN;
    this.optionRegimeSource = false;
    this.optionDeclinaisonSource = false;

    this.displayDialog = false;
  };

  validateStepPrestation = (goToStep: number) => {

    this.errorStepPrestationList = [];
    this.errorStepPeriodeList = [];

    if (this.typeDuplicationSelected === 0) {
      if (this.utils.isNullOrEmpty(this.selectedSourceCmc)) {
        this.errorStepPrestationList.push('Veuillez sélectionner une prestation à dupliquer !');
      }

      if (this.utils.isCollectionNullOrEmpty(this.selectedTargetCmcList)) {
        this.errorStepPrestationList.push('Veuillez sélectionner des prestations cibles !');
      }
    } else {
      if (this.utils.isNullOrEmpty(this.selectedSourceCmcCa)) {
        this.errorStepPrestationList.push('Veuillez sélectionner une prestation à dupliquer !');
      }

      if (this.utils.isCollectionNullOrEmpty(this.selectedTargetCmcCaList)) {
        this.errorStepPrestationList.push('Veuillez sélectionner des prestations cibles !');
      }
    }

    // si aucune erreur, on passe à l'étape suivante.
    if (this.utils.isCollectionNullOrEmpty(this.errorStepPrestationList)) {
      this.activeStep = goToStep;
    }

  };

  validateStepPeriode = (goToStep: number) => {

    this.errorStepPeriodeList = [];


    if (this.utils.isCollectionNullOrEmpty(this.selectedRangeSourceDates)
      || this.utils.isNullOrEmpty(this.selectedRangeSourceDates[0])
      || this.utils.isNullOrEmpty(this.selectedRangeSourceDates[1])) {

      // si la date fin est vide mais que la date de debut est renseignee, on prend la date de debut comme date de fin
      if (!this.utils.isNullOrEmpty(this.selectedRangeSourceDates[0]) && this.utils.isNullOrEmpty(this.selectedRangeSourceDates[1])) {
        this.selectedRangeSourceDates[1] = _cloneDeep(this.selectedRangeSourceDates[0]);
      } else {
        this.errorStepPeriodeList.push(`Veuillez sélectionner une période pour la prestation à dupliquer ! `);
      }
    }

    // si la date debut source === date debut cible et que prestation source == prestation cible, on ne doit pas autoriser la duplication
    if (!this.canDuplicateOnSamePrestation(this.selectedSourceCmcCa, this.selectedTargetCmcCaList, this.selectedRangeSourceDates[0], this.selectedDateDebutTarget)) {
      this.errorStepPeriodeList.push(`Vous ne pouvez pas dupliquer la prestation source sur elle même ! Veuillez dé-selectionner la prestation source dans votre liste de prestations cibles.`);
    }

    // si aucune erreur, on passe à l'étape suivante.
    if (this.utils.isCollectionNullOrEmpty(this.errorStepPeriodeList)) {
      this.activeStep = goToStep;
    }

  };

  /**
   * si la date debut source === date debut cible et que prestation source == prestation cible, on ne doit pas autoriser la duplication
   *
   * @param sourceCmcCa
   * @param targetCmcCaList
   * @param sourceDateDebut
   * @param targetDateDebut
   */
  canDuplicateOnSamePrestation = (sourceCmcCa: ContratMenuConvive__ContrainteAlimDTO, targetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[], sourceDateDebut: Date, targetDateDebut: Date) => {
    for (const targetCmcCa of targetCmcCaList) {
      if (sourceCmcCa.id === targetCmcCa.id) {
        if (this.utils.isDateEqualsYYYYMMDD(moment(sourceDateDebut), moment(targetDateDebut))) return false;
      }
    }
    return true;
  };

  getDateFromString = (ddMMYYY: string) => this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(ddMMYYY, 'YYYYMMDD'));

  launchDuplicationMenus = () => {
    this.duplicationEnCours = true;

    if (this.typeDuplicationSelected === 0) {
      this.duplicationMenuService.launchDuplicationMenusByPrestation(this.selectedRangeSourceDates, this.selectedDateDebutTarget, this.selectedSourceCmc,
        this.selectedTargetCmcList, this.optionEcraserMenu, this.optionRegimeSource, this.optionDeclinaisonSource)
        .subscribe(response => {

          if (!this.utils.isResponseSupplierError(response)) {
            // this.closeDialog();
            this.duplicationMenuResultByPrestation = response.resultList;

            // recharger le planning des menus
            this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);

            // notifier l'utilisateur que le traitement est terminé
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La duplication des menus a été réalisée avec succès`);
          }

          this.activeStep = this.STEP_DUPLICATION.RAPPORT;
        });
    } else {
      this.duplicationMenuService.launchDuplicationMenus(this.selectedRangeSourceDates, this.selectedDateDebutTarget, this.selectedSourceCmcCa,
        this.selectedTargetCmcCaList, this.optionEcraserMenu, this.optionRegimeSource, this.optionDeclinaisonSource)
        .subscribe(response => {

          if (!this.utils.isResponseSupplierError(response)) {
            // this.closeDialog();
            this.duplicationMenuResult = response.one;

            // recharger le planning des menus
            this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);

            // notifier l'utilisateur que le traitement est terminé
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La duplication des menus a été réalisée avec succès`);
          }

          this.activeStep = this.STEP_DUPLICATION.RAPPORT;
        });
    }

    this.duplicationEnCours = false;
  };


  onOptionChanged = ($event: any) => {
    if ($event.name === 'currentDate') {
      const previousDateMoment = moment($event.previousValue);
      const dateMoment = moment($event.value);
      if (previousDateMoment.isBefore(dateMoment)) {
        dateMoment.set('month', dateMoment.month() - 1);
      }
      this.duplicationMenuService.announceMonthChange(dateMoment.toDate());
    }

    if ($event.name === 'value') {
      this.selectedDateDebutTarget = this.selectedRangeSourceDates[0];
    }
  };

  onClosed = () => this.selectedDateDebutTarget = this.selectedRangeSourceDates[0];

  changeTypeDuplication = ($event: any) => {
    this.selectedTargetCmcCaList = [];
    this.selectedTargetCmcList = [];
    this.idSelectedTargetCmcCaList = [];
    this.idSelectedTargetCmcList = [];
    this.selectedSourceCmcCa = null;
    this.selectedSourceCmc = null;

    this.typeDuplicationSelected = $event.value;

    if(this.typeDuplicationSelected == 0) {
      this.selectedSourceCmc = this.preselectSourceCmc(this.mp2Svc.planningMenus, this.duplicationMenuData.sourceCmcList);
      this.idSelectedSourceCmcList.push(this.selectedSourceCmc.id);
    } else {
      this.selectedSourceCmcCa = this.preselectSourceCmcCa(this.mp2Svc.planningMenus, this.duplicationMenuData.sourceCmcCaList);
      this.idSelectedSourceCmcCaList.push(this.selectedSourceCmcCa.id);
    }
  };

  selectSourceCmcCa = (e: any) => {
    this.idSelectedSourceCmcCaList = e.selectedRowKeys;
    this.selectedSourceCmcCa = e.selectedRowsData[0];
  }

  selectTargetCmcCa = (e: any) => {
    if(e.currentDeselectedRowKeys.length == e.currentSelectedRowKeys.length) {
      const data = this.duplicationMenuData?.targetCmcCaList.find(item => item.id == e.currentDeselectedRowKeys[0]);
      e.selectedRowsData.push(data);
      e.selectedRowKeys.push(data.id);
    }
    this.idSelectedTargetCmcCaList = e.selectedRowKeys;
    this.selectedTargetCmcCaList = e.selectedRowsData;
  }

  selectSourceCmc = (e: any) => {
    this.idSelectedSourceCmcList = e.selectedRowKeys;
    this.selectedSourceCmc = e.selectedRowsData[0];
  }

  selectTargetCmc = (e: any) => {
    if(e.currentDeselectedRowKeys.length == e.currentSelectedRowKeys.length) {
      const data = this.duplicationMenuData?.targetCmcList.find(item => item.id == e.currentDeselectedRowKeys[0]);
      e.selectedRowsData.push(data);
      e.selectedRowKeys.push(data.id);
    }
    this.idSelectedTargetCmcList = e.selectedRowKeys;
    this.selectedTargetCmcList = e.selectedRowsData;
  }

  getCmcCaFromId = (id: number): ContratMenuConvive__ContrainteAlimDTO => this.duplicationMenuData.sourceCmcCaList.find(cmcca => cmcca.id === id);

  getDateFromKey = (key: string): string => {
    const result: string[] = key.split("-");
    return this.getDateFromString(result[1]);
  }

  getIdCmcCaFromKey = (key: string): number => {
    const result: string[] = key.split("-");
    return Number(result[0]);
  }

  getNbPlatsCopiesTarget = (result: DuplicationMenuResultModel, key: string) => result.nbPlatsCopiesTarget[key] ? result.nbPlatsCopiesTarget[key] : 0;

  convertToDate = (date: string) => new Date(date);

}

export enum STEP_DUPLICATION {
  SELECTION_PRESTATION = 0,
  SELECTION_PERIODE = 1,
  RECAPITULATIF = 2,
  RAPPORT = 3
}



