import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ChangelogsComponent} from './changelogs.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'changelogs',
        component: ChangelogsComponent
      }
    ])
  ],
  exports: [RouterModule]

})
export class ChangelogsRoutingModule {
}
