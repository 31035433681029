<p-dialog header="Détails"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'auto','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(detailsHtml)"></div>
</p-dialog>
