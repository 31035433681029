import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PlcRepas, PlcRepasWeek} from "../menu-plc-resolver.service";
import {EffectifMode} from "../constant/menu-plc.constant";
import {UtilsService} from "../../../core/utils/utils.service";
import {ConfigurationPortailDTO} from "../../../core/dtos/portail/configuration-portail-dto";

@Component({
  selector: 'yo-generic-prestations-semaine',
  templateUrl: './generic-prestations-semaine.component.html',
  styleUrls: ['./generic-prestations-semaine.component.scss'],

})
export class GenericPrestationsSemaineComponent {


  @Input() public repasPlcWeekList: PlcRepasWeek[] = [];
  @Input() typeEffectif = EffectifMode.Prevision;
  @Input() configPortailPlc : ConfigurationPortailDTO;
  @Output() onEmitPlcRepasAgain = new EventEmitter<PlcRepas>();
  cols: any[] = [];

  constructor(public utils : UtilsService) {
  }

  canDisplayGrille = () => {
    return !this.utils.isCollectionNullOrEmpty(this.repasPlcWeekList);
  };

  onEmitPlcRepas = (event : PlcRepas) => {
    this.onEmitPlcRepasAgain.emit(event);
  };

}
