<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  [showCloseButton]="true"
  width="70vw"
  height="33vw"
  (onHidden)="closeDialog()"
>
  <dx-scroll-view showScrollbar="always">

    <form *ngIf="caPP" (ngSubmit)="save()" #articleForm="ngForm">

      <div id="produitArticleForm" class="container">

        <div class="row">
          <div class="col">
            <div class="title-ca">Article</div>
            <div class="container-catalogArticle">

              <div class="dialog-line">
                <div class="col-md-6 mr-auto label" title="Date effective">Date effective <span class="danger-color">*</span>
                </div>
                <div class="col-md-6">
                  <dx-date-box
                    required
                    type="date"
                    [(ngModel)]="caPP.dateEffective"
                    #dateEffective="ngModel"
                    name="dateEffective"
                    [disabled]="!canModify()"
                    [inputAttr]="{ 'aria-label': 'Date' }"
                  >
                  </dx-date-box>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
                <div class="value">
                  <dx-text-box
                    required
                    class='inputfield'
                    [(ngModel)]="caPP.libelle"
                    #libelle="ngModel"
                    name="libelle"
                  >
                  </dx-text-box>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Référence article fournisseur">Réf. art. fournisseur <span
                  class="danger-color">*</span>
                </div>
                <div class="value">
                  <dx-text-box
                    required
                    class='inputfield'
                    [(ngModel)]="caPP.referenceArticle"
                    #referenceArticle="ngModel"
                    name="referenceArticle"
                  >
                  </dx-text-box>
                </div>
              </div>

              <div class="dialog-line actif-container">
                <div class="label" title="Actif">Actif</div>
                <div class="value">
                  <dx-check-box
                    [(ngModel)]="caPP.actif"
                    #actif="ngModel"
                    name="actif"
                  >
                  </dx-check-box>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="title-ca">Facturation</div>
            <div class="container-catalogArticle">
              <div class="dialog-line">
                <div class="label" title="Unité de facturation">Unité de facturation <span
                  class="danger-color">*</span>
                </div>
                <div class="value">
                  <yo-lookup
                    #uniteDeFacturation
                    (onValueChange)="onChange($event, 'uniteDeFacturation')"
                    [data]="unitesMesure"
                    [selectedItem]="caPP.uniteDeFacturation.id"
                  >
                  </yo-lookup>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Unité de facturaton / Kg">Unité de facturation / Kg <span
                  class="danger-color">*</span>
                </div>
                <div class="input-group input-group-sm">
                  <div class="input-group-append ">
                    <span class="input-group-text">1 {{caPP?.uniteDeFacturation?.libelle}} équivaut à</span>
                  </div>
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="caPP.ratioUniteFacturationUniteBase"
                         #ratioUniteFacturationUniteBase="ngModel"
                         name="ratioUniteFacturationUniteBase"
                         (ngModelChange)="onChangeRatioUC()">
                  <div class="input-group-append ">
                    <span class="input-group-text">KG</span>
                  </div>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Prix d'achat">Prix d'achat <span class="danger-color">*</span></div>
                <div class="input-group input-group-sm">
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="caPP.prix"
                         (ngModelChange)="onPriceUpdated()"
                         #prix="ngModel"
                         name="prix">
                  <div class="input-group-append ">
                    <span class="input-group-text">€ par {{caPP.uniteDeFacturation.libelle}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="title-ca">Commande</div>
            <div class="container-catalogArticle">
              <div class="dialog-line">
                <div class="label" title="Unité de commande">Unité de commande <span class="danger-color">*</span>
                </div>
                <div class="value">
                  <yo-lookup
                    #uniteDeCommande
                    (onValueChange)="onChange($event, 'uniteDeCommande')"
                    [data]="unitesMesure"
                    [selectedItem]="caPP.uniteDeCommande.id"
                  >
                  </yo-lookup>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Unité de commande / unité de facturation">Unité de commande / unité de
                  facturation
                  <span class="danger-color">*</span>
                </div>
                <div class="input-group input-group-sm">
                  <div class="input-group-append ">
                    <span class="input-group-text">1 {{caPP?.uniteDeCommande?.libelle}} équivaut à</span>
                  </div>
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="ratioUCUF"
                         (ngModelChange)="onChangeRatioUC()"
                         [ngModelOptions]="{standalone: true}"
                         [disabled]="isSameUCUF"
                  >
                  <div class="input-group-append ">
                    <span class="input-group-text">{{caPP.uniteDeFacturation.libelle}}</span>
                  </div>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Unité de commande / KG">Unité de commande / KG
                  <span
                    class="danger-color">*</span>
                </div>
                <div class="input-group input-group-sm">
                  <div class="input-group-append ">
                    <span class="input-group-text">1 {{caPP?.uniteDeCommande?.libelle}} équivaut à</span>
                  </div>
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="caPP.ratioUniteCommandeUniteBase"
                         #ratioUniteCommandeUniteBase="ngModel"
                         name="ratioUniteCommandeUniteBase"
                         (ngModelChange)="onChangeRatioUCUF()">
                  <div class="input-group-append ">
                    <span class="input-group-text">KG</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="title-ca">Stockage</div>
            <div class="container-catalogArticle">
              <div class="dialog-line">
                <div class="label" title="Unité de stockage">Unité de stockage <span class="danger-color">*</span>
                </div>
                <div class="value">
                  <yo-lookup
                    #uniteStockage
                    [data]="unitesMesure"
                    (onValueChange)="onChange($event, 'uniteDeStockage')"
                    [selectedItem]="caPP.uniteDeStockage.id"
                  >
                  </yo-lookup>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Unité de stockage / unité de technique">Unité de stockage / unité technique
                  <span class="danger-color">*</span>
                </div>
                <div class="input-group input-group-sm">
                  <div class="input-group-append ">
                    <span class="input-group-text">1 {{caPP?.uniteDeStockage?.libelle}} équivaut à</span>
                  </div>
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="ratioUSUT"
                         (ngModelChange)="onChangeRatioUS()"
                         [ngModelOptions]="{standalone: true}"
                         [disabled]="isSameUSUT">
                  <div class="input-group-append ">
                    <span class="input-group-text">{{catalogueAchat.uniteTechnique.libelle}}</span>
                  </div>
                </div>
              </div>

              <div class="dialog-line">
                <div class="label" title="Unité de stockage / KG">Unité de stockage / KG
                  <span
                    class="danger-color">*</span>
                </div>
                <div class="input-group input-group-sm">
                  <div class="input-group-append ">
                    <span class="input-group-text">1 {{caPP?.uniteDeStockage?.libelle}} équivaut à</span>
                  </div>
                  <input type="number"
                         [min]="0"
                         class="form-control form-control-sm typeCycle"
                         [(ngModel)]="caPP.ratioUniteStockageUniteBase"
                         #ratioUniteStockageUniteBase="ngModel"
                         name="ratioUniteStockageUniteBase"
                         (ngModelChange)="onChangeRatioUSUT()">
                  <div class="input-group-append ">
                    <span class="input-group-text">KG</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="custom-button-container-right">
          <yo-button
            type="submit"
            iconClass="fas fa-save"
            tooltip="Enregistrer"
            buttonClass="cta-success"
            [disabled]="!isFormValid()">
          </yo-button>
          <yo-button
            buttonClass="cta-delete"
            (onClick)="closeDialog()"
            tooltip="Fermer"
            iconClass="fas fa-times"
          >
          </yo-button>
        </div>
      </div>
    </form>
  </dx-scroll-view>
</dx-popup>
