import {ObjectDTO} from "../object-dto";

export class GroupeAchatDTO extends ObjectDTO {

  libelle: string;
  code: string;
  descriptif: string;
  actif: boolean;
  type: string;
}
