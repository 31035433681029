import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {SiteDTO} from "../../core/dtos/site-dto";
import {HELP_FOLDERS} from "../../core/constants";
import {SiteService} from "../../core/site.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RoutemapService} from "../../core/services/routemap.service";
import {SiteResolverService} from "../site-resolver.service";
import {confirm} from "devextreme/ui/dialog";
import {DialogMsgSupplier, Paragraphe} from "../../core/suppliers/dialog-msg-supplier";


@Component({
  selector: 'yo-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent {
  start: boolean = true;
  // Subscriptions
  subGrilleSites: Subscription;
  subSite: Subscription;
  subDeleteSite: Subscription;

  totalRecords: number;

  loading: boolean;

  sitesForGrille: SiteDTO[];
  selectedRows: number[] = [];
  allMode: string;
  checkBoxesMode: string;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/sites';

  constructor(public utils: UtilsService,
              private siteService: SiteService,
              private router: Router,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private siteResolverService: SiteResolverService
  ) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.utils.setTitle('Gestion des sites');
    this.subGrilleSites = this.siteService.searchSite('').subscribe(data => this.sitesForGrille = data);
    this.subSite = this.siteResolverService.siteAnnounceSource.subscribe(site => {
      const idx = this.sitesForGrille.findIndex(s => s.id === site.id);
      if (idx >= 0)
        this.sitesForGrille[idx] = site;
      else
        this.sitesForGrille.push(site);
    });
    this.siteService.siteSubjectToDelete$.subscribe(site => {
      const idx = this.sitesForGrille.findIndex(s => s.id === site.id);
      this.sitesForGrille.splice(idx, 1);
    });
  }

  confirmDelete = async (siteDTO: SiteDTO): Promise<void> => {
    const result = confirm("Etes vous sûr de vouloir supprimer le site '" + siteDTO.libelle + "' ?", 'Suppression de site');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.delete(siteDTO);
    }
  };

  /**
   * Destructionn du site passé en paramètre
   * @param {SiteDTO} siteDTO
   */
  delete = (siteDTO: SiteDTO): void => {
    this.subDeleteSite = this.siteService.deleteSite(siteDTO);
  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleSites);
    this.utils.unsubscribe(this.subSite);
    this.utils.unsubscribe(this.subDeleteSite);
  }

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Fiche d’un site`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Identité d’un <b>site</b>`;
    p1.lines = [
      `Permet de renseigner l’identité d’un <b>site</b> avec 2 notions :`,
      `<ul>
          <li>un libellé</li>
          <li>une référence</li>
       </ul>`,
      `Pour être utilisable, vous devez activer un <b>site</b> `,
    ];

    dms.content = {
      intro: `Un <b>site</b> est un paramètre référentiel que l’on peut assimiler à un sac dans lequel on stockera les données créées par un site à des fins de partage ou pas dans un <b>environnement</b>.`,
      paragraphes: [p1]
    };
    return dms;
  };

  openDialog = (site: SiteDTO | undefined): void => {
    this.siteService.announceOpenDialogEdition(site);
  }
}
