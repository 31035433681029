<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openEquipementANettoyer(undefined)"
          [disabled]="!canCreateAndDelete()"
          tooltip="Créer un équipement à nettoyer">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canCreateAndDelete() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="equipementsANettoyerList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="templateSite">
  </dxi-column>
  <dxi-column caption="Image" [width]="100"
              [allowFiltering]="false" cellTemplate="cellTemplate">
  </dxi-column>
  <div *dxTemplate="let cell of 'cellTemplate'">
    <yo-img-entity *ngIf="!cell.row.data"
                   [entityId]="cell.row.data.id" [refresh]="1"
                   [height]="50"
                   format="thumb"
                   [displayNoImage]="true"
                   [title]="cell.row.data.libelle"
                   [entityName]="entityName"
                   [ordre]="1"></yo-img-entity>
    <yo-img-entity *ngIf="cell.row.data"
                   [entityId]="cell.row.data.id" [refresh]="1"
                   [height]="50"
                   [openable]="true"
                   format="thumb"
                   [displayNoImage]="true"
                   [title]="cell.row.data.libelle"
                   [entityName]="entityName"
                   [ordre]="1"></yo-img-entity>
  </div>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEquipementANettoyer(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoViewMode]="!canModify(cell.row.data)"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="canModify(cell.row.data)?'Modifier l\'équipement à nettoyer':'Voir l\'équipement à nettoyer'">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site [siteLibelle]="cell.row.data.site?.libelle"
             [siteId]="cell.row.data.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} équipement(s) à nettoyer">
  </dxo-pager>

</dx-data-grid>

<yo-pms-equipement-a-nettoyer-dialog></yo-pms-equipement-a-nettoyer-dialog>


