import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {Observable} from 'rxjs';
import {MenusPlanning2Service} from '../../../core/services/gestionmenus/menus-planning2.service';
import {GemrcnHelperSupplier, GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';


@Injectable({
  providedIn: 'root'
})
export class GemrcnResolverService implements Resolve<GemrcnHelperSupplier> {

  constructor(private utils: UtilsService,
              private ggSvc: GestionGemrcnService,
              private mp2Svc: MenusPlanning2Service) {
  }


  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GemrcnHelperSupplier> | Promise<GemrcnHelperSupplier> | GemrcnHelperSupplier => {
    if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus) && !this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.contratMenuConviveList)) {

      const idPrestation = this.mp2Svc.planningMenus.contratMenuConviveList[0].id;
      const idCmcCa = this.mp2Svc.planningMenus.contrainteAlimentaire.id;
      const startDate = new Date(this.mp2Svc.planningMenus.dateList[0].date);
      const stopDate = new Date(this.mp2Svc.planningMenus.dateList[this.mp2Svc.planningMenus.dateList.length - 1].date);

      return this.ggSvc.getAnalyseGemrcnPromise(idPrestation, idCmcCa, startDate, stopDate);
    }
  };
}
