import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {
  cloneDeep as _cloneDeep,
  intersection as _intersection,
  isEmpty as _isEmpty,
  reverse as _reverse,
  sortBy as _sortBy,
  uniq as _uniq
} from 'lodash';
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';

@Injectable()
export class MenusDatesService {


  constructor() {
  }

  /**
   * Récupérer les jours semaine qui matcent entre les preferences utilisateur et le contrat menu convive
   * @param {number[]} listDatesContrat
   * @param {number[]} listDatesPrefs
   * @returns {any[]}
   */
  getIntersectionDaysOfWeek(listDatesContrat: number[], listDatesPrefs: number[]): number[] {
    return _intersection(listDatesContrat, listDatesPrefs);
  }

  getNextDates(currentDates: moment.Moment[], daysOfWeek: number[], nbJours: number): moment.Moment[] {
    let nextStartDate = _cloneDeep(this.getNextStartDate(currentDates, daysOfWeek));
    return _cloneDeep(this.getDates(nextStartDate, nbJours, daysOfWeek));
  }

  getPreviousDates(startDate: moment.Moment, daysOfWeek: number[], nbJours: number): moment.Moment[] {

    let dates = [];
    let pos = 0;

    if (!_isEmpty(daysOfWeek)) {
      let pivotDate = this.getStartDate(startDate, daysOfWeek).clone();

      //pour que l'algo fonctionne, il faut que les jours semaines soient triés dans l'ordre
      daysOfWeek = _sortBy(daysOfWeek);

      for (let i = 0; i < nbJours; i++) {

        pos = daysOfWeek.indexOf(pivotDate.isoWeekday());
        if (pos === 0) {
          let date = pivotDate.subtract(1, 'week').isoWeekday(daysOfWeek[daysOfWeek.length - 1]).clone();
          dates.push(date);
          pos = daysOfWeek.length - 1;
        } else {

          let date = pivotDate.isoWeekday(daysOfWeek[pos - 1]).clone();
          dates.push(date);
          pos--;
        }
      }

      _reverse(dates);
    }

    return dates;
  }

  getNextStartDate(currentDates: moment.Moment[], daysOfWeek: number[]): moment.Moment {

    if (!_isEmpty(currentDates) && !_isEmpty(daysOfWeek)) {
      //pour que l'algo fonctionne, il faut que les jours semaines soient triés dans l'ordre
      daysOfWeek = _sortBy(daysOfWeek);

      //récupérer la derniere date de la période en cours
      let lastCurrentDate = currentDates[currentDates.length - 1].clone();

      // console.log('lastCurrentDate',lastCurrentDate);
      let pos = daysOfWeek.indexOf(lastCurrentDate.isoWeekday());
      // console.log('position of last date in  daysOfWeek',pos);

      if (pos === daysOfWeek.length - 1) {
        return lastCurrentDate.add(1, 'week').isoWeekday(daysOfWeek[0]);
      } else {
        return lastCurrentDate.isoWeekday(daysOfWeek[pos + 1]);
      }
    }

    return undefined;
  }

  /**
   * Récupérer le jour semaine
   * @param value
   * @returns {number} -1 si value n'est pas de type date
   */
  getIsoWeekday(value: any): number {
    let dw = -1;
    if (moment.isMoment(value)) {
      dw = value.isoWeekday();
    }
    return dw;
  }

  /**
   * Vérifie si une date javascript est égale à une date moment avec comme format de comparaison DD/MM/YYYY
   * @param {moment.Moment} momentDate
   * @param {Date} date
   */
  isEquals(momentDate: moment.Moment, date: Date): boolean {

    if (!momentDate || !date) {
      return false;
    }

    return momentDate.format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY')

  }

  getFrenchShortFormat(dateToFormat: moment.Moment): string {

    if (dateToFormat) {
      dateToFormat.locale('fr');
      let dateStr = dateToFormat.startOf('day').format('llll').trim();
      return dateStr.substr(0, dateStr.lastIndexOf(' '));
    }

    return '';
  }

  /***
   * Récupérer une période de currentDates selon une liste de jours semaines, un nombre de jours et une date de début
   * @param {moment.Moment} startDate
   * @param {number} nbJours
   * @param {number[]} daysOfWeek
   * @returns {moment.Moment[]}
   */
  getDates(startDate: moment.Moment, nbJours: number, daysOfWeek: number[]): moment.Moment[] {

    let pivotDate: moment.Moment = _cloneDeep(startDate);
    let dates = [];
    let pos = 0;

    if (startDate && !_isEmpty(daysOfWeek)) {
      //pour que l'algo fonctionne, il faut que les jours semaines soient triés dans l'ordre
      daysOfWeek = _sortBy(daysOfWeek);

      for (let i = 0; i < nbJours; i++) {

        //Passer à la semaine suivante, si le parcours des jours semaines est terminé et qu'on n'a pas tous les jours de la periode
        if (pos >= daysOfWeek.length) {
          pivotDate.add(1, 'week');
          pos = 0;
        }

        //Récupérer la premiere date disponible par rapport aux jours semaines paramétrés
        if (i === 0) {
          pivotDate = this.getStartDate(startDate, daysOfWeek);
          // console.log('first date', pivotDate);
          pos = daysOfWeek.indexOf(pivotDate.isoWeekday());
        }

        //ajouter la date du jour semaine en cours à la periode
        dates.push(pivotDate.clone().isoWeekday(daysOfWeek[pos]));

        //passer au jour semaine suivant
        pos++;
      }
    }

    dates = _sortBy(dates, d => d.format('YYYYMMDD'));

    //renvoyer la periode sous forme de liste de date
    return dates;
  }

  /***
   * Récupérer la premiere date disponible par rapport à une date et une liste de jours semaine
   * @param startDate
   * @param {number[]} daysOfWeek
   * @returns {moment.Moment}
   */
  getStartDate(startDate, daysOfWeek: number[]): moment.Moment {
    let startDayOfWeek = startDate.isoWeekday();
    for (let i = 1; i < daysOfWeek.length; i++) {

      let dwPrev = daysOfWeek[i - 1];
      let dw = daysOfWeek[i];
      if (startDayOfWeek === dwPrev) {
        return startDate.clone().isoWeekday(dwPrev);
      } else if (startDayOfWeek === dw) {
        return startDate.clone().isoWeekday(dw);
      }
      else if (dwPrev < startDayOfWeek && startDayOfWeek < dw) {
        return startDate.clone().isoWeekday(dw);
      }
    }
    // console.log('day 1', currentStartDate.clone().isoWeekday(daysOfWeek[0]).add(1, 'week'));
    return startDate.clone().isoWeekday(daysOfWeek[0]).add(1, 'week');

  }

  /***
   * Récupérer la liste des jours semaine des contrat menus convives
   * @param {ContratMenuConviveRepasDTO[]} contratMenuConviveRepasDTOs
   * @returns {number[]}
   */
  getListeJourSemaineContrat(contratMenuConviveRepasDTOs: ContratMenuConviveRepasDTO[]): number[] {
    if (contratMenuConviveRepasDTOs && contratMenuConviveRepasDTOs.length > 0) {
      let arrNumber = contratMenuConviveRepasDTOs.map(item => item.idJourSemaine);

      //supprimer les doublons de jour
      return _uniq(arrNumber);
    }
    return [];
  }


}
