import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormFieldBaseSupplier} from '../../../../../core/suppliers/form-fieldbase-supplier';
import {FormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {find as _find} from 'lodash'
import {DocumentService} from '../../../../../core/services/document.service';
import {DATEPICKER_FR} from '../../../../../core/constants';

@Component({
  selector: 'yo-dynamic-form-field-bs',
  templateUrl: './dynamic-form-field-bs.component.html',
  styleUrls: ['./dynamic-form-field-bs.component.scss']
})
export class DynamicFormFieldBsComponent implements OnInit {

  @Input() field: FormFieldBaseSupplier<any>;
  @Input() fields: FormFieldBaseSupplier<any>[];
  @Input() form: FormGroup;

  docError = false;
  previewFile: File;
  displayTree = false;

  localeFr = DATEPICKER_FR;

  selectedRowKeys = [];

  constructor(public dsSvc: DomSanitizer,
              private documentSvc: DocumentService,
              public utils: UtilsService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    // console.log('DynamicFormFieldBsComponent init');
  }


  /**
   * Liste déroulante external ref, déclencher une linkedFonction si elle existe
   * @param $event
   */
  changeExternalRef($event: any) {
    if (this.field.linkedFieldFn) {
      this.field.linkedFieldFn($event.value, this.form, this.fields, this.form.controls[this.field.key].value);
    }
  }

  /**
   * Remarque : la seule différence entre cette fonction et la précédente est qu'ici on référence <b>$event.target.value</b>
   * tandis que dans la précédente on référence <b>$event.value</b>
   * @param $event
   */
  changeInput($event: any) {
    if (this.field.linkedFieldFn) {
      this.field.linkedFieldFn($event.target.value, this.form, this.fields, this.form.controls[this.field.key].value);
    }

  }

  getEntityId(): number {
    const fieldId = _find(this.fields, {'key': 'id'});
    if (fieldId) {
      return fieldId.value;
    }
    return 0;
  }

  previewImage() {


    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewImage').setAttribute('src', e.target.result);
    }

    reader.readAsDataURL(this.previewFile);


  }

  onFileChange(event, field: any) {

    console.log('onFileChange', event, this.field);

    if (event.target.files && event.target.files.length) {

      this.previewFile = event.target.files[0];

      if (!this.utils.isNullOrEmpty(this.previewFile)) {

        // previsualiser l'image avant de l'enregistrer
        this.previewImage();

        this.form.get(this.field.key).setValue(this.previewFile);
      }


    }
  }

  deleteFile(field: any) {

    const entityId = this.getEntityId();
    this.documentSvc.removeByEntity(field.entityName, entityId, field.ordre).subscribe(response => {
      // rafraichir l'image ou le document

      field.refresh = new Date().getTime();
    });
  }


  getTitle() {
    const fieldLibelle = _find(this.fields, {'key': 'libelle'});
    return fieldLibelle ? fieldLibelle.value : '';
  }

  getTooltip(field: any) {
    //let tooltip = `<u>${field.label.toUpperCase()}</u>`;
    let tooltip = ``;

    if (field.help) {
      // tooltip += `<br><i class="far fa-question-circle mg-r-5"></i>${field.help}`;
      tooltip += field.help;

    }

    return tooltip;
  }


  changeMultiSelect($event: any) {

    // console.log($event);

    if (this.field.linkedFieldFn) {
      this.field.linkedFieldFn($event.value, this.form, this.fields, this.form.controls[this.field.key].value);
    }
  }

  changeDropdown($event: any) {
    console.log($event);
    this.field.value = $event.value;

    if (this.field.linkedFieldFn) {
      this.field.linkedFieldFn($event.value, this.form, this.fields, this.form.controls[this.field.key].value);
    }
  }

  onSelectRowSingle($event) {
    // console.log($event);
    if ($event && $event.row && $event.row.data) {

      for (const option  of this.field.options) {
        let value = option as any;
        if (value.id === $event.row.data.id) {
          this.field.value = value;
          this.form.controls[this.field.key].setValue(value);
          this.selectedRowKeys = value;
          break;
        }
      }

    }
  }

  getRecursionTreeExpandedRowKeys(object: any, arr: string[]) {

    arr.push(object.id);

    if (object.parent) {
      arr =this.getRecursionTreeExpandedRowKeys(object.parent,arr);
    }

    return arr;
  }

  getExpandedRowKeys( field: FormFieldBaseSupplier<any>) {
    let rowKeys = [];

    if (field && field.value) {

      if (field.value.parent) {
        rowKeys =this.getRecursionTreeExpandedRowKeys(field.value,[]);
      }
      else{
        rowKeys.push(field.value.id);
      }
    }

    return rowKeys;
  }

  showTree() {
    this.displayTree = !this.displayTree;
  }

  getTreeRecursionLabel(object: any, arr: string[]) {

    arr.push(object.libelle);

    if (object.parent) {
      arr =this.getTreeRecursionLabel(object.parent,arr);
    }

    return arr;
  }

  getTreeLabel(field: FormFieldBaseSupplier<any>) {

    let label = '';
    if (field && field.value) {

      if (field.value.parent) {
        let arr =this.getTreeRecursionLabel(field.value, []);
        label = arr.reverse().join(' / ');
      } else {
        label = field.value.libelle;
      }

    }

    return label;
  }
}
