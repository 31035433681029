<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <ng-container>
        <i class="far fa-calendar-check mg-r-5"></i> Validation des menus
      </ng-container>
    </div>

  </ng-template>

  <ng-container *ngIf="!utils.isNullOrEmpty(mp2Svc.planningMenus)">
    <div class="row">
      <div class="col-md-6">Offres alimentaires</div>
      <div class="col-md-6">Prestations</div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="selectedContrats"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Sélectionner les offres alimentaires..."
            [showClearButton]="true"
            [dataSource]="contrats"
            (onValueChanged)="onDropDownBoxValueChanged(chooserPlanning.CONTRAT)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewContrats
                [dataSource]="contrats"
                dataStructure="plain"
                selectionMode="multiple"
                displayExpr="libelle"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="false"
                keyExpr="id"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event, chooserPlanning.CONTRAT)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event, chooserPlanning.CONTRAT)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>

      <div class="col-md-6">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="selectedPrestations"
            valueExpr="id"
            [displayExpr]="displayExprContrat"
            placeholder="Sélectionner les prestations..."
            [showClearButton]="true"
            [dataSource]="prestationsFilter"
            (onValueChanged)="onDropDownBoxValueChanged(chooserPlanning.PRESTATION)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewPrestations
                [dataSource]="prestationsFilter"
                dataStructure="plain"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="false"
                keyExpr="id"
                [displayExpr]="displayExprContrat"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event, chooserPlanning.PRESTATION)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event, chooserPlanning.PRESTATION)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>

    <div class="row mg-t-20">
      <div class="col-md-12">
        <span class="mg-r-10 genlabel">Période sélectionnée</span>
        <span *ngIf="isValidationMenuOk" class="bold">
            {{selectedDates[0] | date:'EEEE d LLLL yyyy' | titlecase }}
          au {{selectedDates[1] |  date:'EEEE d LLLL yyyy' | titlecase }}.
        </span>
        <span *ngIf="!isValidationMenuOk" class="danger-color bold">
          aucune.
        </span>
      </div>
    </div>
    <div class="row ">

      <div class="col-md-12">
        <p-calendar
          dateFormat="dd/mm/yy"


          inline="true"
          selectionMode="range"
          [shortYearCutoff]="false"
          [numberOfMonths]="2"
          [readonlyInput]="true"
          [(ngModel)]="selectedDates"
          (onSelect)="changeDates($event)"
          (onMonthChange)="getMonthMenusCompoDates($event)"
          [disabledDays]="disabledDays"
          [disabledDates]="datesNonDisponibles"
          [minDate]="dateMin"
          [locale]="localeFr"
          firstDayOfWeek="1"
          [showIcon]="true"
          [showWeek]="true"
          [inline]="true">

          <ng-template pTemplate="date" let-date>
            <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                           [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
          </ng-template>

        </p-calendar>
      </div>
    </div>
    <div class="row font-12">
      <div class="col-md-12">
        <div>
          <i class="fas fa-square save-color mg-r-5 "></i>Période sélectionnée
        </div>
        <div>
          <i class="fas fa-square site-secondaire-color mg-r-5 "></i>Menus en cours de saisie
        </div>
        <div>
          <i class="fas fa-square checked-color mg-r-5 "></i>Menus validés = Saisie des effectifs possible sur
          les PLC
        </div>
      </div>
    </div>
    <hr>
    <div class="row mg-t-20">
      <div class="col-md-auto">
        <p-selectButton [options]="optionsEcraserMenu" [(ngModel)]="optionEcraserMenu">
        </p-selectButton>
      </div>
      <div class="col">
        <span *ngIf="optionEcraserMenu === OEM.ECRASER_MENUS_SANS_MODIFICATION_MANUELLE">
          Les menus déjà existants sur les PLC seront écrasés, mais les modifications manuelles seront conservées !
        </span>
        <span *ngIf="optionEcraserMenu === OEM.ECRASER_MENUS_AVEC_MODIFICATIONS_MANUELLES">
         Les menus déjà existants sur les PLC seront écrasés ainsi que toutes les modifications manuelles !!
        </span>
        <span *ngIf="optionEcraserMenu === OEM.AUCUN_ECRASEMENT">
          Les menus déjà existants sur les PLC seront conservés.
        </span>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-auto">

        <p-selectButton [options]="optionsEcraserTauxDePrise" [(ngModel)]="optionEcraserTauxDePrise">
        </p-selectButton>

      </div>
      <div class="col">
        <span *ngIf="optionEcraserTauxDePrise === OETDP.ECRASER">
          Les taux de prise déja existants sur les PLC seront écrasés !
        </span>
        <span *ngIf="optionEcraserTauxDePrise === OETDP.AUCUN_ECRASEMENT">
          Les taux de prise déjà existants sur les PLC seront conservés.
        </span>
      </div>

    </div>
  </ng-container>

  <ng-template pTemplate="footer">
    <yo-button label="Valider les menus" iconClass="fas fa-check" (onClick)="validerMenus(true)"
            [disabled]="!isValidationMenuOk"></yo-button>
    <yo-button label="Annuler la validation" iconClass="fas fa-times"
            buttonClass="cta-inner-delete"
            (onClick)="validerMenus(false)"
            [disabled]="!isValidationMenuOk"></yo-button>
    <yo-button label="Fermer" iconClass="fas fa-sign-out-alt" buttonClass="cta-inner-delete"
            (onClick)="closeDialog()"></yo-button>
  </ng-template>


</p-dialog>
