import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {Environnement_PlcDTO} from "../../../../core/dtos/administration/environnement-plc-dto";
import {EnvironnementPlcService} from "../../../../core/services/portail/environnement-plc.service";


@Injectable({
  providedIn: 'root'
})
export class SidebarEnvironnementPlcResolverService implements Resolve<SidebarEnvironnementPlcSupplier> {

  constructor(private environnementPlcSvc: EnvironnementPlcService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SidebarEnvironnementPlcSupplier> | Promise<SidebarEnvironnementPlcSupplier> | SidebarEnvironnementPlcSupplier => {
    let supplier: SidebarEnvironnementPlcSupplier = new SidebarEnvironnementPlcSupplier();

    let idEnvironnementPlc: number = +route.paramMap.get('idEnvironnementPlc');


    if (idEnvironnementPlc > 0) {
      const envirommentPlc$ = this.environnementPlcSvc.getById(idEnvironnementPlc);

      const all$ = combineLatest([envirommentPlc$]);
      return all$.toPromise().then(data => {
        supplier.environnementPlc = data[0].one;
        return supplier;
      });
    } else {
      supplier.environnementPlc = this.environnementPlcSvc.createEmptyDTO();
      return supplier;
    }
  };
}

export class SidebarEnvironnementPlcSupplier {
  environnementPlc: Environnement_PlcDTO;
}
