import {Injectable} from '@angular/core';
import {ProfilDTO} from '../../core/dtos/profil-dto';
import {RoleDTO} from '../../core/dtos/role-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../core/utils/utils.service';
import {ProfilsService} from '../../core/services/entities/profils.service';
import {RoleService} from '../../core/role.service';
import {UtilisateurService} from "../../core/utilisateur.service";

@Injectable({
  providedIn: 'root'
})
export class ProfilsResolverService implements Resolve<ProfilSupplier>{

  constructor(
    private http: HttpClient,
    private profilSvc: ProfilsService,
    private roleSvc: RoleService,
    private auth2Svc: Auth2Service,
    private gds: GenericDatagridService,
    private utils: UtilsService,
    private utilisateurSvc : UtilisateurService,
  ) { }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfilSupplier> | Promise<ProfilSupplier> | ProfilSupplier => {

    const ps = new ProfilSupplier();

    const roles$ = this.utilisateurSvc.getRoleList();
    const profils$ = this.utilisateurSvc.getProfilList();

    const all$ = combineLatest([roles$,profils$]);
    return all$.toPromise().then(response => {
      ps.roles= response[0].resultList;
      ps.profils = response[1].resultList;
      return ps;
    });
  };
}

export class ProfilSupplier{
  profils:ProfilDTO[];
  roles :RoleDTO[]
}
