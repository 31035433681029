import {RepasDTO} from "./repas-dto";
import {DecoupageRepasDTO} from "./decoupagerepas-dto";
import {ObjectDTO} from "./object-dto";
import {ModeleNutritionnel__FamilleGemrcnDTO} from "./modele-nutritionnel__famille-gemrcn-dto";


export class ModeleNutritionnelPlanningDTO extends ObjectDTO {

  // modele nutritionnel planning
  repas: RepasDTO;
  decoupageRepas: DecoupageRepasDTO;
  actif: boolean = false;
  nbPlatsParComposanteRepas:number;
  jourSemaine:number;

  // modele nutri
  idModeleNutritionnel: number;


  // modele nutri famille gemrcn
  modeleNutritionnel__familleGemrcnList : ModeleNutritionnel__FamilleGemrcnDTO[] =[];



}
