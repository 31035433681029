<dx-popup
  title="Traitement en masse du plan de production"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'90wh'"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row mg-b-10">
    <div class="col-md-4"><label>Nombre de portions </label></div>
    <div class="col-md-8"><input type="number" class="text-right mg-r-5 full-width" [(ngModel)]="nbPortions"
                                 pKeyFilter="pnum"/></div>
  </div>
  <div class="row mg-b-10">
    <div class="col-md-4"><label>Date de fabrication </label></div>
    <div class="col-md-8">
      <dx-date-box #dateboxFab id="dateFab" [(ngModel)]="dateFab" type="date"></dx-date-box>
    </div>
  </div>
  <div class="row mg-b-10">
    <div class="col-md-4"><label>Date limite de consommation </label></div>
    <div class="col-md-8">
      <dx-date-box #dateboxLiv id="dateLiv" [(ngModel)]="dlc" type="date"></dx-date-box>
    </div>
  </div>
  <div class="row mg-b-10">
    <div class="col-md-4"><label>Equipe </label></div>
    <div class="col-md-8">
      <dx-lookup [(value)]="udpEquipe" [dataSource]="dataSourceTeams" [grouped]="true" displayExpr="equipeLibelle"
                 itemTemplate="itemTpl" groupTemplate="groupTpl">
        <dxo-drop-down-options [showTitle]="false">
          <div *dxTemplate="let item of 'groupTpl'">
            <span [title]="item.key"><i class="fas fa-store mg-r-5"></i> {{ item.key }}</span>
          </div>
          <div *dxTemplate="let item of 'itemTpl'">
            <span [title]="item.equipeLibelle"><i class="fas fa-users mg-r-5"></i> {{ item.equipeLibelle }}</span>
          </div>
        </dxo-drop-down-options>
      </dx-lookup>
    </div>
  </div>
  <div class="row mg-b-10">
    <div class="col-md-12 text-right">
      <yo-button iconClass="fas fa-check"
                 class="mg-r-5"
                 [disabled]="disabledSaveBtn()"
                 (onClick)="sendBulkDetailSupplier()"></yo-button>
      <yo-button iconClass="fas fa-times" (onClick)="closeDialog()"
                 buttonClass="cta-inner-delete"></yo-button>
    </div>
  </div>
</dx-popup>
