import {ObjectDTO} from "./object-dto";

export class ListeBesoinsJourDto extends ObjectDTO {

  date: number;
  actif: boolean;

  // liste besoins
  idListeBesoin : number;

  // repas
  idRepas : number;
  repasLibelle:string;

  // contrat menu convive
  idContratMenuConvive : number;
  contratMenuConviveLibelle: string;

  // contrat menu convive repas
  idContratMenuConviveRepas:number;
  contratMenuConviveRepasLibelle:string;
  idJourSemaine:number;

  // contrat menu
  idContratMenu: number;
  contratMenuLibelle: string;

  // site contratMenu
  idContratMenuSite:number;
}
