import {Injectable} from '@angular/core';
import {HttpService} from '../technique/http.service';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from '../../dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto';

export const URL_GET_ALL_BY_ID_MCP = `dolrest/conditionnements/mcp-conditionnements-variantes/listByIdMcp`;
export const URL_GET_ALL_BY_ID_MCPLC = `dolrest/conditionnements/mcp-conditionnements-variantes/listByIdMcplc`;
export const URL_GET_ALL_BY_SITES = `dolrest/conditionnements/mcp-conditionnements-variantes/list`;

@Injectable({
  providedIn: 'root'
})
export class McpConditionnementsVariantesService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles conditionnements plats - conditionnements variantes déclinaisons en fonction d'un identifiant
   * de modèle de conditionnement de plat
   * @returns {Observable<ModeleConditionnementsPlatConditionnementsVariantesDTO>}
   */
  getAllByIdMcp = (idMcp: number): Observable<ResponseWrapper<ModeleConditionnementsPlatConditionnementsVariantesDTO>> => this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCP}/${idMcp}`, null);

  /**
   * Récupérer tous les modèles conditionnements plats - conditionnements variantes déclinaisons en fonction d'un identifiant
   * de modèle de conditionnement de plc
   * @returns {Observable<ModeleConditionnementsPlatConditionnementsVariantesDTO>}
   */
  getAllByIdMcplc = (idMcplc: number): Observable<ResponseWrapper<ModeleConditionnementsPlatConditionnementsVariantesDTO>> => this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCPLC}/${idMcplc}`, null);

  /**
   * Récupération de tous les modèles conditionnements plats - conditionnements variables déclinaisons en fonction
   * de l'environnement.
   * @returns {Observable<ModeleConditionnementsPlatConditionnementsVariantesDTO>}
   */
  getAllBySites = (idsSites?: number[]): Observable<ResponseWrapper<ModeleConditionnementsPlatConditionnementsVariantesDTO>> => this.httpSvc.get(`${URL_GET_ALL_BY_SITES}${idsSites && idsSites.length ? `?idsSites=${idsSites}` : ''}`, null);

}
