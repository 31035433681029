import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FamilleProduitDTO} from '../../dtos/famille-produit-dto';
import {Observable, Subject} from 'rxjs';
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {GenericDatagridService} from "../generics/generic-datagrid.service";
import {ObjectDTO} from "../../dtos/object-dto";

export const URL_FIND_ALL = `dolrest/famillesproduits/findAll`;

@Injectable()
export class FamillesProduitService {

  familleProduitList: FamilleProduitDTO[];

  private subjectfamilleProduit = new Subject();
  familleProduit$ = this.subjectfamilleProduit.asObservable();

  private subjectFamilleProduitSaved = new Subject();
  familleProduitSaved$ = this.subjectFamilleProduitSaved.asObservable();

  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private httpSvc: HttpService,
              private gds: GenericDatagridService) {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.familleProduitList = [];
        this.familleProduitList = response.resultList;
      });
  }

  announceFamilleProduit = (parents: FamilleProduitDTO[], familleProduit?: FamilleProduitDTO) => {
    this.subjectfamilleProduit.next({ parents, familleProduit });
  };

  get findAll(): (isFabrique?: boolean) => Observable<ResponseWrapper<FamilleProduitDTO>> {
    return this._findAll;
  }

  getEntityName = (): string => 'FamilleProduit';

  getSort = (): string[] => ['libelle,asc'];

  getAllFamilleProduits = () => this.gds.getAll(this.getEntityName(), ['libelle,asc'], false);

  set findAll(value: (isFabrique?: boolean) => Observable<ResponseWrapper<FamilleProduitDTO>>) {
    this._findAll = value;
  }

  /**
   * enlever tous les sous niveaux de famille<br>
   *     enlever les familles winapro incoherentes A000...
   * @param response
   */
  removeChildren = (familles: FamilleProduitDTO[]) => {

    if (this.utils.isCollectionNullOrEmpty(familles)) {
      familles = [];
    }

    // enlever tous les sous niveaux de famille
    familles = familles.filter(f => !f.code.includes('.'));

    // enlever les familles winapro incoherentes A000...
    familles = familles.filter(f => !f.libelle.includes('A0000'));

    return familles;
  };

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  private _findAll = (isFabrique: boolean = true): Observable<ResponseWrapper<FamilleProduitDTO>> => {
    return this.httpSvc.get(`${URL_FIND_ALL}?isFabrique=${isFabrique}`);
  }

  delete = (ids: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/referentiel/famille/produit/delete?ids=${ids}`);
  }

  announceFamilleProduitSaved = (): void => {
    this.subjectFamilleProduitSaved.next();
  }

  save = (familleProduit: FamilleProduitDTO): Observable<any> => {
    return this.httpSvc.post(`dolrest/referentiel/famille/produit/save`, familleProduit);
  }

}
