<ng-container *ngIf="displayAppellations">

  <ng-container *ngFor="let pa of _produitAppellations">
     <!--on n'affiche que si le produit appellation est actif-->
    <ng-container *ngIf="pa.actif">
      <span class="pg-2" [title]="pa.appellationLibelle" >
      <yo-img-entity [ordre]="1" [refresh]="refreshImg" [entityName]="appellationSvc.getEntityName()"
                     [displayNoImage]="false" format="thumb"
                     [entityId]="pa.appellationId"
                     [title]="pa.appellationLibelle"
                     [openable]="true"
                     [height]="18"
      ></yo-img-entity>
      </span>
    </ng-container>

  </ng-container>


</ng-container>

