import {Injectable} from '@angular/core';

@Injectable()
export class AdministrationRoutemapService {

  constructor() { }

  getSites = (): string[] => ['/administration/features', 'sites'];

  getEnvironnements = (): string[] => ['/administration/features/environnements'];

  getConvives = (): string[] => ['/administration/features/convives'];

  getApportsNutritionnels = (): string[] => ['/administration/features/apports-nutritionnels'];

  getDeclinaisons = (): string[] => ['/administration/features/declinaisons'];

  getAllergenes = (): string[] => ['/administration/features/allergenes'];

  getFamillesGEMRCN = (): string[] => ['/administration/features/familles-gemrcn'];

  getFamillesProduit = (): string[] => ['/administration/features/familles-produit'];

  getTypesProduit = (): string[] => ['/administration/features/types-produit'];

  getGroupesNutritionnels = (): string[] => ['/administration/features/groupes-nutritionnels'];

  getFamillesPlanAlimentaires = (): string[] => ['/administration/features/familles-plans-alimentaires'];

  getRepas = (): string[] => ['/administration/features/repas'];

  getDecoupagesRepas = (): string[] => ['/administration/features/decoupages-repas'];

  getModelesNutritionnels = (): string[] => ['/administration/features/modeles-nutritionnels'];

}
