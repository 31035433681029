import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {MarcheProduitService} from '../../../core/services/gestionproduits/marche-produit.service';
import {TYPE_LIST_UDP} from "../../../core/constants";

@Injectable({
  providedIn: 'root'
})
export class MarchesProduitResolverService implements Resolve<MarcheProduitSupplier> {

  constructor(private mpSvc: MarcheProduitService,
              private udpSvc: UnitesDeProductionService,
              private gds: GenericDatagridService,
              private produitsSvc: ProduitsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MarcheProduitSupplier> | Promise<MarcheProduitSupplier> | MarcheProduitSupplier => {

    const mps = new MarcheProduitSupplier();

    const idProduit = route.parent.paramMap.get('idProduit');
    const produit$ = this.gds.getOne(this.produitsSvc.getEntityName(), +idProduit);
    const uniteDeProductionList$ = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const all$ = combineLatest(uniteDeProductionList$, produit$);

    return all$.toPromise().then(data => {
      mps.uniteDeProductionList = data[0].resultList;
      mps.produit = data[1].one;

      return mps;
    });
  };
}

export class MarcheProduitSupplier {

  uniteDeProductionList: UniteDeProductionDTO[];
  produit: ProduitDTO;
}
