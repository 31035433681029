import {NgModule} from '@angular/core';

import {GestionListesBesoinsRoutingModule} from './gestion-listes-besoins-routing.module';
import {SharedModule} from '../shared/shared.module';

import {GrilleListesBesoinsComponent} from './grille-listes-besoins/grille-listes-besoins.component';

import {CoreModule} from '../core/core.module';
import {GestionListesBesoinsRootComponent} from './gestion-listes-besoins-root/gestion-listes-besoins-root.component';
import {LbComponent} from './lb/lb.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {BesoinsComponent} from './liste-besoins/besoins/besoins.component';
import {ArticlesComponent} from './liste-besoins/besoins/articles/articles.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {
    DxDataGridModule,
    DxDropDownBoxModule,
    DxLookupModule, DxPopupModule,
    DxSelectBoxModule,
    DxToolbarModule
} from 'devextreme-angular';
import {BulkEditionArticleComponent} from './liste-besoins/besoins/articles/bulk-edition-article/bulk-edition-article.component';

import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {DialogModule} from 'primeng/dialog';

import {CalendarModule} from 'primeng/calendar';
import {SelectButtonModule} from 'primeng/selectbutton';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';


@NgModule({
    imports: [
        GestionListesBesoinsRoutingModule,
        CoreModule,
        CommonModule,
        SharedModule,
        GestionProcessusModule,
        FormsModule,
        ReactiveFormsModule,


        CardModule,
        TableModule,
        PanelModule,
        ListboxModule,
        DialogModule,

        CalendarModule,
        SelectButtonModule,
        OverlayPanelModule,
        InputTextModule,
        DxSelectBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        DxLookupModule,
        DxToolbarModule,
        DxPopupModule


    ],
  entryComponents: [

  ],
  declarations: [
    GestionListesBesoinsRootComponent,
    GrilleListesBesoinsComponent,
    BesoinsComponent,
    LbComponent,
    ArticlesComponent,
    BulkEditionArticleComponent
  ],
})
export class GestionListesBesoinsModule {
}
