<!--ICONES MENUS-->
<div id="left-menu" class="root animated">

  <ng-container *ngFor="let item of itemNavSvc.menuItems;  index as i;">
    <ng-container
      *ngTemplateOutlet="tplItemFlMenu;context:{item:item,index:i}">
    </ng-container>
  </ng-container>

  <p-tieredMenu #menu
                appendTo="body"
                styleClass="sidemenu-sized"
                [popup]="true"
                [model]="subMenuItems"></p-tieredMenu>

  <div class="root-footer animated">
    <!--ICONE VERSION-->

    <div class="flex-container">
      <div class="item-container">
        <yo-infos-version [displayLabel]="true"
                          resetIconClasses="true"
                          iconClasses="far fa-question-circle site-secondaire-color sidebar-icon"
                          labelClasses="white sidebar-label"
                          class="animated">
        </yo-infos-version>
      </div>
    </div>

    <div class="flex-container pointer zoom-items"
         (click)="toggleSideMenu()"
         title="Agrandir"
    >
      <div
        class="item-container"
      >
        <i
          class="fas fa-angle-double-right item-icon pointer cursor animated"
          [class.reverse]="isSideMenuOpened">
        </i>
        <span class="item-label pointer white unselectable">Réduire</span>
      </div>
    </div>

  </div>
</div>


<!--TEMPLATE ICON MENU FIRST LEVEL-->
<ng-template #tplItemFlMenu let-item="item" let-index="index">

  <!--        HACK PRIMENG pour positionner le menu en haut à droite de l'élément-->
  <div [attr.id]="'submenudolapp'+index" (click)="openRealMenu(item,menu,$event)">
  </div>

  <div
    [class.selected]="itemNavSvc.selectedFlMenuItem === item"
    [class.pre-selected]="itemNavSvc.newFlMenuItem === item "
    (click)="openMenu('submenudolapp' + index, menu)"
    [title]="item.label"
  >
    <div class="flex-container pointer zoom-items">
      <div class="item-container pointer">
        <i [attr.class]="item.icon + ' item-icon cursor'"></i>
        <label
          [title]="item.label"
          class="item-label pointer unselectable {{ itemNavSvc.newFlMenuItem !== item && itemNavSvc.selectedFlMenuItem === item ? 'toolbar-color' : 'white' }}">{{ item.label }}</label>
      </div>
    </div>
  </div>
</ng-template>

