import {SiteDTO} from "./site-dto";

export class Tournee {

  id: number;

  site: SiteDTO;

  libelle: string;

  actif:boolean;

  code:string;

  poidsMaxEssieux: number;

}
