import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MenuCompositionDTO} from '../../core/dtos/menucomposition-dto';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {USER_PREFERENCE} from '../../core/constants';
import {Subscription} from 'rxjs';

@Component({
  selector: 'yo-cout-portion-pondere',
  templateUrl: './cout-portion-pondere.component.html',
  styleUrls: ['./cout-portion-pondere.component.scss']
})
export class CoutPortionPondereComponent implements OnInit {

  @Input() menuCompo: MenuCompositionDTO;
  subDisplay: Subscription;
  displayCoutsDetails: boolean = false;

  constructor(private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.subcriptionDisplayCoutsDetails();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplay);
  }

  subcriptionDisplayCoutsDetails = () => {
    this.subDisplay = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayCoutsDetails = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES);
      this.cd.markForCheck();
    });
  };
}
