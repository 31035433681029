import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {CoreModule} from "../core/core.module";
import {RequeteurGraphqlComponent} from "./requeteur-graphql.component";
import {RequeteurGraphqlRoutingModule} from "./requeteur-graphql-routing.module";
import {PanelModule} from "primeng/panel";
import {RadioButtonModule} from "primeng/radiobutton";
import {FormsModule} from "@angular/forms";
import {ViewJsonGraphQLComponent} from "./view-json/view-json-graphql.component";
import {ViewPlainJsonGraphqlComponent} from "./view-plain-json/view-plain-json-graphql.component";
import {ViewTableGraphqlComponent} from "./view-table/view-table-graphql.component";
import {DxDataGridModule} from "devextreme-angular";
import {ViewDetailsRowGraphqlComponent} from "./view-table/view-details/view-details-row-graphql.component";
import {DialogModule} from "primeng/dialog";

@NgModule({
  imports: [
    CommonModule,
    RequeteurGraphqlRoutingModule,
    SharedModule,
    CoreModule,
    PanelModule,
    RadioButtonModule,
    FormsModule,

    DxDataGridModule,
    DialogModule,
  ],
  declarations: [RequeteurGraphqlComponent,
    ViewJsonGraphQLComponent,
    ViewPlainJsonGraphqlComponent,
    ViewTableGraphqlComponent,
    ViewDetailsRowGraphqlComponent],
  exports:[
    RequeteurGraphqlComponent
  ]
})
export class RequeteurGraphqlModule { }
