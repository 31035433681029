import {Injectable} from '@angular/core';
import {DeviceDetectorService, DeviceInfo} from "ngx-device-detector";
import {DOL_VERSION} from "../../../dol-version";
import {DialogMsgSupplier, Paragraphe} from "../suppliers/dialog-msg-supplier";
import {UtilsService} from "../utils/utils.service";
import {MessageDialogService} from "./technique/message-dialog-service";

@Injectable({
  providedIn: 'root'
})
export class RequirementsService {

  deviceInfo: DeviceInfo;

  readonly MIN_HEIGHT = 1000;
  readonly MIN_WIDTH = 1600;
  readonly MIN_VERSION_CHROME = 65;
  readonly MIN_VERSION_FIREFOX = 60;
  readonly MIN_VERSION_EDGE = 65;


  knownBrowserMap: Map<string, number> = new Map();

  constructor(private deviceSvc: DeviceDetectorService,
              private messageDialogSvc : MessageDialogService,
              private utils: UtilsService) {


    this.knownBrowserMap = new Map();
    this.knownBrowserMap.set('chrome', this.MIN_VERSION_CHROME);
    this.knownBrowserMap.set('firefox', this.MIN_VERSION_FIREFOX);
    this.knownBrowserMap.set('ms-edge-chromium', this.MIN_VERSION_EDGE);


    this.deviceInfo = this.deviceSvc.getDeviceInfo();

    console.log('BROWSER', this.deviceInfo.browser);
  }

  checkScreen(requiredDevice: RequiredDevice) {

    if (requiredDevice.outerWidth < this.MIN_WIDTH) {
      return false;
    }

    if (requiredDevice.outerHeight < this.MIN_HEIGHT) {
      return false;
    }

    return true;
  }

  /**
   * On verifie le navigateur et sa version
   * @param {RequiredDevice} requiredDevice
   * @returns {boolean}
   */
  checkBrowser(requiredDevice: RequiredDevice) {

    const version = +requiredDevice.deviceInfo.browser_version;
    const browser = requiredDevice.deviceInfo.browser.toLowerCase();

    if (!this.knownBrowserMap.has(browser)) {
      return false;
    }

    const minBrowserVersion = this.knownBrowserMap.get(browser);

    if (version < minBrowserVersion) {
      return false;
    }

    return true;
  }

  public requireCompatibleDevice() {


    const requiredDevice: RequiredDevice = new RequiredDevice();
    requiredDevice.deviceInfo = this.deviceInfo;
    requiredDevice.outerHeight = window.outerHeight;
    requiredDevice.outerWidth = window.outerWidth;

    console.log(`DATAMEAL ONLINE version='${DOL_VERSION}'`);
    console.log(`INFOS PERIPHERIQUE`, requiredDevice.deviceInfo);
    console.log(`RESOLUTION FENETRE NAVIGATEUR  ${requiredDevice.outerWidth} * ${requiredDevice.outerHeight}`);

    // requiredDevice.isScreenOk = this.checkScreen(requiredDevice);
    requiredDevice.isBrowserOk = this.checkBrowser(requiredDevice);


    if (!requiredDevice.isCompatible()) {
      this.openRequirements(requiredDevice);
    }
  }

  private getIconOk(isOk: boolean) {
    let str = '';

    if (isOk) {
      str = `<i class="fa fa-check new-color space"></i>`;
    } else {
      str = `<i class="fa fa-close danger-color space"></i>`;
    }

    return str;
  }

  private getNavigateurLabel(navName: string) {


    if (navName.toLowerCase() === 'chrome') {
      return `<i class="fab fa-chrome yoni-color space"></i>CHROME`;
    }

    if (navName.toLowerCase() === 'firefox') {
      return `<i class="fab fa-firefox yoni-color space"></i>FIREFOX`;
    }

    if (navName.toLowerCase() === 'ms-edge') {
      return `<i class="fab fa-edge yoni-color space"></i>MICROSOFT EDGE`;
    }

    if (navName.toLowerCase() === 'ie') {
      return `<i class="fab fa-internet-explorer yoni-color space"></i>INTERNET EXPLORER`;
    }


    return navName.toUpperCase();
  }

  openRequirements(requiredDevice: RequiredDevice): void {

    const dms: DialogMsgSupplier = new DialogMsgSupplier();
    dms.title = 'Prérequis Datameal Online';
    dms.logo = 'fa fa-cogs yoni-color';

    const p1: Paragraphe = new Paragraphe();
    const sp1: Paragraphe = new Paragraphe();

    p1.title = 'Paramètres systèmes nécessaires';
    sp1.lines.push(`L'application fonctionne seulement sur les navigateurs : <br><br>${this.getNavigateurLabel('chrome')}  en version 65 et plus.<br>${this.getNavigateurLabel('firefox')}  en version 60 et plus.<br>${this.getNavigateurLabel('edge')}  en version 87 et plus.`);
    sp1.lines.push(`La résolution de votre fenetre de navigateur doit être au minimum ${this.MIN_WIDTH} * ${this.MIN_HEIGHT}.`);

    p1.sousParagraphes.push(sp1);

    dms.content.paragraphes.push(p1);

    const p2: Paragraphe = new Paragraphe();

    const sp2: Paragraphe = new Paragraphe();
    p2.title = 'Paramètres systèmes détectés';


    sp2.lines.push(`${this.getIconOk(requiredDevice.isBrowserOk)} Navigateur : ${this.getNavigateurLabel(requiredDevice.deviceInfo.browser)}, version ${requiredDevice.deviceInfo.browser_version}.`);

    sp2.lines.push(`${this.getIconOk(requiredDevice.isScreenOk)} Résolution de votre fenêtre de navigateur : ${requiredDevice.outerWidth} * ${requiredDevice.outerHeight}.`);


    p2.sousParagraphes.push(sp2);

    dms.content.paragraphes.push(p2);

    const p3: Paragraphe = new Paragraphe();
    p3.title = `Votre système n'est actuellement <b><span class="danger-color">PAS OPTIMISÉ</span></b> avec l'application DATAMEAL ONLINE.`;

    dms.content.paragraphes.push(p3);

    this.messageDialogSvc.announceDisplayMessageDialog(dms);


  }

}

export class RequiredDevice {

  isScreenOk: boolean = true;
  isBrowserOk: boolean = true;
  deviceInfo: DeviceInfo;
  outerWidth: number;
  outerHeight: number;

  constructor() {

  }

  isCompatible(): boolean {

    if (!this.isScreenOk) {
      return false;
    }

    if (!this.isBrowserOk) {
      return false;
    }

    return true;
  }
}
