import {Component, Input} from '@angular/core';


@Component({
  selector: 'yo-legend-grid-dev-extreme',
  templateUrl: './legend-grid-dev-extreme.component.html',
  styleUrls: ['./legend-grid-dev-extreme.component.scss']
})
export class LegendGridDevExtremeComponent {

  @Input() modificationManuelle :boolean;
  @Input() colonneModifiable :boolean;
  @Input() colonneInformations :boolean;
  @Input() colonneObligatoire :boolean;
  @Input() celluleActive :boolean;
  @Input() celluleInactive :boolean;
  @Input() celluleDescription: boolean;

}
