import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FamilleNutritionnelleProduitDTO} from '../../core/dtos/famillenutritionelle-produit-dto';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {MenusPlanningService} from '../../core/services/gestionmenus/menus-planning.service';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {USER_PREFERENCE} from '../../core/constants';
import {MenusCompositionService} from '../../core/services/gestionmenus/menus-composition.service';
import {RoutemapService} from '../../core/services/routemap.service';

@Component({
  selector: 'yo-famnuts-menucompo',
  templateUrl: './famnuts-menucompo.component.html',
  styleUrls: ['./famnuts-menucompo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FamnutsMenucompoComponent implements OnInit, OnDestroy {

  @Input() famillesNutritionnellesProduits: FamilleNutritionnelleProduitDTO[];

  //Subscription
  subDisplayFamillesGemrcn: Subscription;
  displayFamillesGemrcn=false;

  constructor(public menuPlanningSvc: MenusPlanningService,
              public menuCompoSvc: MenusCompositionService,
              private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private routeMapSvc: RoutemapService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.subscriptionDisplayFamillesGemrcn();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayFamillesGemrcn);
  }

  subscriptionDisplayFamillesGemrcn = () => {
    this.subDisplayFamillesGemrcn = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayFamillesGemrcn=  this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN);
      this.cd.markForCheck();
    });
  };

  openProduitOnFamnut = () => {
    if (!this.utils.isCollectionNullOrEmpty(this.famillesNutritionnellesProduits)) {
      const famNutProduit = this.famillesNutritionnellesProduits[0];
      this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', famNutProduit.fabrique, famNutProduit.produitId, 'gemrcn']);
    }
  };

}
