<dx-popup
  [showTitle]="true"
  title="Ajouter des Fournisseurs"
  [(visible)]="displayDialog"
  width="70vw"
  maxHeight="auto"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <dx-scroll-view>
    <div class="stepper-container">
      <div class="stepper">
        <strong>{{activeIndex + 1}}</strong>
      </div>
      <strong>{{ itemsSteper[activeIndex].label }}</strong>
    </div>

    <div class="d-flex flex-grow-1">
      <div [class.hidden]="activeIndex === 1">
        <div class="d-flex justify-content-center align-items-center mg-t-10 mg-b-10">
          <dx-data-grid
            [dataSource]="fournisseurList"
            keyExpr="id"
            height="50vh"
            width="auto"
            (onSelectionChanged)="onChangeSelection($event)"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            #grid>
            <dxi-column dataField="site" alignment="left" caption="Site" [width]="300"
                        [allowFiltering]="false" [allowSorting]="false"
                        cellTemplate="siteCellTemplate">
            </dxi-column>
            <dxi-column dataField="libelle" caption="Fournisseur" [width]="300"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>
            <dxi-column dataField="code" caption="Code" [width]="300"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>


            <div *dxTemplate="let cell of 'siteCellTemplate'">
              <yo-site [siteLibelle]="cell.data?.site?.libelle"
                       [siteId]="cell.data?.site?.id">
              </yo-site>
            </div>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-selection mode="multiple"
                           selectAllMode="true"
                           [showCheckBoxesMode]="'always'">
            </dxo-selection>
          </dx-data-grid>
        </div>
      </div>


      <!-- MAJORATION -->
      <div [class.hidden]="activeIndex === 0">

        <div class="d-flex justify-content-center align-items-center mg-t-10 mg-b-10 input-ratio">
          <label class="mg-r-5"> Ratio : </label>
          <div class="p-inputgroup" [style.width.px]="100">
            <input type="text" pInputText [(ngModel)]="ratioMassiveApply">
            <yo-button
              iconClass="fas fa-check"
              (onClick)="massiveApplyMajoration()"
              tooltip="Appliquer ce ratio à tous les fournisseurs">
            </yo-button>
          </div>
        </div>
        <dx-data-grid
          [dataSource]="gaFournisseurList"
          height="50vh"
          width="auto"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #gridratio>
          <dxi-column dataField="fournisseur.libelle" caption="Fournisseur"
                      [filterOperations]="['contains']"
                      [allowFiltering]="false" [allowEditing]="false">
          </dxi-column>
          <dxi-column dataField="ratioPrixUF" caption="Ratio Prix UF" width="200"
                      [filterOperations]="['contains']"
                      [allowFiltering]="false" [allowEditing]="true">
          </dxi-column>
          <dxo-editing
            mode="cell"
            [allowUpdating]="true">
          </dxo-editing>

        </dx-data-grid>
      </div>
    </div>
    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          [label]="activeIndex === 0 ? 'Suivant' : 'Ajouter'"
          (onClick)="activeIndex === 0 ? addFournisseurSelected() : sendFournisseurSelected()"
          [disabled]="fournisseurListSelected.length === 0">
        </yo-button>

        <yo-button buttonClass="cta-delete" iconClass="fas fa-times"
                   (onClick)="closeDialog()">
        </yo-button>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
