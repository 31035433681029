<button
  [id]="buttonId"
  [name]="name"
  [class]="buttonClass"
  (click)="onButtonClick($event)"
  [title]="tooltip"
  [type]="type"
  [class.incompress]="true"
  [disabled]="disabled">
  <i *ngIf="iconPos === 'left'" [class]="iconClass"></i>
  {{label}}
  <i *ngIf="iconPos === 'right'" [class]="iconClass"></i>
</button>
