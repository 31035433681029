import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {GenericHandler} from '../generics/generic-handler';
import {CatalogueAchatDTO} from '../../dtos/catalogue-achat-dto';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {startCase as _startCase} from 'lodash';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {CatalogueAchatNonAffecteDTO} from '../../dtos/gestion-fournisseur/catalogue-achat-non-affecte-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {SiteDTO} from '../../dtos/site-dto';
import {ProduitArticleDTO} from '../../dtos/produit-article-dto';


export const URL_GET_ALL_CANONAFFECT = `dolrest/catalogueachatnonaffecte/getall`;
export const URL_GET_ALL_SECTEUR = `dolrest/catalogueachatnonaffecte/getallsecteur`;
export const URL_GET_ALL_FILLIALE= `dolrest/catalogueachatnonaffecte/getallfournisseurfiliale`;
export const URL_SEARCH_DENREE= `dolrest/catalogueachatnonaffecte/searchdenree`;
export const URL_GET_PRODUIT_ARTICLE_LINK_TO_PROD_DECLI = `dolrest/catalogueachatnonaffecte/produitarticlelinktoproduitdecli`;
export const URL_GET_UNICITY_PA = `dolrest/catalogueachatnonaffecte/getunicityproduitarticle`;
export const URL_POST_AFFECT_CANONAFFECT = `dolrest/catalogueachatnonaffecte/affectcatalogueachatnonaffecte`;



@Injectable()
export class CatalogueAchatNonAffecteService extends GenericHandler<CatalogueAchatNonAffecteDTO>{

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public router: Router,
              public title: Title,
              public http: HttpClient){
    super(utils, auth2Svc, router, http, title);
  }


  getProduitArticlesLinkToProduitDeclinaison = (produitDeclinaison :ProduitDeclinaisonDTO) => this.http.get(URL_GET_PRODUIT_ARTICLE_LINK_TO_PROD_DECLI,
    {params: new HttpParams().set('produitDeclinaisonId', produitDeclinaison.id + '')})
    .pipe(
      catchError(error => this.utils.handleError(error))
    );

  getListCatalogueAchatNonAffecte = () => this.http.get(URL_GET_ALL_CANONAFFECT, {})
    .pipe(
      catchError(error => this.utils.handleError(error))
    );

  getListSecteur = () => this.http.get(URL_GET_ALL_SECTEUR, {})
    .pipe(
      catchError(error => this.utils.handleError(error))
    );

  getListFilliale = () => this.http.get(URL_GET_ALL_FILLIALE, {})
    .pipe(
      catchError(error => this.utils.handleError(error))
    );

  /**
   * Recherche des produit décli
   * @param {string} endUrl
   * @param {SearchSupplierWrapper} ssW
   * @returns {Observable<any>}
   */
  searchDenree = (ssW: SearchSupplierWrapper) => this.http.post(URL_SEARCH_DENREE, ssW).pipe(
    catchError(error => this.utils.handleError(error, true))
  );


  postAffectationCaNonAffect = (produitArticle: ProduitArticleDTO,
                                site: SiteDTO, caNonAffecte: CatalogueAchatNonAffecteDTO[], produitDelicnaison: ProduitDeclinaisonDTO) => this.http.post(URL_POST_AFFECT_CANONAFFECT, produitArticle, {
    params: new HttpParams().set('produitDeclinaisonId', produitDelicnaison.id + '')
      .set('catNonAffecteListId', caNonAffecte ? caNonAffecte.map(ca => ca.id).join(',') : '')
      .set('siteId', site.id + '')
  }).pipe(
    catchError(err => this.utils.handleError(err, true))
  );


  getUnicityProduitArticle = (site: SiteDTO) => this.http.get(URL_GET_UNICITY_PA,
    {params: new HttpParams().set('siteId', site.id + '')})
    .pipe(
      catchError(error => this.utils.handleError(error))
    );


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getSort = (): string[] => ['libelle,asc'];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES CATALOGUES ACHATS NON AFFECTE';

  getCreateNewObjectLabel = (): string => 'CRÉER UN CATALOGUE ACHAT NON AFFECTE';

  getEntityName = (): string => 'catalogueAchatNonAffecte';

  getFields = (dto: CatalogueAchatDTO): FormFieldBaseSupplier<any>[] => undefined;

  createEmptyDTO = (): CatalogueAchatNonAffecteDTO => new CatalogueAchatNonAffecteDTO();

  getAllFromEnvironnement = (): void => {
  };

  getEditObjectLabel = (data: ObjectDTO): string => "";
}
