<div class="d-flex flex-column size-info-general" [style.max-width.px]="utils.getMaxWidthBody()">

  <div>
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <label class="space"><strong>Marché {{marche.libelle | uppercase}}</strong></label>
  </div>

  <div class="flex-grow-1">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        🗣️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-save"
          (onClick)="saveFicheIdentite()"
          [disabled]="isDisabledSaveBtn()"
          tooltip="Enregistrer"
          buttonClass="cta-success"></yo-button>
      </div>
      <div>
        <yo-button
          iconClass="fas fa-times"
          tooltip="Fermer"
          (onClick)="utils.sidenav=false"
          class="cta-inner-delete"></yo-button>
      </div>
    </div>
  </div>
</div>
