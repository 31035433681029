import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';

@Component({
  selector: 'yo-img-entity',
  templateUrl: './img-entity.component.html',
  styleUrls: ['./img-entity.component.scss'],
})
export class ImgEntityComponent implements OnInit {

  noImageUrl: string = 'assets/images/no-image.png';


  @Input() entityId: number;
  @Input() entityName: string;
  @Input() ordre: number;
  @Input() height: number;
  @Input() title: string;
  @Input() format: string; // logo, thumb, regular, full
  @Input() openable: boolean = false;

  @Input() set refresh(value: number) {
    this._refresh= value;

    if(this.entityName){
      this.url = this.getUrl(this.entityName.toLowerCase(), this.entityId, this.ordre, this.format, this._refresh);
    }


  }

  // afficher l'image qui permet de dire qu'il n'y a pas d'image disponible
  @Input() set displayNoImage(value: boolean) {
    this._displayNoImage = value;
    if (value) {
      this.url = this.noImageUrl;
    }
  };

  _displayNoImage: boolean;


  formats: Map<string, number>;
  url: string;
  isError: boolean;
  displayOriginalImg: boolean = false;

  urlOriginal: string;
  widthFull: number;
  _refresh: number = 1;


  constructor(public utils: UtilsService) {
  }

  ngOnInit() {

    if(this.entityName){
      this.url = this.getUrl(this.entityName.toLowerCase(), this.entityId, this.ordre, this.format, this._refresh);
    }


    // console.log('url',this.url);

    this.formats = new Map();
    this.formats.set('logo', 64);
    this.formats.set('thumb', 128);
    this.formats.set('regular', 800);
    this.formats.set('full', 2048);

    // this.width = this.formats.get(this.format);

  }

  getUrl(entityName: string, entityId: number, ordre: number, format: string, modifyDate: number) {

    if (!this.utils.isNullOrEmpty(entityName)) {
      this.isError = false;
      return `dolrest/document/loadByEntity/${entityName}/${entityId}/${ordre}/${format}?date=${modifyDate}`;
    }

    if(!this._displayNoImage){
      return 'assets/images/no-image.png';
    }

    return '';

  }



  getTooltip() {
    return this.isError ? 'Aucune image paramétrée' : '';
  }

  displayOriginalImage() {
    this.displayOriginalImg = !this.displayOriginalImg;

    this.urlOriginal = `dolrest/document/loadByEntity/${this.entityName}/${this.entityId}/${this.ordre}/original?date=${this._refresh}`;
  }

  closeDialog() {
    this.displayOriginalImg = false;
  }
}
