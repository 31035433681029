<span
  *ngIf="isArticleLotMarche()"
  class="badge badge-secondary cursor"

  (mouseenter)="opLotMarche.show($event)"
  (mouseleave)="opLotMarche.hide()"
>
    {{lotMarchePdLumpCa.lotMarcheCode}}
</span>

<p-overlayPanel #opLotMarche appendTo="body">
  <ng-template pTemplate>
    <div style="width:300px;">
      <div class="row">
        <div class="col-md-4 genlabel ">Marché</div>
        <div class="col-md-8 bold" >{{lotMarchePdLumpCa.marcheLibelle}}</div>
      </div>
      <div class="row">
        <div class="col-md-4 genlabel ">Lot Marché</div>
        <div class="col-md-8 bold">{{lotMarchePdLumpCa.lotMarcheLibelle}}</div>
      </div>
      <div class="row">
        <div class="col-md-4 genlabel ">Période</div>
        <div class="col-md-8 bold"> {{lotMarchePdLumpCa.lotMarcheDateDebut | date:'dd/MM/yyyy'}} - {{lotMarchePdLumpCa.lotMarcheDateFin | date:'dd/MM/yyyy'}}</div>
      </div>
      <div class="row">
        <div class="col-md-4 genlabel ">Ordre</div>
        <div class="col-md-8 bold">{{lotMarchePdLumpCa.lmsfOrdre}}</div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
