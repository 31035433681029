import {ObjectDTO} from "./object-dto";

export class ProduitContrainteAlimDTO extends ObjectDTO {

  //    produit
  produitId: number;
  produitLibelle: string;
  produitCode: string;
  produitActif: boolean;

  // regime
  regimeCode: string;
  regimeLibelle: string;
  regimeId: number;
  regimeActif: boolean;

  // texture
  textureCode: string;
  textureLibelle: string;
  textureId: number;
  textureActif: boolean;

  // transient
  selectionne:boolean;

}
