import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {combineLatest, Observable} from 'rxjs';
import {ReglePrefereDTO} from '../../../core/dtos/regle-prefere-dto';
import {UniteDeProduction__ReglePrefereDTO} from '../../../core/dtos/unite-de-production__regle-prefere-dto';
import {ReglePrefereService} from '../../../core/services/entities/regle-prefere.service';
import {UniteDeProductionReglePrefereService} from '../../../core/services/entities/unite-de-production__regle-prefere.service';
import {find as _find} from 'lodash'
import {REGLES_PREFERES} from '../../../core/constants';

@Injectable({
  providedIn: 'root'
})
export class UpArticlesPreferesResolverService implements Resolve<UpArticlesPreferesSupplier> {

  constructor(private gds: GenericDatagridService,
              private reglesPreferesSvc: ReglePrefereService,
              private udpReglePrefereSvc: UniteDeProductionReglePrefereService,
              private utils: UtilsService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpArticlesPreferesSupplier> | Promise<UpArticlesPreferesSupplier> | UpArticlesPreferesSupplier {

    const upAps = new UpArticlesPreferesSupplier();
    const idUniteDeProduction: number = +route.parent.paramMap.get('idUniteDeProduction');
    const reglesPreferes$ = this.gds.search(this.reglesPreferesSvc.filterAll());
    // regle prefere pour l'unite de production
    const reglePrefere$ = this.gds.search(this.udpReglePrefereSvc.filterByIdUniteDeProduction(idUniteDeProduction));

    const all$ = combineLatest([reglesPreferes$,reglePrefere$]);
    return all$.toPromise().then(response => {
      upAps.reglesPreferes = response[0].resultList;

      // s'il existe une régle sur l'unité de production, on la préselectionne
      if(!this.utils.isCollectionNullOrEmpty( upAps.reglesPreferes) ){
        upAps.upReglePrefere = this.udpReglePrefereSvc.createUdpReglePrefere(idUniteDeProduction);

        if(!this.utils.isCollectionNullOrEmpty( response[1].resultList)){
          upAps.upReglePrefere = response[1].resultList[0];
          upAps.selectedReglePrefere=_find(upAps.reglesPreferes,{'id':upAps.upReglePrefere.reglePrefereId});
        }
        // si pas de regle sur l'unité de production, on prend la regle de l'article le moins cher
        if(this.utils.isNullOrEmpty(upAps.selectedReglePrefere)){
          upAps.selectedReglePrefere = _find(upAps.reglesPreferes,{'code':REGLES_PREFERES.MOINS_CHER});
        }
        // on pré-sélectionne la regle
        upAps.selectedReglePrefere = this.utils.preSelectSingleList(upAps.reglesPreferes,upAps.selectedReglePrefere);

      }
      upAps.idUniteDeProduction = idUniteDeProduction;
      return upAps;
    });
  }
}

export class UpArticlesPreferesSupplier {
  reglesPreferes: ReglePrefereDTO[] = [];
  selectedReglePrefere:ReglePrefereDTO;
  upReglePrefere:UniteDeProduction__ReglePrefereDTO;
  idUniteDeProduction: number;
}
