import {Injectable} from '@angular/core';
import {FilterItem} from '../technique/devextreme.service';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {UtilsService} from '../../utils/utils.service';
import {UtilisateurConnexionService} from './utilisateur.connexion.service';

@Injectable()
export class UtilisateurRoutemapService {

  constructor(private userConnexionSvc: UtilisateurConnexionService, private utils: UtilsService) {
  }

  /**
   * cf. fonction java : UtilisateurController#utilisateursSearch()
   * @param {string} endUrl
   * @returns {string}
   */
  getSearchUtilisateursLink(endUrl?: string) {
    return `dolrest/utilisateur/search?${endUrl}`;
  }

  /**
   * cf. fonction java : UtilisateurController#utilisateursConnexionsSearch()
   * @param {string} endUrl
   * @returns {string}
   */
  getSearchUtilisateursConnexionsLink(endUrl?: string) {
    const url = `dolrest/utilisateur/connexion/search?${endUrl}`;
    return url;
  }


  /**
   * cf. {@ link UtilisateurController#utilisateurById()}.
   * @param {number} idUtilisateur
   * @returns {string}
   */
  getUtilisateurFicheIdentiteLink(idUtilisateur: number): string {
    const url = `dolrest/utilisateur/utilisateur/${idUtilisateur}`;
    return url;
  }


  /**
   * cf. {@ link UtilisateurController#deleteone()}.
   * @param {number} idUtilisateur
   * @returns {string}
   */
  getDeleteUtilisateurSLink(idUtilisateur: number): string {
    const url = `dolrest/utilisateur/deleteone/${idUtilisateur}`;
    return url;
  }

  initGenericRequest(filters: FilterItem[], sorts: Sort[], page: number, pageSize: number): GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.userConnexionSvc.getEntityName());
    const rootPath = this.userConnexionSvc.getEntityName().toLowerCase();
    const search = new Search();
    search.predicats = [];

    if (!this.utils.isCollectionNullOrEmpty(filters)) {

      // Au cas où on aurait une date :
      const filterDate = filters.find(filter => filter.column === 'date');
      if (filterDate) {
        const predicat = new Predicat();
        predicat.path = `${rootPath}.date`;
        predicat.operator = PREDICAT_OPERATOR.Equals;
        predicat.type = PREDICAT_TYPE.Date;
        predicat.value = filterDate.value.split(' ')[0];

        search.predicats.push(predicat);
      }

      for (const filter of filters) {

        const predicat = new Predicat();

        switch (filter.column) {
          case 'utilisateurNom':
            predicat.path = `${rootPath}.utilisateur.nom`;
            predicat.operator = PREDICAT_OPERATOR.Contains;
            predicat.type = PREDICAT_TYPE.String;
            predicat.value = filter.value + '';
            search.predicats.push(predicat);
            break;
          case 'utilisateurPrenom':
            predicat.path = `${rootPath}.utilisateur.prenom`;
            predicat.operator = PREDICAT_OPERATOR.Contains;
            predicat.type = PREDICAT_TYPE.String;
            predicat.value = filter.value + '';
            search.predicats.push(predicat);
            break;
          case 'environnement.libelle':
            predicat.path = `${rootPath}.environnement.libelle`;
            predicat.operator = PREDICAT_OPERATOR.Contains;
            predicat.type = PREDICAT_TYPE.String;
            predicat.value = filter.value + '';
            search.predicats.push(predicat);
            break;
        }

      }

    }

    grs.search = search;

    grs.page = page;
    grs.size = pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case 'utilisateurNom':
            sort.path = `${rootPath}.utilisateur.nom`;
            break;
          case 'utilisateurPrenom':
            sort.path = `${rootPath}.utilisateur.prenom`;
            break;
          case 'environnement.libelle':
            sort.path = `${rootPath}.environnement.libelle`;
            break;
          case 'date':
            sort.path = `${rootPath}.date`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;
    return grs;
  }

}
