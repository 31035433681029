import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ResteDTO from '../../dtos/conditionnement/reste-dto';

export const URL_GET_ALL = `dolrest/conditionnements/restes/list`;
export const URL_SAVE = `dolrest/conditionnements/restes/save`;

@Injectable({
  providedIn: 'root'
})
export class RestesService {

  private subjectOpenDialogReste = new Subject<ResteDTO>();
  openDialogReste$ = this.subjectOpenDialogReste.asObservable();

  private subjectResteSaved = new Subject<ResteSavedSupplier>();
  resteSaved$ = this.subjectResteSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupère tous les restes
   * @returns {Observable<ResteDTO>}
   */
  getAll = (): Observable<ResponseWrapper<ResteDTO>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde un reste
   * @param reste
   */
  save = (reste: ResteDTO): Observable<ResponseWrapper<ResteDTO>> => this.httpSvc.post(URL_SAVE, reste);

  announceOpenDialogReste = (param: ResteDTO) => {
    this.subjectOpenDialogReste.next(param);
  };

  announceResteSaved = (reste: ResteDTO, isUpdate: boolean = false) => {
    this.subjectResteSaved.next({ isUpdate, reste } as ResteSavedSupplier);
  };

}

export interface ResteSavedSupplier {
  isUpdate: boolean;
  reste: ResteDTO;
}

