import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {TacheDTO} from "../../core/dtos/tache-dto";
import {TacheSavedSupplier, TachesService} from "../../core/services/taches/taches.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {saveAs} from "file-saver";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-grille-taches',
  templateUrl: './grille-taches.component.html',
  styleUrls: ['./grille-taches.component.scss']
})
export class GrilleTacheComponent implements OnInit, OnDestroy {

  tachesList: any[] = [];

  subTacheSaved: Subscription;

  subInitTasks: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/taches/taches';
  private subXls: Subscription;

  private subImport: Subscription;

  displayImportPopupErrors: boolean = false;

  errors: any[] = [];

  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private genericManageDialogService: GenericManageDialogService,
              private tachesSvc: TachesService,
              private readonly graphQlSvc: GraphQLService,
              private toastSvc: ToastService,
              private i8nSvc: InternationalizationService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always'
  }

  ngOnInit(): void {
    this.initTaches();
    this.tacheSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTacheSaved);
    this.utils.unsubscribe(this.subXls);
    this.utils.unsubscribe(this.subImport);
    this.utils.unsubscribe(this.subInitTasks);
  }

  initTaches = (): void => {
    this.subInitTasks = this.graphQlSvc.sendQuery(`
      {
        allTaches {
          id, libelle, code, actif,
          typeTache { id, libelle }
        }
      }
    `).subscribe(response => this.tachesList = response.allTaches);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.tachesList.find(t => t.id === idSelected))
        .map((t: TacheDTO) => t.id);
      this.tachesSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          if (o.one.errors) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, o.one.errors);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, o.one.errors);
          } else {
            this.tachesList = this.tachesList.filter(t => !idsToDelete.includes(t.id));
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToDelete.length > 1 ? `Les tâches ont bien été supprimées` : `La tâche a bien été supprimée`);
          }
        });
    }
  };

  tacheSavedSubscription = (): void => {
    this.subTacheSaved = this.tachesSvc.tacheSaved$
      .subscribe((response: TacheSavedSupplier) => {
        this.initTaches();
      });
  };

  open = (t?: TacheDTO): void => {
    this.tachesSvc.announceOpenDialogTache(t);
  };

  canDelete = (): boolean => this.selectedRows?.length > 0;

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
  }

  exportXslx() {
    this.subXls = this.tachesSvc.export(this.selectedRows)
      .subscribe(response => {
        const filename = `taches-export-${new Date().getTime()}.xlsx`;
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        this.selectedRows = [];
        saveAs(blob, filename);
      });
  }

  importXslx = ($event: any): void => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.subImport = this.tachesSvc.importFromXlsx(file)
        .subscribe((response) => {
          const res: any = response.one;
          if (res.allElementsImported) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          } else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }

          for (const item of res.elementsImported) {
            let index = this.tachesList.findIndex(atelier => atelier.id == item.id);
            if (index >= 0)
              this.tachesList[index] = item;
            else
              this.tachesList.push(item)
          }

          if (this.errors?.length)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

}
