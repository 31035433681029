<div class="datagrid-container">
  <dx-data-grid
    [dataSource]="denreesInterditesList"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(240)"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
    #grid>
    <dxi-column dataField="id" caption="Actions" [width]="100"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="denreeInterditeLibelle" caption="Déclinaison interdite"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="denreeSubstitutionLibelle" caption="Déclinaison de substitution"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="ratio" caption="Ratio" [width]="100"
                [allowFiltering]="true"
                [allowGrouping]="false"
                cellTemplate="ratioCellTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      [yoHiddenMode]="!gds.canModify(uniteDeProduction)"
                      yoTextAlign="center"
                      yoIconClass="fa fa-edit"
                      yoTooltip="Modifier la denrée interdite"
      ></yo-cell-button>
      <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      [yoHiddenMode]="!gds.canModify(uniteDeProduction)"
                      yoTextAlign="center"
                      yoIconClass="fa fa-trash"
                      yoTooltip="Supprimer la denrée interdite"
      ></yo-cell-button>
    </div>
    <div *dxTemplate="let cell of 'ratioCellTemplate'">
      {{ cell.value }} %
    </div>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>
  </dx-data-grid>

</div>


