import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdministrationComponent} from './administration.component';
import {
  FP_ROUTES,
  FS_ROUTES,
  HAS_ADMINISTRATION,
  HAS_GESTION_IDISTRI,
  HAS_GESTION_STOCKS,
  HAS_PMS,
  HAS_PORTAIL_ADMIN,
  OUTLET_SECONDARY
} from '../core/constants';
import {SitesComponent} from './sites/sites.component';
import {EnvironnementsComponent} from './environnements/environnements.component';
import {UtilisateursComponent} from './utilisateurs/utilisateurs.component';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {FicheidentiteSiteComponent} from './sites/ficheidentite-site/ficheidentite-site.component';
import {FeaturesComponent} from './features/features.component';
import {SiteResolverService} from './site-resolver.service';
import {EnvironnementResolverService} from './environnement-resolver.service';
import {FicheidentiteEnvironnementComponent} from './environnements/ficheidentite-environnement/ficheidentite-environnement.component';
import {ContratMenuService} from '../core/services/gestioncontrats/contrat-menu.service';
import {ConvivesComponent} from './convives/convives.component';
import {FamillesGemrcnComponent} from './familles-gemrcn/familles-gemrcn.component';
import {RepasComponent} from './repas/repas.component';
import {DecoupagesRepasComponent} from './decoupages-repas/decoupages-repas.component';
import {TypesProduitComponent} from './types-produit/types-produit.component';
import {FamillesProduitComponent} from './familles-produit/familles-produit.component';
import {AllergenesComponent} from './allergenes/allergenes.component';
import {FicheidentiteUtilisateurComponent} from './utilisateurs/ficheidentite-utilisateur/ficheidentite-utilisateur.component';
import {UtilisateurResolverService} from './utilisateur-resolver.service';
import {DeclinaisonsComponent} from './declinaisons/declinaisons.component';
import {UnitesDeMesuresComponent} from './unites-de-mesures/unites-de-mesures.component';
import {EquipesComponent} from './equipes/equipes.component';
import {AtelierComponent} from './ateliers/atelier.component';
import {ZonesDeStockageComponent} from './zones-de-stockage/zones-de-stockage.component';
import {SecteursComponent} from './secteurs/secteurs.component';
import {TaxesComponent} from './taxes/taxes.component';
import {AppellationsComponent} from './appellations/appellations.component';
import {ParametresComponent} from './parametres/parametres.component';
import {LitigesComponent} from './litiges/litiges.component';
import {MotifsNonConformiteComponent} from './motifs-non-conformite/motifs-non-conformite.component';
import {ProfilsComponent} from './profils/profils.component';
import {ProfilsResolverService} from './profils/profils-resolver.service';
import {RegimesAlimentairesComponent} from './regimes-alimentaires/regimes-alimentaires.component';
import {UtilisateursConnexionsComponent} from './utilisateurs-connexions/utilisateurs-connexions.component';
import {OrigineGridComponent} from "./origines/origine-grid.component";
import {GrilleStocksMouvementsStatutComponent} from "./stocks/statuts/grille-statuts/grille-statuts.component";

import {GrilleConditionnementsComponent} from './conditionnements/grille-conditionnements.component';
import {VariantesComponent} from './conditionnements/variantes/grille-variantes/variantes.component';
import {ConditionnementsVariantesComponent} from "./conditionnements/conditionnements-variantes/grille/conditionnements-variantes.component";
import {GrilleTacheComponent} from "./taches/grille-taches.component";
import {PmsUniteGrilleComponent} from "./pms/unite/grille/pms-unite-grille.component";
import {PmsSeuilGrilleComponent} from "./pms/seuil/grille/pms-seuil-grille.component";
import {PmsEquipementGrilleComponent} from "./pms/equipement/grille/pms-equipement-grille.component";
import {PmsZoneANettoyerGrilleComponent} from "./pms/zone-a-nettoyer/grille/pms-zone-a-nettoyer-grille.component";
import {PmsEquipementANettoyerGrilleComponent} from "./pms/equipement-a-nettoyer/grille/pms-equipement-a-nettoyer-grille.component";
import {PmsSignatureUtilisateurGrilleComponent} from "./pms/signature-utilisateur/grille/pms-signature-utilisateur-grille.component";
import {PmsTypeUtilisateurGrilleComponent} from "./pms/type-utilisateur/grille/pms-type-utilisateur-grille.component";
import {GridEnvironnementPlcComponent} from "./portail/environnement-plc/grid/grid-environnement-plc.component";
import {SidebarEnvironnementPlcComponent} from "./portail/environnement-plc/sidebar/sidebar-environnement-plc.component";
import {SidebarEnvironnementPlcResolverService} from "./portail/environnement-plc/sidebar/sidebar-environnement-plc-resolver.service";
import {PmsTypeTacheGrilleComponent} from "./pms/type-tache/grille/pms-type-tache-grille.component";
import {PmsTypeEquipementGrilleComponent} from "./pms/type-equipement/grille/pms-type-equipement-grille.component";
import {PmsTacheGrilleComponent} from "./pms/tache/grille/pms-tache-grille.component";
import {ConfigurationPortailPlcGridComponent} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-grid.component";
import {ConfigurationPortailPlcGridResolverService} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-grid-resolver.service";
import {GestionAdministrationComponent} from "./gestion-administration/gestion-administration.component";
import {TourneesComponent} from "./tournees/tournees.component";

const routes: Routes = [{
    path: FP_ROUTES.ADM,
    component: AdministrationComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    data: HAS_ADMINISTRATION || HAS_PORTAIL_ADMIN,
  },
    {
      // path: 'ficheidentite/:idSite',
      path: `${FS_ROUTES.ADM_SITE_FIC}/:idSite`,
      component: FicheidentiteSiteComponent,
      data: HAS_ADMINISTRATION,
      resolve: {
        siteSupplier: SiteResolverService
      },
      outlet: OUTLET_SECONDARY
    },
    {
      //  'ficheidentite/:idEnvironnement',
      path: `${FS_ROUTES.ADM_ENVIRONNEMENT_FIC}/:idEnvironnement`,
      component: FicheidentiteEnvironnementComponent,
      data: HAS_ADMINISTRATION,
      resolve: {
        environnementSupplier: EnvironnementResolverService
      },
      outlet: OUTLET_SECONDARY
    },
    {
      //  'sidebar-environnement-plc/:idEnvironnement',
      path: `${FS_ROUTES.ADM_ENVIRONNEMENT_PLC_FIC}/:idEnvironnementPlc`,
      component: SidebarEnvironnementPlcComponent,
      data: HAS_PORTAIL_ADMIN,
      // data: HAS_ADMINISTRATION,
      resolve: {
        sidebarEnvironnementPlcSupplier: SidebarEnvironnementPlcResolverService
      },
      outlet: OUTLET_SECONDARY
    },
    {
      // path: 'ficheidentite/:idUtilisateur',
      path: `${FS_ROUTES.ADM_UTILISATEUR_FIC}/:idUtilisateur`,
      component: FicheidentiteUtilisateurComponent,
      data: HAS_ADMINISTRATION,
      resolve: {
        utilisateurSupplier: UtilisateurResolverService
      },
      outlet: OUTLET_SECONDARY
    },
    {
      path: 'administration/features',
      component: FeaturesComponent,
      runGuardsAndResolvers: 'always',
      canActivate: [Auth2Guard],
      canActivateChild: [Auth2Guard],
      data: HAS_ADMINISTRATION || HAS_PORTAIL_ADMIN,
      children: [
        {
          path: 'environnements',
          component: EnvironnementsComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'grid-environnement-plc',
          component: GridEnvironnementPlcComponent,
          data: HAS_PORTAIL_ADMIN
        },
        {
          path: 'sites',
          component: SitesComponent,
          data: HAS_ADMINISTRATION,
        },
        {
          path: 'utilisateurs',
          component: UtilisateursComponent,
          data: HAS_ADMINISTRATION
        },

        {
          path: 'utilisateursconnexions',
          component: UtilisateursConnexionsComponent,
          data: HAS_ADMINISTRATION
        },

        {
          path: 'convives',
          component: ConvivesComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'familles-gemrcn',
          component: FamillesGemrcnComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'familles-produit',
          component: FamillesProduitComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'repas',
          component: RepasComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'origines',
          component: OrigineGridComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'decoupages-repas',
          component: DecoupagesRepasComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'types-produit',
          component: TypesProduitComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'allergenes',
          component: AllergenesComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'declinaisons',
          component: DeclinaisonsComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'unites-de-mesures',
          component: UnitesDeMesuresComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'ateliers',
          component: AtelierComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'zones-de-stockage',
          component: ZonesDeStockageComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'equipes',
          component: EquipesComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'secteurs',
          component: SecteursComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'taxes',
          component: TaxesComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'appellations',
          component: AppellationsComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'parametres',
          component: ParametresComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'litiges',
          component: LitigesComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'motifs-non-conformite',
          component: MotifsNonConformiteComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'profils',
          component: ProfilsComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION,
          resolve: {
            profilSupplier: ProfilsResolverService
          }
        },
        {
          path: 'regimes-alimentaires',
          component: RegimesAlimentairesComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION,
        },
        {
          path: 'stocks-mouvements-statuts',
          component: GrilleStocksMouvementsStatutComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_STOCKS,
        },
        {
          path: 'conditionnements',
          component: GrilleConditionnementsComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI
        },
        {
          path: 'variantes',
          component: VariantesComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI
        },
        {
          path: 'conditionnements-variantes',
          component: ConditionnementsVariantesComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI
        },
        {
          path: 'taches',
          component: GrilleTacheComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'unites-pms',
          component: PmsUniteGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'seuils-pms',
          component: PmsSeuilGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'equipements-pms',
          component: PmsEquipementGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'zones-pms',
          component: PmsZoneANettoyerGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'equipements-a-nettoyer-pms',
          component: PmsEquipementANettoyerGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'signatures-utilisateur-pms',
          component: PmsSignatureUtilisateurGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'types-utilisateur-pms',
          component: PmsTypeUtilisateurGrilleComponent,
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'types-tache-pms',
          component: PmsTypeTacheGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'types-equipement-pms',
          component: PmsTypeEquipementGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'taches-pms',
          component: PmsTacheGrilleComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION && HAS_PMS
        },
        {
          path: 'configuration-portail-plc',
          component: ConfigurationPortailPlcGridComponent,
          runGuardsAndResolvers: 'always',
          data: HAS_ADMINISTRATION,// && HAS_PORTAIL_ADMIN,
          resolve: {
            configurationPortailPlcGridSupplier: ConfigurationPortailPlcGridResolverService
          }
        },
        {
          path: 'gestion-administration',
          component: GestionAdministrationComponent,
          data: HAS_ADMINISTRATION
        },
        {
          path: 'tournees',
          component: TourneesComponent,
          data: HAS_ADMINISTRATION
        },
      ]
    },
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SiteResolverService, EnvironnementResolverService, ContratMenuService]
})
export class AdministrationRoutingModule {
}
