import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {GroupeAchatDTO} from "../../../core/dtos/unite-de-production/groupe-achat-dto";
import {GroupeAchatService} from "../../../core/services/gestion-fournisseurs/groupe-achat.service";

@Injectable({
  providedIn: 'root'
})
export class GridGroupeAchatResolverService implements Resolve<GridGroupeAchatSupplier> {

  constructor(private groupeAchatSvc: GroupeAchatService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GridGroupeAchatSupplier> | Promise<GridGroupeAchatSupplier> | GridGroupeAchatSupplier {

    let gridGroupeAchatSupplier: GridGroupeAchatSupplier = new GridGroupeAchatSupplier();

    const groupeAchatList$ = this.groupeAchatSvc.getGroupeAchatList();

    const all$ = combineLatest([groupeAchatList$]);

    return all$.toPromise().then(data => {
      gridGroupeAchatSupplier.groupeAchatList = data[0].resultList;
      return gridGroupeAchatSupplier;
    });
  }
}

export class GridGroupeAchatSupplier {
  groupeAchatList: GroupeAchatDTO[] = [];
}
