import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {GestionLotMarcheService} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';

@Injectable({
  providedIn: 'root'
})
export class EngagementLotMarcheResolverService implements Resolve<EngagementLotMarcheSupplier> {

  constructor(private gestionLotMarcheSvc: GestionLotMarcheService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EngagementLotMarcheSupplier> | Promise<EngagementLotMarcheSupplier> | EngagementLotMarcheSupplier => {
    let engagementLotMarcheSupplier: EngagementLotMarcheSupplier = new EngagementLotMarcheSupplier();
    let idLotMarche: number = +route.paramMap.get('idLotMarche');

    if (idLotMarche > 0) {
      const lotMarche$ = this.gestionLotMarcheSvc.getById(idLotMarche);
      const lmUdpCaList$ = this.gestionLotMarcheSvc.initLmupCaList(idLotMarche);

      const all$ = combineLatest([lotMarche$, lmUdpCaList$]);
      return all$.toPromise().then(data => {
        engagementLotMarcheSupplier.lotMarche = data[0].one;
        return engagementLotMarcheSupplier;
      });
    }
  };
}

export class EngagementLotMarcheSupplier {
  lotMarche: LotMarcheDTO;
}
