import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-gestion-conditionnements-root',
  templateUrl: './gestionconditionnements.component.html',
  styleUrls: ['./gestionconditionnements.component.scss']
})
export class GestionConditionnementsRootComponent implements OnInit {

  ngOnInit(): void {
  }

}
