import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {ProcessLockedEnum} from "../../enums/process-locked-enum";
import {ProgressStatusDTO} from "../../dtos/progress/progress-status-dto";

export const URL_PROGRESS_BAR = 'dolrest/progress-status';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  constructor(private httpSvc: HttpService) {}

  getById(id: number): Observable<ResponseWrapper<ProgressStatusDTO>> {
    return this.httpSvc.get(`${URL_PROGRESS_BAR}?id=${id}`, null);
  }

  startProgressStatus(processWatched: ProcessLockedEnum, comment: string = '') {
    const progress: ProgressStatusDTO = { progression: 0, processWatched, comment } as ProgressStatusDTO;
    return this.httpSvc.post(URL_PROGRESS_BAR, progress, null);
  }

  deleteProgressStatus(id: number) {
    return this.httpSvc.delete(`${URL_PROGRESS_BAR}?id=${id}`, null);
  }

}
