<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Fin de la configuration</h1>
    <div class="item-wizard">
      <p>
        La configuration de votre projet de conditionnement est terminé. Vous pouvez démarrer une nouvelle configuration qui vous
        permettra de démarrer sur un nouveau modèle de conditionnement de plats et sur un nouveau modèle de conditionnement de
        points de livraison clients. Dans le cas où vous souhaitez démarrer une nouvelle configuration uniquement pour un
        nouveau modèle de conditionnement de points de livraison clients tout en conservant le même modèle de conditionnement
        de plats, cliquez sur le bouton "Nouveau modèle pour points de livraison clients". Etant donné que les conditionnements
        se réalisent à partir d'un plan de production, il vous est possible d'enchaîner avec la sélection ou la création d'un plan de production
        en cliquant sur le bouton "Continuer sur un plan de production".
      </p>
      <p>Récapitulatif : </p>
      <div id="resume">
        <p>Libellé du modèle de conditionnement plats : <b>{{ dataModelePlat().labelModelPlat }}</b> </p>
        <p>Site responsable du modèle de conditionnement plats : <b>{{ dataModelePlat().siteSelected }}</b> </p>
        <p>Unité(s) de production appliquant le modèle de conditionnement plats : <b>{{ dataModelePlat().unitesDeProductionSelected }}</b> </p>
        <p>Mode utilisé par le modèle de conditionnement plats : <b>{{ dataModelePlat().modeModelePlat }}</b> </p>
        <p>Unité de conditionnement : <b>{{ dataModelePlat().uniteConditionnement }}</b></p>
        <p>Déclinaison(s) sélectionnée(s) : <b>{{ listDeclinaisons() }}</b></p>
        <p>Plat(s) sélectionné(s) pour votre projet de conditionnement accompagnés respectivement du poids net de leur(s) fiche(s) technique(s) : </p>
        <table class="mg-b-15 recap-plats">
          <thead>
            <tr>
              <th>Plat</th>
              <th *ngFor="let decli of declinaisons">{{ decli.libelle }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let produit of produits">
              <td>{{ produit.libelle }}</td>
              <th *ngFor="let decli of declinaisons">{{ findTotalNetUT(produit.id, decli.id) }} g</th>
            </tr>
          </tbody>
        </table>
        <p>Libellé du modèle de conditionnement points de livraison clients : <b>{{ dataModelePlc().labelModelPlc }}</b></p>
        <p>Site responsable du modèle de conditionnement points de livraison clients : <b>{{ dataModelePlc().siteSelected }}</b></p>
        <p>Unité(s) de production appliquant le modèle de conditionnement points de livraison clients : <b>{{ dataModelePlc().prestationsSelected }}</b></p>
        <p>Point(s) de livraison sélectionné(s) pour votre projet de conditionnement : <b>{{ dataModelePlc().plcsSelected }}</b></p>
        <p>Configuration des équipes : <b>{{ dataModelePlc().config }}</b></p>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 13/13 : Fin de la configuration - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Nouvelle configuration"
            class="mg-r-5"
            type="button"
            (click)="reset()">
      Nouvelle configuration
    </button>
    <button  title="Nouveau modèle pour points de livraison clients"
             class="mg-r-5"
             type="button"
             (click)="resetOnlyPlc()">
      Nouveau modèle pour points de livraison clients
    </button>
    <button  title="Continuer sur un plan de production"
             class="mg-r-5"
             type="button"
             (click)="redirectToProduction()">
      Continuer sur un plan de production
    </button>
    <button  title="Quitter l'assistant"
             class="mg-r-5"
             type="button"
             (click)="closeDialog()">
      Quitter l'assistant
    </button>
  </div>
</div>
