import {SiteDTO} from "./site-dto";
import {RoundPeriodsDto} from "./round-periods-dto";
import {InformationsByDeliveryDayDto} from "./informations-by-delivery-day-dto";

export class OrganisationTourneeInfoDto {

  id: number;

  site: SiteDTO;

  label: string;

  code: string;

  periods: RoundPeriodsDto[] = [];

  informationsByDeliveryDay: InformationsByDeliveryDayDto = new InformationsByDeliveryDayDto();

}
