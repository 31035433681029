import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../core/utils/utils.service';
import {CelluleTableauDTO} from '../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {USER_PREFERENCE} from '../../core/constants';

@Component({
  selector: 'yo-cout-decoupage-pondere',
  templateUrl: './cout-decoupage-pondere.component.html',
  styleUrls: ['./cout-decoupage-pondere.component.scss']
})
export class CoutDecoupagePondereComponent implements OnInit {

  subDisplay: Subscription;

  displayCoutsUnitairesPonderes: boolean = false;

  @Input() cellule: CelluleTableauDTO;

  constructor(private cd: ChangeDetectorRef,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService) {
  }

  ngOnInit() {
   this.subscriptionDisplayCout();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplay);
  }
  subscriptionDisplayCout = () => {
    this.subDisplay = this.prefsUserSvc.preferencesOfUser$.subscribe(item => {
      this.displayCoutsUnitairesPonderes = this.prefsUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES);
      this.cd.markForCheck();
    });
  };

  display = () => {
    if(this.displayCoutsUnitairesPonderes){
      if(Object.keys(this.cellule.mapMenuComposition).length>0){
        return true;
      }
    }
    return false;
  };
}
