import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormFieldBaseSupplier} from "../../../../../core/suppliers/form-fieldbase-supplier";

@Component({
  selector: 'yo-dynamic-form-field-error-bs',
  templateUrl: './dynamic-form-field-error-bs.component.html',
  styleUrls: ['./dynamic-form-field-error-bs.component.scss']
})
export class DynamicFormFieldErrorBsComponent implements OnInit {

  @Input() field: FormFieldBaseSupplier<any>;
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {

  }

  startError(field:FormFieldBaseSupplier<any>):string{
    return  `Le champ "${field.label.toUpperCase()}" `;
  }

}
