import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {PlcRepas} from '../menu-plc-resolver.service';
import {MPlcRepasRow} from '../../../core/models/plc-repas-row';
import {MenucompositionPlcService} from '../../../core/services/entities/menucomposition-plc.service';
import {MenuComposition__PlcDTO} from '../../../core/dtos/menucomposition-plc-dto';
import {catchError, debounceTime, filter, map, switchMap} from 'rxjs/operators';
import {Menu} from 'primeng/menu';
import {
  DialogRecherchePlatsSupplier,
  MenusPlanning2Service
} from '../../../core/services/gestionmenus/menus-planning2.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {cloneDeep as _cloneDeep} from 'lodash'
import {SEARCH_PLATS_TYPE_DIALOG, STATUTS_MENUS, USER_PREFERENCE} from '../../../core/constants';
import {PreferencesUtilisateurService} from '../../../core/services/preferences-utilisateur.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';

@Component({
  selector: 'yo-repas',
  templateUrl: './repas.component.html',
  styleUrls: ['./repas.component.scss']
})
export class RepasComponent implements OnInit, OnDestroy {

  subPlcRepas: Subscription;
  subTypeEffectif: Subscription;
  subChangeEffectifPlat: Subscription;
  subChangeTdpPlat: Subscription;
  subAddPlat: Subscription;

  plcRepas: PlcRepas;
  selectedPlat: MenuComposition__PlcDTO;
  selectedRow: MPlcRepasRow;

  infoPlcRepas: MPlcRepasRow;

  STATUTS_MENUS = STATUTS_MENUS;

  // 1 = effectif previsionnel, 2=effectif fabrication, 3 = effectif facturé
  typeEffectif = 1;
  actions: MenuItem[] = [];


  constructor(public utils: UtilsService,
              private plcSvc: PointDeLivraisonService,
              private prefUsrSvc: PreferencesUtilisateurService,
              private mp2Svc: MenusPlanning2Service,
              private mcPlcSvc: MenucompositionPlcService,
              private pdSvc: ProduitDeclinaisonService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit() {
    this.typeEffectifSubscription();
    this.plcRepasSubscription();
    // ajouter le plat sélectionné par la recherche
    this.addPlatSubscription();
    this.changeEffectifPlatSubscription();
    this.changeTdpPlatSubscription();
  }

  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.plcSvc.typeEffectif$.subscribe(response => this.typeEffectif = response);
  };

  plcRepasSubscription = () => {
    this.subPlcRepas = this.plcSvc.plcRepas$
      .subscribe(response => {
      this.plcRepas = response;
      this.infoPlcRepas = !this.utils.isNullOrEmpty(this.plcRepas) && !this.utils.isCollectionNullOrEmpty(this.plcRepas.rows) ? this.plcRepas.rows[0] : null;

    });
  };

  addPlatSubscription = () => {
    this.subAddPlat = this.mcPlcSvc.addProduitDeclinaisonFromSearch$.pipe(
      map(response => this.mcPlcSvc.createMcPlc(response)),
      switchMap(response => this.mcPlcSvc.save(response))
    )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.pdSvc.displayDialogRecherchePlats = false;
          this.selectedRow.plat = response.one;
          this.plcRepas.rows = _cloneDeep(this.plcRepas.rows);
        }
      });
  };

  changeEffectifPlatSubscription = () => {
    this.subChangeEffectifPlat = this.mcPlcSvc.changeEffectif$
      .pipe(
        debounceTime(300),
        filter(response => !this.utils.isNullOrEmpty(response.effectifPrevisionnel) && !this.utils.isNullOrEmpty(response.effectifFabrication) && !this.utils.isNullOrEmpty(response.effectifFacture)),
        switchMap(response => this.mcPlcSvc.saveEffectifPlatPlc(response, this.typeEffectif)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.plcRepas.rows = this.updateRows(response.one, this.plcRepas.rows);
        }
      });
  };

  changeTdpPlatSubscription = () => {
    this.subChangeTdpPlat = this.mcPlcSvc.changeTdp$
      .pipe(
        debounceTime(300),
        filter(response => !this.utils.isNullOrEmpty(response.tpEffectifPrevisionnel) && !this.utils.isNullOrEmpty(response.tpEffectifFabrication) && !this.utils.isNullOrEmpty(response.tpEffectifFacture)),
        switchMap(response => this.mcPlcSvc.saveTauxDePrisePlatPlc(response, this.typeEffectif)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.plcRepas.rows = this.updateRows(response.one, this.plcRepas.rows);
        }
      });
  };


  updateRows = (plat: MenuComposition__PlcDTO, rows: MPlcRepasRow[]): MPlcRepasRow[] => {

    const newRows = [];

    for (const row of rows) {
      if (!this.utils.isNullOrEmpty(row.plat)
        && row.plat.id === plat.id) {
        row.plat = plat;
      }
      newRows.push(row);
    }
    return newRows;
  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPlcRepas);
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subChangeEffectifPlat);
    this.utils.unsubscribe(this.subChangeTdpPlat);
    this.utils.unsubscribe(this.subAddPlat);
  }

  changeEffectif = ($event: KeyboardEvent, plat: MenuComposition__PlcDTO, propertyName: string) => {
    this.utils.keydownNumber($event, plat, propertyName, true);
    this.mcPlcSvc.announceChangeEffectifPlat(plat);
  };

  changeTauxDePrise = ($event: KeyboardEvent, plat: MenuComposition__PlcDTO, propertyName: string) => {
    this.utils.keydownNumber($event, plat, propertyName, true);
    this.mcPlcSvc.announceChangeTdpPlat(plat);
  };

  inputsForSearch = (rowData: MPlcRepasRow): DialogRecherchePlatsSupplier => {

    const inputsSearch = new DialogRecherchePlatsSupplier();
    inputsSearch.cmcd = rowData.cmcd;
    inputsSearch.dateMenu = rowData.dateMenu;
    inputsSearch.decoupageRepas = rowData.decoupageRepas;
    inputsSearch.repas = rowData.repas;
    inputsSearch.choixMax = rowData.cmcd.nombreChoixMaximum;
    inputsSearch.maxItemsPanier = 1;
    inputsSearch.cmcContrainteAlim = rowData.cmcContrainteAlim;
    inputsSearch.prestation = rowData.prestation;
    // 1 = menus compositions, 2 = menus compositions plc
    inputsSearch.typeDialog = SEARCH_PLATS_TYPE_DIALOG.PLC;
    inputsSearch.sourceObject = rowData;
    inputsSearch.searchMode = this.prefUsrSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_RECHERCHE_MODE);
    inputsSearch.regimeAlimentaire = this.infoPlcRepas.cmcContrainteAlim.regimeLibelle;

    return inputsSearch;

  };

  chooseAction = (menuActions: Menu, $event, rowData: MPlcRepasRow) => {

    this.selectedRow = rowData;

    const modifPlat = {
      label: 'Modifier le plat',
      icon: 'fas fa-utensils',
      disabled: this.isInputDisabled(rowData),
      command: (event) => {
        const inputs = this.inputsForSearch(rowData);
        this.mp2Svc.announceInputRecherchePlats(inputs);
      }
    };

    const addPlat = {
      label: 'Ajouter un plat',
      icon: 'fas fa-utensils',
      disabled: this.isInputDisabled(rowData),
      command: (event) => {
        const inputs = this.inputsForSearch(rowData);
        this.mp2Svc.announceInputRecherchePlats(inputs);

      }
    };

    const removePlat = {
      label: 'Enlever le plat',
      icon: 'fas fa-trash',
      disabled: this.isInputDisabled(rowData),
      command: (event) => {
        this.mcPlcSvc.removePlat(rowData.plat.id).subscribe(response => this.plcRepas.rows = this.updateRows(response.one, this.plcRepas.rows));

      }
    };

    const reinitPlat = {
      label: 'Réinitialiser le plat',
      icon: 'fas fa-redo',
      disabled: this.isInputDisabled(rowData),
      command: (event) => {
        this.mcPlcSvc.reinitPlat(rowData.plat.id).subscribe(response => this.plcRepas.rows = this.updateRows(response.one, this.plcRepas.rows));
      }
    };

    // si plat existe, on peut modifier / supprimer / reinitialiser
    if (!this.utils.isNullOrEmpty(rowData) && !this.utils.isNullOrEmpty(rowData.plat)) {
      if (rowData.plat.actif) {
        this.actions = [modifPlat, removePlat, reinitPlat];
      } else {
        this.actions = [reinitPlat];
      }
    }
    // si plat n'existe pas
    else {
      this.actions = [addPlat];
    }
    menuActions.show($event);
  };

  getHistoLabel = (plat: MenuComposition__PlcDTO) => {
    if (!this.utils.isNullOrEmpty(plat)) {
      const dateCreation = this.utils.convertNumberDateToDate(plat.dateCreation);
      const dateDerniereModification = this.utils.convertNumberDateToDate(plat.dateDerniereModification);
      return `Créé le ${this.utils.getFrenchDateHHMM(dateCreation.getTime())} par ${plat.creePar}.<br><br>Dernière modification le ${this.utils.getFrenchDateHHMM(dateDerniereModification.getTime())} par ${plat.modifiePar}.`;
    }
  };

  isPlatActif = (rowData: MPlcRepasRow) => {
    if (this.utils.isNullOrEmpty(rowData.plat)) {
      return false;
    } else {
      return rowData.plat.actif;
    }
  };

  isInputDisabled = (row: MPlcRepasRow) => {
    if (this.auth2Svc.isSiteLocal(row.prestation.contratMenuSiteId)) {
      return false;
    } else {
      return true;
    }
  };

  canDisplayGrid = () => {
    if (!this.utils.isNullOrEmpty(this.plcRepas)
      && !this.utils.isCollectionNullOrEmpty(this.plcRepas.rows)
    ) {
      return true;
    }
    return false;
  };

}
