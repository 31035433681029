import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ClientDTO} from '../../core/dtos/client-dto';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ClientsService} from '../../core/services/entities/clients.service';
import {UtilsService} from '../../core/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsResolverService implements Resolve<ClientsSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private clientsSvc: ClientsService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientsSupplier> | Promise<ClientsSupplier> | ClientsSupplier => {

    const cs = new ClientsSupplier();

    const clients$ = this.gds.getAll(this.clientsSvc.getEntityName(), this.clientsSvc.getSort(), true);

    const all$ = combineLatest([clients$]);

    return all$.toPromise().then(response => {
      cs.clients = response[0].resultList;
      return cs;
    });

  };
}


export class ClientsSupplier {

  clients: ClientDTO[];
}
