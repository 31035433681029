<h5>
  <div *ngIf="client?.id !== 0">
    <span *ngIf="client.site && client.site.id" class="space">
    <label class="badge badge-secondary">
      <yo-site [siteId]="client?.site?.id" [siteLibelle]="client?.site?.libelle"></yo-site>
    </label>
    </span>

    <label class="badge badge-secondary mg-r-20">
      <i class="fas fa-handshake mg-r-5"></i>
      <yo-viewmode
        [yoLabelFab]="'Client'" [libelle]="client?.libelle"
        [siteId]="client?.site?.id">
      </yo-viewmode>
    </label>
  </div>
  <div *ngIf="client?.id === 0">
    <label class="badge badge-secondary">
      <i class="fas fa-handshake"></i>
      <span class="mg-l-5">Création d'un client</span>
    </label>
  </div>
</h5>
<p-tabMenu [model]="navLinks" [activeItem]="selectedNavLink"></p-tabMenu>
<div>
  <router-outlet></router-outlet>
</div>
