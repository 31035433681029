import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FS_ROUTES, HAS_GESTIONMENU, OUTLET_SECONDARY} from '../core/constants';
import {GestionmenusRootComponent} from './gestionmenus-root/gestionmenus-root.component';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {GestionmenusResolverService} from './gestionmenus-resolver.service';
import {MenusSettingsComponent} from './menus-settings/menus-settings.component';
import {InfosMenucompoComponent} from './infos-menucompo/infos-menucompo.component';
import {GemrcnComponent} from './analyse/gemrcn/gemrcn.component';
import {GemrcnResolverService} from './analyse/gemrcn/gemrcn-resolver.service';
import {CoutsComponent} from './analyse/couts/couts.component';
import {MenusEgalimComponent} from "./analyse/egalim/menus-egalim.component";


const routes: Routes = [
  {
    path: 'gestionmenus',
    component: GestionmenusRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers:'always',
    data: HAS_GESTIONMENU,
    resolve: {
      gestionmenusSupplier: GestionmenusResolverService
    }
  },
  {
    path: `${FS_ROUTES.GME_SETTINGS}`,
    component: MenusSettingsComponent,
    data:HAS_GESTIONMENU,
    outlet: OUTLET_SECONDARY
  }, {
    path: `${FS_ROUTES.GME_INFOS_MENU}`,
    component: InfosMenucompoComponent,
    data: HAS_GESTIONMENU,
    outlet: OUTLET_SECONDARY
  }
  , {
    path: `${FS_ROUTES.GME_GEMRCN}`,
    component: GemrcnComponent,
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONMENU,
    resolve: {
      gemrcnSupplier: GemrcnResolverService
    },
    outlet: OUTLET_SECONDARY
  },
  {
    path: `${FS_ROUTES.GME_COUTS}`,
    component: CoutsComponent,
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONMENU,
    outlet: OUTLET_SECONDARY
  },
  {
    path: `${FS_ROUTES.GME_EGALIM}`,
    component: MenusEgalimComponent,
    runGuardsAndResolvers: 'always',
    data: HAS_GESTIONMENU,
    outlet: OUTLET_SECONDARY
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ ]
})
export class GestionmenusRoutingModule {
}
