import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {ActivatedRoute} from '@angular/router';
import {CmcPlcSupplier} from '../cmc-points-de-livraison/cmc-points-de-livraison-resolver.service';
import {Subscription} from 'rxjs';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {CmcPlcService} from '../../../core/services/entities/cmc-plc.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {find, sortBy, union} from 'lodash';
import {MenuItem} from 'primeng/api';
import {RoutemapService} from '../../../core/services/routemap.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {RepasService} from '../../../core/services/entities/repas.service';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {GcomPointDeLivraisonService} from '../../../core/services/gcom/gcom-point-de-livraison.service';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-cmc-point-de-livraison-gcom',
  templateUrl: './cmc-point-de-livraison-gcom.component.html',
  styleUrls: ['./cmc-point-de-livraison-gcom.component.scss']
})
export class CmcPointsDeLivraisonGcomComponent implements OnInit, OnDestroy {
  subscriptionRoute: Subscription;

  contratMenuConvive: ContratMenuConviveDTO;
  pointsDeLivraisonListUsed: PointDeLivraisonDTO[] = [];
  selectedPointDeLivraison: PointDeLivraisonDTO;
  pointsDeLivraisonForSelection: PointDeLivraisonDTO[] = [];
  pointsDeLivraisonToAdd: PointDeLivraisonDTO[] = [];
  selectedPointsDeLivraisonToAdd: PointDeLivraisonDTO[] = [];
  selectedPointsDeLivraisonToRemove: PointDeLivraisonDTO[] = [];
  pointsDeLivraisonToRemove: PointDeLivraisonDTO[] = [];
  displayPlcRemoveDialog: boolean = false;
  displayPlcAddDialog: boolean = false;

  repasList: RepasDTO[] = [];
  repasToAdd: RepasDTO[] = [];
  repasListUsed: RepasDTO[] = [];
  selectedRepasToAdd: RepasDTO[] = [];
  repasToRemove: RepasDTO[] = [];
  selectedRepasToRemove: RepasDTO[] = [];
  displayRepasRemoveDialog: boolean = false;
  displayRepasAddDialog: boolean = false;

  activeRepas: MenuItem;
  tabMenuRepasList: MenuItem[] = [];

  constructor(
    public utils: UtilsService,
    public cmcPlcService: CmcPlcService,
    private route: ActivatedRoute,
    public pointDeLivraisonSvc: PointDeLivraisonService,
    private routeMapSvc: RoutemapService,
    private gds: GenericDatagridService,
    private repasSvc: RepasService,
    private gcomPlcService: GcomPointDeLivraisonService,
    private toastSvc: ToastService,
    private graphqlSvc: GraphQLService,
    private auth2Svc: Auth2Service) {
  }

  ngOnInit(): void {
    this.initPointsDeLivraisonForSelection();
    this.initRouteData();
    this.initRepasList();
  }

  ngOnDestroy(): void {
    this.subscriptionRoute.unsubscribe();
  }

  initRouteData = () => {
    this.subscriptionRoute = this.route.data.subscribe((data: { cmcPlcSupplier: CmcPlcSupplier }) => {
      this.pointsDeLivraisonListUsed = data.cmcPlcSupplier.pointsDeLivraison;
      this.contratMenuConvive = data.cmcPlcSupplier.contratMenuConvive;
      // selection du point de livraison par defaut par defaut
      if (this.pointsDeLivraisonListUsed.length > 0) {
        this.selectedPointDeLivraison = this.pointsDeLivraisonListUsed[0];
        this.getRepasListUsed();
      } else {
        this.selectedPointDeLivraison = new PointDeLivraisonDTO();
      }
    });
  };

  initPointsDeLivraisonForSelection() {
    this.pointDeLivraisonSvc.getPlcList().subscribe(response => {
      this.pointsDeLivraisonForSelection = response.resultList;
    });
  };

  initRepasList = () => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.graphqlSvc.sendQuery(`
      {
          allRepas(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle,
              site { id, libelle },
              code,
              ordre,
              actif,
          }
      }
    `).subscribe(response => {
      this.repasList = response.allRepas;
    });
  };

  loadRepasCatalogue = () => {
    this.getRepasListUsed();
  };

  detachSelectedPointsDeLivraison = async (event: any) => {
    if (!this.utils.isCollectionNullOrEmpty(this.selectedPointsDeLivraisonToRemove)) {
      const result = confirm(`Etes vous sûr de retirer les points de livraison sélectionnés de la prestation "${this.contratMenuConvive.libelle}" ?`,
        'Suppression de point de livraison');
      const isDeleted: boolean = await result;
      if(isDeleted) {
        this.gcomPlcService.detachPrestation(this.contratMenuConvive, this.selectedPointsDeLivraisonToRemove).subscribe(response => {
          if (response.inError === false) {
            this.pointsDeLivraisonListUsed = this.pointsDeLivraisonListUsed.filter(plc => {
              const result = find(this.selectedPointsDeLivraisonToRemove, {'id': plc.id});
              return !result;
            });
          }
          this.displayPlcRemoveDialog = false;
        });
      }
    }
  };

  attachSelectedPointsDeLivraison = (event: any) => {

    this.pointDeLivraisonSvc.attachToPrestation(this.contratMenuConvive, this.selectedPointsDeLivraisonToAdd).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {
        this.pointsDeLivraisonListUsed = union(this.pointsDeLivraisonListUsed, this.selectedPointsDeLivraisonToAdd);
        this.pointsDeLivraisonListUsed = sortBy(this.pointsDeLivraisonListUsed, 'libelle');
        // forcer la detection angular
        this.pointsDeLivraisonListUsed = [...this.pointsDeLivraisonListUsed];
        this.displayPlcAddDialog = false;
      }
    });
  };

  displayDialogAddPlc = () => {
    this.displayPlcAddDialog = true;
    this.pointsDeLivraisonToAdd = [];
    this.selectedPointsDeLivraisonToAdd = [];

    // ne prendre en compte que les plc qui ne sont pas déjà présents.
    for (let plc of this.pointsDeLivraisonForSelection) {
      const elt = find(this.pointsDeLivraisonListUsed, {'id': plc.id});
      if (!elt) {
        this.pointsDeLivraisonToAdd.push(plc);
      }
    }
    this.pointsDeLivraisonToAdd = [...this.pointsDeLivraisonToAdd];
  };

  displayDialogRemovePlc = () => {
    this.displayPlcRemoveDialog = true;
    this.pointsDeLivraisonToRemove = [...this.pointsDeLivraisonListUsed];
    this.selectedPointsDeLivraisonToRemove = [];
  };


  savePlc = () => {
    this.cmcPlcService.saveCmcPlc(this.contratMenuConvive, this.pointsDeLivraisonListUsed).subscribe((response: ResponseWrapper<number>) => {
      if (response.inError) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Une erreur est survenue lors de la sauvegarde');
      } else {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Point(s) de livraison sauvegardé(s) avec succès');
      }
    });
  };

  displayDialogAddRepas = $event => {
    this.displayRepasAddDialog = true;
    this.repasToAdd = [];
    this.selectedRepasToAdd = [];

    for (let repas of this.repasList) {
      const elt = find(this.repasListUsed, {'id': repas.id});
      if (!elt) {
        this.repasToAdd.push(repas);
      }
    }
    this.repasToAdd = [...this.repasToAdd];
  };

  attachSelectedRepas = () => {

    this.gcomPlcService.attachRepasToPlc(this.contratMenuConvive, this.selectedPointDeLivraison, this.selectedRepasToAdd).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {
        this.repasListUsed = union(this.repasListUsed, this.selectedRepasToAdd);
        this.repasListUsed = sortBy(this.repasListUsed, 'ordre');
        this.tabMenuRepasList = [];
        this.tabMenuRepasList = this.getTabMenuRepasList(this.repasListUsed);
      }
    });
    this.displayRepasAddDialog = false;
  };

  getTabMenuRepasList = (repasListUsed: RepasDTO[]) => {
    let result: MenuItem[] = [];
    repasListUsed.forEach((repas: RepasDTO) => {
      //todo mettre routerLink en plus, dans le futur
      result.push({label: repas.libelle, id: repas.id.toString()});
    });
    return result;
  };

  getRepasListUsed = () => {
    this.gcomPlcService.getGcomRepasOfPlc(this.selectedPointDeLivraison, this.contratMenuConvive).subscribe(response => {
      this.repasListUsed = response.resultList;
      this.tabMenuRepasList = [];
      this.tabMenuRepasList = this.getTabMenuRepasList(this.repasListUsed);
    });
  };

  detachSelectedRepas = async ($event) => {
    const result = confirm(`Etes vous sûr de retirer les repas du point de livraison "${this.selectedPointDeLivraison.libelle}" ?`,
      'Suppression de repas');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.gcomPlcService.detachRepasFromPrestation(this.contratMenuConvive, this.selectedPointDeLivraison, this.selectedRepasToRemove).subscribe(() => {

        this.repasListUsed = this.repasListUsed.filter(repas => {
          const result = find(this.selectedRepasToRemove, {'id': repas.id});
          return !result;
        });
        this.tabMenuRepasList = this.getTabMenuRepasList(this.repasListUsed);
      });
      this.displayRepasRemoveDialog = false;
    }
  };

  displayDialogRemoveRepas = $event => {
    this.displayRepasRemoveDialog = true;
    this.repasToRemove = [...this.repasListUsed];
    this.selectedRepasToRemove = [];
  };
}

