import {ObjectDTO} from "./object-dto";

export class BoncfStatutDTO extends ObjectDTO{

  libelle:string;
  code:string;
  actif:boolean;
  hexCouleur:string;
  ordre:number;
}
