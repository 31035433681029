import {Observable, of, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {UtilsService} from '../../utils/utils.service';
import {ProduitnomenclatureDTO} from '../../dtos/produitnomenclature-dto';
import {find as _find} from 'lodash'
import {ProduitDTO} from '../../dtos/produit-dto';
import {HttpClient} from '@angular/common/http';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {Page, SearchService} from '../search.service';
import {HttpService} from "../technique/http.service";

export const URL_SEARCH_PRODUITDECLIS_FOR_FICHETECHNIQUE = 'dolrest/gestionproduits/produit-fabrique/search/produits-declinaisons';
export const URL_POST_FICHETECHNIQUE_SAVE=`dolrest/gestionproduits/produit/fichetechnique/save`;

@Injectable()
export class FichetechniqueService {

  sortBy: string[] = ['ordreSource'];

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private searchSvc: SearchService,
              private utils: UtilsService){}


 private  yoSelectedProduitDecliSearchSubj = new Subject<ProduitDeclinaisonDTO>();
  yoSelectedProduitDecliSearch$ = this.yoSelectedProduitDecliSearchSubj.asObservable();

  private closeAjoutComposantDialog = new Subject<boolean>();
  closeAjoutComposantDialog$ = this.closeAjoutComposantDialog.asObservable();


  save = (produit: ProduitDTO, pnList: ProduitnomenclatureDTO[]): Observable<ResponseWrapper<ProduitnomenclatureDTO>> => this.http.post(URL_POST_FICHETECHNIQUE_SAVE + `?idProduit=${produit.id}&recetteNbPersonnes=${produit.recetteNbPortions}`, pnList).pipe(
    catchError(error => this.utils.handleError(error))
  );

  /***
   * Rechercher les produits declinaisons selon une liste de criteres
   * @param searchInstance criteres de recherche
   * @returns {Observable<ResponseWrapper<ProduitDeclinaisonDTO>>}
   */
  searchProduitDeclinaisonList = (ssw: SearchSupplierWrapper, udpId: number): Observable<ResponseWrapper<ProduitDeclinaisonDTO>> => {

    if (!this.utils.isNullOrEmpty(ssw)) {
      const sortParam = this.searchSvc.createSortUrlParam([ssw.filtersMap['sortField'].value + ',' + ssw.filtersMap['sortDir'].value]);
      const pageParam = this.searchSvc.createPageUrlParam(new Page(0, ssw.filtersMap['pageSize'].value));

      return this.http.post<ResponseWrapper<ProduitDeclinaisonDTO>>(`${URL_SEARCH_PRODUITDECLIS_FOR_FICHETECHNIQUE}?udpId=${udpId}&${sortParam}${pageParam}`, ssw).pipe(
        catchError(err => this.utils.handleError(err, true)));
    }


    return of(null);
  };

  updatePoidsNetFT = (idProduitDecline: number, poidsNet: number) => this.httpSvc.put(`dolrest/gestionproduits/produit/produits-declinaison/updateTotalPoidsNetFicheTechnique?id=${idProduitDecline}&poidsNet=${poidsNet}`, null);

  initFromBackProduitNomenclature = (cells:ProduitnomenclatureDTO[], row:ProduitDeclinaisonDTO, col:ProduitDeclinaisonDTO ): ProduitnomenclatureDTO => {
    const cell = _find(cells, {'pdUtiliseId': +row.id, 'pdDeclineId': +col.id});

    // valeur utiles pour saisir une recette par population
    if(!this.utils.isNullOrEmpty(cell)){
      cell.quantiteEnKgTmp = cell.quantiteEnKg;
      cell.quantiteEnUtTmp = cell.quantiteEnUt;
      cell.volumeEnLitreTmp = cell.volumeEnLitre;

    }
    return cell;
  };

  initNewProduitNomenclature = (pdDecline: ProduitDeclinaisonDTO,
                                pdUtilise: ProduitDeclinaisonDTO,
                                indexPn: number): ProduitnomenclatureDTO => {
       let pnNew: ProduitnomenclatureDTO = new ProduitnomenclatureDTO();

       pnNew.quantiteEnUt = 0;
       pnNew.quantiteEnKg = 0;
       pnNew.volumeEnLitre = 0;
       pnNew.volumeEnLitreTmp = 0;
       pnNew.quantiteEnUtTmp = 0;
       pnNew.quantiteEnKgTmp = 0;
       pnNew.actif = true;
       pnNew.id = 0;
       pnNew.ordre = indexPn;
       pnNew.conditionneAPart = false;

       pnNew.pdDeclineId = pdDecline.id;
       pnNew.pdDeclineLibelle = pdDecline.libelle;
       pnNew.pdDeclineProduitId = pdDecline.produitId;
       pnNew.pdDeclineProduitLibelle = pdDecline.produitLibelle;
       pnNew.pdDeclineTypeProduitFabrique = pdDecline.fabrique;
       pnNew.pdDeclineTypeProduitId = pdDecline.typeProduitId;
       pnNew.pdDeclineUdmId = pdDecline.uniteDeMesure.id;
       pnNew.pdDeclineUdmLibelle = pdDecline.uniteDeMesure.libelle;
       pnNew.pdDeclinePrixachat = pdDecline.prixAchat;
       pnNew.pdDeclineDeclinaisonLibelle = pdUtilise.declinaison.libelle;


       pnNew.pdUtiliseId = pdUtilise.id;
       pnNew.pdUtiliseLibelle = pdUtilise.libelle;
       pnNew.pdUtiliseProduitId = pdUtilise.produitId;
       pnNew.pdUtiliseProduitLibelle = pdUtilise.produitLibelle;
       pnNew.pdUtiliseTypeProduitFabrique = pdUtilise.fabrique;
       pnNew.pdUtiliseTypeProduitId = pdUtilise.typeProduitId;
       pnNew.pdUtiliseUdmId = pdUtilise.uniteDeMesure.id;
       pnNew.pdUtiliseUdmLibelle = pdUtilise.uniteDeMesure.libelle;
       pnNew.pdUtiliseUdmType = pdUtilise.uniteDeMesure.type;
       pnNew.pdUtiliseRatioVolume = pdUtilise.ratioVolume;
       pnNew.pdUtiliseRatioUniteTechnique = pdUtilise.ratioUniteTechnique;
       pnNew.pdUtilisePrixachat = pdUtilise.udpPrixAchat;
       pnNew.pdUtiliseDeclinaisonLibelle = pdUtilise.declinaison.libelle;

       return pnNew;
     };

  announceSelectedProduitFromSearch = (pd: ProduitDeclinaisonDTO) => {
    this.yoSelectedProduitDecliSearchSubj.next(pd);
  };

  announceCloseAjoutComposants = () => {
    this.closeAjoutComposantDialog.next(true);
  };
}
