import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProduitsService, ProduitSupplier} from '../core/services/entities/produits.service';
import {GenericDatagridService} from '../core/services/generics/generic-datagrid.service';

@Injectable()
export class ProduitresolverService implements Resolve<ProduitSupplier> {


  constructor(private gds: GenericDatagridService,
              private produitsSvc: ProduitsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProduitSupplier> | Promise<ProduitSupplier> | ProduitSupplier {
    let produitSupplier: ProduitSupplier = new ProduitSupplier();

    let idProduit: number = +route.paramMap.get('idProduit');
    let isFabrique: boolean = route.paramMap.get('isFabrique') === 'true' ? true : false;

    produitSupplier.isFabrique = isFabrique;

    const produitContraintesAlim$ = this.gds.search(this.produitsSvc.filterProduitContrainteAlim(idProduit));
    const produitDTO$ = this.gds.getOne(this.produitsSvc.getEntityName(), idProduit);

    if (idProduit > 0) {

      const all$ = combineLatest([produitDTO$,produitContraintesAlim$]);
      return all$
        .toPromise().then(data => {
          const produit = data[0].one;
          produitSupplier.produitDTO = produit;
          produitSupplier.produitDTO.produitContrainteAlimList=data[1].resultList;
          produitSupplier.produitDeclinaisonDTOList = produit.produitDeclinaisons;
          return produitSupplier;
        });

    }
    //si nouveau produit
    else {
      produitSupplier.produitDTO = this.produitsSvc.createEmptyProduitDTO(isFabrique);
      produitSupplier.produitDeclinaisonUtilises = [];

      return produitSupplier;
    }
  }
}

