import {ModuleWithProviders, NgModule} from '@angular/core';

import {UtilsService} from './utils/utils.service';
import {UtilisateurService} from './utilisateur.service';
import {ProduitRouteMapService} from './services/gestionproduits/produit-routemap.service';
import {UnitedemesureService} from './services/unitedemesure.service';
import {FichetechniqueService} from './services/gestionproduits/fichetechnique.service';
import {Auth2Service} from './services/security/auth2.service';
import {Auth2Guard} from './services/security/auth2.guard';
import {RoutemapService} from './services/routemap.service';
import {MenusRoutemapService} from './services/gestionmenus/menus-routemap.service';
import {MenusToolbarService} from './services/gestionmenus/menus-toolbar.service';
import {SiteService} from './site.service';
import {SiteRoutemapService} from './services/entities/site-routemap.service';
import {AdministrationRoutemapService} from './services/entities/administration-routemap.service';
import {PreferencesUtilisateurService} from './services/preferences-utilisateur.service';
import {MenusDatesService} from './services/gestionmenus/menus-dates.service';
import {MenusPlanningService} from './services/gestionmenus/menus-planning.service';
import {MenusCompositionService} from './services/gestionmenus/menus-composition.service';
import {MenusSearchService} from './services/gestionmenus/menus-search.service';
import {LoadingService} from './services/loading.service';
import {EnvironnementService} from './services/entities/gestionenvironnements/environnement.service';
import {EnvironnementRouteMapService} from './services/entities/environnement-route-map.service';
import {EffectifsService} from './services/gestionmenus/effectifs.service';
import {MenusDragndropService} from './services/gestionmenus/menus-dragndrop.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './services/security/auth-interceptor.service';
import {ImpressionService} from './services/impression.service';
import {DolServiceWorkerService} from './services/service-worker/dolsw.service';
import {FrontStorageService} from './services/storage/front-storage.service';
import {GenericFormService} from './services/generics/generic-form.service';
import {GenericDatagridService} from './services/generics/generic-datagrid.service';
import {ConvivesService} from './services/entities/convives.service';
import {FamillesGemrcnService} from './services/entities/familles-gemrcn.service';
import {GroupesNutritionnelsService} from './services/entities/groupes-nutritionnels.service';
import {FamillesPlansAlimentairesService} from './services/entities/familles-plans-alimentaires.service';
import {DecoupagesRepasService} from './services/entities/decoupages-repas.service';
import {RepasService} from './services/entities/repas.service';
import {ContratsMenusConvivesService} from './services/gestioncontrats/contrats-menus-convives.service';
import {ModelesNutritionnelsService} from './services/entities/modeles-nutritionnels.service';
import {GestionGemrcnService} from './services/gestiongemrcn/gestion-gemrcn.service';
import {TypesProduitService} from './services/entities/types-produit.service';
import {FamillesProduitService} from './services/entities/familles-produit.service';
import {SearchService} from './services/search.service';
import {AllergenesService} from './services/entities/allergenes-service.service';
import {CalculGemrcnService} from './services/gestiongemrcn/calcul-gemrcn.service';
import {UtilisateurRoutemapService} from './services/entities/utilisateur-routemap.service';
import {UtilisateurResolverService} from '../gestion-referentiel/utilisateur-resolver.service';
import {RoleService} from './role.service';
import {MenusGemrcnService} from './services/gestionmenus/menus-gemrcn.service';
import {ApportsNutritionnelsService} from './services/entities/apports-nutritionnels.service';
import {DeclinaisonsService} from './services/entities/declinaisons.service';
import {UnitesDeMesuresService} from './services/entities/unites-de-mesures.service';
import {ZonesDeStockageService} from './services/entities/zones-de-stockage.service';
import {AteliersService} from './services/entities/ateliers.service';
import {EquipesService} from './services/entities/equipes.service';
import {GenericManageDialogService} from './services/generics/generic-manage-dialog.service';
import {AdressesService} from './services/entities/adresses.service';
import {UnitesDeProductionService} from './services/entities/unites-de-production.service';
import {JoursSemaineService} from './services/entities/jours-semaine.service';
import {SecteursService} from './services/entities/secteurs.service';
import {FournisseurService} from './services/entities/fournisseur.service';
import {SecteursFournisseursService} from './services/entities/secteurs-fournisseurs.service';
import {SecteurFournisseurDatasourceService} from './services/entities/secteur-fournisseur-datasource.service';
import {UniteDeProduction__secteurFournisseurService} from './services/entities/unite-de-production__secteur-fournisseur.service';
import {ProduitsService} from './services/entities/produits.service';
import {ProduitDeclinaisonService} from './services/entities/produit-declinaison.service';
import {TaxesService} from './services/entities/taxes.service';
import {D3Service} from './d3/d3.service';
import {CataloguesAchatsService} from './services/entities/catalogues-achats.service';
import {CiqualService} from './services/gestionproduits/ciqual.service';
import {ContratsMenusConvivesRepasService} from './services/entities/contrats-menus-convives-repas.service';
import {ContratsMenusConvivesDecoupagesService} from './services/entities/contrats-menus-convives-decoupages.service';
import {ContratsMenusConvivesEquipesService} from './services/entities/contrats-menus-convives-equipes.service';
import {DuplicationMenuService} from './services/gestionmenus/duplication-menu.service';
import {ContratMenuService} from './services/gestioncontrats/contrat-menu.service';
import {PrintMenuService} from './services/gestionmenus/print-menu.service';
import {TachesService} from './services/entities/taches.service';
import {TypeTacheService} from './services/entities/type-tache.service';
import {UtilisateurConnexionService} from "./services/entities/utilisateur.connexion.service";
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from "./services/http-timeout-interceptor.service";

@NgModule({
  imports: [
  ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [

        UtilsService,
        UtilisateurService,
        ProduitRouteMapService,
        UnitedemesureService,
        FichetechniqueService,
        Auth2Service,
        Auth2Guard,
        RoutemapService,
        MenusRoutemapService,
        MenusToolbarService,
        SiteService,
        SiteRoutemapService,
        AdministrationRoutemapService,
        PreferencesUtilisateurService,
        MenusDatesService,
        MenusPlanningService,
        MenusCompositionService,
        MenusSearchService,
        LoadingService,
        EnvironnementService,
        EnvironnementRouteMapService,
        EffectifsService,
        MenusDragndropService,
        PrintMenuService,
        ImpressionService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 120000 }, // 2 minutes par défaut
        DolServiceWorkerService,
        FrontStorageService,
        GenericFormService,
        GenericDatagridService,
        ConvivesService,
        FamillesGemrcnService,
        GroupesNutritionnelsService,
        FamillesPlansAlimentairesService,
        DecoupagesRepasService,
        RepasService,
        ContratsMenusConvivesService,
        ModelesNutritionnelsService,
        GestionGemrcnService,
        TypesProduitService,
        FamillesProduitService,
        SearchService,
        //Service des allergenes pour le crud generique en administration
        TaxesService,
        UtilisateurRoutemapService,
        UtilisateurResolverService,
        TaxesService,
        CalculGemrcnService,
        RoleService,
        MenusGemrcnService,
        ApportsNutritionnelsService,
        DeclinaisonsService,
        UnitesDeMesuresService,
        ZonesDeStockageService,
        AteliersService,
        EquipesService,
        GenericManageDialogService,
        AdressesService,
        UnitesDeProductionService,
        JoursSemaineService,
        SecteursService,
        FournisseurService,
        SecteursFournisseursService,
        SecteurFournisseurDatasourceService,
        UniteDeProduction__secteurFournisseurService,
        ProduitsService,
        ProduitDeclinaisonService,
        TaxesService,
        D3Service,
        CataloguesAchatsService,
        CiqualService,
        ContratsMenusConvivesRepasService,
        ContratsMenusConvivesDecoupagesService,
        ContratsMenusConvivesEquipesService,
        DuplicationMenuService,
        AllergenesService,
        ContratMenuService,
        TypeTacheService,
        TachesService,
        UtilisateurConnexionService,
      ]
    };
  }


}
