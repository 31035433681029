import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class PreparationConditionnementResolverService implements Resolve<PreparationConditionnementSupplier> {

  constructor() {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PreparationConditionnementSupplier> | Promise<PreparationConditionnementSupplier> | PreparationConditionnementSupplier => {
    const supplier: PreparationConditionnementSupplier = new PreparationConditionnementSupplier();

    const idPlanProduction: number = parseInt(route.params.planProductionId, 10);
    supplier.idPlanProduction = idPlanProduction;

    const isRefresh: boolean = route.params.isRefresh && route.params.isRefresh === 'true';
    supplier.isRefresh = isRefresh;

    return of(supplier);
  };

}

export class PreparationConditionnementSupplier {
  idPlanProduction: number;
  isRefresh: boolean;
}

