import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-gestion-upro-root',
  templateUrl: './gestion-upro-root.component.html',
  styleUrls: ['./gestion-upro-root.component.scss']
})
export class GestionUproRootComponent implements OnInit {

  constructor(private title:Title) { }

  ngOnInit() {
    this.title.setTitle('Unités de production');
  }

}
