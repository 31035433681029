import {ObjectDTO} from "./object-dto";

export class ModeleNutritionnelParamGemrcnDTO extends ObjectDTO {


  // modele nutritionnel groupe gemrcn
  modeleNutritionnelGroupeGemrcnId: number;
  modeleNutritionnelGroupeGemrcnChoix: number;

  // modele nutritionnel
  modeleNutritionnelId: number;
  modeleNutritionnelLibelle: string;
  modeleNutritionnelCode: string;

  // repas
  repasId: number;
  repasLibelle: string;
  repasCode: string;


}
