<form [formGroup]="form" (ngSubmit)="saveLivraisons()" novalidate>


  <div class="d-flex justify-content-between mg-b-10">
    <strong>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="mg-r-5">Planning des livraisons fournisseur</span>
    </strong>

    <span>
          <yo-button
            tooltip="Enregistrer"
            type="submit"
            iconClass="fas fa-save"
            buttonClass="cta-success"
            [disabled]="!gds.canModify(uniteDeProduction)"></yo-button>
    </span>
  </div>
  <p-tabMenu [model]="navUpLivraison" [activeItem]="activeItem"></p-tabMenu>
  <fieldset formGroupName="formJoursLivraisonUDP">

    <div class="d-flex flex-row" *ngIf="displayNavFournisseurs">
      <!--JOURS-->
      <div class="mg-r-15">
        <h3 class="first">Jours de livraison</h3>
        <p-listbox [options]="joursSemaineList"
                   [style]="{'width':'250px'}"
                   (onChange)="onChangeJoursSemaine($event)"
                   multiple="multiple" checkbox="checkbox"
                   filter="true"
                   optionLabel="viewValue"
                   [disabled]="!gds.canModify(uniteDeProduction)"
                   formControlName="joursSemaineUDP"
                   [listStyle]="{'width':'248px'}">
          <ng-template let-jourSemaine pTemplate="item">
            <div style="display: inline-block">
              <span>{{jourSemaine.viewValue}}</span>
            </div>
          </ng-template>
        </p-listbox>
        <div class="error"
             *ngIf="formJoursLivraisonUDP.controls['joursSemaineUDP'].dirty && formJoursLivraisonUDP.controls['joursSemaineUDP'].hasError('required')">
          La sélection d'un ou plusieurs jours(s) de livraison est obligatoire.
        </div>
      </div>
      <!--SECTEUR-->
      <div class="mg-r-15" *ngIf="hasAdministration$ | async">

        <h3 class="first">Secteurs</h3>
        <p-listbox [options]="secteurList"
                   [style]="{'width':'250px'}"
                   (onChange)="onChangeSecteurs($event)"
                   multiple="multiple"
                   checkbox="checkbox"
                   optionLabel="libelle"
                   [disabled]="!gds.canModify(uniteDeProduction)"
                   formControlName="secteursUDP"
                   [listStyle]="{'max-height':'300px','width':'248px'}">
          <ng-template let-secteur pTemplate="item">
            <div style="display: inline-block">
              <span>{{secteur.libelle}}</span>
            </div>
          </ng-template>
        </p-listbox>
        <div class="error"
             *ngIf="formJoursLivraisonUDP.controls['secteursUDP']?.dirty && formJoursLivraisonUDP.controls['secteursUDP']?.hasError('required')">
          La sélection d'un ou plusieurs secteur(s) est obligatoire.
        </div>
      </div>
      <!--Filiales Fournisseurs-->
      <div class="mg-r-15">
        <h3 class="first">Filiales Fournisseurs
          <span class="mg-l-5"
                *ngIf="!utils.isCollectionNullOrEmpty(secteurFournisseurList)">({{getNbSecteursFournisseursActifs()}}
            /{{secteurFournisseurList.length}})
                </span>
        </h3>
        <!--FOURNISSEURS-->
        <p-listbox [options]="secteurFournisseurList"
                   optionLabel="fournisseurLibelle"
                   multiple="multiple"
                   checkbox="checkbox"
                   filter="filter"
                   formControlName="secteursFournisseursUDP"
                   [style]="{'width':'450px'}"
                   (onChange)="addToAffectationFournisseurs($event)"
                   [disabled]="!gds.canModify(uniteDeProduction)"
                   [listStyle]="{'max-height':'620px','width':'450px'}">
          <ng-template let-secteurFournisseur pTemplate="item">
            <div
              [title]="tooltipSecteurFournisseur(secteurFournisseur)"
              style="display: inline-block;white-space: nowrap;">
              <span>{{secteurFournisseur.fournisseurLibelle}}</span>
              <span class="font-10">({{secteurFournisseur.secteur.libelle}})</span>
              &nbsp;<i class="fas fa-exclamation-triangle danger-color space"
                       *ngIf="!udpSecteurFournisseurSvc.secteurFournisseurActif(secteurFournisseur)"></i>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </div>

    <div *ngIf="!displayNavFournisseurs">
      <!--AFFECTATIONS-->
      <yo-up-livraisons-affectation
        [affectationsSupplier]="affectationsSupplier">
      </yo-up-livraisons-affectation>
    </div>


  </fieldset>
  <div class="row">
    <div class="col-md-12 font-12">
      <p>
        🗣️<em> N'oubliez pas d'enregistrer.</em>
      </p>
    </div>
  </div>

</form>
