export class MParametrage {

  udpId: number;

  idAtelier: number;
  libelleAtelier: string;

  idEquipe: number;
  libelleEquipe: string;

  idTypeTache: number;
  libelleTypeTache: string;

  idTache: number;
  libelleTache: string;

}
