import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DATEPICKER_FR} from "../../../../../core/constants";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ListeBesoinsDenreeDto} from "../../../../../core/dtos/liste-besoins-denree-dto";

@Component({
  selector: 'yo-bulk-edition-article',
  templateUrl: './bulk-edition-article.component.html',
  styleUrls: ['./bulk-edition-article.component.scss']
})

export class BulkEditionArticleComponent{

  @Input() displayDialog: boolean;
  @Input() selectedListeBesoinsDenree : ListeBesoinsDenreeDto[]
  @Output() onClose = new EventEmitter<boolean>();
  @Output() emitBulk = new EventEmitter<BulkArticleSupplier>()

  localeFr = DATEPICKER_FR;

  dateSortie : Date = undefined;
  dateConso : Date = undefined;
  dateFab : Date =undefined;
  dateAppro : Date = undefined;
  dateLiv : Date = undefined;

  besoinAjuste: number;

  constructor(public utils :UtilsService){}

  closeDialog(){
    this.displayDialog = false;
    this.onClose.emit();
    this.clearData();
  }

  sendBulkBesoinSupplier(){
    let bulkArticle : BulkArticleSupplier = new BulkArticleSupplier()
    bulkArticle.dateSortie = this.dateSortie;
    bulkArticle.dateConso = this.dateConso;
    bulkArticle.dateFab = this.dateFab;
    bulkArticle.dateAppro = this.dateAppro;
    bulkArticle.dateLiv = this.dateLiv;

    bulkArticle.besoinAjuste = this.besoinAjuste;

    this.emitBulk.emit(bulkArticle);
    this.displayDialog = false;
    this.onClose.emit();
    this.clearData();
  }

  isDisabledSaveBtn(){
    return  this.dateSortie === undefined && this.dateConso === undefined
      && this.dateFab === undefined && this.dateAppro === undefined
      && this.dateLiv === undefined && this.besoinAjuste === undefined;
  }

  clearData(){
    this.dateSortie  = undefined;
    this.dateConso  = undefined;
    this.dateFab = undefined;
    this.dateAppro  = undefined;
    this.dateLiv = undefined;
    this.besoinAjuste  = undefined;
  }
}

export class BulkArticleSupplier {

  dateSortie : Date = undefined;
  dateConso : Date = undefined;
  dateFab : Date = undefined;
  dateAppro : Date = undefined;
  dateLiv : Date = undefined;

  besoinAjuste: number = undefined;
}
