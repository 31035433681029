import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TypePrestationDTO} from "../../dtos/type-prestation-dto";
import {UtilsService} from "../../utils/utils.service";
import {catchError} from "rxjs/operators";

export const URL_GET_ALL_TYPE_PRESTATION = `dolrest/typeprestation/findAll`;;

@Injectable({
  providedIn: 'root'
})
export class TypePrestationService {

  typePrestationEnvironnement: TypePrestationDTO[] = [];

  constructor(private http : HttpClient,
              private utils: UtilsService) {


    this.getTypePrestationList().subscribe((response : TypePrestationDTO[]) =>{
      this.typePrestationEnvironnement = response;
    });
  }

  getTypePrestationList(){
    return this.http.get(URL_GET_ALL_TYPE_PRESTATION)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }
}
