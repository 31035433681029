import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {UpIncoherenceSupplier} from "./up-incoherence-resolver.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {ErreurFonctionnelleDto} from "../../../core/dtos/erreur-fonctionnelle-dto";

@Component({
  selector: 'yo-up-incoherence',
  templateUrl: './up-incoherence.component.html',
  styleUrls: ['./up-incoherence.component.scss']
})
export class UpIncoherenceComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  incoherenceList: ErreurFonctionnelleDto[] = [];

  constructor(private route: ActivatedRoute,
              public utils: UtilsService) {
  }

  ngOnInit(): void {

    this.subscriptionRoute = this.route.data.subscribe((data: { upIncoherenceSupplier: UpIncoherenceSupplier }) => {
      this.incoherenceList = data.upIncoherenceSupplier.erreurFonctionnelleList;
    });
  }

  ngOnDestroy(): void {
  }

  getTypeErreur(cellValue: any) {
    switch (cellValue.codeErreur) {
      case  'LME_1':
        return  'Lot marché';

      case  'SMEVAL_ARTICLE_PREFERE':
        return  'Article préféré';

      case  'SMEVAL_JOUR_LIVRAISON':
        return  'Aucun jour de livraison';

      case  'UPE_1':
        return  'Articles manquants';

      default :
          return cellValue.codeErreur;
    }
  }
}
