import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {TypeProduitDTO} from '../../dtos/type-produit-dto';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {Page, SearchService} from '../search.service';
import {HttpService} from '../technique/http.service';

export const URL_POST_SEARCH=`dolrest/gestionmenus2/searchProduitsDeclinaisons`;

@Injectable()
export class MenusSearchService {

  constructor(
              private httpSvc:HttpService,
              private searchSvc:SearchService,
              private utils: UtilsService){
  }

  /**
   * Lancer la recherche de produits déclinés
   * @returns {Observable<TypeProduitDTO[]>}
   */
  launchSearch(ssw: SearchSupplierWrapper): Observable<ResponseWrapper<ProduitDeclinaisonDTO>> {

    if (!this.utils.isNullOrEmpty(ssw)) {

      let endUrl = '';
      if(ssw.filtersMap['sortField'] && ssw.filtersMap['sortDir'])
        endUrl += this.searchSvc.createSortUrlParam([ssw.filtersMap['sortField'].value + ',' + ssw.filtersMap['sortDir'].value]);
      if(ssw.filtersMap['page'] && ssw.filtersMap['size'])
        endUrl += this.searchSvc.createPageUrlParam(new Page( ssw.filtersMap['page'].value, ssw.filtersMap['size'].value));

      return this.httpSvc.post(`${URL_POST_SEARCH}?${endUrl}`, ssw);
    }

    return of(null);

  }

}
