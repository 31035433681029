<div class="card">
  <div class="card-header">


    <form [formGroup]="formFilters">

      <div class="d-flex flex-row align-items-center justify-content-between action-bar">
        <div>
          <p-calendar
            firstDayOfWeek="1"
            formControlName="dates"
            class="mg-r-40"
            dateFormat="dd/mm/yy"
            title="Sélection d'une période"


            selectionMode="range"
            [readonlyInput]="true"
            [(ngModel)]="selectedDates"
            [locale]="localeFr"
            [showIcon]="true"
            [showWeek]="true">
          </p-calendar>
        </div>

        <div>
          <p-multiSelect [options]="unitesProduction"
                         formControlName="unitesDeProduction"
                         selectedItemsLabel="{0} unités de production"
                         title="Filtrer sur les unités de production"


                         maxSelectedLabels="1"
                         (onChange)="changeUdp()"
                         defaultLabel="Unités de production..." optionLabel="libelle"
                         [(ngModel)]="selectedUnitesProduction"></p-multiSelect>
        </div>

        <div>
          <p-multiSelect [options]="fournisseurs"
                         formControlName="fournisseurs"
                         selectedItemsLabel="{0} fournisseurs"
                         title="Filtrer sur les fournisseurs"


                         appendTo="body"
                         maxSelectedLabels="1"
                         defaultLabel="Fournisseurs..."
                         optionLabel="fournisseurLibelle"
                         [(ngModel)]="selectedFournisseurs">
          </p-multiSelect>

          <ng-template let-itemstatut pTemplate="item">
            {{itemstatut.value.fournisseurLibelle}}
          </ng-template>
        </div>

        <div>
          <p-dropdown
            class="mg-r-20"
            [options]="statuts"

            [(ngModel)]="selectedStatut"
            placeholder="Statuts de commande..."
            optionLabel="libelle"
            title="Filtrer sur les statuts de commande"

            filter="true"
            formControlName="statut">
            <ng-template let-itemSelected pTemplate="selectedItem">
              {{itemSelected.libelle.charAt(0).toUpperCase() + itemSelected.libelle.slice(1).toLowerCase()}}
            </ng-template>
            <ng-template let-itemstatut pTemplate="item">
              {{itemstatut.libelle | uppercase}}
            </ng-template>
          </p-dropdown>
        </div>

        <div>
          <yo-button (onClick)="exportDatasToExcelFile()"
                     label="Exporter les données sous Excels" buttonClass="cta-inner-secondary"></yo-button>
        </div>
      </div>

    </form>

  </div>

  <div class="card-body" style="overflow: auto;max-height:85vh; padding-bottom: 10vh">
    <div class="row">

      <div class="col-md-6 mg-t-10" *ngIf="amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit">
        <div class="card">
          <div class="card-header">
            MONTANTS HEBDOMADAIRES DES ACHATS SANS DÉTAIL

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit, 'montants-hebdo-achats-sans-details')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-montant-hebdo-achats-sans-details" (onPointClick)="displayDetailsAmountsItems($event)"
                      [dataSource]="amountItemsOrdersWithoutDetailsPerPeriodByProductionUnit"
                      [series]="seriesOptionsAmountItemsWithoutDetails">
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipAmountItems"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10"
           *ngIf="amountItemsOrdersWithDetailsPerPeriodByProductionUnit && seriesOptionsAmountItemsWithDetails.length">
        <div class="card">
          <div class="card-header">
            MONTANTS HEBDOMADAIRES DES ACHATS PAR FOURNISSEUR ({{ productionUnitSelectedForAmountItems }})

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(amountItemsOrdersWithDetailsPerPeriodByProductionUnitSelected, 'montants-hebdo-achats-par-fournisseur')"> <i
                class="fa fa-file-excel fa-2x space yoni-color"></i></a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-montant-hebdo-achats-avec-details"
                      [dataSource]="amountItemsOrdersWithDetailsPerPeriodByProductionUnitSelected"
                      [series]="seriesOptionsAmountItemsWithDetails">
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipAmountItems"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="numberOrdersWithoutDetailsPerPeriodByProductionUnit">
        <div class="card">
          <div class="card-header">
            NOMBRE DE COMMANDES HEBDOMADAIRES

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(numberOrdersWithDetailsPerPeriodByProductionUnitSelected, 'nombre-hebdo-achats-sans-details')"> <i
                class="fa fa-file-excel fa-2x space yoni-color"></i></a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-nb-hebdo-achats-sans-details" (onPointClick)="displayDetailsNumberItems($event)"
                      [dataSource]="numberOrdersWithoutDetailsPerPeriodByProductionUnit"
                      [series]="seriesOptionsNumberItemsWithoutDetails">
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10"
           *ngIf="numberOrdersWithDetailsPerPeriodByProductionUnitSelected && seriesOptionsNumberItemsWithDetails.length">
        <div class="card">
          <div class="card-header">
            NOMBRE DE COMMANDES HEBDOMADAIRES AVEC DÉTAIL FOURNISSEUR ({{ productionUnitSelectedForNumberItems }})

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(numberOrdersWithDetailsPerPeriodByProductionUnitSelected, 'nombre-commandes-hebdo-par-fournisseur')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-nombre-hebdo-achats-avec-details"
                      [dataSource]="numberOrdersWithDetailsPerPeriodByProductionUnitSelected"
                      [series]="seriesOptionsNumberItemsWithDetails">
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10"
           *ngIf="amountsItemsWeeklyPerFamilyAndPeriod && seriesOptionsAmountsItemsWeeklyPerFamilyAndPeriod.length">
        <div class="card">
          <div class="card-header">
            MONTANTS HEBDOMADAIRES DES ACHATS PAR FAMILLE DE DÉCLINAISON DENRÉE

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(amountsItemsWeeklyPerFamilyAndPeriod, 'montants-hebdo-par-famille-declinaison-denree')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-amounts-items-weekly-per-family-and-period"
                      [dataSource]="amountsItemsWeeklyPerFamilyAndPeriod"
                      [series]="seriesOptionsAmountsItemsWeeklyPerFamilyAndPeriod">
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipAmountStackBar"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="averageAmountItemsPerOrderAndPeriod">
        <div class="card">
          <div class="card-header">
            MONTANTS MOYEN DES ACHATS PAR COMMANDE

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(averageAmountItemsPerOrderAndPeriod, 'motant-moyen-achats-par-commande')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>
          <div class="card-boby">
            <dx-chart
              id="average-amount-items-per-order"
              [dataSource]="averageAmountItemsPerOrderAndPeriod"
              [rotated]="true"
              [barGroupWidth]="8"
              title="Montant moyen des achats par commande"
            >
              <dxo-common-series-settings argumentField="provider" type="stackedbar">
              </dxo-common-series-settings>
              <dxi-series valueField="amount" name="Montant" color="#3F7FBF"></dxi-series>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipAmountItems"></dxo-tooltip>
              <dxi-value-axis>
                <dxo-label [customizeText]="customizeLabelAmountItems"></dxo-label>
              </dxi-value-axis>
              <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                <dxo-margin [left]="50"></dxo-margin>
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="numberItemsOrderedPerPeriod">
        <div class="card">
          <div class="card-header">
            NOMBRE D'ARTICLES COMMANDÉS

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(numberItemsOrderedPerPeriod, 'nombre-articles-commandes')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-number-items-ordered"
                      (onPointClick)="displayDetailsNumberItemsOrderedByProvider($event)"
                      [dataSource]="numberItemsOrderedPerPeriod">
              <dxi-series
                argumentField="numWeek"
                valueField="Nombre d'articles"
                name="Nombre d'articles commandés"
                type="bar"
                color="#ffaa66"
              >
              </dxi-series>
              <dxo-tooltip [enabled]="true"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="findNumberItemsOrderedPerProviderAndPeriodSelected">
        <div class="card">
          <div class="card-header">
            NOMBRE D'ARTICLES COMMANDÉS PAR FOURNISSEUR

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(findNumberItemsOrderedPerProviderAndPeriodSelected, 'nombre-articles-commandes-par-fournisseur')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart
              id="nb-items-per-order"
              [dataSource]="findNumberItemsOrderedPerProviderAndPeriodSelected"
              [rotated]="true"
              [barGroupWidth]="8"
              title="Nombre d'articles commandés par fournisseur"
            >
              <dxo-common-series-settings argumentField="provider" type="stackedbar">
              </dxo-common-series-settings>
              <dxi-series valueField="Nombre d'articles" name="Nombre d'articles" color="#3F7FBF"></dxi-series>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
              <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                <dxo-margin [left]="50"></dxo-margin>
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="litigesNonConformite && seriesOptionsLitiges">
        <div class="card">
          <div class="card-header">
            LITIGES ET NON CONFORMITÉS

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(litigesNonConformite, 'nombre-litiges-motifs-non-conformes')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-litiges-motifs" [dataSource]="litigesNonConformite" [series]="seriesOptionsLitiges">
              <dxo-tooltip [enabled]="true"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="egalimIndicator">
        <div class="card">
          <div class="card-header">
            INDICATEUR EGALIM
          </div>
          <div class="card-boby">
            <dx-bar-gauge
              id="gauge"
              [startValue]="0"
              [endValue]="100"
              [palette]="['#2196f3', '#42a84c']"
              [values]="[egalimIndicator.pourcentageDurable, egalimIndicator.pourcentageBio]"
            >
              <dxo-label [visible]="true" [customizeText]="customizeTextLabelGaugeEgalim"></dxo-label>
              <dxo-tooltip
                [enabled]="true"
                [customizeTooltip]="customizeTooltipGaugeEgalim"
              ></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-title [text]="egalimIndicator.egalim ? 'Conforme à la loi Egalim' : 'Non conforme à la loi Egalim'">
                <dxo-font [size]="28" [color]="egalimIndicator.egalim ? 'green' : 'red'"></dxo-font>
                <dxo-subtitle [text]="getPeriod()">
                  <dxo-font [size]="14" [color]="'black'"></dxo-font>
                </dxo-subtitle>
              </dxo-title>
              <dxo-legend
                [visible]="true"
                verticalAlignment="bottom"
                horizontalAlignment="center"
                [customizeText]="customizeTextGaugeEgalim"
              >
              </dxo-legend>
              <span>Période du xx au xx</span>
            </dx-bar-gauge>
          </div>
          <div class="displayFlex">
            <div class="mg-r-15">
              <label class="padding-label-egalim">Seuil Bio</label>
              <input type="number" [(ngModel)]="egalimThresholdBio" (change)="changeSeuil()"/>
            </div>
            <div class="mg-r-15">
              <label class="padding-label-egalim">Seuil Produits Durables</label>
              <input type="number" [(ngModel)]="egalimThresholdSustainableProducts" (change)="changeSeuil()"/>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-6 mg-t-10" *ngIf="topItemsByAmount">
        <div class="card">
          <div class="card-header">
            TOP ARTICLES COMMANDÉS PAR MONTANT

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(topItemsByAmount, 'top-3-articles-commandes-par-montant')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="top-items-by-amount" [dataSource]="topItemsByAmount">
              <dxo-title text="Top 3 des articles commandés par montant"></dxo-title>
              <dxo-export [enabled]="true"></dxo-export>
              <dxi-series type="bar" name="Montant" argumentField="label" valueField="amount"></dxi-series>
              <dxo-common-annotation-settings type="custom" series="Montant" template="template" [allowDragging]="true">
              </dxo-common-annotation-settings>
              <dxi-annotation
                *ngFor="let data of topItemsByAmount"
                [data]="data"
                [argument]="data.label"
              >
              </dxi-annotation>
              <dxo-legend [visible]="false"></dxo-legend>
              <svg *dxTemplate="let annotation of 'template'" class="annotation">
                <image attr.href="{{ getImageTrophyTop3Path(annotation, true) }}" width="60" height="40"/>
                <rect x="0" y="0" class="border"/>
                <text x="70" y="25" class="state">{{ annotation.argument }}</text>
                <text x="0" y="60">
                  <tspan class="caption">Montant:</tspan>
                  <tspan class="capital" dx="5">{{ annotation.data.amount + ' €' }}</tspan>
                </text>
              </svg>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="topItemsByWeight">
        <div class="card">
          <div class="card-header">
            TOP ARTICLES COMMANDÉS PAR VOLUME

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(topItemsByWeight, 'top-3-articles-commandes-par-volume')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="top-items-by-weight" [dataSource]="topItemsByWeight">
              <dxo-title text="Top 3 des articles commandés par volume"></dxo-title>
              <dxo-export [enabled]="true"></dxo-export>
              <dxi-series type="bar" color="#27c427" name="Volume" argumentField="label"
                          valueField="quantity"></dxi-series>
              <dxo-common-annotation-settings type="custom" series="Volume" template="template" [allowDragging]="true">
              </dxo-common-annotation-settings>
              <dxi-annotation
                *ngFor="let data of topItemsByWeight"
                [data]="data"
                [argument]="data.label"
              >
              </dxi-annotation>
              <dxo-legend [visible]="false"></dxo-legend>
              <svg *dxTemplate="let annotation of 'template'" class="annotation">
                <image attr.href="{{ getImageTrophyTop3Path(annotation, false) }}" width="60" height="40"/>
                <rect x="0" y="0" class="border"/>
                <text x="70" y="25" class="state">{{ annotation.argument }}</text>
                <text x="0" y="60">
                  <tspan class="caption">Volume :</tspan>
                  <tspan class="capital" dx="5">{{ annotation.data.quantity + ' Kg' }}</tspan>
                </text>
              </svg>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-6 mg-t-10" *ngIf="numberItemsOrderedPerPeriod">
        <div class="card">
          <div class="card-header">
            MONTANTS GLOBAL DES ACHATS PAR FOURNISSEUR SUR LA PERIODE

            <span class="float-right">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(volumeItemsByProvider, 'montant-global-achats-par-fournisseur')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>
          </div>

          <div class="card-boby">
            <dx-chart id="chart-amount-items-by-period" [dataSource]="volumeItemsByProvider">
              <dxi-series
                argumentField="provider"
                valueField="amount"
                name="Montants global des achats par fournisseur sur la période"
                type="bar"
                color="#cc00ff"
              >
              </dxi-series>
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeToolTipAmountItems"></dxo-tooltip>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top">
              </dxo-legend>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="col-md-12 mg-t-10" *ngIf="(weightsByAppellationPerPeriod || amountsByAppellationPerPeriod)">
        <div class="card">
          <div class="card-header">
            DÉTAIL DES MONTANTS ET DU POIDS PAR APPELLATION SELECTIONNÉE
          </div>

          <div class="card-boby">

            <span class="float-left" *ngIf="amountsByAppellationPerPeriod">
              <a class="cursor" title="Export excel(csv)"
                 (click)="exportSimpleExcelCsv(amountsByAppellationPerPeriod, 'montant-par-appellation')">
                <i class="fa fa-file-excel fa-2x space yoni-color"></i>
              </a>
            </span>

            <div class="row">

              <div class="col-md-12" *ngIf="amountsByAppellationPerPeriod">
                <dx-pie-chart
                  id="amountsByAppellationPerPeriod"
                  title="Montants par appellation sélectionnée"
                  palette="bright"
                  [dataSource]="amountsByAppellationPerPeriod"
                >
                  <dxo-legend
                    orientation="horizontal"
                    itemTextPosition="right"
                    horizontalAlignment="center"
                    verticalAlignment="bottom"
                    [columnCount]="4"
                  ></dxo-legend>
                  <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipPieWithDevise"></dxo-tooltip>
                  <dxo-export [enabled]="true"></dxo-export>
                  <dxi-series argumentField="tooltip" valueField="amount">
                    <dxo-label
                      [visible]="true"
                      position="columns"
                      [customizeText]="customizeLabelPieWithDevise"
                    >
                      <dxo-font [size]="16"></dxo-font>
                      <dxo-connector [visible]="true" [width]="0.5"></dxo-connector>
                    </dxo-label>
                  </dxi-series>
                </dx-pie-chart>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>


  </div>

</div>
