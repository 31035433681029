import {Component, OnDestroy, OnInit} from "@angular/core";
import {WizardConditionnementService} from "../../core/services/conditionnements/conditionnement-wizard.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {WizardService} from "../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration',
  templateUrl: './wizard-configuration.component.html',
  styleUrls: ['./wizard-configuration.component.scss']
})
export class WizardConfigurationComponent implements OnInit, OnDestroy {

  display: boolean = false;

  fullScreen: boolean = false;

  title: string = "Assistant de configuration d'un conditionnement";

  currentStep: number;

  subOpenDialog: Subscription;

  subNextStep: Subscription;

  constructor(public readonly wizardSvc: WizardConditionnementService,
              private readonly wizardGlobalSvc: WizardService,
              private readonly utilsSvc: UtilsService) {
  }


  ngOnInit(): void {
    this.currentStep = this.wizardSvc.STEP_INITIALIZATION;
    this.wizardSvc.clear(); // Dans le cas où l'utilisateur a réalisé une actualisation de la page accidentellement
    this.initOpenDialogSubscription();
    this.initNextStepSubscription();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subNextStep);
  }

  initNextStepSubscription = (): void => {
    this.subNextStep = this.wizardSvc.subjectStep$
      .subscribe((nextStep) => {
        if (nextStep !== this.wizardSvc.EXIT)
          this.updateStep(nextStep);
        else
          this.closeDialog();
      });
  }

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.wizardSvc.openDialogWizard$
      .subscribe(() => this.display = true);
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  onInitPopup = ($event): void => {
    $event.component.registerKeyHandler("escape", function (arg) {
      arg.stopPropagation();
    });
  }

  start = (): void =>  { this.currentStep = this.wizardSvc.STEP_TARGET_MODEL_DISH; }

  clear = (): void => { this.currentStep = this.wizardSvc.STEP_INITIALIZATION; this.wizardSvc.clear(); }

  handlePreviousStep = (data: any) => {
    this.updateStep(this.wizardSvc.findPreviousStep(data.currentStep));
  }

  handleNextStep = (data: any) => {
    this.wizardSvc.findNextStep(data);
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

  updateStep = (newStep: number): void => { this.currentStep = newStep; }

  closeDialog = (): void => { this.display = false };

}
