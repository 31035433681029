import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {sortBy as _sortBy, uniqBy as _uniqBy} from 'lodash'
import {DATES_FORMAT, GEMRCN_OPERATORS} from '../../constants';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import {ModeleNutritionnelDetailDTO} from '../../dtos/modele-nutritionnel-detail-dto';
import {RegleGemrcnSupplier} from '../../../shared/ui/regle-gemrcn/regle-gemrcn-supplier';

@Injectable()
export class CalculGemrcnService {


  /**
   * Vérifier que la date GEMRCN est cohérent par rapport aux jours semaines du contrat menu convive
   * @param {moment.Moment} date
   * @param {{[p: number]: {js: number; deltaNextJs: number}}} mapJoursSemaine
   * @returns {boolean}
   */
  private isDateGemrcnOk(date: moment.Moment, mapJoursSemaine: { [key: number]: { js: number, deltaNextJs: number } }): boolean {

    if (date && mapJoursSemaine) {
      for (let key of Object.keys(mapJoursSemaine)) {
        if (mapJoursSemaine[key].js === date.clone().isoWeekday()) {
          return true;
        }
      }
    }

    return false;
  }


  //on recalcule la currentStartDate si elle n'est pas comprise dans les jours semaines
  private getRealStartDate(startDate: moment.Moment, joursSemaine: number[]): moment.Moment {
    let jourSemaineOfStartDate: number = startDate.clone().isoWeekday();
    let arr = joursSemaine.filter(js => js === jourSemaineOfStartDate);
    if (arr && arr[0]) {
      return startDate.clone();
    }
    // la start date n'a pas été trouvée dans la liste des jours disponibles,
    return undefined;
  }

  /**
   * Map utilitaire des jours semaines
   * @param {number[]} joursSemaine
   * @returns {{[p: number]: {js: number; deltaNextJs: number}}}
   */
  private getMapJoursSemaine(joursSemaine: number[]): { [key: number]: { js: number, deltaNextJs: number } } {

    joursSemaine = _uniqBy(joursSemaine, undefined);
    joursSemaine = _sortBy(joursSemaine);

    let mapJoursSemaine: { [key: number]: { js: number, deltaNextJs: number } } = {};

    for (let i: number = 0; i < joursSemaine.length; i++) {
      let js = joursSemaine[i];
      let deltaNextJs = 0;

      if (i < joursSemaine.length - 1) {
        deltaNextJs = joursSemaine[i + 1] - js;
      } else if (i > 0) {
        deltaNextJs = 7 - joursSemaine[i] + joursSemaine[0];
        mapJoursSemaine[i] = {js, deltaNextJs};
      } else {
        deltaNextJs = 7;
      }
      mapJoursSemaine[i] = {js, deltaNextJs};
    }


    console.log('mapJoursSemaine', mapJoursSemaine);

    return mapJoursSemaine;
  }

  /**
   * Recuperer les menus compos rattachés à ce modele nutritionnel detail
   * @param {MenuCompositionDTO[]} menusCompos
   * @param {ModeleNutritionnelDetailDTO} modeleNutriDetail
   * @returns {MenuCompositionDTO[]}
   * @deprecated
   */
  private getMenusComposOfModeleNutriDetail(menusCompos: MenuCompositionDTO[], modeleNutriDetail: ModeleNutritionnelDetailDTO, enableChoixMultiple: boolean): MenuCompositionDTO[] {
    let mcsOfModeleNutri: MenuCompositionDTO[] = [];

    return mcsOfModeleNutri;
  }

  /**
   * On verifie la regle
   * @param {string} typeValeur operateur de comparaison
   * @param {number} numerator seuil de comparaison
   * @param {number} countMc nombre de menus compos
   */
  private isRegleGemrcnOk(typeValeur: string, numerator: number, countMc: number) {
    let isOk = false;

    //cas du min
    if (typeValeur === GEMRCN_OPERATORS.MIN.value) {
      if (countMc >= numerator) {
        isOk = true;
      }
    }
    //cas du max
    else if (typeValeur === GEMRCN_OPERATORS.MAX.value) {
      if (countMc <= numerator) {
        isOk = true;
      }
    }
    //cas de l'egalité
    else if (typeValeur === GEMRCN_OPERATORS.EGAL.value) {
      if (countMc === numerator) {
        isOk = true;
      }
    }
    //on a comptabilisé, en mode libre c'est toujours bon, utilisé à titre d'info
    else if (typeValeur === GEMRCN_OPERATORS.LIBRE.value) {
      isOk = true;
    }

    return isOk;
  }

  /**
   * Calcul de la regle GEMRCN
   * @param {MenuCompositionDTO[]} menusCompos
   * @param {ModeleNutritionnelDetailDTO} modeleNutriDetail
   * @returns {RegleGemrcnSupplier}
   * @param nrs nombre de repas parametre dans le contrat convive (nrs=nb repas seuil)
   * @param enableChoixMultiple si true, calcul pour le choix multiple, si false calcul pour le choix dirigé -> on compte seulement les menus compos d'ordre 1
   * @param nbRepasPeriode
   */
  computeRegleGemrcn(nrs: number, nbRepasPeriode: number, menusCompos: MenuCompositionDTO[], modeleNutriDetail: ModeleNutritionnelDetailDTO, enableChoixMultiple: boolean): RegleGemrcnSupplier {

    //nb repas periode
    const nrp = nbRepasPeriode;
    const ratio_nrp_nrs = nrp / nrs;
    //nombre de menus compos rattaches à ce modele nutri detail
    const mcsOfModeleNutri = this.getMenusComposOfModeleNutriDetail(menusCompos, modeleNutriDetail, enableChoixMultiple);
    const countMc = mcsOfModeleNutri.length;

    const rNumerator = modeleNutriDetail.valeur * ratio_nrp_nrs;
    const rDenominator = nrs * ratio_nrp_nrs;
    let isOk = this.isRegleGemrcnOk(modeleNutriDetail.typeValeur, rNumerator, countMc);

    // TODO GEMRCN

    const rgs = new RegleGemrcnSupplier(modeleNutriDetail.familleGemrcn, rNumerator, rDenominator, modeleNutriDetail.typeValeur,undefined);
    rgs.counted = countMc;
    rgs.isOk = isOk;
    rgs.nrp = nrp;
    rgs.nrs = nrs;
    rgs.menusCompos = mcsOfModeleNutri;

    return rgs;
  }

  /**
   * Récupérer l'indice du jour semaine par rapport à une date passée en paramètre
   * @param {moment.Moment} date
   * @param {{[p: number]: {js: number; deltaNextJs: number}}} mapJoursSemaine
   * @returns {number} renvoie l'indice 0 si rien trouvé
   */
  private getIdxJourSemaine(date: moment.Moment, mapJoursSemaine: { [key: number]: { js: number, deltaNextJs: number } }): number {

    for (let key of Object.keys(mapJoursSemaine)) {
      if (mapJoursSemaine[key].js === date.clone().isoWeekday()) {
        return +key;
      }
    }

    return 0;
  }

  /**
   * Est ce qu'on a trouvé la periode GEMRCN qui correspond à la date passée en paramètre?
   * @param {moment.Moment} startDate
   * @param {Date[]} periodDates
   * @returns {boolean}
   */
  private isPeriodGemrcnFound(startDate: moment.Moment, periodDates: Date[]): boolean {

    //on verifie que les objets passés en paramètre sont correctes
    if (periodDates && periodDates.length > 0 && startDate) {

      const sd = startDate.clone();
      const startPeriod = moment(periodDates[0]).clone();
      const endPeriod = moment(periodDates[periodDates.length - 1]).clone();
      //est ce que la date courante est dans la période calculée. Les [] veulent dire inclusif sur les bornes de debut et de fin.
      if (sd.isBetween(startPeriod, endPeriod, null, '[]')) {
        return true;
      }
    }

    return false;
  }

  /***
   * Calcul de la période GEMRCN par récursivité
   *  Exemple :
   *   dateDebGemrcn = 07/02/2018
   *   currentStartDate = 14/02/2018
   *   mapJoursSemaine = [ {js:1,deltaNextJs:2},{js:3,deltaNextJs:1},{js:4,deltaNextJs:4} ]
   *   periodGemrcn = 5
   *   PeriodGemrcnSupplier pgs sera : [ 07/08/2012 , 08/02/2018, 12/02/2018, 14/02/2018, 15/02/2018]
   * @param {moment.Moment} dateDebGemrcn
   * @param {moment.Moment} startDate
   * @param {number} periodGemrcn
   * @param {{[p: number]: {js: number; deltaNextJs: number}}} mapJoursSemaine
   * @returns {PeriodeGermcnSupplier}
   */
  private getPeriodGemrcnByRecursion(dateDebGemrcn: moment.Moment, startDate: moment.Moment, periodGemrcn: number, mapJoursSemaine: { [key: number]: { js: number, deltaNextJs: number } }): PeriodeGermcnSupplier {

    //initialisation par défaut de la période
    let pgs: PeriodeGermcnSupplier = new PeriodeGermcnSupplier(new Date(), new Date(), new Date(), []);

    let mapLength = Object.keys(mapJoursSemaine).length;

    //récupérer l'indice jour semaine pour la date de debut gemrcn
    let idxJs = this.getIdxJourSemaine(dateDebGemrcn, mapJoursSemaine);
    let dateIdx = dateDebGemrcn.clone().toDate();
    let periodDates: Date[] = [];

    //On parcours le nombre de jours de la periode GEMRCN
    for (let i = 0; i < periodGemrcn; i++) {

      //on repart à l'indice 0 de la map jour semaine, si on a parcouru tous les jours semaine de la map
      if (i > 0 && idxJs >= mapLength) {
        idxJs = 0;
      }

      // on initialise la periode avec la dateDebGemrcn pivot
      if (i === 0) {
        periodDates.push(dateIdx);
      } else {
        //on ajoute le jour semaine suivant grace au deltaNext Jour Semaine en date réelle
        let tmpDateIdx = moment(dateIdx).clone().add(mapJoursSemaine[idxJs].deltaNextJs, 'days');
        periodDates.push(tmpDateIdx.toDate());

        //on incrémente le pivot à la nouvelle date ajoutée
        dateIdx = tmpDateIdx.clone().toDate();

        //on incremente l'indice jourSemaine utilisé dans mapJourSemaine
        idxJs++;
      }
    }

    //est ce que la periode a été trouvée ?
    if (this.isPeriodGemrcnFound(startDate, periodDates)) {
      console.log('start date, found with period', startDate.clone().format(DATES_FORMAT.DD_MM_YYYY), periodDates);
      pgs.periodDates = periodDates;
    } else {

      let lastDateOfPeriod = moment(periodDates[periodDates.length - 1]).clone();
      let idxJs = this.getIdxJourSemaine(lastDateOfPeriod, mapJoursSemaine);
      let newDateDebGemrcn = lastDateOfPeriod.clone().add(mapJoursSemaine[idxJs].deltaNextJs, 'days');
      console.log('new search will be launched, last period date, newDateDebGemrcn', lastDateOfPeriod.format(DATES_FORMAT.DD_MM_YYYY), newDateDebGemrcn.format(DATES_FORMAT.DD_MM_YYYY));

      //on relance une recherche de periode
      this.getPeriodGemrcnByRecursion(newDateDebGemrcn.clone(), startDate, periodGemrcn, mapJoursSemaine);
    }


    return pgs;
  }

  /**
   * Calcul les bornes de début et de fin d'une période Gemrcn par rapport à une date de début
   * Exemple :
   *   dateDebGemrcn = 10/10/2018
   *   currentStartDate = 12/11/2018
   *   periodGemrcn = 20
   *   PeriodGemrcnSupplier sera : 31/10/2018, 19/11/2018
   * @param {Date} dateDebGemrcn
   * @param {Date} startDate
   * @param {number} periodGemrcn
   * @returns {PeriodeGermcnSupplier}
   * @param joursSemaine tableau des jours semaines à prendre en compte 1=Lundi, 7=Dimanche
   */
  getCurrentPeriodeGemrcn(dateDebGemrcn: Date, startDate: Date, periodGemrcn: number, joursSemaine: number[]): PeriodeGermcnSupplier {

    let pgs: PeriodeGermcnSupplier = new PeriodeGermcnSupplier(new Date(), new Date(), new Date(), []);

    const mStartDate = moment(startDate);
    const mDateDebGemrcn = moment(dateDebGemrcn);
    const mapJoursSemaine = this.getMapJoursSemaine(joursSemaine);


    //verif date de debut est bien dans la liste des jours semaines
    let realStartDate = this.getRealStartDate(mStartDate, joursSemaine);
    if (!realStartDate) {
      pgs.inError = true;
      pgs.errorMsg = `La date de début de période  ${mStartDate.clone().format(DATES_FORMAT.DD_MM_YYYY)} n'est pas  cohérente aves les jours semaines disponibles ${joursSemaine.join(',')}`;
      return pgs;
    }

    //Pour eviter une infinite loop dans la recursion, on verifie que la periode n'est pas egale à 0
    if (periodGemrcn <= 0) {
      pgs.inError = true;
      pgs.errorMsg = `La période du contrat doit être supérieure ou égale à 1 jour.`;
      return pgs;
    }

    //verif date de debut est supérieur ou égale à la dateDebGemrcn
    if (realStartDate.isBefore(mDateDebGemrcn)) {
      pgs.inError = true;
      pgs.errorMsg = `Date courante ${mStartDate.clone().format(DATES_FORMAT.DD_MM_YYYY)} inférieure à la date de début du GEMRCN ${mDateDebGemrcn.clone().format(DATES_FORMAT.DD_MM_YYYY)}`;
      return pgs;
    }

    //verif dateDebGemrcn est cohérente par rapport aux jours semaines
    if (!this.isDateGemrcnOk(mDateDebGemrcn, mapJoursSemaine)) {
      pgs.inError = true;
      pgs.errorMsg = `Date de début du GEMRCN ${mDateDebGemrcn.clone().format(DATES_FORMAT.DD_MM_YYYY)} non valide car pas dans la liste des jours semaines ${joursSemaine}`;
      return pgs;
    }

    //on recherche la période GEMRCN et on la renvoie si elle est trouvée
    return this.getPeriodGemrcnByRecursion(mDateDebGemrcn, realStartDate, periodGemrcn, mapJoursSemaine);

  }

}

export class PeriodeGermcnSupplier {
  startDate: Date;
  currentDate: Date;
  stopDate: Date;
  periodDates: Date[] = [];
  inError: boolean;
  errorMsg: string;


  constructor(startDate: Date, currentDate: Date, stopDate: Date, periodDates: Date[]) {
    this.startDate = startDate;
    this.stopDate = stopDate;
    this.currentDate = currentDate;
    this.periodDates = periodDates;
  }
}
