import {Component, OnInit, ViewChild} from '@angular/core';
import {FournisseurDTO} from '../../../../../../core/dtos/fournisseur-d-t-o';
import {GestionLotMarcheService} from '../../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {DxDataGridComponent} from 'devextreme-angular';

@Component({
  selector: 'yo-dialog-priorisation-fournisseur',
  templateUrl: './dialog-priorisation-fournisseur.component.html',
  styleUrls: ['./dialog-priorisation-fournisseur.component.scss']
})
export class DialogPriorisationFournisseurComponent implements OnInit {

  @ViewChild('dataGridFournisseur') grid: DxDataGridComponent;

  displayDialog: boolean;
  fournisseurList: FournisseurDTO[] = [];
  dataSource : any;

  constructor(private gestionLotMarcheSvc: GestionLotMarcheService) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.gestionLotMarcheSvc.displayPrioritizationFournisseur$.subscribe(response => {
      this.displayDialog = response.isDisplay;
      this.fournisseurList = response.fournisseurList;
      this.fournisseurList = this.setOrderFournisseur(this.fournisseurList);

      this.dataSource = {
        store: this.fournisseurList,
        reshapeOnPush: true
      };
    });
  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  onReorder = e => {
    console.log(e);
    const visibleRows = e.component.getVisibleRows(),
      toIndex = this.fournisseurList.indexOf(visibleRows[e.toIndex].data),
      fromIndex = this.fournisseurList.indexOf(e.itemData);

    this.fournisseurList.splice(fromIndex, 1);
    this.fournisseurList.splice(toIndex, 0, e.itemData);
    this.fournisseurList = this.setOrderFournisseur(this.fournisseurList);
  };

  setOrderFournisseur = (fournisseurList: FournisseurDTO[]) => {
    let orderNumber = 1 ;
    fournisseurList.forEach( (item : FournisseurDTO) =>{
      item.ordre = orderNumber;
      orderNumber++;
    });
    return fournisseurList;
  };
}
