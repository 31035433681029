<div class="d-flex">
  <div class="mg-t-5" *ngIf="modificationManuelle">
    <i class="fas fa-square col-edited-color mg-r-5 border"></i>Modification manuelle
  </div>
  <div class="mg-t-5" *ngIf="colonneModifiable">
    <i class="fas fa-square col-editable-color mg-l-20 mg-r-5 border"></i>Colonne modifiable
  </div>
  <div class="mg-t-5" *ngIf="colonneInformations">
    <i class="fas fa-square col-info-color mg-l-20 mg-r-5 border"></i>Colonne avec informations supplémentaires
  </div>
  <div class="mg-t-5" *ngIf="colonneObligatoire">
    <i class="fas fa-square col-madatory-color mg-l-20 mg-r-5 border"></i>Colonne obligatoire
  </div>
  <div class="mg-t-5" *ngIf="celluleActive">
    <i class="fas fa-square col-cell-checked mg-l-20 mg-r-5 border"></i>Actif
  </div>
  <div class="mg-t-5" *ngIf="celluleInactive">
    <i class="fas fa-square col-cell-unchecked mg-l-20 mg-r-5 border"></i>Inactif
  </div>
  <div class="mg-t-5 mg-l-20 mg-r-5" *ngIf="celluleDescription">
    Cliquez sur une cellule afin de passer d'un état à un autre (Actif/Inactif)
  </div>
</div>
