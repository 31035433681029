import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {ProduitDeclinaisonDTO} from "../../dtos/produit-declinaison-dto";
import {GenericRequestSupplier, Predicat, Search, Sort} from "../../suppliers/generics/generic-request-supplier";
import {PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from "../../constants";
import {DevextremeService, FilterItem} from "../technique/devextreme.service";
import {FK_PRODUIT_DECLI, ProduitDeclinaisonService} from "../entities/produit-declinaison.service";
import {UtilsService} from "../../utils/utils.service";
import {ProduitsService} from "../entities/produits.service";
import {ProduitDTO} from "../../dtos/produit-dto";
import {HttpService} from "../technique/http.service";

@Injectable({
  providedIn: 'root'
})
export class ProduitDeclinaisonDenreeService {

  constructor(private produitDeclinaisonSvc : ProduitDeclinaisonService,
              private produitSvc : ProduitsService,
              private httpSvc : HttpService,
              private utils: UtilsService,
              private dxSvc: DevextremeService){}

  private openDialogListplatUsinsDenree = new Subject<ProduitDeclinaisonDTO>();
  openDialogListplatUsinsDenree$ = this.openDialogListplatUsinsDenree.asObservable();

  private refreshDialogListPlatGrid = new Subject<boolean>();
  refreshDialogListPlatGrid$ = this.refreshDialogListPlatGrid.asObservable();

  private openDialogReplaceDenree = new Subject<any>();
  openDialogReplaceDenree$ = this.openDialogReplaceDenree.asObservable();

  announceOpenDialogListplatUsinsDenree = (rowData: ProduitDeclinaisonDTO) => {
    this.openDialogListplatUsinsDenree.next(rowData);
  };

  announceRefreshDialogListPlatGrid = (hastoRefresh :boolean) => {
    this.refreshDialogListPlatGrid.next(hastoRefresh);
  };

  announceOpenDialogReplaceDenree = (selectedPlatList :ProduitDTO[], produitUtilise: ProduitDeclinaisonDTO) => {
    const wrapper ={
      selectedPlatList :selectedPlatList,
      produitUtilise :produitUtilise
    };
    this.openDialogReplaceDenree.next(wrapper);
  };

  saveReplaceDenreewithRatio = (platIdList : number[], oldDenree : ProduitDeclinaisonDTO, newDenree : ProduitDeclinaisonDTO, ratio : number) => {

    const fd = new FormData();
    fd.set('oldDenreeId', oldDenree.id + '');
    fd.set('newDenreeId', newDenree.id + '');
    fd.set('ratio', ratio + '');
    fd.set('platIdList', platIdList ? platIdList.join(',') : '');

    return this.httpSvc.post(`dolrest/gestionproduits/replace-denree-into-plat-list-with-ratio`, fd);
  };

  filterDenreeList = (page: number, pageSize: number, filters: FilterItem[], sorts: Sort[]) => {
    const grs = new GenericRequestSupplier(this.produitDeclinaisonSvc.getEntityName());
    const search = new Search();


    const rootPath = this.produitDeclinaisonSvc.getEntityName().toLowerCase();

    const predicat1 = new Predicat();
    predicat1.path = `${rootPath}.produit.typeProduit.fabrique`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = PREDICAT_VALUE.False;

    search.predicats = [predicat1];

    if (!this.utils.isCollectionNullOrEmpty(filters)) {


      for (const filter of filters) {

        let path = '';
        switch (filter.column) {
          case FK_PRODUIT_DECLI.libelle:
            path = `${rootPath}.libelle`;
            break;
          case FK_PRODUIT_DECLI.code:
            path = `${rootPath}.code`;
            break;
          case FK_PRODUIT_DECLI.reference:
            path = `${rootPath}.reference`;
            break;
        }


        const predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;

    grs.page = page;
    grs.size = pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case FK_PRODUIT_DECLI.libelle :
            sort.path = `${rootPath}.libelle`;
            break;
          case FK_PRODUIT_DECLI.code :
            sort.path = `${rootPath}.code`;
            break;
          case FK_PRODUIT_DECLI.reference :
            sort.path = `${rootPath}.reference`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;

    return grs;

  };
}
