import {AvoirFournisseurLigneDto} from "./avoir-fournisseur-ligne-dto";
import {PrixDto} from "../../prix-dto";
import {FactureFournisseurDto} from "../factures/facture-fournisseur-dto";
import {AvoirStatusDto} from "./avoir-status-dto";

export class AvoirFournisseurDto {
  id: number;
  statut: string;
  code: String;
  reference: String;
  facture: FactureFournisseurDto;
  montant: PrixDto;
  montantValide: PrixDto;
  commentaire: String;
  lignes: AvoirFournisseurLigneDto[] = [];

  public static getAllProperties = (): String => {
    return `
      id,
      statut,
      code,
      reference,
      facture {
        ${FactureFournisseurDto.getAllProperties()}
      },
      montant {
        value,
        uniteMonetaire
      },
      montantValide {
        value,
        uniteMonetaire
      },
      commentaire,
      lignes {
        ${AvoirFournisseurLigneDto.getAllProperties()}
      }
    `
  }
}
