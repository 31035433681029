import {Injectable} from '@angular/core';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {AdresseDTO} from '../../dtos/adresse-dto';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {UtilsService} from '../../utils/utils.service';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormControl, FormGroup} from '@angular/forms';
import {SiteDTO} from '../../dtos/site-dto';
import {UnitesDeProductionService} from './unites-de-production.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';

;

@Injectable()
export class AdressesService extends GenericHandler<AdresseDTO> {

  siteReadOnly: boolean = false;

  getHelp = (): DialogMsgSupplier => undefined;


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES ADRESSES';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE ADRESSE';


  constructor(utils: UtilsService,
              private udpSvc: UnitesDeProductionService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName = (): string => 'adresse';

  getFields = (dto: AdresseDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      let site = new SiteDTO();
      dto = new AdresseDTO(site, 0);
    }

    const readonly = !this.canModify(dto);

    let formFields: FormFieldBaseSupplier<any>[] = [

      // Ne pas afficher le libelle site dans les adresses,car DTO utilisé dans d'autre DTOs
      new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        type: 'hidden',
        readonly: true,
        value: dto.site,
        order: 1
      }),

      new FormFieldTextboxSupplier({
        key: 'nom',
        label: 'Nom',
        value: dto.nom,
        maxLength: 100,
        required: false,
        order: 2,
        readonly: readonly,

      }),


      new FormFieldTextboxSupplier({
        key: 'adresse1',
        label: 'Adresse 1',
        value: dto.adresse1,
        maxLength: 100,
        required: false,
        order: 3,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'adresse2',
        label: 'Adresse 2',
        value: dto.adresse2,
        maxLength: 100,
        required: false,
        order: 4,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'adresse3',
        label: 'Adresse 3',
        value: dto.adresse3,
        maxLength: 100,
        required: false,
        order: 5,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'telephone',
        label: 'Téléphone',
        value: dto.telephone,
        maxLength: 20,
        required: false,
        order: 6,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'fax',
        label: 'Fax',
        value: dto.fax,
        maxLength: 20,
        required: false,
        order: 7,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'codePostal',
        label: 'Code Postal',
        value: dto.codePostal,
        maxLength: 5,
        required: false,
        order: 8,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'ville',
        label: 'Ville',
        // minLength: 2,
        maxLength: 50,
        value: dto.ville,
        required: false,
        order: 9,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'email',
        label: 'Email',
        maxLength: 50,
        value: dto.email,
        required: false,
        order: 10,
        readonly: readonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 11
      })
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  /**
   * On compare sur le code postal, la ville et l'adresse1
   * @param {AdresseDTO} adresse1
   * @param {AdresseDTO} adresse2
   * @returns {boolean}
   */
  isSameAddress = (adresse1: AdresseDTO, adresse2: AdresseDTO) => {
    if (!this.utils.isNullOrEmpty(adresse1) && !this.utils.isNullOrEmpty(adresse2)) {
      return adresse1.codePostal === adresse2.codePostal && adresse1.ville === adresse2.ville && adresse1.adresse1 === adresse2.adresse1;
    }
    return false;
  };

  /**
   *Cabler un formulaire Reactive Adresse en dto adresse
   * @param adresseFormGroup
   * @param entityName nom de l'entité à mettre dans l'entityName de la table adresses
   * @return {AdresseDTO}
   */
  prepareAdresseForSave = (adresseFormGroup: FormGroup, entityName: string) => {
    let site = new SiteDTO();
    let adresseToSave = new AdresseDTO(site, 0);

    Object.keys(adresseFormGroup.controls).forEach(field => {
      const control = adresseFormGroup.get(field);
      if (control instanceof FormControl) {
        adresseToSave[field] = control.value;
      }
    });

    adresseToSave.entityName = entityName;

    return adresseToSave;
  };


  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): AdresseDTO => {
    // return new AdresseDTO(this.sitePrincipalId,this.sitePrincipalLibelle,0);
    return new AdresseDTO(null, 0);
  };

  getEditObjectLabel = (data: ObjectDTO): string => "";

  /**
   * NON utilisée mais à garder
   * @param site
   */
  getSite = (site: SiteDTO): SiteDTO => {
    let theSite = this.utils.preSelectSingleList(this.optionsSites, site);
    return theSite;
  };
}
