import {ObjectDTO} from "./object-dto";

export class UniteDeProduction__LivraisonDTO extends ObjectDTO {

  idSecteurFournisseur : number;
  numeroJourSemaine : number;

  //udp secteur fournisseur
  codeClient : string;
  delaiLivraisonHeureLimite:Date;
  delaiLivraisonJours:number;



  //transient
  actif:boolean=true;

}
