import {ObjectDTO} from "../object-dto";
import {PointDeLivraisonDTO} from "../point-de-livraison-d-t-o";

export class Environnement_PlcDTO extends ObjectDTO {

  libelle: string;
  code: string;
  descriptif: string;
  actif: boolean;

  pointDeLivraisonList: PointDeLivraisonDTO[] = [];
}
