<div class="d-flex flex-column " [style.max-width.px]="1000">

  <div [style.max-height.px]="utils.getWindowAvailableHeight(200)" style="overflow-y:auto;overflow-x:hidden">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':'980px'}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        ️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
      </div>
      <div class="mg-r-5">
        <yo-button iconClass="fas fa-save" (onClick)="saveFicheIdentite()" buttonClass="cta-success"
                   [disabled]="!canModify()" tooltip="Enregistrer"></yo-button>
      </div>
      <div>
        <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="utils.sidenav=false"
                buttonClass="cta-inner-delete"></yo-button>
      </div>
    </div>
  </div>

</div>






