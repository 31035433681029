<p-dialog
  [dismissableMask]="false"
  [showHeader]="true"
  (onHide)="closeDialog()"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'width': '22vw'}">
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-clipboard mg-r-5"></i> Remarque permanente pour les commandes de la
      filiale {{selectedUdpSecteurFournisseur.fournisseurLibelle}} ({{selectedUdpSecteurFournisseur.secteurLibelle}}).
    </div>
  </ng-template>

  <textarea id="textRemarque" maxlength="250"
            [(ngModel)]="selectedUdpSecteurFournisseur.remarqueCommande" pInputTextarea></textarea>


  <div class="d-flex justify-content-end mg-t-10">
    <div>
        <span class="mg-r-5">
          <yo-button buttonClass="cta-success" (onClick)="saveRemarque()" iconClass="fas fa-save"></yo-button>
        </span>
      <yo-button iconClass="fas fa-times"
                 buttonClass="cta-delete"
                 (onClick)="closeDialog()">
      </yo-button>

    </div>
  </div>
</p-dialog>
