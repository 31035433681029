import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {combineLatest, Observable} from 'rxjs';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../../core/suppliers/generics/generic-request-supplier';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {FamilleProduitDTO} from '../../../core/dtos/famille-produit-dto';
import {ModeleNutritionnel__FamillePlatDTO} from '../../../core/dtos/modele-nutritionnel__famille-plat-dto';
import {uniqBy as _uniqBy} from 'lodash';
import {ModeleNutritionnelPlanningDTO} from '../../../core/dtos/modele-nutritionnel-planning-dto';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../../core/constants";

@Injectable({
  providedIn: 'root'
})
export class FamillesPlatsResolverService implements Resolve<FamillePlatSupplier> {

  constructor(private gds: GenericDatagridService,
              private famillesProduitService: FamillesProduitService,
              private utils: UtilsService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FamillePlatSupplier> | Promise<FamillePlatSupplier> | FamillePlatSupplier => {

    const vns = new FamillePlatSupplier();

    const idModeleNutri: number = +route.parent.paramMap.get('idModeleNutri');
    const famillesPlats$ = this.gds.getAll(this.famillesProduitService.getEntityName(), this.famillesProduitService.getSort(), true);
    const grilleFamillesPlats$ = this.gds.search(this.filterGrilleFamillesPlats(idModeleNutri));
    const grillePlanning$ = this.gds.search(this.filterGrillePlanning(idModeleNutri));

    const initAll$ = combineLatest([famillesPlats$, grillePlanning$, grilleFamillesPlats$]);

    return initAll$.toPromise().then(response => {
      vns.famillesPlats = response[0].resultList;
      // supprimer toutes les familles incohérentes de winapro
      vns.famillesPlats = this.famillesProduitService.removeChildren(vns.famillesPlats);
      vns.grillePlanning = response[1].resultList;
      vns.grilleFamillesPlats = response[2].resultList;
      // preselectionner les listes deroulantes repas, composante repas, familles gemrcn
      this.preselectElements(vns);

      return vns;
    });

    return undefined;
  };

  filterGrillePlanning = (idModeleNutri: number): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier('ModeleNutritionnelPlanning');

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'modelenutritionnelplanning.modeleNutritionnel.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idModeleNutri + '';

    search.predicats.push(predicat1);
    grs.search = search;

    return grs;
  };

  /**
   * On preselectionne les familles plats, repas et composantes
   * @param vns
   */
  preselectElements = (vns: FamillePlatSupplier) => {

    let famillesPlats: FamilleProduitDTO[] = [];
    let repasList: RepasDTO[] = [];
    let composantesRepas: DecoupageRepasDTO[] = [];

    vns.selectedFamillesPlats = [];
    vns.selectedComposantesRepas = [];
    vns.selectedRepasList = [];

    if (!this.utils.isCollectionNullOrEmpty(vns.grilleFamillesPlats)) {

      // on recupere les familles de la grille des familles de plat
      for (let row of vns.grilleFamillesPlats) {
        famillesPlats.push(row.familleProduit);
      }

      // on supprime les doublons
      if (famillesPlats) {
        famillesPlats = _uniqBy(famillesPlats, 'id');
        famillesPlats.forEach(item => vns.selectedFamillesPlats.push(item))
      }
    }

    // on recupere les repas et composantes repas du planning
    if (!this.utils.isCollectionNullOrEmpty(vns.grillePlanning)) {
      for (let row of vns.grillePlanning) {
        composantesRepas.push(row.decoupageRepas);
        repasList.push(row.repas);
      }
      if (composantesRepas) {
        composantesRepas = _uniqBy(composantesRepas, 'id');
        composantesRepas.forEach(item => vns.selectedComposantesRepas.push(item))
      }
      if (repasList) {
        repasList = _uniqBy(repasList, 'id');
        repasList.forEach(item => vns.selectedRepasList.push(item))
      }
    }
  };

  filterGrilleFamillesPlats = (idModeleNutri: number): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier('ModeleNutritionnel__FamillePlat');

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'modelenutritionnel__familleplat.modeleNutritionnel.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idModeleNutri + '';

    search.predicats.push(predicat1);

    grs.search = search;

    return grs;
  };
}

export class FamillePlatSupplier {
  famillesPlats: FamilleProduitDTO[] = [];
  grilleFamillesPlats: ModeleNutritionnel__FamillePlatDTO[] = [];
  grillePlanning: ModeleNutritionnelPlanningDTO[] = [];

  selectedFamillesPlats: FamilleProduitDTO[] = [];
  selectedRepasList: RepasDTO[] = [];
  selectedComposantesRepas: DecoupageRepasDTO[] = [];
}
