import {Component, OnInit} from "@angular/core";
import FraisDePortDTO from "../../../../../core/dtos/frais-de-port-dto";
import {Subscription} from "rxjs";
import {CommandesService} from "../../../../../core/services/gestion-commandes/commandes.service";
import {PossibleDeliveryDatesCommandePropositionModel} from "../../../../../core/models/gestion-commande-fournisseur/possible-delivery-dates-commande-proposition-model";

@Component({
  selector: 'yo-possible-delivery-dates',
  templateUrl: './possible-delivery-dates-proposition-commande.component.html',
  styleUrls: ['./possible-delivery-dates-proposition-commande.component.scss']
})
export class PossibleDeliveryDatesPropositionCommandeComponent implements OnInit {

  displayDialog: boolean = false;
  subPossibleDeliveryDates: Subscription;
  possibleDeliveryDates: PossibleDeliveryDatesCommandePropositionModel[];

  constructor(private commandesSvc: CommandesService) {
  }

  ngOnInit(): void {
    this.possibleDeliveryDatesSubscription();
  }

  possibleDeliveryDatesSubscription = () => {
    this.subPossibleDeliveryDates = this.commandesSvc.possibleDeliveryDates$.subscribe((items: PossibleDeliveryDatesCommandePropositionModel[]) => {
      this.possibleDeliveryDates = items;
      this.displayDialog = true;
    })
  }

  closeDialog = () => this.displayDialog = false;
}
