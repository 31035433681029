import {Injectable} from "@angular/core";
import {Observable,} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {UtilsService} from "../../utils/utils.service";
import {MotifNonConformiteDTO} from "../../dtos/motif-non-conformite-dto";

export const URL_GET_ALL = 'dolrest/motif-non-conformite/all';

@Injectable({
  providedIn: 'root'
})
export class MotifNonConformiteService {

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère tous les motifs de non conformité
   * @returns {Observable<MotifNonConformiteDTO>}
   */
  getAll = (): Observable<ResponseWrapper<MotifNonConformiteDTO>> => this.httpSvc.get(URL_GET_ALL, null);
}
