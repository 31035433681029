<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  (onHiding)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div *ngIf="forUpdate && typeTache?.site || !forUpdate" class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Site <span class="danger-color">*</span></div>
      <div *ngIf="canModify(); else elseBlock" class="col-md-8">
        <dx-lookup
          [value]="siteSelected?.id"
          [items]="sitesList"
          formControlName="site"
          placeholder="Sélectionner un site"
          valueExpr="id"
          displayExpr="libelle"
          searchExpr="libelle"
        >
        </dx-lookup>
      </div>

      <ng-template #elseBlock>
        <div class="col-md-8">
          <yo-site [siteLibelle]="typeTache.site?.libelle"
                   [siteId]="typeTache.site?.id"></yo-site>
        </div>
      </ng-template>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Libellé <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="input-group-sm">
          <dx-text-box [disabled]="!canModify()" placeholder="Prise de température" formControlName="libelle"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="p-2 d-flex justify-content-end">
      <yo-button
        buttonClass="cta-success"
        type="submit"
        iconClass="fas fa-save"
        title="Enregistrer"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
      </yo-button>
      <yo-button
        class="ml-3"
        buttonClass="cta-inner-delete"
        type="button"
        title="Fermer"
        iconClass="fas fa-times"
        (onClick)="closeDialog()">
      </yo-button>
    </div>
  </form>
</dx-popup>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
