import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {TypesProduitService} from './types-produit.service';
import {FamillesProduitService} from './familles-produit.service';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {DeclinaisonsService} from './declinaisons.service';
import {TaxesService} from './taxes.service';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {SiteDTO} from '../../dtos/site-dto';
import {ProduitDTO} from '../../dtos/produit-dto';
import {cloneDeep as _cloneDeep, find as _find, startCase as _startCase} from 'lodash';

import {FormFieldCurrencySupplier} from '../../suppliers/form-field-currency-supplier';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {
  MSG_KEY,
  MSG_SEVERITY,
  PREDICAT_DIR,
  PREDICAT_OPERATOR,
  PREDICAT_TYPE,
  PREDICAT_VALUE,
  STOCKS_FEATURES,
  TYPES_CODES
} from '../../constants';
import {FormGroup} from '@angular/forms';
import {UniteDeMesureDTO} from '../../dtos/unitedemesure-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {ProduitsService} from './produits.service';
import {ProduitRouteMapService} from '../gestionproduits/produit-routemap.service';
import {FormFieldPrimengEditorSupplier} from '../../suppliers/form-field-primeng-editor';
import {DeclinaisonDTO} from '../../dtos/declinaison-dto';
import {ZonesDeStockageService} from './zones-de-stockage.service';
import {UniqueCodeService} from '../unique-code.service';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {ModelViewMatierePremiere} from '../../../gestion-stocks/stocks/matieres-premieres/model-view-matiere-premiere';
import {ModelViewInventaire} from '../../../gestion-stocks/stocks/inventaire/model-view-inventaire';
import {LazyLoadEvent, TreeNode} from 'primeng/api';
import {ContrainteModel} from '../../models/contrainte-model';
import {RegimeAlimentaireService} from './regime-alimentaire.service';
import {ProduitDeclinaisonContrainteAlimDTO} from '../../dtos/produit-declinaison-contrainte-alim-dto';
import {switchMap} from 'rxjs/internal/operators';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {TachesService} from './taches.service';
import {TacheDTO} from '../../dtos/tache-dto';
import {ProduitDeclinaison__TacheDTO} from '../../dtos/produit-declinaison-tache-dto';
import {TYPE_TACHE} from './type-tache.service';
import {HttpService} from '../technique/http.service';
import {saveAs as fs_saveAs} from "file-saver";
import {ToastService} from "../technique/toast.service";
import {CatalogueAchatDTO} from "../../dtos/catalogue-achat-dto";

export const URL_SAVE_PRODUITDECLINAISON = `dolrest/gestionproduits/produit-declinaison/save`;
export const URL_SAVE_PRODUITDECLINAISON_LIST = `dolrest/gestionproduits/produit-declinaison/save-list`;
export const URL_DUPLIQUE_DECLINAISON = `dolrest/gestionproduits/produit-declinaison/duplique`;
export const URL_GET_DENREES = `dolrest/gestionproduits/produitdeclinaison/typeProduit/fabrique`;
export const FIELD_RATIO_UNITE_TECHNIQUE = 'ratioUniteTechnique';
export const FIELD_INVERSE_RATIO_UNITE_TECHNIQUE = 'inverseRatioTechnique';
export const FIELD_LIBELLE = 'libelle';
export const FIELD_UNITE_DE_MESURE = 'uniteDeMesure';
export const WARNING_UNITE_DE_MESURE = 'Attention si vous modifiez cette unité de mesure pensez à mettre à jour les ratios ci-dessous.';
export const URL_SEARCH_LIGNES_INVENTAIRES = `dolrest/gestion-stocks/search/lignes-inventaire`;
export const URL_SEARCH_LIGNES_MATIERES_PREMIERES = `dolrest/gestion-stocks/search/lignes-matieres-premieres`;
export const URL_POST_DELETE_LOTS = `dolrest/gestion-stocks/delete/lots-perimes`;
export const URL_FIND_PLAT_USING_DENREE = `dolrest/gestionproduits/find-plats-list-using-by-denree`;
export const URL_GET_PRODUIT_DECLI_LIST_BY_PRODUIT_ID = `dolrest/gestionproduits/produitsdeclinaisons/findByIdProduit`;
export const URL_GET_FICHE_TECHNIQUE_COMPONENT = `dolrest/gestionproduits/produitdeclinaison/fichetechnique/components`;
export const URL_GET_PRODUIT_NOMENCLATURE_FICHE_TECHNIQUE = `dolrest/gestionproduits/produitnomenclature/fichetechnique/components`;
export const URL_DELETE_BY_ID = `dolrest/gestionproduits/produit-declinaison/delete-by-id`;
export const URL_PRINT_NUTRITIONAL_INFORMATIONS = `dolrest/gestionproduits/produit-declinaison/print-nutritional-informations/`;
export const URL_CHANGE_UNITE_TECHNIQUE_DENREE = `dolrest/gestionproduits/produit-declinaison/change-unite-technique-denree`;
export const URL_SEARCH_DENREE_BY_PARAMS = `dolrest/gestionproduits/produit-declinaison/search-produit-declinaison-with-params`;

export enum FK_PRODUIT_DECLI {
  libelle = 'libelle',
  libellelong = 'libellelong',
  volumeEngagement = 'volumeEngagement',
  code = 'code',
  reference = 'reference',
  codecomptable = 'codecomptable',
  guid = 'guid',
  dlc = 'dlc'
}

@Injectable()
export class ProduitDeclinaisonService extends GenericHandler<ProduitDeclinaisonDTO> {

  static uniteDeMesureInitiale: UniteDeMesureDTO;
  static creatingNewProduitDeclinaison: boolean = false;

  displayDialogRecherchePlats = false;

  private subjectOpenDialogChangeUniteTechnique = new Subject<ProduitDeclinaisonDTO>();
  openDialogChangeUniteTechnique$ = this.subjectOpenDialogChangeUniteTechnique.asObservable();

  private subjectOpenDialogAjoutLot = new Subject<DialogStockAjoutLotSupplier>();
  openDialogAjoutLot$ = this.subjectOpenDialogAjoutLot.asObservable();

  private subjectPaginationSearchStock = new Subject<any>();
  paginationSearchStock$ = this.subjectPaginationSearchStock.asObservable();

  private subjectOpenDialogProduitDeclinaison = new Subject<DialogProduitDeclinaisonSupplier>();
  public openDialogProduitDeclinaison$ = this.subjectOpenDialogProduitDeclinaison.asObservable();

  private subjectResultSearchStocksForInventaire = new Subject<ResponseWrapper<ModelViewInventaire>>();
  resultSearchStocksForInventaire$ = this.subjectResultSearchStocksForInventaire.asObservable();

  private subjectResultSearchStocksForMatieresPremieres = new Subject<ResponseWrapper<ModelViewMatierePremiere>>();
  resultSearchStocksForMatieresPremieres$ = this.subjectResultSearchStocksForMatieresPremieres.asObservable();

  private subjectProduitDecliListDto = new Subject<ProduitDeclinaisonDTO[]>();
  produitDecliListDto$ = this.subjectProduitDecliListDto.asObservable();

  private subjectProduitDeclinaisonListPropCommande = new Subject<CatalogueAchatDTO[]>();
  produitDeclinaisonListPropCommande$ = this.subjectProduitDeclinaisonListPropCommande.asObservable();

  private subjectNewUniteTechnique = new Subject<ProduitDeclinaisonDTO>();
  newUniteTechnique$ = this .subjectNewUniteTechnique.asObservable();

  private subOnSearchStocks = new Subject<void>();
  onSearchStocks$ = this.subOnSearchStocks.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private udmSvc: UnitesDeMesuresService,
              private httpSvc: HttpService,
              private httpClient: HttpClient,
              private declinaisonsSvc: DeclinaisonsService,
              private gds: GenericDatagridService,
              private taxesSvc: TaxesService,
              private typesProduitSvc: TypesProduitService,
              private famillesProduitSvc: FamillesProduitService,
              private zdsSvc: ZonesDeStockageService,
              private produitsService: ProduitsService,
              private uniqueCodeSvc: UniqueCodeService,
              private tacheSvc: TachesService,
              private regimeSvc: RegimeAlimentaireService,
              private produitRouteMapService: ProduitRouteMapService,
              private toastSvc: ToastService) {

    // @ts-ignore
    super(utils, auth2Svc, router, http, title);

  }


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getTitle = (): string => 'GESTION DES DÉCLINAISONS DU PRODUIT';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE DÉCLINAISON DE PRODUIT';

  getEntityName = (): string => 'produitDeclinaison';

  private getFieldsOfDenree = (dto: ProduitDeclinaisonDTO): FormFieldBaseSupplier<any>[] => {

    const declinaisons = this.declinaisonsSvc.getDeclinaisonList(this.declinaisonsSvc.declinaisonEnvironnement, dto.fabrique);
    const isReadonly = !this.canModify(dto);
    let inverseRatioTechnique = ProduitDeclinaisonService.fixedValue(ProduitDeclinaisonService.calculeInverse(dto.ratioUniteTechnique), 4);

    const tacheApprovisionnement = this.getTacheOfProduitDeclinaison(dto, TYPE_TACHE.APPROVISIONNEMENT);
    const tacheSortie = this.getTacheOfProduitDeclinaison(dto, TYPE_TACHE.SORTIE);

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_LIBELLE,
        label: 'Libellé',
        readonly: isReadonly,
        minLength: 2,
        maxLength: 100,
        value: this.calculeLibelle(dto),
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Libellé complet',
        readonly: isReadonly,
        minLength: 2,
        maxLength: 255,
        value: dto.libellelong,
        required: true,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: `Code, utilisé pour communiquer avec d'autres logiciels`,
        readonly: false,
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'codecomptable',
        label: 'Code Comptable',
        readonly: isReadonly,
        value: dto.codecomptable,
        maxLength: 10,
        required: false,
        order: 7
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_RATIO_UNITE_TECHNIQUE,
        // label: 'Ratio unité technique',
        label: `Ratio unité technique (${dto.uniteDeMesure.libelle.toUpperCase()}/KG)`,
        help: `Ratio unité technique (${dto.uniteDeMesure.libelle.toUpperCase()}/ unité universelle (KG)`,
        readonly: isReadonly,
        value: dto.ratioUniteTechnique,
        required: true,
        min: 0.0001,
        width: 150,
        prefix: `1 ${dto.uniteDeMesure.libelle.toUpperCase()} équivaut à`,
        suffix: 'KG',
        type: 'number',

        order: 10,
        linkedFieldFn: this.changeRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_INVERSE_RATIO_UNITE_TECHNIQUE,
        label: `Ratio unité universelle (KG/${dto.uniteDeMesure.libelle.toUpperCase()})`,
        help: `Ratio unité universelle (unité universelle (KG)/${dto.uniteDeMesure.libelle.toUpperCase()})`,
        readonly: isReadonly,
        value: inverseRatioTechnique,
        required: true,
        min: 0.0001,
        width: 150,
        prefix: `1 KG équivaut à`,
        suffix: `${dto.uniteDeMesure.libelle.toUpperCase()}`,
        type: 'number',
        // type: 'externalRef',

        order: 11,
        linkedFieldFn: this.changeInverseRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioVolume',
        label: 'Ratio Volume',
        help: `Ratio volume / masse (Litre / KG)`,
        readonly: isReadonly,
        value: dto.ratioVolume,
        required: ProduitsService.isRatioVolumeRequired(dto.uniteDeMesure),
        width: 150,
        prefix: `1 LITRE équivaut à`,
        suffix: 'KG',
        type: ProduitsService.getTypeRatioVolume(dto.uniteDeMesure),
        order: 12
      }),

      //Todo Le prix sera réactivé plus tard quand le mode de calcul sera valorisé des prix sans avoir les articles

      // new FormFieldCurrencySupplier({
      //   key: 'prixAchat',
      //   label: `Prix d'achat`,
      //   readonly: isReadonly,
      //   value: dto.prixAchat,
      //   required: true,
      //   type: 'number',
      //   width: 100,
      //   suffix: `€ par ${dto.uniteDeMesure.libelle}`,
      //   order: 13
      // }),

      new FormFieldCurrencySupplier({
        key: 'prixDeVente',
        label: 'Prix de vente',
        readonly: isReadonly,
        value: dto.prixDeVente,
        required: false,
        width: 100,
        suffix: `€ par ${dto.uniteDeMesure.libelle}`,
        type: 'number',
        order: 14
      }),

      new FormFieldTextboxSupplier({
        key: 'dlc',
        label: 'Date limite de conso.',
        prefix: '+',
        suffix: 'jour(s)',
        help: 'Privilégier le produit selon sa date de consommation',
        readonly: isReadonly,
        value: dto.dlc,
        required: true,
        width: 100,
        type: 'number',
        order: 17
      }),


      new FormFieldDropdownSupplier({
        key: 'taxe',
        label: 'Taxe',
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: dto.id === 0 ? this.utils.getFirstElement(this.taxesSvc.taxesEnvironnement) : this.utils.preSelectSingleList(this.taxesSvc.taxesEnvironnement, dto.taxe),
        order: 16,
        options: this.taxesSvc.taxesEnvironnement
      }),

      new FormFieldDropdownSupplier({
        key: 'declinaison',
        label: 'Déclinaison',
        help: `Les déclinaisons sont filtrées par rapport au type de produit (denrée ou plat)`,
        required: true,
        readonly: !this.canModify(dto) || dto.id != 0,
        type: 'externalRef',
        value: dto.id === 0 ? this.utils.getFirstElement(declinaisons) : this.utils.preSelectSingleList(declinaisons, dto.declinaison),
        order: 5,
        options: declinaisons,
      }),

      new FormFieldDropdownSupplier({
        key: 'zoneDeStockage',
        label: 'Zone de stockage',
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.zdsSvc.zdsEnvironnement, dto.zoneDeStockage),
        order: 19,
        options: this.zdsSvc.zdsEnvironnement
      }),

      new FormFieldDropdownSupplier({
        key: 'tacheApprovisionnement',
        label: `Type d'approvisionnement`,
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheApprovisionnementList, tacheApprovisionnement),
        order: 20,
        options: this.tacheSvc.tacheApprovisionnementList
      }),

      new FormFieldDropdownSupplier({
        key: 'tacheSortie',
        label: 'Type de sortie',
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheSortieList, tacheSortie),
        order: 21,
        options: this.tacheSvc.tacheSortieList
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Active',
        readonly: isReadonly,
        value: dto.actif,
        required: false,
        order: 22
      }),


      new FormFieldDropdownSupplier({
        key: FIELD_UNITE_DE_MESURE,
        label: 'Unité technique',
        readonly: isReadonly || dto.id != 0,// modificable en creation mais pas en modification
        type: 'externalRef',
        required: true,
        value: this.utils.preSelectSingleList(this.udmSvc.unitesDeMesureEnvironnement, dto.uniteDeMesure),
        order: 9,
        options: this.udmSvc.unitesDeMesureEnvironnement,
        linkedFieldFn: this.changeUniteDeMesure
      }),


    ];


    return formFields;

  };

  private getFieldsOfPlat = (dto: ProduitDeclinaisonDTO): FormFieldBaseSupplier<any>[] => {
    const isReadonly = !this.canModify(dto);
    const contraintesAlim: ContrainteModel[] = this.regimeSvc.getRegimesContraintes();
    let produitContraintesAlim = dto && dto.produitDeclinaisonContrainteAlimList ? dto.produitDeclinaisonContrainteAlimList : [];
    const declinaisons = this.declinaisonsSvc.getDeclinaisonList(this.declinaisonsSvc.declinaisonEnvironnement, dto.fabrique);
    let inverseRatioTechnique = ProduitDeclinaisonService.fixedValue(ProduitDeclinaisonService.calculeInverse(dto.ratioUniteTechnique), 4);

    const tacheFabrication = this.getTacheOfProduitDeclinaison(dto, TYPE_TACHE.FABRICATION);

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_LIBELLE,
        label: 'Libellé',
        // Le libellé d'un produit déclinaison n'est jamais éditable car il est calculé : il est
        // égal au libellé du produit.
        readonly: isReadonly,
        minLength: 2,
        maxLength: 100,
        value: this.calculeLibelle(dto),
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Libellé complet',
        // Le libellé completd'un produit déclinaison n'est jamais éditable car il est calculé : il est
        // égal au libellé du produit.
        // readonly: isReadonly,
        readonly: isReadonly,
        minLength: 2,
        maxLength: 255,
        value: dto.libellelong,
        required: true,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: `Code, utilisé pour communiquer avec d'autres logiciels`,
        readonly: false,
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'codecomptable',
        label: 'Code Comptable',
        readonly: isReadonly,
        value: dto.codecomptable,
        maxLength: 10,
        required: false,
        order: 7
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_RATIO_UNITE_TECHNIQUE,
        // label: 'Ratio unité technique',
        label: `Ratio unité technique (${dto.uniteDeMesure.libelle.toUpperCase()} poids net / KG)`,
        help: `Ratio unité technique (${dto.uniteDeMesure.libelle.toUpperCase()} poids net / unité universelle (KG)`,
        readonly: isReadonly,
        value: dto.ratioUniteTechnique,
        required: true,
        min: 0.0001,
        width: 150,
        prefix: `1 ${dto.uniteDeMesure.libelle.toUpperCase()} équivaut à`,
        suffix: 'KG',
        type: 'number',

        order: 10,
        linkedFieldFn: this.changeRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: FIELD_INVERSE_RATIO_UNITE_TECHNIQUE,
        label: `Ratio unité universelle (KG/${dto.uniteDeMesure.libelle.toUpperCase()})`,
        help: `Ratio unité universelle (unité universelle (KG)/${dto.uniteDeMesure.libelle.toUpperCase()})`,
        readonly: isReadonly,
        value: inverseRatioTechnique,
        required: true,
        min: 0.0001,
        width: 150,
        prefix: `1 KG équivaut à`,
        suffix: `${dto.uniteDeMesure.libelle.toUpperCase()}`,
        type: 'number',
        // type: 'externalRef',

        order: 11,
        linkedFieldFn: this.changeInverseRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioVolume',
        label: 'Ratio Volume',
        help: `Ratio volume / masse (Litre / KG)`,
        readonly: isReadonly,
        value: dto.ratioVolume,
        required: ProduitsService.isRatioVolumeRequired(dto.uniteDeMesure),
        width: 150,
        prefix: `1 LITRE équivaut à`,
        suffix: 'KG',
        type: ProduitsService.getTypeRatioVolume(dto.uniteDeMesure),
        order: 12
      }),

      new FormFieldCurrencySupplier({
        key: 'prixAchat',
        label: `Prix d'achat`,
        readonly: isReadonly,
        value: dto.prixAchat,
        required: true,
        type: 'number',
        width: 100,
        suffix: `€ par ${dto.uniteDeMesure.libelle}`,
        order: 13
      }),

      new FormFieldCurrencySupplier({
        key: 'prixDeVente',
        label: 'Prix de vente',
        readonly: isReadonly,
        value: dto.prixDeVente,
        required: false,
        width: 100,
        suffix: `€ par ${dto.uniteDeMesure.libelle}`,
        type: 'number',
        order: 14
      }),

      new FormFieldTextboxSupplier({
        key: 'dlc',
        label: 'Date limite de conso.',
        prefix: '+',
        suffix: 'jour(s)',
        help: 'Privilégier le produit selon sa date de consommation',
        readonly: isReadonly,
        value: dto.dlc,
        required: true,
        width: 100,
        type: 'number',
        order: 17
      }),


      new FormFieldDropdownSupplier({
        key: 'tacheFabrication',
        label: 'Type de fabrication',
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheFabricationList, tacheFabrication),
        order: 15,
        options: this.tacheSvc.tacheFabricationList
      }),

      new FormFieldDropdownSupplier({
        key: 'taxe',
        label: 'Taxe',
        required: true,
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value: dto.id === 0 ? this.utils.getFirstElement(this.taxesSvc.taxesEnvironnement) : this.utils.preSelectSingleList(this.taxesSvc.taxesEnvironnement, dto.taxe),
        order: 16,
        options: this.taxesSvc.taxesEnvironnement
      }),

      new FormFieldDropdownSupplier({
        key: 'declinaison',
        label: 'Déclinaison',
        help: `Les déclinaisons sont filtrées par rapport au type de produit (denrée ou plat)`,
        required: true,
        readonly: !this.canModify(dto) || dto.id != 0,
        type: 'externalRef',
        value: dto.id === 0 ? this.utils.getFirstElement(declinaisons) : this.utils.preSelectSingleList(declinaisons, dto.declinaison),
        order: 5,
        options: declinaisons,
      }),

      new FormFieldDropdownSupplier({
        key: 'regimes',
        label: 'Régimes',
        help: 'Régime et Texture',
        required: true,
        readonly: !this.canModify(dto),
        type: 'multiple',
        value: this.regimeSvc.preselectContraintesAlim(contraintesAlim, produitContraintesAlim),
        order: 18,
        options: contraintesAlim
      }),

      new FormFieldPrimengEditorSupplier({
        key: 'modeOperatoire',
        label: 'Mode opératoire',
        help: 'Description du mode opératoire',
        readonly: isReadonly,
        value: dto.modeOperatoire,
        required: false,
        order: 20,
        width: 1000
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Active',
        readonly: isReadonly,
        value: dto.actif,
        required: false,
        order: 22
      }),

      new FormFieldDropdownSupplier({
        key: FIELD_UNITE_DE_MESURE,
        label: 'Unité technique',
        readonly: isReadonly || dto.id != 0,// modifiable en création mais pas en modification
        type: 'externalRef',
        required: true,
        value: this.utils.preSelectSingleList(this.udmSvc.unitesDeMesureEnvironnement, dto.uniteDeMesure),
        order: 9,
        options: this.udmSvc.unitesDeMesureEnvironnement,
        linkedFieldFn: this.changeUniteDeMesure
      }),


    ];


    return formFields;

  };


  getFields = (dto: ProduitDeclinaisonDTO): FormFieldBaseSupplier<any>[] => {

    let fields = [];

    switch (dto.fabrique) {
      // PLAT
      case true:
        fields = this.getFieldsOfPlat(dto);
        break;
      // DENREE
      case false:
        fields = this.getFieldsOfDenree(dto);
        break;
    }

    return fields.sort((a, b) => a.order - b.order);
  };


  changeRatioUniteTechnique = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    if (fields) {
      // On a modifié le ratio technique : il faut répercuter cette modif sur l'inverseratiotechnique
      // Récupération de la nouvelle valeur du ratio unité technique
      let newRatioUniteTechnique: number = parseFloat(value);
      if (isNaN(newRatioUniteTechnique)) {
        newRatioUniteTechnique = 0;
      }

      let newInverseRatioUniteTechnique = ProduitDeclinaisonService.calculeInverse(newRatioUniteTechnique);

      // form.controls[FIELD_INVERSE_RATIO_UNITE_TECHNIQUE].setValue(newInverseRatioUniteTechnique.toFixed(3));
      form.controls[FIELD_INVERSE_RATIO_UNITE_TECHNIQUE].setValue(ProduitDeclinaisonService.fixedValue(newInverseRatioUniteTechnique, 4));
    }
  };

  changeInverseRatioUniteTechnique = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    if (fields) {
      // On a modifié l'inverse du ratio unit technique : il faut répercuter cette modif sur le ratio unité technique
      // Récupération de la nouvelle valeur de l'inverse du ratio unité technique
      let newInverseRatioUniteTechnique: number = parseFloat(value);
      if (isNaN(newInverseRatioUniteTechnique)) {
        newInverseRatioUniteTechnique = 0;
      }

      let newRatioUniteTechnique = ProduitDeclinaisonService.calculeInverse(newInverseRatioUniteTechnique);

      // form.controls[FIELD_RATIO_UNITE_TECHNIQUE].setValue(newRatioUniteTechnique.toFixed(3));
      form.controls[FIELD_RATIO_UNITE_TECHNIQUE].setValue(ProduitDeclinaisonService.fixedValue(newRatioUniteTechnique, 4));

    }
  };


  changeUniteDeMesure = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    if (fields) {

      const udm = object as UniteDeMesureDTO;
      for (let field of fields) {
        if (field.key === 'prixAchat' || field.key === 'prixDeVente') {
          field.suffix = '€ par ' + udm.libelle.toUpperCase();
        } else if (field.key === FIELD_RATIO_UNITE_TECHNIQUE) {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à `;
          field.label = `Ratio unité technique (${udm.libelle.toUpperCase()}/KG)`;
          field.help = `Ratio unité technique (${udm.libelle.toUpperCase()}/ unité universelle (KG)`
        } else if (field.key === FIELD_INVERSE_RATIO_UNITE_TECHNIQUE) {
          field.suffix = `${udm.libelle.toUpperCase()}`;
          field.label = `Ratio unité universelle (KG/${udm.libelle.toUpperCase()})`;
          field.help = `Ratio unité universelle (unité universelle (KG)/${udm.libelle.toUpperCase()})`;
        } else if (field.key === 'ratioVolume') {
          field.required = ProduitsService.isRatioVolumeRequired(udm);
          field.type = ProduitsService.getTypeRatioVolume(udm);
        } else if (field.key === FIELD_UNITE_DE_MESURE) {

          console.log('nouvelle valeur : ' + form.controls[FIELD_UNITE_DE_MESURE].value.libelle + ', id = ' + form.controls[FIELD_UNITE_DE_MESURE].value.id);
          console.log('ancienne valeur : ' + ProduitDeclinaisonService.uniteDeMesureInitiale.libelle + ', id = ' + ProduitDeclinaisonService.uniteDeMesureInitiale.id);
          // On n'est pas en création de ProduitDeclinaison
          if (!ProduitDeclinaisonService.creatingNewProduitDeclinaison) {
            // La nouvelle unité de mesure est différente de l'unité de mesure initiale : on avertit l'utilisateur
            // en lui exhibant un warning.
            if (form.controls[FIELD_UNITE_DE_MESURE].value.id != ProduitDeclinaisonService.uniteDeMesureInitiale.id) {
              field.message = WARNING_UNITE_DE_MESURE;
            } else {
              field.message = null;
            }
          }
        }
      }
    }
  };

  /**
   * Enregistrer un produit déclinaison
   * @param produitDeclinaison
   * @return {Observable<any>}
   */
  saveProduitDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO): Observable<ResponseWrapper<ProduitDeclinaisonDTO>> => {
    if (this.checkValiditeModeOperatoire(produitDeclinaison)) {
      return this.httpSvc.post(URL_SAVE_PRODUITDECLINAISON, produitDeclinaison);
    }
    return of(null);
  };

  saveProduitDeclinaisonList = (produitDeclinaisonList: ProduitDeclinaisonDTO[]) => this.httpSvc.post(URL_SAVE_PRODUITDECLINAISON_LIST, produitDeclinaisonList);

  getAllFromEnvironnement = (): void => {
  };

  initTacheOfProduitDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO, tache: TacheDTO): ProduitDeclinaison__TacheDTO => {

    const pdt = new ProduitDeclinaison__TacheDTO();

    if (!this.utils.isNullOrEmpty(produitDeclinaison) && !this.utils.isNullOrEmpty(tache)) {
      pdt.produitDeclinaisonId = produitDeclinaison.id;
      pdt.tacheId = tache.id;
      pdt.typeTacheId = tache.typeTacheId;
      pdt.typeTacheCode = tache.typeTacheCode;
    }


    return pdt;
  };


  createEmptyDTO = (): ProduitDeclinaisonDTO => this.createEmptyProduitDeclinaisonDTO(new ProduitDTO(), '');

  createEmptyProduitDeclinaisonDTO = (produit: ProduitDTO, code: string): ProduitDeclinaisonDTO => {

    const produitDeclinaison = new ProduitDeclinaisonDTO();

    // 0 tell to the back, it s a creation
    produitDeclinaison.id = 0;
    produitDeclinaison.produitLibelle = produit.libelle;
    produitDeclinaison.site = new SiteDTO();
    produitDeclinaison.site.id = produit.site.id;
    produitDeclinaison.site.libelle = produit.site.libelle;
    produitDeclinaison.produitId = produit.id;
    // guid calculé côté back
    produitDeclinaison.guid = 'temp';
    produitDeclinaison.prixAchat = 0;
    produitDeclinaison.prixDeVente = 0;
    produitDeclinaison.code = code;

    //on herite des propriétés du produit
    produitDeclinaison.dlc = this.utils.isNullOrEmpty(produit.dlc) ? 0 : _cloneDeep(produit.dlc);


    const tacheFabrication = this.produitsService.getTacheOfProduit(produit, TYPE_TACHE.FABRICATION);
    const pdtFab = this.initTacheOfProduitDeclinaison(produitDeclinaison, tacheFabrication);

    const tacheApprovisionnement = this.produitsService.getTacheOfProduit(produit, TYPE_TACHE.APPROVISIONNEMENT);
    const pdtAppro = this.initTacheOfProduitDeclinaison(produitDeclinaison, tacheApprovisionnement);

    const tacheSortie = this.produitsService.getTacheOfProduit(produit, TYPE_TACHE.SORTIE);
    const pdtSortie = this.initTacheOfProduitDeclinaison(produitDeclinaison, tacheSortie);

    produitDeclinaison.produitDeclinaison__tacheList = [pdtFab, pdtAppro, pdtSortie];


    produitDeclinaison.uniteDeMesure = _cloneDeep(produit.uniteDeMesure);
    produitDeclinaison.zoneDeStockage = _cloneDeep(produit.zoneDeStockage);
    produitDeclinaison.libellelong = _cloneDeep(produit.libellelong);
    produitDeclinaison.libelle = _cloneDeep(produit.libelle);
    produitDeclinaison.actif = _cloneDeep(produit.actif);
    produitDeclinaison.fabrique = _cloneDeep(produit.typeProduit.fabrique);
    produitDeclinaison.ratioUniteTechnique = 1;
    produitDeclinaison.ratioVolume = 1;

    //Régime
    produitDeclinaison.produitDeclinaisonContrainteAlimList = [];
    for (const contraint of produit.produitContrainteAlimList) {
      let pdcaDto = new ProduitDeclinaisonContrainteAlimDTO();
      pdcaDto.produitDeclinaisonId = produitDeclinaison.id;
      pdcaDto.produitDeclinaisonLibelle = produitDeclinaison.libelle;
      pdcaDto.regimeActif = contraint.regimeActif;
      pdcaDto.regimeCode = contraint.regimeCode;
      pdcaDto.regimeId = contraint.regimeId;
      pdcaDto.regimeLibelle = contraint.regimeLibelle;
      pdcaDto.textureCode = contraint.textureCode;
      pdcaDto.textureLibelle = contraint.textureLibelle;
      pdcaDto.textureId = contraint.textureId;
      pdcaDto.textureActif = contraint.textureActif;

      produitDeclinaison.produitDeclinaisonContrainteAlimList.push(pdcaDto);
    }

    return produitDeclinaison;
  };

  deleteById = (produitDeclinaison: ProduitDeclinaisonDTO) => {
    const params: HttpParams = new HttpParams().set("idProduitDeclinaison", produitDeclinaison.id + '');
    return this.httpSvc.delete(URL_DELETE_BY_ID, params);
  };

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PRODUIT DÉCLINAISON '${data.libelle.toUpperCase()}'`;

  getProduitDeclinaison = (idProduitDeclinaison: number) => {
    let url = this.produitRouteMapService.getProduitDeclinaisonSLink(idProduitDeclinaison);
    return this.http.get(url).pipe(
      catchError(err => this.utils.handleError(err)))
  };

  getProduitsdecliProduitNomenclatureFichetech = (idProduit: number, idUdp: number) => {
    return this.http.get(URL_GET_PRODUIT_NOMENCLATURE_FICHE_TECHNIQUE, {
      params: new HttpParams()
        .set('idProduit', idProduit + '')
        .set('idUdp', idUdp + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getProduitsdeclisFichetechComponent = (idProduit: number, idUdp: number) => {
    return this.http.get(URL_GET_FICHE_TECHNIQUE_COMPONENT, {
      params: new HttpParams()
        .set('idProduit', idProduit + '')
        .set('idUdp', idUdp + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getProduitDeclinaisonListByIdProduit = (idProduit: number) => {
    return this.http.get(URL_GET_PRODUIT_DECLI_LIST_BY_PRODUIT_ID, {
      params: new HttpParams()
        .set('idProduit', idProduit + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  duplicationProduitDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO, declinaisonsList: DeclinaisonDTO[], ecrasementDesProduitsDeclinaisonsExistants: boolean) => {
    let declinaisonsId: number[] = [];
    declinaisonsList.forEach(declinaison => {
      declinaisonsId.push(declinaison.id);
    });
    const fd = new FormData();
    fd.set('produitDeclinaisonId', '' + produitDeclinaison.id);
    fd.set('declinaisonsIdList', declinaisonsId ? declinaisonsId.join(',') : '');
    fd.set('ecrasementDesProduitsDeclinaisonsExistants', '' + ecrasementDesProduitsDeclinaisonsExistants);
    return this.httpSvc.post(URL_DUPLIQUE_DECLINAISON, fd);
  };

  /**
   * Récupère les produits déclinaison en fonction du champs fabrique du type_produit
   */
  getProduitDeclinaisonByTypeFabrique = (ssWrapper: SearchSupplierWrapper, urlParams: string) => {
    return this.http.post(URL_GET_DENREES + urlParams, ssWrapper).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  /**
   * Recherche de produit déclinaison via des paramêtres
   */
  searchProduitDeclinaisonByParams = (filters: any) => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.httpSvc.get(`${URL_SEARCH_DENREE_BY_PARAMS}?${args}`);
  };

  checkValiditeModeOperatoire = (produitDeclinaison: ProduitDeclinaisonDTO): boolean => {
    let valid: boolean = true;
    if (this.utils.isNullOrEmpty(produitDeclinaison.modeOperatoire)) {
      return valid;
    }
    if (produitDeclinaison.modeOperatoire.match('<img ')) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le mode opératoire ne doit pas contenir d'images`);
      valid = false;
    }
    return valid;
  };

  /**
   * Calcule le libellé à afficher
   * @param produitDeclinaison
   */
  calculeLibelle = (produitDeclinaison: ProduitDeclinaisonDTO): string => {

    // Par défaut le libellé à afficher est le libellé du produit
    // sauf si on est en train de créer un nouveau produit : dans ce cas le libellé à afficher est celui du
    // Produit du produitdeclinaison.

    if (this.utils.isNullOrEmpty(produitDeclinaison)) {
      return '';
    } else if (this.utils.isNullOrEmpty(produitDeclinaison.declinaison)) {
      return produitDeclinaison.produitLibelle;
    } else {
      return produitDeclinaison.libelle;
    }
  };

  filter = (liste: { key, value }[], dto: ProduitDeclinaisonDTO) => {
    let newList = [];
    liste.forEach(item => {
      if (item.value.typeProduit.fabrique === dto.fabrique) {
        newList.push(item);
      }
    });

    return newList;
  };


  /**
   * Calcule l'inverse du {@link number} passé en paramètre. Si ce nombre est <b>null</b>, la valeur renvoyée est 0.
   * @param num
   */
  static calculeInverse = (num: number): number => {
    let value: number = 0;
    if (num == 0) {
      value = 0;
    } else {
      value = (1 / num);
    }
    return value;
  };

  /**
   * Renvoie une {@link string} représentant le {@link number} passé en paramètre. Les '0' situés à droite de la virgule sont supprimés.
   * @param nombre
   * @param fractionDigits
   */
  static fixedValue = (nombre: number, fractionDigits: number): string => {
    let value: string = nombre.toFixed(fractionDigits);
    // On enlève les zéros à droite
    while (value.charAt(value.length - 1) === '0') {
      value = value.substr(0, value.length - 1);
    }
    // Eventuellement : on enlève la virgule ou le point
    if ((value.charAt(value.length - 1) === ',') || (value.charAt(value.length - 1) === '.')) {
      value = value.substr(0, value.length - 1);
    }
    return value;
  };

  announceOpenDialogChangeUniteTechnique = (denree: ProduitDeclinaisonDTO) => {
    this.subjectOpenDialogChangeUniteTechnique.next(denree);
  };

  announceOpenDialogAjoutLot = (selectedMatierePremiere: ModelViewMatierePremiere, selectedInventaire: ModelViewInventaire) => {
    const dialogAjoutLotSupplier = new DialogStockAjoutLotSupplier(selectedMatierePremiere, selectedInventaire);
    this.subjectOpenDialogAjoutLot.next(dialogAjoutLotSupplier);
  };

  announceResultSearchStocks = (response, searchStockSupplier: SearchStockSupplier) => {

    if (searchStockSupplier.feature === STOCKS_FEATURES.INVENTAIRE) {
      this.subjectResultSearchStocksForInventaire.next(response);
    } else if (searchStockSupplier.feature === STOCKS_FEATURES.MATIERES_PREMIERES) {
      this.subjectResultSearchStocksForMatieresPremieres.next(response);
    }

  };

  announcePaginationSearchStock = paramsPagination => {
    this.subjectPaginationSearchStock.next(paramsPagination);
  };

  announceProduitDecliListDto = (produitDecliList: ProduitDeclinaisonDTO[]) => {
    this.subjectProduitDecliListDto.next(produitDecliList);
  };

  announceNewUniteTechnique = (produitDeclinaison :ProduitDeclinaisonDTO) => {
    this.subjectNewUniteTechnique.next(produitDeclinaison);
  };

  searchInventaires = (searchStockSupplier: SearchStockSupplier, idsSites: number[], idsZonesStockagesSelected: number[], idsUdpZonesStockagesSelected: number[]): Observable<ResponseWrapper<any>> => {



    let args = '';
    if (searchStockSupplier) {
      if(searchStockSupplier.values?.dateEntree) args += `&dateEntree=${this.utils.convertYYYYMMdd(searchStockSupplier.values.dateEntree.getTime())}`;
      if(searchStockSupplier.values?.datePerime) args += `&datePeremption=${this.utils.convertYYYYMMdd(searchStockSupplier.values.datePerime.getTime())}`;
      if(searchStockSupplier.values?.denominationArticle) args += `&denominationArticle=${searchStockSupplier.values.denominationArticle}`;
      if(searchStockSupplier.values?.referenceInterneArticle) args += `&referenceInterneArticle=${searchStockSupplier.values.referenceInterneArticle}`;
      if(searchStockSupplier.values?.thresholdStocks) args += `&seuil=${searchStockSupplier.values.thresholdStocks}`;
      if(searchStockSupplier.values?.denree) args += `&denree=${searchStockSupplier.values.denree}`;
      if(searchStockSupplier.values?.codeDenree) args += `&codeDenree=${searchStockSupplier.values.codeDenree}`;
      if(searchStockSupplier.page !== undefined && searchStockSupplier.page !== null && searchStockSupplier.size) args += `&page=${searchStockSupplier.page + 1}&size=${searchStockSupplier.size}`;
      if (idsSites) args += `&sitesIds=${idsSites}`;

      if (idsUdpZonesStockagesSelected && idsUdpZonesStockagesSelected.length) args += `&idsUniteProductionZonesStockage=${idsUdpZonesStockagesSelected.join(',')}`;
      if (idsZonesStockagesSelected && idsZonesStockagesSelected.length) args += `&idsZonesStockagesSelected=${idsZonesStockagesSelected.join(',')}`;
    }
    return this.httpSvc.get(`${URL_SEARCH_LIGNES_INVENTAIRES}?args=1${args}`);
  }

  /**
   * Rechercher les articles du stock correspondant aux critères de recherche
   */
  searchInStock = (searchStockSupplier: SearchStockSupplier, idsSites: number[], idsZonesStockagesSelected: number[], idsUdpZonesStockagesSelected: number[]): Observable<ResponseWrapper<any>> => {
    this.subOnSearchStocks.next();
    let args = '';
    if (searchStockSupplier) {
      if(searchStockSupplier.values?.dateEntree) args += `&dateEntree=${this.utils.convertYYYYMMdd(searchStockSupplier.values.dateEntree.getTime())}`;
      if(searchStockSupplier.values?.datePerime) args += `&datePeremption=${this.utils.convertYYYYMMdd(searchStockSupplier.values.datePerime.getTime())}`;
      if(searchStockSupplier.values?.denominationArticle) args += `&denominationArticle=${searchStockSupplier.values.denominationArticle}`;
      if(searchStockSupplier.values?.referenceInterneArticle) args += `&referenceInterneArticle=${searchStockSupplier.values.referenceInterneArticle}`;
      if(searchStockSupplier.values?.thresholdStocks) args += `&seuil=${searchStockSupplier.values.thresholdStocks}`;
      if(searchStockSupplier.values?.denree) args += `&denree=${searchStockSupplier.values.denree}`;
      if(searchStockSupplier.values?.codeDenree) args += `&codeDenree=${searchStockSupplier.values.codeDenree}`;
      if(searchStockSupplier.page !== undefined && searchStockSupplier.page !== null && searchStockSupplier.size) args += `&page=${searchStockSupplier.page + 1}&size=${searchStockSupplier.size}`;
      if (idsSites) args += `&sitesIds=${idsSites}`;

      if (idsUdpZonesStockagesSelected && idsUdpZonesStockagesSelected.length) args += `&idsUniteProductionZonesStockage=${idsUdpZonesStockagesSelected.join(',')}`;
      if (idsZonesStockagesSelected && idsZonesStockagesSelected.length) args += `&idsZonesStockagesSelected=${idsZonesStockagesSelected.join(',')}`;
    }

    return this.httpSvc.get(`${URL_SEARCH_LIGNES_MATIERES_PREMIERES}?args=1${args}`);
  };

  findPlatUsingByDenree = (idProduitDeclinaison: number) => {
    const params: HttpParams = new HttpParams()
      .set('idProduitDeclinaison', idProduitDeclinaison);

    return this.httpSvc.get(URL_FIND_PLAT_USING_DENREE, params);
  }

  printNutritionalInformations = (libelleProduitDecline: string, idProduitDecline: number) => {
    this.httpClient.get(`${URL_PRINT_NUTRITIONAL_INFORMATIONS}${idProduitDecline}`, {responseType: 'arraybuffer'})
      .subscribe(response => {
        let blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `informations-nutritionnelles-${libelleProduitDecline}.pdf`);
      });
  };

  saveChangeUniteTechnique = (idProduitDeclinaison: number, idNewUniteTechnique: number, oldQteUtEnKg: number, newQteUTEnKg: number) => {
    const params: HttpParams = new HttpParams()
      .set('idProduitDeclinaison', idProduitDeclinaison + '')
      .set('idNewUniteTechnique', idNewUniteTechnique + '')
      .set('oldQteUtEnKg', oldQteUtEnKg + '')
      .set('newQteUTEnKg', newQteUTEnKg + '')

    return this.httpSvc.post(URL_CHANGE_UNITE_TECHNIQUE_DENREE, null, params);
  };

  announceOpenDialogProduitDeclinaison = (param: DialogProduitDeclinaisonSupplier) => {
    this.subjectOpenDialogProduitDeclinaison.next(param);
  };

  announceProduitDeclinaisonListPropCommande = (catalogueAchatList: CatalogueAchatDTO[]) => {
    this.subjectProduitDeclinaisonListPropCommande.next(catalogueAchatList);
  };

  private prepareFieldsPlat = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {

    let all$ = null;
    let isCreation = false;

    // SI CREATION
    if (this.utils.isNullOrEmpty(produitDeclinaison) || produitDeclinaison.id === 0) {
      isCreation = true;
      // récupérer un code unique
      let code$ = this.uniqueCodeSvc.generateUniqueCode(TYPES_CODES.CODE_PRODUIT_DECLINAISON, 'P');
      all$ = combineLatest([code$]);
    }
    // SI MODIFICATION
    else {
      isCreation = false;
      let modeOperatoire$ = this.produitsService.findProduitsModesOperatoires(produitDeclinaison);
      all$ = combineLatest([modeOperatoire$]);
    }

    return all$.pipe(
      switchMap(data => {
        if (data && data[0] != null) {
          if (isCreation) {
            const code = data[0].one;
            produitDeclinaison = this.createEmptyProduitDeclinaisonDTO(produit, code);
          } else {
            produitDeclinaison.modeOperatoire = data[0].modeOperatoire;
          }
        }
        return of({produitDeclinaison: produitDeclinaison, fields: this.getFields(produitDeclinaison)});
      })
    );
  };

  private prepareFieldsDenree = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {

    let all$ = null;

    // SI CREATION
    if (this.utils.isNullOrEmpty(produitDeclinaison) || produitDeclinaison.id === 0) {
      // récupérer un code unique
      let code$ = this.uniqueCodeSvc.generateUniqueCode(TYPES_CODES.CODE_PRODUIT_DECLINAISON, 'D');
      all$ = combineLatest([code$]);
    }

    if (this.utils.isNullOrEmpty(all$)) {
      return of({produitDeclinaison: produitDeclinaison, fields: this.getFields(produitDeclinaison)});
    }

    return all$.pipe(
      switchMap(data => {
        const code = data[0].one;
        produitDeclinaison = this.createEmptyProduitDeclinaisonDTO(produit, code);
        return of({produitDeclinaison: produitDeclinaison, fields: this.getFields(produitDeclinaison)});
      })
    )
  };

  prepareFields = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {
    switch (produit.typeProduit.fabrique) {
      case true:
        return this.prepareFieldsPlat(produit, produitDeclinaison);
      case false:
        return this.prepareFieldsDenree(produit, produitDeclinaison);
    }
  };

  filterProduitDeclinaisonList = (idProduit: number, entityName: string): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(entityName);
    const lcEntityName = entityName.toLowerCase();

    const search = new Search();

    const predicat1 = new Predicat();
    predicat1.path = `${lcEntityName}.produit.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idProduit + '';

    const predicat2 = new Predicat();
    predicat2.path = `${lcEntityName}.actif`;
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    search.predicats = [predicat1, predicat2];

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = `${lcEntityName}.libelle`;
    search.sorts = [sort];

    grs.search = search;

    return grs;
  };

  private getTacheOfProduitDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO, typeTache: TYPE_TACHE) => {
    let tache;

    if (!this.utils.isNullOrEmpty(produitDeclinaison) && !this.utils.isCollectionNullOrEmpty(produitDeclinaison.produitDeclinaison__tacheList)) {
      const pt = _find(produitDeclinaison.produitDeclinaison__tacheList, {'typeTacheCode': typeTache});
      if (!this.utils.isNullOrEmpty(pt)) {
        tache = new TacheDTO();
        tache.id = pt.tacheId;
        tache.code = pt.tacheCode;
        tache.libelle = pt.libelle;
      }

    }

    return tache;
  };
}

export class DialogStockAjoutLotSupplier {
  selectedMatierePremiere: ModelViewMatierePremiere;
  selectedInventaire: ModelViewInventaire;

  constructor(selectedMatierePremiere: ModelViewMatierePremiere, selectedInventaire: ModelViewInventaire) {
    this.selectedMatierePremiere = selectedMatierePremiere;
    this.selectedInventaire = selectedInventaire;
  }
}

export class DialogStockPerimesSupplier {
  selectedMatierePremiere: ModelViewMatierePremiere;
  lotsArticlesLoaded: string[];


  constructor(selectedMatierePremiere: ModelViewMatierePremiere, lotsArticlesLoaded: string[]) {
    this.selectedMatierePremiere = selectedMatierePremiere;
    this.lotsArticlesLoaded = lotsArticlesLoaded;
  }
}

export class SearchStockSupplier {

  values: any;
  selectedNodes: TreeNode[];
  feature: string;
  page: number = 0;
  size: number = 50;


  constructor(values: any, selectedNodes: TreeNode[], feature: string, page: number, size: number) {
    this.values = values;
    this.selectedNodes = selectedNodes;
    this.feature = feature;
    this.page = page;
    this.size = size;
  }
}

export interface DialogProduitDeclinaisonSupplier {
  produit: ProduitDTO;
  produitDeclinaison: ProduitDeclinaisonDTO;
}
