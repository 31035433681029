import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {cloneDeep as _cloneDeep} from 'lodash';
import {
  utils as xlsxUtils,
  WorkBook as xlsxWorkBook,
  WorkSheet as xlsxWorkSheet,
  writeFile as xlsxWriteFile
} from "xlsx";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  templateUrl: './view-table-graphql.component.html',
  styleUrls: ['./view-table-graphql.component.scss'],
  selector: 'yo-view-table-graphql',
})
export class ViewTableGraphqlComponent implements OnInit, OnDestroy {

  hasRequeteurGraphQL: boolean;
  dataLoaded: boolean;
  data: any;
  columns: string[];
  originResults: any[];

  subData: Subscription;
  @ViewChild('grid') grid: DxDataGridComponent;

  constructor(private utils: UtilsService,
              private graphQlSvc: GraphQLService) {
    this.dataLoaded = false;
    this.data = null;
    this.originResults = [];
    this.columns = [];
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subData);
  }

  ngOnInit() {
    this.initDataSubscription();
  }

  isReady() {
    return this.dataLoaded && this.data;
  }

  exportXlsx(): void {
    const fileName = `export-xlsx-${new Date().getTime()}.xlsx`;
    const wb: xlsxWorkBook = xlsxUtils.book_new();
    let sheetJson: any[] = [];

    if (this.data && this.data.length && this.originResults && this.originResults[0]) {
      let firstRow = this.data[0];

      // Je récupère les clés des attributs de type tableau qui n'ont pas été affichés à l'écran
      // par soucis de lisibilité. Nous allons les exporter :
      let keysNotDisplayedList = Object.keys(firstRow).filter(x => !Object.keys(this.originResults[0]).includes(x));

      let cols = Object.keys(firstRow).map((key, idx) => `col${idx+1}`);
      let colValues = Object.keys(firstRow).map((key, idx) => key);

      let resultCols: any = {};
      this.utils.addPropertyListAndValueList(resultCols, cols, colValues);
      sheetJson.push(resultCols);

      this.data.forEach((row) => {

        let result: any = {};

        let values = [];
        Object.keys(row).forEach((key, idx) => {
          values.push(row[key]);
        });

        this.utils.addPropertyListAndValueList(result, cols, values);
        sheetJson.push(result);

        // Attributs de type tableaux :
        //cols = [`col1`]; // col qui se décale si on a des enfants au niveau du tab (type tab)
        //keysNotDisplayedList;

      });

      const sheet: xlsxWorkSheet = xlsxUtils.json_to_sheet(sheetJson, {skipHeader: true});
      xlsxUtils.book_append_sheet(wb, sheet, 'Résultats GraphQL');
    }

    xlsxWriteFile(wb, fileName);
  }

  seeDetailsRow(row: any): void {
    const originalRow = this.originResults.find(x => x.id === row.id);
    this.graphQlSvc.announceDialogDetails(originalRow);
  }

  initDataSubscription(): void {
    this.subData = this.graphQlSvc.queryReceived$
      .subscribe(data => {
        this.data = null;
        this.columns = [];
        this.dataLoaded = true;
        // Ce tableau est très utile pour récupérer les détails de la ligne :
        this.originResults = _cloneDeep(data[Object.keys(data)[0]] );
        const arrayResults = data[Object.keys(data)[0]];
        if (arrayResults && arrayResults.length) {
          arrayResults.forEach(x => {
            let keysCurrentRow = Object.keys(x);

            keysCurrentRow.forEach(key => {

              // Etape 1 : Création dynamique de clés pour un résultat qui a 1 enfant d'un niveau :

              // On se limite qu'à un seul niveau pour 1 objet sinon voir bouton détails si
              // on a + de profondeur au niveau de l'objet ou si on a un tableau afin
              // de garder la lisibilité du tableau html :
              if (x[key] && x[key].constructor.name === 'Object') {
                Object.keys(x[key]).forEach(subKey => {
                  x[key + '-' + subKey] = x[key][subKey];
                  if (!keysCurrentRow.includes(key + '-' + subKey))
                    keysCurrentRow.push(key + '-' + subKey);
                });
              } else if (!x[key]) {
                x[key] = 'Non renseigné';
              }
            });

            // Rafraîchissement des clés :
            keysCurrentRow = Object.keys(x);

            // Suppression des objets 1 et N du résultat
            keysCurrentRow.forEach(key => {
              if (x[key] && (Array.isArray(x[key]) || x[key].constructor.name === 'Object')) {
                delete(x[key]);
              } else if (!this.columns.includes(key)) {
                this.columns.push(key);
              }
            });
          });
        }
        this.data = arrayResults;

        if (this.grid) {
          this.grid.instance.getDataSource().reload();
        }
      });
  }

}
