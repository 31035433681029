import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-factures-root',
  templateUrl: './factures-root.component.html',
  styleUrls: ['./factures-root.component.scss']
})
export class FacturesRootComponent implements OnInit {

  ngOnInit(): void {
  }

}
