import {ObjectDTO} from './object-dto';

export class UniteDeProduction__ZoneDeStockageDTO extends ObjectDTO {

  actif: boolean;

  // site
  siteId:number;

  // udp
  idUniteDeProduction: number;
  actifUniteDeProduction:boolean;

  // zds
  idZoneDeStockage: number;
  libelleZoneDeStockage:string;
  actifZoneDeStockage:boolean;
  codeZoneDeStockage:string;


}

