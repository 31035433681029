import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TacheDTO} from "../../core/dtos/tache-dto";
import {UtilsService} from "../../core/utils/utils.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../core/suppliers/dialog-msg-supplier";
import {TypeTacheDTO} from "../../core/dtos/type-tache-dto";
import {TachesService} from "../../core/services/taches/taches.service";
import {TypesTachesService} from "../../core/services/types-taches/types-taches.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";

@Component({
  selector: 'yo-tache',
  templateUrl: './tache.component.html',
  styleUrls: ['./tache.component.scss']
})
export class TacheComponent implements OnInit, OnDestroy {

  displayDialog = false;

  subOpenDialogTache: Subscription;

  tache: TacheDTO;

  typesTachesList: TypeTacheDTO[];

  form: FormGroup = new FormGroup({});

  dialogTitle: string;

  idSelectedTypeTache: number;

  constructor(public readonly utils: UtilsService,
              private readonly tacheSvc: TachesService,
              private readonly typeTacheEntitySvc: TypesTachesService,
              private readonly toastSvc: ToastService,
              private readonly i8nSvc: InternationalizationService) {
  }

  ngOnInit(): void {
    this.initTypesTaches();
    this.initForm();
    this.openDialogTacheSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogTache);
  }

  openDialogTacheSubscription = (): void => {
    this.subOpenDialogTache = this.tacheSvc.openDialogTache$
      .subscribe((tache: TacheDTO) => {
        this.displayDialog = true;
        if (!tache) {
          this.tache = new TacheDTO();
          this.tache.id = 0;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_TASK", null);
        } else {
          this.tache = tache;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_TASK", null);
        }
        this.initForm();
      });
  };

  initTypesTaches = (): void => {
    this.typeTacheEntitySvc.getAll()
      .subscribe( response => {
        this.typesTachesList = response.resultList;
      });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      this.tache.libelle = this.form.controls['libelle'].value;
      this.tache.actif = this.form.controls['actif'].value;
      this.tache.typeTache = { id: this.idSelectedTypeTache } as TypeTacheDTO;
      this.tacheSvc.save(this.tache)
        .subscribe(response => {
          this.tacheSvc.announceTacheSaved(response.one, this.tache.id !== undefined && this.tache.id !== null && this.tache.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La tâche a été enregistrée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  canModify = () => {
    return this.tacheSvc.canModify(this.tache);
  }

  onChangeTypeTache = ($event) => this.idSelectedTypeTache = $event.selectedItem?.id;

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.tache ? this.tache.libelle : '', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      actif: new FormControl(this.tache?.actif != null ? this.tache.actif : true, [Validators.required])
    });
    this.idSelectedTypeTache = this.tache?.typeTache?.id;
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `La tâche`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
