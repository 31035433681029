import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {UtilsService} from "../../core/utils/utils.service";
import {Observable} from "rxjs";
import {WorkflowInstanceDTO} from "../../core/dtos/workflow-instance-dto";
import {WorkflowDto} from "../../core/dtos/workflow-dto";
import {WorkflowEtapeDto} from "../../core/dtos/workflow-etape-dto";
import {TreeNode} from "primeng/api";
import {ErreurFonctionnelleDto} from "../../core/dtos/erreur-fonctionnelle-dto";
import {SelectionRepasPlcsService} from "../../core/services/selection-plcs/selection-repas-plcs.service";
import {SelectionRepasPlcsDTO} from "../../core/dtos/selection-repas-plcs-dto";
import {ReglePrefereDTO} from "../../core/dtos/regle-prefere-dto";
import {UniteDeProduction__ReglePrefereDTO} from "../../core/dtos/unite-de-production__regle-prefere-dto";
import {UniteDeProductionDTO} from "../../core/dtos/unite-de-production-dto";

@Injectable({
  providedIn: 'root'
})
export class SelectionPlcsResolverService implements Resolve<SelectionRepasPlcsSupplier> {

  constructor(
    private selectionRepasPlcsSvc: SelectionRepasPlcsService,
    private utils: UtilsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SelectionRepasPlcsSupplier> | Promise<SelectionRepasPlcsSupplier> | SelectionRepasPlcsSupplier => {

    let sps = new SelectionRepasPlcsSupplier();
    const idWorkflowInstance: number = +route.parent.paramMap.get('idWorkflowInstance');

    return this.selectionRepasPlcsSvc.getSelectionRepasPlcs(idWorkflowInstance)
      .toPromise().then(response => {

        // workflow
        sps.workflow = response.additionalProperties['workflow'];

        // workflow etape
        sps.workflowEtape = response.additionalProperties['workflowEtape'];

        // workflow instance
        sps.workflowInstance = response.additionalProperties['worklowInstance'];

        // dates selectionnées
        sps.selectedDates = this.getSelectedDates(response.additionalProperties['selectedDates']);

        // arbre structure
        sps.tree = response.additionalProperties['tree'];

        // les noeuds sélectionnés doivent avoir la même reference javascript que les noeuds de l'arbre
        sps.selectedNodes = this.selectionRepasPlcsSvc.preSelectNodes(sps.tree, response.additionalProperties['selectedNodes']);

        // erreurs
        sps.erreurs = response.additionalProperties['erreurs'];

        // regles de sélection des articles preferes
        sps.reglesPreferes = response.additionalProperties['reglesPreferes'];

        // régle selectionnée
        sps.selectedReglePrefere = response.additionalProperties['selectedReglePrefere'];

        // regles du parametrage par defaut
        sps.udpRpList = response.additionalProperties['udpRpList'];

        // unites de production
        sps.udpList = response.additionalProperties['udpList'];

        return sps;
      });
  };

  getSelectedDates = (selectedDates: Date[]) => {
    // console.log('getSelectedDates');

    // si aucune date existante on met celle du jour
    if (this.utils.isCollectionNullOrEmpty(selectedDates)) {
      selectedDates = [];

      selectedDates.push(new Date());
      selectedDates.push(new Date());
    } else {

      selectedDates = selectedDates.sort();
      selectedDates = selectedDates.map(numberDate => this.utils.convertNumberDateToDate(numberDate));

      // si une seule date, on met la période sur 1 jour
      if (selectedDates.length === 1) {
        selectedDates.push(selectedDates[0]);
      }
      // sinon, on met la date de debut et la date de fin
      else {
        selectedDates = [selectedDates[0], selectedDates[selectedDates.length - 1]];
      }
    }

    return selectedDates;
  };


}

export class SelectionRepasPlcsSupplier {

  workflowInstance: WorkflowInstanceDTO;
  workflow: WorkflowDto;
  workflowEtape: WorkflowEtapeDto;
  selectedDates: Date[] = [];
  selectionRepasPlcsList: SelectionRepasPlcsDTO[] = [];
  tree: TreeNode[] = [];
  erreurs: ErreurFonctionnelleDto[] = [];
  selectedNodes: TreeNode[] = [];
  reglesPreferes: ReglePrefereDTO[] = [];
  selectedReglePrefere: ReglePrefereDTO;
  udpRpList: UniteDeProduction__ReglePrefereDTO[] = [];
  udpList: UniteDeProductionDTO[] = [];
  typeEffectif: string;
}

