import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-gcf-root',
  templateUrl: './gcf-root.component.html',
  styleUrls: ['./gcf-root.component.scss']
})
export class GcfRootComponent implements OnInit {

  constructor(private title:Title) { }

  ngOnInit() {
    this.title.setTitle("Commandes fournisseurs");
  }

}
