import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'yo-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  @Input() checked : boolean;

  constructor() { }

  ngOnInit() {
  }

}
