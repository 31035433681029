import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ColumnsPdfDTO} from "../../dtos/columns-pdf-dto";

export const URL_FIND_ALL = 'dolrest/columns-pdf/all';

@Injectable({
  providedIn: 'root'
})
export class ColumnsPdfService {

  constructor(private httpSvc: HttpService) { }

  getAll = (): Observable<ResponseWrapper<ColumnsPdfDTO>> => this.httpSvc.get(URL_FIND_ALL, null);

}
