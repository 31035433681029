import {Injectable} from '@angular/core';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {TYPE_LIST_UDP} from "../../../core/constants";

@Injectable({
  providedIn: 'root'
})
export class CatalogueAchatResolverService implements Resolve<ArticleSupplier> {

  constructor(private udpSvc: UnitesDeProductionService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleSupplier> | Promise<ArticleSupplier> | ArticleSupplier => {

    const as = new ArticleSupplier();
    const udpList$ = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const all$ = combineLatest([udpList$]);

    return all$.toPromise().then(data => {
      as.uniteDeProductionList = data[0].resultList;
      as.selectedUniteDeProduction = as.uniteDeProductionList.length > 0 ? as.uniteDeProductionList[0] : undefined;
      return as;
    });
  };
}

export class ArticleSupplier {

  uniteDeProductionList: UniteDeProductionDTO[];
  selectedUniteDeProduction: UniteDeProductionDTO;
  selectedProduit: ProduitDTO;


  // button event
  event: any;
}
