import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import ModeleConditionnementPlcDTO from "../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {ModelesPlcService} from "../../core/services/conditionnements/plc/modeles-plc.service";
import {SiteDTO} from "../../core/dtos/site-dto";
import {OverlayPanel} from "primeng/overlaypanel";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {ToastService} from "../../core/services/technique/toast.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-modeles-plcs',
  templateUrl: './modeles-plc.component.html',
  styleUrls: ['./modeles-plc.component.scss']
})
export class ModelesPlcComponent implements OnInit, OnDestroy {

  modelesPlcList: ModeleConditionnementPlcDTO[] = [];
  subModelePlcSaved: Subscription;

  hasIDistri = false;
  exportBtnDisabled = false;

  exportOptionList: any[] = [];
  selectExportOption: any;

  duplicationSiteSelectedId: number;
  modeleIdToDuplicate: number;

  duplicationSiteSelected: SiteDTO;

  /**
   * La p-overlayPanel pour la duplication du modèle
   */
  @ViewChild('opDuplication') opDuplication: OverlayPanel;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plc';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private modelsPlcSvc: ModelesPlcService,
              private genericManageDialogService: GenericManageDialogService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit(): void {
    this.initModelesPlc();
    this.initHasGestionIDistri();
    this.initModelePlatSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subModelePlcSaved);
  }

  initModelesPlc = (): void => {
    this.modelsPlcSvc.getAllModelesConditionnementsPlc()
      .subscribe(response => {
        this.modelesPlcList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initModelePlatSavedSubscription = (): void => {
    this.subModelePlcSaved = this.modelsPlcSvc.mcPlcSaved$
      .subscribe(response => {
        if (!response.isUpdate) {
          this.modelesPlcList.push(response.mcPlc);
        } else {
          const idx = this.modelesPlcList.findIndex(m => m.id === response.mcPlc.id);
          if (idx > -1) {
            this.modelesPlcList[idx] = response.mcPlc;
          }
        }
      });
  };

  openModelePlc = (modelPlc: ModeleConditionnementPlcDTO): void => {
    this.genericManageDialogService.openEdit(modelPlc, FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLC);
  };

  delete = async(): Promise<void> => {
    const response = await confirm(this.selectedRows.length > 1 ? `Confirmez-vous la suppression des éléments sélectionnés ?` : `Confirmez-vous la suppression de l'élément sélectionné ?`, `Suppression`);
    if (response) {
      this.modelsPlcSvc.delete(this.selectedRows)
        .subscribe(() => {
          this.modelesPlcList = this.modelesPlcList.filter(m => !this.selectedRows.includes(m.id));
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Suppression réalisée avec succès`);
        });
    }
  }

  /**
   * ouverture p-overlayPanel pour la duplication du modèle
   * @param $event
   * @param rowData
   */
  openPanelDuplication = ($event, rowData: ModeleConditionnementPlcDTO) => {
    if (this.auth2Svc.utilisateur.siteListLocaux.length >= 1) {
      this.duplicationSiteSelected = this.auth2Svc.utilisateur.siteListLocaux[0];
    } else {
      this.duplicationSiteSelected = this.auth2Svc.utilisateur.sitePrincipal;
    }
    this.modeleIdToDuplicate = rowData.id;
    this.opDuplication.toggle($event);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les modèles points de livraison`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };
    return dms;
  };

  canModify = (modelePlc: ModeleConditionnementPlcDTO) => modelePlc.site && this.auth2Svc.isSiteLocal(modelePlc.site.id) && this.hasIDistri;

  canCreateAndDelete = () => this.hasIDistri;

  duplicateModele = () => {
    this.modelsPlcSvc.duplicateModele(this.modeleIdToDuplicate, this.duplicationSiteSelected.id)
      .subscribe((response: ResponseWrapper<ModeleConditionnementPlcDTO>) => {
        const msg = `Modèle plc dupliqué avec succès.`;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, msg);
        this.modelsPlcSvc.announceMcPlcSaved(response.one, false);
        this.opDuplication.hide();
      });
  };

  /**
   * Exporter les fichiers au format xlsx
   */
  exportXslx = () => {
    this.exportBtnDisabled = true;
  };

  importXslx = ($event: any) => {
  };
}
