<p-table
  [value]="familleGemrcnList"
  [columns]="cols"
  [scrollable]="true"
  [scrollHeight]="utils.scrollHeightContent(204)"
  styleClass="p-datatable-sm"
  dataKey="id"
  #dt>
  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

        <col *ngSwitchCase="'libelle'" [style.width.px]="400"
             [style.text-align]="'left'">
        <col *ngSwitchDefault [style.width.px]="100" [style.text-align]="'center'">

      </ng-container>

    </colgroup>
  </ng-template>


  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn">
        <ng-container [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'libelle'">
            <th>
              {{col.header}}
            </th>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <th>
              {{col.header}}
            </th>
          </ng-container>

        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>

      <ng-container *ngFor="let col of columns;  trackBy:utils.trackByFn">

        <ng-container [ngSwitch]="col.field">

          <!--FAMILLE GEMRCN-->
          <ng-container *ngSwitchCase="'libelle'">

            <td [style.width.px]="400">
              <yo-famille-gemrcn [familleNutri]="rowData" [displayLabel]="true"></yo-famille-gemrcn>
            </td>
          </ng-container>

          <!--DÉCLINAISONS-->
          <ng-container *ngSwitchDefault>
            <td [style.width.px]="100">
              <div class="text-center">
                <p-checkbox [ngModel]="getCheckedModel(rowData.id,col.field)"
                            (onChange)="changeCheckedModel(rowData.id,col.field,$event)"
                            binary="true">
                </p-checkbox>
              </div>
            </td>
          </ng-container>

        </ng-container>


      </ng-container>


    </tr>
  </ng-template>


</p-table>

<div class="d-flex mg-t-5">
  <div class="mr-auto">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <em>Sélectionner les familles Gemrcn...</em>
  </div>
  <div class="mg-r-5">
    <yo-button buttonClass="cta-success" iconClass="fas fa-save" (onClick)="saveFamillesGemrcn()"
               [disabled]="!gds.canModify(produit)" tooltip="Enregistrer"></yo-button>
  </div>
  <div>
    <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="utils.sidenav=false"
               class="cta-inner-delete"></yo-button>
  </div>
</div>





