import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ZoneDeStockageDTO} from '../../../core/dtos/zone-de-stockage-dto';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {Subscription} from 'rxjs';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {ZonesDeStockageService} from '../../../core/services/entities/zones-de-stockage.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ToastService} from "../../../core/services/technique/toast.service";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

@Component({
  selector: 'yo-up-zones-de-stockage',
  templateUrl: './up-zones-de-stockage.component.html',
  styleUrls: ['./up-zones-de-stockage.component.scss']
})
export class UpZonesDeStockageComponent implements OnInit, AfterViewInit {

  subscriptionRoute: Subscription;
  subSidenav: Subscription;

  zoneDeStockageList: ZoneDeStockageDTO[] = [];
  zoneDeStockageSelectedList: number[] = [];

  dataSource: any;

  uniteDeProduction: UniteDeProductionDTO;


  constructor(private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              public utils: UtilsService,
              private zdsSvc:ZonesDeStockageService,
              private routeMapSvc: RoutemapService,
              public udpSvc: UnitesDeProductionService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    //abonnement à l'annonce de l'ouverture du sidenav, cas où le router n'est pas reloadé, on veut quand meme reinit
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
    });

    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        this.zoneDeStockageSelectedList = data.uniteDeProductionSupplier?.zonesStockageOfUDP?.map(zs => zs.id);
        this.zoneDeStockageList = data.uniteDeProductionSupplier.zonesStockageList;
        this.dataSource = new DataSource({
          store: new ArrayStore({
            key: 'id',
            data: data.uniteDeProductionSupplier.zonesStockageList,
          }),
        });
      });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
  }

  /**
   * Sauvegarde des zones de stockage liées à l'unité de production {@link uniteDeProduction}.
   */
  private saveZonesDeStockage = (): void => {
    this.udpSvc.saveZonesDeStockage(this.uniteDeProduction, this.zoneDeStockageSelectedList).subscribe(response => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Liaison entre la zone de stockage et l'unité de production réussie avec succès`);
    });
  };

  onChangeZoneDeStockage: (event) => void = event => {
    if (event.addedItems?.length) {
      event.addedItems.forEach(item => {
        if (!this.zoneDeStockageSelectedList.filter(id => id === item.id).length) {
          this.zoneDeStockageSelectedList.push(item.id);
        }
      });
    }
    if (event.removedItems?.length) {
      event.removedItems.forEach(item => {
        if (this.zoneDeStockageSelectedList.filter(id => id === item.id).length) {
          this.zoneDeStockageSelectedList = this.zoneDeStockageSelectedList.filter(id => id !== item.id);
        }
      });
    }
    this.saveZonesDeStockage();
  };

}
