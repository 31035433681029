import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";

@Component({
  templateUrl: './view-plain-json-graphql.component.html',
  styleUrls: ['./view-plain-json-graphql.component.scss'],
  selector: 'yo-view-plain-json-graphql',
})
export class ViewPlainJsonGraphqlComponent implements OnInit, OnDestroy {

  hasRequeteurGraphQL: boolean;
  dataLoaded: boolean;
  data: any;

  subData: Subscription;

  constructor(private utils: UtilsService,
              private graphQlSvc: GraphQLService) {
    this.dataLoaded = false;
    this.data = null;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subData);
  }

  ngOnInit() {
    this.initDataSubscription();
  }

  isReady() {
    return this.dataLoaded && this.data;
  }

  initDataSubscription(): void {
    this.subData = this.graphQlSvc.queryReceived$
      .subscribe(data => {
        this.dataLoaded = true;
        this.data = data;
      });
  }

}
