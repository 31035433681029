<dx-popup
  [showTitle]="true"
  [title]="title"
  [visible]="isPopupDisplayed"
  [width]="800"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="dialog-line">
      <div class="user-details-left">
        <div class="dialog-line">
          <div class="label" title="Nom">Nom <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Nom" formControlName="nom"
            ></dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Prénom">Prénom <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Prénom" formControlName="prenom"
            ></dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="email">Email <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Email" formControlName="email"
            ></dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="code">Code <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Code" formControlName="code"
            ></dx-text-box>
          </div>
        </div>
      </div>
      <div class="user-details-right">
        <div class="dialog-line">
          <div class="label" title="Login">Login <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Login" formControlName="login"
            ></dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label text-nowrap" title="Mot de passe">Mot de passe <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box mode="password" class='inputfield' placeholder="Mot de passe"
                         formControlName="motDePasse">
            </dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Confirmation du mot de passe">Confirmation <span class="danger-color">*</span>
          </div>
          <div class="value">
            <dx-text-box mode="password" class='inputfield' placeholder="Confirmation"
                         formControlName="motDePasseConfirmation"
            ></dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
          <div class="value">
            <dx-check-box
              #actifCheckBox
              [value]="actif"
            ></dx-check-box>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="label" title="Liste des profils">
        <span class="text-nowrap">Liste des profils <span class="danger-color"> *</span> </span>
        <i *ngIf="showProfilDetails" (click)="displayPopUp()" class="fa-lg fa-solid fa-circle-info"></i>
      </div>
      <div class="value">
        <yo-lookup
          #profilLookup
          [data]="allProfils"
          (onValueChange)="onProfilChange($event)"
          [selectedItem]="selectedProfil"
        >
        </yo-lookup>
      </div>
    </div>
    <div class="dialog-line">
      <div class="label text-nowrap" title="Liste des environnements">Liste des environnements <span class="danger-color">*</span></div>
      <div class="value">
        <yo-tagbox
          #environnementsTagBox
          [data]="environnementList"
          [selectedItems]="environmentsSelected"
        >
        </yo-tagbox>
      </div>
    </div>
    <div *ngIf="showPortail" class="dialog-line">
      <div class="label text-nowrap" title="Points de livraison de l'environnement">Env. points de livraison<span
        class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          #environnementsPlcLookUp
          [data]="envPointDeLivraisonList"
        >
        </yo-lookup>
      </div>
    </div>
  </form>


  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button
        [tooltip]="utilisateur?.id ?'Enregistrer le nouvel utilisateur':'Sauvegarde des modifications'"
        type="submit"
        iconClass="fas fa-save"
        buttonClass="cta-success"
        (onClick)="save()">
      </yo-button>
      <yo-button tooltip="Fermer"
                 buttonClass="cta-delete"
                 (onClick)="closeDialog()"
                 iconClass="fas fa-times"
      ></yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="900"
  height="60vh"
  [showTitle]="true"
  title="Détails du profil {{utilisateur?.profil?.libelle}}"
  [(visible)]="isProfilPopupDisplayed"
  [resizeEnabled]="true"
  (onHiding)="closeProfilPopUp()"
>
  <dx-scroll-view
    #scrollView
    id="scrollview"
    showScrollbar="always"
  >

    <dx-data-grid
      showRowLines="true"
      showColumnLines="true"
      [dataSource]="utilisateur?.profil?.roleList"
    >
      <dxi-column dataField="libelle"
                  [allowFiltering]="false"
                  [allowGrouping]="false">

      </dxi-column>
      <dxi-column dataField="description"
                  [allowFiltering]="false"
                  [allowGrouping]="false">
      </dxi-column>

    </dx-data-grid>
  </dx-scroll-view>
</dx-popup>
