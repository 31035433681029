<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openEdit()"
          [disabled]="!canEdit()"
          tooltip="Créer une liaison avec un modèle point de livraison">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canEdit() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="associationsList"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(282)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"

              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openModel(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fas fa-eye"
                    yoTooltip="Voir la liaison"
    >
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} liaison(s)">
  </dxo-pager>
</dx-data-grid>


<yo-liaison-plc-mc-plc></yo-liaison-plc-mc-plc>


