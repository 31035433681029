import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import ModeleConditionnementPlc__PrestationDTO
  from "../../../../../dtos/conditionnement/plc/conditionnement/prestation/mc-plc-prestation-dto";
import {HttpService} from "../../../../technique/http.service";
import {ResponseWrapper} from "../../../../../suppliers/wrappers/response-wrapper";

export const URL_GET_ALL_MODELES_PLC_PRESTATIONS_BY_ID_MCPLC = `dolrest/conditionnements/modeles-conditionnements-plc/prestations/findByIdMcPlc`;

@Injectable({
  providedIn: 'root'
})
export class ModelesPlcPrestationsService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des points de livraison <-> prestations
   * en fonction de l'identifiant du modèle de conditionnement PLC
   * @returns {Observable<ModeleConditionnementPlatDTO>}
   */
  getAllByIdMcplc(idMcplc: number): Observable<ResponseWrapper<ModeleConditionnementPlc__PrestationDTO>> {
    return this.httpSvc.get(`${URL_GET_ALL_MODELES_PLC_PRESTATIONS_BY_ID_MCPLC}/${idMcplc}`, null);
  }

}
