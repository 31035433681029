import {ObjectDTO} from './object-dto';
import {UniteDeProduction__LivraisonDTO} from './unite-de-production__livraison-dto';

export class UniteDeProduction__SecteurFournisseurDTO extends ObjectDTO {

  idSecteurFournisseur : number;
  idUniteDeProduction :number;

  remarqueCommande : string;

  idSecteur:number;
  codeClient:string;
  delaiLivraisonHeureLimite:Date;
  delaiLivraisonJours:number;
  prefere:boolean;

  secteurCode : string;
  secteurLibelle:string;
  fournisseurLibelle : string;
  fournisseurCode : string;
  fournisseurId:number;


  uniteDeProduction__livraisonList: UniteDeProduction__LivraisonDTO[] =[];


  constructor() {
    super();
  }
}
