import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModelViewMouvementDTO} from '../../mouvements/model-view-mouvement';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {catchError} from 'rxjs/operators';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {StockMouvementService} from '../../../core/services/gestion-stock/stock-mouvement.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-modification-mouvement',
  templateUrl: './dialog-modification-mouvement.component.html',
  styleUrls: ['./dialog-modification-mouvement.component.scss']
})
export class DialogModificationMouvementComponent implements OnInit, OnDestroy {

  subAction: Subscription;
  subscriptionRoute: Subscription;

  displayDialog = false;
  ssw: SearchSupplierWrapper;

  selectedStockMvt: ModelViewMouvementDTO;

  form: FormGroup;

  localeFr = DATEPICKER_FR;

  constructor(public utils: UtilsService,
              public stockMvtSvc: StockMouvementService,
              private gfs: GenericFormService,
              public auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initForm();
    this.actionSubscription();
  }

  /**
   * Récupération des éléments permettant de générer le formulaire de modification
   * d'un mouvement.
   */
  actionSubscription = () => {
    this.subAction = this.stockMvtSvc.openDialogModificationMouvement$.pipe(
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.displayDialog = true;
      this.selectedStockMvt = response.mvmDto;
      this.initForm();
    });
  };

  initForm = () => {
    if (this.selectedStockMvt) {
      const dateMvt = new Date(this.selectedStockMvt.dateMouvement);

      if (this.selectedStockMvt.entreeSortie) { // Entrée
        let min = this.selectedStockMvt.quantite - this.selectedStockMvt.quantiteStock;
        if (min < 0 ) { min = 0; }

        this.form = new FormGroup({
          quantite: new FormControl(this.selectedStockMvt.quantite, [Validators.required, Validators.min(min)]),
          prixUnitaire: new FormControl(this.selectedStockMvt.prixUnitaire, [Validators.required]),
          dateMouvement: new FormControl(dateMvt, Validators.required),
        });
      } else { // Sortie
        this.form = new FormGroup({
          quantite: new FormControl(this.selectedStockMvt.quantite, [Validators.required, Validators.min(0)]),
          prixUnitaire: new FormControl(this.selectedStockMvt.prixUnitaire, [Validators.required]),
          dateMouvement: new FormControl(dateMvt, Validators.required),
        });
      }
    } else {
      this.form = new FormGroup({
        quantite: new FormControl(0, [Validators.required, Validators.min(0.0001)]),
        prixUnitaire: new FormControl(0, [Validators.required]),
        dateMouvement: new FormControl(new Date(), Validators.required),
      });
    }
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  startError = (property: string) => `Le champ "${property.toUpperCase()}" `;

  modifierMouvement = () => {
    this.gfs.validateAllFormFields(this.form);

    if (this.form.valid) {
      this.selectedStockMvt.quantite = this.form.controls['quantite'].value;
      this.selectedStockMvt.prixUnitaire = this.form.controls['prixUnitaire'].value;
      this.selectedStockMvt.dateMouvement = this.form.controls['dateMouvement'].value;

      this.stockMvtSvc.saveMouvement(this.selectedStockMvt).subscribe(response => {
        if (!this.utils.isNullOrEmpty(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le mouvement a été enregistré avec succès`);
          this.closeDialog();
        }
      });
    }
  };

}
