import {FormFieldBaseSupplier} from "./form-fieldbase-supplier";

export class FormFieldTextboxSupplier extends FormFieldBaseSupplier<string> {
  controlType = 'textbox';


  constructor(options: any){
    super(options);
  }

}
