import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {ClientsService} from '../../../core/services/entities/clients.service';
import {combineLatest, Observable} from 'rxjs';
import {ClientDTO} from '../../../core/dtos/client-dto';
import {ClientsBusinessService} from "../../../core/services/gestion-clients/clients.service";

@Injectable({
  providedIn: 'root'
})
export class ClientResolverService implements Resolve<ClientSupplier> {

  constructor(private gds: GenericDatagridService,
              private utils: UtilsService,
              private clientsSvc: ClientsService,
              private clientsBSvc: ClientsBusinessService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientSupplier> | Promise<ClientSupplier> | ClientSupplier {

    const cs = new ClientSupplier();
    const idClient: number = +route.paramMap.get('idClient');

    if (idClient === 0) {
      cs.client = this.clientsSvc.createEmptyDTO();
      return cs;
    }

    const client$ = this.gds.getOne(this.clientsSvc.getEntityName(), idClient);
    const all$ = combineLatest([client$]);

    return all$.toPromise().then(response => {
      cs.client = response[0].one;
      this.clientsBSvc.announceClient(cs.client);
      return cs;
    });
  }
}

export class ClientSupplier {
  client: ClientDTO;
}
