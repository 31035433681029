<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>

  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="dialog-line">
      <div class="label" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idSelectedSite"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="true"
          [placeholder]="sitePlaceholder"
          [disabled]="!canModify()"
          (onSelectionChanged)="onChangeSite($event)"
        >
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box placeholder="Libellé" formControlName="libelle" [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Code">Code <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box placeholder="Code" formControlName="code" [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="actif" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          type="submit"
          iconClass="fas fa-save"
          buttonClass="cta-success"
          [hidden]="!canModify()">
        </yo-button>
        <yo-button
          class="mg-l-5"
          buttonClass="cta-delete"
          (onClick)="closeDialog()"
          iconClass="fas fa-times"
          tooltip="Fermer">
        </yo-button>
      </div>
    </div>
  </form>

</dx-popup>
