import {Injectable} from '@angular/core';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {GenericRequestSupplier, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {ReglePrefereDTO} from '../../dtos/regle-prefere-dto';
import {TextureService} from './texture.service';


@Injectable({
  providedIn: 'root'
})
export class ReglePrefereService extends GenericHandler<ReglePrefereDTO> {

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private textureSvc: TextureService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO(): ReglePrefereDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  getEntityName(): string {
    return "ReglePrefere";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getTitle(): string {
    return "";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

  filterAll(): GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.getEntityName());
    const lcEntityName = this.getEntityName().toLowerCase();

    const search = new Search();
    search.predicats = [];

    const sort = new Sort();
    sort.path=`${lcEntityName}.code`;
    sort.dir='asc';
    search.sorts=[sort];

    grs.search = search;

    return grs;
  }

}
