import {ObjectDTO} from "./object-dto";

export class UniteDeProduction__denreeInterditeDto extends ObjectDTO {

  // unité de production
  idUniteDeProduction: number;

  // denrée interdite
  denreeInterditeId: number;
  denreeInterditeLibelle: string;
  denreeInterditeRatioUniteTechnique: number;
  denreeInterditeUniteDeMesure: string;

  denreeSubstitutionId: number;
  denreeSubstitutionLibelle: string;
  denreeSubstitutionRatioUniteTechnique: number;
  denreeSubstitutionUniteDeMesure: string;

  ratio: number;

}
